<template>
  <div>
    <div
      v-if="isIncoming || isInCall"
      class="p-fixed z-10 orange lighten-3 w-100 text-center pa-2 font-weight-semibold"
    >
      <span>{{ displayedName }}</span>
      <span class="mx-2 text-body-2 font-weight-regular"
        >({{ formattedPhone }})</span
      >
      <span v-if="callTime" class="info--text mx-4">
        {{ callTime }}
      </span>
      <v-btn
        v-if="isIncoming"
        color="success"
        class="mx-1"
        rounded
        depressed
        x-small
        @click="acceptIncomingCall"
        ><v-icon x-small class="mx-1">mdi-phone</v-icon
        ><span class="mx-1 font-weight-semibold">Accept</span></v-btn
      >
      <v-btn
        v-if="!isIncoming"
        color="success"
        class="mx-1"
        rounded
        depressed
        x-small
        :loading="!device"
        :disabled="isInCall || !device"
        @click="makeOutgoingCall(null)"
        ><v-icon x-small class="mx-1">mdi-phone</v-icon
        ><span class="mx-1 font-weight-semibold">Call</span></v-btn
      >
      <v-btn
        v-if="isIncoming"
        color="error"
        class="mx-1"
        rounded
        x-small
        depressed
        @click="declineCall"
        ><v-icon x-small class="mx-1">mdi-phone-hangup</v-icon
        ><span class="mx-1 font-weight-semibold">Decline</span></v-btn
      >
      <v-btn
        v-if="!isIncoming"
        color="error"
        class="mx-1"
        rounded
        depressed
        x-small
        :disabled="!isInCall"
        @click="hangupCall"
        ><v-icon x-small class="mx-1">mdi-phone-hangup</v-icon
        ><span class="mx-1 font-weight-semibold">End</span>
      </v-btn>
      <v-btn
        v-if="isIncoming && !isInCall"
        color="deep-orange"
        class="mx-1"
        rounded
        depressed
        dark
        x-small
        @click="ignoreCall"
        ><v-icon x-small class="mx-1">mdi-phone-hangup</v-icon
        ><span class="mx-1 font-weight-semibold">ignore Call</span>
      </v-btn>
      <span v-if="guestConversationId" class="info--text mx-2">
        <v-btn
          rounded
          text
          x-small
          color="amber lighten-4"
          class="grey--text text--darken-4 font-weight-semibold text-decoration-underline"
          @click="conversationLink"
        >
          Go to Conversation</v-btn
        >
      </span>
    </div>
    <v-dialog
      v-else
      v-model="dialog"
      width="300px"
      persistent
      :hide-overlay="true"
      :content-class="isIncoming || isInCall ? 'top-right' : ''"
    >
      <v-card class="">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon @click="ignoreCall">mdi-close</v-icon>
        </v-card-title>
        <v-card-text
          class="flex-1 text-center align-center d-flex flex-column justify-center"
        >
          <div class="text-h6 font-weight-medium">{{ formattedPhone }}</div>
          <user-avatar
            v-if="displayedName"
            class="my-6"
            :name="displayedName"
            :size="70"
            :color="stringToHslColor(displayedName)"
            content-class="text-h4"
          />
          <div class="text-h5 font-weight-bold text-capitalize">
            {{ displayedName }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-center gap-2 py-4">
          <v-btn
            v-if="!isIncoming"
            color="success"
            class="mx-1"
            rounded
            depressed
            :loading="!device"
            :disabled="isInCall || !device"
            @click="makeOutgoingCall(null)"
            ><v-icon class="mx-1">mdi-phone</v-icon
            ><span class="mx-1 font-weight-semibold">Call</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Device } from '@twilio/voice-sdk'
import UserAvatar from 'components/common/user-avatar.vue'
import ColorsMixin from 'components/mixins/colors-mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'VoiceModal',
  components: { UserAvatar },
  mixins: [ColorsMixin],
  props: ['phone', 'name'],
  data() {
    return {
      device: null,
      call: null,
      isInCall: false,
      isIncoming: false,
      callTime: null,
      callTimer: null,
      incomingPhone: null,
      guestName: null,
      guestConversationId: null,
    }
  },
  mounted() {
    if (window.callDevice) this.device = window.callDevice
    else this.startupClient()
  },
  computed: {
    ...mapGetters(['currentUser']),
    formattedPhone() {
      const number = this.phone || this.incomingPhone
      if (!number) return ''
      const phone = number.replace(/\D/g, '')
      return [[0, 3], [3, 5], [5, 8], [8]]
        .map(r => phone.slice(r[0], r[1]))
        .join(' ')
    },
    displayedName() {
      return this.name || this.guestName
    },
    dialog() {
      return this.$store.state.conversations.voice.dialog
    },
  },
  methods: {
    toggleModal(dialog) {
      this.$store.commit('conversations/updateVoiceModal', {
        dialog,
        phone: null,
        name: null,
      })
    },
    conversationLink() {
      this.$router.push({
        path: this.$route.path,
        query: { conversation: this.guestConversationId },
      })
    },
    startCallTimer() {
      let seconds = 0
      this.callTimer = setInterval(() => {
        seconds++
        const mins = Math.floor(seconds / 60)
        const secs = seconds % 60
        this.callTime = `${mins}:${secs < 10 ? '0' : ''}${secs}`
      }, 1000)
      console.log('callTimer', this.callTimer)
    },
    endCallTimer() {
      clearInterval(this.callTimer)
      this.call = null
      this.isIncoming = false
      this.callTime = null
      this.isInCall = false
    },
    callEvent(event) {
      return () => {
        console.log(`Call ${event}`)
        this.isIncoming = false
        if (event == 'accept' && this.isInCall) this.startCallTimer()
        else this.endCallTimer()
      }
    },
    showCallerInfo(phone) {
      this.$store.commit('conversations/updateVoiceModal', {
        phone,
        dialog: true,
      })
      this.$store
        .dispatch('conversations/getCallerInfo', {
          phone,
          call_sid: this.call.parameters.CallSid,
          user_id: this.currentUser.id,
        })
        .then(({ data }) => {
          this.guestName = data.conversation.guest.full_name
          this.guestConversationId = data.conversation.guesty_id
        })
    },
    handleCall() {
      this.call.on('accept', this.callEvent('accept'))
      this.call.on('disconnect', this.callEvent('disconnected'))
      this.call.on('cancel', this.callEvent('cancel'))
      this.call.on('reject', this.callEvent('reject'))
    },
    async makeOutgoingCall(number) {
      if (!this.device) return
      this.isInCall = true
      this.call = await this.device.connect({
        params: { To: number || this.phone, user_id: this.currentUser.id },
      })
      this.incomingPhone = number
      this.handleCall()
    },
    async handleIncomingCall(call) {
      console.log('incoming call', call.parameters)
      this.isIncoming = true
      this.call = call
      this.incomingPhone = call.parameters.From
      this.showCallerInfo(this.incomingPhone)
      this.handleCall()
    },
    acceptIncomingCall() {
      this.isInCall = true
      this.call.accept()
    },
    async declineCall() {
      this.call.reject()
    },
    async hangupCall() {
      if (!this.call) return
      this.call.disconnect()
      this.call = null
      this.isInCall = false
      clearInterval(this.callTimer)
      this.callTime = null
    },
    async ignoreCall() {
      this.toggleModal(false)
      if (this.isIncoming) {
        this.call.ignore()
        this.call = null
        this.isIncoming = false
      }
    },
    async startupClient() {
      const event = 'conversations/generateVoiceToken'
      const { data } = await this.$store.dispatch(event)
      const options = {
        logLevel: 1,
        codecPreferences: ['opus', 'pcmu'],
      }
      this.device = new Device(data.token, options)
      window.callDevice = this.device
      this.device.register()
      this.device.on('registered', () =>
        console.log('Twilio.Device Ready to make and receive calls!')
      )
      this.device.on('incoming', this.handleIncomingCall)
      this.device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message)
      })
    },
  },
}
</script>

<style scoped>
:deep() .top-right {
  margin-left: auto;
  align-self: flex-start;
}
/* Add any scoped styles here */
.z-10 {
  z-index: 10;
}
</style>
