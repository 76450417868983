<template>
  <v-menu v-model="menu" offset-y>
    <template #activator="{ on, attrs }">
      <div>
        <v-sheet
          v-bind="attrs"
          :class="[
            `d-flex
            justify-space-between
            lt-status-label
            text-uppercase
            rounded
            text-no-wrap
            font-weight-medium
            white--text
            ${listingTaskStatusColor(value)}`,
            disabled && 'opacity-30',
            {
              'px-3 py-1 text-caption': small,
              'text-body-2 px-4 py-2': !small,
            },
          ]"
          :width="width"
          v-on="isReadonly || disabled ? null : on"
        >
          <span>{{ $t(value) }}</span>
          <v-icon
            v-if="isApproved"
            class="pl-2"
            :color="listingTaskStatusColor(value)"
            x-small
          >
            fas fa-user-check
          </v-icon>
          <v-icon v-else class="pl-2" color="white" x-small>
            fas fa-chevron-down
          </v-icon>
        </v-sheet>
        <v-tooltip v-if="getIsReasonedStatus(value) && !isMobile" bottom>
          <template #activator="{ on }">
            <span
              class="text-caption text-decoration-underline error--text"
              v-on="on"
            >
              {{ $t('More info') }}
            </span>
          </template>
          <div class="text-caption">
            {{ hint }}
          </div>
        </v-tooltip>
      </div>
    </template>
    <v-list v-if="menu">
      <v-list-item-group
        :value="statusValue"
        color="primary"
        @change="statusChanged"
      >
        <template v-for="item in rawStatuses">
          <v-list-item
            v-if="item.if"
            :key="item.value"
            :value="item.value"
            :disabled="item.disabled"
          >
            <v-list-item-title class="text-body-2">
              <v-icon
                x-small
                :color="listingTaskStatusColor(item.value)"
                class="pr-2"
              >
                fas fa-square
              </v-icon>
              {{ $t(item.value) }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { TASK_STATUSES } from '@/consts'
import { get, isNumber, last } from 'lodash'
import { mapGetters } from 'vuex'
import DeviceMixin from 'components/mixins/device-mixin'
import ListingTaskMethodsMixin from 'components/listing-tasks/mixins/listing-task-methods-mixin'

export default {
  name: 'LtStatus',
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    DeviceMixin,
    ListingTaskMethodsMixin,
  ],
  props: [
    'small',
    'width',
    'isSubTask',
    'readonly',
    'listingTask',
    'hideDone',
    'disabled',
  ],
  data() {
    return {
      menu: false,
      statusValue: this.status,
    }
  },
  computed: {
    ...mapGetters(['currentUserName']),
    value() {
      return this.status
    },
    status() {
      return this.isPendingApproval
        ? TASK_STATUSES.PENDING_APPROVAL
        : this.listingTask.status
    },
    isPendingApproval() {
      return (
        this.listingTask.status === TASK_STATUSES.DONE &&
        [null, false].includes(this.listingTask.manual_approved)
      )
    },
    isApproved() {
      return this.listingTask.manual_approved
    },
    isReadonly() {
      return (
        (this.isDone(this.listingTask.status) && !this.managementRoles) ||
        this.readonly
      )
    },
    hint() {
      const lastRejected = last(this.listingTask.listing_task_rejects)
      return (lastRejected || {}).reason
    },
    pendingCaptureStoreItem() {
      return (
        get(this.listingTask, 'store_item_purchase.status') === 'authorized'
      )
    },
    subTasksDone() {
      const subTasks = this.listingTask.sub_tasks || []
      if (
        this.listingTask.task_type === 'cleaning' ||
        this.listingTask.task_type === 'inspection'
      ) {
        return subTasks.every(
          t => t.is_valid || t.status === TASK_STATUSES.DONE
        )
      }
      return subTasks.every(
        t =>
          t.status === TASK_STATUSES.DONE || t.status === TASK_STATUSES.CANT_DO
      )
    },
    rawStatuses() {
      return [
        {
          value: TASK_STATUSES.NEW,
          if: true,
          disabled: this.statusValue === TASK_STATUSES.NEW,
        },
        {
          value: TASK_STATUSES.IN_PROGRESS,
          if: true,
          disabled: this.statusValue === TASK_STATUSES.IN_PROGRESS,
        },
        {
          value: TASK_STATUSES.PAUSED,
          if: !this.listingTask.listing_id,
          disabled: this.statusValue === TASK_STATUSES.PAUSED,
        },
        {
          value: TASK_STATUSES.IN_REVIEW,
          if: true,
          disabled: this.statusValue === TASK_STATUSES.IN_REVIEW,
        },
        {
          value: TASK_STATUSES.DONE,
          if: !this.hideDone,
          disabled:
            this.statusValue === TASK_STATUSES.DONE ||
            this.pendingCaptureStoreItem ||
            !this.subTasksDone,
        },
        {
          value: TASK_STATUSES.CANT_DO,
          if: this.listingTask.listing_id,
          disabled: this.statusValue === TASK_STATUSES.CANT_DO,
        },
        {
          value: TASK_STATUSES.CLOSED,
          if:
            this.isOperationsPersonal ||
            this.isAdmin ||
            this.isCommunicationManager ||
            this.isSalesManager,
          disabled: this.statusValue === TASK_STATUSES.CLOSED,
        },
        {
          value: TASK_STATUSES.INVESTIGATION,
          if: this.managementRoles && this.listingTask.listing_id,
          disabled: this.statusValue === TASK_STATUSES.INVESTIGATION,
        },
        {
          value: TASK_STATUSES.OWNER_APPROVAL,
          if: this.managementRoles && this.listingTask.listing_id,
          disabled: this.statusValue === TASK_STATUSES.OWNER_APPROVAL,
        },
      ]
    },
  },
  watch: {
    value(val) {
      this.statusValue = val
    },
  },
  methods: {
    updateTask(payload) {
      return this.$store.dispatch(
        this.isSubTask ? 'updateListingSubTask' : 'updateListingTask',
        {
          id: this.listingTask.id,
          ...payload,
        }
      )
    },
    getIsReasonedStatus(currentStatus) {
      const { REJECTED, CLOSED, CANT_DO } = TASK_STATUSES
      return [REJECTED, CLOSED, CANT_DO].includes(currentStatus)
    },
    async statusChanged(value) {
      this.statusValue = value
      const isReasonedStatus = this.getIsReasonedStatus(value)
      if (isReasonedStatus && !this.isSubTask) {
        this.$store.commit('showModal', {
          name: 'RejectModal',
          props: {
            listingTaskId: this.listingTask.id,
            status: value,
            closeCallback: () => {
              this.statusValue = this.value
              this.$emit('after-change', value)
            },
            onChange: () => this.$emit('change', { status: value }),
          },
        })
      } else {
        const payload = {
          status: value,
        }
        const emitPayload = { status: value }

        if (value === TASK_STATUSES.DONE) {
          payload.approved_by_name = this.currentUserName
          payload.manual_approved = true
          emitPayload.approved_by_name = this.currentUserName
          emitPayload.manual_approved = true
          if (!isNumber(this.listingTask.price)) {
            payload.price = 0
          }
          if (!isNumber(this.listingTask.hours)) {
            payload.hours = 0
          }
        }

        if (
          value === TASK_STATUSES.DONE &&
          this.listingTask.listing_task_template &&
          this.listingTask.in_loop
        ) {
          const condition =
            this.listingTask.listing_task_template.loop_condition_text
          this.$store.commit('showModal', {
            name: 'LtLoopMoveToDoneModal',
            props: {
              condition,
              payload,
              listingTask: this.listingTask,
              onChange: () => this.$emit('change', emitPayload),
              afterChange: () => this.$emit('after-change', value),
            },
          })
        } else {
          this.$emit('change', emitPayload)
          await this.updateTask(payload)
          this.$emit('after-change', value)
        }
      }
    },
  },
}
</script>

<style scoped>
.lt-status-label {
  position: relative;
}

.lt-status-label::before {
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  left: 0;
  position: absolute;
  background-color: currentColor;
  opacity: 0.12;
}
</style>
