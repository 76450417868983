<template>
  <v-layout wrap align-center justify-center>
    <v-flex xs11 mt-3>
      <v-layout wrap>
        <v-flex xs12 justify-center mb-2>
          <h3 class="purple-icon">Experience Requests</h3>
          <v-progress-circular
            v-show="listingLoading"
            :size="20"
            class="ml-2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-flex>
        <v-flex xs12>
          <v-data-table :headers="headers" :items="items" class="elevation-1">
            <template #item="{ item }">
              <tr>
                <td class="text-sm-center">{{ item.dates_range }}</td>
                <td class="text-sm-center">
                  {{ parseDate(item.requested_date) }}
                </td>
                <td class="text-sm-center bolder">
                  {{ item.experience.experience_catagory }}
                </td>
                <td class="text-sm-center">{{ item.reservation_adr }}</td>
                <td class="text-sm-center">{{ item.guest_info.name }}</td>
                <td class="text-sm-center">
                  {{ item.guest_info.guest_amount }}
                </td>
                <td class="text-sm-center">{{ item.guest_info.email }}</td>
                <td class="text-sm-center">{{ item.guest_info.phone }}</td>
                <td>{{ truncateBy(item.listing_address, 30) }}</td>
                <td class="text-sm-center">
                  <v-select
                    v-model="item.status"
                    :items="statuses()"
                    class="cyan-icon"
                    style="max-width: 100px"
                    @change="updateRequest($event, 'status', item)"
                  />
                </td>
                <td class="text-sm-center">
                  <v-textarea
                    v-model="item.comments"
                    placeholder="comments..."
                    style="
                      min-height: 100px;
                      max-width: 80%;
                      border: 1px solid lightgray;
                      padding: 10px;
                    "
                    rows="4"
                    cols="15"
                    @change="updateRequest($event, 'comments', item)"
                  >
                  </v-textarea>
                </td>
                <td class="text-sm-center">
                  <v-checkbox
                    v-model="item.sent_email"
                    class="checkbox-txt"
                    @change="updateRequest($event, 'sent_email', item)"
                  ></v-checkbox>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin],
  data: () => ({
    headers: [
      { text: 'Reservation Dates', align: 'center', sortable: false },
      { text: 'Requested Date', align: 'center', sortable: false },
      { text: 'Type', align: 'center', sortable: false },
      { text: 'ADR', align: 'center', sortable: false },
      { text: 'Name', align: 'center', sortable: false },
      { text: 'Guest amount', align: 'center', sortable: false },
      { text: 'Email', align: 'center', sortable: false },
      { text: 'Phone', align: 'center', sortable: false },
      { text: 'Address', align: 'center', sortable: false },
      { text: 'Status', align: 'center', sortable: false },
      { text: 'Comments', align: 'center', sortable: false },
      { text: 'Sent email', align: 'center', sortable: false },
    ],
  }),

  computed: {
    items() {
      return this.$store.state.experienceRequests
    },
  },
  mounted() {},

  methods: {
    statuses() {
      return ['New', 'Initial contact', 'Failed', 'Success']
    },
    updateRequest(newVal, key, request) {
      if (key === 'status') {
        request[key] = newVal
      }
      this.$store.commit('updateListingLoading', true)

      axios
        .post(`/api/experiences/${request.id}`, request)
        .then(response => {
          this.$store.commit(
            'updateExperienceRequests',
            response.data.payload.data.experience_requests
          )
          this.$store.commit('updateListingLoading', false)
        })
        .catch(error => {
          alert(error)
        })
    },
  },
}
</script>
