<template>
  <div>
    <v-progress-linear
      v-show="loading"
      color="secondary"
      style="margin: 0"
      :indeterminate="true"
    />
    <v-tabs
      v-if="$route.name !== 'integration-page'"
      v-model="paramsTab"
      icons-and-text
      class="tabs-menu main-background"
    >
      <v-tab v-if="hasAbility('users-management')" href="#users">
        {{ $t('Users') }}
        <v-icon>$people</v-icon>
      </v-tab>
      <v-tab
        v-if="isAdmin && (hasAbility('super-admin') || !isDesignedVr)"
        href="#settings"
      >
        {{ $t('Settings') }}
        <v-icon>$settings</v-icon>
      </v-tab>
      <v-tab
        v-if="hasAbility(['real-estate-finance']) && showModule('projects')"
        href="#reala"
      >
        {{ $t('Realestate') }}
        <v-icon>$home</v-icon>
      </v-tab>
      <v-tab v-if="managementRoles && showModule('ops')" href="#regions">
        {{ $t('Regions') }}
        <v-icon>mdi-map-legend</v-icon>
      </v-tab>
      <v-tab v-if="managementRoles && showModule('ops')" href="#zones">
        {{ $t('Zones') }}
        <v-icon>mdi-map-marker-circle</v-icon>
      </v-tab>
      <v-tab v-if="managementRoles && showModule('website')" href="#websites">
        {{ $t('Websites') }}
        <v-icon>fas fa-images</v-icon>
      </v-tab>
      <v-tab v-if="managementRoles" href="#coupons-rules">
        {{ $t('Coupon settings') }}
        <v-icon>$settings</v-icon>
      </v-tab>
      <v-tab v-if="managementRoles" href="#coupons">
        {{ $t('Coupons') }}
        <v-icon>sell</v-icon>
      </v-tab>
      <v-tab
        v-if="
          isDesignedVr && showModule('ops') && hasAbility('estimates-config')
        "
        href="#config"
      >
        {{ $t('Estimates') }}
        <v-icon>$calculator</v-icon>
      </v-tab>
      <v-tab
        v-if="isAdmin && hasAbility('integrations-manager')"
        href="#marketplace"
      >
        {{ $t('Integrations') }}
        <v-icon>$complete</v-icon>
      </v-tab>
      <v-tab v-if="managementRoles && showModule('website')" href="#taxes">
        {{ $t('Charge Profiles') }}
        <v-icon>$calculator</v-icon>
      </v-tab>
      <v-tab
        v-if="showModule('channel-manager') && (isDemoTenant || isSystemAdmin)"
        href="#channel-management"
      >
        {{ $t('Channel Mgmt') }}
        <v-icon>mdi-home-assistant</v-icon>
      </v-tab>
      <v-tab v-if="isSystemAdmin && isAdminTenant" href="#tenant-settings">
        {{ $t('Tenant Settings') }}
        <v-icon>mdi-cog</v-icon>
      </v-tab>
      <v-tab v-if="isSystemAdmin && isAdminTenant" href="#aibuilder">
        {{ $t('AI builder') }}
        <v-icon>smart_toy</v-icon>
      </v-tab>
      <v-tab
        v-if="isAdmin && (hasAbility('super-admin') || !isDesignedVr)"
        href="#business-model"
      >
        {{ $t('Business Model') }}
        <v-icon>mdi-office-building</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="paramsTab">
      <v-tab-item value="regions">
        <regions-editor-tab />
      </v-tab-item>
      <v-tab-item value="zones">
        <zones-editor-tab />
      </v-tab-item>
      <v-tab-item value="websites">
        <booking-engine-table />
      </v-tab-item>
      <v-tab-item value="coupons-rules">
        <coupons-rules />
      </v-tab-item>
      <v-tab-item value="coupons">
        <coupons />
      </v-tab-item>
      <v-tab-item v-if="isSystemAdmin && isAdminTenant" value="aibuilder">
        <ai-builder />
      </v-tab-item>
      <v-tab-item
        v-if="managementRoles"
        id="settings"
        class="pl-4 pr-4 main-background"
      >
        <settings v-if="!isEmpty(settings)" />
      </v-tab-item>
      <v-tab-item id="reala" class="pl-4 pr-4 main-background">
        <real-estate-page />
      </v-tab-item>
      <v-tab-item
        v-if="isAdmin || hasAbility('users-management')"
        id="users"
        class="pl-4 pr-4 main-background"
      >
        <v-layout>
          <v-flex xs12 sm10 offset-sm1 mt-2>
            <users-table :headers="usersHeaders" :items="usersManageList" />
          </v-flex>
        </v-layout>
      </v-tab-item>
      <v-tab-item id="config" class="full">
        <estimates-config />
      </v-tab-item>
      <v-tab-item
        v-if="isAdmin && hasAbility('integrations-manager')"
        value="marketplace"
        eager
        class="full"
      >
        <router-view />
      </v-tab-item>
      <v-tab-item value="taxes">
        <tax-profile-table />
      </v-tab-item>
      <v-tab-item value="channel-management">
        <channel-management-table />
      </v-tab-item>
      <v-tab-item value="tenant-settings">
        <tenant-settings />
      </v-tab-item>
      <v-tab-item value="business-model">
        <business-model-tab />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Settings from 'components/settings'

import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import UsersTable from 'components/common/users-table'
import { mapGetters } from 'vuex'
import RealEstatePage from './real-estate/real-estate-page'
import RegionsEditorTab from './regions/regions-editor-tab'
import ZonesEditorTab from './zones/zones-editor-tab'
import EstimatesConfig from './estimates/estimates-config'
import TaxProfileTable from 'components/tax-profiles/tax-profile-table.vue'
import ChannelManagementTable from 'components/channel-settings/channel-management-table.vue'
import TenantSettings from 'components/tenant-settings/tenant-settings.vue'
import Coupons from 'components/coupons'
import CouponsRules from 'components/coupons-rules'
import BookingEngineTable from 'components/booking-engine/booking-engine-table.vue'
import AiBuilder from 'components/ai-builder'
import BusinessModelTab from './business-model/business-model-tab.vue'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  computed: {
    ...mapGetters('users', ['usersManageList']),
    settings() {
      return this.$store.state.settings
    },
    currentListing() {
      return this.$store.state.currentListing
    },
    loading() {
      return this.$store.state.loading
    },
    usersHeaders() {
      return [
        {
          name: 'role',
          text: 'Role',
          align: 'center',
          sortable: false,
          value: 'role',
        },
        {
          text: 'First',
          align: 'center',
          sortable: true,
          value: 'first_name',
        },
        { text: 'Last', align: 'center', sortable: true, value: 'last_name' },
        { text: 'Email', align: 'center', sortable: true, value: 'email' },
        {
          text: 'Department',
          align: 'center',
          sortable: false,
          value: 'department',
        },
        {
          text: 'Zone',
          align: 'center',
          sortable: false,
          value: 'zone_name',
        },
        {
          text: 'Region',
          align: 'center',
          sortable: false,
          value: 'region_name',
        },
        {
          text: 'Payroll',
          align: 'center',
          sortable: false,
          value: 'on_payrole',
        },
        { text: 'Active', align: 'center', sortable: false, value: 'active' },
        { text: 'Action', align: 'center', sortable: false, value: 'action' },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('getSettings')
    this.$store.dispatch('getMarketplaceIntegrations')
    this.$store.dispatch('stripe/getStripeAccounts')
    this.$store.dispatch('users/getUsersStats')
  },
  methods: {
    back() {
      this.$router.push('/dashboard')
    },
  },
  components: {
    BookingEngineTable,
    EstimatesConfig,
    RegionsEditorTab,
    ZonesEditorTab,
    RealEstatePage,
    UsersTable,
    Settings,
    TaxProfileTable,
    ChannelManagementTable,
    TenantSettings,
    Coupons,
    CouponsRules,
    AiBuilder,
    BusinessModelTab,
  },
}
</script>
