<template>
  <v-dialog v-model="dialog" persistent max-width="300px">
    <template #activator="{ on }">
      <v-btn
        :rounded="false"
        class="rounded-0 justify-start"
        block
        :disabled="disabled"
        text
        small
        color="info"
        elevation="0"
        v-on="on"
      >
        <v-icon small left>fas fa-donate</v-icon>Management Fee
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form" @submit="submit">
        <v-card-title> Management Fee </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.number="fee"
            dense
            :hint="`Recommended 20% fee which is $${round(0.2 * amount)}`"
            persistent-hint
            placeholder="Fee in dollars"
            label="Management Fee"
            :rules="[required]"
            outlined
            type="number"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small color="primary" outlined @click="closeModal"
            >Cancel</v-btn
          >
          <v-btn small color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  name: 'LtManageFeeModal',
  mixins: [formRules, CommonFunctions, PermissionsMixin],
  props: ['taskId', 'amount', 'disabled'],
  data() {
    return {
      dialog: false,
      fee: null,
    }
  },
  methods: {
    closeModal() {
      this.dialog = false
      this.$refs.form.reset()
    },
    submit(e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        const { fee, taskId } = this
        this.$store.dispatch('updateListingTask', {
          id: taskId,
          management_fee: fee,
        })
        this.closeModal()
      }
    },
  },
}
</script>

<style scoped></style>
