<template>
  <v-card>
    <conversation-info-title title="Issues" />
    <v-divider />
    <v-card-text class="pa-2">
      <v-col cols="12">
        <listing-task-module
          :pre-filled-listing-id="[reservation.listing_id]"
          class="mt-2 mb-2"
          :reservation="reservation"
          :after-save-func="afterTaskCreate"
        >
          <template #customBtn="{ on }">
            <v-btn
              depressed
              color="blue-grey"
              dark
              small
              class="mt-2"
              v-on="on"
            >
              <v-icon left class="mr-1">add</v-icon>
              {{ $t('New SC') }}
            </v-btn>
          </template>
        </listing-task-module>
      </v-col>
      <v-col xs12 class="bolder bb-1 center-text" mt-1 mb-1>
        {{
          isOutStanding ? 'Outstanding issues' : 'Service calls during the stay'
        }}
      </v-col>
      <div>
        <div v-for="issue in issues || []" :key="issue.id" class="my-4 mx-2">
          <div class="d-flex align-center">
            <span>
              <span class="me-3">
                <v-chip x-small class="pa-3">
                  <v-icon
                    class="mr-2"
                    :color="listingTaskStatusColor(issue.status)"
                    x-small
                  >
                    mdi-circle
                  </v-icon>
                  {{ issue.status }}
                </v-chip>
              </span>
              {{ issue.task_type }} <v-icon small>mdi-chevron-right</v-icon>
              {{ issue.sub_topic }} <v-icon small>mdi-chevron-right</v-icon>
              {{ issue.listing_task_catalog_item_name }}
              <br />
              <div class="black--text font-weight-medium my-3">
                {{ issue.description }}
              </div>
              <span v-if="issue.internal_comments">
                | Internal Comments: {{ issue.internal_comments }}</span
              >
            </span>
          </div>
          <div class="d-flex">
            <div class="d-flex align-center">
              <div
                class="status-circle mr-2"
                :class="priorityColor(issue.priority)"
              ></div>
              <span>
                {{ issue.priority }}
              </span>
            </div>
            <v-divider vertical class="mx-4" />
            <div class="d-flex align-center">
              <router-link
                class="me-1"
                target="_blank"
                :to="`/dashboard/listing-task/${issue.id}`"
              >
                {{ `#${issue.id}` }}
              </router-link>
              <v-tooltip
                v-if="
                  issue.extra_data &&
                  issue.extra_data.created_on_guest &&
                  issue.extra_data.created_on_guest.id === conversation.guest.id
                "
                top
              >
                <div>
                  <div>** Reported by guest **</div>
                  <div>{{ issue.reported_by_name }}</div>
                </div>
                <template #activator="{ on }">
                  <v-icon x-small class="red-warn mr-1" v-on="on"
                    >warning
                  </v-icon>
                </template>
              </v-tooltip>
            </div>
            <v-divider vertical class="mx-4" />
            <div>
              <span>Scheduled:</span>
              <span class="font-weight-medium">
                {{ parseDateWithTimeIfPresent(issue.scheduled_at) }}</span
              >
              <br />
            </div>
          </div>
          <v-divider class="mt-3" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import ListingTaskModule from '../listing-task-module'

export default {
  components: {
    ListingTaskModule,
    ConversationInfoTitle,
  },
  mixins: [CommonFunctions, PermissionsMixin, ColorsMixin],
  props: ['conversation', 'reservation', 'issuesField'],
  data: () => ({
    reportedByComm: false,
    issuesPri: ['Critical', 'High'],
  }),
  computed: {
    issues() {
      return this.conversation[this.issuesField || 'issues']
    },
    isOutStanding() {
      return this.issuesField == 'outstanding_issues'
    },
  },
  methods: {
    afterTaskCreate() {
      setTimeout(() => {
        this.$store.dispatch('conversationUpdate', { id: this.conversation.id })
      }, 500)
    },
  },
}
</script>
<style scoped>
.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: blue;
}
</style>
