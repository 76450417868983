<template>
  <svg
    id="_001-profit"
    data-name="001-profit"
    xmlns="http://www.w3.org/2000/svg"
    width="27.999"
    height="28"
    viewBox="0 0 27.999 28"
  >
    <g
      id="Group_11452"
      data-name="Group 11452"
      transform="translate(13.179 9.899)"
    >
      <g id="Group_11451" data-name="Group 11451">
        <path
          id="Path_6658"
          data-name="Path 6658"
          d="M248.383,181.005a7.383,7.383,0,1,0,7.437,7.383A7.391,7.391,0,0,0,248.383,181.005Zm.842,11.336c-.007,0-.015,0-.022,0v.965a.82.82,0,0,1-1.641,0v-.97a3.374,3.374,0,0,1-1.5-1.057.82.82,0,0,1,1.256-1.054,1.265,1.265,0,0,0,1.345.571.821.821,0,0,0-.28-1.592,2.461,2.461,0,0,1-.959-4.728c.045-.019.093-.018.139-.034v-.98a.82.82,0,1,1,1.641,0v.981a3.169,3.169,0,0,1,1.209.73.82.82,0,0,1-1.142,1.178,1.121,1.121,0,0,0-1.206-.364.821.821,0,0,0,.319,1.577,2.461,2.461,0,0,1,.842,4.773Z"
          transform="translate(-241 -181.005)"
          class="a"
        />
      </g>
    </g>
    <g id="Group_11454" data-name="Group 11454" transform="translate(0 18.102)">
      <g id="Group_11453" data-name="Group 11453">
        <path
          id="Path_6659"
          data-name="Path 6659"
          d="M.824,331a.82.82,0,0,0-.82.82v8.258a.82.82,0,0,0,.82.82H3.285V331Z"
          transform="translate(-0.004 -331.003)"
          class="a"
        />
      </g>
    </g>
    <g id="Group_11456" data-name="Group 11456" transform="translate(6.617 0)">
      <g id="Group_11455" data-name="Group 11455">
        <path
          id="Path_6660"
          data-name="Path 6660"
          d="M133.841,5.12,128.1.2a.817.817,0,0,0-1.067,0L121.29,5.12a.82.82,0,0,0,.534,1.443h2.461V28h5.742a.82.82,0,0,0,.82-.82V25.308a9.007,9.007,0,0,1,0-16.054V6.562h2.461a.82.82,0,0,0,.534-1.443Z"
          transform="translate(-121.003 0.001)"
          class="a"
        />
      </g>
    </g>
    <g
      id="Group_11458"
      data-name="Group 11458"
      transform="translate(4.976 11.539)"
    >
      <g id="Group_11457" data-name="Group 11457">
        <path
          id="Path_6661"
          data-name="Path 6661"
          d="M91.822,211.005a.82.82,0,0,0-.82.82v15.64h3.281V211.005Z"
          transform="translate(-91.002 -211.005)"
          class="a"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Profit',
}
</script>

<style scoped>
.a {
  stroke: currentColor;
  fill: transparent;
}
</style>
