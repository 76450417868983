import { render, staticRenderFns } from "./user-notifications.vue?vue&type=template&id=51f9e21e&scoped=true&"
import script from "./user-notifications.vue?vue&type=script&lang=js&"
export * from "./user-notifications.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f9e21e",
  null
  
)

export default component.exports