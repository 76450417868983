<template>
  <v-menu v-model="menu" offset-y>
    <template #activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        :color="statusColor"
        label
        outlined
        :small="small"
        v-on="readonly ? null : on"
      >
        {{ value }}
        <v-icon v-if="menu">mdi-chevron-up</v-icon>
        <v-icon v-else>mdi-chevron-down</v-icon>
      </v-chip>
    </template>
    <v-list>
      <v-list-item-group :value="value" @change="statusChanged">
        <v-list-item value="Pending purchase">
          <v-list-item-title class="text-body-2">
            <v-icon x-small color="warning lighten-2" class="pr-2"
              >fas fa-square
            </v-icon>
            Pending purchase
          </v-list-item-title>
        </v-list-item>
        <v-list-item value="Pending arrival">
          <v-list-item-title class="text-body-2">
            <v-icon x-small color="orange lighten-2" class="pr-2"
              >fas fa-square
            </v-icon>
            Pending arrival
          </v-list-item-title>
        </v-list-item>
        <v-list-item value="Arrived">
          <v-list-item-title class="text-body-2">
            <v-icon x-small color="green lighten-2" class="pr-2"
              >fas fa-square
            </v-icon>
            Arrived
          </v-list-item-title>
        </v-list-item>
        <v-list-item value="Dispatched">
          <v-list-item-title class="text-body-2">
            <v-icon x-small color="purple lighten-2" class="pr-2"
              >fas fa-square
            </v-icon>
            Dispatched
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'ShopItemStatus',
  props: ['value', 'small', 'width', 'readonly'],
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    statusColor() {
      switch (this.value) {
        case 'Pending purchase':
          return 'warning lighten-2'
        case 'Pending arrival':
          return 'orange lighten-2'
        case 'Arrived':
          return 'green lighten-2'
        case 'Dispatched':
          return 'purple lighten-2'
        default:
          return 'secondary'
      }
    },
  },
  methods: {
    statusChanged(value) {
      this.$emit('update:value', value)
      this.$emit('change', value)
    },
  },
}
</script>

<style scoped></style>
