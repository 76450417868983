<template>
  <v-sheet height="100%">
    <v-tabs
      v-model="paramsTab"
      icons-and-text
      class="tabs-menu main-background invetor-tab"
    >
      <template v-for="tab in tabs">
        <v-tab :key="tab.key" :href="`#${tab.key}`">
          {{ $t(tab.title) }}
          <v-icon>{{ tab.icon }}</v-icon>
        </v-tab>
      </template>
    </v-tabs>
    <PowerBIReportEmbed
      :embed-config="embedConfig"
      css-class-name="reportClass"
      :phased-embedding="false"
      :event-handlers="new Map([])"
    />
  </v-sheet>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from '@/axios/config'
import { PowerBIReportEmbed } from 'powerbi-client-vue-js'
import { models } from 'powerbi-client'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  components: {
    PowerBIReportEmbed,
  },
  data() {
    return {
      tabs: [],
      token: null,
    }
  },
  async beforeCreate() {
    const { data } = await axios.get(`/api/bi/reports/embed-token`)
    this.tabs = data['reports'] || []
    this.token = data['embed_token'] || []
  },
  created() {
    if (!this.paramsTab) {
      this.paramsTab = this.isContractor ? 'today-tasks' : 'tasks-calendar'
    }
  },
  computed: {
    tenantId() {
      return this.$store.state.app.configuration.tenant_id
    },
    embedConfig() {
      const currentTab = this.tabs.find(tab => tab.key === this.paramsTab) || {}
      return {
        type: 'report',
        id: currentTab['report_id'],
        embedUrl: 'https://app.powerbi.com/reportEmbed',
        accessToken: this.token,
        tokenType: models.TokenType.Embed,
        filters: [
          {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
              table: 'DimProperties',
              column: 'public_id',
            },
            operator: 'In',
            values: [this.tenantId],
            filterType: models.FilterType.Basic,
            requireSingleSelection: true,
          },
        ],
        settings: {
          zoomLevel: 1.1,
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
          },
          background: models.BackgroundType.Transparent,
        },
      }
    },
  },
}
</script>
<style>
.reportClass {
  height: 100%;
}
</style>
