import axios from 'axios'

export default {
  namespaced: true,
  state: {
    listingWarranties: [],
    activeWarranties: [],
  },
  mutations: {
    setWarranties(state, payload) {
      state.listingWarranties = payload
    },
    setActiveWarranties(state, payload) {
      state.activeWarranties = payload
    },
  },
  actions: {
    getWarranties(context, listingId) {
      if (listingId) {
        axios.get(`/api/listings/${listingId}/warranties/`).then(res => {
          context.commit('setWarranties', res.data)
        })
      }
    },
    getActiveWarranties(context, listingId) {
      if (listingId) {
        axios.get(`/api/listings/${listingId}/active-warranties/`).then(res => {
          context.commit('setWarranties', res.data)
        })
      }
    },
    async createWarranty(context, { listing_task_id, ...payload }) {
      await axios.post(`/api/listing-task/${listing_task_id}/warranty`, payload)
    },
    async updateWarranty(
      context,
      { listing_task_id, warranty_id, ...payload }
    ) {
      await axios.put(
        `/api/listing-task/${listing_task_id}/warranty/${warranty_id}`,
        payload
      )
    },
    checkWarranty(context, params) {
      axios
        .get(`/api/warranties-check`, {
          params: {
            listing_id: params.listing_id,
            catalog_id: params.catalog_id,
          },
        })
        .then(res => {
          context.commit('setActiveWarranties', res.data)
        })
    },
  },
}
