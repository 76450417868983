import DeviceMixin from 'components/mixins/device-mixin'

export default {
  mixins: [DeviceMixin],
  methods: {
    watchQuery(models) {
      models.forEach(m => this.$watch(m, v => this.pushQuery(m, v)))
    },
    pushQuery(k, v) {
      this.$router.push({
        query: { ...this.$route.query, [k]: v },
      })
    },
    directionsTo(address) {
      const url = `https://www.google.com/maps/dir/?api=1&destination=${address}`
      window.open(url, '_blank')
    },
    updateFromQuery(models) {
      models.forEach(m => {
        const queryVal = this.$route.query[m]
        if (queryVal) this[m] = queryVal
      })
    },
    goToReservation(res_id) {
      this.navigateTo(`/dashboard/reservation/${res_id}`)
    },
    navigateTo(path) {
      this.isMobileOrIPad
        ? this.$router.push(path)
        : window.open(path, '_blank')
    },
  },
}
