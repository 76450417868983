<template>
  <v-row>
    <v-col cols="3">
      <v-select
        v-model="selectedDivision"
        dense
        outlined
        :items="divisions"
        clearable
        label="Choose division"
        class="listing-select"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="selectedFlowId"
        dense
        outlined
        :disabled="!selectedDivision"
        :items="flows"
        item-text="name"
        item-value="id"
        clearable
        label="Choose flow"
        class="listing-select"
      >
        <template #item="{ item, on }">
          <v-list-item v-on="on">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="d-flex flex-row">
              <LtFlowModal :division="selectedDivision" :flow="item">
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" icon v-on="on">
                    <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </LtFlowModal>
              <ConfirmationModal
                :text="`Are you sure you want to delete this flow and all his templates ?`"
                @action="deleteFlow(item.id)"
              >
                <template #activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey lighten-1">mdi-delete-forever</v-icon>
                  </v-btn>
                </template>
              </ConfirmationModal>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-select>
    </v-col>
    <v-col cols="6">
      <v-btn
        v-if="managementRoles && selectedFlowId"
        outlined
        class="cyan-icon mr-2"
        prepend-icon="import_export"
        text
        @click="exportToExcel"
      >
        <v-icon class="pr-2">table_rows</v-icon>
        Export
      </v-btn>
      <listing-task-catalog-item-modal v-if="managementRoles" />
      <v-btn
        :disabled="!selectedDivision || !selectedFlowId"
        color="secondary"
        class="text-capitalize mr-2 ml-2"
        elevation="0"
        @click.native="newTemplate"
        >New Node
      </v-btn>
      <LtFlowModal :division="selectedDivision">
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            :disabled="!selectedDivision"
            color="primary"
            class="text-capitalize"
            elevation="0"
            v-on="on"
            >New Template
          </v-btn>
        </template>
      </LtFlowModal>
    </v-col>
    <v-col v-if="selectedFlowId" cols="12" class="pa-3">
      <ConfirmationModal
        v-if="canCreateNewTemplate"
        text="Are you sure you want to start the following flow? this will also create all the future tasks in that flow."
        @action="startFlow"
      >
        <template #activator="{ on }">
          <v-btn dark color="teal" x-small class="float-right" v-on="on">
            Start Flow
          </v-btn>
        </template>
      </ConfirmationModal>
      <div class="main-flow-wrapper">
        <chart-flow
          :nodes="nodes"
          :drag-id.sync="dragId"
          padding-x="20px"
          @on-drag="showMoveConfirmationModal"
        >
          <template #node="{ data }">
            <lt-template-flow-chart-node
              :template="data"
              :change-field="changeField"
              @onDragStart="dragId = data.id"
              @onDragEnd="dragId = null"
            />
          </template>
        </chart-flow>
        <ConfirmationModal
          ref="MoveConfirmationModal"
          text="Are you sure you want to move this node?"
          :actions="moveActions"
        />
      </div>
    </v-col>
    <v-col v-else class="center-text grey-text" cols="12">
      Must pick a flow to start
    </v-col>
    <LtTemplateModal
      v-if="editTemplateObj && !loading"
      :initial-flow-id="selectedFlowId"
    />
  </v-row>
</template>

<script>
import { cloneDeep, get } from 'lodash'
import LtTemplateModal from 'components/listing-tasks/lt-template-modal'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'
import ChartFlow from 'components/chart-flow/chart-flow'
import LtTemplateFlowChartNode from 'components/listing-tasks/lt-template-flow-chart-node'
import LtFlowModal from 'components/listing-tasks/lt-flow-modal'
import { mapGetters } from 'vuex'
import axios from 'axios'
import ListingTaskCatalogItemModal from '../listing-tasks-catalog/listing-task-catalog-item-modal'
import Toaster from '@/utils/toaster'

export default {
  components: {
    ListingTaskCatalogItemModal,
    LtFlowModal,
    LtTemplateFlowChartNode,
    ChartFlow,
    ConfirmationModal,
    LtTemplateModal,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['division', 'enableFlowStart'],
  data() {
    return {
      selectedDivision: null,
      selectedFlowId: null,
      moveEvent: null,
      dragId: null,
      moveActions: [
        { text: 'Cancel' },
        {},
        {
          text: 'Yes - With Children',
          color: 'primary',
          onClick: () => this.move(true),
        },
        {
          text: 'Yes - Without Children',
          color: 'secondary',
          onClick: () => this.move(false),
        },
      ],
    }
  },
  mounted() {
    this.selectedDivision = this.division
  },
  computed: {
    ...mapGetters(['listingTaskFlows', 'listingTaskTemplates']),
    flows() {
      return !this.selectedDivision
        ? []
        : this.listingTaskFlows.filter(
            f => f.division === this.selectedDivision
          )
    },
    nodes() {
      return this.listingTaskTemplates.map(item => ({
        id: item.id,
        parentId: item.create_after_template_id,
        data: item,
      }))
    },
    displaySetupMode() {
      return this.$store.state.listingTask.displaySetupMode
    },
    canCreateNewTemplate() {
      return (
        this.enableFlowStart &&
        this.displaySetupMode &&
        this.selectedFlowId &&
        (this.isAdmin || this.isPropertyManager)
      )
    },
  },
  methods: {
    exportToExcel() {
      this.$store.commit('updateListingLoading', true)
      axios
        .get(`/api/listing-task-flows/${this.selectedFlowId}/export-to-excel`, {
          responseType: 'blob',
        })
        .then(response => {
          this.$store.commit('updateListingLoading', false)
          this.autoDownloadBlobData(response.data, 'inventory.xlsx')
        })
        .catch(error => {
          alert(error)
        })
    },
    newTemplate() {
      this.$store.commit(
        'updateCurrentEditTemplate',
        cloneDeep(this.defaultTemplate)
      )
    },
    showMoveConfirmationModal(event) {
      this.moveEvent = event
      this.$refs.MoveConfirmationModal.dialog = true
    },
    move(withChildren) {
      this.$store.dispatch('updateListingTaskTemplate', {
        id: this.moveEvent.dragged.id,
        create_after_template_id: this.moveEvent.to.id,
        with_children: withChildren,
      })
    },
    deleteFlow(flowId) {
      this.$store.dispatch('removeListingTaskFlow', {
        flowId,
        division: this.selectedDivision,
      })
    },
    async startFlow() {
      if (this.enableFlowStart) {
        await this.$store.dispatch('startListingTaskFlow', {
          flowId: this.selectedFlowId,
          listingId: this.$store.state.currentListing.id,
        })
        Toaster.show([{ type: 'success', text: 'Flow Started successfully' }])
        await this.$store.dispatch(
          'getListingSetupBg',
          this.$route.fullPath.split('/')[3]
        )
      }
    },
    changeField(id, key, val) {
      this.$store.dispatch('updateListingTaskTemplate', { id, [key]: val })
    },
  },
  watch: {
    selectedDivision(value) {
      this.selectedFlowId = null
      this.$store.dispatch('getListingTaskFlows', { division: value })
    },
    selectedFlowId(value) {
      const currentTemplatesFlowId = get(
        this.listingTaskTemplates,
        '0.listing_task_flow_id'
      )
      if (!value) {
        this.$store.dispatch('getListingTaskTemplates', [])
      } else if (value !== currentTemplatesFlowId) {
        this.$store.dispatch('getListingTaskTemplates', value)
      }
    },
    listingTaskTemplates(value) {
      if (value && value.length) {
        this.selectedFlowId = get(value, '0.listing_task_flow_id')
      }
    },
  },
}
</script>

<style lang="scss">
.main-flow-wrapper {
  background-color: white;
  border-radius: 3px;
  border: 1px solid lightgrey;
  height: 70vh;
}
</style>
