<template>
  <v-col :class="{ 'pa-1': isMobile }" class="ma-1" @click="$emit('click')">
    <v-card
      :elevation="readonly ? 0 : 1"
      rounded
      :min-height="xSmall ? 60 : small ? 80 : 90"
      :color="color"
      class="d-flex flex-column justify-center pa-1 pa-sm-0 text-center"
    >
      <div
        class="text-caption text-sm-body-2 font-weight-medium teal--text text--darken-4 d-flex align-center justify-center"
      >
        <div>{{ label }}</div>
        <div
          v-if="tooltip && !isMobile"
          class="ml-1"
          style="margin-bottom: 1px"
        >
          <info-tooltip><span v-html="tooltip"></span></info-tooltip>
        </div>
      </div>
      <div
        :class="[
          'font-weight-black',
          'teal--text text--darken-0',
          small || xSmall ? 'text-h6' : 'text-h5',
        ]"
      >
        <slot name="main" />
        <span v-if="!$slots.main"> {{ value }} </span>
      </div>
      <slot name="secondary" />
      <div
        v-if="!$slots.secondary"
        class="text-capitalize text-subtitle-2 teal--text text--darken-0 font-weight-medium"
      >
        {{ secondary }}
      </div>
    </v-card>
  </v-col>
</template>
<script>
import DeviceMixin from 'components/mixins/device-mixin'
import infoTooltip from 'components/common/info-tooltip.vue'

export default {
  components: { infoTooltip },
  props: [
    'label',
    'value',
    'small',
    'xSmall',
    'isSelected',
    'secondary',
    'tooltip',
    'readonly',
  ],
  mixins: [DeviceMixin],
  computed: {
    color() {
      if (this.readonly) {
        return 'grey lighten-2'
      }
      if (this.isSelected) return 'cyan lighten-5 pointer'
      return this.small || this.xSmall
        ? 'grey lighten-4 pointer'
        : 'blue-grey lighten-5'
    },
  },
}
</script>
<style scoped></style>
