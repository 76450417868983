<template>
  <v-tooltip v-if="value && value.length > 40" bottom>
    <template #activator="{ on }">
      <v-text-field
        :disabled="disabled"
        :value="value"
        dense
        outlined
        hide-details
        @change="onChange"
        v-on="on"
      />
    </template>
    <span>{{ value }}</span>
  </v-tooltip>
  <v-text-field
    v-else
    :disabled="disabled"
    :value="value"
    dense
    outlined
    hide-details
    @change="onChange"
  />
</template>

<script>
export default {
  props: ['value', 'disabled'],
  data() {
    return {}
  },
  methods: {
    onChange(val) {
      this.$emit('update:value', val)
      this.$emit('change', val)
    },
  },
}
</script>

<style scoped>
:deep() .v-input__slot {
  min-height: 0 !important;
  font-size: 14px;
}

:deep() fieldset {
  border: solid 1px lightgray;
}
</style>
