<template>
  <v-layout v-if="velocityData" wrap class="comp-wrapper align-center">
    <v-flex :class="showTable ? 'xs3' : 'xs12'" pr-2>
      <v-layout wrap>
        <v-flex :class="showTable ? 'xs12' : 'xs6'" class="center-text pt-2">
          <span class="purple-icon big-text">Conversion:</span><br />
          <span class="bolder"
            >{{ round(velocityData.conversion * 100) }}%</span
          >
        </v-flex>
        <v-flex :class="showTable ? 'xs12' : 'xs6'" class="center-text pt-2">
          <span class="purple-icon big-text">Amount:</span><br />
          <span class="bolder">{{ velocityData.total_amount }}</span>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex v-if="showTable" class="xs8">
      <v-layout wrap>
        <v-flex xs4 class="br-1 bb-1 center-text"> Time </v-flex>
        <v-flex xs4 class="br-1 bb-1 center-text"> Inqueries </v-flex>
        <v-flex xs4 class="bb-1 center-text big-text light"> Confirmed </v-flex>
        <v-flex xs4 class="br-1 bb-1 pt-1 pb-1 center-text">
          last 4 days
        </v-flex>
        <v-flex xs4 class="br-1 br-1 light-grey pt-1 pb-1 center-text">
          {{ velocityData.inquiry_last_4 }}
        </v-flex>
        <v-flex xs4 class="br-1 pt-1 pb-1 center-text light-grey big-text">
          {{ velocityData.confirmed_last_4 }}
        </v-flex>
        <v-flex xs4 class="br-1 bb-1 pt-1 pb-1 center-text"> 4-10 days </v-flex>
        <v-flex xs4 class="br-1 br-1 light-grey pt-1 pb-1 center-text">
          {{ velocityData.inquiry_last_10 }}
        </v-flex>
        <v-flex xs4 class="br-1 pt-1 pb-1 center-text light-grey big-text">
          {{ velocityData.confirmed_last_10 }}
        </v-flex>
        <v-flex xs4 class="br-1 bb-1 pt-1 pb-1 center-text">
          10-14 days
        </v-flex>
        <v-flex xs4 class="br-1 br-1 light-grey pt-1 pb-1 center-text">
          {{ velocityData.inquiry_last_14 }}
        </v-flex>
        <v-flex xs4 class="br-1 pt-1 pb-1 center-text light-grey big-text">
          {{ velocityData.confirmed_last_14 }}
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['velocityData', 'showTable'],
  computed: {},
  methods: {},
}
</script>
