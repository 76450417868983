<template>
  <v-card class="pa-4 overflow-y-auto">
    <v-row class="align-baseline">
      <v-col cols="12">
        <h3>
          {{ review.fullName }}
          <span class="ml-2 small-text">({{ review.source }})</span>
          <span v-if="isAdmin" class="small-text grey-text"
            >({{ review.id }})</span
          >
        </h3>
      </v-col>
      <v-col cols="12">
        <h5>Public:</h5>
        {{ review.public }}
      </v-col>
      <v-col v-if="review.private" cols="12">
        <h5>Private:</h5>
        {{ review.private }}
      </v-col>
      <v-col
        v-for="(key, val) in scoreMatrics"
        :key="key"
        class="small-text center-text super-light-gray"
      >
        <v-col v-if="review[key]" cols="12"> {{ val }}: </v-col>
        <v-col v-if="review[key]" cols="12">
          {{ (review[key] / 2).toFixed() }}
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import pickBy from 'lodash/fp/pickBy'
export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['review', 'nonNormalized'],
  data() {
    return {
      scoreMatrics: pickBy(val => this.review[val], {
        Communication: 'normilized_communication',
        Cleaning: 'normilized_cleaning',
        Accuracy: 'normilized_accuracy',
        Location: 'normilized_location',
        Checkin: 'normilized_checkin',
        Value: 'normilized_value',
        Overall: 'normilized_scrore_overall',
      }),
    }
  },
}
</script>
