<template>
  <v-card>
    <v-card-title class="text-h6">Active warranties</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="listingWarranties"
        no-data-text="No active warranties found"
      >
        <template #item.images="{ item }">
          <gallery
            v-if="item.images.length"
            folder="warranties"
            :images="item.images"
            icon="mdi-image"
          />
        </template>
        <template #item.expired_at="{ item }">
          {{ parseDate(item.expired_at, 'DD MMM YYYY') }}
        </template>
        <template #item.listing_task_id="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                target="_blank"
                :to="`/dashboard/listing-task/${item.listing_task_id}`"
                color="info"
                elevation="0"
                small
                v-on="on"
                >{{ item.listing_task_id }}</v-btn
              >
            </template>
            <span>Open service call</span>
          </v-tooltip>
        </template>
        <template #item.listing_task_contractor_id="{ item }">
          {{ nameById(item.listing_task_contractor_id) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Gallery from 'components/common/gallery'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'WarrantiesActiveItems',
  components: { Gallery },
  mixins: [CommonFunctions],
  props: {
    listingId: Number,
  },
  computed: {
    ...mapState('warranty', ['listingWarranties']),
    headers() {
      return [
        { text: '', sortable: false, value: 'images' },
        { text: 'Expiration date', value: 'expired_at' },
        { text: 'Service call ID', value: 'listing_task_id' },
        { text: 'Contractor', value: 'listing_task_contractor_id' },
        { text: 'Description', sortable: false, value: 'description' },
      ]
    },
  },
  methods: {
    ...mapMutations('warranty', ['setWarranties']),
    ...mapActions('warranty', ['getActiveWarranties']),
  },
  mounted() {
    this.getActiveWarranties(this.listingId)
  },
  beforeDestroy() {
    this.setWarranties([])
  },
}
</script>
