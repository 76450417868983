<template>
  <div class="full-width d-flex align-center my-4">
    <v-divider />
    <Tag
      color="secondary--text"
      size="sm"
      bg-color="grey lighten-4"
      class="mx-4"
    >
      <span v-if="userName">
        {{ userName }}
        <v-icon>mdi-circle-small</v-icon>
      </span>
      <span v-html="msg.body"></span>
      <v-icon>mdi-circle-small</v-icon>
      {{ parseDateTZ(msg.sent_at, 'D MMM Y, hh:mm a', timezone) }}
    </Tag>
    <v-divider />
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import Tag from 'components/common/tag'
export default {
  components: { Tag },
  mixins: [CommonFunctions],
  props: ['msg'],
  computed: {
    timezone() {
      return this.$store.state.app.configuration.timezone
    },
    userName() {
      return this.userById(this.msg.by)?.name
    },
  },
}
</script>
