<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.09 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="shop-2">
          <g id="Group_10276" data-name="Group 10276">
            <g id="Group_10275" data-name="Group 10275">
              <path
                id="Path_5230"
                data-name="Path 5230"
                class="cls-1"
                d="M25.84,21.21h-.45V9.44a3.69,3.69,0,0,0,1.7-3.1.35.35,0,0,0-.05-.2L23.89.63A1.24,1.24,0,0,0,22.81,0H4.28A1.25,1.25,0,0,0,3.2.63L.05,6.14a.46.46,0,0,0,0,.2,3.67,3.67,0,0,0,1.7,3.1V21.21H1.25a.39.39,0,0,0-.35.44.38.38,0,0,0,.35.35h24.6a.4.4,0,0,0,.34-.44.38.38,0,0,0-.34-.35ZM.82,6.73H2.09a.4.4,0,0,0,.45-.34.4.4,0,0,0-.35-.45H1.08L3.89,1A.44.44,0,0,1,4.28.8H22.81A.44.44,0,0,1,23.2,1L26,5.94H25a.4.4,0,0,0-.35.45.39.39,0,0,0,.35.34h1.27a2.89,2.89,0,0,1-5.72,0H23.3a.4.4,0,0,0,.45-.34.41.41,0,0,0-.35-.45H3.79a.4.4,0,0,0-.45.35.4.4,0,0,0,.35.44H6.55a2.89,2.89,0,0,1-5.73,0Zm18.87,0a2.89,2.89,0,0,1-5.72,0Zm-6.57,0a2.89,2.89,0,0,1-5.72,0Zm9.36,14.48h-4.3V19.88h4.3Zm0-2.12h-4.3V11.82h4.3Zm2.12,2.12H23.27V11.43a.4.4,0,0,0-.4-.4H17.79a.4.4,0,0,0-.4.4h0v9.78H2.49V9.82A3.68,3.68,0,0,0,7,8a3.69,3.69,0,0,0,5,1.63A3.77,3.77,0,0,0,13.55,8a3.68,3.68,0,0,0,4.94,1.63A3.77,3.77,0,0,0,20.12,8,3.68,3.68,0,0,0,24.6,9.82Z"
              />
            </g>
          </g>
          <g id="Group_10278" data-name="Group 10278">
            <g id="Group_10277" data-name="Group 10277">
              <path
                id="Path_5231"
                data-name="Path 5231"
                class="cls-1"
                d="M15.67,11H4.21a.4.4,0,0,0-.4.4v8.06a.4.4,0,0,0,.4.4H15.67a.4.4,0,0,0,.4-.4V11.43A.4.4,0,0,0,15.67,11Zm-.4,8.06H4.61V11.82H15.27Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Store',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
