<template>
  <tr>
    <td class="font-weight-bold">
      <user-avatar
        class="my-3 mr-3"
        :image="stats.picture"
        :name="stats.name"
        :role="stats.role"
        :size="51"
      />
      {{ stats.name }}
    </td>
    <td class="font-weight-medium">
      {{ stats.ach_recipient_name }}
    </td>
    <td class="text-center font-weight-medium">
      {{ roundWith(stats.hours, 2) }}
    </td>
    <td class="text-center font-weight-medium">
      {{ dollarFormatter(stats.amount_unpaid) }}
    </td>
    <td class="text-center font-weight-medium">
      {{ dollarFormatter(stats.manual_unpaid) }}
    </td>
    <td class="text-center font-weight-medium">
      {{ dollarFormatter(stats.routine_unpaid) }}
    </td>
    <td class="text-center font-weight-medium">
      {{ dollarFormatter(stats.amount_processing) }}
    </td>
    <td class="text-center font-weight-medium">
      {{ dollarFormatter(stats.amount_paid) }}
    </td>
    <td class="text-center font-weight-medium">
      {{ dollarFormatter(stats.amount) }}
    </td>
    <td class="text-center font-weight-medium">
      <v-chip
        :class="[
          contractorStatusColor(stats.on_payrole)['text'],
          'text-uppercase',
        ]"
        label
        small
      >
        {{ stats.on_payrole ? 'On payroll' : 'No payroll' }}
      </v-chip>
    </td>
    <td class="text-center">
      <v-btn color="secondary" icon @click="$emit('show-details', stats.id)">
        <v-icon>mdi-text-box-outline</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { mapMutations } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import ContractorPayments from './mixins/contractor-payments-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import UserAvatar from 'components/common/user-avatar'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  components: {
    UserAvatar,
  },
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    ContractorPayments,
    FormattersMixin,
  ],
  props: {
    stats: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    menu: false,
    menu2: false,
    searchTerm: '',
    from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
      .toISOString()
      .substr(0, 10),
    to: new Date().toISOString().substr(0, 10),
  }),
  methods: {
    ...mapMutations(['updateLtdSelectedIDS']),
    contractorStatusColor(onPayroll) {
      if (onPayroll) {
        return {
          bg: 'info lighten-4',
          text: 'info--text',
        }
      }
      return {
        bg: 'grey lighten-4',
        text: 'secondary--text',
      }
    },
  },
  beforeDestroy() {
    this.updateLtdSelectedIDS(new Set())
  },
}
</script>
