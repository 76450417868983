import axios from 'axios'
import Vue from 'vue'
import moment from 'moment-timezone'
export default {
  namespaced: true,
  state: {
    items: [],
    itemsPagination: {},
    fetchingItems: false,
    tableFilters: {
      page: 1,
      per_page: 50,
      search_term: '',
      from: moment().subtract('3', 'month').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      status: 'Pending arrival',
    },
  },
  mutations: {
    setShopItems(state, payload) {
      state.items = payload
    },
    updateFilters(state, value) {
      state.tableFilters = {
        ...state.tableFilters,
        ...value,
      }
    },
    setShopItem(state, data) {
      const ind = state.items.findIndex(i => i.id === data.id)
      Vue.set(state.items, ind, data)
    },
    setShopItemsPagination(state, payload) {
      state.itemsPagination = payload
    },
    toggleFetchingItems(state, value) {
      state.fetchingItems = value
    },
  },
  actions: {
    async getShopItems({ commit, state }, params = {}) {
      try {
        commit('toggleFetchingItems', true)
        const { data } = await axios.get(`/api/shopping-items`, {
          params: { ...state.tableFilters, ...params },
        })
        commit('toggleFetchingItems', false)
        commit('setShopItems', data.items)
        commit('setShopItemsPagination', data.pagination_info)
      } catch (err) {
        console.log(err)
      }
    },
    async createShopItems({ dispatch }, payload) {
      try {
        const { data } = await axios.post(`/api/shopping-items`, payload)
        await dispatch('getListingTaskBg', payload.listing_task_id, {
          root: true,
        })
        return data
      } catch (err) {
        console.log(err)
      }
    },
    async updateShopItems({ commit }, { id, ...payload }) {
      try {
        commit('toggleFetchingItems', true)
        const { data } = await axios.patch(`/api/shopping-items/${id}`, payload)
        commit('setShopItem', data)
        commit('toggleFetchingItems', false)
        return data
      } catch (err) {
        console.log(err)
      }
    },
    async updateShopItemsBatch({ commit, dispatch }, payload) {
      try {
        commit('toggleFetchingItems', true)
        const { data } = await axios.patch(`/api/shopping-items/batch`, payload)
        dispatch('getShopItems', {})
        commit('toggleFetchingItems', false)
        return data
      } catch (err) {
        console.log(err)
      }
    },
  },
}
