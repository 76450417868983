<template>
  <v-expansion-panels v-if="!isContractor" class="mt-3" multiple accordion>
    <v-expansion-panel v-if="isClaim">
      <v-expansion-panel-header class="text-subtitle-1">
        <v-overlay :opacity="panelOpacity" color="yellow lighten-3" absolute />

        Claim info
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-4">
        <claim-items
          :value="listingTask.claim_items"
          :after-change="claimItemsChange"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="currentReview">
      <v-expansion-panel-header class="text-subtitle-1">
        <v-overlay :opacity="panelOpacity" color="cyan lighten-3" absolute />

        Review details
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-4">
        <review-card :review="currentReview" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel :disabled="isEmpty(listingTask.log)">
      <v-expansion-panel-header class="text-subtitle-1">
        <v-overlay :opacity="panelOpacity" color="info lighten-4" absolute />

        History of activity
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-4">
        <lt-logs :logs="listingTask.log" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel
      v-if="
        listingTask.investor_user && listingTask.task_type == 'administration'
      "
    >
      <v-expansion-panel-header class="text-subtitle-1">
        <v-overlay
          :opacity="panelOpacity"
          color="darkpurple lighten-4"
          absolute
        />

        Investor info
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <LtInvestorInfo :investor="listingTask.investor_user" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel
      v-if="listingTask.continuation_listing_tasks.length"
      id="lt-continuation-panel"
    >
      <v-expansion-panel-header class="text-subtitle-1">
        <v-overlay :opacity="panelOpacity" color="info lighten-4" absolute />

        Continuation listing tasks
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-list>
          <v-list-item
            v-for="task in listingTask.continuation_listing_tasks"
            :key="task.id"
          >
            <v-list-item-title>
              Task
              <router-link
                :target="isMobile ? '_self' : '_blank'"
                :to="connectedLtUrl(task.id)"
                class="info--text mx-1"
              >
                #{{ task.id }}
              </router-link>
              scheduled for
              {{ parseDateWithTime(task.scheduled_at) }} with priority
              <b :class="`${priorityColor(task.priority)}--text mx-1`">{{
                task.priority
              }}</b>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="rejects.length !== 0">
      <v-expansion-panel-header class="text-subtitle-1">
        <v-overlay :opacity="panelOpacity" color="warning lighten-4" absolute />
        Rejects ({{ rejects.length }})
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-simple-table>
          <thead>
            <tr>
              <th>Reason</th>
              <th>Topic</th>
              <th>By</th>
              <th>Done at</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reject_task in rejects" :key="reject_task.reason">
              <td>{{ reject_task.reason }}</td>
              <td>{{ reject_task.topic }}</td>
              <td>{{ reject_task.done_by }}</td>
              <td>
                {{ parseDateTZ(reject_task.created_at, 'MMM Do HH:mm') }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel
      v-if="!isContractor && !isEmpty(listingTask.working_cleaners)"
    >
      <v-expansion-panel-header class="text-subtitle-1">
        <v-overlay :opacity="panelOpacity" color="green lighten-5" absolute />
        Clock ins ({{
          roundWith(sumOfDuration(listingTask.working_cleaners) / 60, 2)
        }}
        hours)
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <lt-clock-ins-expand :listing-task="listingTask" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel
      v-if="
        !isContractor &&
        !isEmpty(listingTask.similar_lt) &&
        !listingTask.reg_cleaning
      "
    >
      <v-expansion-panel-header class="text-subtitle-1">
        <v-overlay :opacity="panelOpacity" color="orange" absolute />
        Similar service calls ({{ listingTask.similar_lt.length }})
        <template #actions>
          <v-icon color="error"> mdi-alert-circle </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <LtSimilarExpand :tasks="listingTask.similar_lt" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import LtSimilarExpand from 'components/listing-tasks/lt-similar-expand'
import LtInvestorInfo from 'components/listing-tasks/lt-investor-info'
import LtLogs from 'components/listing-tasks/lt-logs'
import { TASK_STATUSES } from '@/consts'
import LtClockInsExpand from './lt-clock-ins-expand'
import ClaimItems from 'components/claim-items'
import ReviewCard from '../reviews/review-card'
import DeviceMixin from 'components/mixins/device-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'

export default {
  name: 'ListingTaskExpansionPanels',
  components: {
    ReviewCard,
    ClaimItems,
    LtClockInsExpand,
    LtLogs,
    LtInvestorInfo,
    LtSimilarExpand,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin, ColorsMixin],
  props: ['listingTask'],
  data() {
    return {
      panelOpacity: 0.12,
    }
  },
  computed: {
    currentReview() {
      return this.listingTask.review
    },
    isClaim() {
      return (
        this.listingTask.listing_task_catalog_item_name &&
        this.listingTask.listing_task_catalog_item_name
          .toLowerCase()
          .includes('claim')
      )
    },
    rejects() {
      return this.$store.getters.rejectsList.filter(
        r => r.status === TASK_STATUSES.REJECTED
      )
    },
  },
  methods: {
    connectedLtUrl(id) {
      return { name: 'lt-page', params: { id: `${id}` } }
    },
    claimItemsChange(items) {
      this.listingTask.claim_items = items
    },
    sumOfDuration(users) {
      return users.reduce((acc, user) => acc + (user.duration || 0), 0)
    },
  },
}
</script>

<style scoped>
.v-expansion-panel:before {
  box-shadow: none !important;
}
</style>
