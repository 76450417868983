<template>
  <div
    v-if="currentListingTask"
    class="pa-0"
    :class="{ 'pa-5': isMobileOrIPad }"
    :fluid="isMobileOrIPad"
  >
    <v-row>
      <v-col cols="12" class="pa-0">
        <listing-task-title
          :task="currentListingTask"
          @change-description="changeDescription"
        />
        <v-divider />
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col cols="12" md="8" class="d-flex flex-column mt-1 mb-1">
          <LtTitle :listing-task="currentListingTask" />
          <lt-same-day-tasks
            v-if="
              currentListingTask.same_day_lts &&
              currentListingTask.same_day_lts.length
            "
            class="mt-2 mb-1"
            :items="currentListingTask.same_day_lts"
          />
          <EditableTextField
            :label="$t('Internal Comments')"
            type="textarea"
            class="mt-2"
            :disabled="isContractor || isExperienceManager"
            :placeholder="$t('Add your comments here')"
            :value="currentListingTask.internal_comments"
            @onupdate="commentInternal"
          />
          <div v-if="currentListingTask.when_to_come_text">
            <div class="font-weight-medium text-subtitle-1">When to come:</div>
            <div class="rounded text-body-1">
              <span
                v-html="
                  currentListingTask.when_to_come_text.replace(
                    /\r?\n|\r/g,
                    '<br>'
                  )
                "
              />
            </div>
          </div>
          <div v-if="reservationRequests" class="mt-2">
            <div class="font-weight-medium text-subtitle-1">
              Special requests for guest
            </div>
            {{ reservationRequests }}
          </div>
          <LtActions :listing-task="currentListingTask" />
          <div v-if="!currentListingTask.reg_cleaning">
            <div class="text-subtitle-1 font-weight-medium">
              {{ $t('Description Images') }}
            </div>
            <gallery
              folder="service-calls"
              :thumbnails="true"
              :listing-id="currentListingTask.listing_id"
              :images="currentListingTask.desc_images"
              :remove-img="removeDescImg"
              :rotate-image="rotateDescImg"
              :on-change="addDescImages"
              :uploadable="true"
              :cols="3"
            />
          </div>
          <LtMetadata
            v-if="isMobileOrIPad"
            :listing-task="currentListingTask"
          />
          <v-divider class="my-3" />
          <LtImagesGalley
            :has-sub-tasks="hasSubTasks"
            :listing-task="currentListingTask"
          />
          <div v-if="!currentListingTask.reg_cleaning">
            <div class="font-weight-medium text-subtitle-1">
              {{ $t('Invoices/Quotes') }}
            </div>
            <gallery
              folder="service-calls"
              :thumbnails="true"
              :cols="3"
              :listing-id="currentListingTask.listing_id"
              :images="currentListingTask.receipt_images"
              :rotate-image="rotateReceipt"
              :remove-img="removeReceipt"
              :on-change="addReceiptImages"
              :uploadable="true"
            />
          </div>
          <div v-if="hasSubTasks">
            <div v-for="listSubject in listSubjects" :key="listSubject">
              <LtSubtaskList
                :title="listSubject"
                class="mt-2"
                :listing-task="currentListingTask"
                :tasks="filterBySubject(listSubject)"
              />
            </div>
          </div>
          <div v-if="hasShadowSubTasks">
            <div>
              <LtSubtaskList
                :title="$t('Sub Tasks In Other Service Calls')"
                class="mt-2"
                :listing-task="currentListingTask"
                :tasks="currentListingTask.shadow_sub_tasks"
                :readonly="true"
              />
            </div>
          </div>
          <ListingTaskExpansionPanels :listing-task="currentListingTask" />
          <v-divider
            v-if="!isContractor && !isEmpty(currentListingTask.similar_lt)"
            class="my-2"
          />
        </v-col>
        <v-col cols="12" md="4" class="p-relative">
          <v-sheet
            v-show="!isMobileOrIPad && feedOpen"
            elevation="2"
            style="width: 50%; height: 100%; overflow-y: hidden"
            class="setup-feed"
          >
            <v-slide-x-transition>
              <LtFeed
                class="d-flex flex-column"
                :task-id="currentListingTask.id"
                :logs="logs"
                :users="activeUsers"
                :unseen="unseenComments"
                :comments-list="commentsList"
                :internal-comments="internalComments"
                @add-message="addComment"
                @update-comment="commentInternal"
              />
            </v-slide-x-transition>
          </v-sheet>
          <v-dialog fullscreen :value="isMobileOrIPad && feedOpen">
            <v-slide-x-transition>
              <LtFeed
                class="d-flex flex-column"
                :logs="logs"
                :task-id="currentListingTask.id"
                :users="activeUsers"
                :unseen="unseenComments"
                :comments-list="commentsList"
                :internal-comments="internalComments"
                @add-message="addComment"
                @update-comment="commentInternal"
              />
            </v-slide-x-transition>
          </v-dialog>
          <LtMetadata
            v-if="!isMobileOrIPad"
            class="elevation-1"
            :listing-task="currentListingTask"
          />
          <LtSummary
            v-show="!isCleaner"
            class="elevation-1"
            :listing-task="currentListingTask"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import LtMetadata from 'components/listing-tasks/lt-metadata'
import LtSummary from 'components/listing-tasks/single-listing-task/lt-summary'
import LtImagesGalley from 'components/listing-tasks/lt-images-galley'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import deviceMixin from 'components/mixins/device-mixin'
import EditableTextField from 'components/form-fields/editable-text-field'
import LtSubtaskList from 'components/listing-tasks/lt-subtask-list'
import Gallery from 'components/common/gallery'
import LtTitle from 'components/listing-tasks/lt-title'
import ListingTaskExpansionPanels from 'components/listing-tasks/listing-task-expansion-panels'
import LtFeed from 'components/listing-tasks/lt-feed'
import MentionMixin from 'components/mixins/mention-mixin'
import LtActions from 'components/listing-tasks/single-listing-task/lt-actions'
import LtSameDayTasks from 'components/listing-tasks/lt-same-day-tasks'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'
import ListingTaskTitle from 'components/listing-tasks/single-listing-task/listing-task-title.vue'

export default {
  name: 'ListingTaskView',
  components: {
    LtSameDayTasks,
    LtActions,
    LtFeed,
    ListingTaskExpansionPanels,
    LtTitle,
    Gallery,
    LtSubtaskList,
    EditableTextField,
    LtImagesGalley,
    LtSummary,
    LtMetadata,
    ListingTaskTitle,
  },
  mixins: [
    CommonFunctions,
    deviceMixin,
    PermissionsMixin,
    MentionMixin,
    ListingTaskViewMixin,
  ],
  watch: {
    '$route.query'(query) {
      if (query.comment === 'true') {
        this.$store.dispatch('seenComments', this.currentListingTask.id)
      } else {
        this.$store.dispatch('getListingTaskBg', this.currentListingTask.id)
      }
    },
  },
  async mounted() {
    const { id } = this.$route.params
    await this.$store.dispatch('getListingTask', { id, router: this.$router })
    if (this.$route.query.comment === 'true') {
      await this.$store.dispatch('seenComments', id)
    }
  },
}
</script>

<style scoped>
div >>> .v-expansion-panel-content__wrap {
  padding: 0;
}

.check-br {
  border-left: 10px solid var(--v-darkpurple);
}

.setup-feed {
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 200;
}
</style>
