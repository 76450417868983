<template>
  <v-sheet>
    <v-form ref="updateListing" lazy-validation @submit.prevent="updateListing">
      <div class="d-flex justify-space-between align-center my-3">
        <div class="text-h6">{{ $t('Availability information') }}</div>
        <v-btn small color="primary" type="submit">Save </v-btn>
      </div>
      <v-sheet max-height="700" class="overflow-y-auto px-3">
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="listing.check_in_time"
                label="Check-in start"
                :placeholder="'16:00'"
                :rules="[required]"
                outlined
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="listing.extra_info.check_in_end"
                label="Check-in end"
                :placeholder="'22:00'"
                :rules="[required]"
                outlined
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="listing.check_out_time"
                label="Check-out start"
                :placeholder="'12:00'"
                :rules="[required]"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-sheet>
    </v-form>
  </v-sheet>
</template>

<script>
import axios from 'axios'
import FormRules from 'components/mixins/form-rules-mixin'

export default {
  name: 'ListingChannelAvailability',
  mixins: [FormRules],
  props: ['listing'],
  data() {
    return {}
  },
  async mounted() {},
  methods: {
    async updateListing() {
      if (this.$refs.updateListing.validate()) {
        this.$store.commit('updateLoading', true)
        await this.$store
          .dispatch('listings/updateListing', {
            id: this.$route.params.id,
            payload: {
              days_before_arrival: this.listing.days_before_arrival,
              check_in_time: this.listing.check_in_time,
              check_out_time: this.listing.check_out_time,
              extra_info: {
                check_in_end: this.listing.extra_info.check_in_end,
              },
            },
          })
          .finally(this.$store.commit('updateLoading', false))
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
