<template>
  <div>
    <div class="grey lighten-4">
      <v-container v-show="!loading" class="pb-0 max-w-1600">
        <v-tabs
          v-model="paramsTab"
          slider-color="white"
          background-color="#f6f6f7"
          active-class="white"
        >
          <v-tab href="#manage"> {{ $t('Received Reviews') }} </v-tab>
          <v-tab href="#pending"> {{ $t('Pending Reviews') }}</v-tab>
          <v-tab v-if="showModule('ops')" href="#channels">
            {{ $t('Channels links') }}
          </v-tab>
          <v-tab v-if="showModule('ops')" href="#logs">
            {{ $t('Listing Logs') }}
          </v-tab>
        </v-tabs>
      </v-container>
    </div>
    <v-progress-linear v-show="loadingGuestExpInfo" indeterminate />
    <v-row v-if="stats && (paramsTab === 'pending' || paramsTab == 'manage')">
      <stat-card
        :label="$t('New')"
        :value="stats.reviews_new + stats.reservations_new"
        tooltip="Last 30 days need to manage reviews&reservations"
      />
      <stat-card
        :label="$t('Processed')"
        :value="stats.reservations_processed + stats.reviews_processed"
        tooltip="Last 30 days processed reviews&reservations"
      />
      <stat-card
        :label="$t('Review Removed/Prevented')"
        :value="`${stats.reviews_removed + stats.reservations_prevented}`"
        :tooltip="`Removed: ${stats.reviews_removed} | Prevented: ${stats.reservations_prevented}`"
      />
      <stat-card
        :label="$t('Review Rate')"
        :value="`${toPercent(stats.review_rate_last_30, 2) || '-'}`"
        :secondary="`Prev: ${toPercent(stats.review_rate_last_60, 2) || '-'}`"
        tooltip="Amount of reviews / Amount of reservations"
      />
      <stat-card
        :label="$t('Review Score')"
        :value="`${round(stats.review_score_last_30, 2) || '-'}`"
        :secondary="`Prev: ${round(stats.review_score_last_60, 2) || '-'}`"
        tooltip="Average of all last 30 days reviews score"
      />
    </v-row>
    <v-container fluid>
      <v-row v-if="paramsTab === 'channels'">
        <channel-management />
      </v-row>
      <v-row v-if="paramsTab === 'pending'">
        <v-col cols="6" sm="4" lg="1">
          <date-picker
            v-model="from"
            :label="$t('Check-out from')"
            :max="
              parseISODateWithOffset(
                new Date(new Date().setMonth(new Date().getMonth() + 10))
              )
            "
            @change="fetchReservations"
          />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <v-select
            v-model="reviewsStatus"
            :label="$t('Review Status')"
            hide-details
            outlined
            clearable
            multiple
            dense
            :items="['New', 'In Progress', 'Done', 'Prevented', 'No Action']"
            @change="fetchReservations"
          />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <v-autocomplete
            v-model="filteredListingId"
            :label="$t('Listing')"
            clearable
            hide-details
            :items="listingsPicker"
            item-text="nickname"
            item-value="id"
            dense
            outlined
            @change="fetchReservations({})"
          />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <filters-select
            :value.sync="channel"
            :label="$t('Channel')"
            :items="channelsItems"
            :selection-func="item => item.text"
            @change="fetchReservations({})"
          />
        </v-col>
      </v-row>

      <reservations
        v-show="paramsTab === 'pending'"
        :headers="headers"
        @pagination="paginationChanged"
        @refetch="fetchReservations"
      />
      <reviews-table v-show="paramsTab === 'manage'" class="pa-4" />
      <listing-logs v-if="paramsTab === 'logs'" />
    </v-container>
  </div>
</template>

<script>
import ReviewsTable from '../reviews/reviews-table'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import formattersMixin from 'components/mixins/formatters-mixin'
import StatCard from 'components/common/stat-card.vue'
import Reservations from 'components/reservations.vue'
import FiltersSelect from 'components/form-fields/filters-select.vue'
import Common_functions from 'components/mixins/common_functions'
import DatePicker from 'components/form-fields/date-picker.vue'
import ChannelManagement from 'components/channel-management/channel-management-page.vue'
import ListingLogs from 'components/channel-management/listing-logs.vue'

export default {
  components: {
    ListingLogs,
    ChannelManagement,
    DatePicker,
    FiltersSelect,
    Reservations,
    StatCard,
    ReviewsTable,
  },
  mixins: [PermissionsMixin, formattersMixin, Common_functions],
  methods: {
    ...mapActions(['getReviewsInfo', 'getManagedReservations']),
    ...mapActions('reservation', ['fetchChannels']),
    paginationChanged(data) {
      this.pagination = { ...this.pagination, ...data }
      this.fetchReservations(this.pagination)
    },
    fetchReservations(addons = {}) {
      const { itemsPerPage } = this.pagination
      this.getManagedReservations({
        page: 1,
        per_page: itemsPerPage,
        from: this.from,
        to: this.to,
        date_by: 'check_out',
        status: 'confirmed',
        review_status: this.reviewsStatus,
        channel: this.channel,
        listingId: this.filteredListingId,
        ...addons,
      })
    },
  },
  created() {
    if (!this.paramsTab) {
      this.paramsTab = 'area-report'
    }
  },
  computed: {
    ...mapState(['loadingGuestExpInfo']),
    ...mapGetters(['listingsPicker']),
    ...mapGetters('reservation', ['channelsItems']),
    headers() {
      return [
        {
          text: 'Guest',
          value: 'guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Channel',
          value: 'channel',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Review status',
          value: 'review_status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Notes',
          value: 'dvr_notes',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Listing',
          value: 'nickname',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Confirmation',
          value: 'confirmation_code',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Check out',
          value: 'check_out',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Checkup',
          value: 'checkup',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Survey',
          value: 'survey',
          align: 'center',
          sortable: false,
        },
        {
          text: 'OTA Review',
          value: 'reviews',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Days passed',
          value: 'day_passed',
          align: 'center',
          sortable: false,
        },
        {
          text: '#Messages',
          value: 'messages',
          align: 'center',
          sortable: false,
        },
        {
          text: 'SC reported',
          align: 'center',
          value: 'sc_reported',
          sortable: false,
        },
        {
          text: 'Manual SC Done',
          align: 'center',
          value: 'sc_done',
          sortable: false,
        },
      ]
    },
  },
  data() {
    return {
      view: 'manage',
      stats: {},
      from: this.parseISODateWithOffset(new Date()).substr(0, 10),
      to: this.parseISODateWithOffset(new Date()).substr(0, 10),
      reviewsStatus: ['New', 'In Progress'],
      filteredListingId: null,
      channel: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
    }
  },
  async mounted() {
    const { data } = await this.getReviewsInfo({})
    this.stats = data
    this.intervalStats = setInterval(() => this.getReviewsInfo({}), 1000 * 30)
    this.from = this.parseISODateWithOffset(
      this.$moment.utc().subtract(14, 'days')
    ).substr(0, 10)
    this.to = this.parseISODateWithOffset(this.$moment.utc()).substr(0, 10)
    this.fetchReservations()
    this.fetchChannels()
  },
  beforeDestroy() {
    clearInterval(this.intervalStats)
  },
}
</script>
<style></style>
