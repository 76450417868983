import axios from 'axios'
import get from 'lodash/fp/get'

export default {
  state: {
    devices: [],
  },
  mutations: {
    updateIOTDevices(state, devices) {
      state.devices = devices
    },
    updateIOTEvents(state, events) {
      state.events = events
    },
  },
  actions: {
    async getIOTDevices({ commit }) {
      try {
        const { data } = await axios.get(`/api/iot-devices`)
        commit('updateIOTDevices', data)
      } catch (err) {
        console.error(err)
      }
    },
    async updateMinutHome({ dispatch }, { id, ...body }) {
      try {
        const res = await axios.put(`/api/iot-devices/minut/home/${id}`, body)
        dispatch('getIOTDevices')
        return res
      } catch (err) {
        return err
      }
    },
    async getIOTEvents({ commit }, { listingId }) {
      try {
        const { data } = await axios.get(
          `/api/listings/${listingId}/iot-events`
        )
        commit('updateIOTEvents', data)
      } catch (err) {
        console.error(err)
      }
    },
  },
  getters: {
    devices(state) {
      return state.devices
    },
    listingDevices(state, getters, rooteState, rootGetters) {
      return listingId => {
        const currentListingId = listingId || rootGetters.currentListingId
        return state.devices.filter(
          d => get('listing.id', d) === currentListingId
        )
      }
    },
  },
}
