<template>
  <div>
    <div class="justify-space-between d-flex align-center px-3">
      <month-year-toggle
        :is-yearly="isYearlyStats"
        @toggle="setYearly($event)"
      />
      <month-year-picker
        v-model="dates"
        :is-yearly="isYearlyStats"
        :max-year="new Date().getFullYear()"
        :max-month="new Date().getMonth()"
        @change="updateDate"
      />
    </div>
    <accounting-card
      :listing="listing"
      :is-monthly="!isYearlyStats"
      :year="dates.year"
      :month="dates.month"
    />
  </div>
</template>

<script>
import MonthYearToggle from 'components/month-year-toggle.vue'
import MonthYearPicker from 'components/common/month-year-picker.vue'
import AccountingCard from 'components/accounting-card.vue'
import { MONTHS } from '@/consts'

export default {
  name: 'Pnl',
  components: { AccountingCard, MonthYearPicker, MonthYearToggle },
  props: ['listing'],
  data() {
    return {
      dates: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      isYearlyStats: false,
      lastYearAccountingFetch: new Date().getFullYear(),
    }
  },
  mounted() {
    this.setYearly(false)
    this.updateDate(this.dates, true)
  },
  computed: {
    isSameYear() {
      return this.dates.year === new Date().getFullYear()
    },
    isSameMonth() {
      return this.dates.month === new Date().getMonth()
    },
  },
  methods: {
    setYearly(isYearly) {
      this.isYearlyStats = isYearly
      this.$store.commit('updateAccountingData', !isYearly)
    },
    updateDate({ year, month }, runYearly = false) {
      if (
        this.isYearlyStats ||
        year !== this.lastYearAccountingFetch ||
        runYearly
      ) {
        this.$store.dispatch('getInitialStats', {
          id: this.listing.id,
          is_ytd: this.isSameYear,
          year,
        })
      }
      this.lastYearAccountingFetch = year
      this.$store.dispatch('getInitialStats', {
        year,
        month: MONTHS[month],
        is_ytd: this.isSameMonth && this.isSameYear,

        id: this.listing.id,
      })
    },
  },
}
</script>

<style scoped></style>
