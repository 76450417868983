<template>
  <v-tooltip top :disabled="!tooltip" color="grey lighten-3">
    <template #activator="{ on }">
      <v-card
        class="pa-2 d-flex p-relative"
        rounded
        elevation="1"
        :height="85"
        :min-width="minWidth || '100%'"
        v-on="on"
      >
        <v-sheet width="6" height="100%" class="rounded" :color="color" />
        <div class="d-flex flex-column justify-space-between">
          <div
            class="text-body-2 grey--text text--darken-1 text-capitalize font-weight-medium px-3"
          >
            {{ title }}
          </div>
          <v-skeleton-loader v-if="loading" class="mx-2" type="text" />
          <div v-else class="text-h6 font-weight-medium px-3">
            {{ value }}
          </div>
        </div>
        <div
          v-if="!isNaN(delta) && isFinite(delta)"
          class="text-caption font-weight-medium d-flex align-center delta-span p-absolute"
        >
          <delta-tag :value="delta" />
          <span v-if="isYearly" class="grey--text">since {{ +year - 1 }}</span>
        </div>
      </v-card>
    </template>
    <span class="black--text">{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import DeltaTag from 'components/common/delta-tag.vue'

export default {
  name: 'ReviewStatsBox',
  components: { DeltaTag },
  props: [
    'value',
    'loading',
    'color',
    'isYearly',
    'delta',
    'title',
    'year',
    'minWidth',
    'tooltip',
  ],
  mixins: [FormattersMixin],
}
</script>

<style scoped>
.delta-span {
  bottom: 5px;
  right: 5px;
}
</style>
