<template>
  <v-dialog :value="listingTask" max-width="700px" @click:outside="close">
    <lt-minimal-view
      :listing-task="listingTask"
      :close="close"
      :event="event"
      :calendar="calendar"
      @change-to-task="afterChange"
    />
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import LtMinimalView from 'components/listing-tasks/lt-minimal-view'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  components: {
    LtMinimalView,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: ['listingTask', 'afterChange', 'close', 'event', 'calendar'],
}
</script>

<style lang="scss">
.service-call-edit {
  .vue__time-picker {
    display: block;
    position: relative;
    font-size: 1em;
    width: 100%;
    font-family: sans-serif;
    vertical-align: middle;
    height: 40px;

    input.display-time {
      width: 100%;
      height: 100%;
      border-color: rgba(0, 0, 0, 0.87);
      border-radius: 4px;
      transition: all 0.3s;

      &:focus {
        border-color: #7ed9d9;
        border-width: 2px;
        outline: 0;
      }
    }

    .dropdown {
      width: 100%;

      .select-list {
        width: 100%;
      }
    }
  }
}
</style>
