<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="19.861"
    viewBox="0 0 24 19.861"
  >
    <g transform="translate(10.83 16.882)">
      <g transform="translate(0 0)"><circle cx="1.49" cy="1.49" r="1.49" /></g>
    </g>
    <g transform="translate(0 0)">
      <path
        d="M23.749,50.626a16.025,16.025,0,0,0-23.5,0A.938.938,0,0,0,1.624,51.9a14.15,14.15,0,0,1,20.753,0,.938.938,0,0,0,1.372-1.278Z"
        transform="translate(0 -45.5)"
      />
    </g>
    <g transform="translate(3.422 5.44)">
      <path
        d="M89.882,165.105a11.473,11.473,0,0,0-16.612,0,.937.937,0,1,0,1.331,1.321,9.6,9.6,0,0,1,13.95,0,.938.938,0,0,0,1.331-1.321Z"
        transform="translate(-72.998 -161.547)"
      />
    </g>
    <g transform="translate(7.082 10.922)">
      <path
        d="M160.548,280.459a6.765,6.765,0,0,0-4.563-1.959h-.069a6.765,6.765,0,0,0-4.563,1.959.937.937,0,1,0,1.32,1.332,4.9,4.9,0,0,1,3.243-1.416h.069a4.9,4.9,0,0,1,3.243,1.416.938.938,0,0,0,1.32-1.332Z"
        transform="translate(-151.076 -278.5)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Wifi',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
