<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15.5C6.9625 15.5 5.9875 15.303 5.075 14.909C4.1625 14.515 3.36875 13.9808 2.69375 13.3063C2.01875 12.6313 1.4845 11.8375 1.091 10.925C0.6975 10.0125 0.5005 9.0375 0.5 8C0.5 6.9625 0.697 5.9875 1.091 5.075C1.485 4.1625 2.01925 3.36875 2.69375 2.69375C3.36875 2.01875 4.1625 1.4845 5.075 1.091C5.9875 0.6975 6.9625 0.5005 8 0.5C8.6 0.5 9.18425 0.56875 9.75275 0.70625C10.3212 0.84375 10.8682 1.04375 11.3937 1.30625C11.5812 1.40625 11.7032 1.55625 11.7598 1.75625C11.8163 1.95625 11.7817 2.14375 11.6562 2.31875C11.5312 2.49375 11.3655 2.60625 11.159 2.65625C10.9525 2.70625 10.7495 2.68125 10.55 2.58125C10.15 2.39375 9.73425 2.25 9.30275 2.15C8.87125 2.05 8.437 2 8 2C6.3375 2 4.92175 2.5845 3.75275 3.7535C2.58375 4.9225 1.9995 6.338 2 8C2 9.6625 2.58425 11.0782 3.75275 12.2472C4.92125 13.4163 6.337 14.0005 8 14C9.6625 14 11.0782 13.4158 12.2472 12.2472C13.4163 11.0787 14.0005 9.663 14 8C14 7.9 13.9967 7.803 13.9902 7.709C13.9837 7.615 13.9745 7.51825 13.9625 7.41875C13.9375 7.20625 13.9783 7.00325 14.0848 6.80975C14.1913 6.61625 14.3505 6.488 14.5625 6.425C14.7625 6.3625 14.95 6.38125 15.125 6.48125C15.3 6.58125 15.4 6.73125 15.425 6.93125C15.45 7.10625 15.4688 7.28125 15.4813 7.45625C15.4938 7.63125 15.5 7.8125 15.5 8C15.5 9.0375 15.303 10.0125 14.909 10.925C14.515 11.8375 13.9808 12.6313 13.3063 13.3063C12.6313 13.9813 11.8375 14.5157 10.925 14.9097C10.0125 15.3037 9.0375 15.5005 8 15.5ZM6.95 9.35L13.925 2.35625C14.0625 2.21875 14.2345 2.14675 14.441 2.14025C14.6475 2.13375 14.8255 2.20575 14.975 2.35625C15.1125 2.49375 15.1813 2.66875 15.1813 2.88125C15.1813 3.09375 15.1125 3.26875 14.975 3.40625L7.475 10.925C7.325 11.075 7.15 11.15 6.95 11.15C6.75 11.15 6.575 11.075 6.425 10.925L4.2875 8.7875C4.15 8.65 4.08125 8.475 4.08125 8.2625C4.08125 8.05 4.15 7.875 4.2875 7.7375C4.425 7.6 4.6 7.53125 4.8125 7.53125C5.025 7.53125 5.2 7.6 5.3375 7.7375L6.95 9.35Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckCircle',
}
</script>

<style scoped>
path {
  fill: currentColor;
}
</style>
