<template>
  <v-sheet>
    <v-tabs v-model="tab">
      <v-tab href="#search">Listing Search</v-tab>
      <v-tab
        href="#reserved"
        @click="fetchReservations({ status: ['reserved'] })"
        >Reserved</v-tab
      >
      <v-tab
        href="#confirmed"
        @click="fetchReservations({ status: ['confirmed'] })"
        >Confirmed</v-tab
      >
    </v-tabs>
    <v-sheet class="ma-4">
      <v-row v-if="tab !== 'search'" class="align-center">
        <v-col cols="6" sm="4" lg="2">
          <v-autocomplete
            v-model="listingId"
            label="Listing"
            clearable
            hide-details
            :items="listingsPicker"
            item-text="nickname"
            item-value="id"
            dense
            outlined
            @change="fetchReservations({})"
          />
        </v-col>
        <v-col cols="auto">
          <v-btn
            small
            color="success"
            @click="fetchReservations({ excel: true, checkin: true })"
          >
            <v-icon small left>fas fa-table</v-icon> Export
          </v-btn>
        </v-col>
      </v-row>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="search">
          <listings-search :can-reserve="true" />
        </v-tab-item>

        <v-tab-item value="reserved">
          <reservations :headers="headers" @pagination="paginationChanged" />
        </v-tab-item>

        <v-tab-item value="confirmed">
          <reservations :headers="headers" @pagination="paginationChanged" />
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-sheet>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import ListingsSearch from 'components/listings-search'
import Reservations from 'components/reservations.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  mixins: [CommonFunctions],
  components: {
    ListingsSearch,
    Reservations,
  },
  data() {
    return {
      tab: null,
      dateBy: 'check_in',
      listingId: this.listingId,
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(['listingsPicker']),
    headers() {
      return [
        {
          text: 'Guest',
          value: 'guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Check in',
          value: 'check_in',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Check out',
          value: 'check_out',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Listing',
          value: 'nickname',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Confirmation',
          value: 'confirmation_code',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Payment',
          value: 'is_fully_paid',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Payment links',
          value: 'payment_intents',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Guests count',
          value: 'guests_count',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Requests',
          value: 'special_requests',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions(['getReviewsInfo', 'getManagedReservations']),
    fetchReservations(options = {}) {
      this.getManagedReservations({
        page: 1,
        per_page: this.pagination.itemsPerPage,
        listingId: this.listingId,
        agent_reservations: true,
        ...options,
      })
    },
    paginationChanged(data) {
      this.pagination = { ...this.pagination, ...data }
      this.fetchReservations(this.pagination)
    },
  },
}
</script>

<style></style>
