<template>
  <v-progress-circular
    v-if="isMobileOrIPad"
    class="centerize"
    indeterminate
    color="primary"
    :size="50"
  />
  <Lottie
    v-else
    class="centerize"
    :options="defaultOptions"
    :height="600"
    style="max-width: 600px"
    renderer="svg"
  />
</template>
<script>
import Lottie from 'vue-lottie'
import Pc from '@/assets/images/pc.json'
import DeviceMixin from '../mixins/device-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
export default {
  mixins: [DeviceMixin, PermissionsMixin],
  components: { Lottie },
  data() {
    return {
      defaultOptions: {
        animationData: Pc,
      },
    }
  },
}
</script>

<style scoped>
.centerize {
  position: absolute;
  padding: 6px;
  top: 25%;
  transform: translate(-50%, -50%);
  left: 50%;
}
</style>
