<template>
  <div v-if="isAdmin">
    <v-row class="row">
      <v-col sm="10" offset-sm="1" class="pt-3">
        <iframe
          src="https://public.domo.com/cards/xklzJ"
          width="1200"
          height="600"
          marginheight="0"
          marginwidth="0"
          frameborder="0"
        ></iframe>
      </v-col>
      <v-col sm="10" offset-sm="1" class="pt-3">
        <iframe
          src="https://public.domo.com/cards/2k9rJ"
          width="1200"
          height="600"
          marginheight="0"
          marginwidth="0"
          frameborder="0"
        ></iframe>
      </v-col>
      <v-col sm="10" offset-sm="1" class="pt-3">
        <iframe
          src="https://public.domo.com/cards/1wBEo"
          width="1200"
          height="600"
          marginheight="0"
          marginwidth="0"
          frameborder="0"
        ></iframe>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
export default {
  mixins: [CommonFunctions, PermissionsMixin],
}
</script>
