<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      d="M23.249,10.19,13.81.751a2.559,2.559,0,0,0-3.621,0L.75,10.19a2.559,2.559,0,0,0,0,3.621l9.439,9.439a2.559,2.559,0,0,0,3.621,0l9.439-9.439a2.559,2.559,0,0,0,0-3.621ZM22.189,12.75,12.75,22.189a1.06,1.06,0,0,1-1.5,0L1.811,12.75a1.06,1.06,0,0,1,0-1.5L11.25,1.811a1.06,1.06,0,0,1,1.5,0l9.439,9.439A1.042,1.042,0,0,1,22.5,12a1.054,1.054,0,0,1-.311.75Z"
      transform="translate(0 0)"
    />
    <path
      d="M162.388,146.934c-.059-.089.122.1-2.636-2.7a.75.75,0,0,0-1.069,1.052l1.328,1.349h-7.249a.75.75,0,0,0-.75.75v4.875a.75.75,0,0,0,1.5,0v-4.125h6.418l-1.328,1.349a.75.75,0,0,0,1.069,1.052l2.461-2.5A.79.79,0,0,0,162.388,146.934Z"
      transform="translate(-144.887 -137.262)"
    />
  </svg>
</template>

<script>
export default {
  name: 'MapDirections',
}
</script>

<style scoped></style>
