<template>
  <div>
    <div class="w-100 d-flex justify-end">
      <v-btn color="info" depressed @click="dialog = true">
        <v-icon class="me-1" small>mdi-apps</v-icon>
        Dialer
      </v-btn>
    </div>
    <v-dialog v-model="dialog" max-width="300px">
      <v-card>
        <v-card-text class="pt-6">
          <v-text-field
            v-model="phoneNumber"
            label="Phone Number"
            outlined
          ></v-text-field>

          <div class="dialer-buttons">
            <v-row>
              <v-col
                v-for="number in numbers"
                :key="number"
                cols="4"
                class="text-center pa-1"
              >
                <v-btn class="" outlined @click="appendNumber(number)">
                  {{ number }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="text-center">
                <v-btn class="pa-0" depressed @click="deleteNumber">
                  <v-icon>mdi-backspace</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-btn class="pa-0" outlined @click="appendNumber(0)">
                  0
                </v-btn>
              </v-col>
              <v-col cols="4" class="text-center">
                <v-btn class="pa-0" depressed color="success" @click="makeCall">
                  <v-icon>mdi-phone</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      phoneNumber: '',
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    }
  },
  methods: {
    appendNumber(number) {
      this.phoneNumber += number
    },

    deleteNumber() {
      this.phoneNumber = this.phoneNumber.slice(0, -1)
    },
    makeCall() {
      this.$emit('call', this.phoneNumber)
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.dialer-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
