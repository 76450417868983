<template>
  <div class="ma-0 pb-4 p-relative">
    <v-slide-x-transition>
      <pending-accept-tasks-tab v-if="$route.query['pending-accept']" />
    </v-slide-x-transition>
    <v-list
      v-show="!$route.query['pending-accept']"
      three-line
      :class="{ 'mt-3 grey lighten-4 elevation-3': !initialContractorId }"
      max-width="600"
      style="margin: auto"
    >
      <v-row no-gutters class="align-center justify-between ma-5 mb-0">
        <v-col cols="8" v-if="showContractorSelect">
          <contractor-select
            :value.sync="contractorId"
            :filter-change="contractorChange"
          />
        </v-col>
        <v-spacer v-if="showContractorSelect" />
        <v-col :cols="showContractorSelect ? undefined : '12'">
          <v-select
            v-model="sortOrder"
            :items="sortOptions"
            :label="$t('Sort')"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <div class="text-end">
        <v-btn
          v-if="pendingAccept.length"
          :to="{
            query: { 'pending-accept': 1 },
          }"
          text
          :ripple="false"
          color="primary"
        >
          ({{ pendingAccept.length }}) Pending Accept
          <v-icon x-small right class="cyan-icon">$right_arrow</v-icon>
        </v-btn>
      </div>
      <v-row no-gutters class="align-center justify-center">
        <stat-card
          :x-small="true"
          label="Done"
          :value="`${doneTasks} / ${listingTasks.length}`"
        />
        <stat-card :x-small="true" label="Listing" :value="listingsCount" />
        <stat-card :x-small="true" label="Hours" :value="reportedHours" />
      </v-row>
      <v-tabs
        v-model="scope"
        grow
        center-active
        small
        class="mt-0 mt-sm-2 hide-arrows"
        @change="onScopeChange"
      >
        <v-tab value="picker" class="d-flex align-center px-2">
          <date-picker :icon="true" label="Date" @change="onDateChange" />
        </v-tab>
        <v-tab href="#today">Today</v-tab>
        <v-tab href="#tomorrow">Tomorrow</v-tab>
        <v-tab href="#week">Week</v-tab>
        <v-tab href="#open">All</v-tab>
        <v-tab href="#closed">History</v-tab>
      </v-tabs>
      <div class="task-list">
        <today-task
          v-for="task in orderedTasks"
          :key="task.id"
          :task="task"
          :disabled.sync="tooltip"
          :scope="scope"
        />
        <v-pagination
          v-if="hasPages"
          v-model="page"
          :length="pageLength"
          prev-icon="chevron_left"
          next-icon="chevron_right"
          class="mt-3 mb-3 flex"
          @input="getTasks"
        />
      </div>
      <v-col class="mt-4">
        <span
          v-if="isEmpty(orderedTasks)"
          class="center-text grey-text headline-font"
          >{{ $t('No tasks found') }}</span
        >
      </v-col>
    </v-list>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import TodayTask from 'components/todays-tasks/today-task'
import ContractorSelect from 'components/contractor-select'
import orderBy from 'lodash/orderBy'
import StatCard from 'components/common/stat-card.vue'
import uniq from 'lodash/fp/uniq'
import { round } from 'lodash'
import sumBy from 'lodash/fp/sumBy'
import PendingAcceptTasksTab from 'components/pending-accept-tasks/pending-accept-tasks-tab.vue'
import DatePicker from 'components/form-fields/date-picker.vue'

export default {
  props: ['initialContractorId'],
  components: {
    PendingAcceptTasksTab,
    StatCard,
    TodayTask,
    ContractorSelect,
    DatePicker,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      contractorId: null,
      day: this.$moment(),
      tooltip: false,
      scope: 'today',
      page: 1,
      sortOrder: 'scheduled_at desc',
      sortOptions: [
        { text: this.$t('Newest First'), value: 'scheduled_at desc' },
        { text: this.$t('Oldest First'), value: 'scheduled_at asc' },
      ],
    }
  },
  computed: {
    ...mapState([
      'listingTasks',
      'listingTasksPagination',
      'listingLoading',
      'pendingAccept',
    ]),
    pageLength() {
      return Math.ceil(
        this.listingTasksPagination
          ? this.listingTasksPagination?.count /
              this.listingTasksPagination.per_page
          : 0
      )
    },
    hasPages() {
      return (
        this.listingTasksPagination &&
        this.listingTasksPagination.count > this.listingTasksPagination.per_page
      )
    },
    orderedTasks() {
      return this.mapTasks(this.listingTasks)
    },
    listingsCount() {
      return uniq(this.listingTasks.map(t => t.listing_id)).length
    },
    reportedHours() {
      return round(sumBy('total_hours', this.listingTasks), 1)
    },
    doneTasks() {
      return this.listingTasks.filter(
        t => t.status === 'Done' || t.status === "Can't do"
      ).length
    },
    showContractorSelect() {
      return this.managementRoles && !this.initialContractorId
    },
  },
  mounted() {
    this.contractorId = this.initialContractorId || this.getMyUserId
    this.getTasks()
  },
  watch: {
    sortOrder() {
      this.getTasks()
    },
  },
  methods: {
    ...mapActions(['getListingTasks']),
    onDateNavigation(change) {
      if (change > 0) {
        this.day.add(1, 'day')
      } else {
        this.day.subtract(1, 'day')
      }
      this.getTasks()
    },
    getTasks() {
      const filters = {
        contractor_id: this.contractorId,
        list_view: true,
        status: ['New', 'In Progress', 'Done', 'Rejected', 'Pending Approval'],
        order: this.sortOrder,
        per_page: 30,
        date: this.day.format('YYYY-MM-DD'),
        page: this.page,
      }
      if (this.scope === 'today') {
        filters.date = this.$moment().format('YYYY-MM-DD')
      }
      if (this.scope === 'tomorrow') {
        filters.date = this.$moment().add(1, 'day').format('YYYY-MM-DD')
      }
      if (this.scope === 'week') {
        filters.from = this.$moment().startOf('week').format('YYYY-MM-DD')
        filters.to = this.$moment().endOf('week').format('YYYY-MM-DD')
        filters.date = null
      }
      if (this.scope === 'open') {
        filters.date = null
        filters.status = ['New', 'In Progress']
      }
      if (this.scope === 'closed') {
        filters.date = null
        filters.status = ['Done', 'Rejected']
      }
      this.getListingTasks({ filters })
    },
    contractorChange(value) {
      this.contractorId = value
      this.getTasks()
    },
    mapTasks(tasks) {
      if (!['today', 'tomorrow'].includes(this.scope)) return tasks
      const allTasks = tasks.map(t => {
        const hour = this.$moment.utc(t.scheduled_at).hours()
        return { ...t, allDay: hour < 4 || hour > 22 }
      })
      return orderBy(allTasks, ['allDay', 'scheduled_at'], 'asc')
    },
    onScopeChange() {
      this.page = 1
      this.getTasks()
    },
    onDateChange(val) {
      this.scope = 'picker'
      this.day = this.$moment(val)
      this.getTasks()
    },
  },
}
</script>
<style scoped>
.task-list {
  max-height: calc(100vh - 340px);
  overflow-y: auto;
}
::v-deep .hide-arrows .v-slide-group__prev {
  display: none !important;
}
</style>
