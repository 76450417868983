<template>
  <v-row class="px-3">
    <v-col cols="12">
      <v-row class="flex-wrap-reverse">
        <v-col cols="12" sm="5">
          <v-text-field
            v-model="value.title"
            label="Title *"
            outlined
            hide-details
            :rules="[required]"
            :readonly="readonly"
          />
        </v-col>
        <v-spacer />
        <v-col v-if="!readonly" cols="12" sm="auto" class="d-flex justify-end">
          <ConfirmationModal
            :text="`Are you sure you want to delete this group ?`"
            @action="$emit('delete')"
          >
            <template #activator="{ on }">
              <v-btn color="secondary" outlined v-on="on">Delete Group</v-btn>
            </template>
          </ConfirmationModal>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="value.description"
        label="Description"
        outlined
        hide-details
        :readonly="readonly"
      />
    </v-col>
    <div class="mt-5 mx-3">Check List</div>
    <v-col v-if="value.checkList.length" cols="12">
      <v-divider />
      <div v-for="(checkListItem, index) in value.checkList" :key="index">
        <div>
          <div class="d-flex pa-5 flex-nowrap">
            <div>
              <v-checkbox
                v-model="checkListItem.checked"
                hide-details
                class="checkbox-no-label"
                :readonly="readonly"
              />
            </div>
            <v-divider vertical class="mx-5" />
            <div class="flex-1">
              <div>
                <catalog-item-picker
                  :value="checkListItem.catalog_id"
                  :readonly="true"
                />
              </div>
              <div class="larger-font mt-2">{{ checkListItem.text }}</div>
            </div>
            <template v-if="checkListItem.measurement_unit">
              <v-divider vertical class="mx-5" />
              <div class="align-center text-no-wrap">
                <v-text-field
                  v-model="checkListItem.measurement"
                  label="Measurement *"
                  type="number"
                  outlined
                  hide-details
                  dense
                  :readonly="readonly"
                  @input="onInputChange(checkListItem, $event)"
                />
                <div class="mt-1">
                  {{ checkListItem.measurement_unit }}
                </div>
                <v-tooltip
                  bottom
                  open-delay="300"
                  content-class="picker-tooltip"
                >
                  <template #activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      class="mt-1 text-body-2 text--secondary d-inline"
                      v-on="on"
                    >
                      Total cost:
                      {{ dollarFormatter(itemTotalCost(checkListItem)) }}
                    </div>
                  </template>
                  <div>
                    <div class="mt-1 text-body-2 text--secondary">
                      Labor time per unit:
                      {{ dollarFormatter(checkListItem.labor_time_per_unit) }}
                    </div>
                    <div class="mt-1 text-body-2 text--secondary">
                      Labor cost per unit:
                      {{ dollarFormatter(checkListItem.labor_cost_per_unit) }}
                    </div>
                    <div class="mt-1 text-body-2 text--secondary">
                      Materials cost + Tax per unit:
                      {{
                        dollarFormatter(
                          checkListItem.materials_cost_per_unit * 1.07
                        )
                      }}
                    </div>
                  </div>
                </v-tooltip>
              </div>
            </template>
            <v-divider vertical class="mx-5" />
            <div>
              <v-btn icon large @click="toggleItemNote(checkListItem)">
                <v-icon v-if="checkListItem.note" color="grey" large>
                  mdi-note-text
                </v-icon>
                <v-icon v-else-if="checkListItem.showNote" color="grey" large>
                  mdi-note-outline
                </v-icon>
                <v-icon v-else color="grey" large>
                  mdi-note-plus-outline
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="checkListItem.showNote" class="mb-5">
            <v-text-field
              v-model="checkListItem.note"
              label="Note"
              outlined
              hide-details
              :readonly="readonly"
              @input="onInputChange(checkListItem, $event)"
            />
          </div>
          <v-divider />
        </div>
      </div>
    </v-col>
    <v-col v-if="!readonly" cols="12" class="flex-center">
      <form-dialog
        title="Add New Check List Item"
        @submit="addNewCheckListItem"
        @open="newCheckListItem = {}"
      >
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" text color="grey" large v-on="on">
            <v-icon>mdi-plus</v-icon>
            Add New
          </v-btn>
        </template>
        <v-text-field
          v-model="newCheckListItem.text"
          label="Text *"
          outlined
          dense
          :rules="[required]"
        />
        <catalog-quick-select
          v-model="newCheckListItem.catalog_id"
          :rules="[required]"
          :division="division"
        />
      </form-dialog>
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="value.notes"
        label="General Notes"
        outlined
        :readonly="readonly"
      />
    </v-col>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import formRules from 'components/mixins/form-rules-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'
import { cloneDeep } from 'lodash'
import CatalogQuickSelect from 'components/catalog-quick-select'
import FormDialog from 'components/common/form-dialog'
import { mapGetters } from 'vuex'
import CatalogItemPicker from 'components/common/catalog-item-picker'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'EstimatesBuilderGroupView',
  components: {
    CatalogItemPicker,
    FormDialog,
    CatalogQuickSelect,
    ConfirmationModal,
  },
  mixins: [CommonFunctions, formRules, FormattersMixin],
  props: ['value', 'division', 'readonly'],
  data() {
    return {
      newCheckListItem: {},
    }
  },
  computed: {
    ...mapGetters(['catalogItemsMap']),
  },
  methods: {
    onInputChange(item, value) {
      if (value) item.checked = true
    },
    toggleItemNote(item) {
      item.showNote = !item.showNote
      this.$emit('input', cloneDeep(this.value))
    },
    itemTotalCost(item) {
      const measurement = Number(item.measurement || 0)
      return (
        Math.round(
          (item.labor_cost_per_unit * measurement +
            item.materials_cost_per_unit * 1.07 * measurement) *
            100
        ) / 100
      )
    },
    async addNewCheckListItem() {
      const catalog = this.catalogItemsMap[this.newCheckListItem.catalog_id]
      this.value.checkList.push({
        ...this.newCheckListItem,
        measurement_unit: catalog.measurement_unit,
        labor_time_per_unit: Number(catalog.labor_time_per_unit || 0),
        labor_cost_per_unit: Number(catalog.labor_cost_per_unit || 0),
        materials_cost_per_unit: Number(catalog.materials_cost_per_unit || 0),
        checked: true,
      })
    },
  },
}
</script>

<style scoped>
.checkbox-no-label {
  transform: scale(1.2);
}

.larger-font {
  font-size: 18px;
}
</style>
