<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.536"
    height="21.448"
    viewBox="0 0 15.536 21.448"
  >
    <path
      class="a"
      d="M369.795,0H368.5V21.448h1.295a1.3,1.3,0,0,0,1.295-1.295V1.295A1.3,1.3,0,0,0,369.795,0Z"
      transform="translate(-355.554)"
    />
    <path
      class="b"
      d="M82.741,20.153V1.295A1.3,1.3,0,0,0,81.446,0H69.795A1.3,1.3,0,0,0,68.5,1.295V20.153a1.3,1.3,0,0,0,1.295,1.295H81.446A1.3,1.3,0,0,0,82.741,20.153Z"
      transform="translate(-68.5)"
    />
    <ellipse
      class="c"
      cx="2.309"
      cy="2.309"
      rx="2.309"
      ry="2.309"
      transform="translate(5.459 1.295)"
    />
    <ellipse
      class="a"
      cx="0.647"
      cy="0.647"
      rx="0.647"
      ry="0.647"
      transform="translate(7.121 2.956)"
    />
    <circle class="d" cx="6" cy="6" r="6" transform="translate(1.534 8)" />
    <circle class="a" cx="5" cy="5" r="5" transform="translate(2.534 9)" />
    <path
      class="e"
      d="M248.5,242v7.071a3.549,3.549,0,0,0,0-7.071Z"
      transform="translate(-240.732 -231.557)"
    />
    <path
      class="c"
      d="M179.049,245.535c0-1.953-.932-3.535-2.081-3.535a3.536,3.536,0,0,0,0,7.071C178.117,249.071,179.049,247.488,179.049,245.535Z"
      transform="translate(-169.338 -231.557)"
    />
    <ellipse class="a" cx="0.5" rx="0.5" transform="translate(7.534 14)" />
  </svg>
</template>

<script>
export default {
  name: 'Audio',
}
</script>

<style scoped>
.a {
  fill: #106eb2;
}

.b {
  fill: #2088cb;
}

.c {
  fill: #82deff;
}

.d {
  fill: #ebfaff;
}

.e {
  fill: #4cf;
}
</style>
