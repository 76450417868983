<template>
  <v-card class="pa-3">
    <v-card-title class="d-flex justify-space-between align-center">
      <div>Catalog Information</div>
      <v-icon color="black" @click="closeModal">close</v-icon>
    </v-card-title>
    <v-card-text>
      <div v-if="pickerMode">
        <catalog-quick-select :simple-mode="true" @input="itemId = $event" />
        <span v-if="itemId && !hasAdditionalInfo" class="grey--text">
          No Additional info
        </span>
      </div>
      <div v-else class="d-flex text-capitalize pb-3">
        <span class="text-caption">{{ catalogItem.department }}</span>
        <v-icon small color="info" class="mx-1">fas fa-angle-right</v-icon>
        <span class="text-caption">{{ catalogItem.sub_topic }}</span>
        <v-icon v-if="catalogItem.sub_topic" small color="info" class="mx-1">
          fas fa-angle-right
        </v-icon>
        <span class="text-caption">{{ catalogItem.division }}</span>
        <v-icon v-if="catalogItem.description" small color="info" class="mx-1"
          >fas fa-angle-right</v-icon
        >
        <span class="text-caption">{{ catalogItem.description }}</span>
      </div>
      <div v-if="catalogItem.long_description" class="pb-3">
        <div class="text-subtitle-1">Description</div>
        <div>{{ catalogItem.long_description }}</div>
      </div>
      <div v-if="catalogItem.media && catalogItem.media.length" class="pb-3">
        <div class="text-subtitle-1">Media</div>
        <gallery
          :thumbnails="true"
          :readonly="true"
          :images="catalogItem.media"
        />
      </div>
      <div
        v-if="catalogItem.possible_causes && catalogItem.possible_causes.length"
        class="pb-3"
      >
        <div class="text-subtitle-1">Possible causes</div>
        <div v-if="catalogItem.possible_causes" class="text-caption d-flex">
          <v-row no-gutters class="flex-wrap">
            <v-col
              v-for="i in catalogItem.possible_causes"
              :key="i"
              cols="12"
              sm="6"
            >
              <li class="text-capitalize">{{ i }}</li></v-col
            >
          </v-row>
        </div>
      </div>
      <div
        v-if="catalogItem.quick_fixes && catalogItem.quick_fixes.length"
        class="pb-3"
      >
        <div class="text-subtitle-1">Quick fixes</div>
        <div v-if="catalogItem.quick_fixes" class="text-caption d-flex">
          <v-row no-gutters class="flex-wrap">
            <v-col
              v-for="i in catalogItem.quick_fixes"
              :key="i"
              cols="12"
              sm="6"
            >
              <li class="text-capitalize">{{ i }}</li></v-col
            >
          </v-row>
        </div>
      </div>
      <div
        v-if="catalogItem.needed_actions && catalogItem.needed_actions.length"
        class="pb-3"
      >
        <div class="text-subtitle-1">Needed actions</div>
        <div v-if="catalogItem.needed_actions" class="text-caption d-flex">
          <v-row no-gutters class="flex-wrap">
            <v-col
              v-for="i in catalogItem.needed_actions"
              :key="i"
              cols="12"
              sm="6"
            >
              <li class="text-capitalize">{{ i }}</li></v-col
            >
          </v-row>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Gallery from 'components/common/gallery'
import CatalogQuickSelect from 'components/catalog-quick-select'

export default {
  name: 'CatalogItemInfoModal',
  components: { CatalogQuickSelect, Gallery },
  props: {
    catalogItemId: {
      type: Number,
    },
    pickerMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemId: null,
    }
  },
  methods: {
    ...mapMutations(['hideModal']),
    closeModal() {
      this.hideModal()
    },
  },
  computed: {
    ...mapGetters(['catalogItems']),
    hasAdditionalInfo() {
      let catalogItem = this.catalogItem
      return (
        catalogItem &&
        this.pickerMode &&
        ((catalogItem.needed_actions && catalogItem.needed_actions.length) ||
          (catalogItem.quick_fixes && catalogItem.quick_fixes.length) ||
          (catalogItem.possible_causes && catalogItem.possible_causes.length) ||
          (catalogItem.media && catalogItem.media.length))
      )
    },
    catalogItem() {
      return (
        this.catalogItems.find(
          item => item.id === (this.catalogItemId || this.itemId)
        ) || {}
      )
    },
  },
}
</script>

<style scoped></style>
