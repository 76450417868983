import { round, isEmpty } from 'lodash'
export default {
  data() {
    return {
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 30 || 'Name must be less than 30 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'Email is not valid',
        v => !/\s/.test(v) || 'E-mail must not contain spaces',
        v => (v && v.includes('@')) || 'Email is not valid',
        v =>
          (v && v.indexOf('@') === v.lastIndexOf('@')) || 'Email is not valid',
        v => /^[^@]+@[^@]+$/.test(v) || 'Email is not valid',
        v => /@.+\..+/.test(v) || 'Email is not valid',
        v => !/[!#$%^&*()=[\]{};:"\\|,<>/?]+/.test(v) || 'Email is not valid',
      ],
      phoneRules: [
        v => !!v || 'Phone is required',
        v => /^[+0-9\s\-()]+$/.test(v) || 'Phone number must be valid',
      ],
      zipCodeRules: [
        v => !!v || 'Zip code is required',
        v => /^[0-9-]+$/.test(v) || 'Zip code must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v =>
          /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:"<>?~`\-=[\];.,/])(.{8,})$/.test(
            v
          ) ||
          'Password requires: Min 8 characters, number, upper and lower case, and special character.',
      ],
      urlRules: [
        v => !!v || 'required',
        v =>
          /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/.test(
            v
          ) || 'Url has to be valid using https',
      ],
      stripeRules: [
        v => !!v || 'required',
        ...(process.env.NODE_ENV === 'production'
          ? [
              v =>
                !/test/i.test(v) ||
                'The word "test" is not allowed in the credentials',
            ]
          : []),
      ],
      descriptionRules: [
        v =>
          (v && v.length >= 400 && v.length <= 40000) ||
          'Description must be 400 to 40000 characters',
      ],
      titleRules: [
        v =>
          (v && v.length >= 20 && v.length <= 50) ||
          'Title must be 20 to 50 characters',
      ],
      dateRange: v => {
        const dates = (v || '').split(' - ')
        return (
          Boolean(dates && dates[0] && dates[1]) ||
          'Date range must have start and end dates'
        )
      },
      required: v => {
        if (Array.isArray(v)) {
          return !isEmpty(v) || 'Required field'
        }
        return !!v || v === 0 || !isEmpty(v) || 'Required field'
      },
      maxItems(max) {
        return value =>
          !value ||
          !value.length ||
          value.length <= max ||
          `Maximum ${max} assigness`
      },
      isPositive: v => v > 0 || 'Value must be positive',
      nonNegative: v => v >= 0 || 'Value must be greater than 0',
      min(min) {
        return value => (value || '') >= min || `Minimum is ${round(min, 2)}`
      },
      max(max) {
        return value => (value || '') <= max || `Maximum is ${round(max, 2)}`
      },
      minCurrentYear: v =>
        (v && v >= new Date().getFullYear()) || 'Invalid year',
    }
  },
}
