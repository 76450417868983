var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{key:_vm.item.id,staticClass:"comp-wrapper mt-1 pa-0 text-caption align-center",class:_vm.taskFullDay(_vm.item) ? 'super-light-red' : 'green lighten-5',on:{"click":function($event){return _vm.taskClicked(_vm.item)}}},[(_vm.listStatus === 'In Progress' || _vm.listStatus === 'New')?_c('v-col',{attrs:{"cols":"1","md":"auto"}},[(
        (_vm.item.status === 'New' || _vm.item.status === 'In Progress') &&
        !_vm.item.department
      )?_c('v-checkbox',{nativeOn:{"click":function($event){$event.stopPropagation();return _vm.addToLtdSelected(_vm.item)}},model:{value:(_vm.item.selected),callback:function ($$v) {_vm.$set(_vm.item, "selected", $$v)},expression:"item.selected"}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"2","md":"auto"}},[_c('div',{staticClass:"pt-1 pl-1 pr-1 float-right list-cal-ini",style:('background-color: ' + _vm.stringToColour(_vm.itemAssignName(_vm.item)))},[_c('span',{staticClass:"white--text cal-image bolder2"},[_vm._v(_vm._s(_vm.initials(_vm.nameById(_vm.item.assigned_contractor_id))))])])]),_c('v-col',{staticClass:"align-center pointer text-start d-flex flex-wrap no-gutters",attrs:{"col":"9"}},[_c('v-col',{staticClass:"bb-1 pa-0",attrs:{"cols":"12"}},[(_vm.isSC(_vm.item))?_c('span',{staticClass:"bolder2"},[_vm._v(_vm._s(_vm.getListingById(_vm.item.listing_id).nickname))]):_vm._e(),(_vm.item.department)?_c('span',{staticClass:"cyan-icon"},[_vm._v("(Company task)")]):_vm._e(),_vm._v(" "+_vm._s(_vm.truncateBy(_vm.item.description, _vm.$vuetify.breakpoint.xs ? 18 : 40))+" ")]),_c('v-col',{staticClass:"pa-0 text-start",attrs:{"cols":"9"}},[_c('v-col',{staticClass:"flex-column d-flex pa-0 no-gutters",attrs:{"cols":"12"}},[_c('v-col',{class:_vm.howFarBack(_vm.item.scheduled_at) === 0 ? 'bolder purple-icon' : ''},[_vm._v(" "+_vm._s(_vm.parseDate(_vm.item.scheduled_at))+" - "),(_vm.isSC(_vm.item))?_c('span',{class:_vm.availColor(_vm.item)},[_vm._v(" "+_vm._s(_vm.item.add_today_avialability_text)+" ")]):_vm._e(),_vm._v("- "),(_vm.item.created_by_name)?_c('span',{staticClass:"cyan-icon"},[_vm._v("created by: "+_vm._s(_vm.item.created_by_name))]):_vm._e()]),(
            _vm.howFarBack(_vm.item.scheduled_at) &&
            _vm.item.status !== 'Done' &&
            _vm.howFarBack(_vm.item.scheduled_at) > 0
          )?_c('v-col',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.howFarBack(_vm.item.scheduled_at))+" days delay ")]):_vm._e()],1)],1),_c('v-col',{staticClass:"right-text",style:('color:' + _vm.statusColor(_vm.item)),attrs:{"cols":"3"}},[_c('v-layout',{attrs:{"column":""}},[(_vm.item.priority)?_c('v-flex',{staticClass:"center-text",class:_vm.priorityClass(_vm.item.priority)},[_vm._v(" "+_vm._s(_vm.item.priority)+" ")]):_vm._e(),_c('v-flex',{staticClass:"center-text"},[_vm._v(" "+_vm._s(_vm.item.status)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }