<template>
  <v-container v-if="accountingData" fluid>
    <v-row class="flex-md-row flex-column-reverse">
      <v-col cols="12" md="6" lg="6">
        <v-card>
          <v-card-title class="justify-space-between text-caption">
            <span>
              {{ $t('Accounting Details') }}
              <span class="ml-1">
                <v-tooltip v-if="isCurrentMonthOrYear" top>
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small v-on="on"> $info </v-icon>
                  </template>
                  <div>
                    Using up to date incomes, future income will not be taken
                    into account
                  </div>
                </v-tooltip>
              </span>
            </span>
            <v-menu bottom>
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" icon v-on="on">
                  <v-icon>mdi-export-variant</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link>
                  <v-list-item-title
                    @click="
                      isMonthly ? monthlyReport('pdf') : yearlyReport('pdf')
                    "
                    >PDF
                  </v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title
                    @click="
                      isMonthly ? monthlyReport('xlsx') : yearlyReport('xlsx')
                    "
                    >Excel
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <div
              class="d-flex text-subtitle-1 font-weight-medium align-center mb-3"
            >
              <v-icon color="success ml-auto" left small
                >fas fa-arrow-alt-circle-up
              </v-icon>
              <span class="px-2"> {{ $t('Income') }} </span>
              <v-skeleton-loader
                v-if="fetching"
                :height="20"
                :width="40"
                class="ml-auto"
                type="text"
              />
              <span v-else class="success--text ml-auto">{{
                dollarFormatter(totalIncome)
              }}</span>
            </div>
            <pnl-tree
              v-if="totalIncomeTransfers"
              :loading="fetching"
              :items="transferItems"
              color="success"
            />
            <pnl-tree
              :loading="fetching"
              :items="operationalItems"
              color="success"
              :open="true"
            />
          </v-card-text>
          <v-card-text>
            <div
              class="d-flex text-subtitle-1 font-weight-medium align-center mb-3"
            >
              <v-icon color="warning ml-auto" left small
                >fas fa-arrow-alt-circle-down
              </v-icon>
              <span class="px-2">{{ $t('Expenses') }}</span>
              <v-skeleton-loader
                v-if="fetching"
                :height="20"
                :width="40"
                class="ml-auto"
                type="text"
              />
              <span v-else class="warning--text ml-auto">{{
                dollarFormatter(totalExpenses)
              }}</span>
            </div>
            <pnl-tree
              :loading="fetching"
              :items="operationalExpensesItems"
              color="warning"
              :open="true"
            />
            <pnl-tree :loading="fetching" :items="setupItems" color="warning" />
            <pnl-tree
              :loading="fetching"
              :items="costBaseItems"
              color="warning"
            />
            <pnl-tree
              :loading="fetching"
              :items="financialItems"
              color="warning"
              :show-zero="true"
            />
          </v-card-text>
          <v-divider />
          <v-card-text>
            <div
              v-if="totalTransfers !== 0"
              class="d-flex justify-space-between text-subtitle-1 font-weight-medium align-center mt-4"
            >
              <span>Transfers</span>
              <v-skeleton-loader
                v-if="fetching"
                :height="20"
                :width="40"
                class="ml-auto"
                type="text"
              />
              <span v-else class="info--text">{{
                dollarFormatter(totalTransfers)
              }}</span>
            </div>
            <v-list dense>
              <div v-for="(value, key) in channels" :key="`transfer-${key}`">
                <v-list-item
                  v-if="value['transfered_by_channel'] > 0"
                  class="text-caption pr-0"
                >
                  {{ key }}
                  {{ dollarFormatter(value['transfered_by_channel']) }}
                </v-list-item>
              </div>
              <v-list-item
                v-for="payment in payments"
                :key="`payment-${payment.id}`"
                class="pr-0"
              >
                <v-list-item-icon
                  v-if="payment.title === 'Piggy bank'"
                  class="mr-2"
                >
                  <v-icon small>fas fa-piggy-bank</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-if="payment.title === 'Piggy bank'"
                    class="font-weight-regular text-caption"
                  >
                    <span v-if="payment.piggy_manual_deposit"
                      >External deposit</span
                    >
                  </v-list-item-title>
                  <v-list-item-title
                    v-else-if="payment.type_of_payment === 'Rollover'"
                    class="font-weight-regular text-caption"
                  >
                    {{ payment.recipient_name }}

                    <info-tooltip v-if="payment.note">
                      {{ payment.note }}
                    </info-tooltip>
                  </v-list-item-title>
                  <v-list-item-title
                    v-else
                    class="font-weight-regular text-caption"
                  >
                    {{ payment.type_of_payment }} ({{
                      parseDate(payment.paid_at, 'YYYY/MM/DD', { local: true })
                    }}) to
                    {{ payment.recipient_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-if="payment.title === 'Piggy bank'"
                    class="text-caption font-weight-light secondary--text"
                  >
                    {{ payment.type_of_payment }} ({{
                      parseDate(payment.paid_at, 'YYYY/MM/DD', { local: true })
                    }}) to
                    {{ payment.recipient_name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-skeleton-loader
                  v-if="fetching"
                  :height="20"
                  :width="40"
                  class="ml-auto"
                  type="text"
                />
                <span v-else class="ml-auto">
                  {{ dollarFormatter(payment.amount) }}
                </span>
              </v-list-item>
            </v-list>
          </v-card-text>
          <div class="grey lighten-4 pa-4">
            <div class="d-flex align-center">
              <span>{{ $t('Balance') }}</span>
              <v-spacer />
              <v-skeleton-loader
                v-if="fetching"
                :height="20"
                :width="40"
                type="text"
              />
              <span v-else class="font-weight-bold">
                {{ dollarFormatter(balance) }}
              </span>
            </div>
          </div>
        </v-card>
        <v-card v-if="hasReferrer && (isAdmin || isReferrer)">
          <v-card-title class="text-caption">Referrer</v-card-title>
          <v-card-text>
            <v-list dense>
              <v-list-item
                v-for="payment in referrerPayments"
                :key="payment.type_of_payment"
                class="text-caption pr-0"
              >
                <span>
                  {{ payment.type_of_payment }} ({{
                    parseDate(payment.paid_at, 'YYYY/MM/DD', { local: true })
                  }}) to {{ payment.recipient_name }}
                </span>
                <span class="ml-auto">
                  {{ dollarFormatter(payment.amount) }}
                </span>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions class="grey lighten-4 pa-4">
            <span>Referrer Balance</span>
            <v-spacer />
            <span class="font-weight-bold">
              {{ dollarFormatter(referrerBalance) }}
            </span>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-row>
          <investment-card
            :loading="fetching"
            cols="6"
            height="112"
            :card="cards.income"
          />
          <investment-card
            :loading="fetching"
            cols="6"
            height="112"
            :card="cards.expenses"
          />
          <investment-card
            :loading="fetching"
            cols="6"
            height="112"
            :card="cards.operational_profit"
          />
          <investment-card
            :loading="fetching"
            cols="6"
            height="112"
            :card="cards.transfers"
          />
          <investment-card
            :loading="fetching"
            cols="12"
            height="112"
            :card="cards.balance"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapMutations, mapState } from 'vuex'
import { sumBy } from 'lodash'
import filter from 'lodash/fp/filter'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { MONTHS } from '@/consts'
import PnlTree from 'components/accounting/pnl-tree'
import groupBy from 'lodash/fp/groupBy'
import InvestmentCard from 'components/investment-info/investment-card'
import get from 'lodash/fp/get'
import InfoTooltip from 'components/common/info-tooltip.vue'

const filterZeros = filter(t => t.total)
export default {
  components: {
    InfoTooltip,
    InvestmentCard,
    PnlTree,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['listing', 'isMonthly', 'calendar', 'year', 'month', 'fetching'],
  data() {
    return {
      showUnClassified: false,
      showUtilities: false,
      showMaintenance: false,
      showOneTime: false,
      showMonthly: false,
    }
  },
  mounted() {
    this.$store.commit('taskCalendar/updateMiniCalendarTitle', this.pickerDate)
  },
  computed: {
    ...mapState(['accountingData', 'currentListing']),
    ...mapState({
      expenses: state => state.accountingData.expenses || [],
      payments: state => state.accountingData.payments || [],
      channels: state => state.accountingData.channels || [],
      operationalExpenses: state => state.accountingData.operational_expenses,
      setupExpenses: state => state.accountingData.setup_expenses,
      transferredSum: state => state.accountingData.transfered_sum,
      costBaseExpenses: state => state.accountingData.cost_base_expenses,
      financialExpenses: state => state.accountingData.finance_expenses,
      claims: state => state.accountingData.claims,
      incomeTransfers: state => state.accountingData.income_transfers,
      totalIncome: state => state.accountingData.summary.income,
      totalExpenses: state => state.accountingData.summary.expenses,
      totalChannels: state => state.accountingData.summary.channels_fees,
      referrerPayments: state => state.accountingData.referrer_payments,
      referrerBalance: state => state.accountingData.need_to_transfer_ref,
      mgmtCommissionPercent: state =>
        state.accountingData.summary.management_commission_percent,
      mgmtCommissionFormula: state =>
        state.accountingData.summary.mgmt_commission_formula,
      mgmtCommissionFormulaNumbers: state =>
        state.accountingData.summary.mgmt_commission_formula_numbers,
      balance: state => state.accountingData.summary.pnl_balance,
      totalCleaningIncome: state => state.accountingData.summary.cleaning_fees,
      totalCleaningExpenses: state =>
        state.accountingData.summary.actual_cleaning_fees,
      totalProcessing: state => state.accountingData.summary.processing_fees,
      totalClaims: state => state.accountingData.summary.claims_income_sum,
      totalIncomeTransfers: state =>
        state.accountingData.summary.income_transfers_sum,
      totalTaxesIncome: state => state.accountingData.total_taxes_income,
      totalManagement: state => state.accountingData.summary.management_fees,
      totalManagementVat: state =>
        state.accountingData.summary.management_fees_vat,
      totalExpensesVat: state => state.accountingData.summary.expenses_vat,
      totalChannelVat: state => state.accountingData.summary.channel_fees_vat,
      totalRegistration: state => state.accountingData.registration_fee,
      storeProfits: state => state.accountingData.store_investor_profits,
      expenseRatio: state => state.accountingData.summary.expense_ratio,
      totalAccommodation: state =>
        state.accountingData.summary.fare_accommodation,
    }),
    pickerDate() {
      return `${MONTHS[this.month]} ${this.year}`
    },
    cards() {
      return {
        income: {
          label: this.$t('Income'),
          color: '#EAF5ED',
          textColor: '#396646',
          icon: 'fa-arrow-alt-circle-up',
          iconColor: 'green',
          value: this.totalIncome,
        },
        expenses: {
          label: this.$t('Expenses'),
          color: '#FEF0EC',
          textColor: '#603128',
          icon: 'fa-arrow-alt-circle-down',
          iconColor: 'red',
          value: this.totalExpenses,
        },
        balance: {
          label: this.$t('Balance'),
          color: '#cedcf5',
          textColor: '#1e71f3',
          icon: 'fa-balance-scale',
          iconColor: '#1e71f3',
          value: this.balance,
        },
        transfers: {
          label: this.$t('Transfers'),
          color: '#ffeed0',
          textColor: '#746039',
          icon: 'fa-exchange-alt',
          iconColor: 'orange',
          value: this.totalTransfers,
        },
        operational_profit: {
          label: this.$t('Ops Expense ratio'),
          color: '#F2EBF7',
          icon: 'fa-percentage',
          iconColor: 'purple',
          textColor: '#624774',
          isPercent: true,
          value: this.expenseRatio,
        },
        mortgage: {
          label: this.$t('Mortgage'),
          color: '#e3f6fa',
          textColor: '#48747c',
          icon: 'fa-building',
          iconColor: '#48747c',
          value: this.totalMortgage,
        },
      }
    },
    isCurrentMonthOrYear() {
      const isSameMonth = this.month === new Date().getMonth()
      const isSameYear = this.year === new Date().getFullYear()
      if (this.isMonthly) {
        return isSameMonth && isSameYear
      }
      return isSameYear
    },
    paymentsSum() {
      return sumBy(this.payments, 'amount')
    },
    hasReferrer() {
      return this.currentListing.referrer_id
    },
    totalMortgage() {
      return sumBy(
        this.financialExpenses.filter(
          e => e.short_reason === 'Mortgage Payments'
        ),
        'amount'
      )
    },
    totalTransfers() {
      return this.transferredSum + this.paymentsSum
    },
    taxMethod() {
      return this.$store.state.app.configuration.tax_method
    },
    transferItems() {
      return [
        {
          name: 'Credits',
          id: Math.random(),
          root: true,
          total: this.totalIncomeTransfers,
          children: this.incomeTransfers.map(it => ({
            id: Math.random(),
            comment: !it.should_calculate_in
              ? `This income isn't calculated in accounting, it is only for
              recording purposes ${it.comments}`
              : it.comments,
            name: `${it.income_type} - ${this.parseDate(
              it.transferred_at,
              'YYYY/MM/DD',
              {
                local: true,
              }
            )}`,
            total: it.amount,
          })),
        },
      ]
    },
    claimsItems() {
      return [
        {
          name: 'Claims',
          id: Math.random(),
          total: this.totalClaims,
          children: this.claims.map(it => ({
            id: Math.random(),
            comment: !it.should_calculate_in
              ? `This income isn't calculated in accounting, it is only for
              recording purposes ${it.comments}`
              : it.comments,
            name: `${it.income_type} - ${this.parseDate(
              it.transferred_at,
              'YYYY/MM/DD',
              {
                local: true,
              }
            )}`,
            total: it.amount,
          })),
        },
      ]
    },
    taxItems() {
      const items = [
        {
          id: Math.random(),
          name: 'Cleaning fee',
          total: this.totalCleaningIncome,
        },
      ]
      if (this.taxMethod === 'owner') {
        items.push({
          id: Math.random(),
          name: 'Taxes',
          total: this.totalTaxesIncome,
        })
      }
      return [
        {
          name: 'Taxes & Fees',
          id: Math.random(),
          total: sumBy(items, 'total'),
          children: filterZeros(items),
        },
      ]
    },
    taxExpensesItems() {
      const items = [
        {
          id: Math.random(),
          name: this.$t('Cleaning fee'),
          total: this.totalCleaningExpenses,
        },
        {
          id: Math.random(),
          name: this.$t('Channel fees'),
          comment:
            'Channel fee is the commission we pay after each reservation.',
          total: this.totalChannels,
        },
        {
          id: Math.random(),
          name: this.$t('Management fees'),
          comment:
            this.mgmtCommissionFormula && this.isMonthly
              ? this.totalAccommodation * this.mgmtCommissionPercent ===
                this.totalManagement
                ? `${this.mgmtCommissionFormula} \n ${this.mgmtCommissionFormulaNumbers}`
                : `${this.mgmtCommissionFormula}`
              : null,
          total: this.totalManagement,
        },
        {
          id: Math.random(),
          name: this.$t('Management fees VAT'),
          total: this.totalManagementVat,
        },
        {
          id: Math.random(),
          name: this.$t('Channel fees VAT'),
          total: this.totalChannelVat,
        },
        {
          id: Math.random(),
          name: this.$t('Other expenses VAT'),
          total: this.totalExpensesVat,
        },
        {
          id: Math.random(),
          name: this.$t('Processing fees'),
          comment: 'Processing fees for credit charges done by us',
          total: this.totalProcessing,
        },
        {
          id: Math.random(),
          name: this.$t('Registration fee'),
          total: this.totalRegistration,
        },
      ]
      return [
        {
          name: this.$t('Fees'),
          id: Math.random(),
          total: sumBy(items, 'total'),
          children: filterZeros(items),
        },
      ]
    },
    operationalExpensesItems() {
      let children = [...this.taxExpensesItems]

      children = children.concat(
        this.groupExpensesByCategory(this.operationalExpenses, [
          'related_division',
          'short_reason',
        ])
      )
      return [
        {
          name: this.$t('Operational'),
          id: Math.random(),
          root: true,
          total: sumBy(children, 'total'),
          children: filterZeros(children),
        },
      ]
    },
    setupItems() {
      const children = this.groupExpensesByCategory(this.setupExpenses, [
        'related_division',
        'short_reason',
      ])
      return [
        {
          name: this.$t('Setup'),
          id: Math.random(),
          root: true,
          total: sumBy(this.setupExpenses, 'amount'),
          children: filterZeros(children),
        },
      ]
    },
    costBaseItems() {
      const children = this.groupExpensesByCategory(this.costBaseExpenses, [
        'related_division',
        'short_reason',
      ])
      return [
        {
          name: this.$t('Cost base'),
          id: Math.random(),
          root: true,
          total: sumBy(this.costBaseExpenses, 'amount'),
          children: filterZeros(children),
        },
      ]
    },
    financialItems() {
      const children = this.groupExpensesByCategory(this.financialExpenses, [
        'related_division',
        'short_reason',
      ])
      return [
        {
          name: this.$t('Financial'),
          id: Math.random(),
          root: true,
          total: sumBy(this.financialExpenses, 'amount'),
          children: children,
        },
      ]
    },
    channelItems() {
      return [
        {
          name: this.$t('Channels'),
          id: Math.random(),
          total: +this.totalChannel(),
          children: Object.entries(this.channels).map(([channel, data]) => ({
            id: Math.random(),
            name: channel,
            total: this.channelTotalByFormula(data),
          })),
        },
      ]
    },
    operationalItems() {
      const children = [
        ...this.channelItems,
        ...this.claimsItems,
        ...this.taxItems,
      ]
      if (this.storeProfits) {
        children.push({
          id: 1,
          name: this.$t('Store'),
          total: this.storeProfits,
          children: this.accountingData.store_profits_sources.map(s => ({
            ...s,
            name: s.item_name,
            total: s.investor_amount,
          })),
        })
      }
      return [
        {
          name: this.$t('Operational'),
          id: Math.random(),
          root: true,
          total: sumBy(children, 'total'),
          children: filterZeros(children),
        },
      ]
    },
  },
  methods: {
    ...mapMutations([
      'updateAccountingData',
      'updateListingLoading',
      'showModal',
    ]),
    totalChannel() {
      const totals = Object.entries(this.channels).map(([channel, data]) =>
        this.channelTotalByFormula(data)
      )
      return totals.reduce((acc, curr) => acc + curr, 0).toFixed(2)
    },
    channelTotalByFormula(reservation) {
      const { owners_income_formula } = this.$store.state.app.configuration
      const formula =
        this.listing.owners_income_formula || owners_income_formula
      const VALUES = {
        client_profit: 'fare_accommodation',
        client_price: 'client_price',
        fare_accommodation: 'fare_accommodation',
        net_income: 'net_income',
        price_total: 'price',
      }

      return get(
        owners_income_formula ? VALUES[formula] : 'fare_accommodation',
        reservation
      )
    },
    groupExpensesByCategory(expenses, cats) {
      if (cats.length === 0) {
        return expenses.map(e => ({
          id: Math.random(),
          name: `${e.short_reason}`,
          dateDone: `${this.parseDate(e.work_info.done_at || e.paid_at)}`,
          expense: e,
          total: e.amount,
        }))
      }
      const category = cats[0]

      const children = groupBy(category, expenses)
      return Object.entries(children).map(([subKey, subExps]) => {
        const totalSumTopic = sumBy(subExps, 'amount')
        return {
          name: subKey,
          total: totalSumTopic,
          id: Math.random(),
          children: this.groupExpensesByCategory(subExps, cats.slice(1)),
        }
      })
    },
    expensesInCat(cat, expenses) {
      let res = []
      expenses.forEach(expense => {
        if (
          expense.short_reason === cat ||
          (expense.sub_topic && expense.sub_topic === cat)
        ) {
          res.push(expense)
        }
      })
      return res
    },
    monthlyReport(format) {
      this.updateListingLoading(true)
      const id = this.currentListing.id
      const language = this.$i18n.locale
      const [month, year] = this.pickerDate.split(' ')
      axios
        .get(`/api/listing/${id}/yearly_report`, {
          params: {
            year,
            month,
            language: language,
            is_pdf: format === 'pdf' ? '1' : null,
          },
          responseType: 'blob',
        })
        .then(response => {
          this.updateListingLoading(false)

          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            `${this.currentListing.nickname}-${month}-report.${format}`
          )
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(error => {
          alert(error)
        })
    },
    yearlyReport(format) {
      this.updateListingLoading(true)
      const id = this.currentListing.id
      const language = this.$i18n.locale
      axios
        .get(`/api/listing/${id}/yearly_report?year=${this.year}`, {
          params: {
            year: this.year,
            language: language,
            is_pdf: format === 'pdf' ? '1' : null,
          },
          responseType: 'blob',
        })
        .then(response => {
          this.updateListingLoading(false)

          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            `${this.currentListing.nickname}-${this.year}-report.${format}`
          )
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(error => {
          alert(error)
        })
    },
    updateChosenPayment(payment) {
      this.$store.commit('updateChosenPayment', payment)
    },
  },
}
</script>
<style scoped>
.profit-tree >>> .v-treeview-node__level {
  width: 7px !important;
}
</style>
