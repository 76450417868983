<template>
  <v-container>
    <v-card class="overflow-y-auto">
      <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
        <div class="text-capitalize">{{ integrationName }}</div>
      </v-card-title>
      <v-card-text>
        <div class="text-body-1">
          Sync your bank accounts data with Boom dashboard
        </div>
        <div class="text-subtitle-1 font-weight-medium mt-4">
          Connected Institutions
        </div>
        <v-list>
          <v-list-item
            v-for="integration in pintegrations"
            :key="integration.id"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">{{
                name(integration)
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="d-flex flex-row">
              <plaid-link
                :disabled="btnLoading"
                :update="true"
                :integration-id="integration.id"
              />
              <confirmation-modal
                v-if="integration"
                :text="`Are you sure you want to disconnect ${name(
                  integration
                )} ?`"
                @action="disconnect(integration)"
              >
                <template #activator="{ on }">
                  <v-btn
                    outlined
                    dense
                    text
                    small
                    :loading="btnLoading"
                    class="mx-2"
                    color="error"
                    v-on="on"
                    >Remove</v-btn
                  >
                </template>
              </confirmation-modal>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="justify-end">
        <plaid-link />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import PlaidLink from 'components/plaid/plaid-link.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import axios from '@/axios/config'
import { mapGetters } from 'vuex'

export default {
  name: 'Plaid',
  data() {
    return {
      btnLoading: false,
    }
  },

  components: { ConfirmationModal, PlaidLink },
  computed: {
    ...mapGetters(['integrations']),

    pintegrations() {
      return this.integrations.filter(i => i.name == 'plaid')
    },

    integrationName() {
      return this.$route.params.name
    },
  },

  methods: {
    async disconnect(integration) {
      this.btnLoading = true
      try {
        await axios.post('/api/plaid/disconnect', { id: integration.id })
      } catch (_e) {
        this.btnLoading = false
      }
      this.btnLoading = false
    },

    name(int) {
      const cred = int.creds.find(c => c.key == 'institution_name')
      if (cred) {
        return cred.value
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped></style>
