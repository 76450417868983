<template>
  <div>
    <v-progress-linear v-show="fetchingReportData" indeterminate />
    <div class="pa-0 pt-2">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ListingReportLayout',
  computed: {
    ...mapState('listingReport', ['fetchingReportData']),
  },
}
</script>

<style scoped>
.availability {
  width: 4px;
  height: 100%;
  position: absolute;
  border-radius: 0;
}
::v-deep .v-input--selection-controls__input {
  margin: 5px;
}
</style>
