<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.315"
    height="15.307"
    viewBox="0 0 15.315 15.307"
  >
    <g transform="translate(0 0)">
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <path
            d="M42.243,2.376A7.662,7.662,0,0,1,51.2,1a7.7,7.7,0,0,1,1.72,1.231V.133h1.2V4.321H49.931v-1.2h2.194A6.46,6.46,0,0,0,41.2,7.79c0,.2.009.4.027.594l-1.192.109c-.021-.232-.032-.468-.032-.7h0A7.608,7.608,0,0,1,42.243,2.376Z"
            transform="translate(-40 -0.133)"
          />
          <path d="M512,256.132Z" transform="translate(-512 -248.475)" />
        </g>
      </g>
      <g transform="translate(11.935 11.708)">
        <g transform="translate(0)">
          <path
            d="M57.335,391.566l.932.751a7.718,7.718,0,0,1-.895.937l-.792-.9A6.515,6.515,0,0,0,57.335,391.566Z"
            transform="translate(-56.579 -391.566)"
          />
        </g>
      </g>
      <g transform="translate(13.303 9.805)">
        <g transform="translate(0)">
          <path
            d="M14.942,327.943l1.128.4a7.646,7.646,0,0,1-.534,1.182l-1.045-.583A6.45,6.45,0,0,0,14.942,327.943Z"
            transform="translate(-14.492 -327.943)"
          />
        </g>
      </g>
      <g transform="translate(14.027 7.657)">
        <g transform="translate(0)">
          <path
            d="M.092,256.133h1.2a7.733,7.733,0,0,1-.108,1.291l-1.18-.2A6.526,6.526,0,0,0,.092,256.133Z"
            transform="translate(0 -256.133)"
          />
        </g>
      </g>
      <g transform="translate(10.084 13.152)">
        <g transform="translate(0)">
          <path
            d="M122.222,439.841l.631,1.017a7.654,7.654,0,0,1-1.155.587l-.45-1.109A6.445,6.445,0,0,0,122.222,439.841Z"
            transform="translate(-121.248 -439.841)"
          />
        </g>
      </g>
      <g transform="translate(0.258 9.328)">
        <path
          d="M453.478,311.981a6.442,6.442,0,0,0,.371,1.028l-1.087.5a7.622,7.622,0,0,1-.44-1.22Z"
          transform="translate(-452.322 -311.981)"
        />
      </g>
      <g transform="translate(3.135 12.872)">
        <path
          d="M353.048,430.468a6.48,6.48,0,0,0,.934.57l-.534,1.07a7.676,7.676,0,0,1-1.107-.675Z"
          transform="translate(-352.341 -430.468)"
        />
      </g>
      <g transform="translate(1.338 11.306)">
        <path
          d="M412.117,378.123a6.521,6.521,0,0,0,.692.847l-.859.833a7.716,7.716,0,0,1-.82-1Z"
          transform="translate(-411.13 -378.123)"
        />
      </g>
      <g transform="translate(5.447 13.845)">
        <g transform="translate(0)">
          <path
            d="M282.959,462.995a6.431,6.431,0,0,0,1.07.225l-.146,1.188a7.653,7.653,0,0,1-1.269-.267Z"
            transform="translate(-282.614 -462.995)"
          />
        </g>
      </g>
      <g transform="translate(7.955 13.97)">
        <g transform="translate(0)">
          <path
            d="M202.364,467.185l.255,1.169a7.686,7.686,0,0,1-1.285.168l-.054-1.2A6.505,6.505,0,0,0,202.364,467.185Z"
            transform="translate(-201.28 -467.185)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Rotate',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
