<template>
  <Tag
    v-if="!isNaN(value) && isFinite(value)"
    :color="color"
    inverted
    :rounded="true"
    :height="16"
    class="font-weight-medium d-flex align-center ml-3"
  >
    <v-icon
      v-if="value !== 0 && !$vuetify.breakpoint.xs"
      x-small
      class="mx-1"
      color="inherit"
      >{{ value > 0 ? '$trend_up' : '$trend_down' }}</v-icon
    >
    <v-icon v-else-if="!$vuetify.breakpoint.xs" small right color="grey"
      >mdi-plus-minus</v-icon
    >
    <span class="mx-1">{{
      value > 0 ? `+${toPercent(value)}` : toPercent(value)
    }}</span>
  </Tag>
</template>

<script>
import Tag from 'components/common/tag.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'DeltaTag',
  components: { Tag },
  mixins: [FormattersMixin],
  props: ['value', 'inverted'],
  computed: {
    color() {
      const trendColor = this.value >= 0 ? '#00BF2C' : '#ff4757'
      if (!this.inverted) {
        return trendColor
      } else {
        return this.value < 0 ? '#00BF2C' : '#ff4757'
      }
    },
  },
}
</script>

<style scoped></style>
