<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="12" sm="6" md="3">
        <v-select
          v-model="filters.division"
          outlined
          hide-details
          :items="['Construction', 'Maintenance', 'Design']"
          label="Choose Division"
          clearable
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <listing-select
          :pre-selected.sync="filters.listing_id"
          label="Choose Listing"
          :clearable="true"
          :dense="false"
          :hide-details="true"
          @update:pre-selected="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="filters.search"
          label="Search..."
          prepend-inner-icon="search"
          outlined
          clearable
          hide-details
          @input="fetchDataDebounce(1)"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        class="elevation-1 w-100 mx-3"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        @pagination="paginationChanged($event)"
      >
        <template #item.listing_id="{ item }">
          <listing-picker :value="item.listing_id" :disabled="true" />
        </template>
        <template #item.created_at="{ item }">
          {{ parseDate(item.created_at) }}
        </template>
        <template #item.actions="{ item }">
          <div v-if="item.approved" class="flex-center">
            <v-tooltip bottom open-delay="200">
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-btn color="info" icon small @click="openInDialog(item)">
                    <v-icon small>mdi-eye-outline</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>View</span>
            </v-tooltip>
            <v-tooltip bottom open-delay="200">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  target="_blank"
                  :to="addressLinkToProjectBoard(item.project.id)"
                  icon
                  small
                  v-on="on"
                >
                  <v-icon small>mdi-clipboard-list-outline</v-icon>
                </v-btn>
              </template>
              <span>See Board</span>
            </v-tooltip>
          </div>
          <div v-else class="flex-center">
            <v-tooltip bottom open-delay="200">
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-btn color="info" icon small @click="openInDialog(item)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </span>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
      <v-card v-if="dialog">
        <v-toolbar dark color="primary">
          <v-toolbar-title v-if="dialogEstimate">
            {{ dialogEstimate.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div v-if="dialogEstimate.approved">Approved</div>
          <v-toolbar-items>
            <div class="flex-center">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-toolbar-items>
        </v-toolbar>
        <estimates-builder
          v-if="dialogEstimate"
          :edit-estimate.sync="dialogEstimate"
          @after-update="fetchData()"
          @after-delete="afterDeleteEstimate"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { debounce, find } from 'lodash'
import EstimatesBuilder from 'components/estimates/estimates-builder'
import ListingSelect from 'components/listing-select'
import ListingPicker from 'components/listing/listing-picker'

export default {
  name: 'EstimatesTable',
  components: { ListingPicker, ListingSelect, EstimatesBuilder },
  mixins: [CommonFunctions, PermissionsMixin],
  data: function () {
    return {
      dialog: false,
      dialogEstimate: null,
      filters: {
        division: null,
        listing_id: null,
        search: '',
      },
      tableHeaders: [
        { text: 'Id', value: 'id', sortable: false },
        { text: 'Division', value: 'division', sortable: false },
        {
          text: 'Title',
          value: 'title',
          sortable: false,
          cellClass: 'text-no-wrap',
        },
        { text: 'Listing', value: 'listing_id', sortable: false },
        { text: 'Approved', value: 'approved', sortable: false },
        { text: 'Created At', value: 'created_at', sortable: false },
        { text: 'Actions', value: 'actions', align: 'center', width: 100 },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 25,
        itemsLength: null,
      },
      items: [],
      fetchDataDebounce: debounce(this.fetchData, 300),
    }
  },
  computed: {
    paramsEstimateId: {
      get() {
        return Number(this.$route.params.estimateId)
      },
      set(val) {
        if (Number(this.$route.params.estimateId) !== Number(val)) {
          this.$router.replace({
            params: { ...this.$route.params, estimateId: val },
          })
        }
      },
    },
  },
  async mounted() {
    await this.fetchData()
    await this.initEstimateDialog()
  },
  methods: {
    ...mapActions('estimates', ['getEstimates', 'getEstimate']),
    fetchData(page) {
      if (page) this.pagination.page = page
      this.getEstimates({
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        ...this.filters,
      }).then(res => {
        this.items = res.estimates
        this.pagination = {
          page: parseInt(res.pagi_info.page),
          itemsPerPage: parseInt(res.pagi_info.per_page),
          itemsLength: parseInt(res.pagi_info.count),
        }
      })
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchData()
      }
    },
    openInDialog(estimate) {
      this.dialogEstimate = estimate
      this.paramsEstimateId = estimate.id
      this.dialog = true
    },
    async initEstimateDialog() {
      if (this.paramsEstimateId) {
        let estimate = find(this.items, { id: this.paramsEstimateId })
        if (!estimate) {
          estimate = await this.getEstimate(this.paramsEstimateId)
        }
        if (estimate) {
          this.openInDialog(estimate)
        } else {
          this.paramsEstimateId = null
        }
      }
    },
    afterDeleteEstimate() {
      this.fetchData()
      this.dialog = false
    },
    addressLinkToProjectBoard(projectId) {
      return { path: `/dashboard/projects/${projectId}` }
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.paramsEstimateId = null
        this.dialogEstimate = null
      }
    },
  },
}
</script>

<style scoped></style>
