<template>
  <form-dialog title="Move to guesty partner" @submit="guestyUpdate">
    <template #activator="{ on, attrs }">
      <v-btn color="info" class="mx-1" v-bind="attrs" v-on="on">
        Guesty Partner
      </v-btn>
    </template>
    <v-text-field v-model="token" label="Integration Token" outlined dense />
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog.vue'
import axios from 'axios'

export default {
  name: 'GuestyPartner',
  components: { FormDialog },
  props: ['integrationId'],
  data() {
    return {
      token: '',
    }
  },
  methods: {
    async guestyUpdate() {
      this.$store.commit('updateLoading', true)
      await axios.post('/api/settings/integrations/guesty/partner', {
        token: this.token,
        integration_id: this.integrationId,
      })
      await this.$store.dispatch('getSettings')
      this.$store.commit('updateLoading', false)
    },
  },
}
</script>

<style scoped></style>
