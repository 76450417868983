<template>
  <v-sheet :max-height="maxHeight ? maxHeight : 1000" class="overflow-y-auto">
    <div class="d-flex flex-column-reverse">
      <LtComment
        v-for="(log, time) in logs"
        :key="time"
        class="bt-1 mt-1 pt-1"
        :time="time"
        :log="log"
      />
    </div>
  </v-sheet>
</template>

<script>
import LtComment from 'components/listing-tasks/lt-comment'
export default {
  name: 'LtLogs',
  components: { LtComment },
  props: ['logs', 'maxHeight'],
}
</script>

<style scoped></style>
