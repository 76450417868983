<template>
  <v-tabs
    v-model="paramsTab"
    icons-and-text
    class="tabs-menu main-background invetor-tab"
  >
    <v-tab href="#templates">
      Templates Flows
      <v-icon>mdi-sitemap-outline</v-icon>
    </v-tab>
    <v-tab href="#config">
      Config
      <v-icon>mdi-cog-outline</v-icon>
    </v-tab>
    <v-tabs-items v-model="paramsTab">
      <v-progress-linear
        v-show="listingLoading"
        color="secondary"
        style="margin: 0"
        absolute
        :indeterminate="true"
      />
      <v-tab-item value="templates" class="pl-2 pr-2" eager>
        <listing-task-templates />
      </v-tab-item>
      <v-tab-item value="config" class="pl-2 pr-2" eager>
        <automations-config />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ListingTaskTemplates from 'components/listing-tasks/listing-task-templates'
import AutomationsConfig from 'components/automations-config'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      showRuleSummary: false,
    }
  },
  components: {
    AutomationsConfig,
    ListingTaskTemplates,
  },
}
</script>
