<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43.543"
    height="28"
    viewBox="0 0 43.543 28"
  >
    <g id="speedometer" transform="translate(0 -91.38)">
      <g
        id="Group_12178"
        data-name="Group 12178"
        transform="translate(7.389 91.38)"
      >
        <g id="Group_12177" data-name="Group 12177">
          <path
            id="Path_7021"
            data-name="Path 7021"
            d="M86.882,96.815l7.256,7.256a11.351,11.351,0,0,1,5.851-2.422V91.38A21.565,21.565,0,0,0,86.882,96.815Z"
            transform="translate(-86.882 -91.38)"
            fill="rgba(74,140,140,0.2)"
          />
        </g>
      </g>
      <g
        id="Group_12180"
        data-name="Group 12180"
        transform="translate(0 98.619)"
      >
        <g id="Group_12179" data-name="Group 12179">
          <path
            id="Path_7022"
            data-name="Path 7022"
            d="M5.585,176.5A21.646,21.646,0,0,0,0,190.885a1.275,1.275,0,0,0,1.276,1.276H9.015a1.275,1.275,0,0,0,1.276-1.276,11.365,11.365,0,0,1,2.551-7.126Z"
            transform="translate(0 -176.502)"
            fill="rgba(74,140,140,0.2)"
          />
        </g>
      </g>
      <g
        id="Group_12182"
        data-name="Group 12182"
        transform="translate(16.669 102.709)"
      >
        <g id="Group_12181" data-name="Group 12181">
          <path
            id="Path_7023"
            data-name="Path 7023"
            d="M210.959,224.914a1.271,1.271,0,0,0-1.527-.116l-11.071,7.062a5.1,5.1,0,1,0,7.369,6.456l5.545-11.9A1.276,1.276,0,0,0,210.959,224.914Z"
            transform="translate(-196 -224.596)"
            fill="rgba(74,140,140,0.2)"
          />
        </g>
      </g>
      <g
        id="Group_12184"
        data-name="Group 12184"
        transform="translate(32.578 98.619)"
      >
        <g id="Group_12183" data-name="Group 12183">
          <path
            id="Path_7024"
            data-name="Path 7024"
            d="M388.448,176.5l-3.749,3.749a3.81,3.81,0,0,1,.047,3.233l-1.677,3.6a11.068,11.068,0,0,1,.675,3.8,1.275,1.275,0,0,0,1.276,1.276h7.739a1.275,1.275,0,0,0,1.276-1.276A21.646,21.646,0,0,0,388.448,176.5Z"
            transform="translate(-383.068 -176.502)"
            fill="rgba(74,140,140,0.2)"
          />
        </g>
      </g>
      <g
        id="Group_12186"
        data-name="Group 12186"
        transform="translate(23.047 91.38)"
      >
        <g id="Group_12185" data-name="Group 12185">
          <path
            id="Path_7025"
            data-name="Path 7025"
            d="M271,91.38v10.27a11.439,11.439,0,0,1,3.058.784l2.624-1.674a3.64,3.64,0,0,1,3.674-.194l3.751-3.751A21.565,21.565,0,0,0,271,91.38Z"
            transform="translate(-271 -91.38)"
            fill="rgba(74,140,140,0.2)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Speedometer',
}
</script>

<style scoped></style>
