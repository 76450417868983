<template>
  <v-sheet
    class="flex-grow-1 d-flex align-center justify-center p-relative"
    color="red lighten-5 error--text"
    height="30"
  >
    <slot name="customHeader" />
    <div v-if="!$scopedSlots.customHeader">
      <span class="close-btn"
        ><v-icon color="error" @click="hideVersionAlert">close</v-icon></span
      >
      <span class="text-caption text-sm-body-1"
        >{{ text }}
        <span
          class="font-weight-medium text-decoration-underline pointer"
          @click="refresh"
          >refresh</span
        ></span
      >
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'AppVersion',
  props: ['text'],
  methods: {
    refresh() {
      window.location.reload()
    },
    hideVersionAlert() {
      this.$store.commit('showVersionAlert', false)
    },
  },
}
</script>

<style scoped>
.close-btn {
  position: absolute;
  right: 10px;
}
</style>
