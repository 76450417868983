<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="cancel-4">
          <g id="Group_10280" data-name="Group 10280">
            <path
              id="Path_5234"
              data-name="Path 5234"
              class="cls-1"
              d="M11.15,10.05l8.66-8.67a.77.77,0,0,0-.1-1.1.78.78,0,0,0-1,0L10.05,9,1.38.28a.78.78,0,0,0-1.2,1,.47.47,0,0,0,.1.1l8.66,8.67L.28,18.72a.78.78,0,0,0,1.1,1.1l8.67-8.66,8.66,8.66a.78.78,0,0,0,1.1-.1.76.76,0,0,0,0-1Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Cancel',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
