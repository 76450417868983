<template>
  <v-container v-if="currentUser">
    <v-form ref="form" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" class="py-0">
          <v-row class="align-center justify-space-between">
            <v-col cols="auto">
              <div class="text-h6">Profile Setting</div>
              <div class="text-caption font-weight-medium secondary--text">
                Update your photo and personal details here
              </div>
            </v-col>
            <v-col v-if="dirty" cols="auto" class="ml-auto">
              <v-btn color="primary" small class="mx-1" outlined @click="cancel"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                elevation="0"
                small
                class="mx-1"
                type="submit"
                >Save
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="py-2"><v-divider /></v-col>

        <v-col cols="12">
          <v-row class="justify-space-between align-start">
            <v-col cols="12" md="auto" class="py-0 py-md-1">
              <div
                class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
              >
                Name
              </div>
            </v-col>
            <v-col cols="12" md="6" class="d-flex">
              <v-text-field
                v-model="userObj.first_name"
                dense
                outlined
                class="mr-1"
                :rules="[required]"
                label="First name"
                @change="dirtyForm"
              />
              <v-text-field
                v-model="userObj.last_name"
                dense
                outlined
                :rules="[required]"
                class="ml-1"
                label="Last name"
                @change="dirtyForm"
              />
            </v-col>
          </v-row>
          <v-row class="justify-space-between align-start">
            <v-col cols="12" md="auto" class="py-0 py-md-1">
              <div
                class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
              >
                Email
              </div>
            </v-col>
            <v-col cols="12" md="6" class="d-flex">
              <v-text-field
                dense
                outlined
                label="Email"
                disabled
                :value="currentUser.email"
                persistent-hint
                hint="* Ask your admin to change your email address"
                @change="dirtyForm"
              />
            </v-col>
          </v-row>
          <v-row class="justify-space-between align-start">
            <v-col cols="12" md="auto" class="py-0 py-md-1">
              <div
                class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
              >
                Phone Number
              </div>
            </v-col>
            <v-col cols="12" md="6" class="d-flex">
              <v-text-field
                v-model="userObj.phone"
                dense
                outlined
                label="Phone"
                @change="dirtyForm"
              />
            </v-col>
          </v-row>
          <v-row class="justify-space-between align-start">
            <v-col cols="12" md="auto" class="py-0 py-md-1">
              <div
                class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
              >
                Profile Pic
              </div>
            </v-col>
            <v-col cols="12" md="6" class="d-flex">
              <image-field
                :scale="150"
                :value.sync="userObj.picture"
                @change="dirtyForm"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ImageField from 'components/form-fields/image-field'
import { mapGetters } from 'vuex'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import pick from 'lodash/fp/pick'

export default {
  name: 'UserProfile',
  components: { ImageField },
  mixins: [FormRulesMixin],
  props: ['user'],
  data() {
    return {
      userObj: {},
      dirty: false,
      fields: ['first_name', 'last_name', 'phone', 'picture'],
    }
  },
  mounted() {
    this.userObj = { ...this.currentUser }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('users/updateProfile', {
          id: this.currentUser.id,
          ...pick(this.fields, this.userObj),
        })
        this.dirty = false
      }
    },
    dirtyForm() {
      this.dirty = true
    },
    cancel() {
      this.userObj = { ...this.currentUser }
      this.dirty = false
    },
  },
}
</script>

<style scoped></style>
