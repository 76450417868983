<template>
  <v-list-item
    :key="index"
    class="comp-wrapper mt-1"
    :class="optionListingVisted() ? 'super-light-red' : ''"
  >
    <v-layout wrap class="font-light">
      <v-flex xs12 class="bb-1 bolder2">
        <span v-if="!$vuetify.breakpoint.xs" class="small-text mr-1 cyan-icon">
          {{ item.task_type + ' | ' + item.sub_topic }}
        </span>
        <span class="small-text mr-1" :style="'color:' + statusColor(item)">{{
          item.status + ' |'
        }}</span>
        <span class="small-text purple-icon mr-1">{{
          itemListingNickname(item)
        }}</span>
        <span class="small-text">{{
          truncateBy(item.description, $vuetify.breakpoint.xs ? 20 : 55)
        }}</span>
      </v-flex>
      <v-flex xs12>
        <v-layout align-center>
          <v-flex xs4 class="light-grey">
            <v-layout column>
              <v-flex>
                {{ parseDate(item.switch_data.old_date) }}
              </v-flex>
              <v-flex class="small-text">
                {{ item.switch_data.old_contractor_name }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs1>
            <v-icon class="cyan-icon">arrow_right_alt</v-icon>
          </v-flex>
          <v-flex xs5 class="green lighten-5">
            <v-layout wrap>
              <v-flex xs2>
                <v-tooltip v-if="chosenContractorVisit" top>
                  <template #activator="{ on }">
                    <v-icon
                      style="color: lightcyan"
                      class="icon alt stats-icon mr-1"
                      v-on="on"
                      >fas fa-home</v-icon
                    ></template
                  >
                  <v-layout wrap style="min-width: 200px">
                    We have work being done in this house today.
                  </v-layout>
                </v-tooltip>
              </v-flex>
              <v-flex xs8>
                <v-layout column>
                  <v-flex>
                    {{ parseDate(item.switch_data.new_date) }}
                  </v-flex>
                  <v-flex class="small-text">
                    {{ chosenContractorName }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs2>
                <v-icon
                  v-if="optionalContractors.length > 1"
                  small
                  class="purple-icon"
                  @click="changeContractor"
                  >swap_vertical_circle</v-icon
                >
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex offset-xs1 xs2>
            <v-btn
              class="white--text"
              small
              outlined
              fab
              color="teal"
              @click="switchTask(item)"
            >
              <v-icon>done_outline</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-list-item>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['item', 'index', 'afterSaveFunc'],

  data() {
    return {
      chosenIndex: 0,
    }
  },
  computed: {
    optionalContractors() {
      return this.item.switch_data.new_contractors
    },
    chosenContractorId() {
      return this.optionalContractors[this.chosenIndex].id
    },
    chosenContractorName() {
      return this.optionalContractors[this.chosenIndex].name
    },
    chosenContractorVisit() {
      return this.optionalContractors[this.chosenIndex].listing_visited_today
    },
  },

  watch: {},
  methods: {
    changeContractor() {
      this.chosenIndex += 1
      if (this.chosenIndex >= this.optionalContractors.length) {
        this.chosenIndex = this.chosenIndex % this.optionalContractors.length
      }
    },
    statusColor(task) {
      if (task.status === 'Done' && !task.department && !task.manual_approved) {
        return '#c0a67e'
      } else if (task.status === 'In Progress') {
        return '#dfc12a'
      } else if (task.status === 'Done') {
        return '#75c547'
      } else {
        return 'red'
      }
    },
    optionListingVisted() {
      return this.chosenContractorVisit
    },
    switchTask(task) {
      const userConfirm = confirm('Are you sure you want to switch?')
      if (userConfirm) {
        let startDate = this.dateInSafari(
          window.$store.state.tasks_calendar.view.title
        )
        let date = this.$moment.utc(startDate).toISOString().substr(0, 10)

        axios
          .post(`/api/listing-tasks/switch/${task.id}`, {
            new_contractor_id: this.chosenContractorId,
            new_date: date,
          })
          .then(() => {
            this.$store.dispatch('getSuggestionsTasks', { date: date })
            this.afterSaveFunc()
          })
          .catch(error => {
            alert(error)
          })
      }
    },
  },
}
</script>
