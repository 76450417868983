<template>
  <div v-show="!loading">
    <div class="grey lighten-4">
      <v-container class="max-w-1600 pb-0">
        <v-tabs
          v-model="paramsTab"
          slider-color="white"
          background-color="#f6f6f7"
          active-class="white"
          :grow="isMobile"
        >
          <!--          <v-tab-->
          <!--            v-if="isDesignedVr && isAdmin"-->
          <!--            :disabled="!showModule('accounting')"-->
          <!--            href="#dash"-->
          <!--          >-->
          <!--            <v-icon class="mr-md-2">$activity</v-icon>-->
          <!--            <span class="d-none d-md-inline text-capitalize">Dash</span>-->
          <!--          </v-tab>-->
          <v-tab
            :disabled="
              !(
                showModule('accounting') &&
                showModule('ops') &&
                (isPropertyManager ||
                  isAdmin ||
                  isAccountant ||
                  isHotelReception)
              )
            "
            href="#contractors-payments"
          >
            <v-icon class="mr-md-2">$bill</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Contractors Payments')
            }}</span>
          </v-tab>
          <v-tab
            :disabled="
              !(
                hasAbility('listings-owners-payments') &&
                showModule('accounting')
              )
            "
            href="#listings-payments"
          >
            <v-icon class="mr-md-2">mdi-credit-card-marker-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Owners Payments')
            }}</span>
          </v-tab>
          <v-tab
            v-if="showModule('accounting') && hasAbility('management-payments')"
            href="#management-payments"
          >
            <v-icon class="mr-md-2">mdi-credit-card-chip-outline</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Management Payments')
            }}</span>
          </v-tab>
          <v-tab
            v-if="
              showModule('accounting') && hasAbility('listings-taxes-payments')
            "
            href="#taxes-payments"
          >
            <v-icon class="mr-md-2">mdi-cash-register</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Taxes Payments')
            }}</span>
          </v-tab>
          <v-tab
            v-if="showModule('accounting') && hasAbility('ach-transactions')"
            href="#ach-transactions"
          >
            <v-icon class="mr-md-2">mdi-cash-fast</v-icon>
            <span class="d-none d-md-inline text-capitalize">{{
              $t('Transfers')
            }}</span>
          </v-tab>
        </v-tabs>
      </v-container>
    </div>
    <div>
      <div>
        <v-tabs-items v-model="paramsTab" touchless>
          <v-tab-item
            v-if="
              isPropertyManager || isAdmin || isAccountant || isHotelReception
            "
            value="contractors-payments"
            class="pl-4 pr-4 main-background"
          >
            <listing-tasks-payments />
          </v-tab-item>
          <v-tab-item
            v-if="
              isAdmin || isAccountant || hasAbility('listings-owners-payments')
            "
            value="listings-payments"
            class="pl-4 pb-2 pr-4 main-background"
          >
            <listings-payments
              :natcha-support="config.is_natcha_support"
              :payment-period="config.listing_payment_period"
            />
          </v-tab-item>
          <v-tab-item
            v-if="isAdmin || isAccountant || hasAbility('management-payments')"
            value="management-payments"
            class="pl-4 pr-4 main-background"
          >
            <management-payments :natcha-support="config.is_natcha_support" />
          </v-tab-item>
          <v-tab-item
            v-if="isAdmin || isAccountant || hasAbility('ach-transactions')"
            value="ach-transactions"
            class="pl-4 pr-4 main-background"
          >
            <ach-transactions v-if="paramsTab === 'ach-transactions'" />
          </v-tab-item>
          <v-tab-item
            v-if="isAdmin || isAccountant || hasAbility('management-payments')"
            value="taxes-payments"
            class="pl-4 pr-4 main-background"
          >
            <taxes-payments />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import ListingTasksPayments from 'components/listing-tasks-payments'
import ListingsPayments from 'components/listings-payments'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import AchTransactions from 'components/accounting/ach-transactions'
import ManagementPayments from 'components/management-payments'
import DeviceMixin from 'components/mixins/device-mixin'
import TaxesPayments from 'components/accounting/taxes-payments'

export default {
  components: {
    ManagementPayments,
    AchTransactions,
    ListingsPayments,
    ListingTasksPayments,
    TaxesPayments,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  computed: {
    hasBankAccounts() {
      return !isEmpty(get('bank_accounts_numbers', this.$store.state.user))
    },
    config() {
      return this.$store.state.app.configuration
    },
  },
}
</script>
