<template>
  <v-menu offset-y min-width="280">
    <template #activator="{ on, attrs }">
      <v-btn
        class="max-w-200"
        color="info"
        dark
        v-bind="attrs"
        small
        elevation="0"
        v-on="on"
      >
        <span class="text-capitalize"> Other tasks today</span>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.id"
        :href="`/dashboard/listing-task/${item.id}`"
      >
        <v-list-item-content>
          <v-list-item-title>
            <span class="text-body-2">{{ item.description }}</span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-chip :color="listingTaskStatusColor(item.status)" small>
            {{ item.status }}
          </v-chip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
export default {
  name: 'LtSameDayTasks',
  mixins: [CommonFunctions],
  props: {
    items: Array,
  },
}
</script>
