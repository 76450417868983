export const HOTJAR_EVENTS = {
  VIEW_ITEM: 'view_item',
  STORE_PAGE_OPENED: 'store_page_opened',
  COMPOSE_ORDER_ATTEMPT: 'compose_order_attempt',
  COMPOSE_ORDER_SUCCESSFUL: 'compose_order_successful',
  PAYMENT_ATTEMPT: 'payment_attempt',
  PAYMENT_SUCCESSFUL: 'payment_successful',
}

export default {
  identify(user, params = {}) {
    window.hj('identify', user.id, {
      email: user.email,
      role: user.role,
      ...params,
    })
  },
  event(event_name) {
    window.hj('event', event_name)
  },
}
