<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.22 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="_005-promotion" data-name=" 005-promotion">
          <path
            id="Path_5098"
            data-name="Path 5098"
            class="cls-1"
            d="M6.19,14a.45.45,0,0,0-.45-.79.46.46,0,0,0-.17.62h0A.46.46,0,0,0,6.19,14Z"
          />
          <path
            id="Path_5099"
            data-name="Path 5099"
            class="cls-1"
            d="M4.33,17.14l2.29,4a1.81,1.81,0,0,0,3.14-1.82L8.4,16.92l1.18-.68a.46.46,0,0,0,.17-.62l-.59-1,8.7-1.71a1.36,1.36,0,0,0,1.28-1.44,1.4,1.4,0,0,0-.18-.6L17.45,8.24l1-1.47a.44.44,0,0,0,0-.47l-.91-1.58a.47.47,0,0,0-.42-.22l-1.75.1L13.68,1.7A1.34,1.34,0,0,0,12.51,1h0a1.32,1.32,0,0,0-1.15.63L5.42,9.2,1.56,11.43a3.18,3.18,0,0,0,2.77,5.7ZM9,19.74a.91.91,0,1,1-1.58.9L5.13,16.71l1.58-.91Zm-1-3.6-.46-.79.79-.45.45.78ZM16.87,5.42l.63,1.09-.56.85L15.86,5.48ZM12.11,2.15A.45.45,0,0,1,12.73,2a.44.44,0,0,1,.16.17l5.28,9.14a.45.45,0,0,1-.16.62.43.43,0,0,1-.22.06l-.25,0L12,2.35l.16-.2Zm-.76,1,5.25,9.09L8.67,13.76,6.27,9.59ZM1.19,15.32A2.26,2.26,0,0,1,2,12.22H2l3.54-2.05,2.27,3.94-3.54,2A2.27,2.27,0,0,1,1.19,15.32Z"
          />
          <path
            id="Path_5100"
            data-name="Path 5100"
            class="cls-1"
            d="M4.79,14.29a.46.46,0,0,0-.62-.17l-.79.46a.46.46,0,0,1-.62-.17.45.45,0,0,0-.79.45,1.35,1.35,0,0,0,1.86.5l.79-.45a.46.46,0,0,0,.17-.62Z"
          />
          <path
            id="Path_5101"
            data-name="Path 5101"
            class="cls-1"
            d="M22.55,2,20,3.4a.45.45,0,1,0,.44.79L23,2.78a.45.45,0,0,0,.18-.61A.46.46,0,0,0,22.55,2Z"
          />
          <path
            id="Path_5102"
            data-name="Path 5102"
            class="cls-1"
            d="M22.28,6.69l-1.75-.47a.47.47,0,0,0-.56.32.45.45,0,0,0,.31.56h0l1.76.47a.45.45,0,1,0,.24-.87Z"
          />
          <path
            id="Path_5103"
            data-name="Path 5103"
            class="cls-1"
            d="M17.87.34,17.4,2.1a.45.45,0,0,0,.88.23L18.75.58a.46.46,0,0,0-.88-.25Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Promotion',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
