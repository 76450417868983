<template>
  <div>
    <v-menu
      v-if="!isProjectPage"
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template #activator="{ on }">
        <v-text-field
          v-model="date"
          label="Date"
          persistent-hint
          prepend-icon="event"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        @input="menu1 = false"
      ></v-date-picker>
    </v-menu>
    <v-select
      v-if="!listType && isProjectPage"
      v-model="projectName"
      :items="projectNames"
      placeholder="Project name"
      clearable
    ></v-select>
    <v-row>
      <v-col cols="6">
        <v-switch
          v-if="!listType && !(isContractor && !onPayroleContractor)"
          v-model="onlyMy"
          clearable
          label="My tasks only"
        ></v-switch>
      </v-col>
      <v-col v-if="hasTeam" cols="6">
        <v-checkbox
          v-if="!listType && !(isContractor && !onPayroleContractor)"
          clearable
          label="My team tasks"
          @change="showMyTeamChange"
        ></v-checkbox>
      </v-col>
    </v-row>

    <contractor-select
      v-if="managementRoles && !listType && !isProjectPage"
      :allow-multiple="true"
      :filter-change="contractorFilterChange"
    ></contractor-select>
    <v-select
      v-if="managementRoles && !listType && !isProjectPage"
      v-model="chosenCategories"
      :items="categories"
      label="Department"
      chips
      tags
      multiple
      @change="chosenFilterChange = true"
    ></v-select>

    <listing-task-module
      v-if="!loading && !listingLoading && canDoListingTasks"
    />

    <v-layout
      v-if="projectStats && (isAdmin || isPropertyManager)"
      wrap
      class="center-text"
      justify-center
      align-center
    >
      <v-flex xs12 sm4 mt-2>
        <v-btn
          style="background-color: red"
          class="rounded-card"
          x-large
          dark
          @click="showStatus = 'New'"
        >
          New ({{ projectStats.in_new }})
        </v-btn>
      </v-flex>
      <v-flex xs12 sm4 mt-2>
        <v-btn
          style="background-color: #dfc12a"
          class="rounded-card"
          x-large
          dark
          @click="showStatus = 'In Progress'"
        >
          In-P ({{ projectStats.in_progress }})
        </v-btn>
      </v-flex>
      <v-flex xs12 sm4 mt-2>
        <v-btn
          style="background-color: #75c547"
          class="rounded-card"
          x-large
          dark
          @click="showStatus = 'Done'"
        >
          Done ({{ projectStats.done }})
        </v-btn>
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="headers || []"
      :items="filteredItems"
      class="elevation-1"
      :options.sync="pagination"
    >
      <template #item="{ item }">
        <tr :key="item.id" :style="{ border: '1px solid #7ebac0 !important' }">
          <lt-check-item
            :first-rank="
              !isEmpty(filteredItems) ? filteredItems[0].current_rank : 0
            "
            :last-rank="
              !isEmpty(filteredItems)
                ? filteredItems[filteredItems.length - 1].current_rank
                : 0
            "
            :show-order="orderTasksBy == 'current_rank'"
            :after-change="getCurrentListingTasks"
            :prev-rank="prevRank(item)"
            :current-index="filteredItems.indexOf(item) + 1"
            :next-rank="nextRank(item)"
            :item="item"
          />
        </tr>
      </template>
    </v-data-table>
    <listing-task-map
      v-if="!isEmpty(filteredItems)"
      class="mt-5"
      :tasks="filteredItems"
    />
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import LtCheckItem from 'components/lt-check-item'

import ListingTaskModule from 'components/listing-task-module'
import ContractorSelect from 'components/contractor-select'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import ListingTaskMap from 'components/listing-task-map'
import { mapGetters } from 'vuex'

export default {
  components: {
    ListingTaskMap,
    ContractorSelect,
    LtCheckItem,
    ListingTaskModule,
  },
  mixins: [CommonFunctions, PermissionsMixin],

  props: ['listType', 'filterFunc', 'isProjectPage'],
  data() {
    return {
      showStatus: null,
      menu1: false,
      orderTasksBy: this.listType ? 'rank_at_date' : 'current_rank',
      chosenCategories: null,
      contractorID: null,
      showMyTeam: false,
      chosenFilterChange: false,
      date: new Date().toISOString().substr(0, 10),
      projectName: null,
      onlyMy: !this.listType && !this.isProjectPage,
      headers: [{ text: 'Tasks', align: 'center', sortable: false }],
      pagination: {
        itemsPerPage: 300,
      },
    }
  },
  computed: {
    ...mapGetters(['projectNames']),
    listingTasks() {
      return this.$store.state.listingTasks
    },
    generateStats() {
      return {
        in_new: filter(this.listingTasks, task => task.status === 'New').length,
        in_progress: filter(
          this.listingTasks,
          task => task.status === 'In Progress'
        ).length,
        done: filter(this.listingTasks, task => task.status === 'Done').length,
      }
    },
    categories() {
      return [...new Set(this.listingTasks.map(item => item.task_type))]
    },
    projectStats() {
      return this.$store.state.projectStats || this.generateStats
    },
    parsedFrom() {
      const parsedDate = this.$moment(this.date)
      if (this.date) {
        return parsedDate.toISOString().substr(0, 10)
      }
      return ''
    },
    filteredItems() {
      let items = []

      this.listingTasks.forEach(item => {
        if (
          this.showStatus &&
          this.showStatus != item.status &&
          this.sub_status != item.status
        ) {
          return false
        }
        if (this.onlyMy && item.assigned_contractor_id !== this.getMyUserId) {
          return false
        }
        if (
          !isEmpty(this.chosenCategories) &&
          !this.chosenCategories.includes(item.task_type)
        ) {
          return false
        }
        if (
          this.contractorID &&
          !this.contractorID.includes(item.assigned_contractor_id)
        ) {
          return false
        }
        if (this.filterFunc && this.filterFunc(item)) {
          items.push(item)
        } else if (!this.filterFunc) {
          items.push(item)
        }
      })
      return orderBy(items, this.orderTasksBy)
    },
  },

  watch: {
    date(val) {
      this.date = val
      this.getCurrentListingTasks()
    },
    projectName(val) {
      this.projectName = val
      this.getCurrentListingTasks()
    },
  },
  mounted() {
    this.getCurrentListingTasks()
  },
  methods: {
    showMyTeamChange(val) {
      this.onlyMy = false
      this.showMyTeam = val
      this.getCurrentListingTasks()
    },
    changeOrder(val) {
      if (!val) {
        this.orderTasksBy = 'current_rank'
      } else {
        this.orderTasksBy = 'rank_at_date'
      }
    },
    prevRank(task) {
      const index = this.filteredItems.indexOf(task)
      if (index > 0) {
        return this.filteredItems[index - 1].current_rank
      } else {
        return null
      }
    },
    nextRank(task) {
      const index = this.filteredItems.indexOf(task)
      if (index < this.filteredItems.length - 1) {
        return this.filteredItems[index + 1].current_rank
      } else {
        return null
      }
    },
    contractorFilterChange(contractorID) {
      if (contractorID.length === 0) {
        this.contractorID = null
      } else {
        this.contractorID = contractorID
      }
    },
    getCurrentListingTasks() {
      let startDate = this.date
      let date = this.$moment.utc(startDate)
      let endDate = this.$moment.utc(startDate).add(1, 'days')
      const user = this.$store.state.user
      this.$store.dispatch('getListingTasks', {
        filters: {
          from: date.toISOString().substr(0, 10),
          to: endDate.toISOString().substr(0, 10),
          status: 'All',
          team_id: this.showMyTeam ? user.team_id : null,
          type: this.listType || 'All',
          project_name:
            this.projectName ||
            (this.isProjectPage ? 'Temp place holder' : null),
          listing_id: null,
          saveToCal: false,
          saveToList: true,
          getPropertyTasks: false,
        },
      })
    },
  },
}
</script>
