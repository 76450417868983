<template>
  <v-tooltip v-if="reason" bottom>
    <template #activator="{ on, attrs }">
      <div class="d-flex align-center" v-bind="attrs" v-on="on">
        <v-icon :color="alertColor" left small>
          {{ reasonIcon }}
        </v-icon>
        <span :class="`text-caption ${alertColor}--text`">{{ reason }}</span>
      </div>
    </template>
    <span class="text-caption">{{ reason }}</span>
  </v-tooltip>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'ChatAlert',
  mixins: [CommonFunctions],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    alertColor() {
      return this.item.alert_reason === 'follow_up' ? 'success' : 'error'
    },
    reason() {
      return this.item.alert_reason === 'noise'
        ? 'Noise'
        : this.item.alert_reason === 'behind' && this.item.follow_up_at
        ? this.timeFromNow(this.item.follow_up_at)
        : this.item.alert_reason === 'follow_up' && this.item.follow_up_at
        ? this.timeFromNow(this.item.follow_up_at)
        : ''
    },
    reasonIcon() {
      return this.item.alert_reason === 'noise'
        ? 'mdi-volume-high'
        : 'mdi-alarm'
    },
  },
}
</script>
