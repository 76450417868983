<template>
  <v-data-table
    :headers="filteredHeaders"
    :items="items"
    fixed-header
    height="70vh"
    disable-pagination
    disable-sort
    :loading="isLoading"
    hide-default-footer
    @click:row="clickHandler"
  >
    <template
      v-for="header in filteredHeaders"
      #[getSlotName(header)]="{ item }"
    >
      <v-icon
        v-if="header.cellType === cellTypes.CHECK"
        :key="item[header.value]"
        small
        :color="item[header.value] ? 'success' : 'warning'"
      >
        {{ item[header.value] ? 'check' : 'close' }}
      </v-icon>
      <a
        v-else-if="header.cellType === cellTypes.LINK && item[header.urlKey]"
        :key="`${item[header.value]}_link`"
        target="_blank"
        :href="item[header.urlKey]"
        class="black--text"
      >
        {{ item.address || 'Link' }}</a
      >
      <span
        v-else-if="header.cellType === cellTypes.COMPUTED"
        :key="item[header.text]"
        >{{ header.value(item) }}</span
      >
      <v-btn
        v-else-if="header.cellType === cellTypes.ACTION"
        :key="item[header.value]"
        icon
        @click.stop="actionHandler(header.value, item)"
      >
        <v-icon small> {{ header.icon }}</v-icon>
      </v-btn>
      <confirmation-modal
        v-else-if="header.cellType === cellTypes.CONFIRMED_ACTION"
        :key="item[header.value]"
        :text="`${header.confirmationText} ${item[header.label] || item.name}?`"
        @action="actionHandler(header.value, item)"
      >
        <template #activator="{ on }">
          <v-btn icon small v-on="on"> <v-icon small> $trash</v-icon></v-btn>
        </template>
      </confirmation-modal>
      <v-avatar
        v-else-if="header.cellType === cellTypes.IMAGE"
        :key="item[header.value]"
        size="32"
      >
        <img :src="getPathValue(item, header.value)" />
      </v-avatar>
      <span v-else :key="`${item[header.value]}_modal`" class="black--text">
        {{ formattedField(item, header) }}</span
      >
    </template>
  </v-data-table>
</template>

<script>
import { MANAGMENT_TABLE_TYPES } from '@/consts'
import CommonFunctions from 'components/mixins/common_functions'
import ConfirmationModal from 'components/modals/confirmation-modal'
import get from 'lodash/get'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'DataTable',
  components: { ConfirmationModal },
  mixins: [CommonFunctions, FormattersMixin],
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      cellTypes: MANAGMENT_TABLE_TYPES,
    }
  },
  computed: {
    filteredHeaders() {
      return (this.headers || []).filter(h => !h.hideInTable)
    },
  },
  methods: {
    getSlotName(header) {
      return `item.${header.value}`
    },
    clickHandler(item) {
      this.$emit('onRowClick', item)
    },
    actionHandler(action, item) {
      this.$emit(action, item)
    },
    formattedField(item, header) {
      const val = item[header.value] || this.getPathValue(item, header.value)
      if (!val) return '-'
      if (header.fieldType === 'date') {
        return this.parseDateWithTimeIfPresent(val)
      }
      if (header.isCurrency) return this.dollarFormatter(val)
      return val
    },
    getPathValue(item, path) {
      return get(item, path)
    },
  },
}
</script>
