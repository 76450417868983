import axios from 'axios'
import { unionBy } from 'lodash'
import Toaster from '@/utils/toaster'
import Websocket from '@/utils/websocket'
const inquerySound = require('@/assets/sounds/beep.wav')
const audio = new Audio(inquerySound)
export default {
  state: {
    subNotificationsChannel: null,
    subInquiryChannel: null,
    notifications: [],
    notificationsPagination: {},
  },
  mutations: {
    updateSubNotificationsChannel(state, data) {
      state.subNotificationsChannel = data
    },
    updateSubInquiryChannel(state, data) {
      state.subInquiryChannel = data
    },
    updateNotifications(state, data) {
      state.notifications = data
    },
    unionNotifications(state, data) {
      state.notifications = unionBy(state.notifications, data, 'id')
    },
    preUnionNotifications(state, data) {
      state.notifications = unionBy(data, state.notifications, 'id')
    },
    updateNotificationsPagination(state, data) {
      state.notificationsPagination = data
    },
  },
  getters: {
    notifications: state => state.notifications,
    notificationsPagination: state => state.notificationsPagination,
  },
  actions: {
    async getUserNotifications({ commit }, { page = 1, per_page = 20 } = {}) {
      axios
        .get(`/api/notifications`, { params: { page, per_page } })
        .then(response => {
          commit('unionNotifications', response.data.data)
          commit('updateNotificationsPagination', response.data.pagi_info)
        })
        .catch(console.error)
    },
    async getMoreUserNotifications({ dispatch, state }) {
      const page = state.notificationsPagination.page + 1
      const per_page = state.notificationsPagination.per_page
      dispatch('getUserNotifications', { page, per_page })
    },
    async refreshNotifications({ commit, state }) {
      axios
        .get(
          `/api/notifications?page=1&&per_page=${state.notifications.length}`
        )
        .then(response => {
          commit('updateNotifications', response.data.data)
        })
        .catch(console.error)
    },
    async markNotificationAsRead(store, notificationId) {
      axios
        .post(`/api/notifications/mark-as-read?id=${notificationId}`)
        .then(() => store.dispatch('refreshNotifications'))
        .catch(console.error)
    },
    async markAllNotificationsAsRead(store) {
      axios
        .post(`/api/notifications/mark-as-read`)
        .then(() => store.dispatch('refreshNotifications'))
        .catch(console.error)
    },
    async subscribeToNotificationsChannel(
      { rootGetters, dispatch, commit },
      userId
    ) {
      if (userId) {
        const handler = (event, data) => {
          if (event === 'ws-new-notification') {
            const author = rootGetters['users/usersMap'][data.actor_id] || {}
            const snackbar = {
              user: { name: author.name, image: author.picture },
              text: data.notify_type,
              link: data.link,
              onClick: () => dispatch('markNotificationAsRead', data.id),
            }
            Toaster.show([snackbar])
            commit('preUnionNotifications', [data])
          }
        }
        const sub = Websocket.subscribeToNotificationsChannel(handler, userId)
        commit('updateSubNotificationsChannel', sub)
      }
    },
    async unsubscribeFromNotificationsChannel({ state, commit }) {
      Websocket.unsubscribe(state.subNotificationsChannel)
      commit('updateSubNotificationsChannel', null)
    },
    async subscribeToInquiryChannel({ commit }) {
      const handler = event => {
        if (event === 'ws-new-inquiry') {
          audio.play()
        }
      }
      const sub = Websocket.subscribeToInquiryChannel(handler)
      commit('updateSubInquiryChannel', sub)
    },
    async unsubscribeFromInquiryChannel({ state, commit }) {
      Websocket.unsubscribe(state.subInquiryChannel)
      commit('updateSubInquiryChannel', null)
    },
  },
}
