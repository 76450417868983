<template>
  <v-card class="sales-intelligence mb-4" elevation="1">
    <v-card-title class="d-flex align-center py-3 px-4">
      <div class="d-flex align-center">
        <v-icon left color="primary" size="20">mdi-chart-box</v-icon>
        <span class="text-h6">Sales Intelligence</span>
      </div>
    </v-card-title>

    <v-card-text class="px-4 pt-4">
      <!-- BANT Qualification -->
      <div>
        <div class="d-flex align-center mb-4">
          <v-icon small color="grey darken-1" class="mr-2">mdi-target</v-icon>
          <span class="text-subtitle-1 font-weight-medium"
            >BANT Qualification</span
          >
        </div>

        <v-row dense>
          <v-col cols="6">
            <v-card class="rounded-lg h-100" outlined>
              <div class="pa-4">
                <div class="text-caption grey--text mb-1">Budget</div>
                <div class="text-subtitle-2 font-weight-bold">
                  {{ salesInsights?.bant_qualification?.budget || 'N/A' }}
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="rounded-lg h-100" outlined>
              <div class="pa-4">
                <div class="text-caption grey--text mb-1">Need</div>
                <div class="text-subtitle-2 success--text font-weight-medium">
                  {{ salesInsights?.bant_qualification?.need || 'N/A' }}
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="rounded-lg h-100" outlined>
              <div class="pa-4">
                <div class="text-caption grey--text mb-1">Authority</div>
                <div class="text-subtitle-2 font-weight-medium">
                  {{ salesInsights?.bant_qualification?.authority || 'N/A' }}
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card class="rounded-lg h-100" outlined>
              <div class="pa-4">
                <div class="text-caption grey--text mb-1">Timeline</div>
                <div
                  class="text-subtitle-2 success--text text--darken-2 font-weight-medium"
                >
                  {{ salesInsights?.bant_qualification?.timeline || 'N/A' }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <!-- Sentiment & Urgency -->
      <v-row dense class="mb-6">
        <v-col cols="6">
          <v-card class="rounded-lg" outlined>
            <div class="pa-4">
              <div class="text-caption grey--text mb-1">Sentiment</div>
              <div
                class="text-subtitle-1 grey--text text--darken-1 font-weight-medium"
              >
                {{ salesInsights?.sentiment || 'Neutral' }}
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card class="rounded-lg" outlined>
            <div class="pa-4">
              <div class="text-caption grey--text mb-1">Urgency</div>
              <div class="text-subtitle-1 error--text font-weight-medium">
                {{ salesInsights?.urgency || 'High' }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <!-- Buying Signals -->
      <div class="mb-6">
        <div class="d-flex align-center mb-3">
          <v-icon small color="#2E7D32" class="mr-2">mdi-arrow-right</v-icon>
          <span
            class="text-subtitle-2 font-weight-medium"
            style="color: #2e7d32"
          >
            Buying Signals
          </span>
        </div>
        <div v-if="salesInsights?.buying_signals?.length" class="pl-4">
          <div
            v-for="(signal, index) in salesInsights.buying_signals"
            :key="index"
            class="text-body-2 mb-2"
            style="color: #2e7d32"
          >
            • {{ signal }}
          </div>
        </div>
        <div v-else class="text-body-2 pl-4" style="color: #2e7d32">
          • Defined budget and timeline
        </div>
      </div>

      <!-- Objections -->
      <div class="mb-6">
        <div class="d-flex align-center mb-3">
          <v-icon small color="#D32F2F" class="mr-2">mdi-alert-circle</v-icon>
          <span
            class="text-subtitle-2 font-weight-medium"
            style="color: #d32f2f"
          >
            Objections
          </span>
        </div>
        <div v-if="salesInsights?.objections?.length" class="pl-4">
          <div
            v-for="(objection, index) in salesInsights.objections"
            :key="index"
            class="text-body-2 mb-2"
          >
            • {{ objection }}
          </div>
        </div>
        <div v-else class="text-body-2 grey--text text--darken-1 pl-4">
          No objections raised
        </div>
      </div>

      <!-- Recommended Next Steps -->
      <div class="mb-6">
        <div class="d-flex align-center mb-3">
          <v-icon small color="#1976D2" class="mr-2">mdi-arrow-right</v-icon>
          <span
            class="text-subtitle-2 font-weight-medium"
            style="color: #1976d2"
          >
            Recommended Next Steps
          </span>
        </div>
        <div class="pl-4">
          <div
            v-for="(step, index) in salesInsights.next_steps"
            :key="index"
            class="text-body-2 mb-2"
            style="color: #1976d2"
          >
            • {{ step }}
          </div>
        </div>
      </div>

      <!-- Risk Factors -->
      <div>
        <div class="d-flex align-center mb-3">
          <v-icon small color="#C62828" class="mr-2">mdi-alert</v-icon>
          <span
            class="text-subtitle-2 font-weight-medium"
            style="color: #c62828"
          >
            Risk Factors
          </span>
        </div>
        <div class="pl-4">
          <div
            v-for="(factor, index) in salesInsights.risk_factors"
            :key="index"
            class="text-body-2 mb-2"
            style="color: #c62828"
          >
            • {{ factor }}
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'SalesIntelligencePanel',
  mixins: [FormattersMixin],
  props: {
    salesInsights: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped>
.sales-intelligence {
  overflow-y: auto;
  background-color: white !important;
}

:deep(.v-card) {
  border-radius: 12px !important;
}

:deep(.v-card.rounded-lg) {
  border-radius: 8px !important;
  transition: all 0.2s ease;
}

:deep(.v-card.rounded-lg:hover) {
  border-color: var(--v-primary-base);
  transform: translateY(-1px);
}

.text-subtitle-2 {
  font-size: 0.875rem !important;
  line-height: 1.375rem;
}

.text-body-2 {
  font-size: 0.8125rem !important;
  line-height: 1.25rem;
}
</style>
