<template>
  <v-container
    v-if="currentListingTask"
    :class="{ 'pa-5': isMobileOrIPad }"
    :fluid="isMobileOrIPad"
  >
    <v-row>
      <v-col cols="12" md="8" class="d-flex flex-column">
        <LtTitle :listing-task="currentListingTask" />
        <CatalogItemInlineEditor :listing-task="currentListingTask" />
        <EditableTextField
          label="Description"
          placeholder="Add description..."
          class="mt-2"
          type="textarea"
          :value="currentListingTask.description"
          :disabled="isContractor"
          @onupdate="changeDescription"
        />
        <EditableTextField
          :label="$t('Internal Comments')"
          type="textarea"
          class="mt-2"
          :disabled="isContractor"
          :placeholder="$t('Add your comments here')"
          :value="currentListingTask.internal_comments"
          @onupdate="commentInternal"
        />
        <div v-if="provider" class="text-subtitle-1 mt-2 font-weight-medium">
          Provider Details
        </div>
        <div v-if="provider" class="text-body-1">
          <div>
            <span>Name:</span> <span>{{ provider.name }}</span>
          </div>
          <div>
            <span>Email:</span> <span>{{ provider.email }}</span>
          </div>
          <div>
            <span>Phone:</span> <span>{{ provider.phone }}</span>
          </div>
        </div>
        <div>
          <div class="text-subtitle-1 font-weight-medium">
            {{ $t('Description Images') }}
          </div>
          <gallery
            folder="service-calls"
            :thumbnails="true"
            :listing-id="currentListingTask.listing_id"
            :images="currentListingTask.desc_images"
            :remove-img="removeDescImg"
            :rotate-image="rotateDescImg"
            :on-change="addDescImages"
            :uploadable="true"
            :readonly="isContractor"
            :cols="3"
          />
        </div>
        <LtMetadata v-if="isMobileOrIPad" :listing-task="currentListingTask" />
        <v-divider class="my-3" />
        <ListingTaskExpansionPanels :listing-task="currentListingTask" />
      </v-col>
      <v-col cols="12" md="4" class="p-relative">
        <LtMetadata v-if="!isMobileOrIPad" :listing-task="currentListingTask" />
        <v-divider class="my-2" />
        <LtStoreSummary :listing-task="currentListingTask" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LtMetadata from 'components/listing-tasks/lt-metadata'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import deviceMixin from 'components/mixins/device-mixin'
import EditableTextField from 'components/form-fields/editable-text-field'
import get from 'lodash/fp/get'
import Gallery from 'components/common/gallery'
import LtTitle from 'components/listing-tasks/lt-title'
import ListingTaskExpansionPanels from 'components/listing-tasks/listing-task-expansion-panels'
import CatalogItemInlineEditor from 'components/listing-tasks/catalog-item-inline-editor'
import LtStoreSummary from 'components/listing-tasks/single-listing-task/lt-store-summary'
import { mapGetters } from 'vuex'
import ExpoNotifications from '@/utils/expoNotifications'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'

export default {
  name: 'ListingTaskStoreView',
  components: {
    LtStoreSummary,
    CatalogItemInlineEditor,
    ListingTaskExpansionPanels,
    LtTitle,
    Gallery,
    EditableTextField,
    LtMetadata,
  },
  mixins: [
    CommonFunctions,
    deviceMixin,
    PermissionsMixin,
    ListingTaskViewMixin,
  ],
  async mounted() {
    const { id } = this.$route.params
    await this.$store.dispatch('getListingTask', { id, router: this.$router })
  },
  computed: {
    ...mapGetters('users', ['usersMap']),
    ...mapGetters(['currentListingTask']),
    provider() {
      const providerId = get(
        'store_item_purchase.store_item_menu.contractor_id',
        this.currentListingTask
      )
      return this.usersMap[providerId]
    },
    storePurchaseRequest() {
      return (
        get('intent.metadata.third_party', this.currentListingTask) === 'true'
      )
    },
  },
}
</script>

<style scoped>
div >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
