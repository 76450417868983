<template>
  <v-stepper-content :step="step" class="pa-1">
    <div class="pa-2 white">
      <div v-for="listSubject in listSubjects" :key="listSubject">
        <lt-subtask-list
          :title="listSubject"
          class="mt-2"
          :listing-task="currentListingTask"
          :tasks="filterBySubject(listSubject)"
        />
      </div>
    </div>
  </v-stepper-content>
</template>

<script>
import LtSubtaskList from 'components/listing-tasks/lt-subtask-list.vue'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import compact from 'lodash/compact'
import Common_functions from 'components/mixins/common_functions'

export default {
  name: 'ChecklistStep',
  mixins: [ListingTaskViewMixin, Common_functions],
  components: { LtSubtaskList },
  props: ['step'],
  computed: {
    listSubjects() {
      let subjects = []
      if (this.hasSubTasks) {
        subjects = uniq(map(this.currentListingTask.sub_tasks, 'list_subject'))
        if (subjects.includes(null)) {
          subjects.push('Sub Tasks')
        }
      }
      return compact(subjects).sort().reverse()
    },
    isValid() {
      return this.subTasksDone
    },
  },
}
</script>

<style scoped></style>
