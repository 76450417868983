<template>
  <v-autocomplete
    v-model="selectedItem"
    :items="items"
    label="Select topic & subtopic"
    :filter="customFilter"
    item-value="id"
    clearable
    outlined
    dense
    @change="onChange"
  >
    <template #selection="{ item }">
      <span
        v-if="item.is_required_unit || item.is_required_house"
        class="warning--text mr-1"
      >
        *
      </span>
      <span
        >{{ item.topic }} <v-icon class="mx-2">mdi-chevron-right</v-icon></span
      >
      <span>
        {{ item.sub_topic }}
        <v-icon v-if="item.hint" class="mx-2">mdi-chevron-right</v-icon></span
      >
      <span>{{ item.hint }}</span>
    </template>
    <template #item="{ item }" class="listing-picker mb-1 bb-1 pb-1">
      <span
        v-if="item.is_required_unit || item.is_required_house"
        class="warning--text mr-1"
      >
        *
      </span>
      <span
        >{{ item.topic }}
        <v-icon class="mx-2" small>mdi-chevron-right</v-icon></span
      >
      <span>
        {{ item.sub_topic
        }}<v-icon v-if="item.hint" class="mx-2">mdi-chevron-right</v-icon></span
      >
      <span>{{ item.hint }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import find from 'lodash/find'
import { mapState } from 'vuex'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['filterChange', 'preSelected'],

  data() {
    return {
      selectedItem: this.preSelected,
      search: null,
    }
  },
  methods: {
    customFilter(item, queryText) {
      return (
        (item.topic &&
          item.topic.toLowerCase().includes(queryText.toLowerCase())) ||
        (item.sub_topic &&
          item.sub_topic.toLowerCase().includes(queryText.toLowerCase()))
      )
    },
    onChange(val) {
      if (val) {
        const res = find(this.items, ['id', val])
        this.filterChange(res)
      }
    },
  },
  computed: {
    ...mapState({
      items: state => state.listingInfoDef.listingInfoDefs,
    }),
  },
}
</script>
