<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    offset-y
    :close-on-content-click="shouldCloseOnClick"
    min-width="290px"
    :required="required"
  >
    <template #activator="{ on }">
      <v-btn v-if="icon" icon color="info" small>
        <v-icon small v-on="on"> fas fa-calendar-alt</v-icon></v-btn
      >
      <v-text-field
        v-else
        :clearable="clearable"
        :value="formattedDate"
        :label="label"
        :outlined="outlined !== false"
        :rules="rules"
        dense
        :hide-details="hideDetails"
        :flat="flat"
        :solo="solo"
        :disabled="disabled"
        readonly
        :background-color="textFieldBackground"
        :class="textFieldClass"
        v-on="on"
        @input="inputChange"
      >
        <template #prepend-inner>
          <slot name="prepend-inner" />
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="currDate"
      :range="range"
      :event-color="eventColor"
      :allowed-dates="allowedDates"
      :events="events"
      :min="min"
      :max="max"
      :reactive="reactive"
      @change="onChange"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: [
    'value',
    'hideDetails',
    'flat',
    'solo',
    'min',
    'max',
    'rules',
    'label',
    'range',
    'allowedDates',
    'events',
    'eventColor',
    'required',
    'clearable',
    'disabled',
    'reactive',
    'outlined',
    'textFieldClass',
    'icon',
    'textFieldBackground',
  ],
  data() {
    return {
      menu: false,
      currDate: this.value || (this.range ? [] : null),
    }
  },
  computed: {
    shouldCloseOnClick() {
      if (this.range) {
        return this.currDate && this.currDate.length === 2
      }
      return true
    },
    formattedDate() {
      if (this.range && this.currDate) {
        return this.currDate[0] || this.currDate[1]
          ? `${this.currDate[0] || ''} - ${this.currDate[1] || ''}`
          : null
      }
      return this.currDate
    },
  },
  watch: {
    value(val) {
      this.currDate = val
    },
  },
  methods: {
    onChange(val) {
      if (this.range) {
        if (this.$moment(val[0]).isAfter(this.$moment(val[1]))) {
          this.currDate.reverse()
        }
      }
      this.$emit('input', this.currDate)
      this.$emit('change', this.currDate)
    },
    inputChange(value) {
      if (this.range) {
        this.currDate = value ? value.split(' - ') : ''
      } else {
        this.currDate = value
      }
    },
  },
}
</script>

<style scoped>
>>> .v-text-field__slot input {
  cursor: pointer;
}
</style>
