<template>
  <v-menu
    left
    offset-x
    max-width="400"
    min-width="400"
    :close-on-content-click="false"
    :value="tooltip"
    class="white"
    content-class="white"
    @input="onClick"
  >
    <template #activator="{}">
      <v-btn
        block
        :ripple="false"
        x-small
        color="primary"
        class="ba-black-1 my-2 d-flex align-center justify-center p-relative pointer"
        :class="{
          'bg-black': tooltip,
          'bg-white': !tooltip,
          badge: !hideBadge,
        }"
        :data-badge="content"
        @click="onClick"
      >
        Tasks
      </v-btn>
    </template>
    <slot />
  </v-menu>
</template>

<script>
export default {
  name: 'BadgeButton',
  props: ['icon', 'hideBadge', 'content', 'value'],
  data() {
    return {
      tooltip: false,
    }
  },
  methods: {
    onClick() {
      this.tooltip = !this.tooltip
      this.$emit('input', this.tooltip)
      this.$emit('change', this.tooltip)
    },
  },
}
</script>

<style scoped>
.bg-black {
  background-color: #000000;
}
.badge::after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 0.8em;
  background: var(--v-warning-base);
  color: white;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  box-shadow: 0 0 1px #333;
}
.bg-white {
  background-color: #ffffff;
}
</style>
