<template>
  <v-container>
    <v-card class="overflow-y-auto">
      <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
        <div class="text-capitalize">
          <v-icon
            small
            class="mr-1"
            left
            :color="isActive ? 'success' : 'error'"
            >fas fa-circle</v-icon
          >{{ integrationName }}
        </div>
      </v-card-title>
      <v-card-text>
        <div class="text-body-1">
          Connect your Aloware account with Boom dashboard
        </div>
        <div v-if="isActive">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="apiToken"
                label="API Token"
                dense
                readonly
                outlined
              >
                <template #append>
                  <copy-clipboard :text="apiToken" />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="webhook"
                label="Webhook URL"
                dense
                readonly
                outlined
              >
                <template #append>
                  <copy-clipboard :text="webhook" />
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <h1 class="my-3">Aloware Webhook Setup Instructions</h1>
          <ol>
            <li>
              <strong>Log In to Aloware</strong>: Start by logging into your
              Aloware account.
            </li>
            <li>
              <strong>Access Integration Settings</strong>: Navigate to the
              "Integrations" section of the console. This is typically found in
              the main menu under settings or directly on the dashboard.
            </li>
            <li>
              <strong>Find Webhook Settings</strong>: Within the integrations
              menu, look for the "Webhooks" option. Click on this to manage
              webhook settings.
            </li>
            <li>
              <strong>Add a New Webhook</strong>: Click on the button to add a
              new webhook. This might be labeled "Add Webhook", "Create New", or
              something similar.
            </li>
            <li>
              <strong>Configure Webhook Details</strong>:
              <ul>
                <li>
                  <strong>Name </strong>: Give a descriptive name to your
                  webhook
                </li>
                <li>
                  <strong>URL</strong>: Copy the URL above and paste it in the
                  URL field.
                </li>
                <li>
                  <strong>Authentication Method</strong>: Choose
                  <strong>Bearer</strong> option and paste the API key above in
                  the `API Bearer Token` field.
                </li>
                <li>
                  <strong>Events</strong>: In the event section check the
                  following events:
                  <ul>
                    <li>Contact Created</li>
                    <li>Contact Updated</li>
                    <li>Contact Disposed</li>
                    <li>Communication Initiated</li>
                    <li>Communication Disposed</li>
                    <li>Call Disposed</li>
                    <li>Voicemail Saved</li>
                    <li>Recording Saved</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <strong>Save and Test</strong>: After entering all the details,
              save your webhook. Make sure to test it by sending a test event.
            </li>
          </ol>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <confirmation-modal
          v-if="isActive"
          :text="`Are you sure you want to disconnect ${integrationName} ?`"
          @action="disconnect"
        >
          <template #activator="{ on }">
            <v-btn :loading="creating" color="error" class="mx-2" v-on="on"
              >Disconnect</v-btn
            >
          </template>
        </confirmation-modal>
        <v-btn v-else color="primary" @click="createIntegration">Connect</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import MarketplaceMixin from 'components/marketplace/marketplace-mixin'
import CopyClipboard from 'components/copy-clipboard.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  name: 'Aloware',
  components: { ConfirmationModal, CopyClipboard },
  mixins: [MarketplaceMixin],
  data() {
    return {
      apiKey: null,
    }
  },
  computed: {
    apiToken() {
      const item = this.fields.find(field => field.key === 'api_token')
      return item?.value
    },
    webhook() {
      const item = this.fields.find(field => field.key === 'webhook_url')
      return item?.value
    },
  },
}
</script>

<style scoped></style>
