<template>
  <form-dialog :title="title" max-width="360px" @submit="save">
    <template #activator="{ on }">
      <v-btn v-on="on">{{ title }}</v-btn></template
    >
    <v-text-field
      v-model.number="listingIncome.amount"
      type="number"
      prepend-inner-icon="money"
      outlined
      dense
      label="Amount"
      :rules="[required]"
    />
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      required
      min-width="290px"
    >
      <template #activator="{ on }">
        <v-text-field
          v-model="listingIncome.transferred_at"
          label="transferred at"
          prepend-inner-icon="event"
          outlined
          dense
          readonly
          :rules="[required]"
          v-on="on"
        />
      </template>
      <v-date-picker
        ref="picker"
        v-model="listingIncome.transferred_at"
        :max="
          new Date(new Date().setMonth(new Date().getMonth() + 2))
            .toISOString()
            .substr(0, 10)
        "
        min="2017-01-01"
        @change="saveDate"
      />
    </v-menu>
    <v-autocomplete
      v-model="listingIncome.income_type"
      :items="incomeTypes"
      outlined
      dense
      label="Income Type"
      :rules="[required]"
    />
    <v-textarea
      v-model="listingIncome.comments"
      outlined
      dense
      label="Comments"
      rows="2"
    />
    <v-switch
      v-model="listingIncome.should_calculate_in"
      dense
      label="Calculate in accounting"
    />
  </form-dialog>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import FormDialog from 'components/common/form-dialog'

export default {
  components: { FormDialog },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  props: ['title'],
  data() {
    return {
      menu: false,
      listingIncome: {
        should_calculate_in: true,
      },
    }
  },
  methods: {
    async save() {
      const listingId = this.$route.params.id
      await axios.post(`/api/listing-incomes/${listingId}`, this.listingIncome)
      await this.$store.dispatch('changeEditListing', listingId)
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
  },
}
</script>
