<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="66.923"
    height="58"
    viewBox="0 0 66.923 58"
  >
    <g id="_002-growth" data-name="002-growth" transform="translate(-16 -48)">
      <path
        id="Path_6678"
        data-name="Path 6678"
        d="M439.808,144h-6.692A1.115,1.115,0,0,0,432,145.115V187.5a1.115,1.115,0,0,0,1.115,1.115h6.692a1.115,1.115,0,0,0,1.115-1.115V145.115A1.115,1.115,0,0,0,439.808,144Zm-1.115,42.385h-4.462V146.231h4.462Z"
        transform="translate(-358 -82.615)"
        class="a"
      />
      <path
        id="Path_6679"
        data-name="Path 6679"
        d="M359.808,243.692a1.115,1.115,0,0,0,1.115-1.115V209.115A1.115,1.115,0,0,0,359.808,208h-6.692A1.115,1.115,0,0,0,352,209.115v33.462a1.115,1.115,0,0,0,1.115,1.115Zm-5.577-33.462h4.462v31.231h-4.462Z"
        transform="translate(-289.154 -137.692)"
        class="a"
      />
      <path
        id="Path_6680"
        data-name="Path 6680"
        d="M279.808,291.885a1.115,1.115,0,0,0,1.115-1.115V265.115A1.115,1.115,0,0,0,279.808,264h-6.692A1.115,1.115,0,0,0,272,265.115v25.654a1.115,1.115,0,0,0,1.115,1.115Zm-5.577-25.654h4.462v23.423h-4.462Z"
        transform="translate(-220.308 -185.885)"
        class="a"
      />
      <path
        id="Path_6681"
        data-name="Path 6681"
        d="M199.808,346.962a1.115,1.115,0,0,0,1.115-1.115V329.115A1.115,1.115,0,0,0,199.808,328h-6.692A1.115,1.115,0,0,0,192,329.115v16.731a1.115,1.115,0,0,0,1.115,1.115Zm-5.577-16.731h4.462v14.5h-4.462Z"
        transform="translate(-151.462 -240.962)"
        class="a"
      />
      <path
        id="Path_6682"
        data-name="Path 6682"
        d="M119.808,374.5a1.115,1.115,0,0,0,1.115-1.115V361.115A1.115,1.115,0,0,0,119.808,360h-6.692A1.115,1.115,0,0,0,112,361.115v12.269a1.115,1.115,0,0,0,1.115,1.115Zm-5.577-12.269h4.462v10.038h-4.462Z"
        transform="translate(-82.616 -268.5)"
        class="a"
      />
      <path
        id="Path_6683"
        data-name="Path 6683"
        d="M39.808,408.923a1.115,1.115,0,0,0,1.115-1.115v-6.692A1.115,1.115,0,0,0,39.808,400H33.115A1.115,1.115,0,0,0,32,401.115v6.692a1.115,1.115,0,0,0,1.115,1.115Zm-5.577-6.692h4.462v4.462H34.231Z"
        transform="translate(-13.769 -302.923)"
        class="a"
      />
      <path
        id="Path_6684"
        data-name="Path 6684"
        d="M195.346,116.462h2.231a1.115,1.115,0,0,0,0-2.231h-1.115v-1.115a1.115,1.115,0,0,0-2.231,0v1.307a3.346,3.346,0,0,0,1.115,6.5,1.115,1.115,0,1,1,0,2.231h-2.231a1.115,1.115,0,1,0,0,2.231h1.115V126.5a1.115,1.115,0,0,0,2.231,0v-1.307a3.346,3.346,0,0,0-1.115-6.5,1.115,1.115,0,1,1,0-2.231Z"
        transform="translate(-151.462 -55.077)"
        class="a"
      />
      <path
        id="Path_6685"
        data-name="Path 6685"
        d="M81.808,48H75.115a1.115,1.115,0,1,0,0,2.231h4L63.5,65.846H57.222c.031-.368.047-.74.047-1.115A13.385,13.385,0,1,0,41.42,77.887l-4.69,4.69H28.269a1.115,1.115,0,0,0-.789.327L16.326,94.057A1.115,1.115,0,0,0,17.9,95.635L28.731,84.808h8.461a1.115,1.115,0,0,0,.789-.327l6.375-6.375a13.411,13.411,0,0,0,12.49-10.029h7.116a1.115,1.115,0,0,0,.789-.327L80.692,51.808v4a1.115,1.115,0,0,0,2.231,0V49.115A1.115,1.115,0,0,0,81.808,48ZM43.884,75.885A11.154,11.154,0,1,1,55.038,64.731,11.154,11.154,0,0,1,43.884,75.885Z"
        transform="translate(0)"
        class="a"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Growth',
}
</script>

<style scoped>
svg {
  fill: currentColor;
  stroke: currentColor;
}
</style>
