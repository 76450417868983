<template>
  <v-container>
    <v-card class="overflow-y-auto">
      <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
        <div class="text-capitalize">
          <v-icon
            small
            class="mr-1"
            left
            :color="isActive ? 'success' : 'error'"
            >fas fa-circle</v-icon
          >{{ integrationName }}
        </div>
      </v-card-title>
      <v-card-text>
        <div class="text-body-1">
          Please contact our support team in order to connect Optima
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn disabled color="primary">Connect</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import MarketplaceMixin from 'components/marketplace/marketplace-mixin'

export default {
  name: 'Optima',
  mixins: [MarketplaceMixin],
}
</script>

<style scoped></style>
