<template>
  <div v-if="bookingEngineSettings" class="mt-3 pa-3">
    <div class="d-flex justify-space-between">
      <h3>{{ $t('Booking engines') }}</h3>
      <div class="mr-3">
        <v-btn color="primary" @click="addRule">
          <v-icon left>fas fa-plus</v-icon>
          {{ $t('New Engine') }}</v-btn
        >
      </div>
    </div>
    <v-data-table
      :loading="listingLoading"
      :headers="headers"
      :items="filteredItems"
      class="py-2 mt-4"
    >
      <template #top>
        <div class="d-flex flex-row-reverse">
          <v-switch
            v-model="activeOnly"
            dense
            hide-details
            class="ml-4 px-3 mt-0"
            :label="$t('Active only')"
            @change="getBookingEngineSettings"
          />
        </div>
      </template>
      <template #item="{ item }">
        <BookingEngineRow
          v-if="listings"
          :after-change="afterSave"
          :item="item"
          :listings="listings"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import sortBy from 'lodash/sortBy'
import { mapState } from 'vuex'
import axios from 'axios'
import BookingEngineRow from 'components/booking-engine/booking-engine-row.vue'
export default {
  components: {
    BookingEngineRow,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      activeOnly: true,
      listings: [],
    }
  },
  async mounted() {
    this.getBookingEngineSettings()
    const { data } = await axios.get(
      `/api/booking-engine-settings/listings-picker`
    )
    this.listings = data
  },
  methods: {
    addRule() {
      this.$store.commit('showModal', {
        name: 'BookingEngineModal',
        props: {
          editMode: false,
          afterSaveFunc: this.afterSave,
          listings: this.listings,
        },
        maxWidth: '1200',
      })
    },
    afterSave() {
      this.getBookingEngineSettings()
    },
    getBookingEngineSettings() {
      this.$store.dispatch('getBookingEngineSettings', {
        id:
          this.listingManageContext &&
          this.$router.currentRoute.path.split('/')[3],
        active_only: this.activeOnly,
      })
    },
  },
  computed: {
    ...mapState(['bookingEngineSettings']),
    headers() {
      const baseHeaders = [
        { text: 'Name', align: 'center', sortable: false },
        { text: 'Listings', align: 'center', sortable: false },
        { text: 'Active', sortable: false },
      ]

      if (this.isSystemAdmin) {
        baseHeaders.push({ text: 'API', align: 'center', sortable: false })
      }

      return baseHeaders
    },
    filteredItems() {
      return sortBy(this.bookingEngineSettings, ['listing_nickname'])
    },
  },
}
</script>
