<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.566 8.13814L6.61105 0.183224C6.36696 -0.0608686 5.97124 -0.0608686 5.72718 0.183224C5.48312 0.427316 5.48309 0.823032 5.72718 1.06709L6.3009 1.64081L3.41267 7.80099L0.548309 10.6653C-0.182749 11.3964 -0.182749 12.5859 0.548309 13.317L1.43221 14.2009C2.16499 14.9337 3.35107 14.9337 4.08385 14.2009L4.81616 13.4686L6.78249 15.4349C7.13833 15.7994 7.61454 16 8.12407 16C9.15794 16 9.99906 15.1589 9.99906 14.125C9.99906 13.6155 9.79844 13.1393 9.43394 12.7835L7.65542 11.0049L13.1084 8.44826L13.6821 9.02198C13.9262 9.26607 14.3219 9.26607 14.566 9.02198C14.81 8.77792 14.81 8.3822 14.566 8.13814ZM3.19995 13.317C2.9557 13.5613 2.56036 13.5613 2.31608 13.317L1.43218 12.4331C1.18849 12.1894 1.18849 11.7929 1.43218 11.5492L3.93204 9.04939L5.69981 10.8172L3.19995 13.317ZM8.55941 13.6767C8.68169 13.7954 8.74904 13.9547 8.74904 14.1251C8.74904 14.4697 8.46866 14.7501 8.12404 14.7501C7.95364 14.7501 7.79439 14.6828 7.67561 14.5605C7.67348 14.5583 5.69996 12.5847 5.69996 12.5847L6.58365 11.7011C6.58371 11.701 8.55723 13.6745 8.55941 13.6767ZM6.71549 10.0651L4.68409 8.03367L7.24077 2.58071L12.1685 7.50836L6.71549 10.0651Z"
      fill="#25282F"
    />
    <path
      d="M12.2948 4.05574C12.4513 4.36337 12.8276 4.4859 13.1352 4.3294L14.4074 3.68218C14.7151 3.52565 14.8376 3.14937 14.6811 2.84171C14.5246 2.53405 14.1483 2.41155 13.8406 2.56805L12.5684 3.21527C12.2608 3.37181 12.1382 3.74809 12.2948 4.05574Z"
      fill="#25282F"
    />
    <path
      d="M10.6935 2.45442C11.0011 2.61092 11.3774 2.48838 11.5339 2.18073L12.1811 0.908507C12.3376 0.60085 12.2151 0.224568 11.9074 0.0680682C11.5998 -0.088432 11.2235 0.0340682 11.067 0.341756L10.4198 1.61398C10.2633 1.92163 10.3858 2.29792 10.6935 2.45442Z"
      fill="#25282F"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchTask',
}
</script>

<style scoped></style>
