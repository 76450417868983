<template>
  <v-dialog v-model="dialog" max-width="400">
    <template #activator="{ on }">
      <v-btn outlined small class="flex" color="info" v-on="on">
        Save Filters
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form" @submit.prevent="save">
        <v-card-title> Save Filters </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="name"
            label="Name"
            dense
            :rules="[required]"
            outlined
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small color="primary" outlined @click="closeModal"
            >Cancel</v-btn
          >
          <v-btn small color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
export default {
  name: 'SaveFilterModal',
  mixins: [formRules],
  props: ['filters'],
  data() {
    return {
      name: null,
      dialog: false,
    }
  },
  methods: {
    closeModal() {
      this.dialog = false
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.commit('taskCalendar/updateFilterChanged', false)
        const payload = {
          filters: {
            ...this.filters,
            searchTerm: '',
          },
          key: this.name,
        }
        this.$store.dispatch('users/saveCalendarFilter', payload)
        this.$emit('saved', { key: this.name, value: payload.filters })
        this.closeModal()
      }
    },
  },
}
</script>

<style scoped></style>
