<template>
  <v-card class="pa-3 overflow-y-auto">
    <v-card-title>Reset password</v-card-title>
    <v-form ref="form" @submit.prevent="resetPassword">
      <v-card-text>
        <v-text-field
          v-model="password"
          name="password"
          dense
          :rules="passwordRules"
          label="New password"
          type="password"
          outlined
        />
        <v-text-field
          v-model="passwordConfirmation"
          dense
          name="repeatPassword"
          label="Confirm new password"
          :rules="
            passwordRules.concat(
              password === passwordConfirmation || 'Passwords are not matched'
            )
          "
          type="password"
          outlined
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined small @click="close">cancel</v-btn>
        <v-btn color="primary" type="submit" small>Reset</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import axios from '@/axios/config'

export default {
  name: 'ResetPasswordModal',
  mixins: [FormRulesMixin],
  props: ['userId'],
  data() {
    return {
      password: null,
      passwordConfirmation: null,
    }
  },
  methods: {
    resetPassword() {
      if (this.$refs.form.validate()) {
        axios.post(`/api/reset-password/${this.userId}`, {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        })
        this.close()
      }
    },
    close() {
      this.$store.commit('hideModal')
    },
  },
}
</script>

<style scoped></style>
