var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:[
    { grey: !_vm.isMobile },
    { 'lighten-3': !_vm.isMobile },
    'pa-4',
    'pa-sm-12',
    'login-wrapper',
  ],attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"5","xl":"4"}},[_c('v-card',{staticClass:"rounded-xl text-center px-4 py-2 pa-sm-10",attrs:{"elevation":_vm.isMobile ? 0 : 12}},[(!_vm.redirectUrl)?_c('div',[_c('img',{staticClass:"mb-6",attrs:{"src":_vm.logo,"height":"64"}}),_c('h2',{staticClass:"text-h4 font-weight-medium mb-5 px-3 text-center"},[_vm._v(" Reset password ")]),_c('v-form',{ref:"resetPassword",attrs:{"lazy-validation":""},on:{"submit":_vm.resetPassword}},[_c('v-text-field',{attrs:{"name":"password","rules":_vm.passwordRules,"label":"New password","type":"password","outlined":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"name":"repeatPassword","label":"Confirm new password","rules":_vm.passwordRules.concat(
                  _vm.password === _vm.passwordConfirmation ||
                    'Passwords are not matched'
                ),"type":"password","outlined":""},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),(_vm.error)?_c('div',{staticClass:"text-caption error--text"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('v-btn',{attrs:{"type":"submit","color":"primary","elevation":"0","block":"","x-large":""}},[_c('span',{staticClass:"text--secondary text-capitalize"},[_vm._v(" Reset password ")])])],1)],1):_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":_vm.isMobile ? 180 : 260}},[_vm._v("$lock_checked")]),_c('h3',{staticClass:"text-h5 text-sm-h4 font-weight-bold mb-5"},[_vm._v(" Successful password reset ")]),_c('p',{staticClass:"mb-6"},[_vm._v(" You can now use your new password to log in to your account. ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8","lg":"6"}},[_c('v-btn',{staticClass:"text-capitalize mb-4",attrs:{"color":"primary","elevation":"0","block":"","large":""},on:{"click":_vm.redirect}},[_vm._v(" OK ")])],1)],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }