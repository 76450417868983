<template>
  <v-container grid-list-md style="min-height: 60vh">
    <v-layout wrap>
      <v-flex xs12 sm11 md11 lg11>
        <v-flex>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            required
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="parsedFrom"
                label="From"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="from"
              :max="
                new Date(new Date().setMonth(new Date().getMonth() + 12))
                  .toISOString()
                  .substr(0, 10)
              "
              min="2017-01-01"
              @change="saveFrom"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex xs12>
          <listing-tags :filter-change="tagsChange"></listing-tags>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-layout wrap align-center justify-center class="center-text">
      <v-flex xs12 sm6 mt-3>
        <pricing-monthly
          v-if="$store.state.currentPricingMonthly"
          :title="monthName + ' Monthly stats'"
        >
        </pricing-monthly>
      </v-flex>
      <v-flex xs12 sm6 mt-3>
        <pricing-yearly
          v-if="$store.state.currentPricingYearly"
          :hide-calc="true"
          :title="new Date(from).getFullYear() + ' Yearly stats'"
        >
        </pricing-yearly>
      </v-flex>
    </v-layout>
    <v-layout v-if="outstandingDates" wrap mt-4>
      <v-flex xs12 sm6>
        <v-layout wrap>
          <v-flex xs12 pt-2 pb-2 class="center-text grey-text">
            <h3>Outstanding dates *3 month back from {{ parseDate(from) }}</h3>
          </v-flex>

          <v-flex v-if="!isEmpty(outstandingDates)" xs12>
            <v-layout wrap light-grey purple-icon>
              <v-flex xs4 class="center-text cyanish white--text">
                Date
              </v-flex>
              <v-flex xs4 class="center-text cyanish white--text"> Sum</v-flex>
              <v-flex xs4 class="center-text cyanish white--text">
                Amount
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            v-for="(outstandingDate, index) in outstandingDates"
            :key="index"
            xs12
          >
            <v-layout wrap light-grey purple-icon>
              <v-flex xs4 class="center-text">
                {{ outstandingDate.date }}
              </v-flex>
              <v-flex xs4 class="center-text">
                {{ outstandingDate.sum }}
              </v-flex>
              <v-flex xs4 class="center-text">
                {{ outstandingDate.amount }}
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm6>
        <v-flex xs12 pt-2 pb-2 class="center-text">
          <h3 class="grey-text">
            Yearly month acc *confirmed before {{ parseDate(from) }}
          </h3>
        </v-flex>
        <monthly-graph
          :names="$store.state.pricingReservationsStats.month_names"
          title="Point in time yearly graph"
          :reveune="$store.state.pricingReservationsStats.reveune"
          graph-id="montly-pricing-graph"
        ></monthly-graph>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import PricingMonthly from './pricing-monthly.vue'
import PricingYearly from './pricing-yearly.vue'
import ListingTags from './listing_tags.vue'
import MonthlyGraph from './monthly-stats-graph.vue'

export default {
  components: {
    PricingMonthly,
    PricingYearly,
    ListingTags,
    MonthlyGraph,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data: () => ({
    menu: false,
    from: new Date().toISOString().substr(0, 10),
    tags: [],
    options: {
      hideTabs: true,
    },
  }),
  computed: {
    outstandingDates() {
      return (
        this.$store.state.pricingReservationsStats &&
        this.$store.state.pricingReservationsStats.outstanding_dates
      )
    },
    monthName() {
      const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      return monthNames[new Date(this.from).getMonth()]
    },
    parsedFrom() {
      return this.from ? this.$moment(this.from).format('YYYY/MM/DD') : ''
    },
    yearlyStats() {
      return this.$store.state.pricingStats.yearly_stats
    },
    monthlyStats() {
      return this.$store.state.pricingStats.monthly_stats
    },
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    tagsChange(tags) {
      this.tags = tags
      this.$store.dispatch('getPricingStats', {
        from: this.from,
        tags: this.tags,
      })
    },
    saveFrom(date) {
      this.$refs.menu.save(date)
      this.$store.dispatch('getPricingStats', {
        from: this.from,
        tags: this.tags,
      })
    },
  },
}
</script>
