<template>
  <v-sheet color="grey lighten-2 pa-2">
    <div class="d-flex align-center">
      <v-menu
        :value="menu"
        :close-on-content-click="false"
        bottom
        max-height="500"
        nudge-bottom="60"
      >
        <template #activator="{}">
          <v-text-field
            v-model="searchTerm"
            solo
            flat
            hide-details
            label="search"
            @input="onSearchTerm"
          >
            <template #append>
              <v-progress-circular
                v-show="loadingChat || loadingItems"
                size="25"
                :width="2"
                color="info"
                indeterminate
              />
            </template>
          </v-text-field>
        </template>
        <v-list class="pa-0" nav max-width="340">
          <v-list-item-group v-if="list.length" color="info">
            <div v-for="(item, index) in list" :key="item.confirmation_code">
              <v-list-item class="mb-0 px-4" @click="goToConversation(item)">
                <v-list-item-content>
                  <v-list-item-title class="mb-2">
                    {{ item.guest_name }}
                    <em v-show="item.confirmation_code" class="text-caption">
                      ({{ item.confirmation_code }})
                    </em>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption mb-2">
                    {{ parseDate(item.reservation_check_in, 'MMM DD, YYYY') }}
                    &ndash;
                    {{ parseDate(item.reservation_check_out, 'MMM DD, YYYY') }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <v-chip
                      :color="resStatusColor(item.reservation_status)"
                      label
                      small
                    >
                      {{ item.reservation_status }}
                    </v-chip>
                    <span class="text-caption ml-2">{{ item.listing }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index < list.length - 1" />
            </div>
          </v-list-item-group>
          <v-list-item v-if="!searchTerm" class="px-4">
            <em class="text-caption secondary--text">
              Enter name or confirmation code
            </em>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        class="ml-2 py-6"
        color="white"
        elevation="0"
        @click="showFilters = !showFilters"
      >
        <v-icon color="secondary">$filters</v-icon>
      </v-btn>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            class="ml-2 py-6"
            color="white"
            elevation="0"
            v-on="on"
            @click="$emit('update:mode', 'sc-mode')"
          >
            <v-icon color="secondary">$maintenance</v-icon>
          </v-btn>
        </template>
        <span>Service call mode</span>
      </v-tooltip>
    </div>
    <v-expand-transition>
      <v-sheet v-show="showFilters" class="mt-2 pa-4">
        <chat-filters
          :items="filters"
          :type="type"
          class="mb-4"
          @type-change="$emit('change-type', $event)"
        />
        <listing-select
          label="Search listings"
          :clearable="true"
          :filter-change="listingChanged"
        />
        <v-select
          clearable
          :value="salesStatus"
          label="Tags"
          multiple
          chips
          tags
          :items="salesStatusList"
          outlined
          dense
          @change="$emit('sales-status-change', $event)"
        />
        <v-row>
          <v-col cols="6" class="py-0">
            <v-text-field
              :value="fromPrice"
              label="From price"
              required
              outlined
              dense
              @change="$emit('from-price-change', $event)"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <v-text-field
              :value="toPrice"
              label="To price"
              required
              outlined
              dense
              @change="$emit('to-price-change', $event)"
            />
          </v-col>
        </v-row>
        <v-select
          clearable
          :value="resStatuses"
          label="Reservation status"
          multiple
          chips
          tags
          :items="reservationStatuses"
          outlined
          @change="$emit('reservation-status-change', $event)"
        />
        <v-select
          clearable
          :value="timeSinceCreation"
          label="Time since created"
          :items="periods"
          outlined
          dense
          @change="$emit('time-since-creation-change', $event)"
        />
        <v-row>
          <v-col cols="6" class="py-0">
            <v-menu
              ref="menuFrom"
              v-model="menuFrom"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              required
            >
              <template #activator="{ on }">
                <v-text-field
                  :value="parseDate(from)"
                  label="Check-in from"
                  readonly
                  outlined
                  dense
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="from"
                :max="
                  new Date(new Date().setMonth(new Date().getMonth() + 10))
                    .toISOString()
                    .substr(0, 10)
                "
                min="2018-01-01"
                @change="save('from', $event)"
              />
            </v-menu>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-menu
              ref="menuTo"
              v-model="menuTo"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              required
            >
              <template #activator="{ on }">
                <v-text-field
                  :value="parseDate(to)"
                  label="Check-in to"
                  readonly
                  outlined
                  dense
                  v-on="on"
                />
              </template>
              <v-date-picker
                ref="picker"
                v-model="to"
                :min="new Date(from || null).toISOString().substr(0, 10)"
                :max="
                  new Date(new Date().setMonth(new Date().getMonth() + 10))
                    .toISOString()
                    .substr(0, 10)
                "
                @change="save('to', $event)"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import ChatFilters from 'components/chat-filters'
import ListingSelect from 'components/listing-select'
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import axios from 'axios'
export default {
  name: 'ChatAdditionalFilters',
  components: { ListingSelect, ChatFilters },
  mixins: [CommonFunctions],
  props: {
    mode: {
      type: String,
      default: '',
    },
    filters: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    salesStatus: {
      type: Array,
      default: () => [],
    },
    fromPrice: {
      type: [Number, String],
      default: null,
    },
    toPrice: {
      type: [Number, String],
      default: null,
    },
    resStatuses: {
      type: Array,
      default: () => [],
    },
    timeSinceCreation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showFilters: false,
      menu: false,
      loadingItems: false,
      currentType: '',
      periods: ['Last 24 hours', 'Last 48 hours', 'Last week', 'Any'],
      reservationStatuses: [
        'inquiry',
        'declined',
        'expired',
        'canceled',
        'closed',
        'reserved',
        'confirmed',
        'awaiting_payment',
      ],
      menuFrom: false,
      menuTo: false,
      from: null,
      to: null,
      model: null,
      list: [],
      searchTerm: '',
      debouncer: null,
      loadingChat: false,
    }
  },
  computed: {
    ...mapState({
      salesStatusList: state => state.conversationTags,
      chats: state => state.chatConversations,
    }),
  },
  mounted() {
    this.debouncer = debounce(async () => {
      const { data } = await axios.get('/api/conversations/search', {
        params: { term: this.searchTerm },
      })
      this.menu = true
      this.loadingItems = false
      this.list = data
    }, 750)
  },
  methods: {
    save(range, date) {
      if (this.from || this.to) {
        this.$emit(`save-${range}`, date)
      }
    },
    goToConversation(item) {
      this.$router.push({
        name: 'guests-chat',
        params: { id: item.conversation_id },
      })
      this.searchTerm = item.guest_name
      this.$emit('update:searchTerm', item.conversation_id)
      this.menu = false
    },
    listingChanged(val) {
      this.$emit('listing-change', val)
    },
    onSearchTerm() {
      this.menu = false
      this.loadingItems = true
      this.debouncer.call(this)
    },
  },
}
</script>

<style scoped></style>
