<template>
  <v-card v-if="listingTask">
    <v-card-title>
      <expense-card-header :title="$t('Summary')" />
    </v-card-title>
    <v-divider />
    <div class="pa-6">
      <info-box title="Listing" :value="listingName" />
      <info-box title="Description" :value="listingTask.description" />
    </div>
    <v-divider />
    <div class="pa-6">
      <expense-card-info
        :done-at="parseDate(doneAt)"
        :hours="listingTask.total_hours"
        :material-cost="materialsSum"
        :management-fee="listingTask.management_fee"
        :labor-cost="listingTask.amount"
      />
    </div>
    <v-divider />
    <div v-if="listingTask.desc_images.length" class="pa-6">
      <div class="font-weight-medium">{{ $t('Media') }}</div>
      <gallery
        :thumbnails="true"
        :cols="4"
        :images="listingTask.desc_images"
        :readonly="true"
      />
    </div>
    <v-divider v-show="listingTask.desc_images.length" />
    <div v-if="!isEmpty(listingTask.sub_tasks)" class="pa-6">
      <div class="font-weight-medium pb-4">{{ $t('Subtasks') }}:</div>
      <expense-card-subtasks :list="listingTask.sub_tasks" />
    </div>
    <v-row v-else class="pa-6">
      <v-col cols="12" sm="6" class="d-flex">
        <div
          v-if="
            !isEmpty(listingTask.before_images) || listingTask.before_comments
          "
          class="flex"
        >
          <p class="font-weight-medium">{{ $t('Before job') }}:</p>
          <p>{{ listingTask.before_comments }}</p>
          <gallery
            :thumbnails="true"
            :images="listingTask.before_images"
            :readonly="true"
          />
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex">
        <div
          v-if="
            !isEmpty(listingTask.after_images) || listingTask.after_comments
          "
          class="flex"
        >
          <p class="font-weight-medium">{{ $t('After job') }}:</p>
          <p>{{ listingTask.after_comments }}</p>
          <gallery
            :thumbnails="true"
            :images="listingTask.after_images"
            :readonly="true"
          />
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <div v-if="!isEmpty(listingTask.receipt_images)" class="py-6 px-8">
      <p class="font-weight-medium">{{ $t('Receipt images') }}:</p>
      <gallery
        :thumbnails="true"
        :images="listingTask.receipt_images"
        :cols="4"
        :readonly="true"
      />
    </div>
  </v-card>
</template>

<script>
import get from 'lodash/fp/get'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ExpenseCardHeader from 'components/expenses/expense-card-header'
import ExpenseCardInfo from 'components/expenses/expense-card-info'
import ExpenseCardSubtasks from 'components/expenses/expense-card-subtasks'
import Gallery from 'components/common/gallery'
import { sumBy } from 'lodash/fp'
import InfoBox from 'components/common/info-box.vue'
export default {
  name: 'SummaryCard',
  components: {
    InfoBox,
    ExpenseCardHeader,
    ExpenseCardInfo,
    ExpenseCardSubtasks,
    Gallery,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['listingTask'],
  computed: {
    doneAt() {
      return this.parseDate(this.listingTask.done_at)
    },
    materialsSum() {
      return sumBy('amount', this.listingTask.material_expenses)
    },
    listingName() {
      return (
        this.listingTask.mini_extra_data &&
        this.listingTask.mini_extra_data.listing_name
      )
    },
  },
  methods: {
    removeDescImg(link) {
      const desc_images = this.listingTask.desc_images.filter(
        img => img !== link
      )
      this.updateTask({ desc_images })
    },
    rotateDescImg(oldLink) {
      return newLink => {
        const desc_images = this.listingTask.desc_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ desc_images })
      }
    },
    updateTask(payload) {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        ...payload,
      })
    },
  },
}
</script>
