<template>
  <svg
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.821411 8.13146H4.41927C4.57076 8.12972 4.71906 8.08772 4.84897 8.00977C4.97888 7.93183 5.08573 7.82074 5.15855 7.68789L8.1157 1.7736C8.18858 1.62635 8.30477 1.50493 8.44867 1.42563C8.59257 1.34634 8.75728 1.31298 8.9207 1.33003C9.08346 1.34103 9.23881 1.40224 9.36533 1.50522C9.49185 1.6082 9.58332 1.74789 9.62713 1.90503L13.2907 14.095C13.3388 14.2604 13.4376 14.4064 13.5732 14.5126C13.7088 14.6187 13.8744 14.6795 14.0464 14.6865C14.2076 14.6811 14.3637 14.6284 14.4952 14.5349C14.6267 14.4414 14.7277 14.3113 14.7857 14.1607L17.0364 8.65717C17.0984 8.50279 17.2049 8.37035 17.3424 8.27673C17.4799 8.1831 17.6422 8.13253 17.8086 8.13146H22.1786"
      stroke="#42526E"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Pulse',
}
</script>

<style scoped>
path {
  stroke: currentColor;
}
</style>
