<template>
  <div>
    <v-progress-linear v-show="loadingGuestExpInfo" indeterminate />
    <v-card
      v-if="!isMobile"
      outlined
      class="d-flex justify-space-between py-4 px-8 rounded-lg pre-checkin-container"
    >
      <pre-checkin-stat
        label="Incomplete Checkins"
        :value="managedReservationsStats.missing_pre_checkins"
        color="var(--v-dark500-base)"
      />
      <pre-checkin-stat
        label="Check-Ins"
        :value="managedReservationsStats.checkins"
        color="var(--v-dark500-base)"
      />
      <pre-checkin-stat
        label="Check-Outs"
        :value="managedReservationsStats.checkouts"
        color="var(--v-dark500-base)"
      />
      <pre-checkin-stat
        label="Early Checkins"
        :value="managedReservationsStats.early_checkins"
        color="var(--v-dark500-base)"
      />
      <pre-checkin-stat
        label="Late Checkouts"
        :value="managedReservationsStats.late_checkouts"
        color="var(--v-dark500-base)"
      />
      <pre-checkin-stat
        label="Special requests"
        :value="managedReservationsStats.special_today"
        color="var(--v-success600-base)"
      />
      <pre-checkin-stat
        label="Store purchases today"
        :value="managedReservationsStats.store_today"
        color="var(--v-secondary600-base)"
      />
    </v-card>
    <v-row class="align-center">
      <v-col cols="6" sm="4" lg="2">
        <v-select
          v-model="dateBy"
          outlined
          dense
          hide-details
          :items="[
            { text: 'Check-ins', value: 'check_in' },
            { text: 'Check-outs', value: 'check_out' },
            { text: 'Check-Ins & Outs', value: 'check_in_out' },
          ]"
          @change="fetchReservations({})"
        />
      </v-col>
      <div>
        <date-picker
          v-model="from"
          label="Check-in Date"
          :max="
            parseISODateWithOffset(
              new Date(new Date().setMonth(new Date().getMonth() + 10))
            ).substr(0, 10)
          "
          :min="
            parseISODateWithOffset(
              new Date(new Date().setMonth(new Date().getMonth() - 20))
            ).substr(0, 10)
          "
          @change="fetchReservations"
        />
      </div>
      <v-col cols="6" sm="4" lg="2">
        <v-autocomplete
          v-model="listingId"
          label="Listing"
          clearable
          hide-details
          :items="listingsPicker"
          item-text="nickname"
          item-value="id"
          dense
          outlined
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="1">
        <filters-select
          :value.sync="channel"
          label="Channel"
          :items="channelsItems"
          :selection-func="item => item.text"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="1">
        <filters-select
          :label="$t('Region')"
          :value.sync="selectedRegions"
          :items="listingRegions"
          :selection-func="item => item.text"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="auto">
        <v-checkbox
          v-model="missingOnly"
          dense
          hide-details
          class="mt-0"
          label="Missing Checkins only"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="auto">
        <v-checkbox
          v-model="missingDepositsOnly"
          dense
          hide-details
          class="mt-0"
          label="Missing Deposits only"
          @change="fetchReservations({})"
        />
      </v-col>
      <v-col cols="auto">
        <v-btn
          small
          color="success"
          @click="fetchReservations({ excel: true, checkin: true })"
        >
          <v-icon small left>fas fa-table</v-icon> Export
        </v-btn>
      </v-col>
    </v-row>
    <reservations
      hide-reconcile="true"
      :headers="headers"
      @pagination="paginationChanged"
      @refetch="fetchReservations"
    />
  </div>
</template>

<script>
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import formattersMixin from 'components/mixins/formatters-mixin'
import DatePicker from 'components/form-fields/date-picker.vue'
import Common_functions from 'components/mixins/common_functions'
import Reservations from 'components/reservations.vue'
import FiltersSelect from 'components/form-fields/filters-select.vue'
import PreCheckinStat from '@/components/crm/pre-checkin-stat.vue'

export default {
  name: 'PreCheckIns',
  components: {
    FiltersSelect,
    Reservations,
    DatePicker,
    PreCheckinStat,
  },
  mixins: [PermissionsMixin, formattersMixin, Common_functions, DeviceMixin],
  methods: {
    ...mapActions(['getReviewsInfo', 'getManagedReservations']),
    ...mapActions('reservation', ['fetchChannels']),
    fetchReservations(options = {}) {
      this.getManagedReservations({
        page: 1,
        per_page: this.pagination.itemsPerPage,
        from: this.from,
        to: this.from,
        missing_only: this.missingOnly,
        missing_deposit_only: this.missingDepositsOnly,
        date_by: this.dateBy,
        include_owner: true,
        status: ['confirmed', 'reserved', 'awaiting_payment'],
        channel: this.channel,
        listingId: this.listingId,
        pre_stats: true,
        regions: this.selectedRegions,
        ...options,
      })
    },
    paginationChanged(data) {
      this.pagination = { ...this.pagination, ...data }
      this.fetchReservations(this.pagination)
    },
  },
  computed: {
    ...mapState([
      'loadingGuestExpInfo',
      'managedReservationsStats',
      'managedReservationsInfo',
    ]),
    ...mapState('regions', ['regions']),
    ...mapGetters(['listingsPicker']),
    ...mapGetters('reservation', ['channelsItems']),
    listingRegions() {
      return this.regions.map(r => ({ text: r.name, value: r.id }))
    },
    headers() {
      return [
        {
          text: 'Guest',
          value: 'guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Filled',
          value: 'pre_check_in_filled',
          sortable: false,
        },
        {
          text: 'Verified',
          value: 'verification_status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Check in',
          value: 'check_in',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Check out',
          value: 'check_out',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Listing',
          value: 'nickname',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Channel',
          value: 'channel',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Notes',
          value: 'dvr_notes',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Confirmation',
          value: 'confirmation_code',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Payment',
          value: 'is_fully_paid',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Picture',
          value: 'identity',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Docs',
          value: 'additional_doc',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Guests count',
          value: 'guests_count',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Requests',
          value: 'special_requests',
          sortable: false,
        },
      ].filter(
        h =>
          h.value !== 'verification_status' ||
          this.managedReservationsInfo.show_verification_status
      )
    },
  },
  data() {
    return {
      view: 'pending',
      missingOnly: false,
      missingDepositsOnly: false,
      listingId: null,
      channel: [],
      selectedRegions: [],
      dateBy: 'check_in',
      from: this.parseISODateWithOffset(new Date()).substr(0, 10),

      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
    }
  },
  async mounted() {
    this.fetchReservations()
    this.fetchChannels()
    this.$store.dispatch('getWhatsappTemplates')
  },
}
</script>
<style>
.pre-checkin-container {
  border-color: none !important;
}
</style>
