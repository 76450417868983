<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex sm10>
        <v-switch
          v-if="!loading && false"
          v-model="showRuleSummary"
          mt-2
          mb-2
          :label="$t('Show rules per house')"
        >
        </v-switch>
        <auto-listing-task-rules-table
          v-show="!showRuleSummary"
          :general-mode="true"
        />
        <auto-listing-task-rules-warnings
          v-show="showRuleSummary"
        ></auto-listing-task-rules-warnings>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import AutoListingTaskRulesTable from 'components/auto-listing-task-rules-table'
import AutoListingTaskRulesWarnings from 'components/auto-listing-task-rules-warnings'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
export default {
  mixins: [CommonFunctions, PermissionsMixin],

  components: {
    AutoListingTaskRulesTable,
    AutoListingTaskRulesWarnings,
  },
  data() {
    return {
      showRuleSummary: false,
    }
  },
}
</script>

<style></style>
