<template>
  <div class="w-100 text-center">
    <v-dialog v-model="dialog" width="800" persistent>
      <template #activator="{ on }">
        <v-btn
          color="success"
          class="w-100"
          depressed
          :disabled="disabled"
          v-on="on"
        >
          <v-icon class="me-4">mdi-check-decagram</v-icon>
          Confirm Agent Reservation
        </v-btn>
      </template>
      <v-card class="pa-4">
        <v-card-title
          class="text-h6 d-flex justify-center mb-4 font-weight-bold"
          >Confirm Agent Reservation</v-card-title
        >
        <v-form ref="form" @submit.prevent="create">
          <reservation-guest-search
            :reservation.sync="guestReservation"
            :is-owner-vacation="!!isOwnerVacation"
          />
          <v-card-actions class="justify-end">
            <v-btn color="error" text class="me-4" @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn :loading="loading" depressed color="success" type="submit">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReservationGuestSearch from 'components/reservation/reservation-guest-search.vue'

export default {
  name: 'ConversationInfoAgentConfirm',
  props: ['disabled', 'reservation', 'isOwnerVacation'],
  data() {
    return {
      dialog: false,
      guestReservation: {},
      loading: false,
      confirmDisabled: false,
    }
  },
  components: { ReservationGuestSearch },
  methods: {
    async create() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      const { id } = this.reservation
      const payload = {
        ...this.guestReservation,
        agent_reservation_id: id,
      }
      const { data } = await this.$store.dispatch(
        'reservation/confirmAgentReservation',
        payload
      )

      this.$emit('after-confirm')
      this.loading = false
      this.dialog = false
      this.disabled = true
      window.open(
        `/dashboard/guest-experience/sales?conversation=${data.conversation.guesty_id}`,
        '_blank'
      )
    },
  },
}
</script>

<style></style>
