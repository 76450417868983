<template>
  <v-card class="mx-auto" width="400" height="200">
    <v-card-title class="align-center justify-space-between">
      <div class="d-flex align-center">
        <v-skeleton-loader
          v-if="item.image === 'loading'"
          width="60"
          height="60"
          type="card"
        />
        <v-img
          v-else
          width="60"
          height="60"
          class="rounded"
          :src="item.image"
        />
        <div class="px-3">
          <div class="text-h5 primary--text">
            {{ item.name }}
          </div>
          <div class="text-overline text-subtitle-1">
            {{ item.sub_topic.topic_name }}, {{ item.sub_topic.display_name }}
          </div>
        </div>
      </div>
      <div class="item-actions d-flex align-center">
        <edit-store-item v-if="item" :item="item" />
        <div
          :class="['overline', 'error--text', { 'success--text': item.active }]"
        >
          {{ item.active ? 'Enabled' : 'Disabled' }}
        </div>
      </div>
    </v-card-title>

    <v-card-text>
      <div class="ellipsis-2">
        {{ item.description }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import EditStoreItem from 'components/store/edit-store-item'

export default {
  name: 'StoreItemThumbnail',
  components: {
    EditStoreItem,
  },
  mixins: [CommonFunctions],
  props: ['item'],
}
</script>

<style scoped>
.item-actions {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>
