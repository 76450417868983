<template>
  <div class="text-body-2 ma-2">
    <tag
      class="mr-2"
      :bg-color="listingTaskStatusColor(subtask.status)"
      color="white"
      >{{ subtask.status }}
    </tag>
    {{ subtask.description }}
  </div>
</template>

<script>
import ColorsMixin from 'components/mixins/colors-mixin'
import Tag from 'components/common/tag.vue'

export default {
  components: { Tag },
  mixins: [ColorsMixin],
  props: ['subtask'],
}
</script>

<style>
.status {
  width: 8px;
  height: 8px;
  border-radius: 50px;
}
</style>
