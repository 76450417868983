<template>
  <svg width="60" height="60" viewBox="0 0 25 25">
    <g fill="none" fill-rule="evenodd">
      <g stroke="#272B2D" mask="url(#NoName_svg__b)" opacity="0.7">
        <path
          d="M14.318 16.313c-1.166.952-2.857.744-3.775-.464-.918-1.208-.719-2.96.448-3.912 1.166-.952 2.856-.745 3.775.464.919 1.208.719 2.96-.448 3.912h0zM13.902 8.801a1.969 1.969 0 01-2.83-.347c-.69-.907-.54-2.22.334-2.935a1.969 1.969 0 012.832.349c.69.906.54 2.22-.336 2.933h0zM12.655 9.25v2.128M16.348 17.954a1.969 1.969 0 011.757-2.247c1.132-.124 2.18.68 2.342 1.798a1.969 1.969 0 01-1.757 2.248c-1.131.124-2.18-.681-2.342-1.799zM16.701 16.654l-1.824-1.097 1.824 1.097zM7.469 15.774A1.969 1.969 0 018.583 18.4c-.44 1.05-1.652 1.577-2.708 1.177A1.969 1.969 0 014.76 16.95c.44-1.05 1.652-1.576 2.708-1.176zM8.386 16.63l1.843-1.064-1.843 1.064z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Marriot',
}
</script>

<style scoped></style>
