<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.263"
    height="24"
    viewBox="0 0 25.263 24"
  >
    <path
      class="a"
      d="M48.421,232a.421.421,0,0,0-.421.421v6.737a.421.421,0,0,0,.842,0v-6.737A.421.421,0,0,0,48.421,232Zm0,0"
      transform="translate(-45.474 -219.789)"
    />
    <path
      class="a"
      d="M6.08,8.842A.421.421,0,0,0,6.5,8.421a.363.363,0,0,0-.025-.135.421.421,0,0,0-.362-.295,2.105,2.105,0,0,1-1.9-1.937.159.159,0,0,0,0-.046V5.962a.369.369,0,0,1,0-.067V3.789H5.937a.421.421,0,0,0,0-.842H3.789a.421.421,0,0,0-.421.421V5.246a2.526,2.526,0,0,1-.842-1.878V.842H7.579A2.526,2.526,0,0,1,9.65,1.924a.421.421,0,0,0,.691-.48A3.368,3.368,0,0,0,7.579,0H2.526a.842.842,0,0,0-.842.842V3.368A3.369,3.369,0,0,0,3.394,6.316,2.948,2.948,0,0,0,4.236,8H2.947A2.947,2.947,0,0,0,0,10.947v8.421a.421.421,0,1,0,.842,0V10.947A2.105,2.105,0,0,1,2.947,8.842Zm0,0"
    />
    <path
      class="a"
      d="M89.257,186l-.3-1.162h1.373a.421.421,0,0,0,0-.842H88.415a.418.418,0,0,0-.409.522l.421,1.684a.427.427,0,0,0,.83-.2Zm0,0"
      transform="translate(-83.362 -174.316)"
    />
    <path
      class="a"
      d="M416.421,232a.421.421,0,0,0-.421.421v6.737a.421.421,0,0,0,.842,0v-6.737A.421.421,0,0,0,416.421,232Zm0,0"
      transform="translate(-394.105 -219.789)"
    />
    <path
      class="a"
      d="M297.019,8.286V4.211A4.21,4.21,0,0,0,292.809,0h-.842a4.211,4.211,0,0,0-3.217,1.5.421.421,0,1,0,.644.539,3.368,3.368,0,0,1,2.573-1.2h.842a3.368,3.368,0,0,1,3.368,3.368V8.029a1.716,1.716,0,0,1-.29-.025h-1.432a2.947,2.947,0,0,0,.88-2.109V3.368a.421.421,0,0,0-.421-.421h-3.166a.421.421,0,1,0,0,.842h2.745V5.895A2.105,2.105,0,0,1,292.59,8a.421.421,0,0,0-.362.295.363.363,0,0,0-.025.135.421.421,0,0,0,.421.421h1.154l-1.6,2.876a.422.422,0,0,0,.737.413l1.827-3.3h1.108a2.062,2.062,0,0,1,.61.118h.034a2.106,2.106,0,0,1,1.368,1.987v8.421a.421.421,0,1,0,.842,0V10.947A2.947,2.947,0,0,0,297.019,8.286Zm0,0"
      transform="translate(-273.44)"
    />
    <path
      class="a"
      d="M122.526,65.263h-1.411a3.788,3.788,0,0,0,1.309-2.084,3.368,3.368,0,0,0,1.785-2.968v-.842A3.368,3.368,0,0,0,120.842,56h-6.737a.842.842,0,0,0-.842.842v3.368a3.368,3.368,0,0,0,1.785,2.968,3.788,3.788,0,0,0,1.309,2.084h-1.411A2.947,2.947,0,0,0,112,68.211v8.421a.421.421,0,0,0,.842,0V68.211a2.105,2.105,0,0,1,2.105-2.105h7.579a2.105,2.105,0,0,1,2.105,2.105v8.421a.421.421,0,0,0,.842,0V68.211A2.947,2.947,0,0,0,122.526,65.263Zm-8.421-5.053V56.842h6.737a2.526,2.526,0,0,1,2.526,2.526v.842a2.526,2.526,0,0,1-.842,1.878V58.947a.421.421,0,0,0-.421-.421h-6.737a.421.421,0,0,0-.421.421v3.141A2.526,2.526,0,0,1,114.105,60.211Zm1.735,2.661a.159.159,0,0,0,0-.046,3.539,3.539,0,0,1-.051-.446v-3.01h5.895v3.01a3.611,3.611,0,0,1-.046.446.145.145,0,0,0,0,.046,2.947,2.947,0,0,1-5.794,0Zm0,0"
      transform="translate(-106.105 -53.053)"
    />
    <path
      class="a"
      d="M211.021,263.714a.421.421,0,0,0-.51.307l-.341,1.377h-1.028l-.328-1.364a.421.421,0,1,0-.817.2l.421,1.617-.446,4.72a.421.421,0,0,0,.143.354l1.263,1.1a.421.421,0,0,0,.556,0l1.263-1.1a.421.421,0,0,0,.143-.354l-.421-4.72.421-1.617a.421.421,0,0,0-.32-.522Zm-1.364,7.44-.842-.7.37-4.211h.91l.4,4.211Zm0,0"
      transform="translate(-197.025 -249.82)"
    />
    <path
      class="a"
      d="M160.421,312a.421.421,0,0,0-.421.421v6.737a.421.421,0,0,0,.842,0v-6.737A.421.421,0,0,0,160.421,312Zm0,0"
      transform="translate(-151.579 -295.579)"
    />
    <path
      class="a"
      d="M304.421,312a.421.421,0,0,0-.421.421v6.737a.421.421,0,1,0,.842,0v-6.737A.421.421,0,0,0,304.421,312Zm0,0"
      transform="translate(-288 -295.579)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Team',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
