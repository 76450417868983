<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.84 22.01">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="_003-profits" data-name=" 003-profits">
          <g id="Group_10220" data-name="Group 10220">
            <g id="Group_10219" data-name="Group 10219">
              <path
                id="Path_5081"
                data-name="Path 5081"
                class="cls-1"
                d="M18.72,14V6.07a.44.44,0,0,0-.48-.42H16.33a.46.46,0,0,0-.5.42h0v7.11a4.93,4.93,0,0,0-.94.11V8.39a.46.46,0,0,0-.5-.42h-1.9a.46.46,0,0,0-.5.41h0V15a4.11,4.11,0,0,0-.89,2.56,4.89,4.89,0,0,0,9.74,0A4.3,4.3,0,0,0,18.72,14ZM16.81,6.54h.93v6.94a4.22,4.22,0,0,0-.93-.23ZM13,8.86h.93V13.6a4.63,4.63,0,0,0-.93.5Zm6.34,10.52A4,4,0,0,1,16,21.11a3.72,3.72,0,0,1-3.88-3.52,3.52,3.52,0,0,1,2.4-3.25.52.52,0,0,0,.16-.06A4.25,4.25,0,0,1,16,14.06a1.51,1.51,0,0,1,.3,0h0a4,4,0,0,1,1.67.48A3.33,3.33,0,0,1,19.52,19c-.06.13-.13.25-.2.37Z"
              />
            </g>
          </g>
          <g id="Group_10222" data-name="Group 10222">
            <g id="Group_10221" data-name="Group 10221">
              <path
                id="Path_5082"
                data-name="Path 5082"
                class="cls-1"
                d="M16,17.23a.59.59,0,0,1-.52-.62.57.57,0,0,1,1.13.05.5.5,0,0,0,1,0,1.45,1.45,0,0,0-1-1.35v-.37A.5.5,0,0,0,16,14.5a.51.51,0,0,0-.44.44v.32a1.44,1.44,0,0,0-1.16,1.68s0,.06,0,.09A1.57,1.57,0,0,0,16,18.11a.56.56,0,1,1-.62.56.47.47,0,0,0-.49-.45.46.46,0,0,0-.48.43h0a1.49,1.49,0,0,0,1.2,1.4v.2a.5.5,0,0,0,.55.44.48.48,0,0,0,.43-.44V20a1.41,1.41,0,0,0,1-1.76.56.56,0,0,0,0-.12A1.6,1.6,0,0,0,16,17.23Z"
              />
            </g>
          </g>
          <g id="Group_10224" data-name="Group 10224">
            <g id="Group_10223" data-name="Group 10223">
              <path
                id="Path_5083"
                data-name="Path 5083"
                class="cls-1"
                d="M6.69,11.52H4.77a.48.48,0,0,0-.49.45v7a.47.47,0,0,0,.49.44H6.69A.47.47,0,0,0,7.18,19V12A.47.47,0,0,0,6.69,11.52Zm-.49,7H5.26V12.41H6.2Z"
              />
            </g>
          </g>
          <g id="Group_10226" data-name="Group 10226">
            <g id="Group_10225" data-name="Group 10225">
              <path
                id="Path_5084"
                data-name="Path 5084"
                class="cls-1"
                d="M10.57,10.5H8.65a.47.47,0,0,0-.49.44V19a.46.46,0,0,0,.49.44h1.92a.46.46,0,0,0,.49-.44V10.94A.47.47,0,0,0,10.57,10.5Zm-.49,8.06H9.14V11.39h.94Z"
              />
            </g>
          </g>
          <g id="Group_10228" data-name="Group 10228">
            <g id="Group_10227" data-name="Group 10227">
              <path
                id="Path_5085"
                data-name="Path 5085"
                class="cls-1"
                d="M2.81,15.35H.89a.47.47,0,0,0-.49.45V19a.47.47,0,0,0,.49.44H2.81A.47.47,0,0,0,3.3,19V15.8A.48.48,0,0,0,2.81,15.35Zm-.49,3.2H1.38V16.24h.94Z"
              />
            </g>
          </g>
          <g id="Group_10230" data-name="Group 10230">
            <g id="Group_10229" data-name="Group 10229">
              <path
                id="Path_5086"
                data-name="Path 5086"
                class="cls-1"
                d="M18.65,0H15.29a.47.47,0,0,0-.5.44.47.47,0,0,0,.49.45h2.18L12.14,5.7,10.27,4a.57.57,0,0,0-.7,0L.14,12.57a.41.41,0,0,0,0,.58l0,0a.54.54,0,0,0,.7,0L9.93,5l1.86,1.7a.52.52,0,0,0,.35.14.5.5,0,0,0,.35-.14l5.67-5.14v2a.49.49,0,0,0,.52.46.48.48,0,0,0,.46-.46v-3A.47.47,0,0,0,18.65,0Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'Sales',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
