<template>
  <v-container>
    <div class="d-flex justify-space-between align-center my-3">
      <div class="text-h6">Amenities</div>
      <v-text-field
        v-model="searchTerm"
        :label="$t('Search')"
        hide-details
        outlined
        append-icon="search"
        dense
        class="col-sm-6 col-12 px-3 mt-2 mb-2"
      />
      <div v-if="!isBulkUpdate" class="d-inline-flex">
        <v-btn
          small
          color="primary"
          :disabled="disabledSave"
          @click="updateListing"
          >Save
        </v-btn>
      </div>
    </div>
    <v-sheet max-height="700" class="overflow-y-auto px-3">
      <v-row no-gutters>
        <v-col
          v-for="amenity in list"
          :key="amenity"
          cols="12"
          sm="6"
          md="4"
          class="pa-1"
        >
          <v-checkbox
            v-model="list"
            :label="amenity"
            :value="amenity"
            color="primary"
          />
        </v-col>
      </v-row>
      <v-divider />
      <v-row no-gutters>
        <v-col
          v-for="amenity in filteredAmenities"
          :key="amenity"
          cols="12"
          sm="6"
          md="4"
          class="pa-1"
        >
          <v-checkbox
            v-model="list"
            :label="amenity"
            :value="amenity"
            color="primary"
          />
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import axios from 'axios'
import filter from 'lodash/filter'

export default {
  name: 'AmenitiesEdit',
  props: ['value', 'isChannelManager', 'isBoom', 'isBulkUpdate'],
  data() {
    return {
      list: this.value,
      amenities: [],
      disabledSave: true,
      syncing: false,
      searchTerm: '',
    }
  },
  computed: {
    filteredAmenities() {
      const valuesSet = new Set(this.list)
      return this.amenities.filter(item => {
        const matchesSearch = item
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
        const notInValues = !valuesSet.has(item)
        return matchesSearch && notInValues
      })
    },
  },
  async mounted() {
    await this.loadAmenities()
  },
  methods: {
    async loadAmenities() {
      const path = this.isChannelManager
        ? '/api/settings/cm-amenities'
        : '/api/settings/amenities'
      const { data } = await axios.get(path)
      this.amenities = data
    },
    async updateListing() {
      await this.$store.dispatch('listings/updateListing', {
        id: this.$route.params.id,
        payload: {
          amenities: this.list,
        },
      })
      if (this.isBoom) {
        await this.$store.dispatch('listings/validateListing', {
          id: this.$route.params.id,
        })
      }
      this.disabledSave = true
      await this.loadAmenities()
    },
  },
  watch: {
    list(newVal) {
      this.$emit('input', newVal)
      this.filteredAmenities
      this.disabledSave = false
    },
  },
}
</script>

<style scoped></style>
