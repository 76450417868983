import { render, staticRenderFns } from "./copy-clipboard.vue?vue&type=template&id=a2c1f8f0&scoped=true&"
import script from "./copy-clipboard.vue?vue&type=script&lang=js&"
export * from "./copy-clipboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2c1f8f0",
  null
  
)

export default component.exports