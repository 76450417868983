import axios from 'axios'

export default {
  state: {},
  mutations: {},
  actions: {
    async addSetupComment({ dispatch, commit }, { id, ...payload }) {
      commit('addCommentToSetup', { id, ...payload })
      await axios
        .post(`/api/listings-setups/${id}/comments`, payload)
        .then(() => {
          setTimeout(() => {
            dispatch('getAllListingsSetups', { status: 'All' })
          }, 250)
        })
        .catch(error => {
          console.log(error)
          alert(error)
        })
    },
    async seenSetupComments(store, id) {
      await axios.put(`/api/listings-setups/${id}/comments/seen`)
    },
  },
}
