<template>
  <v-btn
    :ripple="false"
    small
    outlined
    color="black"
    :loading="loading"
    @click="createListingTask"
  >
    <v-icon small left>add</v-icon>
    Task
  </v-btn>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ScCreateBtn',
  props: ['data', 'reviewId', 'type'],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async createListingTask() {
      this.loading = true
      const payload = {
        priority: this.data.priority,
        description: this.data.description,
        type: this.type,
        sub_tasks: this.data.tasks,
        review_id: this.reviewId,
      }
      const { data } = await axios.post('/api/listing-tasks/ai-review', payload)
      this.loading = false
      this.$emit('created', data.id)
    },
  },
}
</script>

<style scoped></style>
