<template>
  <v-card class="pa-4">
    <v-card-title class="d-flex justify-space-between align-center">
      <span class="text-h6">{{ $t('Tenant Information') }}</span>
      <v-chip
        :color="tenant.active ? 'success' : 'error'"
        small
        label
        class="ml-2"
      >
        {{ tenant.active ? $t('Active') : $t('Inactive') }}
      </v-chip>
    </v-card-title>

    <v-card-text>
      <v-list dense class="transparent">
        <v-list-item v-for="(item, key) in tenantInfo" :key="key">
          <v-list-item-content>
            <v-list-item-subtitle class="text-caption">
              {{ item.label }}
            </v-list-item-subtitle>
            <v-list-item-title class="text-body-1">
              {{ item.value }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-divider class="my-4" />

    <v-card-actions class="px-4">
      <confirmation-modal
        :text="
          $t(
            'By deactivating this tenant all listings will be unlisted and all integrations will be disabled. Are you sure?'
          )
        "
        @action="$emit('deactivate')"
      >
        <template #activator="{ on, attrs }">
          <v-btn v-if="tenant.active" color="error" v-bind="attrs" v-on="on">
            {{ $t('Deactivate Tenant') }}
          </v-btn>
        </template>
      </confirmation-modal>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConfirmationModal from '../modals/confirmation-modal.vue'

export default {
  name: 'TenantInfo',

  components: {
    ConfirmationModal,
  },

  props: {
    tenant: {
      type: Object,
      required: true,
    },
  },

  computed: {
    tenantInfo() {
      return [
        {
          label: this.$t('ID'),
          value: this.tenant.id,
        },
        {
          label: this.$t('Public ID'),
          value: this.tenant.public_id,
        },
        {
          label: this.$t('Tenant Name'),
          value: this.tenant.name,
        },
        {
          label: this.$t('Base URL'),
          value: this.tenant.base_url,
        },
      ]
    },
  },
}
</script>
