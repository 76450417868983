<template>
  <v-row>
    <v-col cols="12" lg="3">
      <service-codes v-if="listing" :listing="listing" @save="save" />
    </v-col>
    <v-col v-if="instructionsLangs[selectedLang]" cols="12" lg="9">
      <info-items-panel v-if="!loading" :listing-id="listing.id" />
      <v-card class="mt-4 pa-6">
        <v-card-title class="text-body-1 font-weight-medium pa-0 mb-2">
          Arrival Instructions
        </v-card-title>

        <language-tabs
          :key="activeLanguages.length"
          v-model="activeLanguages"
          :selected-lang="selectedLang"
          :active-tab="activeTabIndex"
          default-lang="en"
          @tab-change="handleTabChange"
          @add-language="handleAddLanguage"
          @remove-language="handleRemoveLanguage"
        />

        <div class="mt-3">
          <div class="mb-2">
            <v-textarea
              v-model="instructionsLangs[selectedLang].check_in"
              outlined
              rows="3"
              dense
              :label="$t('Instructions on how to get to the property...')"
              :messages="
                selectedLang == 'en'
                  ? ''
                  : `If this is detected as the guest's language these instructions will be displayed instead of the default`
              "
            />
          </div>
          <div class="d-flex justify-end">
            <confirmation-modal
              v-if="selectedLang !== defaultLang"
              :text="`Are you sure you want remove this language?`"
              @action="handleRemoveLanguage"
            >
              <template #activator="{ on }">
                <v-btn class="mr-2" color="error" text small v-on="on"
                  ><v-icon small left>mdi-delete</v-icon> Remove Language</v-btn
                >
              </template>
            </confirmation-modal>
            <v-btn color="success" depressed small @click="saveInstructions">
              {{ $t('Save') }}
            </v-btn>
          </div>
        </div>

        <gallery
          :thumbnails="true"
          :uploadable="true"
          :images="checkinInstructionsMedia"
          :on-change="e => addPics('checkin_instructions_media', e)"
          :remove-img="e => removePic('checkin_instructions_media', e)"
          :cols="2"
        />
      </v-card>
      <v-card class="mt-4 pa-6">
        <v-card-title class="text-body-1 font-weight-medium pa-0 mb-2">
          Departure Instructions
        </v-card-title>

        <language-tabs
          :key="activeLanguages.length"
          v-model="activeLanguages"
          :selected-lang="selectedLang"
          :active-tab="activeTabIndex"
          default-lang="en"
          @tab-change="handleTabChange"
          @add-language="handleAddLanguage"
          @remove-language="handleRemoveLanguage"
        />

        <div class="mt-3">
          <div class="mb-2">
            <v-textarea
              v-model="instructionsLangs[selectedLang].check_out"
              outlined
              rows="3"
              dense
              :label="$t('Instruction on how to leave the property...')"
              :messages="
                selectedLang == 'en'
                  ? ''
                  : `If this is detected as the guest's language these instructions will be displayed instead of the default`
              "
            />
          </div>
          <div class="d-flex justify-end">
            <confirmation-modal
              v-if="selectedLang !== defaultLang"
              :text="`Are you sure you want remove this language?`"
              @action="handleRemoveLanguage"
            >
              <template #activator="{ on }">
                <v-btn class="mr-2" color="error" text small v-on="on"
                  ><v-icon small left>mdi-delete</v-icon> Remove Language</v-btn
                >
              </template>
            </confirmation-modal>
            <v-btn color="success" depressed small @click="saveInstructions">
              {{ $t('Save') }}
            </v-btn>
          </div>
        </div>

        <gallery
          :thumbnails="true"
          :uploadable="true"
          :images="checkoutInstructionsMedia"
          :on-change="e => addPics('checkout_instructions_media', e)"
          :remove-img="e => removePic('checkout_instructions_media', e)"
          :cols="2"
        />
      </v-card>
      <v-card class="mt-4 pa-6">
        <div>
          <div class="text-body-1 font-weight-medium mb-1">
            Custom Address Link Url
          </div>
          <v-text-field v-model="customAddressUrl" outlined rows="3" dense>
            <template #append>
              <v-btn
                color="primary"
                depressed
                small
                :disabled="disableCustomAddressUrlSave"
                @click="saveCustomAddressUrl"
                >Save
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <div>
          <div class="text-body-1 font-weight-medium mb-1">
            <div>Travel Guide Url</div>
          </div>

          <v-text-field
            v-model="travelGuideUrl"
            outlined
            rows="3"
            dense
            messages="Accepts embeddable links only (e.g. Notion, Google embedded links)"
          >
            <template #append>
              <v-btn
                color="primary"
                depressed
                small
                :disabled="disableTravelGuideUrlSave"
                @click="saveTravelGuideUrl"
                >Save
              </v-btn>
            </template>
          </v-text-field>
        </div>
        <div>
          <div
            class="text-body-1 font-weight-medium mb-1 d-flex justify-space-between"
          >
            <div>
              Terms of Use
              <span class="text--secondary text-caption"
                >(Overrides Default Terms of Use)</span
              >
            </div>
            <div>
              <v-btn
                color="primary"
                depressed
                small
                :disabled="disableTermsOfUseSave"
                @click="saveTermsOfUse"
                >Save</v-btn
              >
            </div>
          </div>
          <text-editor
            :key="initialTermsOfUse"
            v-model="termsOfUse"
            autofocus
            :show-save-button="false"
          />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import InfoItemsPanel from './info-items-panel.vue'
import ServiceCodes from 'components/service-codes/service-codes.vue'
import Gallery from 'components/common/gallery'
import TextEditor from 'components/common/text-editor'
import { mapState } from 'vuex'
import LanguageTabs from 'components/common/language-tabs'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  name: 'Services',
  components: {
    InfoItemsPanel,
    ServiceCodes,
    Gallery,
    TextEditor,
    LanguageTabs,
    ConfirmationModal,
  },
  mixins: [PermissionsMixin, DeviceMixin],
  props: {
    objName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    enableSave: false,
    newFact: null,
    instructionsLangs: {},
    checkin_instructions_media: [],
    checkout_instructions_media: [],
    customAddressUrl: null,
    travelGuideUrl: null,
    termsOfUse: '',
    initialTermsOfUse: null,
    initialCustomAddressUrl: null,
    initialTravelGuideUrl: null,
    activeLanguages: ['en'],
    selectedLang: 'en',
    activeTabIndex: 0,
    defaultLang: 'en',
  }),
  mounted() {
    const {
      instructions_langs = {},
      custom_address_url,
      travel_guide_url,
      checkin_instructions_media,
      checkout_instructions_media,
      terms_of_use,
    } = this.listing.extra_info

    this.instructionsLangs = {
      en: {
        check_in:
          instructions_langs.en?.check_in ||
          this.listing.checkin_instructions ||
          '',
        check_out:
          instructions_langs.en?.check_out ||
          this.listing.extra_info.boom_checkout_instructions ||
          '',
      },
      ...instructions_langs,
    }

    this.activeLanguages = Object.keys(this.instructionsLangs)

    this.checkin_instructions_media = checkin_instructions_media || []
    this.checkout_instructions_media = checkout_instructions_media || []
    this.customAddressUrl = custom_address_url
    this.initialCustomAddressUrl = custom_address_url
    this.travelGuideUrl = travel_guide_url
    this.initialTravelGuideUrl = travel_guide_url
    this.termsOfUse = terms_of_use || ''
    this.initialTermsOfUse = terms_of_use
  },
  computed: {
    ...mapState(['loading']),
    listing() {
      return this.$store.state[this.objName]
    },
    disableCustomAddressUrlSave() {
      return this.customAddressUrl === this.initialCustomAddressUrl
    },
    disableTravelGuideUrlSave() {
      return this.travelGuideUrl === this.initialTravelGuideUrl
    },
    disableTermsOfUseSave() {
      console.log(this.termsOfUse, this.initialTermsOfUse)
      return this.termsOfUse === this.initialTermsOfUse
    },
    checkinInstructionsMedia() {
      return this.checkin_instructions_media.map(media => media.src || media)
    },
    checkoutInstructionsMedia() {
      return this.checkout_instructions_media.map(media => media.src || media)
    },
  },
  methods: {
    save(services) {
      this.$store.dispatch('updateListingServices', {
        id: this.listing.id,
        services,
      })
      this.enableSave = false
    },
    updateField(payload) {
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload,
      })
    },
    saveCustomAddressUrl() {
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload: { custom_address_url: this.customAddressUrl },
      })
      this.initialCustomAddressUrl = this.customAddressUrl
    },
    saveTravelGuideUrl() {
      this.updateField({ travel_guide_url: this.travelGuideUrl })
      this.initialTravelGuideUrl = this.travelGuideUrl
    },
    saveTermsOfUse() {
      this.updateField({ terms_of_use: this.termsOfUse })
      this.initialTermsOfUse = this.termsOfUse
    },
    handleAddLanguage(langCode) {
      if (!this.activeLanguages.includes(langCode))
        this.activeLanguages.push(langCode)
      const defaults = { check_in: '', check_out: '' }

      if (!this.instructionsLangs[langCode]) {
        this.$set(this.instructionsLangs, langCode, defaults)
      }

      this.saveInstructions(langCode)
    },
    handleRemoveLanguage() {
      const selected = this.selectedLang
      this.activeLanguages = this.activeLanguages.filter(l => l !== selected)
      this.selectedLang = this.activeLanguages[0]
      this.$delete(this.instructionsLangs, selected)
      this.saveInstructions(selected)
    },
    saveInstructions() {
      const payload = {
        instructions_langs: this.instructionsLangs,
      }
      this.updateField(payload)
    },
    handleTabChange(langCode) {
      this.selectedLang = langCode
      this.activeTabIndex = this.activeLanguages.indexOf(langCode)
    },
    addPics(field, pics) {
      this[field] = [...this[field], ...pics]
      this.updateField({ [field]: this[field] })
    },
    removePic(field, pic) {
      this[field] = this[field].filter(p => pic !== (p.src || p))
      this.updateField({ [field]: this[field] })
    },
  },
}
</script>
<style scoped>
:deep() .v-input__append-inner {
  margin: 8px 0;
}
</style>
