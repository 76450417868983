<template>
  <v-card class="my-4">
    <v-card-title class="d-flex justify-space-between align-center">
      iCal Links Manager
      <div class="d-flex align-center">
        <v-btn color="primary" small @click="showAddDialog = true">
          <v-icon left>mdi-plus</v-icon>
          Add iCal Link
        </v-btn>
        <v-btn color="info" text class="mr-2" @click="showHelpDialog = true">
          <v-icon left>mdi-help-circle</v-icon>
          iCal Help
        </v-btn>
      </div>
    </v-card-title>

    <v-card-text>
      <!-- Main Table -->
      <v-data-table
        :headers="headers"
        :items="currentListing.icals"
        :loading="loading"
        dense
      >
        <!-- URL Column -->
        <template #item.url="{ item }">
          <div class="url-cell">
            <span class="text-truncate">{{ item.url }}</span>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="copyUrl(item.url)"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>Copy URL</span>
            </v-tooltip>
          </div>
        </template>

        <!-- Active Column -->
        <template #item.active="{ item }">
          <v-switch
            v-model="item.active"
            class="mt-0"
            dense
            hide-details
            @change="updateStatus(item)"
          ></v-switch>
        </template>

        <!-- Sync Column -->
        <template #item.sync_on="{ item }">
          <v-switch
            v-model="item.sync_on"
            class="mt-0"
            dense
            hide-details
            @change="updateStatus(item)"
          ></v-switch>
        </template>

        <!-- Actions Column -->
        <template #item.actions="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="error"
                v-bind="attrs"
                v-on="on"
                @click="confirmDelete(item)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <!-- Add Dialog -->
    <v-dialog v-model="showAddDialog" max-width="500px">
      <v-card>
        <v-card-title>Add New iCal Link</v-card-title>
        <v-card-text>
          <v-form ref="addForm" v-model="validForm">
            <v-radio-group v-model="newItem.type" row class="mt-0">
              <v-radio label="VRBO" value="vrbo"></v-radio>
              <v-radio label="Other Channel" value="other"></v-radio>
            </v-radio-group>

            <v-text-field
              v-if="newItem.type === 'other'"
              v-model="newItem.name"
              dense
              outlined
              label="Name"
              :rules="[required]"
              required
            />

            <v-text-field
              v-model="newItem.url"
              label="iCal URL"
              dense
              outlined
              :rules="urlRules"
              required
            />

            <v-switch
              v-model="newItem.active"
              dense
              label="Active"
              class="mt-0"
            />

            <v-switch
              v-model="newItem.sync_on"
              dense
              label="Enable Sync"
              class="mt-0"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showAddDialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            :disabled="!validForm"
            @click.prevent="saveNew"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Dialog -->
    <v-dialog v-model="showEditDialog" max-width="500px">
      <v-card>
        <v-card-title>Edit iCal Link</v-card-title>
        <v-card-text>
          <v-form ref="editForm" v-model="validForm">
            <v-text-field
              v-model="editedItem.name"
              label="Name"
              outlined
              dense
              disabled
            />

            <v-text-field
              v-model="editedItem.url"
              outlined
              dense
              label="iCal URL"
              :rules="urlRules"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showEditDialog = false"> Cancel </v-btn>
          <v-btn color="primary" :disabled="!validForm" @click="saveEdit">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showHelpDialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">
          <v-icon left color="info">mdi-calendar-sync</v-icon>
          How to Use iCal Sync
        </v-card-title>

        <v-card-text class="pt-4">
          <div class="mb-4">
            <div class="text-h6 mb-2">Your Calendar Export URL</div>
            <v-sheet
              class="d-flex align-center pa-3 rounded"
              color="grey lighten-4"
            >
              <div class="text-truncate">{{ calendarExportUrl }}</div>
              <v-btn
                v-tooltip="'Copy URL'"
                icon
                color="primary"
                class="ml-2"
                @click="copyExportUrl"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-sheet>
          </div>

          <div class="text-h6 mb-2">Importing Calendar Links</div>
          <v-card outlined class="mb-4">
            <v-list dense>
              <v-list-item>
                <v-list-item-icon class="mr-2">
                  <v-icon color="primary">mdi-numeric-1-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Get the iCal URL from your channel (Airbnb, VRBO,
                    etc.)</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="mr-2">
                  <v-icon color="primary">mdi-numeric-2-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Click "Add iCal Link" and paste the URL</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="mr-2">
                  <v-icon color="primary">mdi-numeric-3-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >Select the channel type and save</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-alert class="mt-4" color="info" text dense>
            <div class="font-weight-medium">Note:</div>
            Calendar sync occurs every 30 minutes. Status changes may take up to
            30 minutes to reflect across all platforms.
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showHelpDialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="showDeleteDialog" max-width="400px">
      <v-card>
        <v-card-title class="text-h5">Confirm Delete</v-card-title>
        <v-card-text>
          Are you sure you want to delete this iCal link?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showDeleteDialog = false"> Cancel </v-btn>
          <v-btn color="error" @click="deleteItem"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for notifications -->
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" :timeout="3000">
      {{ snackbarText }}
    </v-snackbar>
  </v-card>
</template>

<script>
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import axios from 'axios'

export default {
  name: 'IcalManager',

  props: {
    listingId: {
      type: Number,
      required: true,
    },
  },
  mixins: [FormRulesMixin],
  computed: {
    currentListing() {
      return this.$store.state.currentEditListing || {}
    },
    calendarExportUrl() {
      return this.currentListing.ical_link
    },
  },
  data() {
    return {
      loading: false,
      icalLinks: [],
      headers: [
        { text: 'Name', value: 'name', width: '20%' },
        { text: 'URL', value: 'url', width: '40%' },
        { text: 'Active', value: 'active', width: '10%' },
        { text: 'Sync', value: 'sync_on', width: '10%' },
        { text: 'Actions', value: 'actions', sortable: false, width: '20%' },
      ],
      showAddDialog: false,
      showHelpDialog: false,
      showEditDialog: false,
      showDeleteDialog: false,
      validForm: false,
      newItem: {
        type: 'other',
        name: '',
        url: '',
        active: true,
        sync_on: true,
      },
      editedItem: {},
      itemToDelete: null,
      showSnackbar: false,
      snackbarText: '',
      snackbarColor: 'success',
    }
  },

  methods: {
    editItem(item) {
      this.editedItem = { ...item }
      this.showEditDialog = true
    },
    copyExportUrl() {
      navigator.clipboard.writeText(this.calendarExportUrl)
    },
    confirmDelete(item) {
      this.itemToDelete = item
      this.showDeleteDialog = true
    },

    async saveNew() {
      if (!this.$refs.addForm.validate()) return

      const data = {
        listing_id: this.listingId,
        name: this.newItem.type === 'vrbo' ? 'VRBO' : this.newItem.name,
        url: this.newItem.url,
        active: this.newItem.active,
        sync_on: this.newItem.sync_on,
      }
      await axios.post(`/api/listings/${this.listingId}/icals`, data)
      this.$refs.addForm.reset()
      this.$store.dispatch('changeEditListing', this.listingId)
      this.showAddDialog = false
    },

    async saveEdit() {
      if (!this.$refs.editForm.validate()) return

      try {
        // Replace with your actual API call
        await axios.put(
          `/api/listings/${this.listingId}/icals/${this.editedItem.id}`,
          this.editedItem
        )
        this.$refs.editForm.reset()
        this.$store.dispatch('changeEditListing', this.listingId)
        this.showEditDialog = false
      } catch (error) {
        return
      }
    },

    async deleteItem() {
      try {
        // Replace with your actual API call
        await axios.delete(
          `/api/listings/${this.listingId}/icals/${this.itemToDelete.id}`
        )
        this.$store.dispatch('changeEditListing', this.listingId)
        this.showDeleteDialog = false
        this.itemToDelete = null
      } catch (error) {
        return
      }
    },

    async updateStatus(item) {
      try {
        // Replace with your actual API call
        await axios.put(`/api/listings/${this.listingId}/icals/${item.id}`, {
          active: item.active,
          sync_on: item.sync_on,
        })
      } catch (error) {
        item.active = !item.active
      }
    },
    copyUrl(url) {
      navigator.clipboard.writeText(url)
    },
  },
}
</script>

<style scoped>
.url-cell {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 100%;
}

.url-cell span {
  flex: 1;
  overflow: hidden;
}
</style>
