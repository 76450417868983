<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.111"
    height="21"
    viewBox="0 0 24.111 21"
  >
    <g transform="translate(-8 -40)">
      <path
        class="a"
        d="M31.139,44.667h-.583V40.389A.389.389,0,0,0,30.167,40H9.944a.389.389,0,0,0-.389.389v1.167H8.972A.973.973,0,0,0,8,42.528v17.5A.973.973,0,0,0,8.972,61H31.139a.973.973,0,0,0,.972-.972V45.639A.973.973,0,0,0,31.139,44.667ZM10.333,40.778H29.778v3.889H17.962l-1.448-2.9a.389.389,0,0,0-.348-.215H10.333Zm21,19.25a.194.194,0,0,1-.194.194H8.972a.194.194,0,0,1-.194-.194v-17.5a.194.194,0,0,1,.194-.194h6.954l1.448,2.9a.389.389,0,0,0,.348.215H31.139a.194.194,0,0,1,.194.194Z"
      />
      <path
        class="a"
        d="M141.833,184a5.833,5.833,0,1,0,5.833,5.833A5.833,5.833,0,0,0,141.833,184Zm0,10.889a5.056,5.056,0,1,1,5.056-5.056A5.056,5.056,0,0,1,141.833,194.889Z"
        transform="translate(-121.778 -137)"
      />
      <path
        class="a"
        d="M197.945,264.114l-3.643,3.643-1.673-1.338a.389.389,0,0,0-.486.607l1.944,1.556a.389.389,0,0,0,.518-.029l3.889-3.889a.389.389,0,0,0-.55-.55Z"
        transform="translate(-175.054 -213.111)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Complete',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
