<template>
  <v-dialog v-model="dialog" width="400">
    <template #activator="{ on, attrs }">
      <v-btn class="dark-cyan white--text" v-bind="attrs" v-on="on">
        <v-icon left> star </v-icon>
        <span
          v-text="
            trackedListingTask.customer_rating
              ? 'Change your rating'
              : 'Rate your service'
          "
        ></span>
      </v-btn>
    </template>
    <v-card
      v-if="trackedListingTask.status === 'Done'"
      class="elevation-16 mx-auto"
      width="400"
      justify-center
    >
      <v-card-title class="text-h5 justify-center">
        Rate Our Service
      </v-card-title>
      <v-card-text>
        Please take a few seconds to rate your experience with the service
        provider. It really helps!

        <div class="text-center mt-12">
          <v-rating
            v-model="trackedListingTask.customer_rating"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$ratingFull"
            half-increments
            hover
            large
          ></v-rating>
        </div>
        <div
          v-if="trackedListingTask.customer_rating"
          class="text-center mt-12"
        >
          <v-textarea
            v-model="trackedListingTask.customer_rating_desc"
            filled
            name="input-7-1"
            label="Any comments/recommendations?"
          ></v-textarea>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn text @click="dialog = false"> Close </v-btn>
        <v-btn
          :disabled="!trackedListingTask.customer_rating"
          color="primary"
          text
          @click="addRating"
        >
          Rate Now
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  props: ['trackedListingTask'],
  validations: {},
  data() {
    return {
      dialog: true,
    }
  },
  methods: {
    addRating() {
      let data = {
        id: this.$router.currentRoute.params.id,
        customer_rating: this.trackedListingTask.customer_rating,
      }
      if (this.trackedListingTask.customer_rating_desc) {
        data.customer_rating_desc = this.trackedListingTask.customer_rating_desc
      }
      axios
        .post('/api/track-lt/add-customer-rating', data)
        .then(() => {
          this.dialog = null
        })
        .catch(error => {
          alert(error)
        })
    },
  },
}
</script>
