<template>
  <svg
    id="_004-money-bag"
    data-name="004-money-bag"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <path
      id="Path_6674"
      data-name="Path 6674"
      d="M92.668,4.977h6.126a14.567,14.567,0,0,1,2.433-3.582.778.778,0,0,0,.18-.886A.818.818,0,0,0,100.653,0a4.737,4.737,0,0,0-2.92.952,3.024,3.024,0,0,1-2,.689,3.024,3.024,0,0,1-2-.689A4.737,4.737,0,0,0,90.81,0a.818.818,0,0,0-.755.509.778.778,0,0,0,.18.886A14.565,14.565,0,0,1,92.668,4.977Z"
      transform="translate(-85.067 0)"
      fill="#1956b7"
    />
    <path
      id="Path_6675"
      data-name="Path 6675"
      d="M301,452.82v1.641a.82.82,0,0,0,.82.82h9.9a.82.82,0,0,0,.82-.82V452.82a.82.82,0,0,0-.82-.82h-9.9A.82.82,0,0,0,301,452.82Z"
      transform="translate(-284.539 -427.281)"
      fill="#1956b7"
    />
    <path
      id="Path_6676"
      data-name="Path 6676"
      d="M312.539,364.461V362.82a.82.82,0,0,0-.82-.82h-9.9a.82.82,0,0,0-.82.82v1.641a.82.82,0,0,0,.82.82h9.9A.82.82,0,0,0,312.539,364.461Z"
      transform="translate(-284.539 -342.203)"
      fill="#1956b7"
    />
    <path
      id="Path_6677"
      data-name="Path 6677"
      d="M16.357,127.65c-1.608-1.575-2.92-2.909-3.183-5.009h.771a.82.82,0,1,0,0-1.641H7.383a.82.82,0,1,0,0,1.641h.771c-.263,2.1-1.575,3.434-3.183,5.009C2.641,129.93,0,132.523,0,138.445a4.082,4.082,0,0,0,4.1,3.938H14.917a2.433,2.433,0,0,1-.151-.82V135a2.464,2.464,0,0,1,2.461-2.461h2.938A16.643,16.643,0,0,0,16.357,127.65Zm-5.693,4.889a2.455,2.455,0,0,1,.82,4.771v.971a.82.82,0,0,1-1.641,0v-.971A2.457,2.457,0,0,1,8.2,135a.82.82,0,0,1,1.641,0,.82.82,0,1,0,.82-.82,2.455,2.455,0,0,1-.82-4.771v-.971a.82.82,0,0,1,1.641,0v.971a2.457,2.457,0,0,1,1.641,2.31.82.82,0,0,1-1.641,0,.82.82,0,1,0-.82.82Z"
      transform="translate(0 -114.383)"
      fill="#1956b7"
    />
  </svg>
</template>

<script>
export default {
  name: 'PurchasePrice',
}
</script>

<style scoped>
.a {
  fill: #dfe1e6;
}

.b {
  fill: currentColor;
}
</style>
