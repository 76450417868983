<template>
  <v-flex xs12>
    <v-card>
      <v-card-title>
        Optional matches
        <v-progress-circular
          v-show="listingLoading"
          :size="20"
          class="ml-2"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-flex ml-3>
          <v-checkbox
            v-model="showOnlyMy"
            hide-details
            label="Only by me"
          ></v-checkbox>
        </v-flex>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-12 center-text"
        :options.sync="pagination"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template #item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td>
              {{ attachementType(item) }}
              <span v-if="item.bank_transaction_id" class="small-text"
                >* Reconciled</span
              >
              <span v-if="item.dismissed_by" class="small-text"
                >* Dismissed</span
              >
              <span v-if="item.hidden" class="cyan--text"> Hidden </span>
            </td>
            <td>{{ item.diff }}</td>

            <td>{{ attachementCreatedBy(item) }}</td>

            <td>{{ listingName(item) }}</td>
            <td>{{ attachementPrice(item) }}</td>
            <td>{{ parseDate(attachementDate(item)) }}</td>
            <td>
              <span v-if="!attachementDesc(item).includes('.com')">
                {{ attachementDesc(item) }}
              </span>
              <a
                v-if="attachementDesc(item).includes('.com')"
                target="_blank"
                :href="attachementDesc(item)"
              >
                {{ item.confirmation_code }}
              </a>
            </td>

            <td>
              <v-btn outlined class="cyan-icon" @click="addMatch(item)"
                >Attach
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-flex>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import filter from 'lodash/filter'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['listingId'],
  data: function () {
    return {
      showOnlyMy: false,
      sortBy: 'diff',
      sortDesc: false,
      Payment: null,
      pagination: {
        itemsPerPage: 10,
      },
      headers: [
        { text: 'ID', align: 'center', sortable: false },
        { text: 'Type', align: 'center', sortable: false },
        { text: 'Diff', align: 'center', value: 'diff' },
        { text: 'Created by', align: 'center', sortable: false },
        { text: 'Listing', align: 'center', sortable: false },
        { text: 'Amount', align: 'center', value: 'amount' },
        { text: 'Date', align: 'center', value: 'paid_at' },
        { text: 'Info', align: 'center', sortable: false },
        { text: 'Actions', align: 'center', sortable: false },
      ],
    }
  },
  computed: {
    items: {
      get: function () {
        if (this.showOnlyMy) {
          let userID = this.$store.state.user.id
          return filter(
            this.$store.state.currentBankTransactionsOptions,
            function (option) {
              return !option.user_id || option.user_id === userID
            }
          )
        } else {
          return this.$store.state.currentBankTransactionsOptions
        }
      },
    },
  },
  methods: {
    async addMatch(item) {
      await this.$store.dispatch('addMatch', item)
      this.$store.dispatch('getOptionalMatch', {
        id: this.listingId,
      })
    },
  },
}
</script>
