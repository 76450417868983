import { render, staticRenderFns } from "./estimates-config-side-bar.vue?vue&type=template&id=788bd8d3&scoped=true&"
import script from "./estimates-config-side-bar.vue?vue&type=script&lang=js&"
export * from "./estimates-config-side-bar.vue?vue&type=script&lang=js&"
import style0 from "./estimates-config-side-bar.vue?vue&type=style&index=0&id=788bd8d3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "788bd8d3",
  null
  
)

export default component.exports