<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.003"
    height="12"
    viewBox="0 0 10.003 12"
  >
    <path
      id="Icon_ionic-ios-flag"
      data-name="Icon ionic-ios-flag"
      d="M16.459,5.025c-.1.016-.2.031-.309.044a18.421,18.421,0,0,1-1.894.162,11.5,11.5,0,0,1-2.444-.381A11.969,11.969,0,0,0,9.294,4.5a4.615,4.615,0,0,0-2.416.419l-.128.091V16.05a.446.446,0,0,0,.384.447.437.437,0,0,0,.491-.434V11.237a.254.254,0,0,1,.2-.247,7.583,7.583,0,0,1,1.469-.122,11.7,11.7,0,0,1,2.394.484,11.018,11.018,0,0,0,2.606.359,14.816,14.816,0,0,0,2.244-.206.252.252,0,0,0,.216-.247V5.272A.254.254,0,0,0,16.459,5.025Z"
      transform="translate(-6.75 -4.5)"
      class="fillColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'Flag',
}
</script>

<style scoped>
.fillColor {
  fill: currentColor;
}
</style>
