import axios from 'axios'

export default {
  namespaced: true,
  state: {
    storages: [],
    storesNames: [],
  },
  mutations: {
    setStoresNames(state, payload) {
      state.storesNames = payload
    },
    updateStorages(state, payload) {
      state.storages = payload
    },
  },
  actions: {
    async getStorages({ commit }) {
      try {
        const { data } = await axios.get(`/api/storages`)
        commit('updateStorages', data)
      } catch (err) {
        console.log(err)
      }
    },
    async getStoresNames({ commit }) {
      try {
        const { data } = await axios.get(`/api/stores-names`)
        commit('setStoresNames', data)
      } catch (err) {
        console.log(err)
      }
    },
    async createStorage({ dispatch }, data) {
      try {
        await axios.post(`/api/storages`, data)
        dispatch('getStorages')
      } catch (err) {
        console.log(err)
      }
    },
    async updateStorage({ dispatch }, { id, data }) {
      try {
        await axios.post(`/api/storages/${id}`, data)
        dispatch('getStorages')
      } catch (err) {
        console.log(err)
      }
    },
    async deactivateStorage({ dispatch }, id) {
      try {
        await axios.delete(`/api/storages/${id}`)
        dispatch('getStorages')
      } catch (err) {
        console.log(err)
      }
    },
  },
  getters: {
    shoppingItemsResources(state) {
      return state.storages.map(s => s.nickname).concat(state.storesNames)
    },
    sortedStorages(state) {
      return state.storages.sort((a, b) =>
        a.nickname.toLowerCase().localeCompare(b.nickname.toLowerCase())
      )
    },
    storageNames(state, getters) {
      return getters.sortedStorages.map(s => s.nickname)
    },
    storageNamesWithAll(state, getters) {
      return ['All'].concat(...getters.storageNames)
    },
  },
}
