var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"dense":"","label":"Type","items":[
          'ListingIncome',
          'Payment',
          'Expense',
          'AutoListingTaskRule',
          'AppSetting',
          'Reservation',
          'Listing',
          'ChannelListing',
        ],"clearable":"","outlined":"","hide-details":""},on:{"change":function($event){return _vm.fetchDataDebounce(1)}},model:{value:(_vm.filters.item_type),callback:function ($$v) {_vm.$set(_vm.filters, "item_type", $$v)},expression:"filters.item_type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"label":"Event","items":['create', 'update', 'destroy'],"clearable":"","dense":"","outlined":"","hide-details":""},on:{"change":function($event){return _vm.fetchDataDebounce(1)}},model:{value:(_vm.filters.event),callback:function ($$v) {_vm.$set(_vm.filters, "event", $$v)},expression:"filters.event"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"label":"Item Id","dense":"","outlined":"","clearable":"","hide-details":""},on:{"input":function($event){return _vm.fetchDataDebounce(1)}},model:{value:(_vm.filters.item_id),callback:function ($$v) {_vm.$set(_vm.filters, "item_id", $$v)},expression:"filters.item_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[(
          _vm.filters.item_type === 'All' || !_vm.modelsColumnNames[_vm.filters.item_type]
        )?_c('v-text-field',{attrs:{"label":"Changed Field","outlined":"","dense":"","clearable":"","hide-details":""},on:{"input":function($event){return _vm.fetchDataDebounce(1)}},model:{value:(_vm.filters.changed_field),callback:function ($$v) {_vm.$set(_vm.filters, "changed_field", $$v)},expression:"filters.changed_field"}}):_c('v-select',{attrs:{"label":"Changed Field","items":_vm.modelsColumnNames[_vm.filters.item_type],"clearable":"","outlined":"","hide-details":""},on:{"change":function($event){return _vm.fetchDataDebounce(1)}},model:{value:(_vm.filters.changed_field),callback:function ($$v) {_vm.$set(_vm.filters, "changed_field", $$v)},expression:"filters.changed_field"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-text-field',{attrs:{"label":"Old Value","outlined":"","dense":"","clearable":"","hide-details":"","disabled":!_vm.filters.changed_field},on:{"input":function($event){return _vm.fetchDataDebounce(1)}},model:{value:(_vm.filters.changed_value_old),callback:function ($$v) {_vm.$set(_vm.filters, "changed_value_old", $$v)},expression:"filters.changed_value_old"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[_c('v-text-field',{attrs:{"label":"New Value","outlined":"","clearable":"","dense":"","hide-details":"","disabled":!_vm.filters.changed_field},on:{"input":function($event){return _vm.fetchDataDebounce(1)}},model:{value:(_vm.filters.changed_value_new),callback:function ($$v) {_vm.$set(_vm.filters, "changed_value_new", $$v)},expression:"filters.changed_value_new"}})],1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-1 w-100 mx-3 no-hover",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.pagination.itemsLength,"loading":_vm.fetchingData,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100],
      }},on:{"pagination":function($event){return _vm.paginationChanged($event)}},scopedSlots:_vm._u([{key:"item.created_at",fn:function({ item }){return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.parseDateLocalTime(item.created_at))+" ")])]}},{key:"item.whodunnit",fn:function({ item }){return [_vm._v(" "+_vm._s(item.whodunnit?.full_name || 'System')+" ")]}},{key:"item.object_changes",fn:function({ item }){return [_c('div',[(item.event == 'info_log')?_c('div',{staticClass:"my-2 py-1 px-2 overflow-auto changes-row"},_vm._l((item.object_changes),function(change,i){return _c('div',{key:i,staticClass:"text-caption"},[_c('div',[_c('span',{staticClass:"font-weight-medium me-1"},[_vm._v(_vm._s(i)+":")]),_c('span',{staticClass:"mx-1 value-pill amber lighten-5"},[_vm._v(_vm._s(String(change)))])])])}),0):_c('div',{staticClass:"my-2 py-1 px-2 overflow-auto changes-row"},_vm._l((item.object_changes),function(change,i){return _c('div',{key:i,staticClass:"text-caption"},[_c('div',[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v(_vm._s(i)+":")]),_c('span',{staticClass:"mx-1 value-pill red lighten-5"},[_vm._v(_vm._s(String(change[0])))]),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-arrow-right")]),_c('span',{staticClass:"mx-1 value-pill green lighten-5"},[_vm._v(_vm._s(String(change[1])))])],1)])}),0)])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }