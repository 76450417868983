<template>
  <v-container
    :class="[
      { grey: !isMobile },
      { 'lighten-3': !isMobile },
      'pa-4',
      'pa-sm-12',
      'login-wrapper',
    ]"
    fluid
  >
    <v-row justify="center">
      <v-col cols="12" md="6" lg="5" xl="4">
        <v-card
          class="rounded-xl text-center px-4 py-2 pa-sm-10"
          :elevation="isMobile ? 0 : 12"
        >
          <div v-if="!redirectUrl">
            <img class="mb-6" :src="logo" height="64" />
            <h2 class="text-h4 font-weight-medium mb-5 px-3 text-center">
              Reset password
            </h2>
            <v-form ref="resetPassword" lazy-validation @submit="resetPassword">
              <v-text-field
                v-model="password"
                name="password"
                :rules="passwordRules"
                label="New password"
                type="password"
                outlined
              />
              <v-text-field
                v-model="passwordConfirmation"
                name="repeatPassword"
                label="Confirm new password"
                :rules="
                  passwordRules.concat(
                    password === passwordConfirmation ||
                      'Passwords are not matched'
                  )
                "
                type="password"
                outlined
              />
              <div v-if="error" class="text-caption error--text">
                {{ error }}
              </div>
              <v-btn type="submit" color="primary" elevation="0" block x-large>
                <span class="text--secondary text-capitalize">
                  Reset password
                </span>
              </v-btn>
            </v-form>
          </div>
          <div v-else class="text-center">
            <v-icon :size="isMobile ? 180 : 260">$lock_checked</v-icon>
            <h3 class="text-h5 text-sm-h4 font-weight-bold mb-5">
              Successful password reset
            </h3>
            <p class="mb-6">
              You can now use your new password to log in to your account.
            </p>
            <v-row justify="center">
              <v-col cols="12" sm="6" md="8" lg="6">
                <v-btn
                  class="text-capitalize mb-4"
                  color="primary"
                  elevation="0"
                  block
                  large
                  @click="redirect"
                >
                  OK
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import formRules from 'components/mixins/form-rules-mixin'
import deviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'ResetPasswordPage',
  mixins: [formRules, deviceMixin],
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      error: '',
      redirectUrl: '',
    }
  },
  computed: {
    logo() {
      return this.$route.query.logo
    },
  },
  methods: {
    resetPassword(e) {
      e.preventDefault()
      const { password, passwordConfirmation } = this
      const isValid = this.$refs.resetPassword.validate()
      const token = this.$route.query.token
      if (isValid) {
        axios
          .post('/api/reset-password', {
            token,
            password,
            passwordConfirmation,
          })
          .then(({ data }) => {
            this.redirectUrl = data.redirectURL
          })
          .catch(error => {
            if (error.response) {
              this.error = error.response.data.error
            }
          })
      }
    },
    redirect() {
      window.location.replace(this.redirectUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.login-wrapper {
  min-height: 100%;
}
</style>
