<template>
  <div>
    <v-sheet
      v-if="thumbnail"
      class="align-center upload-box rounded justify-center d-flex flex-column"
      :height="width"
      :width="width"
      @click="openFileInputDialog"
    >
      <v-icon color="secondary">upload</v-icon>
      <div class="secondary--text">{{ $t('Upload') }}</div>
    </v-sheet>
    <v-file-input
      v-show="!thumbnail"
      ref="file"
      v-model="files"
      small-chips
      clearable
      outlined
      dense
      :rules="rules"
      :label="label"
      prepend-icon=""
      prepend-inner-icon="mdi-file"
      :multiple="multiple"
      accept="image/png,image/jpeg,image/jpg,image/webp,video/*,.pdf"
      @change="fileInputChanged"
    />
    <div v-if="filesLoading">
      <div class="mb-0">Uploading...</div>
      <v-progress-linear v-model="imageProgress" color="primary" height="25">
        <template #default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </div>
    <v-row v-if="preview">
      <v-col v-for="file in value" :key="file" cols="2">
        <image-view :readonly="true" small-width="100%" :src="file" />
      </v-col>
    </v-row>
    <v-alert
      v-else-if="!filesLoading && imageProgress === 100"
      border="top"
      type="success"
    >
      {{ $t('Images successfully uploaded') }}
    </v-alert>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ImageView from 'components/common/image-view'
import { uploadToCloudinary } from '@/utils/uploadToCloudinary'

export default {
  name: 'DvrMediaUpload',
  components: { ImageView },
  mixins: [CommonFunctions, PermissionsMixin],
  props: {
    value: {
      type: [Array, String],
    },
    width: {
      type: Number,
      default: 200,
    },
    items: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Upload media',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    onChange: {
      type: Function,
      default: () => ({}),
    },
    folder: {
      type: String,
    },
  },
  data() {
    return {
      files: [],
      imageProgress: null,
      filesLoading: false,
    }
  },
  watch: {
    value(val) {
      this.value = val
    },
  },
  beforeDestroy() {
    this.files = []
  },
  methods: {
    openFileInputDialog() {
      this.$refs.file.$refs.input.click()
    },
    fileInputChanged(event) {
      if (event && event.length === 0) {
        this.$emit('update:value', this.multiple ? [] : null)
        return
      }
      const onUploadingChange = value => {
        this.filesLoading = value
        this.$emit('update:uploading', value)
      }
      const onUploadProgress = value => {
        this.imageProgress = value
      }

      uploadToCloudinary(
        this.files,
        {
          onUploadingChange,
          onUploadProgress,
        },
        { folder: this.folder }
      )
        .then(urls => {
          const value = this.multiple ? urls : urls[0]
          this.$emit('update:value', value)
          this.onChange(value)
        })
        .catch(err => (this.error = err))
    },
  },
}
</script>

<style scoped>
.upload-box {
  border: 1px solid var(--v-secondary-lighten1);
  position: relative;
  cursor: pointer;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0 6px;
  z-index: 9999;
}
</style>
