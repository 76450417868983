<template>
  <v-autocomplete
    v-model="model"
    hide-details
    flat
    solo
    dense
    no-filter
    outlined
    clearable
    :items="items"
    item-value="reservation_guesty_id"
    item-text="guest_name"
    :search-input.sync="searchValue"
    label="Search Reservations"
    @change="updateQuery"
    @update:search-input="debouncedSearch"
  >
    <template #item="{ item }">
      <div class="text-capitalize my-4 font-weight-medium">
        <div class="mb-1">
          {{ item.guest_name }}
          <span v-if="item.confirmation_code" class="text-caption font-italic"
            >({{ item.confirmation_code }})</span
          >
        </div>
        <div class="text-caption mb-1">
          {{ parseDate(item.reservation_check_in, 'MMM DD, YYYY') }} -
          {{ parseDate(item.reservation_check_out, 'MMM DD, YYYY') }}
        </div>
        <div class="text-caption mt-2">
          <v-chip
            class="mr-2"
            small
            label
            :color="resStatusColor(item.reservation_status)"
          >
            {{ item.reservation_status }}</v-chip
          >{{ item.listing }}
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { mapActions } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import debounce from 'lodash/debounce'

export default {
  mixins: [CommonFunctions],
  data() {
    return {
      model: null,
      searchValue: null,
      items: [],
      debouncedSearch: debounce(this.getReservations, 500),
    }
  },
  methods: {
    ...mapActions('reservation', ['searchReservations']),
    async getReservations() {
      const res = await this.searchReservations({
        term: this.searchValue,
      })
      if (res.length) this.items = res
    },
    updateQuery(val) {
      if (!val) return
      if (this.$router.currentRoute.fullPath.includes('reservation')) {
        this.$router.push(`${val}`)
      } else {
        this.$router.push(`/dashboard/reservation/${val}`)
      }
    },
  },
}
</script>
<style scoped>
.v-chip > span {
  color: darkslategray;
  font-weight: 600;
}
</style>
