<template>
  <v-dialog
    v-if="conversation"
    v-model="conversation"
    persistent
    max-width="750px"
  >
    <v-flex xs12>
      <v-card class="pa-4">
        <v-layout column align-baseline>
          <v-flex offset-xs10 xs-2 offset-sm11 sm-1>
            <v-btn
              fab
              dark
              small
              color="teal"
              class="float-right"
              @click="closeConversation"
            >
              <v-icon dark>close</v-icon>
            </v-btn>
          </v-flex>
          <conversation-messages
            v-if="conversation"
            :conversation="conversation"
          />
          <conversation-chat-box
            v-if="conversation && managementRoles"
            class="full-width"
          ></conversation-chat-box>
        </v-layout>
      </v-card>
    </v-flex>
  </v-dialog>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ConversationMessages from './conversation-messages'
import ConversationChatBox from './conversation-chat-box'

export default {
  components: {
    ConversationChatBox,
    ConversationMessages,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [''],
  computed: {
    conversation() {
      return (
        this.$store.state.chosenChatConversation ||
        this.$store.state.chosenConversation
      )
    },
  },
  mounted() {
    this.$store.commit('updateChosenChatConversation', this.conversation)
    this.$store.dispatch('getChatConversation', this.conversation.guesty_id)
  },
  methods: {
    closeConversation() {
      this.$store.commit('updateChosenConversation', null)
      this.$store.commit('updateChosenChatConversation', null)
    },
  },
}
</script>
