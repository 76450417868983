<template>
  <div class="full h-100 d-flex flex-column">
    <v-container>
      <v-row>
        <v-col v-if="estimate.title" cols="12" class="text-h5 mb-10">
          {{ estimate.title }}
        </v-col>
        <v-col cols="12" class="text-h6 mb-10">
          <table class="full">
            <tr>
              <th></th>
              <th>Materials</th>
              <th>Labor</th>
              <th>Total</th>
            </tr>
            <tr>
              <td>Estimate</td>
              <td>{{ dollarFormatter(totalMaterialsCost) }}</td>
              <td>{{ dollarFormatter(totalLaborCost) }}</td>
              <td>{{ dollarFormatter(totalPrice) }}</td>
            </tr>
            <tr v-if="expensesStats">
              <td>Current</td>
              <td>{{ dollarFormatter(expensesStats.material_sum) }}</td>
              <td>{{ dollarFormatter(expensesStats.labor_sum) }}</td>
              <td>
                {{
                  dollarFormatter(
                    expensesStats.material_sum + expensesStats.labor_sum
                  )
                }}
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="groups-table mb-10">
          <table class="full">
            <tr class="pb-5">
              <th>Group</th>
              <th>Estimate Cost</th>
            </tr>
            <tr v-for="(group, index) in estimate.groups" :key="index">
              <td>{{ group.title }} ({{ group.name }})</td>
              <td>{{ dollarFormatter(calcGroupTotalCost(group)) }}</td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="mb-10">
          <div class="d-flex">
            <div class="text-h6">Labor Expenses</div>
            <v-spacer />
            <div>
              <form-dialog
                title="Create Labor Expense"
                @submit="onCreateProjectLaborExpense"
                @open="newExpense = {}"
              >
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" class="ml-3" v-bind="attrs" v-on="on">
                    Create Labor Expense
                  </v-btn>
                </template>
                <contractor-select
                  v-model="newExpense.contractor_id"
                  :initials="true"
                  label="Contractor *"
                  :rules="[required]"
                />
                <v-text-field
                  v-model="newExpense.amount"
                  label="Amount *"
                  type="number"
                  outlined
                  dense
                  :rules="[required]"
                />
                <v-textarea
                  v-model="newExpense.description"
                  label="Description *"
                  type="number"
                  outlined
                  dense
                  :rules="[required]"
                />
              </form-dialog>
            </div>
          </div>
          <v-divider class="my-3" />
          <div>
            <v-data-table
              :headers="laborLtsTableHeaders"
              :items="laborLtsExpenses"
              :options="laborLtsTablePagination"
              :server-items-length="laborLtsTablePagination.itemsLength"
              :loading="laborLtsTableFetching"
              class="elevation-1 w-100 mx-3"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
              }"
              @pagination="laborLtsTablePaginationChanged($event)"
            >
              <template #item.paid_at="{ item }">
                {{ parseDate(item.paid_at) }}
              </template>
              <template #item.amount="{ item }">
                {{ dollarFormatter(item.amount) }}
              </template>
              <template #item.assigned_contractor_id="{ item }">
                {{ nameById(item.assigned_contractor_id) }}
              </template>
            </v-data-table>
          </div>
        </v-col>
        <v-col cols="12" class="mb-10">
          <div class="d-flex">
            <div class="text-h6">Material Expenses</div>
            <v-spacer />
            <div>
              <form-dialog
                title="Create Material Expense"
                @submit="onCreateProjectMaterialExpense"
                @open="newExpense = {}"
              >
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" class="ml-3" v-bind="attrs" v-on="on">
                    Create Material Expense
                  </v-btn>
                </template>
                <v-text-field
                  v-model="newExpense.amount"
                  label="Amount *"
                  type="number"
                  outlined
                  dense
                  :rules="[required]"
                />
                <v-textarea
                  v-model="newExpense.description"
                  label="Description *"
                  type="number"
                  outlined
                  dense
                  :rules="[required]"
                />
                <dvr-media-upload
                  :rules="[required]"
                  label="Invoice"
                  folder="service-calls"
                  :value.sync="newExpense.images"
                />
              </form-dialog>
            </div>
          </div>
          <v-divider class="my-3" />
          <div>
            <v-data-table
              :headers="materialLtsTableHeaders"
              :items="materialLtsExpenses"
              :options="materialLtsTablePagination"
              :server-items-length="materialLtsTablePagination.itemsLength"
              :loading="materialLtsTableFetching"
              class="elevation-1 w-100 mx-3"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
              }"
              @pagination="materialLtsTablePaginationChanged($event)"
            >
              <template #item.paid_at="{ item }">
                {{ parseDate(item.paid_at) }}
              </template>
              <template #item.amount="{ item }">
                {{ dollarFormatter(item.amount) }}
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EstimatesMixin from 'components/mixins/estimates-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import { mapActions } from 'vuex'
import formRulesMixin from 'components/mixins/form-rules-mixin'
import FormDialog from 'components/common/form-dialog'
import ContractorSelect from 'components/contractor-select'
import axios from '@/axios/config'
import DvrMediaUpload from 'components/common/dvr-media-upload'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ProjectBudget',
  components: { DvrMediaUpload, ContractorSelect, FormDialog },
  mixins: [CommonFunctions, EstimatesMixin, formRulesMixin, FormattersMixin],
  props: ['project', 'estimate'],
  data() {
    return {
      newExpense: {},
      expensesStats: null,
      laborLtsExpenses: [],
      laborLtsTableFetching: false,
      laborLtsTableHeaders: [
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Paid At', value: 'paid_at', sortable: false },
        {
          text: 'Contractor',
          value: 'assigned_contractor_id',
          sortable: false,
        },
      ],
      laborLtsTablePagination: {
        page: 1,
        itemsPerPage: 25,
        itemsLength: null,
      },
      materialLtsExpenses: [],
      materialLtsTableFetching: false,
      materialLtsTableHeaders: [
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Paid At', value: 'paid_at', sortable: false },
      ],
      materialLtsTablePagination: {
        page: 1,
        itemsPerPage: 25,
        itemsLength: null,
      },
    }
  },
  computed: {},
  mounted() {
    this.fetchExpensesStats()
    this.fetchLaborLtsExpenses()
    this.fetchMaterialLtsExpenses()
  },
  methods: {
    ...mapActions('projects', [
      'createProjectLaborExpense',
      'createProjectMaterialExpense',
    ]),
    onCreateProjectLaborExpense() {
      this.createProjectLaborExpense({
        projectId: this.project.id,
        payload: this.newExpense,
      }).then(() => this.fetchLaborLtsExpenses())
    },
    fetchExpensesStats() {
      axios.get(`/api/projects/${this.project.id}/expenses-stats`).then(res => {
        this.expensesStats = res.data
      })
    },
    fetchLaborLtsExpenses(page) {
      this.laborLtsTableFetching = true
      if (page) this.laborLtsTablePagination.page = page
      axios
        .get(`/api/projects/${this.project.id}/labor-expenses`, {
          params: {
            page: this.laborLtsTablePagination.page,
            per_page: this.laborLtsTablePagination.itemsPerPage,
          },
        })
        .then(res => {
          this.laborLtsExpenses = res.data.listing_tasks
          this.laborLtsTablePagination = {
            page: parseInt(res.data.pagi_info.page),
            itemsPerPage: parseInt(res.data.pagi_info.per_page),
            itemsLength: parseInt(res.data.pagi_info.count),
          }
        })
        .finally(() => {
          this.laborLtsTableFetching = false
        })
    },
    laborLtsTablePaginationChanged(pagination) {
      const page = this.laborLtsTablePagination.page
      const itemsPerPage = this.laborLtsTablePagination.itemsPerPage
      this.laborLtsTablePagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchLaborLtsExpenses()
      }
    },
    onCreateProjectMaterialExpense() {
      this.createProjectMaterialExpense({
        projectId: this.project.id,
        payload: this.newExpense,
      }).then(() => this.fetchMaterialLtsExpenses())
    },
    fetchMaterialLtsExpenses(page) {
      this.materialLtsTableFetching = true
      if (page) this.materialLtsTablePagination.page = page
      axios
        .get(`/api/projects/${this.project.id}/material-expenses`, {
          params: {
            page: this.materialLtsTablePagination.page,
            per_page: this.materialLtsTablePagination.itemsPerPage,
          },
        })
        .then(res => {
          this.materialLtsExpenses = res.data.listing_tasks
          this.materialLtsTablePagination = {
            page: parseInt(res.data.pagi_info.page),
            itemsPerPage: parseInt(res.data.pagi_info.per_page),
            itemsLength: parseInt(res.data.pagi_info.count),
          }
        })
        .finally(() => {
          this.materialLtsTableFetching = false
        })
    },
    materialLtsTablePaginationChanged(pagination) {
      const page = this.materialLtsTablePagination.page
      const itemsPerPage = this.materialLtsTablePagination.itemsPerPage
      this.materialLtsTablePagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchMaterialLtsExpenses()
      }
    },
  },
}
</script>

<style scoped>
th,
td {
  padding: 10px;
  text-align: start;
}

.groups-table table,
.groups-table th,
.groups-table td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
