import axios from 'axios'
import { sum, values } from 'lodash'
import Toaster from '@/utils/toaster'
import get from 'lodash/fp/get'
import imgCompressor from '@/utils/imgCompressor'

const uploadUrl = 'https://api.cloudinary.com/v1_1/do4tedxg6/upload'
const chunkSize = 20 * 1024 * 1024 // 20MB chunks

export async function uploadToCloudinary(
  files,
  { onUploadingChange, onUploadProgress } = {},
  config
) {
  onUploadingChange = onUploadingChange || (() => undefined)
  onUploadProgress = onUploadProgress || (() => undefined)

  const filesProgress = {}
  let totalSize = 0
  const { compress = true, folder = '', uploadPreset = 'tcu2ksyf' } = config
  onUploadingChange(true)
  onUploadProgress(0)

  const promises = files.map(async (f, index) => {
    let shouldImprove = false
    if (Array.isArray(f)) {
      shouldImprove = true
      f = f[0]
    }
    const file =
      f.type?.includes('image') && compress ? await imgCompressor(f) : f
    totalSize += file?.size || 1
    filesProgress[index] = 0

    const formData = new FormData()
    formData.append('upload_preset', compress ? uploadPreset : 'original')
    formData.append(
      'folder',
      `tenants/${window.config.company_name}/${folder.replace(
        /[?&#\\%<>+ ]/g,
        ''
      )}`
    )
    if (shouldImprove) {
      formData.set('upload_preset', 'ota-images')
    }

    if (file.size > 100 * 1024 * 1024) {
      return uploadLarge(
        file,
        formData,
        index,
        filesProgress,
        totalSize,
        onUploadProgress
      )
    } else {
      formData.append('file', file)
      const uploadHeaders = {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      }
      return axios.post(uploadUrl, formData, {
        headers: uploadHeaders,
        withCredentials: false,
        onUploadProgress: progressEvent => {
          filesProgress[index] = progressEvent.loaded
          updateProgress(filesProgress, totalSize, onUploadProgress)
        },
      })
    }
  })

  return Promise.all(promises)
    .then(result =>
      result.map(r => {
        if (r.data.eager && r.data.eager.length) {
          return r.data.eager[0].secure_url
        }
        return r.data.secure_url
      })
    )
    .catch(error => {
      const errorMessage = get('response.data.error', error) || error.message
      Toaster.show([
        { type: 'error', text: `Image upload failed - ${errorMessage}` },
      ])
      throw error
    })
    .finally(() => onUploadingChange(false))
}

async function uploadLarge(
  file,
  formData,
  index,
  filesProgress,
  totalSize,
  onUploadProgress
) {
  const chunks = Math.ceil(file.size / chunkSize)
  let uploadedBytes = 0
  let publicId = null
  let secure_url = null

  for (let i = 0; i < chunks; i++) {
    const start = i * chunkSize
    const end = Math.min(file.size, start + chunkSize)
    const chunk = file.slice(start, end)

    formData.set('file', chunk)
    formData.set('chunk', i + 1)
    formData.set('total_chunks', chunks)

    if (publicId) {
      formData.set('public_id', publicId)
    }

    const response = await axios.post(uploadUrl, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        'X-Unique-Upload-Id': `${file.name}-${Date.now()}`,
      },
      withCredentials: false,
    })

    uploadedBytes += chunk.size
    filesProgress[index] = uploadedBytes
    updateProgress(filesProgress, totalSize, onUploadProgress)

    if (i === 0) {
      publicId = response.data.public_id
      secure_url = response.data.secure_url
    }

    if (i === chunks - 1)
      return { ...response, data: { ...response.data, secure_url } }
  }
}

function updateProgress(filesProgress, totalSize, onUploadProgress) {
  const progress = Math.min(
    Math.round(sum(values(filesProgress)) * 100) / totalSize,
    100
  )
  onUploadProgress(progress)
}
