<template>
  <v-row no-gutters class="align-start pa-2 rounded">
    <v-col cols="auto">
      <v-icon v-if="comment.parent_id" style="transform: scaleX(-1)"
        >fas fa-level-up-alt
      </v-icon>
      <UserAvatar
        v-if="user"
        :image="user.picture"
        :name="user.name"
        :role="user.role"
      />
    </v-col>
    <v-col class="flex d-flex flex-column px-3">
      <div class="d-flex align-center justify-space-between">
        <div class="secondary--text">
          <span v-if="user" class="text-subtitle-2 font-weight-medium">{{
            user.name
          }}</span>
          <v-icon small class="mx-1">mdi-circle-small</v-icon>
          <span class="text-caption">{{ timeAgo(comment.created_at) }}</span>
          <v-icon v-if="!isMobile" small class="mx-1">mdi-circle-small</v-icon>
          <v-tooltip v-if="!isMobile" :disabled="!seenUsers.length" top>
            <template #activator="{ on }">
              <span class="align-center" v-on="on">
                <v-icon small left>fas fa-eye</v-icon>
                <span class="text-caption">{{ seenUsers.length }}</span>
              </span>
            </template>
            <ul>
              <li v-for="(seenU, i) in seenUsers" :key="i">
                {{ seenU.name }}
              </li>
            </ul>
          </v-tooltip>
        </div>
        <div class="d-flex">
          <v-icon v-if="!comment.parent_id" small @click="toggle"
            >fas fa-reply</v-icon
          >
        </div>
      </div>
      <div class="font-light mt-2" v-html="html" />
    </v-col>

    <v-col v-if="openReply" cols="12">
      <text-editor
        v-click-outside="close"
        :mentions="mentionItems"
        :show-toolbar="false"
        @text-submit="replyComment"
      />
    </v-col>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import UserAvatar from 'components/common/user-avatar'
import TextEditor from 'components/common/text-editor'
import { mapGetters } from 'vuex'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'LtFeedItem',
  components: { TextEditor, UserAvatar },
  mixins: [CommonFunctions, DeviceMixin],
  props: ['comment', 'unread'],
  data() {
    return {
      openReply: false,
    }
  },
  methods: {
    toggle() {
      this.openReply = !this.openReply
    },
    close() {
      this.openReply = false
    },
    replyComment(html) {
      this.$emit('add-message', {
        html,
        parent_id: this.comment.id,
      })
      this.openReply = false
    },
  },
  computed: {
    seenUsers() {
      return this.comment.seen
        .filter(userId => userId !== this.currentUserId)
        .map(userId => {
          return this.usersMap[userId]
        })
    },
    mentionItems() {
      return this.activeUsers.map(u => ({
        id: u.id,
        value: u.name,
      }))
    },
    user() {
      return this.usersMap[this.comment.user_id]
    },
    html() {
      return this.comment.message
    },
    ...mapGetters(['currentUserId']),
    ...mapGetters('users', ['usersMap', 'activeUsers']),
  },
}
</script>

<style scoped>
>>> span[data-denotation-char='@'] {
  font-weight: 400;
  color: var(--v-info-base);
}

>>> img {
  width: 100%;
  border-radius: 10px;
}
</style>
