<template>
  <v-main v-if="listingTasksStats && (isAdmin || isPropertyManager)">
    <v-layout wrap>
      <v-layout v-if="!miniVersion" xs12 sm3 offset-sm1 mb-3 mt-2>
        <v-flex xs12 sm10 offset-sm1>
          <v-layout wrap>
            <v-flex xs6>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                required
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="parsedFrom"
                    label="From"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="from"
                  :max="
                    new Date(new Date().setMonth(new Date().getMonth() + 10))
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="2018-01-01"
                  @change="saveFrom"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                required
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="parsedTo"
                    label="To"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="to"
                  :min="new Date(from).toISOString().substr(0, 10)"
                  :max="
                    new Date(new Date().setMonth(new Date().getMonth() + 10))
                      .toISOString()
                      .substr(0, 10)
                  "
                  @change="saveTo"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout v-if="miniVersion" justify-center wrap>
      <v-flex xs12 sm7>
        <v-text-field
          v-model="searchTerm"
          flat
          label="Search..."
          append-icon="search"
          solo-inverted
          style="border: 1px grey solid"
          class="mb-4"
        ></v-text-field>
      </v-flex>
      <v-flex v-if="!listingLoading" xs12 sm8 mt-3 justify-center>
        <v-list class="small-text">
          <v-list-item
            v-for="(user_stats, index) in listingTasksStats"
            :key="index"
            class="comp-wrapper mt-1"
          >
            <v-list-item-avatar>
              <img
                v-if="user_stats.picture"
                :src="user_stats.picture"
                v-on="on"
              />
              <v-tooltip v-if="!user_stats.picture" top>
                <div
                  :style="
                    'background-color: ' +
                    stringToColour(user_stats.contractor_name)
                  "
                  class="pt-1 pl-1 pr-1 float-right list-cal-ini"
                  v-on="on"
                >
                  <span class="white--text cal-image bolder2">{{
                    initials(user_stats)
                  }}</span>
                </div>
                <v-layout wrap style="min-width: 100px">
                  {{ user_stats.contractor_name }}
                </v-layout>
              </v-tooltip>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-layout
                wrap
                align-center
                full-width
                xs12
                ml-1
                class="med-font center-text"
              >
                <v-flex xs1 sm2>
                  <v-tooltip top>
                    <v-icon small class="purple-icon" v-on="on"
                      >query_builder
                    </v-icon>
                    <v-layout wrap style="min-width: 100px">
                      Hours charged
                    </v-layout>
                  </v-tooltip>
                  <br />{{ user_stats.hours }}
                </v-flex>
                <v-flex xs1 sm2>
                  <v-tooltip top>
                    <v-icon small class="cyan-icon" v-on="on">money</v-icon>
                    <v-layout wrap style="min-width: 100px">
                      Total charged
                    </v-layout>
                  </v-tooltip>
                  <br />
                  {{ user_stats.amount }}$
                </v-flex>
                <v-flex xs2 sm2>
                  <v-tooltip top>
                    <v-icon small class="grey-text" v-on="on">av_timer</v-icon>
                    <v-layout wrap style="min-width: 100px">
                      Planned duration
                    </v-layout>
                  </v-tooltip>
                  <br />
                  {{ user_stats.planned_duration }}
                </v-flex>

                <v-flex xs6 sm4 class="comp-wrapper br-1">
                  <v-layout wrap>
                    <v-flex class="green-health">
                      {{ user_stats.tasks_stats['Done'] }}
                      <span class="grey-text"> - Done</span></v-flex
                    >
                    <v-flex class="yellow-health">
                      {{ user_stats.tasks_stats['In Progress'] }}
                      <span class="grey-text"> - In-Pr</span>
                    </v-flex>
                    <v-flex class="red-health">
                      {{ user_stats.tasks_stats['New'] }}
                      <span class="grey-text"> - New</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs2 sm2>
                  <v-flex class="cyan-icon">
                    <span class="grey-text">AVG day - </span
                    >{{ roundWith(user_stats.avg_work_day, 1) }}<br />
                    <span
                      v-if="
                        user_stats.tasks_stats['total_tasks'] > 5 &&
                        user_stats.tasks_stats['total_tasks'] >
                          user_stats.avg_work_day &&
                        calOnDayView
                      "
                      class="small-text red-warn"
                    >
                      Too many tasks schedule
                    </span>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-flex>
    </v-layout>
    <v-layout v-if="!miniVersion" wrap align-center justify-center>
      <v-col cols="12" sm="7">
        <v-text-field
          v-model="searchTerm"
          flat
          hide-details
          label="Search..."
          append-icon="search"
          solo-inverted
          style="border: 1px grey solid"
          class="mb-4"
        ></v-text-field>
      </v-col>
      <v-flex
        v-for="(user_stats, index) in listingTasksStats"
        :key="index"
        class="comp-wrapper"
        xs12
        sm10
        offset-sm1
        mb-3
      >
        <v-progress-circular
          v-show="listingLoading"
          class="ml-2"
          :size="20"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-layout wrap align-center class="center-text headline-font grey-text">
          <v-flex xs6 sm2 class="br-1">{{ user_stats.contractor_name }}</v-flex>
          <v-flex xs3 sm2 class="br-1">Hours: {{ user_stats.hours }}</v-flex>
          <v-flex xs3 sm2>Total charge: {{ user_stats.amount }}</v-flex>
          <v-flex class="comp-wrapper">
            <v-layout wrap>
              <v-flex class="green-health">
                {{ user_stats.tasks_stats['Done'] }}
                <span class="grey-text"> - Done</span></v-flex
              >
              <v-flex class="yellow-health">
                {{ user_stats.tasks_stats['In Progress'] }}
                <span class="grey-text"> - In progress</span>
              </v-flex>
              <v-flex class="red-health">
                {{ user_stats.tasks_stats['New'] }}
                <span class="grey-text"> - New</span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-layout class="comp-wrapper purpleish white--text">
              <v-flex xs3 sm3 class="br-1 bolder"
                >Active now: {{ user_stats.active_now ? 'Yes' : 'No' }}
              </v-flex>
              <v-flex xs3 sm3 class="br-1 bolder"
                >Today clock ins: {{ user_stats.clock_in_hour_today }}
              </v-flex>
              <v-flex xs3 sm3 class="br-1"
                >On payroll: {{ user_stats.on_payrole ? 'Yes' : 'No' }}
              </v-flex>
              <v-flex v-if="user_stats.hourly_pay" xs3 sm3
                >Hourly pay: {{ dollarFormatter(user_stats.hourly_pay) }}
              </v-flex>
              <v-flex v-if="!user_stats.hourly_pay" xs3 sm3
                >Hourly pay: Not defined
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-expansion-panels>
              <v-expansion-panel key="final">
                <v-expansion-panel-header>
                  Tasks ({{
                    user_stats.tasks_stats['In Progress'] +
                    user_stats.tasks_stats['New'] +
                    user_stats.tasks_stats['Done']
                  }})
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout wrap class="center-text purple-icon bt-1">
                    <v-flex
                      v-for="(task, index) in user_stats.listing_tasks_open"
                      :key="index"
                      xs12
                    >
                      <v-layout wrap class="bb-1 mt-1 mb-1">
                        <v-flex xs3 class="br-1">
                          {{ task.description }}
                        </v-flex>
                        <v-flex xs3 class="br-1">
                          {{ task.listing_name }}
                        </v-flex>
                        <v-flex xs2 class="br-1">
                          {{
                            parseDateUSA(task.scheduled_at) || 'Not Scheduled'
                          }}
                        </v-flex>
                        <v-flex
                          xs1
                          class="br-1"
                          :class="listingTaskStatusColor(task.status)"
                        >
                          {{ task.status }}
                        </v-flex>
                        <v-flex xs1 class="br-1">
                          {{ dollarFormatter(task.amount) }}</v-flex
                        >
                        <v-flex xs1 class="br-1">
                          <v-layout
                            v-if="task.clock_ins_items[0]"
                            align-center
                            wrap
                            justify-center
                          >
                            <v-tooltip
                              v-if="!isEmpty(task.clock_ins_items)"
                              top
                              class="mr-2"
                            >
                              <template #activator="{ on }">
                                <v-icon
                                  small
                                  class="icon alt help-icon"
                                  v-on="on"
                                  >fas fa-question-circle
                                </v-icon>
                              </template>
                              <br />
                              <v-layout wrap style="min-width: 210px">
                                <v-flex
                                  xs12
                                  style="border-bottom: 1px dotted white"
                                  class="pb-1"
                                >
                                  Clock ins
                                </v-flex>
                              </v-layout>
                              <v-layout
                                v-for="(item, index) in task.clock_ins_items"
                                :key="index"
                                wrap
                                style="min-width: 210px"
                              >
                                <v-flex class="bolder xs7">
                                  {{ item.worker_name + ': ' }}
                                </v-flex>
                                <v-flex class="pl-2 xs5">
                                  {{ parseDate(item.clocked_in) }} |
                                  {{
                                    roundWith(item.duration / 60, 2) + ' Hours'
                                  }}
                                </v-flex>
                              </v-layout>
                            </v-tooltip>
                          </v-layout>
                          {{ task.total_hours }} hours
                        </v-flex>
                        <v-flex xs1>
                          <v-icon
                            v-if="isAdmin || isPropertyManager"
                            small
                            class="ml-2"
                            style="cursor: pointer"
                            @click="deleteListingTask(task)"
                          >
                            delete
                          </v-icon>

                          <router-link
                            target="_blank"
                            :to="'/dashboard/listing-task/' + task.id"
                            class="sidebar-link purple-icon"
                          >
                            <v-icon small class="ml-2" style="cursor: pointer">
                              search
                            </v-icon>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-main>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'

export default {
  components: {},
  mixins: [CommonFunctions, ColorsMixin, PermissionsMixin, FormattersMixin],
  props: ['miniVersion'],
  data: () => ({
    menu: false,
    menu2: false,
    searchTerm: '',
    from: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
      .toISOString()
      .substr(0, 10),
    to: new Date().toISOString().substr(0, 10),
  }),
  computed: {
    calOnDayView() {
      let date = this.$store.state.listingTaskCalDate
      let view = 'month'
      if (date.includes(' – ')) {
        view = 'week'
      } else if (date.includes(',')) {
        view = 'day'
      }
      return view === 'day'
    },
    listingTasksStats() {
      if (
        this.$store.state.listingTasksStats &&
        this.$store.state.listingTasksStats.user_stats
      ) {
        let contractors = []

        this.$store.state.listingTasksStats.user_stats.forEach(contractor => {
          if (this.passSearchFilter(contractor)) {
            contractors.push(contractor)
          }
        })
        return contractors
      }
      return []
    },
    parsedFrom() {
      return this.from ? this.$moment(this.from).format('YYYY/MM/DD') : ''
    },
    parsedTo() {
      return this.to ? this.$moment(this.to).format('YYYY/MM/DD') : ''
    },
  },
  created() {
    if (!this.miniVersion) {
      this.$store.dispatch('getListingTasksStats', {
        from: this.from,
        to: this.to,
      })
    }
  },
  methods: {
    passSearchFilter(contractor) {
      if (contractor.contractor_name) {
        return contractor.contractor_name
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      }
    },
    deleteListingTask: function (listingTask) {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        this.$store.commit('updateListingLoading', true)

        axios
          .delete('/api/listing-tasks/' + listingTask.id)
          .then(() => {
            this.$store.dispatch('getListingTasksStats', {
              from: this.from,
              to: this.to,
            })
          })
          .catch(() => ({}))
      }
    },
    saveFrom(date) {
      this.$refs.menu.save(date)
      this.$store.dispatch('getListingTasksStats', {
        from: date,
        to: this.to,
        tags: this.tags,
        listingID: this.listingID,
      })
    },
    saveTo(date) {
      this.$refs.menu2.save(date)
      this.to = date
      this.$store.dispatch('getListingTasksStats', {
        from: this.from,
        to: date,
        tags: this.tags,
        listingID: this.listingID,
      })
    },
  },
}
</script>
