<template>
  <div>
    <h1>Storybook</h1>
    <h2>Icons</h2>
    <v-card class="d-flex flex-wrap ma-10 pa-5">
      <div
        v-for="icon in allIcons"
        :key="icon"
        class="icon-box ma-4 text-center"
      >
        <div class="text-body-2 pa-2">{{ icon }}</div>
        <v-icon>${{ icon }}</v-icon>
      </div>
    </v-card>
  </div>
</template>

<script>
import icons from '@/assets/icons/index.js'
export default {
  data() {
    return {
      icons: icons,
    }
  },
  computed: {
    allIcons() {
      return Object.keys(this.icons.values)
    },
  },
  mounted() {},
}
</script>

<style>
.icon-box {
  width: 128px;
  height: 60px;
}
</style>
