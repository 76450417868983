<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.93 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="chat">
          <g id="Group_10271" data-name="Group 10271">
            <path
              id="Path_5204"
              data-name="Path 5204"
              class="cls-1"
              d="M21.65,7.85H10.06a.37.37,0,0,0-.37.36.38.38,0,0,0,.37.37H21.65A.37.37,0,0,0,22,8.21.36.36,0,0,0,21.65,7.85Z"
            />
            <path
              id="Path_5205"
              data-name="Path 5205"
              class="cls-1"
              d="M21.65,10.64H10.06a.37.37,0,1,0,0,.73H21.65a.37.37,0,0,0,0-.73Z"
            />
            <path
              id="Path_5206"
              data-name="Path 5206"
              class="cls-1"
              d="M21.65,13.43H10.06a.37.37,0,1,0,0,.73H21.65a.37.37,0,0,0,0-.73Z"
            />
            <path
              id="Path_5207"
              data-name="Path 5207"
              class="cls-1"
              d="M22.81,4.65H8.9A2.14,2.14,0,0,0,6.77,6.78v8.74A2.14,2.14,0,0,0,8.9,17.65h9.75a.3.3,0,0,1,.26.13l3.15,4a.64.64,0,0,0,.5.24.76.76,0,0,0,.21,0,.63.63,0,0,0,.43-.6V17.61a2.12,2.12,0,0,0,1.73-2.09V6.78A2.12,2.12,0,0,0,22.81,4.65ZM24.2,15.52a1.4,1.4,0,0,1-1.37,1.4.37.37,0,0,0-.37.36v3.81l-3-3.77a1.11,1.11,0,0,0-.84-.4H8.9a1.42,1.42,0,0,1-1.4-1.4V6.78a1.42,1.42,0,0,1,1.4-1.4H22.81a1.4,1.4,0,0,1,1.39,1.4Z"
            />
            <path
              id="Path_5208"
              data-name="Path 5208"
              class="cls-1"
              d="M5.38,10.69a1,1,0,0,0-.63.37L2.24,14.23V11.05a.36.36,0,0,0-.36-.37A1.17,1.17,0,0,1,.73,9.51V1.9A1.17,1.17,0,0,1,1.9.73H14A1.17,1.17,0,0,1,15.17,1.9V3.31a.37.37,0,0,0,.73,0V1.9A1.9,1.9,0,0,0,14,0H1.9A1.9,1.9,0,0,0,0,1.9V9.51a1.9,1.9,0,0,0,1.51,1.86V14.6a.6.6,0,0,0,.4.57.69.69,0,0,0,.2,0A.58.58,0,0,0,2.58,15L5.32,11.5a.26.26,0,0,1,.16-.09A.36.36,0,0,0,5.8,11a.37.37,0,0,0-.41-.32Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Chat',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
