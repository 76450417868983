<template>
  <div v-if="viewIsAllowed" grid-list-md>
    <div grid-list-md>
      <v-tabs
        v-model="paramsTab"
        icons-and-text
        class="tabs-menu main-background"
      >
        <v-tab href="#requests">
          {{ $t('Requests') }}

          <v-icon>business_center</v-icon>
        </v-tab>
        <v-tab
          v-if="hasAbility('inventory') || !isContractor"
          href="#inventory"
        >
          {{ $t('Inventory') }}
          <v-icon>inventory</v-icon>
        </v-tab>
        <v-tab v-if="isAdmin || hasAbility('inventory')" href="#history">
          {{ $t('History') }}

          <v-icon>mdi-clipboard-text-clock</v-icon>
        </v-tab>
        <v-tabs-items v-model="paramsTab" touchless>
          <v-tab-item value="requests">
            <requestsTable :storage-table-mode="true" />
          </v-tab-item>
          <v-tab-item value="inventory">
            <inventory-manager
              v-if="
                isPropertyManager ||
                isAdmin ||
                isAccountant ||
                hasAbility('inventory')
              "
            />
          </v-tab-item>
          <v-tab-item
            v-if="isAdmin || isPropertyManager || isAccountant"
            value="bi"
            class="pl-4 pr-4"
          >
            <v-row class="row">
              <v-col sm="10" offset-sm="1" class="justify-center pb-2 bb-1">
                <iframe
                  src="https://public.domo.com/cards/v2Z7n"
                  width="1200"
                  height="600"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="10" offset-sm="1">
                <iframe
                  src="https://public.domo.com/cards/wpr8X"
                  width="1200"
                  height="600"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="10" offset-sm="1">
                <iframe
                  src="https://public.domo.com/cards/yPXOR"
                  width="1200"
                  height="1200"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="history">
            <inventory-history />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
  <div v-else class="center-text mt-3 grey--text fill-height">
    No storage view for this user
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import InventoryHistory from 'components/inventory/inventory-history'
import InventoryManager from 'components/inventory/inventory-manager.vue'
import requestsTable from 'components/inventory/requests/requests-table.vue'

export default {
  components: {
    InventoryManager,
    InventoryHistory,
    requestsTable,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  computed: {
    viewIsAllowed() {
      return (
        this.managementRoles ||
        this.isCleaningSupervisor ||
        this.onPayroleContractor ||
        this.isCleaningManager
      )
    },
  },
}
</script>
