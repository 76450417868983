<template>
  <v-row no-gutters class="justify-space-between align-center flex-wrap">
    <v-col v-if="paidAt" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Approved at</p>
      <span class="font-weight-medium">{{ paidAt }}</span>
    </v-col>
    <v-col v-if="doneAt" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Done at</p>
      <span class="font-weight-medium">{{ doneAt }}</span>
    </v-col>
    <v-col v-if="hours" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Total hours</p>
      <span class="font-weight-medium">{{ hours }}</span>
    </v-col>
    <v-col v-if="createdByName" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Created by</p>
      <span class="font-weight-medium"> {{ createdByName }} </span>
    </v-col>
    <v-col v-if="materialCost" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Material cost</p>
      <span class="font-weight-medium">
        {{ dollarFormatter(materialCost) }}
      </span>
    </v-col>
    <v-col v-if="laborCost" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Labor</p>
      <span class="font-weight-medium">
        {{ dollarFormatter(laborCost) }}
      </span>
    </v-col>
    <v-col v-if="totalCost" class="mb-2" cols="auto">
      <!--      <div class="text-caption mb-0">Total cost</div>-->
      <!--      <span class="font-weight-medium"> {{ dollarFormatter(totalCost) }} </span>-->
      <!--      <span-->
      <!--        v-if="!isInvestor && managementFee"-->
      <!--        class="text-caption info&#45;&#45;text mb-0"-->
      <!--      >-->
      <!--        ({{ dollarFormatter(managementFee) }} Fee included)-->
      <!--      </span>-->
    </v-col>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
export default {
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: [
    'paidAt',
    'doneAt',
    'hours',
    'materialCost',
    'laborCost',
    'managementFee',
    'createdByName',
  ],
  computed: {
    totalCost() {
      return (
        (this.laborCost || 0) +
        (this.materialCost || 0) +
        (this.managementFee || 0)
      )
    },
  },
}
</script>
