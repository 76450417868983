<template>
  <div class="scale-container full">
    <div
      class="scale-container-buttons d-flex flex-column p-absolute"
      :style="{ 'z-index': buttonsZIndex }"
    >
      <v-btn
        icon
        class="white"
        :disabled="!zoomOutEnabled"
        @click.prevent.stop="handleZoomOut"
      >
        <v-icon>remove_circle_outline</v-icon>
      </v-btn>
      <v-btn
        icon
        class="white"
        :disabled="!zoomInEnabled"
        @click.prevent.stop="handleZoomIn"
      >
        <v-icon>add_circle_outline</v-icon>
      </v-btn>
    </div>
    <div class="full scale-container-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScaleContainer',
  components: {},
  props: {
    buttonsZIndex: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      element: null,
      scale: 1,
    }
  },
  computed: {
    zoomOutEnabled() {
      return this.scale > 0.4
    },
    zoomInEnabled() {
      return this.scale < 1.5
    },
  },
  mounted: function () {
    this.element = this.$el.querySelector('.scale-container-wrapper')
    this.element.style['transform-origin'] = 'left top'
  },
  methods: {
    updateElement() {
      this.element.style.transform = `scale(${this.scale})`
      this.element.style.width = 100 / this.scale + '%'
      this.element.style.height = 100 / this.scale + '%'
      this.$emit('onScaleChange', this.scale)
    },
    handleZoomOut() {
      this.scale -= 0.1
      this.updateElement()
    },
    handleZoomIn() {
      this.scale += 0.1
      this.updateElement()
    },
  },
}
</script>
