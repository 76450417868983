<template>
  <div v-if="multiStatus">
    <v-menu v-model="menu" offset-y>
      <template #activator="{ on, attrs }">
        <v-sheet
          v-bind="attrs"
          :class="[
            `d-flex
            justify-space-between
            lt-status-label
            text-uppercase
            rounded
            text-no-wrap
            px-3 py-1 text-caption font-weight-bold
            ${listingTaskStatusColor(value)}--text`,
            disabled && 'opacity-30',
          ]"
          v-on="disabled ? null : on"
        >
          <span>{{ value }}</span>
          <v-icon class="pl-2" :color="listingTaskStatusColor(value)" x-small>
            fas fa-chevron-right
          </v-icon>
        </v-sheet>
      </template>
      <v-list v-if="menu">
        <v-list-item-group
          :value="status"
          color="primary"
          @change="statusChanged"
        >
          <template v-for="item in rawStatuses">
            <v-list-item :key="item" :value="item">
              <v-list-item-title class="text-body-2">
                <v-icon
                  x-small
                  :color="listingTaskStatusColor(item)"
                  class="pr-2"
                >
                  fas fa-square
                </v-icon>
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
  <div v-else class="d-flex align-center mr-1">
    <div v-if="status !== null && !disabled" class="flex ml-4">
      <v-icon small color="black" @click.stop="undo">fas fa-undo</v-icon>
    </div>
    <div v-if="status === null" class="flex ml-2">
      <v-icon color="warning" size="28" @click.stop="reject"
        >fas fa-times-circle</v-icon
      >
    </div>
    <div v-if="status === null" class="flex ml-2">
      <v-icon color="primary" size="28" @click.stop="check"
        >fas fa-check-circle</v-icon
      >
    </div>
    <div v-if="status" class="flex ml-4">
      <v-icon color="primary" @click.stop>fas fa-check</v-icon>
    </div>
    <div v-if="status === false" class="flex ml-4">
      <v-icon color="warning" @click.stop>fas fa-times</v-icon>
    </div>
  </div>
</template>

<script>
import ColorsMixin from 'components/mixins/colors-mixin'
import { TASK_STATUSES } from '@/consts'

export default {
  name: 'YesOrNo',
  props: ['value', 'disabled', 'multiStatus'],
  mixins: [ColorsMixin],
  data() {
    return {
      menu: false,
      status: this.value,
    }
  },
  computed: {
    rawStatuses() {
      return [TASK_STATUSES.NEW, TASK_STATUSES.DONE, TASK_STATUSES.CLOSED]
    },
  },
  methods: {
    check() {
      this.$emit('approved')
    },
    reject() {
      this.$emit('rejected')
    },
    undo() {
      this.$emit('undo')
    },
    statusChanged(status) {
      this.status = status
      if (status === 'Done') {
        this.$emit('approved')
      } else {
        this.$emit('status-changed', status)
      }
    },
  },
  watch: {
    value(val) {
      this.status = val
    },
  },
}
</script>

<style scoped></style>
