<template>
  <form-dialog title="Add Invoice" @submit="submitInvoice">
    <template #activator="{ on }">
      <v-btn color="info" small class="text-capitalize py-4" v-on="on">
        <v-icon class="mr-2" small color="white">mdi-plus</v-icon>Add Invoice
        Item
      </v-btn>
    </template>
    <div>
      <v-select
        v-model="type"
        class="mr-2"
        label="Type"
        outlined
        dense
        :rules="[required]"
        :items="invoiceTypes"
        @change="typeChange"
      />
      <v-radio-group
        v-model="taxRelation"
        class="pb-2 ma-0"
        row
        hide-details
        :disabled="type === 'TAX' || type === 'ADDITIONAL_DISCOUNT'"
      >
        <v-radio label="Owner" value="owner" />
        <v-radio label="PMC" value="pmc" />
      </v-radio-group>
      <v-text-field
        v-model.number="amount"
        outlined
        dense
        label="Amount ($)"
        :rules="[required, isPositive]"
      />
    </div>
    <v-text-field
      v-model="description"
      outlined
      dense
      label="Description"
      :rules="[required]"
    />
    <v-checkbox
      v-model="shouldRecalculate"
      label="Recalculate price"
      class="mt-0"
      dense
      hide-details
    />
    <div v-if="shouldRecalculate" class="px-2 text-caption">
      This option add the selected item and recalculates the total reservation
      cost, including all applicable taxes and fees. Use this if you want to
      ensure the price reflects any changes to taxes or fees based on the
      property's charge profile.
    </div>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRulesMixin from 'components/mixins/form-rules-mixin'

export default {
  components: { FormDialog },
  mixins: [FormRulesMixin],
  props: ['reservation'],
  data() {
    return {
      type: 'FEE',
      taxRelation: 'owner',
      amount: null,
      shouldRecalculate: false,
      description: '',
      invoiceTypes: [
        {
          value: 'FEE',
          text: 'FEE',
        },
        { text: 'TAX', value: 'TAX' },
        { text: 'Discount', value: 'ADDITIONAL_DISCOUNT' },
        { text: 'Refund', value: 'ACCOMMODATION_FARE_DISCOUNT' },
      ],
    }
  },
  methods: {
    resetForm() {
      this.type = null
      this.amount = null
      this.description = null
    },
    typeChange() {
      if (this.type === 'TAX' || this.type === 'ADDITIONAL_DISCOUNT') {
        this.taxRelation = 'pmc'
      }
    },
    submitInvoice() {
      this.$emit('add-invoice', {
        amount: this.amount,
        description: this.description,
        type: this.type,
        tax_relation: this.taxRelation,
        recalculate_price: this.shouldRecalculate,
      })
      this.resetForm()
    },
  },
}
</script>
