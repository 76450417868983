<template>
  <v-menu
    v-model="menu"
    :disabled="disabled"
    :close-on-content-click="false"
    offset-y
    bottom
    max-height="300"
  >
    <template #activator="{ on, attrs }">
      <v-chip
        v-if="value && value.id"
        :disabled="disabled"
        close-icon="close"
        small
        close
        pill
        v-bind="attrs"
        @click:close="onReset"
        v-on="on"
      >
        <UserAvatar
          :image="value.picture"
          :name="value.name"
          :role="value.role"
        />
        {{ value.name }}
      </v-chip>
      <v-chip
        v-else
        class="empty-chip-field"
        small
        pill
        v-bind="attrs"
        v-on="on"
      >
        {{ $t(title || 'Assignee') }}
      </v-chip>
    </template>

    <v-list>
      <v-text-field
        v-model="search"
        class="pa-3"
        autofocus
        hide-details
        dense
        label="Search"
      />
      <v-list-item-group>
        <v-list-item
          v-for="user in selectedUsers"
          :key="user.id"
          :value="user"
          @click="onAssignee(user)"
        >
          <v-list-item-title>
            <v-avatar v-if="user.picture" size="40">
              <v-img :src="user.picture" />
            </v-avatar>

            <v-avatar v-else size="40" :color="roleColor(user.role)">
              <span class="white--text">{{ initials(user) }}</span>
            </v-avatar>
            <span class="ml-3">
              {{ user.first_name || '' }} {{ user.last_name || '' }}
            </span>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import UserAvatar from 'components/common/user-avatar'

export default {
  name: 'AssigneeSelect',
  components: { UserAvatar },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['value', 'users', 'disabled', 'title'],
  data() {
    return {
      search: '',
      open: false,
      currentAssignee: null,
      menu: false,
    }
  },
  computed: {
    selectedUsers() {
      return this.users.filter(u => {
        if (this.search) {
          return (
            (u.first_name &&
              u.first_name.toLowerCase().includes(this.search.toLowerCase())) ||
            (u.last_name &&
              u.last_name.toLowerCase().includes(this.search.toLowerCase()))
          )
        }
        return true
      })
    },
  },
  methods: {
    onReset() {
      this.$emit('input', { id: null })
      this.$emit('change', { id: null })
    },
    onAssignee(user) {
      this.menu = false
      this.$emit('input', {
        ...user,
        name: `${user.first_name} ${user.last_name}`,
      })
      this.$emit('change', user)
      this.search = ''
    },
  },
}
</script>

<style scoped>
.empty-chip-field {
  border: 1px dashed #000000;
  background-color: #ffffff !important;
}
</style>
