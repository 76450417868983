<template>
  <v-dialog :value="true" persistent max-width="800">
    <v-card>
      <v-form ref="form" lazy-validation @submit.prevent="copyToItems">
        <v-card-title class="pa-sm-6">
          <div class="p-relative flex text-center">
            <h2 class="text-h6">Choose listings to Copy info:</h2>
            <v-icon class="close-button" @click="close">close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="px-sm-6">
          <v-row class="justify-space-between align-center py-3" no-gutters>
            <v-col cols="8">
              <property-picker
                :not-top-header="true"
                :after-change="listingsChanged"
                :value="listings"
                label="Listings"
                :clearable="true"
                :allow-multiple="true"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-sm-6 py-sm-4">
          <v-spacer />
          <v-btn
            color="secondary"
            class="text-capitalize"
            text
            @click.native="close"
            >Close
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            :disabled="loading"
            type="submit"
            elevation="0"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import PropertyPicker from 'components/property-picker'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'InfoCopyItemModal',
  components: { PropertyPicker },
  mixins: [FormRules, FormattersMixin],
  props: ['item'],
  data() {
    return {
      listings: null,
      listingIds: null,
      populateMode: 'default',
    }
  },
  methods: {
    close() {
      this.$store.commit('hideModal')
      this.files = []
      this.listingIds = null
      this.listings = null
    },
    copyToItems() {
      this.$store.dispatch('updateListingInfoItem', {
        id: this.item.id,
        listing_id: this.item.listing_id,
        listing_ids: this.listingIds,
      })
      this.close()
    },
    listingsChanged(val) {
      this.listingIds = val
    },
  },
}
</script>

<style scoped></style>
