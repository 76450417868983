var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-2 black--text"},[_c('div',{staticClass:"text-subtitle-1 font-weight-medium"},[_vm._v("Summary")]),(_vm.payout.reserved)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"mr-2"},[_vm._v("Charges + Refunds: ")]),_c('span',{class:{
        'warning--text': _vm.payments < 0,
        'success--text': _vm.payments > 0,
      }},[_vm._v(_vm._s(_vm.dollarFormatter(_vm.payments)))])]):_vm._e(),(_vm.payout.reserved)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"mr-2"},[_vm._v("Reserved funds:")]),_c('span',{class:{
        'warning--text': _vm.payout.reserved < 0,
        'success--text': _vm.payout.reserved > 0,
      }},[_vm._v(_vm._s(_vm.dollarFormatter(_vm.payout.reserved)))])]):_vm._e(),(_vm.payout.stripe_fee)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"mr-2"},[_vm._v("Stripe Fee:")]),_c('span',{class:{
        'warning--text': _vm.payout.stripe_fee < 0,
        'success--text': _vm.payout.stripe_fee > 0,
      }},[_vm._v(_vm._s(_vm.dollarFormatter(_vm.payout.stripe_fee)))])]):_vm._e(),(_vm.payout.cart_amount)?_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"mr-2"},[_vm._v("Store Purchases:")]),_c('span',{class:{
        'warning--text': _vm.payout.cart_amount < 0,
        'success--text': _vm.payout.cart_amount > 0,
      }},[_vm._v(_vm._s(_vm.dollarFormatter(_vm.payout.cart_amount)))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }