<template>
  <div class="lt-template-flow-chart-node">
    <v-badge :color="statusColor" overlap bordered right class="mt-3">
      <div slot="badge" style="font-size: 12px" class="white--text bolder">
        <v-icon v-if="template.loop" size="15" color="light-blue"
          >mdi-sync</v-icon
        >

        <div v-if="!template.loop && displaySetupMode && taskIsDone">
          <v-icon small>done</v-icon>
        </div>
        <div
          v-if="
            !template.loop && displaySetupMode && !taskIsDone && taskCreated
          "
        >
          <v-icon small>pending</v-icon>
        </div>
        <div
          v-if="!template.loop && displaySetupMode && !taskIsDone && taskLost"
        >
          <v-icon class="red--text" small>delete</v-icon>
        </div>
        <div v-if="!template.loop && !displaySetupMode">
          {{ template.days_into_process }}
        </div>
      </div>
      <v-card
        flat
        bordered
        style="height: 220px"
        :class="[
          'flow-card bg-white q-pa-md',
          {
            'overlay-strong': displaySetupMode && taskIsDone,
            'card-shadow': template.represent_flow_id,
          },
        ]"
      >
        <div
          v-if="template.represent_flow_id"
          class="card-content d-flex flex-column align-center pa-5"
        >
          <v-chip color="primary" label x-large>
            <div class="pa-10">Flow</div>
          </v-chip>
          <div v-if="template.represent_flow" class="flex-fill flex-center">
            {{ template.represent_flow.name }}
          </div>
        </div>
        <div v-else class="card-content">
          <v-card-title class="pb-5 d-block">
            <a
              v-if="displaySetupMode && taskLink"
              class="cyan-icon"
              target="_blank"
              :href="taskLink"
              >View</a
            >
            <listing-setup-label
              :style="taskStyle"
              :role="catalogItemDivision || 'Missing'"
            />
            <v-spacer />
            <listing-setup-label
              v-if="template.assign_to_role"
              color="blue"
              icon="supervisor_account"
              :role="template.assign_to_role"
            />
            <listing-setup-label
              v-if="template.assigned_contractor_id"
              icon="assignment_ind"
              color="orange"
              :role="nameById(template.assigned_contractor_id)"
            />
            <listing-setup-label
              v-if="
                !template.assigned_contractor_id && !template.assign_to_role
              "
              icon="assignment_ind"
              color="red"
              role="Missing"
            />
          </v-card-title>
          <v-card-subtitle style="height: 100px" class="overflow-hidden">
            <v-row class="small-text mb-4">
              <v-col cols="12" class="py-0">
                <span class="text-caption">{{ catalogItemDepartment }}</span>
                <v-icon small color="info" class="mx-1"
                  >fas fa-angle-right
                </v-icon>
                <span class="text-caption">{{ catalogItemSubTopic }}</span>
                <v-icon small color="info" class="mx-1"
                  >fas fa-angle-right
                </v-icon>
                {{ catalogItemDescription }}
              </v-col>
              <v-col class="ellipsis-3 pa-0 ma-3">
                {{ template.description }}
              </v-col>
            </v-row>
          </v-card-subtitle>
        </div>
        <v-card-actions
          v-if="!displaySetupMode"
          style="height: 35px"
          class="overflow-hidden pa-0"
        >
          <div class="d-flex align-center flex-nowrap w-100 pa-1">
            <v-btn
              icon
              color="primary"
              @mousedown="$emit('onDragStart')"
              @mouseup="$emit('onDragEnd')"
            >
              <v-icon>drag_handle</v-icon>
            </v-btn>

            <div
              v-if="template.create_after_template_id"
              class="d-flex flex-row-reverse"
            >
              <v-divider vertical class="ma-1" />
              <ConfirmationModal
                text="Are you sure you want to disconnect this template from tree?"
                @action="
                  changeField(template.id, 'create_after_template_id', null)
                "
              >
                <template #activator="{ on }">
                  <v-btn icon color="primary" v-on="on">
                    <v-icon>account_tree</v-icon>
                  </v-btn>
                </template>
              </ConfirmationModal>
            </div>

            <v-spacer />

            <v-btn icon color="primary" @click="newChildTemplate(template.id)">
              <v-icon>add</v-icon>
            </v-btn>

            <v-divider vertical class="ma-1" />

            <v-btn icon color="primary" @click="editTemplate">
              <v-icon>edit</v-icon>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-badge>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'
import ColorsMixin from 'components/mixins/colors-mixin'
import ListingSetupLabel from 'components/listing-setup-label'
import get from 'lodash/fp/get'
import { cloneDeep } from 'lodash'

export default {
  components: {
    ListingSetupLabel,
    ConfirmationModal,
  },
  mixins: [CommonFunctions, PermissionsMixin, ColorsMixin],
  props: ['template', 'changeField'],
  data() {
    return {
      text: 'This is component A',
    }
  },
  computed: {
    statusColor() {
      if (this.displaySetupMode && this.taskIsDone) {
        return 'green'
      } else if (
        this.displaySetupMode &&
        !this.taskIsDone &&
        this.taskCreated
      ) {
        return 'yellow'
      } else if (this.displaySetupMode && !this.taskIsDone && this.taskLost) {
        return 'red'
      } else {
        return 'grey'
      }
    },
    catalogItemDivision() {
      return get('listing_task_catalog_item.division', this.template)
    },
    catalogItemDepartment() {
      return get('listing_task_catalog_item.department', this.template)
    },
    catalogItemSubTopic() {
      return get('listing_task_catalog_item.sub_topic', this.template)
    },
    catalogItemDescription() {
      return get('listing_task_catalog_item.description', this.template)
    },
    taskIsDone() {
      return this.doneTemplateIds.includes(this.template.id)
    },
    taskCreated() {
      return this.createdTemplateIds.includes(this.template.id)
    },
    taskLost() {
      return (
        this.doneTemplateIds.includes(this.template.create_after_template_id) &&
        !this.createdTemplateIds.includes(this.template.id) &&
        !this.taskIsDone
      )
    },
    displaySetupMode() {
      return this.$store.state.listingTask.displaySetupMode
    },
    doneTemplateIds() {
      if (this.$store.state.currentListingSetupStats) {
        return this.$store.state.currentListingSetupStats
          .listing_task_templates_done_ids
      } else {
        return []
      }
    },
    taskLink() {
      if (
        this.$store.state.currentListingSetupStats &&
        this.$store.state.currentListingSetupStats.templates_tasks_map[
          this.template.id
        ]
      ) {
        return `${this.origin}/dashboard/listing-task/${
          this.$store.state.currentListingSetupStats.templates_tasks_map[
            this.template.id
          ]
        }`
      }
      return ''
    },
    createdTemplateIds() {
      if (this.$store.state.currentListingSetupStats) {
        return this.$store.state.currentListingSetupStats
          .listing_task_templates_not_done_ids
      } else {
        return []
      }
    },
    taskStyle() {
      const division = this.catalogItemDivision
      if (division) {
        return `background-color: ${this.divisionToColor[division]};`
      }
      return 'background-color: #959597'
    },
  },
  methods: {
    newChildTemplate(parentId) {
      this.$store.commit('updateCurrentEditTemplate', {
        ...cloneDeep(this.defaultTemplate),
        create_after_template_id: parentId,
      })
    },
    editTemplate() {
      this.$store.commit('updateCurrentEditTemplate', this.template)
    },
    quickPickChange(change) {
      const data = {
        id: this.template.id,
        listing_task_catalog_item_id: change.id,
      }
      this.$store.dispatch('updateListingTaskTemplate', data)
    },
  },
}
</script>

<style scoped>
.flow-card {
  background-color: #f9f9f9;
  border: 1px solid #a1a1a1;
  width: 200px;
}

.card-content {
  height: calc(100% - 35px);
}

.card-shadow {
  box-shadow: 0 0 1px 1px rgba(138, 212, 212, 0.7) !important;
}

.v-expansion-panel:before {
  box-shadow: none !important;
}

.header-action-btn {
  position: absolute;
  top: 66px;
  right: 20px;
}

div >>> .v-expansion-panel-content__wrap {
  padding: 0;
}

.sync-indicator {
  margin-top: -25px;
  margin-bottom: 5px;
}
</style>
