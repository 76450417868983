<template>
  <svg
    id="Page-1"
    xmlns="http://www.w3.org/2000/svg"
    width="42.533"
    height="58"
    viewBox="0 0 42.533 58"
  >
    <g id="_042---Paper" data-name="042---Paper" transform="translate(0 0)">
      <path
        id="Shape"
        d="M32.733,41.317a.967.967,0,1,0,1.933,0A4.5,4.5,0,0,0,30.8,37.055v-.088a.967.967,0,1,0-1.933,0v.088a4.282,4.282,0,0,0,0,8.524v4.772a2.489,2.489,0,0,1-1.933-2.268.967.967,0,1,0-1.933,0,4.5,4.5,0,0,0,3.867,4.262v.088a.967.967,0,0,0,1.933,0v-.088a4.282,4.282,0,0,0,0-8.524V39.049A2.489,2.489,0,0,1,32.733,41.317Zm-5.8,0a2.489,2.489,0,0,1,1.933-2.268v4.536A2.489,2.489,0,0,1,26.933,41.317Zm5.8,6.767A2.489,2.489,0,0,1,30.8,50.351V45.816A2.489,2.489,0,0,1,32.733,48.083Z"
        transform="translate(-0.833 -1.2)"
        fill="#fff"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M.967,14.983A.967.967,0,0,0,0,15.95V52.2A5.806,5.806,0,0,0,5.8,58H36.733a5.806,5.806,0,0,0,5.8-5.8V14.5a.967.967,0,0,0-.283-.683L28.717.283A.967.967,0,0,0,28.033,0H5.8A5.806,5.806,0,0,0,0,5.8v5.8a.967.967,0,1,0,1.933,0V5.8A3.867,3.867,0,0,1,5.8,1.933H27.067V9.667a5.806,5.806,0,0,0,5.8,5.8H40.6V52.2a3.867,3.867,0,0,1-3.867,3.867H5.8A3.867,3.867,0,0,1,1.933,52.2V15.95A.967.967,0,0,0,.967,14.983ZM29,9.667V3.3L39.233,13.533H32.867A3.867,3.867,0,0,1,29,9.667Z"
        transform="translate(0 0)"
        fill="#fff"
      />
      <path
        id="Shape-3"
        data-name="Shape"
        d="M7,52.833a.967.967,0,0,0,.967.967h3.867a.967.967,0,1,0,0-1.933h-2.9v-2.9a.967.967,0,1,0-1.933,0Z"
        transform="translate(-0.233 -1.6)"
        fill="#fff"
      />
      <path
        id="Shape-4"
        data-name="Shape"
        d="M7.967,21.933h26.1a.967.967,0,1,0,0-1.933H7.967a.967.967,0,0,0,0,1.933Z"
        transform="translate(-0.233 -0.667)"
        fill="#fff"
      />
      <path
        id="Shape-5"
        data-name="Shape"
        d="M7.967,27.933h26.1a.967.967,0,1,0,0-1.933H7.967a.967.967,0,1,0,0,1.933Z"
        transform="translate(-0.233 -0.867)"
        fill="#fff"
      />
      <path
        id="Shape-6"
        data-name="Shape"
        d="M7.967,33.933h26.1a.967.967,0,1,0,0-1.933H7.967a.967.967,0,1,0,0,1.933Z"
        transform="translate(-0.233 -1.067)"
        fill="#fff"
      />
      <path
        id="Shape-7"
        data-name="Shape"
        d="M7.967,39.933H21.5A.967.967,0,1,0,21.5,38H7.967a.967.967,0,1,0,0,1.933Z"
        transform="translate(-0.233 -1.267)"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PurchasePrice',
}
</script>

<style scoped>
.a {
  fill: #dfe1e6;
}

.b {
  fill: currentColor;
}
</style>
