<template>
  <v-col>
    <v-col>
      <v-row class="wrap mt-3 justify-center align-baseline">
        <v-col cols="7">
          <v-text-field
            v-model="searchTerm"
            flat
            label="Search by description..."
            append-icon="search"
            solo-inverted
            hide-details
            style="border: 1px grey solid"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-btn @click="openModal">Create new item</v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-card>
      <h3 class="purple-icon mt-2 ml-2">Info definitions</h3>

      <v-card-title>
        <v-row class="wrap align-center">
          <listing-info-definition-modal
            v-if="showModal"
            :close-func="closeModal"
          />
          <v-progress-circular
            v-show="listingLoading"
            :size="20"
            class="ml-2"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items-per-page="30"
        :items="filteredItems"
        class="elevation-1"
      >
        <template #item="{ item }">
          <listing-info-definition-row
            :key="item.id"
            :after-change="getDefinitions"
            :item="item"
          />
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import filter from 'lodash/filter'
import ListingInfoDefinitionModal from '../info-item-definition/listing-info-definition-modal'
import ListingInfoDefinitionRow from '../info-item-definition/listing-info-definition-row'
export default {
  components: {
    ListingInfoDefinitionRow,
    ListingInfoDefinitionModal,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [],
  data: function () {
    return {
      searchTerm: '',
      showModal: false,
    }
  },
  computed: {
    headers() {
      let cols = [
        { text: 'Topic', align: 'center', sortable: false },
        { text: 'Sub-Topic', align: 'center', sortable: false },
        { text: 'Additional Desc', align: 'center', sortable: false },
        { text: 'Is required (house)', align: 'center', sortable: false },
        { text: 'Is required (unit)', align: 'center', sortable: false },
        { text: 'Header', align: 'center', sortable: false },
        { text: 'Actions', align: 'center', sortable: false },
      ]
      return cols
    },

    filteredItems() {
      return filter(
        this.$store.state.listingInfoDef.listingInfoDefs,
        item =>
          (item.topic &&
            item.topic.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
          (item.comments &&
            item.comments
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())) ||
          (item.sub_topic &&
            item.sub_topic
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()))
      )
    },
  },
  mounted() {
    this.$store.dispatch('getListingInfoDefs')
  },
  methods: {
    closeModal() {
      this.showModal = false
    },
    openModal() {
      this.showModal = true
    },
    getDefinitions() {
      this.$store.dispatch('getListingInfoDefs')
    },
  },
}
</script>
