<template>
  <v-card>
    <v-card-title>
      <h2 class="text-h6">
        <p v-if="item.listing" class="mb-0">
          {{ item.listing.nickname }}
        </p>
        <span v-else>No house connected</span>
      </h2>
    </v-card-title>
    <v-card-text>
      <div class="mb-2">
        <div class="font-weight-bold">Minut Home Name:</div>
        <span>{{ item.home_name }}</span>
      </div>
      <div class="mb-2">
        <div class="font-weight-bold">Description:</div>
        <span>{{ item.description }}</span>
      </div>
      <div class="mb-2">
        <div class="font-weight-bold">Home ID:</div>
        <span>{{ item.home }}</span>
      </div>
      <div class="mb-2">
        <div class="font-weight-bold">Battery:</div>
        <span>{{ `${item.battery.percent} %` }}</span>
      </div>
      <div v-if="item.listing" class="mb-2">
        <div class="font-weight-bold">Listing status:</div>
        <span>{{ item.listing.active ? 'Active' : 'Inactive' }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'DetailsModal',
  props: ['item'],
}
</script>

<style scoped></style>
