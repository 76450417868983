<template>
  <div class="d-flex justify-space-between pb-1 align-center">
    <div class="d-flex align-center flex-wrap">
      <v-tooltip v-if="showOpenInNew" class="mr-2" bottom open-delay="200">
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="info"
            :target="isMobileOrIPad ? undefined : '_blank'"
            :to="`/dashboard/listing-task/${listingTask.id}`"
            icon
            small
            v-on="on"
          >
            <v-icon class="mr-2">mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>Open In New Tab</span>
      </v-tooltip>
      <v-icon v-else color="info" class="mr-2" small>fas fa-home</v-icon>
      <div
        v-if="listingTask.listing"
        class="text-body-1 text-md-h6 font-weight-medium mr-2"
      >
        {{ listingTask.listing.nickname }}
        <span id="span" class="grey--text">#{{ listingTask.id }}</span>
      </div>
      <div v-else class="text-body-1 text-md-h6 font-weight-medium mr-2">
        Company task
      </div>
      <div class="px-2">
        <lt-taskim-btn :listing-task-id="listingTask.id" />
      </div>
      <v-tooltip v-if="listingTask.follow_up_by_id === currentUserId" bottom>
        <template #activator="{ on, attrs }">
          <v-icon v-bind="attrs" color="warning" v-on="on"> mdi-alarm</v-icon>
        </template>
        <span class="text-caption">{{
          timeFromNow(listingTask.follow_up_at)
        }}</span>
      </v-tooltip>
      <v-chip v-if="listingTask.origin_listing_task_id" color="info" x-small
        >Continuation
      </v-chip>
      <v-chip
        v-if="listingTask.store_item_purchase_id"
        color="notification"
        x-small
        >{{ listingTask.store_item_purchase.status }}
      </v-chip>
      <v-tooltip
        v-if="listingTask.listing_task_template_id"
        bottom
        :disabled="
          !listingTask.follow_up_templates ||
          !listingTask.follow_up_templates.length
        "
      >
        <template #activator="{ on }">
          <v-icon class="pa-1 rounded purple-grey-br" v-on="on"
            >account_tree
          </v-icon>
        </template>
        <v-row class="white--text mt-2" wrap style="min-width: 200px">
          <v-col cols="12" class="bb-1 bolder center-text"
            >Following tasks will be created once this moves to done
          </v-col>
          <ul>
            <li
              v-for="(template, i) in listingTask.follow_up_templates"
              :key="i"
            >
              {{ template.description }} | after
              {{ template.create_days_after || 0 }} days
            </li>
          </ul>
        </v-row>
      </v-tooltip>
      <v-icon
        v-if="
          listingTask.listing_task_template &&
          listingTask.listing_task_template.loop
        "
        class="ml-2 pa-1 rounded purple-grey-br"
        >mdi-sync
      </v-icon>
    </div>
    <!-- <div>
      <lt-status
        :listing-task="listingTask"
        :small="isMobileOrIPad"
        :hide-done="true"
      />
    </div> -->
  </div>
</template>

<script>
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import DeviceMixin from 'components/mixins/device-mixin'

import { mapGetters } from 'vuex'
import LtTaskimBtn from 'components/taskim/lt-taskim-btn'

export default {
  name: 'LtTitle',
  components: { LtTaskimBtn },
  mixins: [PermissionsMixin, CommonFunctions, DeviceMixin],
  props: ['listingTask'],
  computed: {
    ...mapGetters(['currentUserId']),
    showOpenInNew() {
      return !this.$router.currentRoute.path.includes('dashboard/listing-task/')
    },
  },
}
</script>

<style scoped></style>
