<template>
  <v-snackbar
    v-if="!uploadError"
    v-model="open"
    top
    app
    color="black-opac"
    class="top-index pa-0"
    content-class="pa-3"
    :timeout="-1"
    min-width="250"
  >
    <div class="text-subtitle-1 font-weight-medium">
      Images are uploading...
    </div>
    <div>Please don't leave the page until completion</div>
    <template #action>
      <div class="pa-3">
        <v-progress-circular
          :rotate="0"
          :size="70"
          :width="5"
          :indeterminate="imageProgress === 100"
          :value="imageProgress"
          color="primary"
        >
          {{ imageProgress }}%
        </v-progress-circular>
      </div>
    </template>
  </v-snackbar>
  <v-snackbar
    v-else-if="uploadError"
    top
    app
    color="warning"
    class="top-index pa-0"
    content-class="pa-3"
    :timeout="-1"
    min-width="250"
    :value="true"
  >
    <div class="text-subtitle-1 font-weight-medium d-flex align-center">
      <v-icon color="white" class="pr-2">fas fa-exclamation-triangle</v-icon>
      {{ uploadError }}
    </div>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LtImagesSnack',
  data() {
    return {
      open: false,
    }
  },
  computed: {
    ...mapGetters(['imageProgress', 'uploadError']),
  },
  watch: {
    imageProgress(val) {
      this.open = val !== 0
    },
  },
}
</script>

<style scoped>
div >>> .black-opac {
  background-color: rgba(0, 0, 0, 0.7);
}
.top-index {
  z-index: 10;
}
</style>
