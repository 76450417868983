<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.83 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="Group_10274" data-name="Group 10274">
          <g id="home-4-2">
            <g id="Group_10270-2" data-name="Group 10270-2">
              <path
                id="Path_5168-2"
                data-name="Path 5168-2"
                class="cls-1"
                d="M20.83,9.66,11,.21a.72.72,0,0,0-1,0L.23,9.66a.72.72,0,0,0,0,1,.72.72,0,0,0,.53.23H2V21.26a.74.74,0,0,0,.74.74H7.94a.74.74,0,0,0,.74-.74h0v-5.9h3.69v5.9a.74.74,0,0,0,.74.74h5.17a.74.74,0,0,0,.74-.74h0V10.93h1.3a.74.74,0,0,0,.73-.74.75.75,0,0,0-.22-.53ZM19,10.19a.74.74,0,0,0-.74.74h0V21.26H13.11v-5.9a.74.74,0,0,0-.74-.74H8.68a.74.74,0,0,0-.74.74h0v5.9H2.78V10.93A.74.74,0,0,0,2,10.19H.74L10.53.74l9.79,9.45Z"
              />
            </g>
          </g>
          <g id="mechanical-gears-">
            <g id="Group_10273" data-name="Group 10273">
              <path
                id="Path_5224"
                data-name="Path 5224"
                class="cls-1"
                d="M17.42,11.33a3.09,3.09,0,0,0,.74-.61l.77.44.7-1.21-.77-.44A3.39,3.39,0,0,0,19,8.56l.87-.15L19.66,7l-.88.15a3.39,3.39,0,0,0-.48-.82l.57-.68-1.07-.9-.57.68a3,3,0,0,0-.9-.33V4.24h-1.4v.89a3,3,0,0,0-.9.33l-.57-.68-1.07.9.57.68a3.39,3.39,0,0,0-.48.82L11.61,7l-.25,1.38.87.15a3.39,3.39,0,0,0,.17.95l-.77.44.7,1.21.77-.44a3.25,3.25,0,0,0,.73.61l-.3.84,1.32.48.3-.83a3.86,3.86,0,0,0,1,0l.3.83,1.31-.48Zm-1.8-1.09a1.79,1.79,0,1,1,1.79-1.79h0a1.78,1.78,0,0,1-1.78,1.79Z"
              />
              <path
                id="Path_5225"
                data-name="Path 5225"
                class="cls-1"
                d="M23.45,12.39l-.6.2a2.79,2.79,0,0,0-.42-.54l.33-.54L21.91,11l-.33.53a2.58,2.58,0,0,0-.67-.14l-.09-.63-1,.14.08.63a2.48,2.48,0,0,0-.6.32l-.47-.43-.67.75.47.42a3,3,0,0,0-.26.64l-.63,0,0,1,.64,0a2.47,2.47,0,0,0,.21.65l-.5.39.62.79.5-.39a2.17,2.17,0,0,0,.58.36l-.14.63,1,.21.14-.62a2.53,2.53,0,0,0,.67-.1l.3.56.89-.47-.3-.56a2.47,2.47,0,0,0,.46-.51l.59.24.37-.93-.58-.24a2.21,2.21,0,0,0,0-.68l.6-.2Zm-2.54,2.67A1.27,1.27,0,0,1,19.47,14a1.28,1.28,0,1,1,1.44,1.09Z"
              />
              <path
                id="Path_5226"
                data-name="Path 5226"
                class="cls-1"
                d="M24.8,8.91l0-.79-.5,0a2.35,2.35,0,0,0-.17-.51l.39-.31-.49-.62-.4.31a1.53,1.53,0,0,0-.46-.28l.1-.5L22.53,6l-.1.49a2.52,2.52,0,0,0-.54.08l-.23-.44-.7.38.23.44a1.93,1.93,0,0,0-.35.4l-.47-.19-.29.74.46.19a2,2,0,0,0,0,.54l-.48.15.25.75.47-.15a2.31,2.31,0,0,0,.34.42l-.26.43.67.41.26-.42a2,2,0,0,0,.53.11l.08.5.78-.12-.07-.5a1.63,1.63,0,0,0,.47-.25l.38.33.52-.59L24.1,9.4a1.94,1.94,0,0,0,.2-.5Zm-1.89.42a1,1,0,0,1-1.37-.41,1,1,0,0,1,1.79-1h0A1,1,0,0,1,22.91,9.33Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Setups',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
