<template>
  <div>
    <side-menu :items="menuItems" />
    <v-sheet
      :style="`margin-${isRtl ? 'right' : 'left'}: ${
        isMobile ? '0' : isIPad ? '56px' : '250px'
      }; padding-bottom: 56px;`"
    >
      <slot />
    </v-sheet>
  </div>
</template>

<script>
import SideMenu from 'components/common/side-menu.vue'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'MenuPage',
  mixins: [DeviceMixin],
  components: { SideMenu },
  props: ['menuItems'],
}
</script>

<style scoped></style>
