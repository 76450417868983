<template>
  <v-btn
    v-if="authUrl"
    outlined
    dense
    text
    mid
    :disabled="disabled"
    @click="openXeroLink"
  >
    <img width="30" :src="require(`@/assets/images/integrations/xero.png`)" />
    {{ update ? 'update' : 'Connect accounts' }}
  </v-btn>
</template>
<script>
import axios from '@/axios/config'
import Toaster from '@/utils/toaster'
export default {
  name: 'XeroLink',
  props: ['update', 'disabled'],
  data() {
    return {
      authUrl: null,
    }
  },
  methods: {
    async openXeroLink() {
      if (this.update) {
        await axios.get(`/api/xero-api/refresh`)
        Toaster.show([{ type: 'success', text: 'Xero account updated' }])
      } else {
        const url = this.authUrl
        window.location.replace(url)
      }
    },
  },
  async mounted() {
    const { data } = await axios.get(`/api/xero-api/auth_url`, {
      params: { update: this.update },
    })
    this.authUrl = data
  },
}
</script>
