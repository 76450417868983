import { filter, orderBy } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import pickBy from 'lodash/fp/pickBy'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import map from 'lodash/map'
import { getQuillDefaultOptions } from '@/utils/editor-options'
import ExpoNotifications from '@/utils/expoNotifications'
import { every } from 'lodash/fp'
import { TASK_STATUSES } from '@/consts'
import ListingTaskMethodsMixin from 'components/listing-tasks/mixins/listing-task-methods-mixin'

export default {
  mixins: [ListingTaskMethodsMixin],
  computed: {
    ...mapGetters([
      'commentsList',
      'currentUserId',
      'currentListingTask',
      'internalComments',
    ]),
    ...mapGetters('users', ['activeUsers', 'usersMap', 'approvers']),
    ...mapState('userGroups', ['groups']),
    feedOpen() {
      return this.$route.query.comment === 'true'
    },
    subTasksDone() {
      if (
        this.currentListingTask.task_type === 'cleaning' ||
        this.currentListingTask.task_type === 'inspection'
      ) {
        return every(
          t => t.is_valid || t.status === 'Done',
          this.currentListingTask.sub_tasks
        )
      }
      return every(
        t => t.status === 'Done' || t.status === "Can't do",
        this.currentListingTask.sub_tasks
      )
    },
    reservationRequests() {
      if (
        this.currentListingTask.reg_cleaning &&
        this.currentListingTask.following_reservation_id
      ) {
        return Object.keys(
          pickBy(item => {
            return Boolean(item)
          }, this.currentListingTask.following_reservation.special_requests)
        ).join(', ')
      }
      return null
    },
    listSubjects() {
      let subjects = []
      if (this.hasSubTasks) {
        subjects = uniq(
          map(
            orderBy(this.currentListingTask.sub_tasks, ['id']),
            'list_subject'
          )
        )
        if (subjects.includes(null)) {
          subjects.push('Sub Tasks')
        }
      }
      return compact(subjects)
    },
    hasSubTasks() {
      return !this.isEmpty(this.currentListingTask.sub_tasks)
    },
    hasShadowSubTasks() {
      return !this.isEmpty(this.currentListingTask.shadow_sub_tasks)
    },
    logs() {
      if (this.currentListingTask) {
        return this.currentListingTask.log
      }
      return []
    },
    mentionItems() {
      return this.activeUsers.map(u => ({
        id: u.id,
        value: u.name,
      }))
    },
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } })
      },
      get() {
        return this.$route.query.tab
      },
    },
    editorOptions() {
      return getQuillDefaultOptions(this.mentionItems, 'Add Comment')
    },
  },
}
