/**
 * Loads an external JavaScript file
 * @param {string} src - The URL of the script to load
 * @returns {Promise} Resolves when script is loaded, rejects on error
 */
export const loadScript = src => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}
