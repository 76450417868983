<template>
  <v-dialog v-model="showMenu" v-hotkey="keymap" max-width="300px">
    <v-card v-if="showMenu" class="pa-3">
      <v-autocomplete
        v-model="selectedItem"
        :items="items"
        autofocus
        class="small-select"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import menuItems from './menu-items'

export default {
  name: 'MenuHotKey',
  data() {
    return {
      showMenu: false,
      selectedItem: '',
    }
  },
  computed: {
    keymap() {
      return {
        'ctrl+k': this.show,
        enter: this.goToMenuItem,
        esc: this.hide,
      }
    },
    items() {
      return menuItems
    },
  },
  methods: {
    show() {
      this.showMenu = true
    },
    hide() {
      this.selectedItem = ''
      this.showMenu = false
    },
    goToMenuItem() {
      if (this.selectedItem && this.selectedItem !== this.$route.fullPath) {
        this.$router.push(this.selectedItem)
      }
      this.hide()
    },
  },
}
</script>

<style scoped></style>
