<template>
  <v-dialog v-if="showSave" v-model="dialog" max-width="400">
    <template #activator="{ on }">
      <v-btn outlined mid class="flex" color="info" v-on="on">
        Save Strategy
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form" @submit.prevent="saveStrategy">
        <v-card-title> Save Strategy </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="name"
            label="Name"
            dense
            :rules="[required]"
            outlined
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small color="primary" outlined @click="closeModal"
            >Cancel</v-btn
          >
          <v-btn small color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import axios from 'axios'
export default {
  name: 'SaveStrategyModal',
  mixins: [formRules],
  props: ['filters', 'strategy', 'save'],
  data() {
    return {
      name: null,
      dialog: false,
      showSave: false,
    }
  },
  watch: {
    strategy() {
      this.showSave = !this.strategy
    },
  },
  mounted() {
    this.showSave = !this.strategy
  },
  computed: {},
  methods: {
    closeModal() {
      this.dialog = false
    },
    saveStrategy() {
      this.$store.commit('updateLoading', true)
      const updateHash = {
        base_price: this.filters.base_price,
        min_price_weekend: this.filters.min_price_weekend,
        min_price_weekday: this.filters.min_price_weekday,
        max_price: this.filters.max_price,
        min_nights_weekend: this.filters.min_nights_weekend,
        min_nights_weekday: this.filters.min_nights_weekday,
        factors: this.filters.factors,
        activated: this.filters.activated,
        enable_emergency_mode: this.filters.enable_emergency_mode,
        enable_group_pricing: this.filters.enable_group_pricing,
        enable_multi_unit_pricing: this.filters.enable_multi_unit_pricing,
        enable_trapped_between_weekends:
          this.filters.enable_trapped_between_weekends,
        name: this.name,
      }

      try {
        axios
          .post(`/api/pricing-strategies/`, updateHash)
          .then(response => {
            this.$store.state.currentPricing.pricing_strategy_id =
              response.data.data.id
            this.$emit('save')
          })
          .catch(alert)
          .finally(() => {
            this.closeModal
          })
        this.$store.commit('updateLoading', false)
      } catch (error) {
        alert(error)
      }
    },
  },
}
</script>

<style scoped></style>
