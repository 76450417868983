<template>
  <v-card>
    <v-form ref="form" @submit.prevent="submit">
      <v-card-text>
        <span v-if="task.ask_rating" class="text-body-2">Rate Condition:</span>
        <v-rating
          v-if="task.ask_rating"
          v-model="task.rating"
          length="5"
          size="18"
          color="notification"
          @click.stop
        />
        <gallery
          v-if="task.must_upload_pic"
          folder="service-calls"
          :cols="4"
          :button-height="200"
          :button-text="$t('Upload your photos')"
          :thumbnails="true"
          :images="afterImages"
          :on-change="addImages"
          :uploadable="true"
          @upload-started="uploadStarted = true"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn small color="warning" @click="closeModal">{{
          $t('Cancel')
        }}</v-btn>
        <v-btn small color="primary" :disabled="submitDisabled" type="submit">{{
          $t('Next')
        }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import Gallery from 'components/common/gallery.vue'

export default {
  name: 'InspectionApprovalModal',
  components: { Gallery },
  mixins: [formRules, CommonFunctions],
  props: ['task', 'onSuccess', 'onCancel'],
  methods: {
    submit() {
      const payload = {
        id: this.task.id,
        is_valid: true,
      }
      if (this.task.ask_rating) {
        payload.rating = this.task.rating
      }
      this.onSuccess(payload)
      this.$store.commit('hideModal')
    },
    closeModal() {
      this.onCancel()
      this.$store.commit('hideModal', null)
    },
    addImages(images) {
      this.afterImages = this.afterImages.concat(images)
      this.onSuccess({ after_images: this.afterImages, id: this.task.id })
    },
  },
  computed: {
    submitDisabled() {
      if (this.task.ask_rating && !this.task.rating) {
        return true
      }
      return (
        this.task.must_upload_pic &&
        !this.task.after_images.length &&
        !this.uploadStarted
      )
    },
  },
  data() {
    return {
      uploadStarted: false,
      afterImages: [],
    }
  },
}
</script>

<style scoped></style>
