<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.59 18">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="task">
          <g id="Group_10125" data-name="Group 10125">
            <path
              id="Path_4955"
              data-name="Path 4955"
              class="cls-1"
              d="M3,4.29H1.29A1.29,1.29,0,0,1,0,3V1.29A1.29,1.29,0,0,1,1.29,0H3A1.29,1.29,0,0,1,4.29,1.29V3A1.29,1.29,0,0,1,3,4.29ZM1.29.86a.43.43,0,0,0-.43.43V3a.43.43,0,0,0,.43.43H3A.43.43,0,0,0,3.43,3V1.29A.43.43,0,0,0,3,.86Z"
            />
          </g>
          <g id="Group_10126" data-name="Group 10126">
            <path
              id="Path_4956"
              data-name="Path 4956"
              class="cls-1"
              d="M3,11.14H1.29A1.28,1.28,0,0,1,0,9.86V8.14A1.28,1.28,0,0,1,1.29,6.86H3A1.28,1.28,0,0,1,4.29,8.14V9.86A1.28,1.28,0,0,1,3,11.14ZM1.29,7.71a.43.43,0,0,0-.43.43V9.86a.43.43,0,0,0,.43.43H3a.43.43,0,0,0,.43-.43V8.14A.43.43,0,0,0,3,7.71Z"
            />
          </g>
          <g id="Group_10127" data-name="Group 10127">
            <path
              id="Path_4957"
              data-name="Path 4957"
              class="cls-1"
              d="M3,18H1.29A1.29,1.29,0,0,1,0,16.71V15a1.29,1.29,0,0,1,1.29-1.29H3A1.29,1.29,0,0,1,4.29,15v1.71A1.29,1.29,0,0,1,3,18ZM1.29,14.57A.43.43,0,0,0,.86,15v1.71a.43.43,0,0,0,.43.43H3a.43.43,0,0,0,.43-.43V15A.43.43,0,0,0,3,14.57Z"
            />
          </g>
          <g id="Group_10128" data-name="Group 10128">
            <path
              id="Path_4958"
              data-name="Path 4958"
              class="cls-1"
              d="M20.14,2.57H6.43A.43.43,0,0,1,6,2.16a.44.44,0,0,1,.41-.45H20.14a.43.43,0,0,1,.45.41.43.43,0,0,1-.41.45Z"
            />
          </g>
          <g id="Group_10129" data-name="Group 10129">
            <path
              id="Path_4959"
              data-name="Path 4959"
              class="cls-1"
              d="M20.14,9.43H6.43a.43.43,0,1,1,0-.86H20.14a.43.43,0,1,1,0,.86Z"
            />
          </g>
          <g id="Group_10130" data-name="Group 10130">
            <path
              id="Path_4960"
              data-name="Path 4960"
              class="cls-1"
              d="M20.14,16.29H6.43A.43.43,0,0,1,6,15.88a.43.43,0,0,1,.41-.45H20.14a.43.43,0,0,1,.45.41.44.44,0,0,1-.41.45Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Tasks',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
