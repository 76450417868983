<template>
  <v-autocomplete
    v-model="filteredSelectedContractor"
    :clearable="!readonly"
    :items="items"
    :label="typeof label !== 'undefined' ? $t(label) : $t('Select Contractor')"
    :disabled="disabled"
    :readonly="readonly"
    :rules="rules"
    :hint="hint"
    :persistent-hint="persistentHint"
    :multiple="allowMultiple"
    :search-input.sync="search"
    item-text="name"
    :prepend-inner-icon="icon"
    :filter="customFilter"
    item-value="id"
    :outlined="typeof outlined !== 'undefined' ? outlined : true"
    dense
    :hide-details="!!hideDetails"
    @input="input"
    @change="changeContractor"
  >
    <template #item="{ item }" class="listing-picker">
      <user-avatar
        class="mr-2"
        :image="item.picture"
        :name="`${item.name}`"
        :role="item.role"
      />
      <div class="d-flex align-center flex-grow-1">
        <span>
          <div>
            <h5>{{ item.name }}</h5>
            <h6>{{ item.ach_recipient_name }}</h6>
          </div>
        </span>

        <span v-if="item.field_of_expertise" class="purple-icon">
          - {{ item.field_of_expertise }}
        </span>

        <v-spacer />
        <Tag
          v-if="item.company_name"
          color="black"
          class="ml-2 yellow lighten-2"
        >
          {{ item.company_name }}
        </Tag>
        <Tag v-if="item.department" color="black" class="ml-2 green lighten-2">
          {{ item.department }}
        </Tag>
        <Tag v-if="item.on_payrole" color="black" class="ml-2 cyan lighten-2">
          Staff
        </Tag>
        <Tag v-else color="black" class="ml-2 yellow">3rd party</Tag>
      </div>
    </template>
    <template v-if="!textSelection" #selection="{ item }">
      <v-chip
        v-if="allowMultiple"
        :close="!readonly"
        small
        class="ma-1 px-2"
        @click:close="removeItem(item)"
      >
        <user-avatar
          v-if="initials"
          :image="item.picture"
          :name="`${item.name}`"
          :role="item.role"
        />
        <span v-else>{{ item.name }}</span>
        <span v-if="item.ach_recipient_name" class="">
          ({{ item.ach_recipient_name }})</span
        >
      </v-chip>
      <div v-else>
        <span> {{ item.name }} </span>
        <span v-if="item.ach_recipient_name" class="ml-1 text-caption">
          ({{ item.ach_recipient_name }})
        </span>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import UserAvatar from 'components/common/user-avatar'
import Tag from 'components/common/tag'
import { mapGetters } from 'vuex'

export default {
  components: {
    UserAvatar,
    Tag,
  },
  props: [
    'filterChange',
    'allowMultiple',
    'includeInActive',
    'hideDetails',
    'textSelection',
    'enableSug',
    'hint',
    'persistentHint',
    'disabled',
    'readonly',
    'outlined',
    'label',
    'input',
    'addOnData',
    'initials',
    'icon',
    'rules',
    'value',
    'filteredItems',
  ],
  data() {
    return {
      selectedContractor: this.value,
      search: null,
      usedSug: false,
    }
  },
  methods: {
    removeItem(item) {
      this.selectedContractor = this.selectedContractor.filter(
        i => i !== item.id
      )
      this.changeContractor(this.selectedContractor)
    },
    changeContractor(contractor) {
      this.selectedContractor = contractor
      this.usedSug = true
      if (this.filterChange) {
        if (this.addOnData) {
          this.filterChange(this.selectedContractor, this.addOnData)
        } else {
          this.filterChange(this.selectedContractor)
        }
      }
      this.search = ''
      this.$emit('input', this.selectedContractor)
      this.$emit('update:value', this.selectedContractor)
    },
    customFilter(item, queryText) {
      const name = item.name ? item.name.toLowerCase() : ''
      const recipientName = item.ach_recipient_name
        ? item.ach_recipient_name.toLowerCase()
        : ''
      const searchText = queryText.toLowerCase()
      return name.includes(searchText) || recipientName.includes(searchText)
    },
  },
  computed: {
    ...mapGetters('users', ['activeUsers', 'usersList']),
    listingTaskCreationInfo() {
      return this.$store.state.listingTaskCreationInfo
    },
    items() {
      if (this.filteredItems) {
        return this.filteredItems
      }
      if (this.includeInActive) {
        return this.usersList
      } else {
        return this.activeUsers
      }
    },
    filteredSelectedContractor() {
      if (Array.isArray(this.selectedContractor) && this.items.length > 0) {
        const validIds = this.items.map(item => item.id)
        return this.selectedContractor.filter(id => validIds.includes(id))
      }
      return this.selectedContractor
    },
  },
  watch: {
    value: {
      handler(val) {
        this.selectedContractor = val
      },
      deep: true,
    },
  },
}
</script>
