<template>
  <v-combobox
    :value="value"
    :items="itemsList"
    :label="label || 'Taskim Sections'"
    clearable
    dense
    hide-details
    outlined
    no-filter
    multiple
    :search-input.sync="search"
    :disabled="disabled"
    @input="itemsChanged"
  >
    <template #selection="{ item }">
      <v-chip
        v-if="allItems[item]"
        small
        outlined
        :close="allowedSectionsIds.has(Number(item))"
        @click:close="itemClicked(item)"
      >
        <span class="text-caption text-no-wrap">
          {{ allItems[item].sectionName }}
        </span>
        <span class="text-caption text-no-wrap ml-2">
          ({{ allItems[item].boardsNames.join(', ') }})
        </span>
      </v-chip>
    </template>
    <template
      #item="{ item }"
      class="listing-picker purple-icon mb-1 bb-1 pb-1"
    >
      <v-list-item @click="itemClicked(item.sectionId)">
        <v-list-item-title class="d-flex align-center">
          <span class="text-caption text-no-wrap">
            {{ item.sectionName }}
          </span>
          <span class="text-caption text-no-wrap ml-2">
            ({{ item.boardsNames.join(', ') }})
          </span>
          <v-spacer />
          <v-icon
            v-if="sectionsIdsSet.has(Number(item.sectionId))"
            class="ml-5"
            color="success"
          >
            mdi-playlist-check
          </v-icon>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import { mapActions, mapState } from 'vuex'
import { isEqual, take } from 'lodash'

export default {
  name: 'LtTaskimSectionsSelect',
  components: {},
  mixins: [CommonFunctions],
  props: ['value', 'disabled', 'label'],
  data() {
    return {
      search: '',
    }
  },
  mounted() {
    if (!this.allBoardsFetched && !this.allBoardsFetching) {
      this.getAllBoards()
    }
    if (!this.boardsFetched && !this.boardsFetching) {
      this.getBoards()
    }
  },
  computed: {
    ...mapState('taskim', [
      'allBoardsFetching',
      'allBoardsFetched',
      'allBoards',
      'boardsFetched',
      'boardsFetching',
      'boards',
    ]),
    sectionsIdsSet() {
      return new Set(this.value.map(Number))
    },
    allItems() {
      const items = {}
      this.allBoards.forEach(board => {
        board.sections_relations.forEach(section_relation => {
          const sectionId = section_relation.section.id
          if (!items[sectionId]) {
            items[sectionId] = {
              sectionId,
              sectionName: section_relation.section.name,
              boardsNames: [],
            }
          }
          items[sectionId].boardsNames.push(board.name)
        })
      })
      return items
    },
    items() {
      const items = {}
      this.boards.forEach(board => {
        board.sections_relations.forEach(section_relation => {
          const sectionId = section_relation.section.id
          if (!items[sectionId]) {
            items[sectionId] = {
              sectionId,
              sectionName: section_relation.section.name,
              boardsNames: [],
            }
          }
          items[sectionId].boardsNames.push(board.name)
        })
      })
      return items
    },
    itemsList() {
      return take(
        Object.values(this.items)
          .sort(this.optionsSort)
          .filter(this.optionsFilter),
        7
      )
    },
    allowedSectionsIds() {
      return new Set(Object.keys(this.items).map(Number))
    },
  },
  methods: {
    ...mapActions('taskim', ['getAllBoards', 'getBoards']),
    optionsSort(itemA, itemB) {
      return (itemA.sectionName + itemA.boardsNames.join(''))
        .toLowerCase()
        .localeCompare(
          (itemB.sectionName + itemB.boardsNames.join('')).toLowerCase()
        )
    },
    optionsFilter(item) {
      return (
        !this.search ||
        (item.sectionName + item.boardsNames.join(''))
          .toLowerCase()
          .includes(this.search.toLowerCase())
      )
    },
    itemClicked(sectionId) {
      if (this.allowedSectionsIds.has(Number(sectionId))) {
        const sectionsIdsSet = this.sectionsIdsSet
        if (sectionsIdsSet.has(Number(sectionId))) {
          sectionsIdsSet.delete(Number(sectionId))
        } else {
          sectionsIdsSet.add(Number(sectionId))
        }
        this.$emit('input', [...sectionsIdsSet])
        this.$emit('change', [...sectionsIdsSet])
      }
    },
    itemsChanged(value) {
      if (!isEqual(value, this.value)) {
        const valueSet = new Set(value.map(Number))
        const allValuesSet = new Set([...value, ...this.value].map(Number))
        const sectionsIdsToChange = []
        allValuesSet.forEach(sectionId => {
          if (
            !this.allowedSectionsIds.has(Number(sectionId)) ||
            valueSet.has(Number(sectionId))
          ) {
            sectionsIdsToChange.push(sectionId)
          }
        })
        this.$emit('input', sectionsIdsToChange)
        this.$emit('change', sectionsIdsToChange)
      }
    },
  },
}
</script>

<style scoped></style>
