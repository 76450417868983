<template>
  <v-row v-if="investor">
    <v-col cols="12">
      <b>Name:</b> {{ investor.first_name }} {{ investor.last_name }}
    </v-col>
    <v-col cols="12"> <b>Email:</b> {{ investor.email }} </v-col>
    <v-col cols="12">
      <b>Personal Address:</b> {{ investor.personal_address }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['investor'],
  computed: {},
  watch: {},
}
</script>
