<template>
  <div>
    <span>New Custom Field</span>
    <v-text-field
      key="custom_codes_key"
      v-model="customCode.key"
      class="text-capitalize mb-4"
      label="Key"
      outlined
      hide-details
      dense
      @focus="$emit('touched')"
    />
    <v-text-field
      key="custom_codes_value"
      v-model="customCode.value"
      class="text-capitalize mb-4"
      label="Value"
      hide-details
      outlined
      dense
      @focus="$emit('touched')"
    />
  </div>
</template>

<script>
export default {
  props: ['customCode', 'sectionKey'],
}
</script>

<style></style>
