<template>
  <v-layout align-baseline>
    <v-flex
      v-for="i in 6"
      :key="i"
      xs2
      class="br-1w"
      :class="getOccupancyColor(monthData(i) ? monthData(i)['occupancy'] : '')"
    >
      <v-layout column class="white--text text-center text-body-2">
        <v-flex v-if="monthData(i)">
          {{ monthData(i)['month'].substring(0, 3) }}
        </v-flex>
        <v-flex v-if="monthData(i)" class="bolder">
          {{ round(monthData(i)['occupancy']) + '%' }}
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import round from 'lodash/round'

export default {
  props: ['monthOccupancy'],
  data: () => ({}),
  computed: {},
  methods: {
    getOccupancyColor(occupancy) {
      if (occupancy < 10) {
        return 'red0-10'
      } else if (occupancy < 20) {
        return 'red10-20'
      } else if (occupancy < 35) {
        return 'yellow20-30'
      } else if (occupancy < 40) {
        return 'green30-40'
      } else if (occupancy < 55) {
        return 'green40-55'
      } else if (occupancy < 70) {
        return 'green55-70'
      } else if (occupancy < 101) {
        return 'green70-90'
      }
    },
    round(num) {
      return round(num)
    },
    monthData(addAmount) {
      addAmount = addAmount - 1
      var currentDate = this.$moment()
      var futureMonth = this.$moment(currentDate).add(addAmount, 'M')
      if (this.monthOccupancy) {
        return {
          month: futureMonth.format('MMMM'),
          occupancy:
            round(
              this.monthOccupancy[Number(futureMonth.year())][
                Number(futureMonth.month()) + 1
              ],
              2
            ) * 100,
        }
      }
    },
  },
}
</script>
