<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(11.478 0)">
      <path
        class="a"
        d="M237.643,21.735a.506.506,0,0,0-.738,0l-1.2,1.25V11.211a.522.522,0,1,0-1.044,0V24.3a.546.546,0,0,0,.322.5.5.5,0,0,0,.2.041.51.51,0,0,0,.368-.159l2.087-2.182A.564.564,0,0,0,237.643,21.735Z"
        transform="translate(-234.666 -10.666)"
      />
    </g>
    <g transform="translate(9.391 10.91)">
      <path
        class="a"
        d="M194.971,226.357l-2.087-2.182a.506.506,0,0,0-.738,0,.564.564,0,0,0,0,.771l2.087,2.182a.507.507,0,0,0,.738,0A.564.564,0,0,0,194.971,226.357Z"
        transform="translate(-191.994 -224.016)"
      />
    </g>
    <g transform="translate(0 4.364)">
      <path
        class="a"
        d="M23.386,103.6l-3.762-5.9A3.607,3.607,0,0,0,16.586,96h-2.5a.546.546,0,0,0,0,1.091h2.5a2.576,2.576,0,0,1,2.17,1.214l3.762,5.9a2.806,2.806,0,0,1,.438,1.513v7.191a1.6,1.6,0,0,1-1.565,1.636H2.609a1.6,1.6,0,0,1-1.565-1.636V105.8a2.821,2.821,0,0,1,.52-1.635l3.375-5.354a3.6,3.6,0,0,1,3.05-1.718H9.913a.546.546,0,0,0,0-1.091H7.988a4.634,4.634,0,0,0-3.921,2.209l-3.36,5.332A3.911,3.911,0,0,0,0,105.8v7.111a2.674,2.674,0,0,0,2.609,2.727H21.391A2.674,2.674,0,0,0,24,112.909v-7.191A3.926,3.926,0,0,0,23.386,103.6Z"
        transform="translate(0 -96)"
      />
    </g>
    <g transform="translate(0.522 13.091)">
      <path
        class="a"
        d="M33.1,266.667H30.492a2.674,2.674,0,0,0-2.609,2.727,1.6,1.6,0,0,1-1.565,1.636H17.97a1.6,1.6,0,0,1-1.565-1.636,2.674,2.674,0,0,0-2.609-2.727H11.188a.546.546,0,0,0,0,1.091H13.8a1.6,1.6,0,0,1,1.565,1.636,2.674,2.674,0,0,0,2.609,2.727h8.348a2.674,2.674,0,0,0,2.609-2.727,1.6,1.6,0,0,1,1.565-1.636H33.1a.546.546,0,0,0,0-1.091Z"
        transform="translate(-10.666 -266.667)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Inbox',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
