import { render, staticRenderFns } from "./yes-or-no.vue?vue&type=template&id=364eb053&scoped=true&"
import script from "./yes-or-no.vue?vue&type=script&lang=js&"
export * from "./yes-or-no.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "364eb053",
  null
  
)

export default component.exports