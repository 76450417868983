<template>
  <v-dialog v-model="dialog" max-width="800" @click:outside="closeDialog">
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        color="info"
        class="justify-start"
        block
        small
        tile
        text
        v-on="on"
      >
        <v-icon left small>$warranty</v-icon>
        <span class="ml-2">
          {{ warranty ? 'Edit warranty' : 'Add warranty' }}
        </span>
      </v-btn>
    </template>
    <v-card tile>
      <v-card-title class="py-6">
        <div class="p-relative flex text-center">
          <h2 class="text-h6">Warranty</h2>
          <v-icon class="close-button" @click="closeDialog">close</v-icon>
        </div>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-menu
          v-model="dateMenu"
          offset-y
          transition="scale-transition"
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-bind="attrs"
              label="End date"
              :value="parseDate(expiredAtStamp)"
              prepend-inner-icon="$calendar_fill"
              readonly
              outlined
              dense
              v-on="on"
            />
          </template>
          <v-date-picker v-model="expiredAtStamp" />
        </v-menu>
        <v-textarea
          v-model="description"
          label="Warranty Description"
          outlined
        />
        <v-row>
          <gallery
            cols="3"
            :folder="`warranties/${listingNicknameById(
              listingTask.listing_id
            )}`"
            :thumbnails="true"
            :uploadable="true"
            :images="files"
            :on-change="imgs => (files = imgs)"
          />
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="py-4">
        <v-spacer />
        <v-btn
          color="secondary"
          class="text-capitalize"
          text
          @click="closeDialog"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          class="text-capitalize"
          elevation="0"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import Gallery from 'components/common/gallery.vue'

export default {
  name: 'WarrantyModal',
  components: { Gallery },
  mixins: [CommonFunctions],
  props: ['listingTask'],
  data() {
    return {
      dialog: false,
      dateMenu: false,
      expiredAtStamp: this.parseDate(new Date().toISOString()),
      description: '',
      files: [],
    }
  },
  computed: {
    warranty() {
      return this.listingTask.listing_task_warranty
    },
  },
  methods: {
    ...mapActions(['updateListingTask']),
    ...mapActions('warranty', ['createWarranty', 'updateWarranty']),
    save() {
      const payload = {
        listing_task_id: this.listingTask.id,
        listing_task_contractor_id: this.listingTask.assigned_contractor_id,
        expired_at: new Date(this.expiredAtStamp).toISOString(),
        description: this.description,
      }
      if (this.warranty) {
        payload.warranty_id = this.warranty.id
        this.updateWarranty(payload)
      } else {
        this.createWarranty(payload)
      }
      this.updateImages(this.files)
      this.closeDialog()
    },
    updateImages(files) {
      this.updateListingTask({
        id: this.listingTask.id,
        receipt_images: this.listingTask.receipt_images.concat(files),
      })
    },
    closeDialog() {
      this.dialog = false
    },
  },
  mounted() {
    if (this.warranty) {
      this.expiredAtStamp =
        this.parseDate(this.warranty.expired_at) ||
        this.parseDate(new Date().toISOString())
      this.description = this.warranty.description || ''
    }
  },
}
</script>
