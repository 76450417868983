export default {
  computed: {
    totalMaterialsCost() {
      if (this.estimate && this.estimate.groups) {
        return this.estimate.groups.reduce(
          (acc, group) => acc + this.calcGroupMaterialsCost(group),
          0
        )
      }
      return 0
    },
    totalLaborCost() {
      if (this.estimate && this.estimate.groups) {
        return this.estimate.groups.reduce(
          (acc, group) => acc + this.calcGroupLaborCost(group),
          0
        )
      }
      return 0
    },
    totalPrice() {
      if (this.estimate && this.estimate.groups) {
        return this.estimate.groups.reduce(
          (acc, group) => acc + this.calcGroupTotalCost(group),
          0
        )
      }
      return 0
    },
    estimateOwnerPdfUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/api/estimates/${this.estimate.id}/owner-pdf`
    },
    estimateSynPdfUrl() {
      return `${process.env.VUE_APP_API_ENDPOINT}/api/estimates/${this.estimate.id}/syn-pdf`
    },
  },
  methods: {
    itemMaterialsCost(item) {
      const measurement = Number(item.measurement || 0)
      return (
        Math.round(item.materials_cost_per_unit * 1.07 * measurement * 100) /
        100
      )
    },
    itemLaborCost(item) {
      const measurement = Number(item.measurement || 0)
      return Math.round(item.labor_cost_per_unit * measurement * 100) / 100
    },
    itemTotalCost(item) {
      return (
        Math.round(
          (this.itemLaborCost(item) + this.itemMaterialsCost(item)) * 100
        ) / 100
      )
    },
    calcGroupMaterialsCost(group) {
      return group.checkList
        .filter(item => item.checked)
        .reduce((acc, item) => acc + this.itemMaterialsCost(item), 0)
    },
    calcGroupLaborCost(group) {
      return group.checkList
        .filter(item => item.checked)
        .reduce((acc, item) => acc + this.itemLaborCost(item), 0)
    },
    calcGroupTotalCost(group) {
      return group.checkList
        .filter(item => item.checked)
        .reduce((acc, item) => acc + this.itemTotalCost(item), 0)
    },
    calcGroupTasksCount(group) {
      return group.checkList.filter(item => item.checked).length
    },
  },
}
