<template>
  <v-row class="fill-height">
    <v-tabs
      v-model="paramsTab2"
      icons-and-text
      class="secondary lighten-undefined"
    >
      <v-tab href="#purchases">
        Purchases
        <v-icon>event</v-icon>
      </v-tab>
      <v-tab href="#management">
        Management
        <v-icon>event</v-icon>
      </v-tab>

      <v-tabs-items v-model="paramsTab2" touchless>
        <v-tab-item value="purchases">
          <v-container>
            <purchase-table />
          </v-container>
        </v-tab-item>
        <v-tab-item value="management">
          <v-container>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="searchTerm"
                  outlined
                  prepend-inner-icon="search"
                  label="Search"
                />
              </v-col>
            </v-row>
            <v-row class="justify-start">
              <v-col
                v-for="item in items"
                :key="item.id"
                cols="12"
                sm="auto"
                lg="4"
                xl="3"
              >
                <StoreItemThumbnail :item="item" />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-row>
</template>

<script>
import StoreItemThumbnail from './store-item-thumbnail'
import PurchaseTable from 'components/store/purchase-table'

export default {
  name: 'StoreView',
  components: {
    PurchaseTable,
    StoreItemThumbnail,
  },
  data() {
    return {
      searchTerm: '',
      paramsTab2: null,
    }
  },
  computed: {
    items() {
      const items = this.$store.getters.storeItems
      if (this.searchTerm) {
        return items.filter(i => i.name.includes(this.searchTerm))
      }
      return items
    },
  },
  async mounted() {
    await this.$store.dispatch('getStoreTopics')
    await this.$store.dispatch('getStoreSubTopics')
    this.$store.dispatch('getStoreItems')
  },
}
</script>

<style scoped></style>
