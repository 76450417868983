<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.34375 5.74219H5.85938V6.91406H2.34375V5.74219Z"
      fill="#25282F"
    />
    <path
      d="M2.34375 8.08594H5.85938V9.25781H2.34375V8.08594Z"
      fill="#25282F"
    />
    <path
      d="M2.34375 10.4297H5.85938V11.6016H2.34375V10.4297Z"
      fill="#25282F"
    />
    <path
      d="M13.2422 4.45312H8.55469C7.58543 4.45312 6.79688 5.24168 6.79688 6.21094V13.2422C6.79688 14.2114 7.58543 15 8.55469 15H13.2422C14.2114 15 15 14.2114 15 13.2422V6.21094C15 5.24168 14.2114 4.45312 13.2422 4.45312ZM13.8281 13.2422C13.8281 13.5653 13.5653 13.8281 13.2422 13.8281H8.55469C8.2316 13.8281 7.96875 13.5653 7.96875 13.2422V6.21094C7.96875 5.88785 8.2316 5.625 8.55469 5.625H13.2422C13.5653 5.625 13.8281 5.88785 13.8281 6.21094V13.2422Z"
      fill="#25282F"
    />
    <path
      d="M9.14062 6.79688H12.6562V7.96875H9.14062V6.79688Z"
      fill="#25282F"
    />
    <path
      d="M9.72656 10.3125C10.0502 10.3125 10.3125 10.0502 10.3125 9.72656C10.3125 9.40296 10.0502 9.14062 9.72656 9.14062C9.40296 9.14062 9.14062 9.40296 9.14062 9.72656C9.14062 10.0502 9.40296 10.3125 9.72656 10.3125Z"
      fill="#25282F"
    />
    <path
      d="M12.0703 10.3125C12.3939 10.3125 12.6562 10.0502 12.6562 9.72656C12.6562 9.40296 12.3939 9.14062 12.0703 9.14062C11.7467 9.14062 11.4844 9.40296 11.4844 9.72656C11.4844 10.0502 11.7467 10.3125 12.0703 10.3125Z"
      fill="#25282F"
    />
    <path
      d="M12.0703 12.6562C12.3939 12.6562 12.6562 12.3939 12.6562 12.0703C12.6562 11.7467 12.3939 11.4844 12.0703 11.4844C11.7467 11.4844 11.4844 11.7467 11.4844 12.0703C11.4844 12.3939 11.7467 12.6562 12.0703 12.6562Z"
      fill="#25282F"
    />
    <path
      d="M9.72656 12.6562C10.0502 12.6562 10.3125 12.3939 10.3125 12.0703C10.3125 11.7467 10.0502 11.4844 9.72656 11.4844C9.40296 11.4844 9.14062 11.7467 9.14062 12.0703C9.14062 12.3939 9.40296 12.6562 9.72656 12.6562Z"
      fill="#25282F"
    />
    <path
      d="M1.17188 13.2422V3.51562H3.51562V1.17188H9.72656C10.0496 1.17188 10.3125 1.43473 10.3125 1.75781V3.51562H11.4844V1.75781C11.4844 0.788555 10.6958 0 9.72656 0H2.92969C1.31423 0 0 1.31426 0 2.92969V13.2422C0 14.2114 0.788555 15 1.75781 15H5.85938V13.8281H1.75781C1.43473 13.8281 1.17188 13.5653 1.17188 13.2422ZM2.34375 1.27266V2.34375H1.27266C1.44949 1.84518 1.84518 1.44949 2.34375 1.27266Z"
      fill="#25282F"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchTask',
}
</script>

<style scoped></style>
