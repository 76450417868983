<template>
  <v-card>
    <v-card-title class="text-h5 align-center bb-1">
      Continuation Hierarchy
      <v-spacer />
      <v-btn icon @click="onClose">
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />

    <v-card-text class="pa-0 d-flex flex-column">
      <chart-flow :nodes="hierarchy" padding-x="20px">
        <template #node="{ data }">
          <div
            :class="[
              'chart-flow-node-wrapper pointer',
              {
                'chart-flow-node-wrapper-current':
                  data.extendedProps.task.id === listingTaskId,
              },
            ]"
            @click="openListingTaskInNewTab(data.extendedProps.task.id)"
          >
            <task-calendar-cell
              mode="menu"
              :event="data"
              :users-map="usersMap"
            />
          </div>
        </template>
      </chart-flow>
    </v-card-text>

    <v-divider />
    <v-card-actions class="justify-space-around">
      <v-btn depressed @click="onClose">Close</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CalendarMixin from 'components/mixins/calendar-mixin'
import ChartFlow from 'components/chart-flow/chart-flow'
import TaskCalendarCell from 'components/calendar/task-calendar-cell'

export default {
  name: 'LtContinuationHierarchyModal',
  mixins: [CalendarMixin],
  components: { TaskCalendarCell, ChartFlow },
  props: ['listingTaskId'],
  data() {
    return {
      hierarchy: [],
    }
  },
  beforeMount() {
    this.loadHierarchy()
  },
  computed: {
    ...mapGetters('users', ['usersMap']),
  },
  methods: {
    ...mapActions(['getContinuationHierarchy']),
    onClose() {
      this.$store.commit('hideModal')
    },
    async loadHierarchy() {
      const res = await this.getContinuationHierarchy(this.listingTaskId)
      this.hierarchy = res.data.map(item => ({
        id: item.id,
        parentId: item.parent_id,
        data: this.mapServiceCall(item.task),
      }))
    },
  },
}
</script>

<style scoped>
.chart-flow-node-wrapper {
  background-color: white;
  margin-top: 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 3px;
}

.chart-flow-node-wrapper-current {
  border: 3px solid var(--v-primary-base);
}
</style>
