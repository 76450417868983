<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.3"
    height="24.3"
    viewBox="0 0 24.3 24.3"
  >
    <g transform="translate(0.15 0.15)">
      <path
        d="M22.105,15.8a13.7,13.7,0,0,1-4.306-.686,1.845,1.845,0,0,0-1.873.4L13.2,17.571A15.219,15.219,0,0,1,6.428,10.8l2-2.66A1.912,1.912,0,0,0,8.9,6.206a13.737,13.737,0,0,1-.688-4.311A1.9,1.9,0,0,0,6.316,0H1.895A1.9,1.9,0,0,0,0,1.895,22.131,22.131,0,0,0,22.105,24,1.9,1.9,0,0,0,24,22.105V17.7A1.9,1.9,0,0,0,22.105,15.8Zm.632,6.3a.633.633,0,0,1-.632.632A20.866,20.866,0,0,1,1.263,1.895a.633.633,0,0,1,.632-.632H6.316a.633.633,0,0,1,.632.632,15,15,0,0,0,.748,4.7.7.7,0,0,1-.217.72l-2.3,3.044a.631.631,0,0,0-.058.667,16.573,16.573,0,0,0,7.854,7.854.629.629,0,0,0,.668-.058l3.11-2.357a.634.634,0,0,1,.643-.15,14.986,14.986,0,0,0,4.709.752.633.633,0,0,1,.632.632v4.408Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PhoneCall',
  props: ['color'],
}
</script>

<style scoped>
path {
  fill: currentColor;
}
</style>
