<template>
  <v-container>
    <v-card outlined class="mt-4">
      <v-card-title class="d-flex justify-space-between align-center">
        <span>AirDNA Market Metrics</span>
        <div>
          <v-btn
            small
            color="primary"
            class="me-2"
            :loading="loading"
            @click="fetchMetrics(true)"
          >
            <v-icon small left>mdi-sync</v-icon>
            Sync AirDNA Data
          </v-btn>
          <v-chip small color="primary" class="white--text">
            {{ metricsData?.details?.address }}
          </v-chip>
        </div>
      </v-card-title>

      <!-- Loading state -->
      <v-skeleton-loader
        v-if="loading"
        type="article"
        class="pa-4"
      ></v-skeleton-loader>

      <!-- Error state -->
      <v-alert v-else-if="error" type="error" class="ma-4">
        Failed to load AirDNA metrics: {{ error }}
      </v-alert>

      <!-- Content -->
      <v-card-text v-else-if="metricsData">
        <v-row>
          <v-col cols="12" sm="6">
            <div class="metrics-summary">
              <div class="text-h6 mb-2">Property Details</div>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Accommodates</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ metricsData.details?.accommodates }} guests
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bedrooms</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ metricsData.details?.bedrooms }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bathrooms</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ metricsData.details?.bathrooms }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div class="metrics-summary">
              <div class="text-h6 mb-2">Future Projections</div>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Average Daily Rate</v-list-item-title>
                    <v-list-item-subtitle>
                      {{
                        formatCurrency(metricsData.stats?.future?.summary?.adr)
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Occupancy Rate</v-list-item-title>
                    <v-list-item-subtitle>
                      {{
                        formatPercentage(
                          metricsData.stats?.future?.summary?.occupancy
                        )
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Projected Annual Revenue</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{
                        formatCurrency(
                          metricsData.stats?.future?.summary?.revenue
                        )
                      }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon x-small v-bind="attrs" v-on="on">
                            mdi-information
                          </v-icon>
                        </template>
                        Range:
                        {{
                          formatCurrency(
                            metricsData.stats?.future?.summary?.revenue_lower
                          )
                        }}
                        -
                        {{
                          formatCurrency(
                            metricsData.stats?.future?.summary?.revenue_upper
                          )
                        }}
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col cols="12">
            <div class="text-h6 mb-2">Revenue Trends</div>
            <v-chart :option="chartOption" autoresize style="height: 400px" />
          </v-col>
        </v-row>

        <v-row v-if="comparableListings.length" class="mt-4">
          <v-col cols="12">
            <div class="text-h6 mb-2">Comparable Properties</div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Distance</th>
                    <th>Platform</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="comp in comparableListings"
                    :key="comp.property_id"
                  >
                    <td>{{ (comp.distance_meters / 1000).toFixed(1) }} km</td>
                    <td>
                      {{ getListingPlatform(comp.property_id) }}
                    </td>
                    <td>
                      <v-btn
                        x-small
                        text
                        color="primary"
                        @click="showCompDetails(comp)"
                      >
                        View Details
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Add this dialog component after the v-card closing tag -->
    <v-dialog v-model="showDialog" max-width="600px">
      <v-card v-if="selectedComp">
        <v-card-title class="d-flex justify-space-between">
          <span>Comparable Property Details</span>
          <v-btn icon @click="showDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Property</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ selectedComp.details.title }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Platform</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ getListingPlatform(selectedComp.property_id) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Distance</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ (selectedComp.distance_meters / 1000).toFixed(1) }} km
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

            <v-col cols="12" sm="6">
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Accommodates</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ selectedComp.details.accommodates || 'N/A' }} guests
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bedrooms</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ selectedComp.details.bedrooms || 'N/A' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Bathrooms</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ selectedComp.details.bathrooms || 'N/A' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import VChart from 'vue-echarts'
import { use } from 'echarts/core'
import { LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
])

export default {
  name: 'AirDnaMetricsChart',
  components: {
    VChart,
  },

  props: {
    listingPricingId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      metricsData: null,
      loading: true,
      error: null,
      showDialog: false,
      selectedComp: null,
    }
  },

  async created() {
    await this.fetchMetrics()
  },

  computed: {
    comparableListings() {
      return this.metricsData?.comps?.filter(comp => comp.property_id) || []
    },

    chartOption() {
      if (!this.metricsData?.stats) return {}

      const historical = this.metricsData.stats.historical.metrics
      const future = this.metricsData.stats.future.metrics
      const currentDate = new Date().toISOString().slice(0, 7) // YYYY-MM format

      // Split historical data into past and current
      const historicalData = historical.map(h => ({
        date: h.date,
        value: h.revenue_valuation,
        isFuture: h.date >= currentDate,
      }))

      // Future projections
      const futureData = future.map(f => ({
        date: f.date,
        value: f.revenue,
        lower: f.revenue_lower,
        upper: f.revenue_upper,
      }))

      // Combine all dates for x-axis
      const dates = [
        ...new Set([
          ...historicalData.map(h => h.date),
          ...futureData.map(f => f.date),
        ]),
      ].sort()

      return {
        tooltip: {
          trigger: 'axis',
          formatter: params => {
            const date = params[0].axisValue
            const values = params
              .filter(p => p.value !== '-')
              .map(p => `${p.seriesName}: ${this.formatCurrency(p.value)}`)
            return `${this.formatDate(date)}<br/>${values.join('<br/>')}`
          },
        },
        legend: {
          data: ['Historical', 'Projected', 'Projection Range'],
        },
        xAxis: {
          type: 'category',
          data: dates,
          axisLabel: {
            formatter: value => {
              return this.formatDate(value, true)
            },
            interval: 2, // Show every 3rd label to prevent overcrowding
          },
          axisPointer: {
            label: {
              formatter: params => {
                return this.formatDate(params.value)
              },
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: value => this.formatCurrency(value, true),
          },
        },
        series: [
          {
            name: 'Historical',
            type: 'line',
            data: dates.map(date => {
              const point = historicalData.find(h => h.date === date)
              return point && date < currentDate ? point.value : '-'
            }),
            smooth: true,
            symbol: 'circle',
            symbolSize: 6,
            itemStyle: {
              color: '#2196F3',
            },
          },
          {
            name: 'Projected',
            type: 'line',
            data: dates.map(date => {
              const point = futureData.find(f => f.date === date)
              return point && date >= currentDate ? point.value : '-'
            }),
            smooth: true,
            symbol: 'circle',
            symbolSize: 6,
            itemStyle: {
              color: '#4CAF50',
            },
            markArea: {
              itemStyle: {
                color: 'rgba(76, 175, 80, 0.05)',
              },
              data: [
                [
                  {
                    name: 'Projected',
                    xAxis: futureData[0].date,
                  },
                  {
                    xAxis: futureData[futureData.length - 1].date,
                  },
                ],
              ],
            },
          },
          {
            name: 'Projection Range',
            type: 'line',
            data: dates.map(date => {
              const point = futureData.find(f => f.date === date)
              return point && date >= currentDate ? point.lower : '-'
            }),
            lineStyle: { opacity: 0 },
            stack: 'range',
            symbol: 'none',
            areaStyle: {
              color: 'rgba(76, 175, 80, 0.1)',
            },
          },
          {
            name: 'Projection Range',
            type: 'line',
            data: dates.map(date => {
              const point = futureData.find(f => f.date === date)
              return point && date >= currentDate
                ? point.upper - point.lower
                : '-'
            }),
            lineStyle: { opacity: 0 },
            stack: 'range',
            symbol: 'none',
            areaStyle: {
              color: 'rgba(76, 175, 80, 0.1)',
            },
          },
        ],
      }
    },
  },

  methods: {
    async fetchMetrics(setFactors = false) {
      this.loading = true
      this.error = null

      try {
        const response = await axios.get(
          `/api/airdna/metrics/${this.listingPricingId}?set_factors=${setFactors}`
        )
        this.metricsData = response.data.payload.data.payload
        if (setFactors)
          this.$store.dispatch('getListingPricing', this.listingPricingId)
      } catch (err) {
        this.error = err.response?.data?.message || 'Failed to load metrics'
      } finally {
        this.loading = false
      }
    },

    getListingPlatform(propertyId) {
      const platform = propertyId.split('_')[0]
      return platform.charAt(0).toUpperCase() + platform.slice(1)
    },

    showCompDetails(comp) {
      console.log('selectedComp', comp)
      this.selectedComp = comp
      this.showDialog = true
    },

    formatCurrency(value, compact = false) {
      if (!value) return 'N/A'
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: this.metricsData?.stats?.currency || 'USD',
        notation: compact ? 'compact' : 'standard',
      })
      return formatter.format(value)
    },

    formatPercentage(value) {
      if (!value) return 'N/A'
      return `${(value * 100).toFixed(1)}%`
    },
    formatDate(dateStr, compact = false) {
      const date = new Date(dateStr + '-01')
      return date.toLocaleDateString('en-US', {
        year: compact ? '2-digit' : 'numeric',
        month: compact ? 'short' : 'long',
      })
    },
  },
}
</script>

<style scoped>
.metrics-summary {
  min-height: 200px;
}

:deep(.echarts) {
  border-radius: 4px;
  padding: 16px;
  background-color: #fafafa;
}
</style>
