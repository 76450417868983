<template>
  <v-navigation-drawer
    temporary
    stateless
    :mini-variant="isMobile"
    mini-variant-width="90%"
    right
    app
    width="550"
    :value="value"
    :overlay-opacity="0"
    style="padding-top: 64px"
    @input="drawerOpened"
  >
    <v-sheet
      v-click-outside="onClickOutside"
      height="100%"
      class="pa-3 d-flex flex-column"
    >
      <div class="d-flex grey lighten-4 rounded pa-1">
        <!--        <v-btn-->
        <!--          class="text-capitalize flex-1"-->
        <!--          :ripple="false"-->
        <!--          :color="!isAlerts ? 'white' : ''"-->
        <!--          elevation="0"-->
        <!--          @click="isAlerts = false"-->
        <!--        >-->
        <!--          <span class="text-caption font-weight-medium"-->
        <!--            ><v-icon left>$pulse</v-icon> News Feed-->
        <!--          </span>-->
        <!--        </v-btn>-->
        <v-btn
          class="text-capitalize flex-1"
          :color="isAlerts ? 'white' : ''"
          elevation="0"
          :ripple="false"
          @click="isAlerts = true"
        >
          <span class="text-caption font-weight-medium"
            ><v-icon left>$exclamation_triangle</v-icon> Alerts
          </span>
        </v-btn>
      </div>
      <v-sheet v-if="alerts.length" class="overflow-y-auto mt-3">
        <v-sheet
          v-for="(alert, i) in alerts"
          :key="i"
          height="115"
          class="my-2 rounded-lg"
          outlined
        >
          <div
            class="d-flex flex-nowrap no-gutters overflow-hidden fill-height"
          >
            <div :class="['availability', severityColor(alert.priority)]" />
            <div class="flex-grow-1" style="width: calc(100% - 6px)">
              <div class="d-flex align-center px-5 py-4">
                <v-icon left :color="severityColor(alert.priority)"
                  >$exclamation_triangle</v-icon
                >
                <span
                  class="font-weight-medium black--text"
                  v-html="alert.text"
                ></span>
              </div>
              <div class="px-5">
                <v-btn
                  v-if="!isEmpty(alert.listing_tasks)"
                  x-small
                  elevation="0"
                  color="primary darken1"
                  @click="$emit('update-listing-tasks', alert.listing_tasks)"
                  >Show tasks</v-btn
                >
              </div>
            </div>
          </div>
        </v-sheet>
      </v-sheet>
      <v-sheet v-else class="d-flex flex-column flex justify-center">
        <div class="text-h6 text-center mt-6">
          <div><v-icon>mdi-bell-off</v-icon></div>
          <span>No Alerts</span>
        </div>
      </v-sheet>
    </v-sheet>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NavigationMixin from 'components/mixins/navigation-mixin'
import LtMixin from 'components/mixins/lt-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import get from 'lodash/fp/get'
import Common_functions from 'components/mixins/common_functions'
import { orderBy } from 'lodash'

export default {
  name: 'AlertsDrawer',
  props: ['value'],
  mixins: [
    NavigationMixin,
    LtMixin,
    FormattersMixin,
    ColorsMixin,
    Common_functions,
  ],
  data() {
    return {
      isAlerts: true,
    }
  },
  computed: {
    ...mapState(['listingTaskLoading']),
    ...mapState('listingReport', ['regionReportStats']),
    alerts() {
      let alertsList =
        get('stats.region_stats.alerts', this.regionReportStats) || []
      alertsList = alertsList.map(a => {
        return {
          ...a,
          priorityOrder: this.priorityOrder(a.priority),
        }
      })
      return orderBy(alertsList, ['priorityOrder'], ['asc'])
    },
  },
  methods: {
    ...mapActions(['getListingTaskBg']),
    drawerOpened(isOpened) {
      this.$emit('input', isOpened)
    },
    severityColor(level) {
      if (level === 'High') {
        return 'error'
      }
      if (level === 'Med') {
        return 'tertiary'
      }
      return 'info darken1'
    },
    onClickOutside(event) {
      const isLastOverlay = document.querySelectorAll(
        '.v-overlay.v-overlay--active.theme--dark:not(.v-overlay--absolute)'
      ).length
      if (
        event.target._prevClass === 'v-overlay__scrim' &&
        isLastOverlay === 1
      ) {
        const { alerts: _a, ...query } = this.$route.query
        this.$router.push({ query }).catch(() => {})
      }
    },
  },
}
</script>

<style></style>
