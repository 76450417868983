<template>
  <v-menu
    v-model="dialog"
    offset-y
    nudge-bottom="5"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-badge
        small
        :content="filtersCount"
        color="info"
        overlap
        bordered
        :value="!!filtersCount"
      >
        <v-btn outlined small class="filter-btn" v-on="on">
          <v-icon small class="me-1">mdi-filter-variant</v-icon> Filters
        </v-btn>
      </v-badge>
    </template>
    <v-card outlined class="rounded-lg filter-dialog">
      <v-card-title class="text-h6">Filters</v-card-title>
      <v-card-text>
        <div
          v-for="(filter, key) in filters"
          :key="filter.filter_value"
          class="d-flex scaled-filters gap-3 align-center"
        >
          <v-autocomplete
            v-model="filters[key].type"
            outlined
            dense
            label="Filter"
            hide-details
            :items="filteredFilterTypes(key)"
            item-text="filter_text"
            item-value="filter_value"
            @change="onFilterChange(key)"
          />
          <v-autocomplete
            v-model="filters[key].relation"
            auto-select-first
            style="width: 100px"
            outlined
            dense
            class="non-clickable"
            hide-details
            :items="currentFilter(filters[key].type)?.relations"
          />
          <div>
            <v-autocomplete
              :value="filters[key].options"
              :disabled="!filters[key].type"
              outlined
              multiple
              clearable
              hide-selected
              dense
              hide-details
              :items="currentFilter(filters[key].type)?.options"
              @change="onFilterOptionsChanged(key, $event)"
            />
          </div>
          <v-btn icon @click="removeFilter(key)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>
        <div class="d-flex justify-space-between mt-3">
          <v-btn
            outlined
            small
            class="filter-btn text-capitalize"
            @click="addFilter"
          >
            <v-icon small class="me-1">mdi-plus</v-icon> Add Filter
          </v-btn>
          <v-btn
            small
            color="info"
            depressed
            class="filter-btn text-capitalize me-1"
            @click="applyFilters"
          >
            Apply
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { keyBy } from 'lodash/fp'

const FILTER_TYPES = [
  {
    filter_text: 'Guest Birthday',
    filter_value: 'birthday_month',
    relations: [{ text: 'In', value: 'in' }],
    options: [
      { text: 'January', value: 1 },
      { text: 'February', value: 2 },
      { text: 'March', value: 3 },
      { text: 'April', value: 4 },
      { text: 'May', value: 5 },
      { text: 'June', value: 6 },
      { text: 'July', value: 7 },
      { text: 'August', value: 8 },
      { text: 'September', value: 9 },
      { text: 'October', value: 10 },
      { text: 'November', value: 11 },
      { text: 'December', value: 12 },
    ],
  },
  {
    filter_text: 'Guest Age',
    filter_value: 'guest_age',
    relations: [{ text: 'Between', value: 'between' }],
    options: [
      {
        text: '18-25',
        value: [18, 25],
      },
      {
        text: '26-35',
        value: [26, 35],
      },
      {
        text: '36-45',
        value: [36, 45],
      },
      {
        text: '46-55',
        value: [46, 55],
      },
      {
        text: '56-65',
        value: [56, 65],
      },
      {
        text: '66-74',
        value: [66, 74],
      },
      {
        text: '75+',
        value: [75],
      },
    ],
  },
  {
    filter_text: 'Group Size',
    filter_value: 'group_size',
    relations: [{ text: 'Between', value: 'between' }],
    options: [
      {
        text: '1-2',
        value: [1, 2],
      },
      {
        text: '3-4',
        value: [3, 4],
      },
      {
        text: '5+',
        value: [5],
      },
    ],
  },
  {
    filter_text: 'Season',
    filter_value: 'season',
    relations: [{ text: 'Is', value: 'is' }],
    options: [
      {
        text: 'Winter',
        value: [12, 1, 2],
      },
      {
        text: 'Spring',
        value: [3, 4, 5],
      },
      {
        text: 'Summer',
        value: [6, 7, 8],
      },
      {
        text: 'Fall',
        value: [9, 10, 11],
      },
    ],
  },
  {
    filter_text: 'Review Rating',
    filter_value: 'review_rating',
    relations: [{ text: 'Is', value: 'is' }],
    options: [
      {
        text: '1',
        value: [1],
      },
      {
        text: '2-3',
        value: [2, 3],
      },
      {
        text: '4',
        value: [4],
      },
      {
        text: '5',
        value: [5],
      },
    ],
  },
]

export default {
  name: 'GuestBookFilters',
  data() {
    return {
      dialog: false,
      FILTER_TYPES,
      filters: [{}],
      search: '',
    }
  },
  computed: {
    filtersCount() {
      return this.filters.map(f => f.options).filter(Boolean).length
    },
  },
  methods: {
    filteredFilterTypes(key) {
      const values = this.filters.map(f => f.type)
      this.filters[key].type
      return this.FILTER_TYPES.filter(
        f =>
          this.filters[key].type == f.filter_value ||
          !values.includes(f.filter_value)
      )
    },
    currentFilter(value) {
      return this.FILTER_TYPES.find(f => f.filter_value == value)
    },
    filterOptions(value) {
      if (!value) return {}
      this.currentFilter(value).options || []
    },
    filterRelations(value) {
      return this.currentFilter(value).options || []
    },
    addFilter() {
      this.filters.push({})
    },
    onFilterChange(key) {
      const currentFilter = this.currentFilter(this.filters[key].type)
      this.filters[key].options = null
      this.filters[key].relation = currentFilter?.relations[0]?.value
    },
    removeFilter(key) {
      this.filters.splice(key, 1)
    },
    applyFilters() {
      this.$emit('apply-filters', keyBy('type', this.filters))
      this.dialog = false
    },
    onFilterOptionsChanged(key, val) {
      this.$set(this.filters, key, { ...this.filters[key], options: val })
    },
  },
}
</script>

<style scoped>
.filter-btn {
  border-color: var(--v-light400-base);
}
.filter-dialog {
  box-shadow: 0px 10px 10px #0000000d;
  min-width: 500px;
}
.scaled-filters {
  transform: scale(0.8);
  transform-origin: top left;
  margin-right: -25%;
}
.non-clickable {
  pointer-events: none;
}
.v-menu__content {
  box-shadow: 0px 4px 10px 0px #0b174317;
}
:deep() .v-select__selections > input {
  width: 80px;
}
</style>
