<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_786_1743)">
      <path
        d="M22.8 4.79995H17.6V3.19995C17.5986 2.09585 16.7041 1.20132 15.6 1.19995H8.4C7.2959 1.20132 6.40137 2.09585 6.4 3.19995V4.79995H1.2C0.537305 4.79995 0 5.33726 0 5.99995V14.9039C0.00253906 15.4113 0.322266 15.8626 0.8 16.0332V21.6C0.8 22.2626 1.3373 22.8 2 22.8H22C22.6627 22.8 23.2 22.2626 23.2 21.6V16.0335C23.6777 15.8628 23.9977 15.4113 24 14.9039V5.99995C24 5.33726 23.4627 4.79995 22.8 4.79995ZM7.2 3.19995C7.2 2.53726 7.7373 1.99995 8.4 1.99995H15.6C16.2627 1.99995 16.8 2.53726 16.8 3.19995V4.79995H16V3.19995C16 2.97905 15.8209 2.79995 15.6 2.79995H8.4C8.1791 2.79995 8 2.97905 8 3.19995V4.79995H7.2V3.19995ZM15.2 4.79995H8.8V3.59995H15.2V4.79995ZM22.4 21.6C22.4 21.8208 22.2209 22 22 22H2C1.7791 22 1.6 21.8208 1.6 21.6V16.1707L10.4 17.3843V18.4C10.4 19.0626 10.9373 19.6 11.6 19.6H12.4C13.0627 19.6 13.6 19.0626 13.6 18.4V17.3843L22.4 16.1707V21.6ZM12.8 18.4C12.8 18.6208 12.6209 18.8 12.4 18.8H11.6C11.3791 18.8 11.2 18.6208 11.2 18.4V16C11.2 15.7791 11.3791 15.6 11.6 15.6H12.4C12.6209 15.6 12.8 15.7791 12.8 16V18.4ZM23.2 14.9039C23.2002 15.1033 23.0535 15.2724 22.8561 15.3003L22.7451 15.3156L13.6 16.5767V16C13.6 15.3373 13.0627 14.8 12.4 14.8H11.6C10.9373 14.8 10.4 15.3373 10.4 16V16.5767L1.14434 15.3003C0.946875 15.2726 0.799805 15.1035 0.8 14.9039V5.99995C0.8 5.77905 0.979102 5.59995 1.2 5.59995H22.8C23.0209 5.59995 23.2 5.77905 23.2 5.99995V14.9039Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_786_1743">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Portfolio',
}
</script>

<style scoped></style>
