<template>
  <form-dialog title="Inquiry Expense" @submit="submit">
    <template #activator="{ on }">
      <v-btn small outlined color="warning" :disabled="disabled" v-on="on">
        Inquiry
      </v-btn>
    </template>
    <v-textarea
      v-model="inquiry.message"
      dense
      outlined
      label="Description"
      :rules="[required]"
    />
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
export default {
  name: 'InquiryExpenseModal',
  components: { FormDialog },
  mixins: [FormRules],
  props: ['disabled', 'expenseId'],
  data() {
    return {
      inquiry: {},
    }
  },
  methods: {
    async submit() {
      await this.$store.dispatch('expenses/createLtInquiry', {
        id: this.expenseId,
        ...this.inquiry,
      })
      this.$emit('on-inquiry')
    },
  },
}
</script>

<style scoped></style>
