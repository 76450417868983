<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="(task, index) in sortedSubtasks" :key="index">
      <v-expansion-panel-header
        ><span
          ><strong>{{ task.list_subject }}</strong>
          <span v-if="task.description"> : {{ task.description }}</span>
          <span v-if="task.inspection_mode" class="mx-2">
            <v-icon v-if="task.is_valid" color="primary" @click.stop
              >fas fa-check</v-icon
            >
            <v-icon v-else color="warning" @click.stop>fas fa-times</v-icon>
          </span>
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-if="task.rating" class="d-flex align-center">
          Rate: <v-rating small :value="task.rating" />
        </div>
        <div v-if="task.started_at" class="mb-2">
          <span> Start date: </span>
          <span class="font-weight-bold">
            {{ task.started_at }}
          </span>
        </div>
        <div v-if="task.hours && managementRoles" class="mb-2">
          <span> Hours: </span>
          <span class="font-weight-bold"> {{ task.hours }} </span>
        </div>
        <div v-if="task.price" class="mb-2">
          <span> Price: </span>
          <span class="font-weight-bold">
            {{ dollarFormatter(task.price) }}
          </span>
        </div>
        <div v-if="task.log && Object.keys(task.log).length" class="mb-2">
          <p class="mb-2">Messages:</p>
          <v-list color="grey lighten-4" rounded>
            <v-list-item v-for="(item, key) in task.log" :key="key">
              <v-list-item-content class="py-0">
                <v-list-item-subtitle>
                  {{ item.user }},
                  <span class="text-caption">
                    {{ parseDate(key) }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.msg }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <v-row class="mb-2">
          <v-col cols="12" sm="6">
            <div v-if="!isEmpty(task.before_images)">
              <span> Media before: </span>
              <gallery
                :thumbnails="true"
                :images="task.before_images"
                :readonly="true"
              />
            </div>
          </v-col>
          <v-divider vertical />
          <v-col cols="12" sm="6">
            <div v-if="!isEmpty(task.after_images)">
              <span> Media after: </span>
              <gallery
                :thumbnails="true"
                :images="task.after_images"
                :readonly="true"
              />
            </div>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Gallery from 'components/common/gallery'
import FormattersMixin from 'components/mixins/formatters-mixin'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
export default {
  components: {
    Gallery,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['list'],
  computed: {
    sortedSubtasks() {
      return orderBy(this.list, ['list_subject'], ['description'])
    },
  },
}
</script>
