<template>
  <v-card class="pa-4" style="border-radius: 10px">
    <div class="d-flex pa-4">
      <div
        class="flex text-center justify-center font-weight-medium text-subtitle-1"
      >
        {{ title }}
      </div>
    </div>
    <v-form ref="form">
      <textarea
        v-model="policy.topic"
        placeholder="Topic"
        rows="1"
        style="
          width: 100%;
          resize: none;
          padding: 15px;
          border-radius: 10px;
          border: solid #00bcd4;
          margin-bottom: 10px;
        "
      ></textarea>
      <textarea
        v-if="!(edit && group)"
        v-model="policy.subject"
        placeholder="Subject"
        rows="1"
        style="
          width: 100%;
          resize: none;
          padding: 15px;
          border-radius: 10px;
          border: solid #00bcd4;
          margin-bottom: 10px;
        "
      ></textarea>
      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="submit">Submit</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AddPolicyModal',
  props: [
    'policy',
    'afterSubmitEvent',
    'beforeSubmitEvent',
    'edit',
    'group',
    'current_topic',
  ],
  computed: {
    title() {
      if (this.edit && this.group) {
        return 'Edit Policy Group'
      }

      return this.policy ? 'Add Policy' : 'Add Policy Group'
    },
  },
  methods: {
    async submit() {
      if (this.beforeSubmitEvent) {
        this.beforeSubmitEvent()
      }
      this.$store.commit('hideModal')

      if (this.edit && this.group) {
        await axios.post(`/api/policies/update-policy-group`, {
          current_topic: this.current_topic,
          new_topic: this.policy.topic,
          department: this.policy.department,
        })
      } else if (this.policy.id) {
        await axios.put(`/api/policies/${this.policy.id}`, {
          policy: this.policy,
        })
      } else {
        await axios.post(`/api/policies`, {
          policy: this.policy,
        })
      }

      if (this.afterSubmitEvent) {
        this.afterSubmitEvent()
      }
    },
    close() {
      this.$store.commit('hideModal')
      this.label = null
    },
  },
}
</script>
