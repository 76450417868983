<template>
  <svg
    id="_003-investment"
    data-name="003-investment"
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <path
      id="Path_6665"
      data-name="Path 6665"
      d="M25.592,4.15l.8,1.6,1.468-.734L25.851,1,21.836,3.008l.734,1.468,1.436-.718C22.031,8.547,15.445,20.688,0,20.688v1.641c16.425,0,23.537-13.254,25.592-18.178Z"
      transform="translate(0 -0.945)"
      fill="#3d933a"
    />
    <path
      id="Path_6666"
      data-name="Path 6666"
      d="M91,451h6.563v3.336H91Z"
      transform="translate(-86.023 -426.336)"
      fill="#3d933a"
    />
    <path
      id="Path_6667"
      data-name="Path 6667"
      d="M241,451h6.563v3.336H241Z"
      transform="translate(-227.82 -426.336)"
      fill="#3d933a"
    />
    <path
      id="Path_6668"
      data-name="Path 6668"
      d="M391,451h6.617v3.336H391Z"
      transform="translate(-369.617 -426.336)"
      fill="#3d933a"
    />
    <path
      id="Path_6669"
      data-name="Path 6669"
      d="M241,361h6.563v3.281H241Z"
      transform="translate(-227.82 -341.258)"
      fill="#3d933a"
    />
    <path
      id="Path_6670"
      data-name="Path 6670"
      d="M391,361h6.617v3.281H391Z"
      transform="translate(-369.617 -341.258)"
      fill="#3d933a"
    />
    <path
      id="Path_6671"
      data-name="Path 6671"
      d="M391,271h6.617v3.281H391Z"
      transform="translate(-369.617 -256.18)"
      fill="#3d933a"
    />
    <path
      id="Path_6672"
      data-name="Path 6672"
      d="M5.8,13.18V10.568a2.457,2.457,0,0,1-1.641-2.31H5.8a.82.82,0,1,0,.82-.82A2.455,2.455,0,0,1,5.8,2.667V0A6.727,6.727,0,0,0,0,6.617,6.672,6.672,0,0,0,5.8,13.18Z"
      fill="#3d933a"
    />
    <path
      id="Path_6673"
      data-name="Path 6673"
      d="M107.641,10.568V13.18a6.652,6.652,0,0,0,0-13.18V2.667a2.457,2.457,0,0,1,1.641,2.31h-1.641a.82.82,0,1,0-.82.82,2.455,2.455,0,0,1,.82,4.771Z"
      transform="translate(-100.203)"
      fill="#3d933a"
    />
  </svg>
</template>

<script>
export default {
  name: 'Investment',
}
</script>

<style scoped>
.a {
  fill: #dfe1e6;
}

.b {
  fill: currentColor;
}
</style>
