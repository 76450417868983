<template>
  <form-dialog title="Card Info">
    <template #activator="{ on }">
      <v-btn color="blue" small dark depressed v-on="on">Show card info</v-btn>
    </template>
    <div class="d-flex">
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="card.number"
            outlined
            dense
            label="Card Number"
            :disabled="true"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="card.expirationDate"
            outlined
            dense
            label="Expiration Date"
            :disabled="true"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="card.cvc"
            outlined
            dense
            label="CVC"
            :disabled="true"
          />
        </v-col>
      </v-row>
    </div>
    <template #actions>
      <v-btn outlined class="cyan-icon" text @click="retrieveToken">
        Retrieve Card Info
      </v-btn>
    </template>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import { BasisTheory } from '@basis-theory/basis-theory-js'
import axios from 'axios'

export default {
  components: { FormDialog },
  mixins: [FormRulesMixin],
  props: ['reservation'],
  data() {
    return {
      type: null,
      amount: null,
      description: '',
      card: {
        expirationDate: null,
        cvc: null,
        number: null,
      },
      keys: {},
    }
  },
  async mounted() {
    const { data } = await axios.get('/api/settings/bt_keys')
    this.keys = data
  },
  methods: {
    async retrieveToken() {
      // initiate public app

      this.btPublic = await new BasisTheory().init(this.keys.creator_key, {
        elements: true,
      })
      // initiate privat app
      this.btPrivat = await new BasisTheory().init(this.keys.reader_key)

      try {
        //create session

        const sessionBt = await this.btPublic.sessions.create()

        // authorize session

        await axios.post(`/api/tokenizer/session/authorize`, {
          session_nonce: sessionBt.nonce,
        })

        // retrieve token

        const token = await this.btPrivat.tokens.retrieve(
          this.reservation.tokenizer_id,
          { apiKey: sessionBt.sessionKey }
        )

        console.log(token)
        this.card.number = token.data.card
        this.card.cvc = token.data.pincode
        this.card.expirationDate = token.data.expiration
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
