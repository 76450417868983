<template>
  <div>
    <div
      v-if="label"
      class="font-weight-medium text-caption text--secondary text-md-subtitle-1"
    >
      {{ label }}:
    </div>
    <div
      v-if="!isEdit"
      class="rounded text-field-label"
      :class="{
        'editable-field': !disabled,
        'text-h5 font-weight-semibold': large,
      }"
      @click.stop="disabled ? null : edit()"
    >
      <span
        v-if="!modelValue && !isEdit"
        class="secondary--text"
        :class="{ 'text-h5 font-weight-semibold': large }"
        >{{ placeholder }}</span
      >
      <span
        v-else
        class="text-pre-line text-break"
        :class="{ 'text-h5 font-weight-semibold': large }"
        >{{ modelValue }}</span
      >
    </div>
    <div v-else>
      <v-textarea
        v-if="type === 'textarea'"
        v-model="modelValue"
        autofocus
        hide-details
        outlined
        :class="{ 'text-h5 font-weight-semibold': large }"
        :placeholder="placeholder"
        :rows="1"
        @blur="onChange"
        @keyup.enter="onChange"
      />
      <v-text-field
        v-else
        v-model="modelValue"
        :type="type"
        autofocus
        :class="{ 'text-h5 font-weight-semibold': large }"
        :placeholder="placeholder"
        @blur="onChange"
        @keyup.enter="onChange"
      >
        <template #append>
          <v-progress-circular
            v-show="loading"
            size="15"
            width="1"
            indeterminate
          />
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditableTextField',
  props: [
    'loading',
    'value',
    'type',
    'placeholder',
    'disabled',
    'label',
    'large',
  ],
  data() {
    return {
      isEdit: false,
      modelValue: this.value,
      isDirty: false,
    }
  },
  watch: {
    value(val) {
      this.modelValue = val
    },
    modelValue() {
      this.isDirty = true
    },
  },
  methods: {
    edit() {
      this.isEdit = true
    },
    onChange(e) {
      if (e.shiftKey && e.key === 'Enter') return
      this.isEdit = false
      if (this.isDirty) {
        this.$emit('onupdate', this.modelValue)
      }
      this.isDirty = false
    },
  },
}
</script>
<style scoped>
.text-field-lable {
  font-size: 14px;
}
</style>
