<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="search">
          <path
            id="Path_5218"
            data-name="Path 5218"
            class="cls-1"
            d="M15.58,20.17a4.59,4.59,0,1,1,4.59-4.59h0A4.61,4.61,0,0,1,15.58,20.17Zm0-8.25a3.67,3.67,0,1,0,3.67,3.66h0A3.66,3.66,0,0,0,15.58,11.92Z"
          />
          <path
            id="Path_5219"
            data-name="Path 5219"
            class="cls-1"
            d="M21.54,22a.51.51,0,0,1-.33-.13L18.4,19.05a.45.45,0,0,1,0-.64.46.46,0,0,1,.65,0h0l2.81,2.81a.46.46,0,0,1-.32.78Z"
          />
          <path
            id="Path_5220"
            data-name="Path 5220"
            class="cls-1"
            d="M3.21,22H1.38A1.38,1.38,0,0,1,0,20.62v-5.5a1.38,1.38,0,0,1,1.38-1.37H3.21a1.37,1.37,0,0,1,1.37,1.37v5.5A1.37,1.37,0,0,1,3.21,22ZM1.38,14.67a.45.45,0,0,0-.46.45v5.5a.45.45,0,0,0,.46.46H3.21a.46.46,0,0,0,.46-.46v-5.5a.46.46,0,0,0-.46-.45Z"
          />
          <path
            id="Path_5221"
            data-name="Path 5221"
            class="cls-1"
            d="M8.71,22H6.88A1.38,1.38,0,0,1,5.5,20.62V11.46a1.38,1.38,0,0,1,1.38-1.38H8.71a1.37,1.37,0,0,1,1.37,1.38v9.16A1.37,1.37,0,0,1,8.71,22ZM6.88,11a.46.46,0,0,0-.46.46v9.16a.45.45,0,0,0,.46.46H8.71a.46.46,0,0,0,.46-.46V11.46A.47.47,0,0,0,8.71,11Z"
          />
          <path
            id="Path_5222"
            data-name="Path 5222"
            class="cls-1"
            d="M11.46,10.08A.46.46,0,0,1,11,9.62V6a1.38,1.38,0,0,1,1.38-1.38h1.83A1.37,1.37,0,0,1,15.58,6V8.71a.46.46,0,1,1-.91,0V6a.47.47,0,0,0-.46-.46H12.38a.46.46,0,0,0-.46.46V9.62A.46.46,0,0,1,11.46,10.08Z"
          />
          <path
            id="Path_5223"
            data-name="Path 5223"
            class="cls-1"
            d="M20.62,11a.46.46,0,0,1-.45-.46V1.38a.46.46,0,0,0-.46-.46H17.88a.45.45,0,0,0-.46.46V8.71a.46.46,0,1,1-.92,0V1.38A1.38,1.38,0,0,1,17.88,0h1.83a1.37,1.37,0,0,1,1.37,1.38v9.16A.46.46,0,0,1,20.62,11Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OverallStatus',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
