import Compressor from 'compressorjs'

export const imgCompressor = async file => {
  return new Promise(resolve => {
    new Compressor(file, {
      quality: 0.7,
      success(result) {
        resolve(result)
      },
    })
  })
}

export default imgCompressor
