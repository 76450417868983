<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.66 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <path
          class="cls-1"
          d="M15.66,12.2,12.37,7.05a1.46,1.46,0,0,0-.13-2.65l1-3.48A.44.44,0,0,0,13.12.5a1.68,1.68,0,0,0-2.4,0,.85.85,0,0,1-.59.24A.89.89,0,0,1,9.53.5h0a1.68,1.68,0,0,0-2.4,0h0A.85.85,0,0,1,5.94.5h0a1.68,1.68,0,0,0-2.4,0,.43.43,0,0,0-.11.42l1,3.47a1.46,1.46,0,0,0-.82,1.9,1.48,1.48,0,0,0,.68.76L1,12.2A6.38,6.38,0,0,0,6.37,22h3.92a6.37,6.37,0,0,0,5.37-9.8ZM5.33,1.1h0a1.7,1.7,0,0,0,2.4,0h0a.84.84,0,0,1,1.19,0h0a1.7,1.7,0,0,0,2.4,0,.85.85,0,0,1,1-.14l-1,3.35H5.29L4.33,1A.85.85,0,0,1,5.33,1.1Zm9.79,17.17a5.46,5.46,0,0,1-4.83,2.87H6.37A5.52,5.52,0,0,1,.86,15.62a5.45,5.45,0,0,1,.87-3L5.2,7.21H6.4a.43.43,0,0,0,.45-.41.44.44,0,0,0-.41-.45H5a.6.6,0,0,1-.6-.6.6.6,0,0,1,.6-.6H11.7a.6.6,0,0,1,0,1.2H10.26a.44.44,0,0,0-.45.41.43.43,0,0,0,.41.45h1.24l3.47,5.45a5.44,5.44,0,0,1,.19,5.61Z"
        />
        <path
          class="cls-1"
          d="M9.09,14l-.33-.12V12a1.83,1.83,0,0,1,.49.23.42.42,0,0,0,.6-.1.41.41,0,0,0-.08-.58,2.52,2.52,0,0,0-1-.43v-.43a.43.43,0,0,0-.86,0v.47l-.22.05A1.7,1.7,0,0,0,6.5,12.48,1.51,1.51,0,0,0,7,14a4,4,0,0,0,.9.51V17A1.69,1.69,0,0,1,7,16.64a.43.43,0,0,0-.47.72,2.55,2.55,0,0,0,1.41.49v.44a.43.43,0,0,0,.45.41.43.43,0,0,0,.41-.41V17.8a2.09,2.09,0,0,0,1.58-1.69A1.83,1.83,0,0,0,9.09,14ZM7.53,13.3a.68.68,0,0,1-.19-.66A.89.89,0,0,1,7.9,12v1.58A2.35,2.35,0,0,1,7.53,13.3Zm2,2.67a1.24,1.24,0,0,1-.73.93V14.82l0,0A1,1,0,0,1,9.49,16Z"
        />
        <path
          class="cls-1"
          d="M8.33,7.21a.43.43,0,0,0,0-.86.44.44,0,0,0-.44.43A.48.48,0,0,0,8,7.09.42.42,0,0,0,8.33,7.21Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Finance',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
