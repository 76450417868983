<template>
  <v-dialog v-model="dialog" fullscreen>
    <template #activator="props">
      <slot name="customBtn" v-bind="props" />
      <v-btn
        v-if="!$scopedSlots.customBtn"
        color="secondary darken-1"
        class="mb-2"
        elevation="0"
        outlined
        v-on="props.on"
      >
        <span class="text-capitalize"> New Tenant </span>
      </v-btn>
    </template>
    <v-sheet class="white overflow-y-auto">
      <v-container fluid>
        <v-card-title class="py-6">
          <div class="p-relative flex text-center">
            <h2 class="text-h6">{{ $t('Create new tenant') }}</h2>
          </div>
        </v-card-title>
        <v-form
          ref="createTenant"
          lazy-validation
          @submit.prevent="createTenant"
        >
          <v-card-text class="px-6">
            <p class="text-body-1 font-weight-medium">Tenant details</p>
            <div class="d-flex align-end mb-4">
              <profile-upload v-model="appSettingObj.logo" />
              <v-text-field
                v-model="tenantObj.name"
                class="pl-4"
                label="Name"
                outlined
                hide-details
                dense
                :rules="[required]"
              />
            </div>
            <p class="text-body-1 font-weight-medium">User details</p>
            <div class="mb-1">
              <v-text-field
                id="autocomplete"
                v-model="userObj.first_name"
                label="First Name"
                outlined
                dense
                :rules="[required]"
              />
            </div>
            <div class="mb-1">
              <v-text-field
                v-model="userObj.last_name"
                label="Last Name"
                outlined
                dense
              />
            </div>
            <div class="mb-1">
              <v-text-field
                v-model="userObj.email"
                label="Email"
                outlined
                dense
              />
            </div>
            <div class="mb-1">
              <v-text-field
                v-model="userObj.password"
                label="Password"
                placeholder="Password: (min 8 characters, at least one uppercase, one lowercase, one digit and one special character)"
                outlined
                dense
              />
            </div>
            <div class="mb-1">
              <v-text-field
                v-model="userObj.password_confirmation"
                label="Password confirmation"
                outlined
                dense
              />
            </div>

            <p class="text-body-1 font-weight-medium">AppSetting details</p>
            <div class="mb-1">
              <v-text-field
                v-model="appSettingObj.contact_email"
                label="Email"
                outlined
                dense
              />
            </div>
            <div class="mb-1">
              <currency-picker v-model="appSettingObj.currency" />
            </div>
            <div class="mb-1">
              <v-checkbox
                v-model="appSettingObj.is_channel_manager"
                class="mt-0"
                label="Is Channel Manager"
                dense
                outlined
                hide-details
              />
            </div>
            <div class="mb-1">
              <v-checkbox
                v-model="addOnboardingTasks"
                class="mt-0"
                label="Create onboarding listing tasks"
                dense
                outlined
                hide-details
              />
            </div>
            <div class="mb-1">
              <v-autocomplete
                v-model="appSettingObj.timezone"
                label="Timezone"
                outlined
                dense
                :items="timezones"
                :rules="[required]"
              />
            </div>

            <div class="mb-1">
              <v-text-field
                v-model="appSettingObj.processing_rate"
                label="Processing rate"
                placeholder="Processing fee: (default 0)"
                outlined
                dense
                :rules="[required]"
              />
            </div>
            <div class="mb-1">
              <v-select
                v-model="appSettingObj.listing_payment_period"
                label="Listing payment period"
                outlined
                :items="['weekly', 'monthly']"
                dense
                :rules="[required]"
              />
            </div>

            <div class="d-flex flex-column my-8">
              <v-select
                v-model="appSettingObj.tax_payment_method"
                class="mt-2 mb-2"
                label="Taxes are paid by"
                outlined
                dense
                clearable
                :items="['company', 'owner']"
                small-chips
              />
              <v-select
                v-model="appSettingObj.reservation_cal_method"
                class="mt-2 mb-2"
                label="Calculation type"
                outlined
                dense
                clearable
                :items="['days_in_month', 'check_ins', 'check_outs']"
                small-chips
              />
              <v-select
                v-model="appSettingObj.net_income_formula"
                prepend-inner-icon="mdi-calculator"
                outlined
                label="Net Income Formula"
                dense
                :items="NET_INCOME_FORMULAS"
              />
              <v-select
                v-model="appSettingObj.management_commission_formula"
                outlined
                prepend-inner-icon="mdi-calculator"
                label="Management Commission Formula"
                dense
                :items="MANAGEMENT_COMMISSION_FORMULAS"
              />
              <v-select
                v-model="appSettingObj.owner_revenue_formula"
                outlined
                prepend-inner-icon="mdi-calculator"
                label="Owner Revenue Formula"
                dense
                :items="OWNER_REVENUE_FORMULAS"
              />
            </div>
            <div class="mb-1">
              <v-checkbox
                v-model="addDummyData"
                class="mt-0"
                label="Populate with dummy data"
                dense
                outlined
                hide-details
              />
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-6 py-4">
            <v-spacer />
            <v-btn color="primary" outlined elevation="0" @click="close"
              >Cancel</v-btn
            >
            <v-btn color="primary" elevation="0" type="submit">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import axios from '@/axios/config'
import GlobalMixin from 'components/mixins/global-mixin'
import FormRules from 'components/mixins/form-rules-mixin'
import {
  NET_INCOME_FORMULAS,
  OWNER_REVENUE_FORMULAS,
  MANAGEMENT_COMMISSION_FORMULAS,
} from '@/consts'
import moment from 'moment-timezone'
import ProfileUpload from 'components/common/profile-upload.vue'
import CurrencyPicker from 'components/common/currency-picker.vue'

export default {
  name: 'CreateTenantPage',
  components: { CurrencyPicker, ProfileUpload },
  mixins: [FormRules, GlobalMixin],
  data() {
    return {
      dialog: false,
      addDummyData: false,
      tenantObj: {
        name: '',
        subdomain: '',
        domain: 'app.boomnow.com',
        base_url: 'https://app.boomnow.com',
      },
      userObj: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      appSettingObj: {
        contact_email: '',
        currency: '',
        timezone: '',
        is_channel_manager: true,
        logo: null,
        tax_payment_method: 'company',
        processing_rate: 0,
        reservation_cal_method: 'check_ins',
        listing_payment_period: 'monthly',
        net_income_formula:
          'fare_accommodation + cleaning_fee - reported_channel_fee',
        management_commission_formula:
          'fare_accommodation * commission_percent',
        owner_revenue_formula: 'net_income - management_commission',
        management_total_formula:
          'cleaning_fee + management_commission + pmc_fees',
      },
      addOnboardingTasks: true,
      NET_INCOME_FORMULAS,
      OWNER_REVENUE_FORMULAS,
      MANAGEMENT_COMMISSION_FORMULAS,
    }
  },
  computed: {
    timezones() {
      return moment.tz.names()
    },
  },
  methods: {
    close() {
      this.dialog = false
    },
    async createTenant() {
      if (this.$refs.createTenant.validate()) {
        this.tenantObj.domain = 'app.boomnow.com'
        this.tenantObj.subdomain = this.tenantObj.name
          .replace(' ', '')
          .toLowerCase()
        this.tenantObj.name =
          this.tenantObj.subdomain.charAt(0).toUpperCase() +
          this.tenantObj.subdomain.slice(1)
        const payload = {
          app_settings: this.appSettingObj,
          user: this.userObj,
          tenant: this.tenantObj,
          add_onboarding_tasks: this.addOnboardingTasks,
          add_dummy_data: this.addDummyData,
        }
        this.$store.commit('updateLoading', true)
        await axios.post(`/api/tenants`, payload)
        this.$store.commit('updateLoading', false)
        this.$refs.createTenant.reset()
        this.close()
      }
    },
  },
}
</script>
