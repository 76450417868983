<template>
  <div>
    <v-tabs
      v-model="paramsTab"
      icons-and-text
      class="tabs-menu main-background"
    >
      <v-tab href="#paper-trail">
        Paper Trail
        <v-icon>mdi-database-search-outline</v-icon>
      </v-tab>
      <v-tab v-if="isDesignedVr" href="#ai-agents">
        AI Agents
        <v-icon>mdi-cloud-search</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="paramsTab" touchless>
      <v-tab-item value="paper-trail" class="full">
        <paper-trail v-if="paramsTab === 'paper-trail'" />
      </v-tab-item>
      <v-tab-item value="ai-agents" class="full">
        <div v-if="paramsTab === 'ai-agents'">
          <div style="dislay: flex; flex-direction: column; margin: 100px">
            <div style="display: flex">
              <span>Download all conversations</span>
              <v-btn
                v-if="!conversationDownload"
                style="margin-left: 20px; margin-top: -5px"
                alt="Download all conversations"
                @click="downloadConversations"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn
                v-else
                disabled
                style="margin-left: 20px; margin-top: -5px"
                alt="Download all conversations"
              >
                <v-icon class="rotating">mdi-loading</v-icon>
              </v-btn>
            </div>
            <div style="display: flex; margin-top: 10px">
              <span>Generate CS Policy File</span>
              <v-btn
                v-if="!settings.cs_policy_file_generation_in_progress"
                style="margin-left: 20px; margin-top: -5px"
                alt="Generate CS Policy File"
                @click="generateCSPolicyFile"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn
                v-else
                disabled
                style="margin-left: 20px; margin-top: -5px"
                alt="Generate CS Policy File"
              >
                <v-icon class="rotating">mdi-loading</v-icon>
              </v-btn>
            </div>
            <div style="display: flex; margin-top: 10px">
              <span>Generate Sales Policy File</span>
              <v-btn
                v-if="!settings.sales_policy_file_generation_in_progress"
                style="margin-left: 20px; margin-top: -5px"
                alt=">Generate Sales Policy File"
                @click="generateSalesPolicyFile"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn
                v-else
                style="margin-left: 20px; margin-top: -5px"
                disabled
                class="float-right"
                alt=">Generate Sales Policy File"
              >
                <v-icon class="rotating">mdi-loading</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PaperTrail from 'components/dev-tools/paper-trail'
import axios from 'axios'

export default {
  name: 'DevTools',
  components: { PaperTrail },
  data() {
    return {
      conversationDownload: false,
    }
  },
  mounted() {
    this.$store.dispatch('getAiConfigs')
  },
  computed: {
    settings() {
      return this.$store.state.AiConfigs || {}
    },
  },
  methods: {
    async downloadConversations() {
      this.conversationDownload = true
      const url = '/api/conversations/download'

      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      })
        .then(response => {
          const file = new Blob([response.data], {
            type: response.headers['content-type'],
          })
          const fileURL = window.URL.createObjectURL(file)
          const link = document.createElement('a')
          link.href = fileURL
          link.setAttribute('download', 'all_conversations.txt')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(fileURL)
          this.conversationDownload = false
        })
        .catch(error => console.error('Download error:', error))
    },
    async generateCSPolicyFile() {
      this.settings.cs_policy_file_generation_in_progress = true
      const url = '/api/ai-configs/generate-policy-file?department=cs'
      axios.post(url)
    },
    async generateSalesPolicyFile() {
      this.settings.sales_policy_file_generation_in_progress = true
      const url = '/api/ai-configs/generate-policy-file?department=sales'
      axios.post(url)
    },
  },
}
</script>

<style scoped>
.rotating {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
