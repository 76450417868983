<template>
  <div>
    <div>
      <div class="font-weight-semibold dark600--text mb-2">
        {{ label }}
      </div>
      <div
        class="border-left pl-4 text-h5"
        :style="`border-left-color: ${color}`"
      >
        {{ value || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreCheckinStat',
  props: ['color', 'label', 'value'],
}
</script>

<style>
.border-left {
  border-left: 3px solid;
}
</style>
