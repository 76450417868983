<template>
  <v-dialog
    v-if="currentReview"
    v-model="currentReview"
    persistent
    max-width="750px"
  >
    <v-card class="pa-4">
      <v-row class="align-baseline">
        <v-col class="justify-end">
          <v-btn
            fab
            dark
            small
            color="teal"
            class="float-right"
            @click="closeConversation"
          >
            <v-icon dark>close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <h3>
            {{ currentReview.fullName }}
            <span v-if="isAdmin" class="small-text grey-text"
              >({{ currentReview.id }})</span
            >
          </h3>
        </v-col>
        <v-col cols="12">
          {{ currentReview.public }}
        </v-col>
        <v-col
          v-for="(key, val) in scoreMatrics"
          :key="key"
          class="small-text center-text super-light-gray"
        >
          <v-col cols="12"> {{ val }}: </v-col>
          <v-col cols="12">
            {{ currentReview[key] }}
          </v-col>
        </v-col>
        <v-col cols="12">
          <v-textarea v-model="dvrReply" rows="3" label="Reply" />
        </v-col>
        <v-col cols="12" class="text-end">
          <v-btn
            :disabled="!dvrReply"
            color="blue darken-1"
            small
            text
            @click="sendReply"
            >send</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: [''],
  data() {
    return {
      dvrReply: null,
      scoreMatrics: {
        Communication: 'normilized_communication',
        Cleaning: 'normilized_cleaning',
        Accuracy: 'normilized_accuracy',
        Location: 'normilized_location',
        Checkin: 'normilized_checkin',
        Value: 'normilized_value',
      },
    }
  },
  computed: {
    ...mapGetters(['currentReview']),
  },
  methods: {
    sendReply() {
      this.$store.dispatch('postReply', {
        message: this.dvrReply,
        id: this.currentReview.id,
      })
    },
    closeConversation() {
      this.$store.commit('updateCurrentReview', null)
    },
  },
}
</script>
