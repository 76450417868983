<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
  >
    <g>
      <path
        d="M161,275.2c0,10.3-8.4,18.7-18.7,18.7c-10.3,0-18.7-8.4-18.7-18.7c0-10.3,8.4-18.7,18.7-18.7
		C152.6,256.4,161,264.8,161,275.2z M368,256.4c-10.3,0-18.7,8.4-18.7,18.7c0,10.3,8.4,18.7,18.7,18.7c10.3,0,18.7-8.4,18.7-18.7
		C386.7,264.8,378.3,256.4,368,256.4z M437.8,365.4c-49,38.4-119.7,29.8-158.1-19.2l-24.2,36.2l-24-35.9
		c-37.7,47.2-106,56.2-154.6,20.4C26.9,330,16.2,259.6,53,209.6c-4.7-16-12.2-31.1-22-44.6l70.5,0c46.1-28.7,99.6-43.4,154-42.2
		c53-0.9,105.2,13.5,150.1,41.7l75.2,0.1c-9.9,13.6-17.4,28.9-22.1,45C495.2,258.5,486,327.7,437.8,365.4z M233.6,276.1
		c0-49.7-40.3-89.9-89.9-89.9c-49.7,0-89.9,40.3-89.9,89.9c0,49.7,40.3,89.9,89.9,89.9C193.4,366,233.6,325.8,233.6,276.1
		L233.6,276.1z M256,268c4.2-57.4,51.2-102.3,108.7-103.9c-34.4-14.9-71.6-22.3-109.2-21.7c-37.6-0.4-74.8,6.9-109.5,21.3
		C204.1,164.8,251.7,210,256,268L256,268z M452.6,245.5c-17.2-46.6-68.9-70.4-115.5-53.2c-46.6,17.2-70.4,68.9-53.2,115.5v0
		c17.2,46.6,68.9,70.4,115.5,53.2C446,343.7,469.8,292.1,452.6,245.5L452.6,245.5z M197.9,275.2L197.9,275.2
		c0,30.8-25,55.7-55.7,55.6c-30.7,0-55.6-25-55.6-55.7s25-55.6,55.7-55.6C173,219.6,197.8,244.5,197.9,275.2L197.9,275.2z
		 M178.7,275.2L178.7,275.2c0-20.2-16.4-36.5-36.5-36.5s-36.4,16.4-36.4,36.5c0,20.1,16.4,36.4,36.5,36.4
		C162.4,311.5,178.7,295.2,178.7,275.2z M423.6,275.2L423.6,275.2c0,30.8-24.9,55.7-55.7,55.6c-30.7,0-55.6-24.9-55.6-55.7
		c0-30.7,24.9-55.6,55.7-55.6C398.7,219.6,423.6,244.5,423.6,275.2z M404.5,275.1c0-20.1-16.3-36.5-36.5-36.5
		c-20.1,0-36.5,16.3-36.5,36.5c0,20.1,16.3,36.5,36.5,36.5C388.1,311.6,404.4,295.3,404.5,275.1L404.5,275.1L404.5,275.1z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TripAdvisor',
}
</script>

<style scoped></style>
