<template>
  <v-menu
    v-model="menu"
    :open-on-hover="openOnHover"
    :top="pos.top"
    :bottom="pos.bottom"
    :left="pos.left"
    :right="pos.right"
    :offset-x="pos.offsetX"
    :offset-y="pos.offsetY"
  >
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>

    <v-list dense>
      <template v-for="(item, i) in items">
        <v-list-item
          v-if="!item.items"
          :key="i"
          dense
          :disabled="item.disabled"
          @click="onClick(item.actionKey)"
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon :disabled="item.disabled" v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
        <simple-menu
          v-else
          :key="i"
          :items="item.items"
          :open-on-hover="true"
          :position="nestedPos"
          @action="onClick($event)"
        >
          <template #activator="{ on, attrs }">
            <v-list-item
              v-bind="attrs"
              dense
              :disabled="item.disabled"
              v-on="on"
            >
              <v-list-item-icon v-if="item.icon">
                <v-icon :disabled="item.disabled" v-text="item.icon" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title" />
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon :disabled="item.disabled">mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </simple-menu>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'SimpleMenu',
  mixins: [CommonFunctions],
  props: ['items', 'openOnHover', 'position', 'nestedPosition'],
  data: () => ({
    menu: false,
  }),
  computed: {
    pos() {
      return this.position || { right: true, offsetY: true }
    },
    nestedPos() {
      return this.nestedPosition || { right: true, offsetX: true }
    },
  },
  methods: {
    onClick(actionKey) {
      actionKey && this.$emit('action', actionKey)
      this.menu = false
    },
  },
}
</script>

<style scoped></style>
