<template>
  <div v-if="statsObject">
    <v-card-title class="text-caption secondary--text">
      {{ title }}
    </v-card-title>
    <canvas :id="graphId" ref="graphEl"></canvas>
  </div>
</template>

<script>
import $ from 'cash-dom'
import map from 'lodash/fp/map'
import compose from 'lodash/fp/compose'
import toNumber from 'lodash/fp/toNumber'
import isEqual from 'lodash/fp/isEqual'
import ColorsMixin from 'components/mixins/colors-mixin'

const countsIn = compose(map(x => Math.round(toNumber(x))))

export default {
  name: 'SourcesGraphNew',
  props: ['statsObject', 'title', 'graphId', 'graphType', 'mini'],
  mixins: [ColorsMixin],
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    titles() {
      return Object.keys(this.statsObject)
    },
  },
  watch: {
    statsObject(newVal, oldVal) {
      const { chart } = this
      if (chart && !isEqual(newVal, oldVal)) {
        chart.config.data = this.generateChartData(newVal)
        chart.update()
      }
    },
  },
  mounted() {
    this.drawChart()
  },
  methods: {
    canvas() {
      return $(this.$refs.graphEl)
    },
    drawChart: function () {
      const { graphType, statsObject } = this
      const ctx = this.canvas().get(0).getContext('2d')
      setTimeout(() => {
        const chart = window.Chart
        this.chart = new chart(ctx, {
          type: graphType || 'doughnut',
          data: this.generateChartData(statsObject),
          options: {
            legend: {
              position: 'bottom',
            },
            tooltips: {
              backgroundColor: '#ffffff',
              bodyFontColor: '#000000',
            },
          },
        })
      }, 200)
    },
    generateChartData(statsObject) {
      return {
        labels: Object.keys(statsObject),
        datasets: [
          {
            data: countsIn(statsObject),
            backgroundColor: Object.keys(statsObject).map(key =>
              this.stringToColour(key)
            ),
          },
        ],
      }
    },
  },
}
</script>
