<template>
  <v-text-field
    id="autocomplete"
    :value="address"
    autocomplete="off"
    label="Address"
    :rules="rules"
    outlined
    dense
  />
</template>

<script>
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { loadGmaps } from '@/modules/remote-resources'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'GoogleAddress',
  props: ['value', 'rules'],
  data() {
    return {
      address: this.value,
      place: null,
    }
  },
  mounted() {
    this.initGoogleAutocomplete()
    this.address = this.value
  },
  watch: {
    value(newVal) {
      this.address = newVal
    },
  },
  methods: {
    async initGoogleAutocomplete() {
      const googlePlaces = get(window, 'google.maps.places', null)
      if (isNil(googlePlaces)) await loadGmaps()

      const inputElem = document.getElementById('autocomplete')
      this.autocomplete = new window.google.maps.places.Autocomplete(inputElem)
      this.autocomplete.addListener(
        'place_changed',
        this.onAutocomplete.bind(this)
      )
    },
    parsePlaceAttribute(attribute, place) {
      const addressComponent =
        place.address_components.find(
          component => component.types[0] === attribute
        ) || {}
      if (isEmpty(addressComponent) || isNil(addressComponent.long_name)) {
        return ''
      }

      return addressComponent.long_name
    },
    onAutocomplete() {
      const place = this.autocomplete.getPlace()

      if (isEmpty(place.address_components)) return false
      const data = {
        street: this.parsePlaceAttribute('route', place),
        zip_code: this.parsePlaceAttribute('postal_code', place),
        country: this.parsePlaceAttribute('country', place),
        city: this.parsePlaceAttribute('locality', place),
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: place.formatted_address,
      }
      this.address = data.address
      this.$emit('change', data)
    },
  },
}
</script>

<style scoped></style>
