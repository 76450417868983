import { render, staticRenderFns } from "./guest-crm.vue?vue&type=template&id=c60e5f44&scoped=true&"
import script from "./guest-crm.vue?vue&type=script&lang=js&"
export * from "./guest-crm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c60e5f44",
  null
  
)

export default component.exports