<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.571"
    height="20"
    viewBox="0 0 18.571 20"
  >
    <g transform="translate(-17.066)">
      <g transform="translate(17.066 12.857)">
        <g transform="translate(0)">
          <path
            d="M34.923,307.2a.714.714,0,0,0-.714.714V312.2a.714.714,0,0,1-.714.714H19.209a.714.714,0,0,1-.714-.714v-4.286a.714.714,0,0,0-1.429,0V312.2a2.143,2.143,0,0,0,2.143,2.143H33.495a2.143,2.143,0,0,0,2.143-2.143v-4.286A.714.714,0,0,0,34.923,307.2Z"
            transform="translate(-17.066 -307.2)"
          />
        </g>
      </g>
      <g transform="translate(22.075)">
        <path
          d="M145.079,12.352a.714.714,0,0,0-.992,0L141.733,14.7V.714a.714.714,0,0,0-1.429,0V14.7l-2.352-2.352a.714.714,0,0,0-1.01,1.01l3.571,3.571a.714.714,0,0,0,1.01,0h0l3.571-3.571A.714.714,0,0,0,145.079,12.352Z"
          transform="translate(-136.742)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Download',
}
</script>
