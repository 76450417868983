<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="14"
    viewBox="0 0 24 14"
  >
    <g transform="translate(-1773.5 -127.107)">
      <line class="a" x2="19" transform="translate(1778.5 128.107)" />
      <line class="a" x2="19" transform="translate(1778.5 134.107)" />
      <line class="a" x2="19" transform="translate(1778.5 140.107)" />
      <line class="a" x2="2" transform="translate(1773.5 128.107)" />
      <line class="a" x2="2" transform="translate(1773.5 134.107)" />
      <line class="a" x2="2" transform="translate(1773.5 140.107)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TableView',
}
</script>

<style scoped>
.a {
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
}
</style>
