<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <template #activator="{ on }">
      <v-btn
        class="text-capitalize"
        color="secondary darken-1"
        outlined
        elevation="0"
        v-on="on"
        >New item
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pa-6">
        <div class="p-relative flex text-center">
          <h2 class="text-h6">Create new inventory item</h2>
          <v-icon class="close-button" @click="close">close</v-icon>
        </div>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form>
          <v-container class="py-0" fluid>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="inventoryItem.sku"
                  label="SKU"
                  outlined
                  dense
                  hide-details
                  required
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="inventoryItem.title"
                  label="Title"
                  outlined
                  dense
                  hide-details
                  required
                />
                <div v-if="$v.title.$error" class="warning--text mt-1">
                  {{ $t('Required field') }}
                </div>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="inventoryItem.catagory"
                  :items="$store.state.catagoriesOptions"
                  label="Category"
                  outlined
                  dense
                  hide-details
                />
                <div v-if="$v.catagory.$error" class="warning--text mt-1">
                  {{ $t('Required field') }}
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="inventoryItem.price"
                  label="Price"
                  outlined
                  dense
                  hide-details
                  required
                />
                <div v-if="$v.price.$error" class="warning--text mt-1">
                  {{ $t('Required field') }}
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="inventoryItem.markup"
                  label="Markup"
                  outlined
                  dense
                  hide-details
                  required
                />
                <div v-if="$v.markup.$error" class="warning--text mt-1">
                  {{ $t('Required field') }}
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="inventoryItem.current_amount"
                  label="Initial Amount"
                  outlined
                  dense
                  hide-details
                  required
                />
                <div v-if="$v.current_amount.$error" class="warning--text mt-1">
                  {{ $t('Required field') }}
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="inventoryItem.min_alert"
                  label="Min amount alert"
                  outlined
                  dense
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="inventoryItem.storage_nickname"
                  :items="storageNames"
                  label="Storage"
                  outlined
                  dense
                  hide-details
                />
                <div
                  v-if="$v.storage_nickname.$error"
                  class="warning--text mt-1"
                >
                  {{ $t('Required field') }}
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="auto">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  required
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="inventoryItem.date_of_arrival"
                      label="Date of arrival"
                      prepend-inner-icon="$calendar_fill"
                      outlined
                      dense
                      hide-details
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="inventoryItem.date_of_arrival"
                    :max="
                      new Date(new Date().setMonth(new Date().getMonth() + 6))
                        .toISOString()
                        .substr(0, 10)
                    "
                    min="2019-01-12"
                    @change="saveUntilDate"
                  />
                </v-menu>
                <div
                  v-if="$v.date_of_arrival.$error"
                  class="warning--text mt-1"
                >
                  {{ $t('Required field') }}
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="inventoryItem.size.height"
                  label="Height"
                  outlined
                  dense
                  required
                />
                <v-text-field
                  v-model="inventoryItem.size.width"
                  label="Width"
                  outlined
                  dense
                  required
                />
                <v-text-field
                  v-model="inventoryItem.size.depth"
                  label="Depth"
                  outlined
                  dense
                  required
                />
              </v-col>
              <v-col cols="12" sm="6">
                <img
                  id="blahfds"
                  src="https://res.cloudinary.com/do4tedxg6/image/upload/v1575277201/yuwqgoeg3c9zthkedlvm.jpg"
                  style="max-width: 100%; max-height: 400px"
                />
              </v-col>
              <v-col cols="12">
                <dvr-media-upload :value.sync="images" folder="inventory" />
                <v-row v-show="images" class="mt-2">
                  <v-col
                    v-for="(image, index) in images"
                    :key="`preview-${index}`"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                  >
                    <v-img :src="image" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="px-6 py-4">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="text-capitalize"
          text
          @click.native="close"
          >Cancel
        </v-btn>
        <v-btn
          color="primary"
          class="text-capitalize"
          elevation="0"
          :disabled="loading"
          @click.native="save"
          >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DvrMediaUpload from 'components/common/dvr-media-upload'
import { mapGetters } from 'vuex'

export default {
  components: { DvrMediaUpload },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['type'],
  validations: {
    title: {
      required,
    },
    catagory: {
      required,
    },
    price: {
      required,
    },
    current_amount: {
      required,
    },
    markup: {
      required,
    },
    date_of_arrival: {
      required,
    },
    storage_nickname: {
      required,
    },
  },
  data() {
    return {
      menu2: false,
      search: null,
      dialog: false,
      editMode: false,
      inventoryItem: {
        markup: 0.15,
        size: {},
      },
      images: [],
    }
  },
  mounted() {
    window.$store.state.itemModal = this
  },
  computed: {
    ...mapGetters('storages', ['storageNames']),
    sku() {
      return this.inventoryItem.sku
    },
    title() {
      return this.inventoryItem.title
    },
    catagory() {
      return this.inventoryItem.catagory
    },
    description() {
      return this.inventoryItem.description
    },
    price() {
      return this.inventoryItem.price
    },
    current_amount() {
      return this.inventoryItem.current_amount
    },
    markup() {
      return this.inventoryItem.markup
    },
    date_of_arrival() {
      return this.inventoryItem.date_of_arrival
    },
    storage_nickname() {
      return this.inventoryItem.storage_nickname
    },
  },
  methods: {
    setDialog(val) {
      this.dialog = val
    },
    close() {
      this.dialog = false
      this.inventoryItem = { markup: 0.15, size: {} }
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        let data = {}
        data.sku = this.inventoryItem.sku
        data.title = this.inventoryItem.title
        data.catagory = this.inventoryItem.catagory
        data.price = this.inventoryItem.price
        data.size = this.inventoryItem.size
        data.current_amount = this.inventoryItem.current_amount
        if (this.inventoryItem.min_alert) {
          data.min_alert = this.inventoryItem.min_alert
        }

        data.markup = this.inventoryItem.markup
        data.date_of_arrival = this.inventoryItem.date_of_arrival
        data.storage_nickname = this.inventoryItem.storage_nickname
        if (this.images.length) {
          data.images = this.images
        }
        this.$store.commit('updateLoading', true)

        axios
          .post('/api/inventory-items/', data)
          .then(() => {
            this.dialog = null
            this.$store.commit('updateLoading', false)
            this.inventoryItem = {
              markup: 0.15,
              size: {},
            }
            this.$store.dispatch('getInventoryItems', {})
            this.$store.dispatch('getRequestsByFilter', {
              request_type: 'Storage',
            })
          })
          .catch(error => {
            alert(error)
            this.inventoryItem = {
              markup: 0.15,
              size: {},
            }
            this.$store.commit('updateLoading', false)
          })
      }
    },
    saveUntilDate(date) {
      this.$refs.menu2.save(date)
    },
  },
}
</script>
