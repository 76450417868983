<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <template #activator="{ on }">
      <v-btn
        :rounded="false"
        class="rounded-0 justify-start"
        title="set"
        block
        :disabled="disabled"
        text
        small
        color="info"
        elevation="0"
        v-on="disabled ? null : on"
      >
        <v-icon small left>mdi-alarm</v-icon>Follow-Up
        <span v-if="disabled" class="ml-1"> on</span>
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-title> SC Follow-Up </v-card-title>
        <v-card-text>
          <div v-if="isEdit" class="text-subtitle-1">
            Current follow-up time is:
            <b>{{
              parseDate(listingTask.follow_up_at, 'ddd, MMM Do HH:mm', {
                local: true,
              })
            }}</b>
          </div>
          <v-btn-toggle
            v-model="timeType"
            color="primary accent-3"
            class="mb-4"
          >
            <v-btn small value="fixed"> Fixed </v-btn>
            <v-btn small value="custom"> Custom </v-btn>
          </v-btn-toggle>
          <v-menu
            v-if="timeType === 'custom'"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            required
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-model="date"
                outlined
                dense
                :rules="[required]"
                label="Follow-up on"
                prepend-inner-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="date"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-select
            v-else
            v-model="fixed"
            outlined
            :rules="[required]"
            label="Follow-up after"
            dense
            :items="followUpItems"
          />
          <contractor-select
            label="Follow-up on"
            icon="$account_alt"
            :rules="[required]"
            :enable-sug="true"
            :allow-multiple="false"
            :value.sync="listingTask.follow_up_by_id"
          />
        </v-card-text>
        <v-card-actions
          :class="[{ 'justify-space-between': isEdit }, 'justify-end']"
        >
          <div v-if="isEdit">
            <v-btn small color="error" @click="dismissFollowUp">
              Dismiss
            </v-btn>
          </div>
          <div>
            <v-btn small color="primary" outlined @click="closeModal"
              >Cancel</v-btn
            >
            <v-btn small color="primary" type="submit">Save</v-btn>
          </div>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import ContractorSelect from 'components/contractor-select'

export default {
  name: 'LtFollowupModal',
  components: { ContractorSelect },
  mixins: [formRules, CommonFunctions],
  props: ['listingTask', 'disabled'],
  data() {
    return {
      dialog: false,
      menu: false,
      date: null,
      fixed: null,
      note: null,
      timeType: 'fixed',
    }
  },
  computed: {
    isEdit() {
      return Boolean(this.listingTask.follow_up_at)
    },
    followUpItems() {
      return [
        '0.5 hours',
        '1 hours',
        '2 hours',
        '4 hours',
        '6 hours',
        '8 hours',
        '12 hours',
        '1 days',
        '2 days',
        '3 days',
        '4 days',
        '5 days',
        '6 days',
        '1 weeks',
        '2 weeks',
        '1 months',
        '2 months',
        '6 months',
      ]
    },
  },
  methods: {
    closeModal() {
      this.dialog = false
      this.$refs.form.reset()
    },
    dismissFollowUp() {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        follow_up_at: null,
        follow_up_by_id: null,
      })
      this.closeModal()
    },
    submit() {
      if (this.$refs.form.validate()) {
        const payload = {
          id: this.listingTask.id,
          follow_up_by_id: this.listingTask.follow_up_by_id,
        }
        if (this.timeType === 'fixed') {
          const [num, units] = this.fixed.split(' ')
          payload.follow_up_at = this.$moment()
            .utc()
            .add(num, units)
            .format('YYYY-MM-DD HH:mm')
        } else {
          payload.follow_up_at = this.date
        }
        this.$store.dispatch('updateListingTask', payload)
        this.closeModal()
      }
    },
  },
}
</script>

<style scoped></style>
