<template>
  <div>
    <div class="px-10 py-5">
      <v-row>
        <v-col cols="3">
          <v-menu
            v-model="dateMenu"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                :value="date"
                label="Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                outlined
                dense
                hide-details
                v-on="on"
              />
            </template>
            <v-date-picker v-model="date" reactive @change="dateChanged" />
          </v-menu>
        </v-col>
      </v-row>
      <task-calendar-filters @on-fetch="fetchData" />
    </div>
    <v-row class="pa-10 pt-0">
      <v-col cols="12">
        <div style="height: 5px" class="mb-2">
          <v-progress-linear
            :active="fetchingData"
            indeterminate
            color="secondary"
          />
        </div>
        <v-expansion-panels
          v-for="section in sections"
          :key="section"
          v-model="panel[section]"
          class="mb-10"
        >
          <v-expansion-panel
            v-for="(item, key, index) in sectionData(section)"
            :key="key"
            :readonly="!(item.list || []).length"
          >
            <v-expansion-panel-header :hide-actions="!(item.list || []).length">
              <div class="d-flex text-subtitle-2 bolder">
                <div style="min-width: 50px" class="text-center">
                  {{ (item.list || []).length }}
                </div>
                <div class="flex-fill">- {{ item.title }}</div>
                <div
                  v-if="isPMPage && (item.list || []).length && item.counts"
                  class="mx-3 d-flex"
                >
                  <span class="mr-2">S: {{ item.counts.single }}</span>
                  <span class="mr-2">H: {{ item.counts.hotel }}</span>
                  <span>R: {{ item.counts.resort }}</span>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider />
              <v-list v-if="panel[section] === index" dense>
                <template v-if="item.listType === 'service-calls'">
                  <div v-for="(item, i) in item.list || []" :key="i">
                    <task-calendar-cell
                      mode="list"
                      :event="mapServiceCall(item)"
                      :open-in-new-tab-on-click="true"
                      :hide-actions="true"
                      :users-map="usersMap"
                      @click:listing-name="
                        ({ event, listingId }) => {
                          event.stopPropagation()
                          onListingNameClick(key, i, listingId)
                        }
                      "
                    />
                    <template v-if="item.showListingList">
                      <div class="ml-10 light-wrapper">
                        <div v-for="(item, i) in item.listingList" :key="i">
                          <task-calendar-cell
                            :users-map="usersMap"
                            mode="list"
                            :is-daily-view="true"
                            :event="mapServiceCall(item)"
                            :open-in-new-tab-on-click="true"
                            :hide-actions="true"
                          />
                          <v-divider />
                        </div>
                      </div>
                    </template>
                    <v-divider />
                  </div>
                </template>
                <template v-else-if="item.listType === 'reservations'">
                  <div v-for="(item, i) in item.list || []" :key="i">
                    <simple-reservation-item
                      :date="date"
                      :reservation="item"
                      :pm-mode="isPMPage"
                    />
                    <v-divider />
                  </div>
                </template>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CalendarMixin from 'components/mixins/calendar-mixin'
import LtMixin from 'components/mixins/lt-mixin'
import TaskCalendarCell from 'components/calendar/task-calendar-cell'
import SimpleReservationItem from 'components/reservation/simple-reservation-item'
import axios from 'axios'
import TaskCalendarFilters from 'components/calendar/task-calendar-filters'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { MAX_SC_TASKS_RESULT } from '@/consts'
import Toaster from '@/utils/toaster'
import { get, pickBy } from 'lodash'
import { autoCancelToken } from '@/axios/config'

export default {
  name: 'DailySummary',
  components: {
    TaskCalendarFilters,
    SimpleReservationItem,
    TaskCalendarCell,
  },
  mixins: [CommonFunctions, PermissionsMixin, CalendarMixin, LtMixin],
  data() {
    return {
      panel: {},
      fetchingData: false,
      dateMenu: false,
      date: this.$moment().format('YYYY-MM-DD'),
      data: {
        early_checkin_reservations_today: {
          dataKey: 'early_checkin_reservations_today',
          listType: 'reservations',
          sections: ['PM', 'CS'],
          title: this.$t('Early Checkin Reservations Today'),
        },
        late_checkin_reservations_today: {
          dataKey: 'late_checkin_reservations_today',
          listType: 'reservations',
          sections: ['PM', 'CS'],
          title: this.$t('Late Checkin Reservations Today'),
        },
        late_checkout_reservations_today: {
          dataKey: 'late_checkout_reservations_today',
          listType: 'reservations',
          sections: ['PM', 'CS'],
          title: this.$t('Late Checkout Reservations Today'),
        },
        early_checkout_reservations_today: {
          dataKey: 'early_checkout_reservations_today',
          listType: 'reservations',
          sections: ['PM', 'CS'],
          title: this.$t('Early Checkout Reservations Today'),
        },
        home_owner_reservations_upcoming_week: {
          dataKey: 'home_owner_reservations_upcoming_week',
          listType: 'reservations',
          sections: ['PM', 'CS'],
          title: this.$t('Home Owner Reservations In The Upcoming Week'),
        },
        all_checkin_reservations_today: {
          dataKey: 'all_checkin_reservations_today',
          listType: 'reservations',
          sections: ['PM', 'CS'],
          title: this.$t('All Checkin Reservations Today'),
        },
        all_checkin_reservations_today_no_deposit: {
          dataKey: 'all_checkin_reservations_today',
          filter: r => Boolean(r.should_pay_deposit),
          listType: 'reservations',
          sections: ['CS'],
          title: this.$t('All Checkin Reservations Today With No Deposit'),
        },
        all_checkin_reservations_today_pool_fence: {
          dataKey: 'all_checkin_reservations_today',
          filter: r => Boolean(get(r, 'special_requests.1 X Pool fence')),
          listType: 'reservations',
          sections: ['PM', 'CS'],
          title: this.$t('All Checkin Reservations Today With Pool Fence'),
        },
        all_checkin_reservations_today_luggage_drop: {
          dataKey: 'all_checkin_reservations_today',
          filter: r => Boolean(r.luggage_drop),
          listType: 'reservations',
          sections: ['PM'],
          title: this.$t('All Checkin Reservations Today With Luggage Drop'),
        },
        all_checkin_reservations_today_source_expedia: {
          dataKey: 'all_checkin_reservations_today',
          filter: r => Boolean(r.source === 'Expedia'),
          listType: 'reservations',
          sections: ['BO'],
          title: this.$t('All Checkin Reservations Today From Expedia'),
        },
        all_checkin_reservations_today_same_day_booking: {
          dataKey: 'all_checkin_reservations_today',
          filter: r => Boolean(r.is_same_day_booking),
          listType: 'reservations',
          sections: ['CS'],
          title: this.$t('All Checkin Reservations Today Same Day Booking'),
        },
        all_checkin_reservations_3days: {
          dataKey: 'all_checkin_reservations_3days',
          listType: 'reservations',
          sections: ['CS'],
          title: this.$t('All Checkin Reservations 72h'),
        },
        reservations_3days_pre_checkin_filled: {
          dataKey: 'reservations_3days_pre_checkin_filled',
          listType: 'reservations',
          sections: ['BO'],
          title: this.$t(
            'All Checkin Reservations 72h - Conrad/KD - Pre Checkin Form - Filled'
          ),
        },
        reservations_today_pre_checkin_filled: {
          dataKey: 'reservations_today_pre_checkin_filled',
          listType: 'reservations',
          sections: ['BO'],
          title: this.$t('All Checkin Today - Pre Checkin Form - Filled'),
        },
        reservations_3days_pre_checkin_not_filled: {
          dataKey: 'reservations_3days_pre_checkin_not_filled',
          listType: 'reservations',
          sections: ['BO'],
          title: this.$t(
            'All Checkin Reservations 72h - Conrad/KD - Pre Checkin Form - Not Filled'
          ),
        },
        reservations_today_pre_checkin_not_filled: {
          dataKey: 'reservations_today_pre_checkin_not_filled',
          listType: 'reservations',
          sections: ['BO'],
          title: this.$t('All Checkin Today - Pre Checkin Form - Not Filled'),
        },
        all_checkout_reservations_today: {
          dataKey: 'all_checkout_reservations_today',
          listType: 'reservations',
          sections: ['PM', 'CS'],
          title: this.$t('All Checkout Reservations Today'),
        },
        instant_booking_reservations_upcoming_week: {
          dataKey: 'instant_booking_reservations_upcoming_week',
          listType: 'reservations',
          sections: ['CS'],
          title: this.$t('Instant Booking Reservations In The Upcoming Week'),
        },
        reservations_longer_then_10_days: {
          dataKey: 'reservations_longer_then_10_days',
          listType: 'reservations',
          sections: ['PM'],
          title: this.$t(
            'Reservations Longer Then 10 Days In The Upcoming 2 Weeks And Currently Staying'
          ),
        },
        reservations_not_paid: {
          dataKey: 'reservations_not_paid',
          sort: (a, b) =>
            a.source.toLowerCase().localeCompare(b.source.toLowerCase()),
          listType: 'reservations',
          sections: ['CS'],
          title: this.$t('All Reservations Not Fully Paid'),
        },
        sc_photo_shoots_upcoming_two_week: {
          dataKey: 'sc_photo_shoots_upcoming_two_week',
          listType: 'service-calls',
          sections: ['PM'],
          title: this.$t(
            'Service Calls In The Upcoming Two Week Related To Photo Shoots'
          ),
        },
        sc_cs_claims: {
          dataKey: 'sc_cs_claims',
          sort: (a, b) => new Date(a.created_at) - new Date(b.created_at),
          listType: 'service-calls',
          sections: ['BO'],
          title: this.$t('All CS Opened Claims Service Calls'),
        },
        sc_today_with_store_item_purchase: {
          dataKey: 'sc_today_with_store_item_purchase',
          listType: 'service-calls',
          sections: ['PM', 'CS'],
          title: this.$t('Service Calls Today With Store Item Purchase'),
        },
        sc_today_related_to_reservation_currently_staying: {
          dataKey: 'sc_today_related_to_reservation_currently_staying',
          listType: 'service-calls',
          sections: ['PM'],
          title: this.$t(
            'Service Calls Today Related To Reservation That Are Currently Staying'
          ),
        },
      },
    }
  },
  mounted() {
    this.updateExcludedFilters([
      'searchTerm',
      'availabilities',
      'project_id',
      'openedBy',
      'listingCatalogItemId',
    ])
    this.$store.commit('taskCalendar/resetFilters')
    this.fetchData()
  },
  beforeDestroy() {
    this.updateExcludedFilters([])
  },
  computed: {
    ...mapGetters('taskCalendar', ['filters']),
    ...mapGetters('users', ['usersMap']),
    ...mapState('taskCalendar', ['excludedFilters', 'forcedFilters']),
    isPMPage() {
      return this.paramsTab === 'pm-summary'
    },
    isGEPage() {
      return this.paramsTab === 'ge-summary' || this.paramsTab === 'summary'
    },
    sections() {
      return this.isPMPage ? ['PM', 'CL'] : this.isGEPage ? ['BO', 'CS'] : []
    },
  },
  methods: {
    ...mapMutations('taskCalendar', ['updateExcludedFilters']),
    fetchData() {
      this.fetchingData = true
      const filters = this.prepareFiltersPayload(this.filters, {
        excludedFilters: this.excludedFilters,
        forcedFilters: this.forcedFilters,
      })
      axios
        .get('/api/daily-summary', {
          params: {
            sections: this.sections,
            ...filters,
            date: this.date,
          },
          cancelToken: autoCancelToken('GetDailySummary'),
        })
        .then(res => this.mapData(res.data))
        .finally(() => {
          this.fetchingData = false
        })
    },
    getListingTasks(listingId) {
      const filters = this.prepareFiltersPayload(this.filters, {
        excludedFilters: this.excludedFilters,
        forcedFilters: this.forcedFilters,
      })
      return axios
        .get('/api/listing-tasks', {
          params: {
            ...filters,
            listing_id: listingId,
            date: this.date,
            per_page: -1,
          },
        })
        .then(res => {
          return res.data
        })
        .finally(() => {
          this.fetchingData = false
        })
    },
    dateChanged(val) {
      this.date = val.toString()
      this.fetchData()
    },
    mapData(resData) {
      Object.keys(this.data).forEach(key => {
        const section = this.data[key]
        section.list = this.sortByListings(resData[section.dataKey] || [])
        if (section.filter) section.list = section.list.filter(section.filter)
        if (section.sort) section.list = section.list.sort(section.sort)
        section.counts = { ...this.countListingType(section.list) }
      })
    },
    countListingType(reservations) {
      let single = 0
      let hotel = 0
      let resort = 0
      reservations.forEach(r => {
        if (!r.listing) return
        if (r.listing.hotel && r.listing.hotel.resort) {
          resort += 1
        } else if (r.listing.hotel) {
          hotel += 1
        } else {
          single += 1
        }
      })
      return { single, hotel, resort }
    },
    sortByListings(reservations) {
      const sortI = r =>
        !r.listing
          ? 0
          : r.listing.hotel && r.listing.hotel.resort
          ? 3
          : r.listing.hotel
          ? 2
          : 1
      return reservations.sort((a, b) => {
        const compare = sortI(a) - sortI(b)
        if (compare === 0) {
          if (a.listing && b.listing) {
            return a.listing.nickname
              .toLowerCase()
              .localeCompare(b.listing.nickname.toLowerCase())
          } else {
            return a.scheduled_at - b.scheduled_at
          }
        } else {
          return compare
        }
      })
    },
    async onListingNameClick(key, index, listingId) {
      this.data[key].list[index].showListingList =
        !this.data[key].list[index].showListingList
      if (!this.data[key].list[index].listingList) {
        const res = await this.getListingTasks(listingId)
        const tasks = res.tasks.filter(
          t => t.id !== this.data[key].list[index].id
        )
        this.data[key].list[index].listingList = tasks
        if (tasks.length === MAX_SC_TASKS_RESULT) {
          Toaster.show([
            {
              type: 'error',
              timeout: 8000,
              text: `Service calls response is limited to ${MAX_SC_TASKS_RESULT} results in order to get
               a better data integrity please specify your filters to be more precisely`,
            },
          ])
        }
      }
      this.$forceUpdate()
    },
    sectionData(section) {
      return pickBy(this.data, item => {
        return item.sections && item.sections.includes(section)
      })
    },
  },
}
</script>

<style lang="scss"></style>
