<template>
  <video
    :id="'example-player' + myId"
    controls
    :autoplay="shouldPlay"
    class="cld-video-player cld-video-player-skin-dark"
  />
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['src', 'width', 'height', 'autoplay'],
  data() {
    return {
      myId: Math.floor(Math.random() * 100000) + 1,
    }
  },
  computed: {
    shouldPlay() {
      return this.autoplay !== 'undefined' ? this.autoplay : true
    },
  },
  watch: {},
  mounted() {
    var cld = window.cloudinary.Cloudinary.new({ cloud_name: 'Designedvr' })
    var demoplayer = cld
      .videoPlayer('example-player' + this.myId, {
        transformation: { crop: 'limit', width: 300 },
      })
      .width(this.width || 500)
      .height(this.height || 500)

    demoplayer.source(this.src)
  },
  methods: {},
}
</script>
