<template>
  <v-row no-gutters class="align-start">
    <v-col cols="auto">
      <UserAvatar :image="log.user_pic" :name="log.user" :role="log.role" />
    </v-col>
    <v-col class="flex d-flex flex-column px-3 pb-3">
      <div>
        <span class="text-subtitle-2 font-weight-medium">{{ log.user }}</span>
        <span class="ml-3 text-caption secondary--text">{{
          timeAgo(time)
        }}</span>
        <v-icon
          v-if="log.log_level"
          small
          class="rounded-circle white--text"
          :class="logLevelClass"
          >star</v-icon
        >
      </div>
      <div class="text-body-2 secondary--text" v-html="log.msg"></div>
    </v-col>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import UserAvatar from 'components/common/user-avatar'
export default {
  name: 'LtComment',
  components: { UserAvatar },
  mixins: [CommonFunctions],
  props: ['time', 'log'],
  computed: {
    logLevelClass() {
      const logLevel = this.log.log_level
      if (logLevel === 'low') {
        return 'yellow lighten-1'
      }
      if (logLevel === 'medium') {
        return 'orange lighten-1'
      }
      if (logLevel === 'high') {
        return 'warning lighten-1'
      }
      return ''
    },
  },
}
</script>

<style scoped></style>
