<template>
  <v-card>
    <v-card-title>{{
      isEditMode ? 'Edit Account' : 'Add New Account'
    }}</v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="formData.account_name"
          label="Account Name"
          :readonly="formData.source !== 'INTERNAL'"
        />
        <v-text-field
          v-if="isEditMode"
          v-model="formData.account_number"
          label="Account Number"
          :readonly="formData.source === 'XERO'"
        />
        <v-text-field
          v-if="isEditMode"
          v-model="formData.routing_number"
          label="Routing Number"
        />
        <multi-property-picker
          v-if="isEditMode"
          :disabled="disableListingsPicker"
          class="input-auto"
          :value="formData.listings?.map(l => l.id)"
          :label="$t('Connected Listings')"
          :hide-btns="true"
          @input="onListingsChange"
        />
        <multi-property-picker
          v-if="isEditMode"
          :disabled="disableOwnerListingsPicker"
          class="input-auto"
          :value="formData.owner_listings?.map(l => l.id)"
          :label="$t('Connected Owner Listings')"
          :hide-btns="true"
          @input="onOwnerListingsChange"
        />
        <v-text-field
          v-if="isEditMode && formData.code"
          class="input-auto"
          :value="formData.code"
          dense
          flat
          hide-details
          :readonly="item.source === 'XERO'"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="submit">{{
        isEditMode ? 'Save' : 'Add'
      }}</v-btn>
      <v-btn @click="onClose">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import MultiPropertyPicker from 'components/multi-property-picker'
import CommonFunctions from 'components/mixins/common_functions'
import FormattersMixin from 'components/mixins/formatters-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapActions } from 'vuex'

export default {
  name: 'BankAccountModal',
  components: { MultiPropertyPicker },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: {
    item: Object,
    isEditMode: {
      type: Boolean,
      default: false,
    },
    afterSaveFunc: Function,
  },
  data() {
    return {
      formData: {},
      newBankAccount: {
        account_number: '',
        routing_number: '',
        account_name: 'NEW BANK ACCOUNT',
        code: '',
        source: 'INTERNAL',
      },
    }
  },
  computed: {
    disableListingsPicker() {
      return !this.isEmpty(this.formData.owner_listings?.map(l => l.id))
    },
    disableOwnerListingsPicker() {
      return !this.isEmpty(this.formData.listings?.map(l => l.id))
    },
  },
  mounted() {
    this.formData = this.isEditMode ? this.item : this.newBankAccount
  },
  methods: {
    ...mapActions('bankAccounts', [
      'updateBankAccount',
      'createNewBankAccount',
    ]),
    submit() {
      if (this.$refs.form.validate()) {
        const action = this.isEditMode ? this.updateAccount : this.saveAccount
        action(this.formData).then(() => {
          if (typeof this.afterSaveFunc === 'function') {
            this.afterSaveFunc(this.formData)
          }
          this.onClose()
        })
      }
    },
    saveAccount(accountData) {
      return this.createNewBankAccount(accountData)
    },
    updateAccount(accountData) {
      return this.updateBankAccount(accountData)
    },
    onListingsChange(listingIds) {
      this.formData.listings = listingIds.map(id => ({ id }))
      this.formData.listing_ids = listingIds
    },
    onOwnerListingsChange(ownerListingIds) {
      this.formData.owner_listings = ownerListingIds.map(id => ({ id }))
      this.formData.owner_listing_ids = ownerListingIds
    },
    onClose() {
      this.$store.commit('hideModal')
    },
  },
}
</script>

<style scoped></style>
