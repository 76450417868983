var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.reservation)?_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"d-flex text-subtitle-2"},[(_vm.reservation.confirmation_code && !_vm.pmMode)?_c('div',{staticClass:"mr-10"},[_vm._v(" "+_vm._s(_vm.reservation.confirmation_code)+" ")]):_vm._e(),(_vm.reservation.listing)?_c('div',[_vm._v(" "+_vm._s(_vm.reservation.listing.nickname)+" ")]):_vm._e()]),_c('div',{class:`d-flex text-body-2 align-center flex-wrap ${
      _vm.popUp ? 'flex-container' : ''
    }`},[(!_vm.pmMode)?_c('div',{class:`mb-2 ${_vm.popUp ? 'flex-item' : 'mr-10'}`},[_vm._v(" Source: "),_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.reservation.source))])]):_vm._e(),_c('div',{class:`mb-2 ${_vm.popUp ? 'flex-item' : 'mr-10'}`},[_vm._v(" Nights count: "),_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.reservation.nights_count)+" ")])]),_c('div',{class:`mb-2 ${_vm.popUp ? 'flex-item' : 'mr-10'}`},[_vm._v(" Checkin at: "),_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.parseDateTZ(_vm.reservation.check_in, 'YYYY-MM-DD', _vm.timezone))+" ")])]),_c('div',{class:`mb-2 ${_vm.popUp ? 'flex-item' : 'mr-10'}`},[_vm._v(" Checkout at: "),_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.parseDateTZ(_vm.reservation.check_out, 'YYYY-MM-DD', _vm.timezone))+" ")])]),(!_vm.pmMode)?_c('div',{class:`mb-2 ${_vm.popUp ? 'flex-item' : 'mr-10'}`},[_vm._v(" Status: "),_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.reservation.status))])]):_vm._e(),(
        _vm.reservation.planned_arrival && _vm.isSameDay(_vm.reservation.check_in, _vm.date)
      )?_c('Tag',{staticClass:"mb-2 mr-2 d-inline-block text-no-wrap",attrs:{"bg-color":_vm.checkInType === 'ECI' ? 'warning' : 'success',"color":"white"}},[_vm._v(" "+_vm._s(_vm.checkInType)+"-"+_vm._s(_vm.reservation.planned_arrival.split(':')[0])+" ")]):_vm._e(),(
        _vm.reservation.planned_departure &&
        _vm.isSameDay(_vm.reservation.check_out, _vm.date)
      )?_c('Tag',{staticClass:"mb-2 mr-2 d-inline-block text-no-wrap",attrs:{"bg-color":_vm.checkOutType === 'LCO' ? 'warning' : 'success',"color":"white"}},[_vm._v(" "+_vm._s(_vm.checkOutType)+"-"+_vm._s(_vm.reservation.planned_departure.split(':')[0])+" ")]):_vm._e(),_c('Tag',{staticClass:"mb-2 mr-2 d-inline-block text-no-wrap",attrs:{"bg-color":_vm.reservation.should_pay_deposit ? 'warning' : 'success',"color":"white"}},[_vm._v(" "+_vm._s(_vm.reservation.should_pay_deposit ? 'Deposit Not paid' : 'Deposit Paid')+" ")]),(_vm.reservation.conversation && _vm.popUp)?_c('div',{class:`${_vm.popUp && !_vm.reservation.conversation ? 'flex-hidden' : ''}`},[_c('v-btn',{staticClass:"mb-2 mr-2",attrs:{"target":"_blank","icon":"","color":"info","to":{
          name: 'guests-chat',
          params: {
            id:
              _vm.reservation.conversation && _vm.reservation.conversation.guesty_id,
          },
        }}},[_c('v-icon',[_vm._v("$chat")])],1)],1):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }