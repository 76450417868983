<template>
  <svg
    id="Слой_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="-380.2 274.7 65.7 65.8"
  >
    <g>
      <defs>
        <path
          id="SVGID_1_"
          d="M-326.3 303.3h-20.5v8.5h11.8c-1.1 5.4-5.7 8.5-11.8 8.5-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4c-3.9-3.4-8.9-5.5-14.5-5.5-12.2 0-22 9.8-22 22s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
        />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlink:href="#SVGID_1_" overflow="visible" />
      </clipPath>
      <path class="st2" d="M-370.8 320.3v-26l17 13z" />
      <defs>
        <path
          id="SVGID_3_"
          d="M-326.3 303.3h-20.5v8.5h11.8c-1.1 5.4-5.7 8.5-11.8 8.5-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4c-3.9-3.4-8.9-5.5-14.5-5.5-12.2 0-22 9.8-22 22s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
        />
      </defs>
      <clipPath id="SVGID_4_">
        <use xlink:href="#SVGID_3_" overflow="visible" />
      </clipPath>
      <path class="st3" d="M-370.8 294.3l17 13 7-6.1 24-3.9v-14h-48z" />
      <g>
        <defs>
          <path
            id="SVGID_5_"
            d="M-326.3 303.3h-20.5v8.5h11.8c-1.1 5.4-5.7 8.5-11.8 8.5-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4c-3.9-3.4-8.9-5.5-14.5-5.5-12.2 0-22 9.8-22 22s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
          />
        </defs>
        <clipPath id="SVGID_6_">
          <use xlink:href="#SVGID_5_" overflow="visible" />
        </clipPath>
        <path class="st4" d="M-370.8 320.3l30-23 7.9 1 10.1-15v48h-48z" />
      </g>
      <g>
        <defs>
          <path
            id="SVGID_7_"
            d="M-326.3 303.3h-20.5v8.5h11.8c-1.1 5.4-5.7 8.5-11.8 8.5-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4c-3.9-3.4-8.9-5.5-14.5-5.5-12.2 0-22 9.8-22 22s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
          />
        </defs>
        <clipPath id="SVGID_8_">
          <use xlink:href="#SVGID_7_" overflow="visible" />
        </clipPath>
        <path class="st5" d="M-322.8 331.3l-31-24-4-3 35-10z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Google',
}
</script>

<style scoped>
.st0 {
  fill: #e0e0e0;
}
.st1 {
  fill: #fff;
}
.st2 {
  clip-path: url(#SVGID_2_);
  fill: #fbbc05;
}
.st3 {
  clip-path: url(#SVGID_4_);
  fill: #ea4335;
}
.st4 {
  clip-path: url(#SVGID_6_);
  fill: #34a853;
}
.st5 {
  clip-path: url(#SVGID_8_);
  fill: #4285f4;
}
</style>
