<template>
  <v-layout wrap align-center>
    <v-row class="align-center">
      <v-col cols="6" sm="4" lg="2">
        <v-text-field
          v-model="filters.search_term"
          label="Search"
          prepend-inner-icon="search"
          outlined
          dense
          hide-details
          @input="debouncedFetchCouponsRules"
        />
      </v-col>
    </v-row>

    <v-flex xs12 class="right-text">
      <v-btn
        color="primary"
        elevation="0"
        small
        outlined
        @click.stop="openModalRule"
      >
        <v-icon>add</v-icon>
        <span class="text-capitalize ml-1">Add Rule</span>
      </v-btn>

      <v-dialog v-model="dialog" max-width="600">
        <v-card>
          <v-card-title>New Rule</v-card-title>

          <v-form ref="formRule" @submit.prevent="submitFormRule">
            <v-card-text>
              <v-text-field
                v-model="formData.description"
                dense
                outlined
                :rules="[val => !!val || 'This field is required']"
                :label="$t('Description')"
                hint="Example: 5% Discount"
              />

              <v-select
                v-model="formData.discount_type"
                label="Type"
                outlined
                :items="[
                  { name: 'Percentage', value: 'percentage' },
                  { name: 'Fixed amount', value: 'fixed_amount' },
                ]"
                item-text="name"
                item-value="value"
                :rules="[val => !!val || 'This field is required']"
                dense
              />

              <v-text-field
                v-model="formData.discount_value"
                :label="$t('Discount')"
                type="number"
                outlined
                :rules="positiveNumberRule"
                :min="1"
                hint="Enter a positive number"
              ></v-text-field>

              <v-text-field
                v-model="formData.min_stay_nights"
                :label="$t('Min nights')"
                type="number"
                outlined
                :rules="positiveNumberRule"
                :min="1"
                hint="Enter a positive number"
              ></v-text-field>

              <multi-property-picker
                v-if="!listingLoading"
                v-model="formData.applies_to"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="secondary"
                class="text-capitalize"
                text
                @click="closeModalRule"
              >
                Cancel
              </v-btn>

              <v-btn
                color="primary"
                class="text-capitalize"
                elevation="0"
                type="submit"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-flex>

    <v-flex xs12 class="right-text">
      <v-dialog v-model="dialogNewCoupon" max-width="600">
        <v-card>
          <v-card-title>
            <v-row class="align-center">
              <v-col> New coupon </v-col>

              <v-col class="text-right">
                <v-btn
                  color="primary"
                  elevation="0"
                  small
                  outlined
                  @click.stop="generateCouponCode"
                >
                  <span class="text-capitalize ml-1">Generate Code</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-form ref="formNewCoupon" @submit.prevent="submitFormNewCoupon">
            <v-card-text>
              <v-text-field
                v-model="formDataCoupon.code"
                dense
                outlined
                :rules="[val => !!val || 'This field is required']"
                :label="$t('Code')"
              />

              <v-row>
                <date-picker
                  label="From"
                  :value="parseDate(formDataCoupon.valid_from)"
                  :reactive="true"
                  :min="new Date().toISOString().substr(0, 10)"
                  :max="
                    new Date(new Date().setMonth(new Date().getMonth() + 10))
                      .toISOString()
                      .substr(0, 10)
                  "
                  :required="true"
                  :dense="true"
                  :hide-details="true"
                  :flat="true"
                  :solo="true"
                  :outlined="false"
                  text-field-class="text-caption"
                  @change="setDateFrom($event)"
                >
                  <template #prepend-inner>
                    <v-icon small color="info">fas fa-calendar-alt</v-icon>
                  </template>
                </date-picker>

                <date-picker
                  label="Until"
                  :value="parseDate(formDataCoupon.valid_until)"
                  :reactive="true"
                  :min="new Date().toISOString().substr(0, 10)"
                  :max="
                    new Date(new Date().setMonth(new Date().getMonth() + 10))
                      .toISOString()
                      .substr(0, 10)
                  "
                  :required="true"
                  :dense="true"
                  :hide-details="true"
                  :flat="true"
                  :solo="true"
                  :outlined="false"
                  text-field-class="text-caption"
                  @change="setDateTo($event)"
                >
                  <template #prepend-inner>
                    <v-icon small color="info">fas fa-calendar-alt</v-icon>
                  </template>
                </date-picker>
              </v-row>

              <v-checkbox
                v-model="formDataCoupon.one_time_use"
                label="One time use"
                class="checkbox-txt"
              ></v-checkbox>

              <v-text-field
                v-if="!formDataCoupon.one_time_use"
                v-model="formDataCoupon.usage_limit"
                label="Limit"
                type="number"
                outlined
                :rules="positiveNumberRule"
                :min="0"
                hint="Enter a positive number"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="secondary"
                class="text-capitalize"
                text
                @click="changeNewCouponModal"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                class="text-capitalize"
                elevation="0"
                @click="submitFormNewCoupon"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-flex>

    <v-flex xs12 mt-3>
      <v-data-table
        v-if="items"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="pagination.totalItems"
        class="elevation-1"
        @pagination="paginationChanged($event)"
      >
        <template #item="{ item }">
          <tr>
            <td>
              <span v-if="item.description">{{ item.description }}</span>
              <span v-else>Not specified</span>
            </td>
            <td>
              <span v-if="item.discount_value">
                <span v-if="item.discount_type">{{
                  item.discount_type == 'percentage' ? '%' : currencySign
                }}</span>
                {{ item.discount_value }}</span
              >
              <span v-else>Not specified</span>
            </td>
            <td>
              <span v-if="item.min_stay_nights">{{
                item.min_stay_nights
              }}</span>
              <span v-else>Not specified</span>
            </td>
            <td>
              <div
                v-if="item.applies_to"
                style="width: 150px; max-height: 150px"
                class="overflow-auto"
              >
                <span>
                  <span
                    v-for="(item, index) in item.applies_to.split(',')"
                    :key="index"
                  >
                    <router-link
                      style="text-decoration: none"
                      class="ml-1 mr-1 purple-icon"
                      target="_blank"
                      :to="{ path: `/dashboard/property/${item}/stats` }"
                    >
                      {{ item }}
                    </router-link>
                  </span>
                </span>
              </div>

              <span v-else>ALL</span>
            </td>
            <td>
              <span v-if="item.discount_codes_count">{{
                item.discount_codes_count
              }}</span>
              <span v-else>0</span>
            </td>
            <td>
              <span v-if="item.discount_codes_used_count">{{
                item.discount_codes_used_count
              }}</span>
              <span v-else>0</span>
            </td>
            <td>
              <v-btn icon class="mr-2" @click.stop="updateCouponRule(item)">
                <v-icon small>edit</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                color="primary"
                elevation="0"
                small
                outlined
                @click.stop="changeNewCouponModal(item.id)"
              >
                <v-icon>add</v-icon>
                <span class="text-capitalize ml-1">Add Coupon</span>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import DatePicker from 'components/form-fields/date-picker.vue'
import MultiPropertyPicker from './multi-property-picker.vue'
import { nanoid } from 'nanoid'

export default {
  components: {
    DatePicker,
    MultiPropertyPicker,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: [],
  data: () => ({
    oncePerCustomerOnly: null,
    priceRules: [],
    modalType: '',
    formDataCoupon: {
      price_rule_id: null,
      code: '',
      valid_from: null,
      valid_until: null,
      one_time_use: false,
    },
    formData: {
      description: '',
      min_stay_nights: 1,
      discount_type: null,
      discount_value: null,
      applies_to: [],
    },
    pagination: {
      itemsPerPage: 10,
    },
    dialog: false,
    dialogNewCoupon: false,
    filters: {
      search_term: '',
    },
    editDialog: false,
    headers: [
      { text: 'Description', value: 'description' },
      { text: 'Discount', sortable: false },
      { text: 'Min nights', sortable: false },
      { text: 'Property ids', sortable: false },
      { text: 'Issued', sortable: false },
      { text: 'Used', sortable: false },
      { text: 'Action', sortable: false },
      { text: 'Create coupon', sortable: false },
    ],
  }),
  computed: {
    items() {
      return this.$store.state.couponsRules
    },
    positiveNumberRule() {
      return [
        value => !!value || 'Value is required',
        value =>
          /^[0-9]+(\.[0-9]+)?$/.test(value) ||
          'Value must be a positive number',
      ]
    },
  },
  watch: {
    '$store.state.couponsRulesPagination.count': {
      immediate: true,
      handler(newTotalCount) {
        this.pagination.totalItems = newTotalCount
      },
    },
  },
  methods: {
    fetchCouponsRules() {
      this.$store.dispatch('getCouponsRules', {
        ...this.filters,
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
      })
    },
    debouncedFetchCouponsRules() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.fetchCouponsRules()
      }, 300)
    },
    openModalRule(type, rule = null) {
      this.modalType = type
      if (type === 'edit') {
        this.formData = { ...rule }
      } else {
        this.formData = {}
      }
      this.dialog = true
    },
    clearFromData() {
      this.formData = {
        description: '',
        min_stay_nights: 1,
        discount_type: null,
        discount_value: null,
        applies_to: [],
      }
    },
    closeModalRule() {
      this.dialog = false
      this.$refs.form.resetValidation()
      this.clearFromData()
    },
    changeNewCouponModal(ruleId = null) {
      this.formDataCoupon.price_rule_id = ruleId
      this.dialogNewCoupon = !this.dialogNewCoupon
    },
    async submitFormRule() {
      if (this.$refs.formRule.validate()) {
        this.$store.dispatch('createCouponRule', this.formData)
        this.closeModalRule()
      }
    },
    updateCouponRule(item) {
      this.$store.commit('showModal', {
        name: 'CouponRuleModal',
        props: {
          editMode: true,
          afterSaveFunc: this.afterChange,
          priceRule: item,
        },
      })
    },
    submitFormNewCoupon() {
      if (this.$refs.formNewCoupon.validate()) {
        this.$store.dispatch('createCoupon', this.formDataCoupon)
        this.formDataCoupon = {
          price_rule_id: null,
          code: '',
          valid_from: null,
          valid_until: null,
          one_time_use: false,
        }
        this.dialogNewCoupon = false
      }
    },
    generateCouponCode() {
      this.formDataCoupon.code = nanoid()
    },
    setDateFrom(value) {
      this.formDataCoupon.valid_from = value
    },
    setDateTo(value) {
      this.formDataCoupon.valid_until = value
    },
    paginationChanged() {
      this.fetchCouponsRules()
    },
  },
}
</script>
