<template>
  <v-autocomplete
    v-model="selectedListings"
    :items="listings"
    :clearable="!readonly"
    :label="$t(label) || $t('Select Listings')"
    item-text="nickname"
    item-value="id"
    multiple
    :disabled="disabled"
    :hide-details="typeof hideDetails === 'undefined' ? false : hideDetails"
    :search-input.sync="search"
    autocomplete="off"
    dense
    :rules="rules"
    outlined
    :readonly="readonly"
    :menu-props="closeOnContentClick ? { closeOnContentClick } : {}"
    @input="search = ''"
    @change="onChange"
  >
    <template v-if="!hideBtns" #prepend-item>
      <div v-if="!someListingsSelected" class="d-flex flex-wrap px-2">
        <v-btn
          color="grey lighten-3"
          class="text-capitalize mb-2 mr-2"
          elevation="0"
          @click="selectAll"
        >
          Select all
        </v-btn>
        <v-btn
          color="grey lighten-3"
          class="text-capitalize mb-2 mr-2"
          elevation="0"
          @click="selectJustUnits"
        >
          Select units
        </v-btn>
        <v-btn
          color="grey lighten-3"
          class="text-capitalize"
          elevation="0"
          @click="selectJustHouses"
        >
          Select houses
        </v-btn>
      </div>
      <v-btn
        v-else
        color="grey lighten-3"
        class="text-capitalize mx-2"
        elevation="0"
        @click="UnSelectAll"
      >
        Unselect all
      </v-btn>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template #selection="{ selected, item, parent }">
      <v-chip
        :key="item.id"
        :input-value="selected"
        :close="!readonly && !disabled"
        small
        class="chip--select-multi ma-1 pl-0"
        @click:close="remove(item.id)"
        @input="parent.selectItem(item)"
      >
        <v-avatar>
          <img :src="item.picture" />
        </v-avatar>
        <span class="ml-2">{{ item.nickname }}</span>
      </v-chip>
    </template>
    <template #item="{ item }">
      <template>
        <v-list-item-avatar>
          <img :src="item.picture" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="multi-picker"
            v-html="item.nickname"
          ></v-list-item-title>
          <v-list-item-subtitle
            v-html="item.listing_type"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapGetters } from 'vuex'
import { isArray } from 'lodash'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: [
    'label',
    'filterChange',
    'hideBtns',
    'value',
    'rules',
    'hideDetails',
    'filterListingTypes',
    'readonly',
    'disabled',
    'closeOnContentClick',
    'filterListingIds',
    'customListings',
  ],
  data() {
    return {
      selectedListings: [],
      search: null,
    }
  },
  mounted() {
    this.setSelectedListings(this.value)
    // if (!this.value || this.value.length !== this.selectedListings.length) {
    //   this.onChange(this.selectedListings)
    // }
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    icon() {
      if (this.allListingsSelected) return 'mdi-close-box'
      if (!this.someListingsSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    allListingsSelected() {
      return this.listings.length === this.selectedListings.length
    },
    someListingsSelected() {
      return this.listings.length > 0 && this.selectedListings.length > 0
    },
    listings() {
      let filtered = this.customListings || this.listingsPicker || []
      if (this.filterListingTypes) {
        filtered = filtered.filter(
          l => !this.filterListingTypes.includes(l.listing_type)
        )
      }
      if (this.filterListingIds) {
        filtered = filtered.filter(l => this.filterListingIds.includes(l.id))
      }
      return filtered
    },
    listingsIdsSet() {
      return new Set(this.listings.map(l => l.id))
    },
  },
  methods: {
    setSelectedListings(ids) {
      let tmparr = ids
      if (!isArray(ids)) {
        tmparr = []
        tmparr.push(ids)
      }
      this.selectedListings = tmparr.filter(id => this.listingsIdsSet.has(id))
    },
    onChange(filters) {
      this.$emit('input', filters)
      this.filterChange && this.filterChange(filters)
    },
    selectAll() {
      this.setSelectedListings([...this.listingsIdsSet])
      this.onChange(this.selectedListings)
    },
    remove(item) {
      const index = this.selectedListings.indexOf(item)
      if (index >= 0) {
        this.selectedListings.splice(index, 1)
      }
      this.onChange(this.selectedListings)
    },
    selectJustUnits() {
      const selectedListings = this.listings
        .filter(l => l.listing_type === 'unit')
        .map(l => l.id)
      this.setSelectedListings(selectedListings)
      this.onChange(this.selectedListings)
    },
    selectJustHouses() {
      const selectedListings = this.listings
        .filter(
          l =>
            l.listing_type !== 'unit' &&
            l.listing_type !== 'hotel' &&
            l.beds &&
            l.beds > 2
        )
        .map(l => l.id)
      this.setSelectedListings(selectedListings)
      this.onChange(this.selectedListings)
    },
    UnSelectAll() {
      this.setSelectedListings([])
      this.onChange(this.selectedListings)
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allListingsSelected) {
          this.setSelectedListings([])
        } else {
          this.setSelectedListings([...this.listingsIdsSet])
        }
        this.onChange(this.selectedListings)
      })
    },
  },
  watch: {
    value(val) {
      this.setSelectedListings(val)
    },
    listingsPicker() {
      this.setSelectedListings(this.value)
    },
  },
}
</script>
