<template>
  <v-card>
    <v-form ref="form" @submit="rejectTask">
      <v-card-title> {{ status }} Task</v-card-title>
      <v-card-text>
        <v-radio-group
          v-if="isRejectedStatus"
          v-model="topic"
          class="pb-2 ma-0"
          hide-details
          row
        >
          <v-radio label="Administrative" value="administrative" />
          <v-radio label="Professional" value="professional" />
        </v-radio-group>
        <v-textarea
          v-model="reason"
          :label="`${status} reason`"
          :rules="[required]"
          outlined
          rows="4"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn small color="primary" outlined @click="cancel">Cancel</v-btn>
        <v-btn small color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import { TASK_STATUSES } from '@/consts'

export default {
  name: 'RejectModal',
  mixins: [formRules],
  props: ['listingTaskId', 'status', 'closeCallback', 'onChange'],
  data() {
    return {
      reason: null,
      topic: 'administrative',
    }
  },
  computed: {
    isRejectedStatus() {
      return this.status === TASK_STATUSES.REJECTED
    },
  },
  methods: {
    cancel() {
      this.onChange && this.onChange(this.status)
      this.closeCallback && this.closeCallback()
      this.closeModal()
    },
    closeModal() {
      this.$store.commit('hideModal')
    },
    async rejectTask(e) {
      const { reason, topic, status } = this
      e.preventDefault()
      if (this.$refs.form.validate()) {
        this.onChange && this.onChange(status)
        await this.$store.dispatch('rejectTask', {
          id: this.listingTaskId,
          status,
          reason,
          topic,
        })
        this.closeCallback && this.closeCallback()
        this.closeModal()
      }
    },
  },
}
</script>

<style scoped></style>
