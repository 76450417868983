<template>
  <v-stepper-content :step="step">
    <div class="text-h6">Add Media</div>
    <gallery
      folder="service-calls"
      :cols="4"
      :thumbnails="true"
      :listing-id="currentListingTask.listing_id"
      :images="currentListingTask.after_images"
      :remove-img="removeAfterImg"
      :rotate-image="rotateAfterImg"
      :on-change="addAfterImg"
      :uploadable="true"
    />
  </v-stepper-content>
</template>

<script>
import Gallery from 'components/common/gallery.vue'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'

export default {
  name: 'MediaStep',
  mixins: [ListingTaskViewMixin],
  components: { Gallery },
  props: ['step'],
  methods: {
    removeAfterImg(link) {
      const after_images = this.currentListingTask.after_images.filter(
        img => img !== link
      )
      this.updateTask({ after_images })
    },
    rotateAfterImg(oldLink) {
      return newLink => {
        const after_images = this.currentListingTask.after_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ after_images })
      }
    },
    addAfterImg(imgs) {
      this.updateTask({
        after_images: this.currentListingTask.after_images.concat(imgs),
      })
      this.sendImagesUpdatedNotification()
    },
  },
}
</script>

<style scoped></style>
