<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.105"
    height="22.094"
    viewBox="0 0 18.105 22.094"
  >
    <g transform="translate(0)">
      <path
        class="a"
        d="M177.734,9.246a4.626,4.626,0,1,1-9.251,0c0-2.555,4.626-9.238,4.626-9.238S177.734,6.691,177.734,9.246Zm0,0"
        transform="translate(-159.629 -0.007)"
      />
      <path
        class="b"
        d="M243.841,0s-.279.4-.7,1.05c1.293,1.984,3.926,6.261,3.926,8.189a4.627,4.627,0,0,1-3.926,4.573,4.629,4.629,0,0,0,5.326-4.573C248.466,6.683,243.841,0,243.841,0Zm0,0"
        transform="translate(-230.361)"
      />
      <path
        class="b"
        d="M170.906,66.6c-1.187,2.025-2.406,4.424-2.406,5.736a4.625,4.625,0,0,0,7.239,3.816C174.941,73.38,172.658,69.411,170.906,66.6Zm0,0"
        transform="translate(-159.645 -63.1)"
      />
      <path
        class="c"
        d="M14.763,16.867a7.333,7.333,0,1,1-14.665,0C.1,12.817,7.43,2.223,7.43,2.223S14.763,12.816,14.763,16.867Zm0,0"
        transform="translate(-0.098 -2.106)"
      />
      <g transform="translate(3.737 0.117)">
        <path
          class="d"
          d="M79.391,187.156l1.015,1.015-8.219,8.219-1.015-1.015Zm0,0"
          transform="translate(-71.172 -177.432)"
        />
        <path
          class="d"
          d="M82.718,189.1a1.426,1.426,0,1,1-2.016,0A1.426,1.426,0,0,1,82.718,189.1Zm0,0"
          transform="translate(-79.805 -178.875)"
        />
        <path
          class="d"
          d="M185.874,307.436a1.426,1.426,0,1,1-2.016,0A1.426,1.426,0,0,1,185.874,307.436Zm0,0"
          transform="translate(-177.537 -290.992)"
        />
        <path
          class="d"
          d="M120.947,2.223s-.409.591-1.033,1.545c2.019,3.092,6.3,10.005,6.3,13.1a7.333,7.333,0,0,1-6.3,7.259,7.328,7.328,0,0,0,8.365-7.259C128.279,12.816,120.947,2.223,120.947,2.223Zm0,0"
          transform="translate(-117.351 -2.223)"
        />
      </g>
      <path
        class="a"
        d="M59.965,187.156l1.015,1.015-8.219,8.219-1.015-1.015Zm0,0"
        transform="translate(-49.03 -177.315)"
      />
      <path
        class="a"
        d="M63.292,189.1a1.426,1.426,0,1,1-2.016,0A1.426,1.426,0,0,1,63.292,189.1Zm0,0"
        transform="translate(-57.664 -178.758)"
      />
      <path
        class="a"
        d="M166.449,307.436a1.426,1.426,0,1,1-2.016,0A1.426,1.426,0,0,1,166.449,307.436Zm0,0"
        transform="translate(-155.396 -290.875)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Humidity',
}
</script>

<style scoped>
.a {
  fill: #d9ecf2;
}

.b {
  fill: #bdd9e5;
}

.c {
  fill: #26a3b9;
}

.d {
  fill: #168aa5;
}
</style>
