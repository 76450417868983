<template>
  <v-sheet width="100">
    <v-card
      elevation="0"
      width="100"
      height="100"
      class="rounded fill-height align-center justify-center d-flex upload-box p-relative"
      @mouseover="hoverPic"
      @mouseout="outPic"
      @click="pickFile"
    >
      <v-icon color="info" class="p-absolute">upload</v-icon>
      <img width="100%" height="100%" class="rounded" :src="value" />
      <v-file-input
        ref="file"
        v-model="files"
        type="file"
        style="display: none"
        accept="image/png,image/jpeg,image/jpg,image/webp"
        @change="readURL"
      />
    </v-card>
    <v-progress-linear
      v-if="filesLoading"
      v-model="imageProgress"
      class="my-2 rounded"
      width="100"
      color="info"
      height="20"
    >
      <template #default="{ value: v }">
        <strong>{{ Math.ceil(v) }}%</strong>
      </template>
    </v-progress-linear>
  </v-sheet>
</template>

<script>
import axios from 'axios'
import Toaster from '@/utils/toaster'
export default {
  name: 'ProfileUpload',
  props: ['value', 'limitation'],
  data() {
    return {
      files: [],
      picHovered: false,
      filesLoading: false,
      imageProgress: 0,
    }
  },
  methods: {
    hoverPic() {
      this.picHovered = true
    },
    outPic() {
      this.picHovered = false
    },
    async readURL() {
      const formData = new FormData()
      formData.append('upload_preset', 'original')
      const isValid = this.limitation ? await this.limitation(this.files) : true
      if (!isValid) {
        Toaster.show([
          {
            type: 'error',
            text: 'Image must be at least in resolution of 1024x768',
          },
        ])
        return
      }
      this.filesLoading = true
      formData.append('file', this.files)
      return axios
        .post('https://api.cloudinary.com/v1_1/do4tedxg6/upload', formData, {
          withCredentials: false,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / this.files.size
            )
            this.imageProgress = progress > 100 ? 100 : progress
          },
        })
        .then(result => {
          const urls = result.data.secure_url
          this.$emit('input', urls)
          this.$emit('on-upload', urls)
        })
        .catch(err => (this.error = err))
        .then(() => {
          this.filesLoading = false
        })
    },
    pickFile() {
      this.$refs.file.$refs.input.click()
    },
  },
}
</script>

<style scoped>
.upload-box {
  border: 1px dashed var(--v-info-base);
  position: relative;
  cursor: pointer;
}
</style>
