<template>
  <div v-if="statsObject">
    <canvas v-if="mini" :id="graphId" ref="graphEl"></canvas>
    <v-card v-else>
      <v-card-title class="text-caption secondary--text">{{
        title
      }}</v-card-title>
      <v-divider />
      <v-card-text :class="graphId">
        <v-row class="justify-space-between align-center">
          <v-col cols="6" sm="5" lg="4">
            <canvas :id="graphId" ref="graphEl"></canvas>
          </v-col>
          <v-col cols="6" sm="7">
            <div
              v-for="(item, key) in legend"
              :key="key"
              class="d-flex align-center"
            >
              <v-icon class="mr-2" :color="item" size="x-small"
                >mdi-circle</v-icon
              >
              <span>{{ key }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import $ from 'cash-dom'
import map from 'lodash/fp/map'
import compose from 'lodash/fp/compose'
import toNumber from 'lodash/fp/toNumber'
import defaultTo from 'lodash/fp/defaultTo'
import isEqual from 'lodash/fp/isEqual'
import isEmpty from 'lodash/fp/isEmpty'
import zipObject from 'lodash/fp/zipObject'

const countsIn = compose(map(x => Math.round(toNumber(x.count))))

const colorsIn = compose(defaultTo('gray'), map('color'))

export default {
  props: ['statsObject', 'title', 'graphId', 'graphType', 'mini'],
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    titles() {
      return Object.keys(this.statsObject)
    },
    legend() {
      return zipObject(this.titles, colorsIn(this.statsObject))
    },
  },
  watch: {
    statsObject(newVal, oldVal) {
      const { chart } = this
      if (chart && !isEqual(newVal, oldVal)) {
        chart.config.data = this.generateChartData(newVal)
        chart.update()
      }
    },
  },
  mounted() {
    this.drawChart()
  },
  methods: {
    canvas() {
      return $(this.$refs.graphEl)
    },
    drawChart: function () {
      const { graphType, statsObject } = this
      const ctx = this.canvas().get(0).getContext('2d')
      setTimeout(() => {
        const chart = window.Chart
        this.chart = new chart(ctx, {
          type: graphType || 'doughnut',
          data: this.generateChartData(statsObject),
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              backgroundColor: '#ffffff',
              bodyFontColor: '#000000',
            },
          },
        })
      }, 200)
    },
    generateChartData(statsObject) {
      return {
        labels: Object.keys(statsObject),
        datasets: [
          {
            data: countsIn(statsObject),
            backgroundColor: colorsIn(statsObject),
          },
        ],
      }
    },
    hasData() {
      return !isEmpty(this.statsObject)
    },
  },
}
</script>
