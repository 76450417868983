<template>
  <v-dialog v-model="listingSetupTask" persistent max-width="360px">
    <v-flex xs12>
      <v-flex xs12>
        <v-card class="pa-4">
          <span v-if="!isWarning" class="purple-icon">
            Task: {{ listingSetupTask.description }}
          </span>
          <v-btn
            fab
            dark
            small
            color="teal"
            class="float-right"
            @click="closeTask"
          >
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-flex v-if="!isInvestor" xs12>
            <v-layout wrap mt-3>
              <v-flex xs12>
                <v-text-field
                  v-model="message"
                  prepend-icon="message"
                  label="Post new message"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 mb-3 justify-center>
                <v-btn
                  v-if="message"
                  class="cyan-icon"
                  outlined
                  style="max-width: 70px"
                  text
                  @click="sendMessage"
                >
                  Send
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-divider></v-divider>
          <v-flex
            v-for="(val, key) in listingSetupTask.log"
            :key="key"
            xs12
            class="grey--text"
          >
            <v-layout
              v-if="!isMobileOrIPad"
              wrap
              class="pa-2"
              :class="val['type'] === 'message' ? 'super-light-yellow' : ''"
            >
              <v-flex xs6 class="purple-icon">{{
                parseDateWithTime(key)
              }}</v-flex>
              <v-flex xs6 class="cyan-icon" style="text-align: right">{{
                val['user']
              }}</v-flex>
              <v-flex xs12 class="align-left"> {{ val['msg'] }}</v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-flex>
        </v-card>
      </v-flex>
    </v-flex>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import axios from 'axios'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: [],
  data: function () {
    return {
      message: null,
    }
  },
  computed: {
    listingSetupTask() {
      return this.$store.state.chosenListingSetupTask
    },
    isListingTask() {
      return this.$store.state.chosenListingSetupTask.assigned_contractor_id
    },
    isWarning() {
      return this.$store.state.chosenListingSetupTask.warning_type
    },
  },
  methods: {
    closeTask() {
      this.$store.commit('updateChosenListingSetupTask', null)
    },
    sendMessage() {
      let path = ''
      if (this.isWarning) {
        path = 'api/communication-warnings'
      } else if (this.isListingTask) {
        path = 'api/listing-tasks'
      } else {
        path = 'api/listing-setup-tasks'
      }
      axios
        .post(`/${path}/${this.listingSetupTask.id}/send-msg`, {
          msg: this.message,
        })
        .then(response => {
          this.message = null
          this.$store.commit(
            'updateChosenListingSetupTask',
            response.data.payload.data
          )

          if (this.$route.fullPath.includes('listing-setup')) {
            this.$store.dispatch(
              'getListingSetup',
              this.$route.fullPath.split('/')[3]
            )
          } else if (this.$route.fullPath.includes('listing-task')) {
            setTimeout(() => {
              this.$store.dispatch(
                'getListingTaskBg',
                this.$route.fullPath.split('/')[3]
              )
            }, 100)
          } else if (this.$route.fullPath.includes('service-calls')) {
            let startDate = this.dateInSafari(
              window.$store.state.tasks_calendar.view.title
            )
            let date = this.$moment(startDate).startOf('day').utc()

            this.$store.dispatch('getListingTasks', {
              filters: {
                from: date.toISOString().substr(0, 10),
                to: this.getEndDate(
                  startDate,
                  window.$store.state.tasks_calendar.view.type
                ),
                status: 'All',
                type: 'All',
                listing_id: null,
                saveToCal: true,
                getPropertyTasks: true,
              },
            })
          } else if (this.$route.fullPath.includes('marketing')) {
            this.$store.dispatch('getCommunicationWarnings')
          }
          this.closeTask()
        })
        .catch(error => {
          alert(error)
        })
    },
  },
}
</script>
