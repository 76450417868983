<template>
  <div class="requests">
    <v-dialog
      v-if="!isInvestor"
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="800px"
    >
      <template #activator="props">
        <slot name="customBtn" v-bind="props" />
        <v-btn
          v-if="!$scopedSlots.customBtn"
          color="secondary darken-1"
          class="mb-2"
          elevation="0"
          outlined
          v-on="props.on"
        >
          <span class="text-capitalize"> Create new request </span>
        </v-btn>
      </template>
      <v-card v-if="dialog">
        <v-card-title class="pa-6 mb-4">
          <div class="p-relative flex text-center">
            <h2 class="text-h6">
              {{ formTitle }}
            </h2>
            <v-icon class="close-button" @click="close">close</v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <v-select
            v-if="!editMode"
            v-model="editedItem.storage_action_type"
            :items="allowedActions"
            :disabled="!!relatedRequest || onlyTake"
            label="Type of action"
            outlined
            dense
          />
          <v-select
            v-if="!editMode && editedItem.storage_action_type"
            v-model="editedItem.storage_nickname"
            :disabled="!!relatedRequest"
            :items="storagesWithout(editedItem.destination_storage_id)"
            item-value="nickname"
            item-text="nickname"
            label="Storage"
            outlined
            dense
            clearable
            @change="
              getInventoryItems({
                storage: ['Return', 'Take', 'Rent'].includes(
                  editedItem.storage_action_type
                )
                  ? editedItem.storage_nickname
                  : 'All',
              })
            "
          />
          <v-select
            v-if="storageMoveRequest"
            v-model="editedItem.destination_storage_id"
            :disabled="!!relatedRequest"
            :items="
              storagesWithout(storageIdByNickname(editedItem.storage_nickname))
            "
            item-value="id"
            item-text="nickname"
            label="Destination Storage"
            outlined
            dense
            clearable
          />
          <listing-select
            v-if="!storageAddRequest && !storageMoveRequest"
            :filter-change="listingChange"
            :no-prepend-icon="true"
            :outlined="true"
            :dense="true"
            :disabled="!!relatedRequest"
            :pre-selected="editedItem.listing_id"
            :clearable="true"
            item-text="nickname"
          />
          <span
            v-if="$v.editedItemListingId.$error"
            class="red-health font-light mt--1"
          >
            {{ $t('Required field') }}
          </span>
          <v-select
            v-if="editMode"
            v-model="editedItem.status"
            :items="statuses"
            :label="$t('Status')"
          />
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            required
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="editedItem.done_until"
                :label="dateText"
                prepend-inner-icon="$calendar_fill"
                readonly
                outlined
                dense
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="editedItem.done_until"
              :min="new Date().toISOString().substr(0, 10)"
              @change="saveDate"
            />
          </v-menu>
          <inventory-list
            v-if="
              !editMode &&
              editedItem.storage_action_type &&
              editedItem.storage_nickname
            "
            :only-take="onlyTake"
            :storage-nickname="editedItem.storage_nickname"
            :return-type-mode="!!relatedRequest"
            :action-type="editedItem.storage_action_type"
            :shopping-list.sync="editedItem.requests_inventory_items"
          />
          <v-textarea
            v-model="editedItem.note"
            rows="3"
            outlined
            dense
            label="✎ Note"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click.native="close">Cancel</v-btn>
          <v-btn
            :disabled="isShoppingListNotValid"
            color="blue darken-1"
            text
            @click.native="save"
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ListingSelect from 'components/listing-select.vue'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import InventoryList from 'components/inventory/inventory-list'
import { isEmpty } from 'lodash'
import { get } from 'lodash/fp'
export default {
  components: {
    InventoryList,
    ListingSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [
    'listing',
    'afterClick',
    'reservationId',
    'guestName',
    'relatedRequest',
    'onlyTake',
  ],
  data: () => ({
    cosumablesAmount: [1],
    files: [],
    menu: false,
    chosenImages: [],
    dialog: false,
    headers: [
      { text: 'Actions', value: 'name' },
      {
        text: 'Type',
        value: 'type',
      },
      { text: 'Priority', value: 'priority' },
      { text: 'Description', value: 'description' },
      { text: 'images', value: 'images' },
      { text: 'Scheduled At', value: 'done_until' },
      { text: 'Status', value: 'status' },
      { text: 'Next Opening', value: 'next_opening' },
      { text: 'Inventory', value: 'inventory' },
      { text: 'Created By', value: 'created_by' },
      { text: 'Assignee', value: 'assignee' },
    ],
    editedIndex: -1,
    editedItem: {
      inventory: {},
      type: 'StorageRequest',
      storage_nickname: null,
      destination_storage_id: null,
      description: '',
      priority: null,
      images: '',
      done_until: new Date().toISOString().substr(0, 10),
      status: null,
      created_by: '',
      assignee: '',
      listing_id: null,
      requests_inventory_items: [],
      created_from_request_id: null,
      need_listing_task: false,
      simple_mode: false,
      storage_action_type: null,
    },
    pagination: { itemsPerPage: 20 },
  }),
  validations: {
    editedItemType: {
      required,
    },
    editedItemListingId: {
      required,
    },
  },
  mounted() {
    if (this.onlyTake) {
      this.editedItem.storage_action_type = 'Take'
      const myStorage = this.sortedStorages.find(s =>
        s.owner_ids.includes(this.getMyUserId)
      )
      this.editedItem.storage_nickname = myStorage && myStorage.nickname
      if (this.$store.state.currentlistingTask) {
        this.editedItem.listing_id =
          this.$store.state.currentlistingTask.listing.id
      }
      this.getInventoryItems({
        storage: this.editedItem.storage_nickname,
      })
    }
  },
  computed: {
    ...mapGetters('storages', ['sortedStorages']),
    isShoppingListNotValid() {
      const hasNoValidAmounts = this.isReturnRequest
        ? !this.editedItem.requests_inventory_items.some(
            item => item.requested_amount > 0
          )
        : this.isAnyRequestedAmountEmpty

      return this.isShoppingListEmpty || hasNoValidAmounts
    },
    isAnyRequestedAmountEmpty() {
      return this.editedItem.requests_inventory_items.some(
        item =>
          item.requested_amount === '' ||
          item.requested_amount === null ||
          item.requested_amount <= 0
      )
    },
    isShoppingListEmpty() {
      return isEmpty(this.editedItem.requests_inventory_items)
    },
    allowedActions() {
      if (this.relatedRequest) {
        return ['Return']
      }
      if (
        this.isAdmin ||
        this.isPropertyManager ||
        this.hasAbility('inventory')
      ) {
        return ['Add', 'Take', 'Rent', 'Move']
      }
      return ['Take', 'Rent', 'Move']
    },
    isReturnRequest() {
      return this.editedItem.storage_action_type === 'Return'
    },
    dateText() {
      if (this.editedItem.storage_action_type === 'Add') {
        return 'Arrival Date'
      }
      if (this.editedItem.storage_action_type === 'Return') {
        return 'Returned Date'
      }
      if (this.editedItem.storage_action_type === 'Take') {
        return 'Picked At'
      }
      if (this.editedItem.storage_action_type === 'Rent') {
        return 'Rented At'
      }
      if (this.editedItem.storage_action_type === 'Move') {
        return 'Moved At'
      }
      return 'Scheduled At'
    },
    storageAddRequest() {
      return this.editedItem.storage_action_type === 'Add'
    },
    storageMoveRequest() {
      return this.editedItem.storage_action_type === 'Move'
    },
    editedItemType() {
      return this.editedItem.type
    },
    editedItemListingId() {
      return (
        this.storageAddRequest ||
        this.storageMoveRequest ||
        this.editedItem.listing_id
      )
    },
    editMode() {
      return this.editedIndex !== -1
    },
    formTitle() {
      if (this.relatedRequest) {
        return 'New Return Request'
      }
      if (this.editMode) {
        return 'Edit Request'
      }
      return 'New Request'
    },
    requests() {
      return this.$store.state.requests
    },
    statuses() {
      return ['New', 'In Progress', 'Done']
    },
    loading: function () {
      return this.$store.state.listingLoading
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
      this.$emit('state', val)
    },
    relatedRequest(request) {
      this.relatedRequest = request
      request && this.updateFromRelatedRequest()
    },
  },
  methods: {
    ...mapActions(['getInventoryItems']),
    storagesWithout(id) {
      return this.sortedStorages.filter(s => s.id !== id)
    },
    updateFromRelatedRequest() {
      this.dialog = true
      this.editedItem.storage_action_type = 'Return'
      this.editedItem.storage_nickname = this.storageNicknameById(
        this.relatedRequest.storage_id
      )
      this.editedItem.destination_storage_id =
        this.relatedRequest.destination_storage_id
      this.editedItem.listing_id = this.relatedRequest.listing_id
      this.editedItem.requests_inventory_items =
        this.relatedRequest.requests_inventory_items
      this.editedItem.created_from_request_id = this.relatedRequest.id
    },
    listingChange(listingID) {
      this.editedItem.listing_id = listingID
    },
    inventoryList(inventoryL) {
      let inventoryArray = []
      if (!isEmpty(inventoryL)) {
        inventoryL = JSON.parse(inventoryL)
        Object.keys(inventoryL).map(objectKey => {
          inventoryArray.push(objectKey + '  #' + inventoryL[objectKey])
        })
      }
      return inventoryArray
    },

    saveDate(date) {
      this.$refs.menu.save(date)
    },
    close() {
      this.dialog = false
      this.cosumablesAmount = [1]

      setTimeout(() => {
        this.files = []
        this.editedItem = Object.assign(
          {},
          {
            inventory: {},
            type: 'StorageRequest',
            description: '',
            priority: null,
            done_until: new Date().toISOString().substr(0, 10),
            storage_nickname: null,
            storage_action_type: this.onlyTake ? 'Take' : null,
            destination_storage_id: null,
            images: '',
            status: null,
            created_by: '',
            assignee: '',
            requests_inventory_items: [],
            created_from_request_id: null,
            listing_id: null,
            need_listing_task: false,
          }
        )
        this.editedIndex = -1
      }, 300)
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        let data = {}
        const listing =
          this.listing ||
          this.$store.state.currentEditListing ||
          this.$store.state.currentListing
        let path = ''
        let obj = this.editedItem
        this.cosumablesAmount = [1]
        data.amount = obj.amount
        if (obj.storage_nickname) {
          data.storage_nickname = obj.storage_nickname
        }
        if (obj.destination_storage_id) {
          data.destination_storage_id = obj.destination_storage_id
        }
        if (obj.storage_action_type) {
          data.storage_action_type = obj.storage_action_type
        }

        data.note = obj.note
        data.done_until = obj.done_until
        data.description = obj.description
        data.type = obj.type
        data.priority = obj.priority
        data.status = obj.status || 'New'
        data.requests_inventory_items = obj.requests_inventory_items || []
        data.created_from_request_id = obj.created_from_request_id
        if (this.storageAddRequest || this.storageMoveRequest) {
          path = '/api/storage/requests'
        } else if (this.editedIndex > -1) {
          path = `/api/listings/${this.editedItemListingId}/requests/${obj.id}/update`
        } else {
          data.files = this.files
          path = `/api/listings/${this.editedItemListingId}/requests`
        }
        this.$store.commit('updateLoading', true)
        axios
          .post(path, data)
          .then(() => {
            if (this.$route.fullPath.includes('/dashboard/inventory')) {
              this.$store.dispatch('getRequestsByFilter', {
                request_type: 'Storage',
              })
            } else if (listing && listing.id !== 0) {
              if (this.$route.fullPath.includes('/property')) {
                this.$store.dispatch('changeListing', { listingId: listing.id })
              } else {
                this.$store.dispatch('changeEditListing', listing.id)
              }
            } else if (!listing) {
              this.$store.dispatch('getRequestsByFilter')
            }
            this.$store.commit('updateLoading', false)

            this.editedItem = {
              inventory: {},
              type: 'StorageRequest',
              description: '',
              priority: null,
              storage_nickname: null,
              destination_storage_id: null,
              images: '',
              done_until: new Date().toISOString().substr(0, 10),
              status: null,
              created_by: '',
              assignee: '',
              requests_inventory_items: [],
              created_from_request_id: null,
              listing_id: null,
              need_listing_task: false,
            }
            this.$v.$reset()
          })
          .catch(error => {
            const msg = get('response.data.error', error)
            alert(msg || error.message)
            this.$store.commit('updateLoading', false)
          })
        this.close()
      }
    },
  },
}
</script>

<style>
.requests input[type='file'] {
  position: absolute;
  top: -500px;
}

.requests div.file-listing {
  width: 200px;
}

.requests span.remove-file {
  color: red;
  cursor: pointer;
  float: right;
}
</style>
