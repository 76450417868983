<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="259.578"
    height="185.945"
    viewBox="0 0 259.578 185.945"
  >
    <defs>
      <clipPath id="a">
        <path
          class="a"
          d="M113.463,194.145C70.145,172.313,52.8,145.481,66.3,114.674,75.86,92.854,94.816,88.06,117.82,88.715c27.186.655,40.669-17.138,62.278-34.164,14.017-11.044,28.542-25.778,47.4-20.3,29.323,8.517,30.428,43.768,40.884,58.829,11.153,17.026,24.275,15.272,38.8,27.689,38.295,32.727-7.735,57.53-56.167,75.874S156.78,215.977,113.463,194.145Z"
          transform="translate(1862.604 7469.186)"
        />
      </clipPath>
      <filter
        id="b"
        x="39.587"
        y="95.369"
        width="167.982"
        height="122.011"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="c" />
        <feFlood flood-opacity="0.078" />
        <feComposite operator="in" in2="c" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="translate(-1924.422 -7502.255)">
      <path
        class="a"
        d="M113.463,194.145C70.145,172.313,52.8,145.481,66.3,114.674,75.86,92.854,94.816,88.06,117.82,88.715c27.186.655,40.669-17.138,62.278-34.164,14.017-11.044,28.542-25.778,47.4-20.3,29.323,8.517,30.428,43.768,40.884,58.829,11.153,17.026,24.275,15.272,38.8,27.689,38.295,32.727-7.735,57.53-56.167,75.874S156.78,215.977,113.463,194.145Z"
        transform="translate(1862.604 7469.186)"
      />
      <g class="b">
        <g transform="translate(1971.126 7529)">
          <g transform="translate(7.883 23)">
            <g class="i" transform="matrix(1, 0, 0, 1, -54.59, -49.74)">
              <path
                class="c"
                d="M145.865,178.476v78.094a6.785,6.785,0,0,1-2.261,5.107l-.205.205a6.886,6.886,0,0,1-4.491,1.646H14.843a6.862,6.862,0,0,1-4.457-1.646.579.579,0,0,1-.205-.205,6.811,6.811,0,0,1-2.3-5.107V178.476a6.64,6.64,0,0,1,1.851-4.662,6.441,6.441,0,0,1,1.739-1.44,6.987,6.987,0,0,1,3.359-.857H138.906a7.277,7.277,0,0,1,5.142,2.261,7.015,7.015,0,0,1,1.817,4.7Z"
                transform="translate(46.7 -71.15)"
              />
            </g>
            <path
              class="d"
              d="M148.466,52.879c-.489.6-67.143,53.612-67.143,53.612L14.142,52.938l.03-.028v-.03L77.518,1.349a6.016,6.016,0,0,1,7.591,0Z"
              transform="translate(-12.295 0)"
            />
            <path
              class="e"
              d="M168.12,57.415V104.76l-52.979,42.2L62.132,104.7V57.415Z"
              transform="translate(-46.12 -40.469)"
            />
            <path
              class="d"
              d="M324.684,371.88a1.318,1.318,0,0,1-.2.2L264.8,332.272l5.462-4.4Z"
              transform="translate(-188.972 -231.099)"
            />
            <path
              class="d"
              d="M75.548,332.1,15.87,371.938a.441.441,0,0,1-.2-.2l54.39-44.059Z"
              transform="translate(-13.37 -230.96)"
            />
            <g transform="translate(27.753 29.373)">
              <path
                class="f"
                d="M101.912,99.517h82.473V103.1H101.912Z"
                transform="translate(-101.912 -99.517)"
              />
              <path
                class="f"
                d="M101.912,146.275h82.473v3.578H101.912Z"
                transform="translate(-101.912 -132.474)"
              />
              <path
                class="f"
                d="M101.912,193.039h82.473v3.578H101.912Z"
                transform="translate(-101.912 -165.435)"
              />
              <path
                class="f"
                d="M148.841,239.8h54.773v3.578H148.841Z"
                transform="translate(-134.99 -198.392)"
              />
            </g>
          </g>
        </g>
      </g>
      <g transform="translate(2092 7640)">
        <path
          class="g"
          d="M24.1,0A24.1,24.1,0,1,0,48.2,24.1,24.127,24.127,0,0,0,24.1,0Z"
        />
        <path
          class="h"
          d="M14.908,24.067a1.494,1.494,0,0,1-1.064-.442L7.317,17.1a1.507,1.507,0,0,1,2.131-2.131L14.91,20.43,26.9,8.441a1.507,1.507,0,0,1,2.131,2.131L15.977,23.626a1.51,1.51,0,0,1-1.068.442Z"
          transform="translate(6.933 8.066)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EmailSent',
}
</script>

<style scoped>
.a {
  fill: #e1f0ff;
}
.b {
  clip-path: url(#a);
}
.c {
  fill: #f6b863;
}
.d {
  fill: #eaa14e;
}
.e {
  fill: #eeefee;
}
.f {
  fill: #dbd8dd;
}
.g {
  fill: #00bf2c;
}
.h {
  fill: #fff;
}
.i {
  filter: url(#b);
}
</style>
