<template>
  <v-container>
    <stripe-accounts-table />
  </v-container>
</template>

<script>
import StripeAccountsTable from 'components/stripe/stripe-accounts-table.vue'

export default {
  name: 'Stripe',
  components: { StripeAccountsTable },
}
</script>

<style scoped></style>
