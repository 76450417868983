<template>
  <div class="d-flex align-center justify-space-between py-4">
    <div class="d-flex align-center">
      <LtSubtaskCreateModal :listing-task-id="listingTask.id">
        <template #activator="slotData">
          <v-btn
            class="secondary--text"
            elevation="0"
            :disabled="readonly"
            v-on="slotData.on"
          >
            <v-icon class="pr-2" small>fas fa-tasks</v-icon>
            <span v-if="!isMobile"> Sub task </span>
          </v-btn>
        </template>
      </LtSubtaskCreateModal>
      <v-btn-toggle
        group
        color="primary"
        :value="feedOpen ? 'comments' : null"
        class="mx-2"
        dense
      >
        <v-btn value="comments" text @click="showFeed">
          <v-badge
            v-if="unseenCommentsAmount !== 0"
            :value="unseenCommentsAmount !== 0"
            color="error"
            bordered
            overlap
            :content="unseenCommentsAmount"
          >
            <v-icon color="inherit">comment</v-icon>
          </v-badge>
          <v-badge
            v-else
            :value="commentsAmount !== 0"
            color="grey"
            bordered
            overlap
            small
            :content="commentsAmount"
          >
            <v-icon color="inherit">comment</v-icon>
          </v-badge>
        </v-btn>
      </v-btn-toggle>
      <!-- <ClockInModal :task="listingTask" /> -->
    </div>
    <!-- <div>
      <LtMenuActions :readonly="readonly" :listing-task="listingTask" />
    </div> -->
  </div>
</template>

<script>
import LtSubtaskCreateModal from 'components/listing-tasks/lt-subtask-create-modal'
import ClockInModal from 'components/clock-in-modal'
import DeviceMixin from 'components/mixins/device-mixin'
import MentionMixin from 'components/mixins/mention-mixin'

import { mapGetters } from 'vuex'
export default {
  name: 'LtActions',
  components: { LtSubtaskCreateModal },
  mixins: [DeviceMixin, MentionMixin],
  props: ['listingTask'],
  methods: {
    showFeed() {
      this.$router.push({
        query: this.feedOpen ? {} : { comment: 'true' },
      })
    },
  },
  computed: {
    ...mapGetters(['currentUserId']),
    readonly() {
      return this.listingTask.status === 'Owner Approval'
    },
    feedOpen() {
      return this.$route.query.comment === 'true'
    },
  },
}
</script>

<style scoped></style>
