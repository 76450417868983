<template>
  <div>
    <v-container>
      <v-row justify="space-between">
        <v-btn
          color="blue"
          text
          @click="
            $router.push({
              path: `/dashboard/service-calls/area-report`,
              query: {
                filter: 'my-tasks',
                section: 'service-calls',
                region_ids: $route.query.region_ids,
              },
            })
          "
          ><v-icon class="mr-2">mdi-arrow-left</v-icon> Back</v-btn
        >
        <div class="d-flex align-end">
          <span :class="['pa-0', 'availability', availabilityClass]" />
          <div
            v-if="reviewReport.last_reservation"
            class="text-center text--secondary mr-2 pointer"
            @click="
              goToReservation(
                reviewReport.last_reservation.reservation_guesty_id
              )
            "
          >
            <div class="text-decoration-underline">
              {{ parseDate(reviewReport.last_reservation.check_out, 'MMM DD') }}
            </div>
            <div class="text-caption text--secondary">Last Checkout</div>
          </div>
          <div
            v-if="reviewReport.next_reservation"
            class="text-center font-weight-bold pointer"
            @click="
              goToReservation(
                reviewReport.next_reservation.reservation_guesty_id
              )
            "
          >
            <div class="cyan--text text-decoration-underline">
              {{
                parseDate(
                  isOccupied
                    ? reviewReport.next_reservation.check_out
                    : reviewReport.next_reservation.check_in,
                  'MMM DD'
                )
              }}
            </div>
            <div
              :class="[
                'text-caption',
                'font-weight-medium',
                isOccupied ? 'red--text' : 'success--text',
              ]"
            >
              Next {{ isOccupied ? 'Checkout' : 'Checkin' }}
            </div>
          </div>
        </div>
      </v-row>
      <v-row class="justify-space-between mb-2 grey lighten-4">
        <div class="d-flex">
          <listing-info-panel
            :is-report="true"
            :listing="currentListing"
            :door-code="reviewReport.listing?.door_code"
          />
        </div>
        <div class="ml-2" :class="isMobile && 'w-100'">
          <v-btn
            :disabled="
              !reviewReport.inspection_service_call ||
              reviewReport.inspection_service_call.status === 'Done'
            "
            class="mt-2"
            color="yellow"
            depressed
            @click="
              navigateTo(
                `/dashboard/listing-task/${reviewReport.inspection_service_call.id}`
              )
            "
            ><v-icon class="mr-2 ml-2">mdi-magnify</v-icon> Start
            Inspection</v-btn
          >
          <div
            v-if="
              reviewReport.inspection_service_call &&
              reviewReport.inspection_service_call.status === 'Done'
            "
            class="text-caption text--secondary"
          >
            Inspection Already done
          </div>
        </div>
      </v-row>
      <v-row class="ml-0">
        <div class="text-left mt-3">
          <div v-if="Object.keys(reviewReport.prev_tasks).length">
            <span class="text-body-2 mr-3 font-weight-bold">Last:</span>
            <span>
              <v-chip
                v-for="(task, key) in reviewReport.prev_tasks"
                :key="task.id"
                class="mr-3 mb-1 pa-1"
                small
                label
                :color="`grey lighten-3`"
              >
                <span
                  class="black--text font-weight-medium mr-1 text-capitalize"
                >
                  {{ key }}:</span
                ><span>{{
                  parseDate(task.scheduled_at, 'MMM DD')
                }}</span></v-chip
              >
            </span>
          </div>
          <div v-if="Object.keys(reviewReport.next_tasks).length" class="mt-2">
            <span class="text-body-2 mr-3 font-weight-bold">Next:</span>
            <span>
              <v-chip
                v-for="(task, key) in reviewReport.next_tasks"
                :key="task.id"
                class="mr-3 mb-1 pa-1"
                small
                label
                :color="`green lighten-5`"
              >
                <span
                  class="black--text font-weight-medium mr-1 text-capitalize"
                >
                  {{ key }}:</span
                ><span>{{
                  parseDate(task.scheduled_at, 'MMM DD')
                }}</span></v-chip
              ></span
            >
          </div>
        </div>
      </v-row>
      <review-stats v-if="reviewReport.stats" :stats="reviewReport.stats" />
    </v-container>
    <div>
      <div class="pb-0">
        <v-tabs
          v-if="reviewReport.stats"
          v-model="section"
          :optional="true"
          slider-color="white"
          background-color="#f6f6f7"
          active-class="white"
          show-arrows
        >
          <v-tab href="#service-calls">
            <span class="text-capitalize"
              >{{ $t('Service Calls') }} ({{ totalServiceCalls }})</span
            >
          </v-tab>
          <v-tab href="#pending-expenses">
            <span class="text-capitalize"
              >{{ $t('Pending Approval') }} ({{
                stats.pending_expenses_count + stats.pending_approval_count
              }})</span
            >
          </v-tab>
          <v-tab href="#pending-reviews">
            <span class="text-capitalize"
              >{{ $t('Pending Reviews') }} ({{
                stats.pending_reviews_count
              }})</span
            >
          </v-tab>
        </v-tabs>
      </div>
      <v-container v-if="reviewReport.stats" fluid>
        <div v-if="!reportLoading && section === 'pending-expenses'">
          <v-radio-group v-model="expenseTypeToggle" dense row class="mt-0">
            <v-radio class="mr-1" value="all">
              <template #label>
                <div class="text-caption text-capitalize font-weight-medium">
                  {{ $t('All') }}
                </div>
              </template></v-radio
            >
            <v-radio
              v-for="division in expenseDivisions"
              :key="division"
              class="mr-1"
              :value="division"
            >
              <template #label>
                <div class="text-caption text-capitalize font-weight-medium">
                  {{ division }}
                </div>
              </template></v-radio
            >
          </v-radio-group>
        </div>
        <div>
          <v-row v-if="section === 'service-calls'">
            <v-col cols="12">
              <v-select
                v-if="isMobile"
                v-model="taskFilter"
                dense
                outlined
                filled
                color="primary"
                item-color="primary"
                hide-details
                :label="$t('Filter')"
                :items="[
                  {
                    text: `${$t('Today Tasks')} (${stats.today_tasks_count})`,
                    value: 'today-tasks',
                  },
                  {
                    text: `${$t('My Tasks')} (${stats.my_tasks_count})`,
                    value: 'my-tasks',
                  },
                  {
                    text: `${$t('Unassigned')} (${stats.unassigned_count})`,
                    value: 'unassigned',
                  },
                  {
                    text: `${$t('Critical/High')} (${
                      stats.high_open_issues_count
                    })`,
                    value: 'high-open',
                  },
                  {
                    text: `${$t('Owner Approvals')} (${
                      stats.owner_approvals_count
                    })`,
                    value: 'owner-approvals',
                  },
                  {
                    text: `${$t('Review tasks')} (${stats.review_tasks_count})`,
                    value: 'review-tasks',
                  },
                  {
                    text: `${$t('Med/Low')} (${stats.med_low_issues_count})`,
                    value: 'med-low',
                  },
                  {
                    text: `${$t('Overdue')} (${stats.overdue_count})`,
                    value: 'overdue',
                  },
                  {
                    text: `${$t('Rejected')} (${stats.reject_count})`,
                    value: 'rejected',
                  },
                  {
                    text: `${$t('Claims')} (${stats.claims_count})`,
                    value: 'claims',
                  },
                ]"
              />
              <v-btn-toggle
                v-else
                v-model="taskFilter"
                color="primary"
                class="overflow-auto"
              >
                <v-btn :small="isMobile" value="today-tasks"
                  >{{ $t('Today Tasks') }} ({{
                    stats.today_tasks_count
                  }})</v-btn
                >
                <v-btn :small="isMobile" value="my-tasks"
                  >{{ $t('My Tasks') }} ({{ stats.my_tasks_count }})</v-btn
                >
                <v-btn :small="isMobile" value="unassigned"
                  >{{ $t('Unassigned') }} ({{ stats.unassigned_count }})</v-btn
                >
                <v-btn :small="isMobile" value="high-open"
                  >{{ $t('Critical/High') }} ({{
                    stats.high_open_issues_count
                  }})</v-btn
                >
                <v-btn :small="isMobile" value="owner-approvals"
                  >{{ $t('Owner Approvals') }} ({{
                    stats.owner_approvals_count
                  }})</v-btn
                >
                <v-btn :small="isMobile" value="review-tasks"
                  >{{ $t('Review tasks') }} ({{
                    stats.review_tasks_count
                  }})</v-btn
                >
                <v-btn :small="isMobile" value="med-low"
                  >{{ $t('Med/Low') }} ({{ stats.med_low_issues_count }})</v-btn
                >
                <v-btn :small="isMobile" value="overdue"
                  >{{ $t('Overdue') }} ({{ stats.overdue_count }})</v-btn
                >
                <v-btn :small="isMobile" value="rejected"
                  >{{ $t('Reject') }} ({{ stats.reject_count }})</v-btn
                >
                <v-btn :small="isMobile" value="claims"
                  >{{ $t('Claims') }} ({{ stats.claims_count }})</v-btn
                >
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row v-if="section === 'service-calls'">
            <v-col cols="12" md="auto">
              <filters-select
                :label="$t('Status')"
                :value.sync="filters.status"
                :items="ltStatuses"
                @change="getReportTasks"
              />
            </v-col>
            <v-col cols="12" md="auto">
              <filters-select
                :label="$t('Priority')"
                :value.sync="filters.priorities"
                :items="['Critical', 'High', 'Med', 'Low']"
                @change="getReportTasks"
              />
            </v-col>
          </v-row>
          <maintenance-task
            v-for="task in listingTasks"
            :key="task.id"
            class="ma-2 mx-0"
            :is-selected="+taskId === task.id"
            :task="task"
            :nickname="task.listing?.nickname"
          />
        </div>
        <v-list class="text-left pt-0">
          <listing-expense
            v-for="expense in filteredExpenses"
            :key="expense.id"
            :expense="expense"
            :validatable="true"
            @validate-expense="validateExpenseHandler"
            @on-inquiry="tabChange"
            @on-change="dataChange"
          />
        </v-list>
        <div v-if="section === 'pending-reviews'">
          <review-row
            v-for="review in reviews"
            :key="review.id"
            :review="review"
            @validate-review="validateReviewHandler"
          />
        </div>
        <div v-if="['client-refunds', 'supplies'].includes(section)">
          <listing-expense
            v-for="expense in expenses"
            :key="expense.id"
            :nickname="expense.listing?.nickname"
            :expense="expense"
            @validate-expense="validateExpenseHandler"
            @on-inquiry="tabChange"
            @on-change="dataChange"
          />
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import MaintenanceTask from 'components/listing-report/maintenance-task'
import ReviewStats from 'components/listing-report/review-stats'
import { mapActions, mapGetters, mapState } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import NavigationMixin from 'components/mixins/navigation-mixin'
import LtMixin from 'components/mixins/lt-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import ListingInfoPanel from 'components/listing/listing-info-panel'
import ListingExpense from 'components/listing-report/listing-expense'
import uniqBy from 'lodash/uniqBy'
import ReviewRow from 'components/listing-report/review-row.vue'
import Common_functions from 'components/mixins/common_functions'
import AreaReport from 'components/mixins/area-report'
import FiltersSelect from 'components/form-fields/filters-select.vue'
export default {
  name: 'ListingReportSingle',
  components: {
    FiltersSelect,
    ReviewRow,
    ListingExpense,
    ListingInfoPanel,
    ReviewStats,
    MaintenanceTask,
  },
  mixins: [
    FormattersMixin,
    NavigationMixin,
    LtMixin,
    DeviceMixin,
    Common_functions,
    AreaReport,
  ],
  data() {
    return {
      filters: {
        listings_id: [],
        priorities: [],
        status: [],
      },
      availabilityClass: null,
      expenseTypeToggle: 'all',
    }
  },
  computed: {
    ...mapGetters(['quickTasksCount']),
    ...mapState(['currentListing']),
    ...mapState('listingReport', [
      'fetchingReportData',
      'report',
      'updatedReportTask',
    ]),
    listingId() {
      return this.$route.params.id
    },
    isOccupied() {
      return this.$moment(
        this.reviewReport.next_reservation.check_in
      ).isBefore()
    },
    reviewReport() {
      return this.$store.state.listingReport.listingReportStats
    },
    filteredExpenses() {
      return this.expenseTypeToggle === 'all'
        ? this.expenses
        : this.expenses.filter(
            e => e.related_division === this.expenseTypeToggle
          )
    },
    expenseDivisions() {
      return uniqBy(this.expenses, 'related_division').map(
        e => e.related_division
      )
    },
    listingTasks() {
      return this.prioritize(this.report.listing_tasks)
    },
    expenses() {
      return this.report.expenses
    },
    reviews() {
      return this.report.reviews
    },
    reportLoading() {
      return this.fetchingReportData
    },
    inspection_service_call() {
      return this.report.inspection_service_call
    },
  },
  mounted() {
    this.$store.dispatch('changeListing', {
      listingId: this.listingId,
    })
    if (
      !this.reviewReport.listing ||
      this.reviewReport.listing.id !== +this.listingId
    ) {
      this.getStats()
    } else {
      this.getReportTasks()
    }
  },
  methods: {
    ...mapActions('listingReport', ['getListingReport', 'getReviewReport']),
    ...mapActions('expenses', ['validateExpense']),
    async validateExpenseHandler(id) {
      await this.validateExpense(id)
      await this.getReportTasks()
      await this.getStats()
    },
    addToQuery(filter) {
      const currentQuery = this.$route.query
      this.$router.push({ query: { ...currentQuery, ...filter } })
    },
    async validateReviewHandler(id) {
      await this.$store.dispatch('approveReview', id)
      await this.getReportTasks()
      await this.getStats()
    },
    async getStats() {
      await this.getReviewReport({
        listingId: this.listingId,
      })
      this.$nextTick(() => {
        this.getReportTasks()
      })
    },
    async getReportTasks() {
      this.getListingReport({
        id: this.listingId,
        filter: this.taskFilter,
        ...this.filters,
      })
    },
    tabChange() {
      this.getReportTasks()
    },
    dataChange() {
      this.getReviewReport({
        listingId: this.listingId,
      })
      this.$nextTick(() => {
        this.getReportTasks()
      })
    },
  },
  watch: {
    quickTasksCount() {
      this.getStats()
    },
    updatedReportTask(task) {
      if (task) {
        const index = this.report.listing_tasks.findIndex(
          lt => lt.id === task.id
        )
        if (index !== -1) {
          this.$set(this.report.listing_tasks, index, task)
        }
      }
    },
    async '$route.query'(query, oldQuery) {
      const { filter } = query
      if (
        this.section === 'pending-reviews' ||
        this.section === 'pending-expenses'
      ) {
        if (this.taskFilter !== this.section) {
          this.taskFilter = this.section
        }
      }
      if (filter !== oldQuery.filter) {
        await this.getReportTasks()
      }
    },
  },
}
</script>

<style scoped></style>
