import { cloneDeep, mapValues } from 'lodash'

class LocalStorage {
  static has(key) {
    return key in LocalStorage.storage
  }

  static get(key) {
    return cloneDeep(LocalStorage.storage[key])
  }

  static set(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
    LocalStorage.storage[key] = value
  }

  static remove(...keys) {
    keys.forEach(key => {
      localStorage.removeItem(key)
      delete LocalStorage.storage[key]
    })
  }

  static clearAll() {
    localStorage.clear()
    LocalStorage.storage = {}
  }
}

LocalStorage.storage = mapValues(localStorage, v => {
  try {
    return JSON.parse(v)
  } catch (e) {
    return v
  }
})

export default LocalStorage
