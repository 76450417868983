<template>
  <div>
    <div ref="googleMap" class="google-map fill-height" />
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
// import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { orderBy, groupBy } from 'lodash'

export default {
  props: ['mapOptions', 'markersData', 'showDirections'],
  data() {
    return {
      google: null,
      map: null,
      info: null,
      markers: [],
      styles: [
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    }
  },
  watch: {
    markersData() {
      this.initializeMap()
    },
    mapOptions(newOptions, oldOptions) {
      if (newOptions.center !== oldOptions.center) {
        this.map.panTo(newOptions.center)
        setTimeout(() => this.map.setZoom(16), 100)
      }
    },
  },
  async mounted() {
    const googleMapApi = await new Loader({
      apiKey: 'AIzaSyCzvYaJgsS0jhNhEjzREy_G9Quie3zc7A4',
    }).load()

    this.google = googleMapApi
    this.initializeMap()
  },
  methods: {
    initializeDirections(map, markers) {
      const directionsRenderer = new this.google.maps.DirectionsRenderer({
        suppressMarkers: true,
      })
      const directionsService = new this.google.maps.DirectionsService()
      const { origin, destination, waypoints } = this.mapRoute(markers)
      var request = {
        origin,
        destination,
        waypoints,
        optimizeWaypoints: false, //set to true if you want google to determine the shortest route or false to use the order specified.
        travelMode: 'DRIVING',
      }
      directionsRenderer.setMap(map)
      directionsService.route(request).then(res => {
        const { legs } = res.routes[0]
        const minutes = legs.reduce((acc, curr) => acc + curr.duration.value, 0)
        const meters = legs.reduce((acc, curr) => acc + curr.distance.value, 0)
        this.$emit('route-info', { minutes, meters })
        directionsRenderer.setDirections(res)
      })
    },
    mapRoute(markers) {
      const orderedMarkers = orderBy(markers, 'order')
      const origin = orderedMarkers.shift().position
      const destination = orderedMarkers.pop().position
      const waypoints = orderedMarkers.map(m => ({
        location: m.position,
        stopover: true,
      }))
      return { origin, destination, waypoints }
    },

    initializeMap() {
      this.markers = []
      const mapContainer = this.$refs.googleMap
      this.info = new this.google.maps.InfoWindow()

      const allOptions = {
        ...this.mapOptions,
        styles: this.styles,
      }
      this.map = new this.google.maps.Map(mapContainer, allOptions)

      const routeMarkers = this.markersData.filter(m => !m.info && m.navigate)
      if (this.showDirections && routeMarkers.length > 1) {
        this.initializeDirections(this.map, routeMarkers)
      }

      const bounds = new this.google.maps.LatLngBounds()
      const groups = groupBy(this.markersData, 'position.lng')

      let singleMarkers = []
      let groupedMarkers = []

      Object.keys(groups).forEach(k => {
        if (groups[k].length > 1) groups[k] = groups[k].filter(m => !m.isCustom)
        if (groups[k].length > 1) {
          groupedMarkers.push(groups[k])
        } else {
          singleMarkers = singleMarkers.concat(groups[k])
        }
      })

      groupedMarkers.forEach(g => {
        const marker = new this.google.maps.Marker({
          id: g[0].id,
          position: g[0].position,
          map: this.map,
          icon: g[0].icon,
          label: {
            ...g[0].label,
            text: g.map(m => m.label && m.label.text).join(', '),
            className: 'multi-marker-label',
          },
        })
        marker.addListener('click', () => {
          this.google.maps.event.addListener(this.map, 'click', () =>
            this.info.close()
          )
          this.info.setContent(
            `<div class="mt-1 d-flex">${g
              .map(
                m =>
                  `<span id="marker_${m.id}" class="${m.label.className} multi-marker">${m.label.text}</span>`
              )
              .join('')}</div>`
          )
          this.info.open({ anchor: marker, map: this.map })
          this.$nextTick(() => {
            g.forEach(marker => {
              const el = document.getElementById(`marker_${marker.id}`)
              el.addEventListener('click', () => {
                this.$emit('open-task', marker.id)
                this.info.close()
              })
            })
          })
        })
        this.markers.push(marker)
      })

      singleMarkers.forEach(m => {
        const marker = new this.google.maps.Marker({
          id: m.id,
          position: m.position,
          marker: m,
          map: this.map,
          icon: m.icon,
          label: m.label,
        })
        if (m.clickAction) {
          marker.addListener('click', () => {
            this.$emit('open-task', marker.id)
          })
        }
        if (m.info) {
          marker.addListener('click', () => {
            this.google.maps.event.addListener(this.map, 'click', () =>
              this.info.close()
            )
            this.info.setContent(m.info)
            this.info.open({
              anchor: marker,
              map: this.map,
            })
          })
        }

        bounds.extend(m.position)
        this.markers.push(marker)
      })
      this.map.fitBounds(bounds)
      // new MarkerClusterer({
      //   map: this.map,
      //   markers: this.markers,
      // })
    },
  },
}
</script>
<style>
.multi-marker {
  margin: 0 5px;
  width: 25px;
  height: 25px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
