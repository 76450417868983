<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.6"
    height="24"
    viewBox="0 0 21.6 24"
  >
    <path
      class="a"
      d="M329.6,336.8a.8.8,0,1,1-.8-.8A.8.8,0,0,1,329.6,336.8Zm0,0"
      transform="translate(-311.6 -319.2)"
    />
    <path
      class="a"
      d="M20.4,211.2H20v-2a1.2,1.2,0,0,0-1.2-1.2H1.2A1.2,1.2,0,0,0,0,209.2v11.2a1.2,1.2,0,0,0,1.2,1.2H18.8a1.2,1.2,0,0,0,1.2-1.2v-2.8h.4a1.2,1.2,0,0,0,1.2-1.2v-4A1.2,1.2,0,0,0,20.4,211.2Zm-1.2,9.2a.4.4,0,0,1-.4.4H1.2a.4.4,0,0,1-.4-.4V209.2a.4.4,0,0,1,.4-.4H18.8a.4.4,0,0,1,.4.4v3.6H16a1.2,1.2,0,0,0-1.2,1.2v2.4a1.2,1.2,0,0,0,1.2,1.2h3.2Zm1.6-4a.4.4,0,0,1-.4.4H16a.4.4,0,0,1-.4-.4V214a.4.4,0,0,1,.4-.4h4.8Zm-.8-3.6V212h.4a.4.4,0,0,1,.4.4v.4Zm0,0"
      transform="translate(0 -197.6)"
    />
    <path
      class="a"
      d="M216.8,3.168A2.8,2.8,0,0,0,219.168.8h1.664A2.8,2.8,0,0,0,223.2,3.168V9.6h.8V1.2A1.2,1.2,0,0,0,222.8,0h-5.6A1.2,1.2,0,0,0,216,1.2V9.6h.8ZM222.8.8a.4.4,0,0,1,.4.4V2.36A2.006,2.006,0,0,1,221.64.8Zm-6,.4a.4.4,0,0,1,.4-.4h1.16a2.006,2.006,0,0,1-1.56,1.56Zm0,0"
      transform="translate(-205.2)"
    />
    <path
      class="a"
      d="M248,82.4a2.4,2.4,0,1,0,2.4-2.4A2.4,2.4,0,0,0,248,82.4Zm4,0a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,252,82.4Zm0,0"
      transform="translate(-235.6 -76)"
    />
    <path
      class="a"
      d="M120.8,51.168a2.8,2.8,0,0,0,2.368-2.368H124V48h-2.8a1.2,1.2,0,0,0-1.2,1.2v6h.8Zm0-1.968a.4.4,0,0,1,.4-.4h1.16a2.006,2.006,0,0,1-1.56,1.56Zm0,0"
      transform="translate(-114 -45.6)"
    />
    <path
      class="a"
      d="M24.8,99.168A2.8,2.8,0,0,0,27.168,96.8H28V96H25.2A1.2,1.2,0,0,0,24,97.2v3.6h.8Zm0-1.968a.4.4,0,0,1,.4-.4h1.16a2.006,2.006,0,0,1-1.56,1.56Zm0,0"
      transform="translate(-22.8 -91.2)"
    />
    <path
      class="a"
      d="M152,146.4h.8a1.6,1.6,0,0,1,1.6-1.6V144A2.4,2.4,0,0,0,152,146.4Zm0,0"
      transform="translate(-144.4 -136.8)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Wallet',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
