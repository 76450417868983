<template>
  <v-sheet
    :width="menuExpanded ? 500 : isFull ? '100%' : 'auto'"
    class="flex"
    :class="{
      'scrollable-dvr': !absolute && !isMobile,
      'mobile-sidebar': isMobile,
    }"
  >
    <v-progress-linear :active="fetching" indeterminate color="info" />

    <v-overlay v-if="fetching" absolute :opacity="0.2" />
    <v-tabs v-if="isFull" v-model="tab" centered show-arrows>
      <v-tab v-if="isDesignedVr" href="#sales-intelligence">
        <v-icon class="mr-2">mdi-chart-box</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Sales Intel') }}</span>
      </v-tab>
      <v-tab href="#reservation">
        <v-icon class="mr-2" small>$home</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Res') }}</span>
      </v-tab>
      <v-tab href="#guest-info">
        <v-icon class="mr-2">$people</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Guest') }}</span>
      </v-tab>
      <v-tab
        v-if="
          currentReservation.store_items_options &&
          currentReservation.store_items_options.length
        "
        href="#store"
      >
        <v-icon class="mr-2">$store</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Store') }}</span>
      </v-tab>
      <v-tab href="#payment-info">
        <v-icon class="mr-2">$money</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Payment') }}</span>
      </v-tab>
      <v-tab
        v-if="
          conversation.issues &&
          conversation.issues.length > 0 &&
          ['confirmed', 'inquiry'].includes(currentReservation.status)
        "
        href="#issues"
      >
        <v-badge
          :content="conversation.issues.length"
          overlap
          top
          color="warning"
          class="text-center"
        >
          <v-icon class="mr-2">$maintenance</v-icon>
        </v-badge>

        <span class="d-none d-sm-inline">{{ $t('Service calls') }}</span>
      </v-tab>
      <v-tab
        v-if="
          !isEmpty(currentReservation.chat_relevant_listings) &&
          currentReservation.status !== 'confirmed'
        "
        href="#comparables"
      >
        <v-icon class="mr-2">tune</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Comps') }}</span>
      </v-tab>
      <v-tab
        v-if="!isEmpty(conversation.extra_data.sales_status)"
        href="#timeline"
      >
        <v-icon class="mr-2">timeline</v-icon>
        <span class="d-none d-sm-inline">{{ $t('Timeline') }}</span>
      </v-tab>
      <!--      <v-tab-->
      <!--        v-if="-->
      <!--          currentReservation.status !== 'confirmed' &&-->
      <!--            this.$store.state.currentPricingMonthly-->
      <!--        "-->
      <!--        href="#pricing"-->
      <!--      >-->
      <!--        <v-icon class="mr-2">local_offer</v-icon>-->
      <!--        <span class="d-none d-sm-inline">Pricing</span>-->
      <!--      </v-tab>-->
      <!--      <v-tab v-if="currentReservation.status === 'confirmed'" href="#iot-tab">-->
      <!--        <v-icon class="mr-2">$wifi_alt</v-icon>-->
      <!--        <span class="d-none d-sm-inline">IOT</span>-->
      <!--      </v-tab>-->
    </v-tabs>
    <div
      class="d-flex"
      :class="[
        isMobile ? 'conversation-info-mobile' : 'conversation-info',
        { 'h-0': !menuExpanded && isMobile },
      ]"
    >
      <v-slide-x-reverse-transition>
        <v-tabs-items
          v-if="menuExpanded || isFull"
          v-model="tab"
          class="flex-grow-1"
          :class="{ 'overflow-auto': isMobile }"
          touchless
        >
          <v-tab-item
            v-if="isDesignedVr && getSalesInsights"
            value="sales-intelligence"
          >
            <sales-intelligence-panel :sales-insights="getSalesInsights" />
          </v-tab-item>
          <v-tab-item value="contractor-tasks" class="pa-1">
            <todays-tasks-tab :initial-contractor-id="conversation.user_id" />
          </v-tab-item>

          <v-tab-item key="1" value="reservation">
            <conversation-info-reservation
              v-if="conversation"
              :single-reservation="singleReservation"
              :conversation="conversation"
            />
          </v-tab-item>

          <v-tab-item value="guest-info">
            <conversation-info-guest
              v-if="hasReservations"
              :reservation="currentReservation"
              :conversation="conversation"
            />
            <div v-if="conversation.user">
              <conversation-info-user :conversation="conversation" />
              <reservation-create-modal
                v-if="hasAbility(['create-reservation'])"
                :pre-filled="userPreFilled"
                class="ml-auto"
                @oncreate="createReservation"
              >
                <template #customBtn="{ on }">
                  <div class="d-flex justify-center mb-8">
                    <v-btn
                      color="indigo"
                      outlined
                      class="rounded-pill font-weight-medium"
                      v-on="on"
                    >
                      <v-icon class="me-1">mdi-plus</v-icon>
                      Create Inquiry/Reservation</v-btn
                    >
                  </div>
                </template>
              </reservation-create-modal>
              <div class="ma-2">
                <conversation-select :conversation="conversation" />
              </div>
            </div>
          </v-tab-item>

          <v-tab-item value="payment-info">
            <conversation-info-payment
              :conversation-id="conversation.guesty_id"
              :guest="conversation.guest"
              :reservation="currentReservation"
            />
          </v-tab-item>
          <v-tab-item value="store">
            <purchase-table
              :reservation-id="currentReservation.id"
              :guest-id="currentReservation.guest_id"
            />
            <quick-charge
              :full-width="true"
              :reservation="currentReservation"
              :tourist-email="touristEmail"
              @get-purchases="getPurchases"
            />
            <div class="d-flex justify-space-around">
              <store-dialog
                :disabled="!touristEmail"
                :confirmation-code="currentReservation.confirmation_code"
              />
            </div>
          </v-tab-item>
          <v-tab-item v-if="hasOutstandingIssues" value="outstanding_issues">
            <conversation-info-issues
              issues-field="outstanding_issues"
              :conversation="conversation"
              :reservation="currentReservation"
            />
          </v-tab-item>
          <v-tab-item value="issues">
            <conversation-info-issues
              :conversation="conversation"
              :reservation="currentReservation"
            />
          </v-tab-item>
          <v-tab-item value="comparables">
            <conversation-info-optional-listings
              :reservation="currentReservation"
              :conversation="conversation"
            />
          </v-tab-item>
          <v-tab-item value="pricing">
            <conversation-pricing :conversation="conversation" />
          </v-tab-item>
          <v-tab-item value="marketing">
            <marketing-links
              :integrations="currentReservation?.listing?.integrations"
            />
          </v-tab-item>
          <!--          <v-tab-item value="iot-tab">-->
          <!--            <conversation-info-iot-->
          <!--              :listing-id="currentReservation.listing_id"-->
          <!--            />-->
          <!--          </v-tab-item>-->
        </v-tabs-items>
      </v-slide-x-reverse-transition>
      <aside
        v-if="!isFull"
        class="text-center pa-0 pa-sm-3 ma-3 ma-sm-0"
        :class="{
          'p-absolute right-0': isMobile && menuExpanded,
          'conversation-info-menu': !isMobile,
          'mobile-location': isMobile,
        }"
      >
        <v-btn
          v-if="!keepExpanded"
          class="mb-2"
          small
          :text="!isMobile"
          :fab="isMobile"
          @click="menuExpanded = !menuExpanded"
        >
          <v-icon :small="isMobile" :class="{ open: menuExpanded }"
            >$expand_menu</v-icon
          >
        </v-btn>
        <v-tabs
          v-if="menuExpanded || !isMobile"
          v-model="tab"
          vertical
          hide-slider
          class="aside-tabs"
          :active-class="
            isMobile ? 'mobile-active' : 'secondary lighten-3 black--text'
          "
        >
          <v-tab
            v-if="isDesignedVr && getSalesInsights"
            href="#sales-intelligence"
            :class="{ 'secondary lighten-3 mb-2': !isMobile }"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn small :fab="isMobile" :text="!isMobile">
                  <v-icon v-bind="attrs" v-on="on">mdi-chart-box</v-icon>
                </v-btn>
              </template>
              <span>Sales Intelligence</span>
            </v-tooltip>
          </v-tab>
          <v-tab
            v-if="isOperations"
            class="mb-2"
            :class="{ 'secondary lighten-3 mb-2': !isMobile }"
            href="#contractor-tasks"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn small :fab="isMobile" :text="!isMobile">
                  <v-icon v-bind="attrs" v-on="on">mdi-wrench</v-icon>
                </v-btn>
              </template>
              <span>Contractor Tasks</span>
            </v-tooltip>
          </v-tab>

          <v-tab
            v-if="hasReservations"
            class="mb-2"
            :class="{ 'secondary lighten-3 mb-2': !isMobile }"
            href="#reservation"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn small :fab="isMobile" :text="!isMobile">
                  <v-icon v-bind="attrs" v-on="on">$home</v-icon>
                </v-btn>
              </template>
              <span>Reservations</span>
            </v-tooltip>
          </v-tab>
          <v-tab
            :class="{ 'secondary lighten-3 mb-2': !isMobile }"
            href="#guest-info"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn small :fab="isMobile" :text="!isMobile">
                  <v-icon v-bind="attrs" v-on="on">$people</v-icon>
                </v-btn>
              </template>
              <span>Guest</span>
            </v-tooltip>
          </v-tab>
          <v-tab
            v-if="
              hasReservations &&
              !isEmpty(currentReservation.store_items_options)
            "
            :class="{ 'secondary lighten-3 mb-2': !isMobile }"
            href="#store"
            @click="storeSelectHandler"
          >
            <v-badge
              v-if="storeCount"
              overlap
              :content="storeCount"
              top
              color="primary"
              class="text-center"
            >
              <v-icon>$store</v-icon>
            </v-badge>
            <v-icon v-else>$store</v-icon>
          </v-tab>
          <v-tab
            v-if="hasReservations"
            :class="{ 'secondary lighten-3 mb-2': !isMobile }"
            href="#payment-info"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn small :fab="isMobile" :text="!isMobile">
                  <v-icon v-bind="attrs" v-on="on">$money</v-icon>
                </v-btn>
              </template>
              <span>Payment</span>
            </v-tooltip>
          </v-tab>
          <v-tab
            v-if="
              hasReservations &&
              currentReservation &&
              ['confirmed', 'inquiry'].includes(currentReservation.status) &&
              showModule('ops')
            "
            :class="{ 'secondary lighten-3 mb-2': !isMobile }"
            href="#issues"
            @click="menuExpanded = true"
          >
            <v-badge
              v-if="conversation.issues && conversation.issues.length"
              :content="conversation.issues.length"
              top
              color="primary"
              class="text-center"
            >
              <v-btn small :fab="isMobile" :text="!isMobile">
                <v-icon>$maintenance</v-icon>
              </v-btn>
            </v-badge>
            <v-btn v-else small :fab="isMobile" :text="!isMobile">
              <v-icon>$maintenance</v-icon>
            </v-btn>
          </v-tab>
          <v-tab
            v-if="
              hasReservations &&
              !isEmpty(currentReservation.chat_relevant_listings) &&
              currentReservation.status !== 'confirmed'
            "
            :class="{ 'secondary lighten-3 mb-2': !isMobile }"
            href="#comparables"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn small :fab="isMobile" :text="!isMobile">
                  <v-icon v-bind="attrs" v-on="on">tune</v-icon>
                </v-btn>
              </template>
              <span>Comps</span>
            </v-tooltip>
          </v-tab>
          <v-tab v-if="hasOutstandingIssues" href="#outstanding_issues">
            <v-badge
              :content="conversation.outstanding_issues.length"
              overlap
              top
              color="warning"
              class="text-center"
            >
              <v-btn small :fab="isMobile" :text="!isMobile">
                <v-icon class="mr-0 mr-sm-2">error</v-icon>
              </v-btn>
            </v-badge>
          </v-tab>
          <v-tab
            v-if="hasReservations"
            href="#marketing"
            :class="{ 'secondary lighten-3 mb-2': !isMobile }"
            @click="menuExpanded = true"
          >
            <v-tooltip left>
              <template #activator="{ on, attrs }">
                <v-btn small :fab="isMobile" :text="!isMobile">
                  <v-icon v-bind="attrs" v-on="on"
                    >mdi-share-variant-outline</v-icon
                  >
                </v-btn>
              </template>
              <span>Marketing</span>
            </v-tooltip>
          </v-tab>
        </v-tabs>
      </aside>
    </div>
  </v-sheet>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ConversationInfoPayment from 'components/conversation-info-payment'
import ConversationInfoOptionalListings from 'components/conversation-info-optional-listings'
import ConversationPricing from 'components/conversation-pricing'
import ConversationInfoReservation from 'components/conversation-info-reservation'
import ConversationInfoGuest from 'components/conversation-info-guest.vue'
import ConversationInfoUser from 'components/conversation-info-user.vue'
import ConversationInfoIssues from 'components/conversations/conversation-info-issues'
import MarketingLinks from 'components/conversations/marketing-links.vue'
import PurchaseTable from 'components/store/purchase-table'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import QuickCharge from 'components/store/quick-charge'
import StoreDialog from 'components/store/store-dialog'
import deviceMixin from 'components/mixins/device-mixin'
import TodaysTasksTab from 'components/todays-tasks/todays-tasks-tab.vue'
import ReservationCreateModal from 'components/reservation/reservation-create-modal.vue'
import ConversationSelect from 'components/chat/conversation-select'
import SalesIntelligencePanel from 'components/crm/sales-intelligence-panel.vue'
import { maxBy } from 'lodash'

export default {
  mixins: [CommonFunctions, PermissionsMixin, deviceMixin],
  props: [
    'conversation',
    'isFull',
    'keepExpanded',
    'singleReservation',
    'absolute',
    'fetching',
  ],
  data() {
    return {
      storeDialog: null,
      signupDialog: null,
      tab: null,
      panel: true,
      panel2: false,
      message: null,
      messageModule: 'airbnb',
      menuExpanded: this.keepExpanded || false,
      currentConversation: this.conversation,
      touristEmail: null,
    }
  },
  computed: {
    ...mapGetters({
      currentReservation: 'chosenChatReservation',
    }),
    ...mapState(['serviceCallModalOpened']),
    storeCount() {
      return this.currentConversation.store_items_count
    },
    storePortalPath() {
      const { confirmation_code } = this.currentReservation
      return `${process.env.VUE_APP_TOURIST_PORTAL_URL}/tourist/store/reservation/${confirmation_code}?externalCheckout=true`
    },
    signupPortalPath() {
      const { guest_full_name, logo, guest } = this.conversation
      return `${process.env.VUE_APP_TOURIST_PORTAL_URL}/tourist/signup?g=${guest.guesty_id}&fullName=${guest_full_name}&logo=${logo}&externalCheckout=true`
    },
    hasOutstandingIssues() {
      return (
        this.hasReservations &&
        !this.isEmpty(this.conversation.outstanding_issues)
      )
    },
    hasReservations() {
      return this.conversation && this.conversation.related_reservations.length
    },
    isOperations() {
      return this.$route.params.paramsTab === 'operations'
    },
    userPreFilled() {
      const { full_name, phone, email } = this.conversation.user
      const name = full_name.split(' ')
      return {
        first_name: name[0],
        last_name: name[1],
        phone,
        email,
      }
    },
    getSalesInsights() {
      const messages = this.conversation.thread_plus_dvr_log || []
      const aiSalesMessages = messages.filter(msg => {
        if (!msg.generated_by_ai) return false
        try {
          const parsed = JSON.parse(msg.body)
          return parsed?.ai_response?.sales_insights
        } catch {
          return false
        }
      })

      if (!aiSalesMessages.length) return null

      const lastAiMessage = maxBy(aiSalesMessages, 'id')
      return JSON.parse(lastAiMessage.body)?.ai_response?.sales_insights
    },
  },
  methods: {
    ...mapMutations(['updatePushedMessage']),
    ...mapActions(['getTouristInfo', 'getStorePurchases']),
    getPurchases() {
      this.getStorePurchases({
        guest_id: this.currentReservation.guest_id,
        page: 1,
      })
    },
    storeSelectHandler() {
      this.menuExpanded = true
      this.getTourist(this.conversation.guest_id)
    },
    openStoreModal() {
      const { confirmation_code } = this.currentReservation
      window.open(
        `${process.env.VUE_APP_TOURIST_PORTAL_URL}/tourist/store/reservation/${confirmation_code}?externalCheckout=true`,
        '_blank'
      )
    },
    async getTourist(id) {
      const { data } = await this.getTouristInfo({ id })
      this.touristEmail = data && data.email
    },
    closeStore() {
      this.getPurchases()
      this.storeDialog = null
    },
    closeSignup() {
      this.getTourist(this.conversation.guest_id)
      this.signupDialog = null
    },
    createReservation(resId) {
      window.open(`/dashboard/reservation/${resId}`, '_blank')
    },
  },
  mounted() {
    this.updatePushedMessage(null)
  },
  watch: {
    serviceCallModalOpened(val) {
      if (val) {
        this.menuExpanded = true
        this.tab = 'issues'
      }
    },
  },
  components: {
    PurchaseTable,
    ConversationInfoGuest,
    ConversationInfoUser,
    ConversationInfoReservation,
    ConversationPricing,
    ConversationInfoOptionalListings,
    ConversationInfoPayment,
    ConversationInfoIssues,
    QuickCharge,
    StoreDialog,
    MarketingLinks,
    TodaysTasksTab,
    ReservationCreateModal,
    ConversationSelect,
    SalesIntelligencePanel,
  },
}
</script>
<style lang="scss" scoped>
:deep() .theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}
.conversation-info-mobile {
  height: 100vh;
}
.conversation-info {
  border: 1px solid var(--v-secondary-lighten1);

  &-menu {
    border-left: 1px solid var(--v-secondary-lighten1);

    .v-tab {
      min-width: unset;
      padding: 0 12px;
      border-radius: 5px;

      &--active {
        border: 1px solid currentColor !important;
      }
    }
  }
}
.open {
  transform: rotateY(180deg);
}

.toggle-btn {
  color: #7ebac0;
}

.btn--active {
  background-color: #7ebac0 !important;
  color: #ffffff !important;
}

.store-iframe {
  width: 100%;
  height: 100vh;
}

.mobile-sidebar {
  position: absolute;
  right: 0;
}
.mobile-active {
  background-color: transparent !important;
}
:deep() .mobile-active > button {
  background-color: #e5e7eb !important;
}
.v-tab:before,
.v-tabs-slider {
  background-color: transparent;
}
.mobile-location {
  top: 10vh !important;
  position: relative;
  height: 0;
}
.h-0 {
  height: 0;
}
</style>
