<template>
  <div class="pt-0 d-flex flex-grow-1 flex-column p-relative h-100">
    <v-sheet class="d-flex justify-start align-center flex-wrap pa-0">
      <div class="w-100 d-flex justify-space-between flex-wrap align-center">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-tooltip bottom open-delay="500">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  outlined
                  class="px-1 ma-2"
                  :class="{
                    'green lighten-5 green--text text--darken-2':
                      !conversation.auto_pilot_on_hold,
                  }"
                  small
                  v-on="on"
                  @click="switchAutoPilotOnHold"
                >
                  <v-icon small>
                    {{
                      conversation.auto_pilot_on_hold ? 'mdi-play' : 'mdi-pause'
                    }}
                  </v-icon>
                  <span class="font-weight-semibold"
                    >AI
                    {{
                      conversation.auto_pilot_on_hold ? 'Paused' : 'Live'
                    }}</span
                  >
                </v-btn>
              </template>
              <span>{{
                conversation.auto_pilot_on_hold ? 'Resume AI' : 'Pause AI'
              }}</span>
            </v-tooltip>

            <confirmation-modal
              v-if="!isMobile"
              :text="`Please confirm manager assistance is needed?`"
              @action="moveToManager"
            >
              <template #activator="{ on }">
                <v-tooltip bottom open-delay="500">
                  <template #activator="{ on: tooltipOn, attrs }">
                    <v-btn
                      v-bind="attrs"
                      class="px-1 ma-2"
                      small
                      outlined
                      v-on="{ ...on, ...tooltipOn }"
                    >
                      <v-icon small> supervisor_account</v-icon>
                    </v-btn>
                  </template>
                  <span>Reassign to manager</span>
                </v-tooltip>
              </template>
            </confirmation-modal>

            <v-tooltip bottom open-delay="500">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="!isMobile"
                  v-bind="attrs"
                  outlined
                  class="px-1 ma-2"
                  small
                  :class="{
                    'red lighten-5 red--text text--darken-2':
                      conversation.escalated,
                  }"
                  v-on="on"
                  @click="switchEscalation"
                >
                  <v-icon small class="me-1">
                    {{
                      conversation.escalated ? 'mdi-bell' : 'mdi-bell-outline'
                    }}
                  </v-icon>
                  <span class="font-weight-semibold">{{
                    conversation.escalated ? 'AI escalted' : ''
                  }}</span>
                </v-btn>
              </template>
              <span>{{
                conversation.escalated
                  ? 'Remove escalation'
                  : 'Mark as escalated'
              }}</span>
            </v-tooltip>

            <v-tooltip bottom :open-delay="500">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <sale-btn
                    v-if="!isMobile && !activeReservation.agent_id"
                    :conversation-id="conversation.id"
                    :reservation-id="activeReservation.id"
                    :reservation-sellers="activeReservation.reservation_sellers"
                  />
                </div>
              </template>
              <span>Mark as sale</span>
            </v-tooltip>

            <v-chip v-if="integrationLabel" class="mr-2" label>
              {{ integrationLabel }}
            </v-chip>

            <v-btn
              v-if="conversation.direct_chat_link"
              height="32"
              class="mr-2"
              elevation="0"
              f
              x-small
              color="white"
              :href="conversation.direct_chat_link"
              target="_blank"
            >
              <v-icon small>public</v-icon>
            </v-btn>
            <div class="d-inline-block">
              <conversation-assignee
                :after-change="refetchConversations"
                :pre-selected="userById(conversation.assigned_user_id)"
                :conversation="conversation"
              />
            </div>
          </div>
        </div>
        <div class="d-flex align-center flex-1">
          <v-divider v-if="!isMobile" vertical class="mx-2" />
          <v-tooltip bottom open-delay="500">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <copy-clipboard :text="conversationLink" class="mx-1" />
              </div>
            </template>
            <span>Copy link</span>
          </v-tooltip>

          <v-tooltip v-if="!isMobile" bottom open-delay="500">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn-toggle
                  v-model="toggleBtn"
                  borderless
                  rounded
                  class="mx-1"
                >
                  <v-btn
                    value="notes"
                    icon
                    small
                    :disabled="notes.length === 0"
                  >
                    <v-icon small> $file</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </div>
            </template>
            <span>Notes</span>
          </v-tooltip>

          <v-tooltip v-if="!isMobile" bottom open-delay="500">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn small icon class="mx-1" @click="showTags">
                  <v-icon :color="showTagPop ? 'info' : ''" small>
                    mdi-tag-outline
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>Conversation tags</span>
          </v-tooltip>

          <v-tooltip bottom open-delay="500">
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <conversation-summary-dialog
                  v-if="conversation?.id"
                  class="mx-1"
                  :conversation-id="conversation.id"
                />
              </div>
            </template>
            <span>AI Summary</span>
          </v-tooltip>

          <follow-up-modal
            v-if="managementRoles || isCommunicationPerson"
            :conversation="conversation"
            :mini-button="true"
            @on-action="onFollowUpModalAction"
          />
          <v-tooltip v-if="conversation.allow_courtesy_early_check_in" bottom>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <Tag bg-color="yellow" color="black" class="pa-1">
                  Courtesy CI
                </Tag>
              </div>
            </template>
            <span
              >Guest is allowed to get in at 15:00 (even a bit earlier if he is
              already there) for free, please use saved message</span
            >
          </v-tooltip>
          <v-tooltip v-if="highRisk" bottom>
            <template #activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <Tag bg-color="red" color="white" class="pa-1">
                  Screening on
                </Tag>
              </div>
            </template>
            <span>High risk score please do some screaning</span>
          </v-tooltip>
          <v-divider v-if="!isMobile" vertical class="mx-2" />
          <v-rating
            v-if="!isMobile"
            v-model="conversation.fun_level"
            length="5"
            size="10"
            :disabled="conversation.fun_level"
            @input="setFunLevel($event, conversation.id)"
          />
        </div>
        <div v-if="isMobile" class="me-3">
          <v-icon class="pointer ms-2" @click="closeDrawer"
            >fas fa-times</v-icon
          >
        </div>
      </div>
    </v-sheet>
    <v-divider />
    <div
      v-if="conversation.thread_plus_dvr_log"
      ref="msgscroll"
      class="flex overflow-auto"
      style="height: 0"
    >
      <div
        v-for="(msg, index) in messages"
        :key="`msg-${index}`"
        class="d-flex"
      >
        <div v-if="msg.generated_by_ai" class="d-flex w-100 justify-end">
          <div
            v-if="!conversationLoading && requestPolicyApproval(msg)"
            class="ai-component align-baseline"
            style="margin-top: 20px"
          >
            <div class="center-text pb-3 bb-1 full-width ai-component-header">
              <img
                class="mr-2"
                style="width: 40px; height: 40px"
                src="AI-Config-2.svg"
              />
              <span><strong>Approval</strong></span>
              <span v-if="msg.approval_policy" class="cyan--text tooltip">
                <v-icon class="mr-2 cyan--text tooltipicon">$info</v-icon>
                <span class="tooltiptext">
                  {{ msg.approval_policy }}
                </span>
              </span>
            </div>
            <div v-if="msg.ai_status" class="ai-component-content">
              <v-radio-group v-model="msg.ai_approval_choice" disabled>
                <v-radio :value="1">
                  <template #label>
                    <div
                      v-if="aiApprovalMessage(msg)"
                      class="ai-component-message mt-3"
                      style="margin-left: 25px"
                    >
                      <span>
                        <span class="cyan--text">
                          <v-icon class="mr-2 cyan--text">$chat</v-icon>
                        </span>
                        <strong>Choice 1</strong>
                      </span>
                      <p v-html="aiApprovalMessage(msg)"></p>
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="2">
                  <template #label>
                    <div
                      v-if="aiRejectionMessage(msg)"
                      class="ai-component-message mt-3"
                      style="margin-left: 25px"
                    >
                      <span>
                        <span class="cyan--text">
                          <v-icon class="mr-2 cyan--text">$chat</v-icon>
                        </span>
                        <strong>Choice 2</strong>
                      </span>
                      <p v-html="aiRejectionMessage(msg)"></p>
                    </div>
                  </template>
                </v-radio>
                <v-radio v-if="msg.ai_approval_choice == 3" :value="3">
                  <template #label>
                    <div
                      class="ai-component-message mt-3"
                      style="width: 100%; margin-left: 25px"
                    >
                      <span>
                        <span class="cyan--text">
                          <v-icon class="mr-2 cyan--text">$chat</v-icon>
                        </span>
                        <strong>Regenerate</strong>
                      </span>
                      <textarea
                        v-model="msg.ai_approval_comment"
                        placeholder="Add Comments"
                        rows="5"
                        style="
                          width: 100%;
                          resize: none;
                          padding: 15px;
                          border-radius: 10px;
                          border: solid #00bcd4;
                          margin-top: 20px;
                        "
                      >
                      </textarea>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <div class="ai-component-buttons">
                <v-btn
                  class="ai-component-approve-btn"
                  @click="submitAiApproval(msg)"
                >
                  {{ 'Submitted' }}
                </v-btn>
              </div>
            </div>
            <div v-else class="ai-component-content">
              <v-radio-group v-model="AiApprovalChoice">
                <v-radio :value="1">
                  <template #label>
                    <div
                      v-if="aiApprovalMessage(msg)"
                      class="ai-component-message mt-3"
                      style="margin-left: 25px"
                    >
                      <span>
                        <span class="cyan--text">
                          <v-icon class="mr-2 cyan--text">$chat</v-icon>
                        </span>
                        <strong>Choice 1</strong>
                      </span>
                      <p v-html="aiApprovalMessage(msg)"></p>
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="2">
                  <template #label>
                    <div
                      v-if="aiRejectionMessage(msg)"
                      class="ai-component-message mt-3"
                      style="margin-left: 25px"
                    >
                      <span>
                        <span class="cyan--text">
                          <v-icon class="mr-2 cyan--text">$chat</v-icon>
                        </span>
                        <strong>Choice 2</strong>
                      </span>
                      <p v-html="aiRejectionMessage(msg)"></p>
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="3">
                  <template #label>
                    <div
                      class="ai-component-message mt-3"
                      style="width: 100%; margin-left: 25px"
                    >
                      <span>
                        <span class="cyan--text">
                          <v-icon class="mr-2 cyan--text">$chat</v-icon>
                        </span>
                        <strong>Regenerate</strong>
                      </span>
                      <textarea
                        v-model="aiApprovalComment"
                        placeholder="Add Comments"
                        rows="5"
                        style="
                          width: 100%;
                          resize: none;
                          padding: 15px;
                          border-radius: 10px;
                          border: solid #00bcd4;
                          margin-top: 20px;
                        "
                      >
                      </textarea>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <div class="ai-component-buttons">
                <v-btn
                  class="ai-component-approve-btn"
                  @click="submitAiApproval(msg)"
                >
                  {{ 'Submit' }}
                </v-btn>
              </div>
            </div>
          </div>
          <div
            v-if="
              !conversationLoading &&
              !requestPolicyApproval(msg) &&
              (hasAbility('ai-config') || isSuperAdmin) &&
              (dryRunMessage(msg) || msg.dry_run_in_progress)
            "
            class="float-right ai-component align-baseline max-w-85 ma-2 pa-3 text-body-2 text-sm-body-1 scale-animation"
            style="margin-right: 18px !important"
          >
            <div style="width: 100%; visibility: hidden">
              <v-btn outlined x-small color="blue-grey" class="float-right">
                <v-icon class="me-1" x-small color="purple darken-4"
                  >mdi-creation</v-icon
                >
                Dry Run
              </v-btn>
            </div>
            <div
              v-if="!msg.dry_run_in_progress"
              class="center-text pb-3 bb-1 full-width ai-component-header"
            >
              <span><strong>Dry Run</strong></span>
            </div>
            <div v-if="!msg.dry_run_in_progress" class="ai-component-content">
              <div
                v-if="
                  !aiSuggestionMessage(dryRunMessage(msg)) &&
                  (!aiSuggestionActions(dryRunMessage(msg)) ||
                    aiSuggestionActions(dryRunMessage(msg)).length === 0)
                "
                class="ai-component-message mt-3"
              >
                <div
                  class="center-text pb-3 bb-1 full-width ai-component-no-suggestion"
                >
                  <img
                    class="mr-2"
                    style="width: 60px; height: 60px"
                    src="no messages.svg"
                  />
                  <span style="font-size: 12px; margin-left: 10px">
                    <strong>No AI Suggestion Found</strong>
                  </span>
                </div>
              </div>
              <div
                v-if="aiSuggestionMessage(dryRunMessage(msg))"
                class="ai-component-message mt-3"
              >
                <span>
                  <span class="cyan--text">
                    <v-icon class="mr-2 cyan--text">$chat</v-icon>
                  </span>
                  <strong>Message</strong>
                  <span
                    v-if="aiSuggestionMessageTranslation(dryRunMessage(msg))"
                    class="cyan--text tooltip"
                  >
                    <v-icon class="mr-2 cyan--text tooltipicon">
                      mdi-translate
                    </v-icon>
                    <span class="tooltiptext">
                      {{ aiSuggestionMessageTranslation(dryRunMessage(msg)) }}
                    </span>
                  </span>
                  <span
                    v-if="aiSuggestionMessageReason(dryRunMessage(msg))"
                    class="cyan--text tooltip"
                  >
                    <v-icon class="mr-2 cyan--text tooltipicon">$info</v-icon>
                    <span class="tooltiptext">
                      {{ aiSuggestionMessageReason(dryRunMessage(msg)) }}
                    </span>
                  </span>
                </span>
                <p v-html="aiSuggestionMessage(dryRunMessage(msg))"></p>
              </div>
              <div
                v-for="co_pilot_action in aiSuggestionActions(
                  dryRunMessage(msg)
                )"
                :key="co_pilot_action.action_name"
                class="ai-component-action"
              >
                <div>
                  <span class="cyan--text">
                    <v-icon class="mr-2">{{
                      iconForAction(co_pilot_action)
                    }}</v-icon>
                  </span>
                  <strong>
                    {{ labelForAction(co_pilot_action) }}
                    <span
                      v-if="
                        co_pilot_action.action_name ===
                          'create_multi_follow_ups' &&
                        co_pilot_action.payload &&
                        co_pilot_action.payload['1st_follow_up'] &&
                        co_pilot_action.payload['1st_follow_up'].human_time
                      "
                      class="ml-1"
                    >
                      ({{
                        co_pilot_action.payload['1st_follow_up'].human_time
                      }})
                    </span>
                  </strong>
                  <span
                    v-if="
                      co_pilot_action.action_name === 'create_multi_follow_ups'
                    "
                    height="32"
                    class="mr-2 cyan--text followup-tooltip followup-btn"
                    elevation="0"
                    x-small
                    @click="followUpNow(msg.id)"
                  >
                    <v-icon class="followup-tooltipicon">
                      mdi-send-clock
                    </v-icon>
                    <span class="followup-tooltiptext">Follow-Up Now</span>
                  </span>
                  <span
                    v-if="
                      co_pilot_action.payload &&
                      co_pilot_action.payload['1st_follow_up'] &&
                      co_pilot_action.payload['1st_follow_up'].followup_message
                    "
                    class="cyan--text tooltip"
                  >
                    <v-icon class="mr-2 cyan--text tooltipicon">$info</v-icon>
                    <span class="tooltiptext">
                      {{
                        co_pilot_action.payload['1st_follow_up']
                          .followup_message
                      }}
                    </span>
                  </span>
                </div>
                <div
                  v-if="
                    co_pilot_action.action_name === 'update_service_call' &&
                    co_pilot_action.payload
                  "
                >
                  <div class="diff-container">
                    <div v-if="co_pilot_action.payload.status">
                      <div>
                        <span class="was"> Previous Status: </span>
                        <span>
                          {{ co_pilot_action.payload.status.before }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be"> Updated Status: </span>
                        <span>
                          {{ co_pilot_action.payload.status.after }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        co_pilot_action.payload.status &&
                        (co_pilot_action.payload.description ||
                          co_pilot_action.payload.internal_comments ||
                          co_pilot_action.payload.before_images)
                      "
                      class="divider"
                    ></div>
                    <div v-if="co_pilot_action.payload.description">
                      <div>
                        <span class="was"> Previous Description: </span>
                        <span>
                          {{
                            normalizeData(
                              co_pilot_action.payload.description.before
                            )
                          }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be"> Updated Description: </span>
                        <span>
                          {{
                            normalizeData(
                              co_pilot_action.payload.description.after
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        co_pilot_action.payload.description &&
                        (co_pilot_action.payload.internal_comments ||
                          co_pilot_action.payload.before_images)
                      "
                      class="divider"
                    ></div>
                    <div v-if="co_pilot_action.payload.internal_comments">
                      <div>
                        <span class="was"> Previous Comments: </span>
                        <span>
                          {{
                            normalizeData(
                              co_pilot_action.payload.internal_comments.before
                            )
                          }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be"> Updated Comments: </span>
                        <span>
                          {{
                            normalizeData(
                              co_pilot_action.payload.internal_comments.after
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        co_pilot_action.payload.internal_comments &&
                        co_pilot_action.payload.before_images
                      "
                      class="divider"
                    ></div>
                    <div v-if="co_pilot_action.payload.before_images">
                      <div
                        v-if="
                          normalizeData(
                            co_pilot_action.payload.before_images.before
                          ) !== 'none'
                        "
                        class="was"
                      >
                        <span>Media:</span>
                        <a
                          v-for="(image, index) in normalizeData(
                            co_pilot_action.payload.before_images.before
                          )"
                          :key="`desc-image-${index}`"
                          :href="image"
                          target="blank"
                        >
                          {{ image }}
                        </a>
                      </div>
                      <div v-else>
                        <span class="was">Media: none</span>
                      </div>
                      <div
                        v-if="
                          normalizeData(
                            co_pilot_action.payload.before_images.after
                          ) !== 'none'
                        "
                        class="will-be"
                      >
                        <span>Media:</span>
                        <a
                          v-for="(image, index) in normalizeData(
                            co_pilot_action.payload.before_images.after
                          )"
                          :key="`desc-image-${index}`"
                          :href="image"
                          target="blank"
                        >
                          {{ image }}
                        </a>
                      </div>
                      <div v-else>
                        <span class="will-be">Media: none</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="
                    co_pilot_action.action_name === 'update_reservation' &&
                    co_pilot_action.payload
                  "
                >
                  <div class="diff-container">
                    <div v-if="co_pilot_action.payload.planned_arrival">
                      <div>
                        <span class="was"> Previous Planned Arrival: </span>
                        <span>
                          {{ co_pilot_action.payload.planned_arrival?.before }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be"> Updated Planned Arrival: </span>
                        <span>
                          {{ co_pilot_action.payload.planned_arrival?.after }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        co_pilot_action.payload.planned_arrival &&
                        co_pilot_action.payload.planned_departure
                      "
                      class="divider"
                    ></div>
                    <div v-if="co_pilot_action.payload.planned_departure">
                      <div>
                        <span class="was"> Previous Planned Departure: </span>
                        <span>
                          {{
                            co_pilot_action.payload.planned_departure?.before
                          }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be">
                          Updated Planned Departure:
                        </span>
                        <span>
                          {{ co_pilot_action.payload.planned_departure?.after }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="
                    co_pilot_action.action_name === 'process_guest_checkout' &&
                    co_pilot_action.payload
                  "
                >
                  <div class="diff-container">
                    <div>
                      <div>
                        <span>
                          Planned Departure:
                          {{ co_pilot_action.payload.planned_departure }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{
                    co_pilot_action.payload &&
                    (co_pilot_action.payload.description ||
                      (co_pilot_action.payload['1st_follow_up'] &&
                        co_pilot_action.payload['1st_follow_up']
                          .followup_message))
                  }}
                </div>
              </div>
            </div>
            <div v-if="msg.dry_run_in_progress" class="ai-component-content">
              <div class="text-center">
                <ai-anim />
              </div>
            </div>
            <div
              v-if="
                !msg.dry_run_in_progress &&
                (aiSuggestionMessage(msg) || aiSuggestionActions(msg))
              "
              class="ai-component-buttons"
              style="visibility: hidden"
            >
              <v-btn :disabled="true" class="ai-component-reject-btn">
                {{
                  dryRunMessage(msg).ai_status === 'rejected'
                    ? 'Rejected'
                    : 'Reject'
                }}
              </v-btn>
              <v-btn :disabled="true" class="ai-component-approve-btn">
                {{
                  dryRunMessage(msg).ai_status === 'approved'
                    ? 'Approved'
                    : 'Approve'
                }}
              </v-btn>
            </div>
            <div v-if="!msg.dry_run_in_progress" class="conversation-tags">
              <div
                v-for="conversationTag in dryRunMessage(msg).ai_tags"
                :key="conversationTag"
                class="conversation-tag"
                style="background-color: var(--v-primary-darken2)"
              >
                {{ conversationTag }}
              </div>
            </div>
            <div
              v-if="
                !msg.dry_run_in_progress && dryRunMessage(msg).detected_policy
              "
              class="conversation-tags"
            >
              <div
                class="conversation-tag"
                style="background-color: var(--v-warning-darken1)"
              >
                Detected Policy: {{ dryRunMessage(msg).detected_policy }}
              </div>
            </div>
            <div
              v-if="!msg.dry_run_in_progress && isSuperAdmin"
              class="conversation-tags"
            >
              <div
                v-if="dryRunMessage(msg).detected_policy"
                class="conversation-tag"
                style="background-color: var(--v-warning-darken1)"
              >
                Detected Policy: {{ dryRunMessage(msg).detected_policy }}
              </div>
              <div
                v-if="
                  dryRunMessage(msg).sales_stages &&
                  dryRunMessage(msg).sales_stages.current_stage
                "
                class="conversation-tag"
                style="background-color: var(--v-warning-darken1)"
              >
                Current Sales Stage:
                {{ dryRunMessage(msg).sales_stages.current_stage }}
              </div>
              <div
                v-if="
                  dryRunMessage(msg).sales_stages &&
                  dryRunMessage(msg).sales_stages.missing_info &&
                  dryRunMessage(msg).sales_stages.missing_info.length
                "
                class="conversation-tag"
                style="background-color: var(--v-warning-darken1)"
              >
                Missing Sales Info:
                {{ dryRunMessage(msg).sales_stages.missing_info }}
              </div>
              <div
                v-if="
                  dryRunMessage(msg).sales_stages &&
                  dryRunMessage(msg).sales_stages.next_step
                "
                class="conversation-tag"
                style="background-color: var(--v-warning-darken1)"
              >
                Next Sales Step: {{ dryRunMessage(msg).sales_stages.next_step }}
              </div>
            </div>
          </div>
          <div
            v-if="
              !conversationLoading &&
              !requestPolicyApproval(msg) &&
              !msg.dry_run
            "
            class="float-right ai-component align-baseline max-w-85 ma-2 pa-3 text-body-2 text-sm-body-1"
          >
            <div style="width: 100%" class="d-flex align-center justify-end">
              <v-btn
                v-if="hasAbility('ai-config') || isSuperAdmin"
                outlined
                x-small
                color="blue-grey"
                @click="dryRun(msg)"
              >
                Dry Run
              </v-btn>
              <ai-train-modal
                class="ms-2 scale-up"
                :train-messages="[
                  ...trainMessagesFor(msg),
                  messageForTrainAgent(msg),
                ]"
                :conversation="conversation"
                :msg="messageForTrainAgent(msg)"
              />
            </div>
            <div class="center-text pb-3 bb-1 full-width ai-component-header">
              <img
                class="mr-2"
                style="width: 40px; height: 40px"
                src="AI-Config-2.svg"
              />
              <span><strong>Agent</strong></span>
            </div>
            <div class="ai-component-content">
              <div
                v-if="
                  !aiSuggestionMessage(msg) &&
                  (!aiSuggestionActions(msg) ||
                    aiSuggestionActions(msg).length === 0)
                "
                class="ai-component-message mt-3"
              >
                <div
                  class="center-text pb-3 bb-1 full-width ai-component-no-suggestion"
                >
                  <img
                    class="mr-2"
                    style="width: 60px; height: 60px"
                    src="no messages.svg"
                  />
                  <span style="font-size: 12px; margin-left: 10px">
                    <strong>No AI Suggestion Found</strong>
                  </span>
                </div>
              </div>
              <div
                v-if="aiSuggestionMessage(msg)"
                class="ai-component-message mt-3"
              >
                <span>
                  <span class="cyan--text">
                    <v-icon class="mr-2 cyan--text">$chat</v-icon>
                  </span>
                  <strong>Message</strong>
                  <span
                    v-if="aiSuggestionMessageTranslation(msg)"
                    class="cyan--text tooltip"
                  >
                    <v-icon class="mr-2 cyan--text tooltipicon">
                      mdi-translate
                    </v-icon>
                    <span class="tooltiptext">
                      {{ aiSuggestionMessageTranslation(msg) }}
                    </span>
                  </span>
                  <span
                    v-if="aiSuggestionMessageReason(msg)"
                    class="cyan--text tooltip"
                  >
                    <v-icon class="mr-2 cyan--text tooltipicon">$info</v-icon>
                    <span class="tooltiptext">
                      {{ aiSuggestionMessageReason(msg) }}
                    </span>
                  </span>
                </span>
                <p v-html="aiSuggestionMessage(msg)"></p>
              </div>
              <div
                v-for="co_pilot_action in aiSuggestionActions(msg)"
                :key="co_pilot_action.action_name"
                class="ai-component-action"
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div style="display: flex; align-items: center">
                    <span class="cyan--text">
                      <v-icon class="mr-2">{{
                        iconForAction(co_pilot_action)
                      }}</v-icon>
                    </span>
                    <strong>
                      {{ labelForAction(co_pilot_action) }}
                      <span
                        v-if="
                          co_pilot_action.action_name ===
                            'create_multi_follow_ups' &&
                          co_pilot_action.payload &&
                          co_pilot_action.payload['1st_follow_up'] &&
                          co_pilot_action.payload['1st_follow_up'].human_time
                        "
                        class="ml-1"
                      >
                        ({{
                          co_pilot_action.payload['1st_follow_up'].human_time
                        }})
                      </span>
                    </strong>
                  </div>

                  <div style="display: flex; align-items: center">
                    <span
                      v-if="
                        co_pilot_action.action_name ===
                        'create_multi_follow_ups'
                      "
                      height="32"
                      class="mr-2 cyan--text followup-tooltip followup-btn"
                      elevation="0"
                      x-small
                      @click="followUpNow(msg.id)"
                    >
                      <v-icon class="followup-tooltipicon">
                        mdi-send-clock
                      </v-icon>
                      <span class="followup-tooltiptext">Follow-Up Now</span>
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    co_pilot_action.action_name === 'update_service_call' &&
                    co_pilot_action.payload
                  "
                >
                  <div class="diff-container">
                    <div v-if="co_pilot_action.payload.status">
                      <div>
                        <span class="was"> Previous Status: </span>
                        <span>
                          {{ co_pilot_action.payload.status.before }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be"> Updated Status: </span>
                        <span>
                          {{ co_pilot_action.payload.status.after }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        co_pilot_action.payload.status &&
                        (co_pilot_action.payload.description ||
                          co_pilot_action.payload.internal_comments ||
                          co_pilot_action.payload.before_images)
                      "
                      class="divider"
                    ></div>
                    <div v-if="co_pilot_action.payload.description">
                      <div>
                        <span class="was"> Previous Description: </span>
                        <span>
                          {{
                            normalizeData(
                              co_pilot_action.payload.description.before
                            )
                          }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be"> Updated Description: </span>
                        <span>
                          {{
                            normalizeData(
                              co_pilot_action.payload.description.after
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        co_pilot_action.payload.description &&
                        (co_pilot_action.payload.internal_comments ||
                          co_pilot_action.payload.before_images)
                      "
                      class="divider"
                    ></div>
                    <div v-if="co_pilot_action.payload.internal_comments">
                      <div>
                        <span class="was"> Previous Comments: </span>
                        <span>
                          {{
                            normalizeData(
                              co_pilot_action.payload.internal_comments.before
                            )
                          }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be"> Updated Comments: </span>
                        <span>
                          {{
                            normalizeData(
                              co_pilot_action.payload.internal_comments.after
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        co_pilot_action.payload.internal_comments &&
                        co_pilot_action.payload.before_images
                      "
                      class="divider"
                    ></div>
                    <div v-if="co_pilot_action.payload.before_images">
                      <div
                        v-if="
                          normalizeData(
                            co_pilot_action.payload.before_images.before
                          ) !== 'none'
                        "
                        class="was"
                      >
                        <span>Media:</span>
                        <a
                          v-for="(image, index) in normalizeData(
                            co_pilot_action.payload.before_images.before
                          )"
                          :key="`desc-image-${index}`"
                          :href="image"
                          target="blank"
                        >
                          {{ image }}
                        </a>
                      </div>
                      <div v-else>
                        <span class="was">Media: none</span>
                      </div>
                      <div
                        v-if="
                          normalizeData(
                            co_pilot_action.payload.before_images.after
                          ) !== 'none'
                        "
                        class="will-be"
                      >
                        <span>Media:</span>
                        <a
                          v-for="(image, index) in normalizeData(
                            co_pilot_action.payload.before_images.after
                          )"
                          :key="`desc-image-${index}`"
                          :href="image"
                          target="blank"
                        >
                          {{ image }}
                        </a>
                      </div>
                      <div v-else>
                        <span class="will-be">Media: none</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="
                    co_pilot_action.action_name === 'update_reservation' &&
                    co_pilot_action.payload
                  "
                >
                  <div class="diff-container">
                    <div v-if="co_pilot_action.payload.planned_arrival">
                      <div>
                        <span class="was"> Previous Planned Arrival: </span>
                        <span>
                          {{ co_pilot_action.payload.planned_arrival?.before }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be"> Updated Planned Arrival: </span>
                        <span>
                          {{ co_pilot_action.payload.planned_arrival?.after }}
                        </span>
                      </div>
                    </div>
                    <div
                      v-if="
                        co_pilot_action.payload.planned_arrival &&
                        co_pilot_action.payload.planned_departure
                      "
                      class="divider"
                    ></div>
                    <div v-if="co_pilot_action.payload.planned_departure">
                      <div>
                        <span class="was"> Previous Planned Departure: </span>
                        <span>
                          {{
                            co_pilot_action.payload.planned_departure?.before
                          }}
                        </span>
                      </div>
                      <div>
                        <span class="will-be">
                          Updated Planned Departure:
                        </span>
                        <span>
                          {{ co_pilot_action.payload.planned_departure?.after }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="
                    co_pilot_action.action_name === 'process_guest_checkout' &&
                    co_pilot_action.payload
                  "
                >
                  <div class="diff-container">
                    <div>
                      <div>
                        <span>
                          Planned Departure:
                          {{ co_pilot_action.payload.planned_departure }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="
                    co_pilot_action.payload &&
                    co_pilot_action.payload['meta'] &&
                    co_pilot_action.payload['meta']['steps'] &&
                    co_pilot_action.payload['meta']['active_step']
                  "
                >
                  <div
                    class="step-navigation"
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      font-style: 1.2em;
                    "
                  >
                    <div class="step-info">
                      <span>
                        {{
                          co_pilot_action['payload']['meta']['steps'].indexOf(
                            activeFollowUpFor(msg, co_pilot_action)
                          ) + 1
                        }}
                        /
                        {{ co_pilot_action['payload']['meta']['steps'].length }}
                      </span>
                    </div>

                    <div class="arrows-container">
                      <a
                        class="arrow-left"
                        @click="prevFollowUpStep(msg, co_pilot_action)"
                      >
                        <span class="mdi mdi-arrow-left-bold-outline"></span>
                      </a>
                      <a
                        class="arrow-right"
                        @click="nextFollowUpStep(msg, co_pilot_action)"
                      >
                        <span class="mdi mdi-arrow-right-bold-outline"></span>
                      </a>
                    </div>
                  </div>
                  <div
                    v-for="followupStep in stepsFor(co_pilot_action)"
                    :key="followupStep"
                    :class="
                      followupStep === activeFollowUpFor(msg, co_pilot_action)
                        ? 'followup-active'
                        : 'followup-unactive'
                    "
                  >
                    {{ co_pilot_action.payload[followupStep].followup_message }}
                  </div>
                </div>
                <div v-else>
                  {{
                    co_pilot_action.payload &&
                    co_pilot_action.payload.description
                  }}
                </div>
              </div>
            </div>
            <div
              v-if="aiSuggestionMessage(msg) || aiSuggestionActions(msg)"
              class="ai-component-buttons"
            >
              <v-btn
                :disabled="msg.ai_status === 'approved'"
                class="ai-component-reject-btn"
                :x-small="isMobile"
                depressed
                @click="rejectAiSuggestion(msg)"
              >
                {{ msg.ai_status === 'rejected' ? 'Rejected' : 'Reject' }}
              </v-btn>
              <v-btn
                :disabled="msg.ai_status === 'rejected'"
                class="ai-component-approve-btn"
                depressed
                :x-small="isMobile"
                @click="approveAiSuggestion(msg)"
              >
                {{ msg.ai_status === 'approved' ? 'Approved' : 'Approve' }}
              </v-btn>
              <div
                v-if="
                  (index === messages?.length - 1 ||
                    checkLastAgentMessage(msg)) &&
                  !(
                    msg.ai_status === 'approved' || msg.ai_status === 'rejected'
                  )
                "
                style="font-size: 13px"
                class="d-flex"
              >
                <v-btn
                  color="purple lighten-2 me-1"
                  class="white--text"
                  style="margin-left: 4px"
                  :x-small="isMobile"
                  depressed
                  @click="regenerateAiSuggestion"
                >
                  <v-icon class="me-2" small color="white">refresh</v-icon>
                  REGENERATE
                </v-btn>
              </div>
            </div>
            <div v-if="false" class="conversation-tags">
              <div
                v-for="conversationTag in msg.ai_tags"
                :key="conversationTag"
                class="conversation-tag"
              >
                {{ conversationTag }}
              </div>
            </div>
            <div class="conversation-tags">
              <div
                v-if="msg.detected_policy"
                class="conversation-tag secondary"
              >
                Detected Policy: {{ msg.detected_policy }}
              </div>
              <div
                v-if="msg.sales_stages && msg.sales_stages.current_stage"
                class="conversation-tag primary"
              >
                Current Sales Stage: {{ msg.sales_stages.current_stage }}
              </div>
              <div v-if="false" class="conversation-tag info">
                Missing Sales Info:
                {{ msg.sales_stages.missing_info }}
              </div>
              <div v-if="false" class="conversation-tag info">
                Next Sales Step: {{ msg.sales_stages.next_step }}
              </div>
            </div>
          </div>
        </div>
        <img
          v-else-if="msg.msg_type === 'img'"
          :src="msg.body"
          class="img ma-6"
        />
        <div v-else-if="msg.msg_type === 'video'" style="display: flex">
          <video
            class="ma-6"
            :max-width="isMobile ? '85%' : '60%'"
            controls
            :src="msg.body"
          />
          <span
            v-if="transcodedAudios(msg)"
            class="cyan--text tooltip"
            style="margin-top: auto; margin-bottom: 33px; margin-left: -15px"
          >
            <v-icon class="mr-2 cyan--text tooltipicon">mdi-transcribe</v-icon>
            <span class="tooltiptext">
              {{ transcodedAudios(msg) }}
            </span>
          </span>
        </div>
        <msg-box
          v-else-if="msg.msg_type !== 'log'"
          :msg="msg"
          :train-messages="trainMessagesFor(msg)"
          :conversation="conversation"
          :collapsable="
            msg.by === 'host' &&
            !msg.rep_name &&
            msg.body &&
            msg.body.length > 50
          "
        />
        <log-line v-else :msg="msg" />
      </div>
    </div>
    <conversation-tag-pop v-if="showTagPop" />
  </div>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import get from 'lodash/fp/get'
import axios from 'axios'
import CopyClipboard from 'components/copy-clipboard'
import MsgBox from 'components/msg-box'
import FollowUpModal from 'components/conversations/follow-up-modal'
import ConversationAssignee from './chat/conversation-assignee'
import LogLine from 'components/chat/log-line'
import Tag from 'components/common/tag'
import ConversationTagPop from 'components/conversation-tag-pop'
import { mapGetters, mapMutations, mapState } from 'vuex'
import orderBy from 'lodash/fp/orderBy'
import filter from 'lodash/fp/filter'
import compose from 'lodash/fp/compose'
import SaleBtn from 'components/crm/sale-btn.vue'
import ConfirmationModal from 'components/modals/confirmation-modal'
import deviceMixin from 'components/mixins/device-mixin'
import AiAnim from 'components/loaders/ai-anim.vue'
import AiTrainModal from 'components/conversations/ai-train-modal.vue'
import ConversationSummaryDialog from 'components/crm/conversation-summary-dialog.vue'

const extractNotes = compose(
  orderBy(['sent_at'], ['desc']),
  filter(i => i.module === 'note' && !i.generated_by_ai)
)
export default {
  components: {
    ConfirmationModal,
    SaleBtn,
    ConversationTagPop,
    Tag,
    ConversationAssignee,
    FollowUpModal,
    MsgBox,
    CopyClipboard,
    LogLine,
    AiAnim,
    AiTrainModal,
    ConversationSummaryDialog,
  },
  mixins: [CommonFunctions, PermissionsMixin, deviceMixin],
  props: ['conversation', 'getConversations', 'drawerMode'],
  computed: {
    ...mapState(['showTagPop']),
    ...mapGetters(['chosenChatReservation', 'conversationLoading']),
    activeReservation() {
      return get('active_reservation', this.conversation) || {}
    },
    conversationLink() {
      return `${location.origin}/dashboard/sales-chat/${this.conversation.guesty_id}`
    },
    integrationLabel() {
      return this.chosenChatReservation.integration &&
        this.chosenChatReservation.integration.platform === 'airbnb2'
        ? this.chosenChatReservation.integration.nickname
        : ''
    },
    highRisk() {
      return (
        this.chosenChatReservation.status === 'inquiry' &&
        this.chosenChatReservation.risk_analysis &&
        this.chosenChatReservation.risk_analysis.risk_score > 0.8
      )
    },
    conversationId() {
      return get('id', this.conversation)
    },
    linkRegex() {
      return /((https?|ftps?):\/\/[^"'<(,)>\s]+)(?![^<>]*>|[^"]*?<\/a)/gim
    },
    notes() {
      return extractNotes(this.conversation.thread_plus_dvr_log)
    },
    notesOn() {
      return this.toggleBtn === 'notes'
    },
    messages() {
      if (this.notesOn) {
        return this.notes
      }
      return this.conversation.thread_plus_dvr_log
    },
    threadLength() {
      const filteredMessages = this.messages.filter(e => !e.dry_run)
      if (filteredMessages) {
        return filteredMessages.length
      }
      return 0
    },
    isSellerAssigned() {
      return this.reservationSellers.length
    },
  },
  mounted() {
    this.reservationSellers = this.activeReservation.reservation_sellers || []
    this.scrollToEnd()
    setTimeout(() => {
      this.scrollToEnd()
    }, 250)
  },
  data() {
    return {
      toggleBtn: null,
      isTagPop: false,
      reservationSellers: [],
      aiApprovalComment: null,
      AiApprovalChoice: null,
      followUpActiveSteps: {},
    }
  },
  methods: {
    ...mapMutations(['updateShowTagPop']),
    trainMessagesFor(msg) {
      const filteredMessages = this.messages
        .filter(
          e =>
            e.sent_at <= msg.sent_at &&
            !e.generated_by_ai &&
            ['email', 'sms', 'whatsapp'].includes(e.msg_type)
        )
        .slice(-3)
      return filteredMessages
    },
    messageForTrainAgent(msg) {
      return { ...msg, body: JSON.parse(msg.body)?.message }
    },
    checkLastAgentMessage(msg) {
      const notDryRunMessages = this.messages?.filter(
        e => e.dry_run !== true && e.by === 'host'
      )
      return msg.id === notDryRunMessages[notDryRunMessages?.length - 1]?.id
    },
    stepsFor(aiAction) {
      if (
        aiAction.payload &&
        aiAction.payload['meta'] &&
        aiAction.payload['meta']['steps']
      ) {
        return aiAction.payload['meta']['steps'].filter(
          step => aiAction.payload[step]
        )
      } else {
        return []
      }
    },
    activeFollowUpFor(msg, aiAction) {
      return (
        this.followUpActiveSteps[msg.id] ||
        (aiAction &&
          aiAction['payload'] &&
          aiAction['payload']['meta'] &&
          aiAction['payload']['meta']['active_step'])
      )
    },
    prevFollowUpStep(msg, aiAction) {
      if (
        aiAction &&
        aiAction['payload'] &&
        aiAction['payload']['meta'] &&
        aiAction['payload']['meta']['steps'] &&
        aiAction['payload']['meta']['active_step']
      ) {
        const activeStep =
          this.followUpActiveSteps[msg.id] ||
          aiAction['payload']['meta']['active_step']
        let prevIndex =
          aiAction['payload']['meta']['steps'].indexOf(activeStep) - 1

        if (prevIndex < 0) {
          prevIndex = aiAction['payload']['meta']['steps'].length - 1
        }

        this.followUpActiveSteps[msg.id] =
          aiAction['payload']['meta']['steps'][prevIndex]
      }
    },
    nextFollowUpStep(msg, aiAction) {
      if (
        aiAction &&
        aiAction['payload'] &&
        aiAction['payload']['meta'] &&
        aiAction['payload']['meta']['active_step']
      ) {
        const activeStep =
          this.followUpActiveSteps[msg.id] ||
          aiAction['payload']['meta']['active_step']
        let nextIndex =
          aiAction['payload']['meta']['steps'].indexOf(activeStep) + 1

        if (nextIndex >= aiAction['payload']['meta']['steps'].length) {
          nextIndex = 0
        }

        this.followUpActiveSteps[msg.id] =
          aiAction['payload']['meta']['steps'][nextIndex]
      }
    },
    closeDrawer() {
      this.$router.push({ query: {} })
    },
    refetchConversations() {
      this.getConversations({ isRefresh: true })
    },
    async setFunLevel(value, id) {
      await this.$store.dispatch('conversationUpdate', {
        fun_level: value,
        id: id,
      })
      this.$emit('update-conversations')
    },
    switchNewLine(message) {
      if (message) {
        return message
          .replace(/(?:\r\n|\r|\n)/g, '<br>')
          .replace(
            this.linkRegex,
            '<a style="word-break: break-all" href="$1" target="_blank">$1</a>'
          )
      }
    },
    messageClass(msg) {
      if (msg.msg_type === 'log') {
        return 'full-width'
      } else if (msg.by === 'guest') {
        return 'guest-msg'
      } else if (msg.by !== 'guest' && msg.module === 'note') {
        return 'note-msg'
      } else if (!msg.rep_name && msg.by !== 'guest') {
        return 'staff-msg'
      } else {
        return 'staff-msg'
      }
    },
    scrollToEnd() {
      var content = this.$refs.msgscroll
      if (content) {
        content.scrollTop = content.scrollHeight
      }
    },
    showTags() {
      this.updateShowTagPop(true)
    },
    moveToManager() {
      this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        move_to_manager: true,
      })
    },
    onFollowUpModalAction() {
      this.$store.commit('updateConversationsPagination', null)
      this.$emit('update-conversations')
      if (this.getConversations) {
        this.getConversations()
      }
    },
    regenerateAiSuggestion() {
      this.$emit('regenerate-ai-answer')
    },
    async rejectAiSuggestion(msg) {
      if (msg.ai_status) {
        return
      }

      this.$store.commit('showModal', {
        name: 'AiRejectModal',
        maxWidth: 400,
        props: {
          conversationId: this.conversation.id,
          messageId: msg.id,
        },
      })
    },
    async followUpNow(msg_id) {
      axios.post(`/api/conversations/follow-up-now?message_id=${msg_id}`)
    },
    async approveAiSuggestion(msg) {
      if (msg.ai_status) {
        return
      }
      msg.ai_status = 'pending'
      await axios.post(`/api/conversations/${msg.id}/approve-ai-suggestion`)
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
      })
      this.$emit('approve-ai-suggestion', this.aiPureMessage(msg))
    },
    async submitAiApproval(msg) {
      if (msg.ai_status) {
        return
      }

      await axios.post(`/api/conversations/submit-ai-approval`, {
        comments: this.aiApprovalComment,
        message_id: msg.id,
        approval_choice: this.AiApprovalChoice,
      })
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
      })
      this.aiApprovalComment = null
      this.AiApprovalChoice = null
    },
    dryRunMessage(originalMessage) {
      const filteredMessages = this.messages.filter(
        e => e.dry_run && e.id === originalMessage.dry_run_message_id
      )

      if (filteredMessages.length === 0) {
        return
      }

      return filteredMessages.reduce((max, message) => {
        return max.sent_at > message.sent_at ? max : message
      }, filteredMessages[0])
    },
    async dryRun(msg) {
      axios.post(`/api/conversations/dry-run`, {
        original_message_id: msg.id,
      })
    },
    aiPureMessage(msg) {
      return JSON.parse(msg.body).message
    },
    aiApprovalMessage(msg) {
      let message = JSON.parse(msg.body).approve_message
      if (!message) {
        return null
      }

      return this.normalizeMessage(message)
    },
    aiRejectionMessage(msg) {
      let message = JSON.parse(msg.body).reject_message
      if (!message) {
        return null
      }

      return this.normalizeMessage(message)
    },
    aiSuggestionMessage(msg) {
      let message = JSON.parse(msg.body).message
      if (!message) {
        return null
      }

      return this.normalizeMessage(message)
    },
    normalizeMessage(message) {
      if (typeof message === 'object') message = JSON.stringify(message)
      if (message) {
        message = message.replace(
          /\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g,
          `<span style="display: inline-block; word-wrap: break-word; max-width: 100%"; overflow: hidden; white-space: nowrap; text-overflow: ellipsis>
             <a href="$2" target="_blank">$1</a>
           </span>`
        )

        message = message.replace(
          /(?:^|\s)((?:https?:\/\/[^\s/$.?#].[^\s]*))/g,
          `<span style="display: inline-block; word-wrap: break-word; max-width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">
             <a href="$1" target="_blank">$1</a>
           </span>`
        )
      }

      return message
    },
    aiSuggestionMessageTranslation(msg) {
      return JSON.parse(msg.body).message_translation
    },
    aiSuggestionMessageReason(msg) {
      return JSON.parse(msg.body).message_reason
    },
    requestPolicyApproval(msg) {
      return JSON.parse(msg.body)['ai_flow'] === 'request_policy_approval'
    },
    normalizeData(data) {
      if (!data || data.length === 0) {
        return 'none'
      }
      return data
    },
    aiSuggestionActions(msg) {
      const actions = JSON.parse(msg.body)?.actions?.filter(
        e => e.requires_action && this.labelForAction(e)
      )
      if (!actions) return
      let sortedActions = []
      let action = actions.find(e => e.action_name === 'create_service_call')
      if (action) {
        sortedActions.push(action)
      }
      action = actions.find(e => e.action_name === 'update_service_call')
      if (action) {
        sortedActions.push(action)
      }
      action = actions.find(e => e.action_name === 'update_reservation')
      if (action) {
        sortedActions.push(action)
      }
      action = actions.find(e => e.action_name === 'process_guest_checkout')
      if (action) {
        sortedActions.push(action)
      }
      action = actions.find(
        e => e.action_name === 'update_conversation_quality_level_score'
      )
      if (action) {
        sortedActions.push(action)
      }
      action = actions.find(e => e.action_name === 'escalate')
      if (action) {
        sortedActions.push(action)
      }
      action = actions.find(e => e.action_name === 'create_multi_follow_ups')
      if (action) {
        sortedActions.push(action)
      }
      return sortedActions
    },
    transcodedAudios(message) {
      if (message.transcoded_audios && message.transcoded_audios.length) {
        return message.transcoded_audios.join('<br>')
      }
    },
    labelForAction(co_pilot_action) {
      if (co_pilot_action.action_name === 'create_service_call') {
        return 'Create Service Call'
      } else if (co_pilot_action.action_name === 'create_multi_follow_ups') {
        return 'Create Follow-Up'
      } else if (
        co_pilot_action.action_name ===
        'update_conversation_quality_level_score'
      ) {
        return 'Fun Level Changed'
      } else if (co_pilot_action.action_name === 'escalate') {
        return 'Escalation'
      } else if (co_pilot_action.action_name === 'update_service_call') {
        return 'Edit Service Call'
      } else if (co_pilot_action.action_name === 'update_reservation') {
        return 'Edit Reservation'
      } else if (co_pilot_action.action_name === 'process_guest_checkout') {
        return 'Guest Checkout'
      }
    },
    iconForAction(co_pilot_action) {
      if (
        co_pilot_action.action_name === 'create_service_call' ||
        co_pilot_action.action_name === 'update_service_call'
      ) {
        return '$maintenance'
      } else if (co_pilot_action.action_name === 'create_multi_follow_ups') {
        return '$alarm'
      } else if (
        co_pilot_action.action_name ===
        'update_conversation_quality_level_score'
      ) {
        return 'mdi-star'
      } else if (co_pilot_action.action_name === 'escalate') {
        return 'mdi-bell-outline'
      } else if (
        co_pilot_action.action_name === 'update_reservation' ||
        co_pilot_action.action_name === 'process_guest_checkout'
      ) {
        return '$people'
      }
    },
    async switchEscalation() {
      this.conversation.escalated = !this.conversation.escalated
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        escalated: this.conversation.escalated,
      })
      this.$emit('update-conversations')
    },
    async switchAutoPilotOnHold() {
      this.conversation.auto_pilot_on_hold =
        !this.conversation.auto_pilot_on_hold
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        auto_pilot_on_hold: this.conversation.auto_pilot_on_hold,
      })
      this.$emit('update-conversations')
    },
  },
  watch: {
    conversationId() {
      this.toggleBtn = null
      this.reservationSellers = this.activeReservation.reservation_sellers || []
    },
    threadLength() {
      setTimeout(() => {
        this.scrollToEnd()
      }, 100)
    },
    notesOn(val) {
      if (!val) {
        setTimeout(() => {
          this.scrollToEnd()
        }, 100)
      }
    },
  },
}
</script>
<style scoped>
.img {
  width: 50%;
  border-radius: 5%;
  margin-top: 5px;
}
.ai-component {
  width: 500px;
  margin-left: auto;
  margin-right: 24px;
  border: 1px solid lightgrey;
  border-radius: 11px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ai-component-content {
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.scale-up {
  transform: scale(1.0018);
  margin: 5px;
}
.ai-component-reject-btn {
  margin: 5px;
  background-color: var(--v-warning-base) !important;
  color: white;
}
.ai-component-approve-btn {
  margin: 5px;
  background-color: var(--v-success-base) !important;
  color: white;
}
.ai-component-message {
  flex-direction: column;
  align-items: center;
}
.ai-component-action {
  flex-direction: column;
  margin-top: 10px;
}
.ai-component-buttons {
  justify-content: left;
  align-items: center;
  display: flex;
  margin-top: 30px;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  left: -140px;
  top: 37px;
  position: absolute;
  z-index: 1;
  word-break: break-word;
}
.tooltip.bell .tooltiptext {
  left: 0;
}
.tooltip .tooltipicon {
  font-size: 20px;
  margin: 7px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.followup-tooltip {
  position: relative;
  display: inline-block;
}

.followup-tooltip .followup-tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;
  left: -172px;
  top: 37px;
  position: absolute;
  z-index: 1;
}
.followup-btn {
  cursor: pointer;
  margin-right: -6px !important;
  margin-left: -2px;
}
.followup-tooltip .followup-tooltipicon {
  font-size: 20px;
  margin: 7px;
}

.followup-tooltip:hover .followup-tooltiptext {
  visibility: visible;
}

.ai-component-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ai-component-no-suggestion {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: none;
}
.diff-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}
.diff-content {
  margin-left: 10px;
}
.divider {
  border-top: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}
.was {
  color: red;
}
.will-be {
  color: green;
}
.conversation-tags {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
}
.conversation-tag {
  background: var(--v-primary-base);
  color: white;
  margin: 4px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  font-size: 11px;
}
.max-w-85 {
  max-width: 85%;
}
.scale-animation {
  opacity: 0.88;
  background-color: var(--v-primary-base);
  color: white;
  animation: scale 2s infinite;
}
.scale-animation .cyan--text {
  color: white !important;
}

.followup-unactive {
  display: none;
}

.step-info {
  font-size: 0.7em !important;
  font-weight: bold;
}

@keyframes scale {
  0% {
    transform: scale(0.99);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.99);
  }
}
</style>
