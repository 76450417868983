<template>
  <v-row>
    <v-col cols="6">
      <div class="text-subtitle-2 text-md-h6 font-weight-medium">
        {{ $t('Before') }}
      </div>
      <EditableTextField
        class="editable"
        type="textarea"
        :placeholder="$t('Add your comments here')"
        :value="listingTask.before_comments"
        @onupdate="commentBefore"
      />
      <gallery
        folder="service-calls"
        :thumbnails="true"
        :listing-id="listingTask.listing_id"
        :images="beforeImages"
        :remove-img="removeBeforeImg"
        :rotate-image="rotateBeforeImg"
        :on-change="addBeforeImages"
        :uploadable="true"
      />
    </v-col>
    <v-divider vertical />
    <v-col cols="6">
      <div class="text-subtitle-2 text-md-h6 font-weight-medium">
        {{ $t('After') }}
      </div>
      <EditableTextField
        class="editable"
        type="textarea"
        :placeholder="$t('Add your comments here')"
        :value="listingTask.after_comments"
        @onupdate="commentAfter"
      />
      <gallery
        folder="service-calls"
        :thumbnails="true"
        :listing-id="listingTask.listing_id"
        :images="afterImages"
        :remove-img="removeAfterImg"
        :rotate-image="rotateAfterImg"
        :uploadable="true"
        :on-change="addAfterImages"
      />
    </v-col>
  </v-row>
</template>

<script>
import Gallery from 'components/common/gallery'
import EditableTextField from 'components/form-fields/editable-text-field'
import ExpoNotifications from '@/utils/expoNotifications'

export default {
  name: 'LtImagesGalley',
  components: {
    EditableTextField,
    Gallery,
  },
  props: ['listingTask'],
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    beforeImages() {
      return this.listingTask.before_images
    },
    afterImages() {
      return this.listingTask.after_images
    },
  },
  methods: {
    sendImagesUpdatedNotification() {
      ExpoNotifications.dashboardPush({
        title: 'Images Updated Successfully',
        link_task_id: this.listingTask.id,
      })
    },
    addBeforeImages(images) {
      this.updateTask({ before_images: this.beforeImages.concat(images) })
      this.sendImagesUpdatedNotification()
    },
    addAfterImages(images) {
      this.updateTask({ after_images: this.afterImages.concat(images) })
      this.sendImagesUpdatedNotification()
    },
    rotateBeforeImg(oldLink) {
      return newLink => {
        const before_images = this.listingTask.before_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ before_images })
      }
    },
    rotateAfterImg(oldLink) {
      return newLink => {
        const after_images = this.listingTask.after_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ after_images })
      }
    },
    removeBeforeImg(link) {
      const before_images = this.listingTask.before_images.filter(
        img => img !== link
      )
      this.updateTask({ before_images })
    },
    removeAfterImg(link) {
      const after_images = this.listingTask.after_images.filter(
        img => img !== link
      )
      this.updateTask({ after_images })
    },
    commentBefore(comment) {
      this.edited = false
      this.updateTask({ before_comments: comment })
    },
    commentAfter(comment) {
      this.edited = false
      this.updateTask({ after_comments: comment })
    },
    updateTask(payload) {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        ...payload,
      })
    },
  },
}
</script>

<style scoped></style>
