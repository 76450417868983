<template>
  <listing :key="$route.params.id" />
</template>

<script>
import Listing from 'components/listing.vue'
export default {
  components: { Listing },
}
</script>
