<template>
  <div>
    <v-hover v-slot="{ hover }">
      <div
        class="d-flex flex-nowrap no-gutters overflow-hidden fill-height"
        :class="{ pointer: openInNewTabOnClick }"
        @click.prevent="openInNewTab"
      >
        <div :class="['availability', availabilityClass]" />
        <div class="flex-grow-1">
          <div
            class="d-flex align-start justify-start overflow-hidden"
            style="height: 20px"
          >
            <div class="d-flex flex-grow-1 align-start overflow-hidden">
              <div
                v-if="listingTask.task_type"
                :style="`background-color: ${
                  typeToColor[listingTask.task_type] || 'grey'
                }`"
                style="height: 18px"
                class="task-type-tag bolder white--text px-2 mr-1"
                :class="{ 'pa-1': mMenu }"
              >
                {{ listingTask.task_type }}
              </div>
              <div class="d-inline-block">
                <Tag
                  v-if="status"
                  :class="[
                    'd-inline-block ml-1',
                    listingTaskStatusColor(status),
                  ]"
                  color="white"
                >
                  {{ listingTaskStatusFormat(status) }}
                </Tag>
                <Tag
                  v-if="isDailyView"
                  :class="['d-inline-block ml-1']"
                  color="white"
                  bg-color="black"
                >
                  {{ availabilityText }}
                </Tag>
                <Tag
                  v-if="listingTask.priority"
                  :bg-color="priorityColor(listingTask.priority)"
                  color="white"
                  class="d-inline-block ml-1"
                >
                  {{ listingTask.priority }}
                </Tag>
                <Tag
                  v-if="listingTask.auto_rescheduled"
                  bg-color="purple"
                  color="white"
                  class="d-inline-block ml-1"
                >
                  auto move
                </Tag>
                <Tag
                  v-if="checkInType"
                  :bg-color="checkInType === 'ECI' ? 'warning' : 'success'"
                  color="white"
                  class="d-inline-block ml-1"
                >
                  {{ checkInType }}-{{ plannedArrival.split(':')[0] }}
                </Tag>
                <Tag
                  v-if="checkOutType"
                  :bg-color="checkOutType === 'LCO' ? 'warning' : 'success'"
                  color="white"
                  class="d-inline-block ml-1"
                >
                  {{ checkOutType }}-{{ plannedDeparture.split(':')[0] }}
                </Tag>
                <!--                <Tag-->
                <!--                  v-if="luggageDrop"-->
                <!--                  bg-color="brown"-->
                <!--                  color="white"-->
                <!--                  class="d-inline-block ml-1"-->
                <!--                >-->
                <!--                  <v-icon color="white" small>mdi-bag-carry-on</v-icon>-->
                <!--                  -{{ luggageDrop.split(':')[0] }}-->
                <!--                </Tag>-->
                <Tag
                  v-if="delayInDays && delayInDays > 7"
                  bg-color="warning"
                  color="white"
                  class="d-inline-block ml-1"
                >
                  <v-icon x-small class="white--text">watch_later</v-icon>
                  {{ delayInDays }}
                </Tag>
                <!--                <v-tooltip-->
                <!--                  v-if="-->
                <!--                    listingTask.follow_up_by_id === currentUserId &&-->
                <!--                    isDesignedVr-->
                <!--                  "-->
                <!--                  bottom-->
                <!--                >-->
                <!--                  <template #activator="{ on, attrs }">-->
                <!--                    <v-icon-->
                <!--                      v-bind="attrs"-->
                <!--                      small-->
                <!--                      color="warning"-->
                <!--                      class="d-inline-block ml-1"-->
                <!--                      v-on="on"-->
                <!--                    >-->
                <!--                      mdi-alarm-->
                <!--                    </v-icon>-->
                <!--                  </template>-->
                <!--                  <span class="text-caption">{{-->
                <!--                    timeFromNow(listingTask.follow_up_at)-->
                <!--                  }}</span>-->
                <!--                </v-tooltip>-->
                <!--                <v-icon-->
                <!--                  v-if="isMyTask"-->
                <!--                  x-small-->
                <!--                  class="ml-1"-->
                <!--                  title="My Task"-->
                <!--                  color="notification"-->
                <!--                  >fas fa-star-->
                <!--                </v-icon>-->
                <!--                <v-icon-->
                <!--                  v-if="extraData.vip && isDesignedVr"-->
                <!--                  x-small-->
                <!--                  title="VIP"-->
                <!--                  class="ml-1"-->
                <!--                  color="notification"-->
                <!--                  >fas fa-gem-->
                <!--                </v-icon>-->
                <!--                <v-icon-->
                <!--                  v-if="listingTask.store_item_purchase_id && isDesignedVr"-->
                <!--                  x-small-->
                <!--                  title="Store"-->
                <!--                  class="ml-1"-->
                <!--                  color="notification"-->
                <!--                  >fas fa-store-->
                <!--                </v-icon>-->
                <!--                <v-icon-->
                <!--                  v-if="listingTask.listing_task_template_id"-->
                <!--                  x-small-->
                <!--                  title="In Flow"-->
                <!--                  class="ml-1"-->
                <!--                  color="secondary"-->
                <!--                  >account_tree-->
                <!--                </v-icon>-->
                <!--                <v-icon-->
                <!--                  v-if="-->
                <!--                    listingTask.listing_task_template &&-->
                <!--                    listingTask.listing_task_template.loop-->
                <!--                  "-->
                <!--                  small-->
                <!--                  title="In Flow"-->
                <!--                  class="ml-1"-->
                <!--                  color="secondary"-->
                <!--                  >mdi-sync-->
                <!--                </v-icon>-->
              </div>
            </div>
            <div
              v-if="!hideActions && (mCell || mList)"
              class="d-flex p-absolute right-0"
            >
              <v-icon
                v-if="isSelected"
                color="primary"
                @click.stop.prevent="addToLtdSelected(listingTask)"
              >
                mdi-checkbox-marked
              </v-icon>
              <v-icon
                v-else-if="canBeSelect && hover && !isMobileOrIPad"
                color="secondary"
                @click.stop.prevent="addToLtdSelected(listingTask)"
                >mdi-checkbox-blank-outline
              </v-icon>
            </div>
          </div>
          <div :class="['d-flex black--text align-center', { 'pt-2': mMenu }]">
            <div class="flex-center align-self-start p-relative">
              <v-avatar
                v-if="hasAdditionalAssignee"
                size="25"
                class="ba-1 ma-1 p-absolute multi-assign-2"
              />
              <v-avatar
                v-if="hasAdditionalAssignee"
                size="25"
                class="ba-1 ma-1 p-absolute multi-assign"
              />
              <v-avatar size="25" class="ba-1 ma-1 avatar-background">
                {{ initialsFromFullName(contractorName) }}
              </v-avatar>
            </div>
            <div
              :class="[
                'align-center px-1',
                {
                  'ellipsis-1': mCell || mList,
                },
              ]"
            >
              <span v-if="isListingView" :class="{ bolder: mList || mMenu }"
                >{{ parseDateTZ(listingTask.scheduled_at, 'HH:mm') }} -
              </span>
              <span
                v-else-if="extraData.listing_name"
                :class="{ bolder: mList || mMenu }"
                @click="
                  onListingNameClick(
                    $event,
                    listingTask.id,
                    listingTask.listing_id
                  )
                "
                >{{ extraData.listing_name }} -
              </span>
              <span v-if="mCell || mList">{{ listingTask.description }}</span>
              <div v-else>{{ listingTask.description }}</div>
            </div>
          </div>
          <div v-if="mMenu" class="d-flex pt-3">
            <div class="text-caption text-center black--text px-2 d-flex">
              <div class="mr-1 bolder text-no-wrap">Assigned To</div>
              <div class="text-no-wrap">{{ contractorName }}</div>
            </div>
          </div>
          <div
            v-if="mList || mMenu"
            :class="['d-flex', { 'pt-2': mMenu, 'pt-5': mList }]"
          >
            <div
              v-if="parsedDate"
              class="text-caption text-center black--text px-2"
            >
              {{ parsedDate }}
            </div>
            <div class="d-flex flex-grow-1 justify-space-around">
              <div
                v-if="mList"
                class="d-flex text-caption text-center black--text px-2"
              >
                <div class="mr-1 bolder text-no-wrap">Assigned To</div>
                <div class="text-no-wrap">{{ contractorName }}</div>
              </div>
              <div
                :class="[
                  'text-caption text-center black--text px-2',
                  { 'd-flex': mList },
                ]"
              >
                <div class="mr-1 bolder text-no-wrap">Created At</div>
                <div class="text-no-wrap">{{ formattedCreatedAt }}</div>
              </div>
              <div
                :class="[
                  'text-caption text-center black--text px-2',
                  { 'd-flex': mList },
                ]"
              >
                <div class="mr-1 bolder text-no-wrap">Scheduled At</div>
                <div class="text-no-wrap">{{ formattedScheduledAt }}</div>
              </div>
              <div
                v-if="listingTask.code"
                :class="[
                  'text-caption text-center black--text px-2',
                  { 'd-flex': mList },
                ]"
              >
                <div class="mr-1 bolder text-no-wrap">Guest Code</div>
                <div class="text-no-wrap">{{ listingTask.code }}</div>
              </div>
            </div>
          </div>
          <div v-if="mMenu && subtasksCount">
            <div class="text-center font-weight-black mt-4">
              <div
                style="right: 0"
                class="d-flex p-absolute mr-3 font-weight-medium text-body-2"
              >
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <div class="d-flex" v-bind="attrs" v-on="on">
                      <v-icon class="mr-1" x-small color="success"
                        >mdi-circle</v-icon
                      >{{ subtaskStats.passed }}
                    </div>
                  </template>
                  <span>Passed</span>
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <div class="ml-2 d-flex" v-bind="attrs" v-on="on">
                      <v-icon class="mr-1" x-small color="warning"
                        >mdi-circle</v-icon
                      >{{ subtaskStats.failed }}
                    </div>
                  </template>
                  <span>Failed</span>
                </v-tooltip>
              </div>
              <span>Subtasks</span>
            </div>
            <div style="max-height: 300px" class="overflow-auto">
              <cell-subtask
                v-for="(subtask, ind) in subtasks"
                :key="ind"
                :subtask="subtask"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <v-menu v-if="!hideActions && mList" close-on-click offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  color="secondary"
                  x-small
                  outlined
                  text
                  v-on="on"
                >
                  Actions
                  <v-icon class="ml-2" small>fas fa-caret-down</v-icon>
                </v-btn>
              </template>
              <v-list class="pa-0">
                <v-list-item dense class="pa-0 flex-center">
                  <media-upload
                    :on-change="uploadMedia"
                    folder="service-calls"
                    :preview="false"
                  />
                </v-list-item>
                <v-list-item class="pa-0 flex-center">
                  <add-repair-materials
                    :disabled="
                      listingTask.status !== 'New' &&
                      listingTask.status !== 'In progress'
                    "
                    :task-id="listingTask.id"
                  />
                </v-list-item>
                <v-list-item v-if="listingTask.listing_id" class="pa-0">
                  <v-btn
                    block
                    text
                    small
                    color="success"
                    elevation="0"
                    @click="showOnMap"
                  >
                    Open Map
                  </v-btn>
                </v-list-item>
                <v-list-item class="pa-0 flex-center">
                  <lt-taskim-btn
                    :listing-task-id="listingTask.id"
                    :text-button="true"
                    :small="true"
                  />
                </v-list-item>
                <v-list-item dense class="pa-0">
                  <v-btn
                    :disabled="
                      listingTask.status === 'Done' || listingTask.reg_cleaning
                    "
                    block
                    text
                    small
                    color="success"
                    elevation="0"
                    @click="moveToDone"
                  >
                    {{ $t('Move to Done') }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="flex-center">
            <div v-if="mList || mMenu">
              <gallery
                v-if="!isEmpty(listingTask.after_images)"
                readonly
                icon="$photos_after"
                icon-color="info"
                :images="listingTask.after_images"
              />
            </div>
          </div>
        </div>
      </div>
    </v-hover>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import DeviceMixin from 'components/mixins/device-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import MentionMixin from 'components/mixins/mention-mixin'
import Tag from 'components/common/tag'
import Gallery from 'components/common/gallery'
import MediaUpload from 'components/common/media-upload'
import LtTaskimBtn from 'components/taskim/lt-taskim-btn'
import AddRepairMaterials from 'components/listing-tasks/add-repair-materials'
import CellSubtask from 'components/calendar/cell-subtask.vue'
import { filter } from 'lodash'
import get from 'lodash/fp/get'
import size from 'lodash/fp/size'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'TaskCalendarCell',
  components: {
    AddRepairMaterials,
    LtTaskimBtn,
    MediaUpload,
    Gallery,
    Tag,
    CellSubtask,
  },
  mixins: [
    CommonFunctions,
    DeviceMixin,
    ColorsMixin,
    MentionMixin,
    FormattersMixin,
  ],
  props: [
    'mode',
    'event',
    'hideActions',
    'isListingView',
    'isDailyView',
    'openInNewTabOnClick',
    'usersMap',
  ],
  computed: {
    ...mapGetters(['currentUserId']),
    ...mapState(['ltdSelectedIDS']),
    mCell() {
      return this.mode === 'cell'
    },
    hasAdditionalAssignee() {
      return (
        this.listingTask.assigned_contractor1_id ||
        this.listingTask.assigned_contractor2_id ||
        this.listingTask.assigned_contractor3_id ||
        this.listingTask.assigned_contractor4_id
      )
    },
    subtasks() {
      return get('mini_extra_data.sub_tasks', this.listingTask)
    },
    subtaskStats() {
      return get('mini_extra_data.subtask_stats', this.listingTask)
    },
    subtasksCount() {
      return size(this.subtasks)
    },
    mList() {
      return this.mode === 'list'
    },
    mMenu() {
      return this.mode === 'menu'
    },
    parsedDate() {
      return this.event.allDay
        ? 'All day'
        : filter([
            this.parseDate(this.event.startStr, 'H:mm'),
            this.parseDate(this.event.endStr, 'H:mm'),
          ]).join(' - ')
    },
    delayInDays() {
      if (
        this.listingTask &&
        this.listingTask.scheduled_at &&
        this.listingTask.original_scheduled_at
      ) {
        return this.$moment(this.listingTask.scheduled_at).diff(
          this.$moment(this.listingTask.original_scheduled_at),
          'days'
        )
      } else {
        return 0
      }
    },
    listingTask() {
      return this.event.extendedProps.task
    },
    extraData() {
      return (
        this.listingTask.mini_extra_data || this.listingTask.extra_data || {}
      )
    },
    plannedDeparture() {
      return this.extraData.late_checkout_purchase
    },
    plannedArrival() {
      return this.extraData.early_check_in_purchase
    },
    originArrival() {
      return this.extraData.origin_check_in_time
    },
    originDeparture() {
      return this.extraData.origin_check_out_time
    },
    luggageDrop() {
      return this.extraData.luggage_drop
    },
    checkInType() {
      if (this.plannedArrival && this.plannedArrival !== this.originArrival) {
        return +this.plannedArrival.split(':')[0] <
          +this.originArrival.split(':')[0]
          ? 'ECI'
          : 'LCI'
      }
      return ''
    },
    checkOutType() {
      if (
        this.plannedDeparture &&
        this.plannedDeparture !== this.originDeparture
      ) {
        return +this.plannedDeparture.split(':')[0] >
          +this.originDeparture.split(':')[0]
          ? 'LCO'
          : 'ECO'
      }
      return ''
    },
    availabilityClass() {
      if (this.checkOutType) {
        return `${this.checkOutType} ${this.listingTask.add_today_avialability_text}`
      } else if (this.checkInType) {
        return `${this.checkInType} ${this.listingTask.add_today_avialability_text}`
      } else {
        return this.listingTask.add_today_avialability_text
      }
    },
    availabilityText() {
      const availabilityText =
        this.availabilityTextMap[this.listingTask.add_today_avialability_text]
      if (this.checkOutType) {
        return `${this.checkOutType} ${availabilityText}`
      } else if (this.checkInType) {
        return `${this.checkInType} ${availabilityText}`
      } else {
        return availabilityText
      }
    },
    isMyTask() {
      return this.currentUserId === this.listingTask.assigned_contractor_id
    },
    contractor() {
      if (this.listingTask.assigned_contractor_id) {
        return this.usersMap[this.listingTask.assigned_contractor_id]
      } else {
        return {}
      }
    },
    contractorName() {
      if (
        this.contractor &&
        this.usersMap[this.listingTask.assigned_contractor_id]
      ) {
        return this.usersMap[this.listingTask.assigned_contractor_id].name
      } else {
        return null
      }
    },
    status() {
      return this.listingTask.sub_status &&
        !this.listingTask.manual_approved &&
        this.listingTask.status === 'Done'
        ? this.listingTask.sub_status
        : this.listingTask.status
    },
    formattedCreatedAt() {
      return this.$moment(this.listingTask.created_at).format('MMM DD, YYYY')
    },
    formattedScheduledAt() {
      return this.$moment(this.listingTask.scheduled_at).format('MMM DD, YYYY')
    },
    canBeSelect() {
      return (
        ['New', 'In Progress'].includes(this.listingTask.status) &&
        !this.listingTask.department
      )
    },
    isSelected() {
      return this.ltdSelectedIDS.has(this.listingTask.id)
    },
  },
  methods: {
    ...mapActions(['updateListingTask', 'getSubTasks']),
    openInNewTab() {
      if (this.openInNewTabOnClick) {
        window.open(`/dashboard/listing-task/${this.listingTask.id}`, 'blank')
      }
    },
    onListingNameClick(event, listingTaskId, listingId) {
      this.$emit('click:listing-name', { event, listingTaskId, listingId })
    },
    showFeed() {
      this.$router.push({
        query:
          this.feedOpenId && this.feedOpenId === this.listingTask.id
            ? {}
            : { feed_id: this.listingTask.id },
      })
    },
    async uploadMedia(files) {
      const data = await this.updateListingTask({
        id: this.listingTask.id,
        after_images: files,
        feedOpenId: null,
      })
      this.event.setExtendedProp('task', data)
    },
    async moveToDone() {
      const payload = {
        id: this.listingTask.id,
        status: 'Done',
      }
      if (this.listingTask.in_loop) {
        const condition =
          this.listingTask.listing_task_template.loop_condition_text
        this.$store.commit('showModal', {
          name: 'LtLoopMoveToDoneModal',
          props: {
            condition,
            payload,
            afterChange: res => this.event.setExtendedProp('task', res),
          },
        })
      } else {
        const res = await this.$store.dispatch('updateListingTask', payload)
        this.event.setExtendedProp('task', res)
      }
    },
    showOnMap() {
      window.open(
        `https://maps.google.com/?q=${this.listingTask.extra_data.listing.address}`,
        '_blank'
      )
    },
  },
}
</script>

<style lang="scss">
.task-type-tag {
  font-size: 0.65rem;
  border-bottom-right-radius: 5px;
}
.multi-assign {
  bottom: -2px;
  left: -2px;
}
.multi-assign-2 {
  bottom: -4px;
  left: -4px;
}
.avatar-background {
  background: white;
}
</style>
