<template>
  <v-layout wrap align-center>
    <v-flex xs12>
      <v-text-field
        v-model="searchTerm"
        flat
        label="Search..."
        append-icon="search"
        solo-inverted
        style="border: 1px grey solid"
        class="mt-3"
      ></v-text-field>
    </v-flex>
    <v-flex xs6 sm3 mt-1 class="center-text">
      <v-checkbox
        v-model="parkingPassesOnly"
        label="Parking passes"
        class="checkbox-txt"
      ></v-checkbox>
    </v-flex>
    <v-flex xs6 sm3 mt-1 class="center-text">
      <v-checkbox
        v-model="parkingDonly"
        label="Designated parking"
        class="checkbox-txt"
      ></v-checkbox>
    </v-flex>
    <v-flex xs12 mt-3>
      <v-data-table
        v-if="items"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        class="elevation-1"
      >
        <template #item="{ item }">
          <tr>
            <td class="text-sm-left">
              <img
                :src="item.picture"
                style="max-width: 100px; max-height: 100px"
                alt=""
              />
            </td>
            <td class="text-sm-left">
              <router-link
                style="text-decoration: none"
                class="mr-1"
                target="_blank"
                :to="addressFor(item.id, 'edit')"
              >
                <v-icon class="purple-icon">edit</v-icon>
              </router-link>
              <router-link
                v-if="isAdmin"
                style="text-decoration: none"
                class="mr-2"
                target="_blank"
                :to="addressFor(item.id, 'show')"
              >
                <v-icon class="cyan-icon">visibility</v-icon>
              </router-link>
              <span class="ml-2">
                {{ item.nickname }}
              </span>
            </td>
            <td class="text-sm-left">
              <v-tooltip v-if="item.has_assigned_parking" top mb-1>
                <v-icon class="purple-icon" v-on="on">drive_eta</v-icon>
                <v-layout wrap style="min-width: 150px">
                  Designated parking space
                </v-layout>
              </v-tooltip>
              <span
                v-if="item.assigned_parking_desc"
                class="grey-text ml-2 bl-1"
              >
                {{ item.assigned_parking_desc }}</span
              >
              <v-tooltip v-if="item.has_parking_pass" top mb-1>
                <v-icon class="cyan-icon" v-on="on">local_parking</v-icon>
                <v-layout wrap style="min-width: 150px">
                  Parking pass
                </v-layout>
              </v-tooltip>
              <span v-if="item.parking_pass_desc" class="grey-text ml-2 bl-1">
                {{ item.parking_pass_desc }}</span
              >

              <v-tooltip top mb-1>
                <template #activator="{ on }">
                  <v-icon
                    v-if="!item.has_parking_pass && !item.has_assigned_parking"
                    class="red-warn"
                    v-on="on"
                    >block
                  </v-icon>
                </template>
                <v-layout wrap style="min-width: 150px"> No Parking</v-layout>
              </v-tooltip>
            </td>
            <td class="text-sm-left">
              <v-layout column>
                <v-flex v-if="item.parking_today.msg">
                  {{ item.parking_today.msg }}
                </v-flex>
                <v-flex v-if="!item.parking_today.msg">
                  <span
                    :class="
                      item.parking_today.need_parking
                        ? 'yellow-health'
                        : 'green-health'
                    "
                  >
                    {{
                      item.parking_today.need_parking
                        ? 'Guest needs parking'
                        : "Guest didn't specify he needs parking"
                    }}
                  </span>
                  <router-link
                    v-if="item.parking_today.confirmation_code"
                    target="_blank"
                    :to="`/pre-check-in/${item.parking_today.confirmation_code}`"
                    class="cyan-icon ml-2"
                  >
                    <v-icon
                      small
                      class="mr-2 purple-icon"
                      style="cursor: pointer"
                    >
                      open_in_browser
                    </v-icon>
                  </router-link>
                </v-flex>
                <v-layout wrap class="bt-1">
                  <v-flex
                    v-if="!item.parking_today.msg && item.has_assigned_parking"
                    class="br-1 text-sm-center"
                  >
                    <v-icon> straighten</v-icon>
                    {{ item.parking_today.licence_plate || '-' }}
                  </v-flex>
                  <v-flex
                    v-if="!item.parking_today.msg && item.has_assigned_parking"
                    class="text-sm-center"
                  >
                    <v-icon> directions_car</v-icon>
                    {{ item.parking_today.car_model || '-' }}
                  </v-flex>
                </v-layout>

                <span
                  v-if="
                    !item.parking_today.deposit_taken &&
                    item.has_parking_pass &&
                    item.parking_today.msg !== 'No guest today'
                  "
                >
                  <v-icon class="red-warn">report_problem</v-icon>
                  No deposit taken
                </span>
                <v-flex
                  v-if="item.parking_today.guest_phone"
                  class="bt-1 cyan-icon"
                >
                  {{ item.parking_today.guest_name }} |
                  <span class="bolder purple-icon">{{
                    item.parking_today.guest_phone
                  }}</span>
                </v-flex>
              </v-layout>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: [],
  data: () => ({
    parkingPassesOnly: false,
    parkingDonly: false,
    searchTerm: '',
    pagination: {
      itemsPerPage: 100,
    },
    dialog: false,
    headers: [
      { text: '', sortable: false },
      { text: 'Nickname', value: 'nickname' },
      { text: 'Parking', sortable: false },
      { text: 'Today', sortable: false },
    ],
  }),
  computed: {
    items() {
      let res = []
      if (this.$store.state.parkings) {
        this.$store.state.parkings.forEach(listing => {
          if (this.passFilters(listing)) {
            let title = listing.nickname || ''
            let address = listing.address || ''
            if (
              title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
              address.toLowerCase().includes(this.searchTerm.toLowerCase())
            ) {
              res.push(listing)
            }
          }
        })
      }
      return res
    },
  },

  watch: {},
  created() {
    this.$store.dispatch('getParkings')
  },

  methods: {
    passFilters(item) {
      if (this.parkingPassesOnly && !item.has_parking_pass) {
        return false
      }
      if (this.parkingDonly && !item.has_assigned_parking) {
        return false
      }
      return true
    },
    availColor(text) {
      if (text === 'Check-in and Checkout today') {
        return 'orange--text font-light'
      } else if (text === 'Check-in today') {
        return 'purple--text font-light'
      } else if (text === 'Checkout today') {
        return 'dark-yellow font-light'
      } else if (text === 'Free today') {
        return 'green--text font-light'
      }
    },

    addressFor(id, action) {
      if (action === 'edit') {
        return { path: `/dashboard/edit/${id}` }
      } else {
        return { path: `/dashboard/property/${id}` }
      }
    },
    score(item) {
      return item.yearly_stats.health
    },
  },
}
</script>
