<template>
  <div>
    <div>
      <phone-dialer @call="$emit('call', $event)" />
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      disable-sort
      class="elevation-0 pointer"
      :loading="callLogLoading"
      :options="pagination"
      :server-items-length="pagination.count"
      @pagination="paginationChanged($event)"
      @click:row="rowClick($event)"
    >
      <template #item.status="{ item }">
        <div class="d-flex align-center justify-start">
          <v-btn
            small
            icon
            class="me-1"
            :color="`${STATUS_ICON[item.status]?.color} darken-1`"
            readonly
          >
            <v-icon small>
              {{ STATUS_ICON[item.status]?.icon || 'mdi-phone-off' }}
            </v-icon>
          </v-btn>
          <div
            class="text--secondary font-weight-medium text-caption text-no-wrap"
          >
            {{ STATUS_ICON[item.status]?.label || item.status }}
          </div>
        </div>
      </template>
      <template #item.incoming="{ item }">
        <v-btn
          icon
          small
          :color="item.incoming ? 'blue darken-4' : 'green darken-3'"
          readonly
        >
          <v-icon small dark>
            {{ item.incoming ? 'mdi-phone-incoming' : 'mdi-phone-outgoing' }}
          </v-icon>
        </v-btn>
      </template>
      <template
        #[`item.conversation.extra_data.reservation_check_in`]="{ item }"
      >
        <span class="d-flex min-w-200"
          ><span>{{
            parseDate(item.conversation.extra_data.reservation_check_in)
          }}</span>
          <v-icon small class="mx-1">mdi-arrow-right-thick </v-icon>
          <span>
            {{
              parseDate(item.conversation.extra_data.reservation_check_out)
            }}</span
          >
        </span>
      </template>
      <template #item.recording_url="{ item }">
        <audio
          v-if="item.recording_url"
          controls
          :src="item.recording_url"
          class="small-audio-player"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import formattersMixin from 'components/mixins/formatters-mixin'
import PhoneDialer from 'components/crm/phone-dialer.vue'

const STATUS_ICON = {
  busy: {
    icon: 'mdi-phone-off',
    color: 'orange',
    label: 'Busy',
  },
  completed: {
    icon: 'mdi-phone-in-talk',
    color: 'green',
    label: 'Success',
  },
  'no-answer': {
    icon: 'mdi-phone-missed',
    color: 'red',
    label: 'No Answer',
  },
}
export default {
  components: { PhoneDialer },
  name: 'CallLog',
  mixins: [formattersMixin],
  data() {
    return {
      STATUS_ICON,
      search: '',
      items: [],
      pagination: {},
      callLogLoading: false,
      headers: [
        { text: 'Type', value: 'incoming' },
        { text: 'Status', value: 'status', align: 'start' },

        {
          text: 'Guest',
          align: 'start',
          sortable: false,
          value: 'conversation.guest.full_name',
        },
        {
          text: 'Agent',
          align: 'start',
          sortable: false,
          value: 'user.full_name',
        },
        { text: 'Number', value: 'number' },
        { text: 'Call Time', value: 'created_at' },
        { text: 'Duration', value: 'duration' },
        { text: 'Property', value: 'conversation.extra_data.listing_nickname' },
        {
          text: 'Check-in',
          value: 'conversation.extra_data.reservation_check_in',
        },
        { text: 'Recording', value: 'recording_url' },
      ],
    }
  },
  mounted() {
    this.fetchCallLogs()
  },
  methods: {
    paginationChanged(pagination) {
      if (pagination.page === 1) return
      this.fetchCallLogs(pagination.page)
    },
    duration(seconds) {
      if (!seconds) return '-'
      return seconds > 60
        ? `${Math.floor(seconds / 60)}m ${seconds % 60}s`
        : `${seconds}s`
    },
    async fetchCallLogs(page = 1) {
      this.callLogLoading = true
      const { data } = await this.$store.dispatch('conversations/getCallLogs', {
        page,
      })
      this.items = data.call_logs.map(call => ({
        ...call,
        number: (call.incoming ? call.from_number : call.to_number).replace(
          /(\+972)(\d{2})(\d{3})(\d{2})/,
          '$1 $2 $3 $4'
        ),
        created_at: this.fromNow(call.created_at),
        duration: this.duration(call.duration_seconds),
      }))
      this.pagination = data.pagination
      this.callLogLoading = false
    },
    rowClick({ conversation }) {
      window.open(
        `/dashboard/guest-experience/sales?conversation=${conversation.guesty_id}`,
        '_blank'
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.small-audio-player {
  height: 30px !important;
}
</style>
