<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g transform="translate(-2 -2)">
      <path
        class="a"
        d="M24,4.4H22V3.6a1.6,1.6,0,0,0-3.2,0v.8H15.6V3.6a1.6,1.6,0,0,0-3.2,0v.8H9.2V3.6A1.6,1.6,0,1,0,6,3.6v.8H4a2,2,0,0,0-2,2v16a2,2,0,0,0,2,2H15.2a.4.4,0,1,0,0-.8H4a1.2,1.2,0,0,1-1.2-1.2V10H25.2v6.8a.4.4,0,0,0,.8,0V6.4a2,2,0,0,0-2-2Zm-4.4-.8a.8.8,0,0,1,1.6,0V6a.8.8,0,1,1-1.6,0Zm-6.4,0a.8.8,0,0,1,1.6,0V6a.8.8,0,0,1-1.6,0Zm-6.4,0a.8.8,0,1,1,1.6,0V6A.8.8,0,1,1,6.8,6ZM25.2,9.2H2.8V6.4A1.2,1.2,0,0,1,4,5.2H6V6A1.6,1.6,0,1,0,9.2,6V5.2h3.2V6a1.6,1.6,0,0,0,3.2,0V5.2h3.2V6A1.6,1.6,0,0,0,22,6V5.2h2a1.2,1.2,0,0,1,1.2,1.2Z"
        transform="translate(0)"
      />
      <path
        class="a"
        d="M25.2,27.8a.8.8,0,0,0-.8-.8H22.8a.8.8,0,0,0-.8.8V29a.8.8,0,0,0,.8.8h1.6a.8.8,0,0,0,.8-.8ZM22.8,29V27.8h1.6V29Z"
        transform="translate(-12 -15)"
      />
      <path
        class="a"
        d="M13.2,27.8a.8.8,0,0,0-.8-.8H10.8a.8.8,0,0,0-.8.8V29a.8.8,0,0,0,.8.8h1.6a.8.8,0,0,0,.8-.8ZM10.8,29V27.8h1.6V29Z"
        transform="translate(-4.8 -15)"
      />
      <path
        class="a"
        d="M48.4,29.8a.8.8,0,0,0,.8-.8V27.8a.8.8,0,0,0-.8-.8H46.8a.8.8,0,0,0-.8.8V29a.8.8,0,0,0,.8.8Zm-1.6-2h1.6V29H46.8Z"
        transform="translate(-26.4 -15)"
      />
      <path
        class="a"
        d="M25.2,36.8a.8.8,0,0,0-.8-.8H22.8a.8.8,0,0,0-.8.8V38a.8.8,0,0,0,.8.8h1.6a.8.8,0,0,0,.8-.8ZM22.8,38V36.8h1.6V38Z"
        transform="translate(-12 -20.4)"
      />
      <path
        class="a"
        d="M13.2,36.8a.8.8,0,0,0-.8-.8H10.8a.8.8,0,0,0-.8.8V38a.8.8,0,0,0,.8.8h1.6a.8.8,0,0,0,.8-.8ZM10.8,38V36.8h1.6V38Z"
        transform="translate(-4.8 -20.4)"
      />
      <path
        class="a"
        d="M24.4,45H22.8a.8.8,0,0,0-.8.8V47a.8.8,0,0,0,.8.8h1.6a.8.8,0,0,0,.8-.8V45.8A.8.8,0,0,0,24.4,45Zm-1.6,2V45.8h1.6V47Z"
        transform="translate(-12 -25.8)"
      />
      <path
        class="a"
        d="M34.8,29.8h1.6a.8.8,0,0,0,.8-.8V27.8a.8.8,0,0,0-.8-.8H34.8a.8.8,0,0,0-.8.8V29A.8.8,0,0,0,34.8,29.8Zm0-2h1.6V29H34.8Z"
        transform="translate(-19.2 -15)"
      />
      <path
        class="a"
        d="M34,38a.8.8,0,0,0,.8.8.4.4,0,0,0,0-.8V36.8h1.6a.4.4,0,0,0,0-.8H34.8a.8.8,0,0,0-.8.8Z"
        transform="translate(-19.2 -20.4)"
      />
      <path
        class="a"
        d="M12.4,45H10.8a.8.8,0,0,0-.8.8V47a.8.8,0,0,0,.8.8h1.6a.8.8,0,0,0,.8-.8V45.8A.8.8,0,0,0,12.4,45Zm-1.6,2V45.8h1.6V47Z"
        transform="translate(-4.8 -25.8)"
      />
      <path
        class="a"
        d="M41.2,36a5.2,5.2,0,1,0,5.2,5.2A5.2,5.2,0,0,0,41.2,36Zm0,9.6a4.4,4.4,0,1,1,4.4-4.4A4.4,4.4,0,0,1,41.2,45.6Z"
        transform="translate(-20.4 -20.4)"
      />
      <path
        class="a"
        d="M46.966,44.632l-3.111,3.111-1.414-1.414a.4.4,0,1,0-.566.566l1.7,1.7a.4.4,0,0,0,.566,0L47.532,45.2a.4.4,0,1,0-.566-.566Z"
        transform="translate(-23.855 -25.509)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CalendarCheck',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
