<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="10.285"
    viewBox="0 0 24 10.285"
  >
    <g transform="translate(0 0)">
      <path
        d="M23.143,137.435a.857.857,0,0,0-.857.857v2.571H19.715v-2.571a.857.857,0,1,0-1.714,0v2.571H10.212a5.142,5.142,0,1,0,0,1.714H23.143A.857.857,0,0,0,24,141.72v-3.428A.857.857,0,0,0,23.143,137.435Zm-18,7.712a3.428,3.428,0,1,1,3.428-3.428A3.428,3.428,0,0,1,5.147,145.147Z"
        transform="translate(0 -136.577)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DoorKey',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
