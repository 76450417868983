import axios from 'axios'
import customAxios from '@/axios/config'

export default {
  namespaced: true,
  actions: {
    async getSmartRules({ commit }, listing_id) {
      const { data } = await axios.get(`
        api/listings/${listing_id}/smart-rules
      `)
      return data
    },
    async createSmartRule({ dispatch }, { listing_id, ...payload }) {
      await customAxios.post(`/api/listings/${listing_id}/smart-rules`, payload)
      dispatch('getSmartRules', listing_id)
    },
    async deleteSmartRule({ dispatch }, { id, listing_id }) {
      await customAxios.delete(`
        /api/listings/${listing_id}/smart-rules/${id}
      `)
      dispatch('getSmartRules', listing_id)
    },
  },
}
