import { render, staticRenderFns } from "./menu-hot-key.vue?vue&type=template&id=13ae110f&scoped=true&"
import script from "./menu-hot-key.vue?vue&type=script&lang=js&"
export * from "./menu-hot-key.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ae110f",
  null
  
)

export default component.exports