var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.section)?_c('div',{staticClass:"taskim-section"},[_c('div',{staticClass:"d-flex align-center"},[(!_vm.isDynamicSection)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.collapseToggle}},[(_vm.collapsed)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-chevron-right ")]):(_vm.tasksFetching)?_c('v-progress-circular',{attrs:{"size":"18","indeterminate":"","color":"primary"}}):_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-chevron-down")])],1):_vm._e(),_c('inline-text-field',{attrs:{"disabled":_vm.isDynamicSection,"value":_vm.section.name},on:{"update:value":function($event){return _vm.$set(_vm.section, "name", $event)},"change":_vm.onSectionNameChanged}}),(_vm.multiSelected && _vm.selectedListings.length)?_c('lt-taskim-btn',{attrs:{"listing-tasks-ids":_vm.selectedListings.map(i => i.id),"small":true,"move-from-section-id":_vm.section.id}}):_vm._e(),_c('v-spacer'),(!_vm.isMobile)?_c('div',{staticClass:"mx-2 text-body-2"},[_vm._v("("+_vm._s(_vm.totalHours)+")")]):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"mx-2 text-body-2"},[_vm._v(" ("+_vm._s(_vm.totalTasksText)+") ")]):_vm._e(),(_vm.boardId && _vm.$listeners['order-up'] && !_vm.isDynamicSection)?_c('v-btn',{class:{ 'opacity-50': _vm.orderUpDisabled },attrs:{"icon":"","disabled":_vm.orderUpDisabled},on:{"click":function($event){return _vm.$emit('order-up')}}},[_c('v-icon',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("mdi-arrow-up-bold")])],1):_vm._e(),(_vm.boardId && _vm.$listeners['order-down'] && !_vm.isDynamicSection)?_c('v-btn',{class:{ 'opacity-50': _vm.orderDownDisabled },attrs:{"icon":"","disabled":_vm.orderDownDisabled},on:{"click":function($event){return _vm.$emit('order-down')}}},[_c('v-icon',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("mdi-arrow-down-bold")])],1):_vm._e(),(
        !_vm.isDynamicSection &&
        !_vm.projectId &&
        ![82, 164, 165, 166].includes(_vm.section.id)
      )?_c('ConfirmationModal',{attrs:{"text":`Are you sure you want to delete this section ?`},on:{"action":function($event){return _vm.onDeleteSection(_vm.section.id)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""}},on),[_c('v-icon',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("mdi-delete-forever")])],1)]}}],null,false,3945933629)}):_vm._e(),(!_vm.isMobile)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.multiSelected = !_vm.multiSelected}}},[_c('v-icon',{attrs:{"small":"","color":_vm.multiSelected ? 'primary' : 'grey lighten-1'}},[_vm._v("mdi-checkbox-multiple-marked")])],1):_vm._e()],1),(!_vm.isDynamicSection && _vm.collapsed)?_c('v-divider'):_vm._e(),_c('v-expand-transition',[(!(!_vm.isDynamicSection && _vm.collapsed))?_c('div',{staticClass:"taskim-section"},[_c('div',{staticClass:"taskim-section-table-container"},[(_vm.isMobile)?_c('taskim-section-mobile',{attrs:{"items":_vm.tableTasks,"active-users":_vm.activeUsers,"users-map":_vm.usersMap},on:{"listingTaskFieldChange":_vm.listingTaskFieldChange,"show-feed":_vm.showFeed,"lt-status-update":_vm.stateTaskimUpdateTask,"lt-after-change":_vm.setTaskFromCurrentListingTask}}):_c('v-data-table',{directives:[{name:"sortable-data-table",rawName:"v-sortable-data-table",value:(!_vm.isDynamicSection && !_vm.isMobile),expression:"!isDynamicSection && !isMobile"}],ref:"dataTable",staticClass:"taskim-section-table",attrs:{"headers":_vm.headers,"items":_vm.tableTasks,"loading":_vm.tasksFetching,"item-key":"tableId","hide-default-footer":!_vm.isDynamicSection,"item-class":_vm.itemRowClass,"sort-by":_vm.sort.by,"sort-desc":_vm.sort.desc,"options":_vm.pagination,"show-select":_vm.multiSelected,"server-items-length":_vm.pagination.itemsLength,"footer-props":{
            'items-per-page-options': [25, 50, 100],
          }},on:{"update:sortBy":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.sort, "by", $event)},function($event){return _vm.sortChangedDebounce()}],"update:sortDesc":function($event){return _vm.$set(_vm.sort, "desc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.sort, "desc", $event)},function($event){return _vm.sortChangedDebounce()}],"dragged":_vm.orderOnDrag,"pagination":function($event){return _vm.$emit('pagination-changed', $event)}},scopedSlots:_vm._u([(_vm.show)?{key:"item.description",fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[(!_vm.isDynamicSection && !_vm.isMobile)?_c('v-icon',{staticClass:"grab px-2",attrs:{"small":""}},[_vm._v(" mdi-drag ")]):_vm._e(),(_vm.show)?_c('taskim-text-field',{class:['flex-1', { 'ml-2': _vm.isDynamicSection }],attrs:{"value":item.description,"disabled":Boolean(_vm.projectId)},on:{"update:value":function($event){return _vm.$set(item, "description", $event)},"change":function($event){return _vm.listingTaskFieldChange(item, 'description', $event)}}}):_vm._e(),(_vm.show)?_c('div',{staticClass:"flex-center pl-2"},[_c('v-btn',{attrs:{"color":"info","icon":"","small":""},on:{"click":function($event){return _vm.showFeed(item)}}},[(
                      item.internal_comments ||
                      (item.listing_task_comments &&
                        item.listing_task_comments.length)
                    )?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-comment-text ")]):_c('v-icon',{attrs:{"small":"","color":"info lighten-2"}},[_vm._v(" mdi-comment-text-outline ")])],1)],1):_vm._e()],1)]}}:null,{key:"item.listing_task_catalog_item_id",fn:function({ item }){return [(_vm.show)?_c('catalog-item-picker',{attrs:{"value":item.listing_task_catalog_item_id},on:{"input":function($event){return _vm.listingTaskFieldChange(
                  item,
                  'listing_task_catalog_item_id',
                  $event
                )}}}):_vm._e()]}},{key:"item.status",fn:function({ item }){return [(_vm.show)?_c('lt-status',{attrs:{"small":_vm.isMobile,"listing-task":item},on:{"after-change":_vm.setTaskFromCurrentListingTask,"change":function($event){return _vm.stateTaskimUpdateTask({ id: item.id, ...$event })}}}):_vm._e()]}},{key:"item.amount",fn:function({ item }){return [_c('div',[_vm._v(_vm._s(_vm.dollarFormatter(item.amount)))])]}},{key:"item.priority",fn:function({ item }){return [_c('lt-priority',{attrs:{"value":item.priority},on:{"input":function($event){return _vm.listingTaskFieldChange(item, 'priority', $event)}}})]}},{key:"item.assigned_contractor_id",fn:function({ item }){return [(_vm.show)?_c('assignee-select',{key:item.assigned_contractor_id,attrs:{"users":_vm.activeUsers,"value":_vm.usersMap[item.assigned_contractor_id]},on:{"change":function($event){return _vm.listingTaskFieldChange(
                  item,
                  'assigned_contractor_id',
                  $event.id
                )}}}):_vm._e()]}},{key:"item.total_hours",fn:function({ item }){return [(_vm.show)?_c('taskim-text-field',{staticClass:"flex-1 max-w-150 white text-center",attrs:{"value":item.total_hours},on:{"update:value":function($event){return _vm.$set(item, "total_hours", $event)},"change":function($event){return _vm.listingTaskFieldChange(item, 'total_hours', $event)}}}):_vm._e()]}},{key:"item.listing_id",fn:function({ item }){return [(_vm.show)?_c('listing-picker',{attrs:{"value":item.listing_id},on:{"input":function($event){return _vm.listingTaskFieldChange(item, 'listing_id', $event)}}}):_vm._e()]}},{key:"item.after_images",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"flex-1 text-no-wrap"},[(!_vm.isEmpty(item.after_images))?_c('gallery',{attrs:{"images":item.after_images,"icon-color":"black","cols":12,"remove-img":img => _vm.removeAfterImg(item, img),"icon":"mdi-paperclip"}}):_vm._e()],1),_c('div',{staticClass:"flex-1"},[_c('gallery',{attrs:{"images":[],"icon-color":"black","uploadable":"","thumbnails":"","button-text":"","cols":12,"on-change":imgs => _vm.addAfterImg(item, imgs)}})],1)])]}},{key:"item.scheduled_at",fn:function({ item }){return [(_vm.show)?_c('date-picker',{attrs:{"value":_vm.parseDate(item.scheduled_at),"events":val => _vm.scheduleAtAllowedDates(val, item),"event-color":val => _vm.eventDateColor(val, item),"reactive":true,"min":new Date().toISOString().substr(0, 10),"max":new Date(new Date().setMonth(new Date().getMonth() + 10))
                  .toISOString()
                  .substr(0, 10),"required":true,"dense":true,"hide-details":true,"flat":true,"solo":true,"outlined":false},on:{"change":function($event){return _vm.listingTaskFieldChange(item, 'scheduled_at', $event)}}}):_vm._e()]}},{key:"item.scheduled_at_end",fn:function({ item }){return [(_vm.show)?_c('date-picker',{attrs:{"value":_vm.parseDate(item.scheduled_at_end),"events":val => _vm.scheduleAtAllowedDates(val, item),"event-color":val => _vm.eventDateColor(val, item),"reactive":true,"min":new Date().toISOString().substr(0, 10),"max":new Date(new Date().setMonth(new Date().getMonth() + 10))
                  .toISOString()
                  .substr(0, 10),"required":true,"dense":true,"hide-details":true,"flat":true,"solo":true,"outlined":false},on:{"change":function($event){return _vm.listingTaskFieldChange(item, 'scheduled_at_end', $event)}}}):_vm._e()]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.parseDate(item.created_at))+" ")]}},{key:"item.created_by_id",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.nameById(item.created_by_id))+" ")]}},{key:"item.actions",fn:function({ item }){return [(_vm.show)?_c('taskim-task-actions',{attrs:{"section":_vm.section,"task":item}}):_vm._e()]}}],null,true),model:{value:(_vm.selectedListings),callback:function ($$v) {_vm.selectedListings=$$v},expression:"selectedListings"}})],1),(!_vm.isDynamicSection)?_c('div',{staticClass:"taskim-section-new-task-container"},[(_vm.show)?_c('inline-text-field',{attrs:{"disable-debounce":true,"placeholder":"+ New Task"},on:{"change":_vm.onSectionNewTask},model:{value:(_vm.newTask),callback:function ($$v) {_vm.newTask=$$v},expression:"newTask"}}):_vm._e()],1):_vm._e()]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }