import { GOOGLE_MAPS_URL } from '@/consts'
import { mapState } from 'vuex'
import { omit } from 'lodash'

export default {
  computed: {
    ...mapState('taskCalendar', ['excludedFilters', 'forcedFilters']),
    ...mapState(['tasksCatalog']),
    quickFieldCatalog() {
      const catalog =
        this.tasksCatalog.catalogItemSelect.find(
          c => c.description === 'Quick Field Task'
        ) || {}
      return {
        task_type: catalog.department,
        sub_topic: catalog.sub_topic,
        listing_task_catalog_item_name: catalog.description,
        listing_task_catalog_item_id: catalog.id,
      }
    },
  },
  methods: {
    prepareFiltersPayload(
      { onlyItems, project, status, ...filters },
      { excludedFilters, forcedFilters } = {}
    ) {
      const filteredItems = onlyItems.reduce((acc, item) => {
        acc[item] = true
        return acc
      }, {})
      if (project) {
        filters.project_id = project.id
      }
      filters.status = status.length ? status : 'All'

      return {
        ...omit(
          {
            ...filters,
            ...filteredItems,
          },
          excludedFilters || []
        ),
        ...(forcedFilters || {}),
      }
    },
    passFiltersSc(day) {
      return !(
        this.filters.availabilities &&
        this.filters.availabilities.length &&
        this.failedAbilitiesFilters(day)
      )
    },
    failedAbilitiesFilters(task) {
      return this.filters.availabilities.every(timePeriod => {
        const [key, val] = timePeriod.split('--')
        return task.mini_extra_data[key] !== val
      })
    },
    directionsTo(task) {
      window.open(
        `${GOOGLE_MAPS_URL}/dir/?api=1&destination=${task.lat},${task.lng}&dir_action=navigate`
      )
    },
    whatsappShare(task) {
      const url = `${process.env.DASHBOARD_BASE_URL}/dashboard/listing-task/${task.id}`
      window.open(`whatsapp://send?text=${url} `, '_blank')
    },
  },
}
