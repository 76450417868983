<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.5"
    height="30"
    viewBox="0 0 27.5 30"
  >
    <g transform="translate(0)">
      <g transform="translate(0 0)">
        <path
          class="a"
          d="M48.208,4.375A.625.625,0,0,0,47.583,5c0,.55-.565,1.158-1.558,1.709a19.474,19.474,0,0,0,.306-3.531c0-.013-.012-.109-.016-.139a1.246,1.246,0,0,0-.05-.306C45.324.031,34.3,0,33.833,0,29.879,0,22.192.558,21.409,2.67a1.243,1.243,0,0,0-.074.447s0,.005,0,.008c0,1.321,1.179,2.342,2.545,3.524,1.736,1.5,3.7,3.207,3.7,5.851a7.45,7.45,0,0,1-1.168,4.377,8.539,8.539,0,0,0-1.321,4.672c-1.58-.6-2.511-1.41-2.511-2.174,0-.657.719-1.364,1.974-1.94a.625.625,0,0,0-.522-1.136c-1.742.8-2.7,1.893-2.7,3.076,0,2.494,4.165,4.375,9.688,4.375s9.688-1.881,9.688-4.375a2.434,2.434,0,0,0-.491-1.425.625.625,0,1,0-1.018.725,1.218,1.218,0,0,1,.259.7c0,1.2-2.28,2.508-5.793,2.96a21.288,21.288,0,0,1,5.855-6.281,19.794,19.794,0,0,0,4.818-4.871.625.625,0,0,0-.69-.936,34.108,34.108,0,0,1-8.566,1,39.529,39.529,0,0,1-4.6-.262.625.625,0,0,1,.148-1.241A38.239,38.239,0,0,0,35.083,10a29.5,29.5,0,0,0,10.28-1.61s.005,0,.007,0C48.233,7.256,48.833,5.92,48.833,5A.625.625,0,0,0,48.208,4.375ZM33.833,3.75a41.25,41.25,0,0,1-7.659-.623A41.685,41.685,0,0,1,33.833,2.5a41.25,41.25,0,0,1,7.659.623A41.685,41.685,0,0,1,33.833,3.75Z"
          transform="translate(-21.333 0)"
        />
        <path
          class="a"
          d="M104.517,419.413l-.54.012a22.166,22.166,0,0,1-4.4-.438.625.625,0,0,0-.684.894,11.075,11.075,0,0,0,4.487,4.478.625.625,0,0,0,.9-.464,24.678,24.678,0,0,1,.846-3.671.628.628,0,0,0-.1-.563A.637.637,0,0,0,104.517,419.413Z"
          transform="translate(-94.29 -394.425)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Hurricane',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
