<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.64 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="Outline">
          <rect
            id="Rectangle_17084"
            data-name="Rectangle 17084"
            class="cls-1"
            x="5.15"
            y="19.98"
            width="0.61"
            height="0.61"
          />
          <path
            id="Path_5227"
            data-name="Path 5227"
            class="cls-1"
            d="M13.34,15.51H7.21V10.1a.36.36,0,0,0-.36-.36H.36A.36.36,0,0,0,0,10.1V21.64A.36.36,0,0,0,.36,22h13a.36.36,0,0,0,.36-.36V15.87A.36.36,0,0,0,13.34,15.51Zm-4,.72h1.44v1.44H9.38Zm-6.5-5.77H4.33V11.9H2.89Zm-2.16,0H2.16v1.8a.36.36,0,0,0,.36.36H4.69a.36.36,0,0,0,.36-.36v-1.8H6.49v5H.72Zm2.16,5.77H4.33v1.44H2.89Zm-2.16,0H2.16V18a.36.36,0,0,0,.36.36H4.69A.36.36,0,0,0,5.05,18v-1.8H6.49v5.05H.72ZM13,21.28H7.21V16.23H8.66V18a.36.36,0,0,0,.36.36h2.16a.36.36,0,0,0,.36-.36v-1.8H13Z"
          />
          <rect
            id="Rectangle_17085"
            data-name="Rectangle 17085"
            class="cls-1"
            x="11.5"
            y="19.98"
            width="0.91"
            height="0.61"
          />
          <rect
            id="Rectangle_17086"
            data-name="Rectangle 17086"
            class="cls-1"
            x="5.15"
            y="13.92"
            width="0.61"
            height="0.91"
          />
          <path
            id="Path_5228"
            data-name="Path 5228"
            class="cls-1"
            d="M21.19,4.68h.09a.35.35,0,0,0,.31-.18.41.41,0,0,0,.05-.19V2.89a.36.36,0,0,0-.28-.36L10.9,0h-.17L.28,2.53A.36.36,0,0,0,0,2.89V4.32a.39.39,0,0,0,.06.21.39.39,0,0,0,.39.15l.63-.16V9H1.8V4.35l9-2.18,9,2.18V21.28H17.67V7.21a.36.36,0,0,0-.36-.36h-13A.36.36,0,0,0,4,7.21H4V8.66A.36.36,0,0,0,4.33,9H17v.72h-9v.72h9v.72h-9v.72h9v.72h-9v.72h9v7.94H14.43V22h7.21v-.72H20.56V4.52ZM4.69,8.3V7.57H17V8.3Zm6.13-6.86h-.09L.72,3.87v-.7L10.82.73l10.1,2.44v.7l-10-2.42Z"
          />
          <path
            id="Path_5229"
            data-name="Path 5229"
            class="cls-1"
            d="M11.54,5.77a1.08,1.08,0,0,0,0-2.16H10.1a1.08,1.08,0,1,0,0,2.16ZM9.74,4.69a.36.36,0,0,1,.36-.36h1.44a.36.36,0,0,1,0,.72H10.1a.36.36,0,0,1-.36-.36Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Inventory',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
