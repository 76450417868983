<template>
  <v-layout v-if="currentGuest" wrap justify-center>
    <v-flex xs6 sm7 mt-3 class="comp-wrapper">
      <v-layout wrap>
        <v-flex sm2 xs4>
          <img class="guest-profile mr-3 pt-1 pb-1" :src="avatarImg" />
        </v-flex>
        <v-flex xs7>
          <v-layout column xs3>
            <v-flex class="grey-text mt-2 mb-2 bb-1">
              <span class="purple-icon bolder"> Name:</span>
              {{ currentGuest.full_name }}
            </v-flex>
            <v-flex class="grey-text mt-2 mb-2 bb-1">
              <span class="purple-icon bolder"> Email:</span>
              {{ currentGuest.emails[0] }}
            </v-flex>
            <v-flex class="grey-text mt-2 mb-2 bb-1">
              <span class="purple-icon bolder"> Phone:</span>
              {{ currentGuest.phones[0] }}
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout column class="bl-1">
            <span class="purple-icon"> Is marketing enabled </span>
            <v-switch
              v-model="marketingEnabled"
              :class="
                currentGuest.marketing_enabled ? 'cyan-icon' : 'grey-text'
              "
              :label="currentGuest.marketing_enabled ? 'ON' : 'OFF'"
              @change="save()"
            >
            </v-switch>
            <span class="purple-icon mt-3"> Is memeber </span>
            <v-switch
              v-model="designedvrMemeber"
              :class="
                currentGuest.designedvr_memeber ? 'cyan-icon' : 'grey-text'
              "
              :label="currentGuest.designedvr_memeber ? 'ON' : 'OFF'"
              @change="save()"
            >
            </v-switch>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex sm7 xs12 justify-center>
      <h3 class="purple-icon mt-3 mb-2 center-text">Notes</h3>
    </v-flex>
    <v-flex sm7 xs12 mt-1>
      <v-layout wrap>
        <v-flex xs12 class="comp-wrapper">
          <div class="grey--text">
            Notes ({{ Object.keys(currentGuest.notes).length }})
          </div>
          <v-flex xs12>
            <v-text-field
              v-model="message"
              prepend-icon="message"
              label="Post new message"
            ></v-text-field>
            <v-btn
              v-if="message"
              class="cyan-icon"
              outlined
              style="max-width: 70px"
              text
              @click="sendMessage"
            >
              Send
            </v-btn>
          </v-flex>
          <v-divider></v-divider>
          <v-flex
            v-for="(val, key) in currentGuest.notes"
            :key="key"
            xs12
            class="grey--text"
          >
            <v-layout wrap class="pa-2">
              <v-flex xs6 class="purple-icon">{{ parseDate(key) }}</v-flex>
              <v-flex xs6 class="cyan-icon" style="text-align: right">{{
                val['user']
              }}</v-flex>
              <v-flex xs12 class="align-left"> {{ val['msg'] }}</v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex sm7 xs12 justify-center>
      <h3 class="purple-icon mt-3 mb-2 center-text">Reservations</h3>
    </v-flex>
    <v-flex sm7 xs12>
      <reservations :guest-reservations="true" class="mt-1"></reservations>
    </v-flex>
    <v-flex sm7 xs12>
      <experience-manager></experience-manager>
    </v-flex>
    <v-flex sm7 xs12 justify-center>
      <h3 class="purple-icon mt-3 mb-2 center-text">Reviews</h3>
    </v-flex>
    <v-flex sm7 xs12 mt-2>
      <v-layout wrap class="comp-wrapper">
        <v-flex
          v-for="(review, index) in currentGuest.guest_stats.reviews"
          :key="index"
          class="bb-1"
        >
          <v-layout wrap>
            <v-flex xs12 class="cyan-icon bolder">
              {{ parseDate(review.created_at) }}
            </v-flex>
            <v-flex xs12 class="grey-text">
              {{ review.public }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios'
import Reservations from 'components/reservations'
import ExperienceManager from 'components/experience-manager'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
export default {
  components: {
    Reservations,
    ExperienceManager,
  },
  mixins: [CommonFunctions, PermissionsMixin],

  data() {
    return {
      message: null,
    }
  },
  computed: {
    marketingEnabled: {
      get() {
        return this.currentGuest.marketing_enabled
      },
      set(value) {
        this.currentGuest.marketing_enabled = value
        this.$store.dispatch('setCurrentGuest', this.currentGuest)
      },
    },
    designedvrMemeber: {
      get() {
        return this.currentGuest.designedvr_memeber
      },
      set(value) {
        this.currentGuest.designedvr_memeber = value
        this.$store.dispatch('setCurrentGuest', this.currentGuest)
      },
    },

    currentGuest() {
      return this.$store.state.currentGuest
    },
    avatarImg() {
      const item = this.currentGuest
      if (item.picture) {
        return item.picture
      } else {
        return '/no_image_available.jpg'
      }
    },
  },
  methods: {
    sendMessage() {
      axios
        .post(`/api/marketing/create-note/${this.currentGuest.id}`, {
          msg: this.message,
        })
        .then(() => {
          this.message = null
          this.$store.dispatch('getCurrentGuest', this.currentGuest.id)
        })
        .catch(error => {
          alert(error)
        })
    },
  },
}
</script>
