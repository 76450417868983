<template>
  <v-row>
    <v-col cols="auto" sm="3" md="2">
      <p class="text-caption mb-0">Total hours</p>
      <span class="text-h6 font-weight-medium"
        >{{ roundWith(hours, 2) }} h</span
      >
    </v-col>
    <v-col cols="auto" sm="3" md="2">
      <p class="text-caption mb-0">Total charge</p>
      <span class="text-h6 font-weight-medium">
        {{ dollarFormatter(charge) }}
      </span>
    </v-col>
    <v-col v-if="layout !== 'paid'" cols="auto" sm="3" md="2">
      <p class="text-caption mb-0">Total deposit</p>
      <span class="text-h6 font-weight-medium">
        {{ dollarFormatter(deposit) }}
      </span>
    </v-col>
    <v-col v-if="layout !== 'paid'" cols="auto" sm="3" md="2">
      <p class="text-caption mb-0">Total left</p>
      <span class="text-h6 font-weight-medium">
        {{ dollarFormatter(charge - deposit) }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ContractorListingTaskDetailsSummary',
  mixins: [CommonFunctions, FormattersMixin],

  props: {
    layout: {
      type: String,
      default: '',
    },
    hours: {
      type: Number,
      default: 0,
    },
    charge: {
      type: Number,
      default: 0,
    },
    deposit: {
      type: Number,
      default: 0,
    },
  },
}
</script>
