<template>
  <v-layout column>
    <v-form
      ref="form"
      v-model="valid"
      class="pa-0 flex checkout-form"
      lazy-validation
      @submit.prevent="submitPaymentForm"
    >
      <v-layout column justify-space-between>
        <v-flex class="spreedly-form">
          <v-row class="d-flex mb-4">
            <v-col class="d-flex flex-column spreedly-field-wrapper" cols="8">
              <label for="spreedly-number">Card Number</label>
              <div id="spreedly-number" class="field flex"></div>
              <div
                v-if="errors.cardNumber"
                class="text-caption error--text ml-2"
              >
                {{ errors.cardNumber }}
              </div>
            </v-col>
            <v-col cols="4" class="d-flex flex-column spreedly-field-wrapper">
              <label for="spreedly-cvv">CVV</label>
              <div id="spreedly-cvv" class="field flex"></div>
              <div v-if="errors.cardCvc" class="text-caption error--text ml-2">
                {{ errors.cardCvc }}
              </div>
            </v-col>
            <v-col cols="4" class="d-flex flex-column spreedly-field-wrapper">
              <v-text-field
                v-model="expirationMonth"
                label="Month"
                outlined
                dense
                placeholder="MM"
                :rules="[v => !!v || 'Month is required']"
                maxlength="2"
                required
              />
            </v-col>
            <v-col cols="4" class="d-flex flex-column spreedly-field-wrapper">
              <v-text-field
                v-model="expirationYear"
                label="Year"
                outlined
                dense
                placeholder="YYYY"
                :rules="[minCurrentYear]"
                maxlength="4"
                required
              />
            </v-col>
          </v-row>
          <v-divider class="form-divider mt-2" />
          <div class="text-caption error--text">
            {{ cardErrors }}
          </div>
          <slot />
          <v-row
            class="justify-md-space-between flex-column-reverse mt-6 px-3 flex-md-row align-md-center"
          >
            <div
              class="d-flex justify-md-start justify-sm-space-between align-center py-md-0 py-2"
            >
              <span class="d-flex">
                <span
                  ><a
                    target="_blank"
                    class="spreedly-link"
                    href="https://spreedly.com/terms"
                    >Terms</a
                  ></span
                >
                <v-divider class="mx-2" vertical />
                <span
                  ><a
                    target="_blank"
                    class="spreedly-link"
                    href="https://spreedly.com/privacy"
                    >Privacy</a
                  ></span
                >
              </span>
            </div>
            <v-btn
              type="submit"
              :loading="paymentProcessing"
              color="primary"
              elevation="0"
              :block="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              large
              :disabled="!valid || !spreedlyReady"
            >
              {{ buttonText || 'Pay Now' }}
            </v-btn>
          </v-row>
        </v-flex>
      </v-layout>
    </v-form>
  </v-layout>
</template>

<script>
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import axios from 'axios'

export default {
  name: 'SpreedlyElement',
  mixins: [FormRulesMixin],
  props: ['environmentKey', 'intentId', 'buttonText', 'metadata', 'guest'],
  data() {
    return {
      spreedly: null,
      errors: {
        cardNumber: '',
        cardCvc: '',
      },
      cardErrors: '',
      valid: false,
      expirationMonth: '',
      expirationYear: '',
      paymentProcessing: false,
      spreedlyReady: false,
    }
  },
  mounted() {
    this.loadSpreedlyScript()
  },
  methods: {
    loadSpreedlyScript() {
      const script = document.createElement('script')
      script.src = 'https://core.spreedly.com/iframe/iframe-v1.min.js'
      script.async = true
      document.head.appendChild(script)
      script.onload = () => {
        this.initializeSpreedly()
      }
    },
    initializeSpreedly() {
      this.spreedly = window.Spreedly
      this.spreedly.init(this.environmentKey, {
        numberEl: 'spreedly-number',
        cvvEl: 'spreedly-cvv',
      })
      this.spreedly.on('ready', () => {
        this.spreedlyReady = true
        this.spreedly.setPlaceholder('number', 'Card')
        this.spreedly.setPlaceholder('cvv', 'CVV')
      })
      this.spreedly.on('errors', errors => {
        this.handleSpreedlyErrors(errors)
      })
      this.spreedly.on('paymentMethod', (token, pmData) => {
        this.cardErrors = ''
        this.handlePaymentMethod(token, pmData)
      })
    },
    submitPaymentForm() {
      if (this.$refs.form.validate()) {
        this.paymentProcessing = true
        this.$emit('payment-processing')
        const additionalFields = {
          month: this.expirationMonth,
          year: this.expirationYear,
          full_name: this.guest.full_name,
          email: this.guest.email,
        }
        this.spreedly.tokenizeCreditCard(additionalFields)
      }
    },
    handleSpreedlyErrors(errors) {
      this.paymentProcessing = false
      this.cardErrors = errors.map(error => error.message).join(', ')
      this.$emit('payment-failed')
    },
    async handlePaymentMethod(token) {
      this.paymentProcessing = false
      axios
        .post('/api/spreedly/purchase', {
          payment_method_token: token,
          payment_intent_id: this.intentId,
        })
        .then(() => {
          console.log('Payment successful')
          this.$emit('payment-success', {
            user: { email: this.email },
          })
        })
        .catch(e => {
          this.handleSpreedlyErrors([{ message: e.response.data.error }])
          this.$emit('payment-failed')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.spreedly-form .field {
  padding: 10px 12px 10px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  width: 100%;
  margin-top: 6px;
  height: 40px;
}

.spreedly-field-wrapper {
  position: relative;
}

.spreedly-link {
  text-decoration: none;
}

.form-divider {
  background: rgb(118, 118, 118);
}

label {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
}
</style>
