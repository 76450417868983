import axios from 'axios'
import filter from 'lodash/filter'

export default {
  state: {
    listingInfoDefs: [],
    requiredInfoDefs: [],
  },
  mutations: {
    updateListingInfoDefs(state, data) {
      state.listingInfoDefs = data
      state.requiredInfoDefs = filter(data, info => {
        return info.is_required_house || info.is_required_unit
      })
    },
  },
  actions: {
    async getListingInfoDefs({ commit }) {
      try {
        const { data } = await axios.get('/api/listing-info-defs/')
        commit('updateListingInfoDefs', data)
      } catch (err) {
        console.error(err)
      }
    },
    async createListingInfoDef({ dispatch }, item) {
      try {
        await axios.post('/api/listing-info-defs/', item)
        dispatch('getListingInfoDefs')
      } catch (err) {
        return err
      }
    },
    async createListingInfoDefBatch({ dispatch }, { listingId, infoDefIds }) {
      try {
        await axios.post(`/api/listing-info-items/${listingId}/create-batch/`, {
          info_def_ids: infoDefIds,
        })
        dispatch('getListingInfoItems', { listingId })
      } catch (err) {
        return err
      }
    },
    async updateListingInfoDef({ dispatch }, item) {
      try {
        await axios.patch(`/api/listing-info-defs/${item.id}`, item)
        dispatch('getListingInfoDefs')
      } catch (err) {
        return err
      }
    },
  },
  getters: {},
}
