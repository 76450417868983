<template>
  <v-layout
    v-if="managementRoles || isCommunicationAgent"
    wrap
    justify-center
    class="center-text mt-4"
  >
    <v-flex xs12 sm6>
      <v-select
        v-model="warningType"
        :items="[
          'All',
          'Problematic payments',
          'Overbooking',
          'Block warning',
          'Missing links',
          'Missing guest info',
          'Awaiting payment',
        ]"
        label="Type"
      ></v-select>
      <v-progress-linear
        v-show="listingLoading"
        color="secondary"
        style="margin: 0"
        :indeterminate="true"
      ></v-progress-linear>
    </v-flex>
    <v-flex v-if="!isEmpty(filteredWarnings)" xs12 class="center-text">
      <h4 class="purple-icon">#{{ filteredWarnings.length }} warnings</h4>
    </v-flex>
    <v-flex xs12 sm8 mt-3 justify-center>
      <v-list v-if="!isEmpty(filteredWarnings)">
        <v-list-item
          v-for="(item, index) in filteredWarnings"
          :key="index"
          :class="colorByType(item)"
          class="comp-wrapper mt-1"
        >
          <v-list-item-action class="small-text mr-2" style="min-width: 130px">
            <a target="_blank" class="pr-2" :href="item.link"
              >{{ item.warning_type }} {{ item.additional_info.type }}</a
            >
          </v-list-item-action>
          <v-list-item-avatar>
            <v-icon> {{ iconByType(item) }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="pointer full-width align-center">
            <v-layout
              wrap
              full-width
              justify-center
              align-center
              center-text
              bolder
              grey--text
              small-text
            >
              <v-flex v-if="item.listing_nickname" xs2 sm3>
                {{ item.listing_nickname }}
              </v-flex>
              <v-flex v-if="item.from" xs3 sm2>
                {{ parseDate(item.from) }}
              </v-flex>
              <v-flex v-if="item.from" xs2 sm1>
                <v-icon class="cyan-icon">arrow_right_alt</v-icon>
              </v-flex>
              <v-flex v-if="item.from" xs3 sm2>
                {{ parseDate(item.to) }}
              </v-flex>
              <v-flex v-if="item.comments" xs8 sm5>
                {{ item.comments }}
              </v-flex>
              <v-flex v-if="item.link" xs2 sm2>
                <v-btn
                  class="white--text"
                  small
                  outlined
                  fab
                  color="teal"
                  @click="editWarning(true, 'dismissed', item)"
                >
                  <v-icon>done_outline</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs12 sm2>
                <v-icon
                  v-if="isEmpty(item.log)"
                  class="grey--text"
                  @click="updateChosenListingSetupTask(item)"
                  >message</v-icon
                >
                <v-badge v-if="!isEmpty(item.log)" color="dark-purple">
                  <span
                    slot="badge"
                    style="font-size: 10px"
                    class="white--text bold"
                    >{{ hashSize(item.log) }}</span
                  >
                  <v-icon
                    class="cyan-icon"
                    @click="updateChosenListingSetupTask(item)"
                    >message</v-icon
                  >
                </v-badge>
              </v-flex>
            </v-layout>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <span v-if="isEmpty(filteredWarnings)" class="mt-3 purple-icon">
        No warnings found
      </span>
    </v-flex>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['taskClicked', 'afterSaveFunc', 'showSwap', 'filterSc', 'filterCt'],
  data() {
    return {
      warningType: 'All',
      searchTerm: '',
    }
  },
  computed: {
    filteredWarnings() {
      let warnings = []
      if (this.warningType !== 'All') {
        this.$store.state.communicationWarnings.forEach(warning => {
          if (warning.warning_type === this.warningType) {
            warnings.push(warning)
          }
        })
      } else {
        warnings = this.$store.state.communicationWarnings
      }
      return warnings
    },
  },
  mounted() {
    this.$store.dispatch('getCommunicationWarnings')
  },
  methods: {
    editWarning(val, key, warning) {
      if (key === 'dismissed') {
        const userConfirm = confirm('Are you sure?')
        if (userConfirm) {
          warning[key] = val
          this.$store.dispatch('updateWarning', warning)
        }
      }
    },
    iconByType(warning) {
      if (warning.warning_type === 'Overbooking') {
        return 'domain_disabled'
      } else if (warning.warning_type === 'Block warning') {
        return 'block'
      } else if (warning.warning_type === 'Problematic payments') {
        return 'payment'
      } else if (warning.warning_type === 'Missing links') {
        return 'link_off'
      }
    },
    colorByType(warning) {
      if (warning.warning_type === 'Overbooking') {
        return 'yellowish'
      } else if (warning.warning_type === 'Block warning') {
        return 'super-light-grey'
      } else if (warning.warning_type === 'Problematic payments') {
        return 'super-light-red'
      } else if (warning.warning_type === 'Missing links') {
        return ''
      }
    },
    changeList(list, warningType) {
      this.warningType = warningType
    },
  },
}
</script>
