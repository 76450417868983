import { render, staticRenderFns } from "./open-new-tab.vue?vue&type=template&id=4fdc2dce&scoped=true&"
import script from "./open-new-tab.vue?vue&type=script&lang=js&"
export * from "./open-new-tab.vue?vue&type=script&lang=js&"
import style0 from "./open-new-tab.vue?vue&type=style&index=0&id=4fdc2dce&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fdc2dce",
  null
  
)

export default component.exports