<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.208"
    height="24"
    viewBox="0 0 25.208 24"
  >
    <path
      class="a"
      d="M347.535,41.085l.033,0a.409.409,0,0,0,.056,0,.913.913,0,0,1,.894.911.408.408,0,1,0,.817,0,1.732,1.732,0,0,0-1.359-1.689v-.392a.408.408,0,1,0-.817,0v.411a1.729,1.729,0,0,0,.447,3.4.912.912,0,1,1-.912.913.408.408,0,0,0-.817,0,1.732,1.732,0,0,0,1.282,1.67v.447a.408.408,0,1,0,.817,0v-.429a1.729,1.729,0,0,0-.37-3.418.912.912,0,0,1-.071-1.821Zm0,0"
      transform="translate(-328.219 -37.507)"
    />
    <path
      class="a"
      d="M19.41.391a5.86,5.86,0,0,0-5.46,3.835H3.813a.427.427,0,0,0-.408.427l-.1,14.327H.419A.4.4,0,0,0,0,19.368v1.818a3.208,3.208,0,0,0,3.063,3.2v.009h12.61v-.012c.1,0,.144.012.209.012h.009a3.208,3.208,0,0,0,3.2-3.208V11.99c.1.006.21.009.316.009a5.8,5.8,0,0,0,0-11.608ZM3.218,23.574h0a2.4,2.4,0,0,1-2.4-2.391V19.8H12.661v1.381a3.2,3.2,0,0,0,.946,2.27c.043.043.1.076.141.127Zm15.058-2.391a2.391,2.391,0,0,1-2.386,2.391h-.008a2.409,2.409,0,0,1-2.4-2.4V19.368a.377.377,0,0,0-.39-.389H4.125L4.219,5.042h9.5a6.136,6.136,0,0,0-.112,1.134A5.759,5.759,0,0,0,14.1,8.514H10.247a.408.408,0,0,0,0,.817h4.3a5.764,5.764,0,0,0,3.729,2.558Zm1.134-10A4.982,4.982,0,1,1,24.392,6.2,4.981,4.981,0,0,1,19.41,11.182Zm0,0"
      transform="translate(0 -0.391)"
    />
    <path
      class="a"
      d="M125.069,212.746a1.266,1.266,0,1,0,1.307,1.266A1.288,1.288,0,0,0,125.069,212.746Zm0,1.715a.451.451,0,1,1,.491-.449A.471.471,0,0,1,125.069,214.461Zm0,0"
      transform="translate(-117.444 -201.905)"
    />
    <path
      class="a"
      d="M192.719,229.912a.408.408,0,0,0,.408.408h5.445a.408.408,0,1,0,0-.817h-5.445A.408.408,0,0,0,192.719,229.912Zm0,0"
      transform="translate(-182.88 -217.807)"
    />
    <path
      class="a"
      d="M125.069,143.2a1.266,1.266,0,1,0,1.307,1.266A1.288,1.288,0,0,0,125.069,143.2Zm0,1.715a.451.451,0,1,1,.491-.449.471.471,0,0,1-.491.449Zm0,0"
      transform="translate(-117.444 -135.912)"
    />
    <path
      class="a"
      d="M125.069,282.281a1.266,1.266,0,1,0,1.307,1.266A1.288,1.288,0,0,0,125.069,282.281Zm0,1.715a.451.451,0,1,1,.491-.449A.471.471,0,0,1,125.069,284Zm0,0"
      transform="translate(-117.444 -267.89)"
    />
    <path
      class="a"
      d="M198.572,299.5h-5.445a.408.408,0,1,0,0,.817h5.445a.408.408,0,1,0,0-.817Zm0,0"
      transform="translate(-182.88 -284.234)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Bill',
}
</script>

<style scoped>
svg {
  color: currentColor;
}
</style>
