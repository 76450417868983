<template>
  <v-dialog
    v-if="open"
    v-model="open"
    :persistent="modalIsPersistent"
    :max-width="modalMaxWidth"
    :fullscreen="fullscreen"
    :scrollable="scrollable"
  >
    <component v-bind="modalProps" :is="open" />
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import AiDistributionModal from 'components/modals/ai-distribution-modal'
import EditCatalogItemModal from 'components/listing-tasks-catalog/edit-catalog-item-modal'
import CatalogItemInfoModal from 'components/listing-tasks-catalog/catalog-item-info-modal'
import RejectModal from 'components/listing-tasks/reject-modal'
import InfoItemModal from 'components/info-item-modal'
import InfoCopyItemModal from 'components/info-copy-item-modal'
import ExpenseCard from 'components/expenses/expense-card'
import SummaryCard from 'components/listing-tasks/summary/summary-card'
import PreMadeMsgModal from 'components/conversations/pre-made-msg-modal'
import AutoListingTaskRuleModal from 'components/auto-listing-task-rule-modal'
import LtLoopMoveToDoneModal from 'components/modals/lt-loop-move-to-done-modal'
import LtContinuationHierarchyModal from 'components/modals/lt-continuation-hierarchy-modal'
import ListingsPaymentsModal from 'components/modals/listings-payments-modal'
import ContractorsPaymentsModal from 'components/modals/contractors-payments-modal'
import UsersListModal from 'components/modals/users-list-modal'
import UpdateFieldsModal from 'components/modals/update-fields-modal'
import ListingsListModal from 'components/modals/listings-list-modal'
import ReservationCardModal from 'components/reservation/reservation-card-modal'
import CalendarBlockModal from 'components/calendar/calendar-block-modal'
import ReviewCard from 'components/reviews/review-card'
import EditStoreMenuItem from 'components/store/edit-store-menu-item'
import ListingRegionModal from 'components/regions/listing-region-modal'
import IntegrationModal from 'components/integration-modal'
import StripeAccountModal from 'components/stripe/stripe-account-modal'
import AddCardModal from 'components/common/add-card-modal'
import InspectionApprovalModal from 'components/listing-tasks/inspection-approval-modal.vue'
import ResetPasswordModal from 'components/user/reset-password-modal.vue'
import ReservationEditModal from 'components/reservation/reservation-edit-modal.vue'
import ReservationOverrideModal from 'components/reservation/reservation-override-modal.vue'
import AutoMessageRuleModal from 'components/auto-message-rules/auto-message-rule-modal.vue'
import BookingEngineModal from 'components/booking-engine/booking-engine-modal.vue'
import ListingCreateModal from 'components/listing-create-modal.vue'
import ListingZoneModal from 'components/zones/listing-zone-modal'
import UserEdit from 'components/user/user-edit'
import AiRejectModal from 'components/conversations/ai-reject-modal'
import CouponRuleModal from 'components/coupon-rule-modal'
import AddPolicyModal from 'components/add-policy-modal'
import AiBuilderModal from 'components/ai-builder-modal'
import ExpenseTypeModal from 'components/modals/expense-type-modal.vue'
import TaxProfileEditModal from 'components/tax-profiles/tax-profile-edit-modal.vue'
import BankAccountModal from 'components/bank-accounts-modal.vue'

export default {
  name: 'GlobalModal',
  components: {
    TaxProfileEditModal,
    ListingCreateModal,
    ReservationOverrideModal,
    ReservationEditModal,
    ResetPasswordModal,
    InspectionApprovalModal,
    AddCardModal,
    AiDistributionModal,
    BookingEngineModal,
    StripeAccountModal,
    IntegrationModal,
    ListingRegionModal,
    EditStoreMenuItem,
    ReviewCard,
    AutoMessageRuleModal,
    CalendarBlockModal,
    ReservationCardModal,
    ListingsListModal,
    UsersListModal,
    ListingsPaymentsModal,
    ContractorsPaymentsModal,
    LtLoopMoveToDoneModal,
    LtContinuationHierarchyModal,
    AutoListingTaskRuleModal,
    SummaryCard,
    ExpenseCard,
    InfoItemModal,
    InfoCopyItemModal,
    CatalogItemInfoModal,
    EditCatalogItemModal,
    PreMadeMsgModal,
    RejectModal,
    UpdateFieldsModal,
    ListingZoneModal,
    UserEdit,
    AiRejectModal,
    CouponRuleModal,
    AddPolicyModal,
    AiBuilderModal,
    ExpenseTypeModal,
    BankAccountModal,
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentModal',
      'modalProps',
      'modalMaxWidth',
      'modalIsPersistent',
      'fullscreen',
      'scrollable',
    ]),
  },
  watch: {
    currentModal(val) {
      this.open = val
    },
    open(val) {
      !val && this.closeModal()
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('hideModal')
    },
  },
}
</script>

<style scoped></style>
