<template>
  <v-container fluid>
    <v-row class="align-center">
      <v-col cols="12" sm="auto" align="center">
        <v-select
          v-model="filters.source"
          label="Source"
          :items="sourceOptions"
          outlined
          hide-details
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto" align="center">
        <v-select
          v-model="filters.owned_by"
          label="Owned By"
          :items="ownedByOptions"
          outlined
          hide-details
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="filters.search"
          :label="$t('Search')"
          prepend-inner-icon="search"
          outlined
          clearable
          hide-details
          @input="fetchDataDebounce(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-btn color="primary" @click="addBankAccount">
          <v-icon>mdi-plus</v-icon>
          Add Internal Account
        </v-btn>
      </v-col>
      <plaid-link :update="true" />
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="filters.only_connected"
          label="Only Connected"
          hide-details
          class="d-inline-block mt-0"
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="filters.only_defaults"
          label="Only defaults"
          hide-details
          class="d-inline-block mt-0"
          @change="fetchData(1)"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        :loading="fetchingData"
        class="elevation-1 w-100 mx-3"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        @pagination="paginationChanged($event)"
      >
        <template #item.actions="{ item }">
          <v-icon small @click="editBankAccount(item)">mdi-pencil</v-icon>
        </template>

        <template #item.company_account="{ item }">
          {{ item.company_account ? 'True' : 'False' }}
        </template>
        <template #item.user_id="{ item }">
          <div
            v-if="
              item.user_id &&
              !usersWithCredits.some(user => user.id === item.user_id)
            "
          >
            User no longer valid
            <v-btn icon @click="updateAccount({ id: item.id, user_id: null })">
              <v-icon small>close</v-icon>
            </v-btn>
          </div>

          <div
            v-else-if="
              item.listings.map(l => l.id).length === 0 &&
              item.owner_listings.map(l => l.id).length === 0
            "
            class="mt-5 min-w-200 d-flex"
          >
            <v-autocomplete
              :label="$t('Connected user')"
              dense
              outlined
              item-text="name"
              :items="usersWithCredits"
              clearable
              item-value="id"
              :value="item.user_id"
              @change="updateAccount({ id: item.id, user_id: $event })"
            >
              <template #selection="{ item }">
                {{ item.name }}
              </template>
              <template
                #item="{ item }"
                class="listing-picker purple-icon mb-1 bb-1 pb-1"
              >
                <v-avatar v-if="item.picture">
                  <img :src="item.picture" />
                </v-avatar>
                <v-avatar v-else :color="roleColor(item)">
                  <span class="white--text text-h5">{{ initials(item) }}</span>
                </v-avatar>
                <span class="ml-3">
                  {{ item.name }}
                  <span class="grey--text">
                    - {{ item.bank_accounts_numbers }}
                  </span>
                </span>
              </template>
            </v-autocomplete>
          </div>
          <div v-else>-</div>
        </template>
        <template #item.listings="{ item }">
          <div v-if="!isEmpty(item.listings.map(l => l.id))" class="mt-5">
            <multi-property-picker
              :value="item.listings.map(l => l.id)"
              :label="$t('Connected Listings')"
              :readonly="true"
              :disabled="true"
            />
          </div>
          <div v-else>-</div>
        </template>
        <template #item.owner_listings="{ item }">
          <div v-if="!isEmpty(item.owner_listings.map(l => l.id))" class="mt-5">
            <multi-property-picker
              :value="item.owner_listings.map(l => l.id)"
              :label="$t('Connected Owner Listings')"
              :readonly="true"
              :disabled="true"
              hide-details
            />
          </div>
          <div v-else>-</div>
        </template>
        <template #item.balance="{ item }">
          {{ item.balance ? dollarFormatter(item.balance) : '-' }}
        </template>
        <template #no-data>
          <v-row>
            <v-col cols="12" class="text-center"> No data available </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { debounce, findIndex } from 'lodash'
import FormattersMixin from 'components/mixins/formatters-mixin'
import MultiPropertyPicker from 'components/multi-property-picker'
import { mapActions, mapGetters } from 'vuex'
import PlaidLink from 'components/plaid/plaid-link.vue'

export default {
  name: 'BankAccounts',
  components: { MultiPropertyPicker, PlaidLink },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  data() {
    return {
      filters: {
        source: 'All',
        owned_by: 'All',
        search: '',
        only_connected: true,
        only_defaults: false,
      },
      fetchingData: true,
      pagination: {
        page: 1,
        itemsPerPage: 25,
        itemsLength: null,
      },
      items: [],
      fetchDataDebounce: debounce(this.fetchData, 300),
      editItem: {},
      sources: ['All', 'CSV Import', 'XERO', 'QUICKBOOKS', 'PLAID', 'INTERNAL'],
      owners: ['All', 'User', 'Listing', 'Owner Listing'],
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    ...mapGetters('users', ['usersList', 'usersMap']),
    sourceOptions() {
      return this.sources
    },
    ownedByOptions() {
      return this.owners
    },
    tableHeaders() {
      const commonHeaders = [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Account Name', value: 'account_name', sortable: false },
        { text: 'Account Number', value: 'account_number', sortable: false },
        { text: 'Routing Number', value: 'routing_number', sortable: false },
        { text: 'Source', value: 'source', sortable: false },
        { text: 'Account Id', value: 'account_id', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        {
          text: 'Is Company Account',
          value: 'company_account',
          sortable: false,
        },
        { text: 'Balance', value: 'balance', sortable: false },
        { text: 'User', align: 'center', value: 'user_id', sortable: false },
      ]

      const actionHeaders = [
        {
          text: 'Listings',
          align: 'center',
          value: 'listings',
          sortable: false,
        },
        {
          text: 'Owner Listings',
          align: 'center',
          value: 'owner_listings',
          sortable: false,
        },
      ]

      return [...commonHeaders, ...actionHeaders]
    },
    usersWithCredits() {
      return this.usersList.filter(
        user => user.active && user.bank_accounts_numbers.length
      )
    },
  },
  methods: {
    ...mapActions('bankAccounts', [
      'searchBankAccounts',
      'updateBankAccount',
      'createNewBankAccount',
    ]),
    fetchData(page) {
      this.fetchingData = true
      if (page) this.pagination.page = page
      this.searchBankAccounts({
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        ...this.filters,
      })
        .then(data => {
          this.items = data.items
          this.pagination = {
            page: parseInt(data.pagi_info.page),
            itemsPerPage: parseInt(data.pagi_info.per_page),
            itemsLength: parseInt(data.pagi_info.count),
          }
        })
        .finally(() => {
          this.fetchingData = false
        })
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchData()
      }
    },
    updateAccount(item) {
      this.editItem = { ...item }
      this.updateBankAccount(this.editItem).then(() => {
        const accountIndex = findIndex(this.items, { id: this.editItem.id })
        if (accountIndex !== -1) {
          this.items[accountIndex] = {
            ...this.items[accountIndex],
            ...this.editItem,
          }
        }
        this.fetchData()
      })
    },
    addBankAccount() {
      this.$store.commit('showModal', {
        name: 'BankAccountModal',
        maxWidth: 400,
        props: {
          afterSaveFunc: accountData => {
            this.filters.source = 'All'
            this.filters.only_connected = false
            this.filters.only_defaults = false
            this.filters.search = accountData.account_name
            this.fetchData()
          },
        },
      })
    },
    editBankAccount(item) {
      this.editItem = { ...item }
      this.$store.commit('showModal', {
        name: 'BankAccountModal',
        maxWidth: 400,
        props: {
          item: this.editItem,
          isEditMode: true,
          afterSaveFunc: accountData => {
            const accountIndex = findIndex(this.items, { id: accountData.id })
            if (accountIndex !== -1) {
              this.items[accountIndex] = {
                ...this.items[accountIndex],
                ...accountData,
              }
            }
            this.fetchData()
          },
        },
      })
    },
  },
}
</script>

<style scoped></style>
