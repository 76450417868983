<template>
  <v-list>
    <v-list-item
      v-for="(item, index) in list"
      :key="index"
      active-class="info--text"
      :ripple="false"
    >
      <v-list-item-avatar
        :width="isMobile ? 60 : 80"
        :height="isMobile ? 60 : 80"
        tile
      >
        <v-img v-if="item.images[0]" :src="item.images[0]"></v-img>
        <v-overlay v-else color="secondary lighten-2" absolute>
          <v-icon>fas fa-image</v-icon>
        </v-overlay>
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="d-flex justify-space-between align-center">
          <div>
            <p class="text-body-2 text-sm-body-1 font-weight-medium mb-0">
              {{ item.title }}
            </p>
            <p class="d-sm-none text-caption mb-0">
              <span
                >Amount: <b>{{ item.requested_amount }}</b></span
              >
              <span
                >Price:
                <b>{{
                  dollarFormatter(item.price_to_client * item.requested_amount)
                }}</b></span
              >
            </p>
          </div>
          <div class="d-none d-sm-flex justify-center align-center">
            <span class="text-caption">Amount</span>
            <span class="font-weight-medium ml-2">{{
              item.requested_amount
            }}</span>
          </div>
          <div class="d-none d-sm-flex align-center">
            <span class="text-caption">Price</span>
            <span class="font-weight-medium ml-2">
              {{
                dollarFormatter(item.price_to_client * item.requested_amount)
              }}
            </span>
          </div>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import DeviceMixin from 'components/mixins/device-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  mixins: [DeviceMixin, CommonFunctions, FormattersMixin],
  props: ['list'],
  methods: {
    round(num) {
      return Math.round(num * 100) / 100
    },
  },
}
</script>
