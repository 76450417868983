<template>
  <div>
    <iframe
      v-if="isPdf(media)"
      width="100%"
      height="500"
      frameborder="0"
      :src="media"
    ></iframe>
    <img
      v-if="!isPdf(media) && isImage(media)"
      style="width: 100%"
      :src="media"
      class="image-design"
    />
    <video-player
      v-if="isVideo(media) && !isImage(media)"
      :src="media"
      :width="370"
      :height="370"
    ></video-player>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import VideoPlayer from './video-player'

export default {
  components: { VideoPlayer },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['media', 'deleteImg', 'rotate'],
}
</script>
