<template>
  <v-menu v-model="menu" offset-y>
    <template #activator="{ on, attrs }">
      <div>
        <v-sheet
          v-bind="attrs"
          :class="[
            `d-flex
            justify-space-between
            conversation-status-label
            text-uppercase
            rounded
            text-no-wrap
            font-weight-semibold
            white--text
            ${getStatusColor(value)}`,
            disabled && 'opacity-30',
            {
              'px-3 py-1 text-caption': small,
              'text-body-2 px-4 py-2': !small,
            },
          ]"
          :width="width"
          v-on="disabled ? null : on"
        >
          <span>{{ value || 'Set Status' }}</span>
          <v-icon class="pl-2" color="white" x-small>
            fas fa-chevron-down
          </v-icon>
        </v-sheet>
      </div>
    </template>
    <v-list v-if="menu">
      <v-list-item-group
        :value="statusValue"
        color="primary"
        @change="statusChanged"
      >
        <template #default>
          <v-list-item
            v-for="item in statuses"
            :key="item"
            :value="item"
            :disabled="item === value"
          >
            <div class="text-caption font-weight-semibold d-flex align-center">
              <div class="">
                <span class="px-2 dot" :class="getStatusColor(item)" />
              </div>
              <span class="ms-2">{{ item }}</span>
            </div>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { CONVERSATION_SALES_STATUSES } from '@/consts'

export default {
  name: 'ConversationStatus',
  props: {
    value: {
      type: String,
      default: 'Set Status',
    },
    small: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [Number, String],
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      statusValue: this.value,
      statuses: CONVERSATION_SALES_STATUSES,
    }
  },
  watch: {
    value(val) {
      this.statusValue = val
    },
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case 'COMPLETE':
          return 'success'
        case 'LOST':
          return 'error'
        case 'WON':
          return 'warning'
        case 'AWAITING PAYMENT':
          return 'purple'
        case 'ENQUIRY PAUSED':
          return 'orange'
        case 'SENT FOR APPROVAL':
          return 'pink'
        case '3RD - EXPIRED/CLOSED TEXT':
          return 'success darken-2'
        case 'EMAIL/WHATSAPP OFFER SENT':
          return 'deep-orange'
        case 'ENQUIRY':
          return 'grey'
        default:
          return 'grey'
      }
    },
    statusChanged(value) {
      this.statusValue = value
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
}
</script>

<style scoped>
.conversation-status-label {
  position: relative;
}

.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}
</style>
