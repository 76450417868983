<template>
  <form-dialog
    class="text-capitalize"
    title="Edit Expenses"
    @close="onClose"
    @submit="submit"
  >
    <template #activator="{ on }">
      <v-list-item v-on="on">Edit expenses</v-list-item>
    </template>
    <v-select
      v-model="division"
      label="Related Division"
      :items="itemsDivisions"
      dense
      outlined
    />
    <listing-select
      v-if="hasAbility('super-accountant')"
      :no-prepend-icon="true"
      :outlined="true"
      :dense="true"
      :clearable="true"
      item-text="nickname"
      :hide-title="true"
      :pre-selected.sync="listingId"
    />
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import CommonFunctions from 'components/mixins/common_functions'
import FormRules from 'components/mixins/form-rules-mixin'
import ListingSelect from 'components/listing-select'
import { isBoolean } from 'lodash'
export default {
  name: 'ExpensesEditModal',
  components: { ListingSelect, FormDialog },
  mixins: [CommonFunctions, FormRules],
  props: ['isSetup'],
  data() {
    return {
      division: null,
      listingId: null,
    }
  },
  computed: {
    itemsDivisions() {
      if (!isBoolean(this.isSetup)) {
        return this.budgetDivisions.concat(this.operationalDivisions)
      }
      if (this.isSetup) {
        return this.budgetDivisions
      }
      return this.operationalDivisions
    },
  },
  methods: {
    onClose() {
      this.division = null
      this.listingId = null
    },
    submit() {
      const payload = {}
      if (this.division) {
        payload.related_division = this.division
      }
      if (this.listingId) {
        payload.listing_id = this.listingId
      }
      this.$emit('submit', payload)
    },
  },
}
</script>

<style scoped></style>
