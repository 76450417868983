<template>
  <v-sheet>
    <conversation-drawer />
    <div class="ma-2 ma-sm-0 d-flex justify-space-between align-center">
      <div class="flex-grow-1">
        <task-calendar-filters
          :open-on-change="false"
          @change="onFiltersChanged"
        />
      </div>
      <div class="text-caption text-center mr-2">Creation date</div>
      <v-btn-toggle v-model="toggleTimeFrame" @change="onTimeFrameChange">
        <v-btn small value="today"> Today </v-btn>
        <v-btn small value="week"> Week </v-btn>
        <v-btn small value="month"> Month </v-btn>
        <v-btn small value="all"> All </v-btn>
      </v-btn-toggle>
    </div>
    <v-divider class="mb-4" />
    <v-card
      outlined
      class="d-flex justify-space-between py-4 px-8 rounded-lg pre-checkin-container"
    >
      <pre-checkin-stat
        class="flex-1"
        label="Total Tasks"
        :value="taskStats.total"
        color="var(--v-info-base)"
      />
      <pre-checkin-stat
        class="flex-1"
        label="New Tasks"
        :value="taskStats.in_new"
        color="var(--v-error-base)"
      />
      <pre-checkin-stat
        class="flex-1"
        label="In Progress"
        :value="taskStats.in_progress"
        color="var(--v-yellow-base)"
      />
      <pre-checkin-stat
        class="flex-1"
        label="Completed"
        :value="taskStats.done"
        color="var(--v-success600-base)"
      />
    </v-card>
    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      disable-sort
      fixed-header
      hide-default-footer
      height="70vh"
      :loading="listingLoading"
    >
      <template #item.priority="{ item }">
        <div v-if="item.priority">
          <lt-priority :value="item.priority" :readonly="true" />
        </div>
        <div v-else>-</div>
      </template>
      <template #item.scheduled_at="{ item }">
        {{ parseDate(item.scheduled_at) }}
      </template>
      <template #item.created_at="{ item }">
        {{ parseDate(item.created_at) }}
      </template>
      <template #item.task="{ item }">
        <a
          class="text-decoration-underline blue--text font-weight-medium"
          @click="openTaskDrawer(item.id)"
          >#{{ item.id }}</a
        >
      </template>
      <template #item.status="{ item }">
        <v-sheet width="180px">
          <lt-status
            :listing-task="item"
            small="true"
            @change="onStatusChange({ id: item.id, ...$event })"
            @after-change="getTasks"
          />
        </v-sheet>
      </template>
      <template #item.assigned_to_name="{ item }">
        {{ item.assigned_to_name || '-' }}
      </template>
    </v-data-table>
    <v-pagination
      v-if="listingTasksPagination"
      v-model="page"
      :length="pageLength"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3 flex"
      @input="getTasks"
    />
  </v-sheet>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TaskCalendarFilters from 'components/calendar/task-calendar-filters.vue'
import ConversationDrawer from 'components/crm/conversation-drawer.vue'
import LtMixin from 'components/mixins/lt-mixin'
import formattersMixin from 'components/mixins/formatters-mixin'
import LtStatus from 'components/listing-tasks/lt-status'
import LtPriority from 'components/listing-tasks/lt-priority'
import PreCheckinStat from 'components/crm/pre-checkin-stat.vue'

export default {
  name: 'Requests',
  components: {
    TaskCalendarFilters,
    ConversationDrawer,
    LtStatus,
    LtPriority,
    PreCheckinStat,
  },
  mixins: [LtMixin, formattersMixin],
  data() {
    return {
      page: 1,
      listingTasks: [],
      listingTasksPagination: {},
      taskStats: {},
      toggleTimeFrame: 'today',
      headers: [
        {
          text: 'Priority',
          value: 'priority',
          align: 'center',
          width: '150px',
        },
        { text: 'Task', value: 'task', width: '120px', align: 'center' },
        {
          text: 'Agent',
          value: 'created_by_name',
          width: '150px',
          align: 'center',
        },
        { text: 'Description', value: 'description' },
        {
          text: 'Created At',
          value: 'created_at',
          width: '150px',
          align: 'center',
        },
        {
          text: 'Scheduled At',
          value: 'scheduled_at',
          width: '150px',
          align: 'center',
        },
        { text: 'Assigned To', value: 'assigned_to_name', align: 'center' },
        { text: 'Status', value: 'status', align: 'center', width: '150px' },
      ],
    }
  },
  async mounted() {
    this.onTimeFrameChange(false)
    this.getTasks()
  },
  methods: {
    ...mapActions(['getListingTasks']),
    onTimeFrameChange(getTasks = true) {
      this.page = 1
      this.filters.date = null
      this.filters.from = null
      this.filters.to = null
      if (this.toggleTimeFrame === 'today') {
        this.filters.date = this.$moment().format('YYYY-MM-DD')
      }
      if (this.toggleTimeFrame === 'week') {
        this.filters.from = this.$moment().startOf('week').format('YYYY-MM-DD')
        this.filters.to = this.$moment().endOf('week').format('YYYY-MM-DD')
      }
      if (this.toggleTimeFrame === 'month') {
        this.filters.from = this.$moment().startOf('month').format('YYYY-MM-DD')
        this.filters.to = this.$moment().endOf('month').format('YYYY-MM-DD')
      }
      if (getTasks) this.getTasks()
    },
    openConversation(conversation) {
      this.$router.push({ query: { conversation } })
    },
    openTaskDrawer(taskId) {
      this.addToQuery({ reportTaskDrawer: taskId })
    },
    onFiltersChanged() {
      this.getTasks()
    },
    async getTasks() {
      const filters = this.prepareFiltersPayload(this.filters, {})
      const data = await this.getListingTasks({
        filters: {
          ...filters,
          triggered_by_ai: true,
          created_date: true,
          stats: true,
          page: this.page,
        },
      })
      this.listingTasks = data.tasks
      this.listingTasksPagination = data.pagi_info
      this.taskStats = data.task_stats
    },
  },
  computed: {
    ...mapState(['listingLoading']),
    ...mapGetters('taskCalendar', ['filters']),
    pageLength() {
      return Math.ceil(
        this.listingTasksPagination.count / this.listingTasksPagination.per_page
      )
    },
    items() {
      return [...(this.listingTasks || [])]
    },
  },
}
</script>

<style scoped>
.pre-checkin-container {
  border-color: none !important;
  background-color: var(--v-light100-base) !important;
}
</style>
