<template>
  <v-layout class="comp-wrapper">
    <v-flex>
      <v-layout wrap class="cyan-icon bolder justify-center mb-2">
        <span class="text-h5 purple-icon">{{
          title ? title : currentPricingYearly.current_year + ' Stats'
        }}</span>
      </v-layout>
      <v-layout wrap class="cyan-icon bolder">
        <v-flex xs4 class="br-1 bb-1"> </v-flex>
        <v-flex xs4 class="br-1 bb-1 center-text">
          {{ currentPricingYearly.current_year - 1 }}
        </v-flex>
        <v-flex xs4 class="bb-1 center-text big-text light">
          {{ currentPricingYearly.current_year }}
        </v-flex>
        <v-flex xs4 class="br-1 br-1 pt-2 pb-2"> Occupancy </v-flex>
        <v-flex xs4 class="br-1 br-1 light-grey pt-2 pb-2 center-text">
          {{
            currentPricingYearly.last_year_occupancy !== 0
              ? round(currentPricingYearly.last_year_occupancy) + '%'
              : 'N/A'
          }}
          <br />
        </v-flex>
        <v-flex
          xs4
          class="br-1 pt-2 pb-2 center-text purpleish white--text big-text"
        >
          <span v-if="currentPricingYearly.occupancy">
            {{ round(currentPricingYearly.occupancy) }}%</span
          >
        </v-flex>

        <v-flex xs4 class="br-1 br-1 pt-2 pb-2"> Confirmed ADR </v-flex>
        <v-flex xs4 class="br-1 br-1 light-grey pt-2 pb-2 center-text">
          {{
            currentPricingYearly.last_year_confirmed_adr !== 0
              ? '$' + currentPricingYearly.last_year_confirmed_adr
              : 'N/A'
          }}
          <br />
        </v-flex>
        <v-flex
          xs4
          class="br-1 pt-2 pb-2 center-text purpleish white--text big-text"
        >
          <span v-if="currentPricingYearly.confirmed_adr">{{
            dollarFormatter(currentPricingYearly.confirmed_adr)
          }}</span>
        </v-flex>

        <v-flex xs4 class="br-1 br-1 pt-2 pb-2"> Avaiable ADR </v-flex>
        <v-flex xs4 class="br-1 br-1 light-grey pt-2 pb-2 center-text">
          -
        </v-flex>
        <v-flex
          xs4
          class="br-1 pt-2 pb-2 center-text purpleish white--text big-text"
        >
          {{ dollarFormatter(currentPricingYearly.unconfirmed_adr) }}
          {{ adrDiffText }}
        </v-flex>

        <v-flex xs4 class="br-1 br-1 pt-2 pb-2"> Confirmed sum </v-flex>
        <v-flex xs4 class="br-1 br-1 light-grey pt-2 pb-2 center-text">
          {{
            currentPricingYearly.last_year_confirmed_sum !== 0
              ? '$' +
                currentPricingYearly.last_year_confirmed_sum?.toLocaleString(
                  'en'
                )
              : 'N/A'
          }}
          <br />
        </v-flex>
        <v-flex
          xs4
          class="br-1 pt-2 pb-2 center-text purpleish white--text big-text"
        >
          {{ dollarFormatter(currentPricingYearly.confirmed_sum) }}
        </v-flex>

        <v-flex xs4 class="br-1 br-1 pt-2 pb-2"> Available sum </v-flex>
        <v-flex xs4 class="br-1 br-1 light-grey pt-2 pb-2 center-text">
          -
        </v-flex>
        <v-flex
          xs4
          class="br-1 pt-2 pb-2 center-text purpleish white--text big-text"
        >
          {{ dollarFormatter(currentPricingYearly.unconfirmed_sum) }}
          {{ sumDiffText }}
        </v-flex>

        <v-flex xs4 class="br-1 br-1 pt-2 pb-2"> Total </v-flex>
        <v-flex xs4 class="br-1 br-1 light-grey pt-2 pb-2 center-text">
          -
        </v-flex>
        <v-flex
          xs4
          class="br-1 pt-2 pb-2 center-text purpleish white--text big-text"
        >
          {{
            dollarFormatter(
              currentPricingYearly.unconfirmed_sum +
                currentPricingYearly.confirmed_sum
            )
          }}
        </v-flex>
      </v-layout>
      <v-layout v-if="!hideCalc" wrap justify-center mt-2>
        <v-flex class="center-text">
          <v-btn outlined class="cyan-icon" text @click="expand = !expand">
            {{ !expand ? 'Show Investment Calc' : 'Hide' }}
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout v-if="expand" wrap mt-2 class="bt-1 pt-2">
        <v-flex xs12>
          <v-flex>
            <v-text-field
              v-model="currentPricing.investment_amount"
              prepend-icon="money"
              label="Investment amount"
              required
            ></v-text-field>
          </v-flex>
        </v-flex>
        <v-flex xs12 class="bt-1 pt-2">
          <h3 class="purple-icon">Current income/outcome</h3>
        </v-flex>
        <v-flex xs6>
          <v-flex>
            <v-text-field
              v-model="currentPricingYearly.confirmed_sum"
              prepend-icon="money"
              label="Current income"
              disabled
            ></v-text-field>
          </v-flex>
        </v-flex>
        <v-flex xs6>
          <v-flex>
            <v-text-field
              v-model="currentExpenses"
              prepend-icon="money"
              label="Current expenses"
              disabled
            ></v-text-field>
          </v-flex>
        </v-flex>
        <v-flex xs12 class="bt-1 pt-2">
          <h3 class="purple-icon">Forecast income/outcome</h3>
        </v-flex>
        <v-flex xs6>
          <v-flex>
            <v-text-field
              v-model="currentPricing.desired_occupancy"
              prepend-icon="money"
              label="Available occupancy"
              required
              :hint="'current: ' + currentPricingYearly.occupancy / 100 + '%'"
            ></v-text-field>
            <span class="grey-text small pl-2"></span>
          </v-flex>
        </v-flex>
        <v-flex xs6>
          <v-flex>
            <v-text-field
              v-model="currentPricing.desired_expenses"
              prepend-icon="money"
              label="Expenses amount"
              required
            ></v-text-field>
          </v-flex>
        </v-flex>
        <v-flex
          v-if="currentPricing.desired_occupancy"
          xs4
          class="bt-1 br-1 mt-1 pt-2"
        >
          <v-flex justify-center>
            <v-flex class="big-text center-text purple-icon">Future Sum</v-flex>
            <v-flex class="big-text center-text cyan-icon">
              {{ dollarFormatter(futureIncome) }}
            </v-flex>
          </v-flex>
        </v-flex>
        <v-flex
          v-if="currentPricing.desired_occupancy"
          xs4
          class="bt-1 br-1 mt-1 pt-2"
        >
          <v-flex justify-center>
            <v-flex class="big-text center-text purple-icon">Yearly Net</v-flex>
            <v-flex class="big-text center-text cyan-icon">
              {{ dollarFormatter(net) }}
            </v-flex>
          </v-flex>
        </v-flex>
        <v-flex
          v-if="currentPricing.desired_occupancy"
          xs4
          class="bt-1 mt-1 pt-2"
        >
          <v-flex justify-center>
            <v-flex class="big-text center-text purple-icon">Cap</v-flex>
            <v-flex class="big-text center-text cyan-icon">
              {{ round(cap * 100) }}%
            </v-flex>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
export default {
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['hideCalc', 'title'],
  data() {
    return {
      expand: false,
    }
  },
  computed: {
    futureIncome() {
      return (
        this.currentPricingYearly.unconfirmed_sum *
        this.currentPricing.desired_occupancy
      )
    },
    totalIncome() {
      return this.currentPricingYearly.confirmed_sum + this.futureIncome
    },
    totalExpenses() {
      if (this.currentPricing.desired_expenses) {
        return (
          this.currentExpenses + Number(this.currentPricing.desired_expenses)
        )
      } else {
        return this.currentExpenses
      }
    },
    net() {
      return this.totalIncome - this.totalExpenses
    },
    cap() {
      return (
        (this.totalIncome - this.totalExpenses) /
        this.currentPricing.investment_amount
      )
    },
    currentPricingYearly() {
      return this.$store.state.currentPricingYearly
    },
    currentPricing() {
      return this.$store.state.currentPricing
    },
    adrDiffText() {
      const diff =
        this.currentPricingYearly.unconfirmed_adr -
        this.currentPricingYearly.unconfirmed_adr_other
      if (diff) {
        return `(${this.currentPricingYearly.unconfirmed_adr_other})`
      }
      return ''
    },
    sumDiffText() {
      const diff =
        this.currentPricingYearly.unconfirmed_sum -
        this.currentPricingYearly.unconfirmed_sum_other
      if (diff) {
        return `(${this.currentPricingYearly.unconfirmed_sum_other})`
      }
      return ''
    },
    currentExpenses() {
      let sum = 0
      const expensesData =
        this.currentPricing.yearly_stats[this.yearNow()]['expenses_data']
      for (var key in expensesData) {
        if (key !== 'Cleaning') {
          sum += expensesData[key].count
        }
      }
      return this.round(sum)
    },
  },
}
</script>
