<template>
  <div class="d-flex justify-space-between align-center">
    <div>
      <v-icon
        class="row-icon mr-5"
        :style="`background: ${background}`"
        :color="color"
        >{{ icon }}</v-icon
      >
      <span class="text-body-2 font-weight-bold">{{ label }}</span>
    </div>
    <div class="text-h6 font-weight-bold">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'label', 'value', 'color', 'background'],
}
</script>

<style scoped>
.row-icon {
  width: 48px;
  height: 48px;
  border-radius: 10px;
}
</style>
