<template>
  <v-list>
    <v-list-item
      v-for="task in tasks"
      :key="task.id"
      :to="`/dashboard/listing-task/${task.id}`"
      target="_blank"
      class="px-0 px-sm-4"
    >
      <v-list-item-icon class="mr-2">
        <v-icon :color="priorityColor(task.priority)" small>mdi-circle</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="text-body-2">
          {{ task.extra_data.listing_name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-chip v-show="task.division" class="mr-2" x-small>
            {{ task.division }}
          </v-chip>
          <v-chip v-show="task.department" class="mr-2" x-small>
            {{ task.department }}
          </v-chip>
          <v-chip v-show="task.sub_topic" class="mr-2" x-small>
            {{ task.sub_topic }}
          </v-chip>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-caption">
          {{ task.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <p
          v-if="task.status"
          class="text-caption mb-0"
          :class="listingTaskStatusColor(task.status)"
        >
          {{ task.status }}
        </p>
        <p class="text-caption secondary--text mb-0">
          {{ parseDate(task.scheduled_at) }}
        </p>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'

export default {
  mixins: [CommonFunctions, PermissionsMixin, ColorsMixin],
  props: ['tasks'],
}
</script>

<style scoped>
.v-expansion-panel:before {
  box-shadow: none !important;
}
.header-action-btn {
  position: absolute;
  top: 66px;
  right: 20px;
}
div >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
