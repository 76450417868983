<template>
  <div ref="container" class="text-resize-container">
    <span ref="wrapper" style="font-size: 1px">
      <slot />
    </span>
    <v-spacer ref="spacer" />
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'TextResize',
  props: {
    maxSize: {
      type: Number,
      default: 16,
    },
  },
  data: function () {
    return {
      loaded: false,
      resizeDebounce: debounce(this.onResize, 100),
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.resizeDebounce)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeDebounce)
  },
  methods: {
    onResize() {
      this.$refs.spacer.classList.remove('d-none')
      let fontSize = 1
      this.$refs.wrapper.style.fontSize = `${fontSize}px`
      while (this.$refs.spacer.clientWidth > 0 && fontSize <= this.maxSize) {
        fontSize += 1
        this.$refs.wrapper.style.fontSize = `${fontSize}px`
      }
      while (this.$refs.spacer.clientWidth <= 0) {
        fontSize -= 1
        this.$refs.wrapper.style.fontSize = `${fontSize}px`
      }
      this.$refs.spacer.classList.add('d-none')
    },
  },
}
</script>

<style scoped>
.text-resize-container {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
}
</style>
