<template>
  <v-menu
    :key="value"
    v-model="menu"
    :disabled="readonly"
    offset-y
    bottom
    max-height="300"
  >
    <template #activator="menuSlotData">
      <v-tooltip
        v-if="value"
        :disabled="menu"
        bottom
        open-delay="100"
        content-class="picker-tooltip"
      >
        <template #activator="tooltipSlotData">
          <span v-bind="tooltipSlotData.attrs" v-on="tooltipSlotData.on">
            <v-chip
              close-icon="close"
              :close="!readonly"
              label
              outlined
              v-bind="menuSlotData.attrs"
              @click:close="onReset"
              v-on="menuSlotData.on"
            >
              {{ truncateBy(selectedItemLastBreadcrumb) }}
            </v-chip>
          </span>
        </template>
        <div class="d-flex align-center flex-nowrap">
          <div v-for="(text, index) in breadcrumbs(selectedItem)" :key="index">
            <span class="text-caption">{{ text }}</span>
            <v-icon
              v-if="index !== breadcrumbs(selectedItem).length - 1"
              small
              color="info"
              class="mx-1"
            >
              fas fa-angle-right
            </v-icon>
          </div>
        </div>
      </v-tooltip>
      <v-chip
        v-else
        class="empty-chip-field"
        label
        v-bind="menuSlotData.attrs"
        v-on="menuSlotData.on"
      >
        {{ title || 'Catalog Item' }}
      </v-chip>
    </template>

    <v-list>
      <v-text-field
        v-model="search"
        class="pa-3"
        autofocus
        hide-details
        dense
        label="Search"
      />
      <v-list-item-group>
        <v-list-item
          v-for="item in items"
          :key="item.id"
          @click="onSelect(item.id)"
        >
          <v-list-item-title class="d-flex align-center">
            <div v-for="(text, index) in breadcrumbs(item)" :key="index">
              <span class="text-caption">{{ text }}</span>
              <v-icon
                v-if="index !== breadcrumbs(item).length - 1"
                small
                color="info"
                class="mx-1"
              >
                fas fa-angle-right
              </v-icon>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import { mapGetters } from 'vuex'
import { last, take } from 'lodash'

export default {
  name: 'CatalogItemPicker',
  components: {},
  mixins: [CommonFunctions],
  props: ['value', 'readonly', 'title'],
  data() {
    return {
      menu: false,
      search: '',
    }
  },
  computed: {
    ...mapGetters(['catalogItems', 'catalogItemsMap']),
    items() {
      return take(this.catalogItems.filter(this.searchFilter), 7)
    },
    selectedItem() {
      return this.catalogItemsMap[this.value]
    },
    selectedItemLastBreadcrumb() {
      return last(this.breadcrumbs(this.selectedItem))
    },
  },
  methods: {
    onReset() {
      this.$emit('input', null)
    },
    onSelect(value) {
      this.$emit('input', value)
      this.search = ''
      this.menu = false
    },
    searchFilter(item) {
      return (
        (item.division &&
          item.division.toLowerCase().includes(this.search.toLowerCase())) ||
        (item.department &&
          item.department.toLowerCase().includes(this.search.toLowerCase())) ||
        (item.sub_topic &&
          item.sub_topic.toLowerCase().includes(this.search.toLowerCase())) ||
        (item.description &&
          item.description.toLowerCase().includes(this.search.toLowerCase()))
      )
    },
    breadcrumbs(item) {
      if (!item) return []
      return [
        item.division,
        item.department,
        item.sub_topic,
        item.description,
      ].filter(Boolean)
    },
  },
}
</script>

<style scoped>
>>> .v-chip.v-chip--outlined.v-chip.v-chip {
  background-color: #ffffff !important;
}

.empty-chip-field {
  border: 1px dashed #000000;
  background-color: #ffffff !important;
}
</style>
