<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="_004-settings" data-name=" 004-settings">
          <g id="Group_10249" data-name="Group 10249">
            <g id="Group_10248" data-name="Group 10248">
              <path
                id="Path_5119"
                data-name="Path 5119"
                class="cls-1"
                d="M21.17,7.85l-2.54-.37A8.3,8.3,0,0,0,17.79,6l1-2.48a1,1,0,0,0-.43-1.21L14.69.14a1,1,0,0,0-1.28.24L11.83,2.46a7.68,7.68,0,0,0-1.66,0L8.59.38A1,1,0,0,0,7.32.14L3.68,2.3a1,1,0,0,0-.43,1.23L4.21,6a8.67,8.67,0,0,0-.84,1.48L.84,7.85a1,1,0,0,0-.84,1v4.34a1,1,0,0,0,.83,1l2.54.38A8.67,8.67,0,0,0,4.21,16l-1,2.47a1,1,0,0,0,.42,1.22l3.66,2.17a1,1,0,0,0,.49.13,1,1,0,0,0,.77-.38l1.58-2.08a9.2,9.2,0,0,0,1.66,0l1.58,2.08a1,1,0,0,0,1.27.25l1.06-.63a.47.47,0,0,0,.14-.63.45.45,0,0,0-.61-.16l-1.13.62L12.4,18.78A.46.46,0,0,0,12,18.6a8,8,0,0,1-2,0,.47.47,0,0,0-.43.18l-1.81,2.3L4.13,18.9a.08.08,0,0,1,0-.09l1.06-2.7a.46.46,0,0,0-.06-.44,8,8,0,0,1-1-1.73.44.44,0,0,0-.35-.28L1,13.25l0-.08V8.83l0-.07,2.79-.42a.44.44,0,0,0,.36-.28,7.57,7.57,0,0,1,1-1.73.46.46,0,0,0,.06-.44l-1-2.68a.11.11,0,0,1,0-.12L7.87.94,9.61,3.23A.45.45,0,0,0,10,3.4a6.37,6.37,0,0,1,2,0,.45.45,0,0,0,.42-.17L14.22.93,17.87,3.1a.06.06,0,0,1,0,.09l-1,2.7a.44.44,0,0,0,.06.44,8.16,8.16,0,0,1,1,1.73.49.49,0,0,0,.36.29l2.8.41.05.08v4.33l-.05.08-1.8.26a.46.46,0,0,0,.14.91l1.78-.26a1,1,0,0,0,.84-1V8.83A1,1,0,0,0,21.17,7.85Z"
              />
              <path
                id="Path_5120"
                data-name="Path 5120"
                class="cls-1"
                d="M16.34,12.94a2.43,2.43,0,0,1-.76-1.78,4.51,4.51,0,0,0-1.45-3.3,4.35,4.35,0,0,0-3.19-1.44,4.54,4.54,0,0,0-2.81,1,.47.47,0,0,0-.07.64.69.69,0,0,0,.13.11L11,9.88a2.51,2.51,0,0,1-1.35,2l-2.5-1.5a.51.51,0,0,0-.48,0,.44.44,0,0,0-.24.37A4.58,4.58,0,0,0,11,15.58a4.21,4.21,0,0,0,1.05-.14l5.85,5.85a2.4,2.4,0,1,0,3.4-3.4h0Zm3.26,8.14h0a1.47,1.47,0,0,1-1.05-.43l-6-6a.47.47,0,0,0-.33-.14l-.13,0a3.39,3.39,0,0,1-1.05.17,3.65,3.65,0,0,1-3.62-3.1l2,1.21a.46.46,0,0,0,.44,0l.2-.1a3.4,3.4,0,0,0,1.89-3.06.47.47,0,0,0-.22-.39L9.28,7.77A3.62,3.62,0,0,1,11,7.33a3.51,3.51,0,0,1,2.52,1.15,3.63,3.63,0,0,1,1.2,2.64,3.31,3.31,0,0,0,1,2.47l4.95,4.95a1.49,1.49,0,0,1-1,2.54Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Settings',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
