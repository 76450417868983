<template>
  <div
    class="d-flex list-checkin-btn justify-space-between align-center py-2"
    @click="$emit('click')"
  >
    <span style="flex: 4">
      <v-icon x-small left color="warning lighten-1">fas fa-square</v-icon>
      {{ label }}
    </span>
    <span style="flex: 1" class="text-center">{{ positive }}</span>
    <span style="flex: 1" class="text-center">{{ negative }}</span>
  </div>
</template>

<script>
export default {
  name: 'TodayReportRow',
  props: ['positive', 'negative', 'label'],
  methods: {},
}
</script>

<style></style>
