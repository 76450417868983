<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.227"
    height="22.095"
    viewBox="0 0 14.227 22.095"
  >
    <g transform="translate(0)">
      <path
        class="a"
        d="M91.158,224.281a.923.923,0,0,0,.921.921h12.382a.923.923,0,0,0,.921-.921V212.267H91.158v12.014Z"
        transform="translate(-91.158 -203.107)"
      />
      <path
        class="b"
        d="M104.509,28.8H92.081a.923.923,0,0,0-.921.921v6.352h14.224V29.721A.858.858,0,0,0,104.509,28.8Z"
        transform="translate(-91.16 -27.557)"
      />
      <rect
        class="c"
        width="14.224"
        height="0.644"
        transform="translate(0 8.516)"
      />
      <rect
        class="d"
        width="4.971"
        height="1.243"
        transform="translate(4.649)"
      />
      <path
        class="e"
        d="M184.165,260.267l-2.256,4.511h1.1l-2.256,4.235,5.248-5.478h-1.473l2.762-3.268Z"
        transform="translate(-176.892 -249.035)"
      />
      <path
        class="a"
        d="M216.534,70.9h-1.565V69.333h-.644V70.9h-1.565v.69h1.565v1.519h.644V71.589h1.565Z"
        transform="translate(-207.511 -66.341)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Battery',
}
</script>

<style scoped>
.a {
  fill: #40596b;
}

.b {
  fill: #ffd15c;
}

.c {
  fill: #f2f2f2;
}

.d {
  fill: #cdd6e0;
}

.e {
  fill: #fff;
}
</style>
