<template>
  <v-row no-gutters>
    <v-col
      v-if="!isOwnerVacation && reservation.listing_id !== marbellaId"
      class="px-2"
      cols="12"
    >
      <v-autocomplete
        v-model="reservation.guest"
        :items="guests"
        :search-input.sync="search"
        item-value="guesty_id"
        item-text="full_name"
        return-object
        no-filter
        outlined
        dense
        clearable
        label="Search Existing Guests..."
        @change="guestSelect"
      >
        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title class="info--text" v-html="item.full_name" />
            <v-list-item-subtitle v-html="item.emails.join(', ')" />
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col class="px-2" cols="6">
      <v-text-field
        v-model="reservation.first_name"
        label="First Name"
        :rules="[required]"
        outlined
        dense
      />
    </v-col>
    <v-col class="px-2" cols="6">
      <v-text-field
        v-model="reservation.last_name"
        label="Last Name"
        :rules="[required]"
        outlined
        dense
      />
    </v-col>
    <v-col class="px-2" cols="12" md="6">
      <v-text-field
        v-model="reservation.email"
        label="Email"
        :rules="emailRules"
        outlined
        dense
      />
    </v-col>
    <v-col class="px-2" cols="12" md="6">
      <phone-input :key="phoneKey" v-model="reservation.phone" />
    </v-col>
    <v-col v-if="isStr && !isOwnerVacation" class="px-2" cols="12">
      <v-text-field
        v-model="reservation.city"
        label="home town"
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import PhoneInput from 'components/common/phone-input.vue'
import { MARBELLA_DEMO_LISTING_ID } from '@/consts'

export default {
  mixins: [FormRules],
  components: { PhoneInput },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
    isOwnerVacation: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      phoneKey: 0,
      guests: [],
      chosenGuest: null,
    }
  },
  computed: {
    marbellaId() {
      return MARBELLA_DEMO_LISTING_ID
    },
    isStr() {
      return this.reservation.reservation_type !== 'long_term'
    },
  },
  watch: {
    search(val) {
      if (!val) {
        return
      }
      this.fetchEntriesDebounced(val)
    },
  },
  methods: {
    guestSelect(guest) {
      this.chosenGuest = guest
      const [first_name, last_name] = guest.full_name.split(' ')
      this.reservation.first_name = first_name
      this.reservation.last_name = last_name
      this.reservation.phone = guest.primary_phone || guest.phones[0]
      this.reservation.email = guest.primary_email || guest.emails[0]
      this.reservation.guest_id = guest.guesty_id
    },
    fetchEntriesDebounced(newValue) {
      // cancel pending call
      clearTimeout(this._timerId)

      // delay new call 500ms
      this._timerId = setTimeout(async () => {
        this.guests = await this.$store.dispatch('guests/fetchGuests', {
          term: newValue,
        })
      }, 1000)
    },
  },
}
</script>

<style></style>
