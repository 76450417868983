<template>
  <v-sheet class="mt-4" width="100%">
    <v-card v-if="settings" class="pa-4">
      <div class="text-h5">{{ $t('Usage') }}</div>
      <div class="d-flex mt-3">
        <div>
          <strong>Input:</strong>
          <span class="green--text font-weight-bold">
            ${{ settings.input_tokens_usd }}
          </span>
          <span class="font-weight-regular">
            ({{ settings.input_tokens_usage }} tokens)
          </span>
        </div>
        <div class="ml-3">
          <strong>Output:</strong>
          <span class="green--text font-weight-bold">
            ${{ settings.output_tokens_usd }}
          </span>
          <span class="font-weight-regular">
            ({{ settings.output_tokens_usage }} tokens)
          </span>
        </div>
      </div>
      <v-row>
        <v-col>
          <div class="text-h5">{{ $t('Deployment Info') }}</div>
          <div v-if="items.length > 0" class="my-2">
            <v-data-table
              :headers="headers"
              :items="items"
              class="elevation-1 w-100"
            >
              <template #item.deployed_at="{ item }">
                <span>
                  {{ parseDateWithTime(item.deployed_at) }}
                </span>
              </template>

              <template #item.listings="{ item }">
                <span v-if="item.ai_version.listing_id">{{ listing.id }}</span>
                <span v-else>ALL</span>
              </template>

              <template #item.prompt="{ item }">
                <v-btn icon @click="openPromptModal">
                  <v-icon small>preview</v-icon>
                </v-btn>

                <v-dialog v-model="dialog">
                  <div class="custom-json-editor jse-theme-dark">
                    <json-editor-vue
                      v-model="item.instructions"
                      mode="text"
                    ></json-editor-vue>
                  </div>
                </v-dialog>
              </template>
            </v-data-table>
          </div>
          <div v-else class="text-body-1 my-2">
            No AI deployment found. Please, make a new deployment first.
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
import axios from '@/axios/config'
import JsonEditorVue from 'json-editor-vue'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  components: {
    JsonEditorVue,
  },
  props: ['settings', 'tenantId'],
  mixins: [CommonFunctions],
  data: () => ({
    aiDeployment: {},
    items: [],
    headers: [
      { text: 'Date', value: 'deployed_at' },
      { text: 'Agent', value: 'ai_version.ai_agent_type' },
      { text: 'Version', value: 'ai_version.version_number' },
      { text: 'Listings', value: 'listings' },
      { text: 'Preview', value: 'prompt' },
    ],
    dialog: false,
  }),
  watch: {
    tenantId: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.fetchData(newVal)
          this.fetchListData(newVal)
        }
      },
    },
  },
  methods: {
    openPromptModal() {
      this.dialog = true
    },
    async fetchData(tenantId) {
      if (!tenantId) return

      try {
        const { data } = await axios.get('/api/ai-deployments/last', {
          params: { tenant_id: tenantId },
        })
        this.aiDeployment = data.item
      } catch (e) {
        console.log(e)
      }
    },
    async fetchListData(tenantId) {
      if (!tenantId) return

      try {
        const { data } = await axios.get('/api/ai-deployments', {
          params: { tenant_id: tenantId },
        })
        this.items = data
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>
@import 'vanilla-jsoneditor/themes/jse-theme-dark.css';

.custom-json-editor {
  --jse-theme-color: #44a2a2;
  --jse-theme-color-highlight: #687177;
  --jse-panel-background: #f7f7f7;
  --jse-background-color: #3c3a3a;
}
</style>
