<template>
  <div class="d-inline-block">
    <v-btn depressed outlined icon class="btn-border" @click="showDialog">
      <v-icon class="info--text" small>mdi-code-json</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Raw Rentals United Data
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-4">
          <div v-if="loading" class="text-center py-4">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else-if="error" class="error--text">
            {{ error }}
          </div>
          <div v-else>
            <div class="d-flex justify-end mb-2">
              <v-btn
                small
                outlined
                color="primary"
                class="me-2"
                :loading="syncLoading"
                @click="resyncData"
              >
                <v-icon small class="me-1">mdi-sync</v-icon>
                Re-sync Data
              </v-btn>
              <copy-clipboard
                :text="prettyJson"
                description="Copy JSON"
                color="primary"
              />
            </div>
            <pre class="raw-data">{{ prettyJson }}</pre>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CopyClipboard from 'components/copy-clipboard'

export default {
  name: 'RawRuDataDialog',

  components: {
    CopyClipboard,
  },

  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      syncLoading: false,
      error: null,
      rawData: null,
    }
  },

  computed: {
    isAdmin() {
      return this.$store.state.user?.abilities?.includes('super-admin')
    },

    prettyJson() {
      return this.rawData ? JSON.stringify(this.rawData, null, 2) : ''
    },
  },

  methods: {
    async showDialog() {
      this.dialog = true
      this.loading = true
      this.error = null

      try {
        this.rawData = await this.$store.dispatch('reservation/getRawRuData', {
          id: this.reservation.id,
        })
      } catch (err) {
        this.error = err.response?.data?.error || 'Failed to load data'
      } finally {
        this.loading = false
      }
    },

    async resyncData() {
      this.syncLoading = true
      try {
        await this.$store.dispatch('reservation/resyncReservation', {
          id: this.reservation.id,
        })
        // Refresh reservation data
        await this.$emit('refresh')
        // Reload raw data
        this.rawData = await this.$store.dispatch('reservation/getRawRuData', {
          id: this.reservation.id,
        })
      } catch (err) {
        this.$store.commit(
          'setSnackbar',
          {
            message: err.response?.data?.error || 'Failed to resync data',
            color: 'error',
          },
          { root: true }
        )
      } finally {
        this.syncLoading = false
      }
    },
  },
}
</script>

<style scoped>
.raw-data {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
  font-size: 12px;
  max-height: 600px;
  overflow-y: auto;
}
.btn-border {
  border: solid 1px #dfe1e6;
}
</style>
