<template>
  <form-dialog
    title="Create continuation task"
    :max-width="800"
    @submit="duplicate"
    v-on="$listeners"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :rounded="false"
        class="rounded-0 justify-start"
        block
        :disabled="disabled"
        text
        small
        color="info"
        elevation="0"
        v-on="on"
      >
        <v-icon small left
          >{{
            buttonText === 'Continuation'
              ? 'fas fa-forward'
              : 'fa-exclamation-triangle'
          }}
        </v-icon>
        {{ $t(buttonText) }}
      </v-btn>
    </template>
    <catalog-quick-select
      v-model="newTask.listing_task_catalog_item_id"
      :rules="[required]"
    />
    <v-textarea
      v-model="newTask.description"
      class="mt-3"
      :label="$t('Description')"
      required
      :rules="[required]"
      rows="3"
      dense
      outlined
    />
    <contractor-select
      v-if="!isClaim"
      class="mt-3 mb-3"
      :value.sync="newTask.assigned_contractor_id"
    />
    <claim-items
      v-if="isClaim"
      v-model="newTask.claim_items"
      class="comp-wrapper"
      :entry-mode="entryMode"
    />
    <v-menu
      v-if="!isClaim"
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      required
      min-width="290px"
    >
      <template #activator="{ on }">
        <v-text-field
          v-model="newTask.scheduled_at"
          prepend-inner-icon="event"
          label="Scheduled at"
          readonly
          :rules="[required]"
          dense
          outlined
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="newTask.scheduled_at"
        :events="allowedDates"
        :event-color="eventDateColor"
        @change="saveDate"
      />
    </v-menu>
  </form-dialog>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormMixin from 'components/mixins/form-rules-mixin'

import ContractorSelect from 'components/contractor-select.vue'
import CatalogQuickSelect from 'components/catalog-quick-select'
import ClaimItems from 'components/claim-items'
import axios from 'axios'
import FormDialog from 'components/common/form-dialog'
import Toaster from '@/utils/toaster'

export default {
  name: 'LtContinuationModal',
  components: {
    FormDialog,
    ClaimItems,
    CatalogQuickSelect,
    ContractorSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormMixin],
  props: [
    'listingTaskId',
    'disabled',
    'allowedDates',
    'buttonText',
    'entryMode',
    'preFields',
  ],
  data() {
    return {
      dialog: false,
      menu: false,
      maxPerDay: 10,
      createdTaskId: null,
      changedDate: null,
      listingTask: {},
      newTask: {
        listing_task_catalog_item_id: null,
        listing_task_catalog_item_name: null,
        scheduled_at: this.parseDate(this.$moment()),
      },
    }
  },
  computed: {
    isClaim() {
      return (
        this.newTask.listing_task_catalog_item_name &&
        this.newTask.listing_task_catalog_item_name
          .toLowerCase()
          .includes('claim')
      )
    },
  },
  watch: {
    async listingTaskId(id) {
      await this.updateOriginTask(id)
    },
  },
  async mounted() {
    await this.updateOriginTask(this.listingTaskId)
  },
  methods: {
    async updateOriginTask(id) {
      if (id) {
        const {
          data: { listing_task },
        } = await axios.get(`/api/listing-tasks/${id}`)
        this.listingTask = listing_task
        const { preFields = {} } = this
        this.newTask.description =
          preFields.description || listing_task.description
        this.newTask.listing_task_catalog_item_id =
          preFields.listing_task_catalog_item_id ||
          listing_task.listing_task_catalog_item_id
        this.newTask.listing_task_catalog_item_name =
          preFields.listing_task_catalog_item_name ||
          listing_task.listing_task_catalog_item_name
        this.newTask.used_items_ids = preFields.used_items_ids
      }
    },
    addressFor(id) {
      return { path: `/dashboard/listing-task/${id}` }
    },
    closeSnack() {
      this.dialog = false
      this.createdTaskId = null
      this.newTask = {}
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    async duplicate() {
      const task = await this.$store.dispatch('createContinuationTask', {
        listing_task_id: this.listingTaskId,
        ...this.newTask,
      })
      this.$emit('oncreated')
      Toaster.show([
        {
          type: 'success',
          timeout: 10000,
          text: `Listing task #${task.id} created successfully, click to go to task`,
          onClick: () => {
            const routeData = this.$router.resolve({
              name: 'lt-page',
              params: { id: task.id },
            })
            window.open(routeData.href, '_blank')
          },
        },
      ])
    },
  },
}
</script>
