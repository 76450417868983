var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile)?_c('v-navigation-drawer',{key:_vm.isRtl ? '1' : '0',staticStyle:{"padding-top":"64px"},attrs:{"mini-variant":_vm.isMobileOrIPad,"permanent":"","fixed":"","right":_vm.isRtl,"width":"250"}},[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{staticClass:"font-weight-normal",attrs:{"mandatory":"","color":"black"}},[_vm._l((_vm.filteredItems),function(item,i){return [(item.children)?_c('v-list-group',{key:i,attrs:{"value":_vm.childrenChosen(item),"prepend-icon":item.icon,"color":"black"},on:{"click":function($event){return _vm.groupClick(item.children)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true)},_vm._l((item.children),function(subItem){return _c('v-list-item',{key:subItem.value,class:[
              subItem.value === _vm.paramsTab ? 'menu-active' : '',
              {
                'menu-active-rtl': _vm.isRtl && subItem.value === _vm.paramsTab,
                'menu-active-ltr': !_vm.isRtl && subItem.value === _vm.paramsTab,
              },
            ],on:{"click":function($event){return _vm.groupClick(subItem.value)}}},[_c('v-list-item-icon',{staticClass:"mr-2 pl-md-3"},[_c('v-icon',[_vm._v(_vm._s(subItem.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(subItem.title))])],1)],1)}),1):_c('v-list-item',{key:item.value,class:[
            item.value === _vm.paramsTab ? 'menu-active' : '',
            {
              'menu-active-rtl': _vm.isRtl && item.value === _vm.paramsTab,
              'menu-active-ltr': !_vm.isRtl && item.value === _vm.paramsTab,
            },
          ],attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.groupClick(item.value)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)]})],2)],1)],1):_c('v-bottom-navigation',{attrs:{"fixed":""}},[_c('v-tabs',{attrs:{"height":"56","show-arrows":"","icons-and-text":"","centered":""},model:{value:(_vm.paramsTab),callback:function ($$v) {_vm.paramsTab=$$v},expression:"paramsTab"}},_vm._l((_vm.flatItems),function(item){return _c('v-tab',{key:item.value,attrs:{"href":`#${item.value}`}},[_c('span',{staticClass:"text-tiny py-1 mb-0"},[_vm._v(_vm._s(item.title))]),_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }