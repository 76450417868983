<template>
  <v-btn-toggle
    dense
    rounded
    color="primary"
    value="showListings"
    multiple
    @change="toggleSelected"
  >
    <v-btn>
      <v-icon>mdi-home</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  props: ['showListings'],
  data() {
    return {
      toggle_exclusive: [],
    }
  },
  methods: {
    toggleSelected() {
      this.$emit('toggle-show-listing')
    },
  },
}
</script>

<style></style>
