<template>
  <v-row class="p-relative w-100" height="900">
    <listing-task-map
      class="fill-height w-100 h-100 p-absolute"
      :tasks="mapTasks"
      :users="mapUsers"
      :locations="mapLocations"
      :center="center"
      :show-directions="isContractorScope && selectedUser"
      @task-map-click="taskMapClick"
      @route-info="routeMinutesHandler"
    />
    <v-col cols="12" md="2" class="pa-0 ma-2">
      <div class="p-relative mb-2">
        <listings-map-view
          :show-listings.sync="showListings"
          @toggle-show-listing="showListings = !showListings"
        />
      </div>
      <tasks-map-list
        :tasks="listingTasks"
        :users="users"
        :task-order-map="taskOrderMap"
        :directions-info="contractorDirectionsInfo"
        :is-contractor-scope="isContractorScope"
        :listing-task="currentTask"
        @task-click="taskMapClick"
        @user-click="userClick"
        @change-to-task="afterChangeToTask"
      />
    </v-col>
    <v-spacer />
    <v-col v-if="currentTask" cols="12" md="4" class="pa-0 ma-2">
      <v-card height="700px" class="overflow-auto">
        <lt-minimal-view
          :close="close"
          :event="currentEvent"
          :listing-task="currentTask"
          :task-order-map="taskOrderMap"
          :calendar="calApi"
          @change-to-task="afterChangeToTask"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CalendarMixin from 'components/mixins/calendar-mixin'
import { mapTaskOrder } from 'components/calendar/utils'
import TasksMapList from 'components/tasks-map-view/tasks-map-list.vue'
import ListingTaskMap from 'components/listing-task-map.vue'
import LtMinimalView from 'components/listing-tasks/lt-minimal-view'
import ListingsMapView from 'components/tasks-map-view/listings-map-view.vue'

export default {
  components: { TasksMapList, ListingTaskMap, LtMinimalView, ListingsMapView },
  mixins: [CalendarMixin],
  props: ['calApi', 'isContractorScope'],
  data() {
    return {
      center: null,
      currentTask: null,
      currentEvent: null,
      selectedUser: null,
      listTab: null,
      contractorDirectionsInfo: {},
      showListings: false,
    }
  },
  computed: {
    ...mapState(['listingTasks', 'listingTasksLoaded', 'listingTaskLoading']),
    ...mapGetters(['listingsPicker']),
    taskOrderMap() {
      return mapTaskOrder(this.listingTasks)
    },
    users() {
      return this.filteredContractors || []
    },
    mapTasks() {
      if (this.isContractorScope && this.selectedUser) {
        return this.listingTasks.filter(
          lt => lt.assigned_contractor_id === this.selectedUser.id
        )
      }
      return this.listingTasks
    },
    mapUsers() {
      if (this.isContractorScope && this.selectedUser) {
        return [this.selectedUser]
      }
      return this.users
    },
    mapLocations() {
      return this.showListings ? this.listingsPicker : []
    },
  },
  methods: {
    async taskMapClick(task) {
      this.$store.dispatch('getListingTaskBg', task.id)
      this.currentTask = task
      this.currentEvent = this.calApi.getEventById(task.id)
      this.center = { lat: task.lat, lng: task.lng }
    },
    afterChangeToTask() {
      this.$emit('get-tasks')
    },
    close() {
      this.currentTask = null
    },
    userClick(user) {
      this.selectedUser = user
    },
    routeMinutesHandler({ userId, minutes, meters }) {
      this.contractorDirectionsInfo = {
        ...this.contractorDirectionsInfo,
        [userId]: { minutes, meters },
      }
    },
  },
}
</script>
