<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="a"
      d="M.378,10.541h9.784a.378.378,0,0,0,.378-.378V.378A.378.378,0,0,0,10.162,0H.378A.378.378,0,0,0,0,.378v9.784A.378.378,0,0,0,.378,10.541Zm0,0"
      transform="translate(0 13.459)"
    />
    <path
      class="a"
      d="M249.378,10.541h9.784a.378.378,0,0,0,.378-.378V.378A.378.378,0,0,0,259.162,0h-9.784A.378.378,0,0,0,249,.378v9.784A.378.378,0,0,0,249.378,10.541Zm0,0"
      transform="translate(-235.541 13.459)"
    />
    <path
      class="a"
      d="M.378,259.541h9.784a.378.378,0,0,0,.378-.378v-9.784a.378.378,0,0,0-.378-.378H.378a.378.378,0,0,0-.378.378v9.784A.378.378,0,0,0,.378,259.541Zm0,0"
      transform="translate(0 -249)"
    />
    <path
      class="a"
      d="M249.378,259.541h9.784a.378.378,0,0,0,.378-.378v-9.784a.378.378,0,0,0-.378-.378h-9.784a.378.378,0,0,0-.378.378v9.784A.378.378,0,0,0,249.378,259.541Zm0,0"
      transform="translate(-235.541 -249)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Tiles',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
