<template>
  <div>
    <TaskCalendarFilters class="ma-2 ma-sm-5" @on-fetch="filterChanged" />
    <v-card class="ma-sm-5">
      <v-card-title>
        <v-row>
          <v-col cols="6" sm="3">
            <date-picker
              v-model="from"
              label="Scheduled from"
              @change="saveFrom"
            />
          </v-col>
          <v-col cols="6" sm="3">
            <date-picker v-model="to" label="Scheduled to" @change="saveTo" />
          </v-col>
          <v-col v-if="!isMobile" cols="12" sm="3">
            <v-select
              v-model="paid"
              label="Paid"
              clearable
              :items="paidItems"
              outlined
              dense
              @change="paidChange"
            />
          </v-col>
          <v-col v-if="!isMobile" class="pt-0 ml-3" cols="12" sm="2">
            <div>
              Total hours:
              <span class="font-weight-medium">{{ totalHours }}</span>
            </div>
            <div>
              Total amount:
              <span class="font-weight-medium">{{
                dollarFormatter(totalAmount)
              }}</span>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider v-if="isMobile" />
      <v-data-table
        :headers="orderedHeaders"
        :items="filteredRequests"
        class="elevation-1 px-0 px-sm-4"
        disable-sort
        fixed-header
        height="60vh"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, -1],
        }"
        @pagination="paginationChanged($event)"
        @click:row="onRowClicked"
      >
        <template #item.scheduled_at="{ item }">
          {{ parseDate(item.scheduled_at) }}
        </template>
        <template #item.assigned_to_name="{ item }">
          {{ item.assigned_to_name || '-' }}
        </template>
        <template #item.priority="{ item }">
          <lt-priority
            v-model="item.priority"
            @input="priorityChanged(item.id, $event)"
          />
        </template>
        <template #item.status="{ item }">
          <lt-status
            :listing-task="item"
            small="true"
            @after-change="getListingTasks"
          />
        </template>
        <template #item.description="{ item }">
          <div class="text-start text-caption">
            {{ item.description }}
          </div>
        </template>
        <template #item.material_expenses="{ item }">
          <div>
            {{ expensesSum(item.material_expenses) }}
          </div>
        </template>
        <template #item.amount="{ item }">
          <div>
            {{ dollarFormatter(item.amount) }}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import LtMixin from 'components/mixins/lt-mixin'
import TaskCalendarFilters from 'components/calendar/task-calendar-filters'
import { orderBy } from 'lodash'
import { keyBy } from 'lodash/fp'
import FormattersMixin from 'components/mixins/formatters-mixin'
import DatePicker from 'components/form-fields/date-picker.vue'
import LtPriority from 'components/listing-tasks/lt-priority'
import LtStatus from 'components/listing-tasks/lt-status'
import DeviceMixin from 'components/mixins/device-mixin'
import { sumBy } from 'lodash'

export default {
  components: {
    DatePicker,
    TaskCalendarFilters,
    LtPriority,
    LtStatus,
  },
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    LtMixin,
    FormattersMixin,
    DeviceMixin,
  ],
  props: ['listingId', 'viewMode', 'useCalendar', 'hideFilters'],
  data: function () {
    return {
      headers: [
        {
          text: 'Priority',
          align: 'center',
          value: 'priority',
          width: '100px',
          sortable: false,
          mobileOrder: 2,
        },
        { text: 'Listing', value: 'listingNickname', mobileOrder: 2 },
        {
          text: 'Description',
          value: 'description',
          sortable: false,
          mobileOrder: 6,
        },
        {
          text: 'Scheduled At',
          align: 'center',
          value: 'scheduled_at',
          width: '120px',
          mobileOrder: 4,
        },
        {
          text: 'Hours',
          align: 'center',
          value: 'total_hours',
        },
        {
          text: 'Contractor',
          value: 'assigned_to_name',
          mobileOrder: 5,
          width: '130px',
          align: 'center',
        },
        {
          text: 'Material Cost',
          value: 'material_expenses',
          align: 'center',
        },
        {
          text: 'Labor Cost',
          value: 'amount',
          align: 'center',
        },
        {
          text: 'Status',
          align: 'center',
          value: 'status',
          width: '180px',
          mobileOrder: 1,
        },
      ],
      from: this.$moment().utc().toISOString().substr(0, 10),
      to: this.$moment().utc().add(1, 'days').toISOString().substr(0, 10),
      pagination: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: null,
      },
      listingsIds: [],
      totalHours: 0,
      totalAmount: 0,
      paid: null,
      paidItems: [
        { text: 'Paid', value: 'true' },
        { text: 'Not paid', value: 'false' },
      ],
    }
  },
  mounted() {
    this.$store.commit('updateListingTasks', [])
    this.getListingTasks()
  },
  methods: {
    paidChange() {
      this.getListingTasks()
    },
    paginationChanged(pagination) {
      const { page, itemsPerPage } = this.pagination
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.getListingTasks()
      }
    },
    filterChanged() {
      this.pagination.page = 1
      this.getListingTasks()
    },
    async getListingTasks() {
      const payload = this.prepareFiltersPayload(this.filters, {
        excludedFilters: this.excludedFilters,
        forcedFilters: this.forcedFilters,
      })
      const data = await this.$store.dispatch('getListingTasks', {
        filters: {
          ...payload,
          from: this.from,
          to: this.to,
          is_paid: this.paid,
          page: this.pagination.page,
          per_page: this.pagination.itemsPerPage,
          include_material_expenses: true,
        },
      })
      this.totalHours = data.total_hours
      this.totalAmount = data.total_amount
      if (this.pagination.page === 1) {
        this.pagination.itemsLength = parseInt(data.pagi_info.count)
      }
    },
    listingChange(listingsIds) {
      this.listingsIds = listingsIds
      this.getListingTasks()
    },
    saveTo() {
      if (this.from && this.to) {
        this.getListingTasks()
      }
    },
    saveFrom() {
      if (this.from && this.to) {
        this.getListingTasks()
      }
    },
    updateTask(payload) {
      return this.$store.dispatch('updateListingTask', {
        ...payload,
      })
    },
    priorityChanged(id, value) {
      this.updateTask({ priority: value, id })
    },
    onRowClicked({ id }) {
      this.addToQuery({ reportTaskDrawer: id })
    },
    expensesSum(expenses) {
      const sum = sumBy(expenses, 'amount')
      return sum ? this.dollarFormatter(sum) : '-'
    },
  },
  computed: {
    ...mapGetters('taskCalendar', ['filters']),
    ...mapState('taskCalendar', ['excludedFilters', 'forcedFilters']),
    ...mapState(['tasksCalendarListingResources']),
    filteredRequests: {
      get: function () {
        const listingResource = keyBy('id', this.tasksCalendarListingResources)
        let tasks = this.$store.state.listingTasks
          .filter(task => this.passFiltersSc(task))
          .map(t => ({
            ...t,
            listingNickname:
              listingResource[t.listing_id] &&
              listingResource[t.listing_id].title,
          }))
        return orderBy(tasks, 'scheduled_at', 'asc')
      },
    },
    orderedHeaders() {
      return this.isMobile
        ? orderBy(
            this.headers.filter(h => !!h.mobileOrder),
            'mobileOrder'
          )
        : this.headers
    },
  },
}
</script>
<style scoped>
:deep() .v-data-table__mobile-row {
  flex-wrap: wrap;
  padding-bottom: 10px !important;
}
:deep() .v-data-table__mobile-row__header {
  min-height: 40px;
  display: flex;
  align-items: center;
}

:deep() .v-data-table__mobile-table-row {
  margin-top: 15px;
}
:deep() .v-data-table > .v-data-footer {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
