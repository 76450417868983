<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="22"
    viewBox="0 0 28 22"
  >
    <g transform="translate(-1707 -229)">
      <g transform="translate(1703 222.888)">
        <path class="a" d="M4.5,18h27" transform="translate(0 -0.888)" />
        <path class="b" d="M4.5,9h27" />
        <path class="a" d="M4.5,27h27" transform="translate(0 -1.775)" />
      </g>
      <g class="c" transform="translate(1710 229)">
        <circle class="d" cx="3" cy="3" r="3" />
        <circle class="e" cx="3" cy="3" r="2.5" />
      </g>
      <g class="c" transform="translate(1718 237)">
        <circle class="d" cx="3" cy="3" r="3" />
        <circle class="e" cx="3" cy="3" r="2.5" />
      </g>
      <g class="c" transform="translate(1726 245)">
        <circle class="d" cx="3" cy="3" r="3" />
        <circle class="e" cx="3" cy="3" r="2.5" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Filters',
}
</script>

<style scoped>
/*.a,*/
/*.e {*/
/*  fill: none;*/
/*}*/
/*.a,*/
/*.b,*/
/*.c {*/
/*  stroke: #42526e;*/
/*}*/
/*.a,*/
/*.b {*/
/*  stroke-linecap: round;*/
/*  stroke-linejoin: round;*/
/*}*/
/*.b {*/
/*  fill: #f7f7f8;*/
/*}*/
/*.c {*/
/*  fill: #dfe1e6;*/
/*}*/
/*.d {*/
/*  stroke: none;*/
/*}*/
svg {
  fill: currentColor;
  stroke: currentColor;
}
</style>
