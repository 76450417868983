<template>
  <div>
    <v-card-title class="text-caption secondary--text">
      {{ title }}
    </v-card-title>
    <v-divider class="mb-6" />
    <canvas :id="graphId"></canvas>
  </div>
</template>

<script>
import isEmpty from 'lodash/fp/isEmpty'
import isEqual from 'lodash/fp/isEqual'

export default {
  props: ['names', 'reveune', 'title', 'graphId', 'color'],
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    reveune: function (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.draw()
      }
    },
  },
  mounted: function () {
    this.draw()
  },
  methods: {
    resetGraph: function () {
      if (this.chart) {
        this.chart.destroy()
      }
      const element = document.getElementById(this.graphId)
      let parent = element.parentNode
      parent.removeChild(element)
      const newElement = document.createElement('canvas')
      newElement.setAttribute('id', this.graphId)
      newElement.setAttribute('class', 'align-center')
      parent.appendChild(newElement)
    },
    draw: function () {
      this.resetGraph()
      const canvas = document.getElementById(this.graphId)
      const ctx = canvas.getContext('2d')
      if (isEmpty(this.reveune)) {
        ctx.font = '15px Arial'
        ctx.textAlign = 'center'
        ctx.fillStyle = 'lightgrey'
        ctx.fillText(
          this.title + ' No Data',
          canvas.width / 2,
          canvas.height / 2
        )
      } else {
        const chart = window.Chart
        this.chart = new chart(ctx, {
          type: 'bar',
          data: {
            labels: this.names,
            datasets: [
              {
                label: this.title,
                backgroundColor: this.color,
                borderColor: this.color,
                data: this.reveune,
              },
            ],
          },
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    drawTicks: false,
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          },
        })
      }
    },
  },
}
</script>
