<template>
  <form-dialog :title="$t('Add User')" :max-width="400" @submit="submit">
    <template #activator="{ on }">
      <v-btn color="primary" class="text-capitalize" elevation="0" v-on="on">
        {{ $t('Add user') }}
      </v-btn>
    </template>
    <v-select
      v-model="newUser.role"
      outlined
      dense
      :rules="[required]"
      :items="rolesItems"
      :label="$t('User Role')"
    />
    <v-text-field
      v-model="newUser.email"
      outlined
      dense
      :rules="emailRules"
      :label="$t('Email')"
      required
    />
    <v-text-field
      v-model="newUser.password"
      :label="$t('Password')"
      :rules="passwordRules"
      outlined
      dense
    />
    <v-text-field
      v-model="newUser.phone"
      outlined
      dense
      :label="$t('Phone number')"
      @change="validatePhone"
    >
      <template #append>
        <v-progress-circular
          v-show="validating"
          size="15"
          width="1"
          indeterminate
        />
      </template>
    </v-text-field>
    <v-text-field
      v-model="newUser.first_name"
      :label="$t('First name')"
      dense
      outlined
      :rules="[required]"
    />
    <v-text-field
      v-model="newUser.last_name"
      outlined
      dense
      :label="$t('Last name')"
    />
    <v-text-field
      v-model="newUser.company_name"
      outlined
      dense
      :label="$t('Company name')"
    />
    <div v-if="isDesignedVr && newUser.role !== 'investor'">
      <v-select
        v-model="newUser.department"
        :items="divisions"
        :rules="[required]"
        :label="$t('Division')"
        dense
        outlined
      />
      <v-select
        v-model="newUser.sub_role"
        :items="subRoles[newUser.department] || defaultSubRoles"
        :label="$t('User Sub Role')"
        :rules="[required]"
        dense
        outlined
      />
    </div>
    <div v-if="isDesignedVr">
      <div class="text-body-2">{{ $t('Void check images') }}</div>
      <gallery
        :images="newUser.void_check_image"
        :cols="6"
        thumbnails
        folder="users"
        uploadable
        readonly
        :on-change="addVoidCheckImages"
      />
      <div class="text-body-2">{{ $t('W9 images') }}</div>
      <gallery
        :images="newUser.w9_image"
        :cols="6"
        thumbnails
        folder="users"
        uploadable
        readonly
        :on-change="addW9Images"
      />
      <div class="text-body-2">{{ $t('Insurance proof images') }}</div>
      <gallery
        :images="newUser.insurance_proof_image"
        :cols="6"
        thumbnails
        folder="users"
        uploadable
        readonly
        :on-change="addInsuranceProofImages"
      />
      <div class="text-body-2">{{ $t('Service agreement') }}</div>
      <gallery
        folder="users"
        :images="newUser.service_agreement"
        :cols="6"
        thumbnails
        uploadable
        readonly
        :on-change="addServiceAgreement"
      />
    </div>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import RolesMixin from 'components/mixins/roles-mixin'
import Gallery from 'components/common/gallery'

export default {
  name: 'UserCreateModal',
  components: { FormDialog, Gallery },
  mixins: [FormRules, CommonFunctions, RolesMixin, PermissionsMixin],
  data() {
    return {
      newUser: {
        void_check_image: [],
        w9_image: [],
        insurance_proof_image: [],
      },
      invalidPhone: true,
      validating: false,
    }
  },
  computed: {
    rolesItems() {
      if (this.hasAbility('user-manage')) {
        return [...this.roles, { text: 'Admin', value: 'admin' }]
      }
      return this.roles
    },
  },
  methods: {
    async validatePhone(value) {
      if (!value) {
        this.invalidPhone = true
        return
      }
      this.validating = true
      const is_valid = await this.$store.dispatch('users/validatePhone', value)
      if (is_valid) {
        this.invalidPhone = true
      } else {
        this.invalidPhone = 'Invalid phone format'
      }
      this.validating = false
    },
    addVoidCheckImages(images) {
      this.newUser.void_check_image = images
    },
    addW9Images(images) {
      this.newUser.w9_image = images
    },
    addInsuranceProofImages(images) {
      this.newUser.insurance_proof_image = images
    },
    addServiceAgreement(images) {
      this.newUser.service_agreement = images
    },
    submit() {
      this.$store.dispatch('users/createUser', this.newUser)
    },
  },
}
</script>

<style scoped></style>
