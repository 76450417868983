<template>
  <div class="text--darken-4 font-weight-bold" style="white-space: nowrap">
    <v-btn
      v-bind="btnDefaults"
      :class="['red', score === 'bad' ? selected : `${notSelected} red--text`]"
      @click.stop="setPerformance('bad')"
    >
      Bad
    </v-btn>
    <v-btn
      v-bind="btnDefaults"
      :class="[
        'amber',
        score === 'okay' ? selected : `${notSelected} amber--text`,
      ]"
      @click.stop="setPerformance('okay')"
    >
      Okay
    </v-btn>
    <v-btn
      v-bind="btnDefaults"
      :class="[
        'green',
        score === 'great' ? selected : `${notSelected} green--text`,
      ]"
      @click.stop="setPerformance('great')"
    >
      Great
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: ['contractorId', 'taskId', 'performanceScore'],
  data() {
    return {
      score: this.performanceScore,
      notSelected: 'ml-1 text--darken-4 font-weight-bold lighten-4 opacity-50',
      selected: ' ml-1 font-weight-bold darken-1 white--text',
      btnDefaults: { outlined: true, depressed: true, small: true },
    }
  },
  methods: {
    ...mapActions('listingReport', ['updatePerformance']),
    async setPerformance(score) {
      const task = await this.updatePerformance({
        performance_score: score === this.score ? null : score,
        performance_user: this.contractorId,
        listing_id: this.$route.params.id,
        task_id: this.taskId,
      })
      this.score = task.performance_score
    },
  },
}
</script>

<style></style>
