<template>
  <v-layout xs12 wrap justify-center>
    <v-flex xs8 mt-3 mb-3 class="comp-wrapper">
      <v-text-field
        v-model="searchTerm"
        class="mt-3 mb-3"
        flat
        label="Search..."
        append-icon="search"
        solo-inverted
        hide-details
        style="background-color: #7ebac0a1; border: 1px grey solid"
      ></v-text-field>
      <br />
      <property-picker
        v-if="
          (isAdmin ||
            isInvestor ||
            isPropertyManager ||
            isCommunicationManager) &&
          !loading
        "
        :not-top-header="true"
        :after-change="listingChanged"
      ></property-picker>
    </v-flex>
    <v-flex
      v-if="$store.state.listingInfoItems"
      xs12
      mt-2
      mb-1
      class="comp-wrapper"
    >
      <div>
        <info-items-panel
          v-if="!loading"
          :ext-search-term="searchTerm"
          :listing-id="selectedListingID"
        ></info-items-panel>
      </div>
    </v-flex>
  </v-layout>
</template>
<script>
import PropertyPicker from './property-picker.vue'
import InfoItemsPanel from './info-items-panel.vue'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import debounce from 'lodash/debounce'

export default {
  components: {
    'property-picker': PropertyPicker,
    InfoItemsPanel,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      selectedListingID: null,
      searchTerm: null,
    }
  },
  computed: {},
  watch: {
    searchTerm: {
      handler: function () {
        this.debouncer.call(this)
      },
      deep: true,
    },
  },
  created() {
    this.debouncer = debounce(this.getItems, 750)
  },

  methods: {
    getItems() {
      this.$store.dispatch('getListingInfoItems', {
        listingId: this.selectedListingID || 0,
        searchTerm: this.searchTerm,
      })
    },
    listingChanged(id) {
      this.selectedListingID = id
      this.$store.dispatch('getListingInfoItems', {
        listingId: id,
        searchTerm: this.searchTerm,
      })
    },
  },
}
</script>
