<template>
  <v-autocomplete
    v-model="selectedItem"
    :items="items"
    :rules="rules"
    :label="$t(label)"
    :search-input.sync="search"
    :filter="customFilter"
    :clearable="!readonly"
    :messages="messages"
    color="info"
    item-value="id"
    return-object
    dense
    outlined
    :readonly="readonly"
    @change="onChange"
  >
    <template #append-outer>
      <v-icon v-if="outerIcon" color="orange" @click="outerClick"
        >{{ outerIcon }}
      </v-icon>
    </template>
    <template #selection="{ item }">
      <div>
        <span v-if="item.division" class="text-overline">
          {{ item.division }}
        </span>
        <v-icon
          v-show="item.division && item.department"
          class="mx-1"
          color="info"
          small
        >
          fas fa-caret-right
        </v-icon>
        <span class="text-overline">{{ item.department }}</span>
        <v-icon v-show="item.sub_topic" class="mx-1" color="info" small
          >fas fa-caret-right
        </v-icon>
        <span class="text-overline">{{ item.sub_topic }}</span>
        <v-icon v-if="item.description" class="mx-1" color="info" small
          >fas fa-caret-right
        </v-icon>
        <span v-if="item.description && isDesignedVr" class="text-overline">{{
          splitDescription(item.description)
        }}</span>
      </div>
    </template>
    <template #item="{ item }" class="listing-picker">
      <v-sheet width="100%" class="py-3">
        <div>
          <span v-if="item.division" class="text-overline">{{
            item.division
          }}</span>
          <v-icon
            v-show="item.division && item.department"
            class="mx-1"
            color="info"
            small
          >
            fas fa-caret-right
          </v-icon>
          <span class="text-overline">{{ item.department }}</span>
          <v-icon v-show="item.sub_topic" color="info" class="mx-1" small
            >fas fa-caret-right
          </v-icon>
          <span class="text-overline">{{ item.sub_topic }}</span>
          <v-sheet
            v-if="isDesignedVr"
            max-width="350"
            class="text-body-1 black--text text-capitalize"
            >{{ item.description }}
          </v-sheet>
        </div>
      </v-sheet>
    </template>
  </v-autocomplete>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import noop from 'lodash/fp/noop'

export default {
  name: 'CatalogQuickSelect',
  mixins: [CommonFunctions, PermissionsMixin],
  props: {
    filterChange: {
      type: Function,
      default: noop,
    },
    outerClick: {
      type: Function,
      default: noop,
    },
    outerIcon: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    simpleMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
    },
    division: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
    rejectMode: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'By Catalog',
    },
    messages: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedItem: this.value,
      search: null,
    }
  },
  computed: {
    items() {
      const items = this.$store.state.tasksCatalog.catalogItemSelect
      if (this.rejectMode && this.isDesignedVr) {
        return items.filter(
          item => item.system && item.sub_topic === 'quick-task'
        )
      }
      if (!this.division) return items
      return items.filter(
        item => item.division === this.division || item.public
      )
    },
  },
  watch: {
    value(val) {
      this.selectedItem = val
    },
  },
  mounted() {
    if (!this.items || this.items.length === 0) {
      this.$store.dispatch('getListingTaskCatalogItemsSelect')
    }
  },
  methods: {
    customFilter(item, queryText) {
      return (
        (item.description &&
          item.description.toLowerCase().includes(queryText.toLowerCase())) ||
        (item.department &&
          item.department.toLowerCase().includes(queryText.toLowerCase())) ||
        (item.sub_topic &&
          item.sub_topic.toLowerCase().includes(queryText.toLowerCase()))
      )
    },
    splitDescription(item) {
      const words = item.split(' ')
      return words[0]
    },
    onChange(val) {
      const id = val ? val.id : null
      this.$emit('input', id)
      this.filterChange(this.simpleMode ? id : val)
    },
  },
}
</script>
