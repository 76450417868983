<template>
  <v-navigation-drawer
    v-if="!isMobile"
    :key="isRtl ? '1' : '0'"
    :mini-variant="isMobileOrIPad"
    permanent
    fixed
    :right="isRtl"
    style="padding-top: 64px"
    width="250"
  >
    <v-list flat>
      <v-list-item-group mandatory color="black" class="font-weight-normal">
        <template v-for="(item, i) in filteredItems">
          <v-list-group
            v-if="item.children"
            :key="i"
            :value="childrenChosen(item)"
            :prepend-icon="item.icon"
            color="black"
            @click="groupClick(item.children)"
          >
            <template #activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="subItem in item.children"
              :key="subItem.value"
              :class="[
                subItem.value === paramsTab ? 'menu-active' : '',
                {
                  'menu-active-rtl': isRtl && subItem.value === paramsTab,
                  'menu-active-ltr': !isRtl && subItem.value === paramsTab,
                },
              ]"
              @click="groupClick(subItem.value)"
            >
              <v-list-item-icon class="mr-2 pl-md-3">
                <v-icon>{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.value"
            :disabled="item.disabled"
            :class="[
              item.value === paramsTab ? 'menu-active' : '',
              {
                'menu-active-rtl': isRtl && item.value === paramsTab,
                'menu-active-ltr': !isRtl && item.value === paramsTab,
              },
            ]"
            @click="groupClick(item.value)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
  <v-bottom-navigation v-else fixed>
    <v-tabs v-model="paramsTab" height="56" show-arrows icons-and-text centered>
      <v-tab
        v-for="item in flatItems"
        :key="item.value"
        :href="`#${item.value}`"
      >
        <span class="text-tiny py-1 mb-0">{{ item.title }}</span>
        <v-icon>{{ item.icon }}</v-icon>
      </v-tab>
    </v-tabs>
  </v-bottom-navigation>
</template>

<script>
import DeviceMixin from 'components/mixins/device-mixin'
export default {
  name: 'SideMenu',
  mixins: [DeviceMixin],
  props: ['items'],
  computed: {
    flatItems() {
      return this.filteredItems.flatMap(item => item.children || item)
    },
    filteredItems() {
      return this.items.reduce((acc, item) => {
        if (item.children && item.show) {
          const filteredChildren = item.children.filter(child => child.show)
          if (filteredChildren.length) {
            acc.push({
              ...item,
              children: filteredChildren,
            })
          }
        } else if (item.show) {
          acc.push(item)
        }
        return acc
      }, [])
    },
  },
  methods: {
    childrenChosen(item) {
      return item.children.map(item => item.value).includes(this.paramsTab)
    },
    groupClick(value) {
      if (Array.isArray(value)) {
        if (!value.map(item => item.value).includes(this.paramsTab)) {
          this.paramsTab = value[0].value
        }
      } else {
        this.paramsTab = value
      }
    },
  },
}
</script>

<style scoped>
.menu-active-ltr {
  border-left: 4px solid var(--v-primary-base) !important;
}
.menu-active-rtl {
  border-right: 4px solid var(--v-primary-base) !important;
}
.menu-active {
  background-color: var(--v-secondary-lighten3) !important;
  font-weight: 500;
  color: black;
}
.text-tiny {
  font-size: 0.65rem;
}
</style>
