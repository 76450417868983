<template>
  <div>
    <v-text-field
      label="Time"
      outlined
      dense
      hide-details
      readonly
      :value="currentDate"
      @click="openDate"
    />
    <datetime
      ref="dvrDateTime"
      v-model="date"
      :minute-step="15"
      input-class="dvr-datetime d-none"
      format="HH:mm"
      zone="UTC"
      type="time"
      @input="changeDate"
    />
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
export default {
  name: 'DvrTimePicker',
  mixins: [CommonFunctions],
  props: {
    borderRadius: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
    format: {
      type: String,
      default: 'HH:mm',
    },
    allowedHours: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      date: this.value && this.value !== '00:00' ? this.value + 'Z' : null,
      menu: false,
      isMounted: false,
    }
  },
  computed: {
    currentDate() {
      if (this.date) {
        return this.$moment(this.date).utc().format('HH:mm')
      }
      return null
    },
  },
  mounted() {
    this.isMounted = true
    if (this.value && this.value !== '00:00') {
      this.$emit('update:value', this.value)
    } else {
      this.$emit('update:value', null)
    }
  },
  methods: {
    changeDate(val) {
      if (this.isMounted) {
        const hour = this.$moment(val).utc().format('HH:mm')
        this.$emit('update:value', hour)
        this.$emit('change', hour)
      }
    },
    openDate() {
      this.$refs.dvrDateTime.$el
        .getElementsByClassName('dvr-datetime')[0]
        .click()
    },
    allowedStep: m => m % 30 === 0,
  },
}
</script>

<style scoped>
.dvr-datetime >>> input {
  padding: 8px 10px;
  font-size: 16px;
  border: solid 1px rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  color: #444;
}
</style>
