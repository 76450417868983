<template>
  <v-container>
    <v-card class="overflow-y-auto">
      <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
        <div class="text-capitalize">{{ integrationName }}</div>
      </v-card-title>
      <v-card-text>
        <div class="text-body-1">
          Sync your bank accounts data with Boom dashboard
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="primary">Connect</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Quickbooks',
  computed: {
    integrationName() {
      return this.$route.params.name
    },
  },
}
</script>

<style scoped></style>
