import axios from 'axios'

export default {
  namespaced: true,
  state: {
    groups: [],
  },
  mutations: {
    updateUserGroups(state, data) {
      state.groups = data
    },
  },
  actions: {
    async getUserGroups({ commit }) {
      const { data } = await axios.get(`/api/groups`)
      commit('updateUserGroups', data)
    },
  },
}
