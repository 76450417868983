var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.stats && (_vm.isAdmin || _vm.isPropertyManager))?_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('contractor-payments-date-range',{attrs:{"contractor-id":_vm.chosenContractorId},on:{"change":function($event){return _vm.getStats(1)}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('contractor-select',{attrs:{"filter-change":_vm.contractorSelected,"label":"Choose contractor","value":_vm.chosenContractorId,"hide-details":true}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","items":_vm.divisions,"clearable":"","label":"Division"},on:{"change":_vm.divisionSelected}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"items":['Staff', '3rd party'],"multiple":"","outlined":"","dense":"","hide-details":"","label":"Employee Type"},model:{value:(_vm.payrollFilter),callback:function ($$v) {_vm.payrollFilter=$$v},expression:"payrollFilter"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"items":[
            { text: 'Unpaid', value: 'unpaid' },
            { text: 'Paid', value: 'paid' },
            { text: 'Processing', value: 'processing' },
            { text: 'Manual', value: 'manual' },
          ],"outlined":"","dense":"","hide-details":"","label":"Sort By"},on:{"change":function($event){return _vm.getStats(1)}},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[(_vm.payableUsersWithMissingBankInfo.length)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"mx-5",attrs:{"color":"primary","icon":""},on:{"click":_vm.openModalOfContractorsWithMissingBankInfo}},[_c('v-icon',[_vm._v("mdi-bank-minus")])],1)],1)]}}],null,false,1415773610)},[_c('span',[_vm._v("Contractors With Missing Bank Info")])]):_vm._e()],1)],1)],1),_c('v-data-table',{attrs:{"options":_vm.pagination,"server-items-length":_vm.pagination.itemsLength,"headers":_vm.headers,"loading":_vm.fetchingData,"items":_vm.stats,"footer-props":{
      'items-per-page-options': [5, 10, 15, 20],
    }},on:{"pagination":function($event){return _vm.paginationChanged($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"text-end"},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.getStats(1, true)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-table")]),_vm._v(" Export ")],1)],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('contractor-lt-stats',{attrs:{"stats":item},on:{"show-details":_vm.showDetails}})]}}],null,false,218067849)}),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.isDetailsModal),callback:function ($$v) {_vm.isDetailsModal=$$v},expression:"isDetailsModal"}},[_c('contractor-listing-task-details',{on:{"marked-as-processing":function($event){return _vm.getStats()}}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }