<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.54 21.49">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="Icon_feather-wifi" data-name="Icon feather-wifi">
          <path
            id="Path_5162"
            data-name="Path 5162"
            class="cls-1"
            d="M5,10.81a11.91,11.91,0,0,1,16.66,0"
          />
          <path
            id="Path_5163"
            data-name="Path 5163"
            class="cls-1"
            d="M.75,6.08A17.36,17.36,0,0,1,25.31,5.6c.17.16.32.32.48.48"
          />
          <path
            id="Path_5164"
            data-name="Path 5164"
            class="cls-1"
            d="M9.17,15.56a6.46,6.46,0,0,1,8.22,0"
          />
          <path
            id="Path_5165"
            data-name="Path 5165"
            class="cls-1"
            d="M13.27,20.74h0"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WifiAlt',
}
</script>

<style scoped>
.cls-1 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.5px;
}
</style>
