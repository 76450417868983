<template>
  <v-dialog v-model="tags" persistent max-width="750px">
    <v-btn fab dark small color="teal" class="float-right" @click="close">
      <v-icon dark>close</v-icon>
    </v-btn>
    <v-flex xs12 class="center-text purpleish white--text pt-3 pb-2">
      <h3>Conversation Topics</h3>
    </v-flex>
    <v-flex xs12>
      <v-card v-if="addMode" class="pa-4">
        <v-btn outlined color="indigo" @click="addMode = false">Cancel</v-btn>
        <v-layout column>
          <v-text-field
            v-model="newTag.title"
            label="Title"
            required
          ></v-text-field>
          <v-text-field
            v-model="newTag.description"
            label="Description when to use"
          ></v-text-field>
          <v-select
            v-model="newTag.department"
            :items="['Sales', 'Customer-Support']"
            label="Department"
            required
          ></v-select>
          <v-btn
            mt-2
            :disabled="
              !newTag.title || !newTag.description || !newTag.department
            "
            class="cyanish white--text"
            text
            @click.native="save"
            >Save
          </v-btn>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex v-if="!addMode" xs12>
      <v-card class="pa-4">
        <v-layout wrap align-center>
          <v-flex
            xs9
            mb-2
            pl-2
            pr-2
            class="center-text full-width"
            justify-center
          >
            <v-text-field
              v-model="searchTerm"
              class="mt-3 mb-3"
              flat
              label="Search by title..."
              hide-details
              autofocus
              append-icon="search"
              solo-inverted
              style="background-color: #7ebac0a1; border: 1px grey solid"
            ></v-text-field>
          </v-flex>
          <v-flex xs3 pr-2 justify-center class="center-text">
            <v-btn fab small class="cyan-icon" @click="addMode = true">
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout column justify-center align-center>
          <v-flex
            v-for="(tag, index) in filteredItems"
            :key="index"
            class="comp-wrapper full-width"
          >
            <v-layout wrap align-center class="font-light center-text">
              <v-flex xs4 class="br-1">
                <v-chip
                  class="ma-2 rounded-card bolder"
                  :style="'background-color: ' + stringToColour(tag.title)"
                  label
                  text-color="white"
                >
                  <v-icon left>mdi-label</v-icon>
                  {{ tag.title }}
                </v-chip>
              </v-flex>
              <v-flex xs6 class="br-1"
                ><span class="cyan-icon mr-2">{{ tag.department }}</span
                >{{ tag.description }}
              </v-flex>
              <v-flex xs2 class="br-1">
                <v-btn
                  small
                  class="purpleish white--text"
                  @click="moveToEditMode(tag)"
                >
                  <v-icon>create</v-icon>
                </v-btn>
                <v-btn small class="greenish white--text" @click="addTag(tag)">
                  Use
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-dialog>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import ColorsMixin from 'components/mixins/colors-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { isEmpty } from 'lodash'

export default {
  components: {},
  mixins: [CommonFunctions, ColorsMixin, PermissionsMixin],
  props: ['afterMessageChosen', 'module'],
  data: () => ({
    chosenTag: null,
    searchSub: null,
    addMode: false,
    searchTerm: '',
    newTag: {},
  }),
  computed: {
    filteredItems() {
      let res = []
      if (this.conversationTagsObjects) {
        this.conversationTagsObjects.forEach(item => {
          const title = item.title || ''
          const desc = item.description || ''
          if (
            title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            desc.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            res.push(item)
          }
        })
      }
      return res
    },
    conversationTagsObjects() {
      return this.$store.state.conversationTagsObjects
    },
    tags() {
      return this.$store.state.conversationTags
    },
    conversation() {
      return this.$store.state.chosenChatConversation
    },
    showTagPop: {
      get() {
        return this.$store.state.showTagPop
      },
      set(val) {
        this.$store.commit('updateShowTagPop', val)
      },
    },
    preMadeMessages() {
      return this.$store.state.preMadeMessages
    },
  },
  mounted() {
    if (isEmpty(this.$store.state.conversationTags)) {
      this.$store.dispatch('getChatConversationTags')
    }
  },
  methods: {
    moveToEditMode(item) {
      this.addMode = true
      this.newTag = item
    },
    save() {
      this.$store.dispatch('createConverastionTag', this.newTag)
      this.newTag = {}
      this.addMode = false
    },
    addTag(tag) {
      this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        new_tag: tag.title,
      })
      this.$store.commit('updateShowTagPop', null)
    },
    close() {
      this.$store.commit('updateShowTagPop', null)
    },
  },
}
</script>
