<template>
  <tr class="bolder headline-font">
    <td class="text-center max-w-200">
      {{ item.nickname }}
    </td>
    <td
      v-for="(timeKey, index) in timeOptions"
      :key="index"
      class="text-center max-w-200"
    >
      <v-row
        :class="{ 'super-light-yellow': timeKey === 'at_date' }"
        class="justify-center fill-height align-center"
        @click="showModal(item[timeKey].listing_tasks)"
      >
        <v-col
          class="pa-0"
          cols="12"
          :class="availColor(item[timeKey].availability_text)"
          >{{ item[timeKey].availability_text }}
        </v-col>
        <div
          v-if="item[timeKey].early_check_in"
          class="round-tag pa-1 bolder purple-bg white--text small-text"
        >
          Early checkin
        </div>
        <div
          v-if="item[timeKey].late_checkout"
          class="round-tag pa-1 bolder cyanish white--text small-text"
        >
          Late checkout
        </div>
        <v-col class="pa-0" cols="12">
          <v-chip
            v-if="item[timeKey].listing_tasks.length > 0"
            class="ma-2"
            color="secondary"
          >
            {{ item[timeKey].listing_tasks.length }}
          </v-chip>
          <v-chip
            v-if="filterInConflict(item[timeKey].listing_tasks).length > 0"
            class="ma-2 white--text bolder"
            color="red"
          >
            {{ filterInConflict(item[timeKey].listing_tasks).length }}
          </v-chip>
        </v-col>
      </v-row>
    </td>
  </tr>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['item'],
  data: function () {
    return {
      timeOptions: ['before_2', 'before_1', 'at_date', 'after_1', 'after_2'],
    }
  },
  methods: {
    filterInConflict(tasks) {
      let res = []
      tasks.forEach(task => {
        if (task.extra_data.in_conflict) {
          res.push(task)
        }
      })
      return res
    },
    showModal(tasks) {
      this.$store.commit('updateLtDailyPopTasks', tasks)
      this.$store.commit('updateShowLtDailyPop', true)
    },
  },
}
</script>
