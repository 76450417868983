<template>
  <v-dialog v-model="open" eager width="900px">
    <template #activator="{ on }">
      <v-icon class="icon alt" color="primary" v-on="on"
        >mdi-map-marker
      </v-icon>
    </template>
    <v-card>
      <div>
        <div ref="googleMapInput" class="google-map fill-height" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import { GOOGLE_MAP_API_KEY } from '@/consts'

export default {
  props: ['mapOptions', 'markersData', 'showDirections', 'position'],
  data() {
    return {
      open: false,
      google: null,
      map: null,
      info: null,
      marker: null,
      styles: [
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    }
  },
  async mounted() {
    const googleMapApi = await new Loader({
      apiKey: GOOGLE_MAP_API_KEY,
    }).load()

    this.google = googleMapApi
    this.initializeMap()
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMapInput
      this.info = new this.google.maps.InfoWindow()

      const allOptions = {
        ...this.mapOptions,
        draggableCursor: 'pointer',
        styles: this.styles,
      }
      this.map = new this.google.maps.Map(mapContainer, allOptions)

      this.marker = new this.google.maps.Marker({
        position: this.position,
        map: this.map,
      })
      this.panToMarker()
      this.map.addListener('click', event => {
        const { lat, lng } = event.latLng
        const latlng = { lat: parseFloat(lat()), lng: parseFloat(lng()) }
        const geocoder = new this.google.maps.Geocoder()
        geocoder.geocode({ location: latlng }, results => {
          this.marker.setPosition(event.latLng)
          this.$emit('marker-moved', {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            place: results[0],
          })
        })
        setTimeout(() => {
          this.open = false
        }, 1000)
      })
    },
    panToMarker() {
      this.map.panTo(this.marker.position)
      this.map.setZoom(18)
    },
  },
}
</script>
<style>
.multi-marker {
  margin: 0 5px;
  width: 25px;
  height: 25px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
