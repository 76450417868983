<template>
  <v-flex xs12 :class="!pricing.activated ? 'red10-20' : ''">
    <v-card class="grey--text">
      <v-layout wrap>
        <v-flex xs2>
          <v-flex>
            <img
              :src="pricing.image"
              height="70px"
              class="float-left pl-2"
              contain
            />
          </v-flex>
        </v-flex>
        <v-flex xs4 pt-2>
          <v-layout column mt-2>
            <v-layout wrap>
              <v-icon class="icon alt stats-icon mr-2"
                >fas fa-map-marked-alt</v-icon
              >
              <div class="cyan-icon big-text">
                {{ truncateAddress(pricing) }}
              </div>
            </v-layout>
            <v-layout align-center mt-2>
              <v-icon
                small
                class="mr-1"
                :color="pricing.activated ? 'success' : 'secondary'"
              >
                {{
                  pricing.activated
                    ? 'fas fa-check-circle'
                    : 'fas fa-times-circle'
                }}
              </v-icon>
              <span
                class="pr-2 mr-3 br-1"
                :class="pricing.listed ? 'green--text' : 'red--text'"
              >
                {{ pricing.activated ? $t('Active') : $t('Disabled') }}
              </span>
              <v-icon
                small
                class="mr-1"
                :color="pricing.listed ? 'success' : 'secondary'"
              >
                {{
                  pricing.listed ? 'fas fa-check-circle' : 'fas fa-times-circle'
                }}
              </v-icon>
              <span
                class="pr-2 mr-3 br-1"
                :class="pricing.listed ? 'green--text' : 'red--text'"
              >
                {{ pricing.listed ? $t('Listed') : $t('Unlisted') }}
              </span>
              <tag
                v-if="pricing.strategy_name"
                :class="['d-inline-block ml-1 mr-3 pa-1']"
                bg-color="lightgrey"
                >{{ pricing.strategy_name }}
              </tag>
            </v-layout>
            <months-occupancy
              class="mt-2 ml-2"
              :month-occupancy="pricing.stats.month_occupancy"
            ></months-occupancy>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout column mt-2 pr-4>
            <v-layout align-baseline class="justify-end">
              <v-flex xs6>
                <v-row>
                  <v-col class="br-1">
                    14 days: <br />
                    {{
                      round(pricing.yearly_stats['14_days_back'] * 100)
                    }}%</v-col
                  >
                  <v-col class="br-1"
                    >30 days: <br />
                    {{
                      round(pricing.yearly_stats['30_days_back'] * 100)
                    }}%</v-col
                  >
                  <v-col class="br-1">
                    90 days: <br />
                    {{
                      round(pricing.yearly_stats['90_days_back'] * 100)
                    }}%</v-col
                  >
                </v-row>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider light></v-divider>
      <v-card-actions class="pa-3">
        <v-layout wrap class="sm8">
          <v-flex xs3>
            <v-text-field
              v-model="pricing.base_price"
              prepend-icon="money"
              label="Base price"
              required
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              v-model="pricing.min_price_weekend"
              prepend-icon="vertical_align_bottom"
              label="Min price weekends"
              required
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              v-model="pricing.min_price_weekday"
              prepend-icon="vertical_align_bottom"
              label="Min price weekdays"
              required
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              v-model="pricing.max_price"
              prepend-icon="money"
              label="Max price"
              required
              disabled
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          target="_blank"
          style="max-width: 85px"
          class="cyan-icon"
          :href="`/dashboard/pricing/${pricing.id}`"
          text
          >View</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import MonthsOccupancy from './months-occupancy.vue'
import Tag from 'components/common/tag.vue'
import round from 'lodash/round'
export default {
  components: {
    Tag,
    MonthsOccupancy,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['pricing'],
  data: function () {
    return {}
  },
  computed: {},
  methods: {
    round: function (input) {
      return round(input, 1)
    },
    addressFor(id) {
      return { path: `/dashboard/pricing/${id}` }
    },
    save() {
      this.$store.dispatch('savePricing', { pricing: this.pricing })
    },
  },
}
</script>
