<template>
  <v-container
    v-if="trackedListingTask"
    class="pa-0 issue-tracker-wrapper d-flex"
    fluid
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        lg="6"
        class="issue-tracker-bg d-none d-lg-block"
      ></v-col>
      <v-col cols="12" lg="6" class="pa-6 pa-sm-12">
        <h2 class="text-capitalize text-body-1 secondary--text mb-6">
          Issue tracker
        </h2>
        <v-card>
          <v-card-text>
            <v-chip
              :color="taskStatusColor(trackedListingTask.status)['bg']"
              class="text-uppercase mb-2"
              label
              small
            >
              <span
                :class="taskStatusColor(trackedListingTask.status)['textColor']"
                >{{ trackedListingTask.status }}</span
              >
            </v-chip>
            <p class="text-h6 mb-2">
              Listing: {{ trackedListingTask.extra_data.listing_name }}
            </p>
            <p>{{ trackedListingTask.description }}</p>
            <v-timeline dense>
              <v-timeline-item
                color="green lighten-5"
                fill-dot
                icon="done"
                icon-color="green darken-1"
              >
                <p class="font-weight-medium mb-0">Issue was created</p>
                <p class="text-caption secondary--text mb-0">
                  {{
                    $moment(new Date(trackedListingTask.created_at)).format(
                      'YYYY-MM-DD HH:mm a'
                    )
                  }}
                </p>
              </v-timeline-item>

              <v-timeline-item
                :color="isAssigned ? 'green lighten-5' : 'grey lighten-3'"
                :icon="isAssigned ? 'done' : 'clear'"
                :icon-color="isAssigned ? 'green darken-1' : 'white'"
                fill-dot
              >
                <p v-if="isAssigned" class="font-weight-medium mb-0">
                  <span
                    v-if="
                      trackedListingTask.assigned_contractor.first_name ||
                      trackedListingTask.assigned_contractor.last_name
                    "
                  >
                    {{ trackedListingTask.assigned_contractor.first_name }}
                    {{ trackedListingTask.assigned_contractor.last_name }}
                  </span>
                  <span v-else>
                    {{ trackedListingTask.assigned_contractor.email }}
                  </span>
                  was assigned
                </p>
                <p v-else class="mb-0">Not assigned yet</p>
              </v-timeline-item>

              <v-timeline-item
                :color="
                  isAssigned && trackedListingTask.scheduled_at
                    ? 'green lighten-5'
                    : 'grey lighten-3'
                "
                :icon="
                  !trackedListingTask.scheduled_at || !isAssigned
                    ? 'clear'
                    : taskDone
                    ? 'done'
                    : 'alarm'
                "
                :icon-color="
                  isAssigned && trackedListingTask.scheduled_at
                    ? 'green darken-1'
                    : 'white'
                "
                fill-dot
              >
                <p class="font-weight-medium mb-0">
                  Scheduled arrival on
                  <span v-if="scheduledHour != 0">{{
                    $moment
                      .utc(new Date(trackedListingTask.scheduled_at))
                      .format('DD MMMM, HH:mm')
                  }}</span>
                  <span v-else>{{
                    $moment(new Date(trackedListingTask.scheduled_at)).format(
                      'YYYY-MM-DD'
                    )
                  }}</span>
                </p>
              </v-timeline-item>
              <v-timeline-item
                :color="taskStatusColor(trackedListingTask.status)['bg']"
                :icon="taskStatusIcon(trackedListingTask.status)['icon']"
                :icon-color="taskStatusIcon(trackedListingTask.status)['color']"
                fill-dot
              >
                <span>Status: {{ trackedListingTask.status }}</span>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
        <rate-lt
          v-if="taskDone"
          :tracked-listing-task="trackedListingTask"
        ></rate-lt>
        <div v-if="trackedListingTask.customer_rating" class="text-h6 mt-6">
          Thanks for your rating!
        </div>
        <div v-if="taskDone" class="secondary--text mt-6">
          <div class="font-weight-medium">Still have issues?</div>
          <div class="text-caption">
            Contact us via the channel or call us at:
            <a class="text-decoration-none" href="tel:+17862030688"
              >+1 786-203-0688</a
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import RateLt from './rate_lt'
import { mapState } from 'vuex'
import ListingTaskMethodsMixin from 'components/listing-tasks/mixins/listing-task-methods-mixin'
export default {
  components: {
    RateLt,
  },
  mixins: [CommonFunctions, PermissionsMixin, ListingTaskMethodsMixin],
  data() {
    return {}
  },
  created() {
    this.$store.dispatch(
      'getTrackedListingTask',
      this.$router.currentRoute.params.id
    )
  },
  methods: {
    taskStatusColor(status) {
      if (status === 'New') {
        return {
          bg: 'warning lighten-4',
          textColor: 'warning--text',
        }
      } else if (status === 'In Progress') {
        return {
          bg: 'orange lighten-4',
          textColor: 'orange--text',
        }
      } else {
        return {
          bg: 'green lighten-4',
          textColor: 'green--text',
        }
      }
    },
    taskStatusIcon(status) {
      if (status === 'New') {
        return {
          icon: 'mdi-new-box',
          color: 'warning',
        }
      } else if (status === 'In Progress') {
        return {
          icon: 'mdi-cog-outline',
          color: 'orange',
        }
      } else {
        return {
          icon: 'done',
          color: 'green',
        }
      }
    },
  },
  computed: {
    ...mapState(['trackedListingTask']),
    scheduledHour() {
      if (this.trackedListingTask) {
        return this.$moment.utc(this.trackedListingTask.scheduled_at).hours()
      }
      return null
    },
    taskDone() {
      return this.isDone(this.trackedListingTask.status)
    },
    isAssigned() {
      return (
        this.trackedListingTask &&
        this.trackedListingTask.assigned_contractor_id
      )
    },
  },
}
</script>
<style lang="scss">
.issue-tracker-wrapper {
  min-height: 100%;
}
.issue-tracker-bg {
  background-color: #fffaf4;
  background-image: url('../../assets/images/issue-tracker-bg.png');
  background-position: center bottom;
  background-size: cover;
}
</style>
