<template>
  <v-card class="overflow-y-auto">
    <v-form ref="form" @submit.prevent="save">
      <v-card-title> Listing Region</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="name"
          label="Name"
          :rules="[required]"
          outlined
          rows="4"
        />
        <v-text-field
          v-model="slackChannel"
          label="Slack channel"
          outlined
          rows="4"
        />
        <contractor-select
          icon="$account_alt"
          label="Area manager"
          :enable-sug="true"
          :value.sync="assignedManagerId"
        />
        <multi-property-picker v-model="listingIds" />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn small color="primary" outlined @click="closeModal">Cancel</v-btn>
        <v-btn small color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import { mapGetters } from 'vuex'
import MultiPropertyPicker from 'components/multi-property-picker'
import ContractorSelect from 'components/contractor-select.vue'

export default {
  name: 'ListingRegionModal',
  components: { ContractorSelect, MultiPropertyPicker },
  mixins: [formRules],
  props: ['region'],
  data() {
    return {
      name: null,
      listingIds: [],
      assignedManagerId: null,
      slackChannel: null,
    }
  },
  mounted() {
    this.name = this.region.name
    this.assignedManagerId = this.region.assigned_manager_id
    this.slackChannel = this.region.slack_channel
    this.listingIds = this.listingsPicker
      .filter(l => l.region_id === this.region.id)
      .map(l => l.id)
  },
  computed: {
    ...mapGetters(['listingsPicker']),
  },
  methods: {
    closeModal() {
      this.$store.commit('hideModal')
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('regions/updateRegion', {
          id: this.region.id,
          name: this.name,
          assigned_manager_id: this.assignedManagerId,
          slack_channel: this.slackChannel,
          listings: this.listingIds,
        })
        this.closeModal()
      }
    },
  },
}
</script>

<style scoped></style>
