import { render, staticRenderFns } from "./owner-approval-modal.vue?vue&type=template&id=6834db0a&scoped=true&"
import script from "./owner-approval-modal.vue?vue&type=script&lang=js&"
export * from "./owner-approval-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6834db0a",
  null
  
)

export default component.exports