import { render, staticRenderFns } from "./bank-transactions.vue?vue&type=template&id=34e038a4&"
import script from "./bank-transactions.vue?vue&type=script&lang=js&"
export * from "./bank-transactions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports