import { findKey, first, omit } from 'lodash'
import { TASK_STATUSES } from '@/consts'
export const initialFilters = {
  availabilities: [],
  contractor_id: [],
  listings_id: [],
  searchTerm: '',
  type: 'All',
  project_id: null,
  regions: [],
  categories: [],
  onlyItems: [],
  cities: [],
  priorities: [],
  status: Object.values(omit(TASK_STATUSES, 'CLOSED')),
  openedBy: [],
  listingCatalogItemId: null,
  division: null,
  include_ids: [],
}
export default {
  namespaced: true,
  state: {
    userDefaultHasBeenSet: false,
    selectedSavedFilter: null,
    filters: initialFilters,
    excludedFilters: [],
    forcedFilters: {},
    filterChanged: false,
    filterFetched: true,
    miniCalendarTitle: '',
    calendarRefresh: false,
  },
  mutations: {
    updateUserDefaultHasBeenSet(state, value) {
      state.userDefaultHasBeenSet = value
    },
    toggleCalendarRefresh(state) {
      state.calendarRefresh = !state.calendarRefresh
    },
    updateSelectedSavedFilter(state, value) {
      state.selectedSavedFilter = value
    },
    updateCalendarFilters(state, data) {
      state.filters = {
        ...omit(
          {
            ...state.filters,
            ...data,
          },
          state.excludedFilters
        ),
        ...state.forcedFilters,
      }
    },
    updateFilterChanged(state, value) {
      state.filterChanged = value
    },
    updateFilterFetched(state, value) {
      state.filterFetched = value
    },
    resetFilters(state, initialValues = {}) {
      state.userDefaultHasBeenSet = false
      state.selectedSavedFilter = null
      state.filters = {
        ...omit(
          {
            ...initialFilters,
            ...initialValues,
          },
          state.excludedFilters
        ),
        ...state.forcedFilters,
      }
    },
    updateMiniCalendarTitle(state, value) {
      state.miniCalendarTitle = value
    },
    updateExcludedFilters(state, value) {
      state.excludedFilters = value
      state.filters = {
        ...omit(state.filters, state.excludedFilters),
        ...state.forcedFilters,
      }
    },
    updateForcedFilters(state, value) {
      state.forcedFilters = value
      state.filters = {
        ...omit(state.filters, state.excludedFilters),
        ...state.forcedFilters,
      }
    },
  },
  getters: {
    filters(state) {
      return {
        ...omit(state.filters, state.excludedFilters),
        ...state.forcedFilters,
      }
    },
    savedUserCalendarFilters(state, getters, rootState) {
      return rootState.user && rootState.user.filters
    },
    defaultUserCalendarFilterName(state, getters, rootState) {
      const filters = rootState.user && rootState.user.filters
      return (
        findKey(filters, { isDefault: true }) || first(Object.keys(filters))
      )
    },
  },
  actions: {
    addToIncludeIdsFilter({ commit, getters }, id) {
      const include_ids = getters.filters.include_ids || []
      include_ids.push(id)
      commit('updateCalendarFilters', { include_ids })
    },
    resetIncludeIdsFilter({ commit }) {
      commit('updateCalendarFilters', { include_ids: [] })
    },
  },
}
