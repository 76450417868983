<template>
  <div class="inline-text-field-container">
    <v-tooltip :disabled="!isOverflowing()" bottom open-delay="500">
      <template #activator="{ on, attrs }">
        <div tabindex="-1" v-bind="attrs" style="outline: none" v-on="on">
          <div v-if="!currentValue && placeholder" class="placeholder">
            {{ placeholder }}
          </div>
          <v-hover v-model="isHovered" open-delay="200">
            <div
              ref="input"
              tabindex="-1"
              :class="[
                'inline-text-field text-no-wrap',
                { outlined: showOutline },
              ]"
              :contenteditable="!disabled"
              @input="onInput"
              @keydown="onKeydown"
            />
          </v-hover>
        </div>
      </template>
      <div class="text-pre">{{ currentValue }}</div>
    </v-tooltip>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'InlineTextField',
  props: ['value', 'placeholder', 'disabled', 'disableDebounce', 'multiline'],
  data() {
    return {
      isHovered: false,
      onInputChangedDebounce: debounce(this.onInputChanged, 300),
      input: '',
      inputIsFocused: false,
      change: '',
      currentValue: '',
    }
  },
  computed: {
    showOutline() {
      return !this.disabled && (this.isHovered || this.inputIsFocused)
    },
  },
  watch: {
    value(value) {
      if (value !== this.currentValue) {
        this.input.innerText = value
        this.currentValue = value
      }
    },
    inputIsFocused(value) {
      if (value) {
        this.change = this.currentValue
      } else if (this.change !== this.currentValue) {
        this.$emit('change', this.currentValue)
      }
    },
  },
  created() {
    if (!document.body.getAttribute('tabindex')) {
      document.body.setAttribute('tabindex', '-1')
    }
    document.addEventListener('focusin', this.documentFocusChanged)
  },
  mounted() {
    this.input = this.$refs.input
    this.input.innerText = this.value
    this.currentValue = this.value
    this.$nextTick(() => this.$forceUpdate())
  },
  beforeDestroy() {
    document.removeEventListener('focusin', this.documentFocusChanged)
  },
  methods: {
    onInput(event) {
      this.input = event.target
      this.currentValue = this.input.innerText
      this.disableDebounce
        ? this.onInputChanged()
        : this.onInputChangedDebounce()
    },
    onInputChanged() {
      if (this.currentValue !== this.value) {
        this.$emit('input', this.currentValue)
      }
    },
    documentFocusChanged(event) {
      this.inputIsFocused = event.target === this.input
    },
    onKeydown(event) {
      if (event.isComposing || event.keyCode === 229 || event.which === 229) {
        return
      }
      // on Enter
      if (event.keyCode === 13 || event.which === 13) {
        if (this.multiline && event.shiftKey) return
        event.preventDefault()
        this.input.parentElement.focus()
      }
    },
    isOverflowing() {
      return (
        (this.input && this.input.scrollWidth > this.input.clientWidth) || false
      )
    },
  },
}
</script>
<style scoped lang="scss">
.inline-text-field-container {
  outline: none;
  position: relative;
  min-width: 0;
}

.inline-text-field {
  padding: 6px 10px;
  line-height: 20px;
  outline: none;
  overflow: auto;
}

.outlined {
  outline: auto 3px var(--v-secondary-lighten);
}

.placeholder {
  pointer-events: none;
  position: absolute;
  top: 5px;
  left: 10px;
  opacity: 0.4;
}
</style>
