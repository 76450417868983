<template>
  <div>
    <div>
      <v-row class="align-center">
        <v-col cols="6" sm="4" lg="2">
          <v-text-field
            v-model="filters.searchTerm"
            label="Search"
            prepend-inner-icon="search"
            outlined
            dense
            hide-details
            @input="debouncedFetchAiLogs"
          />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <filters-select
            :value.sync="filters.event_type"
            label="Type"
            :items="typeItems"
            :selection-func="item => item.text"
            @change="filteredAiLogs()"
          />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <v-autocomplete
            v-model="filters.settings_type"
            :items="settingTypes"
            item-value="value"
            item-text="text"
            clearable
            dense
            outlined
            hide-details
            label="Conf"
            @change="filteredAiLogs()"
          />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <v-autocomplete
            v-model="filters.agentType"
            :items="agentTypes"
            item-value="value"
            item-text="text"
            clearable
            dense
            outlined
            hide-details
            label="Agent Type"
            @change="filteredAiLogs()"
          />
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <date-picker
            v-model="filters.createdAt"
            label="Date"
            :max="
              parseISODateWithOffset(
                new Date(new Date().setMonth(new Date().getMonth() + 10))
              ).substr(0, 10)
            "
            :min="
              parseISODateWithOffset(
                new Date(new Date().setMonth(new Date().getMonth() - 20))
              ).substr(0, 10)
            "
            class="date-picker-container"
            @change="filteredAiLogs()"
          >
            <template #prepend-inner>
              <button
                v-if="filters.createdAt"
                class="clear-button v-icon notranslate v-icon--link material-icons theme--light"
                @click="clearDate"
              >
                clear
              </button>
            </template>
          </date-picker>
        </v-col>
        <v-col cols="6" sm="4" lg="2">
          <v-checkbox
            v-model="filters.usedSuggestionOnly"
            dense
            hide-details
            class="mt-0"
            label="AI Suggestion Used only"
            @change="filteredAiLogs()"
          />
        </v-col>
        <v-col cols="6" sm="3" class="d-flex align-center">
          <multi-property-picker
            v-model="filters.listings_ids"
            :filter-change="() => filteredAiLogs()"
            hide-details
          />
        </v-col>
        <v-col cols="6" sm="3" class="d-flex align-center">
          <div class="me-2 font-weight-medium text-body-2">Last:</div>
          <v-btn-toggle
            v-model="filters.selected_period"
            color="primary"
            @change="filteredAiLogs()"
          >
            <v-btn small value="last_hour">Hour</v-btn>
            <v-btn small value="last_12_hours">12 Hours</v-btn>
            <v-btn small value="last_day">Day</v-btn>
            <v-btn small value="last_week">Week</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </div>
    <div class="mb-4 d-flex">
      <investment-card
        class="px-2"
        cols="4"
        height="70"
        :card="cards.messages"
      />
      <investment-card
        class="px-2"
        cols="4"
        height="70"
        :card="cards.escalations"
      />
      <investment-card
        class="px-2"
        cols="4"
        height="70"
        :card="cards.serviceCalls"
      />
    </div>
    <div>
      <v-data-table
        :headers="HEADERS"
        :loading="logsLoading"
        :items="fetchedAiLogs.logs"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        fixed-header
        disable-sort
        :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
        @pagination="paginationChanged($event)"
      >
        <template #item.link="{ item }">
          <router-link
            v-if="item.review_id || item.conversation"
            color="primary"
            x-small
            icon
            :to="
              item.review_id
                ? `/dashboard/reviews-dashboard/manage?id=${item.review_id}`
                : `/dashboard/guest-experience/cs?conversation=${
                    item.conversation && item.conversation.guesty_id
                  }`
            "
            class="text-decoration-underline"
            target="_blank"
            >{{
              item.conversation &&
              item.conversation.guest &&
              item.conversation.guest.full_name
            }}
          </router-link>
        </template>
        <template #item.description="{ item }">
          <div class="d-flex">
            <div class="code-container flex-1">
              <div>
                {{ item.description }}
              </div>
            </div>
          </div>
        </template>
        <template #item.event_type="{ item }">
          <v-chip label small color="blue-grey lighten-5">
            {{ item.event_type }}
          </v-chip>
        </template>
        <template #item.settings_type="{ item }">
          <v-chip
            label
            small
            :class="item.settings_type == 'auto_pilot' ? 'cyan' : 'grey'"
            color="lighten-5"
          >
            {{ item.settings_type }}
          </v-chip>
        </template>
        <template #item.agent_type="{ item }">
          <v-chip
            label
            small
            :class="item.agent_type == 'cs' ? 'yellow' : 'pink'"
            color="lighten-5"
          >
            {{ item.agent_type }}
          </v-chip>
        </template>
        <template #item.ai_suggestion_used="{ item }">
          <v-icon v-if="item.ai_suggestion_used">mdi mdi-check</v-icon>
          <v-icon v-else>mdi mdi-close</v-icon>
        </template>
        <template #item.created_at="{ item }">
          <div>
            {{
              parseDate(item.created_at, 'YYYY-MM-DD HH:mm', { local: true })
            }}
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import NavigationMixin from 'components/mixins/navigation-mixin'
import { mapState, mapActions } from 'vuex'
import Common_functions from 'components/mixins/common_functions'
import DatePicker from 'components/form-fields/date-picker.vue'
import FiltersSelect from 'components/form-fields/filters-select.vue'
import InvestmentCard from 'components/investment-info/investment-card'
import MultiPropertyPicker from 'components/multi-property-picker.vue'

const HEADERS = [
  { text: 'Link', value: 'link', width: '200', align: 'center' },
  { text: 'Type', value: 'event_type', width: '200', align: 'center' },
  {
    text: 'Accepted',
    value: 'ai_suggestion_used',
    width: '100',
    align: 'center',
  },
  { text: 'Conf', value: 'settings_type', width: '200', align: 'center' },
  { text: 'Agent', value: 'agent_type', width: '200', align: 'center' },

  { text: 'Created', value: 'created_at', width: '200', align: 'center' },
  {
    text: 'AI Suggestion',
    value: 'description',
    align: 'center',
  },
]

export default {
  components: {
    FiltersSelect,
    DatePicker,
    InvestmentCard,
    MultiPropertyPicker,
  },
  mixins: [NavigationMixin, Common_functions],
  data() {
    return {
      HEADERS,
      pagination: {
        page: 1,
        itemsPerPage: 15,
      },
      page: 1,
      logsLoading: false,
      listingId: null,
      logs: [],
      showMoreItemId: null,
      filters: {
        searchTerm: '',
        usedSuggestionOnly: false,
        listingId: null,
        event_type: [],
        listings_ids: [],
        settings_type: null,
        createdAt: null,
        selected_period: 'last_hour',
      },
    }
  },
  mounted() {
    this.startPooling()
  },
  beforeDestroy() {
    clearInterval(this.poolingInterval)
  },
  computed: {
    ...mapState({
      fetchedAiLogs: state => state.aiLogs,
    }),
    cards() {
      const stats = this.fetchedAiLogs.stats || {}
      return {
        messages: {
          label: 'Messages',
          color: '#EAF5ED',
          textColor: '#396646',
          icon: 'mdi-message',
          iconColor: 'green',
          isCount: true,
          value: stats.conversation_answer || '--',
        },
        escalations: {
          label: 'Escalations',
          color: '#FEF0EC',
          textColor: '#603128',
          icon: 'mdi-alert-circle',
          iconColor: 'red',
          isCount: true,
          value: stats.escalations || '--',
        },
        serviceCalls: {
          label: 'Service Calls',
          color: '#ffeed0',
          textColor: '#746039',
          iconColor: 'orange',
          icon: 'mdi-clipboard-list',
          isCount: true,
          captionValue: stats.service_calls_created_open
            ? `(${stats.service_calls_created_open} Open)`
            : '',
          value: stats.service_calls_created || '--',
        },
      }
    },
    typeItems() {
      return [
        { text: 'CONVERSATION_ANALYSED', value: 'conversation_analysis' },
        { text: 'CONVERSATION_ANSWER', value: 'conversation_answer' },
        { text: 'FUNCTION_CALL', value: 'function_call' },
        { text: 'INSTRUCTIONS_GENERATION', value: 'instructions_generation' },
        { text: 'REVIEW_ANALYZED', value: 'review_analyzed' },
        { text: 'POLICY_GENERATION', value: 'policy_generation' },
        { text: 'SERVICE_CALL_CREATED', value: 'service_call_created' },
      ]
    },
    settingTypes() {
      return [
        { text: 'Autopilot', value: 'auto_pilot' },
        { text: 'Copilot', value: 'co_pilot' },
      ]
    },
    agentTypes() {
      return [
        { text: 'CS', value: 'cs' },
        { text: 'Sales', value: 'sales' },
        { text: 'Ops', value: 'operations' },
      ]
    },
  },
  watch: {
    'fetchedAiLogs.pagination'(newPagination) {
      this.pagination = {
        itemsLength: newPagination.count,
        itemsPerPage: newPagination.per_page,
        page: newPagination.page,
      }
    },
    'filters.createdAt'(val) {
      if (val) {
        this.filters.selected_period = null
      }
    },
    'filters.selected_period'(val) {
      if (val) {
        this.filters.createdAt = null
      }
    },
  },
  methods: {
    ...mapActions(['getAiLogs']),
    filteredAiLogs(paginationPayload = null) {
      const payload = {
        created_at: this.filters.createdAt,
        ai_suggestion_used: this.filters.usedSuggestionOnly,
        event_type: this.filters.event_type,
        settings_type: this.filters.settings_type,
        agent_type: this.filters.agentType,
        listing_id: this.filters.listingId,
        search_term: this.filters.searchTerm,
        selected_period: this.filters.selected_period,
        listings_ids: this.filters.listings_ids,
      }

      if (paginationPayload == null) {
        paginationPayload = {
          page: 1,
          per_page: this.pagination.itemsPerPage,
        }
      }

      this.getAiLogs({ ...paginationPayload, ...payload })
    },
    debouncedFetchAiLogs() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.filteredAiLogs()
      }, 300)
    },
    startPooling() {
      this.poolingInterval = setInterval(() => {
        if (this.pagination.page === 1) this.filteredAiLogs()
      }, 8000)
    },
    paginationChanged(paginationData) {
      const paginationPayload = {
        page: paginationData.page,
        per_page: paginationData.itemsPerPage,
      }
      this.filteredAiLogs(paginationPayload)
    },
    clearDate() {
      this.filters.createdAt = null
      this.filteredAiLogs()
    },
    toggleShowMore(itemId) {
      if (this.showMoreItemId === itemId) {
        this.showMoreItemId = null
      } else {
        this.showMoreItemId = itemId
      }
    },
  },
}
</script>

<style scoped>
.date-picker-container {
  position: relative;
}

.clear-button {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 1;
}

.code-container {
  max-height: 200px;
  width: 0;
  overflow: scroll;
  white-space: pre-line;
  background: white;
  border: solid 1px darkgrey;
  font-family: monospace;
  font-size: 11px;
  padding: 10px;
  margin: 10px;
  line-height: 1rem;
  border-radius: 3px;
  text-align: initial;
}
.v-data-table >>> tr:hover {
  background: transparent !important;
}
</style>
