<template>
  <td
    class="flex xs12"
    :class="item.status === 'New' ? 'super-light-red' : 'green lighten-5'"
  >
    <div
      v-if="myLoader > 0"
      class="mr-2 ml-2 fileUploadWarning layout purple-border round-tag purple-icon white"
    >
      <v-progress-circular
        :size="20"
        class="ml-2 mr-2"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <span
        ><span class="cyan-icon bolder">{{ myLoader }} files</span> uploads are
        not finished, you can keep working but <strong>please stay</strong> on
        the page.</span
      >
    </div>
    <v-layout
      :class="justMoved ? 'just-selected' : ''"
      align-center
      center-text
      wrap
    >
      <v-flex xs="2" sm="1">
        <v-row class="row br-1">
          <v-col class="col mr-3">
            <v-row
              v-if="
                (isOperationsPersonal || isAdmin) &&
                showOrder &&
                (prevRank || prevRank === 0)
              "
              class="cyan-icon"
            >
              <v-icon @click="updateListingTask(prevRank - 0.1, 'current_rank')"
                >keyboard_arrow_up
              </v-icon>
            </v-row>
            <v-row class="purple-icon bolder">
              #{{ currentIndex }}
              <span
                v-if="!timeNotSet(item.scheduled_at)"
                class="ml-2 very-small-text cyan-icon"
                >Time Scheduled
              </span>
              <span v-if="isAdmin" class="ml-2 very-small-text purple-icon">{{
                item.current_rank
              }}</span>
            </v-row>

            <v-row
              v-if="
                (isOperationsPersonal || isAdmin) &&
                showOrder &&
                (nextRank || nextRank === 0)
              "
            >
              <v-icon
                class="cyan-icon"
                @click="updateListingTask(nextRank + 0.1, 'current_rank')"
                >keyboard_arrow_down
              </v-icon>
            </v-row>
            <v-row
              v-if="
                !isEmpty(item.mini_extra_data.suggestions) &&
                (isOperationsPersonal || isAdmin || onPayroleContractor)
              "
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <span class="small-text purple-icon" v-on="on"
                    >{{ item.mini_extra_data.suggestions.length }} more sc</span
                  >
                </template>
                <v-container>
                  <v-row class="col-1" style="min-width: 400px">
                    <template v-for="sug in item.mini_extra_data.suggestions">
                      <v-col :key="sug.id" sm="12">
                        {{ sug.task_type }} | {{ sug.sub_topic }} |
                        {{ sug.description }}
                      </v-col>
                    </template>
                  </v-row>
                </v-container>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-row>
            <v-icon class="green-health pl-2" @click="openLink(listingAddress)"
              >location_on
            </v-icon>

            <v-tooltip v-if="!isEmpty(item.mini_extra_data.red_flags)" top>
              <template #activator="{ on }">
                <v-icon
                  medium
                  class="icon ml-1 alt red--text help-icon"
                  v-on="on"
                  >whatshot
                </v-icon>
              </template>
              <v-row wrap style="min-width: 200px">
                <v-col
                  v-for="(redFlag, index) in item.mini_extra_data.red_flags"
                  :key="index"
                  cols="12"
                  class="center-text"
                >
                  {{ redFlag }}
                </v-col>
              </v-row>
            </v-tooltip>
          </v-row>
        </v-row>
      </v-flex>
      <v-flex xs3 mt-2 br-1 pr-2>
        <v-layout column align-center justify-center>
          <v-icon class="cyan-icon mr-2">{{ itemIcon }}</v-icon>
          <span class="purple-icon small-text bolder">
            <router-link
              target="_blank"
              :to="addressFor(item.id)"
              class="sidebar-link purple-icon"
            >
              {{ truncateBy(listingAddress, 40) }}
            </router-link>
          </span>
          <span v-if="item.project_name" class="small-text br-1">{{
            parseDate(item.scheduled_at)
          }}</span>
          <span class="small-text">{{
            nameById(item.assigned_contractor_id)
          }}</span>
          <v-row
            :class="item.add_today_avialability_text"
            class="pa-1 white--text bolder round-tag very-small-text"
          >
            {{ item.add_today_avialability_text }}
          </v-row>
        </v-layout>
      </v-flex>
      <v-flex xs2 mt-2 br-1 pl-2>
        <v-layout wrap>
          <v-flex xs12>
            <v-layout column align-center justify-center>
              <v-tooltip v-if="item.mini_extra_data.pre_check_in" top>
                <template #activator="{ on }">
                  <v-icon small class="icon ml-1 alt help-icon" v-on="on"
                    >fas fa-question-circle
                  </v-icon>
                </template>
                <v-layout wrap style="min-width: 200px">
                  <pre-check-in-card
                    :pre-check-in="item.mini_extra_data.pre_check_in"
                  ></pre-check-in-card>
                </v-layout>
              </v-tooltip>

              <span class="small-text bolder">{{ item.description }}</span>
              <v-col
                v-if="item.priority"
                md="auto"
                class="right-text white--text bolder center-text rounded-card pa-1"
                style="font-size: 9px"
                :class="priorityClass(item.priority)"
              >
                {{ item.priority }}
              </v-col>
              <span
                v-if="cleaningOnSite"
                class="small-text purple-bg white--text rounded-card bolder"
                style="
                  font-size: 9px !important;
                  padding-left: 2px;
                  padding-right: 2px;
                "
                >Cleaning on site</span
              >
              <span
                v-if="daysSinceLastGuest && daysSinceLastGuest !== -1"
                class="small-text cyanish white--text rounded-card bolder mt-1"
                style="
                  font-size: 9px !important;
                  padding-left: 2px;
                  padding-right: 2px;
                "
                >{{ daysSinceLastGuest }} days empty</span
              >
              <span
                v-if="daysSinceLastGuest && daysSinceLastGuest === -1"
                class="small-text cyanish white--text rounded-card bolder mt-1"
                style="
                  font-size: 9px !important;
                  padding-left: 2px;
                  padding-right: 2px;
                "
                >Guest in house</span
              >
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="item.status === 'New'" xs5 mt-2>
        <v-btn
          v-if="!subTasksAmount && showEasyColmplete"
          class="greenish"
          fab
          dark
          @click="moveToDone"
        >
          <v-icon>verified</v-icon>
        </v-btn>
        <router-link
          v-if="subTasksAmount"
          target="_blank"
          :to="addressFor(item.id)"
          class="sidebar-link purple-icon"
        >
          View ({{ subTasksAmount }} tasks)
        </router-link>
        <v-btn
          v-if="!subTasksAmount && !showEasyColmplete"
          class="greenish"
          fab
          :disabled="true"
        >
          <v-icon>verified</v-icon>
        </v-btn>
        <v-btn
          v-if="
            managementRoles &&
            firstRank != item.current_rank &&
            (firstRank || firstRank === 0)
          "
          x-small
          class="ml-2 pa-1 very-small-text cyan-icon"
          @click="updateListingTask(firstRank - 0.1, 'current_rank')"
        >
          <v-icon>arrow_circle_up</v-icon>
          Top
        </v-btn>
        <v-btn
          v-if="managementRoles && lastRank && lastRank != item.current_rank"
          x-small
          class="ml-2 pa-1 very-small-text purple-icon"
          @click="updateListingTask(lastRank + 0.1, 'current_rank')"
        >
          <v-icon>arrow_circle_down</v-icon>
          Bottom
        </v-btn>
      </v-flex>
      <v-flex v-if="item.status !== 'New'" xs5 mt-2>
        <v-layout wrap align-center>
          <v-flex xs8 class="br-1">
            <gallery
              v-if="!isEmpty(item.after_images)"
              :images="item.after_images"
              icon="images"
            />
            <v-btn color="secondary" icon @click="isAfterFilesUpload = true">
              <v-icon>mdi-image</v-icon>
            </v-btn>
          </v-flex>
          <v-flex v-if="!isCleaningSupervisor && !isCleaningManager" xs3>
            <v-layout wrap>
              <v-flex>
                <v-icon
                  v-if="isEmpty(item.log)"
                  class="grey--text"
                  @click="updateChosenListingSetupTask(item)"
                  >message
                </v-icon>
                <v-badge v-if="!isEmpty(item.log)" color="dark-purple">
                  <span
                    slot="badge"
                    style="font-size: 10px"
                    class="white--text bold"
                    >{{ objSize(item.log) }}</span
                  >
                  <v-icon
                    class="cyan-icon"
                    @click="updateChosenListingSetupTask(item)"
                    >message
                  </v-icon>
                </v-badge>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex v-if="item.is_valid" xs6 mt-2>
        <v-layout wrap>
          <v-flex xs12>
            <v-btn class="cyanish" fab dark>
              <v-icon class="">done_outline</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="isAfterFilesUpload" max-width="600">
      <v-card>
        <v-card-title> Upload files</v-card-title>
        <v-card-text>
          <dvr-media-upload :value.sync="afterFiles" folder="service-calls" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="secondary"
            class="text-capitalize"
            text
            @click="isAfterFilesUpload = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            elevation="0"
            @click="saveAfterImages"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </td>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import size from 'lodash/size'

import get from 'lodash/fp/get'
import PreCheckInCard from './pre-check-in/pre-check-in-card.vue'
import DvrMediaUpload from 'components/common/dvr-media-upload'
import Gallery from 'components/common/gallery'

export default {
  components: {
    DvrMediaUpload,
    PreCheckInCard,
    Gallery,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [
    'item',
    'afterChange',
    'prevRank',
    'nextRank',
    'firstRank',
    'lastRank',
    'currentIndex',
    'showOrder',
  ],
  data: function () {
    return {
      singleSelect: false,
      menu: false,
      menu2: false,
      beforeFiles: [],
      afterFiles: [],
      videoFiles: [],
      beforeImagesUploaded: false,
      imageDialog: false,
      chosenImages: [],
      upladingBefore: false,
      upladingVideo: false,
      showPicker: false,
      showListingPicker: false,
      editDesc: false,
      isAfterFilesUpload: false,
    }
  },
  computed: {
    justMoved() {
      return this.item.id == this.$store.state.justMovedTaskId
    },
    subTasksAmount() {
      return (
        this.item.mini_extra_data.sub_tasks_amount +
        this.item.mini_extra_data.shadow_sub_tasks_amount
      )
    },
    showEasyColmplete() {
      let type = this.item.task_type
      if (
        type === 'garbage patrol' ||
        type == 'waste management' ||
        type === 'security patrol' ||
        type === 'day patrol' ||
        this.item.mini_extra_data.super_simple_enabled
      ) {
        return true
      }
      return false
    },
    listingAddress() {
      return get('mini_extra_data.listing_name', this.item)
    },
    itemIcon() {
      if (
        this.item.task_type === 'garbage patrol' ||
        this.item.task_type === 'waste management'
      ) {
        return 'delete_outline'
      } else if (this.item.task_type === 'security patrol') {
        return 'security'
      } else if (this.item.task_type === 'day patrol') {
        return 'meeting_room'
      } else if (this.item.task_type === 'repair') {
        return 'construction'
      } else if (this.item.task_type === 'cleaning') {
        return 'cleaning_services'
      } else if (this.item.task_type === 'landscape') {
        return 'nature_people'
      } else if (this.item.task_type === 'pest-control') {
        return 'bug_report'
      } else if (this.item.task_type === 'pool') {
        return 'pool'
      } else if (this.item.task_type === 'setup') {
        return 'house_siding'
      } else {
        return ''
      }
    },
    cleaningOnSite() {
      return this.item.mini_extra_data.cleaning_on_site
    },
    daysSinceLastGuest() {
      return this.item.mini_extra_data.days_since_last_guest
    },
  },
  methods: {
    openLink(address) {
      window.open(`https://maps.google.com/?q=${address}`, '_blank')
    },
    addressFor(id) {
      return { path: `/dashboard/listing-task/${id}` }
    },
    hasAfterImages() {
      return this.item.after_images.length > 0
    },
    updateChosenListingSetupTask(task) {
      this.$store.commit('updateChosenListingSetupTask', task)
    },
    async saveAfterImages() {
      await this.$store.dispatch('updateListingTask', {
        id: this.item.id,
        after_images: this.afterFiles,
      })
      this.afterFiles = []
      this.afterChange()
      this.isAfterFilesUpload = false
    },
    moveToDone() {
      if (this.item.in_loop) {
        this.$store.commit('showModal', {
          name: 'LtLoopMoveToDoneModal',
          props: {
            condition: this.item.listing_task_template.loop_condition_text,
            payload: { id: this.item.id, status: 'Done' },
            afterChange: () =>
              setTimeout(() => {
                this.afterChange()
              }, 500),
          },
        })
      } else {
        this.updateListingTask('Done', 'status')
      }
    },
    updateListingTask(val, key) {
      if (key === 'current_rank') {
        this.item.current_rank = val
        this.$store.commit('updateJustMovedTaskId', this.item.id)
        setTimeout(() => {
          this.$store.commit('updateJustMovedTaskId', null)
        }, 4000)
      }

      let item = this.item

      item[key] = val
      this.$store.dispatch('updateListingTask', { id: item.id, [key]: val })
      setTimeout(() => {
        this.afterChange()
      }, 500)
    },

    objSize(item) {
      return size(item)
    },
  },
}
</script>
