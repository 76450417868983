<template>
  <v-sheet>
    <v-row class="ma-2 ma-sm-0">
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="searchQuery"
          :label="$t('Search')"
          dense
          outlined
          clearable
          append-icon="mdi-magnify"
          @input="handleSearchDebounced"
        />
      </v-col>

      <v-col cols="12" sm="3">
        <multi-property-picker
          v-model="selectedListingId"
          :label="$t('Select Listings')"
          hide-details
          :hide-btns="true"
          @input="handleFilterChange"
        />
      </v-col>

      <v-col cols="12" sm="2">
        <v-select
          v-model="selectedBlockType"
          :items="blockTypeOptions"
          :label="$t('Type')"
          dense
          outlined
          clearable
          @change="handleFilterChange"
        />
      </v-col>
    </v-row>

    <v-divider class="mb-4" />

    <v-data-table
      :headers="headers"
      :items="listingBlocks"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItems"
      fixed-header
      height="70vh"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100],
      }"
    >
      <template #item.start_date="{ item }">
        {{ formatDate(item.start_date) }}
      </template>

      <template #item.end_date="{ item }">
        {{ formatDate(item.end_date) }}
      </template>
    </v-data-table>
  </v-sheet>
</template>

<script>
import axios from 'axios'
import formattersMixin from 'components/mixins/formatters-mixin'
import MultiPropertyPicker from '../multi-property-picker.vue'
import debounce from 'lodash/debounce'

export default {
  name: 'ListingsBlocks',

  components: {
    MultiPropertyPicker,
  },

  mixins: [formattersMixin],

  data() {
    return {
      listingBlocks: [],
      loading: false,
      selectedBlockType: null,
      selectedListingId: [],
      searchQuery: '',
      totalItems: 0,
      blockTypeOptions: [],
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
      },
      headers: [
        {
          text: 'Listing',
          align: 'center',
          value: 'listing.nickname',
          width: '120px',
        },
        {
          text: 'Start Date',
          value: 'start_date',
          align: 'center',
          width: '150px',
        },
        {
          text: 'End Date',
          value: 'end_date',
          align: 'center',
          width: '150px',
        },
        {
          text: 'Type',
          value: 'block_type',
          align: 'center',
          width: '150px',
        },
        {
          text: 'Note',
          value: 'note',
          align: 'center',
          width: '150px',
        },
      ],
    }
  },

  watch: {
    options: {
      handler() {
        this.fetchListingBlocks()
      },
      deep: true,
    },
  },

  created() {
    this.handleSearchDebounced = debounce(this.handleSearch, 300)
    this.fetchListingBlocks()
  },

  methods: {
    async fetchListingBlocks() {
      this.loading = true
      try {
        const params = {
          page: this.options.page,
          per_page: this.options.itemsPerPage,
          block_type: this.selectedBlockType || '',
          listing_id: this.selectedListingId?.[0] || '',
          search: this.searchQuery,
        }

        const response = await axios.get('/api/listing-blocks', { params })
        this.listingBlocks = response.data.listing_blocks
        this.blockTypeOptions = response.data.block_types
        this.totalItems = response.data.pagination.count
      } catch (error) {
        console.error('Error fetching listing blocks:', error)
      } finally {
        this.loading = false
      }
    },

    handleSearch() {
      this.options.page = 1
      this.fetchListingBlocks()
    },

    handleFilterChange() {
      this.options.page = 1
      this.fetchListingBlocks()
    },

    formatDate(date) {
      return new Date(date).toLocaleDateString()
    },
  },
}
</script>

<style scoped></style>
