<template>
  <v-container>
    <v-layout v-if="companyActivity" wrap pa-3 mt-2 class="comp-wrapper">
      <v-flex xs6 class="comp-wrapper">
        <span class="text-h5 purple-icon">On call Communication</span>
        <v-layout column>
          <v-flex
            v-for="communicationPerson in onCallCommunication"
            :key="communicationPerson.name"
            class="communicationPerson.clocked_in ? 'cyan-icon' : 'grey-text'"
          >
            {{ communicationPerson.name }} -
            <span v-if="communicationPerson.clocked_in" class="cyan-icon"
              >Clocked in since {{ communicationPerson.last_clock_in }}</span
            >
            <span v-if="!communicationPerson.clocked_in" class="grey--text"
              >Last clock out {{ communicationPerson.last_clock_out }}</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 class="comp-wrapper">
        <span class="text-h5 purple-icon">On call PM</span>
        <v-layout column>
          <v-flex v-for="pm in onCallPm" :key="pm.name">
            {{ pm.name }} -
            <span v-if="pm.clocked_in" class="cyan-icon"
              >Clocked in since {{ pm.last_clock_in }}</span
            >
            <span v-if="!pm.clocked_in" class="grey--text"
              >Last clock out {{ pm.last_clock_out }}</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 mt-5>
        <GmapMap
          :center="{
            lat: center[0],
            lng: center[1],
          }"
          :zoom="9"
          :options="{ disableDefaultUI: true }"
          map-type-id="terrain"
          style="width: 100%; height: 500px"
        >
          <GmapMarker
            v-for="item in companyActiveUsers"
            :key="item.id"
            :label="{ text: item.name, color: 'black' }"
            :position="{
              lat: item.lat,
              lng: item.lng,
            }"
          />
        </GmapMap>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import filter from 'lodash/filter'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  mixins: [CommonFunctions, PermissionsMixin],

  data() {
    return {}
  },
  computed: {
    center() {
      if (this.companyActivity) {
        return this.companyActivity.center
      }
      return [0, 0]
    },
    companyActivity() {
      return this.$store.state.companyActivity
    },
    companyActivityUsers() {
      if (this.companyActivity) {
        return this.companyActivity.users
      }
      return []
    },
    companyActiveUsers() {
      return (
        filter(this.companyActivityUsers, user => user.lng && user.lat) || []
      )
    },
    onCallPm() {
      return (
        filter(
          this.companyActivityUsers,
          user => user.role === 'property-manager'
        ) || []
      )
    },
    onCallCommunication() {
      return (
        filter(
          this.companyActivityUsers,
          user => user.role === 'communication-manager'
        ) || []
      )
    },
  },
  methods: {},
}
</script>
