<template>
  <form-dialog title="Materials" @submit="addMaterials" @close="onClose">
    <template #activator="{ on }">
      <v-btn :disabled="disabled" outlined small color="info" v-on="on">
        <v-icon small left>fas fa-plus</v-icon> {{ $t('Materials') }} /
        {{ $t('Supplies') }}
      </v-btn>
    </template>
    <v-select
      v-model="expenseType"
      :label="$t('Expense Type')"
      outlined
      dense
      :items="['Repair Materials', 'Supplies']"
    />
    <v-text-field
      v-model.number="amount"
      :label="$t('Amount')"
      :rules="[required]"
      dense
      outlined
    />
    <v-textarea
      v-model="description"
      :label="$t('Description')"
      :rules="[required]"
      dense
      outlined
    />
    <v-autocomplete
      v-if="isDesignedVr"
      v-model="bankAccountId"
      :label="$t('Card owner')"
      dense
      outlined
      clearable
      :allow-overflow="false"
      :items="accountNumbers"
    />
    <date-picker
      v-model="paidAt"
      :label="$t('paid at')"
      :hide-details="false"
      :rules="[required]"
    />
    <dvr-media-upload
      :label="$t('Invoice')"
      :value.sync="images"
      folder="service-calls"
    />
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import DvrMediaUpload from 'components/common/dvr-media-upload'
import DatePicker from 'components/form-fields/date-picker'
export default {
  name: 'AddRepairMaterials',
  components: { DatePicker, DvrMediaUpload, FormDialog },
  mixins: [FormRules],
  props: ['taskId', 'disabled'],
  data() {
    return {
      amount: null,
      description: null,
      images: [],
      bankAccountId: null,
      expenseType: 'Repair Materials',
      paidAt: this.$moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    accountNumbers() {
      return this.$store.state.app.bankAccounts.map(ba => ({
        value: ba.id,
        text: `${
          ba.first_name || ba.last_name
            ? `${ba.first_name} ${ba.last_name}`
            : 'Company'
        } (${ba.account_number})`,
      }))
    },
  },
  methods: {
    addMaterials() {
      this.$store.dispatch('addRepairMaterials', {
        id: this.taskId,
        amount: this.amount,
        expense_type: this.expenseType,
        description: this.description,
        bank_account_id: this.bankAccountId,
        images: this.images,
        paid_at: this.paidAt,
      })
    },
    onClose() {
      this.amount = null
      this.description = null
      this.images = []
      this.expenseType = 'Repair Materials'
      this.paidAt = this.$moment().format('YYYY-MM-DD')
    },
  },
}
</script>

<style scoped></style>
