import { render, staticRenderFns } from "./assignee-select.vue?vue&type=template&id=6d7053d6&scoped=true&"
import script from "./assignee-select.vue?vue&type=script&lang=js&"
export * from "./assignee-select.vue?vue&type=script&lang=js&"
import style0 from "./assignee-select.vue?vue&type=style&index=0&id=6d7053d6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d7053d6",
  null
  
)

export default component.exports