<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
  >
    <path
      class="a"
      d="M20.562,18.375H2.625V.438a.438.438,0,1,0-.875,0V18.375H.437a.438.438,0,0,0,0,.875H1.75v1.312a.437.437,0,1,0,.875,0V19.25H20.562a.437.437,0,1,0,0-.875Z"
    />
    <path
      class="a"
      d="M107.1,117.167a.436.436,0,0,0,.309-.128l4.941-4.941,2.316,2.316a.437.437,0,0,0,.619,0l6.253-6.253v1.569a.438.438,0,0,0,.875,0V107.1a.437.437,0,0,0-.438-.438h-2.625a.438.438,0,0,0,0,.875h1.569l-5.944,5.944-2.316-2.316a.437.437,0,0,0-.619,0l-5.25,5.25a.437.437,0,0,0,.309.747Z"
      transform="translate(-102.292 -102.292)"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowChart',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
