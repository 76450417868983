<template>
  <v-autocomplete
    v-model="selectedConversationTag"
    clearable
    small-chips
    deletable-chips
    :items="tags"
    :disabled="disabled"
    :rules="rules"
    label="Choose Topics"
    :multiple="multiple"
    :filter="customFilter"
    item-value="title"
    outlined
    @change="changeConversationTag"
  >
    <template #item="{ item }">
      <v-row class="align-center">
        <Tag :class="['green']" color="white" class="ml-2">
          {{ item.department }}
        </Tag>
        <span class="ml-2 mr-2">
          {{ item.title }}
        </span>
        <span v-if="item.description" class="grey-text">
          - {{ truncateBy(item.description, 35) }}
        </span>
      </v-row>
    </template>
    <template #selection="{ item }">
      <v-chip small class="ma-1">{{ item.title }}</v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import Tag from 'components/common/tag'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'ConversationTagMultiSelect',
  components: {
    Tag,
  },
  mixins: [CommonFunctions],
  props: [
    'filterChange',
    'disabled',
    'rules',
    'value',
    'multiple',
    'preSelected',
  ],
  data() {
    return {
      selectedConversationTag: this.preSelected,
    }
  },
  computed: {
    listingTaskCreationInfo() {
      return this.$store.state.listingTaskCreationInfo
    },
    tags() {
      return this.$store.state.conversationTagsObjects
    },
  },
  watch: {
    value(val) {
      this.selectedConversationTag = val
    },
  },
  methods: {
    customFilter(item, queryText) {
      return (
        (item.description &&
          item.description.toLowerCase().includes(queryText.toLowerCase())) ||
        (item.title &&
          item.title.toLowerCase().includes(queryText.toLowerCase()))
      )
    },
    changeConversationTag(conversationTag) {
      this.selectedConversationTag = conversationTag
      this.filterChange && this.filterChange(this.selectedConversationTag)
      this.$emit('update:value', conversationTag)
    },
  },
}
</script>
