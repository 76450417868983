<template>
  <v-card class="pa-4">
    <v-card-title class="text-center">
      <span class="text-h5">{{
        $t(`Publish ${selectedVersion.version_number}`)
      }}</span>
    </v-card-title>

    <v-form>
      <v-select
        v-model="selectedTenant"
        :disabled="!tenants.length"
        class="d-inline-block my-2"
        label="Select Tenant"
        item-text="name"
        item-value="id"
        hide-details
        :items="tenants"
        outlined
        dense
        @change="fetchListingsPerTenant"
      />
      <v-select
        v-model="selectedListing"
        :disabled="!selectedTenant"
        class="d-inline-block my-2"
        label="Listing (optional)"
        item-text="nickname"
        return-object
        hide-details
        :items="listingsNoScope"
        outlined
        dense
      ></v-select>
      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined @click="close">Cancel</v-btn>
        <v-btn color="primary" :disabled="!selectedTenant" @click="submit">
          Submit
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['selectedVersion'],
  data() {
    return {
      selectedTenant: null,
      selectedListing: null,
      tenants: [
        {
          name: 'All tenants',
          tenant_id: null,
        },
      ],
    }
  },
  computed: {
    listingsNoScope() {
      return this.$store.state.listingsNoScope
    },
  },
  mounted() {
    this.fetchTenants()
  },
  methods: {
    ...mapActions('tenants', ['getTenants']),
    async submit() {
      this.$store.commit('hideModal')
      const params = {
        ai_version_id: this.selectedVersion.id,
        tenant_id: this.selectedTenant,
        listing_id: this.selectedListing?.id,
      }
      this.$store.dispatch('deployAiVersionControl', params)
    },
    close() {
      this.$store.commit('hideModal')
      this.label = null
    },
    async fetchTenants() {
      const { data } = await this.getTenants()
      this.tenants = [...this.tenants, ...data]
    },
    async fetchListingsPerTenant() {
      this.$store.commit('updatelistingsNoScope', [])
      if (this.selectedTenant) {
        this.$store.dispatch('getlistingsNoScope', {
          tenant_id: this.selectedTenant,
        })
      }
    },
  },
}
</script>
