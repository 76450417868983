<template>
  <v-card class="pa-4" style="border-radius: 10px">
    <div class="d-flex pa-4">
      <div
        class="flex text-center justify-center font-weight-medium text-subtitle-1"
      >
        Reject AI Generation
      </div>
    </div>
    <v-form ref="form">
      <textarea
        v-model="rejectReason"
        placeholder="Reject Reason"
        rows="5"
        style="
          width: 100%;
          resize: none;
          padding: 15px;
          border-radius: 10px;
          border: solid #00bcd4;
          margin-bottom: 10px;
        "
      ></textarea>
      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined @click="close">Cancel</v-btn>
        <v-btn color="primary" :disabled="!rejectReason" @click="submit"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AiRejectModal',
  props: ['messageId', 'conversationId'],
  data() {
    return {
      rejectReason: null,
    }
  },
  methods: {
    async submit() {
      this.$store.commit('hideModal')
      await axios.post(
        `/api/conversations/${this.messageId}/reject-ai-suggestion?reject_reason=${this.rejectReason}`
      )
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversationId,
      })
      this.rejectReason = null
    },
    close() {
      this.$store.commit('hideModal')
      this.rejectReason = null
    },
  },
}
</script>
