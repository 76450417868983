<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-4">
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="text-h5">Business Models</div>
            <v-btn color="primary" @click="openDialog()">
              <v-icon left>mdi-plus</v-icon>
              Add Business Model
            </v-btn>
          </div>

          <!-- Business Models Table -->
          <v-data-table
            :headers="headers"
            :items="businessModels"
            :loading="loading"
            class="elevation-1"
          >
            <!-- Name Column -->
            <template #[`item.name`]="{ item }">
              <a
                href="#"
                @click.prevent="openDetails(item)"
                class="text-decoration-underline"
              >
                {{ item.name }}
              </a>
            </template>

            <!-- Default Model Column -->
            <template #[`item.is_default`]="{ item }">
              <v-icon
                :color="item.is_default ? 'success' : 'grey'"
                @click="setDefaultModel(item)"
              >
                {{ item.is_default ? 'mdi-star' : 'mdi-star-outline' }}
              </v-icon>
            </template>

            <!-- Actions Column -->
            <template #[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="openDialog(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                small
                color="error"
                @click="confirmDelete(item)"
                :disabled="item.is_default"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Add/Edit Dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >{{ editedItem.id ? 'Edit' : 'New' }} Business Model</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  label="Name"
                  dense
                  outlined
                  required
                  :rules="[required]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.description"
                  dense
                  outlined
                  label="Description"
                />
              </v-col>
              <v-col cols="12" v-if="editedItem.id">
                <multi-property-picker v-model="editedItem.listing_ids" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn color="primary" @click="save" :loading="saving"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card>
        <v-card-title>Delete Business Model</v-card-title>
        <v-card-text>
          Are you sure you want to delete this business model?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="deleteDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="deleteBusinessModel">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <business-model-details
      v-if="selectedModel"
      v-model="showDetails"
      :model="selectedModel"
      @update="handleModelUpdate"
    />
  </v-container>
</template>

<script>
import BusinessModelDetails from './business-model-details'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import MultiPropertyPicker from 'components/multi-property-picker.vue'

export default {
  name: 'BusinessModelTab',
  mixins: [FormRulesMixin],
  components: {
    MultiPropertyPicker,
    BusinessModelDetails,
  },

  data() {
    return {
      loading: false,
      saving: false,
      dialog: false,
      deleteDialog: false,
      businessModels: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Default', value: 'is_default', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedItem: {
        id: null,
        name: '',
        description: '',
        listing_ids: [],
        is_default: false,
      },
      itemToDelete: null,
      showDetails: false,
      selectedModel: null,
    }
  },
  methods: {
    openDialog(item = null) {
      if (item) {
        this.editedItem = item
      } else {
        this.editedItem = {
          id: null,
          name: '',
          description: '',
          listing_ids: [],
          is_default: false,
        }
      }
      this.dialog = true
    },

    closeDialog() {
      this.dialog = false
      this.editedItem = {
        id: null,
        name: '',
        description: '',
        listing_ids: [],
        is_default: false,
      }
    },

    async save() {
      this.saving = true
      try {
        if (this.editedItem.id) {
          await this.$store.dispatch(
            'businessModel/updateBusinessModel',
            this.editedItem
          )
        } else {
          await this.$store.dispatch(
            'businessModel/createBusinessModel',
            this.editedItem
          )
        }
        this.closeDialog()
        await this.fetchBusinessModels()
      } catch (error) {
        console.log(error)
      }
      this.saving = false
    },

    confirmDelete(item) {
      this.itemToDelete = item
      this.deleteDialog = true
    },

    async deleteBusinessModel() {
      try {
        await this.$store.dispatch(
          'businessModel/deleteBusinessModel',
          this.itemToDelete.id
        )
        await this.fetchBusinessModels()
      } catch (error) {
        console.log(error)
      } finally {
        this.deleteDialog = false
        this.itemToDelete = null
      }
    },

    async setDefaultModel(item) {
      try {
        await this.$store.dispatch(
          'businessModel/setDefaultBusinessModel',
          item.id
        )
        await this.fetchBusinessModels()
      } catch (error) {
        console.log(error)
      }
    },

    async fetchBusinessModels() {
      this.loading = true
      try {
        this.businessModels = await this.$store.dispatch(
          'businessModel/fetchBusinessModels'
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    openDetails(item) {
      this.selectedModel = { ...item }
      this.showDetails = true
    },

    handleModelUpdate(updatedModel) {
      this.fetchBusinessModels()
    },
  },
  mounted() {
    this.fetchBusinessModels()
  },
}
</script>
