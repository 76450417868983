export default {
  themes: {
    light: {
      primary: '#44a2a2',
      secondary: {
        base: '#8F90A6',
        lighten: '#dfe0f2',
        lighten1: '#F2F2F2',
        lighten3: '#F6F6F7',
        darken1: '#464673',
        darken2: '#5D6C84',
      },
      tertiary: '#FA825B',
      accent: '#bdaec5',
      darkpurple: '#464673',
      error: {
        base: '#A01622',
        lighten1: '#ff4757',
      },
      notification: {
        base: '#ffdd00',
        lighten1: '#FDCA50',
      },
      success: {
        base: '#1AA872',
        lighten1: '#00BF2C',
        lighten2: '#9fe5cb',
        lighten3: '#ccece1',
      },
      info: {
        base: '#1e71f3',
        lighten1: '#88D7FE',
        lighten5: '#9ed4f1',
        darken1: '#3A57E8',
      },
      warning: '#FF4757',
      yellow: '#FFD54F',

      light100: '#FDFDFD',
      light300: '#FAFAFA',
      light400: '#E5E7EB',
      info400: '#3E92E6',
      info600: '#0B5FB3',
      dark500: '#7C869B',
      dark600: '#4A505D',
      dark700: '#25282F',
      black950: '#0B0D0E',
      success600: '#009F28',
      secondary600: '#C13345',
      primary500: '#00C2C9',
      primary600: '#009BA1',
      warning600: '#9F9700',
    },
  },
  options: { customProperties: true },
}
