import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    regions: [],
  },
  mutations: {
    updateRegions(state, payload) {
      state.regions = payload
    },
  },
  actions: {
    async getRegions({ commit }) {
      const { data } = await axios.get(`/api/region`)
      commit('updateRegions', data)
      return data
    },
    async deleteRegion({ dispatch }, { id }) {
      await axios.delete(`/api/region/${id}`)
      dispatch('getRegions')
    },
    async createRegion({ dispatch }, data) {
      await axios.post(`/api/region`, data)
      dispatch('getRegions')
    },
    async updateRegion({ dispatch }, { id, ...data }) {
      await axios.put(`/api/region/${id}`, data)
      dispatch('getRegions')
      dispatch('getListingsPicker', null, { root: true })
    },
  },
}
