<template>
  <v-sheet width="100%">
    <v-card class="pa-4">
      <div class="mb-4 text-h5 font-weight-bold">Actions</div>
      <form-dialog max-width="800" title="Update listings CM" @submit="submit">
        <template #activator="{ on }">
          <v-btn color="success" small v-on="on">CM update</v-btn>
        </template>
        <multi-property-picker
          v-model="listing_ids"
          :custom-listings="filteredFeaturedListings"
        />
        <v-combobox
          v-model="select"
          :items="items"
          label="Attributes"
          multiple
          :rules="[required]"
          outlined
          dense
        />

        <listing-cancellation-policy
          v-if="showCancellationPolicy"
          v-model="data.cancellation_policy"
        />

        <amenities-edit
          v-if="showAmenities"
          v-model="data.amenities"
          :is-boom="true"
          :is-channel-manager="true"
          :is-bulk-update="true"
        />
      </form-dialog>
    </v-card>
  </v-sheet>
</template>

<script>
import FormDialog from 'components/common/form-dialog.vue'
import { mapActions, mapGetters } from 'vuex'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import formRules from 'components/mixins/form-rules-mixin'
import AmenitiesEdit from 'components/listing/amenities-edit.vue'
import ListingCancellationPolicy from 'components/listing/listing-cancellation-policy.vue'

export default {
  components: {
    FormDialog,
    MultiPropertyPicker,
    AmenitiesEdit,
    ListingCancellationPolicy,
  },
  mixins: [formRules],
  props: {
    tenant: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      select: [],
      items: ['Amenities', 'Cancellation policy'],
      listing_ids: [],
      data: {
        amenities: [],
        cancellation_policy: [],
      },
    }
  },
  watch: {
    select(newVal) {
      if (!newVal.includes('Amenities')) {
        this.data.amenities = []
      }
      if (!newVal.includes('Cancellation policy')) {
        this.data.cancellation_policy = []
      }
    },
    tenant: {
      immediate: true,
      handler(newVal) {
        this.fetchListingsPerTenant(newVal)
      },
    },
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    showAmenities() {
      return this.select.includes('Amenities')
    },
    showCancellationPolicy() {
      return this.select.includes('Cancellation policy')
    },
    listingsNoScope() {
      return this.$store.state.listingsNoScope
    },
    filteredFeaturedListings() {
      const filtered = this.listingsNoScope.filter(
        listing => listing.has_ru_channel_listing
      )
      return filtered
    },
  },
  methods: {
    ...mapActions('listings', ['bulkUpdateListings']),
    submit() {
      this.bulkUpdateListings({
        listings_ids: this.listing_ids,
        items: this.items,
        tenant_id: this.tenant,
        data: this.data,
      })
    },
    async fetchListingsPerTenant(value) {
      this.$store.commit('updatelistingsNoScope', [])
      if (this.tenant) {
        this.$store.dispatch('getlistingsNoScope', {
          tenant_id: value,
        })
      }
    },
  },
}
</script>

<style></style>
