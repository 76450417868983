import axios from 'axios'

export default {
  state: {
    currentReviews: [],
    reviewsPagination: {},
    currentReview: null,
    lastFilter: 0,
  },
  mutations: {
    updateCurrentReviews(state, data) {
      state.currentReviews = data
    },
    updateCurrentReview(state, data) {
      state.currentReview = data
    },
    updateReviewsPagination(state, data) {
      state.reviewsPagination = data
    },
    updateLastFilter(state, data) {
      state.lastFilter = data
    },
  },
  getters: {
    currentReview: state => {
      return state.currentReview
    },
  },
  actions: {
    async updateReview({ dispatch }, payload) {
      try {
        await axios.patch(`/api/reviews/${payload.id}`, payload)
        dispatch('getReviews')
      } catch (err) {
        console.error(err)
      }
    },
    async approveReview(_store, id) {
      try {
        const { data } = await axios.put(`/api/reviews/${id}/approve`)
        return data
      } catch (err) {
        console.error(err)
      }
    },
    async postReply({ dispatch, commit }, payload) {
      try {
        await axios.post(`/api/reviews/${payload.id}/post-reply`, payload)
        commit('updateCurrentReview', null)
        dispatch('getReviews')
      } catch (err) {
        console.error(err)
      }
    },
    async getReviews({ commit, state }, payload) {
      try {
        commit('updateListingLoading', true)
        const filters = payload ? payload.filters : state.lastFilter
        commit('updateLastFilter', filters)
        const { data } = await axios.get('/api/reviews', { params: filters })
        commit('updateCurrentReviews', data.reviews)
        commit('updateReviewsPagination', data.pagination)
        commit('updateListingLoading', false)
        return data
      } catch (err) {
        commit('updateListingLoading', false)
        console.error(err)
        return {}
      }
    },
  },
}
