<template>
  <v-card class="overflow-y-auto">
    <v-card-title class="d-flex justify-space-between align-center">
      <div>Calendar Block</div>
      <div v-if="!isMultiUnit">
        <reservation-create-modal
          class="ml-auto"
          :pre-filled="preFilled"
          @oncreate="createReservation"
        />
      </div>
    </v-card-title>
    <v-form ref="form" @submit.prevent="save">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <multi-property-picker
              v-model="block.listings_ids"
              :rules="[required]"
              :custom-listings="listingsActiveAndDups"
              hide-details
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="4">
            <date-picker
              v-model="block.from"
              label="from"
              :rules="[required]"
              :hide-details="true"
              :allowed-dates="dateIsAllowed"
              @input="onFormChanged($event, 'from')"
            />
          </v-col>
          <v-col cols="6" md="4">
            <date-picker
              v-model="block.to"
              :allowed-dates="dateIsAllowed"
              label="to"
              :hide-details="true"
              :rules="[required]"
              @input="onFormChanged($event, 'to')"
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              v-model.number="block.minNights"
              type="number"
              label="Min Nights"
              outlined
              :hide-details="true"
              dense
              :rules="[max(365), min(1)]"
              @input="onFormChanged($event, 'min_nights')"
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-select
              v-model="block.status"
              label="Status"
              outlined
              dense
              :items="[
                { text: 'Blocked', value: 'unavailable' },
                { text: 'Available', value: 'available' },
              ]"
              :hide-details="true"
              @input="onFormChanged($event, 'status')"
            />
          </v-col>
          <v-col cols="6" md="4" class="d-flex align-center">
            <v-checkbox
              v-model="block.manualOverride"
              class="ma-0"
              hide-details
              :label="$t('Manual Override')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-checkbox
              v-model="changePrice"
              class="ma-0"
              hide-details
              :label="$t('Change price')"
            />
          </v-col>
          <template v-if="changePrice">
            <v-col v-if="activePriceType === 'price'" cols="4">
              <v-text-field
                v-model.number="block.price"
                type="number"
                :hide-details="true"
                label="Static price"
                outlined
                dense
                :rules="changePrice ? [required] : []"
                @input="onFormChanged($event, 'price')"
              />
            </v-col>
          </template>
        </v-row>

        <v-textarea
          v-model="block.note"
          outlined
          dense
          label="Note"
          :rules="block.status === 'unavailable' ? [required] : []"
          @input="onFormChanged($event, 'note')"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="info" outlined text @click="close">Cancel</v-btn>
        <v-btn color="info" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import DatePicker from 'components/form-fields/date-picker'
import FormRules from 'components/mixins/form-rules-mixin'
import ReservationCreateModal from 'components/reservation/reservation-create-modal'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CalendarBlockModal',
  components: { ReservationCreateModal, DatePicker, MultiPropertyPicker },
  mixins: [FormRules],
  props: [
    'from',
    'to',
    'note',
    'listingId',
    'minNights',
    'status',
    'onChange',
    'onReservationCreate',
    'isMultiUnit',
    'rates',
  ],
  data() {
    return {
      block: {
        from: this.from,
        to: this.to,
        note: this.note,
        minNights: this.minNights,
        status: this.status,
        manualOverride: this.rates[this.from].manual_override,
        listings_ids: [this.listingId],
      },
      activePriceType: 'price',
      changePrice: false,
      preFilled: {
        range: [this.from, this.to],
        listingId: this.listingId,
      },
    }
  },
  watch: {
    changePrice(newValue) {
      if (!newValue) this.block.price = null
    },
    activePriceType(newValue, oldValue) {
      if (newValue !== oldValue) this.block.price = null
    },
  },
  computed: {
    ...mapGetters(['listingsActiveAndDups']),
  },
  methods: {
    close() {
      this.$store.commit('hideModal')
    },
    dateIsAllowed(date) {
      return (
        this.rates[date] &&
        this.rates[date].status !== 'booked' &&
        this.rates[date].block_type !== 'smart_rules'
      )
    },
    createReservation(res_id) {
      this.onReservationCreate(res_id)
      this.close()
    },
    async save() {
      if (this.$refs.form.validate()) {
        try {
          this.$store.commit('updateLoading', true)
          const rates = await this.$store.dispatch(
            'listingCalendar/blockCalendarBatch',
            {
              ...this.block,
            }
          )
          this.$store.commit('updateLoading', false)
          this.onChange(rates)
          this.close()
        } catch (error) {
          this.$store.commit('updateLoading', false)
          console.error('Failed to block calendar:', error)
        }
      }
    },
    onFormChanged(_val, key) {
      if (key === 'min_nights' || key === 'price')
        this.block.manualOverride = true
    },
  },
}
</script>

<style scoped></style>
