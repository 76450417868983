<template>
  <div v-if="section" class="taskim-section">
    <div class="d-flex align-center">
      <v-btn v-if="!isDynamicSection" icon @click="collapseToggle">
        <v-icon v-if="collapsed" color="grey lighten-1">
          mdi-chevron-right
        </v-icon>
        <v-progress-circular
          v-else-if="tasksFetching"
          size="18"
          indeterminate
          color="primary"
        />
        <v-icon v-else color="grey lighten-1">mdi-chevron-down</v-icon>
      </v-btn>
      <inline-text-field
        :disabled="isDynamicSection"
        :value.sync="section.name"
        @change="onSectionNameChanged"
      />
      <lt-taskim-btn
        v-if="multiSelected && selectedListings.length"
        :listing-tasks-ids="selectedListings.map(i => i.id)"
        :small="true"
        :move-from-section-id="section.id"
      />
      <v-spacer />
      <div v-if="!isMobile" class="mx-2 text-body-2">({{ totalHours }})</div>
      <div v-if="!isMobile" class="mx-2 text-body-2">
        ({{ totalTasksText }})
      </div>
      <v-btn
        v-if="boardId && $listeners['order-up'] && !isDynamicSection"
        icon
        :disabled="orderUpDisabled"
        :class="{ 'opacity-50': orderUpDisabled }"
        @click="$emit('order-up')"
      >
        <v-icon small color="grey lighten-1">mdi-arrow-up-bold</v-icon>
      </v-btn>
      <v-btn
        v-if="boardId && $listeners['order-down'] && !isDynamicSection"
        icon
        :disabled="orderDownDisabled"
        :class="{ 'opacity-50': orderDownDisabled }"
        @click="$emit('order-down')"
      >
        <v-icon small color="grey lighten-1">mdi-arrow-down-bold</v-icon>
      </v-btn>
      <ConfirmationModal
        v-if="
          !isDynamicSection &&
          !projectId &&
          ![82, 164, 165, 166].includes(section.id)
        "
        :text="`Are you sure you want to delete this section ?`"
        @action="onDeleteSection(section.id)"
      >
        <template #activator="{ on }">
          <v-btn small icon v-on="on">
            <v-icon small color="grey lighten-1">mdi-delete-forever</v-icon>
          </v-btn>
        </template>
      </ConfirmationModal>
      <v-btn
        v-if="!isMobile"
        small
        icon
        @click="multiSelected = !multiSelected"
      >
        <v-icon small :color="multiSelected ? 'primary' : 'grey lighten-1'"
          >mdi-checkbox-multiple-marked</v-icon
        >
      </v-btn>
    </div>
    <v-divider v-if="!isDynamicSection && collapsed" />
    <v-expand-transition>
      <div v-if="!(!isDynamicSection && collapsed)" class="taskim-section">
        <div class="taskim-section-table-container">
          <taskim-section-mobile
            v-if="isMobile"
            :items="tableTasks"
            :active-users="activeUsers"
            :users-map="usersMap"
            @listingTaskFieldChange="listingTaskFieldChange"
            @show-feed="showFeed"
            @lt-status-update="stateTaskimUpdateTask"
            @lt-after-change="setTaskFromCurrentListingTask"
          />
          <v-data-table
            v-else
            ref="dataTable"
            v-model="selectedListings"
            v-sortable-data-table="!isDynamicSection && !isMobile"
            :headers="headers"
            :items="tableTasks"
            :loading="tasksFetching"
            item-key="tableId"
            :hide-default-footer="!isDynamicSection"
            class="taskim-section-table"
            :item-class="itemRowClass"
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
            :options="pagination"
            :show-select="multiSelected"
            :server-items-length="pagination.itemsLength"
            :footer-props="{
              'items-per-page-options': [25, 50, 100],
            }"
            @dragged="orderOnDrag"
            @update:sort-by="sortChangedDebounce()"
            @update:sort-desc="sortChangedDebounce()"
            @pagination="$emit('pagination-changed', $event)"
          >
            <template v-if="show" #item.description="{ item }">
              <div class="d-flex">
                <v-icon
                  v-if="!isDynamicSection && !isMobile"
                  small
                  class="grab px-2"
                >
                  mdi-drag
                </v-icon>
                <taskim-text-field
                  v-if="show"
                  :class="['flex-1', { 'ml-2': isDynamicSection }]"
                  :value.sync="item.description"
                  :disabled="Boolean(projectId)"
                  @change="listingTaskFieldChange(item, 'description', $event)"
                />
                <div v-if="show" class="flex-center pl-2">
                  <v-btn color="info" icon small @click="showFeed(item)">
                    <v-icon
                      v-if="
                        item.internal_comments ||
                        (item.listing_task_comments &&
                          item.listing_task_comments.length)
                      "
                      small
                    >
                      mdi-comment-text
                    </v-icon>
                    <v-icon v-else small color="info lighten-2">
                      mdi-comment-text-outline
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </template>
            <template #item.listing_task_catalog_item_id="{ item }">
              <catalog-item-picker
                v-if="show"
                :value="item.listing_task_catalog_item_id"
                @input="
                  listingTaskFieldChange(
                    item,
                    'listing_task_catalog_item_id',
                    $event
                  )
                "
              />
            </template>
            <template #item.status="{ item }">
              <lt-status
                v-if="show"
                :small="isMobile"
                :listing-task="item"
                @after-change="setTaskFromCurrentListingTask"
                @change="stateTaskimUpdateTask({ id: item.id, ...$event })"
              />
            </template>
            <template #item.amount="{ item }">
              <div>{{ dollarFormatter(item.amount) }}</div>
            </template>
            <template #item.priority="{ item }">
              <lt-priority
                :value="item.priority"
                @input="listingTaskFieldChange(item, 'priority', $event)"
              />
            </template>
            <template #item.assigned_contractor_id="{ item }">
              <assignee-select
                v-if="show"
                :key="item.assigned_contractor_id"
                :users="activeUsers"
                :value="usersMap[item.assigned_contractor_id]"
                @change="
                  listingTaskFieldChange(
                    item,
                    'assigned_contractor_id',
                    $event.id
                  )
                "
              />
            </template>
            <template #item.total_hours="{ item }">
              <taskim-text-field
                v-if="show"
                class="flex-1 max-w-150 white text-center"
                :value.sync="item.total_hours"
                @change="listingTaskFieldChange(item, 'total_hours', $event)"
              />
            </template>
            <template #item.listing_id="{ item }">
              <listing-picker
                v-if="show"
                :value="item.listing_id"
                @input="listingTaskFieldChange(item, 'listing_id', $event)"
              />
            </template>
            <template #item.after_images="{ item }">
              <div class="d-flex align-center">
                <div class="flex-1 text-no-wrap">
                  <gallery
                    v-if="!isEmpty(item.after_images)"
                    :images="item.after_images"
                    icon-color="black"
                    :cols="12"
                    :remove-img="img => removeAfterImg(item, img)"
                    icon="mdi-paperclip"
                  />
                </div>
                <div class="flex-1">
                  <gallery
                    :images="[]"
                    icon-color="black"
                    uploadable
                    thumbnails
                    button-text=""
                    :cols="12"
                    :on-change="imgs => addAfterImg(item, imgs)"
                  />
                </div>
              </div>
            </template>
            <template #item.scheduled_at="{ item }">
              <date-picker
                v-if="show"
                :value="parseDate(item.scheduled_at)"
                :events="val => scheduleAtAllowedDates(val, item)"
                :event-color="val => eventDateColor(val, item)"
                :reactive="true"
                :min="new Date().toISOString().substr(0, 10)"
                :max="
                  new Date(new Date().setMonth(new Date().getMonth() + 10))
                    .toISOString()
                    .substr(0, 10)
                "
                :required="true"
                :dense="true"
                :hide-details="true"
                :flat="true"
                :solo="true"
                :outlined="false"
                @change="listingTaskFieldChange(item, 'scheduled_at', $event)"
              />
            </template>
            <template #item.scheduled_at_end="{ item }">
              <date-picker
                v-if="show"
                :value="parseDate(item.scheduled_at_end)"
                :events="val => scheduleAtAllowedDates(val, item)"
                :event-color="val => eventDateColor(val, item)"
                :reactive="true"
                :min="new Date().toISOString().substr(0, 10)"
                :max="
                  new Date(new Date().setMonth(new Date().getMonth() + 10))
                    .toISOString()
                    .substr(0, 10)
                "
                :required="true"
                :dense="true"
                :hide-details="true"
                :flat="true"
                :solo="true"
                :outlined="false"
                @change="
                  listingTaskFieldChange(item, 'scheduled_at_end', $event)
                "
              />
            </template>
            <template #item.created_at="{ item }">
              {{ parseDate(item.created_at) }}
            </template>
            <template #item.created_by_id="{ item }">
              {{ nameById(item.created_by_id) }}
            </template>
            <template #item.actions="{ item }">
              <taskim-task-actions
                v-if="show"
                :section="section"
                :task="item"
              />
            </template>
          </v-data-table>
        </div>
        <div v-if="!isDynamicSection" class="taskim-section-new-task-container">
          <inline-text-field
            v-if="show"
            v-model="newTask"
            :disable-debounce="true"
            placeholder="+ New Task"
            @change="onSectionNewTask"
          />
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { cloneDeep, debounce, filter, toNumber } from 'lodash'
import CommonFunctions from 'components/mixins/common_functions'
import formRules from 'components/mixins/form-rules-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import InlineTextField from 'components/inline-inputs/inline-text-field'
import ConfirmationModal from 'components/modals/confirmation-modal'
import LtStatus from 'components/listing-tasks/lt-status'
import DeviceMixin from 'components/mixins/device-mixin'
import DatePicker from 'components/form-fields/date-picker'
import AssigneeSelect from 'components/common/assignee-select'
import LtPriority from 'components/listing-tasks/lt-priority'
import CatalogItemPicker from 'components/common/catalog-item-picker'
import TaskimTaskActions from 'components/taskim/taskim-task-actions'
import ListingPicker from 'components/listing/listing-picker'
import TaskimTextField from 'components/taskim/taskim-text-field.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'
import Gallery from 'components/common/gallery.vue'
import TaskimSectionMobile from './taskim-section-mobile.vue'
import LtTaskimBtn from './lt-taskim-btn.vue'

export default {
  name: 'TaskimSection',
  components: {
    ListingPicker,
    TaskimTaskActions,
    CatalogItemPicker,
    LtPriority,
    AssigneeSelect,
    DatePicker,
    LtStatus,
    ConfirmationModal,
    InlineTextField,
    TaskimTextField,
    Gallery,
    TaskimSectionMobile,
    LtTaskimBtn,
  },
  mixins: [
    CommonFunctions,
    formRules,
    PermissionsMixin,
    FormattersMixin,
    DeviceMixin,
  ],
  props: [
    'boardId',
    'section',
    'collapsed',
    'orderUpDisabled',
    'orderDownDisabled',
    'projectId',
  ],
  data() {
    return {
      newTask: '',
      headers: [],
      show: true,
      sort: this.isDynamicSection
        ? {
            by: this.querySectionSort.by,
            desc: this.querySectionSort.dir === 'desc',
          }
        : {},
      sortChangedDebounce: debounce(this.sortChanged, 100),
      multiSelected: false,
      selectedListings: [],
    }
  },
  created() {
    this.headers = filter([
      {
        sortable: true,
        width: '400px',
        text: 'Description',
        value: 'description',
        cellClass: 'pl-0 pr-1',
      },
      !this.projectId && {
        sortable: true,
        width: '100px',
        text: 'Listing',
        value: 'listing_id',
      },
      { sortable: true, width: '150px', text: 'Status', value: 'status' },
      !this.projectId && {
        sortable: true,
        width: '100px',
        text: 'Priority',
        value: 'priority',
        sort: (a, b) => this.priorityOrder(a) - this.priorityOrder(b),
      },
      !this.projectId &&
        !this.isMobile && {
          sortable: true,
          width: '100px',
          text: 'Catalog',
          value: 'listing_task_catalog_item_id',
        },
      {
        sortable: true,
        width: '100px',
        text: 'Assigned To',
        value: 'assigned_contractor_id',
      },
      !this.isMobile && {
        sortable: true,
        width: '110px',
        text: 'Media',
        value: 'after_images',
      },
      !this.isMobile && {
        sortable: true,
        width: '150px',
        text: 'Start date',
        value: 'scheduled_at',
      },
      !this.isMobile && {
        sortable: true,
        width: '150px',
        text: 'End date',
        value: 'scheduled_at_end',
      },
      this.isAdmin &&
        !this.isMobile && {
          sortable: true,
          width: '100px',
          text: 'Hours',
          value: 'total_hours',
        },
      this.projectId &&
        this.isAdmin && {
          sortable: true,
          width: '100px',
          text: 'Price',
          value: 'amount',
        },
      !this.projectId &&
        !this.isMobile && {
          sortable: true,
          width: '120px',
          text: 'Created By',
          value: 'created_by_id',
        },
      !this.projectId &&
        !this.isMobile && {
          sortable: true,
          width: '115px',
          text: 'Created At',
          value: 'created_at',
        },
      !this.isMobile && {
        sortable: false,
        width: '100px',
        text: 'Actions',
        value: 'actions',
      },
    ])
  },
  watch: {
    collapsed() {
      this.multiSelected = false
      this.selectedListings = []
    },
  },
  computed: {
    ...mapState('taskim', ['querySectionSort', 'tasks', 'tasksFetching']),
    ...mapGetters('users', ['activeUsers', 'usersMap']),
    ...mapGetters(['currentListingTask']),
    isDynamicSection() {
      return this.section.id === 'query'
    },
    tableTasks() {
      return this.tasks.map((task, i) => ({
        ...task,
        tableId: `${task.id} ${i}`,
      }))
    },
    feedOpenId() {
      return this.$route.query.feed_id && this.currentListingTask
        ? toNumber(this.$route.query.feed_id)
        : null
    },
    pagination() {
      return this.section.pagi_info
        ? {
            page: this.section.pagi_info.page,
            itemsPerPage: this.section.pagi_info.per_page,
            itemsLength: this.section.pagi_info.count,
          }
        : {
            page: 1,
            itemsPerPage: -1,
            itemsLength: undefined,
          }
    },
    totalHours() {
      return `${this.section.tasks_time} hours`
    },
    totalTasksText() {
      const count = this.section.pagi_info
        ? this.section.pagi_info.count
        : this.section.tasks_count
      if (this.section.pagi_info && !count) return 'No results'
      if (this.section.pagi_info && count === 1) return '1 result'
      if (this.section.pagi_info && count > 1) return count + ' results'
      if (!count) return 'Empty'
      if (count === 1) return '1 task'
      return count + ' tasks'
    },
  },
  methods: {
    ...mapActions('taskim', [
      'updateSection',
      'deleteSection',
      'createSectionTask',
      'updateSectionTask',
      'setTaskFromCurrentListingTask',
      'stateTaskimRemoveSection',
      'stateTaskimUpdateTask',
      'getSectionTasks',
    ]),
    ...mapMutations('taskim', ['setTasks']),
    ...mapActions(['updateListingTask']),
    ...mapMutations(['updateCurrentListingTask']),
    onSectionNameChanged(name) {
      if (name) {
        const section = cloneDeep(this.section)
        section.name = name
        this.updateSection(section)
      }
    },
    async onDeleteSection(id) {
      this.stateTaskimRemoveSection(id)
      await this.deleteSection(id)
    },
    async onSectionNewTask(description) {
      if (description) {
        await this.createSectionTask({
          sectionId: this.section.id,
          description,
        })
        this.newTask = ''
      }
    },
    async listingTaskFieldChange(listingTask, key, value) {
      const payload = {
        id: listingTask.id,
        [key]: value,
      }
      this.stateTaskimUpdateTask(payload)
      await this.updateListingTask(payload)
      await this.setTaskFromCurrentListingTask()
    },

    scheduleAtAllowedDates(val, listingTask) {
      const parsedDate = this.$moment(val).utc().add(1, 'days')
      const timeNow = this.$moment().utc()
      const house_vacant_dates =
        listingTask.house_vacant_dates ||
        (listingTask.extra_data && listingTask.extra_data.house_vacant_dates) ||
        {}
      const house_check_in_dates =
        listingTask.house_check_in_dates ||
        (listingTask.extra_data &&
          listingTask.extra_data.house_check_in_dates) ||
        {}
      const house_check_out_dates =
        listingTask.house_check_out_dates ||
        (listingTask.extra_data &&
          listingTask.extra_data.house_check_out_dates) ||
        {}
      return Boolean(
        timeNow <= parsedDate &&
          (!house_vacant_dates[val] ||
            house_check_in_dates[val] ||
            house_check_out_dates[val])
      )
    },
    showFeed(item) {
      this.updateCurrentListingTask(item)
      setTimeout(() => {
        this.$router.push({
          query:
            this.feedOpenId && this.feedOpenId === item.id
              ? {}
              : { feed_id: item.id },
        })
      }, 1)
    },
    itemRowClass(item) {
      return item.id === this.feedOpenId ? 'primary' : ''
    },
    async orderOnDrag(event) {
      const tasks = this.$refs.dataTable.internalCurrentItems
      const task = tasks[event.oldIndex]
      const newIndex =
        event.oldIndex < event.newIndex ? event.newIndex : event.newIndex - 1
      const afterTask = tasks[newIndex]
      const tasksClone = cloneDeep(this.tasks)
      const element = tasksClone.splice(event.oldIndex || 0, 1)[0]
      tasksClone.splice(event.newIndex || 0, 0, element)
      this.setTasks(tasksClone)
      await this.updateSectionTask({
        sectionId: this.section.id,
        taskId: task.id,
        after_task_id: afterTask ? afterTask.id : 0,
      })
    },
    collapseToggle() {
      this.$emit('update:collapsed', !this.collapsed)
      if (this.collapsed) {
        this.getSectionTasks(this.section.id)
      }
    },
    sortChanged() {
      if (this.isDynamicSection) {
        this.$emit('server-sort-changed', {
          by: this.sort.by,
          dir: this.sort.desc ? 'desc' : 'asc',
        })
      }
    },
    addAfterImg(item, imgs) {
      const newImgs = item.after_images.concat(imgs)
      this.listingTaskFieldChange(item, 'after_images', newImgs)
    },
    removeAfterImg(item, img) {
      const newImgs = item.after_images.filter(i => i !== img)
      this.listingTaskFieldChange(item, 'after_images', newImgs)
    },
  },
}
</script>
<style scoped>
.taskim-section {
  display: flex;
  flex-direction: column;
}

.taskim-section-table-container {
  border-radius: 3px 3px 0 0;
  border: 1px solid lightgrey;
  overflow-x: auto;
  width: 100%;
}

.taskim-section-new-task-container {
  border-radius: 0 0 3px 3px;
  border: 1px solid lightgrey;
  border-top: 0;
  padding: 2px;
  position: relative;
}

>>> .taskim-section-table table > thead > tr > th:nth-child(1),
>>> .taskim-section-table table > tbody > tr > td:nth-child(1) {
  position: sticky !important;
  left: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.93);
  box-shadow: inset -1px 0 0 0 lightgrey;
}

>>> .taskim-section-table table td + td,
>>> .taskim-section-table table th + th {
  border-left: 1px solid lightgrey;
}

>>> .taskim-section-table table td,
>>> .taskim-section-table table th {
  max-width: 400px;
}
:deep() .v-simple-checkbox {
  transform: scale(0.8);
}
</style>
