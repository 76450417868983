<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.917"
    height="24"
    viewBox="0 0 27.917 24"
  >
    <g transform="translate(0 -28.588)">
      <g transform="translate(0 28.588)">
        <g transform="translate(0 0)">
          <path
            class="a"
            d="M27.878,42.169h0L22.607,28.934a.548.548,0,0,0-.712-.307h0L.511,37.142a.537.537,0,0,0-.219.174A.542.542,0,0,0,0,37.792V52.04a.548.548,0,0,0,.548.548H23.565a.548.548,0,0,0,.548-.548V44.259l3.459-1.378A.548.548,0,0,0,27.878,42.169Zm-4.861,9.324H1.1V38.34H23.017ZM9.144,37.247,18.32,33.59a4.36,4.36,0,0,0,3.142,1.355l.915,2.3Zm14.969,5.835V37.792a.548.548,0,0,0-.548-.548h-.009l-1.161-2.917a.454.454,0,0,0-.045-.069.541.541,0,0,0-.573-.422A3.277,3.277,0,0,1,18.9,32.6a.543.543,0,0,0-.7-.123.616.616,0,0,0-.077.014l-11.2,4.461a.541.541,0,0,0-.3.293h-3.4l18.573-7.4,4.866,12.218Z"
            transform="translate(0 -28.588)"
          />
          <path
            class="a"
            d="M32.391,195.3a3.277,3.277,0,0,1,2.212,2.21.548.548,0,0,0,.525.391.5.5,0,0,0,.084-.013.512.512,0,0,0,.075.015H48.44a.473.473,0,0,0,.067-.014.51.51,0,0,0,.616-.375v0a3.276,3.276,0,0,1,2.212-2.212.541.541,0,0,0,.376-.6.553.553,0,0,0,.016-.081v-4.384a.5.5,0,0,0-.016-.081.54.54,0,0,0-.376-.6,3.273,3.273,0,0,1-2.211-2.213.541.541,0,0,0-.607-.376.521.521,0,0,0-.077-.015H35.288a.517.517,0,0,0-.082.016.541.541,0,0,0-.6.376,3.277,3.277,0,0,1-2.212,2.212.541.541,0,0,0-.376.6.552.552,0,0,0-.015.081v4.384a.505.505,0,0,0,.015.076A.543.543,0,0,0,32.391,195.3Zm.7-4.846a4.349,4.349,0,0,0,2.415-2.415H48.217a4.35,4.35,0,0,0,2.415,2.415v3.939a4.354,4.354,0,0,0-2.415,2.415H35.511a4.354,4.354,0,0,0-2.415-2.415Z"
            transform="translate(-29.808 -176.097)"
          />
          <path
            class="a"
            d="M131.288,225.521A3.288,3.288,0,1,0,128,222.233,3.288,3.288,0,0,0,131.288,225.521Zm0-5.48a2.192,2.192,0,1,1-2.192,2.192A2.192,2.192,0,0,1,131.288,220.041Z"
            transform="translate(-119.232 -205.905)"
          />
          <path
            class="a"
            d="M76.822,256.589a.822.822,0,1,0-.822-.822A.822.822,0,0,0,76.822,256.589Zm0-1.1a.274.274,0,1,1-.274.274A.274.274,0,0,1,76.822,255.493Z"
            transform="translate(-70.794 -239.439)"
          />
          <path
            class="a"
            d="M252.822,256.589a.822.822,0,1,0-.822-.822A.822.822,0,0,0,252.822,256.589Zm0-1.1a.274.274,0,1,1-.274.274A.274.274,0,0,1,252.822,255.493Z"
            transform="translate(-234.738 -239.439)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Money',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
