import axios from '@/axios/config'

class ExpoNotifications {
  static dashboardPush(params) {
    if (screen.width <= 960) {
      return axios.post('/api/notifications/expo', params)
    }
  }
}

export default ExpoNotifications
