<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40.96"
    height="28"
    viewBox="0 0 40.96 28"
  >
    <g id="rating" transform="translate(0 -81)">
      <path
        id="Path_7026"
        data-name="Path 7026"
        d="M242.007,214.87l-.1-.2-.1.2a1.2,1.2,0,0,1-.9.656l-.222.032.161.157a1.2,1.2,0,0,1,.345,1.062l-.038.222.2-.1a1.2,1.2,0,0,1,1.117,0l.2.1-.038-.222a1.2,1.2,0,0,1,.345-1.062l.161-.157-.222-.032A1.2,1.2,0,0,1,242.007,214.87Z"
        transform="translate(-221.427 -122.975)"
        fill="rgba(74,140,140,0.2)"
      />
      <path
        id="Path_7027"
        data-name="Path 7027"
        d="M99.56,214.871l-.1-.2-.1.2a1.2,1.2,0,0,1-.9.656l-.222.032.161.157a1.2,1.2,0,0,1,.345,1.062L98.7,217l.2-.1a1.2,1.2,0,0,1,1.117,0l.2.1-.038-.222a1.2,1.2,0,0,1,.345-1.062l.161-.157-.222-.032A1.2,1.2,0,0,1,99.56,214.871Z"
        transform="translate(-90.376 -122.975)"
        fill="rgba(74,140,140,0.2)"
      />
      <path
        id="Path_7028"
        data-name="Path 7028"
        d="M384.454,214.871l-.1-.2-.1.2a1.2,1.2,0,0,1-.9.656l-.222.032.161.157a1.2,1.2,0,0,1,.345,1.062L383.6,217l.2-.1a1.2,1.2,0,0,1,1.117,0l.2.1-.038-.222a1.2,1.2,0,0,1,.345-1.062l.161-.157-.223-.032A1.2,1.2,0,0,1,384.454,214.871Z"
        transform="translate(-352.479 -122.975)"
        fill="rgba(74,140,140,0.2)"
      />
      <path
        id="Path_7029"
        data-name="Path 7029"
        d="M36.56,81H4.4A4.4,4.4,0,0,0,0,85.4V99.8a4.4,4.4,0,0,0,4.4,4.4H16.638l2.844,4.266a1.2,1.2,0,0,0,2,0l2.844-4.266H36.56a4.4,4.4,0,0,0,4.4-4.4V85.4A4.4,4.4,0,0,0,36.56,81ZM13.726,92.605l-1.45,1.414.342,2a1.2,1.2,0,0,1-1.741,1.265l-1.793-.942-1.793.942A1.2,1.2,0,0,1,5.55,96.015l.342-2-1.45-1.414a1.2,1.2,0,0,1,.665-2.047l2-.291.9-1.816a1.2,1.2,0,0,1,2.152,0l.9,1.816,2,.291a1.2,1.2,0,0,1,.665,2.047Zm11.4,0-1.45,1.414.342,2a1.2,1.2,0,0,1-1.741,1.265l-1.793-.943-1.793.942a1.2,1.2,0,0,1-1.741-1.265l.342-2-1.45-1.414a1.2,1.2,0,0,1,.665-2.047l2-.291.9-1.816a1.2,1.2,0,0,1,2.152,0l.9,1.816,2,.291a1.2,1.2,0,0,1,.665,2.047Zm11.4,0-1.45,1.414.342,2a1.2,1.2,0,0,1-1.741,1.265l-1.793-.942-1.793.942a1.2,1.2,0,0,1-1.741-1.265l.342-2-1.45-1.414a1.2,1.2,0,0,1,.665-2.047l2-.291.9-1.816a1.2,1.2,0,0,1,2.152,0l.9,1.816,2,.291a1.2,1.2,0,0,1,.665,2.047Z"
        fill="rgba(74,140,140,0.2)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Rating',
}
</script>

<style scoped></style>
