import axios from 'axios'
import { keyBy } from 'lodash'

export default {
  state: {
    listingTaskCatalogItems: [],
    catalogItemSelect: [],
  },
  mutations: {
    updateCatalogItemSelect(state, data) {
      state.catalogItemSelect = data
    },
    updateListingTaskCatalogItems(state, data) {
      state.listingTaskCatalogItems = data
    },
  },
  actions: {
    async getListingTaskCatalogItems({ commit, dispatch }) {
      try {
        const { data } = await axios.get(`/api/listing-task-catalog-items`)
        commit('updateListingTaskCatalogItems', data.payload.data.items)
        commit('updateListingTaskSubTopics', data.payload.data.sub_topics)
        dispatch('getListingTaskCatalogItemsSelect')
      } catch (err) {
        console.error(err)
      }
    },
    async getListingTaskCatalogItemsSelect({ commit }) {
      try {
        const { data } = await axios.get(
          `/api/listing-task-catalog-items-options`
        )
        commit('updateCatalogItemSelect', data)
      } catch (err) {
        console.error(err)
      }
    },
    async updateListingTaskCatalogItem({ dispatch }, { id, payload }) {
      try {
        const { data } = await axios.patch(
          `/api/listing-task-catalog-items/${id}`,
          payload
        )
        dispatch('getListingTaskCatalogItems')
        return data
      } catch (error) {
        this.$store.commit('updateListingLoading', false)
      }
    },
    async createListingTaskCatalogItem({ dispatch }, payload) {
      try {
        await axios.post(`/api/listing-task-catalog-items/`, payload)
        dispatch('getListingTaskCatalogItems')
      } catch (error) {
        console.error(error)
      }
    },
    async archiveCatalogItem({ dispatch }, id) {
      try {
        await axios.delete(`/api/listing-task-catalog-items/${id}`)
        dispatch('getListingTaskCatalogItems')
      } catch (error) {
        console.log(error)
      }
    },
    async restoreCatalogItem({ dispatch }, id) {
      try {
        await axios.patch(`/api/listing-task-catalog-items/${id}/restore`)
        dispatch('getListingTaskCatalogItems')
      } catch (error) {
        console.log(error)
      }
    },
  },
  getters: {
    catalogItems(state) {
      return state.listingTaskCatalogItems
    },
    catalogItemsMap(state) {
      return keyBy(state.listingTaskCatalogItems, 'id')
    },
  },
}
