<template>
  <div>
    <h1>Digital Checkin</h1>
    <v-row>
      <v-col cols="12" md="2">
        <v-btn-toggle
          v-model="selectedSettings"
          mandatory
          class="flex-column d-flex"
          color="primary"
        >
          <v-btn value="specialRequests">Special Requests</v-btn>
          <v-btn value="storeConfig">Store Config</v-btn>
          <v-btn value="checkinInfo">Check-in</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" md="10">
        <div v-if="selectedSettings === 'storeConfig'">
          <v-row class="align-center">
            <v-col cols="auto" class="d-flex align-center">
              <v-text-field
                v-model="searchTerm"
                outlined
                hide-details
                hide
                prepend-inner-icon="search"
                label="Search"
              />
            </v-col>
            <v-col class="d-flex flex-grow-1 justify-end align-center">
              <edit-store-item>
                <template #customBtn="{ on }">
                  <v-btn
                    class="white--text me-9"
                    color="blue"
                    large
                    depressed
                    v-on="on"
                  >
                    <v-icon class="mr-2"> mdi-plus </v-icon>New Store Item
                  </v-btn>
                </template>
              </edit-store-item>
              <store-toggle
                v-if="config"
                :config="config"
                @change="saveStoreSupport"
              />
            </v-col>
          </v-row>
          <v-row class="justify-start">
            <v-col
              v-for="item in items"
              :key="item.id"
              cols="12"
              sm="auto"
              lg="4"
              xl="3"
            >
              <store-item-thumbnail :item="item" />
            </v-col>
          </v-row>
        </div>
        <special-requests
          v-if="checkinSettings && selectedSettings === 'specialRequests'"
          :special-requests="checkinSettings.special_requests"
          :original-special-requests="originalCheckinSettings.special_requests"
          :loading="loading"
          @save="saveCheckinSettings"
        />
        <checkin-info
          v-if="checkinSettings && selectedSettings === 'checkinInfo'"
          :checkin-info="checkinSettings.checkin_info || {}"
          :original-checkin-info="originalCheckinSettings.checkin_info"
          :loading="loading"
          @save="saveCheckinSettings"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SpecialRequests from 'components/guests/special-requests.vue'
import CheckinInfo from 'components/guests/checkin-info.vue'
import StoreItemThumbnail from 'components/store/store-item-thumbnail.vue'
import { mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import StoreToggle from 'components/guests/store-toggle.vue'
import EditStoreItem from 'components/store/edit-store-item'

export default {
  components: {
    SpecialRequests,
    CheckinInfo,
    StoreItemThumbnail,
    StoreToggle,
    EditStoreItem,
  },
  data() {
    return {
      selectedSettings: 'storeConfig',
      searchTerm: null,
      checkinSettings: null,
      originalCheckinSettings: null,
      loading: false,
    }
  },
  computed: {
    items() {
      const items = this.$store.getters.storeItems
      if (this.searchTerm) {
        return items.filter(i => i.name.includes(this.searchTerm))
      }
      return items
    },
    config() {
      return this.$store.state.app.configuration
    },
  },
  async mounted() {
    this.fetchCheckinSettings()
    await this.$store.dispatch('getStoreTopics')
    await this.$store.dispatch('getStoreSubTopics')
    await this.$store.dispatch('stripe/getStripeAccounts')
    this.$store.dispatch('getStoreItems')
  },
  methods: {
    ...mapActions('settings', ['updateCheckinSettings', 'getCheckinSettings']),
    ...mapActions(['updateSettings']),
    async fetchCheckinSettings() {
      this.loading = true
      const { data } = await this.getCheckinSettings()
      this.checkinSettings = data
      this.originalCheckinSettings = cloneDeep(data)
      this.loading = false
    },
    async saveCheckinSettings(settings) {
      const { data } = await this.updateCheckinSettings({ ...settings })
      this.checkinSettings = data
      this.originalCheckinSettings = cloneDeep(data)
    },

    saveStoreSupport(val) {
      this.updateSettings({ store_support: val })
    },
  },
}
</script>

<style>
.text-field-label {
  font-size: 16 !important;
}
</style>
