import axios from '@/axios/config'
import { keyBy } from 'lodash/fp'
import { findIndex } from 'lodash'

export default {
  namespaced: true,
  state: {
    groupsFetching: false,
    groupsFetched: false,
    groups: [],
    questionsFetching: false,
    questions: [],
  },
  mutations: {
    setGroupsFetching(state, value) {
      state.groupsFetching = value
    },
    setGroupsFetched(state, value) {
      state.groupsFetched = value
    },
    setGroups(state, payload) {
      state.groups = payload
    },
    setQuestionsFetching(state, value) {
      state.questionsFetching = value
    },
    setQuestions(state, payload) {
      state.questions = payload
    },
  },
  actions: {
    async getEstimates({ commit }, params) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .get(`/api/estimates`, { params })
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async createEstimate({ commit }, data) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(`/api/estimates`, data)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async getEstimate({ commit }, id) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .get(`/api/estimates/${id}`)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async updateEstimate({ commit }, estimate) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(`/api/estimates/${estimate.id}`, estimate)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async deleteEstimate({ commit }, id) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .delete(`/api/estimates/${id}`)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async approveEstimate({ commit }, estimateId) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(`/api/estimates/${estimateId}/approve`)
        .then(res => res.data)
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async getGroups({ commit }, { division, withQuestions = false }) {
      commit('updateHeaderProgressBar', true, { root: true })
      commit('setGroupsFetching', true)
      const params = { division }
      if (withQuestions) params.with_questions = true
      return axios
        .get(`/api/estimates/groups`, { params })
        .then(res => {
          commit('setGroups', res.data)
          commit('setGroupsFetched', true)
          return res.data
        })
        .finally(() => {
          commit('setGroupsFetching', false)
          commit('updateHeaderProgressBar', false, { root: true })
        })
    },
    async createGroup({ commit }, data) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios.post(`/api/estimates/groups`, data).then(res => res.data)
    },
    async updateGroup({ commit, dispatch }, group) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(`/api/estimates/groups/${group.id}`, group)
        .then(res => {
          dispatch('setGroup', res.data)
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async deleteGroup({ commit, dispatch }, id) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .delete(`/api/estimates/groups/${id}`)
        .then(res => {
          dispatch('removeGroup', id)
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    removeGroup({ state, commit }, id) {
      const groups = [...state.groups]
      const groupIndex = findIndex(groups, { id })
      if (groupIndex !== -1) {
        groups.splice(groupIndex, 1)
        commit('setGroups', groups)
      }
    },
    setGroup({ state, commit }, payload) {
      const groups = [...state.groups]
      const groupIndex = findIndex(groups, { id: payload.id })
      if (groupIndex !== -1) {
        groups[groupIndex] = payload
        commit('setGroups', groups)
      }
    },
    async getGroupQuestions({ commit }, { id, bg = false }) {
      commit('updateHeaderProgressBar', true, { root: true })
      !bg && commit('setQuestionsFetching', true)
      return axios
        .get(`/api/estimates/groups/${id}/questions`)
        .then(res => {
          commit('setQuestions', res.data)
          return res.data
        })
        .finally(() => {
          !bg && commit('setQuestionsFetching', false)
          commit('updateHeaderProgressBar', false, { root: true })
        })
    },
    async createQuestion({ commit }, { groupId, question }) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(`/api/estimates/groups/${groupId}/questions`, question)
        .then(res => res.data)
    },
    async updateQuestion({ commit, dispatch }, { groupId, question }) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .post(
          `/api/estimates/groups/${groupId}/questions/${question.id}`,
          question
        )
        .then(res => {
          dispatch('setQuestion', res.data)
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    async deleteQuestion({ commit, dispatch }, { groupId, questionId }) {
      commit('updateHeaderProgressBar', true, { root: true })
      return axios
        .delete(`/api/estimates/groups/${groupId}/questions/${questionId}`)
        .then(res => {
          dispatch('removeQuestion', questionId)
          return res.data
        })
        .finally(() => commit('updateHeaderProgressBar', false, { root: true }))
    },
    removeQuestion({ state, commit }, id) {
      const questions = [...state.questions]
      const questionIndex = findIndex(questions, { id })
      if (questionIndex !== -1) {
        questions.splice(questionIndex, 1)
        commit('setQuestions', questions)
      }
    },
    setQuestion({ state, commit }, payload) {
      const questions = [...state.questions]
      const questionIndex = findIndex(questions, { id: payload.id })
      if (questionIndex !== -1) {
        questions[questionIndex] = payload
        commit('setQuestions', questions)
      }
    },
    setQuestionsOrder({ state, commit }, { questionId, afterQuestionId }) {
      const questions = [...state.questions]
      const questionIndex = findIndex(questions, { id: questionId })
      const afterQuestionIndex = findIndex(questions, {
        id: afterQuestionId,
      })
      if (questionIndex !== -1) {
        const question = questions[questionIndex]
        questions.splice(questionIndex, 1)
        const newIndex =
          questionIndex < afterQuestionIndex
            ? afterQuestionIndex
            : afterQuestionIndex + 1
        questions.splice(newIndex, 0, question)
      }
      commit('setQuestions', questions)
    },
  },
  getters: {
    groupsMap(state) {
      return keyBy('id', state.groups)
    },
    questionsMap(state) {
      return keyBy('id', state.questions)
    },
  },
}
