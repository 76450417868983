<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m505.262 193.198c-3.727-28.768-28.403-51.063-58.194-51.063-1.823 0-3.642.084-5.448.251-10.422-38.462-47.094-66.346-89.339-66.346-26.107 0-51.466 10.57-69.337 28.6-6.359-2.553-13.188-3.881-20.11-3.881-25.44 0-46.916 17.624-52.489 41.563-22.764 1.75-41.767 15.616-49.777 36.144h-55.319c-10.721 0-20.14 5.679-25.408 14.187h-69.841c-5.523 0-10 4.478-10 10v99.591c0 43.419 33.227 79.225 75.589 83.323-2.285 4.647-3.57 9.872-3.57 15.391 0 19.299 15.701 35 35 35s35-15.701 35-35c0-5.365-1.214-10.451-3.38-15h234.723c-2.167 4.549-3.38 9.635-3.38 15 0 19.299 15.701 35 35 35s35-15.701 35-35c0-5.519-1.285-10.744-3.57-15.391 42.361-4.098 75.588-39.903 75.588-83.323v-99.591c0-4.38-2.817-8.102-6.738-9.455zm-265.478 19.455h252.216v29.442h-252.216zm-24.529-50.517c.986 0 1.991.038 2.988.114 2.684.199 5.338-.684 7.36-2.463 2.022-1.778 3.241-4.296 3.382-6.985.942-17.968 15.81-32.042 33.848-32.042 6.121 0 12.115 1.644 17.335 4.754 4.23 2.519 9.668 1.585 12.813-2.204 14.174-17.074 36.342-27.269 59.3-27.269 35.997 0 66.793 25.51 71.635 59.337.409 2.854 2.029 5.393 4.445 6.965 2.417 1.573 5.396 2.024 8.169 1.243 3.41-.963 6.956-1.45 10.538-1.45 18.543 0 34.08 13.099 37.822 30.518h-249.56c-5.268-8.508-14.687-14.187-25.408-14.187h-26.856c6.924-10.093 18.72-16.331 32.189-16.331zm-110.006 36.331h104.672c5.438 0 9.862 4.424 9.862 9.861v110.485h-14.869v-17.792c0-5.522-4.477-10-10-10s-10 4.478-10 10v17.792h-17.33v-17.792c0-5.522-4.477-10-10-10s-10 4.478-10 10v17.792h-17.33v-17.792c0-5.522-4.477-10-10-10s-10 4.478-10 10v17.792h-14.867v-110.485c0-5.437 4.424-9.861 9.862-9.861zm-29.862 14.186v29.442h-55.387v-29.442zm46.632 188.306c0 8.271-6.729 15-15 15s-15-6.729-15-15 6.729-15 15-15 15 6.729 15 15zm282.962 15c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.728 15-15 15zm87.019-113.715c0 35.133-28.582 63.715-63.714 63.715h-344.572c-35.132 0-63.714-28.582-63.714-63.715v-40.148h55.387v66.718c0 5.522 4.477 10 10 10h144.397c5.523 0 10-4.478 10-10v-66.718h252.216z"
    />
  </svg>
</template>

<script>
export default {
  name: 'BathTub',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
