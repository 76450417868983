<template>
  <div>
    <div v-if="item.all" class="d-flex align-center">
      <v-progress-linear :color="color" :value="value" rounded />
      <span class="text-caption ml-2">
        {{ item.status_done }}/{{ item.all }}
      </span>
    </div>
    <span v-else class="secondary--text">{{ noStatsText }}</span>
  </div>
</template>

<script>
export default {
  name: 'ListingSetupStats',
  props: ['item', 'color', 'value', 'noStatsText'],
}
</script>

<style scoped></style>
