<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.689"
    height="24"
    viewBox="0 0 18.689 24"
  >
    <g transform="translate(-0.055 0)">
      <path
        class="a"
        d="M18.506,4.859,9.485.053a.448.448,0,0,0-.427,0L.288,4.862a.449.449,0,0,0-.233.394v6.278a13,13,0,0,0,7.792,11.9l1.217.531a.449.449,0,0,0,.357,0l1.372-.592a12.974,12.974,0,0,0,7.95-11.961V5.256A.449.449,0,0,0,18.506,4.859Zm-.66,6.55a12.078,12.078,0,0,1-7.4,11.135l0,0-1.195.516-1.038-.453A12.1,12.1,0,0,1,.953,11.534V5.522L9.277.959l8.568,4.566Zm0,0"
        transform="translate(0 0)"
      />
      <path
        class="a"
        d="M80.632,147.913a.449.449,0,0,0-.683.583l2.372,2.778a.449.449,0,0,0,.625.057l5.525-4.495a.449.449,0,0,0-.567-.7l-5.184,4.218Zm0,0"
        transform="translate(-74.668 -136.67)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Security',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
