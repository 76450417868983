<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.93 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="activity">
          <path
            id="Path_5209"
            data-name="Path 5209"
            class="cls-1"
            d="M.77,20.2H3.83a.43.43,0,0,0,.38-.44V9a.43.43,0,0,0-.38-.45H.77A.42.42,0,0,0,.39,9V19.76A.41.41,0,0,0,.77,20.2ZM1.15,9.43H3.44v9.88H1.15Z"
          />
          <path
            id="Path_5210"
            data-name="Path 5210"
            class="cls-1"
            d="M5.35,20.2H8.41a.42.42,0,0,0,.38-.45V6.29a.42.42,0,0,0-.38-.45H5.35A.43.43,0,0,0,5,6.29V19.75A.43.43,0,0,0,5.35,20.2ZM5.74,6.73H8V19.31H5.74Z"
          />
          <path
            id="Path_5211"
            data-name="Path 5211"
            class="cls-1"
            d="M9.94,20.21H13a.44.44,0,0,0,.39-.45v-9a.43.43,0,0,0-.39-.45H9.94a.42.42,0,0,0-.38.45v9A.43.43,0,0,0,9.94,20.21Zm.38-9h2.29v8.08H10.32Z"
          />
          <path
            id="Path_5212"
            data-name="Path 5212"
            class="cls-1"
            d="M14.52,20.2h3.06a.41.41,0,0,0,.38-.44V5.84a.42.42,0,0,0-.38-.45H14.52a.42.42,0,0,0-.38.45V19.75A.42.42,0,0,0,14.52,20.2ZM14.9,6.29h2.3v13H14.9Z"
          />
          <path
            id="Path_5213"
            data-name="Path 5213"
            class="cls-1"
            d="M19.11,20.2h3.05a.42.42,0,0,0,.38-.45V.45A.42.42,0,0,0,22.16,0H19.11a.43.43,0,0,0-.39.45v19.3A.43.43,0,0,0,19.11,20.2ZM19.49.9h2.29V19.31H19.49Z"
          />
          <path
            id="Path_5214"
            data-name="Path 5214"
            class="cls-1"
            d="M22.54,21.1H.39a.45.45,0,0,0-.38.52A.44.44,0,0,0,.39,22H22.54a.45.45,0,0,0,0-.9Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Activity',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
