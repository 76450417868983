<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g transform="translate(-3.375 -3.375)">
      <path
        class="a"
        d="M16.552,11.474a.793.793,0,1,1,.788.769A.77.77,0,0,1,16.552,11.474Zm.054,1.373h1.469v5.542H16.605Z"
        transform="translate(-5.969 -3.322)"
      />
      <path
        class="a"
        d="M11.375,4.452a6.92,6.92,0,1,1-4.9,2.027,6.877,6.877,0,0,1,4.9-2.027m0-1.077a8,8,0,1,0,8,8,8,8,0,0,0-8-8Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Info',
}
</script>

<style scoped>
svg {
  fill: currentColor !important;
}
</style>
