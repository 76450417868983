import { findIndex } from 'lodash'

export default {
  state: {
    currentModal: null,
    modalProps: {},
    modalMaxWidth: 1200,
    modalIsPersistent: true,
    modalIsFullscreen: false,
    snackbars: [],
  },
  mutations: {
    showModal(
      state,
      {
        name,
        props = {},
        maxWidth = 1200,
        isPersistent = true,
        fullscreen = false,
        scrollable = true,
      }
    ) {
      state.currentModal = name
      state.modalProps = props
      state.modalMaxWidth = maxWidth
      state.modalIsPersistent = isPersistent
      state.modalIsFullscreen = fullscreen
      state.modalScrollable = scrollable
    },
    hideModal(state) {
      state.currentModal = null
      state.modalProps = {}
      state.modalMaxWidth = 1200
      state.modalIsPersistent = true
      state.modalIsFullscreen = false
      state.modalScrollable = true
    },
    addSnack(state, snack) {
      state.snackbars.push(snack)
    },
    removeSnack(state, snackId) {
      const snackIndex = findIndex(
        state.snackbars,
        snack => snack.snackId === snackId
      )
      if (snackIndex !== -1) {
        state.snackbars.splice(snackIndex, 1)
      }
    },
  },
  getters: {
    currentModal(state) {
      return state.currentModal
    },
    modalProps(state) {
      return state.modalProps
    },
    modalMaxWidth(state) {
      return state.modalMaxWidth
    },
    modalIsPersistent(state) {
      return state.modalIsPersistent
    },
    snackbars(state) {
      return state.snackbars
    },
    fullscreen(state) {
      return state.modalIsFullscreen
    },
    scrollable(state) {
      return state.modalScrollable
    },
  },
}
