<template>
  <v-dialog v-model="showTemplateModal" persistent max-width="750px">
    <v-card v-if="showTemplateModal" class="service-call-create">
      <v-card-title class="py-6">
        <div class="p-relative flex text-center">
          <div>
            <h2 class="text-h6">
              <span v-if="template.id">Edit template</span>
              <span
                v-else-if="!template.id && template.create_after_template_id"
              >
                Create child template
              </span>
              <span v-else>Create template</span>

              <span v-if="template.id" class="small-text grey--text">
                / ID: {{ template.id }}
              </span>
              <span
                v-if="template.create_after_template_id"
                class="small-text grey--text"
              >
                / Parent: {{ template.create_after_template_id }}
              </span>
            </h2>
            <div class="text-body-2 grey--text">{{ flowName }}</div>
          </div>
          <v-icon class="close-button" @click="close">close</v-icon>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <v-row v-if="!isEditMode">
          <v-col class="pt-0 mb-5">
            <v-switch
              v-model="flowRepresentation"
              hide-details
              dense
              label="Flow Representation"
              class="d-inline-block pa-0 ma-0"
            />
          </v-col>
        </v-row>
        <v-row v-if="flowRepresentation">
          <v-col cols="12" sm="6">
            <v-select
              v-model="selectedDivision"
              dense
              outlined
              :items="divisions"
              clearable
              label="Choose division"
              class="listing-select"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="template.represent_flow_id"
              dense
              outlined
              :items="flowsForRepresentation"
              item-text="name"
              item-value="id"
              clearable
              label="Choose flow"
              :class="[
                'listing-select',
                { 'mb-4': !$v.template.represent_flow_id.$error },
              ]"
            />
            <p
              v-if="$v.template.represent_flow_id.$error"
              class="warning--text"
            >
              {{ $t('Required field') }}
            </p>
          </v-col>
        </v-row>
        <v-row v-else class="wrap">
          <v-col cols="12">
            <catalog-quick-select
              :value="template.listing_task_catalog_item_id"
              :filter-change="quickPickChange"
            />
            <p
              v-if="$v.template.listing_task_catalog_item_id.$error"
              class="warning--text"
            >
              {{ $t('Required field') }}
            </p>
          </v-col>
          <v-col cols="12">
            <listing-priority :value.sync="template.priority" />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="template.description"
              label="Description"
              :rows="4"
              required
              dense
              outlined
              hide-details
              :class="{ 'mb-4': !$v.template.description.$error }"
            />
            <p v-if="$v.template.description.$error" class="warning--text">
              {{ $t('Required field') }}
            </p>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="template.create_days_after"
              dense
              outlined
              hide-details
              label="Days after"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="template.assign_to_role"
              dense
              clearable
              outlined
              hide-details
              label="Role"
              :items="personalTypes"
              :disabled="!!template.assigned_contractor_id"
            />
          </v-col>
          <v-col cols="6">
            <contractor-select
              :disabled="!!template.assign_to_role"
              :value.sync="template.assigned_contractor_id"
            />
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="isLoop"
              hide-details
              label="Loop / Repeat"
              class="d-inline-block"
            />
            <v-expand-transition>
              <v-row v-show="isLoop">
                <v-col cols="10">
                  <div class="small-text">Loop condition text</div>
                  <v-textarea
                    v-model="template.loop_condition_text"
                    dense
                    outlined
                    hide-details
                    no-resize
                    rows="1"
                    placeholder="The task loop until condition"
                  />
                </v-col>
                <v-col cols="2">
                  <div class="small-text">Days after</div>
                  <v-text-field
                    v-model="template.days_after_prev_loop_iteration"
                    dense
                    outlined
                    hide-details
                    type="number"
                  />
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions class="py-4">
        <ConfirmationModal
          v-if="template.id"
          text="Are you sure you want to archive this template?"
          @action="archive"
        >
          <template #activator="{ on }">
            <v-btn color="primary" class="text-capitalize" text v-on="on">
              Archive
            </v-btn>
          </template>
        </ConfirmationModal>
        <v-spacer />
        <v-btn color="secondary" class="text-capitalize" text @click="close">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          class="text-capitalize"
          elevation="0"
          :loading="listingLoading"
          @click.native="save"
          >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import CommonFunctions from 'components/mixins/common_functions'
import ContractorSelect from 'components/contractor-select.vue'
import CatalogQuickSelect from 'components/catalog-quick-select'
import ListingPriority from 'components/listing-priority'
import ConfirmationModal from 'components/modals/confirmation-modal'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { find, get, isNumber, pick } from 'lodash'

export default {
  components: {
    ListingPriority,
    ContractorSelect,
    CatalogQuickSelect,
    ConfirmationModal,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  validations: {
    template: {
      listing_task_catalog_item_id: { required },
      description: { required },
      represent_flow_id: { required },
    },
  },
  props: ['initialFlowId'],
  data() {
    return {
      isLoop: false,
      checklist: [],
      template: this.defaultTemplate,
      flowRepresentation: false,
      selectedDivision: null,
      representationFlows: [],
    }
  },
  mounted() {
    this.template = this.editTemplateObj
    this.template.listing_task_flow_id = this.initialFlowId
    this.checklist = this.template.listing_task_catalog_item.checklist
    this.isLoop = !!this.template.loop
    if (this.template.represent_flow_id) {
      this.flowRepresentation = true
      this.selectedDivision = this.template.represent_flow.division
    }
  },
  computed: {
    ...mapState(['listingLoading']),
    ...mapGetters(['listingTaskFlows']),
    isEditMode() {
      return !!this.template.id
    },
    flowsForRepresentation() {
      return !this.selectedDivision
        ? []
        : this.representationFlows.filter(
            f =>
              f.division === this.selectedDivision &&
              f.id !== this.initialFlowId
          )
    },
    flowName() {
      return this.listingTaskFlows.filter(f => f.id === this.initialFlowId)[0]
        .name
    },
    showTemplateModal: {
      get() {
        return this.$store.state.listingTask.showTemplateModal && this.template
      },
      set(val) {
        this.$store.commit('updateShowTemplateModal', val)
      },
    },
    priority() {
      return this.template.priority
    },
    priorities() {
      return [
        { text: 'Critical', color: 'warning' },
        { text: 'High', color: 'orange' },
        { text: 'Med', color: 'indigo' },
        { text: 'Low', color: 'info' },
      ]
    },
  },
  methods: {
    close() {
      this.$store.commit('updateCurrentEditTemplate', null)
    },
    archive() {
      this.$store.dispatch('updateListingTaskTemplate', {
        id: this.template.id,
        create_after_template_id: null,
        is_active: false,
      })
      this.close()
    },
    quickPickChange(change) {
      this.template.listing_task_catalog_item_id = change.id
      this.checklist = change.checklist
      this.template.assigned_contractor_id = change.user_id

      if (!this.template.description) {
        this.template.description = change.description
      }
    },
    noErrors(keys) {
      this.$v.$touch()
      return !keys.filter(key => get(this.$v, key).$invalid).length
    },
    save() {
      if (
        (this.flowRepresentation &&
          this.noErrors(['template.represent_flow_id'])) ||
        (!this.flowRepresentation &&
          this.noErrors([
            'template.listing_task_catalog_item_id',
            'template.description',
          ]))
      ) {
        if (this.flowRepresentation) {
          const representFlow = find(this.flowsForRepresentation, {
            id: this.template.represent_flow_id,
          })
          this.template = {
            ...this.defaultTemplate,
            ...pick(this.template, [
              'id',
              'create_after_template_id',
              'listing_task_flow_id',
              'represent_flow_id',
            ]),
            description: `Represent Flow: ${representFlow.name}`,
          }
        } else {
          this.template.represent_flow_id = null
        }
        if (this.isEditMode) {
          this.$store.dispatch('updateListingTaskTemplate', this.template)
        } else {
          this.$store.dispatch('createListingTaskTemplate', this.template)
        }
        this.close()
      }
    },
  },
  watch: {
    selectedDivision(value) {
      this.$store
        .dispatch('getListingTaskFlows', {
          division: value,
          commitToStore: false,
        })
        .then(data => {
          this.representationFlows = data || []
        })
    },
    isLoop(value) {
      this.template.loop = value
      if (value) {
        this.template.loop_condition_text =
          this.template.loop_condition_text || ''
        this.template.days_after_prev_loop_iteration = isNumber(
          this.template.days_after_prev_loop_iteration
        )
          ? this.template.days_after_prev_loop_iteration
          : 1
      } else {
        this.template.loop_condition_text = null
        this.template.days_after_prev_loop_iteration = null
      }
    },
  },
}
</script>
<style lang="scss">
.service-call-create {
  .vue__time-picker {
    display: block;
    position: relative;
    font-size: 1em;
    width: 100%;
    font-family: sans-serif;
    vertical-align: middle;
    height: 40px;

    input.display-time {
      width: 100%;
      height: 100%;
      border-color: rgba(0, 0, 0, 0.87);
      border-radius: 4px;
      transition: all 0.3s;

      &:focus {
        border-color: #7ed9d9;
        border-width: 2px;
        outline: 0;
      }
    }

    .dropdown {
      width: 100%;

      .select-list {
        width: 100%;
      }
    }
  }
}
</style>
