<template>
  <v-sheet width="100%">
    <v-card class="pa-4">
      <div class="mb-4 text-h5 font-weight-bold">Invoices</div>
      <v-row>
        <v-col cols="6">
          <v-switch
            v-model="value.invoices.isActiveSpaceInvoice"
            label="Space invoices"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
}
</script>

<style></style>
