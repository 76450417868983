<template>
  <v-row class="align-center">
    <v-col cols="4">
      <v-text-field
        :value="searchTerm"
        dense
        outlined
        hide-details
        label="Search Address"
        @input="$emit('update:searchTerm', $event)"
      />
    </v-col>
    <v-col class="flex-grow-0">
      <v-btn-toggle
        color="primary"
        mandatory
        dense
        @change="$emit('toggle-listings', $event)"
      >
        <v-btn class="white black --text" :value="true"> Active </v-btn>
        <v-btn class="white black --text" :value="false"> Disabled </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col>
      <v-btn color="grey" text @click="$emit('reset-filters')">RESET</v-btn>
      <v-btn
        color="primary"
        elevation="0"
        :disabled="!searchTerm"
        @click="$emit('fetch-listings')"
        >Apply</v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ['searchTerm', 'activeField'],
}
</script>
