<template>
  <div>
    <v-card>
      <v-card-title class="grey-text bb-1">
        {{ title || 'Listing Incomes' }}
        <v-progress-circular
          v-show="loading"
          :size="20"
          class="ml-2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card-title>

      <v-data-table
        :headers="headers()"
        :items="items"
        :options.sync="pagination"
      >
        <template #item="{ item }">
          <tr>
            <td class="text-center" :class="isReconciledClass(item)">
              {{ item.amount.toLocaleString() }}
            </td>
            <td
              v-if="!investmentView"
              class="text-sm-center"
              :class="isReconciledClass(item)"
            >
              <v-icon v-if="item.bank_transaction_id" class="cyan-icon"
                >done_all</v-icon
              >
              <div v-else>
                <v-icon v-if="!item.manual_dismiss" class="purple-icon"
                  >close</v-icon
                >
                <dismiss-reason-modal
                  :dismissed="item.manual_dismiss"
                  :item="item"
                  type="ListingIncome"
                  @after-action="onDismiss"
                />
              </div>
            </td>
            <td
              v-if="!investmentView"
              class="text-sm-center"
              :class="isReconciledClass(item)"
            >
              {{ item.listing_name }}
            </td>
            <td class="text-center" :class="isReconciledClass(item)">
              {{ parseDate(item.transferred_at) }}
            </td>
            <td
              v-if="!investmentView"
              class="text-sm-center"
              :class="isReconciledClass(item)"
            >
              {{ item.should_calculate_in ? 'Yes' : 'No' }}
            </td>
            <td class="text-center" :class="isReconciledClass(item)">
              <span v-if="item.comments">{{ item.comments }}</span>
            </td>
            <td class="text-center" :class="isReconciledClass(item)">
              {{ item.income_type }}
            </td>

            <td
              v-if="!investmentView"
              class="justify-center layout px-0"
              :class="isReconciledClass(item)"
            >
              <v-icon
                v-if="isAdmin || hasAbility('super-accountant')"
                small
                style="cursor: pointer"
                :disabled="
                  canDeleteFinancialData(item.transferred_at, currentUser)
                "
                @click="deleteIncome(item)"
              >
                delete
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import CommonMixin from 'components/mixins/common_functions'
import AccountingMixin from 'components/mixins/accounting-mixin'
import DismissReasonModal from 'components/dismiss-reason-modal'
import { mapGetters } from 'vuex'

export default {
  components: { DismissReasonModal },
  mixins: [CommonMixin, AccountingMixin],
  props: ['listing', 'title', 'investmentView'],
  data: function () {
    return {
      income: null,
      pagination: {
        itemsPerPage: 10,
      },
    }
  },
  methods: {
    headers() {
      let headers = []
      headers.push({ text: 'Amount', align: 'center', sortable: false })
      if (!this.investmentView) {
        headers.push({ text: 'Reconciled', align: 'center', sortable: false })
        headers.push({ text: 'Listing', align: 'center', sortable: false })
      }
      headers.push({ text: 'Transferred At', align: 'center', sortable: false })
      if (!this.investmentView) {
        headers.push({
          text: 'Calculated in accounting',
          align: 'center',
          sortable: false,
        })
      }
      headers.push({ text: 'Comments', align: 'center', sortable: false })
      headers.push({ text: 'Income Type', align: 'center', sortable: false })

      if (!this.investmentView) {
        headers.push({ text: 'Actions', align: 'center', sortable: false })
      }
      return headers
    },
    onDismiss() {
      this.$store.dispatch('changeEditListingBG', this.listing.id)
    },
    isReconciledClass(item) {
      if (item.bank_transaction_id || item.manual_dismiss) {
        return 'green lighten-5'
      } else {
        return 'super-light-red'
      }
    },

    parseDate: function (date) {
      if (date) {
        return this.$moment(date).format('YYYY/MM/DD')
      }
    },
    deleteIncome: function (income) {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        axios
          .delete(`/api/listing-incomes/${income.id}`)
          .then(() => {
            this.$store.dispatch('changeEditListing', this.listing.id)
          })
          .catch(() => ({}))
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'isAdmin']),
    items: {
      get: function () {
        return this.$store.state.recentListingIncomes
      },
    },
    loading: function () {
      return this.$store.state.loading
    },
  },
}
</script>
