<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="24"
    viewBox="0 0 29 24"
  >
    <g transform="translate(19081 -21542)">
      <g class="a" transform="translate(-19081 21542)">
        <rect class="c" width="29" height="24" />
        <rect class="d" x="0.5" y="0.5" width="28" height="23" />
      </g>
      <g class="a" transform="translate(-19059 21542)">
        <rect class="c" width="7" height="24" />
        <rect class="d" x="0.5" y="0.5" width="6" height="23" />
      </g>
      <path
        class="b"
        d="M232.305,0V1.429h4.7l-8.066,8.066,1.01,1.01,8.066-8.066v4.7h1.429V0Z"
        transform="translate(-18903.686 21723.314) rotate(-135)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ExpandMenuIcon',
}
</script>

<style scoped>
.a,
.d {
  fill: none;
}

.a {
  stroke: currentColor;
}

.b {
  fill: currentColor;
}

.c {
  stroke: none;
}
</style>
