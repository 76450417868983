<template>
  <v-sheet class="d-flex px-4 py-2" outlined rounded>
    <v-menu
      v-model="menu"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      required
      min-width="290px"
    >
      <template #activator="{ on }">
        <div class="d-inline-flex align-center pointer" v-on="on">
          <v-icon color="accent" small>$calendar_fill</v-icon>
          <span class="secondary--text ml-2">
            {{ parseDate(dateRange.from, 'YYYY/MM/DD') || 'From' }}
          </span>
        </div>
      </template>
      <v-date-picker
        :value="dateRange.from"
        :max="
          new Date(new Date().setMonth(new Date().getMonth() + 10))
            .toISOString()
            .substr(0, 10)
        "
        min="2018-01-01"
        @change="saveFrom"
      ></v-date-picker>
    </v-menu>
    <div class="secondary--text mx-5">&mdash;</div>
    <v-menu
      v-model="menu2"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      required
      min-width="290px"
    >
      <template #activator="{ on }">
        <div class="d-inline-flex align-center pointer" v-on="on">
          <v-icon color="accent" small>$calendar_fill</v-icon>
          <span class="secondary--text ml-2">
            {{ parseDate(dateRange.to, 'YYYY/MM/DD') || 'To' }}
          </span>
        </div>
      </template>
      <v-date-picker
        :value="dateRange.to"
        :min="new Date(dateRange.from).toISOString().substr(0, 10)"
        :max="
          new Date(new Date().setMonth(new Date().getMonth() + 10))
            .toISOString()
            .substr(0, 10)
        "
        @change="saveTo"
      ></v-date-picker>
    </v-menu>
  </v-sheet>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'ContractorPaymentsDateRange',
  mixins: [CommonFunctions],
  props: ['contractorId'],
  data() {
    return {
      menu: false,
      menu2: false,
    }
  },
  computed: {
    ...mapState({
      dateRange: 'contractorPaymentsDateRange',
    }),
  },
  methods: {
    ...mapMutations({
      changeDateRange: 'setContractorPaymentsDateRange',
    }),
    saveFrom(date) {
      this.changeDateRange({
        range: 'from',
        value: date,
      })
      this.$emit('change', this.dateRange)
    },
    saveTo(date) {
      this.changeDateRange({
        range: 'to',
        value: date,
      })
      this.$emit('change', this.dateRange)
    },
  },
}
</script>

<style scoped></style>
