<template>
  <div>
    <div class="d-flex py-2 flex-column">
      <div class="d-flex justify-space-between align-start">
        <div>
          <span>{{ item.guest_full_name }}</span>
          <span
            v-if="writeAction().length > 0"
            class="secondary--text font-italic text-caption"
          >
            <span v-for="(action, idx) in writeAction()" :key="idx">
              typing..
            </span>
          </span>
        </div>
        <div class="text-no-wrap text-caption mb-2">
          {{ timeFromNow(item.last_message_from_guest) }}
        </div>
      </div>
      <div class="d-flex justify-space-between">
        <div
          v-if="item.state.lastMessage"
          class="secondary--text text-caption mb-1"
        >
          {{ item.state.lastMessage.body }}
        </div>
        <v-tooltip
          v-if="
            item.extra_data.last_message &&
            item.extra_data.last_message.by !== 'host' &&
            item.extra_data.last_message.module !== 'note'
          "
          bottom
        >
          <template #activator="{ on }">
            <v-icon small color="warning" v-on="on">report_problem</v-icon>
          </template>
          <span>Last message is from guest</span>
        </v-tooltip>
      </div>
      <div class="d-flex justify-space-between align-center text-caption">
        <div>
          <v-chip
            v-if="item.sales_status"
            class="pointer"
            color="primary"
            label
            x-small
            dark
          >
            {{ item.sales_status }}
          </v-chip>

          <v-icon
            v-if="item.have_payment_problem"
            color="error"
            class="ml-1"
            small
          >
            mdi-credit-card-remove-outline
          </v-icon>
          <v-tooltip v-if="!isEmpty(item.extra_data.warnings)" bottom>
            <template #activator="{ on }">
              <v-icon color="error" small v-on="on"> mdi-bell-alert</v-icon>
            </template>
            <div>
              <p
                v-for="(warn, index) in item.extra_data.warnings"
                :key="index"
                class="mb-0"
              >
                {{ warn.title }} &mdash; {{ warn.text }}
              </p>
            </div>
          </v-tooltip>
          <v-tooltip v-if="!item.resolved" bottom>
            <template #activator="{ on }">
              <v-icon color="error" small v-on="on">
                mdi-alert-circle-outline
              </v-icon>
            </template>
            <span>Item is not resolved</span>
          </v-tooltip>
          <Tag v-if="item.assigned_user_id" bg-color="grey" color="white">
            {{ nameById(item.assigned_user_id) }}
          </Tag>
          <v-icon v-else color="secondary" small>
            mdi-account-multiple-plus
          </v-icon>
        </div>
        <ChatAlert :item="item" />
      </div>
    </div>
    <div>
      <v-divider />
      <div class="d-flex justify-space-between py-2">
        <div>
          <div>{{ item.extra_data.listing_nickname }}</div>
          <div class="d-flex align-center text-caption">
            <span>{{ parseDate(item.extra_data.reservation_check_in) }}</span>
            <v-icon>arrow_right_alt</v-icon>
            <span>
              {{ parseDate(item.extra_data.reservation_check_out) }}
            </span>
          </div>
        </div>
        <div class="text-right">
          <div>{{ dollarFormatter(item.reservation_price) }}</div>
          <div>
            <span class="text-caption">{{
              item.extra_data.reservation_source
            }}</span>
            <v-chip
              :class="[
                resStatusColor(item.reservation_status),
                'pointer',
                'text-caption',
              ]"
              x-small
            >
              {{ item.reservation_status }}
            </v-chip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonFunctions from 'components/mixins/common_functions'
import ChatAlert from 'components/chat-alert'
import Tag from 'components/common/tag'
import { mapGetters } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ChatGuestBox',
  components: { Tag, ChatAlert },
  mixins: [commonFunctions, FormattersMixin],
  props: ['item'],
  computed: {
    ...mapGetters(['currentUserId']),
  },
  methods: {
    writeAction() {
      let res = []
      if (this.currentUserId && this.item.extra_data.actions) {
        this.item.extra_data.actions.forEach(action => {
          if (
            res.length === 0 &&
            action.user_id !== this.currentUserId &&
            action.type === 'start_writing' &&
            this.lessThenOneMinAgo(new Date(action.at))
          ) {
            res.push(action)
          }
        })
      }
      return res
    },
    lessThenOneMinAgo(date) {
      const duration = this.$moment.duration(
        this.$moment().diff(this.$moment(date))
      )
      return duration.asMinutes() < 1
    },
  },
}
</script>

<style scoped></style>
