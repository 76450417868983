<template>
  <v-sheet
    height="160"
    elevation="2"
    class="d-flex flex-column align-center rounded-lg pa-2 ba-1 p-relative"
  >
    <v-sheet width="100%" class="d-flex justify-space-between align-center">
      <div class="text-h6 font-weight-medium text-center pa-2 text-capitalize">
        {{ title }}
      </div>
      <v-icon small :color="active ? 'success' : 'error'">fas fa-circle</v-icon>
    </v-sheet>
    <div class="flex-grow-1">
      <v-img
        :width="60"
        :src="require(`@/assets/images/integrations/${name.toLowerCase()}.png`)"
      />
    </div>
    <v-sheet width="100%" class="d-flex justify-space-between align-center">
      <div class="text-subtitle-1 text-uppercase">{{ category }}</div>
      <v-btn
        :to="{
          name: 'integration-page',
          params: { paramsTab: 'marketplace', name },
        }"
        color="success"
        small
        >Settings</v-btn
      >
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'IntegrationBox',
  props: ['active', 'title', 'category', 'name'],
  data() {
    return {
      btnLoad: false,
    }
  },
}
</script>

<style scoped></style>
