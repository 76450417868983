<template>
  <v-list>
    <v-expansion-panels v-model="panelState" flat>
      <v-expansion-panel
        v-for="user in users"
        :key="user.id"
        @change="expanded(user)"
      >
        <v-expansion-panel-header class="pa-2">
          <div class="font-weight-medium text-body-2">
            <div class="d-flex align-center">
              <div :class="['clocked', 'mr-2', stringToHslColor(user.name)]" />
              <div>
                {{ user.name }}
              </div>
            </div>
            <div class="text-caption font-weight-bold mt-1">
              {{ user.workHours }}
              <span class="text--secondary font-weight-medium">work hours</span>
            </div>
            <div
              v-if="directionsInfo[user.id]"
              class="text-caption font-weight-bold mt-1"
            >
              <span class="text-caption font-weight-medium mt-1">
                {{ metersToMiles(directionsInfo[user.id].meters) }} miles
                <span class="text--secondary font-weight-medium">
                  ({{
                    $moment
                      .duration(directionsInfo[user.id].minutes / 60, 'minutes')
                      .humanize()
                  }})</span
                >
              </span>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list nav dense class="grey lighten-5 pa-0">
            <v-list-item-group>
              <task-map-item
                v-for="task in orderTasks(user.tasks)"
                :key="task.id"
                :draggable="true"
                :loading="swappingTasks"
                :class="{
                  'blue lighten-4':
                    destinationTask.id === task.id &&
                    destinationTask.id !== originTask.id,
                }"
                :task="task"
                :task-order-map="taskOrderMap"
                @dragstart.native="dragStart(task)"
                @dragenter.native.prevent.stop="dragEnter(task)"
                @dragover.native.prevent.stop
                @drop.native.prevent.stop="drop"
                @dragend.native.prevent.stop="dragEnd"
                @task-click="$emit('task-click', task)"
              />
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
        <v-divider />
      </v-expansion-panel>
    </v-expansion-panels>
  </v-list>
</template>

<script>
import CalendarMixin from 'components/mixins/calendar-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import TaskMapItem from 'components/tasks-map-view/task-map-item'
import { mapTaskOrder, orderTasks } from 'components/calendar/utils'
import { isNil } from 'lodash'

export default {
  components: { TaskMapItem },
  mixins: [CalendarMixin, FormattersMixin, ColorsMixin],
  props: ['tasks', 'directionsInfo'],
  data() {
    return {
      panelState: null,
      destinationTask: {},
      originTask: {},
      swappingTasks: false,
    }
  },
  computed: {
    userTasksMap() {
      const userTasks = {}
      this.tasks.forEach(t => {
        userTasks[t.assigned_contractor_id]
          ? userTasks[t.assigned_contractor_id].push(t)
          : (userTasks[t.assigned_contractor_id] = [t])
      })
      return userTasks
    },
    taskOrderMap() {
      return mapTaskOrder(this.tasks)
    },
    users() {
      return this.filteredContractors
        .filter(u => this.userTasksMap[u.id])
        .map(u => {
          const workHours = (this.userTasksMap[u.id] || []).reduce(
            (acc, curr) => (curr.allDay ? acc : acc + curr.planned_duration),
            0
          )
          return {
            ...u,
            workHours,
            tasks: this.userTasksMap[u.id],
          }
        })
    },
  },
  methods: {
    orderTasks,
    expanded(user) {
      this.$nextTick(() => {
        this.$emit('user-click', !isNil(this.panelState) && user)
      })
    },
    dragStart(task) {
      this.originTask = task
    },
    async drop() {
      if (this.destinationTask.id !== this.originTask.id) {
        const shouldAddHour = this.$moment(
          this.originTask.scheduled_at
        ).isBefore(this.destinationTask.scheduled_at)
        const newTime = shouldAddHour
          ? this.$moment(this.destinationTask.scheduled_at).add(1, 'hour')
          : this.$moment(this.destinationTask.scheduled_at).subtract(1, 'hour')
        this.swappingTasks = true
        await this.$store.dispatch('updateListingTask', {
          id: this.originTask.id,
          scheduled_at: newTime,
        })
        this.$emit('change-to-task')
        this.originTask = {}
        this.destinationTask = {}
      }
    },
    dragEnter(task) {
      this.destinationTask = task
    },
    async dragEnd() {
      setTimeout(() => {
        this.swappingTasks = false
      }, 2000)
    },
  },
}
</script>
<style scoped>
.clocked {
  width: 8px;
  height: 8px;
  border-radius: 50px;
}
div >>> .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
