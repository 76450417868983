import axios from '@/axios/config'

export default {
  namespaced: true,
  actions: {
    async getFeeds(_, params) {
      return axios.get(`/api/feeds`, { params })
    },
    async getFeed(_, { id, ...params }) {
      return axios.get(`/api/feeds/${id}`, {
        params,
      })
    },
    async createMessage(_, { id, body }) {
      return axios.post(`/api/feeds/${id}/message`, { body })
    },
    async getFeedChannelNames() {
      return axios.get('/api/feeds/names')
    },
  },
}
