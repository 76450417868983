<template>
  <div v-if="autoMessageRulesList" class="mt-3">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <h3 class="mr-8">{{ $t('Automated Messages') }}</h3>
        <v-checkbox
          v-model="selectedTypes"
          dense
          hide-details
          class="mr-2 mt-0"
          label="Check-in"
          value="Check-in"
        />
        <v-checkbox
          v-model="selectedTypes"
          dense
          hide-details
          class="mr-2 mt-0"
          label="Check-out"
          value="Check-out"
        />
        <v-checkbox
          v-model="selectedTypes"
          dense
          hide-details
          class="mr-2 mt-0"
          label="Confirmation"
          value="Confirmation"
        />
        <v-checkbox
          v-model="selectedTypes"
          dense
          hide-details
          class="mr-2 mt-0"
          label="Creation"
          value="Creation"
        />
        <v-switch
          v-model="activeOnly"
          dense
          hide-details
          class="ml-4 px-3 mt-0"
          :label="$t('Active only')"
          @change="getAutoMessageRules"
        />
      </div>
      <div class="d-flex align-center">
        <v-text-field
          v-model="search"
          :placeholder="$t('Search rules...')"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          outlined
          class="mr-4"
          @input="debouncedSearch"
        />
        <v-btn color="primary" @click="addRule">
          <v-icon left>fas fa-plus</v-icon>
          {{ $t('New Auto Msg') }}
        </v-btn>
      </div>
    </div>
    <v-data-table
      :loading="listingLoading"
      :headers="headers"
      :items="filteredItems"
      :server-items-length="pagiInfo.count"
      :page.sync="pagination.page"
      :items-per-page.sync="pagination.itemsPerPage"
      class="py-2 mt-4"
      @update:page="handlePageChange"
      @update:items-per-page="handlePageSizeChange"
    >
      <template #item="{ item }">
        <auto-message-rule-row
          :key="item.id"
          :after-change="afterSave"
          :item="item"
          :whatsapp-templates="whatsappTemplates"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import sortBy from 'lodash/sortBy'
import { mapState } from 'vuex'
import AutoMessageRuleRow from 'components/auto-message-rules/auto-message-rule-row.vue'
import { debounce } from 'lodash'

export default {
  components: {
    AutoMessageRuleRow,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['filter'],
  data() {
    return {
      activeOnly: true,
      whatsappTemplates: null,
      search: '',
      selectedTypes: [],
      pagination: {
        page: 1,
        itemsPerPage: 50,
      },
      debouncedSearch: null,
    }
  },
  created() {
    this.debouncedSearch = debounce(() => {
      this.page = 1
      this.getAutoMessageRules()
    }, 300)
  },
  mounted() {
    this.getAutoMessageRules()
    this.getWhatsappTemplates()
  },
  methods: {
    addRule() {
      this.$store.commit('showModal', {
        name: 'AutoMessageRuleModal',
        props: {
          editMode: false,
          afterSaveFunc: this.afterSave,
          templates: this.whatsappTemplates,
          is_ai: this.filter == 'ai',
        },
      })
    },
    afterSave(showInactive = false) {
      if (showInactive) {
        this.activeOnly = false
      }
      this.getAutoMessageRules()
    },
    async getWhatsappTemplates() {
      const { data } = await this.$store.dispatch('getWhatsappTemplates')
      this.whatsappTemplates = data
    },
    handlePageChange(page) {
      this.pagination.page = page
      this.getAutoMessageRules()
    },
    handlePageSizeChange(perPage) {
      this.pagination.itemsPerPage = perPage
      this.pagination.page = 1
      this.getAutoMessageRules()
    },
    getAutoMessageRules() {
      this.$store.dispatch('getAutoMessageRules', {
        id:
          this.listingManageContext &&
          this.$router.currentRoute.path.split('/')[3],
        active_only: this.activeOnly,
        filter: this.filter,
        search: this.search,
        page: this.pagination.page,
        per_page: -1,
      })
    },
  },
  computed: {
    ...mapState(['autoMessageRules']),
    autoMessageRulesList() {
      return this.autoMessageRules?.auto_message_rules || []
    },
    pagiInfo() {
      return (
        this.autoMessageRules?.pagi_info || {
          count: 0,
          page: this.pagination.page,
          per_page: this.pagination.itemsPerPage,
        }
      )
    },
    headers() {
      return [
        { text: 'Active', sortable: false },
        { text: 'Name', align: 'center', sortable: false },
        { text: 'Rule Type', align: 'center', sortable: false },
        { text: 'Allowed Channels', align: 'center', sortable: false },
        { text: 'Ignored Channels', align: 'center', sortable: false },
        { text: 'Via', align: 'center', sortable: false },
        { text: 'Listings', align: 'center', sortable: false },
        { text: 'Timing', align: 'center', sortable: false },
        { text: 'Last sent', align: 'center', sortable: false },
        { text: 'Actions', align: 'center', sortable: false },
      ]
    },
    filteredItems() {
      let items = sortBy(this.autoMessageRulesList, ['listing_nickname'])

      if (this.selectedTypes.length > 0) {
        items = items.filter(item => {
          return this.selectedTypes.includes(item.sent_at_event)
        })
      }

      return items
    },
  },
}
</script>
