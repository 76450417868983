import { MANAGMENT_TABLE_TYPES } from '@/consts'
import formatterMixin from 'components/mixins/formatters-mixin'
const d = formatterMixin.methods.dollarFormatter

export const INVENTORY_HEADERS = [
  {
    text: 'SKU',
    value: 'sku',
  },
  {
    text: 'Title',
    value: 'title',
  },
  {
    text: 'Category',
    value: 'catagory',
  },
  {
    text: 'Price',
    value: 'price',
    isCurrency: true,
  },
  {
    text: 'Markup',
    cellType: MANAGMENT_TABLE_TYPES.COMPUTED,
    value: item =>
      `${d((item.mark_up + 1) * item.price)} (${item.mark_up * 100}%)`,
  },
  {
    text: 'Count',
    cellType: MANAGMENT_TABLE_TYPES.COMPUTED,
    value: item =>
      `${item.current_amount} ${
        item.min_alert ? `(min. ${item.min_alert})` : ''
      }`,
  },
  {
    text: 'Image',
    cellType: MANAGMENT_TABLE_TYPES.IMAGE,
    value: 'images[0]',
  },
  {
    text: 'Item Link',
    cellType: MANAGMENT_TABLE_TYPES.LINK,
    urlKey: 'item_link',
  },
  {
    text: '',
    cellType: MANAGMENT_TABLE_TYPES.ACTION,
    icon: '$trash',
    value: 'deleteItem',
  },
]

export const HISTORY_HEADERS = [
  {
    text: 'Action',
    value: 'action_type',
  },
  {
    text: 'Count',
    value: 'amount',
  },
  {
    text: 'SKU',
    value: 'inventory_item.sku',
  },
  {
    text: 'Title',
    value: 'inventory_item.title',
  },
  {
    text: 'Price',
    value: 'inventory_item.price',
    isCurrency: true,
  },
  {
    text: 'Approved by',
    value: 'approvedBy',
  },
  {
    text: 'Time',
    value: 'created_at',
    fieldType: 'date',
  },
  {
    text: 'Comment',
    value: 'comments',
  },
  {
    text: 'Storage',
    value: 'storage.nickname',
  },
]
