import axios from 'axios'
import keyBy from 'lodash/fp/keyBy'

export default {
  state: {
    items: {},
    topics: {},
    sub_topics: {},
    mediaLoading: false,
    storePurchases: [],
    storePurchasesLoading: false,
    storePurchasesPagination: {},
  },
  mutations: {
    updateStoreItems(state, data) {
      state.items = keyBy('id', data)
    },
    updateStorePurchases(state, data) {
      state.storePurchases = data
    },
    updateStorePurchasesLoading(state, data) {
      state.storePurchasesLoading = data
    },
    updateStoreTopics(state, data) {
      state.topics = keyBy('id', data)
    },
    updateStoreSubTopics(state, data) {
      state.sub_topics = keyBy('id', data)
    },
    updateStoreItem(state, data) {
      state.items[data.id] = data
    },
    updateMediaLoading(state, data) {
      state.mediaLoading = data
    },
    setStorePurchasesPagination(state, data) {
      state.storePurchasesPagination = data
    },
  },
  actions: {
    async getStoreItems({ commit }) {
      try {
        const { data } = await axios.get(`/api/admin/store`)
        commit('updateStoreItems', data)
      } catch (err) {
        console.error(err)
      }
    },
    async getTouristInfo(_, { id }) {
      return axios.get(`/api/admin/store/users/${id}`)
    },
    async createQuickStorePurchase(_, { reservationId, ...purchaseDetails }) {
      return axios.post(
        `/api/tourist/reservations/${reservationId}/store-items/quick-add`,
        {
          ...purchaseDetails,
        }
      )
    },

    async createPaymentIntent(
      _,
      { item_id, reservation_id, purchase_id, options = {}, count }
    ) {
      return axios.post(
        `/api/tourist/reservations/${reservation_id}/store-items/add`,
        { item_id, options, count, purchase_id }
      )
    },
    async getStorePurchases({ commit }, params) {
      try {
        commit('updateStorePurchasesLoading', true)
        const { data } = await axios.get('/api/admin/store/purchases', {
          params,
        })
        commit('updateStorePurchases', data.purchases || [])
        commit('setStorePurchasesPagination', data.pagination)
        commit('updateStorePurchasesLoading', false)
      } catch (err) {
        console.error(err)
      }
    },
    async refreshItems({ commit }) {
      try {
        const { data } = await axios.get(`/api/admin/store`)
        commit('updateStoreItems', data)
      } catch (err) {
        console.error(err)
      }
    },
    async getStoreTopics({ commit }) {
      try {
        const { data } = await axios.get(`/api/store/topics`)
        commit('updateStoreTopics', data)
      } catch (err) {
        console.error(err)
      }
    },
    async getStoreSubTopics({ commit }) {
      try {
        const { data } = await axios.get(`/api/store/sub-topics`)
        commit('updateStoreSubTopics', data)
      } catch (err) {
        console.error(err)
      }
    },
    async createStoreItemMenu(_store, { storeItemId, ...payload }) {
      try {
        return axios.post(
          `/api/admin/store-item/${storeItemId}/menu-item`,
          payload
        )
      } catch (err) {
        console.error(err)
      }
    },
    async updateStoreItemMenu(_store, { storeItemId, id, ...payload }) {
      try {
        return axios.put(
          `/api/admin/store-item/${storeItemId}/menu-item/${id}`,
          payload
        )
      } catch (err) {
        console.error(err)
      }
    },
    async createStoreItem({ _commit, dispatch }, data) {
      try {
        await axios.post(`/api/admin/store/generic-item`, data)
        dispatch('getStoreItems')
      } catch (err) {
        console.error(err)
      }
    },
    async updateStoreItem(
      { commit, dispatch },
      { id, logo, media, video, bullets, ...payload }
    ) {
      commit('updateMediaLoading', true)
      const data = {}
      Object.keys(payload).forEach(key => {
        data[key] = payload[key]
      })
      data.bullets = bullets || []
      if (logo) {
        data.logo = logo
      }
      if (video) {
        data.video = video
      }
      if (media) {
        data.media = media
      }
      try {
        const res = await axios.put(`/api/admin/store/${id}`, data)
        commit('updateStoreItem', res)
      } catch (err) {
        console.error(err)
      }
      setTimeout(() => {
        dispatch('getStoreItems')
        commit('updateMediaLoading', false)
      }, 6000)
    },
  },
  getters: {
    topicsList(state) {
      return Object.values(state.topics)
    },
    subTopicsList(state) {
      return Object.values(state.sub_topics)
    },
    storeItems(state) {
      return Object.values(state.items).map(item => {
        return {
          ...item,
          sub_topic: state.sub_topics[item.sub_topic_id] || {},
        }
      })
    },
  },
}
