import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    zones: [],
  },
  mutations: {
    updateZones(state, payload) {
      state.zones = payload
    },
  },
  actions: {
    async getZones({ commit }) {
      const { data } = await axios.get(`/api/zone`)
      commit('updateZones', data)
      return data
    },
    async deleteZone({ dispatch }, { id }) {
      await axios.delete(`/api/zone/${id}`)
      dispatch('getZones')
    },
    async createZone({ dispatch }, data) {
      await axios.post(`/api/zone`, data)
      dispatch('getZones')
    },
    async updateZone({ dispatch }, { id, ...data }) {
      await axios.put(`/api/zone/${id}`, data)
      dispatch('getZones')
      dispatch('getListingsPicker', null, { root: true })
    },
  },
}
