<template>
  <div class="language-tabs">
    <v-tabs
      v-model="localActiveTab"
      show-arrows
      background-color="white"
      @change="handleTabChange"
    >
      <v-tab v-for="lang in selectedLanguages" :key="lang.code">
        <div class="d-flex align-center">
          <country-flag
            :country="lang.country"
            :rounded="true"
            size="small"
            class="mr-1"
          />
          {{ LANGUAGES.find(l => l.lang === lang.code)?.label }}
        </div>
      </v-tab>

      <v-tab @click.stop="showAddLanguage = true">
        <v-icon>mdi-plus</v-icon>
      </v-tab>
    </v-tabs>

    <!-- Language Picker Dialog -->
    <v-dialog v-model="showAddLanguage" max-width="300">
      <v-card>
        <v-card-title>{{ $t('Add Language') }}</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedNewLang"
            :items="availableLanguages"
            item-text="label"
            item-value="code"
            outlined
            dense
          >
            <template #selection="{ item }">
              <div class="d-flex align-center">
                <country-flag
                  :country="item.country"
                  :rounded="true"
                  size="small"
                  class="mr-2"
                />
                {{ item.label }}
              </div>
            </template>
            <template #item="{ item }">
              <div class="d-flex align-center">
                <country-flag
                  :country="item.country"
                  :rounded="true"
                  size="small"
                  class="mr-2"
                />
                {{ item.label }}
              </div>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showAddLanguage = false">
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!selectedNewLang"
            @click="addLanguage"
          >
            {{ $t('Add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { LANGUAGES } from '@/consts'

export default {
  name: 'LanguageTabs',

  components: {
    CountryFlag,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
    selectedLang: {
      type: String,
      required: true,
    },
    defaultLang: {
      type: String,
      default: 'en',
    },
    activeTab: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showAddLanguage: false,
      selectedNewLang: null,
      languages: [],
      LANGUAGES,
      localActiveTab: this.activeTab,
    }
  },

  watch: {
    activeTab(newVal) {
      this.localActiveTab = newVal
    },
  },

  computed: {
    selectedLanguages() {
      return this.value.map(code => {
        const lang = LANGUAGES.find(l => l.lang === code)
        return {
          code,
          label: lang.label,
          country: lang.country,
        }
      })
    },
    availableLanguages() {
      const usedCodes = this.languages.map(l => l.code)
      return LANGUAGES.filter(l => !usedCodes.includes(l.lang)).map(l => ({
        code: l.lang,
        label: l.label,
        country: l.country,
      }))
    },
  },

  methods: {
    handleTabChange() {
      if (!this.selectedLanguages[this.localActiveTab]) return
      this.$emit('tab-change', this.selectedLanguages[this.localActiveTab].code)
    },

    addLanguage() {
      if (this.selectedNewLang) {
        this.$emit('add-language', this.selectedNewLang)
        this.showAddLanguage = false
        this.selectedNewLang = null
      }
    },
  },
}
</script>

<style scoped>
.language-tabs {
  background-color: white;
}
</style>
