<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <rect class="a" width="26" height="26" rx="13" />
    <path
      class="b"
      d="M4.891,5.5A2.751,2.751,0,1,0,2.14,2.751,2.752,2.752,0,0,0,4.891,5.5Zm2.446.611H6.284a3.326,3.326,0,0,1-2.786,0H2.446A2.445,2.445,0,0,0,0,8.56v.306a.917.917,0,0,0,.917.917H8.866a.917.917,0,0,0,.917-.917V8.56A2.445,2.445,0,0,0,7.337,6.114Z"
      transform="translate(8.109 8.002)"
    />
  </svg>
</template>

<script>
export default {
  name: 'AccountAlt',
}
</script>

<style scoped>
.a {
  fill: #dfe1e6;
}

.b {
  fill: currentColor;
}
</style>
