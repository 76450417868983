<template>
  <v-card :loading="listingLoading" elevation="0">
    <v-card-title class="grey-text">
      <v-row>
        <v-col cols="12" md="4" xl="3">
          <v-text-field
            v-model="searchTerm"
            label="Search..."
            outlined
            dense
            prepend-inner-icon="search"
            hide-details
          />
        </v-col>
        <v-col cols="6" md="4" xl="2">
          <v-select
            v-model="catagoryPicked"
            :items="catagoriesOptions"
            label="Category"
            multiple
            outlined
            dense
            hide-details
            @change="categoryChange($event)"
          />
        </v-col>
        <v-col cols="6" md="4" xl="2">
          <v-select
            v-if="!pickedStorage"
            v-model="storagePicked"
            :items="shopingCartMode ? storageNames : storageNamesWithAll"
            label="Storage"
            outlined
            dense
            hide-details
            @change="storageChange($event)"
          >
            <template v-if="isAdmin" #append>
              <form-dialog
                title="Create New Storage"
                @submit="createStorage(storage)"
                @open="storage = {}"
              >
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @mousedown.stop>
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <v-text-field
                  v-model="storage.nickname"
                  label="Nickname *"
                  outlined
                  dense
                  :rules="[required]"
                />
                <v-text-field
                  v-model="storage.address"
                  label="Address"
                  outlined
                  dense
                />
                <contractor-select
                  :value.sync="storage.owner_ids"
                  :allow-multiple="true"
                  :initials="true"
                />
              </form-dialog>
            </template>
            <template #item="{ item, on }">
              <v-list-item dense v-on="on">
                <v-list-item-content>
                  <v-list-item-title
                    :class="{
                      'primary--text darken-2 font-weight-bold':
                        item === storagePicked,
                    }"
                    v-text="item"
                  />
                </v-list-item-content>
                <v-list-item-action
                  v-if="item !== 'All' && isAdmin"
                  class="d-flex flex-row"
                >
                  <form-dialog
                    title="Storage"
                    @submit="updateStorageByName(item)"
                    @open="storage = storageBy('nickname', item)"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn small v-bind="attrs" icon v-on="on">
                        <v-icon small color="grey lighten-1">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <v-text-field
                      v-model="storage.nickname"
                      label="Nickname *"
                      outlined
                      dense
                      :rules="[required]"
                    />
                    <v-text-field
                      v-model="storage.address"
                      label="Address"
                      outlined
                      dense
                    />
                    <contractor-select
                      :value.sync="storage.owner_ids"
                      :allow-multiple="true"
                      :initials="true"
                    />
                  </form-dialog>
                  <ConfirmationModal
                    :text="`Are you sure you want to deactivate this storage ?`"
                    @action="deactivateStorageByName(item)"
                  >
                    <template #activator="{ on }">
                      <v-btn small icon v-on="on">
                        <v-icon small color="grey lighten-1">
                          mdi-delete-forever
                        </v-icon>
                      </v-btn>
                    </template>
                  </ConfirmationModal>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" xl="5" class="d-flex justify-space-between">
          <div class="d-flex flex-column flex-sm-row">
            <v-checkbox
              v-model="showZeroAmounts"
              class="mt-1 mr-2"
              label="Show 0 amount"
              hide-details
              dense
            />
            <v-checkbox
              v-model="showAlerts"
              class="mt-1"
              :label="`Alerts only (${alerts.length})`"
              hide-details
              dense
            />
          </div>
          <v-spacer />
          <v-btn
            v-if="managementRoles"
            outlined
            class="cyan-icon mr-5"
            prepend-icon="import_export"
            text
            @click="exportToExcel"
          >
            <v-icon class="pr-2">table_rows</v-icon>
            Export
          </v-btn>
          <inventory-modal headers="inventoryHeaders" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <data-table
      :headers="inventoryHeaders"
      :items="filteredInventory"
      @onRowClick="updateChosenInventoryItem"
      @deleteItem="deleteItem"
    />
    <v-pagination
      v-if="inventoryPagination"
      v-model="page"
      :length="
        Math.ceil(
          inventoryPagination.properties_count / inventoryPagination.per_page
        )
      "
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3 flex"
    />
    <inventory-item-card />
    <v-dialog v-model="imageDialog" persistent max-width="900px">
      <v-flex xs12>
        <v-card class="pa-4">
          <v-layout column align-baseline justify-center>
            <v-flex offset-xs10 xs-2 offset-sm11 sm-1>
              <v-btn
                fab
                dark
                small
                color="teal"
                class="float-right"
                @click="closeImages"
              >
                <v-icon dark>close</v-icon>
              </v-btn>
            </v-flex>
            <v-flex
              v-for="(image, index) in chosenImages"
              :key="index"
              xs12
              class="comp-wrapper mt-3 pa-3"
            >
              <img
                :key="index"
                style="width: 100%"
                :src="image"
                class="image-design"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import CommonFunctions from 'components/mixins/common_functions'
import formRules from 'components/mixins/form-rules-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { INVENTORY_HEADERS } from 'components/inventory/config.js'
import DeviceMixin from 'components/mixins/device-mixin'
import DataTable from 'components/common/data-table/data-table.vue'
import InventoryModal from 'components/inventory/inventory-modal.vue'
import InventoryItemCard from 'components/inventory/inventory-item-card.vue'
import ConfirmationModal from 'components/modals/confirmation-modal'
import FormDialog from 'components/common/form-dialog'
import ContractorSelect from 'components/contractor-select.vue'

export default {
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin, formRules],
  props: ['shopingCartMode', 'addedItemFunc', 'pickedStorage', 'removeFunc'],
  data() {
    return {
      imageDialog: false,
      page: 1,
      chosenImages: null,
      showZeroAmounts: false,
      showAlerts: false,
      storagePicked: this.pickedStorage ? this.pickedStorage : 'All',
      pagination: {
        itemsPerPage: 30,
      },
      catalogView: false,
      model: 0,
      catagoryPicked: null,
      searchTerm: '',
      inventoryHeaders: INVENTORY_HEADERS,
      storage: {},
    }
  },
  watch: {
    page() {
      this.debouncer.call(this)
    },
    showZeroAmounts() {
      this.page = 1
      this.debouncer.call(this)
    },
    showAlerts() {
      this.page = 1
      this.debouncer.call(this)
    },
    searchTerm() {
      this.page = 1
      this.debouncer.call(this)
    },
  },
  computed: {
    ...mapState(['catagoriesOptions', 'inventoryItems']),
    ...mapGetters('storages', ['storageNames', 'storageNamesWithAll']),
    ...mapGetters(['inventoryPagination']),
    headers() {
      if (this.shopingCartMode) {
        return [
          { text: 'Actions', sortable: false },
          { text: 'Title', value: 'title' },
          { text: 'Amount', sortable: false },
          { text: 'Category', value: 'catagory' },
          { text: 'Price', value: 'price' },
        ]
      }
      return this.isMobile
        ? [
            { text: 'Image', sortable: false },
            { text: 'Title', value: 'title' },
            { text: 'Actions', sortable: false },
          ]
        : this.isIPad
        ? [
            { text: 'Image', sortable: false },
            { text: 'Title', value: 'title' },
            { text: 'Price', value: 'price' },
            { text: 'Amount', sortable: false },
            { text: 'Actions', sortable: false },
          ]
        : [
            { text: 'Image', sortable: false },
            { text: 'Title', value: 'title' },
            { text: 'Category', value: 'catagory' },
            { text: 'Price', value: 'price' },
            { text: 'Markup', sortable: false },
            { text: 'Amount', sortable: false },
            { text: 'Actions', sortable: false },
          ]
    },
    alerts() {
      return filter(this.inventoryItems, item => {
        return item.min_alert && item.current_amount <= item.min_alert
      })
    },
    filteredInventory() {
      if (this.inventoryItems) {
        const storageId = this.storageIdByNickname(this.storagePicked)
        let res = []
        this.inventoryItems.forEach(item => {
          if (storageId) {
            item.current_amount = item.amount_per_storage[storageId] || 0
          }
          if (
            this.showAlerts &&
            (!item.min_alert ||
              (item.min_alert && item.current_amount > item.min_alert))
          ) {
            return false
          }
          const title = item.title || ''
          const description = item.description || ''
          const sku = item.sku || ''
          if (
            title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            description.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
            sku.toLowerCase().includes(this.searchTerm.toLowerCase())
          ) {
            if (item.current_amount === 0 && this.showZeroAmounts) {
              res.push(item)
            } else if (item.current_amount !== 0) {
              res.push(item)
            }
          }
        })
        return res
      }
      return []
    },
  },
  mounted() {
    this.debouncer = debounce(this.getInventoryItems, 750)
    this.getInventoryItems()
  },
  methods: {
    ...mapActions('storages', [
      'createStorage',
      'updateStorage',
      'deactivateStorage',
    ]),
    exportToExcel() {
      this.$store.commit('updateListingLoading', true)
      axios
        .get(`/api/inventory-items/all/export-to-excel`, {
          responseType: 'blob',
        })
        .then(response => {
          this.$store.commit('updateListingLoading', false)
          this.autoDownloadBlobData(response.data, 'inventory.xlsx')
        })
        .catch(error => {
          alert(error)
        })
    },
    getInventoryItems() {
      this.$store.dispatch('getInventoryItems', {
        catagory: this.catagoryPicked,
        search: this.searchTerm,
        storage: this.storagePicked,
        page: this.page,
        showZeroAmounts: this.showZeroAmounts,
      })
    },
    deleteItem(item) {
      const userConfirm = confirm(
        `Are you sure you want to delete ${item.title}?`
      )

      if (userConfirm) {
        axios
          .delete('/api/inventory-items/' + item.id)
          .then(() => {
            this.debouncer.call(this)
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    priceChange(newPrice, item) {
      item.price = newPrice
      this.$store.dispatch('UpdateInventoryItem', {
        price: newPrice,
        id: item.id,
      })
      setTimeout(() => {
        this.page = 1
        this.debouncer.call(this)
      }, 250)
    },
    updateChosenInventoryItem: function (item) {
      this.$store.commit('updateChosenInventoryItem', item)
    },
    showImages(item) {
      this.imageDialog = true
      this.chosenImages = item.images
    },
    closeImages() {
      this.imageDialog = false
    },
    storageChange(storage) {
      this.storagePicked = storage
      this.debouncer.call(this)
    },
    categoryChange(newCatagry) {
      this.catagoryPicked = newCatagry
      this.debouncer.call(this)
    },
    updateStorageByName(oldName) {
      const id = this.storageIdByNickname(oldName)
      this.updateStorage({ id, data: this.storage })
    },
    deactivateStorageByName(name) {
      const id = this.storageIdByNickname(name)
      this.deactivateStorage(id)
    },
  },
  components: {
    FormDialog,
    ConfirmationModal,
    InventoryModal,
    InventoryItemCard,
    DataTable,
    ContractorSelect,
  },
}
</script>
