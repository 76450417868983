<template>
  <v-container>
    <seam-devices />

    <v-data-table
      v-if="isActiveMinut"
      :headers="headers"
      :items="devices"
      :items-per-page="20"
      sort-by="battery.percent"
      class="elevation-3"
    >
      <template #item="{ item, index }">
        <tr>
          <td>
            <div class="d-flex align-center">
              <v-icon
                :color="item.offline ? 'warning' : 'success'"
                class="mr-2"
                small
                >mdi-circle</v-icon
              >
              <div v-if="item.listing">
                <p class="mb-0">
                  <router-link
                    class="text-decoration-none info--text"
                    :to="`/dashboard/property/${item.listing.id}`"
                    >{{ item.listing.nickname }}</router-link
                  >
                </p>
                <p class="d-lg-none text-caption secondary--text mb-0">
                  {{ item.home }}
                </p>
              </div>
              <div v-else>No house connected</div>
              <v-spacer />
              <Battery class="d-sm-none" :percent="item.battery.percent" />
              <v-btn class="d-sm-none ml-4" icon @click="showDetails(item)">
                <v-icon class="secondary--text" small>mdi-eye</v-icon>
              </v-btn>
            </div>
            <div class="d-md-none ml-6" @click="edited = item.home">
              <EditableTextField
                v-if="isAdmin || isPropertyManager"
                :key="index"
                :value="item.home_name"
                :is-edit="edited === item.home"
                @onupdate="changeHomeName"
                @onblur="blurfield"
              />
              <div v-else>
                {{ item.home_name }}
              </div>
            </div>
          </td>
          <td
            class="d-none d-md-table-cell editable"
            @click="edited = item.home"
          >
            <EditableTextField
              v-if="isAdmin || isPropertyManager"
              :key="index"
              :value="item.home_name"
              :is-edit="edited === item.home"
              @onupdate="changeHomeName"
              @onblur="blurfield"
            />
            <div v-else>
              {{ item.home_name }}
            </div>
          </td>
          <td class="d-none d-lg-table-cell">{{ item.description }}</td>
          <td class="d-none d-lg-table-cell">{{ item.home }}</td>
          <td class="d-none d-sm-table-cell">
            <Battery :percent="item.battery.percent" />
          </td>
          <td class="d-none d-sm-table-cell">{{ item.device_type }}</td>
          <td class="d-none d-sm-table-cell">
            <v-icon
              :color="item.listing && item.listing.active ? 'green' : 'red'"
            >
              {{ item.listing && item.listing.active ? 'done' : 'close' }}
            </v-icon>
          </td>
          <td class="d-none d-sm-table-cell d-lg-none">
            <v-btn icon @click="showDetails(item)">
              <v-icon class="secondary--text" small>mdi-eye</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="detailsModal" max-width="420">
      <iot-details :item="currentItem" />
    </v-dialog>
  </v-container>
</template>

<script>
import get from 'lodash/fp/get'
import { mapActions, mapGetters } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import Battery from 'components/iot/battery-level.vue'
import IotDetails from 'components/iot/iot-details-modal'
import EditableTextField from '../form-fields/editable-text-field'
import SeamDevices from 'components/seam-devices.vue'

export default {
  name: 'IotDashboard',
  components: { Battery, IotDetails, EditableTextField, SeamDevices },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  data() {
    return {
      detailsModal: false,
      currentItem: {},
      edited: false,
    }
  },
  computed: {
    ...mapGetters(['devices']),
    headers() {
      return this.isMobileOrIPad
        ? [
            { text: 'House', value: 'listing.nickname' },
            { text: 'Battery', value: 'battery.percent' },
            { text: 'Type', value: '' },
            { text: 'Listing Active', value: 'listing.active' },
            { text: '', value: 'actions', sortable: false },
          ]
        : this.isLaptop
        ? [
            { text: 'House', value: 'listing.nickname' },
            { text: 'Minut Home Name', value: 'home_name', sortable: false },
            { text: 'Battery', value: 'battery.percent' },
            { text: 'Type', value: '' },
            { text: 'Listing Active', value: 'listing.active' },
            { text: '', value: 'actions', sortable: false },
          ]
        : [
            { text: 'House', value: 'listing.nickname' },
            { text: 'Minut Home Name', value: 'home_name', sortable: false },
            { text: 'Description', value: 'description' },
            { text: 'Home ID', value: 'home' },
            { text: 'Battery', value: 'battery.percent' },
            { text: 'Type', value: '' },
            { text: 'Listing Active', value: 'listing.active' },
          ]
    },
    getNickname() {
      return get('listing.nickname')
    },
    getListingId() {
      return get('listing.id')
    },
    isActiveMinut() {
      return false
    },
  },
  methods: {
    ...mapActions(['getIOTDevices']),
    showDetails(item) {
      this.currentItem = item
      this.detailsModal = true
    },
    changeHomeName(value) {
      if (value) {
        this.$store
          .dispatch('updateMinutHome', {
            id: this.edited,
            name: value,
          })
          .then(res => {
            if (res.response && res.response.status === 404) {
              alert(`Listing with nickname ${value} is not exists`)
            } else {
              this.edited = false
            }
          })
        return
      }
      this.edited = false
    },
    blurfield() {
      this.edited = false
    },
  },
  mounted() {
    this.getIOTDevices()
  },
}
</script>

<style scoped></style>
