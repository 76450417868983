<template>
  <div>
    <div class="w-full d-flex justify-space-between mr-6 ml-4 mb-6">
      <div class="d-flex">
        <v-btn
          small
          class="mr-2"
          color="success"
          :disabled="saveDisabled"
          depressed
          @click="save"
        >
          <v-icon small class="mr-2"> mdi-content-save </v-icon>Save
        </v-btn>
        <v-btn
          small
          color="secondary"
          outlined
          :disabled="saveDisabled"
          @click="cancel"
        >
          Cancel</v-btn
        >
      </div>
      <v-btn small class="white--text" color="blue" depressed @click="add">
        <v-icon small class="mr-2"> mdi-plus </v-icon>Add
      </v-btn>
    </div>
    <div>
      <v-progress-linear
        v-show="loading"
        color="primary"
        class="ma-0"
        :indeterminate="true"
      />
      <div v-for="(request, key) in requests" :key="key">
        <v-divider />
        <div class="d-flex align-center pr-6 flex-wrap my-6">
          <div>
            <v-btn icon class="mr-2" @click="remove(key)">
              <v-icon small color="secondary">mdi-delete</v-icon>
            </v-btn>
          </div>
          <div class="font-weight-medium flex-grow-1 mr-10">
            <editable-text-field
              :placeholder="$t('Add description')"
              :value="request.name"
              @onupdate="updateRequestName(key, $event)"
            />
          </div>
          <div class="mr-4">
            <v-autocomplete
              v-model="request.amenity"
              :items="amenities"
              clearable
              label="Only if Amenity"
              hide-details
              outlined
              class="max-w-200"
              dense
            />
          </div>
          <div>
            <v-radio-group
              v-model="request.type"
              class="mt-0 pt-0 mr-4"
              hide-details
              row
            >
              <v-radio label="Yes/No" value="toggle" />
              <v-radio label="Counter" value="counter" />
            </v-radio-group>
          </div>
          <v-sheet width="100px">
            <v-text-field
              v-if="request.type === 'counter'"
              v-model.number="request.max"
              label="Max"
              type="number"
              dense
              outlined
              hide-details
            />
            <v-checkbox
              v-else
              v-model="request.required"
              class="mt-0 pt-0 mr-10"
              label="Required"
              hide-details
            />
          </v-sheet>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditableTextField from '@/components/form-fields/editable-text-field.vue'
import isEqual from 'lodash/fp/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { BOOM_AMENITIES } from '@/consts'

export default {
  components: {
    EditableTextField,
  },
  props: ['specialRequests', 'originalSpecialRequests', 'loading'],
  data() {
    return {
      amenities: BOOM_AMENITIES,
      saveDisabled: true,
      requests: this.specialRequests || [],
    }
  },
  watch: {
    specialRequests: {
      handler() {
        this.saveDisabled = isEqual(this.requests, this.originalSpecialRequests)
        this.requests = this.specialRequests
      },
      deep: true,
    },
  },
  methods: {
    async save() {
      this.$emit('save', { special_requests: this.requests })
    },
    cancel() {
      this.requests = cloneDeep(this.originalSpecialRequests)
      this.saveDisabled = true
    },
    updateRequestName(key, val) {
      this.requests[key].name = val
    },
    remove(key) {
      this.requests.splice(key, 1)
    },
    add() {
      this.saveDisabled = false
      this.requests.push({
        name: null,
        type: 'toggle',
        required: false,
        amenity: null,
        max: 0,
      })
    },
  },
}
</script>

<style></style>
