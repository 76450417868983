<template>
  <div class="d-flex flex-wrap justify-space-between align-center mb-4">
    <div class="mb-2 mb-sm-0">
      <p class="text-caption mb-0">Title</p>
      <span class="text-subtitle-2 text-md-h6">{{ title }}</span>
    </div>
    <v-btn
      v-if="showLink & ltId"
      color="secondary lighten-1"
      elevation="0"
      target="_blank"
      :to="{
        path: `/dashboard/listing-task/${ltId}`,
      }"
    >
      <span class="font-weight-light text-capitalize black--text">
        View Task
      </span>
    </v-btn>
    <v-btn
      v-if="showLink && reqId"
      color="secondary lighten-1"
      elevation="0"
      target="_blank"
      :to="{
        path: `/dashboard/inventory?request_id=${reqId}`,
      }"
    >
      <span class="font-weight-light text-capitalize black--text">
        View Storage Request
      </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ['title', 'showLink', 'reqId', 'ltId'],
}
</script>
