import { MANAGMENT_TABLE_TYPES } from '@/consts'

export const MARKETING_HEADERS = [
  {
    text: 'Name',
    value: 'nickname',
    cellType: MANAGMENT_TABLE_TYPES.LINK,
    readonly: true,
  },
  {
    text: 'Beds',
    value: 'beds',
    readonly: true,
  },
  {
    text: 'Baths',
    value: 'baths',
    readonly: true,
  },
  {
    text: 'Listed',
    value: 'listed',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    readonly: true,
  },
  {
    text: 'Airbnb Score',
    value: 'airbnb_review_score',
    readonly: true,
  },
  {
    text: 'Airbnb',
    value: 'Airbnb',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
  {
    text: 'Direct',
    value: 'Direct',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
  {
    text: 'PlumGuide',
    value: 'PlumGuide',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
  {
    text: 'Marriott',
    value: 'Marriott',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
  {
    text: 'VRBO',
    value: 'HomeAway',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
  {
    text: 'TripAdvisor',
    value: 'TripAdvisor',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
  {
    text: 'Booking.com',
    value: 'Booking.com',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
  {
    text: 'Expedia',
    value: 'Expedia',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
  {
    text: 'HomeToGo',
    value: 'HomeToGo',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
  {
    text: 'Zoopla',
    value: 'Zoopla',
    cellType: MANAGMENT_TABLE_TYPES.CHECK,
    align: 'center',
  },
]

export const TABS = {
  MARKETING: 'Marketing',
  FINANCE: 'Finance',
}
