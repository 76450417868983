<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 21.25 22.2">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="home-4">
          <g id="Group_10270" data-name="Group 10270">
            <path
              id="Path_5168"
              data-name="Path 5168"
              class="cls-1"
              d="M20.93,9.76,11.14.31a.72.72,0,0,0-1,0L.33,9.76a.72.72,0,0,0,0,1A.72.72,0,0,0,.84,11h1.3V21.36a.74.74,0,0,0,.74.74H8a.74.74,0,0,0,.74-.74h0v-5.9h3.69v5.9a.74.74,0,0,0,.74.74h5.17a.74.74,0,0,0,.74-.74h0V11h1.3a.74.74,0,0,0,.73-.74.75.75,0,0,0-.22-.53Zm-1.81.53a.74.74,0,0,0-.74.74h0V21.36H13.21v-5.9a.74.74,0,0,0-.74-.74H8.78a.74.74,0,0,0-.74.74H8v5.9H2.88V11a.74.74,0,0,0-.74-.74H.84L10.63.84l9.79,9.45Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0.2px;
}
</style>
