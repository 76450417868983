import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {},
  actions: {
    async getTenants() {
      return axios.get('/api/tenants')
    },
    async getTenantSettings(_, tenantId) {
      return axios.get(`/api/tenants/${tenantId}/settings`)
    },
  },
}
