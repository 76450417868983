export const getQuillDefaultOptions = (
  mentions,
  placeholder,
  { showToolbar = true, imgHandler } = {}
) => ({
  placeholder: placeholder || '',
  modules: {
    toolbar: showToolbar && {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        ['link', 'code-block', 'image'],
      ],
      handlers: {
        image: imgHandler,
      },
    },
    mention: {
      mentionListClass:
        'v-list v-sheet theme--light v-list--dense v-list--nav black--text pl-2 elevation-4',
      listItemClass: 'v-list-item v-list-item--link theme--light black--text',
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'],
      defaultMenuOrientation: 'top',
      source: (searchTerm, renderList, mentionChar) => {
        let values

        if (mentionChar === '@') {
          values = mentions
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm)
        } else {
          const matches = []
          for (let i = 0; i < values.length; i++)
            if (
              ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            )
              matches.push(values[i])
          renderList(matches, searchTerm)
        }
      },
    },
  },
})
