<template>
  <v-sheet :width="scale">
    <v-card
      elevation="0"
      :width="scale"
      :height="scale"
      class="rounded fill-height align-center justify-center d-flex upload-box"
      @mouseover="hoverPic"
      @mouseout="outPic"
      @click="pickFile"
    >
      <v-overlay v-show="picHovered" absolute
        ><v-icon>upload</v-icon></v-overlay
      >
      <v-overlay v-show="!picHovered && !value" :opacity="0" absolute>
        <v-icon color="secondary">upload</v-icon>
      </v-overlay>
      <img width="100%" height="100%" class="rounded" :src="value" />
      <v-file-input
        ref="file"
        v-model="files"
        type="file"
        style="display: none"
        accept="image/png,image/jpeg,image/jpg,image/webp"
        @change="readURL"
      />
    </v-card>
    <v-progress-linear
      v-if="filesLoading"
      v-model="imageProgress"
      class="my-2 rounded"
      width="100"
      height="20"
    >
      <template #default="{ value: v }">
        <strong>{{ Math.ceil(v) }}%</strong>
      </template>
    </v-progress-linear>
  </v-sheet>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ImageField',
  props: {
    scale: {
      type: Number,
      default: 100,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      files: [],
      picHovered: false,
      filesLoading: false,
      imageProgress: 0,
    }
  },
  methods: {
    hoverPic() {
      this.picHovered = true
    },
    outPic() {
      this.picHovered = false
    },
    readURL() {
      this.filesLoading = true
      const formData = new FormData()
      formData.append('upload_preset', 'ie2mgrcv')
      formData.append('file', this.files)
      return axios
        .post('https://api.cloudinary.com/v1_1/do4tedxg6/upload', formData, {
          baseURL: null,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / this.files.size
            )
            this.imageProgress = progress > 100 ? 100 : progress
          },
        })
        .then(result => {
          const urls = result.data.secure_url
          this.$emit('update:value', urls)
          this.$emit('on-change', urls)
        })
        .catch(err => (this.error = err))
        .then(() => {
          this.filesLoading = false
        })
    },
    pickFile() {
      this.$refs.file.$refs.input.click()
    },
  },
}
</script>

<style scoped>
.upload-box {
  position: relative;
  cursor: pointer;
}
</style>
