<template>
  <v-card>
    <v-card-title class="text-h5 align-center bb-1">
      <v-icon class="pr-2" color="secondary">mdi-sync</v-icon>
      Loop Condition
      <v-spacer />
      <v-btn icon @click="onClose">
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-3 text-body-1">
      <p>This is a reacquiring task</p>
      <p>
        Is the following condition done? <br />
        <b>{{ condition }}</b>
      </p>
      <v-divider />
      <p class="mt-2">
        By pressing <b>"Yes"</b> the flow will continue and the next tasks will
        be created:
      </p>
      <ul v-if="listingTask" class="mb-4 mt-2 ml-3">
        <li v-for="(template, i) in listingTask.follow_up_templates" :key="i">
          {{ template.description }} |
          {{ template.create_days_after || 0 }} days from now.
        </li>
      </ul>
      <v-divider />
      <p class="mt-2">
        By pressing <b>"No"</b> a new task like this one will be created for you
        <span v-if="listingTask" class="bolder">{{
          listingTask
            ? `in ${listingTask.listing_task_template.days_after_prev_loop_iteration} days`
            : ''
        }}</span
        >.
      </p>
    </v-card-text>

    <v-card-actions class="justify-space-around">
      <v-btn depressed @click="onClose">Cancel</v-btn>
      <v-spacer />
      <v-btn color="secondary" @click="onNo">No</v-btn>
      <v-btn color="success" @click="onYes">Yes</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'LtLoopMoveToDoneModal',
  props: ['condition', 'payload', 'afterChange', 'onChange', 'listingTask'],
  methods: {
    onClose() {
      this.$store.commit('hideModal')
    },
    async onNo() {
      this.onChange && this.onChange(res)
      const res = await this.$store.dispatch('updateListingTask', {
        ...this.payload,
        status: 'Done',
      })
      this.onClose()
      this.afterChange && this.afterChange(res)
    },
    async onYes() {
      this.onChange && this.onChange(res)
      const res = await this.$store.dispatch('updateListingTask', {
        ...this.payload,
        status: 'Done',
        in_loop: false,
      })
      this.onClose()
      this.afterChange && this.afterChange(res)
    },
  },
}
</script>

<style scoped></style>
