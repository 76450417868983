<template>
  <v-sheet
    ref="s-wrap"
    v-hotkey="filterKeyMap"
    class="s-wrap scrollable-dvr pa-0"
  >
    <v-list class="py-0">
      <v-list-item
        v-for="item in chatConversations"
        :key="item.id"
        :class="[conversationClass(item), 'pointer chat-box-separator']"
        @click="changeChosenConversation(item)"
      >
        <v-list-item-content class="py-0">
          <ChatGuestBox :item="item" />
        </v-list-item-content>
      </v-list-item>
      <mugen-scroll
        v-if="
          conversationsPagination &&
          conversationsPagination.conversations_count > 29
        "
        scroll-container="s-wrap"
        class="center-text"
        :handler="onScroll"
        :should-handle="!listingLoading"
      >
        loading...
      </mugen-scroll>
    </v-list>
  </v-sheet>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import MugenScroll from 'vue-mugen-scroll'
import { mapActions, mapMutations, mapState } from 'vuex'
import ChatGuestBox from 'components/conversations/chat-guest-box'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['changeMobile', 'conversations', 'currentConversation'],
  data() {
    return {}
  },
  mounted() {
    if (this.$route.params.id) {
      this.getChatConversation(this.$route.params.id)
      this.updateChatMobileView('conversation')
    }
  },
  computed: {
    ...mapState(['chatConversations', 'conversationsPagination']),
    filterKeyMap() {
      return {
        'ctrl+g': this.focusGuestSearch,
      }
    },
  },
  watch: {
    '$route.params.id'(newId, oldId) {
      if (newId !== oldId) {
        this.getChatConversation(newId)
        this.updateChatMobileView('conversation')
      }
    },
    currentConversation(item, oldItem) {
      if (
        (item.extra_data.listing_id && !oldItem) ||
        item.extra_data.listing_id !== oldItem.extra_data.listing_id
      ) {
        this.changeEditListingBG(item.extra_data.listing_id)
      }
    },
  },
  components: {
    ChatGuestBox,
    MugenScroll,
  },
  methods: {
    ...mapMutations([
      'updateConversationsPagination',
      'updateChosenChatConversation',
      'updateChatMobileView',
      'changeEditListing',
    ]),
    ...mapActions([
      'getChatConversation',
      'changeEditListingBG',
      'getPricingMonthlyStats',
    ]),
    focusGuestSearch() {
      this.$refs.guestSearchRef.focus()
    },
    changeChosenConversation(item) {
      if (this.$route.params.id !== item.guesty_id) {
        this.$router.push({
          name: 'guests-chat',
          params: { id: item.guesty_id },
        })
      }
    },
    conversationClass(conv) {
      if (this.currentConversation && this.currentConversation.id === conv.id) {
        return 'grey lighten-2'
      }
      return ''
    },
    unRead(conversation) {
      return (
        conversation &&
        (conversation.marked_as_unread ||
          !(
            conversation.extra_data['read_by'] &&
            conversation.extra_data['read_by'].length !== 0
          ))
      )
    },
    onScroll() {
      if (!this.listingLoading) {
        this.$emit('scroll')
      }
    },
  },
}
</script>

<style scoped>
.address-text {
  line-height: 1;
  font-size: 10px;
}
.chat-user-details {
  line-height: 1;
}
.chat-box-separator {
  border-bottom: 1px solid var(--v-secondary-base);
}

.chat-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 5px;
  font-weight: 700;
}
</style>
