<template>
  <v-combobox
    v-model="itemName"
    :items="items"
    :rules="rules"
    :search-input.sync="search"
    :hide-details="hideDetails"
    :outlined="outlined"
    dense
    :label="label"
    @input="onChange"
    @keydown="onKeydown"
  >
    <template #append>
      <v-progress-circular
        v-show="isLoading"
        :size="20"
        :width="2"
        indeterminate
        color="primary"
      />
    </template>
  </v-combobox>
</template>

<script>
import debounce from 'lodash/fp/debounce'
import axios from 'axios'

export default {
  name: 'ShopItemField',
  props: ['rules', 'value', 'hideDetails', 'outlined', 'label'],
  data() {
    return {
      isLoading: false,
      search: '',
      itemName: this.value,
      items: [],
    }
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
      console.log('changed')
    },
    onKeydown() {
      this.isLoading = true
      this.getItemNames(this.search)
    },
    getItemNames: debounce(500, async function (val) {
      const { data } = await axios.get('/api/shopping-items/item-names', {
        params: { search: val },
      })
      this.isLoading = false
      this.items = data
    }),
  },
  watch: {
    value(val) {
      this.itemName = val
    },
  },
}
</script>

<style scoped></style>
