<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.501"
    height="20.001"
    viewBox="0 0 17.501 20.001"
  >
    <path
      class="a"
      d="M21.176,6.4a17.714,17.714,0,0,0-1.7-1.947,17.715,17.715,0,0,0-1.947-1.7,3.116,3.116,0,0,0-1.776-.824H6.061A1.564,1.564,0,0,0,4.5,3.491V20.367A1.564,1.564,0,0,0,6.061,21.93H20.437A1.564,1.564,0,0,0,22,20.367V8.178A3.115,3.115,0,0,0,21.176,6.4ZM18.589,5.339a17.049,17.049,0,0,1,1.418,1.589H17V3.921a17.032,17.032,0,0,1,1.589,1.418ZM20.75,20.367a.317.317,0,0,1-.313.313H6.061a.317.317,0,0,1-.313-.313V3.491a.317.317,0,0,1,.313-.313H15.75V7.553a.625.625,0,0,0,.625.625H20.75Zm-3.125-2.188H8.874a.625.625,0,1,1,0-1.25h8.751a.625.625,0,1,1,0,1.25Zm0-2.5H8.874a.625.625,0,1,1,0-1.25h8.751a.625.625,0,1,1,0,1.25Zm0-2.5H8.874a.625.625,0,1,1,0-1.25h8.751a.625.625,0,1,1,0,1.25Z"
      transform="translate(-4.499 -1.928)"
    />
  </svg>
</template>

<script>
export default {
  name: 'File',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
