<template>
  <confirmation-modal
    :text="`Are you sure you want to ${
      storeSupport ? 'enable' : 'disable'
    } store support?`"
    @action="$emit('change', storeSupport)"
    @cancel="storeSupport = !storeSupport"
  >
    <template #activator="{ on }">
      <v-btn-toggle v-model="storeSupport" v-on="on">
        <v-btn :value="false" active-class="error white--text" v-on="on">
          Store Off
        </v-btn>
        <v-btn
          :value="true"
          active-class="success darken-1  white--text"
          v-on="on"
        >
          Store On
        </v-btn>
      </v-btn-toggle>
    </template>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from 'components/modals/confirmation-modal'

export default {
  components: {
    ConfirmationModal,
  },
  props: ['config'],
  data() {
    return {
      storeSupport: this.config.store_support || false,
    }
  },
}
</script>

<style></style>
