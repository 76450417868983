<template>
  <v-card class="overflow-auto" height="700px">
    <contractors-list
      v-if="isContractorScope"
      :tasks="tasks"
      :directions-info="directionsInfo"
      @task-click="taskClick"
      @user-click="userClick"
      @change-to-task="taskChanged"
    />
    <v-list v-else :key="listKey" nav dense class="pa-0">
      <v-list-item-group :value="selectedItem">
        <task-map-item
          v-for="task in orderedTasks"
          :key="task.id"
          :task="task"
          :task-order-map="taskOrderMap"
          @task-click="taskClick"
        />
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import ContractorsList from 'components/contractors-management/contractors-list'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import TaskMapItem from 'components/tasks-map-view/task-map-item'

export default {
  components: { ContractorsList, TaskMapItem },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [
    'tasks',
    'taskOrderMap',
    'listingTask',
    'directionsInfo',
    'isContractorScope',
  ],
  data() {
    return {
      selectedItem: null,
      listKey: 0,
    }
  },
  computed: {
    orderedTasks() {
      return Object.values(this.taskOrderMap)
        .flat()
        .map(id => this.tasks.find(task => task.id === id))
    },
  },
  watch: {
    listingTask(val) {
      this.listKey++
      this.selectedItem =
        val && this.orderedTasks.findIndex(t => t.id === val.id)
    },
  },
  methods: {
    taskChanged() {
      this.$emit('change-to-task')
    },
    taskClick(task) {
      this.$emit('task-click', task)
    },
    userClick(user) {
      this.$emit('user-click', user)
    },
  },
}
</script>

<style></style>
