<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card v-if="settings.automations">
          <v-card-title class="text-h6">Automations</v-card-title>
          <v-divider />
          <div class="pa-5">
            <div class="d-flex align-center">
              <div class="flex-grow-1">Cleaning</div>
              <div>
                <v-btn-toggle
                  v-model="settings.automations.enableCleaning"
                  mandatory
                  @change="save"
                >
                  <v-btn :value="false" small active-class="error white--text">
                    Off
                  </v-btn>
                  <v-btn :value="true" small active-class="success white--text">
                    On
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
            <v-divider class="my-5" />
            <div class="d-flex align-center">
              <div class="flex-grow-1">Refresh</div>
              <div>
                <v-btn-toggle
                  v-model="settings.automations.enableRefresh"
                  mandatory
                  @change="save"
                >
                  <v-btn :value="false" small active-class="error white--text">
                    Off
                  </v-btn>
                  <v-btn :value="true" small active-class="success white--text">
                    On
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mixins: [],
  components: {},
  data() {
    return {
      settings: {},
    }
  },
  async beforeMount() {
    this.settings = await this.$store.dispatch('getSettings')
  },
  methods: {
    save() {
      this.$store.dispatch('updateSettings', this.settings)
    },
  },
}
</script>
