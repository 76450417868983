<template>
  <div>
    <v-dialog v-model="dialog" max-width="380px">
      <v-card class="pb-4">
        <div class="d-flex justify-space-between font-weight-bold pa-4">
          <span class="text-uppercase"> This task total </span>
          <span>{{ totalTime(working, 'duration') }} min</span>
        </div>
        <v-text-field
          v-model="searchTerm"
          class="mb-4 px-4"
          label="Search"
          prepend-inner-icon="search"
          outlined
          dense
          hide-details
        />
        <div v-if="currentlyWorkingCleaners.length">
          <v-divider />
          <div class="font-weight-bold pa-4">Currently working</div>
          <v-list class="py-0" dense>
            <v-list-item
              v-for="cleaner in currentlyWorkingCleaners"
              :key="cleaner.id"
            >
              <v-list-item-title>
                <div class="d-flex justify-space-between align-center">
                  <div class="flex-grow-1">
                    <div
                      v-if="
                        cleaner.user_details.first_name &&
                        cleaner.user_details.last_name
                      "
                      class="font-weight-medium"
                    >
                      {{ cleaner.user_details.first_name }}
                      {{ cleaner.user_details.last_name }}
                    </div>
                    <div v-else>
                      {{ cleaner.user_details.email }}
                    </div>
                    <div class="text-caption text-capitalize secondary--text">
                      {{ formatRole(cleaner.user_details.role) }}
                    </div>
                  </div>
                  <div class="text-right">
                    <span>{{ clock[cleaner.user_details.id] }}</span>
                    <v-btn class="warning" elevation="0" dark icon x-small>
                      <v-icon
                        x-small
                        @click="stopClocking(cleaner.user_details.id, task.id)"
                        >mdi-stop
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider class="mt-3 mb-3"></v-divider>
        </div>
        <v-list
          v-for="(cleanerList, index) in [taskCleaners, filteredCleaners]"
          :key="index"
          class="py-0"
          dense
        >
          <v-list-item
            v-for="cleaner in cleanerList"
            :key="cleaner.id"
            :class="{ 'grey lighten-3': expand[cleaner.id] }"
          >
            <v-list-item-content>
              <v-list-item-title>
                <div
                  class="d-flex justify-space-between align-center"
                  :class="{ 'cyan-icon': cleanerTotalTime(cleaner.id, task) }"
                >
                  <div
                    class="flex-grow-1"
                    :class="{ pointer: cleanerTotalTime(cleaner.id, task) }"
                    @click="
                      cleanerTotalTime(cleaner.id, task) &&
                        expandToggle(cleaner.id)
                    "
                  >
                    <div
                      v-if="cleaner.first_name && cleaner.last_name"
                      class="font-weight-medium"
                    >
                      <v-icon
                        v-if="currentUserId === cleaner.id"
                        x-small
                        class="ml-1"
                        title="My Task"
                        color="notification"
                        >fas fa-star
                      </v-icon>
                      {{ cleaner.first_name }} {{ cleaner.last_name }}
                    </div>
                    <div v-else>
                      <v-icon
                        v-if="currentUserId === cleaner.id"
                        x-small
                        class="ml-1"
                        title="My Task"
                        color="notification"
                        >fas fa-star
                      </v-icon>
                      {{ cleaner.email }}
                    </div>
                    <div class="text-caption text-capitalize secondary--text">
                      {{ formatRole(cleaner.role) }}
                    </div>
                  </div>
                  <div class="text-right">
                    <v-btn class="green" elevation="0" dark icon x-small>
                      <v-icon
                        x-small
                        @click="startClocking(cleaner.id, task.id)"
                        >mdi-play
                      </v-icon>
                    </v-btn>
                    <div class="text-caption secondary--text">
                      {{ cleanerTotalTime(cleaner.id, task) }} min
                    </div>
                  </div>
                </div>
              </v-list-item-title>
              <v-expand-transition v-if="cleanerTotalTime(cleaner.id, task)">
                <v-list v-show="expand[cleaner.id]" dense color="transparent">
                  <v-list-item
                    v-for="time in cleanerTimes(cleaner.id, task)"
                    :key="time.id"
                    class="justify-space-between text-body-2"
                  >
                    <div v-if="editTimeId !== time.id">
                      {{ time.duration }} min
                    </div>
                    <v-text-field
                      v-else
                      v-model.number="editTimeDuration"
                      type="number"
                      dense
                      :rules="[required, isPositive]"
                      :min="1"
                      style="flex: 3"
                    />
                    <div class="flex-grow-1 text-right">
                      {{ parseDate(time.clocked_in) }}
                    </div>
                    <div
                      v-if="isAdmin || hasAbility('clocking-management')"
                      class="ml-10"
                    >
                      <v-btn
                        v-if="editTimeId === time.id"
                        icon
                        color="primary"
                        :disabled="
                          !isNumber(editTimeDuration) || editTimeDuration < 1
                        "
                        @click="confirmEditTimeDuration(time)"
                      >
                        <v-icon>done</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="editTimeId !== time.id"
                        icon
                        color="primary"
                        @click="startEditTimeDuration(time)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                      <ConfirmationModal
                        v-if="editTimeId !== time.id"
                        text="Are you sure you want to delete this timing?"
                        @action="removeUserClockIn(time.id)"
                      >
                        <template #activator="{ on }">
                          <v-btn icon color="primary" v-on="on">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                      </ConfirmationModal>
                    </div>
                  </v-list-item>
                </v-list>
              </v-expand-transition>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </v-list>
        <div
          v-if="isAdmin || hasAbility('clocking-management')"
          class="py-2 px-4"
        >
          <span class="orange--text small-text">
            Editing tracked time will not affect already set time spent. To
            change It, please clear Time spent hours.
          </span>
        </div>
      </v-card>
    </v-dialog>
    <v-btn
      v-if="
        isPropertyManager ||
        isAdmin ||
        onPayroleContractor ||
        isCleaningManager ||
        isCleaningSupervisor
      "
      color="light300"
      elevation="0"
      class="text-left"
      @click="showClockInList"
    >
      <div class="pt-1">
        <div
          v-if="!isMobileOrIPad"
          style="font-size: 0.625rem"
          :class="{
            'green--text': currentlyWorkingCleaners.length,
            'secondary--text': !currentlyWorkingCleaners.length,
          }"
        >
          {{ $t('Time Tracked') }}
        </div>
        <div>
          <v-icon
            :color="currentlyWorkingCleaners.length ? 'green' : 'info'"
            x-small
            >$clock
          </v-icon>
          <span
            :class="[
              {
                'green--text': currentlyWorkingCleaners.length,
                'info--text': !currentlyWorkingCleaners.length,
              },
              'text-caption',
              'text-lowercase',
              'ml-1',
            ]"
          >
            {{ totalTime(working, 'duration') }} {{ $t('Min') }}
          </span>
        </div>
      </div>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { filter, sumBy, now, sortBy } from 'lodash'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormRules from 'components/mixins/form-rules-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'
import DeviceMixin from 'components/mixins/device-mixin'
import ClockInsMixin from 'components/mixins/clock-ins-mixin'

export default {
  components: { ConfirmationModal },
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    FormRules,
    DeviceMixin,
    ClockInsMixin,
  ],
  props: ['task'],
  data: function () {
    return {
      dialog: false,
      searchTerm: '',
      expand: {},
      editTimeId: null,
      editTimeDuration: null,
    }
  },
  mounted() {
    if (this.currentlyWorkingCleaners.length) {
      this.currentlyWorkingCleaners.forEach(item => {
        this.clocking(item.user_details.id, new Date(item.clocked_in))
      })
    }
  },
  beforeDestroy() {
    if (Object.keys(this.timer).length) {
      Object.keys(this.timer).forEach(t => clearInterval(this.timer[t]))
    }
  },
  computed: {
    ...mapGetters(['currentUserId']),
    available() {
      if (this.isCleaningSupervisor || this.isCleaningManager) {
        return filter(this.task.available_cleaners, cleaner =>
          ['cleaner', 'cleaning-manager', 'cleaning-supervisor'].includes(
            cleaner.role
          )
        )
      } else if (this.isContractor) {
        return filter(
          this.task.available_cleaners,
          cleaner => cleaner.id === this.currentUserId
        )
      } else {
        return this.task.available_cleaners
      }
    },
    taskCleaners() {
      const t = this.task
      const contractors = [
        t.assigned_contractor_id,
        t.assigned_contractor1_id,
        t.assigned_contractor2_id,
        t.assigned_contractor3_id,
        t.assigned_contractor4_id,
      ]
      return this.available.filter(
        c =>
          this.cleanerTotalTime(c.id, this.task) || contractors.includes(c.id)
      )
    },
    nonTaskCleaners() {
      const taskCleaners = this.taskCleaners.map(t => t.id)
      return this.available.filter(c => !taskCleaners.includes(c.id))
    },
    filteredCleaners() {
      if (this.searchTerm) {
        return filter(
          this.nonTaskCleaners,
          c =>
            (c.name &&
              c.name.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (c.email &&
              c.email.toLowerCase().includes(this.searchTerm.toLowerCase()))
        )
      } else {
        return sortBy(this.nonTaskCleaners, ['duration'])
      }
    },
    working() {
      return this.task.working_cleaners
    },
    currentlyWorkingCleaners() {
      return filter(this.task.working_cleaners, cleaner => !cleaner.duration)
    },
  },
  methods: {
    expandToggle(id) {
      this.$set(this.expand, id, !this.expand[id])
    },
    showClockInList() {
      this.dialog = true
    },
    removeUserClockIn(timeId) {
      this.$store.dispatch('removeUserClockIn', timeId)
    },
    startEditTimeDuration(time) {
      this.editTimeId = time.id
      this.editTimeDuration = time.duration
    },
    confirmEditTimeDuration(time) {
      this.$store.dispatch('updateListingTaskClocking', {
        timeId: this.editTimeId,
        duration: this.editTimeDuration,
      })
      time.duration = this.editTimeDuration
      this.editTimeId = null
      this.editTimeDuration = null
    },
  },
}
</script>
