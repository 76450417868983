<template>
  <form-dialog
    ref="`LosSetting${losSetting.id}`"
    :title="$t('Edit LOS Setting')"
    :max-width="900"
    @submit="updateLosSettingForm"
  >
    <template #activator="{ on }">
      <v-btn
        color="primary"
        class="text-capitalize"
        icon
        elevation="0"
        v-on="on"
      >
        <v-icon size="25">edit</v-icon>
      </v-btn>
    </template>
    <v-row class="align-center">
      <v-col cols="12">
        <v-text-field
          v-model="losSetting.name"
          outlined
          dense
          :label="$t('Name')"
          required
          :rules="[required]"
        />
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="losSetting.active"
          :class="losSetting.active ? 'cyan-icon' : 'grey-text'"
          :label="losSetting.active ? 'ON' : 'OFF'"
        >
        </v-switch>
      </v-col>
      <v-col cols="6">
        <date-picker
          :label="$t('Valid From')"
          :value="parseDate(losSetting.from)"
          min="2019-01-01"
          required
          @change="saveFrom"
        />
      </v-col>
      <v-col cols="6">
        <date-picker
          :label="$t('Valid To')"
          :value="parseDate(losSetting.to)"
          min="2019-01-01"
          required
          @change="saveTo"
        />
      </v-col>
      <v-col cols="12">
        <multi-property-picker
          v-model="losSetting.whitelist"
          :label="$t('All Listings')"
          multiple
          :hide-btns="true"
        />
      </v-col>
      <v-col cols="12">
        <multi-property-picker
          v-model="losSetting.blacklist"
          :disabled="losSetting.whitelist.length !== 0"
          :label="$t('Blacklisted listings')"
          :hide-btns="true"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="losSetting.discount"
          label="Discount (%)"
          type="number"
          required
          outlined
          dense
          :rules="[required, max(500), min(-100)]"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="losSetting.nights"
          outlined
          dense
          :label="$t('Nights')"
          required
          :rules="[required, min(0), max(35)]"
        />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import DatePicker from 'components/form-fields/date-picker.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LosSettingEditModal',
  components: { FormDialog, MultiPropertyPicker, DatePicker },
  mixins: [FormRules, FormattersMixin],
  props: ['losSetting'],
  data() {
    return {}
  },

  computed: {},
  methods: {
    ...mapActions('losSetting', ['updateLosSetting']),
    async updateLosSettingForm() {
      this.$store.commit('updateLoading', true)
      await this.$store
        .dispatch('losSetting/updateLosSetting', {
          id: this.losSetting.id,
          los_setting: this.losSetting,
        })
        .finally(this.$store.commit('updateLoading', false))
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    saveFrom(date) {
      this.losSetting.from = date
    },
    saveTo(date) {
      this.losSetting.to = date
    },
  },
}
</script>

<style scoped></style>
