<template>
  <div class="taskim-task-actions flex-center">
    <v-tooltip bottom open-delay="200">
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          color="info"
          target="_blank"
          :to="addressLinkTo"
          icon
          small
          v-on="on"
        >
          <v-icon small>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
      <span>Open In New Tab</span>
    </v-tooltip>

    <v-tooltip bottom open-delay="200">
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <copy-clipboard color="info" :text="addressLink" />
        </span>
      </template>
      <span>Copy Link To Clipboard</span>
    </v-tooltip>

    <v-tooltip v-if="!isDynamicSection" bottom open-delay="200">
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <lt-taskim-btn
            :listing-task-id="task.id"
            :small="true"
            :move-from-section-id="section.id"
          />
        </span>
      </template>
      <span>Move To Another Section</span>
    </v-tooltip>

    <ConfirmationModal
      v-if="!isDynamicSection"
      :text="`Are you sure you want to remove this task ?\n\n${task.description}`"
      @action="removeFromSection"
    >
      <template #activator="{ on }">
        <v-tooltip bottom open-delay="200">
          <template #activator="tooltipSlotData">
            <span v-bind="tooltipSlotData.attrs" v-on="tooltipSlotData.on">
              <v-btn color="info" icon small v-on="on">
                <v-icon small>mdi-timeline-remove-outline</v-icon>
              </v-btn>
            </span>
          </template>
          <span>Remove From Section</span>
        </v-tooltip>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from 'components/modals/confirmation-modal'
import CopyClipboard from 'components/copy-clipboard'
import { mapActions } from 'vuex'
import LtTaskimBtn from './lt-taskim-btn'

export default {
  name: 'TaskimTaskActions',
  components: { LtTaskimBtn, CopyClipboard, ConfirmationModal },
  mixins: [],
  props: ['section', 'task'],
  computed: {
    addressLinkTo() {
      return { path: `/dashboard/listing-task/${this.task.id}` }
    },
    addressLink() {
      return `${window.location.origin}/dashboard/listing-task/${this.task.id}`
    },
    isDynamicSection() {
      return this.section.id === 'query'
    },
  },
  methods: {
    ...mapActions('taskim', ['updateSectionTask']),
    async removeFromSection() {
      await this.updateSectionTask({
        sectionId: this.section.id,
        taskId: this.task.id,
        operation: 'remove',
      })
    },
  },
}
</script>
<style scoped lang="scss"></style>
