<template>
  <div class="d-flex">
    <v-tabs v-model="tab" class="ma-4">
      <v-tab href="#housekeeping">{{ $t('Housekeeping') }} </v-tab>
      <v-tab href="#multicalendar"> {{ $t('Multi calendar') }}</v-tab>
      <v-tab href="#search"> {{ $t('Listing search') }}</v-tab>
      <v-tab href="#reviews"> {{ $t('Reviews') }}</v-tab>
      <v-tab href="#reservations"> {{ $t('Reservations') }}</v-tab>
      <v-tab-item id="search" class="ma-4">
        <listings-search :can-reserve="true" />
      </v-tab-item>
      <v-tab-item id="multicalendar" class="ma-4">
        <multi-calendar />
      </v-tab-item>
      <v-tab-item id="housekeeping" class="ma-4">
        <housekeeping-tab />
      </v-tab-item>
      <v-tab-item id="reviews" class="ma-4">
        <ReviewsTable />
      </v-tab-item>
      <v-tab-item id="reservations" class="ma-4">
        <ReservationManager />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import HousekeepingTab from 'components/hotel-dashboard/housekeeping-tab.vue'
import ListingsSearch from 'components/listings-search'
import ReviewsTable from 'components/reviews/reviews-table'
import MultiCalendar from 'components/calendar/multi-calendar'
import ReservationManager from 'components/reservation-manager'

export default {
  components: {
    MultiCalendar,
    HousekeepingTab,
    ReviewsTable,
    ListingsSearch,
    ReservationManager,
  },
  data() {
    return {
      tab: 'search',
    }
  },
}
</script>
<style></style>
