<template>
  <v-sheet height="100%" style="overflow: hidden">
    <div class="d-flex justify-space-between pa-3 secondary">
      <div class="text-subtitle-1 font-weight-medium">{{ title }}</div>
      <v-btn icon small :to="{ path: '' }">
        <v-icon class="white--text">close</v-icon>
      </v-btn>
    </div>
    <v-tabs v-model="tab" class="overflow-y-auto">
      <v-tab href="#comment">
        <v-icon class="mr-md-2">mdi-text-box-outline</v-icon>
        <span class="d-none d-md-inline text-capitalize">{{
          $t('Comments')
        }}</span>
      </v-tab>
      <v-tab href="#logs">
        <v-icon class="mr-md-2">mdi-clipboard-text-outline</v-icon>
        <span class="d-none d-md-inline text-capitalize">{{ $t('Logs') }}</span>
      </v-tab>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item v-if="tab === 'comment'" id="comment" class="py-3 px-5">
          <div>
            <editable-text-field
              v-if="$listeners['update-comment']"
              placeholder="Description"
              type="textarea"
              :value="internalComments"
              @onupdate="updateComment"
            />
            <div class="py-3">
              <text-editor
                :imgs-folder="`task/${taskId}/comments`"
                :mentions="usersItems"
                :autofocus="true"
                @text-submit="addFeedComment"
              />
            </div>
            <v-sheet no-gutters class="py-3 overflow-y-auto flex-grow-1">
              <div v-if="!commentsList.length" class="py-3">
                <div class="text-center">
                  <v-icon x-large color="secondary"
                    >fas fa-comment-slash</v-icon
                  >
                  <div class="pa-2 text--secondary text-h6">No comments</div>
                </div>
              </div>
              <div
                v-for="(comment, i) in commentsList"
                v-else
                :key="i"
                class="px-2"
              >
                <LtFeedItem
                  class="mt-2"
                  :unread="unseen.includes(comment.id)"
                  :comment="comment"
                  v-on="$listeners"
                />
                <div v-if="comment.replies.length" class="pl-4">
                  <LtFeedItem
                    v-for="reply in comment.replies"
                    :key="reply.id"
                    class="mt-2"
                    :comment="reply"
                    :unread="unseen.includes(comment.id)"
                  />
                </div>
              </div>
            </v-sheet>
          </div>
        </v-tab-item>
        <v-tab-item v-if="tab === 'logs'" id="logs" class="pl-2 pr-2">
          <LtLogs v-if="!isEmpty(logs)" class="mt-3" :logs="logs" />
          <div v-else class="pa-3 mt-3">
            <div class="text-center">
              <v-icon x-large color="secondary">rss_feed</v-icon>
              <div class="pa-2 text--secondary text-h6">No Logs</div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-sheet>
</template>

<script>
import TextEditor from 'components/common/text-editor'
import LtFeedItem from 'components/listing-tasks/lt-feed-item'
import CommonFunctions from 'components/mixins/common_functions'
import DeviceMixin from 'components/mixins/device-mixin'
import LtLogs from 'components/listing-tasks/lt-logs'
import { mapState } from 'vuex'
import EditableTextField from 'components/form-fields/editable-text-field.vue'

export default {
  name: 'LtFeed',
  components: { EditableTextField, LtFeedItem, TextEditor, LtLogs },
  mixins: [DeviceMixin, CommonFunctions],
  props: [
    'unseen',
    'commentsList',
    'logs',
    'title',
    'users',
    'internalComments',
    'taskId',
  ],
  data() {
    return {
      tab: 'messages',
    }
  },
  computed: {
    ...mapState('userGroups', ['groups']),
    usersItems() {
      const groups = this.groups.map(g => ({
        id: g.groups_users.map(u => u.user_id),
        value: g.name,
      }))
      const users = this.users.map(u => ({
        id: u.id,
        value: u.name,
      }))
      return users.concat(groups)
    },
  },
  methods: {
    addFeedComment(html) {
      this.$emit('add-message', { html })
    },
    updateComment(value) {
      this.$emit('update:internal-comments', value)
      this.$emit('update-comment', value)
    },
    internalCommentsInputValue() {
      return this.$refs.internalCommentsInput
        ? this.$refs.internalCommentsInput.$refs.input.value
        : this.internalComments
    },
  },
}
</script>

<style scoped>
.lt-comments-wrapper {
  z-index: 10;
}
</style>
