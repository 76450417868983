<template>
  <v-card>
    <v-card-title class="text-h5 align-center bb-1">
      <v-icon class="pr-2" color="secondary">mdi-home-group</v-icon>
      {{ title || 'Listings' }}
      <v-spacer />
      <v-btn icon @click="onClose">
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />

    <v-card-text class="pa-0 d-flex flex-column">
      <v-row class="px-5 text-title-1">
        <v-col cols="1">Picture</v-col>
        <v-col cols="3">Nickname</v-col>
        <v-col cols="5">Address</v-col>
        <v-col cols="2">Type</v-col>
        <v-col cols="1">Link</v-col>
      </v-row>
      <v-divider />
      <div class="px-5 flex-fill overflow-auto">
        <v-list v-if="filteredListings.length" dense>
          <div v-for="(listing, index) in filteredListings" :key="index">
            <v-row>
              <v-col cols="1" align-self="center">
                <v-avatar rounded width="60" height="40">
                  <v-img v-if="listing.picture" :src="listing.picture" />
                  <span
                    v-if="listing.nickname"
                    class="text-caption white--text"
                  >
                    {{ listing.nickname.slice(0, 2) }}
                  </span>
                </v-avatar>
              </v-col>
              <v-col cols="3">{{ listing.nickname }}</v-col>
              <v-col cols="5">{{ listing.address }}</v-col>
              <v-col cols="2">{{ listing.listing_type }}</v-col>
              <v-col cols="1">
                <v-btn icon @click="openListingInNewTab(listing.id)">
                  <v-icon color="primary">mdi-open-in-new</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider v-if="index < filteredListings.length - 1" />
          </div>
        </v-list>
        <div v-else class="pa-10 text-center">No results</div>
      </div>
    </v-card-text>

    <v-divider />
    <v-card-actions class="justify-space-around">
      <v-btn depressed @click="onClose">Close</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ListingsListModal',
  props: ['title', 'listings', 'filter'],
  computed: {
    ...mapGetters(['listingsPicker']),
    filteredListings() {
      if (this.filter) {
        return (this.listings || this.listingsPicker).filter(this.filter)
      }
      return this.listings || this.listingsPicker
    },
  },
  methods: {
    onClose() {
      this.$store.commit('hideModal')
    },
    openListingInNewTab(id) {
      window.open(`/dashboard/edit/${id}`, 'blank')
    },
  },
}
</script>

<style scoped></style>
