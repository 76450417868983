<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.6"
    height="24"
    viewBox="0 0 17.6 24"
  >
    <g transform="translate(-64)">
      <g transform="translate(64)">
        <g transform="translate(0)">
          <path
            class="a"
            d="M81.6,4.4a.4.4,0,0,0-.114-.283l-4-4A.4.4,0,0,0,77.2,0V0h-12A1.2,1.2,0,0,0,64,1.2V22.8A1.2,1.2,0,0,0,65.2,24H80.4a1.2,1.2,0,0,0,1.2-1.2V4.4Zm-4-3.034L80.234,4H78a.4.4,0,0,1-.4-.4ZM80.8,22.8a.4.4,0,0,1-.4.4H65.2a.4.4,0,0,1-.4-.4V1.2a.4.4,0,0,1,.4-.4H76.8V3.6A1.2,1.2,0,0,0,78,4.8h2.8Z"
            transform="translate(-64)"
          />
        </g>
      </g>
      <g transform="translate(66.8 10.8)">
        <path
          class="a"
          d="M121.2,216a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,121.2,216Zm0,1.6a.4.4,0,1,1,.4-.4A.4.4,0,0,1,121.2,217.6Z"
          transform="translate(-120 -216)"
        />
      </g>
      <g transform="translate(70.8 11.6)">
        <rect class="a" width="8" height="0.8" />
      </g>
      <g transform="translate(66.8 6.8)">
        <path
          class="a"
          d="M121.2,136a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,121.2,136Zm0,1.6a.4.4,0,1,1,.4-.4A.4.4,0,0,1,121.2,137.6Z"
          transform="translate(-120 -136)"
        />
      </g>
      <g transform="translate(70.8 7.6)">
        <rect class="a" width="8" height="0.8" />
      </g>
      <g transform="translate(66.8 14.8)">
        <path
          class="a"
          d="M121.2,296a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,121.2,296Zm0,1.6a.4.4,0,1,1,.4-.4A.4.4,0,0,1,121.2,297.6Z"
          transform="translate(-120 -296)"
        />
      </g>
      <g transform="translate(70.8 15.6)">
        <rect class="a" width="8" height="0.8" />
      </g>
      <g transform="translate(66.8 18.8)">
        <path
          class="a"
          d="M121.2,376a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,121.2,376Zm0,1.6a.4.4,0,1,1,.4-.4A.4.4,0,0,1,121.2,377.6Z"
          transform="translate(-120 -376)"
        />
      </g>
      <g transform="translate(70.8 19.6)">
        <rect class="a" width="8" height="0.8" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Files',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
