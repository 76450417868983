<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.889"
    height="23"
    viewBox="0 0 27.889 23"
  >
    <g transform="translate(0.5 0.5)">
      <path
        class="a"
        d="M21.056,29.833V27.389A4.889,4.889,0,0,0,16.167,22.5H6.389A4.889,4.889,0,0,0,1.5,27.389v2.444"
        transform="translate(-1.5 -7.834)"
      />
      <path
        class="a"
        d="M17.278,9.389A4.889,4.889,0,1,1,12.389,4.5a4.889,4.889,0,0,1,4.889,4.889Z"
        transform="translate(-2.611 -4.5)"
      />
      <path
        class="a"
        d="M33.667,29.869V27.425A4.889,4.889,0,0,0,30,22.7"
        transform="translate(-6.778 -7.87)"
      />
      <path
        class="a"
        d="M24,4.7a4.889,4.889,0,0,1,0,9.472"
        transform="translate(-5.667 -4.537)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PeopleIcon',
}
</script>

<style scoped>
.a {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
