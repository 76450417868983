<template>
  <v-card elevation="0" rounded="lg">
    <v-card-title class="text-caption secondary--text">
      {{ title }}
    </v-card-title>
    <v-divider class="mb-6" />
    <div :style="`height:${height}px`">
      <canvas :id="graphId"></canvas>
    </div>
  </v-card>
</template>

<script>
import isEqual from 'lodash/fp/isEqual'

export default {
  props: [
    'statsObject',
    'title',
    'graphId',
    'monthsNames',
    'color',
    'config',
    'height',
    'label',
  ],
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    statsObject(newVal, oldVal) {
      const { chart } = this
      if (chart && !isEqual(newVal, oldVal)) {
        this.draw()
      }
    },
  },
  mounted: function () {
    this.draw()
  },
  methods: {
    resetGraph: function () {
      if (this.chart) {
        this.chart.destroy()
      }
      const element = document.getElementById(this.graphId)
      const parent = element.parentNode
      parent.removeChild(element)
      const newElement = document.createElement('canvas')
      newElement.setAttribute('id', this.graphId)
      newElement.setAttribute('class', 'align-center')
      parent.appendChild(newElement)
    },
    draw: function () {
      this.resetGraph()
      const chart = window.Chart
      this.chart = new chart(document.getElementById(this.graphId), {
        type: 'line',
        data: {
          labels: this.monthsNames,
          datasets: [
            {
              data: this.statsObject,
              borderColor: this.color,
              fill: false,
              label: this.label || this.title,
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: !this.height,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
        ...(this.config || {}),
      })
    },
  },
}
</script>
