<template>
  <v-container v-if="!loading">
    <tasks-calendar
      v-if="listingTasksLoaded"
      :listing-id="listingId"
      :no-scroll="true"
      class="mb-5"
    />
    <warranties-active-items class="mt-5" :listing-id="listingId" />
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import TasksCalendar from 'components/calendar/tasks-calendar'
import filter from 'lodash/filter'
import WarrantiesActiveItems from 'components/warranties-active-items'

export default {
  components: {
    WarrantiesActiveItems,
    TasksCalendar,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['listingId'],
  computed: {
    listingStaticTasks() {
      return this.$store.state.listingStaticTasks
    },
    listingTasksLoaded() {
      return this.$store.state.listingTasksLoaded
    },
  },

  mounted() {
    this.$store.dispatch('getAutoListingTaskRules', {
      id: this.listingId,
    })
    setTimeout(() => {
      if (!this.listingTasksLoaded) {
        this.tasksLoaded = true
      }
    }, 10000)
    if (this.isAdmin || this.isPropertyManager) {
      this.$store.dispatch('getListingStaticTasks', {
        listing_id: this.listingId,
      })
    }
    this.getTaskCalMonth(this.listingId)
  },
  methods: {
    filterByListName(listName) {
      if (this.$store.state.listingStaticTasks) {
        return filter(
          this.$store.state.listingStaticTasks.tasks,
          task => task.department === listName
        )
      } else {
        return []
      }
    },
  },
}
</script>
