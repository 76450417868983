<template>
  <div class="grey lighten-4">
    <v-progress-linear v-show="conversationsLoading" indeterminate />
    <conversation-drawer @update-conversations="refreshCS" />
    <v-row v-if="stats && !isMobile"> </v-row>
    <v-tabs
      v-if="stats"
      v-model="tab"
      grow
      center-active
      slider-color="grey"
      :class="{ 'mt-0 mt-sm-2': true, 'hide-arrows': isMobile }"
      background-color="teal lighten-5"
      @change="tabChange"
    >
      <dashboard-tab
        href="#unanswered"
        label="unanswered"
        :value="stats.unanswered_count"
      />
      <dashboard-tab
        v-if="showModule('ai')"
        href="#ai-escalations"
        :label="isMobile ? 'AI' : 'AI Escalations'"
        :value="stats.ai_escalations_count"
      />
      <dashboard-tab
        href="#active_leads"
        label="Active"
        :value="stats.active_leads_count"
      />
      <dashboard-tab
        href="#follow_ups"
        label="Follow ups"
        :value="stats.follow_ups_count"
      />
      <dashboard-tab href="#all" label="All" />
    </v-tabs>
    <div>
      <div class="d-inline-block my-2">
        <contractor-select
          v-model="user"
          :filtered-items="usersFiltered"
          :label="$t('Search Users')"
          icon="mdi-magnify"
          :hide-details="true"
          :include-in-active="true"
          @input="onUserChange"
        />
      </div>
      <div v-if="isMobile && conversations">
        <v-divider />
        <conversations-mobile
          :items="conversations"
          :pagination="pagination"
          @paginate="paginate"
        />
      </div>
      <conversations-table
        v-else
        mode="cs"
        :headers="headers"
        :items="conversations"
        :options="pagination"
        @update-conversations="refreshCS"
        @row-click="rowClick"
        @paginate="paginate"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import dashboardTab from 'components/common/dashboard-tab.vue'
import formattersMixin from 'components/mixins/formatters-mixin'
import colorsMixin from 'components/mixins/colors-mixin'
import ConversationsTable from 'components/crm/conversations-table.vue'
import ConversationDrawer from './conversation-drawer.vue'
import DeviceMixin from 'components/mixins/device-mixin'
import ConversationsMobile from 'components/crm/conversations-mobile.vue'
import CommonFunctions from 'components/mixins/common_functions'
import contractorSelect from 'components/contractor-select.vue'
import { keyBy } from 'lodash/fp'

export default {
  components: {
    dashboardTab,
    ConversationsTable,
    ConversationDrawer,
    ConversationsMobile,
    contractorSelect,
  },
  mixins: [formattersMixin, colorsMixin, DeviceMixin, CommonFunctions],
  props: ['userGroup'],
  data() {
    return {
      conversations: null,
      tab: this.$route.query.conversation ? 'all' : 'unanswered',
      stats: null,
      conversationsLoading: false,
      pagination: { page: 1 },
      filters: {},
      drawer: false,
      conversationId: null,
      user: null,
      headers: [
        {
          text: 'User',
          value: 'user.full_name',
          sortable: false,
        },
        {
          text: 'Role',
          value: 'user.role',
          sortable: false,
        },
        {
          text: 'Last Msg',
          value: 'last_message_from_guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Assignee',
          value: 'assigned_user_id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      items: [{}],
    }
  },
  watch: {
    userGroup() {
      this.getUsers({ [this.tab]: true })
    },
  },
  computed: {
    ...mapState('users', ['allUsers']),
    usersMap() {
      return keyBy('id', this.allUsers)
    },
    usersFiltered() {
      return this.allUsers.filter(this.usersFilter)
    },
  },
  mounted() {
    this.getUsers({
      [this.tab]: true,
      conversation_id: this.$route.query.conversation,
    })
    this.intervalStats = setInterval(
      () => this.getUsers({ stats_only: true }),
      1000 * 30
    )
    this.intervalList = setInterval(() => this.refreshCS(), 1000 * 60)
  },
  beforeDestroy() {
    clearInterval(this.intervalStats)
    clearInterval(this.intervalList)
  },
  methods: {
    ...mapActions(['getUsersInfo']),
    refreshCS() {
      this.getUsers({
        [this.tab]: true,
        ...this.filters,
        page: this.pagination.page,
        hideLoader: true,
      })
    },
    async getUsers(filters = {}) {
      if (!filters.stats_only && !filters.hideLoader)
        this.conversationsLoading = true
      filters[this.userGroup] = true
      const { data } = await this.getUsersInfo({ ...filters })
      this.stats = data.stats

      if (filters.stats_only) return
      this.conversations = data.conversations
      this.pagination = {
        page: +data.pagination.page,
        itemsPerPage: data.pagination.per_page,
        totalItems: data.pagination.conversations_count,
      }
      this.conversationsLoading = false
    },
    tabChange(tab) {
      this.filters = {}
      this.getUsers({ [tab]: true, ...this.filters })
    },
    updateFilter(field, val) {
      this.filters = { ...this.filters, [field]: val }
      this.getUsers({ [this.tab]: true, ...this.filters })
    },
    paginate(page) {
      if (page === this.pagination.page) return
      this.getUsers({ [this.tab]: true, ...this.filters, page })
    },
    updateConversations() {
      this.getUsers({ [this.tab]: true, ...this.filters, page: 1 })
    },
    rowClick({ guesty_id }) {
      const query = { conversation: guesty_id }
      if (!guesty_id) query.newConvUser = this.user
      this.$router.push({ query })
    },
    onUserChange(user) {
      this.user = user
      const userObj = this.usersMap[user]
      this.rowClick({
        guesty_id: userObj.conversation?.guesty_id,
      })
    },
    usersFilter(u) {
      if (this.userGroup === 'owners') return u.role === 'investor'
      if (this.userGroup === 'contractors')
        return !['investor', 'tourist'].includes(u.role)
    },
  },
}
</script>
<style scoped>
::v-deep .v-data-footer {
  justify-content: end;
}
::v-deep .v-tabs-bar {
  height: 100%;
}
::v-deep .hide-arrows .v-slide-group__prev {
  display: none !important;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
