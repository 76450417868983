<template>
  <v-sheet width="100%">
    <v-card class="pa-4">
      <v-select
        v-model="value.calculation"
        dense
        class="mt-4 mb-2"
        outlined
        label="Calculation"
        :items="['active_listings', 'active_listings_with_reservations']"
      />
      <v-text-field
        v-model.number="value.per_door"
        class="mt-2 mb-2"
        label="PPD"
        outlined
        dense
      />
      <div class="mt-6">
        <div class="mb-4 text-h5 font-weight-bold">Charge Invoice</div>
        <div class="d-inline-flex align-center">
          <confirmation-modal
            v-if="value.paymentMethodId"
            :text="`Are you sure you want to charge ${
              value.company_name
            } ${toMoney(chargeAmount)} for ${invoiceDateDisplay}?`"
            @action="onCharge"
          >
            <template #activator="{ on }">
              <v-btn
                :disabled="!!lastInvoice"
                class="me-2"
                color="success"
                :loading="invoiceLoading"
                depressed
                v-on="on"
              >
                {{ lastInvoice ? 'Invoice Paid' : `Charge Invoice` }}
                ({{ invoiceDateDisplay }})</v-btn
              >
            </template>
          </confirmation-modal>
          <div v-else>No Card On file</div>
          <v-text-field
            v-if="!lastInvoice && value.paymentMethodId"
            v-model.number="value.invoice.charge.amount"
            label="Charge Amount"
            hide-details
            dense
            outlined
            prepend-inner-icon="mdi-currency-usd"
          >
          </v-text-field>
          <div class="ms-6">
            <v-dialog
              v-model="bannerDialog"
              class="white"
              persistent
              max-width="600px"
            >
              <template #activator="{ on }">
                <v-switch
                  v-model="billingInfo.payment_issue_banner"
                  color="error"
                  v-on="on"
                >
                  <template #label>
                    <div class="d-flex align-center">
                      <span class="error--text ms-1 font-weight-medium"
                        >Show Billing Issue Banner</span
                      >
                    </div>
                  </template>
                </v-switch>
              </template>
              <v-card class="pa-8 text-center">
                <div class="font-weight-medium text-h7">
                  {{
                    `Are you sure you want to ${
                      billingInfo.payment_issue_banner ? 'display' : 'hide'
                    } the billing issue banner?`
                  }}
                </div>
                <div
                  v-if="billingInfo.payment_issue_banner"
                  class="mt-6 d-inline-block"
                >
                  <v-text-field
                    v-model="billingInfo.payment_issue_banner_due"
                    label="Due date"
                    type="date"
                    hide-details
                    outlined
                    dense
                  />
                </div>
                <div class="mt-4">
                  <v-btn
                    style="width: 80px"
                    class="ma-2"
                    color="warning"
                    small
                    outlined
                    depressed
                    @click="cancelBanner"
                  >
                    Cancel</v-btn
                  >
                  <v-btn
                    style="width: 80px"
                    class="ma-2"
                    color="success"
                    small
                    :disabled="!billingInfo.payment_issue_banner_due"
                    depressed
                    @click="onToggleBanner"
                  >
                    Ok</v-btn
                  >
                </div>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
      <div class="mt-6">
        <billing-tab
          :tenant-id="value.tenant_id"
          :last4="value.last4"
          :tenant-settings="value"
        />
      </div>
      <div class="mt-6">
        <div class="d-flex justify-space-between">
          <div class="mb-4 text-h5 font-weight-bold">Contract Info</div>
          <div>
            <v-btn
              color="primary"
              depressed
              :disabled="!billingDirty"
              @click="save"
            >
              Save Billing Info</v-btn
            >
          </div>
        </div>
        <div class="mb-5">
          <div class="text-body-2 font-weight-medium mb-1 text--secondary">
            Active Modules:
          </div>
          <v-chip
            v-for="module in value.approved_modules"
            :key="module"
            small
            class="font-weight-medium me-1"
          >
            {{ module }}
          </v-chip>
        </div>
        <v-text-field
          v-model="billingInfo.contract_end"
          class="mt-2 mb-2 d-inline-block"
          label="Contract End"
          outlined
          hide-details
          type="date"
          dense
          @change="onChange"
          @focus="e => e.target.showPicker()"
        />
        <v-text-field
          v-model="billingInfo.min_charge"
          class="ma-2 d-inline-block"
          label="Min Charge"
          outlined
          hide-details
          type="number"
          dense
          @change="onChange"
        />
        <gallery
          :folder="`agreements/${value.company_name}`"
          :thumbnails="true"
          :cols="2"
          :images="billingInfo.contract_file ? [billingInfo.contract_file] : []"
          :remove-img="removeImg"
          :uploadable="true"
          :on-change="updateFile"
          button-text="Contract File"
        />
      </div>
    </v-card>
  </v-sheet>
</template>

<script>
import formattersMixin from 'components/mixins/formatters-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import { mapActions } from 'vuex'
import BillingTab from 'components/billing/billing-tab.vue'
import Gallery from 'components/common/gallery.vue'
import axios from '@/axios/config'

export default {
  props: ['value'],
  mixins: [formattersMixin],
  components: { ConfirmationModal, BillingTab, Gallery },
  data() {
    return {
      invoiceLoading: false,
      billingDirty: false,
      bannerDialog: false,
    }
  },
  methods: {
    ...mapActions('stripe', ['chargeInvoice']),
    async onCharge() {
      this.invoiceLoading = true
      await this.chargeInvoice({
        tenant_id: this.value.tenant_id,
        month: this.value.invoice.invoiceMonth,
        year: this.value.invoice.invoiceYear,
        amount: this.chargeAmount,
      }).catch(() => {
        this.invoiceLoading = false
      })
      this.invoiceLoading = false
      this.$emit('fetch-settings')
    },
    removeImg() {
      this.billingInfo.contract_file = null
    },
    updateFile(images) {
      this.billingInfo.contract_file = images[0]
      this.onChange()
    },
    cancelBanner() {
      this.bannerDialog = false
      this.billingInfo.payment_issue_banner =
        !this.billingInfo.payment_issue_banner
    },
    onToggleBanner() {
      this.bannerDialog = false
      this.save()
    },
    onChange() {
      this.billingDirty = true
    },
    async save() {
      axios.patch(`/api/settings/${this.value.tenant_id}/reports`, {
        billing_info: this.billingInfo,
      })
      this.billingDirty = false
    },
  },
  computed: {
    lastInvoice() {
      return this.value.invoice.lastInvoice
    },
    chargeAmount() {
      return this.value.invoice.charge.amount
    },
    billingInfo() {
      return this.value.billing_info
    },
    invoiceDateDisplay() {
      return this.$moment({
        year: this.value.invoice.invoiceYear,
        month: this.value.invoice.invoiceMonth - 1,
      }).format('MMM YYYY')
    },
  },
}
</script>

<style></style>
`
