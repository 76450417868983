<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.047"
    height="24.047"
    viewBox="0 0 24.047 24.047"
  >
    <path
      class="a"
      d="M30.023,16.5H11.763L20.18,8.081,18,5.977,5.977,18,18,30.023l2.1-2.1L11.763,19.5h18.26Z"
      transform="translate(-5.977 -5.977)"
    />
  </svg>
</template>

<script>
export default {
  name: 'LeftArrow',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
