<template>
  <v-layout
    v-if="
      isAdmin ||
      isPropertyManager ||
      isCommunicationManager ||
      isCleaningSupervisor
    "
    wrap
    justify-center
    class="center-text mt-4"
  >
    <v-flex xs12 class="d-flex justify-center">
      <v-flex sm8>
        <h4>
          Calendar Stats {{ rangeText }}
          <v-btn
            :loading="listingLoading"
            :disabled="listingLoading"
            class="cyanish reqBtn white--text mx-4"
            @click="refreshListing"
          >
            Refresh
            <template #loader>
              <span class="custom-loader">
                <v-icon light>cached</v-icon>
              </span>
            </template>
          </v-btn>
        </h4>
      </v-flex>
    </v-flex>
    <!--    <v-flex xs12 sm3 br-1 mt-2>-->
    <!--      <v-btn-->
    <!--        style="background-color: darkcyan;"-->
    <!--        :elevation="listStatus === 'Sugg' ? 4 : ''"-->
    <!--        class="rounded-card"-->
    <!--        x-large-->
    <!--        dark-->
    <!--        @click="changeList(newTasks, 'Sugg')"-->
    <!--      >-->
    <!--        Swaps ({{ suggTasks.length }})-->
    <!--      </v-btn>-->
    <!--    </v-flex>-->
    <v-flex xs12 sm2 mt-2>
      <v-btn
        style="background-color: red"
        :elevation="listStatus === 'New' ? 4 : ''"
        class="rounded-card"
        small
        dark
        @click="changeList(newTasks, 'New')"
      >
        New ({{ newTasks.length }})
      </v-btn>
    </v-flex>
    <v-flex xs12 sm2 mt-2>
      <v-btn
        style="background-color: #dfc12a"
        class="rounded-card"
        small
        dark
        @click="changeList(inPTasks, 'In Progress')"
      >
        In-P ({{ inPTasks.length }})
      </v-btn>
    </v-flex>
    <v-flex xs12 sm2 mt-2>
      <v-btn
        style="background-color: #c0a67e"
        class="rounded-card"
        small
        dark
        @click="changeList(pArrovalTasks, 'Pending Approval')"
      >
        P-App ({{ pArrovalTasks.length }})
      </v-btn>
    </v-flex>
    <v-flex xs12 sm2 mt-2>
      <v-btn
        style="background-color: #75c547"
        class="rounded-card"
        small
        dark
        @click="changeList(doneTasks, 'Done')"
      >
        Done ({{ doneTasks.length }})
      </v-btn>
    </v-flex>
    <v-flex v-if="!listingLoading" xs12 sm8 mt-3 justify-center>
      <h3
        v-if="listStatus && listStatus !== 'Sugg'"
        class="headline-font bb-1 bolder"
        mt-3
        mb-2
      >
        Showing tasks in status {{ listStatus }}
      </h3>
      <h3
        v-if="listStatus && listStatus === 'Sugg'"
        class="headline-font bb-1 bolder"
        mt-3
        mb-2
      >
        Showing suggestions for today
      </h3>
      <v-flex xs12>
        <v-text-field
          v-model="searchTerm"
          flat
          label="Search"
          append-icon="search"
          solo-inverted
          style="border: 1px grey solid"
          hide-details
          class="mb-4"
        ></v-text-field>
      </v-flex>
      <span
        v-if="isEmpty(currentList)"
        class="mt-3 grey-text headline-font center-text"
        >{{ $t('No tasks found') }}</span
      >
      <div v-if="!isEmpty(currentList)">
        <v-btn
          v-if="!someListingsSelected"
          class="cyan-icon white"
          @click="selectAll"
          >Select all
        </v-btn>
        <v-btn
          v-if="someListingsSelected"
          class="purple-icon"
          @click="UnSelectAll"
          >UnSelect all
        </v-btn>

        <div v-for="(item, index) in currentList" :key="index">
          <contractor-switch-tile
            v-if="listStatus === 'Sugg'"
            :after-save-func="afterSaveFunc"
            :index="index"
            :item="item"
            mt-1
          />
          <listing-task-tile
            v-else
            :task-clicked="setTaskClicked"
            :list-status="listStatus"
            :item="item"
          />
        </div>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import sortBy from 'lodash/sortBy'

import ContractorSwitchTile from './contractor-switch-tile'
import ListingTaskTile from './listing-tasks/listing-task-tile'

export default {
  components: {
    ListingTaskTile,
    ContractorSwitchTile,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['taskClicked', 'afterSaveFunc', 'showSwap', 'filterSc', 'filterCt'],
  data: () => ({
    listStatus: '',
    searchTerm: '',
  }),
  computed: {
    someListingsSelected() {
      return this.$store.state.ltdSelectedIDS.size > 0
    },
    currentList() {
      if (this.listStatus === 'New') {
        return this.newTasks
      } else if (
        this.listStatus === 'In Progress' ||
        this.listStatus === 'Rejected'
      ) {
        return this.inPTasks
      } else if (this.listStatus === 'Pending Approval') {
        return this.pArrovalTasks
      } else if (this.listStatus === 'Done') {
        return this.doneTasks
      } else if (this.listStatus === 'Sugg') {
        return this.suggTasks
      } else {
        return []
      }
    },
    rangeText() {
      return this.$store.state.listingTaskCalDate
    },
    suggTasks() {
      let tasks = []
      this.$store.state.taskCalendarSuggestions.forEach(task => {
        task.task.switch_data = {
          new_contractors: task.new_contractors,
          old_contractor_id: task.old_contractor_id,
          old_contractor_name: task.old_contractor_name,
          new_date: task.new_date,
          old_date: task.old_date,
        }
        tasks.push(task.task)
      })
      return sortBy(tasks, ['scheduled_at'])
    },
    newTasks() {
      let tasks = []
      this.$store.state.listingTasks.forEach(task => {
        if (
          task.status === 'New' &&
          this.passCalendarFilters(task) &&
          this.passSearchFilter(task)
        ) {
          tasks.push(task)
        }
      })
      return sortBy(tasks, ['scheduled_at'])
    },
    inPTasks() {
      let tasks = []
      this.$store.state.listingTasks.forEach(task => {
        if (
          (task.status === 'In Progress' || task.status === 'Rejected') &&
          this.passCalendarFilters(task) &&
          this.passSearchFilter(task)
        ) {
          tasks.push(task)
        }
      })
      return sortBy(tasks, ['scheduled_at'])
    },
    pArrovalTasks() {
      let tasks = []
      this.$store.state.listingTasks.forEach(task => {
        if (
          task.status === 'Done' &&
          !task.manual_approved &&
          this.passCalendarFilters(task) &&
          this.passSearchFilter(task)
        ) {
          tasks.push(task)
        }
      })
      return sortBy(tasks, ['scheduled_at'])
    },

    doneTasks() {
      let tasks = []
      this.$store.state.listingTasks.forEach(task => {
        if (
          task.status === 'Done' &&
          task.manual_approved &&
          this.passCalendarFilters(task) &&
          this.passSearchFilter(task)
        ) {
          tasks.push(task)
        }
      })
      return sortBy(tasks, ['scheduled_at'])
    },
  },
  watch: {
    rangeText() {
      this.listStatus = ''
    },
  },
  beforeDestroy() {
    this.$store.commit('updateLtdSelectedIDS', new Set())
  },
  methods: {
    refreshListing() {
      this.afterSaveFunc()
    },
    selectAll() {
      this.currentList.forEach(task => {
        if (!task.department) {
          this.addToLtdSelected(task)
        }
      })
    },
    UnSelectAll() {
      this.currentList.forEach(task => {
        if (!task.department) {
          this.addToLtdSelected(task)
        }
      })
    },

    availColor(task) {
      if (task.add_today_avialability_text === 'Check-in and Checkout today') {
        return 'orange--text bolder elevation-1'
      } else if (task.add_today_avialability_text === 'Check-in today') {
        return 'purple--text bolder elevation-1'
      } else if (task.add_today_avialability_text === 'Checkout today') {
        return 'yellow--text bolder elevation-1'
      } else if (task.add_today_avialability_text === 'Free today') {
        return 'green--text bolder elevation-1'
      }
    },

    passSearchFilter(task) {
      if (task.description) {
        return (
          task.description
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          this.itemListingNickname(task)
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase())
        )
      }
    },
    passCalendarFilters(task) {
      if (this.isSC(task)) {
        return this.filterSc(task)
      } else if (!this.isSC(task)) {
        return this.filterCt(task)
      }
    },
    setTaskClicked(item) {
      this.taskClicked(item)
    },

    changeList(list, listStatus) {
      this.listStatus = listStatus
    },
  },
}
</script>
