<template>
  <v-dialog v-model="dialog" persistent max-width="1100px">
    <v-card>
      <v-card-title class="white--text purpleish align-center">
        <span class="text-h5"
          >Match Transaction #{{ chosenTransaction.id }}</span
        >
        <v-progress-circular
          v-show="listingLoading"
          :size="20"
          class="ml-2"
          indeterminate
          color="primary"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            !chosenTransaction.manual_dismiss &&
            isEmpty(chosenTransaction.extra_data.attached_to)
          "
          class="float-right"
          @click="dismiss(chosenTransaction)"
        >
          Dismiss
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="d-flex">
            <div>
              <span class="font-weight-medium">Total:</span> <br />
              {{ chosenTransaction.amount.toLocaleString('en') }}
            </div>
            <div class="mx-5">
              <span class="font-weight-medium">Transferred at:</span> <br />
              {{ parseDate(chosenTransaction.transferred_at) }}
            </div>
          </div>
          <v-row v-if="!isEmpty(chosenTransaction.extra_data.attached_to)">
            <v-data-table
              :headers="attachedToHeaders"
              :items="chosenTransaction.extra_data.attached_to"
            >
              <template #item.amount="{ item }">
                {{ attachementPrice(item) }}
              </template>
              <template #item.created_by="{ item }">
                {{ attachementCreatedBy(item) }}
              </template>
              <template #item.listing="{ item }">
                {{ listingName(item) }}
              </template>
              <template #item.type="{ item }">
                {{ attachementType(item) }}
              </template>
              <template #item.date="{ item }">
                {{ parseDate(attachementDate(item)) }}
              </template>
              <template #item.info="{ item }">
                <span v-if="!attachementDesc(item).includes('.com')">
                  {{ attachementDesc(item) }}
                </span>
                <a
                  v-if="attachementDesc(item).includes('.com')"
                  target="_blank"
                  :href="attachementDesc(item)"
                >
                  {{ item.confirmation_code }}
                </a>
              </template>
              <template #item.actions="{ item }">
                <v-btn outlined class="cyan-icon" @click="removeMatch(item)"
                  >Remove
                </v-btn>
              </template>
            </v-data-table>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-switch
                v-model="lightRules"
                :label="$t('Permissive rules (get more results)')"
                class="grey-text"
                @change="getOptionalMatches"
              />
            </v-col>
            <v-col cols="12" md="3" class="mr-2">
              <v-select
                v-model="objType"
                :label="$t('Transaction type')"
                class="grey-text"
                :outlined="true"
                :dense="true"
                :clearable="true"
                :items="['Expense', 'Payment', 'Reservation', 'Income']"
                @change="getOptionalMatches"
              />
            </v-col>
            <v-col
              v-if="objType === 'Reservation'"
              cols="12"
              md="3"
              class="mr-2"
            >
              <v-text-field
                v-model="confirmationCode"
                outlined
                dense
                :label="$t('Confirmation code')"
                @input="getOptionalMatches"
              />
            </v-col>
            <v-col v-if="objType" cols="12" md="2" class="mr-2">
              <v-text-field
                v-model="searchTerm"
                :label="$t('ID')"
                hide-details
                outlined
                append-icon="search"
                dense
                @change="getOptionalMatches"
              />
            </v-col>
            <v-col cols="12" md="3">
              <multi-property-picker
                v-if="!listingId"
                :filter-change="filterListingIdChanged"
                :no-prepend-icon="true"
                :outlined="true"
                :dense="true"
                :clearable="true"
              >
              </multi-property-picker>
            </v-col>
            <v-col
              v-if="!$store.state.currentBankTransactionsOptions"
              cols="12"
              class="grey-text"
              >Couldn't find any matches</v-col
            >
            <bank-transactions-options
              v-if="$store.state.currentBankTransactionsOptions"
              :listing-id="chosenListingId"
            />
            <company-income-form
              v-if="
                chosenTransaction.extra_data.attached_to_sum !==
                  chosenTransaction.amount &&
                centralBankView &&
                (chosenTransaction.transaction_type === 'RECEIVE' ||
                  chosenTransaction.transaction_type === 'RECEIVE-TRANSFER')
              "
              :chosen-transaction="chosenTransaction"
            ></company-income-form>
            <expense-modal
              v-if="
                chosenTransaction.extra_data.attached_to_sum !==
                chosenTransaction.amount
              "
              :pre-filled-amount="chosenTransaction.amount"
              :pre-filled-desc="chosenTransaction.reference"
              :connected-transaction-id="chosenTransaction.id"
              :central-bank-view="centralBankView"
              :pre-filled-date="chosenTransaction.transferred_at"
            />
            <br />
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click.native="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import BankTransactionsOptions from 'components/bank-transactions-options'
import CompanyIncomeForm from 'components/company-income-form'
import ExpenseModal from 'components/expenses/expense-modal'
import MultiPropertyPicker from 'components/multi-property-picker.vue'

export default {
  components: {
    MultiPropertyPicker,
    BankTransactionsOptions,
    CompanyIncomeForm,
    ExpenseModal,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: ['change', 'listingId', 'centralBankView'],
  data() {
    return {
      dialog: true,
      lightRules: false,
      searchTerm: '',
      objType: null,
      filterListingId: null,
      confirmationCode: null,
    }
  },
  computed: {
    chosenListingId() {
      return this.listingId || this.filterListingId
    },
    chosenTransaction() {
      return this.$store.state.chosenTransaction
    },
    attachedToHeaders() {
      const headers = [
        {
          text: 'Amount',
          value: 'amount',
        },
      ]
      if (!this.isMobile) {
        headers.push({
          text: 'Created by',
          value: 'created_by',
        })
        headers.push({
          text: 'Listing',
          value: 'listing',
        })
        headers.push({
          text: 'Type',
          value: 'type',
        })
      }
      return headers.concat([
        {
          text: 'Date',
          value: 'date',
        },
        {
          text: 'Info',
          value: 'info',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ])
    },
  },
  mounted() {
    this.getOptionalMatches()
  },
  destroy() {
    this.$store.commit('updateChosenTransaction', null)
    this.$store.commit('updateTransactionOptions', null)
    this.change()
  },
  methods: {
    getOptionalMatches() {
      this.$store.dispatch('getOptionalMatch', {
        id: this.chosenListingId,
        light_rules: this.lightRules,
        obj_type: this.objType,
        confirmation_code: this.confirmationCode,
        search: this.searchTerm,
      })
    },
    dismiss(transaction) {
      const userConfirm = confirm('Are you sure you want to dismiss?')
      if (userConfirm) {
        transaction.manual_dismiss = true
        this.chosenTransaction.manual_dismiss = true
        this.$store.dispatch('updateBankTransaction', transaction)
      }
    },
    async removeMatch(item) {
      await this.$store.dispatch('removeMatch', item, this.chosenListingId)
      this.getOptionalMatches()
    },
    typeClass(type) {
      if (type === 'RECEIVE' || type === 'RECEIVE-TRANSFER') {
        return 'green-health'
      } else {
        return 'red-health'
      }
    },
    close() {
      this.$store.commit('updateChosenTransaction', null)
      this.$store.commit('updateTransactionOptions', null)
      this.change()
    },
    filterListingIdChanged(val) {
      this.filterListingId = val
      this.getOptionalMatches()
    },
  },
}
</script>
