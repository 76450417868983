<template>
  <v-card mt-4 flat align="center">
    <v-card-title>Owner dashboard</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          v-for="(value, feature) in settings.dashboard_settings"
          :key="feature"
          class="d-flex align-center flex-column"
        >
          <v-checkbox
            v-model="settings.dashboard_settings[feature]"
            :label="feature | capitalize"
            @change="toggleFeature(feature)"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-card-title class="pa-0 font-weight-medium black--text"
            >UI Settings</v-card-title
          >
          <v-checkbox
            v-model="settings.ui_settings.show_calendar_hotels"
            label="Show Calendar Hotels"
            @change="toggleUiSetting('show_calendar_hotels')"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['settings'],
  methods: {
    toggleFeature(feature) {
      this.$emit('update:settings', {
        ...this.settings,
        dashboard_settings: {
          ...this.settings.dashboard_settings,
          [feature]: this.settings.dashboard_settings[feature],
        },
      })
    },
    toggleUiSetting(setting) {
      console.log('setting', setting)
      this.$emit('update:settings', {
        ...this.settings,
        ui_settings: {
          ...this.settings.ui_settings,
          [setting]: this.settings.ui_settings[setting],
        },
      })
    },
  },
  filters: {
    capitalize: value => value.charAt(0).toUpperCase() + value.slice(1),
  },
}
</script>
