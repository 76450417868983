import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['commentsList', 'currentUserId']),
    unseenComments() {
      if (this.commentsList) {
        return this.commentsList
          .filter(c => !c.seen.includes(this.currentUserId))
          .map(c => c.id)
      } else {
        return []
      }
    },
    unseenCommentsAmount() {
      if (this.commentsList) {
        return this.unseenComments.length
      } else {
        return 0
      }
    },
    commentsAmount() {
      if (this.commentsList) {
        return this.commentsList.length
      } else {
        return 0
      }
    },
  },
  methods: {
    addFeedComment({ html, parent_id }) {
      this.$store.dispatch('addSetupComment', {
        id: this.feedOpenId,
        html,
        parent_id,
      })
    },
  },
}
