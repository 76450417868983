<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="259.578"
    height="181.678"
    viewBox="0 0 259.578 181.678"
  >
    <defs>
      <clipPath id="a">
        <path
          class="a"
          d="M113.463,194.145C70.145,172.313,52.8,145.481,66.3,114.674,75.86,92.854,94.816,88.06,117.82,88.715c27.186.655,40.669-17.138,62.278-34.164,14.017-11.044,28.542-25.778,47.4-20.3,29.323,8.517,30.428,43.768,40.884,58.829,11.153,17.026,24.275,15.272,38.8,27.689,38.295,32.727-7.735,57.53-56.167,75.874S156.78,215.977,113.463,194.145Z"
          transform="translate(1862.604 7469.186)"
        />
      </clipPath>
    </defs>
    <g transform="translate(-2274.422 -7502.255)">
      <path
        class="a"
        d="M113.463,194.145C70.145,172.313,52.8,145.481,66.3,114.674,75.86,92.854,94.816,88.06,117.82,88.715c27.186.655,40.669-17.138,62.278-34.164,14.017-11.044,28.542-25.778,47.4-20.3,29.323,8.517,30.428,43.768,40.884,58.829,11.153,17.026,24.275,15.272,38.8,27.689,38.295,32.727-7.735,57.53-56.167,75.874S156.78,215.977,113.463,194.145Z"
        transform="translate(2212.604 7469.186)"
      />
      <g class="b" transform="translate(350)">
        <g transform="translate(1937.067 7554.285)">
          <g transform="translate(58.889)">
            <g transform="translate(8.786 0.303)">
              <path
                class="c"
                d="M157.788,24.741a6.1,6.1,0,0,0-.72-4.774,41.829,41.829,0,0,0-27.3-18.773,41.346,41.346,0,0,0-36.29,40.983v49.36h12.391V42.514c0-15.975,13.07-29.313,29.045-29.231a28.866,28.866,0,0,1,22.875,11.458Z"
                transform="translate(-93.477 -1.194)"
              />
              <path
                class="d"
                d="M369.745,347.959v2.253h1.507Z"
                transform="translate(-299.564 -259.869)"
              />
            </g>
            <g transform="translate(42.876)">
              <path
                class="e"
                d="M234.867,0a41.384,41.384,0,0,0-7.2.629A41.42,41.42,0,0,1,257.893,23.85a28.745,28.745,0,0,1,5.87,17.436v49.36h12.391V41.286A41.333,41.333,0,0,0,234.867,0Z"
                transform="translate(-227.671)"
              />
            </g>
            <path
              class="f"
              d="M147.266,306.575V256.364a8.436,8.436,0,0,0-8.436-8.436h-71.5a8.436,8.436,0,0,0-8.436,8.436v50.211a8.436,8.436,0,0,0,8.436,8.436h71.5A8.436,8.436,0,0,0,147.266,306.575Z"
              transform="translate(-58.889 -184.946)"
            />
            <path
              class="g"
              d="M377.616,247.928h-14.4a8.436,8.436,0,0,1,8.436,8.436v50.211a8.436,8.436,0,0,1-8.436,8.436h14.4a8.436,8.436,0,0,0,8.436-8.436V256.364A8.436,8.436,0,0,0,377.616,247.928Z"
              transform="translate(-285.906 -184.946)"
            />
            <circle
              class="c"
              cx="24.476"
              cy="24.476"
              r="24.476"
              transform="translate(25.597 72.048)"
            />
            <path
              class="h"
              d="M234.867,283.615a24.467,24.467,0,0,0-7.2,1.076,24.484,24.484,0,0,1,0,46.8,24.478,24.478,0,1,0,7.2-47.876Z"
              transform="translate(-184.795 -211.567)"
            />
            <path
              class="i"
              d="M241.942,337.268a7.26,7.26,0,1,0-11.725,5.722l-1.954,10.242a1.816,1.816,0,0,0,1.784,2.156h9.269a1.816,1.816,0,0,0,1.784-2.156l-1.954-10.242A7.246,7.246,0,0,0,241.942,337.268Z"
              transform="translate(-184.609 -246.175)"
            />
          </g>
        </g>
      </g>
      <g transform="translate(2440.733 7635.733)">
        <path
          class="j"
          d="M24.1,0A24.1,24.1,0,1,0,48.2,24.1,24.127,24.127,0,0,0,24.1,0Z"
        />
        <path
          class="c"
          d="M14.908,24.068a1.494,1.494,0,0,1-1.064-.442L7.317,17.1a1.507,1.507,0,0,1,2.131-2.131l5.463,5.463L26.9,8.441a1.507,1.507,0,0,1,2.131,2.131L15.977,23.626a1.51,1.51,0,0,1-1.068.442Z"
          transform="translate(6.933 8.066)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LockChecked',
}
</script>

<style scoped>
.a {
  fill: #e1f0ff;
}
.b {
  clip-path: url(#a);
}
.c {
  fill: #fff;
}
.d {
  fill: #e8e8e8;
}
.e {
  fill: #c9e0fd;
}
.f {
  fill: #ffd064;
}
.g {
  fill: #ffc250;
}
.h {
  fill: #eaf2ff;
}
.i {
  fill: #60a0f7;
}
.j {
  fill: #00bf2c;
}
</style>
