<template>
  <v-sheet
    :outlined="outlined && !$vuetify.breakpoint.xs"
    :rounded="outlined && !$vuetify.breakpoint.xs"
    class="d-flex pa-1 text-break"
  >
    <v-avatar
      v-if="icon"
      rounded="lg"
      :class="`${color}--text`"
      class="mr-2 bg-opac"
    >
      <v-icon class="" size="20" :color="color">{{ icon }}</v-icon>
    </v-avatar>

    <div>
      <div class="text-caption secondary--text text-capitalize">
        {{ title }}
      </div>
      <div class="text-body-1 font-weight-medium">
        {{ value }}
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'InfoBox',
  props: ['title', 'value', 'color', 'icon', 'outlined'],
}
</script>

<style scoped>
.row-icon {
  width: 35px;
  height: 35px;
  border-radius: 10px;
}
.bg-opac {
  position: relative;
}
.bg-opac::before {
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  left: 0;
  position: absolute;
  background-color: currentColor;
  opacity: 0.12;
}
</style>
