<template>
  <v-flex xs12>
    <v-expansion-panels class="container">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Create company income
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-select
            v-model="newIncome.category"
            class="mt-2"
            :items="['Design', 'Construction', 'Owner deposit', 'Brokerage']"
            label="Source"
          ></v-select>
          <v-text-field
            v-model="newIncome.amount"
            label="Amount"
            required
          ></v-text-field>
          <v-text-field
            v-model="newIncome.description"
            label="Description"
            required
          ></v-text-field>
          <v-btn color="blue darken-1" text @click.native="addMatch()"
            >Save
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-flex>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['chosenTransaction'],
  data: function () {
    return {
      newIncome: {},
    }
  },
  computed: {},
  mounted() {},
  methods: {
    addMatch() {
      this.$store.dispatch('addMatch', this.newIncome)
    },
  },
}
</script>
