import axios from '@/axios/config'

export default {
  state: {},
  namespaced: true,
  actions: {
    async getExpense(store, id) {
      try {
        return await axios.get(`/api/expenses/${id}`)
      } catch (err) {
        console.error(err)
      }
    },
    async updateExpense(store, { id, data }) {
      try {
        return await axios.post(`/api/expenses/${id}`, data)
      } catch (err) {
        console.error(err)
      }
    },
    async validateExpense(store, expenseId) {
      return axios.post(`/api/expenses/${expenseId}/validate`)
    },
    async updateExpensesBatch(store, payload) {
      try {
        return await axios.patch('/api/expenses/batch/', payload)
      } catch (err) {
        console.error(err)
      }
    },
    async deleteExpense(store, expenseId) {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        try {
          await axios.delete('/api/expenses/' + expenseId)
          return true
        } catch (err) {
          console.error(err)
          return false
        }
      } else {
        return false
      }
    },
    async createLtInquiry(store, { id, ...payload }) {
      try {
        return await axios.post(`/api/expense/${id}/inquiry`, payload)
      } catch (err) {
        console.error(err)
      }
    },
  },
}
