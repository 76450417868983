<template>
  <v-card class="pa-3 overflow-y-auto">
    <v-card-title> Edit Menu Item</v-card-title>
    <v-form ref="form" @submit.prevent="save">
      <v-text-field v-model="item.name" label="Name" dense outlined />
      <v-textarea
        v-model="item.description"
        label="Description"
        dense
        outlined
      />
      <div>Profile Image</div>
      <image-field :value.sync="item.main_image" />
      <div class="text-subtitle-1">Images</div>
      <media-upload
        :multiple="true"
        folder="store"
        :preview="true"
        :value.sync="item.images"
      />
      <div class="text-subtitle-1">Videos</div>
      <media-upload
        folder="store"
        :multiple="true"
        :preview="true"
        :value.sync="item.videos"
      />
      <v-combobox
        v-model="item.bullets"
        class="mt-2"
        outlined
        dense
        small-chips
        label="Bullets"
        :multiple="true"
      />
      <contractor-select
        label="Contractor"
        class="mt-2"
        :value.sync="item.contractor_id"
      />
      <v-text-field
        v-model.number="item.unit_price"
        label="Unit price"
        dense
        outlined
      />
      <v-text-field
        v-model.number="item.markup"
        label="Markup"
        dense
        outlined
      />
      <v-text-field
        v-model.number="item.disclaimer"
        label="Disclaimer"
        dense
        outlined
      />
      <div v-if="isSuperAdmin">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <div>
              Instructions
              <v-icon v-bind="attrs" small v-on="on"> $info_circle_alt </v-icon>
            </div>
          </template>
          <div>
            <span class="font-weight-medium text-caption">key:</span>
            <span class="font-weight-medium text-caption"
              >Same as the object key name</span
            >
          </div>
          <div>
            <span class="font-weight-medium text-caption">type:</span>
            <span class="font-weight-medium text-caption"
              >[date, time, units, options, range]</span
            >
          </div>
          <div>
            <span class="font-weight-medium text-caption">calc_method:</span>
            <span class="font-weight-medium text-caption"
              >[per_day, per_unit, per_option] (if using price)</span
            >
          </div>
          <div>
            <span class="font-weight-medium text-caption">index:</span>
            <span class="font-weight-medium text-caption"
              >filling order of the form (number)</span
            >
          </div>
          <div>
            <span class="font-weight-medium text-caption">values:</span>
            <span class="font-weight-medium text-caption"
              >Array of {text:'', value: ''} objects defining the options of the
              item</span
            >
          </div>
        </v-tooltip>

        <v-textarea outlined :value="stringify(item.fields)" label="Fields" />
      </div>
      <v-card-actions class="justify-end">
        <v-btn type="submit" color="primary">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import MediaUpload from 'components/common/media-upload'
import ContractorSelect from 'components/contractor-select'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ImageField from 'components/form-fields/image-field'

export default {
  name: 'EditStoreMenuItem',
  components: { ImageField, ContractorSelect, MediaUpload },
  mixins: [PermissionsMixin],
  props: ['menuItem', 'itemId'],
  data() {
    return {
      item: { ...this.menuItem },
    }
  },
  computed: {
    isEdit() {
      return this.menuItem.id
    },
  },
  mounted() {
    this.item.storeItemId = this.itemId
  },
  methods: {
    stringify(str) {
      return JSON.stringify(str)
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.isEdit) {
          this.$store.dispatch('updateStoreItemMenu', this.item)
        } else {
          this.$store.dispatch('createStoreItemMenu', this.item)
        }
        this.$store.commit('hideModal')
      }
    },
  },
}
</script>

<style scoped></style>
