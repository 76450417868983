<template>
  <v-card>
    <conversation-info-title title="Pricing" />
    <v-divider />
    <v-card-text>
      <div v-if="conversation.extra_data.adr">
        <p class="font-weight-bold text-center">
          This reservation ADR: {{ round(conversation.extra_data.adr) }}
        </p>
        <pricing-monthly />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import PricingMonthly from 'components/pricing-monthly'
import ConversationInfoTitle from 'components/chat/conversation-info-title'

export default {
  components: {
    ConversationInfoTitle,
    PricingMonthly,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['conversation'],
}
</script>
