import { PRIORITIES_ORDER } from '@/consts'

export default {
  computed: {
    section: {
      get() {
        return this.$route.query.section || 'my-tasks'
      },
      set(section) {
        if (section === 'service-calls') {
          this.taskFilter = 'today-tasks'
        }
        if (section === 'pending-reviews') {
          this.taskFilter = 'checkup'
        }
        this.addToQuery({ section })
      },
    },
    taskFilter: {
      get() {
        return this.$route.query.filter || 'today-tasks'
      },
      set(filter) {
        this.addToQuery({ filter })
      },
    },
    reviewFilter: {
      get() {
        return this.$route.query.filter || 'checkup'
      },
      set(filter) {
        this.addToQuery({ filter })
      },
    },
    taskId() {
      return this.$route.query.reportTaskDrawer
    },
    totalServiceCalls() {
      return this.stats.high_open_issues_count + this.stats.med_low_issues_count
    },
    stats() {
      return this.reviewReport.stats
    },
  },
  methods: {
    prioritize(tasks) {
      return tasks.sort((a, b) =>
        a.priority && b.priority
          ? PRIORITIES_ORDER[a.priority.toLowerCase()] -
            PRIORITIES_ORDER[b.priority.toLowerCase()]
          : -1
      )
    },
  },
}
