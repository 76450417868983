import sumBy from 'lodash/sumBy'

const ContractorPayments = {
  methods: {
    contractorPayments(tasks, type) {
      if (type) {
        if (type === 'manual') {
          return tasks.filter(item => {
            return !item.auto_listing_task_rule_id
          })
        }
        if (type === 'routine') {
          return tasks.filter(item => {
            return item.auto_listing_task_rule_id
          })
        }
      }
      return tasks
    },
    calcTotalHours(tasks, paymentsType) {
      return sumBy(this.contractorPayments(tasks, paymentsType), 'total_hours')
    },
    calcTotalCharge(tasks, paymentsType) {
      return sumBy(
        this.contractorPayments(tasks, paymentsType),
        'payable_amount'
      )
    },
    calcTotalDeposit(tasks, paymentsType) {
      return sumBy(this.contractorPayments(tasks, paymentsType), 'deposit') || 0
    },
    calcTotalLeft(tasks, paymentsType) {
      return (
        this.calcTotalCharge(tasks, paymentsType) -
        this.calcTotalDeposit(tasks, paymentsType)
      )
    },
    taskHaveHotelNickname(task) {
      return !!(
        task.listing &&
        task.listing.hotel &&
        task.listing.hotel.nickname
      )
    },
    taskListingNickname(task, withHotel = false) {
      if (withHotel && this.taskHaveHotelNickname(task)) {
        return task.listing.hotel.nickname
      } else if (task.listing && task.listing.nickname) {
        return task.listing.nickname
      } else {
        return task.mini_extra_data.listing_name
      }
    },
  },
}

export default ContractorPayments
