<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58.384"
    height="58.168"
    viewBox="0 0 58.384 58.168"
  >
    <g transform="translate(0 -0.943)">
      <g transform="translate(28.805 0.943)">
        <path
          class="a"
          d="M281.914,14.216,267.993,1.178a.869.869,0,0,0-1.188,0l-13.92,13.038a.869.869,0,0,0,.594,1.5h5.477V18.06a.869.869,0,0,0,1.738,0V14.85a.869.869,0,0,0-.869-.869h-4.148L267.4,3l11.722,10.979h-4.147a.869.869,0,0,0-.869.869V44.587a.869.869,0,0,0,1.738,0V15.719h5.477a.869.869,0,0,0,.594-1.5Z"
          transform="translate(-252.61 -0.943)"
        />
      </g>
      <g transform="translate(35.151 47.194)">
        <path
          class="a"
          d="M324.28,406.543a.869.869,0,0,0-.869.869v9.311H310v-.739a.869.869,0,1,0-1.738,0v1.608a.869.869,0,0,0,.869.869H324.28a.869.869,0,0,0,.869-.869v-10.18A.869.869,0,0,0,324.28,406.543Z"
          transform="translate(-308.263 -406.543)"
        />
      </g>
      <g transform="translate(0 15.454)">
        <path
          class="a"
          d="M21.829,128.2a.869.869,0,0,0,0,1.738,20.068,20.068,0,1,1-3.939.386.869.869,0,0,0-.339-1.7,21.847,21.847,0,1,0,4.278-.419Z"
          transform="translate(0 -128.2)"
        />
      </g>
      <g transform="translate(4.119 19.573)">
        <path
          class="a"
          d="M66.36,169.5a17.71,17.71,0,1,0,0,25.057A17.738,17.738,0,0,0,66.36,169.5ZM65.131,193.33a15.973,15.973,0,1,1,0-22.6A16,16,0,0,1,65.131,193.33Z"
          transform="translate(-36.122 -164.321)"
        />
      </g>
      <g transform="translate(16.066 26.626)">
        <path
          class="a"
          d="M147.524,236.019v-6.4a3.48,3.48,0,0,1,3.156,3.133.869.869,0,1,0,1.738,0c0-2.474-2.127-4.53-4.894-4.891v-.819a.869.869,0,1,0-1.738,0v.819c-2.767.361-4.894,2.416-4.894,4.891s2.127,4.53,4.894,4.891v6.4a3.481,3.481,0,0,1-3.156-3.133.869.869,0,1,0-1.738,0c0,2.474,2.127,4.53,4.894,4.891v.819a.869.869,0,0,0,1.738,0V245.8c2.767-.361,4.894-2.416,4.894-4.891S150.29,236.38,147.524,236.019Zm-1.738-.133a3.133,3.133,0,1,1,0-6.267Zm1.737,8.157v-6.267a3.133,3.133,0,1,1,0,6.267Z"
          transform="translate(-140.892 -226.175)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Profits',
}
</script>

<style scoped>
.a {
  fill: currentColor;
  stroke: currentColor;
}
</style>
