<template>
  <v-card v-if="conversation.guest.id" elevation="0">
    <conversation-info-title title="" />
    <v-divider />
    <v-card-text>
      <p class="font-weight-bold">PMS info</p>
      <div class="d-flex align-center mb-4">
        <user-avatar :name="conversation.guest.full_name" :size="50" />
        <div class="ml-4">
          <p class="text-body-1 black--text mb-0">
            {{ conversation.guest.full_name }}
          </p>
          <v-btn class="pa-0" :href="`tel:${displayedPhone}`" text x-small>
            <v-icon class="mr-1" x-small>mdi-phone</v-icon>
            {{ displayedPhone }}
          </v-btn>
        </div>
      </div>
      <p>
        <v-icon>person</v-icon>
        <span class="cyan-icon">Registered email: </span>
        {{ displayedEmail }}
      </p>
      <v-btn
        v-if="conversation.guest.airbnb2"
        class="text-capitalize secondary--text mb-4"
        target="_blank"
        :href="conversation.guest.airbnb2['url']"
        block
        outlined
      >
        <v-icon class="mr-2" small>mdi-open-in-new</v-icon>
        <span>Guest Airbnb profile</span>
      </v-btn>
      <pre-check-in-card
        v-if="reservation.status === 'confirmed'"
        :flat-view="true"
        :editable="true"
        :pre-check-in="preCheckIn || reservation.pre_check_in"
        :confirmation-code="reservation.confirmation_code"
        class="mb-2"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormMixin from 'components/mixins/form-rules-mixin'

import PreCheckInCard from 'components/pre-check-in/pre-check-in-card'
import ConversationInfoTitle from 'components/chat/conversation-info-title'
import UserAvatar from 'components/common/user-avatar'

export default {
  components: {
    UserAvatar,
    ConversationInfoTitle,
    PreCheckInCard,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormMixin],
  props: ['conversation', 'reservation'],
  computed: {
    displayedPhone() {
      const { guest } = this.conversation

      return (
        guest.primary_phone || guest.pre_checkin_phone || guest.phones[0] || '-'
      )
    },
    displayedEmail() {
      const { guest } = this.conversation
      return (
        this.conversation.registered_email ||
        guest.primary_email ||
        guest.pre_checkin_email ||
        guest.emails[0] ||
        '-'
      )
    },
  },
  data() {
    return {
      preCheckIn: null,
    }
  },
  async mounted() {
    this.preCheckIn = await this.$store.dispatch(
      'getPreCheckIn',
      this.reservation.confirmation_code
    )
  },
}
</script>
