<template>
  <v-dialog v-model="showProjectModal" persistent max-width="750px">
    <template #activator="{ on }">
      <v-btn class="mx-2" color="success" v-on="on">
        <v-icon left>add</v-icon>
        New Project
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="py-6">
        <div class="p-relative flex text-center">
          <h2 class="text-h6">{{ project.id ? 'Edit' : 'Create' }} Project</h2>
          <v-icon class="close-button" @click="closeModal">close</v-icon>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form ref="projectForm">
          <v-row v-if="project.id" class="mt-3">
            <v-col cols="3">
              <v-select
                v-model="project.status"
                :items="projectStatuses"
                :label="$t('Status')"
                item-text="text"
                item-value="key"
                dense
                outlined
                hide-details
              />
            </v-col>
          </v-row>
          <v-text-field
            v-model="project.name"
            class="mt-3"
            label="Project Name*"
            outlined
            dense
            :rules="[required]"
          />
          <v-textarea
            v-model="project.description"
            class="mt-6"
            :rows="4"
            label="Project Description"
            outlined
            dense
            hide-details
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions class="py-4">
        <v-spacer />
        <v-btn
          color="secondary"
          class="text-capitalize"
          text
          @click="closeModal"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          class="text-capitalize"
          elevation="0"
          :loading="projectLoading"
          @click.native="save"
          >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import { PROJECT_STATUSES } from '@/consts'

export default {
  mixins: [formRules],
  props: ['existingProject'],
  data() {
    return {
      projectStatuses: PROJECT_STATUSES,
      showProjectModal: this.existingProject,
      projectLoading: false,
      project: {
        name: '',
        description: '',
        status: null,
        id: null,
      },
    }
  },
  watch: {
    existingProject(val) {
      this.showProjectModal = !!val
      this.project = val
    },
  },
  methods: {
    closeModal() {
      this.showProjectModal = false
      this.projectLoading = false
      this.project.id = null
      this.$refs.projectForm.reset()
    },
    async save() {
      if (this.$refs.projectForm.validate()) {
        this.projectLoading = true
        const action = this.project.id ? 'updateProject' : 'createProject'
        await this.$store.dispatch(`projects/${action}`, this.project)
        this.closeModal()
      }
    },
  },
}
</script>
