<template>
  <v-row
    :key="item.id"
    :class="taskFullDay(item) ? 'super-light-red' : 'green lighten-5'"
    class="comp-wrapper mt-1 pa-0 text-caption align-center"
    @click="taskClicked(item)"
  >
    <v-col
      v-if="listStatus === 'In Progress' || listStatus === 'New'"
      cols="1"
      md="auto"
    >
      <v-checkbox
        v-if="
          (item.status === 'New' || item.status === 'In Progress') &&
          !item.department
        "
        v-model="item.selected"
        @click.native.stop="addToLtdSelected(item)"
      ></v-checkbox>
    </v-col>
    <v-col cols="2" md="auto">
      <div
        :style="'background-color: ' + stringToColour(itemAssignName(item))"
        class="pt-1 pl-1 pr-1 float-right list-cal-ini"
      >
        <span class="white--text cal-image bolder2">{{
          initials(nameById(item.assigned_contractor_id))
        }}</span>
      </div>
    </v-col>
    <v-col
      col="9"
      class="align-center pointer text-start d-flex flex-wrap no-gutters"
    >
      <v-col cols="12" class="bb-1 pa-0">
        <span v-if="isSC(item)" class="bolder2">{{
          getListingById(item.listing_id).nickname
        }}</span>
        <span v-if="item.department" class="cyan-icon">(Company task)</span>
        {{ truncateBy(item.description, $vuetify.breakpoint.xs ? 18 : 40) }}
      </v-col>
      <v-col cols="9" class="pa-0 text-start">
        <v-col cols="12" class="flex-column d-flex pa-0 no-gutters">
          <v-col
            :class="
              howFarBack(item.scheduled_at) === 0 ? 'bolder purple-icon' : ''
            "
          >
            {{ parseDate(item.scheduled_at) }} -
            <span v-if="isSC(item)" :class="availColor(item)">
              {{ item.add_today_avialability_text }} </span
            >-
            <span v-if="item.created_by_name" class="cyan-icon"
              >created by: {{ item.created_by_name }}</span
            >
          </v-col>

          <v-col
            v-if="
              howFarBack(item.scheduled_at) &&
              item.status !== 'Done' &&
              howFarBack(item.scheduled_at) > 0
            "
            class="red--text"
          >
            {{ howFarBack(item.scheduled_at) }} days delay
          </v-col>
        </v-col>
      </v-col>
      <v-col cols="3" :style="'color:' + statusColor(item)" class="right-text">
        <v-layout column>
          <v-flex
            v-if="item.priority"
            :class="priorityClass(item.priority)"
            class="center-text"
          >
            {{ item.priority }}
          </v-flex>
          <v-flex class="center-text">
            {{ item.status }}
          </v-flex>
        </v-layout>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'

export default {
  components: {},
  mixins: [CommonFunctions, ColorsMixin, PermissionsMixin],
  props: ['listStatus', 'item', 'taskClicked'],
  computed: {},
  methods: {
    taskFullDay(task) {
      let start = task.scheduled_at
        ? task.scheduled_at
        : task.status === 'New'
        ? new Date()
        : task.updated_at
      let hour = this.$moment.utc(start).hours()
      return hour === 0
    },
    statusColor(task) {
      if (task.status === 'Done' && !task.department && !task.manual_approved) {
        return '#c0a67e'
      } else if (task.status === 'In Progress') {
        return '#dfc12a'
      } else if (task.status === 'Done') {
        return '#75c547'
      } else {
        return 'red'
      }
    },
  },
}
</script>
