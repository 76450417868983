<template>
  <v-dialog v-model="dialog" max-width="400">
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card>
      <v-form ref="form" @submit.prevent="createListingTask">
        <v-card-title> Create Service Call </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="description"
            :rules="[required]"
            label="Description"
            outlined
            hide-details
            dense
          />
          <expander title="More">
            <date-picker
              v-model="scheduledAt"
              :allowed-dates="allowedDates"
              :event-color="eventDateColor"
              :hide-details="false"
              label="Scheduled at"
            />
            <contractor-select :value.sync="contractor" :rules="[required]" />
            <catalog-quick-select v-model="catalogItemId" :rules="[required]" />
            <v-select
              v-model="priority"
              :label="$t('Priority')"
              :rules="[required]"
              :items="['Critical', 'High', 'Med', 'Low']"
              class="cyan-icon"
              outlined
              dense
            />
          </expander>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small color="primary" outlined @click="closeModal"
            >Cancel</v-btn
          >
          <v-btn small color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DatePicker from 'components/form-fields/date-picker.vue'
import ContractorSelect from 'components/contractor-select.vue'
import CatalogQuickSelect from 'components/catalog-quick-select.vue'
import Expander from 'components/common/expander.vue'
export default {
  name: 'LtFromSubtaskModal',
  components: { Expander, CatalogQuickSelect, ContractorSelect, DatePicker },
  mixins: [formRules, CommonFunctions, PermissionsMixin],
  props: ['selected', 'taskDescription'],
  data() {
    return {
      dialog: false,
      menu: false,
      contractor: null,
      catalogItemId: null,
      description: this.taskDescription,
      priority: null,
      scheduledAt: this.$moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    listingTask() {
      return this.$store.getters.currentListingTask
    },
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    allowedDates(val) {
      const parsedDate = this.$moment(val).utc().add(1, 'days')
      const timeNow = this.$moment().utc()
      return Boolean(
        timeNow <= parsedDate &&
          (!this.listingTask.house_vacant_dates[val] ||
            this.listingTask.house_check_in_dates[val] ||
            this.listingTask.house_check_out_dates[val])
      )
    },
    closeModal() {
      this.dialog = false
    },
    createListingTask() {
      if (this.$refs.form.validate()) {
        const payload = {
          sub_tasks_ids: this.selected,
          contractor_id: this.contractor,
          description: this.description,
          listing_task_catalog_item_id: this.catalogItemId,
          priority: this.priority,
          scheduled_at: this.scheduledAt,
        }
        this.$store.dispatch('createTaskFromSubTasks', {
          id: this.listingTask.id,
          payload,
        })
        this.$emit('onaction')
        this.dialog = false
      }
    },
  },
}
</script>

<style scoped></style>
