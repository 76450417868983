<template>
  <div class="square">
    <div
      :style="{ width: percentRounded + '%' }"
      :class="['center-text', 'black--text', 'battery-fill', batteryLevel]"
    >
      {{ percentRounded + '%' }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BatteryLevel',
  props: ['percent'],
  computed: {
    percentRounded() {
      return Math.round(this.percent)
    },
    batteryLevel() {
      if (this.percent >= 70) {
        return 'high'
      }
      if (this.percent >= 40) {
        return 'mid'
      }
      return 'low'
    },
  },
}
</script>

<style scoped>
.battery-fill {
  height: 100%;
  font-size: 11px;
  white-space: nowrap;
}
.square {
  border: 1px solid #000000;
  width: 45px;
  height: 20px;
  padding: 2px;
  position: relative;
}
.square:after {
  content: '';
  display: block;
  background: #000000;
  border: 3px solid #000000;
  width: 4px;
  height: 10px;
  position: absolute;
  z-index: 0;
  top: 4px;
  right: -7px;
}
.high {
  background: lawngreen;
}
.mid {
  background: #ffdd00;
}
.low {
  background: orangered;
}
</style>
