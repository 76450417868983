<template>
  <div>
    <v-text-field
      v-show="!showFull"
      label="Add comment.."
      outlined
      dense
      @click="addComment"
    />
    <quill-editor
      v-show="showFull"
      ref="myQuillEditor"
      :key="key"
      :content="content"
      :options="editorOption"
      @change="onEditorChange($event)"
    />
    <v-progress-linear
      v-if="uploading"
      :value="uploadProgress"
    ></v-progress-linear>
    <media-upload
      v-show="false"
      ref="mediaUploadComponent"
      :multiple="false"
      :folder="imgsFolder"
      :preview="false"
      accept="image/png,image/jpeg,image/jpg,image/webp,.pdf"
      :on-change="imageUploaded"
      :progress.sync="uploadProgress"
      :uploading.sync="uploading"
    />
    <div v-if="showSaveButton" class="py-2 text-end">
      <v-btn :disabled="!content" small color="info" @click="sendMsg"
        >Send
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getQuillDefaultOptions } from '@/utils/editor-options'
import MediaUpload from 'components/common/media-upload.vue'
import { uploadToCloudinary } from '@/utils/uploadToCloudinary'

export default {
  name: 'TextEditor',
  components: {
    MediaUpload,
  },
  props: {
    mentions: {
      type: Array,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    imgsFolder: {
      type: String,
      default: 'text-editor',
    },
  },
  data() {
    return {
      content: this.value,
      key: 'initial',
      showFull: false,
      uploadProgress: 0,
      uploading: false,
      editorOption: getQuillDefaultOptions(this.mentions, '', {
        showToolbar: this.showToolbar,
        imgHandler: this.imgHandler,
      }),
    }
  },
  watch: {
    mentions(value) {
      this.key = `initial${Math.random()}`
      this.editorOption = getQuillDefaultOptions(value, '', {
        showToolbar: this.showToolbar,
        imgHandler: this.imgHandler,
      })
      this.imageCopyHandler()
    },
  },
  mounted() {
    if (this.autofocus) {
      this.showFull = true
      this.editorFocus()
    }
    this.imageCopyHandler()
  },
  methods: {
    addComment() {
      this.showFull = true
    },
    onEditorChange({ html }) {
      this.content = html
      this.$emit('input', html)
    },
    sendMsg() {
      this.$emit('text-submit', this.content)
      this.content = ''
    },
    editorFocus() {
      setTimeout(() => this.$refs.myQuillEditor.quill.focus(), 0)
    },
    blobToFile(blob) {
      return new File([blob], 'comment-img.png', { type: 'image/png' })
    },
    imgHandler() {
      this.$refs.mediaUploadComponent.$refs.file.click()
    },
    imageUploaded(img) {
      this.$refs.myQuillEditor.quill.insertEmbed(
        this.$refs.myQuillEditor.quill.getSelection().index,
        'image',
        img
      )
    },
    imageCopyHandler() {
      this.$refs.myQuillEditor.quill.on('text-change', delta => {
        delta.ops.forEach(op => {
          if (
            op.insert &&
            op.insert.image &&
            op.insert.image.startsWith('data:image')
          ) {
            this.$refs.myQuillEditor.quill.deleteText(0, 1)
            uploadToCloudinary(
              [op.insert.image],
              {
                onUploadingChange: val => (this.uploading = val),
                onUploadProgress: val => {
                  this.uploadProgress = val * 100
                },
              },
              { folder: this.imgsFolder, compress: false }
            ).then(urls => this.imageUploaded(urls[0]))
          }
        })
      })
    },
  },
}
</script>

<style scoped>
>>> .ql-mention-list-container {
  z-index: 9999;
  top: 30px !important;
  background-color: white;
  box-shadow: 0 3px 1px -2px rgba(0 0 0 0.2), 0 2px 2px 0 rgba(0 0 0 0.14),
    0 1px 5px 0 rgba(0 0 0 0.12);
}

>>> .v-list-item.v-list-item--link.selected {
  background-color: var(--v-info-lighten4) !important;
}

>>> .ql-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: auto;
}

>>> .ql-editor {
  min-height: 150px;
  max-height: 300px;
  height: auto;
  overflow: auto;
}

>>> .ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

>>> .mention {
  color: var(--v-info-base) !important;
}
</style>
