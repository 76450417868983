var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',[_c('v-sheet',_vm._g(_vm._b({class:[
          `d-flex
          justify-space-between
          conversation-status-label
          text-uppercase
          rounded
          text-no-wrap
          font-weight-semibold
          white--text
          ${_vm.getStatusColor(_vm.value)}`,
          _vm.disabled && 'opacity-30',
          {
            'px-3 py-1 text-caption': _vm.small,
            'text-body-2 px-4 py-2': !_vm.small,
          },
        ],attrs:{"width":_vm.width}},'v-sheet',attrs,false),_vm.disabled ? null : on),[_c('span',[_vm._v(_vm._s(_vm.value || 'Set Status'))]),_c('v-icon',{staticClass:"pl-2",attrs:{"color":"white","x-small":""}},[_vm._v(" fas fa-chevron-down ")])],1)],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.menu)?_c('v-list',[_c('v-list-item-group',{attrs:{"value":_vm.statusValue,"color":"primary"},on:{"change":_vm.statusChanged},scopedSlots:_vm._u([{key:"default",fn:function(){return _vm._l((_vm.statuses),function(item){return _c('v-list-item',{key:item,attrs:{"value":item,"disabled":item === _vm.value}},[_c('div',{staticClass:"text-caption font-weight-semibold d-flex align-center"},[_c('div',{},[_c('span',{staticClass:"px-2 dot",class:_vm.getStatusColor(item)})]),_c('span',{staticClass:"ms-2"},[_vm._v(_vm._s(item))])])])})},proxy:true}],null,false,1334449669)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }