<template>
  <tr :class="{ 'secondary lighten-1': !item.active }">
    <!--    <td class="text-center min-w-150">-->
    <!--      YTD: {{ item.statistics.ytd_usage }} / Last 30:-->
    <!--      {{ item.statistics.last_30_usage }}-->
    <!--    </td>-->
    <td class="text-center min-w-150">
      {{ item.division }}
    </td>
    <td class="text-center min-w-150">
      {{ item.department }}
    </td>
    <td class="text-center min-w-150">
      {{ item.sub_topic }}
    </td>
    <td v-if="isDesignedVr" class="text-center ellipsis-1 min-w-150">
      {{ item.description }}
    </td>
    <!--    <td class="text-center max-w-200">-->
    <!--      {{ item.super_simple_enabled ? 'Yes' : 'No' }}-->
    <!--    </td>-->
    <td class="text-center max-w-200">
      {{ item.auto_approve ? 'Yes' : 'No' }}
    </td>
    <td class="text-center max-w-200">
      {{ item.auto_expense_type }}
    </td>
    <td class="text-center max-w-200">{{ item.avg_duration || 0 }} Hours</td>
    <td class="text-center max-w-200">
      <div>
        <v-icon v-if="managementRoles" small @click="editCatalogItem">
          edit
        </v-icon>
        <ConfirmationModal
          v-if="managementRoles && item.active"
          :text="`Are you sure you want to archive catalog ${item.description} ?`"
          @action="deleteItem(item)"
        >
          <template #activator="{ on }">
            <v-icon
              :disabled="item.system_key"
              small
              class="mr-2 clickable"
              v-on="on"
            >
              delete</v-icon
            >
          </template>
        </ConfirmationModal>
        <v-icon
          v-if="managementRoles && !item.active"
          small
          class="mr-2 clickable"
          @click="restoreItem(item)"
        >
          undo
        </v-icon>
        <v-chip
          v-if="item.system_key"
          x-small
          class="text-uppercase"
          disabled
          color="info"
          >system</v-chip
        >
      </div>
    </td>
  </tr>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'

export default {
  components: { ConfirmationModal },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['item', 'afterChange'],
  data() {
    return {
      search: null,
    }
  },
  methods: {
    editCatalogItem() {
      this.$store.commit('showModal', {
        name: 'EditCatalogItemModal',
        props: { catalogItem: this.item },
        maxWidth: 1300,
      })
    },
    deleteItem(item) {
      this.$store.dispatch('archiveCatalogItem', item.id)
    },
    updateItem(payload) {
      this.$store.dispatch('updateListingTaskCatalogItem', {
        id: this.item.id,
        payload,
      })
    },
    restoreItem(item) {
      this.$store.dispatch('restoreCatalogItem', item.id)
    },
  },
}
</script>
