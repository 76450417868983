<template>
  <v-btn small class="ma-1 text-capitalize" outlined @click="openModal">
    <v-icon left>
      {{ mode == 'edit' ? 'mdi-pencil' : 'warning_amber' }}
    </v-icon>
    {{ mode == 'edit' ? $t('Edit Info') : $t('Missing Info') }}
  </v-btn>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import LtMixin from 'components/mixins/lt-mixin'
import { INVESTOR_HEADERS } from 'components/real-estate/config'

export default {
  mixins: [CommonFunctions, LtMixin],
  props: ['listing', 'mode'],
  methods: {
    openModal() {
      let item = this.listing
      this.$store.commit('showModal', {
        name: 'UpdateFieldsModal',
        props: {
          onConfirm: fields => this.updateFields(item.id, fields),
          item,
          fields: INVESTOR_HEADERS,
        },
      })
    },
    updateFields(id, fields) {
      this.$store.dispatch('updateListingPartial', {
        id,
        ...fields,
        updateCurrentListing: true,
      })
    },
  },
  computed: {},
}
</script>

<style scoped></style>
