<template>
  <form-dialog
    ref="createLedgerAccount"
    :title="$t('Add Ledger Account')"
    :max-width="600"
    @submit="createLedgerAccount"
  >
    <template #activator="{ on }">
      <v-btn color="primary" class="text-capitalize" elevation="0" v-on="on">
        {{ $t('Add Ledger Account') }}
      </v-btn>
    </template>

    <v-text-field
      v-model="account.name"
      outlined
      dense
      :label="$t('Name')"
      required
      :rules="[required]"
    />

    <v-text-field
      v-model="account.code"
      outlined
      dense
      :label="$t('Code')"
      :rules="[required]"
    />

    <v-select
      v-model="account.account_type"
      outlined
      dense
      :items="accountTypes"
      :label="$t('Account Type')"
      required
      :rules="[required]"
    />
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import axios from 'axios'

export default {
  name: 'LedgerAccountCreateModal',
  components: { FormDialog },
  mixins: [FormRules],

  data: () => ({
    account: {
      name: null,
      code: null,
      account_type: null,
    },
    accountTypes: [
      { text: 'Asset', value: 'asset' },
      { text: 'Liability', value: 'liability' },
      { text: 'Equity', value: 'equity' },
      { text: 'Income', value: 'income' },
      { text: 'Expense', value: 'expense' },
    ],
  }),

  methods: {
    async createLedgerAccount() {
      this.$store.commit('updateLoading', true)
      try {
        await axios.post('/api/ledger-accounts', {
          ledger_account: this.account,
        })
        this.$emit('created')
        this.$refs.createLedgerAccount.close()
      } catch (error) {
        this.$store.dispatch('showError', 'Failed to create ledger account')
      } finally {
        this.$store.commit('updateLoading', false)
      }
    },
  },
}
</script>
