<template>
  <div>
    <div class="d-flex align-center py-2" @click="toggle">
      <div v-if="!$slots.title" class="text-subtitle-1 font-weight-medium">
        {{ title }}
      </div>
      <slot v-else name="title" />
      <v-btn v-if="!hideActions" class="mx-1" x-small icon text>
        <v-icon :class="expanded && 'icon-flipped'" color="black" small
          >fas fa-angle-double-down</v-icon
        >
      </v-btn>
    </div>
    <v-expand-transition>
      <div v-if="expanded">
        <slot />
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'Expander',
  props: ['title', 'value', 'hideActions'],
  data() {
    return {
      expanded: Boolean(this.value),
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
      this.$emit('input', this.expanded)
    },
  },
  watch: {
    value(val) {
      this.expanded = Boolean(val)
    },
  },
}
</script>

<style scoped>
.icon-flipped {
  transform: scale(-1);
  -moz-transform: scale(-1);
  -webkit-transform: scale(-1);
  -ms-transform: scale(-1);
}
</style>
