<template>
  <div>
    <v-card flat>
      <v-card-title class="d-flex justify-space-between align-center">
        <div>{{ $t('Ledger Account Lines') }}</div>
        <v-spacer />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="sortedLedgerLines"
        :loading="loading"
        :items-per-page="15"
      >
        <template v-slot:item.line_code="{ item }">
          {{ formatLineCode(item.line_code) }}
        </template>

        <template v-slot:item.default_type="{ item }">
          <v-chip
            small
            :color="item.default_type === 'credit' ? 'success' : 'error'"
            text-color="white"
          >
            {{ item.default_type }}
          </v-chip>
        </template>

        <template v-slot:item.ledger_account="{ item }">
          <v-select
            v-model="item.ledger_account_id"
            :items="accounts"
            item-text="name"
            item-value="id"
            dense
            outlined
            hide-details
            :loading="updatingLine === item.id"
            @change="updateAccount(item)"
          >
            <template v-slot:item="{ item: account }">
              <div>
                <strong>{{ account.name }}</strong>
                <span class="grey--text text--darken-1 ml-2">
                  ({{ account.code }})
                </span>
              </div>
            </template>
            <template v-slot:selection="{ item: account }">
              <div class="d-flex align-center">
                {{ account.name }}
                <span class="grey--text text--darken-1 ml-2">
                  ({{ account.code }})
                </span>
              </div>
            </template>
          </v-select>
        </template>

        <template v-slot:item.recognised_at="{ item }">
          <v-select
            v-model="item.recognised_at"
            :items="recognisedAtOptions"
            dense
            outlined
            hide-details
            @change="updateRecognisedAt(item)"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import Toaster from '@/utils/toaster'

export default {
  name: 'LedgerAccountLines',

  data: () => ({
    loading: false,
    ledgerLines: [],
    accounts: [],
    updatingLine: null,
    headers: [
      {
        text: 'Line Type',
        value: 'line_code',
        sortable: true,
      },
      {
        text: 'Default Type',
        value: 'default_type',
        sortable: true,
      },
      {
        text: 'Assigned Account',
        value: 'ledger_account',
        sortable: false,
      },
      {
        text: 'Recognised At',
        value: 'recognised_at',
        sortable: true,
      },
    ],
    recognisedAtOptions: [
      { text: 'At Confirmation', value: 'confirmed_at' },
      { text: 'At Check-in', value: 'check_in' },
      { text: 'At Check-out', value: 'check_out' },
    ],
  }),

  mounted() {
    this.fetchLedgerLines()
    this.fetchAccounts()
  },

  computed: {
    sortedLedgerLines() {
      return [...this.ledgerLines].sort((a, b) => {
        // Sort by assignment status first (assigned ones on top)
        if (a.ledger_account && !b.ledger_account) return -1
        if (!a.ledger_account && b.ledger_account) return 1

        // Then sort by line_code
        return a.line_code.localeCompare(b.line_code)
      })
    },
  },

  methods: {
    async fetchAccounts() {
      try {
        const response = await axios.get('/api/ledger-accounts')
        this.accounts = response.data.accounts || []
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      }
    },

    async fetchLedgerLines() {
      this.loading = true
      try {
        const response = await axios.get('/api/ledger-account-lines')
        this.ledgerLines = response.data.map(line => ({
          ...line,
          ledger_account_id: line.ledger_account?.id,
        }))
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      } finally {
        this.loading = false
      }
    },

    formatLineCode(code) {
      return code
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },

    async updateAccount(item) {
      this.updatingLine = item.id
      try {
        await axios.patch(`/api/ledger-account-lines/${item.id}`, {
          ledger_account_id: item.ledger_account_id,
        })
        Toaster.show([{ type: 'success', text: 'Account mapping updated' }])
        await this.fetchLedgerLines()
        this.$emit('line-updated')
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      } finally {
        this.updatingLine = null
      }
    },

    async updateRecognisedAt(item) {
      try {
        await axios.patch(`/api/ledger-account-lines/${item.id}`, {
          ledger_account_line: {
            recognised_at: item.recognised_at,
          },
        })
        Toaster.show([{ type: 'success', text: 'Recognition timing updated' }])
        await this.fetchLedgerLines()
        this.$emit('line-updated')
      } catch (error) {
        Toaster.show([{ type: 'error', text: error }])
      }
    },
  },
}
</script>
