<template>
  <v-row justify="center">
    <v-col cols="4">
      <v-form @submit.prevent="save">
        <v-row>
          <v-col cols="10">
            <v-text-field
              ref="form"
              v-model="newZone"
              outlined
              dense
              label="New Zone"
              :rules="[required]"
            />
          </v-col>
          <v-col cols="2">
            <v-btn color="blue darken-1" text type="submit">Save</v-btn>
          </v-col>
          <v-col cols="12" class="flex">
            <data-table
              :headers="HEADERS"
              :items="zones"
              @edit-item="editItem"
              @delete-item="deleteItem"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DataTable from 'components/common/data-table/data-table.vue'
import { MANAGMENT_TABLE_TYPES } from '@/consts'
import formRules from 'components/mixins/form-rules-mixin'

const HEADERS = [
  {
    text: 'Zone',
    value: 'name',
  },
  {
    text: 'Listings',
    value: 'listings_amount',
  },
  {
    text: '',
    cellType: MANAGMENT_TABLE_TYPES.ACTION,
    value: 'edit-item',
    icon: 'mdi-pencil',
    align: 'end',
  },
  {
    text: '',
    cellType: MANAGMENT_TABLE_TYPES.CONFIRMED_ACTION,
    confirmationText: 'Are you sure you want to delete',
    value: 'delete-item',
    align: 'end',
  },
]

export default {
  components: { DataTable },
  mixins: [formRules],
  data() {
    return {
      newZone: null,
      HEADERS,
    }
  },
  computed: {
    ...mapState('zones', ['zones']),
  },
  methods: {
    ...mapActions('zones', ['deleteZone', 'createZone']),
    deleteItem(zone) {
      this.deleteZone({ id: zone.id })
    },
    editItem(zone) {
      this.$store.commit('showModal', {
        name: 'ListingZoneModal',
        props: {
          zone,
        },
      })
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.createZone({ name: this.newZone })
        this.$refs.form.reset()
      }
    },
  },
}
</script>

<style></style>
