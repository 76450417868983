<template>
  <div>
    <v-row>
      <v-col cols="auto">
        <listing-select
          label="Filter by listing"
          :filter-change="listingChange"
          :hide-details="true"
        />
      </v-col>
    </v-row>
    <data-table :headers="HEADERS" :items="logs" :is-loading="logsLoading" />
    <v-pagination
      v-if="pagination"
      v-model="page"
      :length="Math.ceil(pagination.count / pagination.per_page)"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3 flex"
    />
  </div>
</template>

<script>
import DataTable from 'components/common/data-table/data-table.vue'
import ListingSelect from 'components/listing-select.vue'
import { mapActions } from 'vuex'
import NavigationMixin from 'components/mixins/navigation-mixin'

const HEADERS = [
  { text: 'Listing', value: 'listing.nickname' },
  { text: 'Action Description', value: 'description' },
  { text: 'Timestamp', value: 'created_at', fieldType: 'date' },
]

export default {
  components: { DataTable, ListingSelect },
  mixins: [NavigationMixin],
  mounted() {
    this.watchQuery(['page'])
    this.getLogs({ page: this.page })
  },
  data() {
    return {
      HEADERS,
      pagination: null,
      page: +this.$route.query.page || 1,
      logsLoading: false,
      listingId: null,
      logs: [],
    }
  },
  watch: {
    page(val) {
      this.getLogs({ page: val })
    },
  },
  methods: {
    ...mapActions('listings', ['getListingLogs']),
    async getLogs({ page, listingId }) {
      this.logsLoading = true
      const { logs, pagination } = await this.getListingLogs({
        page,
        listing_id: listingId,
      })
      this.pagination = pagination
      this.logs = logs.map(l => ({
        ...l,
        description: this.formatDescription(l.diff),
      }))
      this.logsLoading = false
    },
    formatDescription(diff) {
      if (!diff) return '* First logged change for listing*'
      if (diff[0] === '+') return `Added ${diff[1]}`
      if (diff[0] === '-') return `Removed ${diff[1]}`
      if (diff[0] === '~')
        return `Changed ${diff[1]} "${diff[2]}" to "${diff[3]}"`
    },
    listingChange(listingId) {
      this.listingId = listingId
      this.getLogs({ listingId })
    },
  },
}
</script>

<style></style>
