<template>
  <div>
    <v-row class="wrap align-center">
      <v-col cols="12">
        <h3>
          <span class="mr-2 font-weight-regular"> Claim items </span>

          <v-btn
            fab
            x-small
            color="primary"
            class="white--text"
            @click="addToArray"
          >
            <v-icon dark>add</v-icon>
          </v-btn>
        </h3>
      </v-col>
      <v-col v-for="(val, index) in buildArray" :key="index" cols="12">
        <v-row class="align-center bt-1 bb-1 wrap">
          <v-col :cols="entryMode ? 4 : 3" sm="2">
            <v-text-field
              v-model="val['item_type']"
              label="Item"
              class="small-text"
              @change="updateArray($event, index, 'item_type')"
            ></v-text-field>
          </v-col>
          <v-col :cols="entryMode ? 4 : 3" sm="2">
            <v-select
              v-model="val['amount']"
              class="small-select"
              type="number"
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              label="Quantity"
              @change="updateArray($event, index, 'amount')"
            ></v-select>
          </v-col>
          <v-col cols="3" sm="2">
            <v-autocomplete
              v-model="val['damage']"
              class="small-select"
              :items="['Burn marks', 'Stains', 'Broken', 'Lost']"
              label="Damage"
              @change="updateArray($event, index, 'damage')"
            />
          </v-col>

          <v-col v-if="managementRoles && !entryMode" cols="2">
            <v-text-field
              v-model="val['cost']"
              class="small-text"
              type="number"
              number
              label="Cost per unit"
              @change="updateArray($event, index, 'cost')"
            ></v-text-field>
          </v-col>
          <v-col v-if="managementRoles && !entryMode" cols="2">
            <v-text-field
              class="small-text"
              :disabled="true"
              :value="subTotal(val)"
              label="Sub-total"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-icon
              class="rounded-card red0-10 white--text"
              dark
              @click="removeCell(index)"
              >close</v-icon
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="managementRoles && !isEmpty(buildArray) && claimCompleted"
      class="comp-wrapper center-text align-center mt-3"
      cols="12"
    >
      <v-col v-if="claimCompleted" cols="6">
        <v-btn
          v-clipboard:copy="claimText"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          outlined
          small
          class="cyan-icon mt-3"
          >Copy claim</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import sumBy from 'lodash/sumBy'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['afterChange', 'description', 'images', 'entryMode', 'value'],
  data() {
    return {
      buildArray: [],
    }
  },
  computed: {
    claimCompleted() {
      return (
        this.images &&
        this.description &&
        this.buildArray.length > 0 &&
        this.allItemsCompleted
      )
    },
    allItemsCompleted() {
      let completed = true
      this.buildArray.forEach(item => {
        if (!item.item_type || !item.amount || !item.damage || !item.cost) {
          completed = false
        }
      })
      return completed
    },
    claimText() {
      if (this.images && this.description) {
        let message =
          'Hi Airbnb rep, my name is ' +
          this.$store.state.user.first_name +
          ' from Designedvr, ' +
          this.description +
          '\n'
        message += 'We have found the following issues after his visit: \n'
        this.buildArray.forEach(item => {
          message +=
            item.item_type +
            ' | ' +
            item.amount +
            ' | ' +
            item.damage +
            ' | total in: $' +
            this.subTotal(item) +
            '\n'
        })
        message += 'Total is: $' + this.totalCost

        message += '\n'
        message += 'Attaching images: \n'
        this.images.forEach(src => {
          message += src + '\n'
        })
        return message
      }
      return ''
    },
    totalCost() {
      return sumBy(
        this.buildArray,
        item => Number(item.cost) * Number(item.amount)
      )
    },
  },
  watch: {
    value(val) {
      this.buildArray = val
    },
  },
  mounted() {
    const items = this.value
    if (items && items.length !== 0) {
      this.buildArray = cloneDeep(items)
    }
  },
  methods: {
    subTotal(item) {
      if (item.cost && item.amount) {
        return item.cost * item.amount
      }
    },
    onCopy: function (e) {
      $(e.trigger).removeClass('cyan-icon')
      $(e.trigger).addClass('purple-icon')
    },
    onError: function () {
      alert('Failed to copy texts')
    },
    removeCell(index) {
      this.buildArray.splice(index, 1)
      const res = cloneDeep(this.buildArray)
      this.afterChange && this.afterChange(res)
      this.$emit('input', res)
    },
    updateArray(val, index, itemType) {
      this.buildArray[index][itemType] = val
      const res = cloneDeep(this.buildArray)
      this.afterChange && this.afterChange(res)
      this.$emit('input', res)
    },
    addToArray() {
      this.buildArray.push({})
      const res = cloneDeep(this.buildArray)
      this.afterChange && this.afterChange(res)
      this.$emit('input', res)
    },
  },
}
</script>
