<template>
  <v-dialog v-model="pop" class="white" persistent max-width="1000px">
    <info-items-panel
      v-if="!loading"
      :listing-id="listingId"
      :close-func="close"
      :hide-owners-public="hideOwnersPublic"
    />
  </v-dialog>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import InfoItemsPanel from './info-items-panel.vue'
export default {
  components: {
    InfoItemsPanel,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: {
    afterMessageChosen: String,
    module: String,
    hideOwnersPublic: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    chosenTag: null,
    searchSub: null,
    pop: true,
  }),
  computed: {
    listingId() {
      return this.currentReservation.listing_id
    },
    currentReservation() {
      return this.$store.getters.chosenChatReservation || {}
    },
    showListingInfoPop: {
      get() {
        return this.$store.state.showListingInfoPop
      },
      set(val) {
        this.$store.commit('updateShowListingInfoPop', val)
      },
    },
  },
  methods: {
    close() {
      this.$store.commit('updateShowListingInfoPop', null)
    },
  },
}
</script>
