<template>
  <v-container>
    <div class="text-h6">{{ $t('Cancellation Policy') }}</div>
    <v-card-text class="px-6">
      <div v-for="(policy, ind) in value" :key="ind" class="pa-2">
        <v-row class="align-center">
          <v-col cols="3">
            <v-text-field
              v-model="policy.from"
              type="number"
              outlined
              dense
              label="Days From"
              :rules="[required]"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="policy.to"
              type="number"
              outlined
              dense
              label="Days to"
              :rules="[required]"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="policy.amount"
              type="number"
              outlined
              dense
              label="Charge (%)"
              :rules="[required]"
            />
          </v-col>
          <v-col cols="1">
            <v-icon
              class="ml-auto"
              small
              color="error"
              @click="removePolicy(ind)"
              >$trash</v-icon
            >
          </v-col>
        </v-row>
        <v-divider />
      </div>
      <v-btn outlined class="my-3" color="info" @click="addPolicy"
        ><v-icon left>fas fa-plus</v-icon> Add Policy
      </v-btn>
    </v-card-text>
  </v-container>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  mixins: [FormRules, FormattersMixin],
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  methods: {
    addPolicy() {
      this.$emit('input', [...this.value, { from: '', to: '', amount: '' }])
    },
    removePolicy(index) {
      const newValue = [...this.value]
      newValue.splice(index, 1)
      this.$emit('input', newValue)
    },
  },
}
</script>
