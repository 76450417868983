<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.69 18">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="logout">
          <g id="Group_10279" data-name="Group 10279">
            <path
              id="Path_5232"
              data-name="Path 5232"
              class="cls-1"
              d="M8,17H3.34A2.32,2.32,0,0,1,1,14.66V3.34A2.32,2.32,0,0,1,3.34,1H8.11a.52.52,0,1,0,.05-1H3.34A3.35,3.35,0,0,0,0,3.34V14.66A3.35,3.35,0,0,0,3.34,18H8a.53.53,0,0,0,.54-.49.52.52,0,0,0-.5-.54Z"
            />
            <path
              id="Path_5233"
              data-name="Path 5233"
              class="cls-1"
              d="M16.53,8.64,13.26,5.36a.52.52,0,0,0-.73,0,.5.5,0,0,0,0,.73l2.4,2.4H4.46a.52.52,0,0,0,0,1H14.93l-2.4,2.4a.52.52,0,0,0,0,.73.51.51,0,0,0,.36.15.48.48,0,0,0,.36-.15l3.28-3.28a.52.52,0,0,0,0-.73Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logout',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
