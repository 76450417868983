import axios from 'axios'

export default {
  state: {
    payment: {},
    reservation: {},
    listing: {},
  },
  mutations: {
    updateCheckoutInfo(state, data) {
      state = data
    },
  },
  actions: {
    async getCheckoutInfo({ commit }, { payment_intent_id }) {
      try {
        const { data } = await axios.get(
          `/api/checkout-info/${payment_intent_id}/`
        )
        commit('updateCheckoutInfo', data)
      } catch (err) {
        console.error(err)
      }
    },
    async createReservationIntent(store, { reservationId }) {
      try {
        return await axios.post('/api/intent', { id: reservationId })
      } catch (err) {
        console.error(err)
      }
    },
  },
}
