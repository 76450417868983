<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.938"
    height="18.937"
    viewBox="0 0 18.938 18.937"
  >
    <g transform="translate(2.938)">
      <path
        class="a"
        d="M14.857,14.857H1.143V1.143H8V0H.571A.571.571,0,0,0,0,.571V15.429A.571.571,0,0,0,.571,16H15.429A.571.571,0,0,0,16,15.429V8H14.857Z"
        transform="translate(0 0)"
      />
      <path
        class="a"
        d="M231.9,0V1.258h4.144l-7.105,7.105.89.89,7.105-7.105V6.292H238.2V0Z"
        transform="translate(-222.196)"
      />
      <path
        class="a"
        d="M14.741,14.741H1.134V1.134h2.8V0H.567A.567.567,0,0,0,0,.567V15.308a.567.567,0,0,0,.567.567H15.308a.567.567,0,0,0,.567-.567V11.938H14.741Z"
        transform="translate(-2.937 3.063)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OpenNewTab',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
