var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"item-key":"id","items":_vm.items,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([(!_vm.isMobile)?{key:"item",fn:function({ item }){return [_c('tr',{staticClass:"title-black"},[_c('td',{class:[
          'text-capitalize ',
          { 'font-weight-medium': !!item.adjusted_items.length },
        ]},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('td',{class:{ 'font-weight-medium': !!item.adjusted_items.length }},[_vm._v(" "+_vm._s(item.amount)+" ")]),_c('td',[(!!item.additional)?_c('div',{staticClass:"taskim-task-actions flex-center"},[_c('ConfirmationModal',{attrs:{"text":_vm.text,"max-width":600,"actions":[
              {
                text: 'No',
                color: 'warning',
                onClick: () => {},
              },
              {
                text: 'Remove & Recalculate',
                color: 'notification',
                onClick: () => {
                  _vm.removeInvoiceItem({ ...item, recalculate_price: true })
                },
              },
              {
                text: 'Remove',
                color: 'success',
                onClick: () => {
                  _vm.removeInvoiceItem(item)
                },
              },
            ]},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(item){return [_c('span',_vm._g(_vm._b({},'span',item.type,false),item.on),[_c('v-btn',_vm._g({attrs:{"color":"info","icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-forever-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Delete Invoice Item")])])]}}],null,true)})],1):_vm._e()])]),_vm._l((item.adjusted_items),function(it,ind){return _c('tr',{key:ind},[_c('td',{staticClass:"secondary--text lighten-2 px-5 text-capitalize"},[_vm._v(" "+_vm._s(it.title)+" ")]),_c('td',{staticClass:"secondary--text lighten-2"},[_vm._v(_vm._s(it.amount))]),_c('td',[(!!it.additional)?_c('div',{staticClass:"taskim-task-actions flex-center"},[_c('ConfirmationModal',{attrs:{"text":_vm.text,"max-width":600,"actions":[
              {
                text: 'No',
                color: 'warning',
                onClick: () => {},
              },
              {
                text: 'Remove & Recalculate',
                color: 'notification',
                onClick: () => {
                  _vm.removeInvoiceItem({ ...it, recalculate_price: true })
                },
              },
              {
                text: 'Remove',
                color: 'success',
                onClick: () => {
                  _vm.removeInvoiceItem(it)
                },
              },
            ]},on:{"action":function($event){return _vm.removeInvoiceItem(it)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(slot){return [_c('span',_vm._g({},slot.on),[_c('v-btn',_vm._g({attrs:{"color":"info","icon":"","small":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete-forever-outline")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Delete Invoice Item")])])]}}],null,true)})],1):_vm._e()])])})]}}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }