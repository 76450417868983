<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.776"
    height="16.307"
    viewBox="0 0 14.776 16.307"
  >
    <!--    <defs>-->
    <!--      <style>-->
    <!--        .a {-->
    <!--          fill: none;-->
    <!--          stroke: #ff4757;-->
    <!--          stroke-linecap: round;-->
    <!--          stroke-linejoin: round;-->
    <!--        }-->
    <!--      </style>-->
    <!--    </defs>-->
    <g transform="translate(0.5 0.5)">
      <path class="a" d="M4.5,9H18.276" transform="translate(-4.5 -5.939)" />
      <path
        class="a"
        d="M18.215,6.061V16.776a1.531,1.531,0,0,1-1.531,1.531H9.031A1.531,1.531,0,0,1,7.5,16.776V6.061m2.3,0V4.531A1.531,1.531,0,0,1,11.327,3h3.061a1.531,1.531,0,0,1,1.531,1.531V6.061"
        transform="translate(-5.969 -3)"
      />
      <path class="a" d="M15,16.5v4.592" transform="translate(-9.642 -9.612)" />
      <path
        class="a"
        d="M21,16.5v4.592"
        transform="translate(-12.581 -9.612)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Trash',
}
</script>

<style scoped>
svg {
  stroke: currentColor;
  fill: none;
}
</style>
