<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.15 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="_001-bars" data-name=" 001-bars">
          <g id="Group_10255" data-name="Group 10255">
            <g id="Group_10254" data-name="Group 10254">
              <path
                id="Path_5135"
                data-name="Path 5135"
                class="cls-1"
                d="M21.72,0a3.6,3.6,0,1,0,3.61,3.58A3.6,3.6,0,0,0,21.72,0Zm0,6.4a2.81,2.81,0,1,1,2.82-2.79h0A2.83,2.83,0,0,1,21.72,6.4Z"
              />
            </g>
          </g>
          <g id="Group_10257" data-name="Group 10257">
            <g id="Group_10256" data-name="Group 10256">
              <path
                id="Path_5136"
                data-name="Path 5136"
                class="cls-1"
                d="M22.86,4.15a.84.84,0,0,0-.08-.2.59.59,0,0,0-.1-.17c-.05,0-.07-.1-.12-.15a.48.48,0,0,0-.17-.12.81.81,0,0,0-.18-.1,1.24,1.24,0,0,0-.2-.1l-.2-.08a.18.18,0,0,1-.1,0s-.07,0-.1,0-.07,0-.1-.05L21.44,3a.12.12,0,0,1-.05-.1.17.17,0,0,1,0-.1V2.69s0-.07,0-.1,0-.07.05-.1l.07-.07a.17.17,0,0,1,.13,0,.24.24,0,0,1,.12,0h.15l.12,0,.13,0,.1,0,.1,0,.12.05,0-.15,0-.4v-.1l-.1,0-.1,0a.41.41,0,0,0-.1,0l-.12,0-.12,0h0V1.25h-.59v.49h0a.88.88,0,0,0-.22.1A.37.37,0,0,0,21,2a.8.8,0,0,0-.15.17.47.47,0,0,0-.1.2l-.07.22a1,1,0,0,0,0,.25.75.75,0,0,0,0,.2.48.48,0,0,0,.07.2l.1.17c.05.05.07.1.12.15a.44.44,0,0,0,.18.12.59.59,0,0,0,.17.1,1.24,1.24,0,0,1,.2.1l.2.08a.14.14,0,0,1,.1,0l.1,0s.05.05.07.05l.08.08s0,0,.05.1a.5.5,0,0,1,0,.12V4.5a.16.16,0,0,1-.05.1L22,4.67l-.1.07s-.07,0-.1,0l-.12,0h-.33l-.12,0a.3.3,0,0,1-.15,0,.88.88,0,0,1-.15,0l-.07,0-.07,0-.15-.07,0,.17-.05.45v.07l.08,0a.34.34,0,0,1,.22,0,.34.34,0,0,0,.17.05,1,1,0,0,0,.2,0l.2,0h0v.49h.6V5.41h0a.88.88,0,0,0,.22-.1.82.82,0,0,0,.2-.12A.84.84,0,0,0,22.66,5a.82.82,0,0,0,.12-.2l.08-.22a.69.69,0,0,0,0-.25A.41.41,0,0,0,22.86,4.15Z"
              />
            </g>
          </g>
          <g id="Group_10259" data-name="Group 10259">
            <g id="Group_10258" data-name="Group 10258">
              <path
                id="Path_5137"
                data-name="Path 5137"
                class="cls-1"
                d="M20,7.66a.42.42,0,0,0-.57-.07l-4,3.19L10.25,8.63a.37.37,0,0,0-.37,0l-5.54,4a.41.41,0,0,0-.1.55.37.37,0,0,0,.32.17.35.35,0,0,0,.22-.08l5.37-3.83,5.19,2.15a.47.47,0,0,0,.4,0l4.18-3.32A.43.43,0,0,0,20,7.66Z"
              />
            </g>
          </g>
          <g id="Group_10261" data-name="Group 10261">
            <g id="Group_10260" data-name="Group 10260">
              <path
                id="Path_5138"
                data-name="Path 5138"
                class="cls-1"
                d="M25.77,21.21H23.94V9.76a.39.39,0,0,0-.38-.4H19.91a.4.4,0,0,0-.4.38V21.21H18.18V13.72a.38.38,0,0,0-.38-.4H14.13a.38.38,0,0,0-.4.38v7.51H12.4V13.13a.39.39,0,0,0-.38-.4H8.37a.38.38,0,0,0-.4.38v8.1H6.64V15.45a.38.38,0,0,0-.38-.4H2.59a.38.38,0,0,0-.4.38v5.78H.34a.41.41,0,0,0-.34.46A.42.42,0,0,0,.34,22H25.75a.39.39,0,0,0,.4-.38v0a.36.36,0,0,0-.32-.39Zm-19.95,0H3V15.85H5.82Zm5.79,0H8.77V13.52h2.84Zm5.76,0H14.52V14.12h2.85Zm5.78,0H20.31v-11h2.84Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BarsUp',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
