<template>
  <div class="ma-0 pb-2">
    <v-list
      elevation="3"
      three-line
      max-width="600"
      class="mt-3"
      style="margin: auto"
    >
      <v-btn
        :to="{
          query: {},
        }"
        text
        plain
        :ripple="false"
        color="primary"
      >
        <v-icon x-small left class="cyan-icon fa-rotate-180"
          >$right_arrow</v-icon
        >
        Back
      </v-btn>
      <div class="task-list">
        <today-task
          v-for="task in orderedTasks"
          :key="task.id"
          :task="task"
          :disabled.sync="tooltip"
          :pendin-task-view="true"
          @getTasks="getTasks"
        />
      </div>
      <v-col class="mt-4">
        <span
          v-if="isEmpty(orderedTasks)"
          class="center-text grey-text headline-font"
          >{{ $t('No tasks found') }}</span
        >
      </v-col>
    </v-list>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import TodayTask from 'components/todays-tasks/today-task'
import orderBy from 'lodash/orderBy'
import uniq from 'lodash/fp/uniq'
import { round } from 'lodash'
import sumBy from 'lodash/fp/sumBy'
import axios from 'axios'

export default {
  components: {
    TodayTask,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      contractorId: null,
      day: this.$moment(),
      tooltip: false,
      pendingListingTasks: [],
    }
  },
  computed: {
    ...mapState(['listingLoading']),
    orderedTasks() {
      return this.mapTasks(this.pendingListingTasks)
    },
    listingsCount() {
      return uniq(this.pendingListingTasks.map(t => t.listing_id)).length
    },
    reportedHours() {
      return round(sumBy('total_hours', this.pendingListingTasks), 1)
    },
    doneTasks() {
      return this.pendingListingTasks.filter(
        t => t.status === 'Done' || t.status === "Can't do"
      ).length
    },
  },
  mounted() {
    this.contractorId = this.getMyUserId
    this.getTasks()
  },
  methods: {
    ...mapActions(['getPendingListingTasks']),
    async getTasks() {
      const { data } = await axios.get(`/api/listing-tasks`, {
        params: {
          contractor_id: this.contractorId,
          list_view: true,
          status: ['New'],
          require_accept: true,
        },
      })
      this.pendingListingTasks = data.tasks
      this.totalJobsMap = data.total_jobs
    },
    contractorChange(value) {
      this.contractorId = value
      this.getTasks()
    },
    mapTasks(tasks) {
      const allTasks = tasks.map(t => {
        const date = this.$moment.utc(t.scheduled_at)
        const hour = date.hours()
        return {
          ...t,
          allDay: hour < 4 || hour > 22,
          otherSameDay: this.totalJobsMap[date.format('YYYY-MM-DD')],
        }
      })
      return orderBy(allTasks, ['allDay', 'scheduled_at'], 'asc')
    },
  },
}
</script>
<style scoped>
.task-list {
  max-height: calc(100vh - 340px);
  overflow-y: auto;
}
</style>
