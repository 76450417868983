<template>
  <v-row class="justify-center">
    <v-col cols="11">
      <real-estate-filters
        :search-term.sync="searchTerm"
        @fetch-listings="fetchListings"
        @toggle-listings="toggleListings"
        @reset-filters="resetFilters"
      />
      <data-table
        :headers="headers"
        :items="tableItems"
        @onRowClick="openModal"
      />
      <v-pagination
        v-if="pagination && pagination.properties_count > 29"
        v-model="page"
        :length="Math.ceil(pagination.properties_count / pagination.per_page)"
        prev-icon="chevron_left"
        next-icon="chevron_right"
        class="mt-3 mb-3"
        @input="updatePage"
      ></v-pagination>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DataTable from 'components/common/data-table/data-table.vue'
import RealEstateFilters from 'components/real-estate/real-estate-filters.vue'
import { ESTATE_HEADERS } from 'components/real-estate/config'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  components: { DataTable, RealEstateFilters },
  mixins: [PermissionsMixin, CommonFunctions],
  mounted() {
    const query = {}
    if (!this.$route.query.page) {
      query.page = 1
    }
    this.$router.replace({ query })
  },
  computed: {
    ...mapState('listings', ['listings', 'pagination']),
    tableItems() {
      return (this.listings || []).map(l => ({
        ...l,
        computedInvestementCost:
          l.setup_costs + l.purchase_price * (l.down_payment / 100),
      }))
    },
  },
  watch: {
    '$route.query': {
      handler({ page }) {
        this.page = +page || 1
        this.fetchListings()
      },
    },
  },
  methods: {
    ...mapActions('listings', ['getListings']),
    updatePage(val) {
      this.$router.push({
        query: { ...this.$route.query, page: val },
      })
    },
    fetchListings() {
      this.getListings({
        page: this.page,
        search_term: this.searchTerm,
        active: this.active,
      })
    },
    resetFilterValues() {
      this.page = 1
      this.searchTerm = ''
      this.active = true
    },
    resetFilters() {
      this.resetFilterValues()
      this.fetchListings()
    },
    updateFields(id, fields) {
      this.$store.dispatch('updateListingPartial', {
        id,
        ...fields,
        shouldUpdateState: false,
      })
      setTimeout(() => {
        this.fetchListings()
      }, 1200)
    },
    openModal(item) {
      this.$store.commit('showModal', {
        name: 'UpdateFieldsModal',
        props: {
          onConfirm: fields => this.updateFields(item.id, fields),
          item,
          fields: this.headers.filter(h => !h.readonly),
        },
      })
    },
    toggleListings(val) {
      this.resetFilterValues()
      this.active = val
      this.fetchListings()
    },
  },
  data() {
    return {
      page: +this.$route.query.page || 1,
      headers: ESTATE_HEADERS,
      active: true,
      searchTerm: '',
      tab: null,
    }
  },
}
</script>
