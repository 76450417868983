<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-text class="pa-6">
        <listing-setup-scopes
          :scopes.sync="scopeToEdit"
          :is-save-button="true"
          @on-save="save"
        />
      </v-card-text>
    </v-card>
    <template #activator="{ on }">
      <button class="text-caption" v-on="on">
        <v-icon x-small>mdi-pencil</v-icon>
        edit scope
      </button>
    </template>
  </v-dialog>
</template>

<script>
import ListingSetupScopes from 'components/listing-setup-scopes'
export default {
  name: 'ListingSetupScopeModal',
  components: { ListingSetupScopes },
  props: {
    scope: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      scopeToEdit: [],
    }
  },
  watch: {
    scope: {
      handler(newValue) {
        this.scopeToEdit = [...newValue]
      },
    },
  },
  mounted() {
    if (this.scope) {
      this.scopeToEdit = [...this.scope]
    }
  },
  methods: {
    save() {
      this.dialog = false
      this.$emit('on-update', this.scopeToEdit)
    },
  },
}
</script>
