<template>
  <v-card>
    <v-card-title>
      <object width="50" data="/minut.svg" type="image/svg+xml" />
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <v-chip class="px-4" large>
              <v-avatar tile>
                <v-icon>$thermometer</v-icon>
              </v-avatar>
            </v-chip>
            <div class="ml-3">
              <p class="text-h6 black--text mb-0">
                {{ currentLevels.temperature + ' &#8451;' }}
              </p>
              <span class="text-caption">Temperature</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <v-chip class="px-4" large>
              <v-avatar tile>
                <v-icon>$audio</v-icon>
              </v-avatar>
            </v-chip>
            <div class="ml-3">
              <p class="text-h6 black--text mb-0">
                {{ currentLevels.sound + ' dB' }}
              </p>
              <span class="text-caption">Loudness</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <v-chip class="px-4" large>
              <v-avatar tile>
                <v-icon>$humidity</v-icon>
              </v-avatar>
            </v-chip>
            <div class="ml-3">
              <p class="text-h6 black--text mb-0">
                {{ currentLevels.humidity + ' %' }}
              </p>
              <span class="text-caption">Humidity</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <v-chip class="px-4" large>
              <v-avatar tile>
                <v-icon>$battery</v-icon>
              </v-avatar>
            </v-chip>
            <div class="ml-3">
              <p class="text-h6 black--text mb-0">
                {{ device.battery.percent + ' %' }}
              </p>
              <span class="text-caption">Battery</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MinutDeviceCard',
  props: ['device'],
  computed: {
    currentLevels() {
      const sound = Math.ceil(this.device.latest_sensor_values.sound.value)
      const temperature = Math.ceil(
        this.device.latest_sensor_values.temperature.value
      )
      const humidity = Math.ceil(
        this.device.latest_sensor_values.humidity.value
      )
      return {
        sound,
        temperature,
        humidity,
      }
    },
  },
}
</script>
