<template>
  <v-dialog v-model="tenantsStatus" max-width="900">
    <template #activator="{ on }">
      <v-btn
        color="primary"
        depressed
        outlined
        v-on="on"
        @click="() => getTenantsBilling()"
      >
        Tenant Billing Statuses
      </v-btn>
    </template>
    <v-card class="pa-4">
      <div class="d-flex justify-end mb-4">
        <month-year-picker
          v-model="selectedDates"
          :max-month="currentMonth"
          :max-year="currentYear"
          @change="onDateChange"
        />
      </div>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        disable-pagination
        disable-sort
        hide-default-footer
        fixed-header
        height="900"
      >
        <template #item.receipt="{ item }">
          <a v-if="item.receipt" :href="item.receipt" target="_blank">
            <v-icon color="blue">mdi-file-document-outline</v-icon>
          </a>
        </template>
        <template #item.billed_amount="{ item }">
          {{ item.billed_amount ? toMoney(item.billed_amount) : '-' }}
        </template>
        <template #item.charged_amount="{ item }">
          {{ item.charged_amount ? toMoney(item.charged_amount) : '-' }}
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import monthYearPicker from 'components/common/month-year-picker.vue'
import formattersMixin from 'components/mixins/formatters-mixin'
import { mapActions } from 'vuex'

export default {
  components: { monthYearPicker },
  mixins: [formattersMixin],
  name: 'TenantsTable',
  data() {
    return {
      tenantsStatus: false,
      loading: false,
      selectedDates: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      headers: [
        { text: 'Tenant', value: 'tenant' },
        { text: 'PPD', value: 'ppd', align: 'center' },
        {
          text: 'Charged Listings',
          value: 'chargable_listings',
          align: 'center',
        },
        { text: 'Billed', value: 'billed_amount', align: 'center' },
        { text: 'Charged', value: 'charged_amount', align: 'center' },
        { text: 'Receipt', value: 'receipt', align: 'center' },
      ],
      items: [],
    }
  },
  computed: {
    currentMonth() {
      return new Date().getMonth()
    },
    currentYear() {
      return new Date().getFullYear()
    },
  },
  methods: {
    ...mapActions('billing', ['fetchBillings']),
    async getTenantsBilling(
      month = this.selectedDates.month,
      year = this.selectedDates.year
    ) {
      this.loading = true
      const { data } = await this.fetchBillings({ month, year })
      this.items = data.billings
      this.loading = false
    },
    onDateChange({ month, year }) {
      this.getTenantsBilling(month, year)
    },
  },
}
</script>

<style></style>
