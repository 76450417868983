<template>
  <v-data-table
    :sort-by="['name']"
    :items="losSettings.los_settings"
    :headers="losHeaders"
    v-on="$listeners"
  >
    <template #top>
      <v-row class="px-3 align-center">
        <v-col cols="6" md="2">
          <v-text-field
            v-model="searchTerm"
            label="Search by name..."
            prepend-inner-icon="search"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12" class="text-right">
          <los-setting-create-modal :los-data-set="losSettings" />
        </v-col>
      </v-row>
    </template>
    <template #item="{ item }">
      <tr class="align-center">
        <td>
          <div class="font-weight-medium text-capitalize">{{ item.name }}</div>
        </td>
        <td>
          <div class="font-weight-medium text-capitalize">
            {{ parseDate(item.from, 'DD MMM YYYY') }}
          </div>
        </td>
        <td>
          <div class="font-weight-medium text-capitalize">
            {{ parseDate(item.to, 'DD MMM YYYY') }}
          </div>
        </td>
        <td>
          <div class="font-weight-medium text-capitalize">
            {{ item.active == true ? 'Active' : 'Inactive' }}
          </div>
        </td>
        <td>
          <div class="font-weight-medium text-capitalize">
            {{ item.discount }}
          </div>
        </td>
        <td>
          <div class="font-weight-medium text-capitalize">
            {{ item.nights }}
          </div>
        </td>
        <td>
          <div div class="font-weight-medium text-capitalize">
            {{ item.whitelist.join(', ') }}
          </div>
        </td>
        <td>
          <div class="font-weight-medium text-capitalize">
            {{ item.blacklist.join(', ') }}
          </div>
        </td>
        <td class="text-sm">
          <los-setting-edit-modal :los-setting="item" />
          <confirmation-modal
            :title="'Delete LOS Setting'"
            :text="'Are you sure you want to archive this LOS Setting?'"
            @action="deleteLosSetting(item.id)"
          >
            <template #activator="{ on }">
              <v-btn
                color="warning"
                class="text-capitalize"
                icon
                elevation="0"
                v-on="on"
              >
                <v-icon size="20">$trash</v-icon>
              </v-btn>
            </template>
          </confirmation-modal>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import LosSettingCreateModal from 'components/los-settings/los-settings-create-modal.vue'
import LosSettingEditModal from 'components/los-settings/los-settings-edit-modal.vue'
import CommonFunctions from 'components/mixins/common_functions'
import axios from 'axios'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  name: 'LosSettingsTable',
  components: { ConfirmationModal, LosSettingCreateModal, LosSettingEditModal },
  props: ['headers', 'fetchAction', 'items'],
  mixins: [CommonFunctions],
  data() {
    return {
      searchTerm: '',
    }
  },

  async created() {
    await this.$store.dispatch('losSetting/getLosSettings')
  },

  methods: {
    ...mapActions('losSetting', ['updateLosSetting', 'deleteLosSetting']),
  },
  computed: {
    ...mapState('losSetting', ['losSettings']),
    losHeaders() {
      return [
        {
          name: 'name',
          text: 'Name',
          sortable: false,
          value: 'name',
        },
        {
          name: 'from',
          text: 'From',
          sortable: false,
          value: 'from',
        },
        {
          name: 'to',
          text: 'To',
          sortable: false,
          value: 'to',
        },
        {
          name: 'active',
          text: 'Status',
          sortable: false,
          value: 'active',
        },
        {
          name: 'discount',
          text: 'Discount(%)',
          sortable: false,
          value: 'discount',
        },
        {
          name: 'nights',
          text: 'Nights',
          sortable: false,
          value: 'nights',
        },
        {
          name: 'whitelisted listings',
          text: 'Whitelisted Listings',
          sortable: false,
          value: 'whitelist',
        },
        {
          name: 'blacklisted listings',
          text: 'Blacklisted Listings',
          sortable: false,
          value: 'blacklist',
        },
        {
          name: 'actions',
          text: 'Actions',
          sortable: false,
        },
      ]
    },
  },
}
</script>

<style scoped></style>
