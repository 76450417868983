<template>
  <v-autocomplete
    :value="value"
    item-value="id"
    :label="label"
    :items="allBankAccounts"
    :filter="customBankAccountFilter"
    :clearable="clearable"
    :outlined="outlined"
    @input="$emit('input', $event)"
  >
    <template #selection="{ item }">
      {{ [item.account_name, item.account_number].filter(i => i).join(' | ') }}
    </template>
    <template #item="{ item }">
      {{ [item.account_name, item.account_number].filter(i => i).join(' | ') }}
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'BankAccountPicker',
  props: ['value', 'label', 'clearable', 'outlined'],
  mounted() {
    if (!this.calledFetchBankAccount) {
      this.fetchAllBankAccounts()
    }
  },
  computed: {
    ...mapState('bankAccounts', {
      allBankAccounts: 'allAccounts',
      calledFetchBankAccount: 'fetchAllCalled',
    }),
  },
  methods: {
    ...mapActions('bankAccounts', ['fetchAllBankAccounts']),
    customBankAccountFilter(item, queryText) {
      return (
        (item.account_name &&
          item.account_name.toLowerCase().includes(queryText.toLowerCase())) ||
        (item.account_number &&
          item.account_number.toLowerCase().includes(queryText.toLowerCase()))
      )
    },
  },
}
</script>
