<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.587"
    height="19"
    viewBox="0 0 28.587 19"
  >
    <g transform="translate(0 -42.213)">
      <g transform="translate(4.617 42.213)">
        <g transform="translate(0 0)">
          <path
            class="a"
            d="M85.741,155.768a.408.408,0,0,0-.62-.1l-4.369,4.182-5.636-2.82a.371.371,0,0,0-.4.065l-6.04,5.187a.588.588,0,0,0-.108.713.381.381,0,0,0,.351.227.388.388,0,0,0,.243-.1l5.852-5.025,5.663,2.82a.417.417,0,0,0,.431-.065l4.557-4.344A.632.632,0,0,0,85.741,155.768Z"
            transform="translate(-68.489 -155.569)"
          />
        </g>
      </g>
      <g transform="translate(0 44.649)">
        <g transform="translate(0)">
          <path
            class="a"
            d="M28.18,201.147h-2V186.138a.475.475,0,0,0-.431-.519H21.789a.475.475,0,0,0-.431.519v15.009H19.9v-9.822a.475.475,0,0,0-.431-.519H15.479a.475.475,0,0,0-.431.519v9.822H13.591v-10.6a.475.475,0,0,0-.431-.519H9.2a.475.475,0,0,0-.431.519v10.6H7.308v-7.553a.475.475,0,0,0-.431-.519H2.885a.475.475,0,0,0-.431.519v7.553H.431a.527.527,0,0,0,0,1.037H28.153a.475.475,0,0,0,.431-.519A.44.44,0,0,0,28.18,201.147Zm-21.762,0h-3.1v-7.034h3.1Zm6.31,0h-3.1V191.065h3.1Zm6.283,0h-3.1v-9.3h3.1Zm6.31,0h-3.1v-14.49h3.1Z"
            transform="translate(0 -185.619)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BarsGraph',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
