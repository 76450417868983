<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0634 1.36584H4.09756C3.59024 1.36584 3.16098 1.7756 3.16098 2.30243V4.52682H0.936585C0.409756 4.52682 0 4.93658 0 5.46341V21.6976C0 22.2049 0.409756 22.6341 0.936585 22.6341H19.9024C20.4098 22.6341 20.839 22.2244 20.839 21.6976V19.4732H23.0634C23.5707 19.4732 24 19.0634 24 18.5366V2.30243C24 1.7756 23.5902 1.36584 23.0634 1.36584ZM4.09756 2.14633H23.0634C23.1415 2.14633 23.2195 2.20487 23.2195 2.30243V4.99511H3.94146V2.30243C3.94146 2.20487 4.01951 2.14633 4.09756 2.14633ZM0.936585 5.30731H3.16098V8.15609H0.780488V5.46341C0.780488 5.38536 0.839024 5.30731 0.936585 5.30731ZM20.0585 21.6976C20.0585 21.7756 20 21.8536 19.9024 21.8536H0.936585C0.858537 21.8536 0.780488 21.7951 0.780488 21.6976V8.93658H3.16098V18.5366C3.16098 19.0439 3.57073 19.4732 4.09756 19.4732H20.0585V21.6976ZM23.0634 18.6927H4.09756C4.01951 18.6927 3.94146 18.6341 3.94146 18.5366V5.7756H23.2195V18.5366C23.2195 18.6146 23.161 18.6927 23.0634 18.6927Z"
      fill="#010101"
    />
    <path
      d="M7.23905 3.8829H5.79515C5.58052 3.8829 5.40491 3.7073 5.40491 3.49266C5.40491 3.27803 5.58052 3.10242 5.79515 3.10242H7.23905C7.45369 3.10242 7.6293 3.27803 7.6293 3.49266C7.6293 3.7073 7.45369 3.8829 7.23905 3.8829Z"
      fill="#010101"
    />
    <path
      d="M10.5365 3.8829H9.09264C8.878 3.8829 8.70239 3.7073 8.70239 3.49266C8.70239 3.27803 8.878 3.10242 9.09264 3.10242H10.5365C10.7512 3.10242 10.9268 3.27803 10.9268 3.49266C10.9268 3.7073 10.7512 3.8829 10.5365 3.8829Z"
      fill="#010101"
    />
    <path
      d="M7.59021 12.2732L11.2975 14.1853V13.4439L8.44875 12.0195L11.2975 10.5951V9.85364L7.59021 11.7463V12.2732Z"
      fill="black"
    />
    <path
      d="M12.1171 14.4195H12.8195L15.0049 8.76099H14.2829L12.1171 14.4195Z"
      fill="black"
    />
    <path
      d="M15.8049 9.85364V10.5951L18.6732 12.0195L15.8049 13.4439V14.1853L19.5122 12.2732V11.7463L15.8049 9.85364Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'DevTools',
}
</script>

<style scoped></style>
