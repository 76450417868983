<template>
  <v-container>
    <v-card class="overflow-y-auto">
      <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
        <div class="text-capitalize">{{ integrationName }}</div>
      </v-card-title>
      <v-card-text>
        <div class="text-body-1">
          Sync your Xero accounts data with Boom dashboard
        </div>
        <div v-if="integration.id" class="d-flex flex-row">
          <xero-link :disabled="btnLoading" :update="true" />
          <confirmation-modal
            v-if="integration"
            :text="`Are you sure you want to disconnect ${integrationName} ?`"
            @action="disconnect"
          >
            <template #activator="{ on }">
              <v-btn
                outlined
                dense
                text
                mid
                :loading="btnLoading"
                class="mx-2"
                color="error"
                v-on="on"
                >Remove</v-btn
              >
            </template>
          </confirmation-modal>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <xero-link v-if="!integration.id" />
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import XeroLink from 'components/xero/xero-link.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import axios from '@/axios/config'
import { mapGetters } from 'vuex'
export default {
  name: 'Xero',
  data() {
    return {
      btnLoading: false,
    }
  },
  components: { ConfirmationModal, XeroLink },
  computed: {
    ...mapGetters(['integrations']),
    integrationName() {
      return this.$route.params.name
    },
    integration() {
      return this.integrations.find(i => i.name === this.integrationName) || {}
    },
  },
  methods: {
    async disconnect() {
      this.btnLoading = true
      try {
        await axios.get(`/api/xero-api/${this.integration.id}/disconnect`)
      } catch (error) {
        console.error('Failed to disconnect Xero:', error)
      } finally {
        this.btnLoading = false
      }
    },
  },
}
</script>
<style scoped></style>
