<template>
  <v-row>
    <v-col>
      <v-text-field
        :value="searchTerm"
        dense
        outlined
        label="Search Address"
        @input="$emit('update:searchTerm', $event)"
      />
    </v-col>
    <v-col>
      <v-autocomplete
        label="Missing Channels"
        :value="filteredChannels"
        multiple
        dense
        outlined
        :items="channels"
        @input="$emit('update:filteredChannels', $event)"
      ></v-autocomplete>
    </v-col>
    <v-col class="flex-grow-0">
      <v-btn-toggle
        color="primary"
        mandatory
        dense
        @change="$emit('toggle-listings', $event)"
      >
        <v-btn class="white black --text" :value="true"> Listed </v-btn>
        <v-btn class="white black --text" :value="false"> UnListed </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col>
      <v-btn color="grey" text @click="$emit('reset-filters')">RESET</v-btn>
      <v-btn
        color="primary"
        elevation="0"
        :disabled="!searchTerm && !filteredChannels.length"
        @click="$emit('fetch-listings')"
        >Apply</v-btn
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ['searchTerm', 'channels', 'filteredChannels'],
}
</script>
