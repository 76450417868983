<template>
  <v-row class="justify-lg-space-between justify-center">
    <v-col cols="auto" class="text-center hidden-lg-and-up">
      <div>{{ $t('Total') }}</div>
      <v-progress-circular
        :size="140"
        :rotate="-90"
        width="22"
        color="success lighten-2"
        :value="(totalDone * 100) / totalToday"
      >
        <span class="text-h6 font-weight-bold black--text"
          >{{ totalDone }} / {{ totalToday }}</span
        >
      </v-progress-circular>
    </v-col>
    <v-col cols="auto" class="text-center hidden-md-and-down">
      <div>{{ $t('Cleaning') }}</div>
      <v-progress-circular
        :size="140"
        :rotate="-90"
        width="22"
        color="success lighten-2"
        :value="(cleaningsToday.done * 100) / sumTasks(cleaningsToday)"
      >
        <span class="text-h6 font-weight-bold black--text"
          >{{ cleaningsToday.done }} / {{ sumTasks(cleaningsToday) }}</span
        >
      </v-progress-circular>
    </v-col>
    <v-col cols="auto" class="text-center black--text hidden-md-and-down">
      <div>{{ $t('Maintenance') }}</div>
      <v-progress-circular
        :size="140"
        :rotate="-90"
        width="22"
        color="notification lighten-1"
        :value="(maintenanceToday.done * 100) / sumTasks(maintenanceToday)"
      >
        <span class="text-h6 font-weight-bold black--text"
          >{{ maintenanceToday.done }} / {{ sumTasks(maintenanceToday) }}</span
        >
      </v-progress-circular>
    </v-col>
    <v-col cols="auto" class="text-center hidden-md-and-down">
      <div>{{ $t('Other') }}</div>
      <v-progress-circular
        :size="140"
        :rotate="-90"
        color="darkpurple lighten-4"
        width="22"
        :value="(otherToday.done * 100) / sumTasks(otherToday)"
      >
        <span class="text-h6 font-weight-bold black--text"
          >{{ otherToday.done }} / {{ sumTasks(otherToday) }}</span
        >
      </v-progress-circular>
    </v-col>
  </v-row>
</template>

<script>
import sum from 'lodash/fp/sum'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'TodayStatsGauges',
  mixins: [DeviceMixin],
  props: ['stats'],
  computed: {
    cleaningsToday() {
      return this.stats.graph_stats.cleaning_stats
    },
    maintenanceToday() {
      return this.stats.graph_stats.maintenance_stats
    },

    otherToday() {
      return this.stats.graph_stats.other_tasks
    },
    totalDone() {
      return sum([
        this.cleaningsToday.done,
        this.maintenanceToday.done,
        this.otherToday.done,
      ])
    },
    totalToday() {
      return sum([
        this.sumTasks(this.cleaningsToday),
        this.sumTasks(this.maintenanceToday),
        this.sumTasks(this.otherToday),
      ])
    },
  },
  methods: {
    sumTasks(taskObject) {
      return sum(Object.values(taskObject))
    },
  },
}
</script>

<style scoped>
::v-deep .v-carousel__controls__item.v-btn.v-btn--icon {
  color: black;
}
::v-deep
  .v-carousel__controls__item.v-btn.v-item--active.v-btn--active.v-btn--icon.v-btn--round.theme--dark.v-size--small {
  width: 20px;
  height: 20px;
  font-size: 0.625rem;
}
</style>
