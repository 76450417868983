<template>
  <v-dialog v-model="showLtDailyPop" persistent max-width="600px">
    <v-card class="white pa-3">
      <v-row>
        <v-col cols="12">
          <v-btn fab dark small color="teal" class="float-right" @click="close">
            <v-icon dark>close</v-icon>
          </v-btn>
        </v-col>
        <v-col v-for="task in tasks" :key="task.id" cols="12">
          <listing-task-tile class="ml-3 mr-3" :item="task"></listing-task-tile>
        </v-col>
        <v-col
          v-if="tasks.length === 0"
          class="grey--text center-text headline-font"
        >
          <v-icon class="green--text">done</v-icon>
          No service calls found
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ListingTaskTile from './listing-task-tile'

export default {
  components: {
    ListingTaskTile,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data: function () {
    return {
      dialog: false,
      item: null,
    }
  },
  computed: {
    tasks() {
      return this.$store.state.ltDailyPopTasks
    },
    showLtDailyPop() {
      return this.$store.state.showLtDailyPop
    },
  },
  methods: {
    close() {
      this.$store.commit('updateShowLtDailyPop', false)
    },
  },
}
</script>
