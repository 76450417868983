<template>
  <v-footer v-if="showFooter" class="pa-0">
    <div
      class="d-flex footer-bg white--text align-center justify-center flex pa-4"
    >
      <p class="pr-6 ma-0 white--text text-decoration-none">
        &copy;<strong>EasyAir</strong>
      </p>
      <div v-for="(icon, index) in icons" :key="index" class="d-flex">
        <v-divider vertical />
        <v-btn
          :key="icon.url"
          target="_blank"
          :href="icon.url"
          class="mx-3 white--text"
          icon
        >
          <v-icon size="24px">{{ icon.faIcon }}</v-icon>
        </v-btn>
      </div>
    </div>
  </v-footer>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import get from 'lodash/fp/get'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  computed: {
    showFooter() {
      return false
    },
    websiteLink() {
      return get('links.website', this.$store.state.app.configuration)
    },
    icons() {
      return Object.entries(this.$store.state.app.configuration.links)
        .filter(([title]) => title !== 'website')
        .map(([title, url]) => {
          return {
            faIcon:
              title === 'booking_site' ? '$designedvrcom' : `fab fa-${title}`,
            title,
            url,
          }
        })
    },
  },
}
</script>
