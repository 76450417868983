import { render, staticRenderFns } from "./portfolio-page.vue?vue&type=template&id=2506da92&scoped=true&"
import script from "./portfolio-page.vue?vue&type=script&lang=js&"
export * from "./portfolio-page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2506da92",
  null
  
)

export default component.exports