<template>
  <div class="px-4 mt-3">
    <v-row
      v-if="
        isDesignedVr &&
        (managementRoles ||
          isCleaningManager ||
          isCleaningSupervisor ||
          hasAbility('submit_sc'))
      "
    >
      <v-col cols="auto">
        <listing-task-module
          v-if="!loading"
          :after-save-func="afterChangeToTask"
        />
      </v-col>
    </v-row>
    <task-calendar-filters
      v-show="!isCleaner"
      :listing-id="listingId"
      @on-fetch="getCurrentListingTasks"
    />
    <ltd-snackbar :after-save-func="afterChangeToTask" />
    <div
      v-if="managementRoles && isDesignedVr"
      class="d-flex justify-space-between"
    >
      <div>
        <div
          v-if="unassignedListingTasks.length"
          class="warning--text text-subtitle-2"
        >
          {{ $t('Total Unassigned') }} ({{ unassignedListingTasks.length }})
        </div>
        <div
          v-if="pmUnassignedListingTasks.length"
          class="warning--text text-subtitle-2"
        >
          {{ $t('PM Related') }} ({{ pmUnassignedListingTasks.length }})
        </div>
      </div>
    </div>
    <div v-if="showModal">
      <lt-popup
        :after-change="afterChangeToTask"
        :event="currentEvent"
        :calendar="calApi"
        :close="close"
        :listing-task="currentListingTask"
      />
    </div>

    <v-row wrap>
      <v-col class="pa-0" cols="12">
        <v-divider class="my-3" />
        <div class="d-flex justify-space-between mb-2 flex-wrap align-center">
          <div
            class="d-flex align-center flex-wrap justify-start justify-sm-center"
          >
            <v-btn-toggle
              v-if="!isMobile"
              v-model="toggleView"
              class="my-2 mr-2"
              color="primary"
              dense
              mandatory
              @change="onViewChange"
            >
              <v-btn v-show="!isInvestor" value="calendar">{{
                $t('Calendar')
              }}</v-btn>
              <v-btn value="list">{{ $t('List') }}</v-btn>
              <v-btn value="map">{{ $t('Map') }} </v-btn>
            </v-btn-toggle>
            <v-btn-toggle
              v-model="toggleTimeScope"
              class="my-2 mr-2"
              color="primary"
              dense
              mandatory
              @change="onTimeScopeChange"
            >
              <v-btn
                v-if="!isMobile"
                :disabled="isContractorView"
                value="month"
                >{{ $t('Month') }}</v-btn
              >
              <v-btn :disabled="isContractorView" value="week">{{
                $t('Week')
              }}</v-btn>
              <v-btn value="day">{{ $t('Day') }}</v-btn>
            </v-btn-toggle>
            <v-btn-toggle
              v-model="toggleByScope"
              class="my-2 mr-2"
              color="primary"
              dense
              mandatory
              @change="onByScopeChange"
            >
              <v-btn v-if="!isMobile" value="contractor"
                >{{ $t('Contractors') }}
              </v-btn>
              <v-btn value="listings">{{ $t('Listings') }}</v-btn>
              <v-btn value="all">{{ $t('All') }}</v-btn>
            </v-btn-toggle>
          </div>
          <div
            :class="[isMobile && 'w-100', 'text-center', 'mt-4']"
            class="d-flex align-center flex justify-space-between justify-lg-end"
          >
            <div>
              <v-btn
                :loading="listingLoading"
                :disabled="listingLoading"
                color="primary"
                outlined
                small
                class="mx-2"
                @click="getCurrentListingTasks"
              >
                <v-icon :left="!isMobile">refresh</v-icon>
                <span class="hidden-xs-only">{{ $t('Refresh') }}</span>
              </v-btn>
              <v-btn small primary="info" outlined @click="goToday">{{
                $t('Today')
              }}</v-btn>
              <date-picker
                :icon="true"
                label="Date"
                text-field-class="mx-2"
                @change="goToDate"
              />
            </div>
            <div class="fc-direction-ltr d-flex align-center">
              <v-btn icon @click="onDateNavigation(-1)">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="font-weight-medium text-subtitle-1 mx-0">{{
                calApi ? calApi.view.title : ''
              }}</span>
              <v-btn icon @click="onDateNavigation(1)">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <calendar-event-context
          :users-map="usersMap"
          :event="currentEvent"
          :open="showMenu"
          :pos-x="x"
          :pos-y="y"
        />
        <v-row class="px-2" :class="{ 'hide-calander': isMapView }">
          <full-calendar
            id="tasks-cal"
            ref="tasksCalendar"
            :options="dynamicConfig"
          >
            <template #slotLabelContent="{ date, text }">
              <div v-if="isListingView">
                <div class="text-subtitle-2">{{ parseDate(date, 'dddd') }}</div>
                <div class="text-subtitle-1 font-weight-medium">
                  {{ parseDate(date, 'MMMM DD') }}
                </div>
              </div>
              <div v-else>
                <div class="text-subtitle-2">{{ text }}</div>
              </div>
            </template>
            <template #eventContent="{ event }" class="bt-1">
              <task-calendar-cell
                :users-map="usersMap"
                :mode="toggleView === 'list' ? 'list' : 'cell'"
                :is-listing-view="isListingView"
                :is-daily-view="isDailyView"
                :event="event"
              />
            </template>
            <template
              v-if="managementRoles"
              #resourceLabelContent="{ resource }"
            >
              <div v-if="isListingView">{{ resource.title }}</div>
              <div v-else>{{ resource.title }}</div>
              <div
                v-if="
                  stats[resource.id] &&
                  resource.id !== 'null' &&
                  isContractorView
                "
                class="text-caption font-weight-medium"
              >
                <span>
                  {{ round(stats[resource.id].hours, 1) || 0 }}/8 hrs
                </span>
                <span
                  :class="[stats[resource.id].hours === 8 && 'success--text']"
                  >({{ toPercent((stats[resource.id].hours || 0) / 8) }})</span
                >
                <div>
                  <span>
                    {{ dollarFormatter(stats[resource.id].cost, 0) }}/$300
                  </span>
                  <span
                    :class="[stats[resource.id].cost > 300 && 'success--text']"
                  >
                    (
                    {{ toPercent((stats[resource.id].cost || 0) / 300) }})
                  </span>
                </div>
              </div>
            </template>
          </full-calendar>
          <tasks-map
            v-if="isMapView"
            :is-contractor-scope="toggleByScope === 'contractor'"
            :cal-api="calApi"
            @get-tasks="getCurrentListingTasks"
          />
        </v-row>
      </v-col>
      <v-col
        v-if="
          (isAdmin ||
            isOperationsPersonal ||
            isCommunicationManager ||
            isCleaningSupervisor) &&
          !listingId &&
          !hideStats
        "
        cols="12"
      >
        <task-calendar-stats
          v-if="listingTasksLoaded && isDesignedVr"
          :filter-sc="passFiltersSc"
          :show-swap="isDailyView"
          :after-save-func="afterChangeToTask"
          :task-clicked="taskClicked"
          :listing-loading="listingLoading"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { filter, sortBy } from 'lodash'
import { debounce, find } from 'lodash/fp'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import NavigationMixin from 'components/mixins/navigation-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import LtMixin from 'components/mixins/lt-mixin'
import FullCalendar from '@fullcalendar/vue'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import resourceTimeGrid from '@fullcalendar/resource-timegrid'
import scrollGridPlugin from '@fullcalendar/scrollgrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import TaskCalendarStats from 'components/task-calendar-stats'
import LtdSnackbar from 'components/ltd-snackbar'
import LtPopup from 'components/listing-tasks/lt-popup'
import TaskCalendarFilters from 'components/calendar/task-calendar-filters'
import CalendarEventContext from 'components/calendar/calendar-event-context'
import TaskCalendarCell from 'components/calendar/task-calendar-cell'
import { mapTaskOrder } from 'components/calendar/utils'
import TasksMap from 'components/tasks-map-view/tasks-map.vue'
import CalendarMixin from 'components/mixins/calendar-mixin'
import DatePicker from 'components/form-fields/date-picker'
import ListingTaskModule from 'components/listing-task-module.vue'

const VIEWS = {
  CALENDAR: 'calendar',
  LIST: 'list',
  MAP: 'map',
}
const TIME_SCOPES = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
}
const RESOURCE_SCOPES = {
  ALL: 'all',
  LISTING: 'listings',
  CONTRACTOR: 'contractor',
}

const CALL_API_VIEWS = {
  calendar_month_all: 'dayGridMonth',
  calendar_week_all: 'timeGridWeek',
  calendar_day_all: 'timeGridDay',
  calendar_day_contractor: 'resourceTimeGridDay',
  calendar_week_contractor: 'resourceTimeGridWeek',
  calendar_month_contractor: 'resourceTimeGridMonth',
  calendar_month_listings: 'resourceTimelineMonth',
  calendar_week_listings: 'resourceTimelineWeek',
  calendar_day_listings: 'resourceTimelineDay',
  list_month_all: 'listMonth',
  list_week_all: 'listWeek',
  list_day_all: 'listDay',
  list_month_listings: 'resourceDayGridMonth',
  list_week_listings: 'resourceDayGridWeek',
  list_day_listings: 'resourceDayGridDay',
  list_day_contractor: 'resourceDayGridDay',
  list_week_contractor: 'resourceDayGridWeek',
}

export default {
  components: {
    ListingTaskModule,
    FullCalendar,
    TaskCalendarCell,
    CalendarEventContext,
    TaskCalendarFilters,
    LtPopup,
    LtdSnackbar,
    TaskCalendarStats,
    TasksMap,
    DatePicker,
  },
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    FormattersMixin,
    CalendarMixin,
    ColorsMixin,
    DeviceMixin,
    LtMixin,
    NavigationMixin,
  ],
  props: [
    'listingId',
    'initialView',
    'noScroll',
    'singleView',
    'orderBy',
    'hideStats',
    'initialFilters',
  ],
  watch: {
    listingTaskLoading(val) {
      if (!val) this.updateContractorStats()
    },
    listingTasks() {
      this.updateContractorStats()
    },
    calendarRefresh() {
      this.events = this.getEvents()
    },
  },
  mounted() {
    if (this.isInvestor) {
      this.initialStatus = [
        'New',
        'In Progress',
        'Rejected',
        'Pending Approval',
        'Owner Approval',
        'Done',
      ]
    }
    if (this.isMobile || !this.isDesignedVr) {
      this.toggleTimeScope = TIME_SCOPES.DAY
    }
    if (this.noCustomFilters()) {
      if (this.currentUser.region) {
        this.filters.regions = [+this.currentUser.region]
      }
    }
    this.debouncer = debounce(750, this.getCurrentListingTasks)
    if (this.$refs.tasksCalendar) {
      this.calApi = this.$refs.tasksCalendar.getApi()
      if (!this.listingId && !this.isMobile && this.isDesignedVr) {
        this.toggleTimeScope = TIME_SCOPES.WEEK
      }
      this.updateFromQuery(['toggleView', 'toggleTimeScope'])

      this.onViewChange(this.toggleView)
      $('#tasks-cal button.fc-button-primary').on('click', () => {
        this.debouncer.call(this)
      })
    }
    this.onTimeScopeChange(this.toggleTimeScope)
    this.watchQuery(['toggleView', 'toggleTimeScope'])
  },
  destroyed() {
    this.$store.commit('taskCalendar/resetFilters')
  },
  computed: {
    ...mapGetters('taskCalendar', ['filters']),
    ...mapGetters('users', ['usersMap']),
    ...mapGetters(['currentUser', 'weekStartsAt']),
    ...mapState('taskCalendar', [
      'excludedFilters',
      'forcedFilters',
      'calendarRefresh',
    ]),
    ...mapState(['listingTasks', 'listingTasksLoaded', 'listingTaskLoading']),
    isMapView() {
      return this.toggleView === VIEWS.MAP
    },
    taskId() {
      return this.$route.query.reportTaskDrawer
    },
    isListingView() {
      return this.toggleByScope === 'listings'
    },
    isContractorView() {
      return this.toggleByScope === 'contractor'
    },
    currentListingTask() {
      return this.$store.getters.currentListingTask || this.currentTask
    },
    taskOrderMap() {
      return mapTaskOrder(this.listingTasks)
    },
    resources() {
      if (this.isListingView) {
        return this.$store.state.tasksCalendarListingResources
      }
      return this.$store.state.tasksCalendarContractorResources
    },
    dynamicConfig() {
      let resources = []
      if (this.filters.contractor_id.length && this.isContractorView) {
        resources = this.resources.filter(resource => {
          return (
            this.filters.contractor_id.includes(resource.id) ||
            (this.toggleByScope === 'contractor' &&
              this.toggleView === 'calendar' &&
              resource.id === null)
          )
        })
      } else if (
        this.filters.listings_id &&
        this.filters.listings_id.length &&
        this.isListingView
      ) {
        resources = this.resources.filter(resource => {
          return (
            this.filters.listings_id.includes(resource.id) ||
            (this.toggleByScope === 'listings' &&
              this.toggleView === 'calendar' &&
              resource.id === null)
          )
        })
      } else {
        resources = this.resources
      }
      return {
        ...this.config,
        editable: !this.externalContractor && !this.isInvestor,
        events: this.events,
        resources,
      }
    },
    pmUnassignedListingTasks() {
      return filter(
        this.unassignedListingTasks,
        task =>
          !['cleaning', 'purchase-replacement', 'finance'].includes(
            task.task_type
          ) && !task.mini_extra_data.related_to_setup
      )
    },
    unassignedListingTasks() {
      return filter(this.listingTasks, task => !task.assigned_contractor_id)
    },
    listingTasks() {
      return this.$store.state.listingTasks
    },
    config() {
      return {
        aspectRatio: 0.9,
        height: 'auto',
        stickyFooterScrollbar: true,
        eventMinHeight: 65,
        resourceOrder: this.isListingView ? 'title' : '-id',
        slotMaxTime: '22:00:00',
        slotMinTime: '04:00:00',
        dayMaxEventRows: 25,
        dayMinWidth: 200,
        firstDay: this.weekStartsAt,
        plugins: [
          listPlugin,
          dayGridPlugin,
          timeGridPlugin,
          resourceTimeGrid,
          scrollGridPlugin,
          interactionPlugin,
          resourceTimelinePlugin,
          resourceDayGridPlugin,
          resourceTimeGridPlugin,
        ],
        schedulerLicenseKey: '0765990167-fcs-1637703520',
        initialView: CALL_API_VIEWS.calendar_day_all,
        defaultTimedEventDuration: '01:00',
        timeZone: 'UTC',
        eventOrder: this.isListingView
          ? 'start'
          : this.orderBy || 'allDay,start,status',
        headerToolbar: {
          left: '',
          center: '',
          right: '',
        },
        slotLaneClassNames: ({ isToday }) => {
          if (isToday) {
            return 'primary today-lane'
          }
          return 'p-relative'
        },
        slotMinWidth: 240,
        resourceAreaWidth: '20%',
        displayEventTime: false,
        eventDisplay: 'block',
        eventDidMount: arg => {
          arg.el.addEventListener('contextmenu', jsEvent =>
            this.openTooltip(jsEvent, arg.event)
          )
        },
        eventWillUnmount: arg => {
          arg.el.removeEventListener('contextmenu', jsEvent =>
            this.openTooltip(jsEvent, arg.event)
          )
        },
        eventResize: this.handleEventResize,
        eventDrop: this.handleEventDrop,
        eventClick: this.handleEventClick,
        slotLabelInterval: this.isListingView ? { days: 1 } : null,
        views: {
          resourceTimeGridDay: {
            titleFormat: {
              weekday: 'short',
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            },
          },
          resourceTimelineMonth: {
            titleFormat: {
              month: 'long',
              year: 'numeric',
            },
            type: 'resourceTimelineMonth',
          },
        },
        eventClassNames: ({ event }) => {
          let classes = ''
          if (this.toggleView === VIEWS.LIST) {
            classes += 'no-border event-list-day'
          }
          if (
            event.extendedProps.task.planned_duration < 1 &&
            this.calApi &&
            ![
              'dayGridMonth',
              'listDay',
              'listWeek',
              'listMonth',
              'resourceDayGridDay',
            ].includes(this.calApi.view.type)
          ) {
            classes += ' d-flex overflow-hidden min-event-height'
          }
          return classes
        },
      }
    },
  },
  methods: {
    ...mapActions(['getCreationInfo']),
    getEvents() {
      const eventList = []
      const { listingTasks } = this.$store.state

      if (listingTasks) {
        let items = listingTasks
        if (
          !(
            this.toggleByScope === 'contractor' &&
            this.toggleView === 'calendar'
          ) &&
          this.filters.contractor_id.length
        ) {
          items = listingTasks.filter(lt => lt.assigned_contractor_id)
        }
        items.forEach(day => {
          if (this.passFiltersSc(day)) {
            const needsApproval = !day.manual_approved && day.status === 'Done'

            const start = day.scheduled_at ? day.scheduled_at : new Date()
            let end = day.scheduled_at_end
            if (day.total_hours) {
              end = this.$moment(start).utc().add(day.total_hours, 'hours')
            }
            const isSameDay =
              this.$moment.utc(start).format('YYYY-MM-DD') ===
              this.$moment.utc(end).format('YYYY-MM-DD')
            const hour = this.$moment.utc(start).hours()

            eventList.push({
              task: day,
              id: day.id,
              status: this.statusToNum(
                needsApproval ? 'Pending Approval' : day.status
              ),
              start: this.$moment.utc(start).format('YYYY-MM-DD HH:mm:ss'),
              end: isSameDay
                ? this.$moment.utc(end).format('YYYY-MM-DD HH:mm:ss')
                : this.$moment
                    .utc(start)
                    .add(1, 'hours')
                    .format('YYYY-MM-DD HH:mm:ss'),
              allDay: hour < 4 || hour > 22,
              backgroundColor: '#fff',
              resourceId: this.isContractorView
                ? day.assigned_contractor_id
                : day.listing_id,
            })
          }
        })
      }

      if (eventList.length === 0 && !this.singleView) {
        eventList.push({
          task: {
            id: 0,
            assigned_contractor_id: -1,
            description: "Can't find tasks",
            status: 'demo',
            cant_be_moved: false,
          },
          start: this.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
          end: this.$moment.utc().format('YYYY-MM-DD HH:mm:ss'),
          allDay: true,
        })
      }

      return eventList
    },
    updateContractorStats() {
      const stats = {}
      this.listingTasks.forEach(t => {
        if (t.allDay) return
        const contractor = stats[t.assigned_contractor_id]
        const cost = t.mini_extra_data.avg_price_per_hour || 0
        if (contractor) {
          contractor.hours += t.planned_duration
          contractor.cost += t.planned_duration * cost
        } else {
          stats[t.assigned_contractor_id] = {}
          stats[t.assigned_contractor_id].hours = t.planned_duration
          stats[t.assigned_contractor_id].cost = t.planned_duration * cost
        }
      })
      this.stats = stats
      this.calApi.render()
    },
    onViewChange(val) {
      const view = this.isMapView ? VIEWS.CALENDAR : val
      this.calApi.changeView(
        CALL_API_VIEWS[`${view}_${this.toggleTimeScope}_${this.toggleByScope}`]
      )
      this.$nextTick(() => {
        this.calApi.today()
      })
    },
    onTimeScopeChange(val) {
      const view = this.isMapView ? VIEWS.CALENDAR : this.toggleView
      const calView = CALL_API_VIEWS[`${view}_${val}_${this.toggleByScope}`]
      this.calApi.changeView(calView)
      this.calApi.today()
      this.$nextTick(() => {
        this.debouncer.call(this)
      })
    },
    onByScopeChange(val) {
      if (val === 'contractor') {
        this.toggleTimeScope = 'day'
        this.calApi.today()
        this.debouncer.call(this)
      }
      const view = this.isMapView ? VIEWS.CALENDAR : this.toggleView
      const calView = CALL_API_VIEWS[`${view}_${this.toggleTimeScope}_${val}`]
      this.calApi.changeView(calView)
    },
    openTooltip(jsEvent, calEvent) {
      jsEvent.preventDefault()
      this.showMenu = false
      this.x = jsEvent.clientX
      this.y = jsEvent.clientY
      this.$nextTick(() => {
        this.currentEvent = calEvent
        this.showMenu = true
      })
    },
    handleEventResize({ event, endDelta }) {
      const { task } = event.extendedProps
      const duration = endDelta.milliseconds / 1000 / 60 / 60
      this.$store.dispatch('updateListingTask', {
        planned_duration: task.planned_duration + duration,
        id: task.id,
      })
    },
    async handleEventDrop({ revert, newResource, event }) {
      const { task } = event.extendedProps

      if (
        task.cant_be_moved ||
        (newResource &&
          this.toggleByScope === 'listings' &&
          newResource.id !== 'null')
      ) {
        const message = task.cant_be_moved
          ? 'Are you sure you want to move, this event is locked into this date?'
          : 'Are you sure you want to move this task to another listing?'

        const userConfirm = confirm(message)
        if (!userConfirm) {
          revert()
          return
        }
      }
      const payload = {
        scheduled_at: event.start,
      }
      if (newResource && this.toggleByScope === 'contractor') {
        payload.assigned_contractor_id =
          newResource.id === 'null' ? null : newResource.id
      } else if (newResource && this.toggleByScope === 'listings') {
        payload.listing_id = newResource.id === 'null' ? null : newResource.id
      }

      const updatedTask = await this.$store.dispatch('updateListingTask', {
        id: task.id,
        ...payload,
      })
      event.setExtendedProp('task', updatedTask)
    },
    handleEventClick({ event }) {
      const taskId = event.extendedProps.task.id
      if (!taskId) return
      if (
        !this.isAdmin &&
        (this.isContractor ||
          this.isInvestor ||
          this.isCleaner ||
          !this.onPayrole)
      ) {
        this.currentEvent = event
        this.showModal = true
        this.currentTask = find(t => t.id === taskId, this.listingTasks)
        this.$store.dispatch('getListingTaskBg', taskId)
      } else {
        this.addToQuery({ reportTaskDrawer: taskId })
        this.currentTask = find(t => t.id === taskId, this.listingTasks)
      }
    },
    taskClicked(task) {
      this.showModal = true
      const taskId = task.id
      this.currentTask = find(t => t.id === taskId, this.listingTasks)
      if (!this.isInvestor) {
        this.getCreationInfo({
          listingsIds: [this.currentTask.listing_id],
          id: this.currentTask.id,
        })
      }
      this.$store.dispatch('getListingTaskBg', taskId)
    },
    noCustomFilters() {
      const { type, ...filters } = this.filters
      return (
        type === 'All' &&
        Object.keys(filters).every(k => this.isEmpty(filters[k]))
      )
    },
    async getCurrentListingTasks() {
      const from = this.parseDate(this.calApi.view.activeStart)
      const to = this.parseDate(this.calApi.view.activeEnd)
      this.$store.commit('updateListingTaskCalDate', this.calApi.view.title)
      const payload = this.prepareFiltersPayload(this.filters, {
        excludedFilters: this.excludedFilters,
        forcedFilters: this.forcedFilters,
      })
      const filters = {
        ...payload,
        saveToCal: true,
        listing_id: this.listingId,
        per_page: -1,
      }
      if (this.$moment(from).add(1, 'day').isSame(to, 'day')) {
        filters.date = from
      } else {
        filters.from = from
        filters.to = to
      }
      await this.$store.dispatch('getListingTasks', { filters })
      this.$store.commit('taskCalendar/updateFilterFetched', true)
      this.events = this.getEvents()
    },
    afterChangeToTask(closeModal) {
      if (closeModal) {
        this.close()
      }
    },
    close() {
      this.chosenTaskId = null
      this.currentTask = null
      this.currentEvent = null
      this.showModal = false
      this.$store.commit('updateCurrentListingTask', null)
    },
    goToday() {
      this.calApi.today()
      this.debouncer.call(this)
    },
    goToDate(date) {
      this.calApi.gotoDate(date)
      this.debouncer.call(this)
    },
    onDateNavigation(change) {
      if (change > 0) {
        this.calApi.next()
      } else {
        this.calApi.prev()
      }
      this.debouncer.call(this)
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
      center: {},
      showModal: false,
      showMenu: false,
      currentTask: null,
      calApi: null,
      currentEvent: null,
      toggleView: VIEWS.CALENDAR,
      toggleTimeScope: TIME_SCOPES.WEEK,
      toggleByScope: RESOURCE_SCOPES.LISTING,
      initialStatus: this.listingId
        ? [
            'New',
            'In Progress',
            'Rejected',
            'Pending Approval',
            'Owner Approval',
            'Done',
            `Can't do`,
          ]
        : ['New', 'In Progress', 'Rejected', `Can't do`],
      currentChosenDate: null,
      chosenTaskId: null,
      stats: {},
      events: [],
    }
  },
}
</script>
<style scoped>
#tasks-cal {
  height: 70vh;
}
</style>
<style lang="scss">
.availability {
  width: 6px;
  flex: 0 0 6px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
#tasks-cal .today-lane {
  opacity: 0.2;
}
.availability.wider-border {
  width: 10px;
  flex: 0 0 10px;
  border-radius: 0;
}

.Free.today {
  background: green;
}

.Checkout.today {
  background: linear-gradient(to bottom, red 0, red 33%, green 33%, green 100%);
}

.late-co {
  background: linear-gradient(to bottom, red 0, red 66%, green 66%, green 100%);
}

.early-ci {
  background: linear-gradient(to bottom, green 0, red 35%, red 100%);
}

.Check-in.today {
  background: linear-gradient(to bottom, green 0, green 66%, red 33%, red 100%);
}

.Occupied {
  background: red;
}

.Blocked.today {
  background: lightgrey;
}

.Check-in.and {
  background: linear-gradient(
    to bottom,
    red 0,
    red 33%,
    green 33%,
    green 66%,
    red 66%,
    red 100%
  );
}

a.fc-day-grid-event.res-cal.canceled {
  background-color: #f1f1f1;
}

a.fc-day-grid-event.res-cal.canceled .fc-title,
a.fc-day-grid-event.res-cal.canceled::after {
  color: #a3a6b4;
}

.fc {
  width: 100%;
}

.fc .fc-toolbar.fc-header-toolbar {
  flex-wrap: wrap;
}

.no-border {
  border: none;
}

.fc-h-event {
  border: none;
}

.fc-more-popover {
  max-height: 60%;
  overflow-y: auto;
  z-index: 100 !important;
  max-width: 400px;
}

.min-event-height {
  min-height: 22px;
}

.fc-event {
  border: #ebebeb 1px solid !important;
  border-radius: 7px !important;
}

.fc-listDay-view {
  overflow: auto;
}

.fc-list-event-graphic {
  display: none;
}

.event-list-day .fc-list-event-title {
  padding: 0 !important;
  height: 65px !important;
}

.fc .fc-timegrid-slot-label {
  vertical-align: initial;
}

.hide-calander {
  padding: 12px 0 0 0;

  .fc-view-harness {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  :deep() .fc-scrollgrid-section-sticky > * {
    position: sticky;
    top: 0;
  }
  #tasks-cal {
    height: 65vh;
  }
}
</style>
