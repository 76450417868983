<template>
  <google-map
    :markers-data="allMarkers"
    :map-options="{ center, disableDefaultUI: true }"
    :show-directions="showDirections"
    @open-task="showTask"
    @route-info="routeInfoHandler"
  />
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import CalendarMixin from 'components/mixins/calendar-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import GoogleMap from 'components/common/google-maps/google-map'
import { mapTaskOrder, getTaskOrder } from 'components/calendar/utils'

export default {
  components: { GoogleMap },
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    DeviceMixin,
    CalendarMixin,
    ColorsMixin,
  ],
  props: ['tasks', 'users', 'locations', 'center', 'showDirections'],
  data() {
    return {
      getTaskOrder,
      taskOrder: mapTaskOrder(this.tasks),
    }
  },
  computed: {
    taskMarkers() {
      return this.tasks.map(t => {
        const order = getTaskOrder(t, this.taskOrder)
        const marker = {
          id: t.id,
          order,
          clickAction: 'open-task',
          navigate: !t.allDay,
          position: {
            lat: t.lat,
            lng: t.lng,
          },
          icon: {
            url: this.taskIcon(t),
            labelOrigin: { x: 16, y: -10 },
          },
        }
        marker.label = {
          text: `${order ? order : 'U'}`,
          className: `marker-label ${
            order ? this.stringToHslColor(t.assigned_to_name) : 'grey'
          }`,
          color: 'white',
        }
        return marker
      })
    },
    userMarkers() {
      return this.users
        .filter(c => c.lat)
        .map(u => ({
          id: u.id,
          position: {
            lat: u.lat,
            lng: u.lng,
          },
          label: {
            text: this.initials(u),
          },

          info: `<div class="card" }>
                <h4 class="mb-1">${u.first_name || ''} ${u.last_name || ''}</h4>
                <div class="mb-1"><b>Phone</b> ${u.phone || ''}</div>
                <div class="mb-1"><b>Email</b> ${u.email || ''}</div>
                <div class="cyan-icon bolder">${
                  u.department ? `${u.department} > ` : ''
                } ${u.field_of_expertise || ''}</div>
              </div>`,
        }))
    },
    customMarkers() {
      return this.locations.map(l => ({
        id: l.id,
        navigate: false,
        isCustom: true,
        position: { lat: l.lat, lng: l.lng },
        label: {
          text: 'home',
          fontFamily: 'Material Icons',
          color: '#ffffff',
          fontSize: '18px',
        },
        info: `<span class="font-weight-bold">${l.nickname}</span>`,
      }))
    },
    allMarkers() {
      return [...this.userMarkers, ...this.taskMarkers, ...this.customMarkers]
    },
  },
  watch: {
    tasks() {
      this.taskOrder = mapTaskOrder(this.tasks)
    },
  },
  methods: {
    taskIcon(task) {
      if (!task.manual_approved && task.status === 'Done') {
        return 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png'
      } else if (task.status === 'Done') {
        return 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
      } else if (task.status === 'New') {
        return 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
      } else if (task.status === 'In Progress') {
        return 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
      }
    },
    routeInfoHandler({ minutes, meters }) {
      this.$emit('route-info', { userId: this.users[0].id, minutes, meters })
    },
    showTask(id) {
      const task = this.tasks.find(t => t.id === id)
      this.$emit('task-map-click', task)
    },
    openMulti(id) {
      const task = this.tasks.find(t => t.id === id)
      this.$emit('task-map-click', task)
    },
  },
}
</script>
<style>
.marker-label {
  border-radius: 50%;
  padding: 1px;
  width: 1.3em;
  font-size: 2em;
  font-weight: bolder;
}
.multi-marker-label {
  border-radius: 10%;
  padding: 1px 5px;
  font-size: 2em;
  font-weight: bolder;
  background-color: var(--v-accent-base);
}
</style>
