<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.967"
    height="25"
    viewBox="0 0 34.967 25"
  >
    <g transform="translate(-1 -4)">
      <path
        class="a"
        d="M22.833,30.5V27.833A5.333,5.333,0,0,0,17.5,22.5H6.833A5.333,5.333,0,0,0,1.5,27.833V30.5"
        transform="translate(0 -2)"
      />
      <path
        class="a"
        d="M18.167,9.833A5.333,5.333,0,1,1,12.833,4.5a5.333,5.333,0,0,1,5.333,5.333Z"
        transform="translate(-0.667)"
      />
      <path
        class="a"
        d="M34,30.522V27.855a5.333,5.333,0,0,0-4-5.16"
        transform="translate(-6.167 -2.022)"
      />
      <path
        class="a"
        d="M24,4.7a5.333,5.333,0,0,1,0,10.333"
        transform="translate(-5.5 -0.022)"
      />
      <path class="a" d="M30,12v9" transform="translate(0.967 -1.5)" />
      <path class="a" d="M34.5,16.5h-9" transform="translate(0.967 -1.5)" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PeoplePlus',
}
</script>

<style scoped>
svg {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
