import axios from 'axios'
import get from 'lodash/fp/get'
import Toaster from '@/utils/toaster'

const api = axios.create({})

const is4xxError = status => status >= 400 && status < 500

api.interceptors.response.use(
  response => {
    const { data } = response
    if (data && data.response_message) {
      Toaster.show([{ type: 'success', text: data.response_message }])
    }
    if (response.data.data) {
      response.data = response.data.data
    }
    return response
  },
  error => {
    if (get('constructor.name', error) === 'Cancel') {
      return new Promise(() => {})
    }
    const status = get('response.status', error)
    const errorMessage = get('response.data.error', error) || error.message
    const method = get('response.config.method', error)
    if (is4xxError(status) && method !== 'get') {
      if (errorMessage) {
        Toaster.show([{ type: 'error', text: errorMessage }])
      }
    }
    throw error
  }
)

const axiosCancelable = {}

api.cancel = function (key) {
  if (axiosCancelable[key]) {
    axiosCancelable[key].cancel()
  }
}

api.cancelToken = function (key) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  axiosCancelable[key] = source
  return source.token
}

const autoCancelToken = function (key) {
  api.cancel(key)
  return api.cancelToken(key)
}
api.autoCancelToken = autoCancelToken

export default api
export { autoCancelToken }
