<template>
  <v-autocomplete
    v-model="selectedCurrency"
    :items="currencies"
    item-text="name"
    item-value="code"
    outlined
    dense
    label="Currency"
    required
    @change="$emit('input', selectedCurrency)"
  >
    <template #selection="{ item }">
      <div>{{ item.name }} ({{ item.symbol }}) - {{ item.code }}</div>
    </template>
    <template #item="{ item }">
      <div>{{ item.name }} ({{ item.symbol }}) - {{ item.code }}</div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'CurrencyPicker',
  props: ['value'],
  data() {
    return {
      selectedCurrency: this.value,
      currencies: [
        {
          code: 'AUD',
          name: 'Australian Dollars',
          symbol: '$',
        },
        {
          code: 'BRL',
          name: 'Brazilian Reais',
          symbol: 'R$',
        },
        {
          code: 'BGN',
          name: 'Bulgarian Leva',
          symbol: 'лв.',
        },
        {
          code: 'CAD',
          name: 'Canadian Dollar',
          symbol: '$',
        },
        {
          code: 'CLP',
          name: 'Chilean Pesos',
          symbol: '$',
        },
        {
          code: 'CNY',
          name: 'Chinese Yuan',
          symbol: '￥',
        },
        {
          code: 'COP',
          name: 'Columbian Pesos',
          symbol: '$',
        },
        {
          code: 'CRC',
          name: 'Colon',
          symbol: '₡',
        },
        {
          code: 'HRK',
          name: 'Croatian Kuna',
          symbol: 'kn',
        },
        {
          code: 'CZK',
          name: 'Czech Koruny',
          symbol: 'Kč',
        },
        {
          code: 'DKK',
          name: 'Danish Kroner',
          symbol: 'kr',
        },
        {
          code: 'EGP',
          name: 'Egyptian Pound',
          symbol: 'ج.م',
        },
        {
          code: 'AED',
          name: 'United Arab Emirates Dirham',
          symbol: 'ﺩ.ﺇ',
        },
        {
          code: 'EUR',
          name: 'Euro',
          symbol: '€',
        },
        {
          code: 'GHS',
          name: 'Ghanaian Cedi',
          symbol: 'GH₵',
        },
        {
          code: 'HKD',
          name: 'Hong Kong Dollars',
          symbol: '$',
        },
        {
          code: 'HUF',
          name: 'Forint',
          symbol: 'Ft',
        },
        {
          code: 'INR',
          name: 'Indian Rupee',
          symbol: '₹',
        },
        {
          code: 'IDR',
          name: 'Indonesian Rupiah',
          symbol: 'Rp',
        },
        {
          code: 'ILS',
          name: 'New Shekels',
          symbol: '₪',
        },
        {
          code: 'JPY',
          name: 'Yen',
          symbol: '¥',
        },
        {
          code: 'KES',
          name: 'Kenyan Shilling',
          symbol: 'KSh',
        },
        {
          code: 'MYR',
          name: 'Ringgits',
          symbol: 'RM',
        },
        {
          code: 'MXN',
          name: 'Mexican Pesos',
          symbol: '$',
        },
        {
          code: 'MAD',
          name: 'Moroccan Dirham',
          symbol: 'MAD',
        },
        {
          code: 'TWD',
          name: 'Taiwan Dollars',
          symbol: '$',
        },
        {
          code: 'NZD',
          name: 'New Zealand Dollars',
          symbol: '$',
        },
        {
          code: 'NOK',
          name: 'Krone',
          symbol: 'kr',
        },
        {
          code: 'PEN',
          name: 'Nuevos Soles',
          symbol: 'S/',
        },
        {
          code: 'PHP',
          name: 'Philippines Peso',
          symbol: '₱',
        },
        {
          code: 'PLN',
          name: 'Zlotych',
          symbol: 'zł',
        },
        {
          code: 'GBP',
          name: 'Pounds Sterling',
          symbol: '£',
        },
        {
          code: 'QAR',
          name: 'Qatari Riyal',
          symbol: 'ر.ق',
        },
        {
          code: 'RON',
          name: 'New Lei',
          symbol: 'lei',
        },
        {
          code: 'SAR',
          name: 'Saudi Riyal',
          symbol: 'SR',
        },
        {
          code: 'SGD',
          name: 'Singapore, Dollars',
          symbol: '$',
        },
        {
          code: 'ZAR',
          name: 'Rand',
          symbol: 'R',
        },
        {
          code: 'KRW',
          name: 'South Korean Won',
          symbol: '₩',
        },
        {
          code: 'SEK',
          name: 'Sweden, Kronor',
          symbol: 'kr',
        },
        {
          code: 'CHF',
          name: 'Swiss Francs',
          symbol: 'CHF',
        },
        {
          code: 'THB',
          name: 'Baht',
          symbol: '฿',
        },
        {
          code: 'TRY',
          name: 'Turkish Lira',
          symbol: '₺',
        },
        {
          code: 'UGX',
          name: 'Ugandan Shilling',
          symbol: 'USh',
        },
        {
          code: 'UAH',
          name: 'Ukrainian hryvnia',
          symbol: '₴',
        },
        {
          code: 'USD',
          name: 'United States Dollars',
          symbol: '$',
        },
        {
          code: 'UYU',
          name: 'Uruguayan Pesos',
          symbol: '$U',
        },
        {
          code: 'VND',
          name: 'Vietnamese Dong',
          symbol: '₫',
        },
      ],
    }
  },
  watch: {
    value(newValue) {
      this.selectedCurrency = newValue
    },
  },
}
</script>
<style scoped></style>
