<template>
  <v-chip small pill>
    <UserAvatar :user="user.picture" :name="user.name" :role="user.role" />
    {{ user.name }}
  </v-chip>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import UserAvatar from 'components/common/user-avatar'

export default {
  name: 'Assignee',
  components: { UserAvatar },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['user'],
}
</script>

<style scoped></style>
