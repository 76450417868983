<template>
  <v-card>
    <v-card-title class="text-h5 align-center bb-1">
      <v-icon class="pr-2" color="secondary">mdi-account-group</v-icon>
      {{ title || 'AI Distribution' }}
      <v-spacer />
      <v-btn icon @click="onClose">
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-0 py-5">
      <v-row
        v-if="!approvalForm.visible && !aiSuggestionGenerating"
        class="d-flex flex-column text-title-1"
      >
        <label
          v-for="aiDistributionType in Object.keys(initialContent)"
          :key="aiDistributionType"
        >
          <v-col class="d-flex flex-rows pa-0 px-5">
            <v-col cols="10" class="pa-0">
              <v-col class="pa-0 px-5">
                <div class="d-flex justify-end text-caption">
                  <div>
                    {{ String(initialContent[aiDistributionType]).length }}
                    / {{ 40000 }}
                  </div>
                </div>
                <v-textarea
                  v-model="_self[aiDistributionType + 'FormVal']"
                  :label="aiDistributionType"
                  outlined
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-col>
            <v-col v-if="aiSuggestion[aiDistributionType].approved" cols="2">
              <v-btn
                color="green"
                dark
                class="mt-2"
                button
                @click.prevent="revertAiSuggestion(aiDistributionType)"
              >
                Revert
              </v-btn>
            </v-col>
          </v-col>
        </label>
        <v-col class="text-right px-12">
          <v-btn
            color="blue"
            dark
            class="mr-7"
            button
            @click.prevent="getAiSuggestion()"
          >
            Optimise
          </v-btn>
          <v-btn color="green" dark button @click.prevent="releaseAiContent()">
            Release new content
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="aiSuggestionGenerating" class="px-5 m-5 text-title-1">
        <v-col class="text-center">
          <ai-anim />
        </v-col>
      </v-row>
      <v-row v-if="approvalForm.visible" class="px-12">
        <v-col>
          <v-row
            v-for="aiDistributionType in Object.keys(aiSuggestion)"
            :key="aiDistributionType"
          >
            <v-col>
              <div class="d-flex justify-end text-caption">
                <div>
                  {{ String(initialContent[aiDistributionType]).length }}
                  / {{ 40000 }}
                </div>
              </div>
              <v-textarea
                v-model="initialContent[aiDistributionType]"
                :label="`Initial version of ${aiDistributionType}`"
                outlined
                rows="3"
                color="red"
                @keyup="onApprovalFormFieldDown(aiDistributionType)"
              ></v-textarea>
              <div class="d-flex justify-end text-caption">
                <div>
                  {{ aiSuggestion[aiDistributionType].newContent.length }}
                  / {{ 40000 }}
                </div>
              </div>
              <v-textarea
                v-model="aiSuggestion[aiDistributionType].newContent"
                :label="`AI suggested version of ${aiDistributionType}`"
                outlined
                rows="3"
                color="green"
                @keyup="onApprovalFormFieldDown(aiDistributionType)"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="approvalForm.reasoning"
                label="Reasoning"
                outlined
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                color="red"
                dark
                class="mt-2 mr-10"
                button
                @click.prevent="declineAiSuggestion()"
              >
                Decline
              </v-btn>
              <v-btn
                color="blue"
                dark
                class="mt-2"
                button
                @click.prevent="approveAiSuggestion()"
              >
                Approve
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions class="justify-space-around">
      <v-col>
        <v-btn depressed @click="onClose">Close</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import AiAnim from 'components/loaders/ai-anim.vue'

export default {
  name: 'AiDistributionModal',
  props: ['title', 'listing'],
  data() {
    return {
      initialContent: {
        summary: '',
        access: '',
        interactionWithGuests: '',
        space: '',
      },
      aiSuggestion: {
        summary: {
          approved: false,
          newContent: '',
        },
        access: {
          approved: false,
          newContent: '',
        },
        interactionWithGuests: {
          approved: false,
          newContent: '',
        },
        space: {
          approved: false,
          newContent: '',
        },
      },
      approvalForm: {
        visible: false,
        reasoning: null,
      },
      aiSuggestionGenerating: false,
    }
  },
  async mounted() {
    console.log('test')
    Object.keys(this.initialContent).forEach(
      k => (this.initialContent[k] = this.listing.marketing_content[k])
    )
  },
  methods: {
    onClose() {
      this.$store.commit('hideModal')
    },
    getAiSuggestion() {
      this.aiSuggestionGenerating = true

      axios
        .post(`/api/ai-distribution/${this.listing.id}`, {
          summaryDescription: this.finalContent('summary'),
          accessDescription: this.finalContent('access'),
          interactionDescription: this.finalContent('interactionWithGuests'),
          spaceDescription: this.finalContent('space'),
        })
        .then(response => {
          this.aiSuggestion.summary.newContent =
            response.data.property_details.summary_description
          this.aiSuggestion.access.newContent =
            response.data.property_details.access_description
          this.aiSuggestion.interactionWithGuests.newContent =
            response.data.property_details.interaction_description
          this.aiSuggestion.space.newContent =
            response.data.property_details.space_description

          this.approvalForm.reasoning =
            response.data?.actions?.update_description.reason || 'No reasons'
        })
        .catch(() => ({}))
        .finally(() => {
          this.aiSuggestionGenerating = false
          this.approvalForm.visible = true
        })
    },
    approveAiSuggestion() {
      Object.keys(this.aiSuggestion).forEach(
        k =>
          (this.aiSuggestion[k].approved =
            this.aiSuggestion[k] != this.initialContent[k])
      )
      this.approvalForm.visible = false
    },
    declineAiSuggestion() {
      this.approvalForm.visible = false
    },
    revertAiSuggestion(aiDistributionType) {
      this.aiSuggestion[aiDistributionType].approved = false
    },
    finalContent(aiDistributionType) {
      if (this.aiSuggestion[aiDistributionType].approved) {
        return this.aiSuggestion[aiDistributionType].newContent
      } else {
        return this.initialContent[aiDistributionType]
      }
    },
    setFinalContent(aiDistributionType, val) {
      if (this.aiSuggestion[aiDistributionType].approved) {
        this.aiSuggestion[aiDistributionType].newContent = val
      } else {
        this.initialContent[aiDistributionType] = val
      }
    },
    releaseAiContent() {
      const approvedDescriptionsNames = Object.keys(this.aiSuggestion).filter(
        key => this.aiSuggestion[key].approved
      )

      approvedDescriptionsNames.forEach(key => {
        this.listing.marketing_content[key] = this.aiSuggestion[key].newContent
      })

      this.onClose()
    },
  },
  components: {
    AiAnim,
  },
  computed: {
    summaryFormVal: {
      get() {
        return this.finalContent('summary')
      },
      set(val) {
        this.setFinalContent('summary', val)
      },
    },
    accessFormVal: {
      get() {
        return this.finalContent('access')
      },
      set(val) {
        this.setFinalContent('access', val)
      },
    },
    interactionWithGuestsFormVal: {
      get() {
        return this.finalContent('interactionWithGuests')
      },
      set(val) {
        this.setFinalContent('interactionWithGuests', val)
      },
    },
    spaceFormVal: {
      get() {
        return this.finalContent('space')
      },
      set(val) {
        this.setFinalContent('space', val)
      },
    },
  },
}
</script>

<style scoped></style>
