<template>
  <v-chip :color="`${color} lighten-4`" label small>
    <v-icon v-show="icon" :color="color" class="mr-2" small>{{ icon }}</v-icon>
    <span :class="`${color}--text`">{{ role }}</span>
    <span v-show="name" :class="`${color}--text`">{{ `: ${name}` }}</span>
  </v-chip>
</template>

<script>
export default {
  name: 'ListingSetupLabel',
  props: ['name', 'color', 'icon', 'role'],
}
</script>
