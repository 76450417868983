<template>
  <v-card class="pa-5">
    <v-form ref="form" @submit.prevent="submit">
      <v-card-title class="bolder"> {{ item.address }} </v-card-title>
      <v-card-text class="flex-wrap justify-space-between d-flex">
        <v-row wrap>
          <v-col
            v-for="field in fields"
            :key="field.value"
            :cols="field.cols || isMobileOrIPad ? 12 : 6"
          >
            <v-menu v-if="field.fieldType === 'date'" min-width="290px">
              <template #activator="{ on }">
                <v-text-field
                  :value="valueFields[field.value]"
                  :label="field.text"
                  prepend-inner-icon="event"
                  readonly
                  outlined
                  hide-details
                  dense
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="valueFields[field.value]"
                reactive
                :max="new Date().toISOString()"
              />
            </v-menu>
            <v-select
              v-else-if="field.select"
              v-model="valueFields[field.value]"
              :items="field.select.items"
              :label="field.text"
              outlined
              dense
              hide-details
            />
            <v-checkbox
              v-else-if="field.fieldType === 'checkbox'"
              v-model="valueFields[field.value]"
              dense
              class="mt-0 font-weight-medium"
              hide-details
              :label="field.text"
            />
            <v-textarea
              v-else-if="field.fieldType === 'textarea'"
              v-model="valueFields[field.value]"
              outlined
              :placeholder="field.text"
              :label="field.text"
              rows="3"
              dense
              hide-details
            />
            <v-text-field
              v-else
              v-model="valueFields[field.value]"
              :type="field.isCurrency || field.isNumber ? 'number' : 'text'"
              :label="field.text"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined @click="onClose">Cancel</v-btn>
        <v-btn color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import deviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'UpdateFieldsModal',
  props: ['onConfirm', 'item', 'fields'],
  mixins: [deviceMixin],
  data() {
    return {
      valueFields: (this.fields || []).reduce((acc, curr) => {
        const val = this.item[curr.value]
        if (val === null) return acc
        acc[curr.value] =
          curr.fieldType === 'date'
            ? this.$moment(val).format('YYYY-MM-DD')
            : val
        return acc
      }, {}),
    }
  },
  methods: {
    onClose() {
      this.$store.commit('hideModal')
    },
    submit() {
      this.onConfirm && this.onConfirm(this.valueFields)
      this.onClose()
    },
  },
}
</script>

<style scoped></style>
