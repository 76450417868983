<template>
  <v-container>
    <v-card class="overflow-y-auto">
      <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
        <div class="text-capitalize d-flex justify-space-between align-center">
          <v-icon small class="mr-1" left color="success">fas fa-circle</v-icon
          >Webhook
        </div>
        <v-dialog width="900">
          <template #activator="{ on }">
            <v-btn outlined small v-on="on">Examples</v-btn>
          </template>
          <v-sheet width="900" class="pa-3 d-flex">
            <pre>{{ reviewExample | pretty }}</pre>
            <pre>{{ reservationExample | pretty }}</pre>
          </v-sheet>
        </v-dialog>
      </v-card-title>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-text>
          <div v-for="(hook, ind) in webhooks" :key="ind" class="pa-2">
            <div class="justify-space-between align-center d-flex">
              <span class="text-subtitle-1 font-weight-medium py-3"
                >Hook #{{ ind + 1 }}</span
              >
              <confirmation-modal
                :text="`Are you sure you want to delete webhook?`"
                @action="removeHook(hook)"
              >
                <template #activator="{ on }">
                  <v-icon class="ml-auto" small color="error" v-on="on"
                    >$trash</v-icon
                  >
                </template>
              </confirmation-modal>
            </div>
            <v-text-field
              v-model="hook.url"
              label="URL"
              dense
              outlined
              :rules="urlRules"
            />
            <v-autocomplete
              v-model="hook.events"
              outlined
              :items="events"
              label="Events"
              :rules="[required]"
              multiple
              dense
              small-chips
            />
            <v-divider />
          </div>
          <v-btn outlined class="my-3" color="info" @click="addHook"
            ><v-icon left>fas fa-plus</v-icon> New hook
          </v-btn>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="createWebhooks"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import MarketplaceMixin from 'components/marketplace/marketplace-mixin'
import axios from 'axios'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import FormRulesMixin from 'components/mixins/form-rules-mixin'

export default {
  name: 'Webhook',
  components: { ConfirmationModal },
  mixins: [MarketplaceMixin, FormRulesMixin],
  data() {
    return {
      webhooks: [],
      events: [
        'review.created',
        'review.replied',
        'review.removed',
        'reservation.new',
        'reservation.updated',
        'reservation.canceled',
      ],
      reviewExample: {
        event: 'review.created',
        data: {
          guest: {
            id: 'uniq_id',
            name: 'John Doe',
            email: 'john@doe',
            phone: '1234567890',
          },
          listing: {
            id: 'uniq_id',
            name: 'Listing name',
            address: 'Listing address',
          },
          reservation: {
            id: 'uniq_id',
            checkIn: '2018-01-01T10:00:00.000Z',
            checkOut: '2018-01-01T10:00:00.000Z',
            status: 'confirmed',
          },
          review: {
            id: 'uniq_id',
            comment: 'Review comment',
            rating: 5,
            date: '2018-01-01',
            reply: 'Review reply',
            repliedAt: '2018-01-01',
          },
        },
      },
      reservationExample: {
        event: 'reservation.new',
        data: {
          guest: {
            id: 'uniq_id',
            name: 'John Doe',
            email: 'demo@demo.com',
            phone: '555-555-5555',
          },
          listing: {
            id: 'uniq_id',
            name: 'listing name',
            address: '123 main street',
          },
          reservation: {
            id: 'uniq_id',
            checkIn: '2018-01-01T16:00:00.000Z',
            checkOut: '2018-01-02T11:00:00.000Z',
            status: 'confirmed',
          },
        },
      },
    }
  },
  mounted() {
    this.fetchWebhooks()
  },
  filters: {
    pretty(value) {
      return JSON.stringify(value, null, 2).trim()
    },
  },
  methods: {
    async fetchWebhooks() {
      this.$store.commit('updateLoading', true)
      const { data } = await axios.get('/api/integrations/webhooks')
      this.$store.commit('updateLoading', false)
      this.webhooks = data
    },
    async createWebhooks() {
      this.$store.commit('updateLoading', true)
      await axios.post('/api/integrations/webhooks', {
        webhooks: this.webhooks,
      })
      await this.fetchWebhooks()
    },
    removeHook(hook) {
      const index = this.webhooks.indexOf(hook)
      this.webhooks.splice(index, 1)
      if (hook.id) {
        axios.delete(`/api/integrations/webhooks/${hook.id}`)
      }
    },
    addHook() {
      this.webhooks.push({
        url: '',
        events: [],
      })
    },
  },
}
</script>

<style scoped></style>
