<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.3"
    height="15.423"
    viewBox="0 0 24.3 15.423"
  >
    <path
      class="a"
      d="M23.751,1.349a.509.509,0,0,0-.5-.009L17.741,4.346v-1.8A2.545,2.545,0,0,0,15.2,0H2.542A2.545,2.545,0,0,0,0,2.542V12.581a2.545,2.545,0,0,0,2.542,2.542H15.2a2.545,2.545,0,0,0,2.542-2.542V10.809l5.507,3.007A.508.508,0,0,0,24,13.37V1.786A.508.508,0,0,0,23.751,1.349ZM16.724,12.581A1.527,1.527,0,0,1,15.2,14.106H2.542a1.527,1.527,0,0,1-1.525-1.525V2.542A1.527,1.527,0,0,1,2.542,1.017H15.2a1.527,1.527,0,0,1,1.525,1.525Zm6.259-.068L17.741,9.651V5.505l5.242-2.862Zm0,0"
      transform="translate(0.15 0.15)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Video',
}
</script>

<style scoped>
.a {
  stroke: #000;
  stroke-width: 0.3px;
}
svg {
  fill: currentColor;
}
</style>
