<template>
  <form-dialog
    ref="createLosSetting"
    :title="$t('Add LOS Setting')"
    :max-width="900"
    @submit="createLosSetting"
  >
    <template #activator="{ on }">
      <v-btn color="primary" class="text-capitalize" elevation="0" v-on="on">
        {{ $t('Add LOS Setting') }}
      </v-btn>
    </template>
    <v-row class="align-center">
      <v-col cols="12">
        <v-text-field
          v-model="losSetting.name"
          outlined
          dense
          :label="$t('Name')"
          required
          :rules="[required]"
        />
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="losSetting.active"
          :class="losSetting.active ? 'cyan-icon' : 'grey-text'"
          :label="losSetting.active ? 'ON' : 'OFF'"
        >
        </v-switch>
      </v-col>
      <v-col cols="6">
        <date-picker
          v-model="losSetting.from"
          :label="$t('Valid From')"
          min="2019-01-01"
          required
        />
      </v-col>
      <v-col cols="6">
        <date-picker
          v-model="losSetting.to"
          :label="$t('Valid To')"
          min="2019-01-01"
          required
        />
      </v-col>
      <v-col cols="12">
        <multi-property-picker
          v-model="losSetting.whitelist"
          :label="$t('All Listings')"
          multiple
          :hide-btns="true"
        />
      </v-col>
      <v-col cols="12">
        <multi-property-picker
          v-model="losSetting.blacklist"
          :disabled="losSetting.whitelist.length !== 0"
          :label="$t('Blacklisted listings')"
          :hide-btns="true"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="losSetting.discount"
          label="Discount (%)"
          type="number"
          required
          outlined
          dense
          :rules="[required, max(500), min(-100)]"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="losSetting.nights"
          outlined
          dense
          :label="$t('Nights')"
          required
          :rules="[required, min(0), max(35)]"
        />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import DatePicker from 'components/form-fields/date-picker.vue'

export default {
  name: 'LosSettingCreateModal',
  components: { FormDialog, MultiPropertyPicker, DatePicker },
  mixins: [FormRules, FormattersMixin],
  props: ['losDataSet'],
  data() {
    return {
      losSetting: {
        name: null,
        from: null,
        to: null,
        active: true,
        whitelist: [],
        blacklist: [],
        discount: 0,
      },
    }
  },

  computed: {},
  methods: {
    async createLosSetting() {
      this.$store.commit('updateLoading', true)
      await this.$store
        .dispatch('losSetting/createLosSetting', {
          los_setting: this.losSetting,
        })
        .finally(this.$store.commit('updateLoading', false))
    },
  },
}
</script>

<style scoped></style>
