<template>
  <v-sheet
    elevation="2"
    class="align-center text-left ba-1 pa-4 my-3 mx-0"
    rounded
    :class="review.normilized_scrore_overall < 8 ? 'super-light-red' : ''"
  >
    <div>
      <span class="text-overline">{{
        parseDate(review.created_at_guesty)
      }}</span>
      <span class="mx-2"> | </span>
      <span v-if="review.reservation" class="mx-2">
        {{ review.reservation.listing.nickname }}
      </span>
      <span class="mx-2"> | </span>
      <v-chip v-if="review.reservation" small color="info" label
        >Check-In: {{ parseDate(review.reservation.check_in) }}
      </v-chip>
      <v-chip v-if="review.reservation" small class="mx-2" color="info" label
        >Check-Out: {{ parseDate(review.reservation.check_out) }}</v-chip
      >
    </div>

    <div class="text-left my-2">
      <v-chip small color="notification"
        >Overall:
        {{ (review.normilized_scrore_overall / 2).toFixed(2) }}</v-chip
      >
      <v-chip v-for="(val, key) in scoreMatrics" :key="key" class="mx-1" small
        >{{ key }}: {{ review[val] ? (review[val] / 2).toFixed(2) : 'n/a' }}
      </v-chip>
    </div>
    <div
      v-if="!isEmpty(review.ai_tags) || review.ai_summary"
      class="text-left text-caption py-2 my-1"
    >
      <h4>AI summary:</h4>
      <p v-if="review.ai_summary">
        {{ review.ai_summary }}
      </p>
      <div class="text-left my-2">
        <v-tooltip
          v-for="[tag, value] in Object.entries(review.ai_tags || [])"
          :key="tag"
          content-class="custom-tooltip"
          top
        >
          <template #activator="{ on }">
            <v-chip label color="primary lighten-1" class="ma-1" small v-on="on"
              >{{ tag }}
            </v-chip>
          </template>
          <div class="text-body-2">
            {{ value }}
          </div>
        </v-tooltip>
      </div>
    </div>
    <div v-if="review.public" class="text-left text-caption">
      <h4>Public:</h4>
      {{ review.public }}
    </div>
    <div v-if="review.private" class="text-left text-caption">
      <h4>Private:</h4>
      {{ review.private }}
    </div>
    <div class="text-right">
      <tag
        v-if="review.validated_by_id"
        :inverted="true"
        size="sm"
        color="success"
        >Validated by {{ nameById(review.validated_by_id) }}
      </tag>
      <v-btn
        v-else
        depressed
        small
        color="green lighten-5"
        class="green--text font-weight-bold mr-2"
        @click.stop="$emit('validate-review', review.id)"
      >
        <v-icon class="mr-2" small>mdi-check-circle-outline</v-icon>
        Validate</v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
import Common_functions from 'components/mixins/common_functions'
import Tag from 'components/common/tag'

export default {
  name: 'ReviewRow',
  components: { Tag },
  mixins: [Common_functions],
  props: ['review'],
  computed: {},
  data() {
    return {
      scoreMatrics: {
        Communication: 'normilized_communication',
        Cleaning: 'normilized_cleaning',
        Accuracy: 'normilized_accuracy',
        Location: 'normilized_location',
        Checkin: 'normilized_checkin',
        Value: 'normilized_value',
      },
    }
  },
}
</script>

<style scoped>
.custom-tooltip {
  opacity: 1 !important;
}
</style>
