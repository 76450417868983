<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.844"
    height="24"
    viewBox="0 0 18.844 24"
  >
    <path
      class="a"
      d="M31.141,0H15.2A1.455,1.455,0,0,0,13.75,1.453V22.547A1.455,1.455,0,0,0,15.2,24H31.141a1.455,1.455,0,0,0,1.453-1.453V1.453A1.455,1.455,0,0,0,31.141,0Zm.8,22.547a.8.8,0,0,1-.8.8H15.2a.8.8,0,0,1-.8-.8V1.453a.8.8,0,0,1,.8-.8H31.141a.8.8,0,0,1,.8.8Z"
      transform="translate(-13.75)"
    />
    <path
      class="a"
      d="M38.516,10H24.078a.328.328,0,0,0-.328.328v4.125a.328.328,0,0,0,.328.328H38.516a.328.328,0,0,0,.328-.328V10.328A.328.328,0,0,0,38.516,10Zm-.328,4.125H24.406V10.656H38.188Z"
      transform="translate(-21.875 -8.125)"
    />
    <path
      class="a"
      d="M56.641,97.523H53.078a.328.328,0,0,0-.328.328v3.182a.328.328,0,0,0,.328.329h3.563a.328.328,0,0,0,.328-.328V97.851a.328.328,0,0,0-.328-.328Zm-.328,3.183H53.406V98.179h2.906Z"
      transform="translate(-45.437 -79.237)"
    />
    <path
      class="a"
      d="M27.646,97.523H24.078a.328.328,0,0,0-.328.328v3.182a.328.328,0,0,0,.328.329h3.568a.328.328,0,0,0,.328-.328V97.851a.328.328,0,0,0-.328-.328Zm-.328,3.183H24.406V98.179h2.912Z"
      transform="translate(-21.875 -79.237)"
    />
    <path
      class="a"
      d="M56.641,69.766H53.078a.328.328,0,0,0-.328.328v3.182a.328.328,0,0,0,.328.328h3.563a.328.328,0,0,0,.328-.328V70.094a.328.328,0,0,0-.328-.328Zm-.328,3.182H53.406V70.422h2.906Z"
      transform="translate(-45.438 -56.685)"
    />
    <path
      class="a"
      d="M27.646,69.766H24.078a.328.328,0,0,0-.328.328v3.182a.328.328,0,0,0,.328.328h3.568a.328.328,0,0,0,.328-.328V70.094a.328.328,0,0,0-.328-.328Zm-.328,3.182H24.406V70.422h2.912Z"
      transform="translate(-21.875 -56.685)"
    />
    <path
      class="a"
      d="M85.617,69.766H82.049a.328.328,0,0,0-.328.328v8.387a.328.328,0,0,0,.328.328h3.568a.328.328,0,0,0,.328-.328V70.094a.328.328,0,0,0-.328-.328Zm-.328,8.387H82.377V70.422h2.912Z"
      transform="translate(-68.976 -56.685)"
    />
    <path
      class="a"
      d="M56.641,42.008H53.078a.328.328,0,0,0-.328.328v3.182a.328.328,0,0,0,.328.328h3.563a.328.328,0,0,0,.328-.328V42.336a.328.328,0,0,0-.328-.328Zm-.328,3.182H53.406V42.664h2.906Z"
      transform="translate(-45.438 -34.131)"
    />
    <path
      class="a"
      d="M27.646,42.008H24.078a.328.328,0,0,0-.328.328v3.182a.328.328,0,0,0,.328.328h3.568a.328.328,0,0,0,.328-.328V42.336a.328.328,0,0,0-.328-.328Zm-.328,3.182H24.406V42.664h2.912Z"
      transform="translate(-21.875 -34.131)"
    />
    <path
      class="a"
      d="M85.617,42.008H82.049a.328.328,0,0,0-.328.328v3.182a.328.328,0,0,0,.328.328h3.568a.328.328,0,0,0,.328-.328V42.336a.328.328,0,0,0-.328-.328Zm-.328,3.182H82.377V42.664h2.912Z"
      transform="translate(-68.976 -34.131)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Calculator',
}
</script>

<style scoped>
svg {
  color: currentColor;
}
</style>
