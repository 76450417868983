import { render, staticRenderFns } from "./expense-type-modal.vue?vue&type=template&id=3437695b&scoped=true&"
import script from "./expense-type-modal.vue?vue&type=script&lang=js&"
export * from "./expense-type-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3437695b",
  null
  
)

export default component.exports