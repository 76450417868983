<template>
  <v-dialog v-model="menu" max-width="500">
    <template #activator="{ on, attrs }">
      <v-btn x-small color="primary" v-bind="attrs" v-on="on">
        <v-icon left small>fas fa-reply</v-icon> channel
      </v-btn>
    </template>
    <v-card class="pa-3">
      <v-card-title>Review Reply</v-card-title>
      <v-card-text>
        <div
          v-if="
            review.ai_response && review.ai_language.toLowerCase() !== 'english'
          "
          class="text-subtitle-1"
        >
          Language:
        </div>
        <div class="d-flex justify-space-between align-center">
          <v-btn-toggle
            v-if="
              review.ai_response &&
              review.ai_language.toLowerCase() !== 'english'
            "
            v-model="lang"
            color="primary"
            dense
            @change="langChanged"
          >
            <v-btn small value="en">English</v-btn>
            <v-btn small value="origin">{{ review.ai_language }}</v-btn>
          </v-btn-toggle>
          <v-spacer v-else />
          <copy-clipboard :text="response" />
        </div>
        <v-textarea
          v-model="response"
          label="Reply"
          outlined
          class="my-3"
          rows="3"
          hide-details
          auto-grow
        />
        <div class="text-right"></div>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined @click="close">cancel</v-btn>
        <v-btn
          :disabled="!Boolean(response)"
          color="primary"
          :loading="loading"
          type="submit"
          @click="sendResponse"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@/axios/config'
import CopyClipboard from 'components/copy-clipboard.vue'

export default {
  name: 'ReviewReplyModal',
  components: { CopyClipboard },
  data() {
    return {
      menu: false,
      loading: false,
      response: this.review.ai_response,
      lang: 'en',
    }
  },
  props: ['review'],
  methods: {
    async sendResponse() {
      this.loading = true
      try {
        await axios.put(`/api/reviews/${this.review.id}/post-reply`, {
          message: this.response,
        })
        this.review.status = 'Replied'
        this.loading = false
        this.menu = false
      } catch (e) {
        this.loading = false
      }
    },
    close() {
      this.response = this.review.ai_response
      this.menu = false
    },
    langChanged() {
      this.response =
        this.lang === 'en'
          ? this.review.ai_response
          : this.review.ai_response_origin
    },
  },
}
</script>

<style scoped></style>
