<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="113.329"
    height="117.112"
    viewBox="0 0 113.329 117.112"
  >
    <g
      id="photos_before"
      data-name="photos before"
      transform="translate(-10762 404.112)"
    >
      <g
        id="_001-history"
        data-name="001-history"
        transform="translate(10806.758 -438.557)"
      >
        <path
          id="Path_4869"
          data-name="Path 4869"
          d="M275.487,172.228l-.006,14.992a2.141,2.141,0,0,0,.628,1.515l10.725,10.723,3.03-3.03-10.1-10.095.006-14.1Z"
          transform="translate(-241.206 -120.64)"
          fill="#ff4757"
        />
        <path
          id="Path_4870"
          data-name="Path 4870"
          d="M38.571,34.445A30.022,30.022,0,0,0,8.914,60.159H0L10.714,70.874,21.429,60.159H13.244a25.671,25.671,0,1,1,5.05,20.1L14.916,82.9A30,30,0,1,0,38.571,34.445Z"
          transform="translate(0 0)"
          fill="#ff4757"
        />
      </g>
      <path
        id="Subtraction_3"
        data-name="Subtraction 3"
        d="M89.456,123H10.544A10.555,10.555,0,0,1,0,112.457V33.544A10.555,10.555,0,0,1,10.544,23H45.16A39.841,39.841,0,0,0,43.8,28.006H10.544a5.544,5.544,0,0,0-5.538,5.538V92.187L22.19,75a2.493,2.493,0,0,1,3.535,0l16.2,16.2L62.676,70.459a39.989,39.989,0,0,0,4.711,2.379L43.707,96.519a2.491,2.491,0,0,1-3.535,0l-16.2-16.2L5.006,99.277v13.18a5.544,5.544,0,0,0,5.538,5.538H89.456a5.544,5.544,0,0,0,5.538-5.538V89.285L81.687,75.978c.42.013.861.021,1.313.021a40.523,40.523,0,0,0,5.427-.365L94.994,82.2V74.17A39.872,39.872,0,0,0,100,72.218v40.239A10.555,10.555,0,0,1,89.456,123ZM31,63.07A12.718,12.718,0,1,1,43.727,50.34,12.744,12.744,0,0,1,31,63.07Zm0-20.454a7.724,7.724,0,1,0,7.724,7.724A7.733,7.733,0,0,0,31,42.616Z"
        transform="translate(10762 -410)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PhotosBefore',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
