<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.6"
    height="24"
    viewBox="0 0 17.6 24"
  >
    <g transform="translate(-472.139 -424)">
      <path
        class="a"
        d="M18.1,7.2A3.2,3.2,0,0,0,14.9,4H13.682A4.4,4.4,0,0,0,4.918,4H3.7a3.2,3.2,0,0,0,0,6.4H8.1V9.6H3.7a2.4,2.4,0,1,1,0-4.8h2V4.4a3.6,3.6,0,0,1,7.2,0v.4h2a2.4,2.4,0,1,1,0,4.8H10.5v.8h4.4A3.2,3.2,0,0,0,18.1,7.2Zm0,0"
        transform="translate(471.639 424)"
      />
      <path
        class="a"
        d="M16.5,133.653v-6.8a1.2,1.2,0,0,0-1.2-1.2h-.8v-.874a1.2,1.2,0,1,0-1.526-1.526H9.7v-5.034l.917.917.566-.566L9.3,116.688,7.417,118.57l.566.566.917-.917v5.034H5.626A1.2,1.2,0,1,0,4.1,124.779v1.274H1.7a1.2,1.2,0,0,0-1.2,1.2v4.8a1.2,1.2,0,0,0,1.2,1.2H4.1v.8H2.9v.8H6.1v-.8H4.9v-.8H7.3a1.2,1.2,0,0,0,1.2-1.2v-4.8a1.2,1.2,0,0,0-1.2-1.2H4.9V124.78a1.2,1.2,0,0,0,.726-.726h7.347a1.2,1.2,0,0,0,.726.726v.874H11.3a1.2,1.2,0,0,0-1.2,1.2v6.8a1.2,1.2,0,0,0,1.2,1.2h4A1.2,1.2,0,0,0,16.5,133.653Zm-8.8-1.6a.4.4,0,0,1-.4.4H1.7a.4.4,0,0,1-.4-.4v-.8H7.7Zm-.4-5.2a.4.4,0,0,1,.4.4v3.2H1.3v-3.2a.4.4,0,0,1,.4-.4Zm-2.8-2.8a.4.4,0,1,1,.4-.4A.4.4,0,0,1,4.5,124.053Zm9.6-.8a.4.4,0,1,1-.4.4A.4.4,0,0,1,14.1,123.253Zm-3.2,3.6a.4.4,0,0,1,.4-.4h4a.4.4,0,0,1,.4.4v5.2H10.9Zm0,6.8v-.8h4.8v.8a.4.4,0,0,1-.4.4h-4A.4.4,0,0,1,10.9,133.653Zm0,0"
        transform="translate(471.639 313.147)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Iot',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
