<template>
  <div v-if="reservation" class="pt-2">
    <div class="d-flex text-subtitle-2">
      <div v-if="reservation.confirmation_code && !pmMode" class="mr-10">
        {{ reservation.confirmation_code }}
      </div>
      <div v-if="reservation.listing">
        {{ reservation.listing.nickname }}
      </div>
    </div>
    <div
      :class="`d-flex text-body-2 align-center flex-wrap ${
        popUp ? 'flex-container' : ''
      }`"
    >
      <div v-if="!pmMode" :class="`mb-2 ${popUp ? 'flex-item' : 'mr-10'}`">
        Source:
        <span class="text-no-wrap">{{ reservation.source }}</span>
      </div>
      <div :class="`mb-2 ${popUp ? 'flex-item' : 'mr-10'}`">
        Nights count:
        <span class="text-no-wrap">
          {{ reservation.nights_count }}
        </span>
      </div>
      <div :class="`mb-2 ${popUp ? 'flex-item' : 'mr-10'}`">
        Checkin at:
        <span class="text-no-wrap">
          {{ parseDateTZ(reservation.check_in, 'YYYY-MM-DD', timezone) }}
        </span>
      </div>
      <div :class="`mb-2 ${popUp ? 'flex-item' : 'mr-10'}`">
        Checkout at:
        <span class="text-no-wrap">
          {{ parseDateTZ(reservation.check_out, 'YYYY-MM-DD', timezone) }}
        </span>
      </div>
      <div v-if="!pmMode" :class="`mb-2 ${popUp ? 'flex-item' : 'mr-10'}`">
        Status:
        <span class="text-no-wrap">{{ reservation.status }}</span>
      </div>
      <Tag
        v-if="
          reservation.planned_arrival && isSameDay(reservation.check_in, date)
        "
        :bg-color="checkInType === 'ECI' ? 'warning' : 'success'"
        color="white"
        class="mb-2 mr-2 d-inline-block text-no-wrap"
      >
        {{ checkInType }}-{{ reservation.planned_arrival.split(':')[0] }}
      </Tag>
      <Tag
        v-if="
          reservation.planned_departure &&
          isSameDay(reservation.check_out, date)
        "
        :bg-color="checkOutType === 'LCO' ? 'warning' : 'success'"
        color="white"
        class="mb-2 mr-2 d-inline-block text-no-wrap"
      >
        {{ checkOutType }}-{{ reservation.planned_departure.split(':')[0] }}
      </Tag>
      <Tag
        :bg-color="reservation.should_pay_deposit ? 'warning' : 'success'"
        color="white"
        class="mb-2 mr-2 d-inline-block text-no-wrap"
      >
        {{
          reservation.should_pay_deposit ? 'Deposit Not paid' : 'Deposit Paid'
        }}
      </Tag>
      <div
        v-if="reservation.conversation && popUp"
        :class="`${popUp && !reservation.conversation ? 'flex-hidden' : ''}`"
      >
        <v-btn
          target="_blank"
          icon
          color="info"
          :to="{
            name: 'guests-chat',
            params: {
              id:
                reservation.conversation && reservation.conversation.guesty_id,
            },
          }"
          class="mb-2 mr-2"
        >
          <v-icon>$chat</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Tag from 'components/common/tag'

export default {
  components: { Tag },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['reservation', 'date', 'pmMode', 'popUp'],
  computed: {
    checkInType() {
      const originArrival = this.parseDateTZ(
        this.reservation.check_in,
        'HH:mm',
        this.timezone
      )
      const plannedArrival = this.reservation.planned_arrival
      if (plannedArrival && plannedArrival !== originArrival) {
        return +plannedArrival.split(':')[0] < +originArrival.split(':')[0]
          ? 'ECI'
          : 'LCI'
      }
      return ''
    },
    timezone() {
      return this.reservation.listing.timezone
    },
    checkOutType() {
      const originDeparture = this.parseDateTZ(
        this.reservation.check_out,
        'HH:mm',
        this.timezone
      )
      const plannedDeparture = this.reservation.planned_departure
      if (plannedDeparture && plannedDeparture !== originDeparture) {
        return +plannedDeparture.split(':')[0] > +originDeparture.split(':')[0]
          ? 'LCO'
          : 'ECO'
      }
      return ''
    },
    checkIn() {
      return this.$moment(this.reservation.check_in).utc()
    },
    checkOut() {
      return this.$moment(this.reservation.check_out).utc()
    },
  },
}
</script>

<style lang="scss" scoped>
.flex-hidden {
  visibility: hidden;
  opacity: 0;
}
.flex-container {
  display: flex;
}
.flex-item {
  flex: 1;
}
</style>
