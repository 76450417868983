<template>
  <div v-if="loadingData" class="flex-center">
    <LoaderAnimationPalms />
  </div>
  <div
    v-else-if="!loadingData && (!originalBudget || !expenses)"
    class="flex-center"
  >
    Error
  </div>
  <div v-else>
    <v-row class="fill-height transparent">
      <v-col v-if="canUpdate" cols="12">
        <v-btn
          :disabled="!budgetChanged"
          class="float-right"
          color="primary"
          @click="updateBudget"
        >
          Save Changes
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <listing-budget-card
          title="Construction"
          param="construction"
          icon="mdi-account-hard-hat"
          color="yellow"
          :readonly="!canUpdate"
          :initial.sync="editBudget.construction_initial"
          :final.sync="editBudget.construction_final"
          :dvr-cost.sync="editBudget.construction_dvr_cost"
          :count="expenses.construction.count"
          :sum="expenses.construction.sum"
          :expenses="expenses.construction.expenses"
          :has-images="hasImages('construction_images')"
          @open-images="openImagesDialog('construction_images')"
          @validate-expense="validateExpense"
          @update-expense="updateExpense"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <listing-budget-card
          title="Design"
          param="design"
          icon="mdi-palette-swatch"
          color="pink"
          :readonly="!canUpdate"
          :initial.sync="editBudget.design_initial"
          :final.sync="editBudget.design_final"
          :dvr-cost.sync="editBudget.design_dvr_cost"
          :count="expenses.design.count"
          :sum="expenses.design.sum"
          :expenses="expenses.design.expenses"
          :has-images="hasImages('design_images')"
          @open-images="openImagesDialog('design_images')"
          @validate-expense="validateExpense"
          @update-expense="updateExpense"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <listing-budget-card
          title="Mortgage & Insurance"
          param="finance"
          icon="mdi-finance"
          color="light-blue"
          :readonly="!canUpdate"
          :initial.sync="editBudget.finance_initial"
          :final.sync="editBudget.finance_final"
          :dvr-cost.sync="editBudget.finance_dvr_cost"
          :count="expenses.finance.count"
          :sum="expenses.finance.sum"
          :expenses="expenses.finance.expenses"
          :has-images="hasImages('finance_images')"
          @open-images="openImagesDialog('finance_images')"
          @validate-expense="validateExpense"
          @update-expense="updateExpense"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <listing-budget-card
          title="Landscape"
          param="landscape"
          icon="mdi-palm-tree"
          color="green"
          :readonly="!canUpdate"
          :initial.sync="editBudget.landscape_initial"
          :final.sync="editBudget.landscape_final"
          :dvr-cost.sync="editBudget.landscape_dvr_cost"
          :count="expenses.landscape.count"
          :sum="expenses.landscape.sum"
          :expenses="expenses.landscape.expenses"
          :has-images="hasImages('landscape_images')"
          @open-images="openImagesDialog('landscape_images')"
          @validate-expense="validateExpense"
          @update-expense="updateExpense"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <listing-budget-card
          title="Exterior Attractions"
          param="exterior"
          icon="mdi-ticket"
          color="teal"
          :readonly="!canUpdate"
          :initial.sync="editBudget.exterior_initial"
          :final.sync="editBudget.exterior_final"
          :dvr-cost.sync="editBudget.exterior_dvr_cost"
          :count="expenses.exterior.count"
          :sum="expenses.exterior.sum"
          :expenses="expenses.exterior.expenses"
          :has-images="hasImages('exterior_images')"
          @open-images="openImagesDialog('exterior_images')"
          @validate-expense="validateExpense"
          @update-expense="updateExpense"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <listing-budget-card
          title="Other Setup Costs"
          param="other"
          icon="mdi-clipboard-list-outline"
          color="blue-grey"
          :readonly="!canUpdate"
          :initial.sync="editBudget.other_initial"
          :final.sync="editBudget.other_final"
          :dvr-cost.sync="editBudget.other_dvr_cost"
          :count="expenses.other.count"
          :sum="expenses.other.sum"
          :expenses="expenses.other.expenses"
          :has-images="hasImages('other_images')"
          @open-images="openImagesDialog('other_images')"
          @validate-expense="validateExpense"
          @update-expense="updateExpense"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="fill-height">
          <v-card-text
            class="text--primary fill-height d-flex flex-column justify-space-around"
          >
            <div class="text-h6">
              Total Budget
              <span class="float-right ml-3">{{
                dollarFormatter(totalBudget)
              }}</span>
            </div>
            <div class="text-h6">
              Remaining
              <span
                :class="[
                  'float-right ml-3',
                  remaining.toFixed() >= 0 ? 'success--text' : 'error--text',
                ]"
              >
                {{ dollarFormatter(remaining) }}
              </span>
            </div>
            <div class="text-h6">
              Spent
              <span
                :class="[
                  'float-right ml-3',
                  totalExpenses.toFixed() <= totalBudget.toFixed()
                    ? 'success--text'
                    : 'error--text',
                ]"
              >
                {{ dollarFormatter(totalExpenses) }}
              </span>
            </div>
            <div class="text-h6">
              Currently
              <span
                :class="[
                  'float-right ml-3',
                  currently <= 100 ? 'success--text' : 'error--text',
                ]"
              >
                {{ currently }}%
              </span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isImagesDialog"
      max-width="720"
      @click:outside="closeImagesDialog"
    >
      <v-card>
        <v-card-title> Add images</v-card-title>
        <v-card-text class="py-0">
          <gallery
            :folder="`budgets/${listingNicknameById(listingId)}`"
            class="mb-4"
            :images="editBudget[imagesToDisplay]"
            :thumbnails="true"
            :cols="4"
            :uploadable="true"
            :remove-img="removeImage"
            :on-change="addImages"
          />
        </v-card-text>
        <v-card-actions class="pa-6 pt-0">
          <v-spacer />
          <v-btn
            color="secondary"
            class="text-capitalize"
            text
            @click="closeImagesDialog"
            >Close
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            elevation="0"
            @click="saveImages"
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { camelCase, cloneDeep, isEqual, sumBy, values } from 'lodash'
import common_functions from 'components/mixins/common_functions'
import LoaderAnimationPalms from 'components/loaders/loader-animation-palms'
import ListingBudgetCard from 'components/listing/listing-budget-card'
import Gallery from 'components/common/gallery'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ListingBudget',
  components: {
    Gallery,
    ListingBudgetCard,
    LoaderAnimationPalms,
  },
  mixins: [common_functions, FormattersMixin],
  props: ['listingId'],
  data: function () {
    return {
      loadingData: true,
      originalBudget: null,
      editBudget: null,
      expenses: null,
      isImagesDialog: false,
      imagesToDisplay: '',
      addedImages: [],
    }
  },
  computed: {
    initialBudgetValue() {
      return {
        construction_initial: 0,
        construction_final: 0,
        construction_dvr_cost: 0,
        construction_images: [],
        design_initial: 0,
        design_final: 0,
        design_dvr_cost: 0,
        design_images: [],
        finance_initial: 0,
        finance_final: 0,
        finance_dvr_cost: 0,
        finance_images: [],
        landscape_initial: 0,
        landscape_final: 0,
        landscape_dvr_cost: 0,
        landscape_images: [],
        exterior_initial: 0,
        exterior_final: 0,
        exterior_dvr_cost: 0,
        exterior_images: [],
        other_initial: 0,
        other_final: 0,
        other_dvr_cost: 0,
        other_images: [],
      }
    },
    initialExpensesValue() {
      return {
        construction: { count: 0, sum: 0, expenses: [] },
        design: { count: 0, sum: 0, expenses: [] },
        finance: { count: 0, sum: 0, expenses: [] },
        landscape: { count: 0, sum: 0, expenses: [] },
        exterior: { count: 0, sum: 0, expenses: [] },
        other: { count: 0, sum: 0, expenses: [] },
      }
    },
    totalBudget() {
      return this.editBudget
        ? this.editBudget.construction_final +
            this.editBudget.design_final +
            this.editBudget.finance_final +
            this.editBudget.landscape_final +
            this.editBudget.exterior_final +
            this.editBudget.other_final
        : 0
    },
    totalExpenses() {
      return this.expenses ? sumBy(values(this.expenses), 'sum') : 0
    },
    budgetChanged() {
      return !isEqual(this.originalBudget, this.editBudget)
    },
    remaining() {
      return this.totalBudget - this.totalExpenses
    },
    currently() {
      return this.totalBudget
        ? Math.round((this.totalExpenses / this.totalBudget) * 100)
        : 0
    },
    canUpdate() {
      return this.hasAbility('update-listing-budget')
    },
  },
  mounted() {
    if (this.listingId) {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions({
      getListingBudget: 'listings/getListingBudget',
      updateListingBudget: 'listings/updateListingBudget',
      getListingSetupExpenses: 'listings/getListingSetupExpenses',
      validateExpenseById: 'expenses/validateExpense',
      updateExpenseById: 'expenses/updateExpense',
    }),
    fetchBudget() {
      return this.getListingBudget({
        listingId: this.listingId,
      }).then(res => {
        this.originalBudget = res.data || this.initialBudgetValue
        this.editBudget = cloneDeep(this.originalBudget)
      })
    },
    fetchSetupExpenses() {
      return this.getListingSetupExpenses({
        listingId: this.listingId,
      }).then(res => (this.expenses = this.transformExpenses(res.data)))
    },
    transformExpenses(dbExpenses = []) {
      const expenses = cloneDeep(this.initialExpensesValue)
      dbExpenses.forEach(expense => {
        const key = camelCase(expense.related_division || 'Other')
        if (expenses[key]) {
          expenses[key].count += 1
          expenses[key].sum += expense.amount || 0
          expenses[key].expenses.push(expense)
        }
      })
      return expenses
    },
    fetchData() {
      this.loadingData = true
      const bp = this.fetchBudget()
      const ep = this.fetchSetupExpenses()
      Promise.all([bp, ep]).finally(() => {
        this.loadingData = false
      })
    },
    updateBudget() {
      return this.updateListingBudget({
        listingId: this.listingId,
        budget: this.editBudget,
      }).then(res => {
        this.originalBudget = res.data
        this.editBudget = cloneDeep(this.originalBudget)
      })
    },
    hasImages(field) {
      return Boolean(this.editBudget[field].length)
    },
    openImagesDialog(item) {
      this.imagesToDisplay = item
      this.isImagesDialog = true
    },
    closeImagesDialog() {
      this.imagesToDisplay = ''
      this.isImagesDialog = false
    },
    addImages(images) {
      this.editBudget[this.imagesToDisplay].push(...images)
    },
    saveImages() {
      this.updateBudget()
      this.isImagesDialog = false
    },
    removeImage(link) {
      const images = this.editBudget[this.imagesToDisplay].filter(
        image => image !== link
      )
      this.editBudget[this.imagesToDisplay] = images
      this.updateBudget()
    },
    validateExpense(expenseId) {
      return this.validateExpenseById(expenseId).then(this.fetchSetupExpenses)
    },
    updateExpense({ id, data }) {
      return this.updateExpenseById({ id, data }).then(this.fetchSetupExpenses)
    },
  },
  watch: {
    listingId(value) {
      if (value) {
        this.fetchData()
      }
    },
  },
}
</script>

<style scoped></style>
