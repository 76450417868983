<template>
  <v-card>
    <conversation-info-title title="Comparables" />
    <v-divider />
    <v-card-text>
      <v-layout mt-2 column class="center-text">
        <v-flex
          v-for="(comp, index) in orderedRelevant"
          :key="index"
          xs12
          white
        >
          <v-layout wrap class="center-text bt-1 bb-1 br-1 bl-1">
            <v-flex v-if="listing" class="font-light pr-2 mt-2 mb-2">
              <v-layout wrap align-center class="">
                <v-flex xs2>
                  <v-layout column>
                    <span> Price: </span>
                    <span class="bolder cyan-icon"
                      >{{ comp.price.our_price.toLocaleString('en') }}
                    </span>
                    <span
                      v-if="comp.price.our_price && reservation.price"
                      class="small-text grey--text"
                      >({{ comp.price.our_price > reservation.price ? '+' : ''
                      }}{{
                        round(comp.price.our_price - reservation.price)
                      }})</span
                    >
                  </v-layout>
                </v-flex>
                <v-flex xs2>
                  <img
                    :src="comp.picture"
                    class="pr-2"
                    style="height: 51px; border-radius: 30%; width: 55px"
                    contain
                  />
                </v-flex>
                <v-flex xs4 class="left-text" pl-3>
                  {{ comp.nickname }}
                  <v-icon
                    v-clipboard:success="onCopy"
                    v-clipboard:copy="compLink(comp)"
                    class="mr-3 pr-3"
                    :class="compLink(comp) ? 'pointer-c' : 'red-warn'"
                    >link</v-icon
                  >
                </v-flex>
                <v-flex xs4>
                  <v-layout column>
                    <v-flex xs12 class="bb-1">
                      <span class="br-1 pr-1"
                        ><v-icon>king_bed</v-icon> {{ comp.beds }}</span
                      >
                      <span class="bl-1 pl-1"
                        ><v-icon>bathtub</v-icon> {{ comp.baths }}</span
                      >
                    </v-flex>
                    <v-flex xs12 pt-1>
                      <span class="br-1 pr-1"
                        ><v-icon
                          :class="comp.pool ? 'green-health' : 'red-warn'"
                          >pool</v-icon
                        >
                      </span>
                      <span class="bl-1 pl-1"
                        ><v-icon
                          :class="comp.hot_tub ? 'green-health' : 'red-warn'"
                          >hot_tub</v-icon
                        >
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import orderBy from 'lodash/orderBy'
import ConversationInfoTitle from 'components/chat/conversation-info-title'

export default {
  components: { ConversationInfoTitle },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['conversation', 'reservation'],
  computed: {
    listing() {
      return this.reservation.listing
    },
    orderedRelevant() {
      return orderBy(this.reservation.chat_relevant_listings, 'price.our_price')
    },
  },
  methods: {
    compLink(comp) {
      if (this.conversation) {
        return comp.links[this.reservation.source]
      }
    },
  },
}
</script>
