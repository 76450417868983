<template>
  <div class="d-inline-flex grey lighten-4 rounded pa-1">
    <v-btn elevation="0" :color="!type ? 'white' : ''" @click="changeType('')">
      <span class="text-capitalize">All</span>
    </v-btn>
    <v-btn
      elevation="0"
      :color="type === 'manual' ? 'white' : ''"
      @click="changeType('manual')"
    >
      <span class="text-capitalize">Manual</span>
    </v-btn>
    <v-btn
      elevation="0"
      :color="type === 'routine' ? 'white' : ''"
      @click="changeType('routine')"
    >
      <span class="text-capitalize">Routine</span>
    </v-btn>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'ContractorPaymentsFilter',
  computed: {
    ...mapState({
      type: 'contractorPaymentsType',
    }),
  },
  methods: {
    ...mapMutations({
      changeType: 'setContractorPaymentsType',
    }),
  },
}
</script>
