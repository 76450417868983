<template>
  <v-card>
    <v-card-title>Add Card</v-card-title>
    <v-card-text>
      <stripe-element
        button-text="add card"
        :public-key="publicKey"
        :intent-id="intentId"
        :add-card="true"
        :client-secret="clientSecret"
        @payment-success="paymentSuccess"
        @payment-failed="paymentFailed"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import StripeElement from 'components/stripe/stripe-element'
import Toaster from '@/utils/toaster'
export default {
  name: 'AddCardModal',
  components: { StripeElement },
  props: ['publicKey', 'clientSecret', 'intentId'],
  methods: {
    paymentSuccess() {
      Toaster.show([{ type: 'success', text: 'Card was added successfully' }])
      setTimeout(() => {
        this.$store.dispatch('getConfiguration')
      }, 1000)
      this.$store.commit('hideModal')
    },
    paymentFailed() {
      console.log('failed')
    },
  },
}
</script>

<style scoped></style>
