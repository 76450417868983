import GlobalMixin from './global-mixin'

export default {
  mixins: [GlobalMixin],

  methods: {
    proxiedIf(condition) {
      if (process.env.NODE_ENV === 'development') {
        return true
      }
      try {
        if (this.isDesignedVr === undefined || !this.isDesignedVr) {
          return false
        }
        if (typeof condition === 'function') {
          return condition()
        }
        return condition
      } catch {
        return false
      }
    },
  },
}
