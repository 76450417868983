<template>
  <v-dialog v-model="showMultiEdit" persistent white max-width="560px">
    <v-card class="overflow-auto">
      <v-card-title class="text-center">
        <v-row wrap align-end>
          <v-col cols="8">
            <span class="center-text grey-text"
              >{{ selected.length }} selected</span
            >
          </v-col>
          <v-col cols="4">
            <v-btn
              fab
              small
              dark
              color="teal"
              class="float-right"
              @click="closeSnack"
            >
              <v-icon dark>close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <v-card-text class="pa-0">
      <v-col class="white">
        <v-row class="flex-column">
          <v-col cols="12" column pa-2>
            <v-text-field
              v-model="multiChanges.description"
              dense
              flat
              outlined
              hide-details
              label="Description"
            ></v-text-field>
          </v-col>
          <v-col cols="12" column pa-2>
            <v-text-field
              v-model="multiChanges.amount"
              dense
              flat
              outlined
              hide-details
              label="Price per visit"
            ></v-text-field>
          </v-col>
          <v-col cols="12" column pa-2>
            <v-text-field
              v-model="multiChanges.planned_duration"
              dense
              flat
              outlined
              hide-details
              label="Planned duration"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <catalog-quick-select :filter-change="quickPickChange" />
          </v-col>
          <div class="comp-wrapper">
            <div class="text-subtitle-1">Assigned person:</div>
            <v-radio-group v-model="byContractor" row>
              <v-radio label="Contractor" value="contractor">{{
                $t('By Contractor')
              }}</v-radio>
              <v-radio label="Personnel" value="personnel">{{
                $t('By personnel')
              }}</v-radio>
            </v-radio-group>
            <v-select
              v-if="byContractor === 'personnel'"
              v-model="multiChanges.by_personnel"
              dense
              clearable
              persistent-hint
              hide-details
              :placeholder="$t('Choose Personnel')"
              outlined
              :items="[
                { text: 'Cleaning Supervisor', value: 'cleaning_supervisor' },
                { text: 'Pool', value: 'pool' },
                { text: 'Pest', value: 'pest' },
                { text: 'Landscape', value: 'landscape' },
                { text: 'Host', value: 'host' },
                { text: 'Region Manager', value: 'region_manager' },
              ]"
            />
            <div v-if="byContractor === 'contractor'">
              <contractor-select
                :value.sync="multiChanges.assigned_contractor_id"
              />
            </div>
          </div>
        </v-row>
      </v-col>
      <v-col class="white">
        <v-btn text class="mr-3 pa-2 warning" @click="activate(false)">
          Deactivate all
        </v-btn>
      </v-col>
      <v-col class="white">
        <v-btn text class="mr-3 pa-2 success" @click="activate(true)">
          Activate all
        </v-btn>
      </v-col>
      <v-col class="white">
        <v-btn color="red" text @click="deleteAutoListingTasks">
          Delete all
        </v-btn>
      </v-col>
    </v-card-text>
    <v-card-actions class="white">
      <v-spacer></v-spacer>
      <v-btn
        color="cyan"
        text
        class="mr-3 pa-2"
        :disabled="isEmpty(multiChanges)"
        @click="changeSelected"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-dialog>
</template>
<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import axios from 'axios'
import ContractorSelect from 'components/contractor-select.vue'
import CatalogQuickSelect from 'components/catalog-quick-select'

export default {
  components: {
    CatalogQuickSelect,
    ContractorSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['afterSaveFunc', 'showMultiEdit', 'hide'],
  data() {
    return {
      menu: false,
      dialog: false,
      byContractor: null,
      checklist: [],
      multiChanges: { assigned_contractor_id: null, by_personnel: null },
    }
  },
  computed: {
    selected: {
      get() {
        return this.$store.state.autoRulesSelectedIDS
      },
      set(val) {
        this.$store.commit('updateAutoRulesSelectedIDS', val)
      },
    },
  },
  methods: {
    quickPickChange(change) {
      this.multiChanges.task_type = change.department
      this.multiChanges.sub_topic = change.sub_topic
      this.multiChanges.planned_duration = change.avg_duration || 1
      this.multiChanges.amount = change.avg_price_total
      this.multiChanges.listing_task_catalog_item_id = change.id
      this.multiChanges.listing_task_catalog_item_name = change.description
      if (!this.isEmpty(change.checklist)) {
        this.checklist = change.checklist
      }
      if (change.user_id) {
        this.multiChanges.assigned_contractor_id = change.user_id
      }
    },
    closeSnack() {
      this.multiChanges = { assigned_contractor_id: null, by_personnel: null }
      this.hide()
    },

    deleteAutoListingTasks() {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        this.$store.commit('updateLoading', true)

        if (this.selected.length > 0) {
          axios
            .post('/api/auto-listing-tasks/multi-edit/group', {
              delete_list: true,
              ids: this.selected,
            })
            .then(() => {
              this.$store.commit('updateLoading', false)

              this.selected = []
              this.multiChanges = {
                assigned_contractor_id: null,
                by_personnel: null,
              }
              this.changedDate = null
              this.afterSaveFunc()
            })
            .catch(error => {
              this.$store.commit('updateLoading', false)

              this.multiChanges = {
                assigned_contractor_id: null,
                by_personnel: null,
              }
              console.log(error)
              alert(error)
            })
        }
      }
    },
    activate(active) {
      if (this.selected.length > 0) {
        this.$store.commit('updateLoading', true)

        axios
          .post('/api/auto-listing-tasks/multi-edit/group', {
            ids: this.selected,
            active_all: active,
          })
          .then(() => {
            this.$store.commit('updateLoading', false)

            this.selected = []
            this.multiChanges = { contractorChosen: null }
            this.afterSaveFunc()
          })
          .catch(error => {
            this.$store.commit('updateLoading', false)

            this.multiChanges = { contractorChosen: null }
            console.log(error)
            alert(error)
          })
      }
    },
    changeSelected() {
      if (this.selected.length > 0) {
        this.$store.commit('updateLoading', true)

        axios
          .post('/api/auto-listing-tasks/multi-edit/group', {
            ids: this.selected,
            task_type: this.multiChanges.task_type,
            sub_topic: this.multiChanges.sub_topic,
            listing_task_catalog_item_id:
              this.multiChanges.listing_task_catalog_item_id,
            listing_task_catalog_item_name:
              this.multiChanges.listing_task_catalog_item_name,
            description: this.multiChanges.description,
            assigned_contractor_id: this.multiChanges.assigned_contractor_id,
            by_personnel: this.multiChanges.by_personnel,
            amount: this.multiChanges.amount,
            planned_duration: this.multiChanges.planned_duration,
          })
          .then(() => {
            this.$store.commit('updateLoading', false)

            this.selected = []
            this.multiChanges = { contractorChosen: null }
            this.afterSaveFunc()
          })
          .catch(error => {
            this.$store.commit('updateLoading', false)

            this.multiChanges = { contractorChosen: null }
            console.log(error)
            alert(error)
          })
      }
    },
  },
}
</script>
