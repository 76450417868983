import axios from '@/axios/config'

export default {
  namespaced: true,
  actions: {
    async fetchBilling(_, params) {
      return axios.get(`/api/billing/report`, { params })
    },
    async prevMonthInvoice(_, { tenant_id, ...params }) {
      return axios.get(`/api/billing/${tenant_id}/prev-month-invoice`, {
        params,
      })
    },
    async fetchBillings(_, params) {
      return axios.get(`/api/billing`, { params })
    },
  },
}
