<template>
  <div>
    <div v-if="!disabled" class="d-flex my-3 align-center">
      <v-col cols="auto" class="pa-0">
        <v-text-field
          v-model="newList"
          outlined
          color="primary"
          dense
          hide-details
          placeholder="New List"
        />
      </v-col>

      <v-btn
        color="primary"
        :disabled="createListDisabled"
        class="mx-2"
        @click="addToLists"
      >
        <v-icon left x-small>fas fa-plus</v-icon>
        Add list
      </v-btn>
    </div>
    <v-sheet
      v-for="listName in listSubjects"
      :key="listName"
      elevation="1"
      class="mt-3 ba-1"
    >
      <div class="text-subtitle-1 pa-2">
        <v-row class="align-center">
          <v-col cols="4">
            <v-text-field
              :value="listName"
              placeholder="List name"
              outlined
              :disabled="disabled"
              hide-details
              dense
              @change="updateListName($event, listName)"
            />
          </v-col>
          <v-col cols="auto">
            <v-select
              :value="listDupBy(listName)"
              :items="['Bedroom', 'Bathroom']"
              label="Dup by"
              clearable
              :disabled="disabled"
              hide-details
              dense
              outlined
              class="small-text max-w-150"
              @change="updateListDup($event, listName)"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="!disabled"
              x-small
              color="primary"
              @click="addToArray(listName)"
            >
              <v-icon x-small left color="white">fas fa-plus</v-icon>
              task
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="px-4">
            <v-btn icon text @click="toggleSubTasks(listName)">
              <v-icon
                :class="listToggle[listName] && 'icon-flipped'"
                color="black"
                small
                >fas fa-angle-double-down</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-expand-transition>
        <v-list v-if="listToggle[listName]">
          <v-list-item-group>
            <draggable
              :list="filteredArrays[listName]"
              ghost-class="ghost"
              @end="logChange"
            >
              <v-list-item
                v-for="(val, index) in filteredArrays[listName]"
                :key="index"
              >
                <v-row
                  no-gutters
                  class="align-center px-3 my-1 pt-5 draggable-item"
                >
                  <v-col cols="auto">
                    <v-icon
                      v-if="!disabled"
                      class="pr-2"
                      color="warning"
                      small
                      @click="removeCell(val.id)"
                      >fas fa-minus-circle</v-icon
                    >
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="val.description"
                      :disabled="disabled"
                      hide-details
                      outlined
                      dense
                      :label="$t('Description')"
                      @change="updateArray($event, val.id, 'description')"
                    />
                  </v-col>
                  <v-col cols="auto" class="mx-2">
                    <v-select
                      v-model="val.dup_by"
                      :items="['Bedroom', 'Bathroom']"
                      label="Dup by"
                      clearable
                      hide-details
                      outlined
                      dense
                      class="max-w-150"
                      :disabled="disabled"
                      @change="updateArray($event, val.id, 'dup_by')"
                    />
                  </v-col>
                  <v-col cols="1" class="mx-2">
                    <v-select
                      v-model="val.inspection_mode"
                      :items="[
                        { text: 'Yes/No', value: true },
                        { text: 'Multi', value: false },
                      ]"
                      label="Type"
                      hide-details
                      outlined
                      dense
                      class="max-w-150"
                      :disabled="disabled"
                      @change="updateArray($event, val.id, 'inspection_mode')"
                    />
                  </v-col>
                  <v-col class="px-1" cols="auto">
                    <v-autocomplete
                      v-model="val.amenity_based"
                      :disabled="disabled"
                      :items="cmAmenities"
                      clearable
                      label="Only if Amenity"
                      hide-details
                      outlined
                      class="max-w-150"
                      dense
                      @change="updateArray($event, val.id, 'amenity_based')"
                    />
                  </v-col>
                  <v-col class="px-1" cols="auto">
                    <v-checkbox
                      v-model="val.must_upload_pic"
                      small
                      :disabled="disabled"
                      @change="updateArray($event, val.id, 'must_upload_pic')"
                    >
                      <template #label>
                        <span class="text-body-2">Require Pic</span>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col class="px-1" cols="auto">
                    <v-checkbox
                      v-model="val.ask_rating"
                      small
                      :disabled="disabled"
                      @change="updateArray($event, val.id, 'ask_rating')"
                    >
                      <template #label>
                        <span class="text-body-2">Require Rating</span>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-list-item>
            </draggable>
          </v-list-item-group>
        </v-list>
      </v-expand-transition>
    </v-sheet>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { uniq } from 'lodash/fp'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  components: { draggable },
  props: ['afterChange', 'existingItems', 'disabled'],
  data() {
    return {
      buildArray: [],
      newList: null,
      listToggle: {},
    }
  },
  watch: {
    existingItems(items) {
      if (items && items.length !== 0) {
        this.buildArray = cloneDeep(this.reorderItemsByGroup(items))
      }
    },
  },
  computed: {
    ...mapGetters(['cmAmenities']),
    listSubjects() {
      return uniq(this.buildArray.map(v => v.list_subject))
    },
    createListDisabled() {
      return !this.newList || this.listSubjects.includes(this.newList)
    },
    filteredArrays() {
      return this.listSubjects.reduce((arrays, listName) => {
        arrays[listName] = this.buildArray.filter(
          item => item.list_subject === listName
        )
        return arrays
      }, {})
    },
  },
  mounted() {
    const items = this.existingItems
    if (items && items.length !== 0) {
      items.forEach((item, index) => {
        if (!item.id) item.id = 'item_' + (Date.now() + index + 1)
      })
      this.buildArray = cloneDeep(this.reorderItemsByGroup(items))
    }
  },
  methods: {
    logChange() {
      const combinedArray = []
      for (const listName of this.listSubjects) {
        combinedArray.push(...this.filteredArrays[listName])
      }
      this.afterChange(cloneDeep(combinedArray))
    },
    reorderItemsByGroup(items) {
      const uniqueGroups = uniq(items.map(v => v.list_subject))
      const reorderedItems = []

      uniqueGroups.forEach(group => {
        const groupItems = items.filter(item => item.list_subject === group)
        reorderedItems.push(...groupItems)
      })

      return reorderedItems
    },
    subTotal(item) {
      if (item.cost && item.amount) {
        return item.cost * item.amount
      }
    },
    toggleSubTasks(listName) {
      this.listToggle = {
        ...this.listToggle,
        [listName]: !this.listToggle[listName],
      }
    },
    removeCell(id) {
      const indexToRemove = this.buildArray.findIndex(item => item.id === id)
      if (indexToRemove !== -1) {
        this.buildArray.splice(indexToRemove, 1)
        this.afterChange(cloneDeep(this.buildArray))
      }
    },
    updateArray(val, itemId, itemType) {
      const item = this.buildArray.find(item => item.id === itemId)
      if (item) {
        item[itemType] = val
        this.afterChange(cloneDeep(this.buildArray))
      }
    },
    addToArray(listname) {
      this.listToggle = { ...this.listToggle, [listname]: false }
      this.toggleSubTasks(listname)
      const item = { list_subject: listname, id: 'item_' + Date.now() }
      const previusItem = this.buildArray.find(
        obj => obj.list_subject === listname && !!obj.list_dup_by
      )
      if (previusItem) item.list_dup_by = previusItem.list_dup_by
      this.buildArray.push(item)
      this.afterChange(cloneDeep(this.buildArray))
    },
    addToLists() {
      this.buildArray.push({
        list_subject: this.newList,
        id: 'item_' + Date.now(),
      })
      this.listToggle[this.newList] = true
      this.afterChange(cloneDeep(this.buildArray))
      this.newList = null
    },
    updateListName(newName, oldName) {
      this.buildArray.forEach(item => {
        if (item.list_subject === oldName) {
          item.list_subject = newName
        }
      })
      this.afterChange(cloneDeep(this.buildArray))
    },
    updateListDup(newName, listname) {
      this.buildArray.forEach(item => {
        if (item.list_subject === listname) {
          item.list_dup_by = newName
        }
      })
      this.afterChange(cloneDeep(this.buildArray))
    },
    listDupBy(listName) {
      const listItem = this.buildArray.find(
        item => item.list_subject === listName
      )
      return listItem && listItem.list_dup_by
    },
  },
}
</script>
<style scoped>
.icon-flipped {
  transform: scale(-1);
  -moz-transform: scale(-1);
  -webkit-transform: scale(-1);
  -ms-transform: scale(-1);
}
.draggable-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  cursor: grab;
  transition: background-color 0.3s;
}
.draggable-item:hover {
  padding: 0 15px;
  background: #f5f5f5;
}
.ghost {
  opacity: 0.5;
  .draggable-item {
    background: #f5f5f5;
  }
}
.v-list-item--link:before {
  background: none;
}
</style>
