<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="a"
      d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm0,22.5A10.5,10.5,0,1,1,22.5,12,10.512,10.512,0,0,1,12,22.5Z"
    />
    <path
      class="a"
      d="M209.294,83.118h-1.5v7.811l4.72,4.72,1.061-1.061-4.28-4.28Z"
      transform="translate(-196.544 -78.618)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Clock',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
