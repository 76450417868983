<template>
  <div class="text-caption">
    <div class="d-flex align-center text-subtitle-1 pt-2">
      <div class="mr-2">{{ $t('Summary') }}</div>
    </div>
    <v-form ref="summaryForm">
      <div class="d-flex flex-wrap no-gutters align-center pb-2">
        <div class="secondary--text flex col-6 pa-0">Item Price</div>
        <div class="flex col-6 pa-0">
          <v-text-field
            v-model.number="amount"
            disabled
            class="px-md-4"
            dense
            type="number"
          />
        </div>
      </div>
      <RefundDialog
        v-if="pendingCaptureStoreItem && (isFieldPm || isAdmin) && taskDone"
        type="capture"
        :button-block="true"
        :intent-id="listingTask.intent.payment_intent_id"
        :total="listingTask.intent.amount / 100"
        @success="moveToDone"
      />
      <v-btn
        v-if="!taskDone"
        color="success"
        class="my-8"
        outlined
        block
        @click="moveToDone({ amount })"
        >{{ $t('Move to done') }}
      </v-btn>
      <div
        v-if="taskDone && !isContractor && !listingTask.manual_approved"
        class="d-flex"
      >
        <v-btn class="flex ma-1" color="error" @click="openRejectModal">
          Reject
        </v-btn>
        <v-btn class="flex ma-1" color="success" @click="approveTask">
          Approve
        </v-btn>
      </div>
      <div
        v-if="taskDone && !listingTask.manual_approved && isContractor"
        class="text-subtitle-2 warning--text"
      >
        Task is waiting for manager approval
      </div>
      <ConfirmationModal
        v-if="listingTask.manual_approved && !isContractor"
        text="Are you sure you want to undo expense ?"
        @action="undoApprove"
      >
        <template #activator="slotData">
          <v-btn
            class="mb-2"
            block
            color="warning"
            outlined
            elevation="0"
            v-on="slotData.on"
          >
            Undo approval
          </v-btn>
        </template>
      </ConfirmationModal>
    </v-form>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import formRules from 'components/mixins/form-rules-mixin'
import get from 'lodash/fp/get'
import ConfirmationModal from 'components/modals/confirmation-modal'
import RefundDialog from 'components/stripe/refund-dialog'
import { TASK_STATUSES } from '@/consts'
import ListingTaskMethodsMixin from 'components/listing-tasks/mixins/listing-task-methods-mixin'

export default {
  name: 'LtStoreSummary',
  components: { ConfirmationModal, RefundDialog },
  mixins: [
    CommonFunctions,
    formRules,
    PermissionsMixin,
    ListingTaskMethodsMixin,
  ],
  props: ['listingTask'],
  data() {
    let itemPrice = this.listingTask.store_item_purchase.amount
    const { markup } = this.listingTask.store_item_purchase.extra_data
    if (this.$store.state.user.role === 'contractor' && markup) {
      itemPrice = Math.round(itemPrice / markup)
    }
    return {
      amount: itemPrice,
    }
  },
  computed: {
    taskDone() {
      return this.isDone(this.listingTask.status)
    },
    pendingCaptureStoreItem() {
      return (
        get('store_item_purchase.status', this.listingTask) ===
        'pending_capture'
      )
    },
  },
  methods: {
    moveToDone(payload = {}) {
      payload = {
        id: this.listingTask.id,
        status: 'Done',
        ...payload,
      }
      if (this.listingTask.in_loop) {
        const condition =
          this.listingTask.listing_task_template.loop_condition_text
        this.$store.commit('showModal', {
          name: 'LtLoopMoveToDoneModal',
          props: { condition, payload },
        })
      } else {
        this.$store.dispatch('updateListingTask', payload)
      }
    },
    updateField(key) {
      if (this.listingTask[key] !== this[key]) {
        this.$store.dispatch('updateListingTask', {
          id: this.listingTask.id,
          [key]: this[key],
        })
      }
    },
    approveTask() {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        manual_approved: true,
      })
    },
    openRejectModal() {
      this.$store.commit('showModal', {
        name: 'RejectModal',
        props: {
          listingTaskId: this.listingTask.id,
          status: TASK_STATUSES.REJECTED,
        },
      })
    },
    undoApprove() {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        manual_approved: false,
      })
    },
  },
}
</script>

<style scoped></style>
