<template>
  <v-list-item :ripple="false" class="pa-0" @click="$emit('task-click', task)">
    <span :class="['pa-0', 'availability', availabilityClass]" />
    <div class="text-end mx-1">
      <v-progress-circular
        v-if="loading"
        :width="2"
        :size="25"
        class="ml-2"
        indeterminate
        color="primary"
      />
      <v-avatar
        v-else
        class="ml-3 mr-1"
        :color="stringToHslColor(task.assigned_to_name)"
        size="25"
        ><span class="white--text font-weight-bold">{{
          getTaskOrder(task, taskOrderMap)
        }}</span></v-avatar
      >
      <div>
        <v-chip
          label
          outlined
          class="pa-1"
          :color="listingTaskStatusColor(task.status)"
          x-small
          >{{ listingTaskStatusFormat(task.status) }}</v-chip
        >
      </div>
    </div>
    <v-list-item-content class="ml-2" style="min-height: 75px">
      <v-list-item-title>{{ task.assigned_to_name }}</v-list-item-title>
      <v-list-item-subtitle class="d-flex align-center"
        >{{ parseDate(task.scheduled_at, 'HH:mm MM-DD-YY') }}
        <v-avatar
          class="ml-2 mr-1"
          size="18"
          :color="priorityColor(task.priority)"
          ><span v-if="task.priority" class="white--text font-weight-bold">{{
            task.priority[0]
          }}</span></v-avatar
        >
        <span class="secondary--text"> {{ task.planned_duration }} hrs </span>
      </v-list-item-subtitle>
      <v-list-item-subtitle class="secondary--text">{{
        task.mini_extra_data.listing_name
      }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { getTaskOrder } from 'components/calendar/utils'
import formattersMixin from 'components/mixins/formatters-mixin'
import colorsMixin from 'components/mixins/colors-mixin'

export default {
  mixins: [formattersMixin, colorsMixin],
  props: ['task', 'taskOrderMap', 'loading'],
  data() {
    return { getTaskOrder }
  },
  computed: {
    availabilityClass() {
      if (this.task.mini_extra_data.late_checkout_purchase) {
        return `late-co ${this.task.add_today_avialability_text}`
      } else if (this.task.mini_extra_data.early_check_in_purchase) {
        return `early-ci ${this.task.add_today_avialability_text}`
      } else {
        return this.task.add_today_avialability_text
      }
    },
  },
  methods: {
    listingTaskStatusFormat(status) {
      if (status === 'Pending Approval') {
        return 'Pend'
      } else if (status === 'In Progress') {
        return 'Prog'
      }
      return status
    },
  },
}
</script>
<style scoped>
.availability {
  width: 4px;
  height: 100%;
  position: absolute;
  border-radius: 0;
}
</style>
