<template>
  <tr @click="mediaClicked(item)">
    <td>
      <p v-if="isEmpty(media(item))"></p>
      <v-btn v-else small icon>
        <v-icon color="secondary">fas fa-images</v-icon>
      </v-btn>
    </td>
    <td class="py-2">
      <p
        v-if="!item.listing_info_def && !item.comments"
        class="secondary--text mb-0"
      >
        No topic or comments provided
      </p>
      <div v-else>
        <div
          v-show="item.listing_info_def"
          class="d-flex align-center text-caption font-weight-medium text--secondary"
        >
          <span label outlined small>
            {{ item.listing_info_def.topic }}
          </span>
          <v-icon small color="grey lighten-1" class="mx-2"
            >mdi-chevron-right</v-icon
          >
          <span label outlined small>{{
            item.listing_info_def.sub_topic
          }}</span>
          <v-icon
            v-if="item.listing_info_def.hint"
            color="grey lighten-1"
            small
            class="mx-2"
            >mdi-chevron-right</v-icon
          >
          <span v-if="item.listing_info_def.hint" label outlined>{{
            item.listing_info_def.hint
          }}</span>
          <v-tooltip v-if="!item.comments && isEmpty(media(item))" top>
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" class="mx-3 error--text" small v-on="on"
                >$info_circle</v-icon
              >
            </template>
            <div>Missing info</div>
          </v-tooltip>
        </div>
        <p
          v-show="item.comments"
          class="mb-0 mt-2 font-weight-medium line-height ellipsis-2 pointer"
        >
          {{ item.comments }}
        </p>
      </div>
    </td>
    <td v-if="!isInvestor">
      <v-menu offset-y bottom left>
        <template #activator="{ on, attrs }">
          <v-btn color="secondary" v-bind="attrs" icon v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list class="py-0" dense>
          <v-list-item @click="editItem(item)">
            <v-list-item-title>
              <v-icon small> mdi-pencil </v-icon>
              <span class="secondary--text">Edit</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="copyItem(item)">
            <v-list-item-title>
              <v-icon small> content_copy </v-icon>
              <span class="secondary--text">Copy</span>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="
              deleteItem({
                id: item.id,
                listing_id: item.listing_id,
              })
            "
          >
            <v-list-item-title>
              <v-icon color="warning" small> mdi-delete </v-icon>
              <span class="warning--text">Delete</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import cloneDeep from 'lodash/cloneDeep'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['item', 'mediaClicked', 'afterChange'],
  data() {
    return {
      images: [],
      dialog: false,
    }
  },
  methods: {
    media(item) {
      if (item.movie_url) {
        let images = cloneDeep(item.images)
        images.push(item.movie_url)
        return images
      } else {
        return item.images
      }
    },
    copyItem(item) {
      this.$emit('copy-item', item)
    },
    editItem(item) {
      this.$emit('edititem', item)
    },
    deleteItem(payload) {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        this.$emit('delete-item', payload)
      }
    },
  },
}
</script>
<style scoped>
.line-height {
  line-height: 1.5;
}
</style>
