<template>
  <v-layout
    v-if="managementRoles"
    wrap
    mt-3
    justify-center
    align-center
    class="center-text mt-4"
  >
    <v-flex v-if="!isEmpty(filteredWarnings)" xs12 class="center-text">
      <h4 class="purple-icon">
        #{{ filteredWarnings.length }} listings with active rules
      </h4>
    </v-flex>
    <v-flex xs12 sm4>
      <v-text-field
        v-model="searchTerm"
        flat
        label="Search by description..."
        append-icon="search"
        solo-inverted
        style="border: 1px grey solid"
        class="mb-4"
      ></v-text-field>
    </v-flex>
    <v-flex xs12 sm4 ml-3>
      <v-checkbox
        v-model="onlyWithWarnings"
        hide-details
        label="Show only with warnings"
      ></v-checkbox>
      <v-checkbox
        v-model="noUnits"
        hide-details
        label="Remove hotel units"
      ></v-checkbox>
    </v-flex>
    <v-data-table
      :headers="headers"
      :items="filteredWarnings"
      class="elevation-1"
    >
      <template #item="{ item }">
        <tr>
          <td v-for="header in headers" :key="header.text" class="center-text">
            <span v-if="header.text === 'Listing'">{{ item.nickname }}</span>
            <v-icon
              v-if="header.text !== 'Listing'"
              :class="
                item.rules.includes(header.text) ? 'green-health' : 'red-health'
              "
              >done_outline</v-icon
            >
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import filter from 'lodash/filter'
import flatten from 'lodash/flatten'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin],
  props: [],
  data: () => ({
    onlyWithWarnings: false,
    noUnits: true,
    searchTerm: null,
  }),
  computed: {
    headers() {
      if (this.$store.state.rulesWarnings) {
        let headers = [
          ...new Set(
            flatten(this.$store.state.rulesWarnings.map(item => item.rules))
          ),
        ]
        let cols = [
          {
            text: 'Listing',
            align: 'center',
            sortable: false,
          },
        ]
        headers.forEach(name =>
          cols.push({
            text: name,
            align: 'center',
            sortable: false,
          })
        )
        return cols
      }
      return []
    },
    filteredWarnings() {
      return filter(this.$store.state.rulesWarnings, item =>
        this.passFilters(item)
      )
    },
  },
  mounted() {
    this.$store.dispatch('getRulesWarnings')
  },
  methods: {
    passFilters(item) {
      let res = true
      if (this.onlyWithWarnings && item.warnings.length == 0) {
        res = false
      }

      if (
        !item.nickname ||
        (this.searchTerm &&
          !item.nickname.toLowerCase().includes(this.searchTerm.toLowerCase()))
      ) {
        res = false
      }
      if (this.noUnits && item.is_unit) {
        res = false
      }
      return res
    },
    editWarning(val, key, warning) {
      if (key === 'dismissed') {
        const userConfirm = confirm('Are you sure?')
        if (userConfirm) {
          warning[key] = val
          this.$store.dispatch('updateWarning', warning)
        }
      }
    },
  },
}
</script>
