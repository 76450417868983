<template>
  <svg width="512" height="512" viewBox="1 1 22 22">
    <path
      d="M7 3a1 1 0 0 0-2 0v1a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h4v-2H5a1 1 0 0 1-1-1v-9h16V7a3 3 0 0 0-3-3V3a1 1 0 1 0-2 0v1H7z"
      fill="#c92bcd"
    ></path>
    <path
      d="M7 12a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2zM6 17a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1z"
      fill="#c92bcd"
    ></path>
    <path
      d="M19.707 11.293a1 1 0 0 0-1.414 0l-6.5 6.5a1 1 0 0 0-.274.51l-.5 2.5a1 1 0 0 0 1.177 1.178l2.5-.5a1 1 0 0 0 .511-.274l6.5-6.5a1 1 0 0 0 0-1.414zm-6.286 7.7 4.079-4.079.586.586-4.079 4.079-.732.146z"
      fill="#c92bcd"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'Tools',
}
</script>

<style scoped></style>
