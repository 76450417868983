<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-row no-gutters class="align-center">
      <v-col v-if="canEditCheckin" cols="6" class="pa-1">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="inputs.arrival"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="inputs.arrival"
              hide-details
              dense
              outlined
              label="Check-In time"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-if="menu"
            v-model="inputs.arrival"
            format="24hr"
            :allowed-hours="[
              11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
            ]"
            :allowed-minutes="[0, 30]"
            full-width
            @click:minute="$refs.menu.save(inputs.arrival)"
          />
        </v-menu>
      </v-col>
      <v-col v-if="canEditCheckin" cols="6" class="pa-1">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="inputs.departure"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="inputs.departure"
              hide-details
              dense
              outlined
              label="Check-Out time"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-if="menu2"
            v-model="inputs.departure"
            format="24hr"
            :allowed-hours="[7, 8, 9, 10, 11, 12, 13, 14, 15, 16]"
            :allowed-minutes="[0, 30]"
            full-width
            @click:minute="$refs.menu2.save(inputs.departure)"
          />
        </v-menu>
      </v-col>
      <v-col v-if="canEditNote" cols="12" class="pa-1">
        <v-textarea
          v-model="inputs.dvr_notes"
          hide-details
          dense
          outlined
          label="Notes"
          rows="3"
        />
      </v-col>
      <v-col
        v-if="reservation.status === 'confirmed' && canEditCheckin"
        cols="12"
        class="pa-1"
      >
        <v-menu
          ref="menu3"
          v-model="menu3"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="inputs.luggage_drop"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="inputs.luggage_drop"
              hide-details
              dense
              outlined
              clearable
              label="Luggage Drop"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-time-picker
            v-if="menu3"
            v-model="inputs.luggage_drop"
            format="24hr"
            :allowed-hours="[
              7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
            ]"
            :allowed-minutes="[0, 30]"
            full-width
            @click:minute="$refs.menu3.save(inputs.luggage_drop)"
          />
        </v-menu>
      </v-col>
      <v-col v-if="isDirty" cols="12" class="d-flex">
        <v-row no-gutters>
          <v-col cols="6" class="pa-1">
            <v-btn block color="error" @click="cancel">Cancel</v-btn>
          </v-col>
          <v-col cols="6" class="pa-1">
            <v-btn block color="success" type="submit">Ok</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Common_functions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import get from 'lodash/fp/get'

export default {
  name: 'CheckInOutForm',
  mixins: [Common_functions, PermissionsMixin],
  props: ['reservation'],
  data() {
    return {
      menu: false,
      menu2: false,
      menu3: false,
      originalArrival: null,
      originalDeparture: null,
      inputs: {},
      initialInputs: {},
    }
  },
  mounted() {
    this.initializeFields()
  },
  computed: {
    timezone() {
      const listingTimezone = get('listing.timezone', this.reservation)
      return listingTimezone === 'UTC' ? this.appTimezone : listingTimezone
    },
    appTimezone() {
      return this.$store.state.app.configuration.timezone
    },
    reservationId() {
      return this.reservation.id
    },
    canEditCheckin() {
      return (
        this.isAdmin ||
        this.isCommunicationManager ||
        this.isCommunicationAgent ||
        this.isSalesAgent ||
        this.isSalesManager
      )
    },
    canEditNote() {
      return this.canEditCheckin || this.isFieldPm || this.isCoordinator
    },
    isDirty() {
      return (
        this.inputs.arrival !== this.initialInputs.arrival ||
        this.inputs.departure !== this.initialInputs.departure ||
        this.inputs.luggage_drop !== this.initialInputs.luggage_drop ||
        this.inputs.dvr_notes !== this.initialInputs.dvr_notes
      )
    },
  },
  watch: {
    reservationId() {
      let {
        planned_arrival: arrival,
        planned_departure: departure,
        luggage_drop,
      } = this.reservation
      if (!arrival)
        arrival = this.parseDateTZ(
          this.reservation.check_in,
          'HH:mm',
          this.timezone
        )
      if (!departure)
        departure = this.parseDateTZ(
          this.reservation.check_out,
          'HH:mm',
          this.timezone
        )
      let dvr_notes = this.reservation.dvr_notes
      this.inputs = { arrival, departure, luggage_drop, dvr_notes }
    },
  },
  methods: {
    initializeFields() {
      let {
        planned_arrival: arrival,
        planned_departure: departure,
        luggage_drop,
      } = this.reservation
      if (!arrival)
        arrival = this.parseDateTZ(
          this.reservation.check_in,
          'HH:mm',
          this.timezone
        )
      if (!departure)
        departure = this.parseDateTZ(
          this.reservation.check_out,
          'HH:mm',
          this.timezone
        )
      this.originalArrival = arrival
      this.originalDeparture = departure
      let dvr_notes = this.reservation.dvr_notes
      this.inputs = {
        arrival: this.$moment(arrival, 'hh:mm A').format('HH:mm'),
        departure: this.$moment(departure, 'hh:mm A').format('HH:mm'),
        luggage_drop,
        dvr_notes,
      }
      this.initialInputs = { ...this.inputs }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit('change', { id: this.reservationId, ...this.inputs })
      }
    },
    cancel() {
      this.initializeFields()
    },
  },
}
</script>

<style scoped></style>
