<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="16"
    viewBox="0 0 30 16"
  >
    <g id="Layer_20" data-name="Layer 20" transform="translate(-2.066 -16)">
      <path
        id="Path_7020"
        data-name="Path 7020"
        d="M32.067,17a1,1,0,0,0-1-1H22.806a1,1,0,0,0,0,2h5.821l-8.3,8.219-7.573-5.4a1,1,0,0,0-1.3.115l-9.135,9.4a1,1,0,0,0,1.432,1.4l8.539-8.78,7.558,5.392a1,1,0,0,0,1.286-.105l8.93-8.835v5.868a1,1,0,1,0,2,0Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TrendUp',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
