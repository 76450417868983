var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',[_c('v-sheet',_vm._g(_vm._b({class:[
          `d-flex
          justify-space-between
          lt-status-label
          text-uppercase
          rounded
          text-no-wrap
          font-weight-medium
          white--text
          ${_vm.listingTaskStatusColor(_vm.value)}`,
          _vm.disabled && 'opacity-30',
          {
            'px-3 py-1 text-caption': _vm.small,
            'text-body-2 px-4 py-2': !_vm.small,
          },
        ],attrs:{"width":_vm.width}},'v-sheet',attrs,false),_vm.isReadonly || _vm.disabled ? null : on),[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.value)))]),(_vm.isApproved)?_c('v-icon',{staticClass:"pl-2",attrs:{"color":_vm.listingTaskStatusColor(_vm.value),"x-small":""}},[_vm._v(" fas fa-user-check ")]):_c('v-icon',{staticClass:"pl-2",attrs:{"color":"white","x-small":""}},[_vm._v(" fas fa-chevron-down ")])],1),(_vm.getIsReasonedStatus(_vm.value) && !_vm.isMobile)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"text-caption text-decoration-underline error--text"},on),[_vm._v(" "+_vm._s(_vm.$t('More info'))+" ")])]}}],null,true)},[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.hint)+" ")])]):_vm._e()],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(_vm.menu)?_c('v-list',[_c('v-list-item-group',{attrs:{"value":_vm.statusValue,"color":"primary"},on:{"change":_vm.statusChanged}},[_vm._l((_vm.rawStatuses),function(item){return [(item.if)?_c('v-list-item',{key:item.value,attrs:{"value":item.value,"disabled":item.disabled}},[_c('v-list-item-title',{staticClass:"text-body-2"},[_c('v-icon',{staticClass:"pr-2",attrs:{"x-small":"","color":_vm.listingTaskStatusColor(item.value)}},[_vm._v(" fas fa-square ")]),_vm._v(" "+_vm._s(_vm.$t(item.value))+" ")],1)],1):_vm._e()]})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }