import { MANAGMENT_TABLE_TYPES } from '@/consts'

export const TABS = {
  FINANCE: 'Finance',
}

export const DEAL_TYPES = [
  'Fund',
  'Syndication',
  'Management only',
  'A-Z investor',
]

export const PRODUCT_TYPE = [
  'apartment',
  'destination',
  'hotel',
  'multi-family',
  'luxury',
]

export const ESTATE_HEADERS = [
  {
    text: 'Address',
    value: 'address',
    cellType: MANAGMENT_TABLE_TYPES.LINK,
    readonly: true,
    urlKey: 'realtor_link',
  },
  {
    text: 'Realtor Link',
    value: 'realtor_link',
    align: 'center',
    hideInTable: true,
  },
  {
    text: 'Estimated Current Value',
    value: 'est_current_value',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Setup Costs',
    value: 'setup_costs',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Purchase Price',
    value: 'purchase_price',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Down Payment %',
    value: 'down_payment',
    align: 'center',
    isNumber: true,
  },
  {
    text: 'Initial Investment Cost',
    value: 'computedInvestementCost',
    align: 'center',
    isCurrency: true,
    readonly: true,
  },
  {
    text: 'Monthly Mortgage',
    value: 'monthly_mortgage',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Monthly Insurance',
    value: 'insurance',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Yearly Property tax',
    value: 'yearly_property_tax',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Deal Type',
    value: 'deal_type',
    align: 'center',
    select: { items: DEAL_TYPES },
  },
  {
    text: 'Purchase Commission',
    value: 'purchase_commission',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Purchase Date',
    value: 'purchase_date',
    align: 'center',
    fieldType: 'date',
  },
  {
    text: 'Sell Commission',
    value: 'sell_commission',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Sell Date',
    value: 'sell_date',
    align: 'center',
    fieldType: 'date',
  },
  {
    text: 'Product Type',
    value: 'product_type',
    align: 'center',
    select: { items: PRODUCT_TYPE },
  },
  {
    text: 'BI Ready',
    value: 'bi_ready',
    align: 'center',
    fieldType: 'checkbox',
  },
  {
    text: 'Notes',
    value: 'notes',
    align: 'center',
    fieldType: 'textarea',
    cols: 12,
    hideInTable: true,
  },
]
export const INVESTOR_HEADERS = [
  {
    text: 'Purchase Price',
    value: 'purchase_price',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Setup Costs',
    value: 'setup_costs',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Down Payment %',
    value: 'down_payment',
    align: 'center',
    isNumber: true,
  },
  {
    text: 'Monthly Mortgage',
    value: 'monthly_mortgage',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Yearly Property tax',
    value: 'yearly_property_tax',
    align: 'center',
    isCurrency: true,
  },
  {
    text: 'Purchase Date',
    value: 'purchase_date',
    align: 'center',
    fieldType: 'date',
  },
  {
    text: 'Yearly Insurance',
    value: 'insurance',
    align: 'center',
    isCurrency: true,
  },
]
