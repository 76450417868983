<template>
  <v-list subheader flat>
    <div class="d-flex justify-space-between align-center pb-2">
      <div
        class="text--secondary text-capitalize font-weight-medium text-subtitle-1"
      >
        {{ title }}
        <v-btn
          v-if="managementRoles && !isMobileOrIPad && !readonly && isDesignedVr"
          color="info"
          class="mx-2"
          small
          @click="expandAll"
          >{{ showAll ? 'Collapse all' : 'Expand All' }}</v-btn
        >
        <span
          class="text-body-2 mx-3"
          :class="
            doneTasks === tasks.length ? 'success--text' : 'tertiary--text'
          "
        >
          {{ doneTasks }} / {{ tasks.length }} Done</span
        >
      </div>
      <div v-if="!isContractor && !isMobile">
        <v-menu v-if="selectedSubTasks.length" offset-y>
          <template #activator="{ on, attrs }">
            <v-btn color="darkpurple" text small v-bind="attrs" v-on="on">
              Actions <v-icon class="ml-2" small>fas fa-caret-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <LtFromSubtaskModal
              :selected="selectedSubTasks"
              :listing-task-id="listingTask.id"
              @onaction="resetTasks"
            >
              <template #activator="slotData">
                <v-list-item v-on="slotData.on">
                  <v-list-item-title
                    class="d-flex align-center darkpurple--text"
                  >
                    <v-icon class="mr-1" color="darkpurple" small
                      >fas fa-tasks</v-icon
                    >New SC</v-list-item-title
                  >
                </v-list-item>
              </template>
            </LtFromSubtaskModal>
            <v-list-item v-if="selectedSubTasks.length === 1" @click="editTask">
              <v-list-item-title class="d-flex align-center info--text"
                ><v-icon color="info" class="mr-1" small>edit</v-icon>
                Edit</v-list-item-title
              >
            </v-list-item>
            <ConfirmationModal
              text="Are you sure you want to delete those tasks ?"
              @action="deleteTask"
            >
              <template #activator="slotData">
                <v-list-item v-on="slotData.on">
                  <v-list-item-title class="d-flex align-center warning--text">
                    <v-icon small color="warning" class="mr-1">$trash</v-icon
                    >Delete</v-list-item-title
                  >
                </v-list-item>
              </template>
            </ConfirmationModal>
          </v-list>
        </v-menu>
        <LtSubtaskCreateModal
          v-if="!isMobileOrIPad && !readonly"
          :listing-task-id="listingTask.id"
        >
          <template #activator="slotData">
            <v-btn class="white" elevation="0" small v-on="slotData.on">
              <v-icon small class="pr-1" color="darkpurple">fas fa-plus</v-icon
              >New
            </v-btn>
          </template>
        </LtSubtaskCreateModal>
      </div>
    </div>
    <v-divider />

    <LtSubtaskRow
      v-for="(task, i) in tasks"
      :key="i"
      :task="task"
      :is-expanded="showAll"
      :list-name="title"
      :listing-task="listingTask"
      :selected="selectedSubTasks.includes(task.id)"
      :readonly="readonly"
      @selected="addTask"
      @unselected="removeTask"
    />
  </v-list>
</template>

<script>
import LtSubtaskRow from 'components/listing-tasks/lt-subtask-row'
import LtSubtaskCreateModal from 'components/listing-tasks/lt-subtask-create-modal'
import ConfirmationModal from 'components/modals/confirmation-modal'
import LtFromSubtaskModal from 'components/listing-tasks/lt-from-subtask-modal'
import DeviceMixin from 'components/mixins/device-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  name: 'LtSubtaskList',
  components: {
    LtFromSubtaskModal,
    ConfirmationModal,
    LtSubtaskCreateModal,
    LtSubtaskRow,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin],
  props: ['tasks', 'listingTask', 'title', 'readonly'],
  data() {
    return {
      selectedSubTasks: [],
      openedTasks: false,
      showAll: false,
      editableTask: null,
      panel: [],
    }
  },
  computed: {
    doneTasks() {
      return this.tasks.filter(
        t =>
          t.is_valid !== null || t.status === 'Done' || t.status === "Can't do"
      ).length
    },
  },
  methods: {
    expandAll() {
      this.showAll = !this.showAll
      this.panel = this.tasks.map((_t, i) => i)
    },
    editTask() {
      this.$store.commit('showListingSubTaskModal', this.selectedSubTasks[0])
    },
    resetTasks() {
      this.selectedSubTasks = []
    },
    addTask(id) {
      this.selectedSubTasks = this.selectedSubTasks.concat(id)
    },
    removeTask(id) {
      this.selectedSubTasks = this.selectedSubTasks.filter(
        taskId => taskId !== id
      )
    },
    deleteTask() {
      this.$store.dispatch('deleteListingSubTask', {
        ids: this.selectedSubTasks,
        listingTaskId: this.listingTask.id,
      })
      this.selectedSubTasks = []
    },
  },
}
</script>

<style scoped>
.sc-task-row {
  border: 1px solid var(--v-secondary-lighten1);
}
</style>
