<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.56 24">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="list">
          <g id="Group_11365" data-name="Group 11365">
            <g id="Group_11364" data-name="Group 11364">
              <path
                id="Path_6646"
                data-name="Path 6646"
                class="cls-1"
                d="M8.91,1.87a.47.47,0,1,0,.47.47h0A.47.47,0,0,0,8.91,1.87Z"
              />
            </g>
          </g>
          <g id="Group_11367" data-name="Group 11367">
            <g id="Group_11366" data-name="Group 11366">
              <path
                id="Path_6647"
                data-name="Path 6647"
                class="cls-1"
                d="M3.28,8.06a.47.47,0,1,0,.47.47h0A.47.47,0,0,0,3.28,8.06Z"
              />
            </g>
          </g>
          <g id="Group_11369" data-name="Group 11369">
            <g id="Group_11368" data-name="Group 11368">
              <path
                id="Path_6648"
                data-name="Path 6648"
                class="cls-1"
                d="M3.28,10.87a.47.47,0,1,0,.47.47h0A.47.47,0,0,0,3.28,10.87Z"
              />
            </g>
          </g>
          <g id="Group_11371" data-name="Group 11371">
            <g id="Group_11370" data-name="Group 11370">
              <path
                id="Path_6649"
                data-name="Path 6649"
                class="cls-1"
                d="M3.28,13.69a.47.47,0,1,0,.47.47h0A.47.47,0,0,0,3.28,13.69Z"
              />
            </g>
          </g>
          <g id="Group_11373" data-name="Group 11373">
            <g id="Group_11372" data-name="Group 11372">
              <path
                id="Path_6650"
                data-name="Path 6650"
                class="cls-1"
                d="M3.28,16.5a.47.47,0,1,0,.47.47h0A.47.47,0,0,0,3.28,16.5Z"
              />
            </g>
          </g>
          <g id="Group_11375" data-name="Group 11375">
            <g id="Group_11374" data-name="Group 11374">
              <path
                id="Path_6651"
                data-name="Path 6651"
                class="cls-1"
                d="M3.28,19.31a.47.47,0,1,0,.47.47h0A.47.47,0,0,0,3.28,19.31Z"
              />
            </g>
          </g>
          <g id="Group_11377" data-name="Group 11377">
            <g id="Group_11376" data-name="Group 11376">
              <path
                id="Path_6652"
                data-name="Path 6652"
                class="cls-1"
                d="M14.53,8.06H5.16a.47.47,0,0,0,0,.94h9.37A.47.47,0,0,0,15,8.51a.48.48,0,0,0-.45-.45Z"
              />
            </g>
          </g>
          <g id="Group_11379" data-name="Group 11379">
            <g id="Group_11378" data-name="Group 11378">
              <path
                id="Path_6653"
                data-name="Path 6653"
                class="cls-1"
                d="M14.53,10.87H5.16a.47.47,0,0,0,0,.94h9.37a.47.47,0,0,0,0-.93Z"
              />
            </g>
          </g>
          <g id="Group_11381" data-name="Group 11381">
            <g id="Group_11380" data-name="Group 11380">
              <path
                id="Path_6654"
                data-name="Path 6654"
                class="cls-1"
                d="M14.53,13.69H5.16a.47.47,0,0,0-.47.47.48.48,0,0,0,.47.47h9.37a.48.48,0,0,0,.45-.49.47.47,0,0,0-.45-.45Z"
              />
            </g>
          </g>
          <g id="Group_11383" data-name="Group 11383">
            <g id="Group_11382" data-name="Group 11382">
              <path
                id="Path_6655"
                data-name="Path 6655"
                class="cls-1"
                d="M18.61,18A.47.47,0,0,0,18,18l-1.54,1.55L15.8,19a.47.47,0,1,0-.68.64l0,0,.94.94a.47.47,0,0,0,.66,0l1.87-1.87A.47.47,0,0,0,18.61,18Z"
              />
            </g>
          </g>
          <g id="Group_11385" data-name="Group 11385">
            <g id="Group_11384" data-name="Group 11384">
              <path
                id="Path_6656"
                data-name="Path 6656"
                class="cls-1"
                d="M17.81,14.72V4.22a1.41,1.41,0,0,0-1.4-1.41H13a1.41,1.41,0,0,0-1.32-.94H11.2a2.34,2.34,0,0,0-4.59,0H6.09a1.41,1.41,0,0,0-1.32.94H1.41A1.41,1.41,0,0,0,0,4.22V22.59A1.41,1.41,0,0,0,1.41,24H16.87a4.69,4.69,0,0,0,.94-9.28ZM5.62,3.28a.47.47,0,0,1,.47-.47H7a.47.47,0,0,0,.47-.47h0a1.41,1.41,0,0,1,2.81,0,.47.47,0,0,0,.47.47h.94a.47.47,0,0,1,.47.47h0v.47H5.62ZM1.41,23.06a.47.47,0,0,1-.47-.47V4.22a.47.47,0,0,1,.47-.47H4.69v.47a.47.47,0,0,0,.47.47h7.5a.46.46,0,0,0,.46-.47h0V3.75h3.29a.46.46,0,0,1,.46.47h0v10.4a4.71,4.71,0,0,0-3.75,1.88h-8a.47.47,0,1,0,0,.93h7.42a4.73,4.73,0,0,0-.39,1.88h-7a.47.47,0,1,0,0,.94h7.12a4.63,4.63,0,0,0,1.78,2.81Zm15.46,0a3.73,3.73,0,0,1-3.72-3.33c0-.14,0-.28,0-.42a3.7,3.7,0,0,1,.64-2.08h0a3.74,3.74,0,0,1,3.11-1.67l.42,0a3.75,3.75,0,0,1-.42,7.47Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'FilePaste',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
