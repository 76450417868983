<template>
  <v-card v-if="conversation.user?.id" elevation="0">
    <v-card-text>
      <p class="font-weight-bold">User info</p>
      <div class="d-flex align-center mb-4 black--text">
        <user-avatar :name="user.full_name" :size="50" />
        <div class="ml-4">
          <p class="mb-0 font-weight-medium text-body-1 d-flex align-center">
            <editable-text-field
              class="pt-2"
              disabled
              :value="user.full_name"
              @onupdate="updateName"
            />
          </p>
          <v-btn
            v-if="user.phone"
            class="pa-0 text-body-2"
            :href="`tel:${user.phone}`"
            text
            small
          >
            <v-icon class="mr-1" x-small>mdi-phone</v-icon>
            <span class="ms-1">{{ user.phone }}</span>
          </v-btn>
          <div v-if="user.email" class="text small text-body-2">
            <v-icon class="mr-1" x-small>mdi-email</v-icon>
            <span class="ms-1">{{ user.email }}</span>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import UserAvatar from 'components/common/user-avatar'
import EditableTextField from '@/components/form-fields/editable-text-field.vue'

export default {
  name: 'ConversationInfoUser',
  components: { UserAvatar, EditableTextField },
  props: ['conversation'],
  computed: {
    user() {
      return this.conversation.user
    },
  },
  methods: {
    updateName(name) {
      this.$store.dispatch('users/updateGuestUser', {
        id: this.user.id,
        full_name: name,
      })
    },
  },
}
</script>

<style></style>
