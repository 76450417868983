<template>
  <div grid-list-md>
    <div v-show="!loading" grid-list-md>
      <v-tabs
        v-model="paramsTab"
        icons-and-text
        class="tabs-menu main-background invetor-tab"
      >
        <v-tab href="#listing-search">
          Listing Search
          <v-icon>youtube_searched_for</v-icon>
        </v-tab>
        <v-tab href="#parking">
          Parking
          <v-icon>local_parking</v-icon>
        </v-tab>
        <v-tab href="#reviews">
          Reviews
          <v-icon>message</v-icon>
        </v-tab>
        <v-tab href="#info-search">
          Info search
          <v-icon>info</v-icon>
        </v-tab>
        <v-tab href="#warn">
          Warnings
          <v-icon>report_problem</v-icon>
        </v-tab>
        <v-tab v-if="managementRoles" href="#backoffice">
          Backoffice
          <v-icon>calculate</v-icon>
        </v-tab>
        <v-tab href="#cio">
          Check-in/out
          <v-icon>meeting_room</v-icon>
        </v-tab>
        <v-tab v-if="isAdmin" href="#bi">
          BI
          <v-icon>insights</v-icon>
        </v-tab>
        <v-tab-item id="listing-search" class="pl-4 pr-4 main-background">
          <listings-search></listings-search>
        </v-tab-item>
        <v-tab-item id="parking" class="pl-4 pr-4 main-background">
          <parkings />
        </v-tab-item>
        <v-tab-item id="info-search" class="pl-4 pr-4 main-background">
          <info-search></info-search>
        </v-tab-item>
        <v-tab-item id="reviews" class="pl-4 pr-4 main-background">
          <reviews-table />
        </v-tab-item>
        <v-tab-item id="cio" class="pl-4 pr-4 main-background">
          <check-ins-check-outs />
        </v-tab-item>
        <v-tab-item id="warn" class="pl-4 pr-4 main-background">
          <communication-warnings />
        </v-tab-item>
        <v-tab-item id="backoffice" class="pl-4 pr-4 main-background">
          <v-col sm="10" offset-sm="1">
            <iframe
              src="https://public.domo.com/cards/L9n9v"
              width="1200"
              height="600"
              marginheight="0"
              marginwidth="0"
              frameborder="0"
            ></iframe>
          </v-col>
          <v-col sm="10" offset-sm="1">
            <iframe
              src="https://public.domo.com/cards/Nkpk8"
              width="1200"
              height="600"
              marginheight="0"
              marginwidth="0"
              frameborder="0"
            ></iframe>
          </v-col>
          <v-col sm="10" offset-sm="1">
            <iframe
              src="https://public.domo.com/cards/R6w6w"
              width="1200"
              height="600"
              marginheight="0"
              marginwidth="0"
              frameborder="0"
            ></iframe>
          </v-col>
          <v-col sm="10" offset-sm="1">
            <iframe
              src="https://public.domo.com/cards/XDnDV"
              width="1200"
              height="600"
              marginheight="0"
              marginwidth="0"
              frameborder="0"
            ></iframe>
          </v-col>
          <v-col sm="10" offset-sm="1">
            <iframe
              src="https://public.domo.com/cards/68xlO"
              width="1200"
              height="600"
              marginheight="0"
              marginwidth="0"
              frameborder="0"
            ></iframe>
          </v-col>
          <v-col sm="10" offset-sm="1">
            <iframe
              src="https://public.domo.com/cards/73ymQ"
              width="1200"
              height="600"
              marginheight="0"
              marginwidth="0"
              frameborder="0"
            ></iframe>
          </v-col>
        </v-tab-item>
        <v-tab-item v-if="isAdmin" value="bi" class="pl-4 pr-4 main-background">
          <v-layout wrap justify-center>
            <v-row class="row">
              <v-col sm="5" offset-sm="1" class="pr-3">
                <iframe
                  src="https://public.domo.com/cards/v2Z3m"
                  width="600"
                  height="600"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="5" class="pr-3">
                <iframe
                  src="https://public.domo.com/cards/qxZ32"
                  width="600"
                  height="600"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="10" offset-sm="1">
                <iframe
                  src="https://public.domo.com/cards/rk93E"
                  width="1200"
                  height="600"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="10" offset-sm="1" class="pr-3">
                <iframe
                  src="https://public.domo.com/cards/pY232"
                  width="1200"
                  height="600"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="10" offset-sm="1">
                <iframe
                  src="https://public.domo.com/cards/M89mB"
                  width="1200"
                  height="600"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="10" offset-sm="1">
                <iframe
                  src="https://public.domo.com/cards/L9n9v"
                  width="1200"
                  height="800"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="10" offset-sm="1">
                <iframe
                  src="https://public.domo.com/cards/Nkpk8"
                  width="1200"
                  height="800"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="10" offset-sm="1">
                <iframe
                  src="https://public.domo.com/cards/R6w6w"
                  width="1200"
                  height="600"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
              <v-col sm="10" offset-sm="1">
                <iframe
                  src="https://public.domo.com/cards/XDnDV"
                  width="1200"
                  height="600"
                  marginheight="0"
                  marginwidth="0"
                  frameborder="0"
                ></iframe>
              </v-col>
            </v-row>
          </v-layout>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ListingsSearch from 'components/listings-search'
import Parkings from 'components/parkings'
import InfoSearch from 'components/info-search'
import CommunicationWarnings from 'components/communication-warnings'
import CheckInsCheckOuts from 'components/check-ins-check-outs'
import ReviewsTable from 'components/reviews/reviews-table'

export default {
  components: {
    ReviewsTable,
    ListingsSearch,
    Parkings,
    InfoSearch,
    CommunicationWarnings,
    CheckInsCheckOuts,
  },
  mixins: [CommonFunctions, PermissionsMixin],
}
</script>
