<template>
  <v-dialog v-model="dialog" persistent max-width="300px">
    <template #activator="{ on }">
      <v-btn
        :rounded="false"
        class="rounded-0 justify-start"
        block
        text
        small
        color="info"
        elevation="0"
        v-on="on"
      >
        <v-icon left small>$warranty</v-icon>
        {{ $t('Owner Approval') }}
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form" @submit.prevent="submit">
        <v-card-title> {{ $t('Owner Approval') }}</v-card-title>
        <v-card-text>
          <v-select
            v-model.number="owner"
            dense
            :items="items"
            item-value="id"
            return-object
            item-text="name"
            :label="$t('Choose Owner')"
            :rules="[required]"
            outlined
          >
            <template #item="{ item }">
              <div>
                {{ item.name }}
                <v-chip
                  v-if="item.id === listingTask.listing.main_owner_id"
                  x-small
                  label
                  color="primary"
                >
                  main</v-chip
                >
              </div>
            </template>
            <template #selection="{ item }">
              <div>
                {{ item.name }}
                <v-chip
                  v-if="item.id === listingTask.listing.main_owner_id"
                  x-small
                  color="primary"
                  label
                  >main</v-chip
                >
              </div>
            </template>
          </v-select>
          <div class="text-caption">
            <div>
              <span class="font-weight-bold">{{ $t('Email') }}</span>
              <span class="mx-2">{{ owner.email }}</span>
            </div>
            <div>
              <span class="font-weight-bold">{{ $t('Phone') }}:</span>
              <span class="mx-2">{{ owner.phone }}</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small color="primary" outlined @click="closeModal"
            >Cancel
          </v-btn>
          <v-btn small color="primary" type="submit">{{ $t('Save') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import axios from 'axios'

export default {
  name: 'OwnerApprovalModal',
  mixins: [FormRulesMixin],
  props: ['listingTask'],
  data() {
    return {
      dialog: false,
      items: [],
      owner: {},
    }
  },
  async mounted() {
    const { data } = await axios.get(
      `/api/listings/${this.listingTask.listing_id}/users`
    )
    if (this.listingTask.listing.main_owner_id) {
      this.owner = data.find(
        d => d.id === this.listingTask.listing.main_owner_id
      )
    }
    this.items = data
  },
  methods: {
    closeModal() {
      this.dialog = false
      this.owner = {}
    },
    submit() {
      this.$store.dispatch('requestOwnerApproval', {
        id: this.listingTask.id,
        user_id: this.owner.id,
      })
      this.closeModal()
    },
  },
}
</script>

<style scoped></style>
