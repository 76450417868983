<template>
  <v-sheet
    width="205"
    class="d-flex justify-space-between align-center ba-secondary rounded"
  >
    <v-btn icon :disabled="disabledPrev" @click="update(-1)">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <div>
      <span v-if="!isYearly" class="font-weight-medium text-subtitle-1 mx-1">{{
        $t(MONTHS[dates.month])
      }}</span>
      <span class="font-weight-medium text-subtitle-1 mx-0">{{
        dates.year
      }}</span>
    </div>
    <v-btn icon :disabled="disabledNext" @click="update(1)">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </v-sheet>
</template>

<script>
import { MONTHS } from '@/consts'
import debounce from 'lodash/debounce'

export default {
  name: 'MonthYearPicker',
  props: ['minYear', 'isYearly', 'value', 'maxYear', 'maxMonth', 'minMonth'],
  data() {
    return {
      dates: this.value || {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
      },
      MONTHS: MONTHS,
    }
  },
  computed: {
    disabledPrev() {
      return this.isYearly
        ? this.dates.year === this.minYear
        : this.dates.month === this.minMonth && this.dates.year === this.minYear
    },
    disabledNext() {
      return this.isYearly
        ? this.dates.year === this.maxYear
        : this.dates.month === this.maxMonth && this.dates.year === this.maxYear
    },
  },
  watch: {
    value(val) {
      this.dates = val
    },
  },
  methods: {
    update(change) {
      if (this.isYearly) {
        this.dates.year += change
      } else {
        if (
          (this.dates.month === 11 && change > 0) ||
          (this.dates.month === 0 && change < 0)
        ) {
          this.dates.year += change
          this.dates.month = change > 0 ? 0 : 11
        } else {
          this.dates.month += change
        }
      }
      this.updateField({ ...this.dates, change })
    },
    updateField: debounce(function (params) {
      this.$emit('input', params)
      this.$emit('change', params)
    }, 800),
  },
}
</script>
<style scoped>
.ba-secondary {
  border: 1px solid #9e9e9e !important;
}
</style>
