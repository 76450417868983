var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"font-weight-medium d-inline-block",class:[
    { 'tag-rounded': _vm.rounded },
    `mx-1 ${_vm.color}--text ${_vm.inverted ? 'bg-opac' : _vm.bgColor}`,
    {
      'px-1 tag-font': _vm.size === 'xs',
      'px-2 py-1 text-caption': _vm.size === 'sm',
    },
  ],style:(`background-color: ${_vm.bgColor}; border-radius: ${
    _vm.rounded ? '20px' : '5px'
  }; color: ${_vm.color}`),attrs:{"height":_vm.height}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }