<template>
  <div class="my-8 mx-4">
    <auto-message-rules-table filter="non-ai" />
  </div>
</template>

<script>
import AutoMessageRulesTable from 'components/auto-message-rules/auto-message-rules-table.vue'

export default {
  components: {
    AutoMessageRulesTable,
  },
}
</script>

<style></style>
