<template>
  <div class="d-inline-flex grey lighten-4 rounded pa-1">
    <v-btn
      class="text-capitalize"
      :color="!isYearly ? 'white' : ''"
      elevation="0"
      @click="$emit('toggle', false)"
    >
      <span class="text-caption"> {{ $t('Month') }} </span>
    </v-btn>
    <v-btn
      class="text-capitalize"
      :color="isYearly ? 'white' : ''"
      elevation="0"
      @click="$emit('toggle', true)"
    >
      <span class="text-caption"> {{ $t('Year') }} </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'MonthYearToggle',
  props: ['isYearly'],
}
</script>

<style scoped></style>
