<template>
  <ListingTaskStoreView v-if="storePurchaseRequest" />
  <div v-else-if="isMobile && !showManage">
    <ListingTaskFieldView :change-to-manage="showManageChange" />
  </div>
  <div v-else-if="isMobileOrIPad">
    <ListingTaskViewMobile />
  </div>
  <ListingTaskView v-else />
</template>

<script>
import ListingTaskView from 'components/listing-tasks/single-listing-task/listing-task-view'
import ListingTaskViewMobile from 'components/listing-tasks/single-listing-task/listing-task-view-mobile'
import ListingTaskStoreView from 'components/listing-tasks/single-listing-task/listing-task-store-view'
import DeviceMixin from 'components/mixins/device-mixin'
import get from 'lodash/fp/get'
import ListingTaskFieldView from 'components/listing-tasks/single-listing-task/listing-task-field-view.vue'
import Common_functions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
export default {
  name: 'ListingTaskLayout',
  components: {
    ListingTaskFieldView,
    ListingTaskStoreView,
    ListingTaskView,
    ListingTaskViewMobile,
  },
  data() {
    return {
      showManage: false,
    }
  },
  methods: {
    showManageChange() {
      this.showManage = true
    },
  },
  mixins: [DeviceMixin, Common_functions, PermissionsMixin],
  computed: {
    listingTask() {
      return this.$store.getters.currentListingTask
    },
    storePurchaseRequest() {
      return (
        get('intent.metadata.third_party', this.listingTask) === 'true' ||
        get('intent.metadata.quick_item', this.listingTask) === 'true'
      )
    },
  },
}
</script>

<style scoped></style>
