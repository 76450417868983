export default {
  methods: {
    canDeleteFinancialData(date, user) {
      if (date && user) {
        const tenthDay = this.$moment().date('10')
        const beginningPrevMonth = this.$moment()
          .subtract(1, 'month')
          .startOf('month')

        const endPrevMonth = this.$moment().subtract(1, 'month').endOf('month')
        return !(
          (this.$moment().isBetween(
            beginningPrevMonth,
            tenthDay,
            undefined,
            '[)'
          ) &&
            this.$moment(date).isBetween(
              beginningPrevMonth,
              endPrevMonth,
              undefined,
              '[]'
            )) ||
          user.abilities.includes('super-accountant') ||
          this.$moment(date).isAfter(endPrevMonth)
        )
      }
      return false
    },
  },
}
