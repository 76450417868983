<template>
  <div class="p-relative flex text-center">
    <h2 class="text-h6">
      {{ title }}
      <span v-if="listingName" class="small-text ml-2"
        >({{ listingName }})</span
      >
    </h2>
    <p class="text-caption mb-2">
      {{ subTitle }}
    </p>
    <div class="d-flex align-center close-button">
      <v-tooltip v-if="expenseId && !hideActions" bottom open-delay="200">
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="info"
            target="_blank"
            :to="addressLinkTo"
            icon
            v-on="on"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>Open In New Tab</span>
      </v-tooltip>

      <v-tooltip v-if="expenseId && !hideActions" bottom open-delay="200">
        <template #activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <copy-clipboard color="info" :text="addressLink" :small="false" />
          </span>
        </template>
        <span>Copy Link To Clipboard</span>
      </v-tooltip>

      <v-tooltip
        v-if="
          deleteEnabled &&
          expenseId &&
          (isAdmin || isPropertyManager) &&
          !canDeleteFinancialData(expense.paid_at, currentUser)
        "
        bottom
        open-delay="200"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="info"
            icon
            v-on="on"
            @click="deleteExpense"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
        <span>Delete</span>
      </v-tooltip>

      <v-tooltip
        v-if="
          editEnabled &&
          expenseId &&
          (isAdmin || isPropertyManager) &&
          !canDeleteFinancialData(expense.paid_at, currentUser)
        "
        bottom
        open-delay="200"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            color="info"
            icon
            v-on="on"
            @click="updateEditExpense"
          >
            <v-icon>edit</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>

      <v-btn icon class="ml-5" @click="closeModal">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import CopyClipboard from 'components/copy-clipboard'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import AccountingMixin from 'components/mixins/accounting-mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [PermissionsMixin, AccountingMixin],
  components: { CopyClipboard },
  props: [
    'expenseId',
    'expense',
    'title',
    'subTitle',
    'hideActions',
    'listingName',
    'deleteEnabled',
    'editEnabled',
  ],
  computed: {
    ...mapGetters(['currentUser']),
    addressLinkTo() {
      return { path: `/dashboard/expenses/${this.expenseId}` }
    },
    addressLink() {
      return `${window.location.origin}/dashboard/expenses/${this.expenseId}`
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('hideModal')
    },
    updateEditExpense() {
      this.$store.commit('updateEditExpense', this.expense)
    },
    deleteExpense() {
      this.$emit('delete-expense')
    },
  },
}
</script>
