import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    accounts: [],
  },
  mutations: {
    updateStripeAccounts(state, data) {
      state.accounts = data
    },
    appendStripeAccounts(state, data) {
      state.accounts.push(data)
    },
  },
  actions: {
    async getStripeAccounts({ commit }) {
      const { data } = await axios.get(`/api/stripe-accounts`)
      commit('updateStripeAccounts', data)
    },
    async updateStripeAccount({ dispatch }, { id, ...payload }) {
      await axios.put(`/api/stripe-accounts/${id}`, payload)
      dispatch('getStripeAccounts')
    },
    async createStripeAccount({ commit }, payload) {
      const { data } = await axios.post(`/api/stripe-accounts`, payload)
      commit('appendStripeAccounts', data)
    },
    async createManualCharge(_store, payload) {
      const { data } = await axios.post('/api/manual-payment', payload)
      return data
    },
    async setFutureIntentMethod(_store, intentId) {
      const { data } = await axios.post('/api/stripe/set-future-payment', {
        intent_id: intentId,
      })
      return data
    },
    async chargeInvoice(_store, payload) {
      return axios.post('/api/stripe/billing/charge-invoice', payload)
    },
  },
}
