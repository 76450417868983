<template>
  <v-row class="flex-wrap">
    <v-col
      v-for="(city, i) in cities"
      :key="i"
      class="pl-2 pr-2 mt-2 mb-2 text-center"
      cols="12"
    >
      <city-card :city="city" />
    </v-col>
  </v-row>
</template>

<script>
import CityCard from 'components/city-card.vue'

export default {
  components: {
    CityCard,
  },
  props: ['cities'],
}
</script>
