<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.626"
    height="24"
    viewBox="0 0 25.626 24"
  >
    <g transform="translate(0 -12.158)">
      <g transform="translate(10.943 15.932)">
        <path
          class="a"
          d="M167.826,72.275a.594.594,0,0,0-.412-.128h-2.247l2.113-2.359.2-.219.1-.128a.711.711,0,0,0,.086-.161.535.535,0,0,0,.037-.193.4.4,0,0,0-.2-.385,1.193,1.193,0,0,0-.562-.107h-2.627a.578.578,0,0,0-.391.118.4.4,0,0,0-.139.316q0,.273.182.332a1.717,1.717,0,0,0,.535.064h1.712q-.107.15-.278.353l-.439.492-.594.642-.69.765q-.358.4-.471.535a.5.5,0,0,0,.048.69.728.728,0,0,0,.492.144h3.124a.61.61,0,0,0,.417-.123.407.407,0,0,0,.139-.316A.428.428,0,0,0,167.826,72.275Z"
          transform="translate(-163.629 -68.592)"
        />
      </g>
      <g transform="translate(16.553 19.383)">
        <g transform="translate(0 0)">
          <path
            class="a"
            d="M250.838,123.162a.471.471,0,0,0-.332-.1h-1.8l1.685-1.883.161-.177.1-.123a.562.562,0,0,0,.07-.128.428.428,0,0,0,.032-.155.321.321,0,0,0-.155-.31.952.952,0,0,0-.449-.086h-2.119a.465.465,0,0,0-.31.1.316.316,0,0,0-.112.251q0,.214.144.268a1.366,1.366,0,0,0,.428.048h1.37a3.172,3.172,0,0,1-.225.284l-.353.4-.46.535-.535.61q-.289.321-.38.439a.4.4,0,0,0,.037.535.577.577,0,0,0,.391.112h2.482a.487.487,0,0,0,.332-.1.326.326,0,0,0,.107-.251A.342.342,0,0,0,250.838,123.162Z"
            transform="translate(-247.516 -120.197)"
          />
        </g>
      </g>
      <g transform="translate(21.285 15.633)">
        <g transform="translate(0 0)">
          <path
            class="a"
            d="M322.471,67.822a.594.594,0,0,0-.412-.128H319.8l2.113-2.359.2-.219.128-.155a.71.71,0,0,0,.086-.16.536.536,0,0,0,.037-.193.4.4,0,0,0-.2-.385,1.194,1.194,0,0,0-.562-.107h-2.654a.578.578,0,0,0-.391.118.4.4,0,0,0-.139.316q0,.273.182.332a1.717,1.717,0,0,0,.535.064h1.712q-.107.15-.278.353l-.444.5-.578.642-.69.765q-.358.4-.471.535a.5.5,0,0,0,.048.69.728.728,0,0,0,.492.144h3.119a.61.61,0,0,0,.428-.107.407.407,0,0,0,.139-.316A.428.428,0,0,0,322.471,67.822Z"
            transform="translate(-318.269 -64.112)"
          />
        </g>
      </g>
      <g transform="translate(0 12.158)">
        <path
          class="a"
          d="M23.641,28.351a.535.535,0,0,0-.589-.123A11.364,11.364,0,0,1,8.409,12.922a.535.535,0,0,0-.69-.722A12.444,12.444,0,1,0,23.737,28.945.535.535,0,0,0,23.641,28.351ZM12.454,35.093a11.38,11.38,0,0,1-5.537-21.32A12.267,12.267,0,0,0,6.275,17.7,12.45,12.45,0,0,0,22.132,29.678,11.422,11.422,0,0,1,12.454,35.093Z"
          transform="translate(0 -12.158)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Moon',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
