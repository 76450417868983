<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="243.8pt"
    height="240.6pt"
    viewBox="0 0 243.8 240.6"
    version="1.1"
  >
    <defs>
      <clipPath id="clip1">
        <path d="M 0 0 L 70 0 L 70 240.601563 L 0 240.601563 Z M 0 0 " />
      </clipPath>
      <clipPath id="clip2">
        <path d="M 26 0 L 91 0 L 91 240.601563 L 26 240.601563 Z M 26 0 " />
      </clipPath>
      <clipPath id="clip3">
        <path d="M 50 0 L 243.800781 0 L 243.800781 240 L 50 240 Z M 50 0 " />
      </clipPath>
    </defs>
    <g id="surface1">
      <g clip-path="url(#clip1)" clip-rule="nonzero">
        <path
          style="
            stroke: none;
            fill-rule: evenodd;
            fill: rgb(19.213867%, 55.664063%, 93.359375%);
            fill-opacity: 1;
          "
          d="M 0.00390625 -0.0664063 L 13.40625 -0.0664063 C 16.820313 6.078125 19.191406 12.734375 21.207031 19.449219 C 23.335938 26.546875 24.957031 33.789063 26.492188 41.039063 C 38.917969 98.496094 51.34375 155.957031 63.765625 213.417969 C 65.734375 222.460938 67.660156 231.515625 69.652344 240.554688 L 58.867188 240.554688 C 58.859375 239.855469 58.632813 239.1875 58.496094 238.511719 C 44.214844 172.519531 29.929688 106.53125 15.640625 40.542969 C 13.953125 32.632813 11.988281 24.753906 9.207031 17.152344 C 6.933594 11.039063 4.085938 5.058594 0.00390625 -0.0664063 Z M 0.00390625 -0.0664063 "
        />
      </g>
      <g clip-path="url(#clip2)" clip-rule="nonzero">
        <path
          style="
            stroke: none;
            fill-rule: evenodd;
            fill: rgb(14.109802%, 35.301208%, 73.631287%);
            fill-opacity: 1;
          "
          d="M 26.351563 -0.0664063 L 38.933594 -0.0664063 C 40.964844 11.429688 43.664063 22.789063 46.308594 34.15625 C 58.492188 90.5625 70.664063 146.96875 82.847656 203.375 C 85.53125 215.765625 88.1875 228.164063 90.886719 240.554688 L 80.261719 240.554688 C 77.953125 230.039063 75.714844 219.507813 73.425781 208.988281 C 61.214844 152.429688 49.003906 95.871094 36.78125 39.3125 C 35.558594 33.386719 34.019531 27.53125 32.617188 21.648438 C 30.785156 14.34375 29.046875 6.980469 26.351563 -0.0664063 Z M 26.351563 -0.0664063 "
        />
      </g>
      <g clip-path="url(#clip3)" clip-rule="nonzero">
        <path
          style="
            stroke: none;
            fill-rule: evenodd;
            fill: rgb(10.984802%, 27.43988%, 58.396912%);
            fill-opacity: 1;
          "
          d="M 50.984375 -0.0664063 L 63.71875 -0.0664063 C 63.273438 9.703125 64.710938 19.4375 66.621094 28.988281 C 67.269531 32.277344 68.054688 35.539063 68.796875 38.808594 C 81.890625 100.660156 94.984375 162.515625 108.078125 224.367188 C 105.769531 229.269531 103.410156 234.148438 101.109375 239.058594 C 99.882813 233.636719 98.761719 228.195313 97.578125 222.769531 C 87.328125 175.21875 77.066406 127.671875 66.820313 80.121094 C 62.960938 62.285156 59.164063 44.433594 55.253906 26.605469 C 53.382813 17.796875 51.695313 8.921875 50.984375 -0.0664063 Z M 226.964844 -0.0664063 L 243.816406 -0.0664063 C 238.644531 3.324219 233.714844 7.097656 229.222656 11.355469 C 218.886719 21.0625 210.539063 32.652344 202.941406 44.566406 C 194.777344 57.476563 187.414063 70.871094 180.230469 84.339844 C 179.980469 84.179688 179.734375 84.019531 179.488281 83.859375 C 180.296875 82.054688 181.175781 80.285156 182.003906 78.488281 C 188.535156 64.714844 195.101563 50.957031 202.234375 37.480469 C 206.046875 30.3125 210.019531 23.21875 214.429688 16.398438 C 218.183594 10.605469 222.277344 5.011719 226.964844 -0.0664063 Z M 226.964844 -0.0664063 "
        />
      </g>
      <path
        style="
          stroke: none;
          fill-rule: evenodd;
          fill: rgb(91.014099%, 37.255859%, 14.109802%);
          fill-opacity: 1;
        "
        d="M 76.054688 -0.0664063 L 89.238281 -0.0664063 C 87.984375 4.246094 87.515625 8.742188 87.433594 13.21875 C 87.339844 22.21875 88.65625 31.164063 90.34375 39.984375 C 100.714844 90.222656 111.132813 140.449219 121.519531 190.6875 C 121.757813 192.042969 122.164063 193.375 122.296875 194.746094 C 122.007813 195.664063 121.476563 196.484375 121.097656 197.367188 C 119.128906 201.441406 117.214844 205.546875 115.210938 209.605469 C 104.058594 156.320313 92.871094 103.046875 81.714844 49.769531 C 79.890625 41.015625 77.921875 32.285156 76.695313 23.421875 C 75.632813 15.65625 74.9375 7.734375 76.054688 -0.0664063 Z M 201.769531 -0.0664063 L 215.472656 -0.0664063 C 212.109375 6.203125 209.113281 12.65625 206.191406 19.140625 C 203.136719 25.945313 200.191406 32.796875 197.261719 39.65625 C 191.058594 54.222656 184.953125 68.832031 178.800781 83.417969 C 178.550781 83.25 178.308594 83.082031 178.0625 82.914063 C 181.992188 72.023438 185.9375 61.136719 189.699219 50.183594 C 192.453125 42.132813 195.097656 34.035156 197.300781 25.8125 C 199.546875 17.34375 201.328125 8.703125 201.769531 -0.0664063 Z M 201.769531 -0.0664063 "
      />
      <path
        style="
          stroke: none;
          fill-rule: evenodd;
          fill: rgb(55.664063%, 75.389099%, 53.709412%);
          fill-opacity: 1;
        "
        d="M 103.160156 -0.0664063 L 116.429688 -0.0664063 C 112.238281 5.339844 110.683594 12.285156 110.351563 18.996094 C 109.949219 28.273438 111.566406 37.480469 113.40625 46.535156 C 120.84375 84.476563 128.28125 122.414063 135.714844 160.359375 C 135.964844 161.867188 136.40625 163.351563 136.53125 164.875 C 136.148438 165.917969 135.585938 166.886719 135.132813 167.90625 C 133.230469 171.882813 131.339844 175.859375 129.433594 179.835938 C 120.671875 136.117188 111.964844 92.386719 103.210938 48.664063 C 102.441406 44.601563 101.519531 40.566406 100.828125 36.492188 C 100.058594 31.910156 99.539063 27.285156 99.371094 22.644531 C 99.167969 14.941406 99.90625 7.007813 103.160156 -0.0664063 Z M 180.066406 -0.0664063 L 192.191406 -0.0664063 C 193.023438 3.140625 193.351563 6.453125 193.4375 9.757813 C 193.554688 17.796875 192.234375 25.769531 190.628906 33.621094 C 188.796875 42.410156 186.472656 51.089844 184.054688 59.730469 C 181.90625 67.324219 179.671875 74.890625 177.386719 82.445313 C 177.128906 82.265625 176.878906 82.089844 176.628906 81.914063 C 179.484375 70.671875 182.015625 59.335938 183.777344 47.871094 C 185.144531 38.648438 186.101563 29.296875 185.515625 19.96875 C 185.050781 13.035156 183.664063 5.96875 180.066406 -0.0664063 Z M 180.066406 -0.0664063 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Vrbo',
}
</script>

<style scoped></style>
