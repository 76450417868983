<template>
  <div class="text-center">
    <v-expansion-panels v-if="services" :key="codesKey" accordion>
      <v-expansion-panel
        v-for="(section, sectionKey) in filteredServices"
        :key="sectionKey"
        @click="resetCustomCodes(sectionKey)"
      >
        <v-expansion-panel-header class="text-capitalize">{{
          SERVICE_LABELS[sectionKey] || sectionKey
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="(item, itemKey) in section" :key="itemKey">
            <div class="d-flex align-center">
              <div class="flex-grow-1">
                <v-text-field
                  v-if="typeof item === 'string'"
                  v-model="services[sectionKey][itemKey]"
                  class="text-capitalize mb-4"
                  :label="SERVICE_LABELS[itemKey] || itemKey"
                  outlined
                  hide-details
                  dense
                  @focus="dirty = true"
                />
              </div>
              <div class="d-flex align-center ml-2 mb-4">
                <v-checkbox
                  v-if="sectionKey === 'door_codes' && typeof item === 'string'"
                  v-model="services.public_door_codes[itemKey]"
                  hide-details
                  dense
                  class="mt-0 pt-0"
                  :title="
                    $t(
                      'When selected, this code will be accessible in the guest portal'
                    )
                  "
                  @change="dirty = true"
                />
                <confirmation-modal
                  v-if="
                    typeof item === 'string' &&
                    !(
                      SERVICES[sectionKey] &&
                      SERVICES[sectionKey].includes(itemKey)
                    )
                  "
                  text="Are you sure you want to delete this Service Code?"
                  @action="deleteCustomCode(sectionKey, itemKey)"
                >
                  <template #activator="slotData">
                    <v-btn small text fab v-on="slotData.on">
                      <v-icon small>$trash</v-icon>
                    </v-btn>
                  </template>
                </confirmation-modal>
              </div>
            </div>
            <div v-if="typeof item === 'object'">
              <div>{{ itemKey }}</div>
              <div v-for="(subItem, subItemKey) in item" :key="subItemKey">
                <v-text-field
                  v-model="services[sectionKey][itemKey][subItemKey]"
                  class="text-capitalize mb-4"
                  :label="SERVICE_LABELS[itemKey] || subItemKey"
                  outlined
                  hide-details
                  dense
                  @focus="dirty = true"
                />
              </div>
            </div>
          </div>
          <custom-service-code
            v-if="showCustomCodeFields"
            :custom-code="customCode"
            :section-key="sectionKey"
            @touched="dirty = true"
          />
          <v-btn
            small
            text
            color="success"
            class="bolder"
            @click="showCustomCodeFields = true"
            ><v-icon x-small class="pr-1" color="success">fas fa-plus</v-icon>
            Add Field</v-btn
          >
          <v-btn
            v-if="showCustomCodeFields"
            depressed
            color="success"
            small
            @click="saveClickHandler"
            >Add</v-btn
          >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn
      v-if="true"
      class="w-100 mt-4"
      :disabled="!dirty"
      color="success"
      depressed
      @click="saveClickHandler"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
import {
  SERVICES,
  SERVICE_LABELS,
  initialServices,
} from 'components/service-codes/config'
import { omit, pickBy } from 'lodash/fp'
import CustomServiceCode from 'components/service-codes/custom-service-code.vue'
import ConfirmationModal from 'components/modals/confirmation-modal'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  mixins: [PermissionsMixin],
  components: { CustomServiceCode, ConfirmationModal },
  props: ['listing'],
  data() {
    return {
      SERVICES,
      SERVICE_LABELS,
      showCustomCodeFields: false,
      dirty: false,
      customCode: {},
      codesKey: 0,
      currentSectionKey: null,
      confirmDeleteCustomCode: false,
    }
  },
  computed: {
    services() {
      let sections = this.listing ? initialServices(this.listing) : null
      if (sections && !this.isAdmin) {
        sections = omit('master_codes', sections)
      }
      return sections
    },
    filteredServices() {
      return pickBy(
        (_, key) => key !== 'public_door_codes',
        this.services || {}
      )
    },
  },
  methods: {
    saveClickHandler() {
      if (this.customCode.key) {
        this.services[this.currentSectionKey][this.customCode.key] =
          this.customCode.value
      }
      this.customCode = {}
      this.showCustomCodeFields = false
      this.dirty = false
      this.$emit('save', this.services)
    },
    deleteCustomCode(sectionKey, itemKey) {
      delete this.services[sectionKey][itemKey]
      this.customCode = {}
      this.dirty = false
      this.codesKey++
      this.$emit('save', this.services)
    },
    resetCustomCodes(sectionKey) {
      this.customCode = {}
      this.showCustomCodeFields = false
      this.currentSectionKey = sectionKey
      this.dirty = false
    },
  },
}
</script>

<style></style>
