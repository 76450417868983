<template>
  <svg
    id="layout"
    xmlns="http://www.w3.org/2000/svg"
    width="30.133"
    height="24"
    viewBox="0 0 30.133 24"
  >
    <path
      id="Path_5621"
      data-name="Path 5621"
      d="M29.69,0H.442A.442.442,0,0,0,0,.442V23.558A.442.442,0,0,0,.442,24H29.69a.442.442,0,0,0,.442-.442V.442A.442.442,0,0,0,29.69,0ZM.885,23.115V3.715H5.661a.442.442,0,0,0,0-.885H.885V.885H29.248V2.83H7.784a.442.442,0,0,0,0,.885H29.248v19.4Zm0,0"
    />
    <path
      id="Path_5622"
      data-name="Path 5622"
      d="M441.744,24.489a.442.442,0,1,1-.442-.442A.442.442,0,0,1,441.744,24.489Zm0,0"
      transform="translate(-414.914 -22.632)"
    />
    <path
      id="Path_5623"
      data-name="Path 5623"
      d="M417.7,24.489a.442.442,0,1,1-.442-.442A.442.442,0,0,1,417.7,24.489Zm0,0"
      transform="translate(-392.282 -22.632)"
    />
    <path
      id="Path_5624"
      data-name="Path 5624"
      d="M465.791,24.489a.442.442,0,1,1-.442-.442A.442.442,0,0,1,465.791,24.489Zm0,0"
      transform="translate(-437.545 -22.632)"
    />
    <path
      id="Path_5625"
      data-name="Path 5625"
      d="M59.858,96.188H48.536a.442.442,0,0,0-.442.442v14.624a.442.442,0,0,0,.442.442H59.858a.442.442,0,0,0,.442-.442V96.63A.442.442,0,0,0,59.858,96.188Zm-.442,14.624H48.978V97.072H59.416Zm0,0"
      transform="translate(-45.263 -90.527)"
    />
    <path
      id="Path_5626"
      data-name="Path 5626"
      d="M290.9,96.188h-9.907a.442.442,0,0,0-.442.442v5.189a.442.442,0,0,0,.442.442H290.9a.442.442,0,0,0,.442-.442V96.63A.442.442,0,0,0,290.9,96.188Zm-.442,5.189h-9.022v-4.3h9.022Zm0,0"
      transform="translate(-264.036 -90.527)"
    />
    <path
      id="Path_5627"
      data-name="Path 5627"
      d="M290.9,224.438h-9.907a.442.442,0,0,0-.442.442v7.076a.442.442,0,0,0,.442.442H290.9a.442.442,0,0,0,.442-.442V224.88A.442.442,0,0,0,290.9,224.438Zm-.442,7.076h-9.022v-6.192h9.022Zm0,0"
      transform="translate(-264.036 -211.229)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Layout',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
