<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="800px">
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>

    <v-card>
      <v-card-title class="text-h6 pr-3">
        {{ title }}
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editFlow.name"
                  label="Flow Name"
                  prepend-inner-icon="mdi-checkbook"
                  outlined
                  :rules="[required]"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editFlow.division"
                  label="Division"
                  prepend-inner-icon="mdi-google-circles-communities"
                  outlined
                  :disabled="true"
                  :rules="[required]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">Cancel</v-btn>
        <v-btn color="primary" @click="submit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { cloneDeep } from 'lodash'

export default {
  name: 'LtFlowModal',
  mixins: [formRules, CommonFunctions, PermissionsMixin],
  props: ['title', 'flow', 'division'],
  data() {
    return {
      dialog: false,
      editFlow: {},
    }
  },
  computed: {
    inEditMode() {
      return !!this.editFlow.id
    },
    initialCreateValue() {
      return cloneDeep({ division: this.division })
    },
  },
  watch: {
    dialog(value) {
      this.$refs.form && this.$refs.form.reset()
      if (value) this.editFlow = cloneDeep(this.flow || this.initialCreateValue)
      else this.editFlow = {}
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.update()
        this.closeDialog()
      }
    },
    update() {
      const action = this.editFlow.id
        ? 'updateListingTaskFlow'
        : 'createListingTaskFlow'
      this.$store.dispatch(
        action,
        cloneDeep({
          flow: this.editFlow,
          division: this.division,
        })
      )
    },
  },
}
</script>

<style scoped></style>
