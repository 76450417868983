<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.25"
    height="22"
    viewBox="0 0 19.25 22"
  >
    <path
      class="a"
      d="M0,19.938A2.063,2.063,0,0,0,2.063,22H17.188a2.063,2.063,0,0,0,2.063-2.062V8.25H0Zm13.75-8.422A.517.517,0,0,1,14.266,11h1.719a.517.517,0,0,1,.516.516v1.719a.517.517,0,0,1-.516.516H14.266a.517.517,0,0,1-.516-.516Zm0,5.5a.517.517,0,0,1,.516-.516h1.719a.517.517,0,0,1,.516.516v1.719a.517.517,0,0,1-.516.516H14.266a.517.517,0,0,1-.516-.516Zm-5.5-5.5A.517.517,0,0,1,8.766,11h1.719a.517.517,0,0,1,.516.516v1.719a.517.517,0,0,1-.516.516H8.766a.517.517,0,0,1-.516-.516Zm0,5.5a.517.517,0,0,1,.516-.516h1.719a.517.517,0,0,1,.516.516v1.719a.517.517,0,0,1-.516.516H8.766a.517.517,0,0,1-.516-.516Zm-5.5-5.5A.517.517,0,0,1,3.266,11H4.984a.517.517,0,0,1,.516.516v1.719a.517.517,0,0,1-.516.516H3.266a.517.517,0,0,1-.516-.516Zm0,5.5a.517.517,0,0,1,.516-.516H4.984a.517.517,0,0,1,.516.516v1.719a.517.517,0,0,1-.516.516H3.266a.517.517,0,0,1-.516-.516ZM17.188,2.75H15.125V.688A.69.69,0,0,0,14.438,0H13.063a.69.69,0,0,0-.687.688V2.75h-5.5V.688A.69.69,0,0,0,6.188,0H4.813a.69.69,0,0,0-.687.688V2.75H2.063A2.063,2.063,0,0,0,0,4.813V6.875H19.25V4.813A2.063,2.063,0,0,0,17.188,2.75Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalendarFill',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
