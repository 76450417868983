var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-relative flex text-center"},[_c('h2',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.listingName)?_c('span',{staticClass:"small-text ml-2"},[_vm._v("("+_vm._s(_vm.listingName)+")")]):_vm._e()]),_c('p',{staticClass:"text-caption mb-2"},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]),_c('div',{staticClass:"d-flex align-center close-button"},[(_vm.expenseId && !_vm.hideActions)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","target":"_blank","to":_vm.addressLinkTo,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,false,1586995485)},[_c('span',[_vm._v("Open In New Tab")])]):_vm._e(),(_vm.expenseId && !_vm.hideActions)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('copy-clipboard',{attrs:{"color":"info","text":_vm.addressLink,"small":false}})],1)]}}],null,false,886915365)},[_c('span',[_vm._v("Copy Link To Clipboard")])]):_vm._e(),(
        _vm.deleteEnabled &&
        _vm.expenseId &&
        (_vm.isAdmin || _vm.isPropertyManager) &&
        !_vm.canDeleteFinancialData(_vm.expense.paid_at, _vm.currentUser)
      )?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":""},on:{"click":_vm.deleteExpense}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,3625610872)},[_c('span',[_vm._v("Delete")])]):_vm._e(),(
        _vm.editEnabled &&
        _vm.expenseId &&
        (_vm.isAdmin || _vm.isPropertyManager) &&
        !_vm.canDeleteFinancialData(_vm.expense.paid_at, _vm.currentUser)
      )?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","icon":""},on:{"click":_vm.updateEditExpense}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,4287787913)},[_c('span',[_vm._v("Edit")])]):_vm._e(),_c('v-btn',{staticClass:"ml-5",attrs:{"icon":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("close")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }