<template>
  <v-avatar v-if="image" :size="size || 25" left>
    <v-img :src="image" />
  </v-avatar>
  <v-avatar v-else :color="color || roleColor(role)" :size="size || 25" left>
    <span class="white--text" :class="contentClass">{{
      initialsFromFullName(name)
    }}</span>
  </v-avatar>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  name: 'UserAvatar',
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['image', 'name', 'role', 'size', 'color', 'contentClass'],
}
</script>

<style scoped></style>
