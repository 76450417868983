<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.638"
    height="27.007"
    viewBox="0 0 28.638 27.007"
  >
    <g transform="translate(0.003 -0.379)">
      <path
        d="M99.364,322.332a3.411,3.411,0,1,0,3.411,3.411A3.411,3.411,0,0,0,99.364,322.332Zm0,5.568a2.157,2.157,0,1,1,2.157-2.157A2.157,2.157,0,0,1,99.364,327.9Zm0,0"
        transform="translate(-89.94 -301.768)"
      />
      <path
        d="M28.014,6.251H5.875L5.424,3.724A3.768,3.768,0,0,0,2.37.677L.734.388A.627.627,0,1,0,.515,1.624l1.643.288A2.508,2.508,0,0,1,4.189,3.943L6.408,16.464a3.129,3.129,0,0,0,3.085,2.589H22.659a3.127,3.127,0,0,0,3-2.226l2.947-9.768a.656.656,0,0,0-.094-.558A.635.635,0,0,0,28.014,6.251ZM24.465,16.458a1.868,1.868,0,0,1-1.8,1.336H9.5a1.872,1.872,0,0,1-1.85-1.555L6.1,7.5H27.173Zm0,0"
        transform="translate(0)"
      />
      <path
        d="M309.364,322.332a3.411,3.411,0,1,0,3.411,3.411A3.411,3.411,0,0,0,309.364,322.332Zm0,5.568a2.157,2.157,0,1,1,2.157-2.157A2.157,2.157,0,0,1,309.364,327.9Zm0,0"
        transform="translate(-286.774 -301.768)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ShoppingCart',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
