<template>
  <v-dialog v-model="videoSrc" persistent max-width="750px">
    <v-flex xs12>
      <v-card class="pa-4">
        <v-layout column align-baseline justify-center>
          <v-flex offset-xs10 xs-2 offset-sm11 sm-1>
            <v-btn
              fab
              dark
              small
              color="teal"
              class="float-right"
              @click="closeVideo"
            >
              <v-icon dark>close</v-icon>
            </v-btn>
          </v-flex>
          <v-flex offset-sm3>
            <v-icon
              v-if="!isInvestor"
              class="mb-2"
              @click.prevent="downloadItem(videoSrc)"
              >system_update</v-icon
            >
            <v-icon
              v-if="managementRoles && videoSrc"
              v-clipboard:success="onCopy"
              v-clipboard:copy="videoSrc"
              class="mr-3 br-1 pr-3"
              >link</v-icon
            >
            <video-player
              v-if="isVideo(videoSrc)"
              :src="$store.state.chosenVideo"
              :width="370"
              :height="370"
            ></video-player>
            <div v-if="!isVideo(videoSrc)">
              <span class="grey-text small-text"
                >* Should have been a video but the user uploaded an image</span
              >
              <img
                key="fdss"
                style="width: 100%"
                :src="videoSrc"
                class="image-design"
              />
            </div>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-dialog>
</template>
<script>
import VideoPlayer from './video-player.vue'

import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  components: {
    VideoPlayer,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  computed: {
    videoSrc() {
      return this.$store.state.chosenVideo
    },
    listingTask() {
      return this.$store.state.currentlistingTask || {}
    },
  },
  methods: {
    closeVideo() {
      this.$store.commit('updateChosenVideo', null)
    },
  },
}
</script>
