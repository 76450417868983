<template>
  <div class="light300 py-4 px-12 d-flex">
    <div class="flex-grow-1">
      <catalog-item-inline-editor class="" :listing-task="task" />
      <div>
        <editable-text-field
          large="true"
          class="pt-2"
          :placeholder="$t('Add description')"
          type="textarea"
          :value="task.description"
          @onupdate="$emit('change-description', $event)"
        />
      </div>
    </div>
    <div class="d-flex align-center">
      <clock-in-modal class="mr-2" :task="task" />
      <lt-status :listing-task="task" :hide-done="true" small="true" />
      <span class="ml-2"
        ><lt-menu-actions :readonly="readonly" :listing-task="task"
      /></span>
    </div>
  </div>
</template>

<script>
import EditableTextField from '@/components/form-fields/editable-text-field.vue'
import catalogItemInlineEditor from '../catalog-item-inline-editor.vue'
import ClockInModal from '@/components/clock-in-modal.vue'
import LtStatus from 'components/listing-tasks/lt-status.vue'
import LtMenuActions from 'components/listing-tasks/single-listing-task/lt-menu-actions.vue'

export default {
  components: {
    catalogItemInlineEditor,
    EditableTextField,
    ClockInModal,
    LtStatus,
    LtMenuActions,
  },
  props: ['task'],
  computed: {
    readonly() {
      return this.task.status === 'Owner Approval'
    },
  },
}
</script>

<style></style>
