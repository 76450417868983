<template>
  <div v-if="board" class="full h-100 d-flex flex-column">
    <div>
      <v-tabs
        v-model="paramsTab"
        icons-and-text
        class="tabs-menu main-background"
      >
        <v-tab href="#progress">
          PROGRESS
          <v-icon>mdi-format-list-checks</v-icon>
        </v-tab>
        <v-tab href="#budget">
          BUDGET
          <v-icon>mdi-cash-sync</v-icon>
        </v-tab>
      </v-tabs>
    </div>
    <v-divider />

    <v-tabs-items v-model="paramsTab" class="full" touchless>
      <v-tab-item value="progress" class="full">
        <taskim
          :board-id="board.id.toString()"
          :project-id="currentProjectId"
        />
      </v-tab-item>
      <v-tab-item
        v-if="board.project && board.project.estimate"
        value="budget"
        class="full"
      >
        <project-budget
          :project="board.project"
          :estimate="board.project.estimate"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CalendarMixin from 'components/mixins/calendar-mixin'
import Taskim from 'components/taskim/taskim'
import ProjectBudget from 'components/projects/project-budget'

export default {
  name: 'Project',
  components: { ProjectBudget, Taskim },
  mixins: [CalendarMixin],
  props: ['projectId'],
  data() {
    return {
      board: null,
    }
  },
  computed: {
    currentProjectId() {
      return this.projectId || this.$route.params.projectId
    },
  },
  mounted() {
    this.fetchAndSet()
  },
  methods: {
    ...mapActions('taskim', ['getProjectBoardSections']),
    async fetchAndSet() {
      const res = await this.getProjectBoardSections({
        id: this.currentProjectId,
      })
      this.board = res.board
    },
  },
  watch: {
    currentProjectId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchAndSet()
      }
    },
  },
}
</script>

<style scoped></style>
