import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getCheckinSettings() {
      return axios.get('/api/settings/checkin-settings')
    },
    async updateCheckinSettings(_store, payload) {
      return axios.post('/api/settings/checkin-settings', payload)
    },
  },
}
