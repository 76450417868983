<template>
  <v-container>
    <v-progress-linear
      v-show="listingLoading"
      color="secondary"
      style="margin: 0"
      :indeterminate="true"
    ></v-progress-linear>
    <v-row wrap justify-center>
      <v-col sm="6" cols="12" class="bolder text-h5 center-text">
        <h3 class="purple-icon headline-font">
          Check ins
          <v-icon small class="icon alt purple-icon mr-1"
            >fas fa-door-open</v-icon
          >
        </h3>
        <v-layout v-if="!isEmpty(ciList) && cleanerMode" wrap>
          <v-flex v-for="(item, index) in ciList" :key="index" xs12>
            <v-layout wrap class="comp-wrapper mt-1">
              <v-flex xs3 class="br-1">
                <v-layout v-if="item.guest_name" column class="small-text">
                  <v-flex class="purple-icon">
                    {{ item.guest_name }}
                  </v-flex>
                  <v-flex v-if="item.parking_pass_paid">
                    <v-icon class="green-health mr-2">local_parking</v-icon>
                    Parking pass, paid
                  </v-flex>
                  <v-flex v-if="item.early_check_in_paid">
                    <v-icon class="purple-icon mr-2">doorbell</v-icon>
                    Early checkin, paid
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs9>
                <v-layout
                  wrap
                  full-width
                  justify-center
                  align-center
                  center-text
                  bolder
                  grey--text
                  small-text
                >
                  <v-flex xs6>
                    <v-layout class="br-1" column>
                      <v-flex v-if="item.from" class="small-text">
                        {{ parseDate(item.from) }}
                      </v-flex>
                      <v-flex>
                        {{ item.listing_nickname }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6>
                    <v-btn
                      v-if="cleanerMode && item.paid"
                      :disabled="true"
                      color="blue darken-1 small-text"
                      class="small-text"
                      text
                      >Code: {{ item.code || '---' }}</v-btn
                    >
                    <v-flex v-if="!item.paid" class="red-warn mt--1"
                      >Payment Problem
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <div v-if="!isEmpty(ciList) && !cleanerMode">
          <div
            v-for="(item, index) in ciList"
            :key="index"
            class="comp-wrapper pa-0 mt-1"
          >
            <v-row no-gutters class="align-center small-text pr-3">
              <v-col cols="12" md="10" class="align-center d-flex">
                <v-col cols="3" class="small-text">
                  <a target="_blank" :href="item.link">PMS link</a>
                  <v-tooltip v-if="item.issues.length > 0" top>
                    <template #activator="{ on }">
                      <v-icon
                        :class="
                          issuesAreDone(item.issues)
                            ? 'green-health'
                            : 'red-warn'
                        "
                        v-on="on"
                        >construction
                      </v-icon>
                    </template>
                    <v-layout wrap style="min-width: 400px">
                      <v-flex xs12 class="bolder bb-1 center-text" mt-1 mb-1>
                        <h3>{{ $t('Today service calls') }}</h3>
                      </v-flex>
                      <v-flex xs12 class="bolder bb-1 center-text" mt-2 mb-1>
                        <v-layout wrap class="center-text">
                          <v-flex xs3 class="pr-1 br-1">Starting at</v-flex>
                          <v-flex xs2 class="pr-1 pl-1 br-1">Priority</v-flex>
                          <v-flex xs2 class="pr-1 pl-1 br-1">Status</v-flex>
                          <v-flex xs5 class="pl-1">Desription</v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex
                        v-for="(issue, index) in item.issues"
                        :key="index"
                        class="bb-1"
                        xs12
                      >
                        <v-layout wrap class="center-text">
                          <v-flex xs3 class="pr-1 br-1"
                            >{{ hourDesc(issue) }} ({{ issue.planned_duration }}
                            hour)
                          </v-flex>
                          <v-flex xs2 class="pr-1 pl-1 br-1"
                            >{{ issue.priority }}
                          </v-flex>
                          <v-flex xs2 class="pr-1 pl-1 br-1"
                            >{{ issue.status }}
                          </v-flex>
                          <v-flex xs5 class="pl-1"
                            >{{ issue.task_type }} -
                            {{ issue.description }}
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-tooltip>
                  <v-flex xs12>
                    <v-icon
                      small
                      class="mr-2 purple-icon"
                      style="cursor: pointer"
                    >
                      open_in_browser
                    </v-icon>
                    <span
                      :class="
                        item.pre_check_in_status === 'New'
                          ? 'red-warn'
                          : 'green-text'
                      "
                      >{{
                        item.pre_check_in_status === 'New'
                          ? 'Not filled'
                          : 'Filled'
                      }}</span
                    >
                    <v-tooltip
                      v-if="
                        item.pre_check_in && item.pre_check_in_status !== 'New'
                      "
                      top
                    >
                      <template #activator="{ on }">
                        <v-icon small class="icon ml-1 alt help-icon" v-on="on"
                          >fas fa-question-circle
                        </v-icon>
                      </template>
                      <v-layout wrap style="min-width: 200px">
                        <pre-check-in-card
                          :pre-check-in="item.pre_check_in"
                        ></pre-check-in-card>
                      </v-layout>
                    </v-tooltip>
                  </v-flex>
                </v-col>
                <v-col v-if="item.guest_name" class="small-text" cols="3">
                  {{ item.guest_name }}
                </v-col>
                <v-col cols="6" class="column">
                  <v-layout wrap>
                    <v-flex v-if="item.from" class="small-text" xs5 sm5>
                      {{ parseDate(item.from) }}
                    </v-flex>
                    <v-flex v-if="item.from" class="small-text" xs2 sm2>
                      <v-icon class="cyan-icon">arrow_right_alt</v-icon>
                    </v-flex>
                    <v-flex v-if="item.from" class="small-text" xs5 sm5>
                      {{ parseDate(item.to) }}
                    </v-flex>
                  </v-layout>
                  <v-flex>
                    {{ item.listing_nickname }}
                  </v-flex>
                </v-col>
              </v-col>
              <v-col cols="12" md="2">
                <v-btn
                  v-if="!cleanerMode"
                  :disabled="item.sent_message"
                  color="blue darken-1 small-text"
                  class="text-caption"
                  small
                  block
                  outlined
                  @click="sendInfo(item.reservation_id)"
                  >Send info
                </v-btn>
                <v-flex v-if="!item.paid" class="red-warn mt--1"
                  >Payment Problem
                </v-flex>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="!cleanerMode"
        sm="6"
        cols="12"
        class="bolder text-h5 center-text"
      >
        <h3 class="cyan-icon headline-font">
          Check outs
          <v-icon small class="icon alt stats-icon mr-1"
            >fas fa-door-closed</v-icon
          >
        </h3>
        <v-list v-if="!isEmpty(coList)" class="pa-0">
          <v-list-item
            v-for="(item, index) in coList"
            :key="index"
            class="comp-wrapper mt-1"
          >
            <v-list-item-action class="small-text mr-2">
              <v-layout wrap>
                <v-flex xs12>
                  <a target="_blank" class="pr-2" :href="item.link">PMS link</a>
                </v-flex>
                <v-flex xs12>
                  <v-icon
                    small
                    class="mr-2 purple-icon"
                    style="cursor: pointer"
                  >
                    open_in_browser
                  </v-icon>
                  <span
                    :class="
                      item.pre_check_in_status === 'New'
                        ? 'red-warn'
                        : 'green-text'
                    "
                    >{{
                      item.pre_check_in_status === 'New'
                        ? 'Not filled'
                        : 'Filled'
                    }}</span
                  >
                </v-flex>
              </v-layout>
            </v-list-item-action>

            <v-list-item-content class="pointer full-width align-center">
              <v-layout
                wrap
                full-width
                justify-center
                align-center
                center-text
                bolder
                grey--text
                small-text
              >
                <v-flex v-if="item.guest_name" xs2 sm4 class="br-1">
                  <v-layout column class="small-text">
                    <v-flex class="purple-icon">
                      {{ item.guest_name }}
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-layout class="br-1" column>
                  <v-layout wrap>
                    <v-flex v-if="item.from" class="small-text" xs5 sm5>
                      {{ parseDate(item.from) }}
                    </v-flex>
                    <v-flex v-if="item.from" class="small-text" xs2 sm2>
                      <v-icon class="cyan-icon">arrow_right_alt</v-icon>
                    </v-flex>
                    <v-flex v-if="item.from" class="small-text" xs5 sm5>
                      {{ parseDate(item.to) }}
                    </v-flex>
                  </v-layout>
                  <v-flex>
                    {{ item.listing_nickname }}
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-flex
        v-if="!cleanerMode"
        sm12
        mt-3
        xs12
        :class="$vuetify.breakpoint.xs ? '' : 'ml-2 '"
        class="bolder text-h5 center-text comp-wrapper"
      >
        <h3 class="red-warn headline-font">
          Suspicious reservations <v-icon class="red-warn">report</v-icon>
        </h3>
        <span v-if="isEmpty(susList)" mt-3 class="small-text grey-text"
          >No suspicious reservations found for now</span
        >
        <v-layout v-if="!isEmpty(susList) && !cleanerMode" wrap align-center>
          <v-flex v-for="(item, index) in susList" :key="index" xs12>
            <v-layout wrap class="comp-wrapper mt-1">
              <v-flex xs3 sm2 class="small-text br-1">
                <v-layout wrap>
                  <v-flex xs12>
                    <a target="_blank" class="pr-2" :href="item.link"
                      >PMS link</a
                    >
                  </v-flex>
                  <v-flex xs12>
                    <v-icon
                      small
                      class="mr-2 purple-icon"
                      style="cursor: pointer"
                    >
                      open_in_browser
                    </v-icon>
                    <span
                      :class="
                        item.pre_check_in_status === 'New'
                          ? 'red-warn'
                          : 'green-text'
                      "
                      >{{
                        item.pre_check_in_status === 'New'
                          ? 'Not filled'
                          : 'Filled'
                      }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs5 sm6 class="br-1">
                <v-layout v-if="item.get_guest_name" column class="small-text">
                  <v-flex class="purple-icon">
                    {{ item.get_guest_name }}
                  </v-flex>
                  <v-flex>
                    {{ item.get_listing_nickname }}
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4 sm4>
                <v-layout
                  wrap
                  full-width
                  justify-center
                  align-center
                  center-text
                  bolder
                  grey--text
                  small-text
                >
                  <v-flex xs6>
                    <v-layout align-center wrap>
                      <v-flex v-if="item.check_in" class="small-text" xs5 sm5>
                        {{ parseDate(item.check_in) }}
                      </v-flex>
                      <v-flex v-if="item.check_in" class="small-text" xs2 sm2>
                        <v-icon class="cyan-icon">arrow_right_alt</v-icon>
                      </v-flex>
                      <v-flex v-if="item.check_out" class="small-text" xs5 sm5>
                        {{ parseDate(item.check_out) }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6> </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import PreCheckInCard from './pre-check-in/pre-check-in-card.vue'

export default {
  components: {
    PreCheckInCard,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['city', 'showBeds', 'cleanerMode'],
  data: function () {
    return {}
  },
  computed: {
    todayChecks() {
      return this.$store.state.todayChecks
    },
    ciList() {
      if (this.todayChecks) {
        return this.todayChecks.check_ins_list
      } else {
        return []
      }
    },
    susList() {
      if (this.todayChecks) {
        return this.todayChecks.suspicious
      } else {
        return []
      }
    },
    coList() {
      if (this.todayChecks) {
        return this.todayChecks.check_outs_list
      } else {
        return []
      }
    },
  },
  mounted() {
    this.$store.dispatch('getTodayChecks')
  },
  methods: {
    issuesAreDone(issues) {
      let res = true
      issues.forEach(issue => {
        if (issue.status !== 'Done') {
          res = false
        }
      })
      return res
    },
    hourDesc(issue) {
      let hour = this.$moment.utc(issue.scheduled_at).hours()
      if (hour !== 0) {
        return this.parseDateJustTime(issue.scheduled_at)
      } else {
        return 'No hour scheduled'
      }
    },
    sendInfo(resID) {
      const userConfirm = confirm(
        'Are you sure you want to send the entry info?'
      )
      if (userConfirm) {
        this.$store.commit('updateListingLoading', true)
        axios
          .post(`/api/reservations/${resID}/send-guest-info`)
          .then(() => {
            this.$store.dispatch('getTodayChecks')
          })
          .catch(error => {
            alert(error)
          })
      }
    },
  },
}
</script>
