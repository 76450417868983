<template>
  <v-dialog v-model="show" max-width="600">
    <template #activator="{ on, attrs }">
      <v-btn icon small v-bind="attrs" v-on="on" @click="getAiSummary">
        <v-icon small>mdi-text-box-search</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 lighten-3">
        <v-icon left>mdi-text-box-search</v-icon>
        Conversation Summary
      </v-card-title>
      <v-card-text class="pt-4 pa-0">
        <div v-if="loading" class="text-center py-4">
          <v-progress-circular
            indeterminate
            color="primary"
            size="32"
          ></v-progress-circular>
          <div class="mt-2 text-body-2 grey--text">Generating summary...</div>
        </div>
        <div v-else-if="summary" class="pa-4">
          <div class="mb-6">
            <div
              class="d-flex align-center mb-3 primary--text font-weight-medium"
            >
              <v-icon small color="primary" class="mr-2">
                mdi-format-list-bulleted
              </v-icon>
              Main Topics Covered
            </div>
            <div class="rounded-lg pa-4 grey lighten-4">
              <div
                v-for="(topic, index) in formattedTopics"
                :key="index"
                class="d-flex align-start mb-2"
              >
                <span class="bullet-point">
                  <v-icon x-small color="primary">mdi-circle</v-icon>
                </span>
                <span class="font-weight-medium">{{ topic }}</span>
              </div>
            </div>
          </div>

          <v-divider class="mb-6"></v-divider>

          <div class="mb-6">
            <div
              class="d-flex align-center mb-3 primary--text font-weight-medium"
            >
              <v-icon small color="primary" class="mr-2">
                mdi-timeline-clock
              </v-icon>
              Timeline of Key Events
            </div>
            <div class="rounded-lg pa-4 grey lighten-4">
              <div
                v-for="(event, index) in formattedEvents"
                :key="index"
                class="mb-4"
              >
                <div class="d-flex align-start position-relative">
                  <span class="bullet-point">
                    <v-icon x-small color="primary">mdi-circle</v-icon>
                  </span>
                  <div class="flex-grow-1">
                    <span class="font-weight-medium">{{ event.text }}</span>
                    <div class="timestamp text-caption">
                      <v-icon x-small color="grey" class="mr-1">
                        mdi-clock-outline
                      </v-icon>
                      {{ event.date }}
                      <span v-if="event.time" class="ml-1">
                        at {{ event.time }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <v-divider class="mb-6"></v-divider>

          <div class="mb-6">
            <div
              class="d-flex align-center mb-3 primary--text font-weight-medium"
            >
              <v-icon small color="primary" class="mr-2">
                mdi-flag-checkered
              </v-icon>
              Action Items & Next Steps
            </div>
            <div class="rounded-lg pa-4 grey lighten-4">
              <div
                v-for="(item, index) in formattedActions"
                :key="index"
                class="d-flex align-start mb-2"
              >
                <span class="bullet-point">{{ index + 1 }}.</span>
                <span class="font-weight-medium">{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center py-4 text-body-2 grey--text">
          No summary available
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="show = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'ConversationSummaryDialog',
  mixins: [FormattersMixin, DeviceMixin],
  props: {
    conversationId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      summary: null,
    }
  },
  computed: {
    formattedTopics() {
      if (!this.summary?.main_topics) return []
      return this.summary.main_topics
    },
    formattedEvents() {
      if (!this.summary?.timeline_of_key_events) return []
      return this.summary.timeline_of_key_events.map(event => ({
        date: this.parseDate(event.timestamp, 'MMM D, YYYY'),
        time: this.parseDate(event.timestamp, 'h:mm A'),
        text: event.event,
      }))
    },
    formattedActions() {
      if (!this.summary?.action_items_and_next_steps) return []
      return this.summary.action_items_and_next_steps
    },
  },
  methods: {
    async getAiSummary() {
      if (!this.conversationId) return
      this.loading = true
      this.summary = null
      try {
        const response = await this.$store.dispatch(
          'conversations/getAiSummary',
          {
            conversationId: this.conversationId,
          }
        )
        this.summary = response.data.summary
      } catch (error) {
        console.error('Error getting AI summary:', error)
        this.$store.dispatch(
          'showError',
          'Unable to generate conversation summary'
        )
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.v-dialog__content {
  align-items: flex-start;
  padding-top: 100px;
}

.v-card-text {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  scrollbar-width: thin;
}

.v-card-text::-webkit-scrollbar {
  width: 6px;
}

.v-card-text::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.v-card-text::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.v-card-text::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.bullet-point {
  min-width: 24px;
  margin-right: 12px;
  color: var(--v-primary-base);
  display: flex;
  align-items: center;
  justify-content: center;
}

.timestamp {
  color: #9e9e9e;
  text-align: right;
  margin-top: 4px;
  font-size: 0.75rem;
}

.position-relative {
  position: relative;
}
</style>
