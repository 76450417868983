<template>
  <v-flex xs12 class="listing-picker-new">
    <v-autocomplete
      v-model="selectedListing"
      :items="listings"
      :label="label || $t('Select Listing')"
      item-value="id"
      :search-input.sync="search"
      :rules="rules"
      :item-text="itemText || 'address'"
      class="listing-select"
      :clearable="clearable && !disabled && !readonly"
      :disabled="disabled"
      :readonly="readonly"
      :outlined="outlined !== false"
      :dense="dense !== false"
      :hide-details="hideDetails === true"
      @change:clear="listingChanged"
      @change="listingChanged"
    >
      <template #selection="{ item }">
        <span class="ellipsis-1">
          {{ item.stage === 'setup' ? 'Setup - ' : ''
          }}{{ item[itemText || 'address'] }}</span
        >
      </template>
      <template #item="{ item }" class="listing-picker">
        <img
          :src="item.picture"
          class="pr-2 mb-1"
          style="height: 60px; width: 60px"
          contain
        />
        <div class="d-flex align-start flex-column">
          <div class="font-weight-medium">
            {{ item.stage === 'setup' ? 'Setup - ' : ''
            }}{{ item[itemText || 'address'] }}
          </div>
          <span
            v-if="hideTitle !== true"
            class="text-caption"
            style="font-size: 10px; color: grey"
          >
            {{ item.title }}
          </span>
        </div>
      </template>
    </v-autocomplete>
  </v-flex>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  props: [
    'label',
    'preSelected',
    'showUnits',
    'clearable',
    'hotelId',
    'noPrependIcon',
    'filterChange',
    'disabled',
    'rules',
    'nonSetup',
    'itemText',
    'hideTitle',
    'readonly',
    'outlined',
    'dense',
    'hideDetails',
  ],
  data() {
    return {
      selectedListing: null,
      search: null,
    }
  },
  mounted() {
    if (this.preSelected) {
      this.selectedListing = this.getListingById(this.preSelected)
    }
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    listings() {
      let items = this.listingsPicker
      if (this.nonSetup) {
        items = this.listingsPicker.filter(l => l.stage !== 'setup')
      }
      if (this.showUnits) {
        return items
      }
      if (this.hotelId) {
        return items.filter(
          l => l.hotel_id === this.hotelId && !l.is_multi_unit
        )
      }
      return items.filter(l => l.listing_type !== 'unit')
    },
  },
  methods: {
    listingChanged() {
      this.$emit('update:pre-selected', this.selectedListing)
      this.filterChange && this.filterChange(this.selectedListing)
    },
  },
  watch: {
    preSelected(newVal) {
      if (!newVal) {
        this.selectedListing = null
      } else {
        this.selectedListing = this.getListingById(newVal)
      }
    },
    listingsPicker: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (
          (!this.selectedListing || !this.selectedListing.id) &&
          this.preSelected &&
          newVal &&
          newVal.length &&
          (!oldVal || !oldVal.length)
        ) {
          this.selectedListing = this.getListingById(this.preSelected)
        }
      },
    },
  },
}
</script>
