import { mapGetters } from 'vuex'
import axios from '@/axios/config'

export default {
  data() {
    return {
      creating: false,
    }
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        const payload = this.fields.reduce((obj, item) => {
          obj[item.key] = item.value
          return obj
        }, {})
        await this.updateIntegration({ payload })
        await this.$store.dispatch('getSettings')
      }
    },
    async syncData() {
      await axios.post(`/api/settings/integrations/sync`)
      await this.$store.dispatch('getConfiguration')
    },
    async syncPrices() {
      await axios.get(`api/listings/sync-listings-prices`)
    },
    async updateIntegration(payload) {
      return axios.patch('/api/settings/integrations', {
        integration_id: this.integration.id,
        ...payload,
      })
    },
    async disconnect() {
      this.creating = true
      await axios.delete(`/api/settings/integrations/${this.integration.id}`)
      await this.$store.dispatch('getSettings')
      this.creating = false
    },
    async createIntegration() {
      this.creating = true
      if (this.integration.id) {
        await this.updateIntegration({ active: true })
      } else {
        await axios.post('/api/settings/integrations', {
          marketplace_id: this.marketplaceId,
        })
      }
      await this.$store.dispatch('getSettings')

      this.creating = false
    },
  },
  computed: {
    ...mapGetters(['integrations', 'marketplaceIntegrations']),
    integrationName() {
      return this.$route.params.name
    },
    integration() {
      return this.integrations.find(i => i.name === this.integrationName) || {}
    },
    marketplaceId() {
      return this.marketplaceIntegrations.find(
        m => m.name.toLowerCase() === this.integrationName
      ).id
    },
    isPms() {
      return this.integration.category === 'pms'
    },
    hasIntegration() {
      return this.integration.id
    },
    isActive() {
      return this.integration.id && this.integration.active
    },
    fields() {
      return this.integration.creds || []
    },
  },
}
