<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.747"
    height="21"
    viewBox="0 0 16.747 21"
  >
    <g transform="translate(-16.75 -4.75)">
      <path
        class="a"
        d="M33.187,6.906H28.96V5.06a.31.31,0,0,0-.31-.31H21.6a.31.31,0,0,0-.31.31V6.906H17.06a.31.31,0,0,0-.31.31v2.24a.31.31,0,0,0,.31.31h.748l.847,15.69a.31.31,0,0,0,.31.293H31.282a.31.31,0,0,0,.31-.293l.847-15.69h.748a.31.31,0,0,0,.31-.31V7.216a.31.31,0,0,0-.31-.31ZM21.907,5.37H28.34V6.906H21.907ZM30.988,25.13H19.259l-.83-15.363H31.818ZM32.877,9.146H17.37V7.526H32.877Z"
      />
      <path
        class="a"
        d="M45.227,59.005a.31.31,0,0,0,.31-.31V51.227a.31.31,0,1,0-.62,0V58.7a.31.31,0,0,0,.31.31Z"
        transform="translate(-23.175 -37.986)"
      />
      <path
        class="a"
        d="M62.56,59.005a.31.31,0,0,0,.31-.31V51.227a.31.31,0,1,0-.62,0V58.7a.31.31,0,0,0,.31.31Z"
        transform="translate(-37.437 -37.986)"
      />
      <path
        class="a"
        d="M79.893,59.005a.31.31,0,0,0,.31-.31V51.227a.31.31,0,0,0-.62,0V58.7a.31.31,0,0,0,.31.31Z"
        transform="translate(-51.698 -37.986)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Garbage',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
