<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="filters"
    :label="label"
    :multiple="!single"
    dense
    :menu-props="{
      closeOnClick: true,
      closeOnContentClick: false,
    }"
    hide-details
    outlined
    :items="items"
    :readonly="readonly"
    @blur="$emit('blur')"
    @change="filterChanged"
  >
    <template #append-item>
      <div class="sticky-button flex-column">
        <v-divider />
        <div class="d-flex justify-space-between align-center pa-3">
          <v-btn color="black" text x-small @click="clearFilters">clear</v-btn>
          <v-btn
            color="primary"
            elevation="0"
            class="px-4 black--text"
            small
            @click="selectAll"
            >Select All
          </v-btn>
        </div>
      </div>
    </template>
    <template #selection="{ item }">
      <v-chip small class="ma-1"
        >{{ selectionFunc ? selectionFunc(item) : item }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'FiltersSelect',
  props: [
    'label',
    'items',
    'value',
    'selectionFunc',
    'change',
    'readonly',
    'single',
  ],
  data() {
    return {
      filters: this.value || [],
    }
  },
  watch: {
    value(val) {
      this.filters = val
    },
  },
  methods: {
    clearFilters() {
      this.filters = []
      this.filterChanged()
    },
    selectAll() {
      this.filters = this.items.map(i => (typeof i === 'string' ? i : i.value))
      this.filterChanged()
    },
    filterChanged() {
      this.$emit('update:value', this.filters)
      this.$emit('change', this.filters)
      this.$nextTick(() => this.$refs.autocomplete.updateMenuDimensions())
    },
  },
}
</script>

<style scoped>
.sticky-button {
  position: sticky;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: white;
}
</style>
