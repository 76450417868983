<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="60"
          :width="5"
          :value="round(occupancyTwoWeeks * 100)"
          color="primary"
        >
          <span class="text-caption font-weight-light black--text">{{
            isNaN(round(occupancyTwoWeeks * 100))
              ? 'N/A'
              : `${round(occupancyTwoWeeks * 100)}%`
          }}</span>
        </v-progress-circular>
        <p class="mb-0">
          <span class="text-caption secondary--text">{{
            $t('Next 14 days')
          }}</span>
        </p>
      </v-col>
      <v-col cols="4" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="60"
          :width="5"
          :value="round(occupancyMonth * 100)"
          color="primary"
        >
          <span class="font-weight-light text-caption black--text">{{
            isNaN(round(occupancyMonth * 100))
              ? 'N/A'
              : `${round(occupancyMonth * 100)}%`
          }}</span>
        </v-progress-circular>
        <p class="mb-0">
          <span class="text-caption secondary--text">{{
            $t('Next 30 days')
          }}</span>
        </p>
      </v-col>
      <v-col cols="4" class="text-center">
        <v-progress-circular
          :rotate="-90"
          :size="60"
          :width="5"
          :value="round(occupancyThreeMonth * 100)"
          color="primary"
        >
          <span class="text-caption font-weight-light black--text">{{
            isNaN(round(occupancyThreeMonth * 100))
              ? 'N/A'
              : `${round(occupancyThreeMonth * 100)}%`
          }}</span>
        </v-progress-circular>
        <p class="mb-0">
          <span class="text-caption secondary--text">{{
            $t('Next 90 days')
          }}</span>
        </p>
      </v-col>
    </v-row>
    <v-divider v-if="!hideScore"></v-divider>
    <div v-if="!hideScore">
      <div class="d-flex align-center mt-2 mb-1">
        <span class="text-caption secondary--text">{{ $t('Score') }}</span>
        <v-tooltip min-width="300" top>
          <template #activator="{ on }">
            <v-icon class="ml-2" x-small v-on="on">
              fas fa-question-circle
            </v-icon>
          </template>
          <p class="mb-0">{{ $t('Your Listing Performance') }}</p>
          <v-divider class="my-1"></v-divider>
          <v-row>
            <v-col cols="4">
              <span>Distance</span>
              <h4>14 Days</h4>
              <h4>30 Days</h4>
              <h4>90 Days</h4>
            </v-col>
            <v-col cols="4" class="text-no-wrap">
              <span>Ideal</span>
              <h4>70% booked</h4>
              <h4>50% booked</h4>
              <h4>32% booked</h4>
            </v-col>
            <v-col cols="4" class="text-no-wrap">
              <span>This Listing</span>
              <h4>
                {{
                  isNaN(round(occupancyTwoWeeks * 100))
                    ? 'N/A'
                    : `${round(occupancyTwoWeeks * 100)}% booked`
                }}
              </h4>
              <h4>
                {{
                  isNaN(round(occupancyMonth * 100))
                    ? 'N/A'
                    : `${round(occupancyMonth * 100)}% booked`
                }}
              </h4>
              <h4>
                {{
                  isNaN(round(occupancyThreeMonth * 100))
                    ? 'N/A'
                    : `${round(occupancyThreeMonth * 100)}% booked`
                }}
              </h4>
            </v-col>
          </v-row>
        </v-tooltip>
      </div>
      <span class="text-caption">{{ $t('Your Listing Performance') }}</span>
      <div class="d-flex align-center">
        <v-progress-linear
          color="info"
          :value="round(score * 100)"
          height="12"
          rounded
        ></v-progress-linear>
        <span class="text-caption ml-2">{{ round(score * 100) || 0 }}%</span>
      </div>
      <div
        v-if="backScore"
        class="health-text bt-1 mt-1"
        :class="backScoreClass"
      >
        {{ round(backScore * 100) }}%
      </div>
    </div>
  </v-container>
</template>

<script>
import round from 'lodash/round'

export default {
  props: [
    'score',
    'occupancyTwoWeeks',
    'occupancyMonth',
    'occupancyThreeMonth',
    'occupancyTwoWeeksBack',
    'occupancyMonthBack',
    'occupancyThreeMonthBack',
    'listing',
    'hideScore',
  ],
  computed: {
    backScore() {
      if (
        this.occupancyThreeMonthBack &&
        this.occupancyMonthBack &&
        this.occupancyTwoWeeksBack
      ) {
        return (
          (this.occupancyThreeMonthBack +
            this.occupancyMonthBack +
            this.occupancyTwoWeeksBack) /
          3
        )
      }
      return 0
    },
    backScoreClass() {
      if (this.backScore) {
        if (this.backScore > 0.8) {
          return 'green-health'
        } else if (this.backScore > 0.6) {
          return 'yellow-health'
        }
      }
      return 'red-health'
    },
    scoreClass: function () {
      if (this.score > 0.8) {
        return 'green-health'
      } else if (this.score > 0.6) {
        return 'yellow-health'
      } else {
        return 'red-health'
      }
    },
  },
  methods: {
    round: function (input) {
      return round(input, 1)
    },
  },
}
</script>
