<template>
  <v-sheet>
    <div class="d-flex justify-space-between align-center my-3">
      <div class="text-h6">{{ $t('Smart Rules') }}</div>
    </div>
    <v-card-text class="px-6">
      <v-row>
        <v-col cols="12" sm="6">
          <smart-rules-create-modal
            :listing="listing"
            @fetchRules="fetchRules"
          />
        </v-col>
      </v-row>
      <div v-for="smartRule in listingRules.smart_rules" :key="smartRule.id">
        <div>
          <div class="d-flex align-center">
            <p class="text-subtitle-2">
              {{ ruleHeading() }}
            </p>
            <p class="pl-3 text-subtitle-2">
              {{ smartRule.target_name }}
            </p>
            <p class="pl-3 text-subtitle-2">
              <v-icon small color="warning" @click="removeRule(smartRule)"
                >fas fa-minus-circle</v-icon
              >
            </p>
            <v-spacer />
          </div>
          <v-divider />
        </div>
      </div>
    </v-card-text>
  </v-sheet>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import SmartRulesCreateModal from 'components/smart-rules/smart-rules-create-modal.vue'

export default {
  name: 'ListingChannelAutomation',
  components: { SmartRulesCreateModal },
  mixins: [FormRules],
  props: ['listing'],
  data() {
    return {
      listingRules: [],
    }
  },
  async mounted() {
    this.fetchRules()
  },
  methods: {
    ruleHeading() {
      return `When this listing is booked, block the same dates for: `
    },
    async fetchRules() {
      this.listingRules = await this.$store.dispatch(
        'smartRule/getSmartRules',
        this.listing.id
      )
    },
    async removeRule(rule) {
      this.$store.commit('updateLoading', true)
      await this.$store
        .dispatch('smartRule/deleteSmartRule', {
          id: rule.id,
          listing_id: this.listing.id,
        })
        .finally(this.fetchRules(), this.$store.commit('updateLoading', false))
      this.fetchRules()
    },
  },
}
</script>

<style lang="scss" scoped></style>
