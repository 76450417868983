<template>
  <div>
    <form-dialog
      title="Please confirm the dismissal reason"
      max-width="380"
      @submit="confirmDismissal"
    >
      <template #activator="{ on }">
        <v-btn v-show="!dismissed" x-small depressed v-on="on">Dismiss</v-btn>
      </template>
      <v-textarea
        v-model="reason"
        rows="3"
        outlined
        :rules="[required]"
        dense
        label="Dismissal reason"
      />
    </form-dialog>
    <v-tooltip v-if="dismissed" bottom>
      <template #activator="{ on, attrs }">
        <span v-bind="attrs" class="purple-icon" v-on="on">
          <v-btn
            v-if="item.manual_dismiss && !item.bank_transaction_id"
            class="cyan-icon x-small-btn"
            fab
            x-small
            @click="undo"
          >
            <v-icon>undo</v-icon>
          </v-btn>
          Dismissed</span
        >
      </template>
      <span
        >{{ item.manual_dismiss_reason }} ({{
          nameById(item.dismissed_by_id)
        }})</span
      >
    </v-tooltip>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import FormRules from 'components/mixins/form-rules-mixin'
import FormDialog from 'components/common/form-dialog'

export default {
  components: { FormDialog },
  mixins: [CommonFunctions, FormRules],
  props: ['item', 'type', 'dismissed'],
  data: function () {
    return { reason: '', dialog: false }
  },
  methods: {
    async confirmDismissal() {
      const item = await this.$store.dispatch('dismissItem', {
        item: this.item,
        type: this.type,
        reason: this.reason,
      })
      this.$emit('after-action', item)
      this.dialog = false
    },
    async undo() {
      const item = await this.$store.dispatch('dismissItem', {
        item: this.item,
        type: this.type,
        undo: true,
      })
      this.$emit('after-action', item)
    },
  },
}
</script>
