<template>
  <v-card>
    <v-card-title>
      Listings<v-progress-circular
        v-show="listingLoading"
        class="ml-2"
        :size="20"
        indeterminate
        color="primary"
      />
    </v-card-title>
    <v-data-table :headers="headers" :items="items" class="elevation-1">
      <template #item="{ item }">
        <tr>
          <td class="text-sm-center">
            <v-btn
              :to="basePath(item.id)"
              target="_blank"
              dark
              right
              small
              fab
              class="purple-icon white"
            >
              <v-icon>search</v-icon>
            </v-btn>
          </td>
          <td class="text-sm-center">{{ item.nickname }}</td>
          <td class="text-sm-center">{{ item.bedrooms }}</td>
          <td class="text-sm-center">
            {{ item.occupancy.toLocaleString('en') }}
          </td>
          <td class="text-sm-center" :class="healthClass(item.health)">
            {{ (item.health * 100).toLocaleString('en') }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { sortBy, round } from 'lodash'
import { mapState } from 'vuex'

export default {
  props: ['viewPath', 'units'],
  data() {
    return {
      headers: [
        { text: 'Actions', align: 'center', sortable: false },
        { text: 'Unit', align: 'center', sortable: false },
        { text: 'Bed rooms', align: 'center', sortable: false },
        { text: 'Occupancy', align: 'center', sortable: false },
        { text: 'Health', align: 'center', sortable: false },
      ],
    }
  },
  computed: {
    ...mapState(['listingLoading']),
    items() {
      return sortBy(this.units, ['nickname'])
    },
  },
  methods: {
    basePath(id) {
      return this.viewPath
        ? `${this.viewPath}${id}`
        : `/dashboard/property/${id}/stats`
    },
    healthClass(health) {
      if (health > 0.8) {
        return 'green-health'
      }
      if (health > 0.6) {
        return 'yellow-health'
      }
      return 'red-health'
    },
  },
}
</script>
