<template>
  <div>
    <div
      v-if="edit"
      v-click-outside="clickOutside"
      class="d-flex text-capitalize align-center"
    >
      <catalog-quick-select
        v-model="listingTask.listing_task_catalog_item_id"
        style="flex: initial"
        :filter-change="quickPickChange"
      />
    </div>
    <div
      v-else
      class="pointer d-flex text-capitalize flex-wrap font-weight-medium text-body-2"
    >
      <div @click="edit = true">
        <span class="">{{ itemDivision }}</span>
        <span class="dark600--text mx-2">/</span>
        <span>{{ itemDepartment }}</span>
        <span v-if="itemSubTopic" class="dark600--text mx-2">/</span>

        <span>{{ itemSubTopic }}</span>
        <span v-if="itemDescription" class="dark600--text mx-2">/</span>

        <span>{{ itemDescription }}</span>
        <v-icon
          v-if="hasExtraCatalogInfo"
          small
          color="orange"
          class="pl-2"
          @click="showCatalogInfo"
          >fas fa-wrench</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
import CatalogQuickSelect from 'components/catalog-quick-select'
import get from 'lodash/fp/get'
export default {
  name: 'CatalogItemInlineEditor',
  components: { CatalogQuickSelect },
  props: ['listingTask'],
  data() {
    return {
      edit: false,
    }
  },
  computed: {
    itemDivision() {
      return get('listing_task_catalog_item.division', this.listingTask)
    },
    itemDepartment() {
      return get('listing_task_catalog_item.department', this.listingTask)
    },
    itemSubTopic() {
      return get('listing_task_catalog_item.sub_topic', this.listingTask)
    },
    itemDescription() {
      return (
        get('listing_task_catalog_item.description', this.listingTask) ||
        this.listingTask.listing_task_catalog_item_name
      )
    },
    hasExtraCatalogInfo() {
      const { listing_task_catalog_item } = this.listingTask
      if (listing_task_catalog_item) {
        return (
          listing_task_catalog_item.media.length ||
          listing_task_catalog_item.quick_fixes.length ||
          listing_task_catalog_item.possible_causes.length ||
          listing_task_catalog_item.needed_actions.length
        )
      }
      return false
    },
  },
  methods: {
    showCatalogInfo() {
      this.$store.commit('showModal', {
        name: 'CatalogItemInfoModal',
        props: { catalogItemId: this.listingTask.listing_task_catalog_item_id },
      })
    },
    quickPickChange() {
      this.$store.dispatch('updateListingTask', {
        id: this.listingTask.id,
        listing_task_catalog_item_id:
          this.listingTask.listing_task_catalog_item_id,
      })
    },
    clickOutside() {
      this.edit = false
    },
  },
}
</script>

<style scoped></style>
