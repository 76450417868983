<template>
  <div id="chekin-host-container"></div>
</template>

<script>
import { CHEKIN_SDK_CDN } from '@/consts'

export default {
  name: 'ChekinHostSdk',
  props: {
    housingId: {
      type: String,
      required: false,
    },
  },
  mounted() {
    const script = document.createElement('script')
    script.src =
      process.env.NODE_ENV === 'production'
        ? CHEKIN_SDK_CDN.PRODUCTION
        : CHEKIN_SDK_CDN.STAGING
    script.onload = () => {
      const api = new window.ChekinHousingsSDK()
      const defaultLanguage = this.$i18n.locale
      const initializeApi = () => {
        if (document.getElementById('chekin-host-container') && this.apiKey) {
          api.initialize({
            apiKey: this.apiKey,
            housingId: this.housingId,
            defaultLanguage: defaultLanguage,
            onPoliceAccountConnected: data =>
              console.log('onPoliceAccountConnected:', data),
            onStatAccountConnected: onStatAccountConnected =>
              console.log('Stat Account Connected!', onStatAccountConnected),
          })
          api.renderApp({ targetNode: 'chekin-host-container' })
        } else {
          setTimeout(initializeApi, 100)
        }
      }
      initializeApi()
    }
    document.head.appendChild(script)
  },
  computed: {
    apiKey() {
      return this.$store.state.app.configuration.chekin_host_api_key
    },
  },
}
</script>

<style scoped>
#chekin-host-container {
  width: 100%;
  height: 100vh;
}
</style>
