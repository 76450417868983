<template>
  <div class="full h-100 d-flex flex-column">
    <div>
      <v-tabs
        v-model="paramsTab"
        icons-and-text
        class="tabs-menu main-background"
      >
        <v-tab href="#list">
          ESTIMATES
          <v-icon>mdi-format-list-text</v-icon>
        </v-tab>
        <v-tab href="#builder">
          BUILDER
          <v-icon>mdi-format-list-checks</v-icon>
        </v-tab>
        <v-tab href="#config">
          CONFIG
          <v-icon>mdi-cogs</v-icon>
        </v-tab>
      </v-tabs>
    </div>
    <v-divider />

    <v-tabs-items v-model="paramsTab" class="full" touchless>
      <v-tab-item value="list" class="full">
        <estimates-table v-if="paramsTab === 'list'" />
      </v-tab-item>
      <v-tab-item value="builder" class="full">
        <estimates-builder v-if="paramsTab === 'builder'" />
      </v-tab-item>
      <v-tab-item value="config" class="full">
        <estimates-config v-if="paramsTab === 'config'" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import EstimatesConfig from 'components/estimates/estimates-config'
import EstimatesBuilder from 'components/estimates/estimates-builder'
import EstimatesTable from 'components/estimates/estimates-table'

export default {
  name: 'EstimatesPage',
  components: { EstimatesTable, EstimatesBuilder, EstimatesConfig },
}
</script>

<style scoped></style>
