<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="57.997"
    height="58"
    viewBox="0 0 57.997 58"
  >
    <g id="Page-1" transform="translate(0 -0.001)">
      <g
        id="_083---Money-Bag"
        data-name="083---Money-Bag"
        transform="translate(0 0.001)"
      >
        <path
          id="Shape"
          d="M26.533,34.216a.967.967,0,1,0,1.933,0c0-3.2-2.958-5.84-6.766-6.229V26.967a.967.967,0,0,0-1.933,0v1.021C15.958,28.374,13,31.019,13,34.216s2.958,5.84,6.766,6.229v8.688c-2.738-.346-4.833-2.133-4.833-4.284a.967.967,0,1,0-1.933,0c0,3.2,2.958,5.84,6.766,6.229V52.1a.967.967,0,1,0,1.933,0V51.078c3.808-.387,6.766-3.031,6.766-6.229s-2.958-5.84-6.766-6.229V29.932C24.438,30.278,26.533,32.066,26.533,34.216Zm-11.6,0c0-2.151,2.095-3.938,4.833-4.284V38.5C17.028,38.154,14.933,36.367,14.933,34.216Zm11.6,10.633c0,2.151-2.095,3.938-4.833,4.284V40.565C24.438,40.911,26.533,42.7,26.533,44.849Z"
          transform="translate(-0.434 -0.869)"
          fill="#fff"
        />
        <path
          id="Shape-2"
          data-name="Shape"
          d="M3.48,30.17a.967.967,0,0,0-1.284.47,23.585,23.585,0,0,0-2.2,10C0,52.822,6.829,59,20.3,59a31.257,31.257,0,0,0,8.078-.931.967.967,0,1,0-.5-1.868,29.8,29.8,0,0,1-7.581.866c-12.358,0-18.366-5.375-18.366-16.432a21.376,21.376,0,0,1,2.017-9.183A.967.967,0,0,0,3.48,30.17Z"
          transform="translate(0 -1.005)"
          fill="#fff"
        />
        <path
          id="Shape-3"
          data-name="Shape"
          d="M56.165,25H34.9A2.9,2.9,0,0,0,32,27.9V55.932a2.9,2.9,0,0,0,2.9,2.9H56.165a2.9,2.9,0,0,0,2.9-2.9V27.9A2.9,2.9,0,0,0,56.165,25Zm.967,30.932a.967.967,0,0,1-.967.967H34.9a.967.967,0,0,1-.967-.967V27.9a.967.967,0,0,1,.967-.967H56.165a.967.967,0,0,1,.967.967Z"
          transform="translate(-1.068 -0.835)"
          fill="#fff"
        />
        <path
          id="Shape-4"
          data-name="Shape"
          d="M38.933,37.733H52.466A1.933,1.933,0,0,0,54.4,35.8V31.933A1.933,1.933,0,0,0,52.466,30H38.933A1.933,1.933,0,0,0,37,31.933V35.8A1.933,1.933,0,0,0,38.933,37.733Zm0-5.8H52.466V35.8H38.933Z"
          transform="translate(-1.235 -1.002)"
          fill="#fff"
        />
        <path
          id="Shape-5"
          data-name="Shape"
          d="M40.866,41h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,1,0,0-1.933Z"
          transform="translate(-1.235 -1.37)"
          fill="#fff"
        />
        <path
          id="Shape-6"
          data-name="Shape"
          d="M40.866,45h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,1,0,0-1.933Z"
          transform="translate(-1.235 -1.503)"
          fill="#fff"
        />
        <path
          id="Shape-7"
          data-name="Shape"
          d="M40.866,49h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,1,0,0-1.933Z"
          transform="translate(-1.235 -1.637)"
          fill="#fff"
        />
        <path
          id="Shape-8"
          data-name="Shape"
          d="M40.866,53h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,1,0,0-1.933Z"
          transform="translate(-1.235 -1.77)"
          fill="#fff"
        />
        <path
          id="Shape-9"
          data-name="Shape"
          d="M47.866,41h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,1,0,0-1.933Z"
          transform="translate(-1.469 -1.37)"
          fill="#fff"
        />
        <path
          id="Shape-10"
          data-name="Shape"
          d="M47.866,45h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,1,0,0-1.933Z"
          transform="translate(-1.469 -1.503)"
          fill="#fff"
        />
        <path
          id="Shape-11"
          data-name="Shape"
          d="M47.866,49h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,1,0,0-1.933Z"
          transform="translate(-1.469 -1.637)"
          fill="#fff"
        />
        <path
          id="Shape-12"
          data-name="Shape"
          d="M47.866,53h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,1,0,0-1.933Z"
          transform="translate(-1.469 -1.77)"
          fill="#fff"
        />
        <path
          id="Shape-13"
          data-name="Shape"
          d="M54.866,41h-2.9a.967.967,0,1,0,0,1.933h2.9a.967.967,0,0,0,0-1.933Z"
          transform="translate(-1.703 -1.37)"
          fill="#fff"
        />
        <path
          id="Shape-14"
          data-name="Shape"
          d="M54.866,45h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,0,0,0-1.933Z"
          transform="translate(-1.703 -1.503)"
          fill="#fff"
        />
        <path
          id="Shape-15"
          data-name="Shape"
          d="M54.866,49h-2.9a.967.967,0,1,0,0,1.933h2.9a.967.967,0,0,0,0-1.933Z"
          transform="translate(-1.703 -1.637)"
          fill="#fff"
        />
        <path
          id="Shape-16"
          data-name="Shape"
          d="M54.866,53h-2.9a.967.967,0,0,0,0,1.933h2.9a.967.967,0,0,0,0-1.933Z"
          transform="translate(-1.703 -1.77)"
          fill="#fff"
        />
        <path
          id="Shape-17"
          data-name="Shape"
          d="M6.289,1.221C3.744,3.552,8.216,10.744,10.851,14.5h-.526a3.366,3.366,0,0,0-2.394.988,3.407,3.407,0,0,0-.989,2.4,3.373,3.373,0,0,0,1.7,2.914,45.527,45.527,0,0,0-3.184,3.755A.967.967,0,0,0,7,25.712a46.712,46.712,0,0,1,3.866-4.446H30.624a3.383,3.383,0,1,0,0-6.766H30.1C32.732,10.744,37.2,3.553,34.661,1.221c-1.17-1.071-2.533-.29-3.734.393A7.456,7.456,0,0,1,27.241,2.9a3.745,3.745,0,0,1-2.756-1.218,5.627,5.627,0,0,0-8.023,0A3.745,3.745,0,0,1,13.7,2.9a7.425,7.425,0,0,1-3.683-1.286C8.818.929,7.456.151,6.289,1.221ZM31.652,16.856a1.45,1.45,0,0,1-1.028,2.476h-20.3a1.45,1.45,0,1,1,0-2.9h20.3A1.46,1.46,0,0,1,31.652,16.856ZM13.7,4.833A5.627,5.627,0,0,0,17.714,3.15a3.725,3.725,0,0,1,5.51,0,5.631,5.631,0,0,0,4.017,1.683,9.261,9.261,0,0,0,4.648-1.541,4.042,4.042,0,0,1,1.468-.647c.992.909-1.63,6.388-5.637,11.854H25.308V9.666a.967.967,0,0,0-1.933,0V14.5h-5.8V9.666a.967.967,0,0,0-1.933,0V14.5H13.233C9.225,9.034,6.6,3.554,7.609,2.631a4.8,4.8,0,0,1,1.45.661A9.242,9.242,0,0,0,13.7,4.833Z"
          transform="translate(-0.176 -0.001)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Equity',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
