<template>
  <v-card>
    <v-card-title class="text-h5 align-center bb-1">
      <v-icon class="pr-2" color="secondary">mdi-credit-card-outline</v-icon>
      Payment Actions for {{ contractor.name }}
      <v-spacer />
      <v-btn icon @click="onClose">
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-3 text-body-1">
      <p class="mt-3 mb-8">
        Selected {{ selectedIds.size }} tasks with total balance of
        {{ totalAmount }}
      </p>
      <v-divider />

      <div v-if="natchaSupport" class="mt-10">
        <p class="d-flex justify-space-between align-center">
          <span>1. Download payment file</span>
          <v-btn
            class="text-capitalize"
            color="secondary"
            elevation="0"
            @click="downloadPaymentFile"
          >
            Download Payment File
          </v-btn>
        </p>
        <p class="d-flex justify-space-between align-center">
          <span>
            2. Upload it to Chase.com and wait for processing confirmation
          </span>
        </p>
        <div>
          <span>3. Mark as processing</span>
          <div class="d-flex align-center pt-5">
            <div class="flex-fill">
              <v-text-field
                v-model="confirmationCode"
                readonly
                :disabled="!confirmationCode"
                label="Confirmation Code"
                prepend-inner-icon="search"
                outlined
                dense
                hide-details
              />
            </div>
            <div class="ml-5">
              <ConfirmationModal
                text="Are you sure ?"
                @action="markAsProcessing"
              >
                <template #activator="{ on }">
                  <v-btn
                    class="text-capitalize"
                    color="primary"
                    elevation="0"
                    :disabled="!markAsProcessingEnabled"
                    v-on="on"
                  >
                    Mark as processing
                  </v-btn>
                </template>
              </ConfirmationModal>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-divider />

    <div class="justify-space-between d-flex pa-3">
      <v-btn depressed @click="onClose">Cancel</v-btn>
      <v-btn v-if="!natchaSupport" depressed color="primary" @click="payManual"
        >PAY</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import ConfirmationModal from 'components/modals/confirmation-modal'
import axios from '@/axios/config'
import moment from 'moment-timezone'

export default {
  name: 'ContractorsPaymentsModal',
  components: { ConfirmationModal },
  props: [
    'contractor',
    'dateRange',
    'selectedIds',
    'totalAmount',
    'afterAction',
    'natchaSupport',
    'afterFileDownload',
  ],
  data: function () {
    return {
      markAsProcessingEnabled: false,
      confirmationCode: '',
      inCallToMarkAsProcessing: false,
    }
  },
  methods: {
    ...mapActions([
      'downloadContractorPaymentFile',
      'markContractorPaymentAsProcessing',
    ]),
    onClose() {
      this.$store.commit('hideModal')
    },
    async downloadPaymentFile() {
      const fileName = await this.downloadContractorPaymentFile({
        id: this.contractor.id,
        name: this.contractor.name,
      })
      this.afterFileDownload && this.afterFileDownload(fileName)
      this.confirmationCode = fileName
      this.markAsProcessingEnabled = true
    },
    async payManual() {
      const date = moment.utc().format('YYYY-MM-DD HH-mm-ss')
      const confirmationCode = `Manual- ${this.contractor.name.substring(
        0,
        15
      )} - ${date}`
      const { transaction_id } = await this.markContractorPaymentAsProcessing({
        id: this.contractor.id,
        confirmation_code: confirmationCode,
        transaction_type: 'manual',
        from: this.dateRange.from,
        to: this.dateRange.to,
      })
      await axios.post(`/api/ach-transactions/${transaction_id}/approve`, {})
      this.onClose()
    },
    markAsProcessing() {
      if (!this.inCallToMarkAsProcessing) {
        this.inCallToMarkAsProcessing = true
        this.markContractorPaymentAsProcessing({
          id: this.contractor.id,
          confirmation_code: this.confirmationCode,
          from: this.dateRange.from,
          to: this.dateRange.to,
        })
          .then(res => {
            this.onClose()
            this.afterAction && this.afterAction(res)
          })
          .finally(() => {
            this.inCallToMarkAsProcessing = false
          })
      }
    },
  },
}
</script>

<style scoped></style>
