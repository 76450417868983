<template>
  <v-col>
    <LtDailyDaySummary></LtDailyDaySummary>
    <v-col>
      <v-row class="wrap mt-3 justify-center align-center">
        <v-col cols="4">
          <v-text-field
            v-model="searchTerm"
            flat
            label="Search by nick..."
            append-icon="search"
            solo-inverted
            hide-details
            style="border: 1px grey solid"
            class="mb-4"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            required
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="parsedDateFilter"
                label="Check-in from"
                prepend-icon="event"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="dateFilter"
              @change="saveDateFilter"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="3">
          <v-select
            v-model="statusFilter"
            :label="$t('Status')"
            :items="statusItems"
            item-text="display_name"
            item-value="id"
            multiple
            clearable
          />
        </v-col>
        <v-col cols="3">
          <v-switch
            v-model="onlyWithConflicts"
            mt-2
            label="Only conflicts"
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
    <v-card>
      <v-card-title>
        <v-progress-circular
          v-show="listingLoading"
          :size="20"
          class="ml-2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items-per-page="30"
        :items="filteredItems"
        class="elevation-1"
      >
        <template #item="{ item }">
          <daily-row :item="item"></daily-row>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import filter from 'lodash/filter'
import DailyRow from './daily-row'
import LtDailyDaySummary from './lt-daily-day-summary'
export default {
  components: {
    LtDailyDaySummary,
    DailyRow,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [],
  data: function () {
    return {
      statusFilter: null,
      dateFilter: new Date().toISOString().substr(0, 10),
      searchTerm: '',
      showModal: false,
      menu: null,
      statusItems: [
        'Check-in and Checkout today',
        'Checkout today',
        'Check-in today',
        'Free today',
        'Occupied',
      ],
      onlyWithConflicts: false,
    }
  },
  computed: {
    headers() {
      const today = this.$moment.utc(this.dateFilter)
      const before2 = today.clone().subtract(2, 'days')
      const before = today.clone().subtract(1, 'days')
      const after = today.clone().add(1, 'days')
      const after2 = today.clone().add(2, 'days')

      let cols = [
        { text: 'Listing', align: 'center', sortable: false },
        { text: this.parseDate(before2), align: 'center', sortable: false },
        { text: this.parseDate(before), align: 'center', sortable: false },
        { text: this.parseDate(today), align: 'center', sortable: false },
        { text: this.parseDate(after), align: 'center', sortable: false },
        { text: this.parseDate(after2), align: 'center', sortable: false },
      ]
      return cols
    },
    parsedDateFilter() {
      const parsedDate = this.$moment.utc(this.dateFilter)
      if (parsedDate) {
        return parsedDate.format('YYYY/MM/DD')
      }
      return null
    },
    filteredItems() {
      let searchFilter = filter(
        this.$store.state.dailySchedule,
        item =>
          item.nickname &&
          item.nickname.toLowerCase().includes(this.searchTerm.toLowerCase())
      )
      if (this.onlyWithConflicts) {
        searchFilter = filter(searchFilter, item => item.at_date.in_conflict)
      }
      if (this.statusFilter && this.statusFilter.length > 0) {
        searchFilter = filter(searchFilter, item =>
          this.statusFilter.includes(item.at_date.availability_text)
        )
      }
      return searchFilter
    },
  },
  mounted() {
    this.$store.dispatch('getDailySchedule', {
      date: this.parsedDateFilter,
    })
  },
  methods: {
    saveDateFilter(date) {
      this.$refs.menu.save(date)
      if (this.dateFilter) {
        this.$store.dispatch('getDailySchedule', {
          date: this.parsedDateFilter,
        })
      }
    },
  },
}
</script>
