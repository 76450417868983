var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"xs12":"","wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"comp-wrapper",attrs:{"xs8":"","mt-3":"","mb-3":""}},[_c('v-text-field',{staticClass:"mt-3 mb-3",staticStyle:{"background-color":"#7ebac0a1","border":"1px grey solid"},attrs:{"flat":"","label":"Search...","append-icon":"search","solo-inverted":"","hide-details":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('br'),(
        (_vm.isAdmin ||
          _vm.isInvestor ||
          _vm.isPropertyManager ||
          _vm.isCommunicationManager) &&
        !_vm.loading
      )?_c('property-picker',{attrs:{"not-top-header":true,"after-change":_vm.listingChanged}}):_vm._e()],1),(_vm.$store.state.listingInfoItems)?_c('v-flex',{staticClass:"comp-wrapper",attrs:{"xs12":"","mt-2":"","mb-1":""}},[_c('div',[(!_vm.loading)?_c('info-items-panel',{attrs:{"ext-search-term":_vm.searchTerm,"listing-id":_vm.selectedListingID}}):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }