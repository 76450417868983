<template>
  <v-container v-if="city" row fluid class="comp-wrapper">
    <v-layout wrap>
      <v-flex xs12 class="bolder text-h5 center-text">
        {{ city.name }}
      </v-flex>

      <v-flex v-if="city.host_info" xs12>
        Hosts info <br />
        {{ city.host_info.hosts }}
      </v-flex>
      <v-flex v-if="city.rental_counts" xs12>
        Rental counts <br />
        {{ city.rental_counts }}
      </v-flex>
      <v-flex v-if="city.host_info" xs12>
        Rental activity <br />
        {{ city.rental_activity }}
      </v-flex>
      <v-flex v-if="city.revenue_per_bed" xs12 class="comp-wrapper">
        Revenue per bed<br />
        <v-layout v-for="(val, key) in city.revenue_per_bed" :key="key" wrap>
          <v-flex v-if="!showBeds || showBeds === key">
            {{ key }} beds: <br />
            <v-layout wrap class="center-text" justify-center>
              <v-flex xs4 class="br-1">
                50:
                <span class="red-warn bolder">{{
                  calcTotal(val.calendar_months, '50').toLocaleString('en')
                }}</span>
              </v-flex>
              <v-flex xs4 class="br-1">
                75:
                <span class="yellow-health bolder">{{
                  calcTotal(val.calendar_months, '75').toLocaleString('en')
                }}</span>
              </v-flex>
              <v-flex xs4>
                90:
                <span class="green-health bolder">{{
                  calcTotal(val.calendar_months, '90').toLocaleString('en')
                }}</span>
              </v-flex>
            </v-layout>
            <v-layout wrap class="bb-1 mb-1 mt-1">
              <v-flex
                v-for="(monthData, index) in val.calendar_months"
                :key="index"
                xs12
                sm3
                class="comp-wrapper"
              >
                <span class="purple-icon bolder center-text">{{
                  monthData.our_revenue
                }}</span>
                {{ monthName(monthData.month) }} <br />
                <span class="yellow-health mr-1 bolder">
                  50% : {{ monthData.percentiles['50'] }}
                </span>
                <span class="cyan-icon mr-1 bolder">
                  75% : {{ monthData.percentiles['75'] }}
                </span>
                <span class="green-health bolder">
                  90% : {{ monthData.percentiles['90'] }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  components: {},
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['city', 'showBeds'],
  data: function () {
    return {}
  },

  computed: {},
  methods: {
    calcTotal(monthsData, precentile) {
      let res = 0
      monthsData.forEach(month => {
        res += month.percentiles[precentile]
      })
      return res
    },
  },
}
</script>
