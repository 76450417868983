<template>
  <v-btn
    :loading="loading"
    color="info"
    depressed
    :disabled="loading"
    @click="addCard"
    ><v-icon left>mdi mdi-credit-card</v-icon>
    {{ computedLast4 ? `Update Card (${computedLast4})` : 'Add card' }}</v-btn
  >
</template>

<script>
import axios from '@/axios/config'
export default {
  name: 'AddCreditCard',
  props: ['reservationId', 'tenantId', 'last4'],
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    config() {
      return this.$store.state.app.configuration
    },
    computedLast4() {
      return this.tenantId
        ? this.last4
        : this.config.payment_method && this.config.payment_method.last4
    },
  },
  methods: {
    async addCard() {
      this.loading = true
      const { data } = await axios.post(`/api/stripe/billing/setup-intent`, {
        tenant_id: this.tenantId,
      })
      this.loading = false
      this.$store.commit('showModal', {
        name: 'AddCardModal',
        props: {
          clientSecret: data.client_secret,
          publicKey: data.public_key,
        },
        maxWidth: 400,
        isPersistent: false,
      })
    },
  },
}
</script>

<style scoped></style>
