<template>
  <v-dialog v-model="dialog" fullscreen>
    <v-card>
      <v-progress-linear height="4" indeterminate v-if="loading" />
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          `Business Model - ${model.name}`
        }}</v-toolbar-title>
      </v-toolbar>

      <v-tabs v-model="activeTab">
        <v-tab>Expense Types</v-tab>
        <v-tab>Formulas</v-tab>
        <v-tab>Custom Variables</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <!-- Expense Types Tab -->
        <v-tab-item>
          <v-container>
            <expense-types
              :business-model-id="model.id"
              @update="handleExpenseTypesUpdate"
            />
          </v-container>
        </v-tab-item>

        <!-- Formulas Tab -->
        <v-tab-item>
          <v-container>
            <v-card flat>
              <v-card-text>
                <div class="d-flex align-center my-4">
                  <div class="text-h6">Formula Settings</div>
                  <formula-variables-tool-tip />
                </div>
                <v-row class="align-baseline">
                  <v-col cols="12">
                    <enhanced-formula-builder
                      v-model="model.net_income_formula"
                      label="Net Income Formula"
                      :variables="formulaVariables"
                    />
                  </v-col>
                  <v-col cols="12">
                    <enhanced-formula-builder
                      v-model="model.management_commission_formula"
                      label="Management Commission Formula"
                      :variables="formulaVariables"
                    />
                  </v-col>
                  <v-col cols="12">
                    <enhanced-formula-builder
                      v-model="model.owner_revenue_formula"
                      label="Owner Profit Formula"
                      :variables="formulaVariables"
                    />
                  </v-col>
                  <v-col cols="12">
                    <enhanced-formula-builder
                      v-model="model.owners_income_formula"
                      label="Owners Income Formula"
                      :variables="formulaVariables"
                      :hint="
                        !model.owners_income_formula
                          ? `Using system default (fare_accommodation)`
                          : null
                      "
                    />
                  </v-col>
                  <v-col cols="12">
                    <enhanced-formula-builder
                      v-model="model.management_total_formula"
                      label="Management Total Formula"
                      :variables="formulaVariables"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-tab-item>

        <!-- Custom Variables Tab -->
        <v-tab-item>
          <v-container>
            <v-card flat>
              <v-card-text>
                <v-btn color="primary" class="mb-4" @click="addCustomVariable">
                  Add Variable
                </v-btn>

                <v-data-table
                  :headers="variableHeaders"
                  :items="model.custom_fields"
                  class="elevation-1"
                >
                  <template #item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editVariable(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteVariable(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- Custom Variable Dialog -->
    <v-dialog v-model="variableDialog" max-width="500px">
      <v-card>
        <v-card-title>
          {{ editedVariable.id ? 'Edit' : 'New' }} Variable
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="editedVariable.name"
                  label="Name"
                  dense
                  outlined
                  :rules="[required]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedVariable.variable_name"
                  label="Variable Name"
                  dense
                  outlined
                  :rules="[required]"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedVariable.description"
                  label="Description"
                  dense
                  outlined
                  :rules="[required]"
                />
              </v-col>
              <v-col cols="12">
                <enhanced-formula-builder
                  v-model="editedVariable.formula"
                  :variables="formulaVariables"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeVariableDialog">Cancel</v-btn>
          <v-btn color="primary" @click="saveVariable">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { formulaVariables } from '@/consts'
import FormulaVariablesToolTip from 'components/common/formula-variables-tooltip.vue'
import ExpenseTypes from 'components/expense-types.vue'
import EnhancedFormulaBuilder from 'components/common/enhanced-formula-builder.vue'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import { omit } from 'lodash/fp'

export default {
  name: 'BusinessModelDetails',
  mixins: [FormRulesMixin],
  components: {
    FormulaVariablesToolTip,
    ExpenseTypes,
    EnhancedFormulaBuilder,
  },

  props: {
    value: Boolean,
    model: {
      type: Object,
    },
  },

  data() {
    return {
      dialog: this.value,
      activeTab: 0,
      variableDialog: false,
      loading: false,
      editedVariable: {
        id: null,
        name: '',
        variable_name: '',
        description: '',
        formula: '',
      },
      variableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Variable Name', value: 'variable_name' },
        { text: 'Formula', value: 'formula' },
        { text: 'Description', value: 'description' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },

  computed: {
    formulaVariables() {
      return formulaVariables.concat(
        this.model.custom_fields.map(v => ({
          description: v.description,
          value: v.variable_name,
          category: 'Custom Variables',
        }))
      )
    },
  },

  methods: {
    close() {
      this.dialog = false
      this.$emit('input', false)
    },

    async save() {
      try {
        this.loading = true
        const payload = omit(
          ['created_at', 'updated_at', 'tenant_id'],
          this.model
        )
        await this.$store.dispatch('businessModel/updateBusinessModel', payload)
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },

    handleExpenseTypesUpdate(expenseTypes) {
      this.model.expense_types = expenseTypes
    },

    addCustomVariable() {
      this.editedVariable = {
        id: null,
        name: '',
        variable_name: '',
        description: '',
        formula: '',
      }
      this.variableDialog = true
    },

    editVariable(variable) {
      this.editedVariable = { ...variable }
      this.variableDialog = true
    },

    deleteVariable(variable) {
      const index = this.model.custom_fields.findIndex(
        v => v.id === variable.id
      )
      if (index > -1) {
        this.model.custom_fields.splice(index, 1)
      }
    },

    saveVariable() {
      if (this.editedVariable.id) {
        const index = this.model.custom_fields.findIndex(
          v => v.id === this.editedVariable.id
        )
        if (index > -1) {
          this.model.custom_fields.splice(index, 1, {
            ...this.editedVariable,
          })
        }
      } else {
        this.editedVariable.id = Date.now() // Temporary ID
        this.model.custom_fields.push({ ...this.editedVariable })
      }
      this.save()
      this.closeVariableDialog()
    },

    closeVariableDialog() {
      this.variableDialog = false
      this.editedVariable = {
        id: null,
        name: '',
        variable_name: '',
        description: '',
        formula: '',
      }
    },
  },

  watch: {
    value(val) {
      this.dialog = val
    },
    dialog(val) {
      if (!val) {
        this.$emit('input', val)
      }
    },
  },
}
</script>
