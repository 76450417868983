<template>
  <v-dialog :value="true" persistent max-width="800">
    <v-card>
      <v-form ref="form" lazy-validation @submit.prevent="submitForm">
        <v-card-title class="pa-sm-6">
          <div class="p-relative flex text-center">
            <h2 class="text-h6">
              {{ listingInfoItem.id ? 'Update' : 'Add new' }} info item
            </h2>
            <v-icon class="close-button" @click="close">close</v-icon>
          </div>
        </v-card-title>
        <div class="mx-6 d-flex justify-end">
          <listing-info-definition-modal
            v-if="showNewListingInfoDefModal"
            :close-func="closeNewListingInfoDefModal"
          />
          <v-btn
            depressed
            small
            dark
            color="light-blue"
            @click="showNewListingInfoDefModal = true"
          >
            <v-icon small class="me-1">mdi-plus</v-icon>
            Create new topic
          </v-btn>
        </div>
        <v-card-text class="px-sm-6">
          <v-alert
            v-if="generalDefinition && listingInfoItem.room_title"
            color="warning"
            text
          >
            Old title: {{ listingInfoItem.room_title }}, need to pick the
            correct definition.
          </v-alert>
          <info-def-quick-select
            :show-create="true"
            :pre-selected="
              listingInfoItem ? listingInfoItem.listing_info_def_id : null
            "
            :filter-change="definitionPicked"
          />
          <div v-if="listingInfoItem && listingInfoItem.listing_info_def_id">
            <v-textarea
              v-model="listingInfoItem.comments"
              label="Additional Desc"
              outlined
              rows="4"
            />
          </div>
          <image-view
            v-if="imageUrl"
            :readonly="true"
            :src="imageUrl"
            small-width="100%"
          />
          <gallery
            :folder="`info-items/${listingNicknameById(listingId)}`"
            :thumbnails="true"
            :cols="3"
            :images="files"
            :remove-img="removeImg"
            :uploadable="true"
            :on-change="updateFiles"
          />
          <div>
            <v-switch
              v-model="listingInfoItem.owners_public"
              mt-2
              :label="$t('Make public to owners')"
            />
          </div>
          <v-divider></v-divider>
          <div>
            <v-switch
              v-model="listingInfoItem.guests_public"
              mt-2
              :label="$t('Make public to guests')"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-sm-6 py-sm-4">
          <v-spacer />
          <v-btn
            color="secondary"
            class="text-capitalize"
            text
            @click.native="close"
            >Close
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize"
            :disabled="loading"
            type="submit"
            elevation="0"
          >
            {{ listingInfoItem && listingInfoItem.id ? 'Update' : 'Save' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import InfoDefQuickSelect from 'components/info-item-definition/info-def-quick-select'
import formRules from 'components/mixins/form-rules-mixin'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import ImageView from 'components/common/image-view'
import Gallery from 'components/common/gallery.vue'
import ListingInfoDefinitionModal from 'components/info-item-definition/listing-info-definition-modal.vue'

export default {
  components: {
    Gallery,
    ImageView,
    InfoDefQuickSelect,
    ListingInfoDefinitionModal,
  },
  mixins: [CommonFunctions, formRules, PermissionsMixin],
  props: [
    'listingId',
    'room',
    'afterSaveFunc',
    'open',
    'listingItem',
    'imageUrl',
  ],
  data() {
    return {
      currentTopic: get('listing_info_def.topic', this.listingItem),
      currentHint: get('listing_info_def.hint', this.listingItem),
      listingInfoItem: this.listingItem || {
        rules: {},
        listing_info_def_id: null,
      },
      dialog: false,
      showNewListingInfoDefModal: false,
      previewSrc: false,
      files: this.listingItem ? this.listingItem.images : [],
    }
  },
  computed: {
    generalDefinition() {
      if (this.listingInfoItem.id) {
        return (
          get('topic', this.listingInfoItem.listing_info_def) ===
            'General info' &&
          get('sub_topic', this.listingInfoItem.listing_info_def) === 'General'
        )
      }
      return false
    },
    comments() {
      return this.listingInfoItem.comments
    },
    category() {
      return this.listingInfoItem.category
    },
  },
  created() {
    this.$store.dispatch('getListingInfoDefs')
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        if (this.listingInfoItem.id) {
          this.listingInfoItem.images = this.files
          this.updateItem(this.listingInfoItem)
        } else {
          this.save()
        }
      }
    },
    updateFiles(images) {
      this.files = this.files.concat(images)
    },
    removeImg(img) {
      this.files = this.files.filter(f => f !== img)
    },
    definitionPicked(definition) {
      this.listingInfoItem.listing_info_def_id = definition.id
      this.currentHint = definition.hint
      this.currentTopic = definition.topic
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files

      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }
    },
    close() {
      this.$store.commit('hideModal')
      this.files = []
      this.listingInfoItem = { rules: {} }
    },
    closeNewListingInfoDefModal() {
      this.showNewListingInfoDefModal = false
    },
    async updateItem(payload) {
      await this.$store.dispatch('updateListingInfoItem', payload)
      this.close()
    },
    async save() {
      const data = {}
      data.images = []
      if (!isEmpty(this.files)) {
        data.images = this.files
      }
      if (this.listingInfoItem.listing_info_def_id) {
        data.listing_info_def_id = this.listingInfoItem.listing_info_def_id
      }
      if (this.listingInfoItem.comments) {
        data.comments = this.listingInfoItem.comments
      }
      if (this.listingInfoItem.owners_public != null) {
        data.owners_public = this.listingInfoItem.owners_public
      }
      if (this.listingInfoItem.guests_public != null) {
        data.guests_public = this.listingInfoItem.guests_public
      }
      if (this.listingInfoItem.room_number) {
        data.room_number = this.listingInfoItem.room_number
      }
      if (this.imageUrl) {
        data.images.push(this.imageUrl)
      }
      data.listing_id = this.listingId
      await this.$store.dispatch('createListingInfoItem', data)
      if (this.afterSaveFunc) {
        this.afterSaveFunc()
      }
      this.close()
    },
  },
}
</script>
