<template>
  <div
    v-if="computedItems.length"
    class="virtual-scroller overflow-hidden"
    :style="{ width: widthFormat, height: heightFormat }"
  >
    <v-virtual-scroll
      :items="computedItems"
      :item-height="itemHeightFormat"
      :bench="Math.max(bench, 10)"
      width="100%"
      height="100%"
    >
      <template #default="{ item, index }">
        <virtual-scroller-slot-wrapper
          :key="index"
          :is-loader="isLoaderSlotPlaceholder(index)"
          :is-loader-mount="getMoreItems"
          :width="widthFormat"
          :height="itemHeightFormat"
        >
          <slot name="item" :item="item" :index="index" />
        </virtual-scroller-slot-wrapper>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import { clone, isNumber } from 'lodash'
import VirtualScrollerSlotWrapper from 'components/common/virtual-scroller-slot-wrapper'

export default {
  name: 'VirtualScroller',
  components: { VirtualScrollerSlotWrapper },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    itemHeight: {
      type: [Number, String],
      default: 60,
    },
    bench: {
      type: Number,
      default: 10,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
    getMore: {
      type: Function,
      default: () => undefined,
    },
  },
  data() {
    return {
      lastLength: 0,
    }
  },
  computed: {
    widthFormat() {
      return this.sizeFormat(this.width)
    },
    heightFormat() {
      return this.sizeFormat(this.height)
    },
    itemHeightFormat() {
      return this.sizeFormat(this.itemHeight)
    },
    computedItems() {
      let items = clone(this.items)
      if (this.hasMore) {
        items.push({ id: 'Temporary Item For Loader' })
      }
      return items
    },
  },
  methods: {
    sizeFormat(size) {
      return isNumber(size) ? `${size}px` : size
    },
    isLoaderSlotPlaceholder(index) {
      return (
        this.items.length < this.computedItems.length &&
        index + 1 === this.computedItems.length
      )
    },
    getMoreItems() {
      if (this.lastLength < this.items.length && this.getMore) {
        this.lastLength = this.items.length
        this.getMore()
      }
    },
  },
}
</script>
