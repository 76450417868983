<template>
  <v-sheet
    :height="height"
    :style="`background-color: ${bgColor}; border-radius: ${
      rounded ? '20px' : '5px'
    }; color: ${color}`"
    class="font-weight-medium d-inline-block"
    :class="[
      { 'tag-rounded': rounded },
      `mx-1 ${color}--text ${inverted ? 'bg-opac' : bgColor}`,
      {
        'px-1 tag-font': size === 'xs',
        'px-2 py-1 text-caption': size === 'sm',
      },
    ]"
  >
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    color: {
      type: String,
      default: 'info',
    },
    height: {
      type: [String, Number],
    },
    bgColor: {
      type: String,
      default: 'white',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'xs',
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.tag-font {
  font-size: 0.65rem;
}
.bg-opac {
  position: relative;
}
.bg-opac::before {
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  left: 0;
  position: absolute;
  background-color: currentColor;
  opacity: 0.12;
}
.tag-rounded::before {
  border-radius: 20px;
}
</style>
