<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.323"
    height="22.094"
    viewBox="0 0 12.323 22.094"
  >
    <g transform="translate(0)">
      <g transform="translate(8.406 1.988)">
        <path
          class="a"
          d="M308.318,46.707h3.27a.324.324,0,0,0,0-.647h-3.27a.324.324,0,1,0,0,.647Z"
          transform="translate(-307.994 -46.06)"
        />
        <path
          class="a"
          d="M308.318,81.336h1.635a.324.324,0,0,0,0-.647h-1.635a.324.324,0,0,0,0,.647Z"
          transform="translate(-307.994 -79.195)"
        />
        <path
          class="a"
          d="M311.589,115.689h-3.27a.324.324,0,0,0,0,.647h3.27a.324.324,0,0,0,0-.647Z"
          transform="translate(-307.995 -112.684)"
        />
        <path
          class="a"
          d="M308.318,150.965h1.635a.324.324,0,0,0,0-.647h-1.635a.324.324,0,0,0,0,.647Z"
          transform="translate(-307.994 -145.819)"
        />
        <path
          class="a"
          d="M311.589,184.8h-3.27a.324.324,0,0,0,0,.647h3.27a.324.324,0,0,0,0-.647Z"
          transform="translate(-307.995 -178.816)"
        />
        <path
          class="a"
          d="M308.318,220.079h1.635a.324.324,0,0,0,0-.647h-1.635a.324.324,0,0,0,0,.647Z"
          transform="translate(-307.994 -211.95)"
        />
        <path
          class="a"
          d="M311.589,254.569h-3.27a.324.324,0,0,0,0,.647h3.27a.324.324,0,0,0,0-.647Z"
          transform="translate(-307.995 -245.571)"
        />
        <path
          class="a"
          d="M309.953,289.2h-1.635a.324.324,0,0,0,0,.647h1.635a.324.324,0,1,0,0-.647Z"
          transform="translate(-307.994 -278.705)"
        />
      </g>
      <path
        class="b"
        d="M122.426,17.487a4.607,4.607,0,0,1-4.607,4.607h-.066a4.607,4.607,0,0,1-2.416-8.489V2.481a2.481,2.481,0,1,1,4.963,0V13.6a4.628,4.628,0,0,1,2.126,3.883Z"
        transform="translate(-113.211 0)"
      />
      <path
        class="c"
        d="M195.892,17.487a4.607,4.607,0,0,1-4.607,4.607h-1.295a4.641,4.641,0,0,0,.65-.045,4.609,4.609,0,0,0,3.957-4.562c0-1.569-.81-1.737-2.126-2.58V2.481a2.486,2.486,0,0,0-1.834-2.4,2.482,2.482,0,0,1,3.129,2.4V13.6A4.628,4.628,0,0,1,195.892,17.487Z"
        transform="translate(-186.678 0)"
      />
      <g transform="translate(1.294 1.301)">
        <path
          class="d"
          d="M149.835,46.342a3.313,3.313,0,0,1-3.316,3.313h-.044a3.313,3.313,0,0,1-1.62-6.175.974.974,0,0,0,.481-.838v-11.3a1.188,1.188,0,0,1,.647-1.057,1.175,1.175,0,0,1,.539-.13,1.188,1.188,0,0,1,1.186,1.187v11.3a.974.974,0,0,0,.481.838A3.329,3.329,0,0,1,149.835,46.342Z"
          transform="translate(-143.21 -30.15)"
        />
        <path
          class="e"
          d="M211.333,46.342a3.313,3.313,0,0,1-3.316,3.313h-.044a3.318,3.318,0,0,1-.491-.044,3.315,3.315,0,0,0,2.772-3.269,3.329,3.329,0,0,0-1.645-2.863.974.974,0,0,1-.481-.838v-11.3a1.189,1.189,0,0,0-.647-1.057,1.175,1.175,0,0,1,.539-.13,1.188,1.188,0,0,1,1.186,1.187v11.3a.974.974,0,0,0,.481.838A3.329,3.329,0,0,1,211.333,46.342Z"
          transform="translate(-204.707 -30.15)"
        />
      </g>
      <path
        class="a"
        d="M194.854,31.337v2.828h-2.373V31.337a1.188,1.188,0,0,1,.647-1.057,1.175,1.175,0,0,1,.539-.13A1.188,1.188,0,0,1,194.854,31.337Z"
        transform="translate(-189.06 -28.849)"
      />
      <path
        class="f"
        d="M209.207,31.337v2.828h-1.079V31.337a1.189,1.189,0,0,0-.647-1.057,1.175,1.175,0,0,1,.539-.13A1.188,1.188,0,0,1,209.207,31.337Z"
        transform="translate(-203.413 -28.849)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Thermometer',
}
</script>

<style scoped>
.a {
  fill: #4f667a;
}

.b {
  fill: #e9f5ff;
}

.c {
  fill: #cdeaf7;
}

.d {
  fill: #ff6e6e;
}

.e {
  fill: #f44e92;
}

.f {
  fill: #3a5366;
}
</style>
