<template>
  <div class="estimates-config-side-bar">
    <div class="d-flex align-center pa-3">
      <v-select
        dense
        outlined
        hide-details
        :value="division"
        :items="['Construction', 'Maintenance', 'Design']"
        clearable
        label="Choose Division"
        class="listing-select"
        @input="selectDivision($event)"
      />
    </div>
    <div v-if="division" class="d-flex align-center pa-3">
      <div class="flex-fill text-h6">Groups</div>
      <form-dialog
        title="Create New Estimate Group"
        @submit="onCreateNewGroup"
        @open="editedGroup = {}"
      >
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on">
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-text-field
          v-model="editedGroup.name"
          label="Name *"
          outlined
          dense
          :rules="[required]"
        />
      </form-dialog>
    </div>
    <v-divider />
    <v-list v-if="division" class="transparent">
      <v-hover v-for="group in groups" v-slot="{ hover }" :key="group.id">
        <v-list-item class="pr-1" @click="selectGroup(group)">
          <v-list-item-content>
            <v-list-item-title
              :class="{
                'primary--text darken-2 font-weight-bold':
                  group.id === selectedGroupId,
              }"
            >
              {{ group.name }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-show="hover" class="flex-center flex-row ma-0">
            <form-dialog
              title="Update Estimate Group"
              @submit="onUpdateGroup"
              @open="onUpdateGroupOpen(group)"
            >
              <template #activator="{ on, attrs }">
                <v-btn small v-bind="attrs" icon v-on="on">
                  <v-icon small color="grey lighten-1">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <v-text-field
                v-model="editedGroup.name"
                label="Name *"
                outlined
                dense
                :rules="[required]"
              />
            </form-dialog>
            <ConfirmationModal
              :text="`Are you sure you want to delete this group ?`"
              @action="onDeleteGroup(group.id)"
            >
              <template #activator="{ on }">
                <v-btn small icon v-on="on">
                  <v-icon small color="grey lighten-1">
                    mdi-delete-forever
                  </v-icon>
                </v-btn>
              </template>
            </ConfirmationModal>
          </v-list-item-action>
        </v-list-item>
      </v-hover>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import formRules from 'components/mixins/form-rules-mixin'
import FormDialog from 'components/common/form-dialog'
import ConfirmationModal from 'components/modals/confirmation-modal'

export default {
  name: 'EstimatesConfigSideBar',
  components: { ConfirmationModal, FormDialog },
  mixins: [CommonFunctions, formRules],
  props: ['division', 'selectedGroupId'],
  data() {
    return {
      editedGroup: {},
    }
  },
  async mounted() {
    if (this.division) {
      await this.getGroups({ division: this.division })
    }
  },
  computed: {
    ...mapState('estimates', ['groups']),
    ...mapGetters('estimates', ['groupsMap']),
  },
  methods: {
    ...mapActions('estimates', [
      'getGroups',
      'getGroupQuestions',
      'createGroup',
      'updateGroup',
      'deleteGroup',
      'removeGroup',
    ]),
    async selectDivision(division) {
      this.$emit('update:division', division)
    },
    async selectGroup(group) {
      this.$emit('update:selected-group-id', group.id)
    },
    selectGroupFallback() {
      if (this.groups[0]) {
        this.selectGroup(this.groups[0])
      }
    },
    async onCreateNewGroup() {
      const newGroup = await this.createGroup({
        ...this.editedGroup,
        division: this.division,
      })
      await this.getGroups({ division: this.division })
      await this.selectGroup(newGroup)
    },
    onUpdateGroupOpen(group) {
      this.editedGroup = {
        id: group.id,
        name: group.name,
      }
    },
    async onUpdateGroup() {
      await this.updateGroup({
        ...this.editedGroup,
        division: this.division,
      })
    },
    async onDeleteGroup(id) {
      this.removeGroup(id)
      await this.deleteGroup(id)
      const group = this.groupsMap[this.selectedGroupId]
      if (!group) await this.selectGroupFallback()
    },
  },
  watch: {
    division(value) {
      if (value) {
        this.getGroups({ division: this.division })
      }
      this.selectGroup({ id: null })
    },
    selectedGroupId(value) {
      if (value) {
        this.getGroupQuestions({ id: this.selectedGroupId })
      }
    },
  },
}
</script>
<style scoped lang="scss">
.estimates-config-side-bar {
  display: flex;
  flex-direction: column;
  width: 230px;
  min-width: 230px;
  height: 100%;
  background-color: var(--v-secondary-lighten);
}
</style>
