<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(intg, ind) in integrations"
        :key="ind"
        cols="6"
        md="4"
        xl="3"
      >
        <integration-box
          :id="intg.id"
          :category="intg.category"
          :title="intg.display_name"
          :name="intg.name"
          :active="intg.active"
        />
      </v-col>
      <v-col
        v-for="unint in uninstalled"
        :key="unint.name"
        cols="6"
        md="4"
        xl="3"
      >
        <integration-box
          :id="unint.id"
          :category="unint.category"
          :title="unint.display_name"
          :name="unint.name"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IntegrationBox from 'components/integration-box'
import { mapGetters } from 'vuex'

export default {
  name: 'Integrations',
  components: { IntegrationBox },
  computed: {
    ...mapGetters(['marketplaceIntegrations']),
    integrations() {
      return this.$store.getters.integrations || []
    },
    uninstalled() {
      const installedIds = this.integrations.map(i => i.marketplace_id)
      return this.marketplaceIntegrations.filter(
        m => !installedIds.includes(m.id)
      )
    },
  },
}
</script>

<style scoped></style>
