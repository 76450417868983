<template>
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.812 8.261C6.73 3.087 8.189 0.5 10.5 0.5C12.81 0.5 14.27 3.087 17.188 8.261L17.552 8.906C19.977 13.206 21.19 15.356 20.094 16.928C18.998 18.5 16.286 18.5 10.864 18.5H10.136C4.714 18.5 2.002 18.5 0.905996 16.928C-0.190004 15.356 1.023 13.206 3.448 8.906L3.812 8.261ZM10.5 4.75C10.6989 4.75 10.8897 4.82902 11.0303 4.96967C11.171 5.11032 11.25 5.30109 11.25 5.5V10.5C11.25 10.6989 11.171 10.8897 11.0303 11.0303C10.8897 11.171 10.6989 11.25 10.5 11.25C10.3011 11.25 10.1103 11.171 9.96967 11.0303C9.82901 10.8897 9.75 10.6989 9.75 10.5V5.5C9.75 5.30109 9.82901 5.11032 9.96967 4.96967C10.1103 4.82902 10.3011 4.75 10.5 4.75ZM10.5 14.5C10.7652 14.5 11.0196 14.3946 11.2071 14.2071C11.3946 14.0196 11.5 13.7652 11.5 13.5C11.5 13.2348 11.3946 12.9804 11.2071 12.7929C11.0196 12.6054 10.7652 12.5 10.5 12.5C10.2348 12.5 9.98043 12.6054 9.79289 12.7929C9.60535 12.9804 9.5 13.2348 9.5 13.5C9.5 13.7652 9.60535 14.0196 9.79289 14.2071C9.98043 14.3946 10.2348 14.5 10.5 14.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExclamationTriangle',
}
</script>

<style scoped>
path {
  fill: currentColor;
}
</style>
