<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.59 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="_002-accounting" data-name=" 002-accounting">
          <g id="Group_10263" data-name="Group 10263">
            <g id="Group_10262" data-name="Group 10262">
              <path
                id="Path_5139"
                data-name="Path 5139"
                class="cls-1"
                d="M7.06,11H2.72a.32.32,0,0,0,0,.64H7.06a.32.32,0,0,0,0-.64Z"
              />
              <path
                id="Path_5140"
                data-name="Path 5140"
                class="cls-1"
                d="M7.06,13.83H2.72a.32.32,0,0,0-.32.32.33.33,0,0,0,.32.33H7.06a.33.33,0,0,0,.32-.33.32.32,0,0,0-.32-.32Z"
              />
              <path
                id="Path_5141"
                data-name="Path 5141"
                class="cls-1"
                d="M7.06,16.64H2.72a.33.33,0,0,0,0,.65H7.06a.33.33,0,0,0,0-.65Z"
              />
              <path
                id="Path_5142"
                data-name="Path 5142"
                class="cls-1"
                d="M10.67,13.43h.68a.33.33,0,1,0,0-.65h-.68a.33.33,0,0,0,0,.65Z"
              />
              <path
                id="Path_5143"
                data-name="Path 5143"
                class="cls-1"
                d="M13.43,12.78h-.68a.33.33,0,0,0,0,.65h.68a.33.33,0,0,0,0-.65Z"
              />
              <path
                id="Path_5144"
                data-name="Path 5144"
                class="cls-1"
                d="M15.51,12.78h-.68a.33.33,0,1,0,0,.65h.68a.33.33,0,0,0,0-.65Z"
              />
              <path
                id="Path_5145"
                data-name="Path 5145"
                class="cls-1"
                d="M17.59,12.78H16.9a.33.33,0,0,0,0,.65h.69a.33.33,0,0,0,0-.65Z"
              />
              <path
                id="Path_5146"
                data-name="Path 5146"
                class="cls-1"
                d="M10.67,15.1h.68a.32.32,0,1,0,0-.64h-.68a.32.32,0,0,0,0,.64Z"
              />
              <path
                id="Path_5147"
                data-name="Path 5147"
                class="cls-1"
                d="M13.43,14.46h-.68a.32.32,0,0,0,0,.64h.68a.32.32,0,0,0,0-.64Z"
              />
              <path
                id="Path_5148"
                data-name="Path 5148"
                class="cls-1"
                d="M15.51,14.46h-.68a.32.32,0,1,0,0,.64h.68a.32.32,0,0,0,0-.64Z"
              />
              <path
                id="Path_5149"
                data-name="Path 5149"
                class="cls-1"
                d="M17.59,14.46H16.9a.32.32,0,0,0,0,.64h.69a.32.32,0,0,0,0-.64Z"
              />
              <path
                id="Path_5150"
                data-name="Path 5150"
                class="cls-1"
                d="M10.67,16.78h.68a.32.32,0,1,0,0-.64h-.68a.32.32,0,0,0,0,.64Z"
              />
              <path
                id="Path_5151"
                data-name="Path 5151"
                class="cls-1"
                d="M13.43,16.14h-.68a.32.32,0,0,0,0,.64h.68a.32.32,0,0,0,0-.64Z"
              />
              <path
                id="Path_5152"
                data-name="Path 5152"
                class="cls-1"
                d="M15.51,16.14h-.68a.32.32,0,1,0,0,.64h.68a.32.32,0,0,0,0-.64Z"
              />
              <path
                id="Path_5153"
                data-name="Path 5153"
                class="cls-1"
                d="M17.59,16.14H16.9a.32.32,0,0,0,0,.64h.69a.32.32,0,0,0,0-.64Z"
              />
              <path
                id="Path_5154"
                data-name="Path 5154"
                class="cls-1"
                d="M3.43,7.81A.31.31,0,0,0,3,7.9a.32.32,0,0,0,.09.45h0a2.15,2.15,0,0,0,1.21.41V9.2a.33.33,0,0,0,.33.32.32.32,0,0,0,.32-.32h0V8.74A1.76,1.76,0,0,0,6.34,7.3a1.52,1.52,0,0,0-1-1.74l-.35-.13V3.63a1.11,1.11,0,0,1,.52.23.32.32,0,1,0,.43-.47h0A1.77,1.77,0,0,0,4.94,3V2.59a.33.33,0,0,0-.65,0V3l-.19,0a1.43,1.43,0,0,0-1,1.13,1.28,1.28,0,0,0,.41,1.25,3.88,3.88,0,0,0,.77.43V8.12A1.5,1.5,0,0,1,3.43,7.81Zm1.5-1.69.14,0a.88.88,0,0,1,.63,1,1.12,1.12,0,0,1-.77.88Zm-1-1.2a.63.63,0,0,1-.18-.62.8.8,0,0,1,.54-.63h0V5.15a2.06,2.06,0,0,1-.37-.23Z"
              />
              <path
                id="Path_5155"
                data-name="Path 5155"
                class="cls-1"
                d="M16.88,20.1a.32.32,0,0,0-.32.32v.23a.7.7,0,0,1-.7.7H1.35a.7.7,0,0,1-.7-.7h0V1.34a.7.7,0,0,1,.7-.7H12.72v2.7a1.14,1.14,0,0,0,1.14,1.13h2.69a.38.38,0,0,1,0,.1V6.12a.32.32,0,1,0,.64,0h0V4.58a1.39,1.39,0,0,0-.38-.94L13.57.39h0A1.33,1.33,0,0,0,12.62,0H1.35A1.34,1.34,0,0,0,0,1.34V20.66A1.34,1.34,0,0,0,1.35,22H15.86a1.35,1.35,0,0,0,1.35-1.34v-.23a.33.33,0,0,0-.33-.33Zm-3.51-19L16.1,3.84H13.86a.49.49,0,0,1-.49-.49V1.1Z"
              />
              <path
                id="Path_5156"
                data-name="Path 5156"
                class="cls-1"
                d="M18.47,7.31H9.79A1.12,1.12,0,0,0,8.67,8.43V9.51a.33.33,0,1,0,.65,0V8.43A.47.47,0,0,1,9.79,8h8.68a.47.47,0,0,1,.47.47v9.7a.47.47,0,0,1-.47.47H9.79a.47.47,0,0,1-.47-.47V11a.33.33,0,1,0-.65,0v7.1a1.13,1.13,0,0,0,1.12,1.11h8.68a1.12,1.12,0,0,0,1.12-1.12V8.43a1.12,1.12,0,0,0-1.12-1.12Z"
              />
              <path
                id="Path_5157"
                data-name="Path 5157"
                class="cls-1"
                d="M17.91,9.44a.57.57,0,0,0-.57-.57H10.92a.57.57,0,0,0-.57.57v1.65a.56.56,0,0,0,.57.57h6.42a.56.56,0,0,0,.57-.57ZM17.27,11H11V9.52h6.28Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Accounting',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
svg {
  fill: currentColor;
}
</style>
