<template>
  <form-dialog
    title="Email Invoice"
    submit-text="Send"
    @close="closeDialog"
    @submit="submit"
  >
    <template #activator="{ on }">
      <div block text depressed v-on="on">Email Payment Details</div>
    </template>
    <v-text-field
      v-model="email"
      :rules="[required]"
      label="Email Address"
      dense
      outlined
    />
  </form-dialog>
</template>

<script>
import formDialog from 'components/common/form-dialog.vue'
import { mapActions } from 'vuex'
import FormRulesMixin from 'components/mixins/form-rules-mixin'

export default {
  components: { formDialog },
  mixins: [FormRulesMixin],
  props: ['invoiceEmail', 'reservationId', 'disabled'],
  data() {
    return { email: this.invoiceEmail }
  },
  methods: {
    ...mapActions('reservation', ['emailInvoice']),
    submit() {
      this.emailInvoice({ email: this.email, id: this.reservationId })
    },
    closeDialog() {
      this.$emit('close')
    },
  },
}
</script>
