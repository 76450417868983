var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dryRunMessage)?_c('div',[_c('div',{staticClass:"float-right ai-component align-baseline max-w-85 ma-2 pa-3 text-body-2 text-sm-body-1",staticStyle:{"margin-right":"18px !important"}},[_c('div',{staticStyle:{"width":"100%","visibility":"hidden"}},[_c('v-btn',{staticClass:"float-right",attrs:{"outlined":"","x-small":"","color":"blue-grey"}},[_c('v-icon',{staticClass:"me-1",attrs:{"x-small":""}},[_vm._v("mdi-creation")]),_vm._v(" Dry Run ")],1)],1),_vm._m(0),_c('div',{staticClass:"ai-component-content"},[(
          !_vm.aiSuggestionMessage &&
          (!_vm.aiSuggestionActions || _vm.aiSuggestionActions.length === 0)
        )?_c('div',{staticClass:"ai-component-message mt-3"},[_vm._m(1)]):_vm._e(),(_vm.aiSuggestionMessage)?_c('div',{staticClass:"ai-component-message mt-3"},[_c('span',[_c('span',{staticClass:"cyan--text"},[_c('v-icon',{staticClass:"mr-2 cyan--text"},[_vm._v("$chat")])],1),_c('strong',[_vm._v("Message")]),(_vm.aiSuggestionMessageTranslation)?_c('span',{staticClass:"cyan--text tooltip"},[_c('v-icon',{staticClass:"mr-2 cyan--text tooltipicon"},[_vm._v("mdi-translate")]),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.aiSuggestionMessageTranslation))])],1):_vm._e(),(_vm.aiSuggestionMessageReason)?_c('span',{staticClass:"cyan--text tooltip"},[_c('v-icon',{staticClass:"mr-2 cyan--text tooltipicon"},[_vm._v("$info")]),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.aiSuggestionMessageReason))])],1):_vm._e()]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.aiSuggestionMessage)}})]):_vm._e(),_vm._l((_vm.aiSuggestionActions),function(co_pilot_action){return _c('div',{key:co_pilot_action.action_name,staticClass:"ai-component-action"},[_c('div',[_c('span',{staticClass:"cyan--text"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.iconForAction(co_pilot_action)))])],1),_c('strong',[_vm._v(" "+_vm._s(_vm.labelForAction(co_pilot_action))+" "),(
                co_pilot_action.payload && co_pilot_action.payload.human_time
              )?_c('span',{staticClass:"ml-1"},[_vm._v(" ("+_vm._s(co_pilot_action.payload.human_time)+") ")]):_vm._e()])]),(
            co_pilot_action.payload && co_pilot_action.payload.description
          )?_c('div',[_c('span',{staticClass:"will-be"},[_vm._v(" "+_vm._s(co_pilot_action.payload.description)+" ")])]):_vm._e()])}),_c('div',{staticClass:"dry-run-tags"},[(_vm.dryRunMessage.detected_policy)?_c('div',{staticClass:"dry-run-tag secondary"},[_vm._v(" Detected Policy: "+_vm._s(_vm.dryRunMessage.detected_policy)+" ")]):_vm._e(),(
            _vm.dryRunMessage.sales_stages &&
            _vm.dryRunMessage.sales_stages.current_stage
          )?_c('div',{staticClass:"dry-run-tag primary"},[_vm._v(" Current Sales Stage: "+_vm._s(_vm.dryRunMessage.sales_stages.current_stage)+" ")]):_vm._e(),(
            _vm.isSuperAdmin &&
            _vm.dryRunMessage.sales_stages &&
            _vm.dryRunMessage.sales_stages.missing_info &&
            _vm.dryRunMessage.sales_stages.missing_info.length
          )?_c('div',{staticClass:"dry-run-tag info"},[_vm._v(" Missing Sales Info: "+_vm._s(_vm.dryRunMessage.sales_stages.missing_info)+" ")]):_vm._e(),(
            _vm.isSuperAdmin &&
            _vm.dryRunMessage.sales_stages &&
            _vm.dryRunMessage.sales_stages.next_step
          )?_c('div',{staticClass:"dry-run-tag info"},[_vm._v(" Next Sales Step: "+_vm._s(_vm.dryRunMessage.sales_stages.next_step)+" ")]):_vm._e()])],2)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-text pb-3 bb-1 full-width ai-component-header"},[_c('span',[_c('strong',[_vm._v("Dry Run")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center-text pb-3 bb-1 full-width ai-component-no-suggestion"},[_c('img',{staticClass:"mr-2",staticStyle:{"width":"60px","height":"60px"},attrs:{"src":"no messages.svg"}}),_c('span',{staticStyle:{"font-size":"12px","margin-left":"10px"}},[_c('strong',[_vm._v("No AI Suggestion Found")])])])
}]

export { render, staticRenderFns }