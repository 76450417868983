<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :disabled="readonly"
    offset-y
    bottom
    max-height="300"
  >
    <template #activator="menuSlotData">
      <v-tooltip
        v-model="tooltip"
        :disabled="menu || isMultiple || !!moveFromSectionId"
        bottom
        open-delay="100"
        content-class="picker-tooltip"
      >
        <template #activator="tooltipSlotData">
          <span v-bind="tooltipSlotData.attrs" v-on="tooltipSlotData.on">
            <v-btn
              :icon="!textButton"
              :outlined="textButton"
              :small="small"
              :disabled="readonly"
              v-bind="menuSlotData.attrs"
              color="info"
              v-on="menuSlotData.on"
            >
              <v-icon v-if="moveFromSectionId" :small="small" color="info">
                mdi-table-arrow-right
              </v-icon>
              <v-icon v-else :small="small" color="info">
                mdi-cards-playing-outline
              </v-icon>
              <span v-if="textButton" class="ml-2 info--text">Taskim</span>
            </v-btn>
          </span>
        </template>
        <div>
          <v-progress-circular
            v-if="!hierarchy"
            :size="20"
            indeterminate
            color="primary"
          />
          <div v-else-if="hierarchy.length">
            <div
              v-for="(relation, index) in hierarchy"
              :key="index"
              class="d-flex align-center flex-nowrap"
            >
              <span class="text-caption text-no-wrap">
                {{ relation.sectionName }}
              </span>
              <span class="text-caption text-no-wrap ml-2">
                ({{ relation.boardsNames.join(', ') }})
              </span>
            </div>
          </div>
          <div v-else>Not attached to any Taskim section</div>
        </div>
      </v-tooltip>
    </template>

    <v-list v-if="menu">
      <v-text-field
        v-model="search"
        class="pa-3"
        autofocus
        hide-details
        dense
        label="Search"
      />
      <v-progress-circular
        v-if="!boardsFetched"
        :size="20"
        indeterminate
        color="primary"
      />
      <v-list-item-group>
        <v-list-item
          v-for="(option, index) in options"
          :key="index"
          :disabled="moveFromSectionId == option.sectionId"
          @click="addToSection(option.sectionId)"
        >
          <v-list-item-title class="d-flex align-center">
            <span class="text-caption text-no-wrap">
              {{ option.sectionName }}
            </span>
            <span class="text-caption text-no-wrap ml-2">
              ({{ option.boardsNames.join(', ') }})
            </span>
            <v-spacer />
            <v-icon
              v-if="hierarchySectionsIdSet.has(option.sectionId)"
              class="ml-5"
              color="success"
            >
              mdi-playlist-check
            </v-icon>
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import { mapActions, mapState } from 'vuex'
import { take } from 'lodash'
import Toaster from '@/utils/toaster'

export default {
  name: 'LtTaskimBtn',
  components: {},
  mixins: [CommonFunctions],
  props: [
    'listingTaskId',
    'listingTasksIds',
    'readonly',
    'small',
    'textButton',
    'moveFromSectionId',
  ],
  data() {
    return {
      menu: false,
      tooltip: false,
      hierarchy: null,
      search: '',
    }
  },
  computed: {
    ...mapState('taskim', ['boardsFetching', 'boardsFetched', 'boards']),
    isMultiple() {
      return !!this.listingTasksIds
    },
    options() {
      const options = {}
      this.boards.forEach(board => {
        board.sections_relations.forEach(section_relation => {
          const sectionId = section_relation.section.id
          if (!options[sectionId]) {
            options[sectionId] = {
              sectionId,
              sectionName: section_relation.section.name,
              boardsNames: [],
            }
          }
          options[sectionId].boardsNames.push(board.name)
        })
      })
      return take(
        Object.values(options)
          .sort(this.optionsSort)
          .filter(this.optionsFilter),
        15
      )
    },
    hierarchySectionsIdSet() {
      return new Set((this.hierarchy || []).map(h => h.sectionId))
    },
  },
  methods: {
    ...mapActions('taskim', [
      'getTaskHierarchy',
      'getBoards',
      'updateSectionTasks',
      'updateSectionTask',
      'getSectionTasks',
    ]),
    optionsSort(itemA, itemB) {
      return (itemA.sectionName + itemA.boardsNames.join(''))
        .toLowerCase()
        .localeCompare(
          (itemB.sectionName + itemB.boardsNames.join('')).toLowerCase()
        )
    },
    optionsFilter(item) {
      return (
        !this.search ||
        (item.sectionName + item.boardsNames.join(''))
          .toLowerCase()
          .includes(this.search.toLowerCase())
      )
    },
    async fetchHierarchy() {
      const hierarchyResponse = await this.getTaskHierarchy(this.listingTaskId)
      const hierarchy = {}
      hierarchyResponse.forEach(section => {
        const sectionId = section.id
        if (!hierarchy[sectionId]) {
          hierarchy[sectionId] = {
            sectionId,
            sectionName: section.name,
            boardsNames: [],
          }
        }
        section.boards.forEach(board => {
          hierarchy[sectionId].boardsNames.push(board.name)
        })
      })
      this.hierarchy = Object.values(hierarchy).sort(this.optionsSort)
    },
    addToSection(sectionId) {
      if (this.isMultiple) {
        this.updateSectionTasks({
          sectionId: sectionId,
          tasksIds: this.listingTasksIds,
          operation: 'add',
        })
          .then(() => {
            Toaster.show([
              { type: 'success', text: 'Tasks added successfully' },
            ])
          })
          .catch(() => {
            Toaster.show([{ type: 'error', text: 'Failed to add tasks' }])
          })
      } else {
        this.updateSectionTask({
          sectionId: sectionId,
          taskId: this.listingTaskId,
          operation: 'add',
        })
          .then(this.fetchHierarchy)
          .then(() => {
            Toaster.show([{ type: 'success', text: 'Task added successfully' }])
          })
          .catch(() => {
            Toaster.show([{ type: 'error', text: 'Failed to add task' }])
          })
      }
      if (this.moveFromSectionId) {
        this.removeFromSection()
      }
    },
    removeFromSection() {
      this.updateSectionTasks({
        sectionId: this.moveFromSectionId,
        tasksIds: this.listingTaskId
          ? [this.listingTaskId]
          : this.listingTasksIds,
        operation: 'remove',
      }).then(() => {
        this.getSectionTasks(this.moveFromSectionId)
      })
    },
  },
  watch: {
    menu(val) {
      if (val) {
        this.$nextTick(() => this.$refs.menu.onResize())
        if (!this.boardsFetched && !this.boardsFetching) {
          this.getBoards()
        }
        if (this.moveFromSectionId) {
          this.listingTaskId && this.fetchHierarchy()
        }
      } else {
        this.search = ''
      }
    },
    tooltip(val) {
      if (val && !this.hierarchy) {
        this.fetchHierarchy()
      }
    },
  },
}
</script>

<style scoped></style>
