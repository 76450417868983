import axios, { autoCancelToken } from '@/axios/config'

const initialListingReport = {
  last_reservation: null,
  next_reservation: null,
  inspection_service_call: null,
  listing: null,
  stats: null,
  prev_tasks: [],
  next_tasks: [],
}

export default {
  namespaced: true,
  state: {
    updatedReportTask: null,
    fetchingReportData: false,
    regionReportStats: {
      listings: [],
      reviews: [],
    },
    listingReportStats: initialListingReport,
    report: {
      expenses: [],
      listing_tasks: [],
      reviews: [],
    },
  },
  mutations: {
    updateRegionStats(state, data) {
      state.regionReportStats = data
    },
    updateListingStats(state, data) {
      state.listingReportStats = data
    },
    updatedReportTask(state, task) {
      state.updatedReportTask = task
    },
    toggleFetchingReportData(state, data) {
      state.fetchingReportData = data
    },
    updateListingReport(state, data) {
      state.report = data
    },
  },
  actions: {
    async getListingReport({ commit }, { listingId = '', ...params }) {
      commit('toggleFetchingReportData', true)
      const { data } = await axios.get(`/api/listing-report/${listingId}`, {
        params: { ...params },
      })
      commit('toggleFetchingReportData', false)
      commit('updateListingReport', data)
      return data
    },
    async getReviewReport({ state, commit }, { listingId = '', ...params }) {
      if (
        listingId &&
        state.listingReportStats.listing &&
        state.listingReportStats.listing.id !== +listingId
      ) {
        commit('updateListingStats', initialListingReport)
      }
      commit('toggleFetchingReportData', true)
      const { data } = await axios.get(
        `/api/listing-report/review-report/${listingId}`,
        {
          params,
          cancelToken: autoCancelToken('RegionReport'),
        }
      )
      commit('toggleFetchingReportData', false)

      if (listingId) {
        commit('updateListingStats', data)
      } else {
        commit('updateRegionStats', data)
      }
      return data
    },
    async updatePerformance(_, { listing_id, ...params }) {
      const { data } = await axios.post(
        `/api/listing-report/${listing_id}/performance`,
        params
      )
      return data
    },
    async getPortfolio(_, params) {
      const { data } = await axios.get(`/api/portfolio`, {
        params,
      })
      return data
    },
  },
}
