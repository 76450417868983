<template>
  <v-data-table :headers="headers" :items="accounts">
    <template #top>
      <form-dialog
        class="text-capitalize"
        title="Create Stripe Account"
        @submit="createAccount"
      >
        <template #activator="{ on }">
          <div class="pa-2">
            <v-btn color="primary" class="mb-2" v-on="on"> New Account </v-btn>
          </div>
        </template>
        <v-text-field
          v-model="newAccount.name"
          label="Name"
          dense
          outlined
          :rules="[required]"
        />
        <v-text-field
          v-model="newAccount.stripe_public_key"
          label="Public Key"
          dense
          outlined
          :rules="[required]"
        />
        <v-text-field
          v-model="newAccount.stripe_secret_key"
          label="Secret Key"
          dense
          outlined
          :rules="[required]"
        />
      </form-dialog>
    </template>
    <template #item="{ item }">
      <tr>
        <td>
          <div class="font-weight-medium text-capitalize">{{ item.name }}</div>
        </td>
        <td>
          {{ parseDate(item.created_at, 'D MMM Y') }}
        </td>
        <td>
          <div v-if="item.is_default" class="text-overline">Default</div>
          <v-btn
            v-else
            color="primary"
            small
            @click="onUpdate(true, item.id, 'is_default')"
            >Set default</v-btn
          >
        </td>
        <td>
          <simple-menu
            :items="actionsItems"
            :position="{ left: true, offsetY: true }"
            @action="onAction(item, $event)"
          >
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
          </simple-menu>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import SimpleMenu from 'components/common/simple-menu'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'StripeAccountsTable',
  components: { SimpleMenu, FormDialog },
  mixins: [FormRules, FormattersMixin],
  computed: {
    ...mapState('stripe', ['accounts']),
    actionsItems() {
      return [
        {
          title: 'Edit',
          actionKey: 'edit',
        },
      ]
    },
  },
  methods: {
    ...mapActions('stripe', ['updateStripeAccount', 'createStripeAccount']),
    onUpdate(value, id, key) {
      this.updateStripeAccount({ id, [key]: value })
    },
    createAccount() {
      this.createStripeAccount(this.newAccount)
    },
    onAction(account, event) {
      if (event === 'edit') {
        this.$store.commit('showModal', {
          name: 'StripeAccountModal',
          props: {
            account,
          },
        })
      }
    },
  },
  data() {
    return {
      newAccount: {},
      headers: [
        { text: 'Name', sortable: true },
        { text: 'Created At', value: '' },
        { text: 'Is Default', value: '' },
        { text: 'actions', value: '' },
      ],
    }
  },
}
</script>

<style scoped>
td {
  word-break: break-all;
}
</style>
