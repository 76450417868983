<template>
  <expander :value="isExpanded">
    <template #title>
      <div
        class="header d-flex justify-space-between align-center flex ellipsis-1 py-2"
      >
        <v-checkbox
          v-if="!isContractor && !readonly && !isMobile"
          hide-details
          class="ma-0 pa-0 ml-3"
          :input-value="selected"
          color="primary"
          @click.stop
          @change="onSelected"
        />
        <editable-text-field
          class="flex mx-3 flex-grow-1"
          :disabled="!managementRoles || isMobile"
          :value="task.description"
          @onupdate="updateDescription"
        />
        <div class="d-flex flex align-center justify-end">
          <gallery
            v-if="!isMobile && !isEmpty(task.after_images)"
            :images="task.after_images"
            :readonly="true"
            :thumbnails="false"
            icon-color="black"
            icon="mdi-paperclip"
          />
          <div
            v-if="!isInspection || isValid === false"
            class="d-inline-block px-2"
          >
            <v-badge
              offset-x="5"
              offset-y="5"
              :bordered="Boolean(hasComments)"
              class="mx-2"
              :color="hasComments ? 'success' : 'transparent'"
            >
              <span v-if="hasComments" slot="badge">{{ hasComments }}</span>
              <v-icon
                class="ml-3"
                size="22"
                color="black"
                :disabled="readonly"
                @click.stop="updateChosenListingSetupTask(task)"
              >
                $comment
              </v-icon>
            </v-badge>
          </div>

          <v-icon
            v-if="task.reported_listing_task_id || task.origin_listing_task_id"
            color="info"
            small
            @click="
              navigateTo(
                addressFor(
                  task.reported_listing_task_id || task.origin_listing_task_id
                )
              )
            "
            >$external_link</v-icon
          >
          <lt-from-subtask-modal
            v-else-if="isValid === false"
            :task-description="`${listName} - ${task.description}`"
            :selected="[task.id]"
          >
            <template #activator="{ on }">
              <v-icon color="primary" size="28" v-on="on"
                >mdi-file-document-plus</v-icon
              >
            </template>
          </lt-from-subtask-modal>

          <YesOrNo
            :disabled="isDone(listingTask.status) || readonly"
            :value="isInspection ? isValid : status"
            :multi-status="!isInspection"
            @approved="approveInspection"
            @status-changed="statusChanged"
            @rejected="rejectInspection"
            @undo="undoInspection"
          />
        </div>
      </div>
    </template>
    <div :class="selected ? 'selected-bg' : ''" class="w-100">
      <v-col class="d-flex flex-column pa-0 ml-4">
        <v-row v-if="task.ask_rating" class="align-center small-text">
          <span class="ml-3 secondary--text text-body-2 font-weight-medium"
            >Condition:</span
          >
          <v-rating
            length="5"
            size="18"
            color="notification"
            :value="task.rating"
            @input="updateRating"
            @click.stop
          >
          </v-rating>
        </v-row>
        <v-row no-gutters>
          <v-col class="text-center py-0 d-flex" cols="12">
            <gallery
              folder="service-calls"
              :uploadable="true"
              :thumbnails="true"
              :disabled="readonly"
              :remove-img="removeAfterImg"
              :rotate-image="rotateAfterImg"
              :images="task.after_images"
              :on-change="addAfterImg"
              :cols="isDesktop ? 2 : isLargeLaptop ? 2 : 4"
            />
          </v-col>
        </v-row>

        <div class="d-flex justify-space-between align-center my-2">
          <v-spacer />
          <span v-if="!readonly">
            <ConfirmationModal
              v-if="isOperationsPersonal || isCommunicationManager || isAdmin"
              text="Are you sure you want to delete this subtask?"
              @action="deleteTask(task.id)"
            >
              <template #activator="slotData">
                <v-btn
                  depressed
                  x-small
                  fab
                  dark
                  class="mx-1"
                  color="warning"
                  v-on="slotData.on"
                >
                  <v-icon small>$trash </v-icon>
                </v-btn>
              </template>
            </ConfirmationModal>
          </span>
        </div>
      </v-col>
      <div
        v-if="!isEmpty(task.log)"
        class="mt-4 pa-1 font-weight-medium grey lighten-4"
      >
        Messages:
        <v-flex
          v-for="(val, key) in task.log"
          :key="key"
          xs12
          class="grey--text"
        >
          <v-layout
            wrap
            class="pa-2 text-caption"
            :class="val['type'] === 'message' ? 'super-light-yellow' : ''"
          >
            <v-flex xs6 class="purple-icon"
              >{{ parseDateWithTime(key) }}
            </v-flex>
            <v-flex xs6 class="cyan-icon" style="text-align: right"
              >{{ val['user'] }}
            </v-flex>
            <v-flex xs12 class="align-left font-weight-medium black--text">
              {{ val['msg'] }}
            </v-flex>
          </v-layout>
        </v-flex>
      </div>
      <v-divider />
    </div>
  </expander>
</template>

<script>
import Gallery from 'components/common/gallery'
import ConfirmationModal from 'components/modals/confirmation-modal'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import deviceMixin from 'components/mixins/device-mixin'
import ColorsMixin from 'components/mixins/colors-mixin'
import size from 'lodash/fp/size'
import { mapGetters } from 'vuex'
import YesOrNo from 'components/common/yes-or-no'
import ExpoNotifications from '@/utils/expoNotifications'
import EditableTextField from 'components/form-fields/editable-text-field.vue'
import ltMixin from 'components/mixins/lt-mixin'
import Expander from 'components/common/expander.vue'
import { TASK_STATUSES } from '@/consts'
import LtFromSubtaskModal from 'components/listing-tasks/lt-from-subtask-modal.vue'
import NavigationMixin from 'components/mixins/navigation-mixin'
import ListingTaskMethodsMixin from 'components/listing-tasks/mixins/listing-task-methods-mixin'

export default {
  name: 'LtSubtaskRow',
  components: {
    LtFromSubtaskModal,
    Expander,
    YesOrNo,
    ConfirmationModal,
    Gallery,
    EditableTextField,
  },
  mixins: [
    CommonFunctions,
    deviceMixin,
    PermissionsMixin,
    ColorsMixin,
    ltMixin,
    ListingTaskMethodsMixin,
    NavigationMixin,
  ],
  props: [
    'selected',
    'task',
    'listingTask',
    'readonly',
    'isExpanded',
    'listName',
  ],
  data() {
    return {
      price: this.task.price,
      isValid: this.task.is_valid,
      status: this.task.status,
      room: this.task.dup_by,
      hours: this.task.hours,
      triggerOpen: false,
    }
  },
  computed: {
    TASK_STATUSES() {
      return TASK_STATUSES
    },
    isContractorOnPayrole() {
      return this.assignedContractor.on_payrole
    },
    isInspection() {
      return this.task.inspection_mode
    },
    hasComments() {
      return size(this.task.log)
    },
    ...mapGetters(['assignedContractor']),
  },
  methods: {
    statusChanged(value) {
      this.status = value
      this.updateTask({ status: value })
    },
    openApprovalModal({ onSuccess }) {
      this.$store.commit('showModal', {
        name: 'InspectionApprovalModal',
        props: {
          task: this.task,
          onSuccess,
          onCancel: () => {
            this.isValid = null
            this.status = this.task.status
          },
        },
      })
    },
    approveInspection() {
      if (this.isInspection) {
        this.isValid = true
      } else {
        this.status = 'Done'
      }
      if (
        (this.task.must_upload_pic && !this.task.after_images.length) ||
        this.task.ask_rating
      ) {
        this.openApprovalModal({
          onSuccess: payload => {
            if (this.isInspection) {
              this.updateTask({ is_valid: true, ...payload })
            } else {
              this.updateTask({ status: 'Done', ...payload })
            }
          },
        })
      } else {
        if (this.isInspection) {
          this.updateTask({
            is_valid: true,
          })
        } else {
          this.updateTask({
            status: 'Done',
          })
        }
      }
    },
    rejectInspection() {
      this.isValid = false
      const requiredImages =
        (this.task.must_upload_pic && !this.task.after_images.length) ||
        this.task.ask_rating
      if (requiredImages) {
        this.openApprovalModal({
          onSuccess: payload => {
            if (this.isInspection) {
              this.updateTask({ is_valid: false, ...payload })
            }
          },
        })
      } else this.updateTask({ is_valid: false })
    },
    undoInspection() {
      this.isValid = null
      this.updateTask({
        is_valid: null,
      })
    },
    deleteTask(id) {
      this.$store.dispatch('deleteListingSubTask', {
        ids: [id],
        listingTaskId: this.task.listing_task_id,
      })
    },
    priceChange(val) {
      this.updateTask({
        price: val,
      })
    },
    sendImagesUpdatedNotification() {
      ExpoNotifications.dashboardPush({
        title: 'Images Updated Successfully',
        link_task_id: this.task.id,
      })
    },
    addAfterImg(imgs) {
      this.updateTask({
        after_images: this.task.after_images.concat(imgs),
      })
      this.sendImagesUpdatedNotification()
    },
    rotateBeforeImg(oldLink) {
      return newLink => {
        const before_images = this.task.before_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ before_images })
      }
    },
    rotateAfterImg(oldLink) {
      return newLink => {
        const after_images = this.task.after_images.map(img =>
          img === oldLink ? newLink : img
        )
        this.updateTask({ after_images })
      }
    },
    removeBeforeImg(link) {
      const before_images = this.task.before_images.filter(img => img !== link)
      this.updateTask({ before_images })
    },
    removeAfterImg(link) {
      const after_images = this.task.after_images.filter(img => img !== link)
      this.updateTask({ after_images })
    },
    updateDescription(description) {
      this.updateTask({ description })
    },
    updateRating(rating) {
      this.updateTask({ rating })
    },
    updateTask(payload) {
      this.$store.dispatch('updateListingSubTask', {
        id: this.task.id,
        ...payload,
      })
    },
    onSelected(value) {
      if (value) {
        this.$emit('selected', this.task.id)
      } else {
        this.$emit('unselected', this.task.id)
      }
    },
    addressFor(id) {
      return `/dashboard/listing-task/${id}`
    },
  },
}
</script>

<style scoped>
div >>> .v-badge__badge {
  height: 18px;
  min-width: 18px;
  font-size: 10px;
  padding: 5px 6px;
}

.selected-bg {
  background: rgba(21, 125, 194, 0.05);
}

.icon {
  order: 0;
}

.header {
  order: 1;
}
</style>
