var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:[{ 'ml-auto mr-4': !_vm.isGuest }, { 'ml-4': _vm.isGuest }, 'my-2'],attrs:{"max-width":_vm.isMobile ? '85%' : '60%',"elevation":"0"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('div',{class:[
        'd-flex',
        'align-center',
        { 'flex-row-reverse': !_vm.isGuest },
        'text-caption',
        'black--text',
        'mb-0',
      ]},[(
          !_vm.isGuest &&
          !_vm.msg.auto_message_rule_id &&
          _vm.conversation.active_reservation
        )?_c('ai-train-modal',{attrs:{"train-messages":_vm.trainMessages,"conversation":_vm.conversation,"msg":_vm.msg}}):_vm._e(),(_vm.msg.auto_message_rule_id)?_c('span',{staticClass:"font-weight-medium"},[_vm._v("Auto")]):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.isGuest ? _vm.conversation.guest_full_name : _vm.msg.rep_name))]),(_vm.msg.msg_type)?_c('v-icon',[_vm._v("mdi-circle-small")]):_vm._e(),(_vm.msg.msg_type)?_c('span',[_vm._v(" via "+_vm._s(_vm.msg.msg_type)+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex"},[_c('v-sheet',{staticClass:"mx-2 pa-4 font-weight-medium flex rounded-xl overflow-y-auto",class:[
          { 'grey lighten-4': !_vm.isGuest && !_vm.isNote && !_vm.isFailed },
          { 'info lighten-5': _vm.isGuest && !_vm.isFailed },
          { 'notification lighten-5': _vm.isNote },
          { 'rounded-tl-0': _vm.isGuest, 'rounded-tr-0': !_vm.isGuest },
          { 'msg-failed': _vm.isFailed },
        ],attrs:{"elevation":_vm.isGuest ? 3 : 0,"rounded":""}},[_c('div',{staticClass:"p-relative",class:[{ trw: _vm.collapsable, 'height-trans': _vm.collapsed }]},[_c('div',{staticClass:"msg-box font-weight-medium text-caption text-md-body-2",domProps:{"innerHTML":_vm._s(_vm.switchNewLine(_vm.msg.body))}})]),(_vm.collapsable)?_c('div',{staticClass:"d-flex justify-end flex align-end"},[_c('v-btn',{attrs:{"plain":"","text":""},on:{"click":_vm.onCollapse}},[_c('v-icon',[_vm._v(_vm._s(_vm.collapsed ? 'fas fa-caret-up' : 'fas fa-caret-down'))])],1)],1):_vm._e(),(_vm.msg.recording_url)?_c('div',{staticClass:"mt-2"},[_c('audio',{staticClass:"small-audio-player",attrs:{"controls":"","src":_vm.msg.recording_url}})]):_vm._e()]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[(_vm.msg.ai_translation)?_c('span',{staticClass:"cyan--text tooltip"},[_c('v-icon',{staticClass:"mr-2 cyan--text tooltipicon"},[_vm._v("mdi-translate")]),_c('span',{staticClass:"tooltiptext"},[_vm._v(" "+_vm._s(_vm.msg.ai_translation)+" ")])],1):_vm._e(),(_vm.transcodedAudios(_vm.msg))?_c('span',{staticClass:"cyan--text tooltip"},[_c('v-icon',{staticClass:"mr-2 cyan--text tooltipicon"},[_vm._v("mdi-transcribe")]),_c('span',{staticClass:"tooltiptext"},[_vm._v(" "+_vm._s(_vm.transcodedAudios(_vm.msg))+" ")])],1):_vm._e()])],1),_c('div',{class:[
        'd-flex',
        { 'justify-end': !_vm.isGuest },
        'align-center',
        'text-caption',
        'grey--text',
        'mt-1',
      ]},[(_vm.msg.is_pre_made)?_c('div',[_c('v-chip',{attrs:{"color":"grey lighten-4","x-small":""}},[_c('span',{staticClass:"secondary--text"},[_vm._v("Premade")])]),_c('v-icon',[_vm._v("mdi-circle-small")])],1):_vm._e(),(_vm.isToday(_vm.msg.sent_at))?_c('span',[_vm._v(" "+_vm._s(_vm.parseDateTZ(_vm.msg.sent_at, 'hh:mm A', _vm.timezone))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.parseDateTZ(_vm.msg.sent_at, 'D MMM YYYY, hh:mm A', _vm.timezone))+" ")]),(_vm.msg.status)?_c('span',{staticClass:"ml-2"},[(['read', 'delivered'].includes(_vm.msg.status))?_c('v-icon',{attrs:{"dark":"","color":_vm.msg.status === 'read' ? 'blue' : 'grey',"small":""}},[_vm._v("mdi-check-all")]):_vm._e(),(['queued'].includes(_vm.msg.status))?_c('v-icon',{attrs:{"dark":"","color":"grey","small":""}},[_vm._v("mdi-progress-clock")]):_vm._e(),(_vm.msg.status === 'sent')?_c('v-icon',{attrs:{"dark":"","color":"grey","small":""}},[_vm._v("mdi-check")]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.isFailed)?_c('v-icon',_vm._g({attrs:{"color":"error"}},_vm.msg.error_msg && on),[_vm._v("mdi-alert-circle ")]):_vm._e()]}}],null,false,967625278)},[_c('div',{staticClass:"msg-error"},[_vm._v(" "+_vm._s(_vm.msg.error_msg)+" ")])])],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }