<template>
  <div>
    <v-expansion-panels v-if="warranties.length" mandatory>
      <v-expansion-panel v-for="item in warranties" :key="item.id">
        <v-expansion-panel-header>
          <div>
            Task #{{ item.listing_task_id }}
            <v-btn
              color="primary"
              small
              class="text-capitalize mx-2"
              :to="`/dashboard/listing-task/${item.listing_task_id}`"
              target="_blank"
              elevation="0"
            >
              Open task
            </v-btn>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="auto">
              <div class="text-caption text-secondary">Expires at</div>
              <p>{{ parseDate(item.expired_at, 'DD MMM YYYY') }}</p>
            </v-col>
            <v-col cols="auto">
              <div class="text-caption text-secondary">
                {{ $t('Description') }}
              </div>
              <p>{{ item.description }}</p>
            </v-col>
          </v-row>
          <v-row>
            <gallery
              :folder="`warranties/${listingNicknameById(listingId)}`"
              :thumbnails="true"
              :uploadable="true"
              :images="item.images"
              :on-change="images => addWarrantyImages(item, images)"
              :cols="2"
            />
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      v-else
      class="text-h5 text-center font-weight-light secondary--text my-6"
    >
      Warranties are empty
    </div>
  </div>
</template>

<script>
import Common_functions from 'components/mixins/common_functions'
import Gallery from 'components/common/gallery.vue'

export default {
  name: 'Warranties',
  components: { Gallery },
  props: ['warranties'],
  mixins: [Common_functions],
  computed: {
    listingId() {
      return +this.$route.params.id
    },
  },
  methods: {
    addWarrantyImages(item, images) {
      item.images = item.images.concat(images)
      this.$store.dispatch('updateListingTask', {
        id: item.listing_task_id,
        receipt_images: item.images,
      })
    },
  },
}
</script>

<style scoped></style>
