import axios from 'axios'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    activeProjects: [],
  },
  mutations: {
    updateProjects(state, value) {
      state.activeProjects = value
    },
    createProject({ activeProjects }, value) {
      activeProjects.unshift(value)
    },
    updateProject({ activeProjects }, value) {
      const index = activeProjects.findIndex(project => project.id === value.id)
      const updatedProject = { ...activeProjects[index], ...value }
      Vue.set(activeProjects, index, updatedProject)
    },
    updateListingTasks({ activeProjects }, { index, data }) {
      activeProjects[index].listingTasks = data
      Vue.set(activeProjects, index, activeProjects[index])
    },
  },
  actions: {
    async getProjects({ commit }, params) {
      const { data } = await axios.get('/api/projects', {
        params,
      })
      commit('updateProjects', data)
    },
    async createProject({ commit }, payload) {
      const { data } = await axios
        .post('/api/projects', payload)
        .catch(error => console.log(error))
      commit('createProject', data)
    },
    async updateProject({ commit }, payload) {
      const { data } = await axios
        .post(`/api/projects/${payload.id}`, payload)
        .catch(error => console.log(error))
      commit('updateProject', data)
    },
    async getProjectTasks({ commit }, { projectId, index }) {
      axios.get(`/api/projects/${projectId}/listing-tasks`).then(res => {
        commit('updateListingTasks', { index, data: res.data })
      })
    },
    async deleteProject({ dispatch }, projectId) {
      const { data } = await axios
        .delete(`/api/projects/${projectId}`)
        .catch(error => console.log(error))
      if (data.message === 'OK') dispatch('getProjects')
    },
    async createProjectLaborExpense(store, { projectId, payload }) {
      return axios
        .post(`/api/projects/${projectId}/labor-expense`, payload)
        .then(res => res.data)
    },
    async createProjectMaterialExpense(store, { projectId, payload }) {
      return axios
        .post(`/api/projects/${projectId}/material-expense`, payload)
        .then(res => res.data)
    },
  },
}
