var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.companyActivity)?_c('v-layout',{staticClass:"comp-wrapper",attrs:{"wrap":"","pa-3":"","mt-2":""}},[_c('v-flex',{staticClass:"comp-wrapper",attrs:{"xs6":""}},[_c('span',{staticClass:"text-h5 purple-icon"},[_vm._v("On call Communication")]),_c('v-layout',{attrs:{"column":""}},_vm._l((_vm.onCallCommunication),function(communicationPerson){return _c('v-flex',{key:communicationPerson.name,staticClass:"communicationPerson.clocked_in ? 'cyan-icon' : 'grey-text'"},[_vm._v(" "+_vm._s(communicationPerson.name)+" - "),(communicationPerson.clocked_in)?_c('span',{staticClass:"cyan-icon"},[_vm._v("Clocked in since "+_vm._s(communicationPerson.last_clock_in))]):_vm._e(),(!communicationPerson.clocked_in)?_c('span',{staticClass:"grey--text"},[_vm._v("Last clock out "+_vm._s(communicationPerson.last_clock_out))]):_vm._e()])}),1)],1),_c('v-flex',{staticClass:"comp-wrapper",attrs:{"xs6":""}},[_c('span',{staticClass:"text-h5 purple-icon"},[_vm._v("On call PM")]),_c('v-layout',{attrs:{"column":""}},_vm._l((_vm.onCallPm),function(pm){return _c('v-flex',{key:pm.name},[_vm._v(" "+_vm._s(pm.name)+" - "),(pm.clocked_in)?_c('span',{staticClass:"cyan-icon"},[_vm._v("Clocked in since "+_vm._s(pm.last_clock_in))]):_vm._e(),(!pm.clocked_in)?_c('span',{staticClass:"grey--text"},[_vm._v("Last clock out "+_vm._s(pm.last_clock_out))]):_vm._e()])}),1)],1),_c('v-flex',{attrs:{"xs12":"","mt-5":""}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"500px"},attrs:{"center":{
          lat: _vm.center[0],
          lng: _vm.center[1],
        },"zoom":9,"options":{ disableDefaultUI: true },"map-type-id":"terrain"}},_vm._l((_vm.companyActiveUsers),function(item){return _c('GmapMarker',{key:item.id,attrs:{"label":{ text: item.name, color: 'black' },"position":{
            lat: item.lat,
            lng: item.lng,
          }}})}),1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }