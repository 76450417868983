import axios from 'axios'

export default {
  namespaced: true,
  state: {
    salesKpi: null,
    customerSupportKpi: null,
  },
  mutations: {
    updateSalesKpi(state, data) {
      state.salesKpi = data
    },
    updateCustomerSupportKpi(state, data) {
      state.customerSupportKpi = data
    },
  },
  actions: {
    async getSalesKpi({ commit }, dateTime = null) {
      const { data } = await axios.get(`/api/kpis/sales`, {
        params: { date_time: dateTime },
      })
      commit('updateSalesKpi', data)
      return data
    },
    async getCustomerSupportKpi({ commit }, dateTime = null) {
      const { data } = await axios.get(`/api/kpis/customer-support`, {
        params: { date_time: dateTime },
      })
      commit('updateCustomerSupportKpi', data)
      return data
    },
  },
}
