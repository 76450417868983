<template>
  <v-dialog
    v-model="dialog"
    width="870"
    :fullscreen="isMobile"
    @click:outside="closeDialog"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        small
        block
        text
        color="info"
        elevation="0"
        class="rounded-0 justify-start"
        v-on="on"
      >
        <v-icon small>$shopping_cart</v-icon>
        <span class="ml-2">Shopping list</span>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <div class="p-relative flex text-center">
          <span class="text-h6">Shopping List</span>
          <v-icon class="close-button" @click="closeDialog">close</v-icon>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text v-if="!addItemMode" class="py-4">
        <v-list>
          <shopping-list-item
            v-for="(item, index) in shopItemsToAdd"
            :key="`item-to-add-${index + 1}`"
            :item="item"
            removable
            @remove-item="removeShopItem(index)"
          />
        </v-list>
        <div class="text-center">
          <v-btn
            color="secondary"
            elevation="0"
            text
            @click="addItemMode = true"
          >
            <v-icon>mdi-plus</v-icon>
            <span class="font-weight-medium">Add more</span>
          </v-btn>
        </div>
        <p class="font-weight-medium">Shopping List Items</p>
        <v-list>
          <shopping-list-item
            v-for="item in listingTask.shopping_items"
            :key="item.id"
            :item="item"
          />
        </v-list>
      </v-card-text>
      <v-card-text v-else class="py-4">
        <div class="font-weight-medium">New Shopping List Item</div>
        <v-form ref="addShopItemForm">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="4">
              <v-autocomplete
                v-model="newItem.store_name"
                :items="shoppingItemsResources"
                label="Store"
                item-text="nickname"
                item-value="nickname"
                :rules="[required]"
                outlined
                dense
                :return-object="false"
                @change="storeChanged"
              />
            </v-col>
            <v-col cols="12" sm="6" md="8" lg="5">
              <v-autocomplete
                v-if="isStorage"
                v-model="newItem.name"
                :search-input.sync="searchTerm"
                :items="inventoryItems"
                :loader-height="3"
                item-text="title"
                item-value="title"
                prepend-inner-icon="$search"
                :rules="[required]"
                outlined
                dense
                @input="searchTerm = ''"
              />
              <shop-item-field
                v-else
                v-model="newItem.name"
                :outlined="true"
                label="Store"
                :rules="[required]"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field
                v-model="newItem.quantity"
                type="number"
                label="Quantity"
                :rules="[required]"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12">
              <dvr-media-upload
                :preview="true"
                :thumbnails="true"
                folder="service-calls"
                :value.sync="newItem.images"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          elevation="0"
          class="text-capitalize"
          text
          @click="addItemMode ? closeNewItem() : closeDialog()"
        >
          {{ addItemMode ? 'Cancel' : 'Close' }}
        </v-btn>
        <v-spacer />
        <v-btn
          elevation="0"
          outlined
          color="notification"
          class="text-capitalize"
          @click="moveToCant"
        >
          Move to Can't do
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="text-capitalize"
          :disabled="addItemMode ? false : !canSave"
          @click="addItemMode ? addItem() : createItems()"
        >
          {{ addItemMode ? 'Add' : 'Save' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DeviceMixin from 'components/mixins/device-mixin'
import ShoppingListItem from 'components/shopping-list/shopping-list-item'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import DvrMediaUpload from 'components/common/dvr-media-upload'
import ShopItemField from 'components/form-fields/shop-item-field'
export default {
  name: 'ShoppingListModal',
  components: {
    ShopItemField,
    DvrMediaUpload,
    ShoppingListItem,
  },

  mixins: [DeviceMixin, FormRulesMixin],
  props: {
    listingTask: Object,
  },
  data() {
    return {
      dialog: false,
      searchTerm: '',
      estimatedArrivalMenu: false,
      addItemMode: false,
      newItem: {
        images: [],
        status: 'Pending purchase',
      },
      shopItemsToAdd: [],
    }
  },
  computed: {
    ...mapState({
      inventoryItems: state => state.inventoryItems,
    }),
    ...mapState('storages', ['storages']),
    ...mapGetters('storages', ['shoppingItemsResources']),
    canSave() {
      return Boolean(this.shopItemsToAdd.length)
    },
    isStorage() {
      return this.storages.find(s => s.nickname === this.newItem.store_name)
    },
  },
  methods: {
    ...mapActions('shoppingList', ['createShopItems']),
    ...mapActions('storages', ['getStoresNames']),
    ...mapActions(['getInventoryItems']),
    storeChanged(val) {
      if (this.isStorage) {
        this.getInventoryItems({ storage: val })
      }
      this.newItem.name = null
    },
    addItem() {
      if (this.$refs.addShopItemForm.validate()) {
        const {
          id,
          listing_id,
          assigned_contractor_id,
          priority,
          listing_task_catalog_item,
        } = this.listingTask
        const data = {
          ...this.newItem,
          listing_id,
          requested_by_id: assigned_contractor_id,
          division: listing_task_catalog_item.division,
          origin_task_id: id,
          priority,
        }
        this.shopItemsToAdd.push(data)
        this.closeNewItem()
      }
    },
    removeShopItem(index) {
      this.shopItemsToAdd.splice(index, 1)
    },
    resetShopItems() {
      this.shopItemsToAdd = []
    },
    async createItems() {
      await this.createShopItems({
        listing_task_id: this.listingTask.id,
        data: this.shopItemsToAdd,
      })
      this.resetShopItems()
    },
    removeImage(link) {
      this.newItem.images = this.newItem.images.filter(img => img !== link)
    },
    closeNewItem() {
      this.newItem = {
        images: [],
        status: 'Pending purchase',
      }
      this.addItemMode = false
    },
    closeDialog() {
      this.closeNewItem()
      this.dialog = false
    },
    moveToCant() {
      this.$store.dispatch('rejectTask', {
        id: this.listingTask.id,
        status: "Can't do",
        reason: "Can't complete task, waiting for items arrival",
        topic: 'administrative',
      })
      this.closeDialog()
    },
  },
  mounted() {
    this.getStoresNames()
  },
}
</script>

<style scoped></style>
