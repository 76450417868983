<template>
  <div class="virtual-scroller-slot-wrapper" :style="{ width, height }">
    <div v-if="isLoader" class="full flex-center">
      <v-progress-circular :size="20" indeterminate color="primary" />
    </div>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'VirtualScrollerSlotWrapper',
  props: {
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    isLoader: {
      type: Boolean,
      default: false,
    },
    isLoaderMount: {
      type: Function,
      default: () => undefined,
    },
  },
  mounted() {
    if (this.isLoader && this.isLoaderMount) this.isLoaderMount()
  },
}
</script>
