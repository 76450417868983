<template>
  <v-sheet
    :class="{ 'primary--text border-primary': active }"
    outlined
    elevation="2"
    class="rounded-lg"
    :height="height"
  >
    <v-list-item
      color="primary"
      :class="{
        'primary--text text--darken-1': true,
      }"
      :disabled="disabled"
      class="align-start px-2"
      :ripple="false"
      style="height: 100%; pointer-events: all"
      @click="$emit('changed', filterValue)"
    >
      <v-list-item-content class="align-self-auto align-start py-2">
        <v-list-item-title
          class="text-body-2 font-weight-medium"
          :class="{
            'grey--text': !active,
            'primary--text text--darken-1': active,
          }"
        >
          <v-row class="align-center px-3">
            <v-icon
              v-if="icon && !isMobileOrIPad"
              color="inherit"
              class="mr-1"
              small
              >{{ icon }}</v-icon
            >
            <span
              class="mr-1"
              :class="active ? 'primary--text' : 'black--text'"
            >
              <v-tooltip v-if="tooltip" bottom>
                <template #activator="{ on, attrs }">
                  <span class="small-tab" v-bind="attrs" v-on="on">
                    {{ title }}
                  </span>
                </template>
                <div>
                  {{ tooltip }}
                </div>
              </v-tooltip>
              <span v-else>
                {{ title }}
              </span>
            </span>
            <delta-tag
              :inverted="Boolean(deltaInverted)"
              class="ml-auto"
              :value="delta"
            />
          </v-row>
        </v-list-item-title>

        <v-list-item-subtitle
          class="d-flex font-weight-medium my-1"
          :class="['black--text', { 'primary--text text--darken-1': active }]"
        >
          <slot name="value" />
          <div
            v-if="typeof value !== 'undefined' && !$slots.value"
            class="d-flex align-center"
          >
            <span class="text-subtitle-1 font-weight-medium">{{ value }} </span>
            <div class="d-flex ml-3">
              <span
                class="text-caption small-text grey--text font-weight-medium"
              >
                {{ secondaryText }}
              </span>
              <span
                class="text-caption small-text black--text font-weight-medium mx-2"
              >
                {{ secondaryValue }}
              </span>
            </div>
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-sheet>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import DeltaTag from 'components/common/delta-tag'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'DataFilterButton',
  components: { DeltaTag },
  props: [
    'value',
    'height',
    'tooltip',
    'disabled',
    'title',
    'filterValue',
    'active',
    'icon',
    'delta',
    'deltaInverted',
    'secondaryValue',
    'secondaryText',
  ],
  mixins: [FormattersMixin, DeviceMixin],
}
</script>

<style scoped>
.border-primary {
  border-color: var(--v-primary-darken1) !important;
}
</style>
