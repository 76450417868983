<template>
  <v-layout wrap align-center>
    <v-row class="align-center">
      <v-col cols="6" sm="4" lg="2">
        <v-text-field
          v-model="filters.search_term"
          label="Search"
          prepend-inner-icon="search"
          outlined
          dense
          hide-details
          @input="debouncedFetchCoupons"
        />
      </v-col>

      <v-col cols="6" sm="4" lg="2">
        <v-checkbox
          v-model="filters.valid"
          label="Valid-only"
          class="checkbox-txt"
          @change="getCoupons"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-flex xs12 class="right-text">
      <form-dialog title="New coupon" max-width="380" @submit="submitForm">
        <template #activator="{ on }">
          <v-btn color="primary" elevation="0" small outlined v-on="on">
            <v-icon>add</v-icon>
            <span class="text-capitalize ml-1">Add Coupone</span>
          </v-btn>
        </template>

        <v-card-text>
          <v-select
            ref="discountSelect"
            v-model="formData.price_rule_id"
            :items="itemsForSelect"
            label="Select or Create Discount"
            item-text="description"
            item-value="id"
            :rules="newPriceRule ? [] : [required]"
            @click="handleSelectClick"
            @change="onSelectChange"
          >
            <template #prepend-item>
              <v-list-item @click="onNewPriceRule">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold"
                    >New price rule</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>

          <div v-if="newPriceRule && !formData.price_rule_id">
            <v-text-field
              v-model="formData.description"
              dense
              outlined
              :rules="[required]"
              :label="$t('Description')"
            />

            <v-select
              v-model="formData.discount_type"
              label="Type"
              outlined
              :rules="[required]"
              :items="[
                { name: 'Percentage', value: 'percentage' },
                { name: 'Fixed amount', value: 'fixed_amount' },
              ]"
              item-text="name"
              item-value="value"
              dense
            />

            <v-text-field
              v-model="formData.discount_value"
              :label="$t('Discount')"
              type="number"
              outlined
              :rules="positiveNumberRule"
              :min="1"
              hint="Enter a positive number"
            ></v-text-field>

            <v-text-field
              v-model="formData.min_stay_nights"
              :label="$t('Min nights')"
              type="number"
              outlined
              :rules="positiveNumberRule"
              :min="1"
              hint="Enter a positive number"
            ></v-text-field>

            <multi-property-picker
              v-if="!listingLoading"
              v-model="formData.applies_to"
            />
          </div>

          <v-row class="align-center">
            <v-col> New coupon </v-col>

            <v-col class="text-right">
              <v-btn
                color="primary"
                elevation="0"
                small
                outlined
                @click.stop="generateCouponCode"
              >
                <span class="text-capitalize ml-1">Generate Code</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-text-field
            v-model="formData.code"
            dense
            outlined
            :rules="[required]"
            :label="$t('Code')"
          />

          <v-row>
            <date-picker
              label="From"
              :value="parseDate(formData.valid_from)"
              :reactive="true"
              :min="new Date().toISOString().substr(0, 10)"
              :max="
                new Date(new Date().setMonth(new Date().getMonth() + 10))
                  .toISOString()
                  .substr(0, 10)
              "
              :required="true"
              :dense="true"
              :hide-details="true"
              :flat="true"
              :solo="true"
              :outlined="false"
              text-field-class="text-caption"
              @change="setDateFrom($event)"
            >
              <template #prepend-inner>
                <v-icon small color="info">fas fa-calendar-alt</v-icon>
              </template>
            </date-picker>

            <date-picker
              label="Until"
              :value="parseDate(formData.valid_until)"
              :reactive="true"
              :min="new Date().toISOString().substr(0, 10)"
              :max="
                new Date(new Date().setMonth(new Date().getMonth() + 10))
                  .toISOString()
                  .substr(0, 10)
              "
              :required="true"
              :dense="true"
              :hide-details="true"
              :flat="true"
              :solo="true"
              :outlined="false"
              text-field-class="text-caption"
              @change="setDateTo($event)"
            >
              <template #prepend-inner>
                <v-icon small color="info">fas fa-calendar-alt</v-icon>
              </template>
            </date-picker>

            <v-col v-if="formData.valid_until && formData.valid_from">
              <span class="text-caption">Exclude dates</span>
              <v-date-picker
                v-model="formData.exclude_dates"
                multiple
                :min="formData.valid_from"
                :max="formData.valid_until"
              />
            </v-col>
          </v-row>

          <v-select
            v-model="usageType"
            label="Type"
            outlined
            :rules="[required]"
            :items="usageTypes"
            dense
          />

          <v-text-field
            v-if="usageType == 'Multiple Uses (Limited)'"
            v-model="formData.usage_limit"
            label="Limit"
            type="number"
            outlined
            :rules="positiveNumberRule"
            :min="0"
            hint="Enter a positive number"
          ></v-text-field>
        </v-card-text>
      </form-dialog>

      <v-dialog v-model="dialogEdit" max-width="600">
        <v-card>
          <v-card-title>Update Coupone</v-card-title>
          <v-form ref="formEdit" @submit.prevent="submitFormEditCoupon">
            <v-card-text>
              <v-row>
                <date-picker
                  label="From"
                  :value="parseDate(editFormData.valid_from)"
                  :reactive="true"
                  :min="new Date().toISOString().substr(0, 10)"
                  :max="
                    new Date(new Date().setMonth(new Date().getMonth() + 10))
                      .toISOString()
                      .substr(0, 10)
                  "
                  :required="true"
                  :dense="true"
                  :hide-details="true"
                  :flat="true"
                  :solo="true"
                  :outlined="false"
                  text-field-class="text-caption"
                  @change="setEditFormDateFrom($event)"
                >
                  <template #prepend-inner>
                    <v-icon small color="info">fas fa-calendar-alt</v-icon>
                  </template>
                </date-picker>

                <date-picker
                  label="Until"
                  :value="parseDate(editFormData.valid_until)"
                  :reactive="true"
                  :min="new Date().toISOString().substr(0, 10)"
                  :max="
                    new Date(new Date().setMonth(new Date().getMonth() + 10))
                      .toISOString()
                      .substr(0, 10)
                  "
                  :required="true"
                  :dense="true"
                  :hide-details="true"
                  :flat="true"
                  :solo="true"
                  :outlined="false"
                  text-field-class="text-caption"
                  @change="setEditFormDateTo($event)"
                >
                  <template #prepend-inner>
                    <v-icon small color="info">fas fa-calendar-alt</v-icon>
                  </template>
                </date-picker>
              </v-row>

              <v-text-field
                v-if="!editFormData.one_time_use"
                v-model="editFormData.usage_limit"
                label="Limit"
                type="number"
                outlined
                :rules="positiveNumberRule"
                :min="0"
                hint="Enter a positive number"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="secondary"
                class="text-capitalize"
                text
                @click="closeDialogEdit"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                class="text-capitalize"
                elevation="0"
                type="submit"
                :disabled="
                  !editFormData.description && !editFormData.price_rule_id
                "
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-flex>

    <v-flex xs12 mt-3>
      <v-data-table
        v-if="items"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="pagination.totalItems"
        class="elevation-1"
        @pagination="paginationChanged($event)"
      >
        <template #item="{ item }">
          <tr>
            <td>
              <span v-if="item.price_rule.description">{{
                item.price_rule.description
              }}</span>
              <span v-else>Not specified</span>
            </td>
            <td>
              <span v-if="item.valid_from">{{
                parseDate(item.valid_from, 'MM/DD/YYYY')
              }}</span>
              <span v-else>{{ parseDate(item.created_at, 'MM/DD/YYYY') }}</span>
            </td>
            <td>
              <span v-if="item.valid_until">{{
                parseDate(item.valid_until, 'MM/DD/YYYY')
              }}</span>
              <span v-else>Indefinite</span>
            </td>
            <td>
              <v-tooltip left>
                <template #activator="{ on }">
                  <span
                    v-if="item.exclude_dates && item.exclude_dates.length > 0"
                    v-on="on"
                  >
                    Off days
                  </span>
                  <span v-else>Unspecified</span>
                </template>
                <span>{{ item.exclude_dates.join(' | ') }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-text-field
                v-if="item.code"
                :name="'password_' + item.id"
                :value="item.code"
                readonly
                :append-icon="getAppendIcon(item.id)"
                :type="getInputType(item.id)"
                @click:append="toggleVisibility(item.id)"
              ></v-text-field>
              <span v-else>Not specified</span>
            </td>
            <td>
              <span v-if="item.status">{{ item.status }}</span>
              <span v-else>Not specified</span>
            </td>
            <td>
              <span v-if="isValidToday(item)">
                <v-icon small color="success">circle</v-icon>
              </span>
              <span v-else>
                <v-icon small color="warning">circle</v-icon>
              </span>
            </td>
            <td>
              <span v-if="item.price_rule.discount_value">
                <span v-if="item.price_rule.discount_type">{{
                  item.price_rule.discount_type == 'percentage'
                    ? '%'
                    : currencySign
                }}</span>
                {{ item.price_rule.discount_value }}</span
              >

              <span v-else>Not specified</span>
            </td>
            <td>
              <span v-if="item.price_rule.min_stay_nights">{{
                item.price_rule.min_stay_nights
              }}</span>
              <span v-else>Not specified</span>
            </td>
            <td>
              <div
                v-if="item.price_rule.applies_to"
                style="width: 150px; max-height: 150px"
                class="overflow-auto"
              >
                <span>
                  {{ item.price_rule.applies_to }}
                </span>
              </div>

              <span v-else>Not specified</span>
            </td>
            <td>
              <span v-if="typeof item.one_time_use == 'boolean'">{{
                item.one_time_use == true ? 'One-time' : 'Multiple'
              }}</span>
              <span v-else>Not specified</span>
            </td>
            <td>
              <span>{{
                item.one_time_use == true ? 1 : item.usage_limit || 'unlimited'
              }}</span>
            </td>
            <td>
              <span>{{ item.current_uses }}</span>
            </td>
            <td>
              <span>{{ item.redeemed_count }}</span>
            </td>
            <td>
              <v-btn
                v-if="item.one_time_use == false && item.status != 'active'"
                icon
                class="mr-2"
                @click.stop="openDialogEdit(item)"
                ><v-icon small>edit</v-icon></v-btn
              >

              <confirmation-modal
                :text="`Are you sure you want to delete ${item.price_rule.description}`"
                @action="deleteCoupon(item.id)"
              >
                <template #activator="{ on }">
                  <v-btn icon class="mr-2" v-on="on"
                    ><v-icon small>$trash</v-icon></v-btn
                  >
                </template>
              </confirmation-modal>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-layout>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import DatePicker from 'components/form-fields/date-picker.vue'
import MultiPropertyPicker from './multi-property-picker.vue'
import { nanoid } from 'nanoid'
import FormDialog from 'components/common/form-dialog'

export default {
  components: {
    DatePicker,
    MultiPropertyPicker,
    ConfirmationModal,
    FormDialog,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin, FormRulesMixin],
  props: [],
  data: () => ({
    filters: {
      valid: false,
      search_term: '',
    },
    visibleCode: {},
    formData: {
      exclude_dates: [],
      price_rule_id: null,
      code: '',
      valid_from: null,
      valid_until: null,
      one_time_use: false,

      description: '',
      min_stay_nights: 1,
      discount_type: null,
      discount_value: null,
      applies_to: [],
    },
    editFormData: {},
    pagination: {
      page: 1,
      itemsPerPage: 10,
      totalItems: null,
    },
    dialog: false,
    dialogEdit: false,
    headers: [
      { text: 'Description', sortable: false },
      { text: 'From', sortable: false },
      { text: 'To', sortable: false },
      { text: 'Exclude dates', sortable: false },
      { text: 'Code', sortable: false },
      { text: 'Status', sortable: false },
      { text: 'Valid today', sortable: false },
      { text: 'Discount', sortable: false },
      { text: 'Min nights', sortable: false },
      { text: 'Property ids', sortable: false },
      { text: 'Usage type', sortable: false },
      { text: 'Usage limit', sortable: false },
      { text: 'Usage attepts', sortable: false },
      { text: 'Usage Count', sortable: false },
      { text: 'Actions', sortable: false },
    ],
    newPriceRule: false,
    usageTypes: [
      'Single Use Only',
      'Multiple Uses (Limited)',
      'Unlimited Uses',
    ],
    usageType: null,
  }),
  computed: {
    items() {
      return this.$store.state.coupons
    },
    itemsForSelect() {
      return this.$store.state.couponsRules
    },
    positiveNumberRule() {
      return [
        value => !!value || 'Value is required',
        value =>
          /^[0-9]+(\.[0-9]+)?$/.test(value) ||
          'Value must be a positive number',
      ]
    },
  },
  watch: {
    '$store.state.couponsPagination.count': {
      immediate: true,
      handler(newTotalCount) {
        this.pagination.totalItems = newTotalCount
      },
    },
    usageType(newVal) {
      this.formData.one_time_use = newVal === 'Single Use Only'
    },
  },
  methods: {
    isValidToday(item) {
      const today = new Date().setHours(0, 0, 0, 0)
      const validFrom = item.valid_from
        ? new Date(item.valid_from).setHours(0, 0, 0, 0)
        : null
      const validUntil = item.valid_until
        ? new Date(item.valid_until).setHours(0, 0, 0, 0)
        : null

      return (
        item.status &&
        ((!validFrom && !validUntil) ||
          (validFrom <= today && today <= validUntil))
      )
    },
    getCoupons() {
      this.$store.dispatch('getCoupons', {
        ...this.filters,
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
      })
    },
    debouncedFetchCoupons() {
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.getCoupons()
      }, 300)
    },
    openModal() {
      this.dialog = true
    },
    closeModal() {
      this.clearFromData()
      this.$refs.form.resetValidation()
      this.dialog = false
    },
    openDialogEdit(item) {
      this.editFormData = item
      this.dialogEdit = true
    },
    closeDialogEdit() {
      this.dialogEdit = false
      this.clearFromDataEdit()
      this.$refs.formEdit.resetValidation()
    },
    clearFromDataEdit() {
      this.editFormData = {}
    },
    clearFromData() {
      this.formData = {
        exclude_dates: [],
        price_rule_id: null,
        code: '',
        valid_from: null,
        valid_until: null,
        one_time_use: false,
        description: '',
        min_stay_nights: 1,
        discount_type: null,
        discount_value: null,
        applies_to: [],
      }
    },
    submitForm() {
      let formDataCopy = { ...this.formData }
      if (formDataCopy.exclude_dates.length > 0) {
        formDataCopy.exclude_dates = formDataCopy.exclude_dates.map(date =>
          this.parseDate(date)
        )
      }
      this.$store.dispatch('createCoupon', formDataCopy)
      this.clearFromData()
    },
    submitFormEditCoupon() {
      if (this.$refs.formEdit.validate()) {
        this.editCoupon(this.editFormData)
        this.clearFromDataEdit()
        this.closeDialogEdit()
      }
    },
    deleteCoupon(couponId) {
      this.$store.dispatch('deleteCoupon', couponId)
    },
    editCoupon(coupon) {
      this.$store.dispatch('editCoupon', coupon)
    },
    generateCouponCode() {
      this.formData.code = nanoid()
    },
    toggleVisibility(itemId) {
      this.$set(this.visibleCode, itemId, !this.visibleCode[itemId])
    },
    getAppendIcon(itemId) {
      return this.visibleCode[itemId] ? 'visibility' : 'visibility_off'
    },
    getInputType(itemId) {
      return this.visibleCode[itemId] ? 'text' : 'password'
    },
    setDateFrom(value) {
      this.formData.valid_from = value
    },
    setDateTo(value) {
      this.formData.valid_until = value
    },
    setEditFormDateFrom(value) {
      this.editFormData.valid_from = value
    },
    setEditFormDateTo(value) {
      this.editFormData.valid_until = value
    },
    paginationChanged() {
      this.getCoupons()
    },
    onSelectChange(value) {
      this.formData.price_rule_id = value
    },
    handleSelectClick() {
      this.$store.dispatch('getCouponsRules')
    },
    onNewPriceRule() {
      this.clearFromData()
      this.newPriceRule = true
      this.$nextTick(() => {
        this.$refs.discountSelect.isMenuActive = false
      })
    },
  },
}
</script>
