<template>
  <v-container fluid class="px-8">
    <v-row class="align-center">
      <v-col cols="6" md="4" lg="3">
        <v-text-field
          v-model="searchTerm"
          flat
          dense
          outlined
          label="Search by description..."
          append-icon="search"
          solo-inverted
          hide-details
        />
      </v-col>
      <v-col cols="6" md="4" lg="3">
        <v-combobox
          v-model="chosenDivision"
          :items="divisions"
          clearable
          label="Division"
          dense
          hide-details
          outlined
        />
      </v-col>
      <v-spacer class="hidden-xs-only" />
      <v-col cols="auto">
        <v-switch v-model="isArchived" label="Show Archived" />
      </v-col>
      <v-col cols="auto">
        <listing-task-catalog-item-modal />
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="managementRoles"
          class="text-capitalize"
          elevation="0"
          prepend-icon="import_export"
          @click="exportCatalog"
        >
          <v-icon class="pr-2">table_rows</v-icon>
          Export
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="listingLoading"
      :headers="headers"
      :items-per-page="30"
      :items="filteredItems"
    >
      <template #top> </template>
      <template #item="{ item }">
        <listing-task-catalog-item-row
          :key="item.id"
          :after-change="getCatalog"
          :item="item"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ListingTaskCatalogItemModal from './listing-task-catalog-item-modal.vue'
import ListingTaskCatalogItemRow from './listing-task-catalog-item-row'

import axios from 'axios'

export default {
  components: {
    ListingTaskCatalogItemRow,
    ListingTaskCatalogItemModal,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [],
  data() {
    return {
      isArchived: false,
      chosenDivision: null,
      searchTerm: '',
    }
  },
  computed: {
    headers() {
      const headers = [
        { text: 'Division', align: 'center', sortable: false },
        { text: 'Topic', align: 'center', sortable: false },
        { text: 'Category', align: 'center', sortable: false },
        { text: 'Auto Approve', align: 'center', sortable: false },
        { text: 'Expense type', align: 'center', sortable: false },
        { text: 'Usual Duration', align: 'center', sortable: false },
        { text: 'Actions', align: 'center', sortable: false },
      ]
      if (this.isDesignedVr) {
        headers.splice(3, 0, { text: 'Desc', align: 'center', sortable: false })
      }
      return headers
    },

    filteredItems() {
      return this.$store.state.tasksCatalog.listingTaskCatalogItems
        .filter(item => this.passesFilters(item))
        .filter(item => (this.isArchived ? !item.active : item.active))
    },
  },
  watch: {
    searchTerm: {
      handler: function (val) {
        this.searchTerm = val
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('getListingTaskCatalogItems')
  },
  methods: {
    exportCatalog() {
      this.$store.commit('updateListingLoading', true)
      axios
        .get(`/api/listing-task-catalog-items/all/export-to-excel`, {
          responseType: 'blob',
        })
        .then(response => {
          this.$store.commit('updateListingLoading', false)
          this.autoDownloadBlobData(response.data, 'catalog-report.xlsx')
        })
        .catch(error => {
          alert(error)
        })
    },
    getCatalog() {
      this.$store.dispatch('getListingTaskCatalogItems')
    },
    passesFilters(item) {
      if (this.chosenDivision && item.division != this.chosenDivision) {
        return false
      }
      if (
        this.searchTerm &&
        !(
          item.description &&
          item.description.toLowerCase().includes(this.searchTerm.toLowerCase())
        ) &&
        !(
          item.department &&
          item.department.toLowerCase().includes(this.searchTerm.toLowerCase())
        ) &&
        !(
          item.sub_topic &&
          item.sub_topic.toLowerCase().includes(this.searchTerm.toLowerCase())
        )
      ) {
        return false
      }
      return true
    },
  },
}
</script>
