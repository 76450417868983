<template>
  <div class="fill-height">
    <v-app-bar id="header" app fixed>
      <v-toolbar-title style="overflow: visible">
        <img
          class="mt-2"
          src="@/assets/images/logo-colors-icon.png"
          height="40"
        />
      </v-toolbar-title>
    </v-app-bar>
    <v-main class="flex-content fill-height">
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'ListingTaskTrackerLayout',
}
</script>

<style scoped>
.flex-content {
  display: flex;
}
</style>
