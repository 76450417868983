import axios from '@/axios/config'

export default {
  state: {},
  namespaced: true,
  mutations: {},
  actions: {
    async createReport(_, body) {
      return axios.post(`/api/housekeeping/report`, body)
    },
    async fetchReport(_, params) {
      return axios.get(`/api/housekeeping/report`, { params })
    },
  },
}
