<template>
  <v-layout justify-center>
    <v-dialog v-model="chosenPayment" persistent max-width="900px">
      <v-container fluid grid-list-md>
        <v-card>
          <v-card-title><h4>Payment</h4></v-card-title>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item v-if="amount">
              <v-list-item-content>Amount:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                amount
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="paymentType">
              <v-list-item-content>Payment Type:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                paymentType
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="paidAt">
              <v-list-item-content>Paid At:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                parseDate(paidAt)
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="startPeriod">
              <v-list-item-content>Start Period:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                parseDate(startPeriod)
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="endPeriod">
              <v-list-item-content>End Period:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                parseDate(endPeriod)
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="designedvrAccount">
              <v-list-item-content>From Account:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                designedvrAccount
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="recipientAccount">
              <v-list-item-content>Recipient Account:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                recipientAccount
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="recipientName">
              <v-list-item-content>Recipient Name:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                recipientName
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="checkNumber">
              <v-list-item-content>Check Number:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                checkNumber
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item v-if="transactionID">
              <v-list-item-content>Transaction ID:</v-list-item-content>
              <v-list-item-content class="align-end">{{
                transactionID
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click.native="closePayment"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </v-layout>
</template>

<script>
import get from 'lodash/fp/get'
export default {
  computed: {
    chosenPayment: function () {
      return this.$store.state.chosenPayment
    },
    amount: function () {
      return get(this.chosenPayment, ['amount'])
    },
    paidByUs: function () {
      const paidByUs = get(this.chosenPayment, ['paid_by_us'])
      if (paidByUs) {
        return 'Yes'
      } else {
        return 'No'
      }
    },
    startPeriod: function () {
      return get(this.chosenPayment, ['start_period'])
    },
    paidAt: function () {
      return get(this.chosenPayment, ['paid_at'])
    },
    endPeriod: function () {
      return get(this.chosenPayment, ['end_period'])
    },
    paymentType: function () {
      return get(this.chosenPayment, ['type_of_payment'])
    },
    designedvrAccount: function () {
      return get(this.chosenPayment, ['designedvr_account'])
    },
    recipientAccount: function () {
      return get(this.chosenPayment, ['recipient_account'])
    },
    recipientName: function () {
      return get(this.chosenPayment, ['recipient_name'])
    },
    checkNumber: function () {
      return get(this.chosenPayment, ['check_number'])
    },
    transactionID: function () {
      return get(this.chosenPayment, ['transaction_id'])
    },
  },
  methods: {
    parseDate: function (date) {
      return this.$moment(date).format('YYYY/MM/DD')
    },
    closePayment: function () {
      this.$store.commit('updateChosenPayment', null)
    },
  },
}
</script>
