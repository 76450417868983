<template>
  <div class="d-flex full pa-5 pb-0">
    <estimates-config-side-bar
      :division.sync="division"
      :selected-group-id.sync="selectedGroupId"
    />
    <div
      v-if="groupsFetching || questionsFetching"
      class="flex-fill flex-center pl-5"
    >
      <loader-animation-palms />
    </div>
    <div v-else-if="errorMessage" class="flex-fill flex-center pl-5">
      <div>{{ errorMessage }}</div>
    </div>
    <div v-else class="flex-fill v-window d-flex flex-column px-5 pb-1">
      <div class="d-flex pt-3">
        <div class="text-h5">{{ currentGroupName }}</div>
        <v-spacer />
        <form-dialog
          title="Create New Estimate Question"
          @submit="onCreateNewQuestion"
          @open="newQuestion = {}"
        >
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" text color="secondary" v-on="on">
              <v-icon>mdi-plus</v-icon>
              New Question
            </v-btn>
          </template>
          <v-text-field
            v-model="newQuestion.text"
            label="Text *"
            outlined
            dense
            :rules="[required]"
          />
          <catalog-quick-select
            v-model="newQuestion.catalog_id"
            :rules="[required]"
            :division="division"
          />
        </form-dialog>
      </div>
      <v-divider class="mt-2 mb-5" />

      <div class="estimates-group-table-container">
        <v-data-table
          ref="dataTable"
          v-sortable-data-table
          :headers="headers"
          :items="questions"
          hide-default-footer
          :options="pagination"
          @dragged="orderOnDrag"
        >
          <template #item.text="{ item }">
            <div class="d-flex">
              <v-icon small class="grab px-2">mdi-drag</v-icon>
              <inline-text-field
                class="flex-1 my-2"
                :value="item.text"
                :multiline="true"
                @change="inlineQuestionUpdate(item, { text: $event })"
              />
            </div>
          </template>
          <template #item.catalog_id="{ item }">
            <catalog-item-picker
              :value="item.catalog_id"
              @input="inlineQuestionUpdate(item, { catalog_id: $event })"
            />
          </template>
          <template #item.actions="{ item }">
            <div class="taskim-task-actions flex-center">
              <ConfirmationModal
                :text="`Are you sure you want to delete this question ?\n\n${item.text}`"
                @action="onDeleteQuestion(item.id)"
              >
                <template #activator="{ on }">
                  <v-tooltip bottom open-delay="200">
                    <template #activator="tooltipSlotData">
                      <span
                        v-bind="tooltipSlotData.attrs"
                        v-on="tooltipSlotData.on"
                      >
                        <v-btn color="info" icon small v-on="on">
                          <v-icon small>mdi-delete-forever-outline</v-icon>
                        </v-btn>
                      </span>
                    </template>
                    <span>Delete Question</span>
                  </v-tooltip>
                </template>
              </ConfirmationModal>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FormDialog from 'components/common/form-dialog'
import CommonFunctions from 'components/mixins/common_functions'
import formRules from 'components/mixins/form-rules-mixin'
import LoaderAnimationPalms from 'components/loaders/loader-animation-palms'
import MentionMixin from 'components/mixins/mention-mixin'
import EstimatesConfigSideBar from 'components/estimates/estimates-config-side-bar'
import CatalogQuickSelect from 'components/catalog-quick-select'
import InlineTextField from 'components/inline-inputs/inline-text-field'
import CatalogItemPicker from 'components/common/catalog-item-picker'
import ConfirmationModal from 'components/modals/confirmation-modal'
import { maxBy } from 'lodash'

export default {
  name: 'EstimatesConfig',
  components: {
    ConfirmationModal,
    CatalogItemPicker,
    InlineTextField,
    CatalogQuickSelect,
    EstimatesConfigSideBar,
    LoaderAnimationPalms,
    FormDialog,
  },
  mixins: [CommonFunctions, formRules, MentionMixin],
  data() {
    return {
      division: null,
      selectedGroupId: null,
      newQuestion: {},
      headers: [
        {
          sortable: false,
          text: 'Text',
          value: 'text',
          cellClass: 'pl-0',
        },
        {
          sortable: false,
          width: '150px',
          text: 'Catalog',
          value: 'catalog_id',
        },
        {
          sortable: false,
          width: '100px',
          text: 'Actions',
          value: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapState('estimates', [
      'groupsFetched',
      'groupsFetching',
      'groups',
      'questionsFetching',
      'questions',
    ]),
    ...mapGetters('estimates', ['groupsMap']),
    currentGroup() {
      return this.groupsMap[this.selectedGroupId]
    },
    pagination() {
      return {
        page: 1,
        itemsPerPage: -1,
        itemsLength: undefined,
      }
    },
    currentGroupName() {
      return this.currentGroup ? this.currentGroup.name : ''
    },
    errorMessage() {
      if (!this.division) {
        return 'Select Division'
      } else if (this.groupsFetched) {
        if (!this.groups.length) {
          return 'No Groups! please create your first group.'
        } else if (!this.selectedGroupId) {
          return 'Select Group'
        } else if (!this.groupsMap[this.selectedGroupId]) {
          return 'Invalid Group Id'
        }
      }
      return ''
    },
  },
  methods: {
    ...mapActions('estimates', [
      'getGroupQuestions',
      'createQuestion',
      'updateQuestion',
      'deleteQuestion',
      'setQuestionsOrder',
    ]),
    async reloadQuestions(bg = false) {
      if (this.selectedGroupId) {
        await this.getGroupQuestions({ id: this.selectedGroupId, bg })
      }
    },
    async onCreateNewQuestion() {
      const payload = {
        groupId: this.selectedGroupId,
        question: this.newQuestion,
      }
      if (this.questions.length) {
        payload.question.order = maxBy(this.questions, 'order').order + 1
      }
      await this.createQuestion(payload)
      await this.reloadQuestions()
    },
    async onDeleteQuestion(questionId) {
      await this.deleteQuestion({
        groupId: this.selectedGroupId,
        questionId,
      })
      await this.reloadQuestions()
    },
    async inlineQuestionUpdate(question, payload) {
      await this.updateQuestion({
        groupId: this.selectedGroupId,
        question: {
          ...payload,
          id: question.id,
        },
      })
      await this.reloadQuestions(true)
    },
    async orderOnDrag(event) {
      const items = this.$refs.dataTable.internalCurrentItems
      const item = items[event.oldIndex]
      const newIndex =
        event.oldIndex < event.newIndex ? event.newIndex : event.newIndex - 1
      const afterItem = items[newIndex]
      this.setQuestionsOrder({
        questionId: item.id,
        afterQuestionId: afterItem ? afterItem.id : 0,
      })
      await this.updateQuestion({
        groupId: this.selectedGroupId,
        question: {
          id: item.id,
          after_question_id: afterItem ? afterItem.id : 0,
        },
      })
    },
  },
}
</script>

<style scoped>
.estimates-group-table-container {
  border-radius: 3px 3px 0 0;
  border: 1px solid lightgrey;
  overflow-x: auto;
  width: 100%;
}

>>> .estimates-group-table-container table td + td,
>>> .estimates-group-table-container table th + th {
  border-left: 1px solid lightgrey;
}
</style>
