<template>
  <v-card>
    <div v-if="closeFunc" class="pa-4 pb-0 d-flex justify-end">
      <v-icon @click="closeFunc">close</v-icon>
    </div>
    <v-card-title class="font-weight-regular">
      <v-row class="d-flex flex-center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="searchTerm"
            label="Search"
            prepend-inner-icon="$search"
            hide-details
            outlined
            dense
          ></v-text-field
        ></v-col>
        <v-col cols="6" sm="2">
          <v-btn
            v-if="
              !loading &&
              (managementRoles || isCommunicationAgent) &&
              listingId &&
              !homeownerOnly
            "
            color="primary"
            elevation="0"
            small
            outlined
            @click.stop="openModal"
          >
            <v-icon>add</v-icon>
            <span class="text-capitalize ml-1">Add info</span>
          </v-btn></v-col
        >
        <v-col v-if="!isInvestor" class="ml-2" cols="6" sm="2">
          <ConfirmationModal
            v-if="listingInfoWarnings.length"
            text="Are you sure you want to create all missing items ?"
            @action="createMissingItems"
          >
            <template #activator="{ on }">
              <v-btn
                v-if="listingInfoWarnings"
                small
                outlined
                color="secondary"
                elevation="0"
                v-on="on"
              >
                <v-icon>add</v-icon>
                <span class="text-capitalize ml-1">Create Missing</span>
              </v-btn>
            </template>
          </ConfirmationModal>
        </v-col>
        <v-col v-if="!isInvestor" class="ml-3">
          <v-tooltip v-if="!isEmpty(listingInfoWarnings)" top>
            <template #activator="{ on }">
              <v-icon
                color="primary"
                class="icon alt red-warn mr-1 ml-2"
                v-on="on"
                >fas fa-exclamation-triangle</v-icon
              >
            </template>
            <v-row class="center-text" wrap style="width: 300px">
              <v-col cols="12">
                <h2>Missing info items</h2>
              </v-col>
              <v-col
                v-for="(warn, key) in listingInfoWarnings"
                :key="key"
                cols="12"
              >
                {{ warn.topic }} - {{ warn.sub_topic }}
              </v-col>
            </v-row>
          </v-tooltip>
        </v-col>

        <listing-info-definition-modal
          v-if="defShowModal"
          :close-func="closeModal"
        />
      </v-row>
    </v-card-title>
    <v-divider />
    <v-data-table
      v-if="listingInfoItems"
      :headers="headers"
      :items="filteredItems"
    >
      <template #item="{ item }">
        <info-item-row
          :item="item"
          :after-change="afterChange"
          :media-clicked="mediaClicked"
          @edititem="editItem"
          @delete-item="deleteItem"
          @copy-item="openCopyModal"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import InfoItemRow from './info-item-row'
import { mapState, mapMutations, mapActions } from 'vuex'
import ListingInfoDefinitionModal from 'components/info-item-definition/listing-info-definition-modal.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
export default {
  components: {
    ConfirmationModal,
    ListingInfoDefinitionModal,
    InfoItemRow,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [
    'listingId',
    'title',
    'isRoom',
    'extSearchTerm',
    'homeownerOnly',
    'closeFunc',
    'hideOwnersPublic',
  ],

  data() {
    return {
      images: [],
      defShowModal: false,
      searchTerm: '',
      headers: [
        { text: 'Media', sortable: false },
        { text: 'Topic', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    ...mapState(['listingInfoWarnings', 'listingInfoItems']),
    filteredItems() {
      if (this.listingInfoItems) {
        const searchTerm = this.searchTerm
        return this.listingInfoItems
          .filter(item => {
            if (this.hideOwnersPublic && item.owners_public) return false
            const definition = item.listing_info_def
            const topic = definition ? definition.topic : ''
            const sub_topic = definition ? definition.sub_topic : ''
            const comments = item.comments || ''
            return (
              (topic.toLowerCase().includes(searchTerm.toLowerCase()) ||
                sub_topic.toLowerCase().includes(searchTerm.toLowerCase()) ||
                comments.toLowerCase().includes(searchTerm.toLowerCase())) &&
              ((this.homeownerOnly && item.owners_public) ||
                !this.homeownerOnly)
            )
          })
          .sort(item =>
            !item.comments && this.isEmpty(item.images) && !item.movie_url
              ? -1
              : 1
          )
      }
      return []
    },
  },
  methods: {
    ...mapMutations({
      showModal: 'showModal',
      updateItem: 'updateChosenInfoItem',
    }),
    ...mapActions({
      getItems: 'getListingInfoItems',
      deleteItem: 'deleteListingInfoItem',
      getInfoDefs: 'getListingInfoDefs',
    }),
    createMissingItems() {
      this.$store.dispatch('createListingInfoDefBatch', {
        listingId: this.listingId,
        infoDefIds: this.listingInfoWarnings.map(i => i.id),
      })
    },
    closeModal() {
      this.defShowModal = false
    },
    openDefModal() {
      this.defShowModal = true
    },
    openModal() {
      this.showModal({
        name: 'InfoItemModal',
        props: { listingId: this.listingId },
      })
    },
    openCopyModal(item) {
      this.showModal({
        name: 'InfoCopyItemModal',
        props: { item: item },
      })
    },
    editItem(item) {
      this.showModal({
        name: 'InfoItemModal',
        props: { listingItem: item, listingId: this.listingId },
      })
    },
    afterChange() {
      this.getItems({
        listingId: this.listingId,
        searchTerm: this.extSearchTerm,
      })
    },
    closeImages() {
      this.updateItem(null)
    },
    mediaClicked(item) {
      this.updateItem(item)
    },
  },
  mounted() {
    if (this.listingId) {
      this.getInfoDefs()
      this.getItems({
        listingId: this.listingId,
      })
    }
  },
}
</script>
