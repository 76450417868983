<template>
  <v-dialog v-model="dialog" persistent max-width="300px">
    <template #activator="{ on }">
      <v-btn
        :rounded="false"
        class="rounded-0 justify-start"
        block
        text
        small
        color="info"
        elevation="0"
        v-on="on"
      >
        <v-icon small left>fas fa-money-check-alt</v-icon>Add deposit
      </v-btn>
    </template>
    <v-card>
      <v-form ref="form" @submit="submit">
        <v-card-title> Deposit </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.number="deposit"
            dense
            label="Deposit"
            :rules="[required]"
            outlined
            type="number"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn small color="primary" outlined @click="closeModal"
            >Cancel</v-btn
          >
          <v-btn small color="primary" type="submit">Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'

export default {
  name: 'LtDepositModal',
  mixins: [formRules],
  props: ['taskId'],
  data() {
    return {
      dialog: false,
      deposit: null,
    }
  },
  methods: {
    closeModal() {
      this.dialog = false
      this.$refs.form.reset()
    },
    submit(e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        const { deposit, taskId } = this
        this.$store.dispatch('updateListingTask', { id: taskId, deposit })
        this.closeModal()
      }
    },
  },
}
</script>

<style scoped></style>
