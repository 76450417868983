<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.24"
    height="15.999"
    viewBox="0 0 16.24 15.999"
  >
    <g transform="translate(0 -3.788)">
      <g transform="translate(4.195 9.104)">
        <path
          class="a"
          d="M132.739,226.811a.476.476,0,0,0-.476.476v.032a.476.476,0,0,0,.951,0v-.032A.476.476,0,0,0,132.739,226.811Z"
          transform="translate(-132.263 -225.054)"
        />
        <path
          class="a"
          d="M133.214,171.885a.476.476,0,0,0-.951,0v.032a.476.476,0,0,0,.951,0Z"
          transform="translate(-132.263 -171.412)"
        />
        <path
          class="a"
          d="M191.661,226.811a.476.476,0,0,0-.476.476v.032a.476.476,0,0,0,.951,0v-.032A.476.476,0,0,0,191.661,226.811Z"
          transform="translate(-189.316 -225.054)"
        />
        <path
          class="a"
          d="M192.135,171.885a.476.476,0,0,0-.951,0v.032a.476.476,0,0,0,.951,0Z"
          transform="translate(-189.315 -171.412)"
        />
      </g>
      <g transform="translate(0 3.788)">
        <path
          class="a"
          d="M21.7,387.522h8.121a3.482,3.482,0,0,0,3.478-3.478v-.475H18.22v.475A3.482,3.482,0,0,0,21.7,387.522Z"
          transform="translate(-17.642 -371.523)"
        />
        <path
          class="a"
          d="M.314,311.894H15.926a.314.314,0,0,0,.314-.314v-.607a.314.314,0,0,0-.314-.314H.314a.314.314,0,0,0-.314.314v.607a.314.314,0,0,0,.314.314Z"
          transform="translate(0 -300.926)"
        />
        <path
          class="a"
          d="M57.778,12.57V5.916a1.188,1.188,0,0,1,2.363-.174A2.036,2.036,0,0,0,58.6,7.715v.127a.476.476,0,0,0,.476.476h3.113a.476.476,0,0,0,.476-.476V7.715A2.035,2.035,0,0,0,61.1,5.738a2.139,2.139,0,0,0-4.271.178V12.57Z"
          transform="translate(-55.025 -3.788)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Shower',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
