<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="a"
      d="M215.333,85.333a2,2,0,1,0,2,2A2,2,0,0,0,215.333,85.333Zm0,3a1,1,0,1,1,1-1A1,1,0,0,1,215.333,88.333Z"
      transform="translate(-203.333 -81.333)"
    />
    <path
      class="a"
      d="M196.5,192h-4a.5.5,0,0,0-.5.5v2a.5.5,0,0,0,.5.5h.5v7.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-10A.5.5,0,0,0,196.5,192Zm-.5,10h-2v-7.5a.5.5,0,0,0-.5-.5H193v-1h3v9Z"
      transform="translate(-183 -183)"
    />
    <path
      class="a"
      d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm0,23A11,11,0,1,1,23,12,11.012,11.012,0,0,1,12,23Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'InfoCircleAlt',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
