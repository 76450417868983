import axios from '@/axios/config'

export default {
  state: {},
  namespaced: true,
  actions: {
    async getInventoryActions(_store, params) {
      return axios.get('/api/inventory-actions', {
        params,
      })
    },
  },
}
