<template>
  <v-sheet width="100%">
    <v-card class="pa-4">
      <div class="mb-4 text-h5 font-weight-bold">Users</div>
      <form-dialog
        max-width="500"
        title="Import users from file"
        @submit="submit"
        @close="clearState"
      >
        <template #activator="{ on }">
          <v-btn color="success" small v-on="on">
            <v-icon small left>fas fa-table</v-icon>Import Users
          </v-btn>
        </template>
        <v-form ref="form">
          <v-file-input
            v-model="file"
            accept=".xlsx,.csv,.xls"
            label="Excel sheet"
            outlined
            dense
            hide-details
            :rules="fileRules"
            show-size
            drop-zone
          />
        </v-form>
        <template>
          Click
          <a
            class="info--text text-decoration-underline"
            @click.prevent="downloadCsv"
          >
            here
          </a>
          to download a Users template
        </template>

        <br />

        <template>
          Click
          <a
            class="info--text text-decoration-underline"
            @click.prevent="downloadRolesList"
          >
            here
          </a>
          to download Rols list
        </template>

        <br />

        <template>
          Click
          <a
            class="info--text text-decoration-underline"
            @click.prevent="downloadDivisionsList"
          >
            here
          </a>
          to download a Divisions list
        </template>
      </form-dialog>
    </v-card>
  </v-sheet>
</template>

<script>
import FormDialog from 'components/common/form-dialog.vue'
import axios from '@/axios/config'
import CommonFunctions from 'components/mixins/common_functions'
import RolesMixin from 'components/mixins/roles-mixin'

const CSV_USER_HEADERS = [
  'Email Address*',
  'Role*',
  'Division*',
  'First name*',
  'Last name',
  'Phone Number',
]

export default {
  mixins: [CommonFunctions, RolesMixin],
  components: {
    FormDialog,
  },
  props: {
    tenant: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      fileRules: [
        v => !!v || 'File is required',
        v => !v || v.size < 2000000 || 'File size should be less than 2 MB',
      ],
    }
  },
  computed: {
    rolesList() {
      if (this.hasAbility('super-admin')) {
        return [...this.roles, { text: 'Admin', value: 'admin' }]
      }
      return this.roles
    },
    divisionsList() {
      return this.divisions
    },
  },
  methods: {
    async submit() {
      const form = this.$refs.form
      if (!form.validate() || !this.file) {
        return
      }

      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('tenant_id', this.tenant)

      try {
        await axios.post('/api/users/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        this.clearState()
      } catch (error) {
        console.error()
      }
    },
    downloadCsv() {
      const rows = [CSV_USER_HEADERS]
      this.autoDownloadLocalCsv(rows, 'Users_upload_template')
    },
    downloadRolesList() {
      const rows = this.rolesList.map(item => item.value)
      this.autoDownloadLocalCsv([rows], 'Users_roles_template')
    },
    downloadDivisionsList() {
      const rows = this.divisionsList
      this.autoDownloadLocalCsv([rows], 'Users_divisions_template')
    },
    clearState() {
      this.file = null
    },
  },
}
</script>
