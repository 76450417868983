<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      class="a"
      d="M42.707,38.251,40.35,41.636l-1.663-1.365a.421.421,0,1,0-.534.651l2.015,1.654a.419.419,0,0,0,.267.1.384.384,0,0,0,.058,0,.422.422,0,0,0,.288-.176l2.616-3.76a.421.421,0,0,0-.691-.481Z"
      transform="translate(-22 -22.041)"
    />
    <path
      class="a"
      d="M22.737,15.034V5.127A5.132,5.132,0,0,0,17.61,0H5.127A5.132,5.132,0,0,0,0,5.127V17.61a5.132,5.132,0,0,0,5.127,5.127h9.907a5.47,5.47,0,0,0,7.7-7.7ZM5.127,21.895A4.289,4.289,0,0,1,.842,17.61V5.127A4.289,4.289,0,0,1,5.127.842H17.61a4.289,4.289,0,0,1,4.285,4.285v9.091c-.032-.025-.065-.046-.1-.07-.088-.067-.177-.133-.269-.194l-.03-.018a5.461,5.461,0,0,0-.68-.374c-.032-.015-.063-.032-.1-.046-.093-.041-.187-.076-.283-.112-.06-.023-.12-.046-.181-.067-.087-.029-.175-.055-.264-.08s-.151-.041-.227-.059-.16-.037-.242-.052c-.1-.018-.195-.032-.293-.045-.066-.008-.131-.019-.2-.025-.168-.016-.338-.026-.509-.026a5.48,5.48,0,0,0-5.321,4.211H11.368a.421.421,0,1,0,0,.842h1.706c-.011.139-.021.279-.021.421,0,.171.01.34.026.509.006.067.016.132.025.2.013.1.027.195.045.291s.033.163.052.244.037.15.058.224c.025.09.051.179.081.267.02.059.043.117.064.176.036.1.072.193.113.288.012.028.027.055.04.083a5.419,5.419,0,0,0,.387.7l.011.018c.061.093.128.182.195.271.024.032.045.065.069.1H5.127Zm13.4,1.263a4.607,4.607,0,0,1-3.09-1.192q-.153-.137-.292-.286l-.061-.067q-.132-.147-.251-.3l-.054-.072q-.117-.162-.219-.332c-.014-.023-.028-.045-.042-.069-.069-.119-.132-.241-.19-.366-.009-.02-.019-.039-.028-.059-.059-.13-.11-.263-.156-.4-.005-.017-.013-.033-.018-.049-.045-.137-.083-.277-.115-.419,0-.016-.009-.032-.013-.049-.03-.139-.052-.281-.069-.423,0-.02-.007-.039-.009-.059a4.566,4.566,0,0,1-.026-.488,4.637,4.637,0,0,1,4.632-4.632,4.493,4.493,0,0,1,.489.027l.056.009c.143.017.286.039.426.069l.047.012c.142.032.283.07.421.115l.046.017q.2.069.4.157l.055.027q.189.088.37.192l.065.04c.115.069.227.143.336.222l.069.051q.159.12.308.254l.062.057q.151.141.291.3a4.623,4.623,0,0,1-3.443,7.718Z"
    />
    <path
      class="a"
      d="M26.421,12.842H34A.421.421,0,0,0,34,12H26.421a.421.421,0,1,0,0,.842Z"
      transform="translate(-15.053 -6.947)"
    />
    <path
      class="a"
      d="M26.421,26.842H34A.421.421,0,0,0,34,26H26.421a.421.421,0,0,0,0,.842Z"
      transform="translate(-15.053 -15.053)"
    />
    <path
      class="a"
      d="M12.413,7.148,9.782,10.216,8.173,9.009a.421.421,0,1,0-.505.673l1.925,1.444a.421.421,0,0,0,.573-.063l2.887-3.368a.421.421,0,1,0-.64-.547Z"
      transform="translate(-4.342 -4.053)"
    />
    <path
      class="a"
      d="M12.413,21.148,9.782,24.216,8.173,23.009a.421.421,0,1,0-.505.673l1.925,1.444a.421.421,0,0,0,.573-.063l2.887-3.368a.421.421,0,1,0-.64-.547Z"
      transform="translate(-4.342 -12.158)"
    />
    <path
      class="a"
      d="M12.413,36.148,9.782,39.216,8.173,38.009a.421.421,0,1,0-.505.673l1.925,1.444a.421.421,0,0,0,.573-.063l2.887-3.368a.421.421,0,1,0-.64-.547Z"
      transform="translate(-4.342 -20.842)"
    />
  </svg>
</template>

<script>
export default {
  name: 'Rules',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
