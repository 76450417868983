<template>
  <v-flex xs12>
    <v-select
      ref="tagSelect"
      v-model="chips"
      :items="suggestedTags"
      label="Listing tags"
      chips
      tags
    ></v-select>
  </v-flex>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import axios from '@/axios/config'
export default {
  props: ['listingContext', 'filterChange'],
  data() {
    return {
      selected: [],
      suggestedTags: [],
    }
  },
  computed: {
    chips: {
      get() {
        if (this.$store.state.currentEditListing && this.listingContext) {
          return this.$store.state.currentEditListing.tags
        } else {
          return this.selected
        }
      },
      set(val) {
        if (this.$store.state.currentEditListing && this.listingContext) {
          this.$store.state.currentEditListing.tags = val
          let clone = cloneDeep(this.$store.state.currentEditListing)
          this.$store.commit('changeEditListing', clone)
        } else {
          this.selected = val
          this.filterChange(val)
        }
      },
    },
  },
  watch: {
    selected() {
      setTimeout(() => {
        this.$refs.tagSelect.menuIsActive = false
      }, 10)
    },
  },
  async mounted() {
    const { data } = await axios.get('/api/listings/tags')
    this.suggestedTags = data
  },
}
</script>
