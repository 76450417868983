<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="99.992"
    height="100"
    viewBox="0 0 99.992 100"
  >
    <g id="comments" transform="translate(-0.019)">
      <g id="Group_10008" data-name="Group 10008" transform="translate(0.019)">
        <path
          id="Path_4871"
          data-name="Path 4871"
          d="M85.338,14.651a49.988,49.988,0,0,0-73.848,67.23c-1.607,3.235-4.186,7-8.013,8.879A4.69,4.69,0,0,0,4.831,99.6a25.838,25.838,0,0,0,3.953.3,29.848,29.848,0,0,0,17.59-5.835A49.983,49.983,0,0,0,85.338,14.651ZM81.3,81.311A44.28,44.28,0,0,1,27.558,88.2a2.842,2.842,0,0,0-3.467.444.939.939,0,0,0-.233.169,24.409,24.409,0,0,1-15.074,5.37H8.763c4.292-3.129,7-7.78,8.584-11.4a2.92,2.92,0,0,0,.148-1.839,2.968,2.968,0,0,0-.7-1.607,44.284,44.284,0,1,1,64.5,1.966Z"
          transform="translate(-0.019)"
        />
        <circle
          id="Ellipse_101"
          data-name="Ellipse 101"
          cx="3.51"
          cy="3.51"
          r="3.51"
          transform="translate(46.461 46.49)"
        />
        <circle
          id="Ellipse_102"
          data-name="Ellipse 102"
          cx="3.51"
          cy="3.51"
          r="3.51"
          transform="translate(64.559 46.49)"
        />
        <circle
          id="Ellipse_103"
          data-name="Ellipse 103"
          cx="3.51"
          cy="3.51"
          r="3.51"
          transform="translate(28.364 46.49)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Comment',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
