<template>
  <v-card>
    <v-card-title class="text-h5 align-center bb-1">
      <v-icon class="pr-2" color="secondary">mdi-account-group</v-icon>
      {{ title || 'Users' }}
      <v-spacer />
      <v-btn icon @click="onClose">
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />

    <v-card-text class="pa-0 d-flex flex-column">
      <v-row class="px-5 text-title-1">
        <v-col cols="1">Picture</v-col>
        <v-col cols="3">Name</v-col>
        <v-col cols="2">Department</v-col>
        <v-col cols="2">Role</v-col>
        <v-col cols="2">Sub Role</v-col>
        <v-col cols="2">Type</v-col>
      </v-row>
      <v-divider />
      <div class="px-5 flex-fill overflow-auto">
        <v-list v-if="filteredUsers.length" dense>
          <div v-for="(user, index) in filteredUsers" :key="index">
            <v-row>
              <v-col cols="1">
                <user-avatar
                  :image="user.picture"
                  :name="`${user.name}`"
                  :role="user.role"
                />
              </v-col>
              <v-col cols="3">{{ user.name }}</v-col>
              <v-col cols="2">{{ user.department }}</v-col>
              <v-col cols="2">{{ user.role }}</v-col>
              <v-col cols="2">{{ user.sub_role }}</v-col>
              <v-col cols="2">
                {{ user.on_payrole ? 'Staff' : '3rd party' }}
              </v-col>
            </v-row>
            <v-divider v-if="index < filteredUsers.length - 1" />
          </div>
        </v-list>
        <div v-else class="pa-10 text-center">No results</div>
      </div>
    </v-card-text>

    <v-divider />
    <v-card-actions class="justify-space-around">
      <v-btn depressed @click="onClose">Close</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import UserAvatar from 'components/common/user-avatar'

export default {
  name: 'UsersListModal',
  components: { UserAvatar },
  props: ['title', 'users', 'filter'],
  computed: {
    ...mapGetters('users', ['activeUsers']),
    filteredUsers() {
      if (this.filter) {
        return (this.users || this.activeUsers).filter(this.filter)
      }
      return this.users || this.activeUsers
    },
  },
  methods: {
    onClose() {
      this.$store.commit('hideModal')
    },
  },
}
</script>

<style scoped></style>
