<template>
  <v-container>
    <v-layout wrap>
      <v-flex sm-6 offset-sm-3>
        <v-layout wrap>
          <v-flex xs12>
            <v-text-field
              v-model="investment.address"
              box
              color="deep-purple"
              label="Description"
              rows="2"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="investment.beds"
              label="Beds"
              prepend-icon="king_bed"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="investment.baths"
              label="Baths"
              prepend-icon="bathtub"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-btn
              color="blue darken-1"
              :disabled="loading"
              text
              @click.native="save"
              >Save</v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout
          v-if="$store.state.currentInvestmentListing"
          class="comp-wrapper mt-3"
        >
          <div>
            <city-card
              :city="$store.state.currentInvestmentListing.city_data"
              :show-beds="investment.beds"
            ></city-card>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CityCard from './city-card.vue'

export default {
  components: {
    CityCard,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  validations: {
    bath: {
      required,
    },
    beds: {
      required,
    },
    address: {
      required,
    },
  },
  data() {
    return {
      investment: {},
    }
  },
  computed: {
    bath() {
      return this.investment.bath
    },
    expenseDate() {
      return this.investment.beds
    },
    address() {
      return this.investment.address
    },
  },
  mounted() {
    window.$store.state.expenseModal = this
  },

  methods: {
    save() {
      this.$store.dispatch('getNewInvestmentProperty', this.investment)
    },
  },
}
</script>
