<template>
  <v-row class="align-center justify-space-around mx-1">
    <div v-for="(day, index) in weekdays" :key="index">
      <v-text-field
        v-model.number="prices[index]"
        :label="day"
        type="number"
        outlined
        dense
        hide-details
        style="width: 85px"
        @change="onChange"
        @input="updateModel"
      />
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'WeekdaysPicker',
  props: {
    value: {
      type: Array,
      default: () => [0, 0, 0, 0, 0, 0, 0],
    },
  },

  data() {
    return {
      weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      prices: [...this.value],
    }
  },

  watch: {
    value: {
      handler(newValue) {
        this.prices = [...newValue]
      },
      deep: true,
    },
  },

  methods: {
    onChange() {
      this.$emit('change', this.prices)
    },
    updateModel() {
      this.$emit('input', this.prices)
    },
  },
}
</script>
