<template>
  <listings v-if="display === 'listings'" />
</template>

<script>
import Listings from './listings.vue'

export default {
  components: {
    listings: Listings,
  },
  computed: {
    display: {
      get() {
        return this.$store.state.display
      },
      set(display) {
        this.$store.commit('updateDisplay', display)
      },
    },
    loading() {
      return this.$store.state.loading
    },
  },
  created() {
    const { activeListings } = this.$route.query
    this.$store.dispatch('listings/getListings', {
      page: this.$route.query.page || 1,
      router: this.$router,
      active: activeListings ? activeListings === 'true' : true,
    })
    this.display = 'listings'
  },
}
</script>
