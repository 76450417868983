<template>
  <div :class="['text-caption', 'rounded-lg', 'white']">
    <div class="d-none d-md-block text-subtitle-1 ml-2 pl-1 pt-1">
      {{ $t('Info') }}
    </div>

    <div v-if="currentReview" class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Review') }}</div>

      <div class="d-flex flex col-6 pa-0">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <span
              class="text-body-2 font-weight-medium text-decoration-underline primary--text pointer"
              v-on="on"
              >Details <v-icon v-bind="attrs" small> $info </v-icon></span
            >
          </template>
          <review-card :review="currentReview" />
        </v-tooltip>
      </div>
    </div>
    <div v-if="!isContractor" class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Assigned to') }}</div>

      <div class="d-flex flex col-6 pa-0">
        <AssigneeSelect
          :disabled="isContractor || readonly"
          :users="activeUsers"
          :value="assignedToUser"
          @change="assignUser"
        />
        <v-btn
          :href="`tel:+${phoneNumber}`"
          :disabled="!phoneNumber"
          small
          text
          class="darkpurple--text"
        >
          <v-icon small class="pr-1">fas fa-phone</v-icon>
        </v-btn>
        <v-btn small text icon>
          <v-icon
            :class="showMoreAssignees && 'icon-rotated'"
            small
            :disabled="readonly"
            color="primary"
            @click="toggleAssignees"
            >fas fa-plus</v-icon
          >
        </v-btn>
      </div>
    </div>
    <v-expand-transition>
      <div v-if="showMoreAssignees" class="d-flex align-center pa-2 pa-md-2">
        <div class="black--text flex col-6 pa-0">{{ $t('Assignees') }}</div>

        <div class="d-flex flex col-6 pa-0">
          <contractor-select
            :label="null"
            hint="Up to 4 assignees"
            :persistent-hint="true"
            :filter-change="changeAssignees"
            :allow-multiple="true"
            :outlined="false"
            :disabled="readonly"
            :initials="true"
            :input="limited"
            :enable-sug="true"
            :value="additionalContractors"
          />
        </div>
      </div>
    </v-expand-transition>
    <div class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">
        {{ $t('Requested approver') }}
      </div>
      <div class="d-flex flex col-6 pa-0">
        <AssigneeSelect
          :disabled="!managementRoles"
          :users="approvers"
          :value="approverUser"
          @change="changeApprover"
        />
      </div>
    </div>
    <v-divider />
    <div class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Priority') }}</div>
      <div class="d-flex flex col-6 pa-0">
        <lt-priority
          v-model="listingTask.priority"
          :readonly="!managementRoles"
          @input="priorityChanged"
        />
      </div>
    </div>
    <v-divider />
    <div
      v-if="
        listingTask.origin_listing_task_id ||
        listingTask.continuation_listing_tasks.length
      "
      class="d-flex align-center pa-2 pa-md-2"
    >
      <div class="black--text flex col-6 pa-0">
        {{ $t('Continuation Hierarchy') }}
      </div>
      <div class="flex col-6 pa-0">
        <v-btn
          x-small
          rounded
          outlined
          text
          color="primary"
          @click="openContinuationModal"
          >Tasks ({{ listingTask.continuation_listing_tasks.length }})
        </v-btn>
      </div>
      <v-divider />
    </div>
    <div
      v-if="listingTask.origin_listing_task_id"
      class="d-flex align-center pa-2 pa-md-2"
    >
      <div class="black--text flex col-6 pa-0">{{ $t('Original SC') }}</div>
      <div class="flex col-6 pa-0">
        <router-link
          target="_blank"
          :to="connectedLtUrl(listingTask.origin_listing_task_id)"
          class="info--text"
        >
          View
        </router-link>
      </div>
      <v-divider />
    </div>
    <div
      v-if="listingTask.created_by_listing_task_id"
      class="d-flex align-center pa-2 pa-md-2"
    >
      <div class="black--text flex col-6 pa-0">Template flow SC</div>
      <div class="flex col-6 pa-0">
        <router-link
          target="_blank"
          :to="connectedLtUrl(listingTask.created_by_listing_task_id)"
          class="info--text"
        >
          View
        </router-link>
      </div>
      <v-divider />
    </div>
    <div
      v-if="listingTask.conversation_link"
      class="d-flex align-center pa-2 pa-md-2"
    >
      <div class="black--text flex col-6 pa-0">{{ $t('Related chat') }}</div>
      <div class="flex col-6 pa-0">
        <a
          class="text-decoration-none black--text"
          target="_blank"
          :href="listingTask.conversation_link"
          ><span class="mr-2">Open chat</span>
          <v-icon small>fas fa-external-link-alt</v-icon>
        </a>
      </div>
      <v-divider />
    </div>
    <div class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Created on') }}</div>
      <div class="flex col-6 pa-0">
        {{ parseDateTZ(listingTask.created_at, 'MM/DD/YYYY') }}
      </div>
    </div>
    <v-divider />
    <div class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Scheduled at') }}</div>
      <div class="d-flex col-6 pa-0">
        <div class="d-flex datetime-picker-container">
          <v-sheet width="100px">
            <date-picker
              :value="parseDate(listingTask.scheduled_at)"
              :disabled="isContractor || readonly"
              :events="allowedDates"
              :event-color="eventDateColor"
              :reactive="true"
              :min="new Date().toISOString().substr(0, 10)"
              :max="
                new Date(new Date().setMonth(new Date().getMonth() + 10))
                  .toISOString()
                  .substr(0, 10)
              "
              :required="true"
              :dense="true"
              :hide-details="true"
              :flat="true"
              :solo="true"
              :outlined="false"
              text-field-class="text-caption"
              @change="scheduleAt"
            >
              <template #prepend-inner>
                <v-icon small color="info">fas fa-calendar-alt</v-icon>
              </template>
            </date-picker>
          </v-sheet>
          <div>
            <div :class="timeLabelContainerClass" @click="openDateTimePicker">
              <div class="time-label">
                {{ parseDate(listingTask.scheduled_at, 'HH:mm') || '00:00' }}
              </div>
            </div>
            <datetime
              ref="dvrDateTime"
              :value="parseDate(listingTask.scheduled_at, 'HH:mmZ')"
              :minute-step="15"
              input-class="dvr-datetime d-none"
              format="HH:mm"
              zone="UTC"
              type="time"
              @input="scheduleAtHour"
            />
          </div>
        </div>
      </div>
    </div>
    <v-divider />
    <div class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Created By') }}</div>
      <div class="flex col-6 pa-0">
        <Assignee v-if="createdByUser" :user="createdByUser" />
        <v-chip v-else small pill>{{ $t('Auto Created') }}</v-chip>
      </div>
    </div>
    <v-divider />
    <div v-if="listingTask.listing" class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Address') }}</div>
      <a
        :href="googleMapsLink"
        target="_blank"
        class="flex black--text col-6 pa-0"
      >
        {{ listingTask.listing.address }}
      </a>
    </div>
    <v-divider />
    <div v-if="listingTask.unit" class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Unit number') }}</div>
      <div class="flex col-6 pa-0">
        {{ listingTask.unit_number }}
      </div>
      <v-divider />
    </div>
    <div v-if="listingAvailable" class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Door Code') }}</div>

      <div class="flex col-6 pa-0">
        <ConfirmationModal
          v-if="!showCodes"
          :text="`Please knock 3 times and wait 10 seconds before entry. (<b>${listingTask.add_today_avialability_text}</b>)`"
          @action="showCodes = true"
        >
          <template #activator="slotData">
            <v-btn x-small color="primary" small v-on="slotData.on"
              >{{ $t('Show codes') }}
            </v-btn>
          </template>
        </ConfirmationModal>
        <span v-else>
          {{ listingTask.door_code }}
        </span>
      </div>
    </div>
    <div v-else class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Door Code') }}</div>

      <div class="flex col-6 pa-0">{{ $t("Can't display") }}</div>
    </div>
    <v-divider />
    <div class="d-flex align-center pa-2 pa-md-2">
      <div class="black--text flex col-6 pa-0">{{ $t('Availability') }}</div>
      <div class="flex col-6 pa-0">
        {{ $t(listingTask.listing_available_time) }}
      </div>
    </div>
    <div
      v-if="listingTask.follow_up_by_id === currentUserId"
      class="d-flex align-center pa-2 pa-md-2"
    >
      <v-divider />
      <div class="black--text flex col-6 pa-0">{{ $t('Follow-Up') }}</div>
      <div class="flex col-6 pa-0">
        <v-chip color="warning"
          >{{ timeFromNow(listingTask.follow_up_at) }}
        </v-chip>
      </div>
    </div>
    <div v-if="warranty" class="d-flex align-center pa-2 pa-md-2">
      <v-divider />
      <div class="black--text flex col-6 pa-0">
        {{ $t('Warranty till') }}
      </div>
      <div class="flex col-6 pa-0">
        {{ parseDate(warranty.expired_at, 'DD/MM/YYYY') }}
        <a
          v-if="managementRoles"
          class="text-decoration-none"
          target="_blank"
          :href="`/dashboard/edit/${listingTask.listing_id}/warranties`"
        >
          <v-icon small color="info">mdi-open-in-new</v-icon>
        </a>
      </div>
    </div>
    <div
      v-if="listingTask.free_unit_codes"
      class="d-flex align-center pa-2 pa-md-2"
    >
      <div class="black--text flex col-6 pa-0">{{ $t('Hotel Codes') }}</div>
      <div class="flex col-6 pa-0">
        <v-dialog max-width="300">
          <template #activator="{ on }">
            <v-btn elevation="0" x-small color="info" v-on="on"
              >show codes
            </v-btn>
          </template>
          <v-card class="pa-3" elevation="0">
            <div class="bolder primary--text">{{ $t('Units codes') }}</div>
            <div v-for="(code, key) in listingTask.free_unit_codes" :key="key">
              <div class="">
                {{ key }} -
                <span
                  :class="
                    code === 'Occupied' ? 'red--text' : 'black--text flex'
                  "
                  >{{ code }}</span
                >
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div
      v-if="listingTask.manager_review"
      class="d-flex align-center pa-2 pa-md-2"
    >
      <div class="black--text flex col-6 pa-0">{{ $t('Manager Review') }}</div>
      <v-icon>fas fa-check-square</v-icon>
    </div>
    <div
      v-if="listingTask.shopping_items.length"
      class="d-flex align-center mb-2"
    >
      <div class="secondary--text flex col-6 pa-0">
        {{ $t('Related Items') }}
      </div>

      <div class="flex col-6 pa-0">
        <shopping-list-modal :listing-task="listingTask" />
      </div>
    </div>
    <div
      v-if="listingTask.manual_approved"
      class="d-flex align-center pa-2 pa-md-2"
    >
      <v-divider />
      <div class="black--text flex col-6 pa-0">{{ $t('Approved By') }}</div>
      <div class="flex col-6 pa-0">{{ listingTask.approved_by_name }}</div>
    </div>
  </div>
</template>

<script>
import Assignee from 'components/common/assignee'
import AssigneeSelect from 'components/common/assignee-select'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import deviceMixin from 'components/mixins/device-mixin'
import { get, compact } from 'lodash/fp'
import ConfirmationModal from 'components/modals/confirmation-modal'
import ShoppingListModal from 'components/shopping-list/shopping-list-modal'
import DatePicker from 'components/form-fields/date-picker'
import LtPriority from 'components/listing-tasks/lt-priority'
import ContractorSelect from 'components/contractor-select.vue'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'
import ReviewCard from 'components/reviews/review-card.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ScMetadata',
  components: {
    ReviewCard,
    ContractorSelect,
    LtPriority,
    DatePicker,
    ShoppingListModal,
    AssigneeSelect,
    Assignee,
    ConfirmationModal,
  },
  mixins: [
    CommonFunctions,
    PermissionsMixin,
    deviceMixin,
    ListingTaskViewMixin,
  ],
  props: ['listingTask'],
  data() {
    return {
      showCodes: false,
      moreAssignees: false,
      initialAssignees: false,
    }
  },
  mounted() {
    this.moreAssignees = this.additionalContractors.length > 0
    this.initialAssignees = this.additionalContractors.length > 0
  },
  watch: {
    listingTask(newValue, oldValue) {
      if (
        newValue &&
        oldValue &&
        newValue.scheduled_at !== oldValue.scheduled_at
      ) {
        this.$emit('listing-task-updated')
      }
    },
  },
  computed: {
    ...mapGetters('users', ['activeUsers', 'usersMap', 'approvers']),
    currentReview() {
      return this.listingTask.review
    },
    warranty() {
      return this.listingTask.listing_task_warranty
    },
    additionalContractors() {
      return compact([
        this.listingTask.assigned_contractor1_id,
        this.listingTask.assigned_contractor2_id,
        this.listingTask.assigned_contractor3_id,
        this.listingTask.assigned_contractor4_id,
      ])
    },
    showMoreAssignees() {
      return !this.isContractor && (this.moreAssignees || this.initialAssignees)
    },
    phoneNumber() {
      return get('phone', this.assignedToUser)
    },
    createdByUser() {
      return this.usersMap[this.listingTask.created_by_id]
    },
    assignedToUser() {
      return this.usersMap[this.listingTask.assigned_contractor_id]
    },
    approverUser() {
      return this.usersMap[this.listingTask.requested_approver_id]
    },
    readonly() {
      return this.listingTask.status === 'Owner Approval'
    },
    listingAvailable() {
      return (
        this.listingTask.door_code &&
        !this.isDone(this.listingTask.status) &&
        this.listingTask.listing_available_time !== 'Occupied'
      )
    },
    googleMapsLink() {
      return this.listingTask.listing
        ? `https://www.google.com/maps/search/?api=1&query=${this.listingTask.listing.address}`
        : ''
    },
    timeLabelContainerClass() {
      if (this.isContractor || this.readonly) {
        return 'time-label-container disabled'
      } else {
        return 'time-label-container'
      }
    },
  },
  methods: {
    limited(e) {
      if (e.length > 4) {
        e.pop()
      }
    },
    toggleAssignees() {
      this.moreAssignees = !this.moreAssignees
      this.initialAssignees = false
    },
    changeAssignees(payload) {
      this.updateTask({
        additional_contractors: payload,
      })
    },
    allowedDates(val) {
      const parsedDate = this.$moment(val).utc().add(1, 'days')
      const timeNow = this.$moment().utc()
      return Boolean(
        timeNow <= parsedDate &&
          (!this.listingTask.house_vacant_dates[val] ||
            this.listingTask.house_check_in_dates[val] ||
            this.listingTask.house_check_out_dates[val])
      )
    },
    connectedLtUrl(id) {
      return { path: `/dashboard/listing-task/${id}` }
    },
    assignUser(user) {
      this.updateTask({
        assigned_contractor_id: user.id,
      })
    },
    changeApprover(user) {
      this.updateTask({
        requested_approver_id: user.id,
      })
    },
    statusChanged(value) {
      this.updateTask({ status: value })
    },
    priorityChanged(value) {
      this.updateTask({ priority: value })
    },
    scheduleAt(date) {
      if (date && date !== this.listingTask.scheduled_at) {
        const hour = this.listingTask.scheduled_at
          ? this.parseDate(this.listingTask.scheduled_at, 'HH:mm')
          : this.parseDate(date, 'HH:mm')
        this.updateTask({
          scheduled_at: date,
          hour: hour,
        })
      }
    },
    scheduleAtHour(date) {
      if (!date) {
        return
      }
      const time = this.parseDate(date, 'HH:mm')
      if (time !== this.parseDate(this.listingTask.scheduled_at, 'HH:mm')) {
        this.updateTask({
          hour: time,
        })
      }
    },
    openContinuationModal() {
      this.$store.commit('showModal', {
        name: 'LtContinuationHierarchyModal',
        props: { listingTaskId: this.listingTask.id },
      })
    },
    openDateTimePicker() {
      if (!this.isContractor && !this.readonly) {
        this.$refs.dvrDateTime.$el
          .getElementsByClassName('dvr-datetime')[0]
          .click()
      }
    },
  },
}
</script>

<style scoped>
>>> .v-input__slot {
  padding: 0 !important;
}

.v-divider {
  border-color: #e1e1e1 !important;
}
.icon-rotated {
  transform: rotate(45deg);
}

.time-label-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 2px;
}
.time-label-container.disabled {
  cursor: auto;
}
.time-label {
  width: 100%;
  text-align: center;
}
</style>
