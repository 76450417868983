<template>
  <div class="d-flex align-center">
    <v-dialog v-model="dialog" max-width="500">
      <template #activator="props" class="pb-0">
        <slot name="customBtn" v-bind="props" />
        <div v-if="!$scopedSlots.customBtn" v-on="props.on">
          <v-tooltip v-if="miniButton" bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" text height="32" x-small v-on="on">
                <v-icon v-if="isEdit" :color="dialog ? 'info' : ''" small
                  >mdi-pencil
                </v-icon>
                <v-icon v-else :color="dialog ? 'info' : ''" small
                  >mdi-check
                </v-icon>
                <v-icon :color="dialog ? 'info' : ''" small
                  >mdi-slash-forward
                </v-icon>
                <v-icon :color="dialog ? 'info' : ''" small>$alarm </v-icon>
              </v-btn>
            </template>
            <span>Close / snooze</span>
          </v-tooltip>
          <v-btn v-else small color="info" class="mx-2 float-right">
            Close / Snooze
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <h6 class="text-h6">Next action</h6>
        </v-card-title>
        <v-col v-if="!isEdit" class="mb-4">
          <v-btn-toggle
            v-model="actionType"
            color="primary accent-3"
            class="d-flex"
          >
            <v-btn class="flex-1" value="setFu">Set Follow Up</v-btn>
            <v-btn v-if="!conversation.resolved" class="flex-1" value="resolve">
              Close
            </v-btn>
            <v-btn
              v-if="conversation.resolved"
              class="flex-1"
              value="unresolve"
            >
              Open
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col v-if="actionType === 'unresolve' && !isEdit">
          <span> Are you sure you want to Re-open this ticket? </span>
          <v-card-actions class="justify-end">
            <v-btn color="info" outlined @click="close">Cancel</v-btn>
            <v-btn color="warn" @click="unResolve">Open</v-btn>
          </v-card-actions>
        </v-col>
        <v-form
          v-if="actionType === 'resolve'"
          ref="form"
          class="pa-5"
          @submit.prevent="resolve"
        >
          <ConversationTagMultiSelect
            v-if="isDesignedVr"
            class="mb-2"
            :filter-change="conversationTagsChange"
            :value="conversationTags"
            :multiple="true"
          />
          <v-text-field v-if="!isEdit" v-model="note" outlined label="Note" />
          <v-card-actions class="justify-end">
            <v-btn color="info" outlined @click="close">Cancel</v-btn>
            <v-btn color="info" type="submit">Close </v-btn>
          </v-card-actions>
        </v-form>
        <v-form
          v-if="actionType === 'setFu'"
          ref="form"
          class="pa-5"
          @submit.prevent="submit"
        >
          <ConversationTagMultiSelect
            v-if="!isEdit && !minimal && isDesignedVr"
            class="mb-2"
            :multiple="true"
            :filter-change="conversationTagsChange"
            :value="conversationTags"
          />
          <v-text-field v-if="!isEdit" v-model="note" outlined label="Note" />
          <v-divider class="mb-3" />
          <div v-if="isEdit" class="text-subtitle-1">
            Current follow-up time is:
            <b>{{
              parseDate(conversation.follow_up_at, 'ddd, MMM Do HH:mm', {
                local: true,
              })
            }}</b>
          </div>
          <v-btn-toggle
            v-model="timeType"
            color="primary accent-3"
            class="mb-4"
          >
            <v-btn small value="fixed"> Fixed</v-btn>
            <v-btn small value="custom"> Custom</v-btn>
          </v-btn-toggle>
          <v-menu
            v-if="timeType === 'custom'"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            required
            min-width="290px"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-model="date"
                outlined
                dense
                :rules="[required]"
                label="Follow-up on"
                prepend-inner-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker ref="picker" v-model="date" @input="menu = false" />
          </v-menu>
          <v-select
            v-else
            v-model="fixed"
            outlined
            :rules="[required]"
            label="Follow-up after"
            dense
            :items="followUpItems"
          />
          <v-card-actions class="justify-end">
            <v-btn color="info" outlined @click="close">Cancel</v-btn>
            <v-btn color="info" type="submit">OK</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <div
      v-if="conversation.follow_up_at && !isMobile"
      class="text-center me-2 rounded px-2 mx-3 text-no-wrap"
      :class="
        isBehind(conversation.follow_up_at)
          ? 'red lighten-5'
          : 'yellow lighten-5'
      "
    >
      <div class="text-caption">
        {{ timeFromNow(conversation.follow_up_at) }}
        <v-icon color="secondary" small @click.stop="unfollow">close</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import formRules from 'components/mixins/form-rules-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import ConversationTagMultiSelect from 'components/conversations/coversation-tag-multi-select'
import deviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'FollowUpModal',
  components: {
    ConversationTagMultiSelect,
  },
  mixins: [formRules, CommonFunctions, deviceMixin],
  props: ['conversation', 'miniButton', 'minimal'],
  data() {
    return {
      dialog: false,
      menu: false,
      date: null,
      fixed: null,
      conversationTags: null,
      note: null,
      timeType: 'fixed',
      actionType: 'setFu',
      shouldArchive: false,
    }
  },
  computed: {
    isEdit() {
      return Boolean(this.conversation.follow_up_at)
    },
    followUpItems() {
      return [
        '0.5 hours',
        '1 hours',
        '1.5 hours',
        '2 hours',
        '2.5 hours',
        '4 hours',
        '5 hours',
        '6 hours',
        '8 hours',
        '12 hours',
        '1 days',
        '2 days',
        '3 days',
        '4 days',
        '5 days',
        '6 days',
        '1 weeks',
        '2 weeks',
        '1 months',
        '2 months',
        '6 months',
      ]
    },
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        const { follow_up_at, resolved } = this
        const default_resolve = !resolved && !follow_up_at
        this.actionType = default_resolve ? 'resolve' : 'setFu'
        this.shouldArchive = false
        this.conversationTags = this.isEdit
          ? this.conversation.sales_status
          : null
      }
    },
  },
  methods: {
    conversationTagsChange(tags) {
      this.conversationTags = tags
    },
    close() {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.note = null
      this.dialog = false
    },
    isBehind(date) {
      const end = this.$moment.utc(date, 'YYYY-MM-DD  HH:mm:ss')
      const now = this.$moment.utc()
      return end.isBefore(now)
    },
    async resolve() {
      if (this.$refs.form.validate) {
        this.$router.push({ query: {} })
        const { conversationTags, note } = this
        const payload = {
          id: this.conversation.id,
          sales_status: conversationTags,
          note,
          resolved: true,
        }
        await this.$store.dispatch('conversationUpdate', payload)
        this.$emit('on-action', 'resolve')
        this.close()
      }
    },
    async archive() {
      if (this.$refs.form.validate) {
        const { conversationTags, note } = this
        const payload = {
          id: this.conversation.id,
          sales_status: conversationTags,
          note,
          archive: true,
        }
        await this.$store.dispatch('conversationUpdate', payload)
        this.$emit('on-action', 'archive')
        this.close()
      }
    },
    async unfollow() {
      await this.$store.dispatch('conversationUpdate', {
        id: this.conversation.id,
        follow_up_at: null,
      })
      this.$emit('on-action', 'unfollow')
    },
    async unResolve() {
      const payload = { id: this.conversation.id, resolved: false }
      await this.$store.dispatch('conversationUpdate', payload)
      this.$emit('on-action', 'unResolve')
      this.close()
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const { conversationTags, note } = this
        const payload = {
          id: this.conversation.id,
          sales_status: conversationTags,
          note,
        }
        if (this.timeType === 'fixed') {
          const [num, units] = this.fixed.split(' ')
          payload.follow_up_at = this.$moment()
            .utc()
            .add(num, units)
            .format('YYYY-MM-DD HH:mm')
        } else {
          payload.follow_up_at = this.date
        }
        await this.$store.dispatch('conversationUpdate', payload)
        this.$emit('on-action', 'setFollowUp')
        this.close()
      }
    },
  },
}
</script>

<style scoped></style>
