<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <template #activator="{ on }">
        <v-col cols="12" class="pa-0" v-on="!tooltip && !disabled && on">
          <v-row
            v-for="modalTask in orderedTasks"
            :key="modalTask.id"
            class="mt-4 mb-4 ml-2 mr-2 task-row"
          >
            <span :class="['pa-0', 'availability', availabilityClass]" />
            <v-col cols="12" md="9" class="pr-1">
              <div class="font-weight-bold d-flex mb-1 justify-space-between">
                <div class="ellipsis-1 mr-2">
                  <div>{{ modalTask.extra_data.listing_name }}</div>
                </div>
                <span v-if="!showPending" class="mr-2">{{
                  parseDate(modalTask.scheduled_at, 'ddd, MMM Do', {
                    local: true,
                  })
                }}</span>
              </div>
              <div class="ellipsis-3 text-start">
                {{ modalTask.description }}
              </div>
            </v-col>
            <v-col class="pt-0 pb-2 px-2 mx-1" cols="12">
              <div class="text-caption d-flex">
                <v-icon
                  class="mr-1"
                  :color="priorityColor(modalTask.priority)"
                  small
                  >mdi-circle
                </v-icon>
                <span class="mr-3">
                  {{ modalTask.priority }}
                </span>
                <span v-if="modalTask.allDay" class="font-light">All Day</span>
                <span v-else class="font-light">
                  {{ startTime(modalTask) }} - {{ endTime(modalTask) }}</span
                >
                <v-spacer></v-spacer>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-dialog>
    <v-divider class="mt-3 mb-3"></v-divider>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FormattersMixin from 'components/mixins/formatters-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import ColorsMixin from 'components/mixins/colors-mixin'
import LtMixin from 'components/mixins/lt-mixin'
import orderBy from 'lodash/orderBy'
import DeviceMixin from 'components/mixins/device-mixin'

export default {
  mixins: [ColorsMixin, DeviceMixin, FormattersMixin, CommonFunctions, LtMixin],
  props: ['task', 'disabled', 'showPending', 'mtasks'],
  data() {
    return {
      dialog: null,
      tooltip: false,
      accepted: false,
    }
  },
  computed: {
    ...mapState(['listingTasks', 'listingLoading']),
    availabilityText() {
      const availabilityText =
        this.availabilityTextMap[this.task.add_today_avialability_text]
      if (this.checkOutType) {
        return `${this.checkOutType} ${availabilityText}`
      } else if (this.checkInType) {
        return `${this.checkInType} ${availabilityText}`
      } else {
        return availabilityText
      }
    },
    orderedTasks() {
      return this.mtasks
    },
    totalCountForDay() {
      const count = this.$store.state.listingTaskTotalCount.filter(
        date => date[0] == this.parseDate(this.task.scheduled_at)
      )[0][1]
      return `You have ${count} jobs for this day`
    },
    plannedDeparture() {
      return this.extraData.late_checkout_purchase
    },
    plannedArrival() {
      return this.extraData.early_check_in_purchase
    },
    originArrival() {
      return this.extraData.origin_check_in_time
    },
    originDeparture() {
      return this.extraData.origin_check_out_time
    },
    checkInType() {
      if (this.plannedArrival && this.plannedArrival !== this.originArrival) {
        return +this.plannedArrival.split(':')[0] <
          +this.originArrival.split(':')[0]
          ? 'ECI'
          : 'LCI'
      }
      return ''
    },
    checkOutType() {
      if (
        this.plannedDeparture &&
        this.plannedDeparture !== this.originDeparture
      ) {
        return +this.plannedDeparture.split(':')[0] >
          +this.originDeparture.split(':')[0]
          ? 'LCO'
          : 'ECO'
      }
      return ''
    },
    availabilityClass() {
      if (this.checkOutType) {
        return `${this.checkOutType} ${this.task.add_today_avialability_text}`
      } else if (this.checkInType) {
        return `${this.checkInType} ${this.task.add_today_avialability_text}`
      } else {
        return this.task.add_today_avialability_text
      }
    },
    extraData() {
      return this.task.mini_extra_data || this.task.extra_data || {}
    },
  },
  methods: {
    startTime(modalTask) {
      return this.parseDate(modalTask.scheduled_at, 'h:mm')
    },
    endTime(modalTask) {
      return this.parseDate(modalTask.scheduled_at_end, 'h:mm')
    },
  },
}
</script>
<style scoped>
.task-button {
  height: 80px !important;
  width: 80px !important;
}
.word-break {
  word-break: initial;
}
.task-row {
  position: relative;
}
.availability {
  width: 4px;
  height: 100%;
  position: absolute;
  border-radius: 0;
}
</style>
