<template>
  <v-menu
    :value="open"
    :position-x="posX"
    :position-y="posY"
    absolute
    :z-index="99"
    offset-y
    max-width="400"
  >
    <v-row v-if="event" no-gutters class="secondary lighten-1 pa-0">
      <task-calendar-cell
        v-if="open"
        :users-map="usersMap"
        mode="menu"
        :event="event"
      />
    </v-row>
  </v-menu>
</template>

<script>
import ColorsMixin from 'components/mixins/colors-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import TaskCalendarCell from 'components/calendar/task-calendar-cell'

export default {
  name: 'CalendarEventContext',
  components: { TaskCalendarCell },
  mixins: [CommonFunctions, ColorsMixin],
  props: ['event', 'open', 'posX', 'posY', 'usersMap'],
}
</script>

<style scoped></style>
