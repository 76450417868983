<template>
  <v-tooltip bottom max-width="600">
    <template #activator="{ on }">
      <v-icon class="ml-2" color="primary" v-on="on">mdi-help-circle</v-icon>
    </template>
    <div class="formula-tooltip">
      <div class="tooltip-title">Available Formula Variables</div>
      <div class="variables-section">
        <div class="section-title">Financial Variables</div>
        <div class="variable-list">
          <div class="variable-item">
            <span class="variable-name">fare_accommodation</span>
            <span class="variable-desc"
              >The nightly rate applied to the reservation</span
            >
          </div>
          <div class="variable-item">
            <span class="variable-name">cleaning_fee</span>
            <span class="variable-desc"
              >The cleaning charge collected from the guest</span
            >
          </div>
          <div class="variable-item">
            <span class="variable-name">host_channel_fee</span>
            <span class="variable-desc"
              >Fee charged by the booking platform</span
            >
          </div>
          <div class="variable-item">
            <span class="variable-name">processing_fee</span>
            <span class="variable-desc"
              >Transaction processing costs for MOR reservations</span
            >
          </div>
          <div class="variable-item">
            <span class="variable-name">host_payout | price</span>
            <span class="variable-desc"
              >Total amount received from the booking channel</span
            >
          </div>
          <div class="variable-item">
            <span class="variable-name">client_price</span>
            <span class="variable-desc"
              >Amount paid by guest including fees</span
            >
          </div>
          <div class="variable-item">
            <span class="variable-name">net_income</span>
            <span class="variable-desc"
              >Earnings after deducting fees and expenses</span
            >
          </div>
          <div class="variable-item">
            <span class="variable-name">total_taxes</span>
            <span class="variable-desc"
              >Total taxes reported on the reservation</span
            >
          </div>
          <div class="variable-item">
            <span class="variable-name">commission_percent</span>
            <span class="variable-desc">Defined commission percentage</span>
          </div>
          <div class="variable-item">
            <span class="variable-name">pmc_fees</span>
            <span class="variable-desc">OTA fees that belong to PMC</span>
          </div>
          <div class="variable-item">
            <span class="variable-name">owner_fees</span>
            <span class="variable-desc">OTA fees that belong to Owner</span>
          </div>
          <div class="variable-item">
            <span class="variable-name">management_commission</span>
            <span class="variable-desc">Total fee charged by PMC</span>
          </div>
        </div>
      </div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'FormulaVariablesToolTip',
}
</script>

<style lang="scss" scoped>
.formula-tooltip {
  padding: 16px;

  .tooltip-title {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 12px;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 8px;
  }

  .variables-section {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .section-title {
      font-weight: 600;
      color: #fff;
      margin-bottom: 8px;
      font-size: 0.95em;
    }

    .variable-list {
      .variable-item {
        display: flex;
        margin-bottom: 6px;
        align-items: baseline;

        &:last-child {
          margin-bottom: 0;
        }

        .variable-name {
          color: #8ce99a;
          font-family: monospace;
          font-weight: 600;
          margin-right: 8px;
          min-width: 160px;
        }

        .variable-desc {
          color: rgba(255, 255, 255, 0.9);
          font-size: 0.9em;
          line-height: 1.4;
        }
      }
    }
  }
}
</style>
