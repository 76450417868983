<template>
  <div class="chat-filters">
    <v-btn-toggle :value="type" color="primary" borderless tile>
      <v-tooltip v-for="item in items" :key="item.text" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            height="64"
            :color="type === item.value ? 'primary' : 'white'"
            v-on="on"
            @click="updateFilter(item.value)"
          >
            <v-icon :color="type === item.value ? 'white' : 'primary'">
              {{ item.icon }}
            </v-icon>
            <v-badge v-if="chatStats && chatCounter(item.value)" class="ml-1">
              <template #badge>
                {{ chatCounter(item.value) }}
              </template>
            </v-badge>
          </v-btn>
        </template>
        <span>{{ item.text }}</span>
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChatFilters',
  props: {
    type: {
      type: String,
      default: 'Un-Assigned',
    },
    items: {
      type: Array,
      default: () => [],
    },
    stats: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState(['chatStats']),
  },
  methods: {
    updateFilter(value) {
      this.$emit('type-change', value)
    },
    chatCounter(type) {
      switch (type) {
        case 'Potential-Inquiries':
          return this.chatStats.potential_inquiries
        case 'Un-Assigned':
          return this.chatStats.un_assigned
        case 'My-Tickets':
          return this.chatStats.my_tickets
        case 'Need-to-Follow':
          return this.chatStats.need_to_follow_up
        case 'Un-Resolved':
          return this.chatStats.unresolved
        case 'Un-Answered':
          return this.chatStats.last_24_unanswered
        case 'Unpaid':
          return this.chatStats.have_payment_problem
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.chat-filters {
  .v-btn-toggle {
    width: 100%;
    .v-btn {
      flex-grow: 1;
    }
  }
}
</style>
