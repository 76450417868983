<template>
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m489.341 258.495v-187.468c0-5.523-4.478-10-10-10h-44.628c-5.522 0-10 4.477-10 10v15.921h-109.38c-13.062-19.948-35.277-32.109-59.333-32.109s-46.271 12.161-59.333 32.109h-109.38v-15.921c0-5.523-4.478-10-10-10h-44.628c-5.522 0-10 4.477-10 10v187.468c-13.639 8.983-22.659 24.433-22.659 41.95v106.868c0 5.523 4.478 10 10 10h7.902v29.848c0 5.523 4.478 10 10 10h44.628c5.522 0 10-4.477 10-10v-29.848h346.94v29.848c0 5.523 4.478 10 10 10h44.628c5.522 0 10-4.477 10-10v-29.848h7.902c5.522 0 10-4.477 10-10v-106.868c0-17.517-9.02-32.967-22.659-41.95zm-44.628-177.468h24.628v169.789c-2.458-.372-4.974-.565-7.534-.565h-17.094zm-242.373 25.921c3.677 0 7.057-2.018 8.802-5.254 8.933-16.564 26.121-26.854 44.858-26.854s35.926 10.29 44.858 26.854c1.745 3.236 5.125 5.254 8.802 5.254h115.053v143.304h-21.992c1.527-3.737 2.369-7.824 2.369-12.104v-33.448c0-17.702-14.401-32.104-32.104-32.104h-71.746c-17.702 0-32.104 14.401-32.104 32.104v33.448c0 4.28.842 8.367 2.369 12.104h-29.195c1.527-3.737 2.369-7.824 2.369-12.104v-33.448c0-17.702-14.401-32.104-32.104-32.104h-71.747c-17.702 0-32.104 14.401-32.104 32.104v33.448c0 4.28.842 8.367 2.369 12.104h-23.806v-143.304zm86.796 131.199v-33.448c0-6.674 5.43-12.104 12.104-12.104h71.746c6.674 0 12.104 5.43 12.104 12.104v33.448c0 6.674-5.43 12.104-12.104 12.104h-71.746c-6.675 0-12.104-5.429-12.104-12.104zm-160.411 0v-33.448c0-6.674 5.43-12.104 12.104-12.104h71.747c6.674 0 12.104 5.43 12.104 12.104v33.448c0 6.674-5.43 12.104-12.104 12.104h-71.747c-6.675 0-12.104-5.429-12.104-12.104zm-86.066-157.12h24.628v169.225h-17.094c-2.56 0-5.076.193-7.534.565zm-22.659 219.418c0-16.649 13.545-30.193 30.193-30.193h411.613c16.648 0 30.193 13.544 30.193 30.193v96.868h-28.894v-59.368c0-18.355-14.934-33.289-33.289-33.289h-347.633c-18.355 0-33.289 14.934-33.289 33.289v59.368h-28.894zm48.894 96.868v-59.368c0-7.328 5.962-13.289 13.289-13.289h347.635c7.327 0 13.289 5.961 13.289 13.289v59.368zm-6.364 39.848h-24.628v-19.848h24.628zm411.568 0h-24.628v-19.848h24.628z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Bedroom',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
