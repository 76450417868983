import orderBy from 'lodash/orderBy'

export const orderTasks = tasks => {
  return orderBy(
    tasks,
    ['allDay', 'scheduled_at', 'priorityOrder'],
    ['asc', 'asc', 'asc']
  )
}

export const mapTaskOrder = tasks => {
  const orderedTasks = orderTasks(tasks)
  return orderedTasks.reduce((acc, task) => {
    const contractor = task.assigned_contractor_id || 'unassigned'
    acc[contractor]
      ? acc[contractor].push(task.id)
      : (acc[contractor] = [task.id])
    return acc
  }, {})
}

export const FILTER_OPTIONS = [
  {
    value: 'today_checkins',
    text: 'Today Checkins',
  },
  {
    value: 'today_checkouts',
    text: 'Today Checkouts',
  },
  {
    value: 'free_today',
    text: 'Free Today',
  },
]

export const getTaskOrder = (task, taskOrder) => {
  if (!taskOrder[task.assigned_contractor_id]) return
  const order = taskOrder[task.assigned_contractor_id].indexOf(task.id)
  return order + 1
}
