<template>
  <v-card :loading="listingLoading" outlined>
    <v-card-title class="font-weight-regular secondary--text">{{
      currentPricingMonthly.month_name + ' Pricing Stats'
    }}</v-card-title>
    <v-card-subtitle class="text-caption font-weight-light">
      In the pricing stats we only take into consideration the fare
      accommodation and not the total.
    </v-card-subtitle>
    <v-card-text>
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th></th>
              <th width="150">
                {{ currentPricingMonthly.current_year - 1 }}
              </th>
              <th width="150">
                {{ currentPricingMonthly.current_year }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in pricingStats" :key="item.title">
              <td>
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon small class="mr-2" v-on="on"
                      >fas fa-question-circle</v-icon
                    ></template
                  >
                  <span>{{ item.tooltip }}</span>
                </v-tooltip>
                {{ item.title }}
              </td>
              <td>
                <span v-if="item.title.toLowerCase() === 'occupancy'">
                  {{
                    item.lastYearValue !== 0
                      ? `${round(item.lastYearValue)}%`
                      : 'N/A'
                  }}
                </span>
                <span v-else>
                  {{
                    item.lastYearValue
                      ? dollarFormatter(item.lastYearValue)
                      : 'N/A'
                  }}
                </span>
              </td>
              <td>
                <span v-if="item.title.toLowerCase() === 'occupancy'">
                  {{
                    item.currentValue !== 0
                      ? `${round(item.currentValue)}%`
                      : 'N/A'
                  }}
                </span>
                <span v-else>
                  {{
                    item.currentValue !== 0
                      ? dollarFormatter(item.currentValue)
                      : 'N/A'
                  }}
                </span>
                <v-icon
                  v-if="
                    !['left to sell adr', 'left to sell sum'].includes(
                      item.title.toLowerCase()
                    )
                  "
                  :color="
                    item.level === 'arrow_upward'
                      ? 'success'
                      : item.level === 'arrow_downward'
                      ? 'warning'
                      : ''
                  "
                  >{{ item.level }}</v-icon
                >
                <span v-else>
                  {{ item.level }}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
export default {
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['title'],
  computed: {
    pricingStats() {
      return [
        {
          title: 'Occupancy',
          tooltip:
            'The amount of days the house was occupied throughout the month devided by the amount of days of that month',
          lastYearValue: this.currentPricingMonthly['last_year_occupancy'],
          currentValue: this.currentPricingMonthly.occupancy,
          level: this.biggerOccThisYear,
        },
        {
          title: 'Confirmed ADR',
          tooltip: 'The average daily rate for confirmed reservations',
          lastYearValue: this.currentPricingMonthly['last_year_confirmed_adr'],
          currentValue: this.currentPricingMonthly['confirmed_adr'],
          level: this.biggerAdrThisYear,
        },
        {
          title: 'Left to sell ADR',
          tooltip: "The average daily rate of the days thats weren't sold yet",
          lastYearValue: null,
          currentValue: this.currentPricingMonthly['unconfirmed_adr'],
          level: this.adrDiffText,
        },
        {
          title: 'Confirmed Sum',
          tooltip:
            'The total fare accommodation charged for confirmed reservations',
          lastYearValue: this.currentPricingMonthly['last_year_confirmed_sum'],
          currentValue: this.currentPricingMonthly['confirmed_sum'],
          level: this.biggerConfirmedSumThisYear,
        },
        {
          title: 'Left to sell Sum',
          tooltip:
            "The total fare accommodation available to charge for the days that weren't sold yet",
          lastYearValue: null,
          currentValue: this.currentPricingMonthly['unconfirmed_sum'],
          level: this.sumDiffText,
        },
        {
          title: 'Total',
          tooltip:
            'The total fare accommodation for all of the days left, confirmed or left to sell, the full potential of the current month',
          lastYearValue: this.currentPricingMonthly['last_year_confirmed_sum'],
          currentValue:
            this.currentPricingMonthly['unconfirmed_sum'] +
            this.currentPricingMonthly['confirmed_sum'],
          level: this.biggerTotalThisYear,
        },
      ]
    },
    currentPricingMonthly() {
      return this.$store.state.currentPricingMonthly
    },
    biggerConfirmedSumThisYear() {
      if (
        this.currentPricingMonthly.confirmed_sum >
        this.currentPricingMonthly.last_year_confirmed_sum
      ) {
        return 'arrow_upward'
      } else {
        return 'arrow_downward'
      }
    },
    biggerAdrThisYear() {
      if (
        this.currentPricingMonthly.confirmed_adr >
        this.currentPricingMonthly.last_year_confirmed_adr
      ) {
        return 'arrow_upward'
      } else {
        return 'arrow_downward'
      }
    },
    biggerOccThisYear() {
      if (
        this.currentPricingMonthly.occupancy >
        this.currentPricingMonthly.last_year_occupancy
      ) {
        return 'arrow_upward'
      } else {
        return 'arrow_downward'
      }
    },
    biggerTotalThisYear() {
      if (
        this.currentPricingMonthly.unconfirmed_sum +
          this.currentPricingMonthly.confirmed_sum >
        this.currentPricingMonthly.last_year_confirmed_sum
      ) {
        return 'arrow_upward'
      } else {
        return 'arrow_downward'
      }
    },
    adrDiffText() {
      const diff =
        this.currentPricingMonthly.unconfirmed_adr -
        this.currentPricingMonthly.unconfirmed_adr_other
      if (diff !== 0) {
        return `(${this.currentPricingMonthly.unconfirmed_adr_other})`
      }
      return ''
    },
    sumDiffText() {
      const diff =
        this.currentPricingMonthly.unconfirmed_sum -
        this.currentPricingMonthly.unconfirmed_sum_other
      if (diff !== 0) {
        return `(${this.currentPricingMonthly.unconfirmed_sum_other})`
      }
      return ''
    },
  },
}
</script>
