<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.631"
    height="22.632"
    viewBox="0 0 22.631 22.632"
  >
    <g transform="translate(-470.139 -150)">
      <g transform="translate(470.139 150)">
        <path
          class="a"
          d="M8.958,7.544H1.414A1.416,1.416,0,0,1,0,6.129V1.414A1.416,1.416,0,0,1,1.414,0H8.958a1.416,1.416,0,0,1,1.414,1.414V6.129A1.416,1.416,0,0,1,8.958,7.544ZM1.414.943a.472.472,0,0,0-.471.471V6.129a.472.472,0,0,0,.471.471H8.958a.472.472,0,0,0,.471-.471V1.414A.472.472,0,0,0,8.958.943Z"
        />
      </g>
      <g transform="translate(470.139 159.43)">
        <path
          class="a"
          d="M8.958,23.2H1.414A1.416,1.416,0,0,1,0,21.787V11.414A1.416,1.416,0,0,1,1.414,10H8.958a1.416,1.416,0,0,1,1.414,1.414V21.787A1.416,1.416,0,0,1,8.958,23.2ZM1.414,10.943a.472.472,0,0,0-.471.471V21.787a.472.472,0,0,0,.471.471H8.958a.472.472,0,0,0,.471-.471V11.414a.472.472,0,0,0-.471-.471Z"
          transform="translate(0 -10)"
        />
      </g>
      <g transform="translate(482.396 165.088)">
        <path
          class="a"
          d="M21.958,23.544H14.414A1.416,1.416,0,0,1,13,22.129V17.414A1.416,1.416,0,0,1,14.414,16h7.544a1.416,1.416,0,0,1,1.414,1.414v4.715A1.416,1.416,0,0,1,21.958,23.544Zm-7.544-6.6a.472.472,0,0,0-.471.471v4.715a.472.472,0,0,0,.471.471h7.544a.472.472,0,0,0,.471-.471V17.414a.472.472,0,0,0-.471-.471Z"
          transform="translate(-13 -16)"
        />
      </g>
      <g transform="translate(482.396 150)">
        <path
          class="a"
          d="M21.958,13.2H14.414A1.416,1.416,0,0,1,13,11.787V1.414A1.416,1.416,0,0,1,14.414,0h7.544a1.416,1.416,0,0,1,1.414,1.414V11.787A1.416,1.416,0,0,1,21.958,13.2ZM14.414.943a.472.472,0,0,0-.471.471V11.787a.472.472,0,0,0,.471.471h7.544a.472.472,0,0,0,.471-.471V1.414a.472.472,0,0,0-.471-.471Z"
          transform="translate(-13)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'DashboardTiles',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
