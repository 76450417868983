import { filter, now, sumBy } from 'lodash'

export default {
  data() {
    return {
      clock: {},
      timer: {},
    }
  },
  computed: {},
  methods: {
    startClocking(userId, taskID, moment = new Date()) {
      this.$store.dispatch('clockInUser', {
        id: taskID,
        user_id: userId,
        task_type: 'listing-task',
      })
      this.clocking(userId, moment)
    },
    stopClocking(userId, taskID) {
      this.$store.dispatch('clockOutUser', {
        id: taskID,
        user_id: userId,
        task_type: 'listing-task',
      })
      this.clocking(userId, null)
    },
    formatRole(role) {
      return role.replace('-', ' ')
    },
    totalTime(times) {
      return sumBy(times, 'duration')
    },
    cleanerTimes(userId, task) {
      return filter(task.working_cleaners, { user_id: userId })
    },
    cleanerTotalTime(id, task) {
      return this.totalTime(this.cleanerTimes(id, task))
    },
    clocking(userId, moment) {
      if (moment) {
        this.$set(this.clock, userId, null)
        this.$set(
          this.timer,
          userId,
          setInterval(() => {
            this.clock[userId] = this.$moment(now() - moment)
              .utc()
              .format('HH:mm:ss')
          }, 1000)
        )
      } else {
        clearInterval(this.timer[userId])
        this.$delete(this.clock, userId)
        this.$delete(this.timer, userId)
      }
    },
  },
}
