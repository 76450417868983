<template>
  <v-list-item class="elevation-2 rounded mb-3 pa-0">
    <v-list-item-avatar
      v-if="item.images && item.images.length"
      rounded
      :size="isMobile ? 100 : 130"
      class="ma-0"
    >
      <v-img :src="item.images[0]" />
    </v-list-item-avatar>
    <v-list-item-content class="mx-4">
      <v-list-item-title>
        <div
          class="d-flex flex-column flex-sm-row justify-space-between align-sm-center mb-2 mb-sm-4"
        >
          <div v-if="item.name" class="mb-2 mb-sm-0">{{ item.name }}</div>
          <div class="ml-sm-2">
            <shop-item-status
              :value="item.status"
              :small="isMobile"
              :readonly="true"
            />

            <v-btn
              v-if="removable"
              color="warning"
              icon
              small
              @click="$emit('remove-item')"
            >
              <v-icon small>$trash</v-icon>
            </v-btn>
          </div>
        </div>
      </v-list-item-title>
      <v-list-item-subtitle>
        <div class="d-flex justify-space-between mr-4">
          <div>
            {{ item.store_name }}
          </div>
          <div v-show="item.delivered_at" class="text-right">
            <div class="text-caption secondary--text">Est. Arrival</div>
            <div>
              {{ parseDate(item.delivered_at, 'DD MMM YYYY') }}
            </div>
          </div>
        </div>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import DeviceMixin from 'components/mixins/device-mixin'
import ShopItemStatus from 'components/shopping-list/shop-item-status'
export default {
  name: 'ShoppingListItem',
  components: { ShopItemStatus },
  mixins: [DeviceMixin, CommonFunctions],
  props: {
    item: Object,
    removable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped></style>
