<template>
  <v-dialog v-model="dialog" max-width="500px" class="rounded-xl">
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>

    <v-card>
      <v-card-title class="black--text d-flex text-h5">
        <v-avatar tile size="60" class="rounded">
          <img :src="storeItem.image" alt="" />
        </v-avatar>
        <div class="flex d-flex px-3 flex-column">
          <div class="text-subtitle-1 text-sm-h6">
            {{ storeItem.name }}
          </div>
          <div class="text-subtitle-1">
            {{ priceUnits }}
          </div>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="py-4 black--text">
        <v-form v-if="hasSpecifications" ref="form" class="pt-3">
          <div v-for="option in options" :key="option.key">
            <div v-if="option.units === 'datetime' && option.type === 'select'">
              <div class="text-subtitle-1 font-weight-medium">
                {{ parseLabel(option) }}
              </div>
              <v-select
                v-model="fields[option.key]"
                :rules="[required]"
                outlined
                color="black"
                :items="option.value"
              >
                <template #selection="{ item }">
                  <span>{{ formatDate(item) }}</span>
                </template>
                <template #item="{ item }">
                  <span>{{ formatDate(item) }}</span>
                </template>
              </v-select>
            </div>
          </div>
        </v-form>
        <div class="text-subtitle-1">Description</div>
        <div class="text-caption">
          {{ storeItem.description }}
        </div>
        <div class="py-3">
          <div v-if="storeItem.bullets" class="text-subtitle-1">
            What's included?
          </div>
          <div v-if="storeItem.bullets" class="text-caption d-flex">
            <v-row no-gutters class="flex-wrap">
              <v-col v-for="i in storeItem.bullets" :key="i" cols="12" sm="6">
                <li class="text-capitalize">
                  {{ i }}
                </li>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="storeItem.disclaimer" class="pb-3">
          <div class="text-subtitle-1">Disclaimer</div>
          <div class="text-caption">
            {{ storeItem.disclaimer }}
          </div>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions class="flex-wrap pa-5">
        <div
          class="d-flex py-2 flex text-subtitle-1 font-weight-medium justify-space-between"
        >
          <div>Total Worth</div>
          <div>{{ dollarFormatter(price.total) }}</div>
        </div>
        <v-btn
          color="tertiary"
          outlined
          block
          class="rounded"
          @click="grantForFree"
        >
          Grant For Free
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import isEmpty from 'lodash/fp/isEmpty'
import { DISABLED_STORE_ITEM_STATUSES } from '@/consts'
import FormRules from 'components/mixins/form-rules-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import moment from 'moment-timezone'
import axios from 'axios'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'StoreItemDialog',
  mixins: [FormRules, DeviceMixin, CommonFunctions, FormattersMixin],
  props: ['reservationId', 'storeItem', 'price', 'options', 'status'],
  data() {
    const fields = this.options.reduce((acc, op) => {
      acc[op.key] = null
      return acc
    }, {})
    return {
      dialog: false,
      fields,
    }
  },
  computed: {
    priceUnits() {
      if (this.price.per_hour) {
        return `$${this.price.per_hour}/hour`
      }
      if (this.price.per_day) {
        return `$${this.price.per_day}/day`
      }
      return ''
    },
    hasSpecifications() {
      return !isEmpty(this.options)
    },
    isDisabled() {
      return DISABLED_STORE_ITEM_STATUSES.includes(this.status)
    },
    buttonText() {
      if (this.isDisabled) {
        return this.status.replace('_', ' ')
      }
      return 'View More'
    },
    itemDescription() {
      if (this.status === 'future') {
        return `Will be available ${moment
          .utc(this.storeItem.available_from)
          .format('MMM Do')}`
      }
      return ''
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    formatDate(item) {
      const startDate = moment.utc(item).format('ddd, MMM Do, HH:mm')
      if (this.storeItem.duration) {
        return `${startDate} - ${moment
          .utc(item)
          .add(this.storeItem.duration, 'hour')
          .format('HH:mm')}`
      }
      return startDate
    },
    parseLabel({ label, units }) {
      if (units === 'datetime') {
        return 'Select date & time'
      }
      return label.replace('_', ' ')
    },
    async grantForFree() {
      if (
        !this.hasSpecifications ||
        (this.hasSpecifications && this.$refs.form.validate())
      ) {
        const res = await axios.post(`/api/store/grant`, {
          reservation_id: this.reservationId,
          item_id: this.storeItem.id,
          options: this.fields,
        })
        this.closeDialog()
        this.$emit('after-grant', res)
      }
    },
  },
}
</script>

<style scoped></style>
