<template>
  <v-col cols="12" class="pa-10">
    <div class="d-flex align-baseline mb-5">
      <listing-task-module :hide-button="false" />
      <project-modal :existing-project="editingProject" />
      <v-switch
        v-model="showInactive"
        class="ml-5"
        hide-details
        inset
        label="Show Inactive Projects"
      />
    </div>
    <v-expansion-panels>
      <v-expansion-panel v-for="(project, key) in activeProjects" :key="key">
        <v-expansion-panel-header
          class="py-3"
          @click="fetchProjectTasks(project.id, key)"
        >
          <v-row class="d-flex align-center justify-space-between">
            <div class="bolder">
              <v-icon class="mr-2" small @click="editProject(project)">
                edit
              </v-icon>
              {{ project.name }}
              <span v-if="project.description" class="font-weight-regular">
                | {{ project.description }}</span
              >
            </div>
            <div class="mr-5 d-flex align-center">
              <div class="mr-5">
                <v-progress-circular
                  color="secondary"
                  :width="7"
                  :value="
                    (project.listing_tasks_closed_count /
                      project.listing_tasks_count) *
                    100
                  "
                />
                <div class="text-caption" style="text-align: center">
                  {{ project.listing_tasks_closed_count }} /
                  {{ project.listing_tasks_count }}
                </div>
              </div>
              <v-chip small :color="getColor(project.status)">
                {{ project.status }}
              </v-chip>
            </div>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-for="(task, key) in project.listingTasks"
            :key="key"
            class="mb-2 grey lighten-5 rounded"
          >
            <task-calendar-cell
              mode="list"
              :users-map="usersMap"
              :event="mapServiceCall(task)"
              :open-in-new-tab-on-click="true"
              :hide-actions="true"
            />
          </v-row>
          <v-row>
            <confirmation-modal
              text="Are you sure you want to delete project with all service calls?"
              @action="deleteProject(project.id)"
            >
              <template #activator="{ on }">
                <v-btn
                  color="warning"
                  class="text-capitalize"
                  elevation="0"
                  v-on="on"
                >
                  Delete project
                </v-btn>
              </template>
            </confirmation-modal>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</template>

<script>
import TaskCalendarCell from 'components/calendar/task-calendar-cell'
import CalendarMixin from 'components/mixins/calendar-mixin'
import ProjectModal from 'components/projects/project-modal.vue'
import ConfirmationModal from 'components/modals/confirmation-modal'
import { mapState, mapGetters } from 'vuex'
import ListingTaskModule from 'components/listing-task-module.vue'

export default {
  components: {
    ListingTaskModule,
    TaskCalendarCell,
    ProjectModal,
    ConfirmationModal,
  },
  mixins: [CalendarMixin],
  data() {
    return {
      projects: [],
      editingProject: null,
      showInactive: false,
      STATUS_COLORS: {
        ACTIVE: 'success',
        DONE: 'info',
      },
    }
  },
  mounted() {
    this.fetchProjects()
  },
  computed: {
    ...mapState('projects', ['activeProjects']),
    ...mapGetters('users', ['usersMap']),
  },
  watch: {
    showInactive() {
      this.fetchProjects()
    },
  },
  methods: {
    fetchProjects() {
      this.$store.dispatch('projects/getProjects', {
        without_inactive: !this.showInactive,
        without_estimate: false,
      })
    },
    getColor(status) {
      return this.STATUS_COLORS[status]
    },
    fetchProjectTasks(projectId, index) {
      this.$store.dispatch('projects/getProjectTasks', { projectId, index })
    },
    editProject(project) {
      this.editingProject = { ...project }
    },
    deleteProject(projectId) {
      this.$store.dispatch('projects/deleteProject', projectId)
    },
  },
}
</script>
