<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.312"
    height="25"
    viewBox="0 0 22.312 25"
  >
    <g transform="translate(-142.232 -132.5)">
      <g transform="translate(-2.297)">
        <g transform="translate(144.529 151.798) rotate(-90)">
          <path
            class="a"
            d="M4.565,4.707a.776.776,0,0,0-1.131,0L1.6,6.624V.836A.819.819,0,0,0,.8,0,.819.819,0,0,0,0,.836V8.643a.838.838,0,0,0,.494.773A.759.759,0,0,0,.8,9.479a.782.782,0,0,0,.565-.244l3.2-3.346A.864.864,0,0,0,4.565,4.707Z"
          />
        </g>
        <path
          class="a"
          d="M4.565,3.59,1.365.245a.776.776,0,0,0-1.131,0,.864.864,0,0,0,0,1.183l3.2,3.346a.777.777,0,0,0,1.131,0A.864.864,0,0,0,4.565,3.59Z"
          transform="translate(148.992 155) rotate(-90)"
        />
      </g>
      <g transform="translate(136.711 128.5)">
        <path
          class="b"
          d="M27.333,30.5V27.833A5.333,5.333,0,0,0,22,22.5H16.746"
          transform="translate(0 -2)"
        />
        <path
          class="b"
          d="M22.667,9.833A5.333,5.333,0,1,1,17.333,4.5a5.333,5.333,0,0,1,5.333,5.333Z"
          transform="translate(-0.667)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PersonArrowRight',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}

.b {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
