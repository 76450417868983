import { render, staticRenderFns } from "./follow-up-modal.vue?vue&type=template&id=0cd9a330&scoped=true&"
import script from "./follow-up-modal.vue?vue&type=script&lang=js&"
export * from "./follow-up-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd9a330",
  null
  
)

export default component.exports