var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.trackedListingTask)?_c('v-container',{staticClass:"pa-0 issue-tracker-wrapper d-flex",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"issue-tracker-bg d-none d-lg-block",attrs:{"cols":"12","lg":"6"}}),_c('v-col',{staticClass:"pa-6 pa-sm-12",attrs:{"cols":"12","lg":"6"}},[_c('h2',{staticClass:"text-capitalize text-body-1 secondary--text mb-6"},[_vm._v(" Issue tracker ")]),_c('v-card',[_c('v-card-text',[_c('v-chip',{staticClass:"text-uppercase mb-2",attrs:{"color":_vm.taskStatusColor(_vm.trackedListingTask.status)['bg'],"label":"","small":""}},[_c('span',{class:_vm.taskStatusColor(_vm.trackedListingTask.status)['textColor']},[_vm._v(_vm._s(_vm.trackedListingTask.status))])]),_c('p',{staticClass:"text-h6 mb-2"},[_vm._v(" Listing: "+_vm._s(_vm.trackedListingTask.extra_data.listing_name)+" ")]),_c('p',[_vm._v(_vm._s(_vm.trackedListingTask.description))]),_c('v-timeline',{attrs:{"dense":""}},[_c('v-timeline-item',{attrs:{"color":"green lighten-5","fill-dot":"","icon":"done","icon-color":"green darken-1"}},[_c('p',{staticClass:"font-weight-medium mb-0"},[_vm._v("Issue was created")]),_c('p',{staticClass:"text-caption secondary--text mb-0"},[_vm._v(" "+_vm._s(_vm.$moment(new Date(_vm.trackedListingTask.created_at)).format( 'YYYY-MM-DD HH:mm a' ))+" ")])]),_c('v-timeline-item',{attrs:{"color":_vm.isAssigned ? 'green lighten-5' : 'grey lighten-3',"icon":_vm.isAssigned ? 'done' : 'clear',"icon-color":_vm.isAssigned ? 'green darken-1' : 'white',"fill-dot":""}},[(_vm.isAssigned)?_c('p',{staticClass:"font-weight-medium mb-0"},[(
                    _vm.trackedListingTask.assigned_contractor.first_name ||
                    _vm.trackedListingTask.assigned_contractor.last_name
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.trackedListingTask.assigned_contractor.first_name)+" "+_vm._s(_vm.trackedListingTask.assigned_contractor.last_name)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.trackedListingTask.assigned_contractor.email)+" ")]),_vm._v(" was assigned ")]):_c('p',{staticClass:"mb-0"},[_vm._v("Not assigned yet")])]),_c('v-timeline-item',{attrs:{"color":_vm.isAssigned && _vm.trackedListingTask.scheduled_at
                  ? 'green lighten-5'
                  : 'grey lighten-3',"icon":!_vm.trackedListingTask.scheduled_at || !_vm.isAssigned
                  ? 'clear'
                  : _vm.taskDone
                  ? 'done'
                  : 'alarm',"icon-color":_vm.isAssigned && _vm.trackedListingTask.scheduled_at
                  ? 'green darken-1'
                  : 'white',"fill-dot":""}},[_c('p',{staticClass:"font-weight-medium mb-0"},[_vm._v(" Scheduled arrival on "),(_vm.scheduledHour != 0)?_c('span',[_vm._v(_vm._s(_vm.$moment .utc(new Date(_vm.trackedListingTask.scheduled_at)) .format('DD MMMM, HH:mm')))]):_c('span',[_vm._v(_vm._s(_vm.$moment(new Date(_vm.trackedListingTask.scheduled_at)).format( 'YYYY-MM-DD' )))])])]),_c('v-timeline-item',{attrs:{"color":_vm.taskStatusColor(_vm.trackedListingTask.status)['bg'],"icon":_vm.taskStatusIcon(_vm.trackedListingTask.status)['icon'],"icon-color":_vm.taskStatusIcon(_vm.trackedListingTask.status)['color'],"fill-dot":""}},[_c('span',[_vm._v("Status: "+_vm._s(_vm.trackedListingTask.status))])])],1)],1)],1),(_vm.taskDone)?_c('rate-lt',{attrs:{"tracked-listing-task":_vm.trackedListingTask}}):_vm._e(),(_vm.trackedListingTask.customer_rating)?_c('div',{staticClass:"text-h6 mt-6"},[_vm._v(" Thanks for your rating! ")]):_vm._e(),(_vm.taskDone)?_c('div',{staticClass:"secondary--text mt-6"},[_c('div',{staticClass:"font-weight-medium"},[_vm._v("Still have issues?")]),_c('div',{staticClass:"text-caption"},[_vm._v(" Contact us via the channel or call us at: "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"tel:+17862030688"}},[_vm._v("+1 786-203-0688")])])]):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }