<template>
  <div>
    <v-progress-linear v-show="loadingInvestmentData" indeterminate />
    <v-container v-if="cards" wrap>
      <v-row class="align-center justify-space-between">
        <v-col>
          <investment-details-btn
            v-if="!isDesignedVr && (isInvestor || isAdmin)"
            :listing="currentListing"
            mode="edit"
          />
        </v-col>
        <v-col cols="auto" class="ml-auto">
          <month-year-picker
            v-model="dates"
            :is-yearly="true"
            @change="updateDate"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="headerCard in headerCards"
          :key="headerCard.label"
          cols="6"
          md="3"
        >
          <review-stats-box
            :color="headerCard.color"
            :tooltip="headerCard.tooltip"
            :title="headerCard.label"
            :value="kFormatter(headerCard.value, 0)"
          />
        </v-col>
      </v-row>
      <v-alert v-if="investmentInfo.partial_start_date" dense text type="info">
        Information is based on data starting
        {{ parseDate(investmentInfo.partial_start_date) }}
      </v-alert>
      <v-row v-if="investmentView === 'ytd'">
        <v-col>
          <v-card class="d-flex card-shadow rounded-lg pa-6 flex-column">
            <v-row>
              <v-col cols="12" sm="9">
                <v-row>
                  <investment-card
                    :cols="isMobileOrIPad ? 12 : 6"
                    :height="isMobileOrIPad ? 180 : 250"
                    :card="cards.cash_on_cash"
                  />
                  <investment-card
                    :cols="isMobileOrIPad ? 12 : 6"
                    :height="isMobileOrIPad ? 180 : 250"
                    :card="cards.net_operating_income_before"
                    :card2="cards.net_operating_income_after"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" sm="3">
                <investment-card
                  horizontal="true"
                  height="67"
                  :card="cards.debt_service"
                />
                <investment-card
                  horizontal="true"
                  height="67"
                  :card="cards.expenses"
                />
                <investment-card
                  horizontal="true"
                  height="67"
                  :card="cards.income"
                />
              </v-col>
            </v-row>
            <v-row>
              <investment-card
                horizontal="true"
                cols="4"
                :height="isMobileOrIPad ? 180 : 112"
                :card="cards.adr"
              />
              <investment-card
                cols="4"
                horizontal="true"
                :height="isMobileOrIPad ? 180 : 112"
                :card="cards.cap_rate"
              />
              <investment-card
                cols="4"
                horizontal="true"
                :height="isMobileOrIPad ? 180 : 112"
                :card="cards.occupancy_percent"
              />
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="investmentView === 'projection'">
        <v-col>
          <v-card class="d-flex card-shadow rounded-lg pa-6 flex-column">
            <v-row>
              <v-col cols="12" sm="9">
                <v-row>
                  <investment-card
                    :cols="isMobileOrIPad ? 12 : 6"
                    :height="isMobileOrIPad ? 180 : 250"
                    :card="cards.projected_cash_on_cash"
                  />
                  <investment-card
                    :cols="isMobileOrIPad ? 12 : 6"
                    :height="isMobileOrIPad ? 180 : 250"
                    :card="cards.projected_net_operating_income_before"
                    :card2="cards.projected_net_operating_income_after"
                  />
                </v-row>
              </v-col>
              <v-col cols="12" sm="3">
                <investment-card
                  horizontal="true"
                  height="67"
                  :card="cards.yearly_debt_service"
                />
                <investment-card
                  horizontal="true"
                  height="67"
                  :card="cards.projected_expenses"
                />
                <investment-card
                  horizontal="true"
                  height="67"
                  :card="cards.projected_income"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-3 align-baseline">
        <v-col cols="12" sm="8">
          <monthly-stats-graph
            class="mb-4"
            :names="month_data.names"
            title="Monthly Revenue"
            :reveune="month_data.months_acc"
            graph-id="monthly-graph"
            color="#7ed9d9"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <line-chart
            class="mb-4"
            graph-id="adr"
            title="Average Daily Rate"
            :stats-object="yearly_stats.adr"
            :months-names="month_data.names"
            color="#FFDB58"
          />
          <line-chart
            graph-id="occupancy"
            title="Occupancy Rates"
            :stats-object="monthlyOccupancy"
            :months-names="month_data.names"
            color="#FFDB58"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import InvestmentCard from 'components/investment-info/investment-card'
import { CARDS, TOOLTIPS } from 'components/investment-info/config'
import deviceMixin from 'components/mixins/device-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import MonthYearPicker from 'components/common/month-year-picker'
import get from 'lodash/fp/get'
import LineChart from '../line-chart'
import MonthlyStatsGraph from '../monthly-stats-graph'
import InvestmentDetailsBtn from 'components/listing/investment-details-btn.vue'
import ReviewStatsBox from 'components/reviews/review-stats-box'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  components: {
    ReviewStatsBox,
    InvestmentDetailsBtn,
    MonthlyStatsGraph,
    LineChart,
    InvestmentCard,
    MonthYearPicker,
  },
  mixins: [CommonFunctions, deviceMixin, PermissionsMixin, FormattersMixin],
  props: ['listing'],
  computed: {
    ...mapState(['currentListing']),
    ...mapGetters(['currentUserId']),
    currentYear() {
      return new Date().getFullYear()
    },
    monthlyOccupancy() {
      return (this.yearly_stats.month_occupancy || []).map(
        o => this.round(o * 100, 0) || 0
      )
    },
    month_data() {
      return get('yearly_stats.month_data', this.currentListing) || {}
    },
    yearly_stats() {
      if (this.currentListing) {
        return this.currentListing.yearly_stats || {}
      }
      return {}
    },
  },
  methods: {
    ...mapActions('listings', ['getInvestmentInfo']),
    loadInvestmentInfo(year = this.currentYear) {
      this.loadingInvestmentData = true
      this.getInvestmentInfo({
        listingId: this.$route.params.id,
        year,
      })
        .then(res => {
          this.investmentInfo = res.data
          this.headerCards = [
            'cost_base',
            'purchase_price',
            'setup_costs',
            'initial_investment_cost',
          ].map(key => ({
            ...CARDS[key],
            value: res.data[key],
            tooltip: TOOLTIPS[key] && TOOLTIPS[key](res.data),
          }))

          this.cards = Object.keys(CARDS).reduce((acc, curr) => {
            acc[curr] = {
              ...CARDS[curr],
              value: res.data[curr],
              tooltip: TOOLTIPS[curr] && TOOLTIPS[curr](res.data),
            }
            return acc
          }, {})
          this.loadingInvestmentData = false
        })
        .catch(e => {
          alert(e)
          this.loadingInvestmentData = false
        })
    },
    updateDate({ year }) {
      if (year !== new Date().getFullYear()) {
        this.investmentView = 'ytd'
      }
      this.loadInvestmentInfo(year)
      this.$store.dispatch('updateStats', {
        id: this.$route.params.id,
        month: 'January',
        year,
        runYearly: true,
      })
    },
  },
  mounted() {
    this.loadInvestmentInfo()
  },
  data() {
    return {
      investmentView: 'ytd',
      headerCards: [],
      cards: {},
      investmentInfo: {},
      loadingInvestmentData: false,
      dates: {
        year: new Date().getFullYear(),
      },
      menu: null,
    }
  },
}
</script>
<style scoped>
.card-shadow {
  box-shadow: 0px 5px 20px 3px rgb(0 0 0 / 8%) !important;
}
</style>
