<template>
  <v-card class="overflow-y-auto">
    <v-card-title class="text-h6 text-uppercase d-flex justify-space-between">
      <div>{{ title }}</div>
      <v-switch
        v-model="isEnabled"
        :color="isEnabled ? 'success' : 'error'"
        class="mt-2"
        dense
      >
        <template #label>
          <div class="text-caption">
            {{ isEnabled ? 'Enabled' : 'Disabled' }}
          </div>
        </template>
      </v-switch>
    </v-card-title>
    <v-form ref="form" @submit.prevent="submit">
      <v-card-text>
        <div v-for="(field, i) in fields" :key="i">
          <v-text-field
            v-model="field.value"
            :label="field.key"
            :disabled="!isEnabled"
            :type="field.key.includes('secret') ? 'password' : 'text'"
            dense
            outlined
          />
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn type="submit" color="primary">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from '@/axios/config'

export default {
  name: 'IntegrationModal',
  props: ['fields', 'title', 'integrationId', 'isEnabled'],
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        const payload = this.fields.reduce((obj, item) => {
          obj[item.key] = item.value
          return obj
        }, {})
        axios
          .patch('/api/settings/integrations', {
            integration_id: this.integrationId,
            payload,
            active: this.isEnabled,
          })
          .finally(() => {
            this.$store.dispatch('getSettings')
          })
        this.$store.commit('hideModal')
      }
    },
  },
}
</script>

<style scoped></style>
