<template>
  <v-container fluid>
    <v-row v-if="hasXero">
      <v-col cols="6">
        <bank-account-picker
          v-model="account.is_default_cleaning"
          label="Default Cleaning Account"
          :outlined="true"
        />
      </v-col>
      <v-col v-if="defaultCleaningAccount" cols="6">
        <div>
          <div>Account id: {{ defaultCleaningAccount.account_id }}</div>
          <div>Source: {{ defaultCleaningAccount.source }}</div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row v-if="hasXero">
      <v-col cols="6">
        <bank-account-picker
          v-model="account.is_default_booking"
          label="Default Booking Account"
          :outlined="true"
        />
      </v-col>
      <v-col v-if="defaultBookingAccount" cols="6">
        <div>
          <div>Account id: {{ defaultBookingAccount.account_id }}</div>
          <div>Source: {{ defaultBookingAccount.source }}</div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row v-if="hasXero">
      <v-col cols="6">
        <bank-account-picker
          v-model="account.is_default_management"
          label="Default Management Account"
          :outlined="true"
        />
      </v-col>
      <v-col v-if="defaultManagementAccount" cols="6">
        <div>
          <div>Account id: {{ defaultManagementAccount.account_id }}</div>
          <div>Source: {{ defaultManagementAccount.source }}</div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col cols="6">
        <bank-account-picker
          v-model="account.is_default_expense"
          label="Default Expense Account"
          :outlined="true"
        />
      </v-col>
      <v-col v-if="defaultExpenseAccount" cols="6">
        <div>
          <div>Account id: {{ defaultExpenseAccount.account_id }}</div>
          <div>Source: {{ defaultExpenseAccount.source }}</div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col cols="6">
        <bank-account-picker
          v-model="account.is_default_payment"
          label="Default Payment Account"
          :outlined="true"
        />
      </v-col>
      <v-col v-if="defaultPaymentAccount" cols="6">
        <div>
          <div>Account id: {{ defaultPaymentAccount.account_id }}</div>
          <div>Source: {{ defaultPaymentAccount.source }}</div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="saveAccountSettings"
          >Save Settings</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BankAccountPicker from 'components/accounting/bank-account-picker.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BankAccountSettings',
  components: { BankAccountPicker },
  data() {
    return {
      account: {
        is_default_cleaning: null,
        is_default_booking: null,
        is_default_management: null,
        is_default_expense: null,
        is_default_payment: null,
      },
    }
  },
  computed: {
    ...mapState('bankAccounts', {
      allBankAccounts: 'allAccounts',
      calledFetchBankAccount: 'fetchAllCalled',
    }),
    hasXero() {
      return this.$store.state.app.configuration.has_xero
    },
    defaultCleaningAccount() {
      return this.findDefaultAccount('is_default_cleaning')
    },
    defaultBookingAccount() {
      return this.findDefaultAccount('is_default_booking')
    },
    defaultManagementAccount() {
      return this.findDefaultAccount('is_default_management')
    },
    defaultExpenseAccount() {
      return this.findDefaultAccount('is_default_expense')
    },
    defaultPaymentAccount() {
      return this.findDefaultAccount('is_default_payment')
    },
  },
  watch: {
    allBankAccounts(newVal) {
      if (newVal.length) {
        this.setDefaults()
      }
    },
  },
  methods: {
    ...mapActions('bankAccounts', ['updateBankAccount']),
    saveAccountSettings() {
      const accountTypes = ['is_default_expense', 'is_default_payment']

      if (this.hasXero) {
        accountTypes.push(
          'is_default_cleaning',
          'is_default_booking',
          'is_default_management'
        )
      }

      accountTypes.forEach(accountType => {
        if (this.account[accountType]) {
          this.updateAccount(accountType, this.account[accountType])
        }
      })
    },
    updateAccount(accountType, accountId) {
      const updateData = { id: accountId }
      updateData[accountType] = true

      this.updateBankAccount(updateData)
    },
    setDefaults() {
      const accountTypes = [
        'is_default_cleaning',
        'is_default_booking',
        'is_default_management',
        'is_default_expense',
        'is_default_payment',
      ]

      accountTypes.forEach(accountType => {
        if (!this.account[accountType] && this.allBankAccounts.length) {
          const defaultAccount = this.allBankAccounts.find(
            account => account[accountType]
          )
          if (defaultAccount) {
            this.account[accountType] = defaultAccount.id
          }
        }
      })
    },
    findDefaultAccount(accountType) {
      return this.allBankAccounts.find(
        account => account.id === this.account[accountType]
      )
    },
  },
}
</script>

<style scoped></style>
