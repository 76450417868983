<template>
  <v-dialog v-model="dialog" max-width="300">
    <template #activator="{ on }">
      <v-btn
        small
        outlined
        color="info"
        class="mx-2"
        v-on="on"
        @click="showCredentials"
        >Show credentials</v-btn
      >
    </template>
    <v-sheet class="pa-3">
      <info-box title="Username" :value="data.username" />
      <info-box title="Password" :value="data.password" />
    </v-sheet>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import InfoBox from 'components/common/info-box.vue'

export default {
  name: 'CmCredentials',
  components: { InfoBox },
  data() {
    return {
      dialog: false,
      loading: false,
      data: {
        username: '',
        password: '',
      },
    }
  },
  methods: {
    async showCredentials() {
      const { data } = await axios.get('/api/channel-manager/credentials')
      this.data = data
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.data = {
          username: '',
          password: '',
        }
      }
    },
  },
}
</script>

<style scoped></style>
