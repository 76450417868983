<template>
  <v-row>
    <v-col
      v-for="cleaner in listingTask.working_cleaners"
      :key="cleaner.id"
      cols="12"
      pt-1
      pb-1
    >
      <v-row class="text-center align-baseline flex-wrap">
        <v-col cols="3">
          {{ cleaner.user_details.first_name }}
        </v-col>
        <v-col cols="3">
          {{ cleaner.user_details.last_name }}
        </v-col>
        <v-col cols="6">
          <div v-if="!cleaner.duration">Still working</div>
          <span v-if="cleaner.duration"
            >{{ parseDate(cleaner.clocked_in) }} |
            {{ cleaner.started_at_hour }}
            ({{ cleaner.duration }} min)</span
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'

export default {
  mixins: [CommonFunctions],
  props: ['listingTask'],
}
</script>
