import { uniqueId } from 'lodash'
import { getTimer } from '@/utils/getTimer'

class Toaster {
  static initStore(store) {
    Toaster.store = store
  }

  static show(snackbars = []) {
    snackbars.forEach(snack => {
      const snackId = uniqueId()
      const timer = getTimer(() => {
        Toaster.store.commit('removeSnack', snackId)
      }, snack.timeout || 5000)
      Toaster.store.commit('addSnack', { ...snack, timer, snackId })
      timer.start()
    })
  }
}

export default Toaster
