export default {
  data() {
    return {
      pollingInterval: null,
      POLLING_KEY: 'isPolling',
      POLLING_OWNER: null, //
      intervalStats: null,
      intervalList: null,
    }
  },
  computed: {
    listAction() {
      return this.crmMode === 'cs'
        ? 'getCS'
        : this.crmMode === 'sales'
        ? 'getSales'
        : 'getAll'
    },
    refreshAction() {
      return this.crmMode === 'cs'
        ? 'refreshCS'
        : this.crmMode === 'sales'
        ? 'refreshSales'
        : 'refreshAll'
    },
  },
  mounted() {
    this[this.listAction]({
      [this.tab]: true,
      conversation_id: this.$route.query.conversation,
    })
    // Listen for visibility changes
    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    // Listen for storage events to detect changes from other tabs
    window.addEventListener('storage', this.handleStorageEvent)

    // Attempt to start polling if no other tab is polling
    this.tryStartPolling()

    // Listen for page unload to clean up
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  beforeDestroy() {
    // Clean up listeners and intervals
    this.stopPolling()
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
    window.removeEventListener('storage', this.handleStorageEvent)
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  methods: {
    startPolling() {
      const shouldStartInterval = !this.intervalStats || !this.intervalList
      if (!this.intervalStats) {
        this.intervalStats = setInterval(
          () => this[this.listAction]({ stats_only: true }),
          1000 * 30
        )
      }
      if (!this.intervalList) {
        this.intervalList = setInterval(
          () => this[this.refreshAction](),
          1000 * 60
        )
      }
      if (shouldStartInterval) {
        this.POLLING_OWNER = Date.now()
        console.log(this.$moment(Date.now()).format('HH:mm'))
        localStorage.setItem(this.POLLING_KEY, this.POLLING_OWNER)
        console.log('Polling started by this tab')
      }
    },
    stopPolling() {
      clearInterval(this.intervalStats)
      clearInterval(this.intervalList)
      this.intervalStats = null
      this.intervalList = null
      if (
        localStorage.getItem(this.POLLING_KEY) === this.POLLING_OWNER.toString()
      ) {
        localStorage.removeItem(this.POLLING_KEY)
        console.log(this.$moment(Date.now()).format('HH:mm'))
        console.log('Polling stopped by this tab')
      }
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.stopPolling()
      } else {
        this.tryStartPolling()
      }
    },
    tryStartPolling() {
      const currentPollingOwner = localStorage.getItem(this.POLLING_KEY)
      if (!currentPollingOwner) {
        this.startPolling()
      }
    },
    handleStorageEvent(event) {
      if (event.key === this.POLLING_KEY) {
        if (!event.newValue) {
          // If the polling flag was removed, try to start polling
          if (!document.hidden) {
            this.startPolling()
          }
        }
      }
    },
    handleBeforeUnload() {
      this.stopPolling()
    },
  },
}
