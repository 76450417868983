<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6.755"
    height="11.054"
    viewBox="0 0 6.755 11.054"
  >
    <path
      d="M10.3,11.76l4.228,4.175,4.228-4.175,1.3,1.285-5.527,5.47L9,13.045Z"
      transform="translate(-11.76 20.054) rotate(-90)"
    />
  </svg>
</template>

<script>
export default {
  name: 'RightArrow',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
