<template>
  <span>
    <v-btn
      ref="icontainer"
      :title="description"
      :color="color"
      :small="small !== false"
      icon
      :disabled="copied"
      @click="onCopy"
    >
      <v-icon v-if="!copied" :small="small !== false"> content_copy </v-icon>
      <v-icon v-else color="success" :small="small !== false"> check </v-icon>
    </v-btn>
    <span class="secondary--text">{{ label }}</span>
  </span>
</template>

<script>
export default {
  name: 'CopyClipboard',
  props: ['text', 'description', 'label', 'color', 'small'],
  data() {
    return {
      copied: false,
    }
  },
  methods: {
    onCopy() {
      this.$copyText(this.text, this.$refs.icontainer.$el)
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 1000)
    },
  },
}
</script>

<style scoped></style>
