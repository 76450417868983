<template>
  <v-menu
    :key="value"
    v-model="menu"
    :disabled="disabled"
    offset-y
    bottom
    max-height="300"
  >
    <template #activator="{ attrs, on }">
      <v-chip
        v-if="selectedItem"
        close-icon="close"
        :close="!disabled"
        label
        outlined
        v-bind="attrs"
        :class="{ 'pl-0': selectedItem.picture }"
        @click:close="onReset"
        v-on="on"
      >
        <img
          v-if="selectedItem.picture"
          class="picker-picture"
          :src="selectedItem.picture"
        />
        {{ selectedItem.nickname }}
      </v-chip>
      <v-chip v-else class="empty-chip-field" label v-bind="attrs" v-on="on">
        {{ title || 'Listing' }}
      </v-chip>
    </template>

    <v-list>
      <v-text-field
        v-model="search"
        class="pa-3"
        autofocus
        hide-details
        dense
        label="Search"
      />
      <v-list-item-group>
        <v-list-item
          v-for="item in itemsList"
          :key="item.id"
          @click="onSelect(item.id)"
        >
          <v-list-item-title class="d-flex align-center">
            <img
              v-if="item.picture"
              class="picker-picture"
              :src="item.picture"
            />
            {{ item.nickname }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import { mapState } from 'vuex'
import { isArray, keyBy, take } from 'lodash'

export default {
  name: 'ListingPicker',
  components: {},
  mixins: [CommonFunctions],
  props: ['value', 'disabled', 'title', 'showDups'],
  data() {
    return {
      menu: false,
      search: '',
    }
  },
  mounted() {
    if (this.value && !this.selectedItem) this.onReset()
  },
  computed: {
    ...mapState(['listingsIndex']),
    items() {
      let result = isArray(this.listingsIndex) ? this.listingsIndex : []
      if (!this.showDups) {
        result = result.filter(l => !l.second_guesty_id)
      }
      result = result.filter(l => l.active)
      return result
    },
    itemsList() {
      return take(this.items.filter(this.searchFilter), 20)
    },
    itemsMap() {
      return keyBy(this.items, 'id')
    },
    selectedItem() {
      return this.itemsMap[this.value]
    },
  },
  methods: {
    onReset() {
      this.onSelect(null)
    },
    onSelect(value) {
      this.$emit('input', value)
      this.search = ''
      this.menu = false
    },
    searchFilter(item) {
      return (
        !this.search ||
        (item.nickname &&
          item.nickname.toLowerCase().includes(this.search.toLowerCase())) ||
        (item.address &&
          item.address.toLowerCase().includes(this.search.toLowerCase()))
      )
    },
  },
}
</script>

<style scoped>
.empty-chip-field {
  border: 1px dashed #000000;
  background-color: #ffffff !important;
}

.picker-picture {
  height: 30px;
  margin-right: 10px;
}
</style>
