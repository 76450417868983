<template>
  <v-card>
    <v-card-title>Edit Rule</v-card-title>

    <v-form ref="formEditRule" @submit.prevent="submitFormEditRule">
      <v-card-text>
        <v-text-field
          v-if="!disabled"
          v-model="formEditData.discount_value"
          :label="$t('Discount')"
          type="number"
          outlined
          :rules="positiveNumberRule"
          :min="1"
          hint="Enter a positive number"
        ></v-text-field>

        <v-text-field
          v-if="!disabled"
          v-model="formEditData.min_stay_nights"
          :label="$t('Min nights')"
          type="number"
          outlined
          :rules="positiveNumberRule"
          :min="1"
          hint="Enter a positive number"
        ></v-text-field>

        <multi-property-picker
          v-if="!listingLoading"
          v-model="formEditData.applies_to"
          label="Leave empty and save to apply to all properties."
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          class="text-capitalize"
          text
          @click="closeDialogEdit"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          class="text-capitalize"
          elevation="0"
          type="submit"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import MultiPropertyPicker from './multi-property-picker.vue'

export default {
  components: {
    MultiPropertyPicker,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  props: ['afterSaveFunc', 'editMode', 'priceRule'],
  data: () => ({
    formData: {
      description: '',
      min_stay_nights: 1,
      discount_type: null,
      discount_value: null,
      applies_to: [],
    },
    formEditData: {},
    dialog: false,
    dialogNewCoupon: false,
    editDialog: false,
  }),
  mounted() {
    if (this.currentRule && this.listingTaskRule.by_personnel) {
      const listing = this.listingsPicker.find(
        l => l.id === this.listingTaskRule.listing_id
      )
      const name = this.getPersonnelName(
        this.listingTaskRule.by_personnel,
        listing
      )
      this.personnelNames.push({ name, listing: listing.nickname })
    }
  },
  computed: {
    disabled() {
      return true
    },
  },
  methods: {
    onClose() {
      this.$store.commit('hideModal')
    },
    submitFormEditRule() {
      if (this.$refs.formEditRule.validate()) {
        this.$store.dispatch('editCouponRule', {
          id: this.priceRule.id,
          ...this.formEditData,
        })
        this.closeDialogEdit()
      }
    },
    closeDialogEdit() {
      this.onClose()
      this.formEditData = {}
      this.$refs.formEditRule.resetValidation()
    },
  },
}
</script>
