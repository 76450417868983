<template>
  <scale-container
    :style="styleVars"
    class="chart-flow"
    @onScaleChange="onTreeScaleChange"
  >
    <organization-chart :datasource="tree">
      <template slot-scope="{ nodeData }">
        <div
          class="chart-flow-node-wrapper"
          :draggable="dragId === nodeData.id"
          :style="{ padding: `0 ${paddingX || 0}` }"
          @dragstart.stop="dragStartMethod($event, nodeData)"
          @dragend.stop="dragEndMethod"
          @dragenter.prevent.stop="dragEnterMethod($event, nodeData.id)"
          @dragleave.prevent.stop="dragLeaveMethod"
          @dragover.prevent.stop
          @drop.prevent.stop="droppedMethod($event, nodeData)"
        >
          <slot
            v-if="nodeData.data && Object.keys(nodeData.data).length !== 0"
            name="node"
            v-bind="{ data: nodeData.data }"
          />
          <div
            v-if="
              draggedItem &&
              draggedItem.id !== nodeData.id &&
              dragOverId === nodeData.id
            "
            class="dropzone"
          />
        </div>
      </template>
    </organization-chart>
  </scale-container>
</template>

<script>
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import ScaleContainer from 'components/scale-container'

export default {
  name: 'ChartFlow',
  components: { OrganizationChart, ScaleContainer },
  props: ['nodes', 'dragId', 'paddingX'],
  data() {
    return {
      currentScale: 1,
      dragOverIds: [],
      draggedItem: null,
    }
  },
  computed: {
    tree() {
      return {
        id: 'rootNode',
        parentId: null,
        data: {},
        children: this.buildTree(this.rootNodes),
      }
    },
    nodesIds() {
      return this.nodes.map(item => item.id)
    },
    rootNodes() {
      return this.nodes.filter(
        node => !node.parentId || !this.nodesIds.includes(node.parentId)
      )
    },
    styleVars() {
      return {
        '--chart-flow-hover-scale': Math.max(1 / this.currentScale, 1),
      }
    },
    dragOverId() {
      return this.dragOverIds[0]
    },
  },
  watch: {
    rootNodes(newValue, oldValue) {
      if (
        newValue.length !== oldValue.length ||
        (newValue[0] && !oldValue[0]) ||
        (newValue[0] && oldValue[0] && newValue[0].id !== oldValue[0].id)
      ) {
        this.$nextTick(() => {
          const elements = this.$el.getElementsByClassName(
            'chart-flow-node-wrapper'
          )
          elements[1] &&
            elements[1].scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'center',
            })
        })
      }
    },
  },
  methods: {
    onTreeScaleChange(scale) {
      this.currentScale = scale
    },
    getChildren(parentId) {
      return this.nodes.filter(item => item.parentId === parentId)
    },
    buildTree(nodes = []) {
      return nodes.map(node => {
        const { id, parentId, data = {} } = node
        return {
          id,
          parentId,
          data,
          children: this.buildTree(this.getChildren(node.id)),
        }
      })
    },
    dragStartMethod(event, draggedItem) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      this.draggedItem = draggedItem
    },
    dragEndMethod() {
      this.dragOverIds = []
      this.draggedItem = null
      this.$emit('update:drag-id', null)
    },
    dragEnterMethod(event, nodeId) {
      this.dragOverIds.push(nodeId)
    },
    dragLeaveMethod() {
      this.dragOverIds.shift()
    },
    droppedMethod(event, droppedItem) {
      if (this.draggedItem.id !== droppedItem.id) {
        this.$emit('on-drag', {
          dragged: this.draggedItem.data,
          to: droppedItem.data,
        })
      }
    },
  },
}
</script>

<style scoped>
.chart-flow {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.chart-flow >>> .orgchart-container {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: unset;
  overflow: auto;
}

.chart-flow >>> .orgchart {
  width: fit-content;
  height: fit-content;
  border: none;
  background-image: none;
  padding: 50px;
}

.chart-flow >>> .orgchart > table > tbody > tr:not(.nodes) {
  display: none;
}

.chart-flow >>> .orgchart .node {
  width: fit-content;
  padding: unset;
  border: unset;
}

.chart-flow >>> .orgchart .node.focused,
.chart-flow >>> .orgchart .node:hover {
  background-color: unset;
}

.chart-flow >>> .orgchart .lines .topLine {
  border-top: 2px solid var(--v-secondary-base);
}

.chart-flow >>> .orgchart .lines .downLine {
  background-color: var(--v-secondary-base);
}

.chart-flow >>> .orgchart .lines .leftLine {
  border-left: 1px solid var(--v-secondary-base);
}

.chart-flow >>> .orgchart .lines .rightLine {
  border-right: 1px solid var(--v-secondary-base);
}

.chart-flow-node-wrapper {
  transition: all 0.3s;
  transform-origin: top left;
  margin-bottom: 12px;
}

.chart-flow-node-wrapper:hover {
  transform: scale(var(--chart-flow-hover-scale));
  z-index: 1;
  position: relative;
}

.dropzone {
  width: 90%;
  height: 5px;
  border-radius: 2px;
  margin: 0 auto -5px auto;
  background-color: var(--v-primary-base);
}

.dropzone:after {
  position: relative;
  bottom: 4px;
  left: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: var(--v-secondary-base);
  content: '';
  display: block;
}
</style>
