<template>
  <v-snackbar
    v-if="
      (isOperationsPersonal || isCommunicationManager || isAdmin) &&
      !listingLoading &&
      selected.size > 0
    "
    :timeout="-1"
    value="snackbar"
    color="secondary"
    outlined
    bottom
    class="global-snack"
    elevation="5"
    max-width="fit-content"
    content-class="pa-1"
  >
    <v-row align="center" justify="center">
      <v-col cols="auto" class="bolder ml-3">
        {{ selected.size }} selected
      </v-col>
      <v-divider vertical />
      <div
        class="flex-fill flex-center pointer"
        style="width: 150px"
        @click="expanded = !expanded"
      >
        <v-icon v-if="!expanded" color="primary">mdi-chevron-double-up</v-icon>
        <v-icon v-else color="primary">mdi-chevron-double-down</v-icon>
      </div>
      <v-divider vertical />
      <v-col cols="auto" class="d-flex flex-center">
        <ConfirmationModal
          :text="`Are you sure you want to delete all ?`"
          @action="deleteListingTasks"
        >
          <template #activator="{ on }">
            <v-btn color="error" small v-on="on"> Delete all</v-btn>
          </template>
        </ConfirmationModal>
      </v-col>
      <v-divider vertical />
      <v-col cols="auto" class="d-flex flex-center">
        <v-btn color="primary" small @click="closeSnack">
          Clear selection
        </v-btn>
      </v-col>
      <v-divider vertical />
      <v-col cols="auto" class="d-block">
        <lt-taskim-btn
          :listing-tasks-ids="Array.from(selected)"
          :text-button="true"
          :small="true"
        />
      </v-col>
    </v-row>

    <div
      class="overflow-hidden transition-ease-in-out"
      :style="{ height: expanded ? '130px' : 0 }"
    >
      <v-divider />
      <v-row align="center" justify="center" class="pl-2">
        <v-col cols="auto" class="d-flex flex-center">
          <contractor-select
            :hide-details="true"
            :text-selection="true"
            :filter-change="contractorChange"
          />
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            required
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="changedDate"
                prepend-inner-icon="event"
                label="Scheduled at"
                readonly
                outlined
                dense
                hide-details
                class="ml-3"
                v-on="on"
              />
            </template>
            <v-date-picker
              ref="picker"
              v-model="changedDate"
              :max="
                new Date(new Date().setMonth(new Date().getMonth() + 2))
                  .toISOString()
                  .substr(0, 10)
              "
              :min="new Date().toISOString().substr(0, 10)"
              @change="saveDate"
            ></v-date-picker>
          </v-menu>
          <v-btn
            color="primary"
            class="ml-3"
            small
            :disabled="!contractorChosen && !changedDate"
            @click="changeSelected"
          >
            Change
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-row align="center" justify="center" class="pl-2">
        <v-col cols="auto" class="d-flex flex-center">
          <v-text-field
            v-model="maxPerDay"
            label="Max per day"
            outlined
            dense
            hide-details
            class="ml-3"
          />
          <v-btn color="primary" class="ml-3" small @click="moveToNextOpening">
            Move to next open
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-snackbar>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ContractorSelect from 'components/contractor-select.vue'
import ConfirmationModal from 'components/modals/confirmation-modal'
import LtTaskimBtn from 'components/taskim/lt-taskim-btn'

export default {
  components: {
    LtTaskimBtn,
    ConfirmationModal,
    ContractorSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['afterSaveFunc'],
  data: () => ({
    menu: false,
    maxPerDay: 10,
    expanded: false,
  }),
  computed: {
    ...mapState(['ltdSelectedIDS']),
    snackbar() {
      return this.selected.size > 0
    },
    selected: {
      get() {
        return this.ltdSelectedIDS
      },
      set(val) {
        this.$store.commit('updateLtdSelectedIDS', val)
      },
    },
    contractorChosen: {
      get() {
        return this.$store.state.ltdContractorId
      },
      set(val) {
        this.$store.commit('updateLtdContractorId', val)
      },
    },
    changedDate: {
      get() {
        return this.$store.state.ltdChangedDate
      },
      set(val) {
        this.$store.commit('updateLtdChangedDate', val)
      },
    },
  },
  watch: {
    ltdSelectedIDS(value) {
      if (!value || !value.size) this.expanded = false
    },
  },
  methods: {
    closeSnack() {
      this.expanded = false
      this.selected = new Set()
      this.contractorChosen = null
      this.changedDate = null
      this.clearAllSelected()
    },
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    clearAllSelected() {
      this.$store.state.listingTasks.forEach(task => {
        task.selected = false
      })
      if (this.$store.state.currentTask) {
        this.$store.state.currentTask.suggestions.forEach(task => {
          task.selected = false
        })
      }
    },

    deleteListingTasks() {
      if (this.selected.size > 0) {
        axios
          .post('/api/listing-tasks/multi-edit', {
            delete_list: true,
            ids: [...this.selected],
          })
          .then(() => {
            this.selected = new Set()
            this.contractorChosen = null
            this.changedDate = null
            this.afterSaveFunc()
          })
          .catch(error => {
            console.log(error)
            alert(error)
          })
      }
    },
    contractorChange(contractorID) {
      this.contractorChosen = contractorID
    },
    moveToNextOpening() {
      if (this.selected.size > 0) {
        axios
          .post('/api/listing-tasks/multi-edit', {
            move_to_next_opening: true,
            ids: [...this.selected],
            max_per_day: this.maxPerDay,
          })
          .then(() => {
            this.selected = new Set()
            this.contractorChosen = null
            this.changedDate = null
            this.afterSaveFunc()
          })
          .catch(error => {
            console.log(error)
            alert(error)
          })
      }
    },
    changeSelected() {
      if (this.selected.size > 0) {
        axios
          .post('/api/listing-tasks/multi-edit', {
            changed_date: this.changedDate,
            assigned_contractor_id: this.contractorChosen,
            ids: [...this.selected],
          })
          .then(() => {
            this.selected = new Set()
            this.contractorChosen = null
            this.changedDate = null
            this.afterSaveFunc()
            //get listing tasks
          })
          .catch(error => {
            console.log(error)
            alert(error)
          })
      }
    },
  },
}
</script>

<style scoped>
.global-snack {
  z-index: 5 !important;
}
</style>
