var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"text-h5 align-center bb-1"},[_vm._v(" Continuation Hierarchy "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onClose}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0 d-flex flex-column"},[_c('chart-flow',{attrs:{"nodes":_vm.hierarchy,"padding-x":"20px"},scopedSlots:_vm._u([{key:"node",fn:function({ data }){return [_c('div',{class:[
            'chart-flow-node-wrapper pointer',
            {
              'chart-flow-node-wrapper-current':
                data.extendedProps.task.id === _vm.listingTaskId,
            },
          ],on:{"click":function($event){return _vm.openListingTaskInNewTab(data.extendedProps.task.id)}}},[_c('task-calendar-cell',{attrs:{"mode":"menu","event":data,"users-map":_vm.usersMap}})],1)]}}])})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.onClose}},[_vm._v("Close")]),_c('v-spacer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }