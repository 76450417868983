<template>
  <v-sheet
    elevation="2"
    class="ba-1 pa-3 my-3 mx-0 pointer"
    rounded
    :class="[isSelected && selected, isHovering && hovered]"
    @click="taskClick(task.id)"
    @mouseover="isHovering = true"
    @mouseout="isHovering = false"
  >
    <div class="d-flex justify-space-between text-left">
      <div class="d-flex">
        <span
          class="ba-1 text-caption px-2 font-weight-medium rounded text-capitalize"
        >
          <span class="secondary--text">{{ category }}</span>
        </span>
        <div class="d-flex align-center mx-2 text-body-2">
          <tag
            class="d-flex align-center text-uppercase"
            :color="listingTaskStatusColor(task.status)"
            :inverted="true"
          >
            <div
              :class="['status', 'mr-1', listingTaskStatusColor(task.status)]"
            />
            {{ task.status }}</tag
          >
          <div
            v-if="subtaskStats"
            class="d-flex ml-3 font-weight-medium text-caption text--secondary"
          >
            <div class="mr-1 font-weight-semibold">Subtasks:</div>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <div class="d-flex" v-bind="attrs" v-on="on">
                  <v-icon class="mr-1" x-small color="success"
                    >mdi-circle</v-icon
                  >{{ subtaskStats.passed }}
                </div>
              </template>
              <span>Passed</span>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <div class="ml-2 d-flex" v-bind="attrs" v-on="on">
                  <v-icon class="mr-1" x-small color="warning"
                    >mdi-circle</v-icon
                  >{{ subtaskStats.failed }}
                </div>
              </template>
              <span>Failed</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <performance-bar
        v-if="showPerformanceBar"
        :performance-score="task.performance_score"
        :contractor-id="task.assigned_contractor_id"
        :task-id="task.id"
      />
    </div>
    <div class="text-subtitle-2 font-weight-medium mt-1">
      {{ nickname }}
    </div>
    <div class="text-body-2">
      {{ task.description }}
    </div>
    <v-sheet height="2px" width="100%" rounded class="grey lighten-2 my-2" />
    <div
      class="d-flex text-body-2 font-weight-medium ellipsis-1 align-center secondary-line mt-2"
    >
      <tag
        v-if="task.priority"
        :inverted="true"
        :color="`${priorityColor(task.priority)}`"
      >
        <v-icon :color="priorityColor(task.priority)" x-small>$flag</v-icon>
        <span class="font-weight-medium"> {{ task.priority }}</span>
      </tag>
      <tag v-if="rating" :inverted="true" color="yellow" class="d-flex mr-3">
        <v-icon color="yellow" small>mdi-star</v-icon>
        <span class="ml-1">{{ round(rating, 1) }} </span>
      </tag>

      <tag
        v-for="tag in truncatedTags"
        :key="tag.id"
        :inverted="true"
        :color="tag.color || 'black'"
        ><v-icon v-if="tag.icon" x-small :color="tag.color || 'secondary'">{{
          tag.icon
        }}</v-icon>
        {{ tag.label }}</tag
      >
      <v-tooltip v-if="remainingTags.length" bottom color="blue darken-2">
        <template #activator="{ on }">
          <a class="blue--text underline text-decoration-underline" v-on="on"
            >+{{ remainingTags.length }}</a
          >
        </template>
        <div>
          <div
            v-for="tag in remainingTags"
            :key="tag.id"
            class="white--text my-2 text-body-2"
          >
            {{ tag.label }}
          </div>
        </div>
      </v-tooltip>
      <div class="ml-auto"><slot name="actions" /></div>
    </div>
  </v-sheet>
</template>

<script>
import FormattersMixins from 'components/mixins/formatters-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import PerformanceBar from 'components/listing-report/performance-bar'
import ColorsMixin from 'components/mixins/colors-mixin'
import NavigationMixin from 'components/mixins/navigation-mixin'
import Tag from 'components/common/tag'

export default {
  components: { Tag, PerformanceBar },
  mixins: [FormattersMixins, ColorsMixin, NavigationMixin, CommonFunctions],

  props: ['task', 'isSelected', 'nickname', 'type', 'rating'],
  data() {
    return {
      category: `${this.task.listing_task_catalog_item_name}`,
      showPerformanceBar: false, // ['Closed', "Can't Do", 'Done'].includes(this.task.status),
      isHovering: false,
      selected: 'cyan lighten-4',
      hovered: 'grey lighten-4',
    }
  },
  computed: {
    tags() {
      if (this.type === 'review') {
        return this.task.tags.map(t => ({ color: 'blue lighten-4', label: t }))
      }
      const t = this.task
      const tags = []

      if (t.is_outstanding) {
        tags.push({ color: 'warning', label: 'OUTSTANDING' })
      }

      if (t.status === 'New') {
        tags.push({
          label: `Open: ${this.$moment().diff(t.created_at, 'days')}d`,
        })
      }
      if (t.status === 'Done') {
        tags.push({
          label: `Time: ${t.total_hours}hrs`,
        })
        tags.push({
          label: `Cost: ${this.dollarFormatter(t.amount, 0)}`,
        })
      }
      tags.push({
        label: this.generateTimeTag(),
        icon: '$clock',
      })
      if (t.assigned_contractor_id) {
        tags.push({
          label: `@${this.nameById(t.assigned_contractor_id)}`,
        })
      }
      return tags
    },
    subtaskStats() {
      return (
        this.task.extra_data_subtask_stats &&
        this.task.extra_data_subtask_stats.subtask_stats
      )
    },
    truncatedTags() {
      return this.tags.slice(0, 4)
    },
    remainingTags() {
      return this.tags.slice(4, this.tags.length)
    },
  },
  methods: {
    taskClick(taskId) {
      this.addToQuery({ reportTaskDrawer: taskId })
    },
    generateTimeTag() {
      const t = this.task
      let timeTag = ''

      if (t.status === 'Done') {
        timeTag += `Done: ${this.parseDate(t.moved_to_done_at, 'MMM DD')} `
      } else if (t.scheduled_at) {
        timeTag += `${this.parseDate(t.scheduled_at, 'MMM DD')} `
        // if (t.planned_duration) timeTag += `(exp. ${t.planned_duration}hrs)`
      }
      if (t.next) {
        timeTag += ` | Next: ${this.parseDate(t.next.scheduled_at, 'MMM DD')}`
      }
      return timeTag
    },
  },
}
</script>

<style>
.status {
  width: 8px;
  height: 8px;
  border-radius: 50px;
}
.secondary-line {
  white-space: nowrap;
}
</style>
