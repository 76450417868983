import CommonFunctions from 'components/mixins/common_functions'
import { mapGetters, mapState } from 'vuex'

export default {
  mixins: [CommonFunctions],

  data() {
    return {
      showOnlyItems: [
        { value: 'showOnlyMy', text: 'Only My Tasks' },
        { value: 'unassigned', text: 'Only Unassigned Tasks' },
        { value: 'hideRules', text: 'Hide Rules' },
        { value: 'onlyRules', text: 'Only With Rules' },
        { value: 'onlyDirty', text: 'Only Dirty' },
        { value: 'onlySetup', text: 'Only Related To Setup' },
        { value: 'storeOnly', text: 'Only Have Store Purchase' },
        { value: 'followup', text: 'Only Follow-Up' },
        { value: 'hideFuture', text: 'Hide Future Tasks' },
        { value: 'onlyWithWetArea', text: 'Only Listings With Pool/Jacuzzi' },
        { value: 'hideWetArea', text: 'Hide Listings With Pool/Jacuzzi' },
        { value: 'onlyUnits', text: 'Only Units' },
      ],
    }
  },
  computed: {
    ...mapState('users', ['taskCalendar']),
    ...mapGetters('users', ['usersList']),
    ...mapState('taskCalendar', ['userDefaultHasBeenSet']),
    ...mapGetters('taskCalendar', ['defaultUserCalendarFilterName']),
    filteredContractors() {
      const contractorsFilter =
        this.$store.state.taskCalendar.filters.contractor_id
      return contractorsFilter.length
        ? this.usersList.filter(user => contractorsFilter.includes(user.id))
        : this.usersList
    },
    isDailyView() {
      if (this.calApi) {
        return [
          'listDay',
          'timeGridDay',
          'resourceTimeGridDay',
          'resourceDayGridDay',
          'resourceTimelineDay',
        ].includes(this.calApi.view.type)
      }
      return null
    },
  },
  methods: {
    initDefaultUserCalendarFilters() {
      if (!this.userDefaultHasBeenSet && this.defaultUserCalendarFilterName) {
        const initialFilters =
          this.$store.state.user.filters[this.defaultUserCalendarFilterName]
        this.$store.commit(
          'taskCalendar/updateCalendarFilters',
          initialFilters || {}
        )
        this.$store.commit('taskCalendar/updateUserDefaultHasBeenSet', true)
        this.$store.commit(
          'taskCalendar/updateSelectedSavedFilter',
          this.defaultUserCalendarFilterName
        )
      }
      return this.$store.state.user.filters[this.defaultUserCalendarFilterName]
    },
    generateAvailabilitiesOptions() {
      const availabilitiesOptions = []
      for (let step = 0; step < 4; step++) {
        const date = this.$moment().utc().add(step, 'd').format('YYYY-MM-DD')
        availabilitiesOptions.push({ header: date })
        availabilitiesOptions.push({
          value: `free_${step}_days_ahead--Check-in today`,
          text: 'Check-in',
          group: date,
        })
        availabilitiesOptions.push({
          value: `free_${step}_days_ahead--Checkout today`,
          text: 'Check-out',
          group: date,
        })
        availabilitiesOptions.push({
          value: `free_${step}_days_ahead--Check-in and Checkout today`,
          text: 'Check-in-out',
          group: date,
        })
        availabilitiesOptions.push({
          value: `free_${step}_days_ahead--Free today`,
          text: 'Free',
          group: date,
        })
        availabilitiesOptions.push({ divider: true })
      }
      return availabilitiesOptions
    },
    mapServiceCall(serviceCall) {
      const needsApproval =
        !serviceCall.manual_approved && serviceCall.status === 'Done'
      const start = serviceCall.scheduled_at
        ? serviceCall.scheduled_at
        : new Date()
      const end = serviceCall.scheduled_at_end
      const hour = this.$moment.utc(start).hours()
      return {
        extendedProps: {
          task: serviceCall,
          status: this.statusToNum(
            needsApproval ? 'Pending Approval' : serviceCall.status
          ),
          start: this.$moment.utc(start).format('YYYY-MM-DD HH:mm:ss'),
          end: this.$moment.utc(end).format('YYYY-MM-DD HH:mm:ss'),
          allDay: hour < 4 || hour > 22,
          backgroundColor: '#fff',
          resourceId: serviceCall.assigned_contractor_id,
        },
      }
    },
    openListingTaskInNewTab(listingTaskId) {
      window.open(`/dashboard/listing-task/${listingTaskId}`, '_blank')
    },
  },
}
