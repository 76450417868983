<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.53 22">
    <g id="Шар_2" data-name="Шар 2">
      <g id="Шар_1-2" data-name="Шар 1">
        <g id="_004-maintenance" data-name=" 004-maintenance">
          <g id="Group_10232" data-name="Group 10232">
            <g id="Group_10231" data-name="Group 10231">
              <path
                id="Path_5087"
                data-name="Path 5087"
                class="cls-1"
                d="M6.6,12.47A2.93,2.93,0,1,0,9.53,15.4,2.93,2.93,0,0,0,6.6,12.47Zm0,5.13a2.2,2.2,0,1,1,2.2-2.2,2.19,2.19,0,0,1-2.2,2.2Z"
              />
            </g>
          </g>
          <g id="Group_10234" data-name="Group 10234">
            <g id="Group_10233" data-name="Group 10233">
              <path
                id="Path_5088"
                data-name="Path 5088"
                class="cls-1"
                d="M12.83,13.93h-1.7a3.83,3.83,0,0,0-.29-.7L12,12a.36.36,0,0,0,0-.52L10.49,10A.37.37,0,0,0,10,10l-1.2,1.2a4.7,4.7,0,0,0-.7-.29V9.17A.37.37,0,0,0,7.7,8.8H5.5a.37.37,0,0,0-.37.37h0v1.7a3.83,3.83,0,0,0-.7.29L3.23,10a.35.35,0,0,0-.51,0L1.16,11.51a.37.37,0,0,0,0,.52l1.2,1.2a4.7,4.7,0,0,0-.29.7H.37A.37.37,0,0,0,0,14.3H0v2.2a.37.37,0,0,0,.37.37h1.7a3.83,3.83,0,0,0,.29.7l-1.2,1.2a.36.36,0,0,0,0,.52l1.55,1.55a.37.37,0,0,0,.52,0l1.2-1.2a4.7,4.7,0,0,0,.7.29v1.7A.38.38,0,0,0,5.5,22H7.7a.38.38,0,0,0,.37-.37h0v-1.7a4.7,4.7,0,0,0,.7-.29l1.2,1.2a.36.36,0,0,0,.51,0L12,19.29a.37.37,0,0,0,0-.52l-1.2-1.2a6.07,6.07,0,0,0,.29-.7h1.7a.38.38,0,0,0,.37-.37h0V14.3A.38.38,0,0,0,12.83,13.93Zm-.36,2.2H10.86a.38.38,0,0,0-.36.28,3.88,3.88,0,0,1-.43,1,.38.38,0,0,0,.06.45L11.27,19l-1,1L9.09,18.93a.36.36,0,0,0-.44-.06,4.08,4.08,0,0,1-1,.43.37.37,0,0,0-.28.35v1.62H5.87V19.65a.37.37,0,0,0-.28-.35,3.88,3.88,0,0,1-1-.43.38.38,0,0,0-.45.06L3,20.07l-1-1,1.14-1.14a.38.38,0,0,0,.06-.45,3.88,3.88,0,0,1-.43-1,.38.38,0,0,0-.36-.28H.73V14.67H2.34a.39.39,0,0,0,.36-.28,4.08,4.08,0,0,1,.43-1,.36.36,0,0,0-.06-.44L1.93,11.77l1-1,1.14,1.14a.38.38,0,0,0,.45.06,3.57,3.57,0,0,1,1-.43.38.38,0,0,0,.28-.36V9.53H7.34v1.61a.37.37,0,0,0,.27.36,3.75,3.75,0,0,1,1,.43.36.36,0,0,0,.44-.06l1.14-1.14,1,1-1.14,1.14a.35.35,0,0,0-.05.44,4.05,4.05,0,0,1,.42,1,.4.4,0,0,0,.36.28h1.61Z"
              />
            </g>
          </g>
          <g id="Group_10236" data-name="Group 10236">
            <g id="Group_10235" data-name="Group 10235">
              <path
                id="Path_5089"
                data-name="Path 5089"
                class="cls-1"
                d="M17.23,6.23H16.06C16,4.11,15.14.68,12.46.39v0A.37.37,0,0,0,12.1,0H9.9a.37.37,0,0,0-.37.37v0C6.86.68,6,4.11,5.93,6.23H4.76a.37.37,0,0,0-.36.37V8.07a.36.36,0,0,0,.37.36H17.23a.36.36,0,0,0,.37-.36V6.6a.37.37,0,0,0-.37-.37ZM16.87,7.7H5.13V7H8.07V6.23H6.67c.07-1.85.75-4.8,2.86-5.1V4.4h.73V.73h1.47V4.4h.73V1.13c2.12.31,2.79,3.25,2.87,5.1H8.8V7h8.07Z"
              />
            </g>
          </g>
          <g id="Group_10238" data-name="Group 10238">
            <g id="Group_10237" data-name="Group 10237">
              <path
                id="Path_5090"
                data-name="Path 5090"
                class="cls-1"
                d="M20.17,11.37h-.65l.46-.46a.37.37,0,0,0,0-.52l-1-1a.36.36,0,0,0-.52,0L18,9.81V9.17a.37.37,0,0,0-.37-.37H16.13a.36.36,0,0,0-.36.37h0v.64l-.46-.45a.36.36,0,0,0-.52,0l-1,1a.37.37,0,0,0,0,.52l.46.46h-.64a.36.36,0,0,0-.37.36h0V13.2a.37.37,0,0,0,.37.37h.64l-.46.45a.37.37,0,0,0,0,.52l1,1a.37.37,0,0,0,.52,0l.46-.46v.65a.36.36,0,0,0,.36.36H17.6a.37.37,0,0,0,.37-.36h0v-.65l.45.46a.37.37,0,0,0,.52,0l1-1A.37.37,0,0,0,20,14l-.46-.45h.65a.37.37,0,0,0,.36-.37h0V11.73A.36.36,0,0,0,20.17,11.37Zm-.37,1.46h-.86a.38.38,0,0,0-.35.25,1.34,1.34,0,0,1-.07.18.35.35,0,0,0,.07.41l.61.61-.52.52-.61-.61a.35.35,0,0,0-.41-.07l-.18.07a.37.37,0,0,0-.25.35v.86H16.5v-.86a.37.37,0,0,0-.25-.35l-.17-.07a.37.37,0,0,0-.42.07l-.61.61-.52-.52.61-.61a.37.37,0,0,0,.08-.41,1.42,1.42,0,0,1-.08-.18.37.37,0,0,0-.35-.25h-.86V12.1h.86a.37.37,0,0,0,.35-.24,1.34,1.34,0,0,1,.07-.18.37.37,0,0,0-.07-.42l-.61-.61.52-.52.61.61a.39.39,0,0,0,.42.08l.17-.08a.36.36,0,0,0,.25-.34V9.53h.73v.87a.36.36,0,0,0,.25.34l.17.07a.37.37,0,0,0,.42-.07l.61-.61.52.52-.61.61a.37.37,0,0,0-.07.42l.07.18a.37.37,0,0,0,.34.24h.87Z"
              />
            </g>
          </g>
          <g id="Group_10240" data-name="Group 10240">
            <g id="Group_10239" data-name="Group 10239">
              <path
                id="Path_5091"
                data-name="Path 5091"
                class="cls-1"
                d="M16.87,11a1.47,1.47,0,1,0,0,2.93,1.47,1.47,0,0,0,0-2.93Zm0,2.2a.74.74,0,1,1,.73-.73.74.74,0,0,1-.73.73Z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Maintenance',
}
</script>

<style scoped>
.cls-1 {
  fill: currentColor;
}
</style>
