<template>
  <v-layout v-if="chosenInventoryItem" justify-center>
    <v-dialog v-model="chosenInventoryItem" persistent max-width="500px">
      <v-flex xs12>
        <v-card class="pa-4">
          <v-btn
            fab
            dark
            small
            color="teal"
            class="float-right"
            @click="closeDialog"
          >
            <v-icon dark>close</v-icon>
          </v-btn>
          <gallery
            :images="chosenInventoryItem.images"
            :thumbnails="true"
            :cols="6"
          />
          <dvr-media-upload :value.sync="images" folder="inventory" />
          <v-btn
            v-if="images.length"
            color="primary"
            class="text-capitalize"
            elevation="0"
            @click="saveBeforeImages"
          >
            Upload
          </v-btn>
          <v-btn
            v-if="enableSave"
            class="cyanish float-right mt-2 mr-2 mb-2 white--text"
            @click="save()"
          >
            Save
          </v-btn>
          <h3 class="center-text cyan-icon">Details</h3>

          <v-flex xs12 column>
            <v-text-field
              v-model="chosenInventoryItem.sku"
              prepend-icon="sku"
              label="SKU"
              required
              @change="enableSave = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 column>
            <v-text-field
              v-model="chosenInventoryItem.title"
              prepend-icon="title"
              label="Title"
              required
              @change="enableSave = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 column>
            <v-autocomplete
              v-model="chosenInventoryItem.catagory"
              :items="$store.state.catagoriesOptions"
              prepend-icon="dns"
              label="Catagory"
              @change="enableSave = true"
            />
          </v-flex>
          <v-flex xs12 column>
            <v-text-field
              v-model="chosenInventoryItem.price"
              :disabled="!(isAdmin || hasAbility('super-storage'))"
              prepend-icon="money"
              label="Price"
              required
              @change="enableSave = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 column>
            <v-text-field
              v-model="chosenInventoryItem.mark_up"
              :disabled="!(isAdmin || hasAbility('super-storage'))"
              prepend-icon="add_box"
              label="Markup"
              required
              @change="enableSave = true"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 column>
            <v-text-field
              v-model="chosenInventoryItem.min_alert"
              prepend-icon="report_problem"
              label="Min amount alert"
              required
              @change="enableSave = true"
            ></v-text-field>
          </v-flex>
          <v-layout
            v-if="isAdmin || hasAbility('super-storage')"
            column
            justify-center
            class="center-text"
          >
            <v-btn
              class="purpleish float-right mt-2 mr-2 mb-2 white--text"
              @click="showChangePanel = !showChangePanel"
            >
              Need to fix amount?
            </v-btn>
            <v-flex
              v-if="showChangePanel"
              class="comp-wrapper center-text"
              justify-center
            >
              <span class="mt-2 mb-3 grey-text">
                The current amount is {{ chosenInventoryItem.current_amount }},
                Please write the new total that we got for the item.
              </span>
              <v-text-field
                v-model="chosenInventoryItem.new_amount"
                prepend-icon="money"
                label="New Total Amount"
                type="number"
                required
              ></v-text-field>
              <v-text-field
                v-model="chosenInventoryItem.comments"
                prepend-icon="money"
                label="Comments"
                required
              ></v-text-field>

              <v-btn
                v-if="
                  chosenInventoryItem.comments && chosenInventoryItem.new_amount
                "
                class="cyanish float-right mt-2 mr-2 mb-2 white--text"
                @click="save(true)"
              >
                Modify Amount
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout
            v-if="!isEmpty(chosenInventoryItem.inventory_actions)"
            wrap
            mt-3
          >
            <h3 class="center-text purple-icon">Transfers</h3>
            <template
              v-for="(action, index) in chosenInventoryItem.inventory_actions"
            >
              <v-flex
                v-if="action.action_type !== 'Move From'"
                :key="index"
                class="comp-wrapper mt-2 mb-2 xs12"
              >
                <v-layout wrap>
                  <v-flex xs3 class="purple-icon br-1 center-text"
                    >{{ parseDate(action.created_at) }}
                  </v-flex>
                  <v-flex
                    v-if="action.action_type === 'Add'"
                    xs9
                    class="green-health center-text"
                    ><span class="grey-text bloder"
                      >Adding to storage
                      {{ storageNicknameById(action.storage_id) }}
                      Storage:</span
                    >{{ action.amount }}
                  </v-flex>
                  <v-flex
                    v-if="action.action_type === 'Return'"
                    xs9
                    class="green-health center-text"
                    ><span class="grey-text bloder"
                      >Returned to
                      {{ storageNicknameById(action.storage_id) }} Storage from
                      {{ action.listing_nickname }}: </span
                    >{{ action.amount }}
                  </v-flex>
                  <v-flex
                    v-if="action.action_type === 'Take'"
                    xs9
                    class="red-health center-text"
                    ><span class="grey-text bloder"
                      >Taken out from
                      {{ storageNicknameById(action.storage_id) }} Storage to
                      {{ action.listing_nickname }}: </span
                    >{{ action.amount }}
                  </v-flex>
                  <v-flex
                    v-if="action.action_type === 'Rent'"
                    xs9
                    class="red-health center-text"
                    ><span class="grey-text bloder"
                      >Rented from
                      {{ storageNicknameById(action.storage_id) }} Storage to
                      {{ action.listing_nickname }}: </span
                    >{{ action.amount }}
                  </v-flex>
                  <v-flex
                    v-if="action.action_type === 'Move To'"
                    xs9
                    class="grey-text center-text"
                    ><span class="grey-text bloder"
                      >Moved from
                      {{
                        storageNicknameById(
                          chosenInventoryItem.inventory_actions[index - 1]
                            .storage_id
                        )
                      }}
                      Storage to
                      {{ storageNicknameById(action.storage_id) }}
                      Storage: </span
                    >{{ action.amount }}
                  </v-flex>
                  <v-flex
                    v-if="action.comments"
                    xs12
                    class="grey-text center-text small-text"
                  >
                    {{ action.comments }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </template>
          </v-layout>
        </v-card>
      </v-flex>
    </v-dialog>
  </v-layout>
</template>

<script>
import get from 'lodash/fp/get'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import last from 'lodash/last'

import axios from 'axios'
import Gallery from 'components/common/gallery'
import DvrMediaUpload from 'components/common/dvr-media-upload'

export default {
  components: {
    DvrMediaUpload,
    Gallery,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data: () => ({
    enableSave: false,
    images: [],
    showChangePanel: false,
    imagesUploaded: false,
  }),
  computed: {
    chosenInventoryItem() {
      return this.$store.state.chosenInventoryItem
    },
    paidAt() {
      return get('paid_at', this.chosenInventoryItem)
    },
    amount() {
      return (get('amount', this.chosenInventoryItem) || '').toLocaleString(
        'en'
      )
    },
    description() {
      return get('description', this.chosenInventoryItem)
    },
    expenseType() {
      return get('short_reason', this.chosenInventoryItem)
    },
    hasImage() {
      return get('images[0]', this.chosenInventoryItem)
    },
    imageSrc() {
      if (this.chosenInventoryItem) {
        let src = get('images[0]', this.chosenInventoryItem)
        if (!src) {
          if (this.chosenInventoryItem.short_reason === 'Management Service') {
            src = '/management.png'
          } else if (
            this.chosenInventoryItem.short_reason === 'Cleaning Service'
          ) {
            src = '/cleaning.png'
          } else if (
            this.chosenInventoryItem.short_reason === 'Host Channels Fee'
          ) {
            src = '/fee.png'
          } else if (this.chosenInventoryItem.short_reason === 'Taxes') {
            src = '/taxes.png'
          }
        }
        return src
      }
      return null
    },
  },
  methods: {
    saveBeforeImages() {
      let data = {}
      data.images = this.images
      this.saveFiles(data)
      this.images = []
      this.imagesUploaded = true
    },
    saveFiles(data) {
      this.$store.commit('updateListingLoading', true)

      axios
        .post(
          `/api/inventory-items/${this.chosenInventoryItem.id}/add-images`,
          data
        )
        .then(() => {
          this.$store.commit('updateListingLoading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.commit('updateListingLoading', false)
        })
      setTimeout(() => {
        this.imagesUploaded = false
        this.$store.dispatch('getInventoryItems', {
          catagory: [],
          search: null,
          storage: 'All',
        })
      }, 6000)
    },
    deleteImg(deleteImg) {
      const userConfirm = confirm('Are you sure you want to delete?')
      if (userConfirm) {
        let index = -1
        this.chosenInventoryItem.images.forEach((pic, i) => {
          if (last(pic.split('/')) === last(deleteImg.split('/'))) {
            index = i
          }
        })
        if (index !== -1) {
          this.chosenInventoryItem.images.splice(index, 1)
          this.chosenInventoryItem.allow_delete = true
          this.$store.dispatch('UpdateInventoryItem', this.chosenInventoryItem)
          setTimeout(() => {
            this.$store.dispatch('getInventoryItems', {
              catagory: [],
              search: null,
              storage: 'All',
            })
          }, 200)
        }
      }
    },
    save(closeAfter = false) {
      this.$store.dispatch('UpdateInventoryItem', this.chosenInventoryItem)
      setTimeout(() => {
        this.$store.dispatch('getInventoryItems', {
          catagory: [],
          search: null,
          storage: 'All',
        })
        if (closeAfter) {
          this.closeDialog()
        }
      }, 300)
      this.enableSave = false
    },
    closeDialog() {
      this.enableSave = false
      this.showChangePanel = false
      this.$store.commit('updateChosenInventoryItem', null)
    },
  },
}
</script>
