<template>
  <div class="camera-container fill-available">
    <v-progress-circular style="z-index: 9999" indeterminate size="12" />
    <video
      ref="videoElement"
      class="camera-stream"
      playsinline
      autoplay
      muted="muted"
    />
    <div class="d-flex justify-space-between align-center pa-4 button-bar">
      <v-btn large icon color="black" @click="closeCamera"
        ><v-icon>mdi-close-circle</v-icon>
      </v-btn>
      <!--      <v-btn-->
      <!--        :disabled="recording"-->
      <!--        v-if="useVideo"-->
      <!--        icon-->
      <!--        large-->
      <!--        color="primary"-->
      <!--        outlined-->
      <!--        @click="uploadVideo"-->
      <!--        ><v-icon>mdi-check</v-icon>-->
      <!--      </v-btn>-->
      <v-btn icon x-large color="primary" outlined @click="captureImage"
        ><v-icon large>mdi-camera-iris</v-icon>
      </v-btn>
      <div />
      <!--      <v-btn color="warning" v-show="false" large icon @click="toggleRecording">-->
      <!--        <v-icon>{{-->
      <!--          recording ? 'mdi-stop-circle' : 'mdi-record-circle'-->
      <!--        }}</v-icon>-->
      <!--      </v-btn>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Camera',
  data() {
    return {
      recording: false,
      mediaRecorder: null,
      recordedChunks: [],
      useVideo: false,
    }
  },
  mounted() {
    this.initializeCamera()
  },
  methods: {
    async initializeCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: { max: 640 }, // Set the maximum width for lower quality
            height: { max: 800 }, // Set the maximum height for lower quality
            facingMode: 'environment', // Use the rear camera
          },
        })
        const videoElement = this.$refs.videoElement
        videoElement.srcObject = stream
      } catch (error) {
        console.error('Error accessing camera:', error)
      }
    },
    captureImage() {
      const videoElement = this.$refs.videoElement
      const canvas = document.createElement('canvas')
      canvas.width = videoElement.videoWidth
      canvas.height = videoElement.videoHeight
      canvas
        .getContext('2d')
        .drawImage(videoElement, 0, 0, canvas.width, canvas.height)
      const imageData = canvas.toDataURL('image/jpeg', 0.8) // Adjust quality as needed
      this.uploadImage(imageData)
    },
    toggleRecording() {
      if (this.recording) {
        this.stopRecording()
      } else {
        this.startRecording()
      }
    },
    startRecording() {
      const videoElement = this.$refs.videoElement
      const stream = videoElement.srcObject
      this.useVideo = true
      this.recordedChunks = []
      this.mediaRecorder = new MediaRecorder(stream)
      this.mediaRecorder.addEventListener('dataavailable', event => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data)
        }
      })
      this.mediaRecorder.start()
      this.recording = true
    },
    stopRecording() {
      this.mediaRecorder.stop()
      this.recording = false
    },
    uploadImage(imageData) {
      const file = new File([imageData], 'capture')
      this.$emit('upload', [file])
      this.closeCamera()
    },
    uploadVideo() {
      const blob = new Blob(this.recordedChunks, { type: 'video/webm' })
      const file = new File([blob], 'video')
      this.$emit('upload', [file])
    },
    closeCamera() {
      const videoElement = this.$refs.videoElement
      const stream = videoElement.srcObject
      this.recordedChunks = []
      if (stream) {
        const tracks = stream.getTracks()
        tracks.forEach(track => track.stop())
      }
      this.$emit('closed')
    },
  },
}
</script>

<style scoped>
.camera-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 999;
}

.camera-stream {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
