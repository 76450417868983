<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="111.376"
    height="117.116"
    viewBox="0 0 111.376 117.116"
  >
    <g
      id="photos_after"
      data-name="photos after"
      transform="translate(-10893 404.116)"
    >
      <path
        id="Subtraction_2"
        data-name="Subtraction 2"
        d="M89.456,123H10.544A10.555,10.555,0,0,1,0,112.457V33.544A10.555,10.555,0,0,1,10.544,23H45.16A39.841,39.841,0,0,0,43.8,28.006H10.544a5.544,5.544,0,0,0-5.538,5.538V92.187L22.19,75a2.493,2.493,0,0,1,3.535,0l16.2,16.2L62.676,70.459a39.989,39.989,0,0,0,4.711,2.379L43.707,96.519a2.491,2.491,0,0,1-3.535,0l-16.2-16.2L5.006,99.277v13.18a5.544,5.544,0,0,0,5.538,5.538H89.456a5.544,5.544,0,0,0,5.538-5.538V89.285L81.687,75.978c.42.013.861.021,1.313.021a40.523,40.523,0,0,0,5.427-.365L94.994,82.2V74.17A39.872,39.872,0,0,0,100,72.218v40.239A10.555,10.555,0,0,1,89.456,123ZM31,63.07A12.718,12.718,0,1,1,43.727,50.34,12.744,12.744,0,0,1,31,63.07Zm0-20.454a7.724,7.724,0,1,0,7.724,7.724A7.733,7.733,0,0,0,31,42.616Z"
        transform="translate(10893 -410)"
      />
      <g
        id="_005-verified"
        data-name="005-verified"
        transform="translate(10944.376 -404.116)"
      >
        <g id="Group_10011" data-name="Group 10011" transform="translate(0 0)">
          <g id="Group_10010" data-name="Group 10010">
            <path
              id="Path_4877"
              data-name="Path 4877"
              d="M51.213,8.787A30,30,0,0,0,8.787,51.213,30,30,0,1,0,51.213,8.787ZM30,56.484A26.484,26.484,0,1,1,56.484,30,26.514,26.514,0,0,1,30,56.484Z"
              transform="translate(0 0)"
              fill="#00bf2c"
            />
          </g>
        </g>
        <g
          id="Group_10013"
          data-name="Group 10013"
          transform="translate(15.152 19.859)"
        >
          <g id="Group_10012" data-name="Group 10012">
            <path
              id="Path_4878"
              data-name="Path 4878"
              d="M158.481,169.982a1.758,1.758,0,0,0-2.486,0L140.472,185.5l-8.171-8.171a1.758,1.758,0,0,0-2.486,2.486l9.414,9.414a1.758,1.758,0,0,0,2.486,0l16.766-16.766A1.758,1.758,0,0,0,158.481,169.982Z"
              transform="translate(-129.301 -169.467)"
              fill="#00bf2c"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PhotosAfter',
}
</script>

<style scoped>
svg {
  fill: currentColor;
}
</style>
