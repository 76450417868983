<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.715"
    height="16"
    viewBox="0 0 18.715 16"
  >
    <g transform="translate(0 -37.141)">
      <path
        class="a"
        d="M119.645,131.9a.165.165,0,0,0-.165.165v1.052h3.458v-1.052a.165.165,0,0,0-.165-.165Z"
        transform="translate(-115.113 -91.293)"
      />
      <path
        class="a"
        d="M298.07,131.9a.165.165,0,0,0-.165.165v1.052h3.458v-1.052a.165.165,0,0,0-.165-.165H298.07Z"
        transform="translate(-287.016 -91.293)"
      />
      <path
        class="a"
        d="M52.71,41.811V40.77A1.274,1.274,0,0,1,53.983,39.5H57.11a1.274,1.274,0,0,1,1.273,1.273v1.041h.848V40.77A1.274,1.274,0,0,1,60.5,39.5h3.128a1.274,1.274,0,0,1,1.273,1.273v1.041h.881a2.516,2.516,0,0,1,.5.05v-3.4a1.324,1.324,0,0,0-1.324-1.324H52.65a1.324,1.324,0,0,0-1.324,1.324v3.4a2.519,2.519,0,0,1,.5-.05Z"
        transform="translate(-49.45)"
      />
      <path
        class="a"
        d="M18.029,258.811H.686A.686.686,0,0,0,0,259.5v4.5a.686.686,0,0,0,.686.686H18.029a.686.686,0,0,0,.686-.686v-4.5A.686.686,0,0,0,18.029,258.811Z"
        transform="translate(0 -213.567)"
      />
      <path
        class="a"
        d="M42.26,195.206H28.3a1.408,1.408,0,0,0-1.395,1.217H43.655A1.408,1.408,0,0,0,42.26,195.206Z"
        transform="translate(-25.923 -152.287)"
      />
      <path
        class="a"
        d="M18.762,449.7v.366a.554.554,0,1,0,1.108,0V449.7H18.762Z"
        transform="translate(-18.076 -397.48)"
      />
      <path
        class="a"
        d="M462.938,449.7v.366a.554.554,0,0,0,1.108,0V449.7h-1.108Z"
        transform="translate(-446.016 -397.479)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'BedDoubled',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
