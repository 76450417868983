<template>
  <div class="ma-5">
    <data-table
      :headers="historyHeaders"
      :items="items"
      :loading="tableLoading"
    />
    <v-pagination
      v-if="pagination"
      v-model="page"
      :length="Math.ceil(pagination.count / pagination.per_page)"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3 flex"
    />
  </div>
</template>
<script>
import DataTable from 'components/common/data-table/data-table.vue'
import { HISTORY_HEADERS } from 'components/inventory/config.js'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  components: {
    DataTable,
  },
  mixins: [CommonFunctions],
  data() {
    return {
      historyHeaders: HISTORY_HEADERS,
      items: [],
      tableLoading: false,
      pagination: {
        per_page: 30,
        count: 0,
      },
      page: 1,
    }
  },
  watch: {
    page(val) {
      this.page = val
      this.getActions()
    },
  },
  mounted() {
    this.getActions()
  },
  methods: {
    async getActions() {
      this.tableLoading = true
      const { data } = await this.$store.dispatch(
        'inventory/getInventoryActions',
        { page: this.page }
      )
      this.pagination = data.pagination
      this.items = data.actions.map(i => ({
        ...i,
        approvedBy: this.nameById(i.approved_by_id),
      }))
      this.tableLoading = false
    },
  },
}
</script>

<style></style>
