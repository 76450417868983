<template>
  <div>
    <menu-hot-key />
    <app-version v-if="showVersionAlert" text="System is syncing..." />
    <app-version v-if="generalAlert" :text="generalAlert" />
    <app-version v-if="paymentAlert && !isInvestor">
      <template #customHeader>
        <div
          class="w-100 red lighten-5 red--text text--darken-4 pa-2 text-body-2 font-weight-medium text-center"
        >
          Please address your overdue payment by <b>{{ paymentDueDate }}</b
          >. Billing information can be updated

          <a
            class="red--text text--darken-4 font-weight-bold"
            href="/dashboard/management/settings/?section=billing"
            >here</a
          >
        </div>
      </template>
    </app-version>
    <v-app-bar
      id="site-header"
      :app="!(showVersionAlert || generalAlert)"
      :fixed="!(showVersionAlert || generalAlert)"
      class="dash-app-bar"
      color="white"
    >
      <v-icon v-if="isMobileOrIPad && showBackButton" @click="handleBack">
        mdi-arrow-left
      </v-icon>

      <v-app-bar-nav-icon @click.stop="$emit('update:drawer', !drawer)" />
      <v-toolbar-title v-if="!isCleaner && !isMobile">
        <router-link :to="defaultRouteByRole" @click="setDisplay('listings')">
          <img class="mt-2 mr-2" :src="logo" height="40" />
        </router-link>
      </v-toolbar-title>

      <v-spacer />
      <property-picker
        v-if="showPropertyPicker && !onHotelScreens"
        :show-dups="true"
        :value="+$route.params.id"
      />
      <reservation-search
        v-else-if="onHotelScreens"
        @get-reservation="getReservation"
      />
      <chat-kpi v-if="showChatKPI" />
      <v-spacer />
      <div v-if="user" class="d-flex align-center">
        <div>
          <v-btn
            v-if="isMobile && !isInvestor"
            x-small
            fab
            depressed
            class="ms-2"
            color="orange lighten-4"
            href="https://intercom.help/boomnow/en/"
            target="_blank"
          >
            <v-icon small color="orange darken-4">mdi-help</v-icon>
          </v-btn>
          <v-btn
            v-if="!isMobile && !isInvestor && !isAgent"
            depressed
            small
            color="orange lighten-4"
            href="https://intercom.help/boomnow/en/"
            target="_blank"
            class="me-2"
          >
            <span class="orange--text text--darken-4">Help Center</span>
            <v-icon small class="ms-2" color="orange darken-4"
              >mdi-help-circle</v-icon
            >
          </v-btn>
        </div>
        <v-menu
          v-if="!isInvestor && !isAgent"
          v-model="menu"
          transition="slide-y-transition"
          offset-y
          left
        >
          <template #activator="{ on }">
            <v-btn elevation="0" class="mr-2 ml-2 primary" fab x-small v-on="on"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </template>
          <v-list>
            <listing-task-module
              v-if="showModule('ops')"
              :quick-task="true"
              :pre-fields="{
                listings_ids: listingIds,
                ...quickFieldCatalog,
              }"
              @after-create="incrementQuickTasks"
            >
              <template #customBtn="{ on }">
                <v-list-item link v-on="on">
                  <div @click="menu = false">
                    <v-list-item-title class="text-body-2"
                      ><v-icon class="mr-2">$maintenance</v-icon>
                      {{ $t('New Service Call') }}</v-list-item-title
                    >
                  </div>
                </v-list-item>
              </template>
            </listing-task-module>
            <expense-modal
              v-if="
                !isInvestor &&
                ((onPayrole && isOperationsPersonal) ||
                  isAdmin ||
                  isOperationsPersonal ||
                  isCommunicationPerson)
              "
              :quick-mode="true"
              :central-bank-view="true"
              @open="menu = false"
            />
            <create-tenant-page v-if="isAdminTenant && isSystemAdmin">
              <template #customBtn="{ on }">
                <v-list-item link v-on="on">
                  <div @click="menu = false">
                    <v-list-item-title class="text-body-2"
                      ><v-icon class="mr-2">$calendar</v-icon>
                      {{ $t('New Tenant') }}</v-list-item-title
                    >
                  </div>
                </v-list-item>
              </template>
            </create-tenant-page>
            <reservation-create-modal
              v-if="hasAbility(['create-reservation']) || isHotelReception"
              class="ml-auto"
              @oncreate="createReservation"
            >
              <template #customBtn="{ on }">
                <v-list-item link v-on="on">
                  <div @click="menu = false">
                    <v-list-item-title class="text-body-2"
                      ><v-icon class="mr-2">$calendar</v-icon>
                      {{ $t('Reservation') }}</v-list-item-title
                    >
                  </div>
                </v-list-item>
              </template>
            </reservation-create-modal>
            <v-list-item link>
              <div @click="newListing">
                <v-list-item-title class="text-body-2"
                  ><v-icon class="mr-2">$home</v-icon>
                  {{ $t('New Listing') }}</v-list-item-title
                >
              </div>
            </v-list-item>
            <requests
              v-if="showModule('projects') && !loading"
              :only-take="true"
            >
              <template #customBtn="{ on }">
                <v-list-item link v-on="on">
                  <div @click="menu = false">
                    <v-list-item-title class="text-body-2"
                      ><v-icon class="mr-2">$inventory</v-icon>
                      {{ $t('Supply request') }}</v-list-item-title
                    >
                  </div>
                </v-list-item>
              </template>
            </requests>
          </v-list>
        </v-menu>
        <div v-if="showClockSwitch">
          <v-switch
            v-model="user.clocked_in"
            class="pa-1 pb-0 rounded small-text"
            hide-details
            @change="clockChange"
          />
          <div class="text-caption small-text">
            {{ user.clocked_in ? 'On-duty' : 'Off-duty' }}
          </div>
        </div>
      </div>
      <notifications-tip v-if="showNotificationsTip" />

      <v-progress-linear
        v-if="listingTaskLoading || headerProgressBar"
        indeterminate
        color="primary"
        height="10"
        class="dash-app-bar-progress"
      />
    </v-app-bar>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import axios from 'axios'
import { mapActions, mapGetters, mapState } from 'vuex'
import PropertyPicker from 'components/property-picker'
import NotificationsTip from 'components/notifications-tip'
import ChatKpi from 'components/kpis/chat-kpi'
import MenuHotKey from 'components/menu/menu-hot-key'
import ReservationSearch from 'components/reservation/reservation-search'
import ListingTaskModule from 'components/listing-task-module'
import ExpenseModal from '../expenses/expense-modal'
import ltMixin from 'components/mixins/lt-mixin'
import ReservationCreateModal from 'components/reservation/reservation-create-modal.vue'
import AppVersion from 'components/common/app-version.vue'
import Requests from 'components/inventory/requests/requests.vue'
import CreateTenantPage from 'components/create-tenant-page.vue'

export default {
  name: 'AppHeader',
  components: {
    CreateTenantPage,
    Requests,
    AppVersion,
    ReservationCreateModal,
    ExpenseModal,
    ReservationSearch,
    MenuHotKey,
    ChatKpi,
    NotificationsTip,
    PropertyPicker,
    ListingTaskModule,
  },
  mixins: [CommonFunctions, PermissionsMixin, DeviceMixin, ltMixin],
  props: ['drawer', 'showBackButton'],
  data() {
    return {
      fetchingData: false,
      menu: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserId', 'showVersionAlert', 'currentListingTask']),
    ...mapState([
      'user',
      'listingTaskLoading',
      'headerProgressBar',
      'configuration',
      'tasksCatalog',
    ]),
    quickFieldCatalog() {
      const catalog =
        this.tasksCatalog.catalogItemSelect.find(
          c => c.system_key === 'quick_field_task'
        ) || {}
      return {
        task_type: catalog.department,
        sub_topic: catalog.sub_topic,
        listing_task_catalog_item_name: catalog.description,
        listing_task_catalog_item_id: catalog.id,
      }
    },
    currentListing() {
      return this.$store.state.currentListing
    },
    generalAlert() {
      return this.$store.state.app.configuration.general_alert
    },
    paymentAlert() {
      const { billing_info } = this.$store.state.app.configuration
      return billing_info && billing_info.payment_issue_banner
    },
    paymentDueDate() {
      const { billing_info } = this.$store.state.app.configuration
      return billing_info && billing_info.payment_issue_banner_due
    },
    showPropertyPicker() {
      return (
        (this.managementRoles || this.isCommunicationAgent) &&
        !this.loading &&
        !this.isDemoUser &&
        !this.showChatKPI &&
        ['dashboard/taskim'].every(route => !this.$route.path.includes(route))
      )
    },
    onHotelScreens() {
      return (
        (this.managementRoles || this.isCommunicationAgent) &&
        !this.loading &&
        (this.$route.path.includes('hotel-dashboard') ||
          this.$route.path.includes('guest-experience') ||
          this.$route.path.includes('multi-calendar') ||
          this.$route.path.includes('/reservation/'))
      )
    },
    logo() {
      return this.$store.state.app.configuration.logo_url
    },
    showClockSwitch() {
      return (
        (this.managementRoles || this.userRelatedToComm || this.onPayrole) &&
        (this.isDesignedVr || this.isNox)
      )
    },
    showNotificationsTip() {
      return !this.loading && !this.isInvestor
    },
    showChatKPI() {
      return this.$route.path.includes('dashboard/sales-chat')
    },
    listingIds() {
      const { name } = this.$route
      if (['view-property', 'area-report'].includes(name)) {
        return [+this.$route.params.id]
      } else if (this.currentListingTask)
        return [+this.currentListingTask.listing_id]
      else return []
    },
  },
  methods: {
    ...mapActions('reservation', ['getReservation']),
    ...mapActions(['incrementQuickTasks']),
    setDisplay(display) {
      this.$store.commit('updateDisplay', display)
    },
    newListing() {
      this.$store.commit('showModal', {
        name: 'ListingCreateModal',
        isPersistent: false,
      })
    },
    createReservation(resId) {
      window.open(`/dashboard/reservation/${resId}`, '_blank')
    },
    clockChange(newVal) {
      if (!newVal) {
        this.$store.dispatch('unsubscribeFromInquiryChannel')
      }
      axios
        .post(`/api/users/${this.currentUserId}/clock-${newVal ? 'in' : 'out'}`)
        .then(response => {
          this.$store.commit('updateUser', response.data)
        })
        .catch(alert)
    },
    handleBack() {
      this.$router.back()
    },
  },
}
</script>

<style lang="scss">
.dash-app-bar {
  z-index: 100 !important;
  box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 0px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;
}

.dash-app-bar-progress {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
}
</style>
