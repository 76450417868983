<template>
  <div id="ruApp"></div>
</template>

<script>
import { mapActions } from 'vuex'

const ELS = [
  {
    id: 'cm_jquery',
    src: 'https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js',
    el: 'script',
  },
  {
    id: 'cm_popper',
    src: 'https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js',
    integrity:
      'sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo',
    crossorigin: 'anonymous',
    el: 'script',
  },
  {
    id: 'cm_bootstrap',
    src: 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js',
    integrity:
      'sha384-Tc5IQib027qvyjSMfHjOMaLkfuWVxZxUPnCJA7l2mCWNIpG9mGCD8wGNIcPD7Txa',
    crossorigin: 'anonymous',
    el: 'script',
  },
  {
    id: 'cm_bootstrap_css',
    href: 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
    integrity:
      'sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u',
    crossorigin: 'anonymous',
    rel: 'stylesheet',
    el: 'link',
  },
  {
    id: 'cm_open_sans',
    href: 'https://fonts.googleapis.com/css?family=Open+Sans',
    rel: 'stylesheet',
    el: 'link',
  },
  {
    id: 'cm_fonta',
    href: 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.min.css',
    rel: 'stylesheet',
    el: 'link',
  },
]

export default {
  name: 'ChannelManagement',
  data() {
    return {
      route: null,
      htmlContent: null,
    }
  },
  beforeDestroy() {
    ELS.forEach(el_item => {
      const el = document.getElementById(el_item.id)
      if (el) {
        el.remove()
      }
    })
  },
  async mounted() {
    this.route = this.$route.path
    const { data } = await this.getChannelManagerHtml()
    this.htmlContent = data.html

    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(data, 'text/xml')

    const access_token = xmlDoc
      .querySelector('white-pms-host')
      .getAttribute('token')
    const refresh_token = xmlDoc
      .querySelector('white-pms-host')
      .getAttribute('refresh_token')

    const rentals_script = document.createElement('script')

    rentals_script.setAttribute(
      'src',
      `https://new.rentalsunited.com/white-pms-client/script?token=${access_token}&refreshToken=${refresh_token}`
    )
    document.head.appendChild(rentals_script)

    ELS.forEach(el_item => {
      const el = document.createElement(el_item.el)
      for (const [key, value] of Object.entries(el_item)) {
        el.setAttribute(key, value)
      }
      document.head.appendChild(el)
    })
    setTimeout(() => {
      this.$router.replace(this.route)
    }, 1000)
  },
  methods: {
    ...mapActions('listings', ['getChannelManagerHtml']),
  },
}
</script>
<style lang="scss">
.sc-container {
  display: block;
}
.container-fluid {
  width: 100%;
  /* justify-content: center; */
  padding: 25px;
}
.container-fluid > .row {
  width: 100%;
  display: block;
}
.rates-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
app-rate-tile {
  width: 100%;
  .row {
    flex-wrap: nowrap;
    align-items: center;
  }
}
.manual-options-row {
  display: block;
}
</style>
