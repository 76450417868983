<template>
  <v-select
    :value="value"
    :items="priorities"
    :label="$t('Priority')"
    outlined
    dense
    hide-details
    @change="changePriority"
  >
    <template slot="selection" slot-scope="{ item }">
      <v-icon class="mr-2" :color="priorityColor(item)" x-small
        >mdi-circle</v-icon
      >
      {{ item }}
    </template>
    <template slot="item" slot-scope="{ item }">
      <v-icon class="mr-2" :color="priorityColor(item)" x-small
        >mdi-circle</v-icon
      >
      {{ item }}
    </template>
  </v-select>
</template>

<script>
import ColorsMixin from 'components/mixins/colors-mixin'

export default {
  name: 'ListingPriority',
  mixins: [ColorsMixin],
  props: ['value'],
  data: () => ({
    priorities: ['Critical', 'High', 'Med', 'Low'],
  }),
  methods: {
    changePriority(val) {
      this.$emit('change', val)
      this.$emit('update:value', val)
    },
  },
}
</script>
