<template>
  <svg
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
    "
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    version="1.1"
    viewBox="0 0 100 100"
  >
    <defs />
    <g id="surface1">
      <path
        d="M64.0202+86.2593L92.3834+38.1929C91.9147+36.8647+91.3913+35.56+90.8014+34.2827L40.5866+13.0874L8.45766+59.0639C8.57485+59.8061+8.70766+60.5522+8.85219+61.2905L64.0202+86.2593"
        opacity="1"
        fill="#f6b917"
      />
      <path
        d="M58.7741+42.4624L57.0241+37.5132L42.1881+17.6811L44.8522+17.0171L70.4381+39.1343L85.4889+34.8257C87.2584+34.3725+89.2623+34.2749+90.7975+34.2827C86.067+24.0757+77.4069+15.7007+65.9772+11.6264C43.3756+3.57566+18.528+15.3804+10.4889+37.9897C8.00063+44.9858+7.41469+52.1968+8.45766+59.0639L44.5202+46.5366L58.7741+42.4624"
        opacity="1"
        fill="#1e3561"
      />
      <path
        d="M92.3834+38.1929C91.0592+39.0405+89.1256+40.1772+87.192+40.7944L72.1022+45.0952L62.0358+77.4116L59.4225+78.2671L61.5553+53.5835L60.36+48.4585L46.2233+52.4975L8.84829+61.2905C11.817+75.6694+21.9928+88.1968+36.8366+93.4858C59.442+101.541+84.2741+89.7241+92.3209+67.1304C95.7858+57.396+95.5592+47.2397+92.3834+38.1929"
        opacity="1"
        fill="#1e3561"
      />
      <path
        d="M89.9225+84.0054C87.4811+84.0054+85.3913+85.8413+85.3913+88.6225C85.3913+91.2436+87.2741+93.2202+89.9225+93.2202C92.3405+93.2202+94.4342+91.3921+94.4342+88.6225C94.4342+85.8413+92.3405+84.0054+89.9225+84.0054M89.9225+92.7671C87.5358+92.7671+86.0592+90.9038+86.0592+88.6225C86.0592+86.1772+87.7545+84.4702+89.9225+84.4702C92.0709+84.4702+93.7584+86.1772+93.7584+88.6225C93.7584+91.0679+92.0709+92.7671+89.9225+92.7671"
        opacity="1"
        fill="#1e3561"
      />
      <path
        d="M92.067+87.4585C92.067+86.3491+91.3913+85.8843+90.0709+85.8843L88.0045+85.8843L88.0045+91.3413L88.8991+91.3413L88.8991+89.0093L89.7428+89.0093L91.153+91.3413L92.1647+91.3413L90.6373+88.9468C91.4616+88.8452+92.067+88.4468+92.067+87.4585M88.8366+88.2397L88.8366+86.5639L89.9772+86.5639C90.5475+86.5639+91.2272+86.6421+91.2272+87.3452C91.2272+88.1733+90.5983+88.2397+89.8913+88.2397L88.8366+88.2397"
        opacity="1"
        fill="#1e3561"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Expedia',
}
</script>

<style scoped></style>
