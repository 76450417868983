<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.2"
    height="30"
    viewBox="0 0 19.2 30"
  >
    <g transform="translate(0 0)">
      <g transform="translate(0 14.435)">
        <path
          class="a"
          d="M97.536,210.467a1.059,1.059,0,0,0-2.118,0,7.482,7.482,0,0,1-14.965,0,1.059,1.059,0,1,0-2.118,0,9.6,9.6,0,0,0,8.541,9.565v2.824H83.03a1.059,1.059,0,1,0,0,2.118h9.812a1.059,1.059,0,1,0,0-2.118H88.995v-2.824A9.6,9.6,0,0,0,97.536,210.467Z"
          transform="translate(-78.336 -209.408)"
        />
      </g>
      <g transform="translate(3.706 0)">
        <path
          class="a"
          d="M137.99,0A5.9,5.9,0,0,0,132.1,5.894v9.565a5.894,5.894,0,1,0,11.788.035v-9.6A5.9,5.9,0,0,0,137.99,0Z"
          transform="translate(-132.096 0)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Mic',
}
</script>

<style scoped>
.a {
  fill: currentColor;
}
</style>
