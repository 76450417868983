<template>
  <form-dialog title="Edit Guest" @submit="submit">
    <template #activator="{ on }">
      <v-icon v-on="on">mdi-pencil</v-icon>
    </template>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="guestObj.firstName"
          dense
          outlined
          hide-details
          label="First Name"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="guestObj.lastName"
          hide-details
          dense
          outlined
          label="Last Name"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="guestObj.email"
          dense
          hide-details
          outlined
          label="Email"
        />
      </v-col>
      <v-col cols="12">
        <phone-input v-model="guestObj.phone" />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import PhoneInput from 'components/common/phone-input.vue'

export default {
  name: 'GuestEdit',
  components: { FormDialog, PhoneInput },
  props: ['guest'],
  data() {
    const [firstName, ...lastName] = this.guest.full_name.split(' ')
    const phone = this.guest.primary_phone || this.guest.phones[0]
    const email = this.guest.primary_email || this.guest.emails[0]
    return {
      guestObj: { firstName, lastName: lastName.join(' '), phone, email },
    }
  },
  methods: {
    async submit() {
      await this.$store.dispatch('guests/updateGuest', {
        id: this.guest.id,
        first_name: this.guestObj.firstName,
        last_name: this.guestObj.lastName,
        email: this.guestObj.email,
        phone: this.guestObj.phone,
      })
      this.$emit('onsuccess')
    },
  },
}
</script>

<style scoped></style>
