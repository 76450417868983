<template>
  <v-btn
    v-if="linkToken"
    outlined
    dense
    text
    small
    :disabled="disabled"
    @click="openPlaidLink"
    ><img width="30" :src="require(`@/assets/images/integrations/plaid.png`)" />
    {{ update ? 'reconnect' : 'Add account' }}
  </v-btn>
</template>
<script>
import axios from '@/axios/config'
/* global Plaid */
export default {
  name: 'PlaidLink',
  props: ['update', 'disabled', 'integrationId'],
  data() {
    return {
      linkToken: null,
    }
  },
  methods: {
    openPlaidLink() {
      const config = {
        token: this.linkToken,
        onSuccess: this.onSuccess,
        onExit: this.onExit,
      }
      const linkHandler = Plaid.create(config)
      linkHandler.open()
    },
    onSuccess(publicToken, metadata) {
      const { institution, account_id, link_session_id } = metadata
      const { institution_id, name } = institution
      axios.post(`/api/plaid/plaid-exchange-public`, {
        public_token: publicToken,
        institution_id,
        institution_name: name,
        link_session_id,
        account_id,
        update: this.update,
      })
    },
    onExit(err, metadata) {
      if (err) {
        console.error('Link exit with error:', err)
      }
      console.log('Link exit:', metadata)
    },
  },
  async mounted() {
    const { data } = await axios.get(`/api/plaid/token`, {
      params: { update: this.update, integration_id: this.integrationId },
    })
    this.linkToken = data
  },
}
</script>
