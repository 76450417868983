<template>
  <div class="p-relative flex-grow-0 h-100 mx-5">
    <v-progress-linear
      :active="fetchingData"
      indeterminate
      color="secondary"
      class="absolute-progress"
    />

    <div class="d-flex">
      <v-tooltip bottom open-delay="300">
        <template #activator="{ on }">
          <div class="d-flex" v-on="on">
            <v-divider vertical />
            <div class="flex-center">
              <v-btn icon small @click="goBackOneShift">
                <v-icon small color="primary">mdi-arrow-left</v-icon>
              </v-btn>
              <div class="bolder primary--text text--darken-2 text-center">
                {{ currentShiftTitle }}<br />Shift
              </div>
              <v-btn
                icon
                small
                :disabled="isCurrentShiftData"
                @click="goForwardOneShift"
              >
                <v-icon small color="primary">mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <div class="d-flex px-5">
          <div>
            <div class="indicator-quarter text-h6">Current Shift</div>
            <div class="indicator-quarter text-body-2">Previous Shift</div>
          </div>
          <div class="ml-3">
            <div class="indicator-quarter text-h6">
              {{ currentShiftTimeTitle }}
            </div>
            <div class="indicator-quarter text-body-2">
              {{ previousShiftTimeTitle }}
            </div>
          </div>
        </div>
      </v-tooltip>

      <div v-if="indicators.length" class="d-flex justify-center">
        <v-tooltip
          v-for="(indicator, index) in indicators"
          :key="index"
          bottom
          open-delay="300"
        >
          <template #activator="{ on }">
            <div class="d-flex" v-on="on">
              <v-divider vertical />
              <div class="d-flex px-5">
                <div class="flex-fill flex-center">
                  <v-icon large color="secondary">{{ indicator.icon }}</v-icon>
                </div>
                <div class="ml-3">
                  <div
                    class="indicator-quarter text-h5 primary--text text--darken-2 font-weight-medium"
                  >
                    {{ indicator.curr }}
                  </div>
                  <div
                    class="indicator-quarter text-body-2 secondary--text lighten-1"
                  >
                    {{ indicator.prev }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          {{ indicator.tooltip }}
        </v-tooltip>
      </div>
      <v-divider vertical />
    </div>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapActions, mapState } from 'vuex'
import { round } from 'lodash'
import moment from 'moment-timezone'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ChatKpi',
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  data() {
    return {
      duration: 6123,
      fetchingData: false,
      indicators: [],
      interval: null,
      currentShiftTimeRange: {},
      previousShiftTimeRange: {},
      historyDateTime: null,
    }
  },
  mounted() {
    this.fetchData()
    this.interval = setInterval(() => {
      if (!this.historyDateTime) this.fetchData()
    }, 240000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    ...mapState(['chatType']),
    timezone() {
      return this.$store.state.app.configuration.timezone
    },
    currentShiftTitle() {
      const currentShiftBegin = moment(this.currentShiftTimeRange.begin)
        .tz(this.timezone)
        .format('HH:mm')
      if (currentShiftBegin === '01:00') return 'Midnight'
      if (currentShiftBegin === '09:00') return 'Morning'
      if (currentShiftBegin === '17:00') return 'Night'
      return null
    },
    isCurrentShiftData() {
      const dateCurrentShiftEnd = moment(this.currentShiftTimeRange.begin)
        .tz(this.timezone)
        .add(8, 'hours')
      const now = moment().tz(this.timezone)
      return now.isBefore(dateCurrentShiftEnd)
    },
    currentShiftTimeTitle() {
      const timeBegin = moment(this.currentShiftTimeRange.begin).tz(
        this.timezone
      )
      const timeEnd = moment(timeBegin).add(8, 'hours')
      return `${this.parseDateAndHourInEst(
        timeBegin
      )} - ${this.parseDateAndHourInEst(timeEnd)}`
    },
    previousShiftTimeTitle() {
      const timeBegin = moment(this.previousShiftTimeRange.begin).tz(
        this.timezone
      )
      const timeEnd = moment(timeBegin).add(8, 'hours')
      return `${this.parseDateAndHourInEst(
        timeBegin
      )} - ${this.parseDateAndHourInEst(timeEnd)}`
    },
  },
  watch: {
    chatType() {
      this.fetchData()
    },
    historyDateTime() {
      this.fetchData()
    },
  },
  methods: {
    ...mapActions('kpis', ['getCustomerSupportKpi', 'getSalesKpi']),
    fetchData() {
      this.fetchingData = true
      if (this.chatType === 'sales') {
        this.getSalesKpi(this.historyDateTime)
          .then(res => {
            this.currentShiftTimeRange = res.current_shift.time_range || {}
            this.previousShiftTimeRange = res.previous_shift.time_range || {}
            this.indicators = this.mapSalesIndicators(res)
          })
          .finally(() => {
            this.fetchingData = false
          })
      } else if (this.chatType === 'customerSupport') {
        this.getCustomerSupportKpi(this.historyDateTime)
          .then(res => {
            this.currentShiftTimeRange = res.current_shift.time_range || {}
            this.previousShiftTimeRange = res.previous_shift.time_range || {}
            this.indicators = this.mapCustomerSupportIndicators(res)
          })
          .finally(() => {
            this.fetchingData = false
          })
      } else {
        this.indicators = []
        this.fetchingData = false
      }
    },
    goBackOneShift() {
      this.historyDateTime = moment(this.currentShiftTimeRange.begin).tz(
        this.timezone
      )
    },
    goForwardOneShift() {
      const dateTime = moment(this.currentShiftTimeRange.begin)
        .tz(this.timezone)
        .add(16, 'hours')
      const now = moment().tz(this.timezone)
      this.historyDateTime = now.isBefore(dateTime) ? null : dateTime
    },
    mapSalesIndicators(data) {
      const indicators = []

      indicators.push({
        icon: 'mdi-timer-outline',
        tooltip: 'Response Time',
        prev: data.previous_shift.response_time + '%',
        curr: data.current_shift.response_time + '%',
      })

      indicators.push({
        icon: 'price_check',
        tooltip: 'Total Marked As Sale',
        prev: data.previous_shift.total_mark_as_sale,
        curr: data.current_shift.total_mark_as_sale,
      })

      indicators.push({
        icon: 'mdi-currency-usd',
        tooltip: 'Total Revenue',
        prev: this.dollarFormatter(round(data.previous_shift.total_revenue)),
        curr: this.dollarFormatter(round(data.current_shift.total_revenue)),
      })
      indicators.push({
        icon: 'mdi-swap-horizontal',
        tooltip: 'Conversion Ratio',
        prev: data.previous_shift.conversion_ratio + '%',
        curr: data.current_shift.conversion_ratio + '%',
      })
      return indicators
    },
    mapCustomerSupportIndicators(data) {
      const indicators = []

      indicators.push({
        icon: 'mdi-timer-outline',
        tooltip: 'Response Time',
        prev: data.previous_shift.response_time + '%',
        curr: data.current_shift.response_time + '%',
      })

      indicators.push({
        icon: 'mdi-sticker-plus-outline',
        tooltip: 'Total Tickets Opened',
        prev: data.previous_shift.total_tickets_opened,
        curr: data.current_shift.total_tickets_opened,
      })

      indicators.push({
        icon: 'mdi-sticker-check-outline',
        tooltip: 'Total Tickets Resolved',
        prev: data.previous_shift.total_tickets_resolved,
        curr: data.current_shift.total_tickets_resolved,
      })

      return indicators
    },
  },
}
</script>

<style lang="scss">
.indicator-quarter {
  height: 50%;
  max-height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.absolute-progress {
  position: absolute;
  bottom: -3px;
}
</style>
