<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.122"
    height="20.355"
    viewBox="0 0 20.122 20.355"
  >
    <g transform="translate(-0.192 -9.5)">
      <g transform="translate(1.833 9.65)">
        <path
          class="a"
          d="M53.959,11.492V10.383h1.467V9.65H51.759v.733h1.467v1.109a8.432,8.432,0,1,0,.733,0Zm-.367,16.122a7.7,7.7,0,1,1,7.7-7.7A7.708,7.708,0,0,1,53.592,27.614Z"
          transform="translate(-45.16 -9.65)"
        />
      </g>
      <g transform="translate(8.07 15.883)">
        <path
          class="a"
          d="M200.647,163.192v3.881l-1.828,1.828.518.518,1.936-1.936a.366.366,0,0,0,.107-.259v-4.033Z"
          transform="translate(-198.819 -163.192)"
        />
      </g>
      <g transform="translate(9.899 12.95)">
        <rect class="a" width="0.733" height="1.1" />
      </g>
      <g transform="translate(6.399 13.585)">
        <path
          class="a"
          d="M158.278,106.6l-.318.183-.317.183.645,1.117.635-.367Z"
          transform="translate(-157.643 -106.599)"
        />
      </g>
      <g transform="translate(3.936 16.049)">
        <g transform="translate(0 0)">
          <rect
            class="a"
            width="0.733"
            height="1.291"
            transform="matrix(0.5, -0.866, 0.866, 0.5, 0, 0.635)"
          />
        </g>
      </g>
      <g transform="translate(3.3 19.549)">
        <rect class="a" width="1.1" height="0.733" />
      </g>
      <g transform="translate(3.935 22.502)">
        <g transform="translate(0 0)">
          <rect
            class="a"
            width="1.291"
            height="0.733"
            transform="translate(0 0.645) rotate(-29.993)"
          />
        </g>
      </g>
      <g transform="translate(6.399 24.759)">
        <g transform="translate(0 0)">
          <rect
            class="a"
            width="1.291"
            height="0.733"
            transform="translate(0 1.118) rotate(-60.007)"
          />
        </g>
      </g>
      <g transform="translate(4.478 27.432)">
        <g transform="translate(0 0)">
          <rect
            class="a"
            width="2.137"
            height="0.733"
            transform="matrix(0.5, -0.866, 0.866, 0.5, 0, 1.851)"
          />
        </g>
      </g>
      <g transform="translate(14.38 27.432)">
        <g transform="translate(0 0)">
          <rect
            class="a"
            width="0.733"
            height="2.137"
            transform="translate(0 0.367) rotate(-29.993)"
          />
        </g>
      </g>
      <g transform="translate(9.899 25.781)">
        <rect class="a" width="0.733" height="1.1" />
      </g>
      <g transform="translate(12.853 24.762)">
        <g transform="translate(0 0)">
          <rect
            class="a"
            width="0.733"
            height="1.291"
            transform="matrix(0.866, -0.5, 0.5, 0.866, 0, 0.367)"
          />
        </g>
      </g>
      <g transform="translate(15.112 22.502)">
        <g transform="translate(0 0)">
          <rect
            class="a"
            width="0.733"
            height="1.291"
            transform="matrix(0.5, -0.866, 0.866, 0.5, 0, 0.635)"
          />
        </g>
      </g>
      <g transform="translate(16.131 19.549)">
        <rect class="a" width="1.1" height="0.733" />
      </g>
      <g transform="translate(15.112 16.05)">
        <g transform="translate(0 0)">
          <rect
            class="a"
            width="1.291"
            height="0.733"
            transform="translate(0 0.645) rotate(-29.993)"
          />
        </g>
      </g>
      <g transform="translate(12.852 13.584)">
        <g transform="translate(0 0)">
          <rect
            class="a"
            width="1.291"
            height="0.733"
            transform="translate(0 1.118) rotate(-60.007)"
          />
        </g>
      </g>
      <g transform="translate(0.342 9.992)">
        <path
          class="a"
          d="M14.208,18.94a2.962,2.962,0,0,0-4.185,0l-.733.733a2.962,2.962,0,0,0,0,4.185.366.366,0,0,0,.518,0l4.4-4.4A.366.366,0,0,0,14.208,18.94ZM9.571,23.059a2.23,2.23,0,0,1,.238-2.867l.733-.733a2.23,2.23,0,0,1,2.867-.238Z"
          transform="translate(-8.425 -18.075)"
        />
      </g>
      <g transform="translate(14.272 9.992)">
        <path
          class="a"
          d="M356.628,19.677l-.733-.733a2.963,2.963,0,0,0-4.185,0,.366.366,0,0,0,0,.518l4.4,4.4a.367.367,0,0,0,.518,0,2.959,2.959,0,0,0,0-4.185Zm-.28,3.386-3.839-3.839a2.23,2.23,0,0,1,2.867.238l.733.733a2.226,2.226,0,0,1,.238,2.867Z"
          transform="translate(-351.603 -18.078)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Alarm',
}
</script>

<style scoped>
.a {
  fill: currentColor;
  stroke: currentColor;
  stroke-width: 0.3px;
}
</style>
